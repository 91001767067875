import styled from 'styled-components';

const jobSummaryStyle = () => {
  const FlexRow = styled.nav<{
    width?: string;
    marginTop?: string;
    margin?: string;
    background?: string;
    height?: string;
    boxshadow?: string;
    justifycontent?: string;
    borderTop?: string;
    border?: string;
    borderRadius?: string;
  }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({ justifycontent }) => justifycontent};
    align-items: center;
    position: relative;
    padding: 10px;
    text-align: center;
    border-radius: ${({ borderRadius }) => borderRadius};
    border: ${({ border }) => border};
    margin: ${({ margin }) => margin};
    box-shadow: ${({ boxshadow }) => boxshadow};
    background: ${({ background }) => background};
    @media screen and (min-width: 500px) {
      width: ${({ width }) => width};
    }
    @media screen and (max-width: 499px) {
      width: 100%;
    }
    margin-top: ${({ marginTop }) => marginTop};

    height: ${({ height }) => height};
    border-top: ${({ borderTop }) => borderTop};
  `;
  const DivContent = styled.div<{ margin?: string; textAlign?: string }>`
    // overflow: auto;
    height: 100%;
    width: 100%;
    text-align: ${({ textAlign }) => textAlign};
    // margin-top: 4.4rem;
    margin: ${({ margin }) => margin};
    // background-color: #fbfaff;
  `;

  const FlexCol = styled.div<{
    width?: string;
    // background?: string;
    height?: string;
    margin?: string;
    borderTop?: string;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // padding: 10px;
    text-align: center;
    // background: green;
    // width: 300px;
    @media screen and (max-width: 1200px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
    }

    border-top: ${({ borderTop }) => borderTop};
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  `;
  const Texts = styled.p<{
    fontsize?: string;
    // background?: string;
    height?: string;
    margin?: string;
    fontWeight?: string;
  }>`
    font-size: ${({ fontsize }) => fontsize};
    font-weight: ${({ fontWeight }) => fontWeight};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: ${({ margin }) => margin};

    @media screen and (max-width: 405px) {
      font-size: 1rem;
      margin: 0.5rem 0rem 0.5rem 0.5rem;
    }
  `;

  const CloseBtn = styled.button`
    position: absolute;
    right: -15px;
    top: -15px;
    width: 30px;
    height: 30px;
    background: #ffff;
    text-align: center;
    // color: #333;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding-bottom: 20px;
    @media screen and (max-width: 499px) {
      width: 10%;
    }
  `;
  const ImgTag = styled.img<{
    width?: string;
    height?: string;
    borderradius?: string;
  }>`
    // position: absolute;
    // object-fit: cover;
    border-radius: ${({ borderradius }) => borderradius};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: 0 10px;
    background-color: #fbfaff;
  `;
  const Timeline = styled.div`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    /* background-color: red; */

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      background-color: black;
      top: 0;
      bottom: 0;
      left: 10%;
      margin-left: -3px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    /* @media screen and (max-width: 600px) {
    &::after {
      left: 31px;
    }
  } */
  `;

  const TimelineContainer = styled.div<{
    backgroundColor?: string;
    borderColor?: string;
  }>`
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 100%;
    left: 10%;

    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: ${({ backgroundColor }) => backgroundColor ?? 'black'};
      border: 4px solid ${({ borderColor }) => borderColor ?? 'black'};
      top: 15px;
      border-radius: 50%;
      z-index: 1;
      left: -12px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    &::before {
      content: ' ';
      height: 0;
      position: absolute;
      top: 22px;
      width: 0;
      z-index: 1;
      left: 30px;
      border: medium solid rgba(60, 64, 67, 0.3);
      border-width: 10px 10px 10px 0;
      border-color: transparent rgba(60, 64, 67, 0.3) transparent transparent;
    }

    /* @media screen and (max-width: 600px) {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    left: 0%;

    &::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
      left: 15px;
    }
  } */
  `;

  const TimelineContent = styled.div`
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  `;

  const TransFromHtmlString = styled.div``;

  return {
    CloseBtn,
    DivContent,
    Texts,
    FlexCol,
    FlexRow,
    ImgTag,
    TransFromHtmlString,
    TimelineContent,
    TimelineContainer,
    Timeline,
  };
};

export default jobSummaryStyle;
