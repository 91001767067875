/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import { getAllAdminApproveLog } from 'src/store/actions';
import moment from 'moment';
import Role from 'src/constants/enum/role';
import WithdrawStatus from '../Common/WithdrawStatus';
import {
  getAllWithdrawTransactionsRequest as onGetAllWithdrawTransactionsRequest,
  approveWithdraw as onApproveWithdraw,
  rejectWithdraw as onRejectWithdraw,
} from '../../store/finance/withdraw/actions';
import CoinImage from '../Common/CoinImage';
import { transformStringAmountInput } from '../../utils/input';
import { transformAddressInShortFrom } from '../../utils/address';
import CustomTable from '../Common/CustomTable';
import withdrawStatusesEnum from '../../constants/withdrawStatusesEnum';
import withdrawStatuses from '../../constants/withdrawStatuses';
import SearchBar from '../Table/SearchBar';
import CurrencyOptions from '../Table/CurrencyOptions';
import WithdrawStatusOptions from '../Table/WithdrawStatusOptions';
import PageSizeOptions from '../Table/PageSizeOptions';
import TransactionDetailModal from '../Table/TransactionDetailModal';
import transactionModalTypes from '../../constants/transactionModalTypes';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';
import ChecksumBadge from '../Common/ChecksumBadge';

const WithdrawTransactionsTable = ({ t, tab, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currency, setCurrency] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(withdrawStatusesEnum.PENDING);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [param, setParam] = useState({
    page,
    limit: pageSize,
    status,
    search,
    currencySymbol: currency,
    sortBy,
    sortType,
    userId,
  });

  const { txn, influencerData } = useSelector((state: any) => ({
    txn: state.Withdraw.transactions,
    influencerData: state.Users.userInfo,
  }));
  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('withdraw');
    dispatch(getAllAdminApproveLog());
  };

  useEffect(() => {
    fetchApproveLog();

    const status =
      tab === 1 ? withdrawStatusesEnum.PENDING : withdrawStatusesEnum.ALL;

    setPage(1);
    setPageSize(defaultPageSize);
    setCurrency('');
    setStatus(status);

    setParam({
      ...param,
      page: 1,
      limit: defaultPageSize,
      currencySymbol: '',
      status,
    });
    // Fetch Data With Default Payload When Tab Changed
    dispatch(
      onGetAllWithdrawTransactionsRequest({
        limit: defaultPageSize,
        page: 1,
        status,
        userId,
      }),
    );
  }, [tab]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };
  // const primeZone = moment.tz('2013-11-18 00:00:00', 'Iceland');
  // console.log('object', primeZone);
  // const currentTimeZone = primeZone.tz('Pacific/Midway').format('LT');

  // console.log('currentTimeZone', currentTimeZone);
  const dataTimezone = (created_at: any) => {
    return moment(created_at);
  };
  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ created_at }) => {
          return (
            <>
              <DateTimeComponent
                dateTime={dataTimezone(created_at)}
                dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
              />
            </>
          );
        },
        id: 'created_at',
      },
      {
        Header: t('table:uuid'),
        accessor: ({ uuid }) => {
          return <>{uuid}</>;
        },
        id: 'uuid',
        width: 200,
        disableSort: true,
      },
      {
        Header: t('table:Email'),
        accessor: ({ user }) => {
          return user.email;
        },
        id: 'email',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:Role'),
        accessor: ({ user }) => {
          if (user.role === Role.PUBLISHER || user.role === Role.INFLUENCER) {
            return Role.INFLUENCER;
          } else if (user.role === Role.ADVERTISER || user.role === Role.MARKETER) {
            return Role.MARKETER;
          } else {
            return Role.ADMIN;
          }
        },
        id: 'role',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:Currency'),
        accessor: ({ currency }) => {
          return (
            <>
              <CoinImage symbol={currency.symbol} />
            </>
          );
        },
        id: 'currency',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:checksum_status'),
        // eslint-disable-next-line camelcase
        accessor: ({ checksum_status }) => {
          return <ChecksumBadge checksum_status={{ checksum_status }} />;
        },
        id: 'checksum_status',
        width: 160,
        disableSort: true,
      },
      {
        Header: t('table:Crypto Amount'),
        // eslint-disable-next-line camelcase
        accessor: ({ CRYPTO_amount, currency }) => {
          return `${transformStringAmountInput(CRYPTO_amount)} ${
            currency.main_symbol
          }`;
        },
        id: 'CRYPTO_amount',
        width: 160,
        disableSort: true,
      },
      {
        Header: t('table:Crypto Fiat'),
        // eslint-disable-next-line camelcase
        accessor: ({ FIAT_amount }) => {
          return `${transformStringAmountInput(FIAT_amount)} USDT`;
        },
        id: 'FIAT_amount',
        width: 120,
        disableSort: true,
      },
      {
        Header: t('table:Fiat Rate'),
        // eslint-disable-next-line camelcase
        accessor: ({ FIAT_rate, currency }) => {
          return (
            <>
              {currency.symbol === 'USDT_BEP20'
                ? transformStringAmountInput(FIAT_rate)
                : FIAT_rate.toFixed(9)}{' '}
              USDT
            </>
          );
        },
        id: 'FIAT_rate',
        width: 120,
        disableSort: true,
      },
      {
        Header: t('table:Fee'),
        // eslint-disable-next-line camelcase
        accessor: ({ fee }) => {
          return <>{transformStringAmountInput(fee)} USDT</>;
        },
        id: 'fee',
        width: 120,
        disableSort: true,
      },
      {
        Header: t('table:Dest Address'),
        accessor: ({ address }) => {
          return transformAddressInShortFrom(address, 12);
        },
        id: 'dest_address',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:Tranfer Type'),
        accessor: ({ transferType }) => {
          return transferType;
        },
        id: 'transferType',
        width: 160,
        disableSort: true,
      },
      {
        Header: t('table:Status'),
        accessor: ({ status }) => {
          return <WithdrawStatus status={status} />;
        },
        id: 'status',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:Fairmarket Message'),
        accessor: ({ fairmarket_message }) => {
          return fairmarket_message;
        },
        id: 'fairmarket_message',
        width: 160,
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: (originalRow, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="fas fa-file-alt fa-1x c-pointer" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-start">
                  {isEmpty(userId) && (
                    <>
                      <Link to={`/admin/users/${originalRow.user.uuid}`}>
                        <DropdownItem>
                          <i className="fas fa-user" />
                          &nbsp;&nbsp;
                          {t('table:User Detail')}
                        </DropdownItem>
                      </Link>
                      <div className="dropdown-divider" />
                    </>
                  )}
                  {originalRow.status === withdrawStatuses.PENDING && (
                    <>
                      <DropdownItem
                        onClick={() => {
                          handleApproveWithdraw(originalRow);
                        }}
                      >
                        <i className="fas fa-check-circle icon-green" />
                        &nbsp;&nbsp;{t('table:Approve')}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleCancelWithdraw(originalRow);
                        }}
                      >
                        <i className="fas fa-trash icon-red" />
                        &nbsp;&nbsp;{t('table:Reject')}
                      </DropdownItem>
                      <div className="dropdown-divider" />
                    </>
                  )}
                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('table:Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'action',
        disableSort: true,
      },
    ],
    [t, txn, modalOpen],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(onGetAllWithdrawTransactionsRequest(payload));
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(onGetAllWithdrawTransactionsRequest(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleCurrencyChange = (currency: string) => {
    const payload = {
      ...getDefaultPayload(1),
      currencySymbol: currency,
    };

    dispatch(onGetAllWithdrawTransactionsRequest(payload));
    setCurrency(currency);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(onGetAllWithdrawTransactionsRequest(payload));
    setSearch(search);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(onGetAllWithdrawTransactionsRequest(payload));
    setSearch('');
    setParam(payload);
  };

  const handleStatusChange = (status: string) => {
    const payload = {
      ...getDefaultPayload(1),
      status,
    };

    dispatch(onGetAllWithdrawTransactionsRequest(payload));
    setStatus(status);
    setParam(payload);
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);

    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const handleCancelWithdraw = (row: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Withdraw Reject Confirm', {
        amount: row.FIAT_amount,
        symbol: row.currency.symbol,
        address: transformAddressInShortFrom(row.address, 6),
      }),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onRejectWithdraw(row.uuid));

        if (modalOpen) handleModalToggle();
      }
    });
  };

  const handleApproveWithdraw = (row: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Withdraw Approve Confirm', {
        amount: row.FIAT_amount,
        symbol: row.currency.symbol,
        address: transformAddressInShortFrom(row.address, 6),
      }),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onApproveWithdraw(row.uuid));

        if (modalOpen) handleModalToggle();
      }
    });
  };

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetAllWithdrawTransactionsRequest(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [status, search, currency, page, pageSize],
  );

  if (!txn) return <></>;

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <TransactionDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('table:Withdrawal Detail')}
          modalType={transactionModalTypes.WITHDRAW}
          handleApproveWithdraw={handleApproveWithdraw}
          handleCancelWithdraw={handleCancelWithdraw}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {tab === 2 && (
              <div style={{ width: '150px', marginRight: '20px' }}>
                <WithdrawStatusOptions
                  defaultValue={status}
                  handleStatusChange={handleStatusChange}
                />
              </div>
            )}
            <div style={{ width: '150px', marginRight: '20px' }}>
              <CurrencyOptions
                defaultValue={currency}
                handleCurrencyChange={handleCurrencyChange}
              />
            </div>
            <SearchBar
              placeholder={t('table:Search Placeholder')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(WithdrawTransactionsTable);
