import { useEffect, useState } from 'react';
import { Card, CardFooter, FormGroup } from 'reactstrap';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDefaultCampaignGraphql } from 'src/graphql/default-value/default-campaign';
import { setLoading, unsetLoading } from '../../store/global/actions';

const SettingBudgetForm = ({
  t,
  defaultMinimumBudget,
  defaultMinimumMaxBudget,
  minimumBudgetKey,
  minimumMaxBudgetKey,
}: any) => {
  const dispatch = useDispatch();

  const [minimumBudget, setMinimumBudget] = useState('');
  const [minimumMaxBudget, setMinimumMaxBudget] = useState('');

  const initialValues = {
    minimumBudget: defaultMinimumBudget,
    minimumMaxBudget: defaultMinimumMaxBudget,
  };

  const createCampaignSchema = Yup.object().shape({
    minimumBudget: Yup.number().required('Required').min(0),
    minimumMaxBudget: Yup.number().required('Required').min(0),
  });

  const onsubmit = async () => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t('swal:Do you want to update!'),
      width: 300,
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        if (minimumBudget !== defaultMinimumBudget) {
          await updateDefaultCampaignGraphql({
            key: minimumBudgetKey,
            value: minimumBudget.toString(),
          });
        }
        if (minimumMaxBudget !== defaultMinimumMaxBudget) {
          await updateDefaultCampaignGraphql({
            key: minimumMaxBudgetKey,
            value: minimumMaxBudget.toString(),
          });
        }
        dispatch(unsetLoading());
      }
    });
  };

  useEffect(() => {
    if (defaultMinimumBudget) {
      setMinimumBudget(defaultMinimumBudget);
    }
  }, [defaultMinimumBudget]);

  useEffect(() => {
    if (defaultMinimumBudget) {
      setMinimumMaxBudget(defaultMinimumMaxBudget);
    }
  }, [defaultMinimumMaxBudget]);

  return (
    <Card className="p-4">
      <Formik
        initialValues={initialValues}
        onSubmit={onsubmit}
        validationSchema={createCampaignSchema}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <FormikForm>
              <FormGroup>
                <p>{t('campaign:Minimum Daily Budget')}</p>

                <div className="input-group">
                  <Field
                    name="minimumBudget"
                    type="number"
                    step="any"
                    min="0"
                    value={minimumBudget}
                    className={`form-control form-control-lg ${
                      errors.minimumBudget && touched.minimumBudget && 'is-invalid '
                    } `}
                    onChange={(e: any) => {
                      setMinimumBudget(e.currentTarget.value);
                      handleChange(e);
                    }}
                  />
                  <div className="input-group-text">$ / CPM</div>
                  <ErrorMessage
                    name="minimumBudget"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <p>{t('campaign:Minimum Max Budget')}</p>

                <div className="input-group">
                  <Field
                    name="minimumMaxBudget"
                    type="number"
                    step="any"
                    min="0"
                    value={minimumMaxBudget}
                    className={`form-control form-control-lg ${
                      errors.minimumMaxBudget &&
                      touched.minimumMaxBudget &&
                      'is-invalid '
                    } `}
                    onChange={(e: any) => {
                      setMinimumMaxBudget(e.currentTarget.value);
                      handleChange(e);
                    }}
                  />
                  <div className="input-group-text">$ / CPM</div>
                  <ErrorMessage
                    name="minimumMaxBudget"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </FormGroup>
              <CardFooter>
                {' '}
                <h3 className="float-end">
                  <button type="submit" className="btn btn-success btn-md">
                    {t('button:Confirm')}
                  </button>
                </h3>{' '}
              </CardFooter>
            </FormikForm>
          );
        }}
      </Formik>
    </Card>
  );
};
export default withTranslation()(SettingBudgetForm);
