import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { getInfoWebsite } from 'src/store/actions';
import { useDispatch } from 'react-redux';
import EarningHistory from 'src/components/Publisher/EarningHistory';
import WebsiteCreativeSize from 'src/components/Publisher/Website/WebsiteCreativeSize';
import { withTranslation } from 'react-i18next';
import menu from 'src/constants/menu';
import { useParams } from 'react-router-dom';
import { CardBody, Col, Container, Row, Card, CardHeader } from 'reactstrap';
import config from 'src/config';
import WebsiteTypeSetting from 'src/components/Publisher/Website/WebsiteTypeSetting';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import useWindowDimensions from '../../../components/useWindowDimensions';
import RequestSize from '../../../components/Publisher/RequestSize';
import AdAuto from './webInfo/AdAuto';
import WebReport from './webInfo/WebReport';
import WebCardInfo from './webInfo/WebCardInfo';
import WebTabBar from './webInfo/WebTabBar';

const WebsiteInfo = ({ t }: any) => {
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const [headerPillsTab, setHeaderPillsTab] = useState(1);
  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    dispatch(getInfoWebsite(+id));
  }, []);

  const toggleHeaderPills = (tab: any) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const renderCardWebOnLg = (index: number) => {
    let menuWidth = 0;
    if (document.getElementsByClassName('vertical-menu')?.length) {
      menuWidth = document.getElementsByClassName('vertical-menu')[0].clientWidth;
    }
    if (windowWidth - menuWidth < 1000) return 12;
    return index === 1 ? 4 : 8;
  };

  const infoContent = () => {
    switch (headerPillsTab) {
      case 1:
        return (
          <Row className="flex-column flex-md-row">
            <Col xs={12} md={5} lg={renderCardWebOnLg(1)}>
              <WebsiteCreativeSize />
            </Col>
            <Col xs={12} md={7} lg={renderCardWebOnLg(2)}>
              <RequestSize />
            </Col>
          </Row>
        );
      case 2:
        return <AdAuto />;
      case 3:
        return <WebReport />;
      case 4:
        return <WebsiteTypeSetting />;
      case 5:
        return <EarningHistory />;
      default:
        return <></>;
    }
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('Page.Website Info')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Page.Website Info')}
          menus={[{ menu: menu.DASHBOARD }, { menu: menu.WEBSITE, active: true }]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <WebTabBar
                  headerPillsTab={headerPillsTab}
                  toggleHeaderPills={toggleHeaderPills}
                />
              </CardHeader>
              <CardBody>
                <Row lg={12}>
                  <Col xs={12}>
                    <WebCardInfo />
                  </Col>
                  <Col xs={12}>
                    <CardBody className="p-0">{infoContent()}</CardBody>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(WebsiteInfo);
