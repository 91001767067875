import React, { useState } from 'react';
import { Column } from 'react-table';
import { withTranslation } from 'react-i18next';
import { transactionDetailDateTime } from 'src/utils/dateTime';
import i18n from 'src/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { getAdminEarningTransaction } from 'src/store/website/actions';
import { isEmpty } from 'lodash';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import EarningDetailModal from './EarningDetailModal';
import DateTimeComponent from '../Common/DateTimeComponent';
import DateTimeTypes from '../../constants/dateTimeTypes';

const AdminEarningHistory = ({ t }: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const { txn } = useSelector((state: any) => ({
    txn: state.WebsiteInfo.adminEarningTransaction,
  }));

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Action'),
        accessor: (originalRow, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="mdi mdi-dots-vertical" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-start">
                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'col-1',
        width: 100,
      },
      {
        Header: t('table:No.'),
        accessor: (originalRow, rowIndex) => {
          return pageSize * (page - 1) + (rowIndex + 1);
        },
        id: 'col-2',
        width: 100,
      },
      {
        Header: t('table:Timestamp'),
        accessor: ({ created_at }) => {
          return (
            <DateTimeComponent
              dateTime={created_at}
              dateTimeType={DateTimeTypes.RECENT_TRANSACTION_DATE_TIME}
            />
          );
        },
        id: 'col-3',
      },
      {
        Header: t('table:Transaction Type'),
        accessor: ({ movement }) => {
          return movement.transaction_type_id;
        },
        id: 'col-4',
        width: 100,
      },
      {
        Header: t('table:Fiat Amount'),
        accessor: 'FIAT_amount',
        id: 'col-5',
        width: 100,
      },
      {
        Header: t('table:Opening Balance'),
        accessor: ({ movement }) => {
          return movement.opening_balance;
        },
        id: 'col-6',
        width: 120,
      },
      {
        Header: t('table:Timestamp'),
        accessor: ({ created_at }) =>
          transactionDetailDateTime(created_at, i18n.language),
        width: 200,
        id: 'col-7',
      },
    ],
    [t, page],
  );
  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = ['col-2'];
  const hiddenOnLG: any = [];

  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      page,
      verifyStatus: 'EARNING',
    };
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(),
      limit: newPageSize,
      page: newPage,
    };

    dispatch(getAdminEarningTransaction(payload));

    setPageSize(newPageSize);
    setPage(newPage);
  };

  return (
    <>
      <CustomTable
        columns={columns}
        data={txn.items || []}
        // page={1}
        // pageSize={10}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
      >
        {modalOpen && (
          <EarningDetailModal
            data={!isEmpty(txn) ? txn.items[selectedRow] : null}
            isOpen={modalOpen}
            handleToggle={handleModalToggle}
            handleClose={handleModalClose}
            title={t('Earning Detail')}
          />
        )}
        <Row className="justify-content-between m-0">
          <Col xs={12} md={2} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(AdminEarningHistory);
