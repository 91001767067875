/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageAutoBalance from 'src/components/Common/ImageAutoBalance';
import Dropzone from 'react-dropzone';
import { Card, CardSubtitle, Col, FormGroup, Label, Row } from 'reactstrap';
import jimp from 'jimp';
import { GifFrame, GifUtil, GifCodec } from 'gifwrap';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';

const ImageFromUploadDisplay = (props: any) => {
  const {
    t,
    selectedFiles,
    setSelectedFiles,
    isMissFileSize,
    setIsMissFileSize,
    isMissDimension,
    setIsMissDimension,
    creativeSizes,
    isNative,
  } = props;

  const previewCanvasRef = useRef(null);
  const imgRef: any = useRef(null);
  const [completedCrop, setCompletedCrop] = useState<any>(null);

  const scaleX = imgRef.current
    ? imgRef.current.naturalWidth / imgRef.current.width
    : 0;
  const scaleY = imgRef.current
    ? imgRef.current.naturalHeight / imgRef.current.height
    : 0;

  const handleImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const handleOnCropComplete = (afterCrop: any) => {
    setCompletedCrop(afterCrop);
  };

  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (sizes[i] === 'Bytes') {
      setIsMissFileSize(false);
    } else if (sizes[i] === 'KB') {
      if (parseFloat((bytes / k ** i).toFixed(dm)) > 300) {
        setIsMissFileSize(true);
      } else {
        setIsMissFileSize(false);
      }
    } else {
      setIsMissFileSize(true);
    }

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const formatSize = (targetFile: any, callback: any) => {
    const url = URL.createObjectURL(targetFile);
    const img = new Image();
    img.src = url;

    const size = creativeSizes.item.map((item: any) => [item.width, item.height]);

    img.onload = () => {
      URL.revokeObjectURL(img.src);
      if (size) {
        for (let i = 0; i < size?.length; i++) {
          if (img.width === size[i][0]) {
            if (img.height === size[i][1]) {
              setIsMissDimension(false);
              break;
            } else {
              setIsMissDimension(!isNative);
            }
          } else {
            setIsMissDimension(!isNative);
          }
        }
      }

      callback(`${img.width}x${img.height}`);
    };
  };

  const handleAcceptedFiles = (files: any) => {
    files.map((targetFile: any) => {
      return Object.assign(targetFile, {
        preview: URL.createObjectURL(targetFile),
        formattedSize: formatBytes(targetFile.size),
      });
    });

    if (
      files[0].type === 'image/jpg' ||
      files[0].type === 'image/jpeg' ||
      files[0].type === 'image/png' ||
      files[0].type === 'image/gif'
    ) {
      formatSize(files[0], async (callback: any) => {
        const targetFile = files[0];
        targetFile.fileSize = await callback;

        setSelectedFiles(files);
      });
    } else {
      notify(i18n.t('error:Invalid file type.'), 'error');
    }
  };

  const generateFile = async (canvas: any, cropImage: any) => {
    if (!cropImage || !canvas) {
      return;
    }

    await canvas.toBlob(
      (blob: any) => {
        const newBlob = blob;
        newBlob.name = selectedFiles[0].name;
        handleAcceptedFiles([newBlob]);
        setCrop({
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        });
      },
      'image/jpeg',
      1,
    );
  };

  const generateDownload = async (canvas: any, cropImage: any) => {
    if (!cropImage || !canvas) {
      return;
    }

    // await resizeGif();
    // await resize();

    canvas.toBlob(
      (blob: Blob | MediaSource) => {
        const previewUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = selectedFiles[0].name;
        anchor.href = URL.createObjectURL(blob);
        anchor.click();
        window.URL.revokeObjectURL(previewUrl);
      },
      'image/jpeg',
      1,
    );
  };

  const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const resizeGif = async () => {
    const fileBase64: any = await toBase64(selectedFiles[0]);

    GifUtil.read(fileBase64).then((inputGif) => {
      inputGif.frames.forEach((frame) => {
        const buf = frame.bitmap.data;
        frame.scanAllCoords((x, y, bi) => {
          // Halve all grays on right half of image.

          if (x > inputGif.width / 2) {
            const r = buf[bi];
            const g = buf[bi + 1];
            const b = buf[bi + 2];
            const a = buf[bi + 3];
            if (r === g && r === b && a === 0xff) {
              buf[bi] /= 2;
              buf[bi + 1] /= 2;
              buf[bi + 2] /= 2;
            }
          }
        });
      });

      // // Pass inputGif to write() to preserve the original GIF's specs.
      // return GifUtil.write('modified.gif', inputGif.frames, inputGif).then(
      //     (outputGif) => {
      //         console.log('modified');
      //     },
      // );
    });
  };

  const resize = async () => {
    const image = await jimp.read(selectedFiles[0].preview);
    image.resize(crop.width, crop.height);

    await image.getBase64Async(jimp.MIME_JPEG).then((newImage) => {
      const a = document.createElement('a');
      a.href = newImage;
      a.download = selectedFiles[0].name;
      a.click();
    });
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const cropCanvas: any = completedCrop;
    const canvas: any = previewCanvasRef.current;

    const canvasX = Math.round(cropCanvas.width * scaleX);
    const canvasY = Math.round(cropCanvas.height * scaleY);
    canvas.width = canvasX;
    canvas.height = canvasY;

    const ctx = canvas.getContext('2d');

    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      imgRef.current,
      Math.round(cropCanvas.x * scaleX),
      Math.round(cropCanvas.y * scaleY),
      canvasX,
      canvasY,
      0,
      0,
      canvasX,
      canvasY,
    );
  }, [completedCrop]);

  const autoCalCanvasHeight =
    400 /
    scaleY /
    (Math.round(completedCrop?.width ?? 0) / Math.round(completedCrop?.height ?? 0));

  const autoCalCanvasWidth =
    400 /
    scaleX /
    (Math.round(completedCrop?.height ?? 0) / Math.round(completedCrop?.width ?? 0));

  return (
    <>
      {selectedFiles?.length !== 0 && (
        <div
          style={{
            textAlign: 'center',
            border: '1px solid',
          }}
        >
          <div>
            {isMissDimension || isMissFileSize ? (
              <>
                <ReactCrop
                  className="mt-2"
                  locked
                  ruleOfThirds
                  src={selectedFiles[0].preview}
                  crop={crop}
                  onImageLoaded={handleImageLoaded}
                  onComplete={handleOnCropComplete}
                  onChange={(c: any) => {
                    setCrop(c);
                  }}
                />
              </>
            ) : (
              <>
                <ImageAutoBalance
                  href={null}
                  alt={selectedFiles[0].name}
                  src={selectedFiles[0].preview}
                  sizeImage={400}
                  width={+selectedFiles[0].fileSize.split('x')[0]}
                  height={+selectedFiles[0].fileSize.split('x')[1]}
                />
              </>
            )}
          </div>

          {crop.width !== 0 && (
            <>
              <div>
                {Math.round(completedCrop?.width ?? 0) >
                Math.round(completedCrop?.height ?? 0) ? (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: 'auto',
                      height: autoCalCanvasHeight,
                      border: '1px solid',
                    }}
                  />
                ) : (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: autoCalCanvasWidth,
                      height: 'auto',
                      border: '1px solid',
                    }}
                  />
                )}
              </div>
              <button
                className="btn btn-secondary mt-1 mb-2"
                type="button"
                onClick={() =>
                  generateDownload(previewCanvasRef.current, completedCrop)
                }
              >
                {t('creative:Download cropped image')}
              </button>
            </>
          )}

          {(isMissDimension || isMissFileSize) && (
            <div
              style={{
                textAlign: 'center',
                maxWidth: '80%',
                minWidth: '80%',
                margin: '0 auto',
              }}
            >
              <div>
                {selectedFiles[0].name}
                <br />
                {t('Size')}:{' '}
                {crop.width === 0
                  ? selectedFiles[0].fileSize
                  : `${Math.round(crop.width * scaleX)}x${Math.round(
                      crop.height * scaleY,
                    )}`}
              </div>
            </div>
          )}
        </div>
      )}
      <Dropzone
        onDrop={(acceptedFiles: any) => {
          handleAcceptedFiles(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={`${
              selectedFiles?.length === 0 ? 'dropzone' : 'dropzone-sm mt-4'
            }`}
          >
            <div
              className={`${
                selectedFiles?.length === 0 && 'dz-message needsclick mt-2'
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              {selectedFiles?.length === 0 ? (
                <>
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload animation-bounce" />
                  </div>
                  <h4>{t('creative:Drop files here or click to upload')}</h4>
                  <h5>{t('creative:You can crop images after upload')}</h5>
                </>
              ) : (
                <div className="p-2 text-center">
                  <i className="display-4 text-muted bx bxs-cloud-upload animation-bounce" />
                  <div>
                    {t('creative:Drop files here or click to change the image')}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      {selectedFiles?.length === 0 && (
        <CardSubtitle className="mb-3 mt-3 font-size-14">
          {!isNative ? t('creative:creative size') : t('creative:recommended size')}:{' '}
          {creativeSizes.longString} {t('creative:Maximum file size 300KB')} (
          {t('creative:only JPG, JPEG, PNG, GIF')})
        </CardSubtitle>
      )}

      {selectedFiles?.length !== 0 && (
        <Card className="p-3 text-center p-2 mt-3">
          <Row>
            {!isMissDimension && !isMissFileSize ? (
              <Col xs={7}>
                <strong>
                  {selectedFiles[0].name}
                  <br />

                  {selectedFiles[0].fileSize}
                </strong>
              </Col>
            ) : (
              <>
                <Col
                  xs={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Label className="mb-0">{t('creative:Select size')}</Label>
                </Col>
                <Col xs={4}>
                  <FormGroup className="text-center">
                    <select
                      className="form-control form-control-sm mt-1 w-max-content ms-auto me-auto"
                      onChange={(e) => {
                        const cropSize = e.target.value.split('x');
                        const cropWidth = +cropSize[0].slice(0, -1);

                        const selectCrop = {
                          x: 0,
                          y: 0,
                          width: 0,
                          height: 0,
                        };
                        if (cropSize[1]) {
                          const cropHeight = +cropSize[1].substring(1);

                          selectCrop.width = cropWidth / scaleX;
                          selectCrop.height = cropHeight / scaleY;
                        }

                        setCrop(selectCrop);
                        setCompletedCrop(selectCrop);
                      }}
                    >
                      {['SelectImgSize', ...creativeSizes.item].map((size: any) => {
                        if (typeof size === 'string') {
                          return <option key={0}>{t(`creative:${size}`)}</option>;
                        } else {
                          return (
                            <option key={size.id}>
                              {`${size.width} x ${size.height}`}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </>
            )}

            {crop.width === 0 ? (
              <Col xs={4}>
                {isMissFileSize && (
                  <span
                    className={`badge rounded-pill badge-soft-danger fw-bold fs-6 mx-1 ${
                      !isMissDimension && 'mt-2'
                    }`}
                  >
                    {t('creative:Invalid File Size')}
                  </span>
                )}
                {isMissDimension && (
                  <span className="badge rounded-pill badge-soft-danger fw-bold fs-6 mx-1 mt-2">
                    {t('creative:Invalid File Dimensions')}
                  </span>
                )}

                {!isMissDimension && !isMissFileSize && (
                  <span className="badge rounded-pill badge-soft-success fw-bold fs-6 mx-1 mt-2">
                    {t('creative:Can be used')}
                  </span>
                )}
              </Col>
            ) : (
              <Col xs={4}>
                <button
                  type="button"
                  className="btn btn-success w-100 d-flex justify-content-center align-items-center mt-1"
                  style={{ height: 30 }}
                  onClick={() =>
                    generateFile(previewCanvasRef.current, completedCrop)
                  }
                >
                  {t('creative:Crop')}
                </button>
              </Col>
            )}
          </Row>
        </Card>
      )}
    </>
  );
};

export default withTranslation()(ImageFromUploadDisplay);
