import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createMarketerCampaignGql,
  filterQql,
  getMarketerCampaignsGql,
  updateMarketerCampaignGql,
} from 'src/graphql/advertiser/marketerCampaign';

import { setLoading, unsetLoading } from '../actions';
import {
  filterMarketerCampaignSuccess,
  getMarketerCampaignSuccess,
  updateMarketerCampaignSuccess,
} from './actions';
import MarketerCampaignTypes from './actionTypes';

function* createMarketerCampaign({ payload }: any) {
  try {
    yield put(setLoading());

    yield call(createMarketerCampaignGql, payload);
  } catch (error) {
    // something here
  } finally {
    // CreateJob

    yield put(unsetLoading());
  }
}

function* getMarketerCampaign({ key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getMarketerCampaignsGql, key);

    yield put(getMarketerCampaignSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* updateMarketerCampaign({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(updateMarketerCampaignGql, payload);

    yield put(updateMarketerCampaignSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* filter({ payload, campaignType }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(filterQql, payload);

    yield put(filterMarketerCampaignSuccess(response, campaignType));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* marketerMarkCampaigns() {
  yield takeEvery(
    MarketerCampaignTypes.CREATE_MARKETER_CAMPAIGN,
    createMarketerCampaign,
  );

  yield takeEvery(MarketerCampaignTypes.GET_MARKETER_CAMPAIGN, getMarketerCampaign);
  yield takeEvery(
    MarketerCampaignTypes.UPDATE_MARKETER_CAMPAIGN,
    updateMarketerCampaign,
  );

  yield takeEvery(MarketerCampaignTypes.FILTER_MARKETER_CAMPAIGN, filter);
}

export default marketerMarkCampaigns;
