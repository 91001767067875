import ChangePasswordTypes from './actionTypes';

export const changePasswordRequest = (data: any, history: any) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_REQUEST,
    payload: { data, history },
  };
};

export const changePasswordSuccess = (message: string) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload: message,
  };
};

export const changePasswordFailed = (message: any) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_REQUEST_ERROR,
    payload: message,
  };
};

export const changePasswordConfirm = (data: any, history: any) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM,
    payload: { data, history },
  };
};

export const changePasswordConfirmSuccess = (message: string) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM_SUCCESS,
    payload: message,
  };
};

export const changePasswordConfirmFailed = (message: any) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM_ERROR,
    payload: message,
  };
};
