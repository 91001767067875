import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';

const onCheckLogoBySocialType = (type: SocialMediaType, setSocialMediaLogo: any) => {
  switch (type) {
    case SocialMediaType.INSTAGRAM:
      return setSocialMediaLogo(instagramLogo);
    case SocialMediaType.TIKTOK:
      return setSocialMediaLogo(tikTokLogo);
    case SocialMediaType.TWITTER:
      return setSocialMediaLogo(twitterLogo);
    case SocialMediaType.YOUTUBE:
      return setSocialMediaLogo(youtubeLogo);
    default:
      return setSocialMediaLogo(facebookLogo);
  }
};

export default onCheckLogoBySocialType;
