import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { transformStringAmountInput } from '../../../utils/input';
import SumWalletsRolePieChart from './SumWalletsRolePieChart';
import { roleText } from '../../../constants/role';

const SumWalletsRoleBalance = ({ t }: any) => {
  const { sumWalletsRole } = useSelector((state: any) => ({
    sumWalletsRole: state.SystemBalance.sumWalletsRole,
  }));

  return (
    <div className="admin-text-white">
      <Col xl={6}>
        <Link to="/admin/role-balance-log">
          <Card className="card-h-100">
            <CardBody>
              <div className="d-flex flex-wrap align-items-center mb-4">
                <h5 className="card-title me-2">{t('Wallets Balance Role')}</h5>
              </div>

              <Row className="align-items-center">
                <Col xl={6}>
                  <div id="wallet-balance" className="apex-charts">
                    <SumWalletsRolePieChart />
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="mt-4 mt-sm-0">
                    <Row>
                      {!isEmpty(sumWalletsRole) && (
                        <Col xl={12}>
                          <div className="pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-14 me-2 text-mkt" />{' '}
                              {t(roleText.ADVERTISER)}
                            </p>
                            <h6 className="text-white">
                              {transformStringAmountInput(
                                sumWalletsRole.advertiser_sum,
                              )}{' '}
                              USDT
                            </h6>
                          </div>
                          <div className="pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-14 me-2 text-influ" />{' '}
                              {t(roleText.PUBLISHER)}
                            </p>
                            <h6 className="text-white">
                              {transformStringAmountInput(
                                sumWalletsRole.publisher_sum,
                              )}{' '}
                              USDT
                            </h6>
                          </div>
                          <div className="pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-14 me-2 text-admin" />{' '}
                              {t(roleText.ADMIN)}
                            </p>
                            <h6 className="text-white">
                              {transformStringAmountInput(sumWalletsRole.admin_sum)}{' '}
                              USDT
                            </h6>
                          </div>
                          <div className="pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-14 me-2 text-hold" />{' '}
                              {t(roleText.HOLD)}
                            </p>
                            <h6 className="text-white">
                              {transformStringAmountInput(sumWalletsRole.hold_sum)}{' '}
                              USDT
                            </h6>
                          </div>
                          <div className="pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-14 me-2 text-fee" />{' '}
                              {t(roleText.FEE)}
                            </p>
                            <h6 className="text-white">
                              {transformStringAmountInput(sumWalletsRole.fee_sum)}{' '}
                              USDT
                            </h6>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Link>
      </Col>
    </div>
  );
};

export default withTranslation()(SumWalletsRoleBalance);
