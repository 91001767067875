import styled from 'styled-components';

const InfluencerPostDetailStyle = () => {
  const InfluencerPostDetailContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: auto;
    box-sizing: border-box;
  `;

  const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: max-content;
    min-height: 300px;
    width: 100%;
    max-width: 50rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #fbfaff;
  `;

  const Profile = styled.img`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    animation-delay: 2.5s;
    animation: profile 0.5s linear alternate;

    @keyframes profile {
      0% {
        top: -50px;
        left: 50%;
        transform: translateX(-50%) scale(0.5);
        width: 130px;
        height: 130px;
      }
      100% {
        top: -50px;
        left: 50%;
        transform: translateX(-50%) scale(1);
        width: 130px;
        height: 130px;
      }
    }
  `;

  const ChannelLogo = styled.img`
    position: absolute;
    object-fit: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    top: -53px;
    left: 53%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    animation-delay: 2s;
    animation: channelLogo 0.5s linear alternate;
    background-color: #fbfaff;

    @keyframes channelLogo {
      0% {
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: 130px;
        height: 130px;
      }
      25% {
        top: -50px;
        left: 50%;
        transform: translateX(-50%) scale(1.2);
        width: 130px;
        height: 130px;
      }
      45% {
        top: -50px;
        left: 50%;
        transform: translateX(-50%) scale(1);
        width: 130px;
        height: 130px;
      }
      100% {
        top: -53px;
        left: 55%;
        width: 40px;
        height: 40px;
      }
    }
  `;

  const textEllipsis: string = `
    width: 500px;
    height: 1.2em;
    white-space: nowrap;
  `;

  const NameUser = styled.p`
    ${textEllipsis}
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 4.5rem;
    text-align: center;
  `;

  const CreatedPostDateTime = styled.p`
    ${textEllipsis}
    font-size: 1em;
    font-weight: lighter;
    text-align: center;
    color: #a2b5bb;
  `;

  const MessagePost = styled.div`
    width: 100%;
    overflow: hidden;
    font-size: 1em;
    font-weight: lighter;
    resize: none;
    border: none;
    outline: none;
    white-space: pre-wrap;
    padding: 1.5rem;
    background: white;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    label {
      font-size: 1.5rem;
      font-weight: bold;
      @media (max-width: 570px) {
        font-size: 18px;
      }
    }
    @media (max-width: 570px) {
      width: 450px;
    }
    @media (max-width: 540px) {
      width: 400px;
    }
    @media (max-width: 465px) {
      width: 300px;
    }
  `;

  const ButtonApprove = styled.button`
    background: #3baf96;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    min-width: 8rem;
    font-size: 1.2em;
    font-weight: bold;
    outline: none;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin: 10px 0;
    transition: all 0.1s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
    color: #ffffff;

    &:hover {
      transform: scale(1.05) !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    &:active {
      transform: scale(0.95) !important;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
  `;

  const ButtonReject = styled.button`
    background: #a2b5bb;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    min-width: 8rem;
    font-size: 1.2em;
    font-weight: bold;
    outline: none;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin: 10px 0;
    transition: all 0.1s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
    color: #ffffff;

    &:hover,
    &:active,
    &:focus {
      background-color: #818e92;
    }

    &:hover {
      transform: scale(1.05) !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    &:active {
      transform: scale(0.95) !important;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
  `;

  return {
    InfluencerPostDetailContainer,
    CardContainer,
    Profile,
    ChannelLogo,
    NameUser,
    CreatedPostDateTime,
    MessagePost,
    ButtonApprove,
    ButtonReject,
  };
};

export default InfluencerPostDetailStyle;
