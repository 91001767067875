/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ApplicationsTypes {
  GET_APPLICATIONS_FILTER = '@@application/GET_APPLICATIONS_FILTER',
  GET_APPLICATIONS_FILTER_SUCCESS = '@@application/GET_APPLICATIONS_FILTER_SUCCESS',

  GET_APPLICATION_BY_KEY = '@@application/GET_APPLICATION_BY_KEY',
  GET_APPLICATION_BY_KEY_SUCCESS = '@@application/GET_APPLICATION_BY_KEY_SUCCESS',
  GET_APPLICATION_BY_KEY_FAIL = '@@application/ GET_APPLICATION_BY_KEY_FAIL',

  GET_APPLICATION_CREATIVE_FILTER = '@@application/GET_APPLICATION_CREATIVE_FILTER',
  GET_APPLICATION_CREATIVE_FILTER_SUCCESS = '@@application/GET_APPLICATION_CREATIVE_FILTER_SUCCESS',

  GET_APPLICATION_GRAPH_DATUM = '@@application/GET_APPLICATION_GRAPH_DATUM',
  GET_APPLICATION_GRAPH_DATUM_SUCCESS = '@@application/GET_APPLICATION_GRAPH_DATUM_SUCCESS',
  GET_APPLICATION_GRAPH_DATUM_FAIL = '@@application/GET_APPLICATION_GRAPH_DATUM_FAIL',
  CLEAR_APP_GRAPH_DATUM = '@@application/CLEAR_APP_GRAPH_DATUM',

  GET_FILTER_APPLICATION_DATUM_TABLE = '@@website/GET_FILTER_APPLICATION_DATUM_TABLE',
  GET_FILTER_APPLICATION_DATUM_TABLE_SUCCESS = '@@website/GET_FILTER_APPLICATION_DATUM_TABLE_SUCCESS',
  GET_FILTER_APPLICATION_DATUM_TABLE_FAIL = '@@website/GET_FILTER_APPLICATION_DATUM_TABLE_FAIL',

  GET_PUBLISHER_EARNING_TRANSACTION = '@@website/GET_PUBLISHER_EARNING_TRANSACTION',
  GET_PUBLISHER_EARNING_TRANSACTION_SUCCESS = '@@website/GET_PUBLISHER_EARNING_TRANSACTION_SUCCESS',
  GET_PUBLISHER_EARNING_TRANSACTION_FAIL = '@@website/GET_PUBLISHER_EARNING_TRANSACTION_FAIL',
}

export interface ApplicationState {
  applications: Object;
  application: Object;
  applicationCreative: Object;
  applicationEarningTransaction: Array<any>;
  applicationGraphDatum: Array<any>;
  applicationFilterDatum: Array<any>;
  error: Object;
  loading: Boolean;
}
