import LoginTypes from './actionTypes';

const initialState = {
  error: false,
  errorCode: '',
  message: '',
  loading: false,
  user: null,
  isLoggedIn: false,
  socialMediaInfoImage: [],
};

const login = (state = initialState, action: any) => {
  switch (action.type) {
    case LoginTypes.LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case LoginTypes.GET_SOCIAL_MEDIA_INFO_IMAGE_SUCCESS:
      return {
        ...state,
        socialMediaInfoImage: action.payload,
      };
    case LoginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: false,
        message: '',
        isLoggedIn: true,
        errorCode: '',
      };
    case LoginTypes.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        user: null,
        error: true,
        message: action.payload.errorMessage,
        errorCode: action.payload.errorCode,
      };
    case LoginTypes.LOGOUT_USER:
      return { ...state };
    case LoginTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        errorCode: '',
        error: false,
        message: '',
        socialMediaInfoImage: [],
      };
    case LoginTypes.RESEND_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        error: false,
        message: '',
        errorCode: '',
      };
    case LoginTypes.RESEND_FAILED:
      return {
        ...state,
        loading: false,
        user: null,
        error: true,
        message: action.payload.errorMessage,
        errorCode: action.payload.errorCode,
      };
    case LoginTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: false,
        errorCode: '',
        message: '',
      };
    case LoginTypes.GET_LOGGEDIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case LoginTypes.UPLOAD_PROFILE_SUCCESS:
      if (state?.user) {
        const stateUser = state?.user ? state?.user : {};
        return {
          ...state,
          user: { ...stateUser, image_storage: { key: action.payload } },
        };
      }
      return state;

    default:
      return state;
  }
};

export default login;
