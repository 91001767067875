import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import config from 'src/config';
import ImageAutoBalance from 'src/components/Common/ImageAutoBalance';
import choice from 'src/assets/images/choice.png';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import SearchBar from 'src/components/Table/SearchBar';
import CreativeSizeOptions from 'src/components/Table/CreativeSizeOptions';
import { removeCreativeGraphql } from '../../graphql/advertiser/creative';
import CustomTable from '../../components/Common/CustomTable';
import {
  getCreativesRequest as onGetCreativesRequest,
  getCreativeSizes as onGetCreativeSizes,
  setCreativeRequestLoading,
  unsetCreativeRequestLoading,
} from '../../store/actions';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../../components/Common/DateTimeComponent';

const GalleryPage = ({ t }: any) => {
  //from react
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [nameSearch, setNameSearch] = useState('');
  const [sizeOption, setSizeOption] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  //redux
  const { creativesByUserId, creativeSizes, creativeRequestLoading } = useSelector(
    (state: any) => {
      const creatives = state.Creatives.transactions;
      if (creatives.items) {
        for (let i = 0; i < creatives.items?.length; i++) {
          let inUse = 0;

          creatives.items[i].creativeCampaigns.forEach(
            (creativeCampaign: { deleted_at: any }) => {
              if (!creativeCampaign.deleted_at) {
                inUse += 1;
              }
            },
          );

          creatives.items[i].useInCreative = inUse;
        }
      }

      return {
        creativesByUserId: creatives,
        creativeSizes: state.Creatives.allCreativeSizes,
        creativeRequestLoading: state.Global.creativeRequestLoading,
      };
    },
  );

  //general function
  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      page,
      name: nameSearch,
      size: sizeOption,
    };
  };
  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };
    dispatch(onGetCreativesRequest(payload));
    setPage(newPage);
  };

  const handleSizeChange = (size: string) => {
    const payload = {
      ...getDefaultPayload(),
      page: 1,
      size,
    };
    dispatch(onGetCreativesRequest(payload));
    setSizeOption(size);
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...getDefaultPayload(),
      name: searchInput,
    };
    dispatch(onGetCreativesRequest(payload));
    setNameSearch(searchInput);
  };

  const handleClear = () => {
    setNameSearch('');
    const payload = {
      ...getDefaultPayload(),
      name: '',
    };
    dispatch(onGetCreativesRequest(payload));
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...getDefaultPayload(),
      limit: +newPageSize,
      page: 1,
    };
    dispatch(onGetCreativesRequest(payload));
    setPageSize(+newPageSize);
    setPage(1);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetCreativesRequest(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page, pageSize],
  );

  //table
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: `${t('tableCreative:Creative')}`,
        accessor: ({ name }) => name,
        id: 'name',
        rowCss: 'mt-3',
      },

      {
        Header: `${t('tableCreative:Creative Size')} (${t('pixel')})`,
        accessor: ({ size }) => size,
        id: 'size',
        rowCss: 'mt-3',
      },
      {
        Header: t('In use'),
        accessor: ({ useInCreative }) => useInCreative,
        id: 'useInCreative',
        rowCss: 'mt-3',
        disableSort: true,
      },
      {
        Header: t('Date Added'),
        accessor: ({ created_at }) => created_at,
        Cell: ({ cell }: any) => (
          <DateTimeComponent
            dateTime={cell.row.original.created_at}
            dateTimeType={DateTimeTypes.RECENT_TRANSACTION_DATE_TIME}
          />
        ),
        rowCss: 'mt-3',
        id: 'created_at',
      },
      {
        Header: t('table:Preview'),
        accessor: ({ image_storage, size }) => (
          <div className="pic-frame">
            <div className="pic-middle">
              <ImageAutoBalance
                href={`${config.backendUrl}creatives/display/${image_storage.key}`}
                alt="ad image"
                src={`${config.backendUrl}creatives/display/${image_storage.key}`}
                sizeImage={50}
                width={+size.split('x')[0]}
                height={+size.split('x')[1]}
                size="md"
              />
            </div>
          </div>
        ),
        id: 'preview',
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ key }) => {
          return (
            <div className="mt-2">
              {/* removeCreativeCampaignGraphql */}
              <div className="text-center rounded-circle">
                <div className="avatar-md mx-auto">
                  <span
                    className="avatar-bin bg-gainsboro rounded-circle"
                    onKeyDown={() => {}}
                    onClick={async () => {
                      await Swal.fire({
                        width: 300,
                        title: t('swal:Are you sure ?'),
                        text: t("swal:You won't be able to revert this!"),
                        imageUrl: choice,
                        imageHeight: 200,
                        imageAlt: 'A tall image',
                        customClass: {},
                        cancelButtonColor: 'red',
                        showCancelButton: true,
                        confirmButtonColor: '#009a78',
                        confirmButtonText: t('button:Yes'),
                        cancelButtonText: t('button:No'),
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          dispatch(setCreativeRequestLoading());
                          await removeCreativeGraphql(key);
                          dispatch(dispatch(onGetCreativesRequest({})));
                          dispatch(unsetCreativeRequestLoading());
                        }
                      });
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <i className="mdi mdi-delete font-size-26 text-primary" />
                  </span>
                </div>
              </div>
            </div>
          );
        },
        id: 'Action',
        disableSort: true,
      },
    ],
    [t],
  );

  //set data
  useEffect(() => {
    dispatch(onGetCreativesRequest(getDefaultPayload()));
    dispatch(onGetCreativeSizes({}));
  }, []);

  return (
    <div className="page-content me-4 ms-4">
      <Row className="justify-content-between">
        <Col xs={12} sm={4} md={3} lg={3} className="d-flex align-items-center">
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
          />
        </Col>
        <Col md={1} lg={2} />
        <Col xs={12} md={8} lg={7}>
          <Row className="align-items-center p-3">
            <Col sm={12} md={4}>
              <CreativeSizeOptions
                handleSizeChange={handleSizeChange}
                creativeSizes={creativeSizes}
              />
            </Col>
            <Col sm={12} md={8}>
              <SearchBar
                placeholder={t('table:Search Name')}
                handleSearch={handleSearch}
                handleClear={handleClear}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomTable
        columns={columns}
        data={creativesByUserId.items || []}
        page={page}
        pageSize={pageSize}
        total={creativesByUserId.totalCount || 1}
        handlePageChange={handlePageChange}
        headerCSS="fw-normal bg-header-table"
        hiddenOnLG={[]}
        hiddenOnXS={['Date']}
        hiddenOnSM={['Date']}
        hiddenOnMD={['Date']}
        onSort={handleSort}
        isLoading={creativeRequestLoading}
      />
    </div>
  );
};

export default withTranslation()(GalleryPage);
