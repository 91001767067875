import i18n from 'src/i18n';
import NavData from '../type/navData.type';

const influencerNavData: NavData = {
  role: 'Influencer',
  title: 'Create influencer social profile and get job form marketer',
  items: [
    {
      label: i18n.t('influencer:My Jobs'),
      description: i18n.t('influencer:Check job tracking from marketer.'),
      path: '/influencer/job',
      icon: <i className="fas fa-tasks" />,
    },
    {
      label: i18n.t('influencer:Suggested jobs'),
      description: i18n.t('influencer:You can select your job here.'),
      path: '/influencer/publish-job',
      icon: <i className="fas fa-tasks" />,
    },
  ],
};

export default influencerNavData;
