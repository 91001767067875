import { gql } from '@apollo/client';
import notify from 'src/utils/notify';
import config from 'src/config';
import i18n from 'src/i18n';
import { client } from '../client';
import { logout } from '../common/auth';

export const createRequestNewOwnCurenncyGQL = async (payload: any) => {
  const mutation = gql`
    mutation createRequestNewOwnCurrency($payload: RequestNewOwnCurrencyInput!) {
      createRequestNewOwnCurrency(requestNewOwnCurrencyInput: $payload) {
        key
        name
        symbol
        contract_address
        network
        decimals
        status
        ref_link
      }
    }
  `;
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await client.mutate({
      mutation,
      variables: { payload },
      fetchPolicy: 'no-cache',
    });
    return res.data.createRequestNewOwnCurrency;
  } catch (error) {
    notify('Error duplicate entry', 'error');
    // throw error;
    return null;
    // return null;
  }
};

export const editOwnCurrencyGQL = async (payload: any) => {
  const mutation = gql`
    mutation editOwnCurrency(
      $updateRequestNewOwnCurrencyInput: UpdateRequestNewOwnCurrencyInput!
    ) {
      editOwnCurrency(
        updateRequestNewOwnCurrencyInput: $updateRequestNewOwnCurrencyInput
      ) {
        key
        name
        symbol
        logo
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { updateRequestNewOwnCurrencyInput: payload },
      fetchPolicy: 'no-cache',
    });
    return res.data.editOwnCurrency;
  } catch (error) {
    return null;
  }
};

export const filterRequestOwnCurrencyGQL = async (payload: any) => {
  const query = gql`
    query filterRequestOwnCurrency(
      $filterOwnCurrencyInput: FilterOwnCurrencyInput!
    ) {
      filterRequestOwnCurrency(filterOwnCurrencyInput: $filterOwnCurrencyInput) {
        items {
          id
          key
          name
          symbol
          contract_address
          network
          decimals
          ref_link
          status
          verify_status
          logo
          reject_message
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { filterOwnCurrencyInput: payload },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterRequestOwnCurrency;
  } catch (error) {
    return null;
  }
};

export const approveRequestOwnCurrencyGQL = async (key: any, status: any) => {
  const mutation = gql`
    mutation approveRequestOwnCurrency($key: String!, $status: String!) {
      approveRequestOwnCurrency(key: $key, status: $status)
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { key, status },
      fetchPolicy: 'no-cache',
    });
    return res.data.approveRequestOwnCurrency;
  } catch (error) {
    return null;
  }
};

export const rejectRequestOwnCurrencyGQL = async (key: any, reason: any) => {
  const mutation = gql`
    mutation rejectRequestOwnCurrency($key: String!, $reason: String!) {
      rejectRequestOwnCurrency(key: $key, reason: $reason)
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { key, reason },
      fetchPolicy: 'no-cache',
    });
    return res.data.rejectRequestOwnCurrency;
  } catch (error) {
    return null;
  }
};

export const uploadCurrencyImage = async (fileData: any) => {
  const file = fileData[0] || fileData;
  console.log('uploadCurrencyImage: ', file);
  const formData = new FormData();
  formData.append(
    'operations',
    `{"query":"mutation uploadCurrencyImage($file:Upload!) {\\n uploadCurrencyImage(file:$file)\\n}", "variables": { "file": null }}\n`,
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);

  const access_token = localStorage.getItem('access-token');
  if (access_token) {
    const headers = new Headers();
    headers.append('authorization', `Bearer ${access_token}`);

    try {
      const res = await fetch(<any>config.graphql.URI, {
        method: 'POST',
        headers,
        body: formData,
        redirect: 'follow',
      });
      const resText = await res.text();
      const resData = JSON.parse(resText);

      return resData.data.uploadCurrencyImage;
    } catch (error: any) {
      if (error?.message !== 'Unauthorized')
        notify(i18n.t('error:Upload image error .'), 'error');
      return null;
    }
  } else {
    await logout();
    localStorage.removeItem('access-token');
    localStorage.removeItem('last-silent-refresh');
    window.location.href = '/';
    return null;
  }
};

export const filterMarketerRequestOwnCurrencyGQL = async (payload: any) => {
  const query = gql`
    query filterMarketerRequestOwnCurrency($input: FilterOwnCurrencyInput!) {
      filterMarketerRequestOwnCurrency(input: $input) {
        items {
          id
          key
          name
          symbol
          contract_address
          network
          decimals
          ref_link
          status
          verify_status
          logo
          reject_message
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input: payload },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterMarketerRequestOwnCurrency;
  } catch (error) {
    return null;
  }
};
