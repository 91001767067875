/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum InfluencerCampaignStatus {
  NEW_JOB = 'NEW_JOB',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  CANCEL = 'CANCEL',
  DRAFT = 'DRAFT',
  PAUSE = 'PAUSE',
  SUSPENDED = 'SUSPENDED',
}

export default InfluencerCampaignStatus;
