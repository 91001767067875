import { ActivityLogTypes } from './actionTypes';
import { ActivityLogTransactionsQuery } from '../../common/types';

export const getRecentActivityLogs = () => ({
  type: ActivityLogTypes.GET_RECENT_ACTIVITY_LOGS,
});
export const getNewActivityLogs = (payload: any) => ({
  type: ActivityLogTypes.NEW_ACTIVITY_LOG,
  payload,
});
export const getRecentActivityLogsSuccess = (logs: any) => ({
  type: ActivityLogTypes.GET_RECENT_ACTIVITY_LOGS_SUCCESS,
  payload: logs,
});

export const getActivityLogTransactions = (
  payload: ActivityLogTransactionsQuery,
) => ({
  type: ActivityLogTypes.GET_ACTIVITY_LOG_TRANSACTIONS,
  payload: { ...payload },
});

export const getActivityLogTransactionsSuccess = (logs: any) => ({
  type: ActivityLogTypes.GET_ACTIVITY_LOG_TRANSACTIONS_SUCCESS,
  payload: logs,
});

export const markAsAllRead = () => ({
  type: ActivityLogTypes.MARK_AS_ALL_READ,
});

export const markAsAllReadSuccess = (result: any) => ({
  type: ActivityLogTypes.MARK_AS_ALL_SUCCESS,
  payload: result,
});

export const getAllAdminApproveLog = () => ({
  type: ActivityLogTypes.GET_ALL_ADMIN_APPROVE_LOG,
});

export const getAllAdminApproveLogSuccess = (payload: any) => ({
  type: ActivityLogTypes.GET_ALL_ADMIN_APPROVE_LOG_SUCCESS,
  payload,
});

export const markAsAllReadFail = () => ({
  type: ActivityLogTypes.MARK_AS_ALL_FAIL,
});

export const getActivityLogsFail = (error: any) => ({
  type: ActivityLogTypes.GET_ACTIVITY_LOGS_FAIL,
  payload: error,
});
export const clearActivityLogs = () => ({
  type: ActivityLogTypes.CLEAR_ACTIVITY_LOGS,
});

export const addAllAdminApproveLog = (payload: any) => ({
  type: ActivityLogTypes.ADD_ADMIN_APPROVE_LOG,
  payload,
});
