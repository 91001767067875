import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import classnames from 'classnames';
import addSocial from 'src/assets/images/other/add-social.svg';
import noCampaignImage from 'src/assets/images/no-campaign-image.png';
import JobStatusButtonGroup from 'src/components/Advertiser/jobStatusButtonGroup';
import SearchBar from 'src/components/Table/SearchBar';
import config from 'src/config';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import Pagination from '@mui/material/Pagination';
import { clearMarketerJob, filterJob } from 'src/store/marketerJob/actions';
import moment from 'moment';
import {
  InfluencerJobBackGroundColor,
  InfluencerContentType,
  InfluencerSocialIcon,
} from 'src/components/Common/status/InfluencerJobStatus';
import './JobIndex.style.scss';
import { getDefaultWebsite } from 'src/store/actions';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import paramsFromURL from 'src/constants/paramsFromURL';
import { floorToFixed } from 'src/common/data/mathToFixed';

interface LocationState {
  camPaignName?: string;
}

const JobIndexNew = ({ jobType }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPublish = jobType === 'PUBLISH';
  const [tab, setTab] = useState(1);
  const location = useLocation<LocationState>();
  const [activeTab, toggleTab] = useState(1);
  const camPaignName: any = location?.state?.camPaignName || '';

  const [key] = paramsFromURL(['campaign']);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'INPROGRESS',
    marketer_campaign_key: key,
  });

  const { data, defaultTax, influencerData } = useSelector((state: any) => ({
    data: state.MarketerJob.filter,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    influencerData: state.Users.userInfo,
  }));
  const tax = defaultTax / 100 + 1;

  if (data?.items) {
    if (Object.keys(data.items)?.length) {
      for (let j = 0; j < data.items?.length; j++) {
        let num_influencer = 0;
        let num_follower = 0;
        let total_price = 0;
        const job = data.items[j];
        num_influencer += job.social_media_has_jobs?.length || 0;
        for (let k = 0; k < job.social_media_has_jobs?.length; k++) {
          const socialJob = job.social_media_has_jobs[k];
          num_follower += socialJob.social_media_info.follower || 0;
          total_price += socialJob.price || 0;
        }
        job.num_influencer = num_influencer;
        job.num_follower = num_follower;
        job.total_price = total_price * tax;
      }
    }
  }
  // const primeZone = moment.tz('2013-11-18 00:00:00', 'Iceland');
  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');

  const handleStatusChange = (job_type: string) => {
    const payload = {
      ...param,
      page: 1,
      job_type,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };
  const capitalize = (s: any) => {
    // eslint-disable-next-line func-names
    return s.toLowerCase().replace(/\b./g, function (a: any) {
      return a.toUpperCase();
    });
  };
  const handleSearch = (searchInput: string) => {
    const payload = {
      ...param,
      search: searchInput,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      search: '',
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  useEffect(() => {
    dispatch(getDefaultWebsite());
    dispatch(filterJob(param));
    localStorage.removeItem('descriptionJobNew');
  }, []);

  const inprogress = () => {
    const payload = {
      ...param,
      status: 'INPROGRESS',
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };
  const draft = () => {
    const payload = {
      ...param,
      status: 'DRAFT',
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };
  const finished = () => {
    const payload = {
      ...param,
      status: 'FINISHED',
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };
  const cancel = () => {
    const payload = {
      ...param,
      status: 'CANCEL',
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const renderTabs = () => {
    return (
      <div
        className="nav-tabs-custom card-header-tabs border-top mt-4 mx-0 nav-scrollbar-none mb-2"
        style={{
          fontSize: '1rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <NavItem>
          <NavLink
            to="#"
            style={{ width: '150px' }}
            className={classnames({
              active: activeTab === 1,
            })}
            onClick={() => {
              toggleTab(1);
              inprogress();
            }}
          >
            <h5>{t('StatusCampaign.Inprogess')}</h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            style={{ width: '100px' }}
            className={classnames({
              active: activeTab === 2,
            })}
            onClick={() => {
              toggleTab(2);
              draft();
            }}
          >
            <h5>{t('StatusCampaign.Draft')}</h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            style={{ width: '100px' }}
            className={classnames({
              active: activeTab === 3,
            })}
            onClick={() => {
              toggleTab(3);
              setTab(3);
              finished();
            }}
          >
            <h5>{t('StatusCampaign.Finished')}</h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            style={{ width: '100px' }}
            className={classnames({
              active: activeTab === 4,
            })}
            onClick={() => {
              toggleTab(4);
              setTab(4);
              cancel();
            }}
          >
            <h5>{t('StatusCampaign.Cancel')}</h5>
          </NavLink>
        </NavItem>
      </div>
    );
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>
      <div className="job-container">
        <Breadcrumb
          pageTitle={t('Menus.Jobs')}
          menus={[
            { menu: menu.HOME, active: true },
            {
              menu: isPublish ? menu.Jobs : menu.MARKETER_INVITEONLY_CAMPAIGN,
            },
            {
              menu: isPublish ? menu.MARKETER_CAMPAIGN_JOB_ANNOUNCEMENT : menu.Jobs,
              isSamePage: true,
            },
          ]}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="campaign-name">{camPaignName}</div>
          <div style={{ marginLeft: '10px', width: '100%' }}>
            <button
              className="editbutton"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                history.push(`/EditMarketerCampaign?campaign=${key}`);
              }}
            >
              <i
                className="bx bx-edit"
                style={{
                  cursor: 'pointer',
                  color: `white`,
                  fontSize: '16px',
                }}
              />
              <div style={{ padding: '0px 10px' }}>{t('Edit')}</div>
            </button>
          </div>
        </div>
        <div className="job-header">
          <div className="dropdown-status-job-container">
            <div className="edit-campaign-container">
              {/* <span
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                {camPaignName}
              </span> */}
              {/* <button
                className="edit-campaign-button-container"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  if (isPublish) {
                    history.push(`/EditMarketerPublicCampaign?edit=${key}`);
                  } else {
                    history.push(`/EditMarketerCampaign?edit=${key}`);
                  }
                }}
              >
                <i
                  className="bx bx-edit"
                  style={{
                    cursor: 'pointer',
                    color: `white`,
                    fontSize: '16px',
                  }}
                />
                <div style={{ padding: '0px 10px' }}>Edit</div>
              </button> */}
            </div>
          </div>

          <div className="search-add-job-container">
            <JobStatusButtonGroup
              param={param}
              handleStatusChange={handleStatusChange}
              statusCount={data?.statusCount}
            />
            <SearchBar
              placeholder={t('table:Search Name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
              disableClear
            />
          </div>
        </div>
        {renderTabs()}
        <div
          className="add-button-container "
          onClick={() => {
            dispatch(clearMarketerJob());
            history.push(`/Marketer/AddJobDetail?campaign=${key}`);
            localStorage.removeItem('descriptionJobNew');
          }}
        >
          <div className="add-button">
            <img width={50} height={50} src={addSocial} alt="add-social" />
            <div className="add-button-title"> {t('Menus.Create Job')}</div>
          </div>
        </div>
        {!data?.totalCount ? (
          <>
            <div className="no-campaign-container">
              <img
                src={noCampaignImage}
                className="no-campaign-image-container"
                alt="noCampaignImage"
              />
              <div className="text-container">
                <div className="text-top" style={{ fontSize: '20px' }}>
                  {t('table:There’s no job in here')}
                </div>
                <div
                  className="text-bottom"
                  style={{ fontSize: '20px' }}
                  onClick={() => {
                    if (!key) return;
                    dispatch(clearMarketerJob());
                    history.push(`/Marketer/AddJobDetail?campaign=${key}`);
                  }}
                >
                  {t('table:Create New Job')}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {data?.items?.map((items: any) => {
              return (
                <div
                  style={{ margin: 'auto' }}
                  key={items.key}
                  className="card-job-index"
                >
                  <>
                    <div className="cards-container">
                      <div className="campaign-header ">{t('Menus.Job')}</div>
                      <Card
                        className="card"
                        style={{
                          background: `${InfluencerJobBackGroundColor(
                            items.status,
                          )}`,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (items.job_type === 'PUBLISH')
                            history.push(
                              `/Marketer/JobPublicDetail?job=${items.key}`,
                            );
                          else if (items.job_type === 'INVITE_ONLY') {
                            history.push(`/Marketer/JobDetail?job=${items.key}`);
                          } else {
                            history.push(`/Marketer/JobDetail?job=${items.key}`);
                          }
                        }}
                      >
                        <CardTitle
                          style={{
                            background: 'white',
                            borderTopLeftRadius: '18px',
                            borderTopRightRadius: '18px',
                            padding: '0',
                          }}
                        >
                          <div className="card-job-marketer">
                            <div>
                              <div className="job-name-title">{items.name}</div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                  style={{
                                    marginRight: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <InfluencerSocialIcon
                                    social_media_type={items.social_media_type}
                                  />
                                  <InfluencerContentType
                                    content_type={items.content_type}
                                  />
                                </div>
                                <div>
                                  <div className="social-text">
                                    {capitalize(items.social_media_type)}
                                  </div>
                                  <div className="social-text">
                                    {capitalize(items.content_type)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ display: 'flex', flexDirection: 'column' }}
                            >
                              {items?.job_type === 'PUBLISH'
                                ? 'Suggested Job'
                                : 'Invite Only'}
                              {/* <InfluencerJobStatus
                                status={items.job_type}
                                style={{ maxWidth: '200px' }}
                              /> */}
                              <div className="social-text">{currentTimeZone}</div>
                            </div>
                          </div>
                          {jobType === 'PUBLISH' && (
                            <div style={{ padding: '0' }}>
                              <img
                                src={`${config.backendUrl}api/jobThumbnail/${items?.thumbnail_cover}`}
                                alt={items?.thumbnail_cover}
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                }}
                              />
                            </div>
                          )}
                        </CardTitle>
                        <CardBody>
                          <div className="group-footer">
                            <div className="column-group">
                              <div className="price-num">
                                {floorToFixed(items.total_price, 2)}{' '}
                                {items?.currency?.main_symbol ?? 'USDT'}
                              </div>
                              <div className="price">
                                {t('marketer:create_campaign.Price')}
                              </div>
                            </div>
                            <div className="column-group">
                              <div className="influencer-num">
                                {items.num_influencer}
                              </div>
                              <div className="influencer">
                                {t('marketer:create_campaign.Influencer')}
                              </div>
                            </div>
                            <div className="column-group">
                              <div className="follower-num">
                                {items.num_follower}
                              </div>
                              <div className="follower">
                                {t('marketer:create_campaign.Followers')}
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </>
                </div>
              );
            })}
          </>
        )}

        <Pagination
          count={Math.ceil(data?.totalCount / param.limit)}
          page={param.page}
          color="standard"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
          boundaryCount={2}
        />
      </div>
    </div>
  );
};

export default JobIndexNew;
