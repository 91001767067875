import './CreateMarketerJobCampaignNew2.style.scss';

const UserCards = (props: any) => {
  return (
    <>
      <div className="collapse-hashtag">{props.title}</div>
    </>
  );
};

export default UserCards;
