/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum CountriesTypes {
  /** Get countries */
  GET_COUNTRIES = '@@country/GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS = '@@country/GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAIL = '@@country/GET_COUNTRIES_FAIL',
}

export interface CountryState {
  countries: Array<any>;
  error: Object;
}
