import { withTranslation } from 'react-i18next';

const SelectButton = ({ value, setValue, selfValue, display, t }: any) => (
  <button
    type="button"
    className={`btn outline-sm me-2 ${value === selfValue ? 'active' : ''}`}
    onClick={() => {
      setValue(selfValue);
    }}
  >
    {t(`campaign:${display || selfValue}`)}
  </button>
);

export default withTranslation()(SelectButton);
