import { CountriesTypes } from './actionTypes';

export const getCountries = () => ({
  type: CountriesTypes.GET_COUNTRIES,
});

export const getCountriesSuccess = (countries: any) => ({
  type: CountriesTypes.GET_COUNTRIES_SUCCESS,
  payload: countries,
});

export const getCountriesFail = (error: any) => ({
  type: CountriesTypes.GET_COUNTRIES_FAIL,
  payload: error,
});
