import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { CurrencyTypes } from './actionTypes';

import {
  getCurrenciesSuccess,
  getCurrenciesFail,
  getCurrencies as onGetCurrencies,
  getAvailableCurrenciesSuccess,
  getAvailableNetworkSuccess,
  getCurrenciesByMarketerCurrenciesSuccess,
  getMarketerCurrenciesSuccess,
  getFiltreCurrencySuccess,
} from './actions';
import { setCurrenciesLoading, unsetCurrenciesLoading } from '../global/actions';

import {
  currencies,
  availableCurrencies,
  toggleCurrencyAvailable as toggleCurrencyAvailableGql,
  getAvailableNetworkGQL,
  getCurrenciesByMarketerGQL,
  getMarketerCurrenciesGQL,
  filterCurrenciesGQL,
} from '../../graphql/common';
import notify from '../../utils/notify';
import i18n from '../../i18n';

function* fetchCurrencies() {
  try {
    yield put(setCurrenciesLoading());
    const response: Promise<any> = yield call(currencies);

    yield put(getCurrenciesSuccess(response));
  } catch (error) {
    yield put(getCurrenciesFail(error));
  } finally {
    yield put(unsetCurrenciesLoading());
  }
}

function* fetchAvailableCurrencies() {
  try {
    const response: Promise<any> = yield call(availableCurrencies);

    yield put(getAvailableCurrenciesSuccess(response));
  } catch (error) {
    yield put(getCurrenciesFail(error));
  }
}

function* toggleCurrencyAvailable({ payload: { symbol } }: any) {
  try {
    yield call(toggleCurrencyAvailableGql, symbol);
    yield put(onGetCurrencies());

    notify(i18n.t('success:Currency Available Changed Success'), 'success');
  } catch (error) {
    // Something here
  }
}

function* fetchAvailableNetworks() {
  try {
    const response: Promise<any> = yield call(getAvailableNetworkGQL);

    yield put(getAvailableNetworkSuccess(response));
  } catch (error) {
    //
  }
}

function* fetchCurrencyByMarketer() {
  try {
    const response: Promise<any> = yield call(getCurrenciesByMarketerGQL);

    yield put(getCurrenciesByMarketerCurrenciesSuccess(response));
  } catch (error) {
    //
  }
}

function* fetchAllMarketerCurrencies() {
  try {
    const response: Promise<any> = yield call(getMarketerCurrenciesGQL);
    yield put(getMarketerCurrenciesSuccess(response));
  } catch (error) {
    //
  }
}

function* fetchFilterCurrencies({ payload }: any) {
  try {
    const response: Promise<any> = yield call(filterCurrenciesGQL, payload);

    yield put(getFiltreCurrencySuccess(response));
  } catch (error) {
    //
  }
}

function* currencySaga() {
  yield takeEvery(CurrencyTypes.GET_CURRENCIES, fetchCurrencies);
  yield takeEvery(CurrencyTypes.TOGGLE_CURRENCY_AVAILABLE, toggleCurrencyAvailable);
  yield takeEvery(CurrencyTypes.GET_AVAILABLE_CURRENCIES, fetchAvailableCurrencies);
  yield takeEvery(CurrencyTypes.GET_AVAILABLE_NETWORK, fetchAvailableNetworks);
  yield takeEvery(CurrencyTypes.GET_CURRENCIES_BY_MARKETER, fetchCurrencyByMarketer);
  yield takeEvery(CurrencyTypes.GET_MARKETER_CURRENCIES, fetchAllMarketerCurrencies);
  yield takeEvery(CurrencyTypes.GET_FILTER_CURRENCY, fetchFilterCurrencies);
}

export default currencySaga;
