import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

const getDefaultCampaignGraphql = async (payload: {
  device_platform: string;
  banner_format: string;
  ad_format: string;
}) => {
  const { device_platform, banner_format, ad_format } = payload;

  const query = gql`
    query GetDefaultCampaign(
      $device_platform: device_platform!
      $banner_format: banner_format
      $ad_format: ad_format
    ) {
      getDefaultCampaign(
        device_platform: $device_platform
        banner_format: $banner_format
        ad_format: $ad_format
      ) {
        minimumCPM
        recommendCPM
        minimumBudget
        minimumMaxBudget
        minimumCPMKey
        recommendCPMKey
        minimumBudgetKey
        minimumMaxBudgetKey
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { device_platform, banner_format, ad_format },
    fetchPolicy: 'no-cache',
  });

  return result.data.getDefaultCampaign;
};

const updateDefaultCampaignGraphql = async (updateDefaultCampaignInput: any) => {
  const { key, value } = updateDefaultCampaignInput;
  const mutation = gql`
    mutation UpdateDefaultValue($key: String!, $value: String) {
      updateDefaultValue(updateDefaultValueInput: { key: $key, value: $value }) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
        value,
      },
    });

    notify(i18n.t('success:Your creative size has been updated.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative size has not been updated.'), 'error');
  }
};

export { getDefaultCampaignGraphql, updateDefaultCampaignGraphql };
