import { gql } from '@apollo/client';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { client } from '../client';
import config from '../../config';
import { logout } from '../common/auth';

const campaignGraphql = async (key: number) => {
  const query = gql`
    query GetCampaign($key: String!) {
      getCampaign(key: $key) {
        campaign_key
        status
        name
        device_platform
        CPM
        budget
        max_budget
        isAllCountry
        ad_format
        banner_format
        isEnoughBalance
        isHaveCreative
        currentDailyBudget
        auto_run
        click
        totalImpression
        totalBudget
        totalClick
        countries {
          key
          name
        }
        creative_types {
          key
          name
        }
        creativeCampaigns {
          key
          url_ad
          isActive
          creative {
            name
            size
            key
            image_storage {
              url
              key
            }
          }
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { key },
    fetchPolicy: 'no-cache',
  });

  return result.data.getCampaign;
};

const campaignTodayTotalGraphql = async () => {
  const query = gql`
    query {
      campaignTodayTotal {
        spend
        impression
        click
        CTR
      }
    }
  `;

  const result: any = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.campaignTodayTotal;
};

const campaignStatusSummaryGraphql = async (bannerType: string) => {
  const query = gql`
    query campaignStatusSummary($bannerType: String!) {
      campaignStatusSummary(bannerType: $bannerType) {
        active
        paused
        outOfBudget
        finished
        draft
        archived
        all
      }
    }
  `;

  const result: any = await client.query({
    variables: { bannerType },
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.campaignStatusSummary;
};

//example
const getCampaignReportGraphql = async (payload: {
  search?: string;
  searchCreative?: string;
  limit?: number;
  page?: number;
  start_date?: string;
  end_date?: string;
  campaign_key?: string;
}) => {
  const query = gql`
    query CampaignDatum($payload: FilterTableInput!) {
      campaignDatum(filterTableInput: $payload) {
        totalCount
        items {
          currentDailyBudget
          impression
          click
          CTR
          created_at
          updated_at
          dailyImpression
          note
          campaign {
            name
            click
            totalImpression
            totalBudget
            totalClick
          }
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { payload },
    fetchPolicy: 'no-cache',
  });

  return result.data.campaignDatum;
};

const campaignsGraphql = async () => {
  const query = gql`
    query Campaigns {
      campaigns {
        key
        name
        url
        CPM
        budget
        status
        ad_format
        device_platform
        banner_format
        created_at
        updated_at
        creativeCampaigns {
          url
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
  });

  return result.data.campaigns;
};

const sumDailyCurrentBudgetGraphql = async () => {
  const query = gql`
    query {
      sumDailyCurrentBudget
    }
  `;

  const result: any = await client.query({
    query,
  });

  return result.data.sumDailyCurrentBudget;
};

const updateCampaignGraphQL = async (updateCampaignInput: any) => {
  const { status, device_platform } = updateCampaignInput;

  const updateData = updateCampaignInput;

  if (status) {
    updateData.status = status.toUpperCase();
    if (status === 'OUTOFBUDGET') {
      updateData.status = 'OUT_OF_BUDGET';
    }
  }
  if (device_platform) {
    updateData.device_platform = device_platform.toUpperCase();
  }

  const mutation = gql`
    mutation UpdateCampaign($updateData: UpdateCampaignInput!) {
      updateCampaign(updateCampaignInput: $updateData) {
        campaign_key
        name
        status
        device_platform
        CPM
        budget
        max_budget
        isHaveCreative
        isEnoughBalance
        auto_run
      }
    }
  `;

  try {
    const result = await client.mutate({
      mutation,
      variables: {
        updateData,
      },
    });
    notify(i18n.t('success:Your campaign has been updated.'), 'success');
    return result.data.updateCampaign;
  } catch (error) {
    notify(i18n.t('error:The campaign has not been updated.'), 'error');
    return '';
  }
};

const createCampaignGraphQL = async (createCampaignInput: any) => {
  const mutation = gql`
    mutation CreateCampaign($createCampaignInput: CreateCampaignInput!) {
      createCampaign(createCampaignInput: $createCampaignInput) {
        campaign_key
        name
      }
    }
  `;

  try {
    const result = await client.mutate({
      mutation,
      variables: {
        createCampaignInput,
      },
    });

    notify(i18n.t('success:Your campaign has been created.'), 'success');
    return result.data.createCampaign;
  } catch (error) {
    notify(i18n.t('error:Your campaign has not been created.'), 'error');
    return '';
  }
};

const uploadImage = async (fileData: any) => {
  const file = fileData[0] || fileData;

  const formData = new FormData();
  formData.append(
    'operations',
    '{"query":"mutation UploadFile($file:Upload!) {\\n uploadImage(file:$file)\\n}", "variables": { "file": null }}\n',
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);

  const access_token = localStorage.getItem('access-token');
  if (access_token) {
    const headers = new Headers();
    headers.append('authorization', `Bearer ${access_token}`);

    const response = await fetch(<any>config.graphql.URI, {
      method: 'POST',
      headers,
      body: formData,
      redirect: 'follow',
    });

    const responseText: any = await response.text();

    const responseJson = JSON.parse(responseText);

    if (responseJson.data) return responseJson;
    notify('Creative upload failed.Please try again.', 'error');
    return null;
  }
  notify('authorization failed.Please try again.', 'error');
  await logout();
  localStorage.removeItem('access-token');
  localStorage.removeItem('last-silent-refresh');
  window.location.href = '/';
  return null;
};

const getCampaignFilter = async (filterCampaignInput: any) => {
  const filterData = filterCampaignInput;
  if (filterData.status === 'ALL') {
    filterData.status = null;
  }
  const GET_CAMPAIGN_FILTER = gql`
    query CampaignFilterTable($filterCampaignInput: FilterCampaignInput!) {
      campaignFilterTable(filterCampaignInput: $filterCampaignInput) {
        items {
          name
          ad_format
          max_budget
          budget
          CPM
          eCPM
          status
          campaign_key
          banner_format
          impression
          click
          CTR
          adsCount
          device_platform
          adsActiveCount
          created_at
          currentBudget
          user {
            email
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_CAMPAIGN_FILTER,
    variables: { filterCampaignInput: filterData },
    fetchPolicy: 'no-cache',
  });

  return result.data.campaignFilterTable;
};

const deleteTypeRelationGraphQL = async (
  creative_typeIds: number[],
  campaignId: number,
) => {
  const mutation = gql`
    mutation DeleteTypeRelation($creative_typeIds: [Int!]!, $campaignId: Int!) {
      deleteTypeRelation(
        creative_typeIds: $creative_typeIds
        campaignId: $campaignId
      ) {
        key
        name
      }
    }
  `;

  await client.mutate({
    mutation,
    variables: {
      creative_typeIds,
      campaignId,
    },
  });
};
const deleteCountryRelationGraphQL = async (
  countries_id: number[],
  campaignId: number,
) => {
  const mutation = gql`
    mutation DeleteCountryRelation($countries_id: [Int!]!, $campaignId: Int!) {
      deleteCountryRelation(countries_id: $countries_id, campaignId: $campaignId) {
        key
        name
      }
    }
  `;

  await client.mutate({
    mutation,
    variables: {
      countries_id,
      campaignId,
    },
  });
};

const addTypeRelationGraphQL = async (type_keys: number[], campaign_key: number) => {
  const mutation = gql`
    mutation AddTypeRelation($type_keys: [String!]!, $campaign_key: String!) {
      addTypeRelation(type_keys: $type_keys, campaign_key: $campaign_key)
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        type_keys,
        campaign_key,
      },
    });
  } catch (error) {
    notify(i18n.t('error:Type has not been update.'), 'error');
  }
};
const addCountryRelationGraphQL = async (
  countries_key: string[],
  campaign_key: string,
) => {
  const mutation = gql`
    mutation AddCountryRelation($countries_key: [String!]!, $campaign_key: String!) {
      addCountryRelation(
        countries_key: $countries_key
        campaign_key: $campaign_key
      ) {
        name
      }
    }
  `;

  await client.mutate({
    mutation,
    variables: {
      countries_key,
      campaign_key,
    },
  });
};
const createCampaignExcludeWebsiteGraphql = async (
  createCampaignExcludeWebsiteInput: any,
) => {
  const { campaign_key, urlWeb } = createCampaignExcludeWebsiteInput;
  const mutation = gql`
    mutation CreateCampaignExcludeWebsite($campaign_key: String!, $urlWeb: String!) {
      createCampaignExcludeWebsite(
        createCampaignExcludeWebsiteInput: {
          campaign_key: $campaign_key
          urlWeb: $urlWeb
        }
      ) {
        key
        urlWeb
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        campaign_key,
        urlWeb,
      },
    });
    notify(i18n.t('success:Exclude web has been saved.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Exclude web has not been saved.'), 'error');
  }
};

const campaignExcludeWebsitesGraphql = async (key: number) => {
  const CAMPAIGN_EXCLUDE_WEBSITES = gql`
    query CampaignExcludeWebsites($key: String) {
      campaignExcludeWebsites(key: $key) {
        key
        urlWeb
      }
    }
  `;

  const result: any = await client.query({
    query: CAMPAIGN_EXCLUDE_WEBSITES,
    fetchPolicy: 'no-cache',
    variables: {
      key,
    },
  });

  return result.data.campaignExcludeWebsites;
};

const removeCampaignExcludeWebsite = async (key: number) => {
  const mutation = gql`
    mutation CreateCampaignExcludeWebsite($key: String!) {
      removeCampaignExcludeWebsite(key: $key) {
        key
        urlWeb
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
      },
    });
    notify(i18n.t('success:Exclude web has been deleted.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Exclude web has not been deleted.'), 'error');
  }
};

const removeCampaignGraphql = async (key: any) => {
  const mutation = gql`
    mutation softDeleteMarketerCampaign($key: String!) {
      softDeleteMarketerCampaign(key: $key)
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
      },
    });
    notify(i18n.t('success:Campaign has been deleted.'), 'success');
  } catch (error) {
    // notify(i18n.t('error:Campaign has not been deleted.'), 'error');
  }
};

const removeJobGraphql = async (key: any) => {
  const mutation = gql`
    mutation softDeleteJob($key: String!) {
      softDeleteJob(key: $key)
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
      },
    });
    notify(i18n.t('success:Campaign has been deleted.'), 'success');
  } catch (error) {
    // notify(i18n.t('error:Campaign has not been deleted.'), 'error');
  }
};

export {
  campaignsGraphql,
  createCampaignGraphQL,
  uploadImage,
  getCampaignFilter,
  campaignGraphql,
  updateCampaignGraphQL,
  deleteTypeRelationGraphQL,
  addTypeRelationGraphQL,
  deleteCountryRelationGraphQL,
  addCountryRelationGraphQL,
  createCampaignExcludeWebsiteGraphql,
  campaignExcludeWebsitesGraphql,
  removeCampaignExcludeWebsite,
  getCampaignReportGraphql,
  campaignTodayTotalGraphql,
  campaignStatusSummaryGraphql,
  removeCampaignGraphql,
  sumDailyCurrentBudgetGraphql,
  removeJobGraphql,
};
