import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import menu from '../../constants/menu';
import ReportJobDetailTransactionPage from './ReportJobDetailTransactionPage';

const ReportJobDetail = () => {
  const { t } = useTranslation();
  return (
    <div className="page-content">
      <MetaTags>
        <title>Form Validation | Minia - React Admin & Dashboard Template</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          pageTitle={t('Page.Speed Up Job')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.BAN_USER },
            { menu: menu.REPORT_JOB_DETAIL, active: true },
          ]}
        />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <ReportJobDetailTransactionPage />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportJobDetail;
