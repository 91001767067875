import { call, put, takeEvery } from 'redux-saga/effects';
import { getTaskTypeGroupBySocialTypeGQL } from 'src/graphql/advertiser/task-type';
import { TaskTypesTypes } from './actionTypes';
import { getTaskTypeByGroupSuccess } from './action';

function* getTaskTypeByGroup() {
  try {
    const response: Promise<any> = yield call(getTaskTypeGroupBySocialTypeGQL);

    yield put(getTaskTypeByGroupSuccess(response));
  } catch (error) {
    //
  }
}

function* taskTypeSaga() {
  yield takeEvery(TaskTypesTypes.GET_TASKTYPE_GROUP, getTaskTypeByGroup);
}

export default taskTypeSaga;
