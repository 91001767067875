import { gql } from '@apollo/client';
import { client } from '../client';
import { ActivityLogTransactionsQuery } from '../../common/types';

const markAsAllRead = async () => {
  const MARK_AS_ALL_READ = gql`
    mutation MarkAsAllRead {
      markAsAllRead
    }
  `;

  const response: any = await client.mutate({
    mutation: MARK_AS_ALL_READ,
    fetchPolicy: 'network-only',
  });

  return response.data.markAsAllRead;
};

const resetApproveLogValueByName = async (key: any) => {
  const mutation = gql`
    mutation resetApproveLogValueByName($key: String!) {
      resetApproveLogValueByName(key: $key)
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return res.data.resetApproveLogValueByName;
  } catch (error) {
    return null;
  }
};

const getAllAdminApproveLogGql = async () => {
  const query = gql`
    query {
      getAllAdminApproveLog {
        value
        admin_approve_log {
          name
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });

    return res.data.getAllAdminApproveLog;
  } catch (error) {
    return null;
  }
};
const getRecentActivityLogs = async () => {
  const GET_RECENT_ACTIVITY_LOGS = gql`
    query GetRecentActivityLogs {
      getRecentActivityLogs {
        activity_log_key
        activity_log_type
        context
        is_read
        created_at
      }
    }
  `;

  const response: any = await client.query({
    query: GET_RECENT_ACTIVITY_LOGS,
    fetchPolicy: 'no-cache',
  });

  return response.data.getRecentActivityLogs;
};

const getActivityLogTransactions = async (
  queryInput: ActivityLogTransactionsQuery,
) => {
  const GET_ACTIVITY_LOG_TRANSACTIONS = gql`
    query GetActivityLogTransactions($input: ActivityLogsQueryInput!) {
      getActivityLogTransactions(input: $input) {
        items {
          activity_log_key
          activity_log_type
          context
          created_at
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_ACTIVITY_LOG_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.getActivityLogTransactions;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getRecentActivityLogs,
  getActivityLogTransactions,
  markAsAllRead,
  getAllAdminApproveLogGql,
  resetApproveLogValueByName,
};
