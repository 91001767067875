import { Redirect } from 'react-router-dom';
import ProfitBalanceLogPage from 'src/pages/Admin/ProfitBalanceLogPage';
import FaqsSettingPage from 'src/pages/Admin/FaqsSettingPage';
import ApproveInfluencer from 'src/pages/Admin/influencer/approveInfluencer';
import ApproveJob from 'src/pages/Admin/job/approveJob';
import InfluencerJobTimeLineDetail from 'src/pages/Admin/job/JobTimeLineDetail/influencerJobTimeLineDetail';
import ManageInfluencerPage from 'src/pages/Admin/influencer/manageInfluencerPage';
import approveInfluencerRequestNewPrice from 'src/pages/Admin/influencer/approveInfluencerRequestNewPrice';
import ApproveNewFollowerIndex from 'src/pages/Admin/influencer/newFollower/ApproveNewFollowerIndex';
import InviteOnlyTransactionPage from 'src/pages/Admin/InviteOnlyTransactionPage';
import PublishTransactionPage from 'src/pages/Admin/PublishTransactionPage';

import MadeInHeavenPage from 'src/pages/Admin/MadeInHeavenPage';
import RequestDeleteSocialMedia from 'src/pages/Admin/RequestDeleteSocialMediaPage';
import SuspendSocialJob from 'src/pages/Admin/SuspendSocialJobPage';
import CurrencyApproval from 'src/pages/Admin/CurrencyApproval';
import BanUser from 'src/pages/Admin/BanUser';
import ReportDeclineJob from 'src/pages/Admin/ReportDeclineJob';
import ReportJobDetail from 'src/pages/Admin/ReportJobDetail';
import GleamJobDetail from 'src/pages/mock/gleamJobDetail';
import GleamJobReviewPage from 'src/components/Common/GleamJobReviewPage';
import ReportDeclineJobDetail from 'src/pages/Admin/ReportDeclineJobDetail';
import SettingAdvertiser from '../pages/Admin/manageAdvertiserPage';
import ApprovalAdvertiser from '../pages/Admin/approvalAdvertiser';

import DepositTransactionPage from '../pages/Admin/DepositTransactionPage';
import WithdrawTransactionPage from '../pages/Admin/WithdrawTransactionPage';
import PaymentTransactionPage from '../pages/Admin/PaymentTransactionPage';
import MovementBalancePage from '../pages/Admin/MovementBalancePage';
import PublisherAdminPage from '../pages/Publisher/PublisherAdminPage';
import UserPage from '../pages/Admin/UserPage';
import UserDetailPage from '../pages/Admin/UserDetailPage';
import SettingsPage from '../pages/Admin/SettingsPage';
import SystemBalanceOverviewPage from '../pages/Admin/SystemBalanceOverviewPage';
import SystemBalanceLogPage from '../pages/Admin/SystemBalanceLogPage';
import CryptoBalanceLogPage from '../pages/Admin/CryptoBalanceLogPage';
import RoleBalanceLogPage from '../pages/Admin/RoleBalanceLogPage';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const advertiserRoutes: Array<RouteProps> = [
  {
    path: '/admin/deposit',
    exact: true,
    component: <DepositTransactionPage />,
  },
  {
    path: '/admin/withdraw',
    exact: true,
    component: WithdrawTransactionPage,
  },
  {
    path: '/admin/approve/withdraw',
    exact: true,
    component: WithdrawTransactionPage,
  },
  {
    path: '/admin/inviteOnly',
    exact: true,
    component: InviteOnlyTransactionPage,
  },
  {
    path: '/admin/Publish',
    exact: true,
    component: PublishTransactionPage,
  },
  {
    path: '/admin/payment',
    exact: true,
    component: PaymentTransactionPage,
  },
  {
    path: '/admin/approve/approvalAdvertiser',
    exact: true,
    component: ApprovalAdvertiser,
  },

  {
    path: '/admin/settingAdvertiser',
    exact: true,
    component: SettingAdvertiser,
  },

  {
    path: '/admin/settingAdvertiser',
    exact: true,
    component: SettingAdvertiser,
  },
  {
    path: '/admin/setting-influencer',
    exact: true,
    component: <ManageInfluencerPage />,
  },
  {
    path: '/admin/movement-balance',
    exact: true,
    component: MovementBalancePage,
  },
  {
    path: '/admin/users/:uuid',
    component: UserDetailPage,
  },
  {
    path: '/admin/users',
    exact: true,
    component: <UserPage />,
  },
  {
    path: '/admin/approve/publisher',
    component: PublisherAdminPage,
  },
  {
    path: '/admin/settings',
    component: SettingsPage,
  },
  {
    path: '/admin/system-balance-overview',
    component: SystemBalanceOverviewPage,
  },
  {
    path: '/admin/system-balance-log',
    component: SystemBalanceLogPage,
  },
  {
    path: '/admin/crypto-balance-log',
    component: CryptoBalanceLogPage,
  },
  {
    path: '/admin/role-balance-log',
    component: RoleBalanceLogPage,
  },
  {
    path: '/admin/profit-balance-log',
    component: ProfitBalanceLogPage,
  },
  {
    path: '/admin/settingFAQs',
    component: FaqsSettingPage,
  },
  {
    path: '/admin/approve/manage-job',
    component: <ApproveJob />,
  },
  {
    path: '/admin/approve/influencer-request',
    exact: true,
    component: approveInfluencerRequestNewPrice,
  },
  {
    path: '/admin/approve/influencer',
    exact: true,
    component: <ApproveInfluencer />,
  },
  {
    path: '/job/:jobId/Timeline-detail/:socialJobId',
    exact: true,
    component: <InfluencerJobTimeLineDetail />,
  },
  {
    path: '/admin/approve/influencer-follower',
    exact: true,
    component: <ApproveNewFollowerIndex />,
  },
  {
    path: '/admin/approve/speed-up-job',
    exact: true,
    component: MadeInHeavenPage,
  },
  {
    path: '/admin/approve/delete-social',
    exact: true,
    component: RequestDeleteSocialMedia,
  },
  {
    path: '/admin/approve/suspend-influencer-post',
    exact: true,
    component: SuspendSocialJob,
  },
  {
    path: '/admin/approve/currency-approval',
    exact: true,
    component: <CurrencyApproval />,
  },
  {
    path: '/admin/reportJob',
    exact: true,
    component: <BanUser />,
  },
  {
    path: '/admin/reportJobDetail/:key',
    exact: true,
    component: <ReportJobDetail />,
  },
  {
    path: '/admin/reportDeclineJob',
    exact: true,
    component: <ReportDeclineJob />,
  },
  {
    path: '/admin/reportDeclineJob/Detail',
    exact: true,
    component: <ReportDeclineJobDetail />,
  },
  {
    path: '/admin/approve/manage-job/publishDetail',
    component: <GleamJobDetail job_type="PUBLISH" />,
  },
  {
    path: '/admin/job/review',
    exact: true,
    component: <GleamJobReviewPage />,
  },
  // {
  //   path: '/admin/View-detail/:key',
  //   exact: true,
  //   component: ViewChannelDetail,
  // },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

export default advertiserRoutes;
