import { call, put, takeEvery } from 'redux-saga/effects';

import { CampaignsTypes } from './actionTypes';

import {
  getCampaignsSuccess,
  getCampaignsFail,
  getCampaignsFilterSuccess,
  getCampaignSuccess,
  getCampaignFail,
  campaignExcludeWebsitesSuccess,
  getCampaignReportSuccess,
  getCampaignTodayTotalSuccess,
  getCampaignStatusSummarySuccess,
  getSumDailyCurrentBudgetSuccess,
} from './actions';

import {
  campaignGraphql,
  campaignsGraphql,
  campaignExcludeWebsitesGraphql,
  getCampaignReportGraphql,
  campaignTodayTotalGraphql,
  campaignStatusSummaryGraphql,
  getCampaignFilter,
  sumDailyCurrentBudgetGraphql,
} from '../../graphql/advertiser/campaign';
import { setLoading, unsetLoading } from '../actions';
import {
  setCampaignDatumLoading,
  setCampaignFilterLoading,
  setCampaignStateSummaryLoading,
  unsetCampaignDatumLoading,
  unsetCampaignFilterLoading,
  unsetCampaignStateSummaryLoading,
} from '../global/actions';

function* fetchCampaigns() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(campaignsGraphql);
    yield put(getCampaignsSuccess(response));
  } catch (error) {
    yield put(getCampaignsFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchSumDailyCurrentBudget() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(sumDailyCurrentBudgetGraphql);
    yield put(getSumDailyCurrentBudgetSuccess(response));
  } catch (error) {
    yield put(getCampaignsFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchCampaign(action: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(campaignGraphql, action.id);
    yield put(getCampaignSuccess(response, action.id));
  } catch (error) {
    yield put(getCampaignFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchCampaignTodayTotal() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(campaignTodayTotalGraphql);
    yield put(getCampaignTodayTotalSuccess(response));
  } catch (error) {
    yield put(getCampaignFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchCampaignStatusSummary({ bannerType }: any) {
  try {
    yield put(setCampaignStateSummaryLoading());
    const response: Promise<any> = yield call(
      campaignStatusSummaryGraphql,
      bannerType,
    );
    yield put(getCampaignStatusSummarySuccess(response));
  } catch (error) {
    yield put(getCampaignFail(error));
  } finally {
    yield put(unsetCampaignStateSummaryLoading());
  }
}

function* fetchCampaignReport({ payload }: any) {
  try {
    yield put(setCampaignDatumLoading());
    const response: Promise<any> = yield call(getCampaignReportGraphql, payload);

    yield put(getCampaignReportSuccess(response));
  } catch (error) {
    yield put(getCampaignFail(error));
  } finally {
    yield put(unsetCampaignDatumLoading());
  }
}

function* getCampaignsFilter({ payload }: any) {
  try {
    yield put(setCampaignFilterLoading());
    const response: Promise<any> = yield call(getCampaignFilter, payload);
    yield put(getCampaignsFilterSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetCampaignFilterLoading());
  }
}

function* fetchCampaignExcludeWebsites(action: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      campaignExcludeWebsitesGraphql,
      action.id,
    );
    yield put(campaignExcludeWebsitesSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* campaigns() {
  yield takeEvery(CampaignsTypes.GET_CAMPAIGN, fetchCampaign);
  yield takeEvery(CampaignsTypes.GET_CAMPAIGN_TODAY_TOTAL, fetchCampaignTodayTotal);
  yield takeEvery(
    CampaignsTypes.GET_CAMPAIGN_STATUS_SUMMARY,
    fetchCampaignStatusSummary,
  );
  yield takeEvery(CampaignsTypes.GET_CAMPAIGN_REPORT, fetchCampaignReport);

  yield takeEvery(CampaignsTypes.GET_CAMPAIGNS_FILTER, getCampaignsFilter);
  yield takeEvery(CampaignsTypes.GET_CAMPAIGNS, fetchCampaigns);
  yield takeEvery(
    CampaignsTypes.CAMPAIGN_EXCLUDE_WEBSITES,
    fetchCampaignExcludeWebsites,
  );
  yield takeEvery(
    CampaignsTypes.GET_SUM_DAILY_CURRENT_BUDGET,
    fetchSumDailyCurrentBudget,
  );
}

export default campaigns;
