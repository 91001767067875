import React, { useEffect, useState } from 'react';
import config from 'src/config';
import { getCurrencyLogoBySymbol } from 'src/graphql/common';
import BTC from '../../assets/images/crypto/btc.svg';
import USDT from '../../assets/images/crypto/usdt.svg';
import ETH from '../../assets/images/crypto/eth.svg';
import WIS from '../../assets/images/crypto/wis.svg';
import XRP from '../../assets/images/crypto/xrp.svg';
import DOGE from '../../assets/images/crypto/doge.svg';
import CAS from '../../assets/images/crypto/cas.svg';
import NOICON from '../../assets/images/crypto/nocoin.png';
import cyptologo from '../../assets/images/crypto/c-crypto.png';

interface CoinImageProps {
  size?: string;
}

const defaultProps: CoinImageProps = {
  size: 'md',
};

const CoinImage = ({ size, symbol }: any) => {
  const className = `coin-${size}`;
  const [logo, setLogo] = useState<any>(null);

  useEffect(() => {
    const fetchImage = async () => {
      const _logo = await getCurrencyLogoBySymbol(symbol);
      if (_logo) {
        setLogo(_logo);
      } else {
        setLogo(null);
      }
    };

    fetchImage();
  }, [symbol]);

  if (!logo) {
    switch (symbol) {
      case 'BTC':
        return <img className={className} src={BTC} alt="btc" />;
      case 'USDT_ERC20':
        return <img className={className} src={USDT} alt="usdt" />;
      case 'USDT_TRC20':
        return <img className={className} src={USDT} alt="usdt" />;
      case 'USDT_BEP20':
        return <img className={className} src={USDT} alt="usdt" />;
      case 'USDT':
        return <img className={className} src={USDT} alt="usdt" />;
      case 'ETH':
        return <img className={className} src={ETH} alt="eth" />;
      case 'WIS_ERC20':
        return <img className={className} src={WIS} alt="wis" />;
      case 'WIS_TRC20':
        return <img className={className} src={WIS} alt="wis" />;
      case 'WIS':
        return <img className={className} src={WIS} alt="wis" />;
      case 'WIS_RPC':
        return <img className={className} src={WIS} alt="wis" />;
      case 'XRP':
        return <img className={className} src={XRP} alt="xrp" />;
      case 'DOGE':
        return <img className={className} src={DOGE} alt="doge" />;
      case 'CAS':
        return <img className={className} src={CAS} alt="cas" />;
      case 'BUSD':
        return <img className={className} src={BTC} alt="btc" />;
      default:
        return <img className={className} src={NOICON} alt="noicon" />;
    }
  } else {
    return (
      <img
        className={className}
        src={`${config.backendUrl}api/currencyImage/${logo}`}
        alt={symbol}
        style={{ borderRadius: '50%' }}
      />
    );
  }
};

CoinImage.defaultProps = defaultProps;

export default CoinImage;
