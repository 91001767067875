import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSocialMediaInfo } from 'src/graphql/advertiser/socialMediaInfo';
import { getOneSocialMediaInfo } from 'src/store/actions';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';

const AptitudesControl = () => {
  const dispatch = useDispatch();
  const { aptitudes$, userProfileSelected$, userProfile$ } = useSelector(
    (state: any) => ({
      aptitudes$: state.SocialMediaInfo.aptitudes,
      userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
      userProfile$: state.SocialMediaInfo.socialMediaInfoData,
    }),
  );

  // const validationSchema = Yup.object({
  //   aptitudes: Yup.array()
  //     .required(t('influencer:channel_detail.required'))
  //     .max(5, 'influencer:channel_detail.aptitudes_max_length')
  //     .min(2, 'influencer:channel_detail.aptitudes_min_length'),
  // });
  const [editState, setEditState] = useState<boolean>(false);

  // Functions
  const onSubmit = async (param: any) => {
    await updateSocialMediaInfo(param);
    if (param?.aptitude_ids?.length < 2) {
      notify(i18n.t('please select more than 2 aptitudes'), 'error');
    } else {
      dispatch(getOneSocialMediaInfo(param.key));
      setEditState(false);
    }
  };

  return {
    aptitudes$,
    editState,
    setEditState,
    onSubmit,
    // validationSchema,
    userProfileSelected$,
    userProfile$,
  };
};

export default AptitudesControl;
