import React, { useCallback, useState } from 'react';
import { Column } from 'react-table';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import choice from 'src/assets/images/choice.png';
import Swal from 'sweetalert2';
import { getWebsiteRequestSize } from 'src/store/actions';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { deleteRequestCreativeSize } from 'src/graphql';
import CustomTable from '../Common/CustomTable';
import ScriptModal from './ScriptModal';

const RequestSizeContent = ({ t }: any) => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const [selectedRow, setSelectedRow] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortType, setSortType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const pageSize = 5;
  const [page, setPage] = useState(1);

  const getDefaultPayload = (newPage: number) => {
    setPage(newPage);

    return {
      limit: pageSize,
      page: newPage,
    };
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        website_id: +id,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getWebsiteRequestSize(payload));

      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page, pageSize],
  );

  const { RequestSize, requestSizeLoading } = useSelector((state: any) => ({
    RequestSize: state.WebsiteInfo.requestSize,
    requestSizeLoading: state.Global.requestSizeLoading,
  }));

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Width'),
        accessor: ({ width }) => {
          return width === 0 ? '-' : width;
        },
        id: 'width',
      },
      {
        Header: t('table:Height'),
        accessor: ({ height }) => {
          return height === 0 ? '-' : height;
        },
        id: 'height',
      },
      {
        Header: t('table:AdsFormat'),
        accessor: ({ creative_format, banner_format }) => {
          return banner_format === 'NULL' ? creative_format : banner_format;
        },
        id: 'creative_format',
      },
      {
        Header: t('Page.Script'),
        accessor: (rowIndex) => {
          return (
            <>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleGetScript(rowIndex);
                }}
              >
                <i className="dripicons-code font-size-22" />
              </div>
            </>
          );
        },
        id: 'col-4',
        disableSort: true,
      },
      {
        accessor: ({ id: dataId, width, height }) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleSubmit(dataId, width, height)}
            >
              <i className="mdi mdi-delete font-size-22" />
            </div>
          );
        },
        id: 'col-5',
        disableSort: true,
      },
    ],
    [t],
  );
  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  const handleGetScript = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      limit: pageSize,
      page: newPage,
      website_id: +id,
      sortBy: 'id',
      sortType: 'DESC',
    };
    dispatch(getWebsiteRequestSize(payload));
    setPage(newPage);
  };

  const handleSubmit = async (creative_id: any, width: any, height: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t(`Confirm modal.delete size`, { width, height }),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          limit: 10,
          page: 1,
          website_id: +id,
          sortBy: 'id',
          sortType: 'DESC',
        };
        await deleteRequestCreativeSize(creative_id);
        dispatch(getWebsiteRequestSize(payload));
      }
    });
  };

  return (
    <CustomTable
      headerCSS="fw-normal bg-header-table"
      columns={columns}
      data={RequestSize.items || []}
      page={page}
      pageSize={pageSize}
      total={RequestSize.totalCount}
      hiddenOnXS={hiddenOnXS}
      hiddenOnSM={hiddenOnSM}
      hiddenOnMD={hiddenOnMD}
      hiddenOnLG={hiddenOnLG}
      onSort={handleSort}
      handlePageChange={handlePageChange}
      marginTopClassName="mt-0"
      isLoading={requestSizeLoading}
    >
      <ScriptModal
        data={!isEmpty(RequestSize) ? selectedRow : null}
        isOpen={modalOpen}
        handleToggle={handleModalToggle}
        handleClose={handleModalClose}
        title={t('Script generator')}
      />
    </CustomTable>
  );
};

export default withTranslation()(RequestSizeContent);
