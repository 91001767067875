import { useEffect, useState } from 'react';

// import Breadcrumbs
import { Row, Col } from 'reactstrap';
import ReportChart from 'src/components/Publisher/ReportChart';
import DashboardEarningHistory from 'src/components/Publisher/DashboardEarningHistory';
import balanceLogRangeEnum from 'src/constants/balanceLogRangeEnum';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEarningTransaction, getWebsiteGraphDatum } from 'src/store/actions';
import { withTranslation } from 'react-i18next';
import WalletWidget from '../../components/Common/WalletWidget';

const PublisherDashboard = ({ t }: any) => {
  const [datumReportParam, setDatumReportParam] = useState({
    website_id: 1,
    limit: 10,
    page: 1,
    log_range: 'DAILY',
  });

  const dispatch = useDispatch();

  const { user, websiteGraphDatum } = useSelector((state: any) => ({
    earning_transaction: state.WebsiteInfo.allEarningTransaction,
    user: state.login.user,
    websiteGraphDatum: state.WebsiteInfo.websiteGraphDatum,
  }));

  useEffect(() => {
    dispatch(
      getAllEarningTransaction({
        limit: 10,
        page: 1,
        log_range: balanceLogRangeEnum.DAILY,
        email: user.email,
      }),
    );
    const payload: any = {
      page: 1,
      website_id: 1,
      limit: 10,
      log_range: 'DAILY',
      shortcut_range: 'THISMONTH',
      log_start: '',
    };
    dispatch(getWebsiteGraphDatum(payload));
  }, []);

  return (
    <div className="page-content">
      <Row>
        <div>
          <p
            className="font-size-24 fw-bold"
            style={{ paddingLeft: 0, fontSize: 20 }}
          >
            {t('Reports')}
          </p>
        </div>
        <div>
          <ReportChart
            graph_datum={websiteGraphDatum}
            setDatumReportParam={setDatumReportParam}
            datumReportParam={datumReportParam}
          />
        </div>
      </Row>
      <Row className="align-items-stretch py-4">
        <Col xs={12} md={4} className="d-flex align-items-stretch mb-4 mb-md-0">
          <WalletWidget />
        </Col>
        <Col xs={12} md={8}>
          <DashboardEarningHistory />
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(PublisherDashboard);
