import { call, put, takeEvery } from 'redux-saga/effects';
import {
  filterGql,
  getOneSocialMediaInfoGql,
  getSocialJobGql,
  getSocialMediaInfoGql,
  socialHasJobByJobKeyGql,
  getAllAptitude as getAllAptitudeGQL,
  getAllKeywordAptitude as getAllKeywordAptitudeGQL,
  getInfluencerPriceRateGql,
  socialMediaFilterTableGql,
  getYoutubeVideoDataGql,
  getFacebookDataGql,
  getInstagramDataGql,
  getTwitterPostDataGql,
  getTiktokPostDataGql,
  socialJobSummaryGql,
  getInfluencerRequestNewPriceGql,
  requestNewPriceFilterTableGql,
  getSocialMediaInfoByUUIDGQL,
  getExpiredSocialMediaTokenGql,
  socialJobCountGql,
  filterRequestDeleteSocialTable,
  getSocialMediaInfoGroupByAptitudeGql,
  calculateSocialMediaPriceGQL,
  updateSocialMediaInfoFrom3rdPartyGQL,
  filterGleamGQL,
  socialHasGleamJobRequestByJobKeyGQL,
  getSocialGleamJobGQL,
  socialHasGleamJobReviewByJobKeyGQL,
  marketerSumPendingBalanceGQL,
  getSocialGleamJobForReviewGQL,
  influencerActivePendingJobCountGQL,
  reportJobDeclineGQL,
  getUserHasReportGQL,
  getAdminReportJobDeclineGQL,
  getSocialGleamJobByKeyGQL,
} from 'src/graphql/advertiser/socialMediaInfo';

import { setLoading, unsetLoading } from '../actions';
import {
  filterSocialJobSuccess,
  getOneSocialMediaInfoSuccess,
  getSocialJobByJobKeySuccess,
  getSocialJobSuccess,
  getSocialMediaInfoSuccess,
  getAllAptitudeSuccess,
  getAllAptitudeFail,
  getAllKeywordAptitudeSuccess,
  getAllKeywordAptitudeFail,
  getInfluencerPriceRateSuccess,
  socialMediaFilterTableSuccess,
  getInfluencerRequestNewPriceSuccess,
  getDataSuccess,
  socialJobSummarySuccess,
  requestNewPriceFilterTableSuccess,
  getSocialMediaInfoByUUIDSuccess,
  getExpiredSocialMediaTokenSuccess,
  socialJobCountSuccess,
  requestDeleteSocialMediaFilterTableSuccess,
  getSocialMediaInfoGroupByAptitudeSuccess,
  getRecommendContentPriceSuccess,
  updateSocialMediaInfoFrom3rdPartySuccess,
  filterSocialGleamJobSuccess,
  getSocialGleamJobByJobKeySuccess,
  getSocialGleamJobSuccess,
  getSocialGleamJobReviewByJobKeySuccess,
  marketerSumPendingBalanceSuccess,
  getSocialGleamJobForReviewSuccess,
  influencerActivePendingJobCountSuccess,
  reportJobDeclineSuccess,
  getReportJobDeclineSuccess,
  getAdminReportJobDeclineSuccess,
  getSocialGleamJobByKeySuccess,
} from './actions';
import SocialMediaInfoTypes from './actionTypes';
import { Aptitude, KeywordAptitude } from './reducer';

function* getSocialMediaInfo() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getSocialMediaInfoGql);

    yield put(getSocialMediaInfoSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getYoutubeVideoData({ path, social_key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      getYoutubeVideoDataGql,
      path,
      social_key,
    );

    yield put(getDataSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getTwitterPostData({ path, social_key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      getTwitterPostDataGql,
      path,
      social_key,
    );

    yield put(getDataSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getTiktokPostData({ path, social_key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      getTiktokPostDataGql,
      path,
      social_key,
    );

    yield put(getDataSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getFacebookData({ path, social_key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getFacebookDataGql, path, social_key);

    yield put(getDataSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getInstagramData({ path, social_key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getInstagramDataGql, path, social_key);

    yield put(getDataSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getOneSocialMediaInfo({ key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getOneSocialMediaInfoGql, key);

    yield put(getOneSocialMediaInfoSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getSocialJobByJobKey({ input }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(socialHasJobByJobKeyGql, input);

    yield put(getSocialJobByJobKeySuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* filter({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(filterGql, payload);

    yield put(filterSocialJobSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}
function* socialMediaFilterTable({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(socialMediaFilterTableGql, payload);

    yield put(socialMediaFilterTableSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* requestNewPriceFilterTable({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(
      requestNewPriceFilterTableGql,
      payload,
    );

    yield put(requestNewPriceFilterTableSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getSocialJob({ key }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(getSocialJobGql, key);

    yield put(getSocialJobSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getAllAptitude() {
  try {
    yield put(setLoading());
    const response: Aptitude[] = yield call(getAllAptitudeGQL);

    yield put(getAllAptitudeSuccess(response));
  } catch (error) {
    // something here
    yield put(getAllAptitudeFail());
  } finally {
    yield put(unsetLoading());
  }
}

function* getAllKeywordAptitude() {
  try {
    yield put(setLoading());
    const response: KeywordAptitude[] = yield call(getAllKeywordAptitudeGQL);

    yield put(getAllKeywordAptitudeSuccess(response));
  } catch (error) {
    // something here
    yield put(getAllKeywordAptitudeFail());
  } finally {
    yield put(unsetLoading());
  }
}

function* getInfluencerRequestNewPrice({ key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getInfluencerRequestNewPriceGql, key);

    yield put(getInfluencerRequestNewPriceSuccess(response));
  } catch (error) {
    // something here
    yield put(getAllKeywordAptitudeFail());
  } finally {
    yield put(unsetLoading());
  }
}

function* getInfluencerPriceRate() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getInfluencerPriceRateGql);

    yield put(getInfluencerPriceRateSuccess(response));
  } catch (error) {
    // something here
    yield put(getAllKeywordAptitudeFail());
  } finally {
    yield put(unsetLoading());
  }
}

function* socialJobSummary() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(socialJobSummaryGql);

    yield put(socialJobSummarySuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* socialJobCount() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(socialJobCountGql);
    yield put(socialJobCountSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getSocialMediaInfoByUUID({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getSocialMediaInfoByUUIDGQL, payload);

    yield put(getSocialMediaInfoByUUIDSuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* getExpiredSocialMediaToken({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      getExpiredSocialMediaTokenGql,
      payload,
    );

    yield put(getExpiredSocialMediaTokenSuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* requestDeleteSocialMediaFilterTable({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      filterRequestDeleteSocialTable,
      payload,
    );

    yield put(requestDeleteSocialMediaFilterTableSuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* getSocialMediaInfoGroupByAptitude() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getSocialMediaInfoGroupByAptitudeGql);

    yield put(getSocialMediaInfoGroupByAptitudeSuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* getRecommendContentPrice({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(calculateSocialMediaPriceGQL, payload);
    yield put(getRecommendContentPriceSuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* updateSocialMediaInfoFrom3rdParty({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      updateSocialMediaInfoFrom3rdPartyGQL,
      payload,
    );
    yield put(updateSocialMediaInfoFrom3rdPartySuccess(response));
  } catch (error) {
    yield put(updateSocialMediaInfoFrom3rdPartySuccess(null));
  } finally {
    yield put(unsetLoading());
  }
}

function* filterGleam({ payload }: any) {
  try {
    const response: Promise<any> = yield call(filterGleamGQL, payload);
    yield put(filterSocialGleamJobSuccess(response));
  } catch (error) {
    yield put(filterSocialGleamJobSuccess(null));
  }
}

function* getSocialGleamJobByJobKey({ payload }: any) {
  try {
    const response: Promise<any> = yield call(
      socialHasGleamJobRequestByJobKeyGQL,
      payload,
    );
    yield put(getSocialGleamJobByJobKeySuccess(response));
  } catch (error) {
    yield put(getSocialGleamJobByJobKeySuccess(null));
  }
}

function* getSocialGleamJob({ key }: any) {
  try {
    const response: Promise<any> = yield call(getSocialGleamJobGQL, key);
    yield put(getSocialGleamJobSuccess(response));
  } catch (error) {
    yield put(getSocialGleamJobSuccess(null));
  }
}

function* marketerSumPendingBalance() {
  try {
    const response: Promise<any> = yield call(marketerSumPendingBalanceGQL);
    yield put(marketerSumPendingBalanceSuccess(response));
  } catch (error) {
    yield put(marketerSumPendingBalanceSuccess(null));
  }
}

function* socialHasGleamJobReviewByJobKey({ param }: any) {
  try {
    const response: Promise<any> = yield call(
      socialHasGleamJobReviewByJobKeyGQL,
      param,
    );

    yield put(getSocialGleamJobReviewByJobKeySuccess(response));
  } catch (error) {
    yield put(getSocialGleamJobReviewByJobKeySuccess(null));
  }
}

function* getSocialGleamJobForReview({ payload }: any) {
  try {
    const response: Promise<any> = yield call(
      getSocialGleamJobForReviewGQL,
      payload,
    );
    yield put(getSocialGleamJobForReviewSuccess(response));
  } catch (error) {
    yield put(getSocialGleamJobForReviewSuccess(null));
  }
}

function* getInfluencerActivePendingJobCount() {
  try {
    const response: Promise<any> = yield call(influencerActivePendingJobCountGQL);
    yield put(influencerActivePendingJobCountSuccess(response));
  } catch (error) {
    yield put(influencerActivePendingJobCountSuccess(null));
  }
}
function* getReportJobDecline({ key }: any) {
  try {
    const response: Promise<any> = yield call(reportJobDeclineGQL, key);
    yield put(reportJobDeclineSuccess(response));
  } catch (error) {
    yield put(reportJobDeclineSuccess(null));
  }
}

function* getUserHasReport({ key }: any) {
  try {
    const response: Promise<any> = yield call(getUserHasReportGQL, key);
    yield put(getReportJobDeclineSuccess(response));
  } catch (error) {
    yield put(getReportJobDeclineSuccess(null));
  }
}

function* getAdminReportJobDecline({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getAdminReportJobDeclineGQL, payload);
    console.log('response', response);
    yield put(getAdminReportJobDeclineSuccess(response));
  } catch (error) {
    yield put(getAdminReportJobDeclineSuccess(null));
  }
}

function* getSocialGleamJobByKey({ key }: any) {
  try {
    const response: Promise<any> = yield call(getSocialGleamJobByKeyGQL, key);
    yield put(getSocialGleamJobByKeySuccess(response));
  } catch (error) {
    yield put(getSocialGleamJobByKeySuccess(null));
  }
}

function* socialMediaInfos() {
  yield takeEvery(SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO, getSocialMediaInfo);

  yield takeEvery(
    SocialMediaInfoTypes.GET_ONE_SOCIAL_MEDIA_INFO,
    getOneSocialMediaInfo,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_JOB_BY_JOB_KEY,
    getSocialJobByJobKey,
  );

  yield takeEvery(SocialMediaInfoTypes.GET_YOUTUBE_VIDEO_DATA, getYoutubeVideoData);
  yield takeEvery(SocialMediaInfoTypes.GET_FACEBOOK_DATA, getFacebookData);
  yield takeEvery(SocialMediaInfoTypes.GET_INSTAGRAM_DATA, getInstagramData);
  yield takeEvery(SocialMediaInfoTypes.GET_TIKTOK_DATA, getTiktokPostData);
  yield takeEvery(SocialMediaInfoTypes.GET_TWITTER_DATA, getTwitterPostData);
  yield takeEvery(SocialMediaInfoTypes.FILTER_SOCIAL_JOB, filter);
  yield takeEvery(SocialMediaInfoTypes.GET_SOCIAL_JOB, getSocialJob);
  yield takeEvery(SocialMediaInfoTypes.GET_ALL_APTITUDE, getAllAptitude);
  yield takeEvery(
    SocialMediaInfoTypes.GET_ALL_KEYWORD_APTITUDE,
    getAllKeywordAptitude,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_INFLUENCER_PRICE_RATE,
    getInfluencerPriceRate,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_INFLUENCER_REQUEST_NEW_PRICE,
    getInfluencerRequestNewPrice,
  );
  yield takeEvery(
    SocialMediaInfoTypes.SOCIAL_MEDIA_FILTER_TABLE,
    socialMediaFilterTable,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_REQUEST_NEW_PRICE_FILTER_TABLE,
    requestNewPriceFilterTable,
  );
  yield takeEvery(SocialMediaInfoTypes.SOCIAL_JOB_SUMMARY, socialJobSummary);
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_By_UUID,
    getSocialMediaInfoByUUID,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_EXPIRED_SOCIAL_MEDIA_TOKEN,
    getExpiredSocialMediaToken,
  );
  yield takeEvery(SocialMediaInfoTypes.SOCIAL_JOB_COUNT, socialJobCount);
  yield takeEvery(
    SocialMediaInfoTypes.REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE,
    requestDeleteSocialMediaFilterTable,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE,
    getSocialMediaInfoGroupByAptitude,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_RECOMMEND_CONTENT_PRICE,
    getRecommendContentPrice,
  );
  yield takeEvery(
    SocialMediaInfoTypes.UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY,
    updateSocialMediaInfoFrom3rdParty,
  );
  yield takeEvery(SocialMediaInfoTypes.FILTER_SOCIAL_GLEAM_JOB, filterGleam);
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY,
    getSocialGleamJobByJobKey,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_WORK,
    getSocialGleamJob,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY,
    socialHasGleamJobReviewByJobKey,
  );

  yield takeEvery(
    SocialMediaInfoTypes.MARKETER_SUM_PENDING_BALANCE,
    marketerSumPendingBalance,
  );

  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_REVIEW,
    getSocialGleamJobForReview,
  );
  yield takeEvery(
    SocialMediaInfoTypes.INFLUENCER_ACTIVE_PENDING_JOB_COUNT,
    getInfluencerActivePendingJobCount,
  );
  yield takeEvery(SocialMediaInfoTypes.REPORT_JOB_DECLINE, getReportJobDecline);
  yield takeEvery(SocialMediaInfoTypes.GET_REPORT_JOB_DECLINE, getUserHasReport);
  yield takeEvery(
    SocialMediaInfoTypes.GET_ADMIN_REPORT_JOB_DECLINE,
    getAdminReportJobDecline,
  );
  yield takeEvery(
    SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_KEY,
    getSocialGleamJobByKey,
  );
}

export default socialMediaInfos;
