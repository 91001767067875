import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import * as Yup from 'yup';
import i18n from 'src/i18n';
import { createSocialMediaInfoGql } from 'src/graphql/advertiser/socialMediaInfo';
import { setUserProfileList } from 'src/store/actions';
import notify from 'src/utils/notify';

export const initialForm: UserSocialProfile = {
  id: '',
  access_token: '',
  fan_count: 0,
  name: '',
  type: null,
  access_token_secret: '',
  aptitudes: [],
  introduce: '',
  job_score: 0,
  like: 0,
  aptitude_ids: [],
  photo_content_price: 0,
  share_content_price: 0,
  picture_url: '',
  post_engagement: 0,
  rating: 0,
  is_enable_share_price: true,
  is_enable_photo_price: true,
  is_enable_video_price: true,
};

export const validationSchema = Yup.object({
  introduce: Yup.string().required(i18n.t('influencer:channel_detail.required')),
  fan_count: Yup.number()
    .required(i18n.t('influencer:channel_detail.required'))
    .min(1, i18n.t('influencer:channel_detail.min_fan_count', { length: 1 })),
});

export const onBack = ({ history }: any) => {
  history.replace('/influencer/social-calculate/?type=select');
};

export const onSubmit = async ({
  userProfileList$,
  dispatch,
  history,
  formValues,
}: any) => {
  let { social_media_type } = formValues;
  if (social_media_type.includes('_PERSONAL')) {
    [social_media_type] = social_media_type.split('_PERSONAL');
  }

  if (formValues.aptitude_ids < 2 || formValues?.aptitude_ids?.length < 2) {
    notify(i18n.t('Please Select 2 aptitude.'), 'error');
    return;
  }
  const res = await createSocialMediaInfoGql({
    social_media_type,
    name: formValues.name,
    follower: formValues.fan_count,
    post_engagement: formValues.post_engagement,
    job_score: formValues.job_score,
    rating: formValues.rating,
    like: formValues.like,
    share_content_price: formValues.share_content_price,
    photo_content_price: formValues.photo_content_price,
    video_content_price: formValues.video_content_price,
    introduce: formValues.introduce,
    aptitude_ids: formValues.aptitude_ids,
    image_profile_url: formValues.picture_url,
    access_token: formValues.access_token,
    access_token_secret: formValues.access_token_secret,
    expire_access_token: formValues?.expire_access_token,
    is_enable_share_price: formValues.is_enable_share_price,
    is_enable_photo_price: formValues.is_enable_photo_price,
    is_enable_video_price: formValues.is_enable_video_price,
    social_id: formValues.id,
    profile_path: formValues.profile_path,
  });
  if (res) {
    const newUserProfileList = userProfileList$.filter(
      (value: UserSocialProfile) => value.id !== formValues.id,
    );
    dispatch(setUserProfileList(newUserProfileList));
    history.replace('/influencer/social-calculate/?type=select');
  }
};
