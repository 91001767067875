// import DatePicker from 'react-datepicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import './FloatingDateInput.style.scss';
import 'react-widgets/styles.css';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  value?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  isInvalid: boolean;
  onChange?: Function;
  label: string;
  isRequired?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  dateMin?: any;
  onlyDisplay?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  disableMinDate?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  isDisabled?: boolean;
  width?: string;
  labelClass?: string;
  labelStyle?: any;
}

const FloatingDateInputComponent = (props: Props) => {
  const { t } = useTranslation();
  const {
    value,
    onChange,
    label,
    isRequired,
    onlyDisplay,
    // width,
    // isInvalid,
    labelClass,
    labelStyle,
  } = props;
  const thisValue = value ? new Date(value).toDateString() : undefined;
  return (
    <div
      className="float-date-input-container"
      // style={{ marginBottom: '5px', border: '1px solid red' }}
      style={{ backgroundColor: 'white', borderRadius: '5px' }}
    >
      {!onlyDisplay ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            maxDate={new Date()}
            mask="____/__/__"
            views={['year', 'month', 'day']}
            value={value}
            onChange={(e) => (onChange ? onChange(e) : null)}
            renderInput={(params) => (
              <TextField
                fullWidth
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                variant="outlined"
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      ) : (
        <p style={{ fontSize: 18, marginLeft: 5 }}>{thisValue}</p>
      )}
      <Label className={`date-label w-100 ${labelClass}`} style={labelStyle}>
        {t(label)}
        {isRequired ? <span className="input-required">*</span> : ''}
      </Label>
    </div>
  );
};

FloatingDateInputComponent.defaultProps = {
  value: null,
  onChange: null,
  isRequired: null,
  dateMin: Date.now(),
  onlyDisplay: false,
  disableMinDate: false,
  isDisabled: false,
  width: '100%',
  labelClass: '',
  labelStyle: {},
};

export default FloatingDateInputComponent;
