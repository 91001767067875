import { call, put, takeEvery } from 'redux-saga/effects';

import { CountriesTypes } from './actionTypes';

import { getCountriesSuccess, getCountriesFail } from './actions';

import countriesGraphql from '../../graphql/advertiser/country';
import { setLoading, unsetLoading } from '../actions';

function* fetchCountries() {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(countriesGraphql);

    yield put(getCountriesSuccess(response));
  } catch (error) {
    yield put(getCountriesFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* countries() {
  yield takeEvery(CountriesTypes.GET_COUNTRIES, fetchCountries);
}

export default countries;
