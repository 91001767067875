import { call, put, takeEvery } from 'redux-saga/effects';
import { InviteOnlyType } from './actionTypes';

import { getInviteOnlyTransactions } from '../../../graphql/admin/transaction';
import { getAllInviteOnlyTransactionsRequestSuccess } from './actions';

function* getAllInviteOnly({
  payload: { limit, page, search, userId, isCampaign, jobType },
}: any) {
  const response: Promise<any> = yield call(
    getInviteOnlyTransactions,
    limit,
    page,
    search,
    userId,
    isCampaign,
    jobType,
  );

  yield put(getAllInviteOnlyTransactionsRequestSuccess(response));
}

export function* inviteSaga() {
  yield takeEvery(
    InviteOnlyType.GET_ALL_INVITE_ONLY_TRANSACTIONS_REQUEST,
    getAllInviteOnly,
  );
}

export default inviteSaga;
