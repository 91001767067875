import { WebsiteTransactionQuery } from 'src/common/types';
import { WebsiteTypes } from './actionTypes';
// Website List
export const getWebsites = (payload: {
  limit: number;
  page: number;
  url?: string;
  status?: string;
  sortBy?: any;
  sortType?: any;
}) => ({
  type: WebsiteTypes.GET_WEBSITE,
  payload: { ...payload },
});

export const getWebsitesSuccess = (website: any) => ({
  type: WebsiteTypes.GET_WEBSITE_SUCCESS,
  payload: website,
});

export const getWebsitesFail = (error: any) => ({
  type: WebsiteTypes.GET_WEBSITE_FAIL,
  payload: error,
});

// Website Info
export const getInfoWebsite = (id: any) => ({
  type: WebsiteTypes.GET_INFO_WEBSITE,
  id,
});

export const getInfoWebsiteSuccess = (websiteInfo: any, id: any) => ({
  type: WebsiteTypes.GET_INFO_WEBSITE_SUCCESS,
  payload: websiteInfo,
  id,
});

export const getInfoWebsiteFail = (error: any) => ({
  type: WebsiteTypes.GET_INFO_WEBSITE_FAIL,
  payload: error,
});

// Website Report
export const getWebsiteReport = (payload: any) => ({
  type: WebsiteTypes.GET_WEBSITE_REPORT,
  payload: { ...payload },
});

export const getWebsiteReportSuccess = (websiteReport: any) => ({
  type: WebsiteTypes.GET_WEBSITE_REPORT_SUCCESS,
  payload: websiteReport,
});

export const getWebsiteReportFail = (error: any) => ({
  type: WebsiteTypes.GET_WEBSITE_REPORT_FAIL,
  payload: error,
});

// Create Website
export const createWebsite = (
  payload: {
    url: any;
    status: string;
    userId: any;
    category: string;
    typeIndex: any;
  },
  history: any,
) => ({
  type: WebsiteTypes.CREATE_WEBSITE,
  payload: { ...payload, history },
});

export const createWebsiteFail = (error: any) => ({
  type: WebsiteTypes.CREATE_WEBSITE_FAIL,
  payload: error,
});

// Update Website Status (ADMIN)
export const updateWebsiteStatus = (payload: { id: any; status: string }) => ({
  type: WebsiteTypes.UPDATE_WEBSITE_STATUS,
  payload: { ...payload },
});

export const updateWebsiteStatusFail = (error: any) => ({
  type: WebsiteTypes.UPDATE_WEBSITE_STATUS_FAIL,
  payload: error,
});

// GET REQUEST CREATIVE SIZE
export const getWebsiteRequestSize = (payload: any) => ({
  type: WebsiteTypes.GET_REQUEST_SIZE,
  payload: { ...payload },
});

export const getWebsiteRequestSizeSuccess = (requestSize: any) => ({
  type: WebsiteTypes.GET_REQUEST_SIZE_SUCCESS,
  payload: requestSize,
});

export const getWebsiteRequestSizeFail = (error: any) => ({
  type: WebsiteTypes.GET_REQUEST_SIZE_FAIL,
  payload: error,
});

// REQUEST CREATIVE SIZE (CREATE)
export const createWebsiteRequestSize = (payload: {
  website_id: number;
  width: number;
  height: number;
  creative_format: number;
  banner_format: any;
  text_position: string;
}) => ({
  type: WebsiteTypes.CREATE_REQUEST_SIZE,
  payload: { ...payload },
});

export const createWebsiteRequestSizeSuccess = (request: any) => ({
  type: WebsiteTypes.CREATE_REQUEST_SIZE_SUCCESS,
  payload: request,
});

export const createWebsiteRequestSizeFail = (error: any) => ({
  type: WebsiteTypes.CREATE_REQUEST_SIZE_FAIL,
  payload: error,
});

// EARNING TRANSACTION (PUBLISHER)
export const getEarningTransaction = (payload: {
  limit: number;
  page: number;
  user_id?: any;
  email?: any;
  website_id?: any;
  application_key?: any;
  sortBy?: any;
  sortType?: any;
}) => ({
  type: WebsiteTypes.GET_EARNING_TRANSACTION,
  payload: { ...payload },
});

export const getEarningTransactionSuccess = (earningTransaction: any) => ({
  type: WebsiteTypes.GET_EARNING_TRANSACTION_SUCCESS,
  payload: earningTransaction,
});

// Website graphql datum
export const getWebsiteGraphDatum = (payload: {
  website_id: number;
  limit: number;
  page: number;
  log_range: string;
  log_start?: string;
  log_end?: string;
  shortcut_range?: string;
  sortBy?: string;
  sortType?: string;
}) => ({
  type: WebsiteTypes.GET_WEBSITE_GRAPH_DATUM,
  payload: { ...payload },
});

export const getWebsiteGraphDatumSuccess = (website_graph_datum: any) => ({
  type: WebsiteTypes.GET_WEBSITE_GRAPH_DATUM_SUCCESS,
  payload: website_graph_datum,
});

export const getEarningTransactionFail = (error: any) => ({
  type: WebsiteTypes.GET_EARNING_TRANSACTION_FAIL,
  payload: error,
});

// EARNING TRANSACTION (ADMIN)
export const getAdminEarningTransaction = (payload: {
  limit: number;
  page: number;
  user_id?: any;
}) => ({
  type: WebsiteTypes.GET_ADMIN_EARNING_TRANSACTION,
  payload: { ...payload },
});

export const getAdminEarningTransactionSuccess = (earningTransaction: any) => ({
  type: WebsiteTypes.GET_ADMIN_EARNING_TRANSACTION_SUCCESS,
  payload: earningTransaction,
});

export const getAdminEarningTransactionFail = (error: any) => ({
  type: WebsiteTypes.GET_ADMIN_EARNING_TRANSACTION_FAIL,
  payload: error,
});

// WEBSITE TRANSACTION LOG (GRAPH)
export const getWebsiteTransactionLog = (payload: WebsiteTransactionQuery) => ({
  type: WebsiteTypes.GET_WEBSITE_TRANSACTION_LOG,
  payload: { ...payload },
});

export const getWebsiteTransactionLogSuccess = (websiteTransaction: any) => ({
  type: WebsiteTypes.GET_WEBSITE_TRANSACTION_LOG_SUCCESS,
  payload: websiteTransaction,
});

// ALL EARNING TRANSACTION (USER)
export const getAllEarningTransaction = (payload: WebsiteTransactionQuery) => ({
  type: WebsiteTypes.GET_ALL_EARNING_TRANSACTION,
  payload: { ...payload },
});

export const getAllEarningTransactionSuccess = (earningTransaction: any) => ({
  type: WebsiteTypes.GET_ALL_EARNING_TRANSACTION_SUCCESS,
  payload: earningTransaction,
});

export const getAllEarningTransactionFail = (error: any) => ({
  type: WebsiteTypes.GET_ALL_EARNING_TRANSACTION_FAIL,
  payload: error,
});

//  GET WEBSITE TYPES ( CREATIVE TYPES )
export const getWebsiteTypes = () => ({
  type: WebsiteTypes.GET_WEBSITE_TYPES,
});

export const getWebsiteTypesSuccess = (website_type: any) => ({
  type: WebsiteTypes.GET_WEBSITE_TYPES_SUCCESS,
  payload: website_type,
});

export const getWebsiteTypesFail = (error: any) => ({
  type: WebsiteTypes.GET_WEBSITE_TYPES_FAIL,
  payload: error,
});

// GET WEBSITE TRANSACTION ( BY DATE )
export const getWebsiteTransactionByDate = (payload: {
  website_id: any;
  date: string;
}) => ({
  type: WebsiteTypes.GET_WEBSITE_TRANSACTION_BY_DATE,
  payload: { ...payload },
});

export const getWebsiteTransactionByDateSuccess = (website_transaction: any) => ({
  type: WebsiteTypes.GET_WEBSITE_TRANSACTION_BY_DATE_SUCCESS,
  payload: website_transaction,
});

export const getWebsiteTransactionByDateFail = (error: any) => ({
  type: WebsiteTypes.GET_WEBSITE_TRANSACTION_BY_DATE_FAIL,
  payload: error,
});

// GET PENDING APPROVAL
export const getPendingApproval = (payload: {
  limit: any;
  page: any;
  url?: string;
  sortBy?: string;
  sortType?: string;
}) => ({
  type: WebsiteTypes.GET_PENDING_APPROVAL,
  payload: { ...payload },
});

export const getPendingApprovalSuccess = (website_pending: any) => ({
  type: WebsiteTypes.GET_PENDING_APPROVAL_SUCCESS,
  payload: website_pending,
});

export const getPendingApprovalFail = (error: any) => ({
  type: WebsiteTypes.GET_PENDING_APPROVAL_FAIL,
  payload: error,
});
