/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/button-has-type */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const UserInfoFillModal = ({ open, setOpen }: any) => {
  const history = useHistory();
  const { t }: any = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        history.push('/profile');
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('Please fill your information')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('fill information detail')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          style={{
            width: '80px',
            height: '30px',
            background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
            borderRadius: '8px',
            border: 'none',
            color: 'white',
          }}
          onClick={() => {
            setOpen(false);
            history.push('/profile');
          }}
          autoFocus
        >
          Agree
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default UserInfoFillModal;
