import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';
import { setUserProfileList, setUserProfileStatus } from 'src/store/actions';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';

const onTikTokProcess = async ({ search, dispatch }: any) => {
  const code = new URLSearchParams(search).get('code');
  try {
    const tikTokDataRes = await fetch(
      `${config.backendUrl}api/tiktok/token/?code=${code}`,
    );
    const tikTokData = await tikTokDataRes.json();

    if (tikTokData && tikTokData.token && tikTokData.refresh_token) {
      const userData: UserSocialProfile = {
        id: tikTokData.open_id,
        access_token: tikTokData.token,
        access_token_secret: tikTokData.refresh_token,
        fan_count: tikTokData.follower_count,
        name: tikTokData.display_name,
        social_media_type: SocialMediaType.TIKTOK,
        picture_url: tikTokData.avatar_large_url,
        profile_path: tikTokData.profile_deep_link,
        expire_access_token: tikTokData.expire || null,
      };
      dispatch(setUserProfileList([userData]));
      dispatch(setUserProfileStatus('PASS'));
    } else {
      dispatch(setUserProfileList([]));
      dispatch(setUserProfileStatus('Please Authorize to get Tiktok data'));
    }
  } catch (error: any) {
    dispatch(setUserProfileList([]));
    dispatch(setUserProfileStatus(error?.message));
  }
};

export default onTikTokProcess;
