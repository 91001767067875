import { takeEvery, put, call } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import TwoFactorTypes from './actionTypes';
import {
  generateTwoFactorSecret as generateTwoFactorSecretGql,
  enableTwoFactor as enableTwoFactorGql,
  disableTwoFactorRequest as disableTwoFactorRequestGql,
  getTwoFactorStatus as getTwoFactorStatusGql,
  disableTwoFactorConfirm as disableTwoFactorConfirmGql,
} from '../../../graphql/common/two-factor';
import {
  generateTwoFactorSecretSuccess,
  getTwoFactorStatusSuccess,
  getTwoFactorStatus as onGetTwoFactorStatus,
  generateTwoFactorSecret as onGenerateTwoFactorSecret,
} from './actions';
import { Response, TwoFactorStatus } from '../../../common/types';
import notify from '../../../utils/notify';
import i18n from '../../../i18n';
import { setLoading, unsetLoading } from '../../global/actions';

function* generateTwoFactorSecret() {
  try {
    const response: Response = yield call(generateTwoFactorSecretGql, null);

    yield put(generateTwoFactorSecretSuccess(response));
  } catch (e) {
    // something here
  } finally {
    // something here
  }
}

function* enableTwoFactor({ payload: { code, secret } }: any) {
  try {
    yield put(setLoading());
    const response: Response = yield call(enableTwoFactorGql, code, secret);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(onGenerateTwoFactorSecret());
    } else {
      yield put(onGetTwoFactorStatus());

      notify(i18n.t('success:Enable 2FA Success'), 'success');
    }
  } catch (e) {
    // something here
  } finally {
    // something here
    yield put(unsetLoading());
  }
}

function* disableTwoFactorRequest({ payload: { code } }: any) {
  try {
    yield put(setLoading());
    const response: Response = yield call(disableTwoFactorRequestGql, code);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      yield put(onGetTwoFactorStatus());
      yield put(onGenerateTwoFactorSecret());

      notify(i18n.t('success:Request Disable 2FA Success'), 'success');
    }
  } catch (e) {
    // something here
  } finally {
    // something here
    yield put(unsetLoading());
  }
}

function* disableTwoFactorConfirm({ payload: { data } }: any) {
  try {
    yield put(setLoading());
    const response: Response = yield call(disableTwoFactorConfirmGql, data);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      yield put(onGetTwoFactorStatus());
      yield put(onGenerateTwoFactorSecret());

      notify(i18n.t('success:Disable 2FA Success'), 'success');
    }
  } catch (e) {
    // something here
  } finally {
    // something here
    yield put(unsetLoading());
  }
}

function* getTwoFactorStatus() {
  try {
    const response: TwoFactorStatus = yield call(getTwoFactorStatusGql);

    yield put(
      getTwoFactorStatusSuccess(
        response.enableStatus,
        response.requestDisableStatus,
      ),
    );
  } catch (e) {
    // something here
  } finally {
    // something here
  }
}

function* twoFactorSaga() {
  yield takeEvery(TwoFactorTypes.GET_TWO_FACTOR_SECRET, generateTwoFactorSecret);
  yield takeEvery(TwoFactorTypes.ENABLE_TWO_FACTOR, enableTwoFactor);
  yield takeEvery(
    TwoFactorTypes.DISABLE_TWO_FACTOR_REQUEST,
    disableTwoFactorRequest,
  );
  yield takeEvery(TwoFactorTypes.GET_TWO_FACTOR_STATUS, getTwoFactorStatus);
  yield takeEvery(
    TwoFactorTypes.DISABLE_TWO_FACTOR_CONFIRM,
    disableTwoFactorConfirm,
  );
}

export default twoFactorSaga;
