//react
import { useState } from 'react';
//react style
import { Container, CardHeader, Card, NavLink, NavItem, CardBody } from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
//component
import MetaTags from 'react-meta-tags';
//redux
import config from '../../config';
import menu from '../../constants/menu';
import Breadcrumb from '../../components/Common/Breadcrumb';
import SwiftDoc from './swiftDoc';
import KotlinDoc from './kotlinDoc';
import FlutterDoc from './flutterDoc';
import ReactNativeDoc from './reactNativeDoc';

const DocIndex = ({ t }: any) => {
  const [navSelect, setNavSelect] = useState(1);

  const contentIndex = () => {
    switch (navSelect) {
      case 1:
        return <SwiftDoc />;
      case 2:
        return <KotlinDoc />;
      case 3:
        return <FlutterDoc />;
      case 4:
        return <ReactNativeDoc />;
      default:
        return <></>;
    }
  };

  const docTabs = [
    'iOS (swift)',
    'Android (kotlin)',
    'Flutter (Dart)',
    'React native (JS)',
  ];
  return (
    <div style={{ padding: 50 }}>
      <MetaTags>
        <title>
          {t('page:Document')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('page:Document')}
          menus={[{ menu: menu.HOME }, { menu: menu.Document, active: true }]}
        />

        <Card>
          <CardHeader>
            <div className="nav-tabs-custom nav-scrollbar-none">
              {docTabs.map((docTab, index) => {
                return (
                  <NavItem className={index !== 0 ? 'ms-2 me-2 font-size-12' : ''}>
                    <NavLink
                      className={`${navSelect === index + 1 && 'active'} `}
                      onClick={() => {
                        setNavSelect(index + 1);
                      }}
                    >
                      <h5 className="mb-0">{docTab}</h5>
                    </NavLink>
                  </NavItem>
                );
              })}
            </div>
          </CardHeader>
          <CardBody style={{ padding: 40 }}>
            {contentIndex()}
            {/* <DocCode />  */}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default withTranslation()(DocIndex);
