import { CardBody, Col, Row, Card } from 'reactstrap';
import DateTimeComponent from 'src/components/Common/DateTimeComponent';
import AppVerifyStatus from 'src/components/Common/AppVerifyStatus';
import DateTimeTypes from '../../../constants/dateTimeTypes';

// eslint-disable-next-line import/prefer-default-export
export const ApplicationCardInfo = ({ application, t }: any) => (
  <Card>
    <CardBody className="p-4">
      <h6 className="font-size-16 fw-normal">{t('ApplicationName')}</h6>
      <div className="d-flex align-items-center">
        <h5 className="font-size-22 me-2 mb-0">{application.name}</h5>
      </div>
      <hr />
      <Row>
        <Col md={10}>
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div>
              <h6 className="font-size-16 fw-normal">{t('Status')}</h6>
              <h5 className="font-size-20">
                <AppVerifyStatus status={application.status} />
              </h5>
            </div>
            <div>
              <h6 className="font-size-16 fw-normal">{t('table:Earnings/day')}</h6>
              <h5 className="font-size-20">${application.current_revenue}</h5>
            </div>
            <div>
              <h6 className="font-size-16 fw-normal">{t('ApplicationAdded')}</h6>
              <h5 className="font-size-20">
                <DateTimeComponent
                  dateTime={application.created_at}
                  dateTimeType={DateTimeTypes.NORMAL_DATE_TIME}
                />
              </h5>
            </div>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);
