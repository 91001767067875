import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next';

// Redux
import { useDispatch } from 'react-redux';
import { getWebsites } from 'src/store/actions';

// import Table
import WebsiteTable from 'src/components/Publisher/Website/WebsiteList';

import menu from 'src/constants/menu';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
// import Breadcrumbs
import config from '../../../config';
import Breadcrumb from '../../../components/Common/Breadcrumb';

const MyWebsite = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWebsites({ limit: 10, page: 1 }));
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {t('page:Website')} | {config.appName}
          </title>
        </MetaTags>

        <Container fluid>
          <Breadcrumb
            pageTitle={t('page:Website')}
            menus={[{ menu: menu.DASHBOARD }, { menu: menu.WEBSITE, active: true }]}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <WebsiteTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(MyWebsite);
