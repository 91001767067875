/* eslint-disable prettier/prettier */
import React from 'react';
import { connect } from 'formik';

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/prop-types
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);
    if (keys?.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`;
      let errorElement = document.querySelector(selector);
      if (!errorElement) {
        const selectorId = `[id="${keys[0]}"]`;
        errorElement = document.querySelector(selectorId);
      }

      if (errorElement) {
        // errorElement.focus();
        const y = errorElement.getBoundingClientRect().top + window.scrollY - 200;
        window.scroll({
          top: y,
          behavior: 'smooth',
        });
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
