import React, { useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { layoutTheme } from 'src/constants/layout';
import { useSelector } from 'react-redux';

const CreativeType = (props: any) => {
  const { setTypeData, typeOption, typeData, fromSetup, name, t } = props;

  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  const [haveClick, setHaveClick] = useState(0);
  const [showError, setShowError] = useState(false);

  const selectStyle = {
    control: (styles: any) => {
      if (!typeData?.length && name === 'creativeType' && showError) {
        return {
          ...styles,
          borderColor: '#fd625e',
          '&:hover': {
            borderColor: '#fd625e',
          },
          boxShadow: 'none',
        };
      }
      if (layoutMode !== layoutTheme.DARKMODE) {
        return {
          ...styles,
          borderColor: '#ced4d9',
          '&:hover': {
            borderColor: '#009a78',
          },
          boxShadow: 'none',
        };
      }
      return {
        ...styles,
        backgroundColor: '#0d0f00',
        borderColor: '#474d4a',
        color: 'black',
        '&:hover': {
          borderColor: '#009a78',
        },
        boxShadow: 'none',
      };
    },
    option: (styles: any, optionProps: any) => {
      const newStyle = styles;
      newStyle[':active'].backgroundColor = '#009a78';
      const backgroundColor = layoutMode === layoutTheme.DARKMODE ? '#0d0f00' : '';
      const focused =
        layoutMode === layoutTheme.DARKMODE
          ? { color: optionProps.isFocused ? 'black' : 'a3958f' }
          : { color: optionProps.isFocused ? 'white' : 'black' };
      return {
        ...newStyle,
        backgroundColor: optionProps.isFocused ? '#009a78' : backgroundColor,
        cursor: 'pointer',
        ...focused,
      };
    },
    menu: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            backgroundColor: '#0d0f00',
            cursor: 'pointer',
          }
        : {
            ...provided,
            cursor: 'pointer',
          },
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#009a78',
      cursor: 'pointer',
    }),
    multiValueLabel: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            color: 'black',
            cursor: 'pointer',
          }
        : {
            ...provided,
            color: 'white',
            cursor: 'pointer',
          },

    multiValueRemove: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            color: 'black',
            '&:hover': {
              backgroundColor: '#037e63',
            },
            cursor: 'pointer',
          }
        : {
            ...provided,
            color: 'white',
            '&:hover': {
              backgroundColor: '#037e63',
            },
            cursor: 'pointer',
          },
  };

  const handleChange = (selectedOptions: React.SetStateAction<never[]>) => {
    setTypeData(selectedOptions);
  };

  const handleClick = () => {
    if (name === 'creativeType') setHaveClick(haveClick + 1);
  };

  if (haveClick) {
    window.onclick = () => {
      setShowError(true);
    };
  }

  return (
    <div>
      {!fromSetup && (
        <>
          <Label className="fs-5 input-label">
            {t('Category (select at least 1)')}
            <span className="input-required">*</span>
          </Label>
        </>
      )}

      <Row>
        <Col style={{ zIndex: 5 }} md={12} lg={8}>
          <FormGroup className="mt-3" onClick={() => handleClick()}>
            <Select
              isMulti
              name={name || ''}
              options={typeOption}
              value={typeData}
              classNamePrefix="select2-selection"
              closeMenuOnSelect={false}
              onChange={handleChange}
              styles={selectStyle}
              placeholder={t('Select')}
            />

            {!typeData?.length && name === 'creativeType' && (
              <div
                style={{
                  color: 'red',
                  marginTop: '5px',
                  marginBottom: '0',
                  fontWeight: 'bold',
                }}
              >
                {t('Pick at least 1 campaign category')}
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
export default withTranslation()(CreativeType);
