import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { Field, ErrorMessage } from 'formik';
import ErrorFocus from 'src/utils/ErrorFocus';
import * as Yup from 'yup';
import 'yup-phone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  createUserInfo,
  updateUserInfo,
  uploadProfile,
} from 'src/store/users/actions';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { shortPhoneDigit } from 'src/utils/country';
import TimeZone from '../../../../utils/timezone.json';

const MarketerAccountControl = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { marketerData, user } = useSelector((state: any) => ({
    marketerData: state.Users.userInfo,
    user: state.login.user,
    userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
    register_third_data$: state.register.register_third_data,
  }));
  const dq = 'xwx';
  const [value, setValue] = useState(dq);
  //? Form Controls
  const formControls: FormControl[] = [
    // {
    //   label: 'marketer:account.user_name',
    //   name: 'user_name',
    //   type: FormInputType.STRING,
    //   required: true,
    // },
    {
      label: 'marketer:account.name',
      name: 'name',
      type: FormInputType.STRING,
      required: true,
    },
    {
      label: 'marketer:account.company',
      name: 'company',
      type: FormInputType.STRING,
      required: true,
    },
    {
      label: 'marketer:account.company_type',
      name: 'company_type',
      type: FormInputType.SELECT,
      disabled: false,
      options: [
        {
          label: 'marketer:account.brand_or_company',
          value: CompanyType.BRAND_OR_COMPANY,
        },
        {
          label: 'marketer:account.agency',
          value: CompanyType.AGENCY,
        },
        {
          label: 'marketer:account.personal',
          value: CompanyType.PERSONAL,
        },
      ],
      required: true,
    },
    {
      label: 'marketer:account.timezone',
      name: 'timezone',
      type: FormInputType.TIMEZONE,
    },
    {
      label: 'marketer:account.phone_number',
      name: 'phone_number',
      type: FormInputType.STRING,
      required: true,
    },
    // {
    //   label: 'influencer:account.hr',
    //   name: 'hr',
    //   type: FormInputType.HR,
    // },
  ];

  const initialValues: any = {
    // user_name: '',
    name: '',
    company: '',
    company_type: CompanyType.BRAND_OR_COMPANY,
    phone_number: '',
    country_phone_digit: '+66',
    country_phone_number: '',
    timezone: marketerData.timezone,
    timezone_offset: 0,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const validationSchema = Yup.object().shape({
    // user_name: Yup.string().required(t('marketer:account.required')),
    name: Yup.string().required(t('marketer:account.required')),
    company: Yup.string().required(t('marketer:account.required')),
    // company_type: Yup.string().required(t('marketer:account.required')),
    company_type: Yup.string(),
    country_phone_number: Yup.string().required(t('marketer:account.required')),
  });
  //? Form Logic
  const [onEdit, setOnEdit] = useState(false);
  const haveDataInDataBase = marketerData
    ? Object.keys(marketerData)?.length > 1
    : false;
  const showInfo = !onEdit && haveDataInDataBase;
  //? Constructor

  //? Functions
  const displays = (errors: any, touched: boolean, handleChange: any) => {
    const test = (input: any) => {
      switch (input.type) {
        case FormInputType.STRING:
          return textInput(input, errors, touched, handleChange);
        case FormInputType.SELECT:
          return dropdownInput(input, errors, touched, handleChange);
        case FormInputType.HR:
          return hrDisplay();
        case FormInputType.TIMEZONE:
          return selectTimeZone(input);
        default:
          return textInput(input, errors, touched, handleChange);
      }
    };
    const items = [];
    let itemsInRow: any = [];

    for (let i = 0; i < formControls?.length; i++) {
      const item = formControls[i];

      itemsInRow.push(item);
      if (itemsInRow?.length === 2 || i === formControls?.length - 1) {
        items.push(itemsInRow);
        itemsInRow = [];
      }
    }

    return items.map((itemInRow) => (
      <div
        style={{
          justifyContent: 'space-between',
        }}
        className="item-from-controls"
      >
        {itemInRow.map((input: any) => (
          <div
            style={{ padding: '0 1rem' }}
            className="item-width-50 text-white-marketer"
          >
            {test(input)}
          </div>
        ))}
      </div>
    ));
  };

  const onSubmit = async () => {
    const submitData = { ...formValues };
    delete submitData.dataReceived;
    delete submitData.country_phone_number;

    if (!haveDataInDataBase) {
      delete submitData.image_storage;
      dispatch(createUserInfo(submitData));
    } else {
      const submitKeys = Object.keys(submitData);
      for (let i = 0; i < submitKeys?.length; i++) {
        const submitKey = submitKeys[i];
        if (submitData[submitKey] === marketerData[submitKey]) {
          delete submitData[submitKey];
        }
      }

      if (Object.keys(submitData)?.length) {
        dispatch(updateUserInfo(submitData));
      }
    }
    setValue(marketerData.timezone);
    setOnEdit(false);
  };

  useEffect(() => {
    if (onEdit === true) {
      setValue(marketerData.timezone);
    }
  }, [onEdit]);

  const handleFileSelected = async (e: any): Promise<void> => {
    const files = Array.from(e.target.files);
    dispatch(uploadProfile(files));
  };
  const temp = [...TimeZone];
  useEffect(() => {
    if (haveDataInDataBase) {
      const { name, company, company_type, phone_number, country_phone_digit } =
        marketerData;

      const data: any = {
        // user_name,
        name,
        company,
        company_type,
        phone_number,
        country_phone_digit: country_phone_digit || '+66',
        country_phone_number: `${country_phone_digit}${phone_number}` || '',
        timezone: marketerData.timezone,
        timezone_offset: 0,
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(initialValues)) {
        (initialValues as any)[key] = data[key];
      }
      setFormValues({ ...data });
    }
  }, [marketerData]);

  //? Component UI
  const textInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
  ) => {
    let shortCountry = ' ';
    if (shortPhoneDigit[formValues.country_phone_digit]?.length) {
      [shortCountry] = shortPhoneDigit[formValues.country_phone_digit];
    }
    // eslint-disable-next-line no-nested-ternary
    return showInfo ? (
      <p className="text-display-container">
        <span className="text-header">{t(input.label)}:</span>
        <span className="text-content">
          {input.name === 'phone_number'
            ? `${marketerData.country_phone_digit} ${marketerData.phone_number}`
            : marketerData[input.name] || '-'}
        </span>
      </p>
    ) : input.name === 'phone_number' ? (
      <FormGroup className="phone-number-input-container">
        <Label>
          {t(input.label)}
          {input.required && <span className="input-required">*</span>}
        </Label>
        <div className="phone-input" style={{ display: 'flex' }}>
          <ReactPhoneInput
            enableSearch
            autoFormat={false}
            countryCodeEditable
            disableSearchIcon
            country={shortCountry || 'th'}
            inputStyle={{
              height: '35px',
              fontSize: '13px',
              paddingLeft: '48px',
            }}
            inputProps={{
              required: true,
              maxlength: '4',
              autoComplete: 'off',
            }}
            searchStyle={{ margin: '0', width: '97%', height: '30px' }}
            buttonStyle={{ borderRadius: '5px 0 0 5px' }}
            dropdownStyle={{
              width: '200px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              zIndex: '99',
            }}
            onChange={(e: any) => {
              setFormValues({
                ...formValues,
                country_phone_digit: `+${e}`,
                country_phone_number: `+${e}${formValues.phone_number}`,
              });
            }}
          />
          <input
            className="phone-number-width"
            type="number"
            value={formValues.phone_number}
            name="country_phone_number"
            onChange={(e: any) => {
              setFormValues({
                ...formValues,
                phone_number: e.target.value,
                country_phone_number: `${formValues.country_phone_digit}${e.target.value}`,
              });
            }}
          />
        </div>

        <div style={{ paddingLeft: '14px' }}>
          <ErrorMessage
            name="country_phone_number"
            component="div"
            className="invalid-message"
          />
          <ErrorFocus name="country_phone_number" />
        </div>
      </FormGroup>
    ) : (
      <FormGroup className="float-input-container">
        <Label className="input-fix-ab">
          {t(input.label)}
          {input.required && <span className="input-required">*</span>}
        </Label>
        <Field
          name={input.name}
          value={formValues[input.name as keyof typeof initialValues]}
          className={`w-100 ${
            errors[input.name] && touched[input.name] ? 'is-invalid' : ''
          }`}
          placeholder={t(input.label)}
          onChange={(e: any) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
            handleChange(e);
          }}
          disabled={input.disabled}
        />
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name={input.name} />
      </FormGroup>
    );
  };

  const dropdownInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
  ) => {
    return showInfo ? (
      <>
        <p className="text-display-container">
          <span className="text-header">{t(input.label)}:</span>
          <span className="text-content">
            {t(`marketer:account.${marketerData[input.name]}`) ?? '-'}
          </span>
        </p>
      </>
    ) : (
      <>
        <FormGroup className="float-input-container">
          <select
            name={input.name}
            value={formValues[input.name as keyof typeof initialValues]}
            className={`w-100 ${
              errors[input.name] && touched[input.name] ? 'is-invalid' : ''
            }`}
            disabled={input.disabled}
            style={{ cursor: input.disabled ? 'no-drop' : 'pointer' }}
            onChange={(e: any) => {
              const { name, value } = e.target;
              setFormValues({ ...formValues, [name]: value });
              handleChange(e);
            }}
          >
            {input.options ? (
              <>
                {input.options.map((option: FormOption) => (
                  <option
                    value={
                      typeof option.value === 'boolean'
                        ? option.value.toString()
                        : option.value
                    }
                    // label={t(option.label)} //IOS not work
                  >
                    {t(option.label)}
                  </option>
                ))}
              </>
            ) : (
              <></>
            )}
          </select>
          <Label>
            {t(input.label)}
            {input.required ? <span className="input-required">*</span> : ''}
          </Label>
          <ErrorMessage
            name={input.name}
            component="div"
            className="invalid-message"
          />
          <ErrorFocus name={input.name} />
        </FormGroup>
      </>
    );
  };

  const defaultSelected = temp.find((opt) => opt?.abbr === '');
  const [selected, setSelected] = useState(defaultSelected);
  const onChange = (option: any) => {
    setSelected(option);
    const timeZoneLabel = option.label.split(') ');
    setFormValues({
      ...formValues,
      timezone: timeZoneLabel[1],
      timezone_offset: option?.offset * 60,
    });
  };

  const customTimeZoneStyles = {
    // Define your custom styles here
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? 'transparent' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px c' : provided.boxShadow,
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#1b75d0' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#1b75d0',
        color: 'white',
      },
    }),
  };

  const selectTimeZone = (input: FormControl) => {
    return showInfo ? (
      <p className="text-display-container">
        <span className="text-header">{t(input.label)}:</span>
        <span className="text-content">{marketerData.timezone}</span>
      </p>
    ) : (
      // <p className="input-container">
      <FormGroup className="time-zone-input-container">
        <Label>
          {t(input.label)}
          <span className="input-required">*</span>
        </Label>
        <Select
          options={temp}
          value={selected}
          onChange={onChange}
          styles={customTimeZoneStyles}
        />
        <br />
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
      </FormGroup>
      // </p>
    );
  };

  const hrDisplay = () => {
    return <hr className="hr" />;
  };

  //? Return
  return {
    formValues,
    setFormValues,
    onEdit,
    setOnEdit,
    marketerData,
    user,
    initialValues,
    validationSchema,
    haveDataInDataBase,
    showInfo,
    textInput,
    onSubmit,
    handleFileSelected,
    displays,
    t,
    selectTimeZone,
  };
};

export default MarketerAccountControl;

// eslint-disable-next-line no-unused-vars, no-shadow
enum CompanyType {
  // eslint-disable-next-line no-unused-vars
  BRAND_OR_COMPANY = 'BRAND_OR_COMPANY',
  // eslint-disable-next-line no-unused-vars
  AGENCY = 'AGENCY',
  // eslint-disable-next-line no-unused-vars
  PERSONAL = 'PERSONAL',
  TIMEZONE = 'TIMEZONE',
}

interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  options?: FormOption[];
  required?: boolean;
  disabled?: boolean;
  input_type?: string;
}

interface FormOption {
  label: string;
  value: string | number | boolean;
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  SELECT,
  // eslint-disable-next-line no-unused-vars
  HEADER,
  // eslint-disable-next-line no-unused-vars
  HR,
  TIMEZONE,
}
