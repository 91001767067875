import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

const allowNotificationMailGraphql = async () => {
  const query = gql`
    query {
      allowNotificationMail {
        id
        user_id
        allow_market_mail
        allow_balance_low_mail
        allow_campaign_out_of_budget_mail
        allow_campaign_finished_mail
      }
    }
  `;

  const result: any = await client.query({
    query,
  });

  return result.data.allowNotificationMail;
};

const updateAllowNotificationMail = async (data: any) => {
  const {
    allow_market_mail,
    allow_balance_low_mail,
    allow_campaign_out_of_budget_mail,
    allow_campaign_finished_mail,
  } = data;
  const CHANGE_PASSWORD_CONFIRM = gql`
    mutation UpdateAllowNotificationMail(
      $allow_market_mail: Boolean
      $allow_balance_low_mail: Boolean
      $allow_campaign_out_of_budget_mail: Boolean
      $allow_campaign_finished_mail: Boolean
    ) {
      updateAllowNotificationMail(
        updateAllowNotificationMailInput: {
          allow_market_mail: $allow_market_mail
          allow_balance_low_mail: $allow_balance_low_mail
          allow_campaign_out_of_budget_mail: $allow_campaign_out_of_budget_mail
          allow_campaign_finished_mail: $allow_campaign_finished_mail
        }
      ) {
        id
        allow_market_mail
        allow_balance_low_mail
        allow_campaign_out_of_budget_mail
        allow_campaign_finished_mail
      }
    }
  `;

  try {
    const response = await client.mutate({
      mutation: CHANGE_PASSWORD_CONFIRM,
      variables: {
        allow_market_mail,
        allow_balance_low_mail,
        allow_campaign_out_of_budget_mail,
        allow_campaign_finished_mail,
      },
      errorPolicy: 'all',
    });
    notify(i18n.t('success:Email notifications have been updated.'), 'success');

    return response.data.updateAllowNotificationMail;
  } catch (error) {
    notify(i18n.t('error:Email notifications have not been updated.'), 'error');
    return '';
  }
};

export { allowNotificationMailGraphql, updateAllowNotificationMail };
