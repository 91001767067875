import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import userImage from 'src/assets/images/flupower/user-info.png';
import userNoImage from 'src/assets/images/flupower/user-influ.png';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import EmailNotificationsSetting from 'src/components/Common/setting/EmailNotificationsSetting';
import config from 'src/config';
import resizeImage from 'src/utils/resizeImage';
import { uploadProfile } from 'src/store/users/actions';
import Role from 'src/constants/enum/role';
import Breadcrumb from '../../components/Common/Breadcrumb';
import menu from '../../constants/menu';
import TwoFactorSettingForm from '../../components/Common/TwoFactorSettingForm';
import ChangePasswordForm from '../../components/Common/setting/ChangePassword/ChangePasswordForm';
import AccountSetting from '../../components/Common/AccountSetting';
import './index.style.scss';
import InfluencerAccountPage from '../Publisher/Influencer/InfluencerAccount/InfluencerAccount.component';
import MarketerAccountPage from '../Advertiser/Marketer/MarketerAccount/MarketerAccount.component';
import RemoveData from '../RemoveData';

const ProfilePage = ({ t }: any) => {
  const location = useLocation();
  const twoFaProps: any = location.state;
  const [activeTab, toggleTab] = useState(twoFaProps ? 3 : 1);
  const dispatch = useDispatch();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const { user, socialMediaInfo, userInfo } = useSelector((state: any) => ({
    user: state.login.user,
    socialMediaInfo: state.login.socialMediaInfoImage,
    userInfo: state.Users.userInfo,
  }));
  const tab: string | null = params.get('tab');

  const handleFileSelected = async (e: any): Promise<void> => {
    const files: any = Array.from(e.target.files);

    const fileConfig: any = {
      file: files[0],
      maxSize: 500,
    };
    const file = await resizeImage(fileConfig);
    dispatch(uploadProfile([file]));
  };

  // const handleFileSelected = async (e: any): Promise<void> => {
  //   const files = Array.from(e.target.files);
  //   dispatch(uploadProfile(files));
  // };

  useEffect(() => {
    if (tab) toggleTab(+tab);
  }, []);
  const renderTabs = () => {
    return (
      <div
        className="nav-tabs-custom card-header-tabs border-top mt-4 mx-0 nav-scrollbar-none"
        style={{ fontSize: '1rem', backgroundColor: 'white' }}
      >
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                active: activeTab === 1,
              },
              'px-3 profile-nave',
            )}
            onClick={() => {
              toggleTab(1);
            }}
          >
            <div style={{ display: 'flex', fontSize: '' }}>
              <h6 className="m-0">
                {t('Menus.Account Setting')}{' '}
                {!userInfo?.name && user.role !== Role.ADMIN && (
                  <ErrorIcon color="error" fontSize="small" />
                )}
              </h6>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                active: activeTab === 2,
              },
              'px-3 profile-nave',
            )}
            onClick={() => {
              toggleTab(2);
            }}
          >
            <div style={{ display: 'flex', fontSize: '' }}>
              <h6 className="m-0">{t('Menus.Language Setting')}</h6>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                active: activeTab === 3,
              },
              'px-3 profile-nave',
            )}
            onClick={() => {
              toggleTab(3);
            }}
          >
            <div style={{ display: 'flex', fontSize: '' }}>
              <h6 className="m-0">{t('Menus.2FA Setting')}</h6>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                active: activeTab === 4,
              },
              'px-3 profile-nave',
            )}
            onClick={() => {
              toggleTab(4);
            }}
          >
            <div style={{ display: 'flex', fontSize: '' }}>
              <h6 className="m-0">{t('Menus.Email Notifications Setting')}</h6>
            </div>
          </NavLink>
        </NavItem>
        {user?.email_verified_at && (
          <NavItem>
            <NavLink
              to="#"
              className={classnames(
                {
                  active: activeTab === 5,
                },
                'px-3 profile-nave',
              )}
              onClick={() => {
                toggleTab(5);
              }}
            >
              <div>
                <h6 className="m-0">{t('Menus.Change Password')}</h6>
              </div>
            </NavLink>
          </NavItem>
        )}
        {user.role === Role.PUBLISHER && (
          <NavItem>
            <NavLink
              to="#"
              className={classnames(
                {
                  active: activeTab === 6,
                },
                'px-3 profile-nave',
              )}
              onClick={() => {
                toggleTab(6);
              }}
            >
              <div>
                <h6 className="m-0">{t('Menus.Remove social authentication')}</h6>
              </div>
            </NavLink>
          </NavItem>
        )}
      </div>
    );
  };
  const imageProfile = () => {
    if (!user?.image_storage) {
      return <img src={userImage} alt="Header Avatar" className="image-profile" />;
    }
    if (user?.image_storage?.key) {
      return (
        <img
          src={`${config.backendUrl}api/profile/${user?.image_storage?.key}`}
          alt="Header Avatar"
          className="image-profile"
        />
      );
    }
    return <img src={userImage} alt="Header Avatar" className="image-profile" />;
  };

  const renderTabsContent = () => {
    return (
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          {user?.role === Role.ADVERTISER ? (
            <MarketerAccountPage />
          ) : (
            <InfluencerAccountPage />
          )}
        </TabPane>
        <TabPane tabId={2}>
          <AccountSetting />
        </TabPane>
        <TabPane tabId={3}>
          <TwoFactorSettingForm />
        </TabPane>
        <TabPane tabId={4}>
          <EmailNotificationsSetting />
        </TabPane>
        <TabPane tabId={5}>
          <ChangePasswordForm />
        </TabPane>
        <TabPane tabId={6}>
          <RemoveData />
        </TabPane>
      </TabContent>
    );
  };

  return (
    <>
      <div
        className={` ${
          user.role === Role.ADVERTISER ? '' : 'page-content'
        } h-100-per-full`}
        style={{
          paddingTop: user.role === Role.ADVERTISER ? '6.3vh' : '',
          paddingLeft: user.role === Role.ADVERTISER ? '4.83vh' : '',
          paddingRight: user.role === Role.ADVERTISER ? '4.83vh' : '',
          backgroundColor: '#1a1a1a',
          color: 'white',
        }}
      >
        <MetaTags>
          <title>{t('Profile')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            pageTitle={t('Profile')}
            menus={[{ menu: menu.HOME }, { menu: menu.PROFILE, active: true }]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="px-0">
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0 px-4 py-2">
                        <div className="flex-shrink-0">
                          <div
                            className="avatar-xl me-3"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginLeft: '12px',
                            }}
                          >
                            {imageProfile()}
                            <div style={{ paddingRight: '15px' }}>
                              <label
                                htmlFor="upload-input"
                                className="upload-icon withdraw-page"
                              >
                                <i className="fas fa-pen" />
                                <input
                                  id="upload-input"
                                  onChange={handleFileSelected}
                                  type="file"
                                  className="upload-input"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="mb-1 text-white">{t('My Account')}</h5>
                            <p className="text-white">{user.email}</p>

                            <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-white">
                              <div>
                                <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                {t(`${user.role}`)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  {renderTabs()}
                </CardBody>
              </Card>
              {renderTabsContent()}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(ProfilePage);
