import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaFilterTable } from 'src/store/actions';
import ReactFlagsSelect from 'react-flags-select';
import { ReactFlagsSelectCountry } from 'src/utils/country';
import control from './FollowChoice.style';
import IconRadioInputComponent from '../../../../../shared/IconRadioInput/IconRadioInput.component';

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
}

interface FormControl {
  label: string;
  color: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  value: string;
}

const formControls: FormControl[] = [
  {
    label: 'men',
    name: 'fas fa-mars',
    color: '#3498DB',
    type: FormInputType.STRING,
    required: true,
    value: 'MEN',
  },
  {
    label: 'women',
    name: 'fas fa-venus',
    value: 'WOMEN',
    color: '#BB8FCE',
    type: FormInputType.STRING,
    required: true,
  },
  // {
  //   label: 'lgbt',
  //   name: 'fas fa-transgender-alt',
  //   value: 'LGBT',
  //   color: '#f7df81',
  //   type: FormInputType.STRING,
  //   required: true,
  // },
  // {
  //   label: 'ALL',
  //   name: 'fas fa-reply-all:before ',
  //   value: 'ALL',
  //   color: '#f7df81',
  //   type: FormInputType.STRING,
  //   required: true,
  // },
];
const formLGBT: FormControl[] = [
  {
    label: 'lgbt',
    name: 'fas fa-transgender-alt',
    value: 'LGBT',
    color: '#f7df81',
    type: FormInputType.STRING,
    required: true,
  },
];
const { DivContent, Texts, FlexRow, FlexCol, Radio, GridBox, Select, Option, Btn } =
  control();
const FollowChoice = ({ form, setForm, ageRange, toggle }: any) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const [selectCounty, setSelectCounty] = useState(false);
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));
  const [isLGBT, setIsLGBT] = useState(false);
  return (
    <DivContent
      justifycontent="flex-start"
      width="100%"
      borderTop="1px solid #EAECEE "
      marginTop="20px"
    >
      <FlexCol>
        <Texts margin="20px 10px 0 10px" fontsize="1.5rem">
          {t('marketer:create_job_campaign:Influencer_country')}
        </Texts>
        <FlexRow justifycontent="flex-start" width="100%">
          <FlexCol margin="0 auto">
            <Radio
              type="radio"
              value=""
              name="county"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              defaultChecked
              onChange={(event: any) => {
                setForm({
                  ...form,
                  country: event.target.value,
                });

                setSelectCounty(false);
                return <></>;
              }}
            />{' '}
            {t('marketer:create_job_campaign:All')}
          </FlexCol>
          <FlexCol margin="0 auto">
            <Radio
              type="radio"
              value="Thailand"
              name="county"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              onChange={(event: any) => {
                setForm({
                  ...form,
                  country: event.target.value,
                });
                setSelectCounty(false);
                return <></>;
              }}
            />{' '}
            {t('marketer:create_job_campaign:Thailand')}
          </FlexCol>
          <FlexCol margin="0 auto">
            <Radio
              type="radio"
              value="OTHER"
              name="county"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              onChange={() => setSelectCounty(!selectCounty)}
            />{' '}
            {t('marketer:create_job_campaign:other')}
          </FlexCol>
        </FlexRow>
        {selectCounty && (
          <div style={{ width: '300px', marginTop: '12px' }}>
            <ReactFlagsSelect
              searchable
              placeholder={t('marketer:create_job_campaign:Search all country')}
              selectedSize={16}
              optionsSize={16}
              searchPlaceholder={t('marketer:create_job_campaign:Search country')}
              onSelect={(label: any) => {
                const country: any = ReactFlagsSelectCountry;
                setForm({ ...form, country: country[label] });
                setSelected(label);
                const rfsBtn = document.getElementById('rfs-btn');
                if (!rfsBtn) return;
                rfsBtn.click();
              }}
              showOptionLabel
              selected={selected}
            />
          </div>
        )}
      </FlexCol>

      <FlexCol borderTop="1px solid #EAECEE " margin="20px 0">
        <Texts margin="20px 10px 0 10px" fontsize="1.5rem">
          {t('marketer:create_job_campaign:Gender')}
        </Texts>
        <GridBox>
          {formControls.map((data, index) => (
            <IconRadioInputComponent
              id={index.toString()}
              icon={data.name}
              label={t(`marketer:create_job_campaign:${data.label}`)}
              background={data.color}
              labelSize="1em"
              isChecked={data.value === form.gender}
              onClick={() => {
                setForm({
                  ...form,
                  isLGBT,
                  gender: form.gender === data.value ? null : data.value,
                });
              }}
            />
          ))}
          {formLGBT.map((data, index) => (
            <IconRadioInputComponent
              id={index.toString()}
              icon={data.name}
              label={t(`marketer:create_job_campaign:${data.label}`)}
              background={data.color}
              labelSize="1em"
              isChecked={isLGBT}
              onClick={() => {
                setForm({
                  ...form,
                  isLGBT: !isLGBT,
                });
                setIsLGBT(!isLGBT);
              }}
            />
          ))}
        </GridBox>
      </FlexCol>
      <FlexCol margin="180px  10px 0" borderTop="1px solid #EAECEE ">
        <Texts margin="20px 10px 5px 10px" fontsize="1.5rem">
          {t('marketer:create_job_campaign:Age')}
        </Texts>
        <FlexCol width="100%">
          <Select
            name="cars"
            id="cars"
            width="70%"
            height="50px"
            borderradius="5px"
            value={ageRange?.findIndex((v: any) => v.min_age === form.min_age)}
            onChange={(e) => {
              setForm({
                ...form,
                min_age: ageRange[e.target.value].min_age,
                max_age: ageRange[e.target.value].max_age,
              });
            }}
          >
            {ageRange.map((value: any, index: number) => (
              <Option value={index} width="100%" height="30px">
                {value.label}
              </Option>
            ))}
          </Select>
          <Texts margin="10px auto 0 auto" fontsize="0.7rem" fontweight="bold">
            {t('marketer:create_job_campaign:note')} :
            {t('marketer:create_job_campaign:select_age_influencers')}
          </Texts>
        </FlexCol>
      </FlexCol>
      <FlexCol>
        <Btn
          type="submit"
          width="150px"
          height="40px"
          borderradius="10px"
          background="#3DB5FF"
          onClick={(e: any) => {
            e.preventDefault();
            const submitForm = {
              ...form,
              social_media_type: marketerJob.social_media_type,
              content_type: marketerJob.content_type,
            };
            delete submitForm.propertyType;

            dispatch(socialMediaFilterTable(submitForm));
            toggle();
          }}
        >
          {t('marketer:create_job_campaign:Submit')}
        </Btn>
      </FlexCol>
    </DivContent>
  );
};
export default FollowChoice;
