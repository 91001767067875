/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { filterSocialGleamJob } from 'src/store/actions';
import { useHistory } from 'react-router';
import { formatDateForm } from 'src/utils/dateTime';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import { transformStringAmountInput } from '../../utils/input';
//import './DepositHistoryTable.style.scss';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBarWhite';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';
import PageSizeOptions from '../Table/PageSizeOptions';
import { InfluencerSocialIcon } from '../Common/status/InfluencerJobStatus';

const JobIncomeTable = ({ t }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  // Redux
  const { txn, influencerData } = useSelector((state: any) => ({
    txn: state.SocialMediaInfo.filterG,
    influencerData: state.Users.userInfo,
  }));

  // const handleModalToggle = () => {
  //   setModalOpen(!modalOpen);
  // };

  // const handleModalClose = () => {
  //   setModalOpen(false);
  // };

  // const handleGetDetail = (rowIndex: any) => {
  //   setSelectedRow(rowIndex);
  //   setModalOpen(!modalOpen);
  // };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(filterSocialGleamJob(payload));

    setPageSize(newPageSize);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(filterSocialGleamJob(payload));
    setSearch(val);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(filterSocialGleamJob(payload));
    setSearch('');
  };

  const dataTimezone = (dataTimezone: any) => {
    const currentTimeZone = moment(dataTimezone)
      .add(influencerData.timezone_offset, 'minutes')
      .format('HH:mm A');
    return currentTimeZone;
  };

  const rendorSocialMediaType = (social_media_info: any) => {
    const social_type: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (!social_type.includes(social_media_info[i].social_media_type)) {
        social_type.push(social_media_info[i].social_media_type);
      }
    }
    return (
      <>
        {social_type?.map((socialType: any) => {
          return <InfluencerSocialIcon social_media_type={socialType} />;
        })}
      </>
    );
  };

  const rendorSocialImage = (social_media_info: any) => {
    const social_key: any[] = [];
    const social_image: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (
        !social_key.includes(social_media_info[i].key) &&
        social_media_info[i].social_media_type !== 'NOSOCIAL'
      ) {
        social_key.push(social_media_info[i].key);
        social_image.push(social_media_info[i].image_profile_url);
      }
    }
    return (
      <>
        {social_image?.map((url: any) => {
          return (
            <img
              src={url}
              alt="icons"
              className="image-icon"
              style={{
                width: '30px',
                height: '30px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              onError={(e) => {
                e.currentTarget.src = userAvatar;
              }}
            />
          );
        })}
      </>
    );
  };

  const rendorSocialName = (social_media_info: any) => {
    const social_key: any[] = [];
    const social_name: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (
        !social_key.includes(social_media_info[i].key) &&
        social_media_info[i].social_media_type !== 'NOSOCIAL'
      ) {
        social_key.push(social_media_info[i].key);
        social_name.push(social_media_info[i].name);
      }
    }
    return social_name.join(',');
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ social_media_has_job }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {formatDateForm(social_media_has_job?.created_at)}
              {/* <DateTimeComponent
                dateTime={dataTimezone(post_date)}
                dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
              /> */}
            </div>
          );
        },
        id: 'created_at',
        width: 50,
      },
      {
        Header: t('table:Influencer photo'),
        accessor: ({ social_media_info }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {rendorSocialImage(social_media_info)}
            </div>
          );
        },
        id: 'img',
        disableSort: true,
        width: 80,
      },
      {
        Header: t('table:Social'),
        accessor: ({ social_media_info }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {rendorSocialMediaType(social_media_info)}
            </div>
          );
        },
        id: 'social-media',
        disableSort: true,
        width: 50,
      },
      {
        Header: t('table:Influencer name'),
        accessor: ({ social_media_info }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {rendorSocialName(social_media_info)}
            </div>
          );
        },
        id: 'social-media-name',
        disableSort: true,
        width: 120,
      },
      {
        Header: t('table:Brand Name'),
        accessor: ({ social_media_has_job }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {
                social_media_has_job?.job?.marketer_campaign?.user?.user_info
                  ?.company
              }
            </div>
          );
        },
        id: 'brand-name',
        disableSort: true,
        width: 150,
      },
      {
        Header: t('table:Job Name'),
        accessor: ({ social_media_has_job }) => {
          return (
            <div style={{ marginTop: '1rem' }}>{social_media_has_job.job.name}</div>
          );
        },
        id: 'job-name',
        disableSort: true,
        width: 150,
      },
      {
        Header: t('table:Amount'),
        // eslint-disable-next-line camelcase
        accessor: ({ social_media_has_job }) => {
          return (
            <>
              <div style={{ marginTop: '1rem' }}>
                {transformStringAmountInput(social_media_has_job?.price)}{' '}
                {social_media_has_job?.job?.currency?.main_symbol}
              </div>
            </>
          );
        },
        id: 'price',
        disableSort: true,
        width: 120,
      },
      {
        Header: t('table:Detail'),
        // eslint-disable-next-line no-unused-vars
        accessor: ({ social_media_has_job }) => {
          if (social_media_has_job.job.is_gleam_job) {
            return (
              <div style={{ marginTop: '1rem' }}>
                <a
                  href={`/JobDetail?job=${social_media_has_job?.job?.key}&task=${social_media_has_job?.group}`}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(
                      `/JobDetail?job=${social_media_has_job?.job?.key}&task=${social_media_has_job?.group}`,
                    );
                  }}
                >
                  {t(`table:detail`)}
                </a>
              </div>
            );
          } else {
            return (
              <div style={{ marginTop: '1rem' }}>
                <a
                  href={`/JobDetail?job=${social_media_has_job?.job?.key}&detail=${social_media_has_job?.key}`}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(
                      `/JobDetail?job=${social_media_has_job?.job?.key}&detail=${social_media_has_job?.key}`,
                    );
                  }}
                >
                  {t(`table:detail`)}
                </a>
              </div>
            );
          }
        },
        id: 'action',
        disableSort: true,
        width: 50,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(filterSocialGleamJob(payload));
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);

    return {
      limit: pageSize,
      page,
      status: 'FINISHED',
      search,
    };
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(filterSocialGleamJob(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [search, page, pageSize],
  );

  const hiddenOnXS: any = ['created_at', 'FIAT_amount', 'brand-name'];
  const hiddenOnSM: any = ['FIAT_amount', 'brand-name'];
  const hiddenOnMD: any = ['brand-name'];
  const hiddenOnLG: any = [];

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
        //isLoading={depositTransactionsLoading}
      >
        {/* <TransactionDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Job Detail')}
        /> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageSizeOptions
            defaultValue={10}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
          <div className="deposit-table-history-container">
            <div>
              <SearchBar
                placeholder={t('table:Search Job Name')}
                handleSearch={handleSearch}
                handleClear={handleClear}
              />
            </div>
          </div>
        </div>
      </CustomTable>
    </>
  );
};

export default withTranslation()(JobIncomeTable);
