import React, { useState } from 'react';
import CardLoading from '../Common/CardLoading';

function FiatWithdrawal() {
  const [isLoading, setIsLoading] = useState(true);

  // Function to handle iframe load event
  const handleIframeLoad = () => {
    setIsLoading(false); // Set loading state to false once iframe content is loaded
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      {isLoading && <CardLoading />}{' '}
      {/* Render CardLoading component while loading */}
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSerDKdY82Pp1e4GXjJKJKN9hXr_fUD0dysBvkg0gV53lsv3oQ/viewform?embedded=true"
        width="640"
        height="1410"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="Embedded Google Form"
        style={{ border: 'none', display: isLoading ? 'none' : 'block' }} // Hide iframe while loading
        onLoad={handleIframeLoad} // Handle iframe load event
      >
        Loading…
      </iframe>
    </div>
  );
}

export default FiatWithdrawal;
