import MarketerJobTypes from './actionTypes';
import { DescriptionJobNew, MarketerJobNewType } from './reducer';

// export const createMarketerJob = (payload: any) => ({
//   type: MarketerJobTypes.CREATE_MARKETER_JOB,
//   payload,
// });

export const createMarketerJobNew = (payload: MarketerJobNewType) => ({
  type: MarketerJobTypes.CREATE_MARKETER_JOB_NEW,
  payload,
});
export const createMarketerJobsNew = (payload: MarketerJobNewType) => ({
  type: MarketerJobTypes.CREATE_MARKETER_JOBS_NEW,
  payload,
});

export const setDescriptionJobNew = (payload: DescriptionJobNew) => ({
  type: MarketerJobTypes.SET_DESCRIPTION_JOB_NEW,
  payload,
});

export const getMarketerJob = (key: string) => ({
  type: MarketerJobTypes.GET_MARKETER_JOB,
  key,
});

// export const getMarketerJobSuccess = (payload: any) => ({
//   type: MarketerJobTypes.GET_MARKETER_JOB_SUCCESS,
//   payload,
// });

// export const updateMarketerJob = (payload: any) => ({
//   type: MarketerJobTypes.UPDATE_MARKETER_JOB,
//   payload,
// });

// export const updateMarketerJobSuccess = (payload: any) => ({
//   type: MarketerJobTypes.UPDATE_MARKETER_JOB_SUCCESS,
//   payload,
// });

// export const filterJob = (payload: any) => ({
//   type: MarketerJobTypes.FILTER_JOB,
//   payload,
// });

// export const filterJobSuccess = (payload: any) => ({
//   type: MarketerJobTypes.FILTER_JOB_SUCCESS,
//   payload,
// });

// export const clearMarketerJob = () => ({
//   type: MarketerJobTypes.CLEAR_MARKETER_JOB,
// });
