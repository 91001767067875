import Pagination from '@mui/material/Pagination';
import { useEffect, useMemo, useState } from 'react';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import useWindowSize from 'src/utils/windowSize';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import { useTranslation } from 'react-i18next';
import * as formatter from 'src/utils/convert-value';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SortByAptitude from 'src/components/Advertiser/Models/SelectInfuencer/SortByAptitude';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import addSocial from 'src/assets/images/other/add-social.svg';
import crypto from 'src/assets/images/crypto.png';
import finance from 'src/assets/images/finance-stock.png';
import ITproduct from 'src/assets/images/ITproduct.png';
import game from 'src/assets/images/game.png';
import food from 'src/assets/images/food.png';
import healthy from 'src/assets/images/healthy.png';
import beauty from 'src/assets/images/beauty.png';
import sport from 'src/assets/images/sport.png';
import lifestyle from 'src/assets/images/lifestyle.png';
import travel from 'src/assets/images/travel.png';
import pet from 'src/assets/images/pet.png';
import car from 'src/assets/images/car-motorcycle.png';
import kid from 'src/assets/images/kids-and-family.png';
import fetish from 'src/assets/images/fetish-amulet.png';
import house from 'src/assets/images/house-garden.png';
import other from 'src/assets/images/other.png';
import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import MarketerCampaignStatusButtonGroup from 'src/components/Advertiser/marketerCampaignStatusButtonGroup';
import { OnLoading } from 'src/components/Common/Loading';
import {
  CampaignStatusBackGroundColor,
  MarketerCampaignStatus,
} from 'src/components/Common/status/MarketerCampaign';
import SearchBar from 'src/components/Table/SearchBar';
import { Dialog } from '@mui/material';
import config from 'src/config';
import {
  getDefaultWebsite,
  getSocialMediaInfoGroupByAptitude,
  socialMediaFilterTable,
} from 'src/store/actions';

import { filterMarketerCampaign } from 'src/store/marketerCampaign/actions';
import './discover.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { Link } from 'react-router-dom';
import ChannelDetailPage from 'src/pages/Publisher/Influencer/ChannelDetail/ChannelDetail.component';
import { retry } from 'redux-saga/effects';
import SocialMediaType from 'src/constants/enum/socialMediaType';
import Header from 'src/components/Layout/Header';
import HomePageNavBar from 'src/components/Home/HomePageNavBar';
import HomePageNavBarDiscover from 'src/components/Home/HomePageNavBar copy';

const Discover = ({ campaignType }: any) => {
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [cardKey, setCardKey] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const isPublish = campaignType === 'PUBLISH';

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'ALL',
    marketer_campaign_type: campaignType ? 'PUBLISH' : 'INVITE_ONLY',
  });

  const { aptitude, isLoggedIn, user } = useSelector((state: any) => ({
    aptitude: state.SocialMediaInfo.discover,
    isLoggedIn: state.login.isLoggedIn,
    user: state.login.user,
  }));

  //console.log('first,aptitud', aptitude);
  // const tax = defaultTax / 100 + 1;
  // if (data && Object.keys(data)?.length) {
  //   for (let i = 0; i < data.items.length; i++) {
  //     const campaign = data.items[i];
  //     let num_influencer = 0;
  //     let num_follower = 0;
  //     let total_price = 0;
  //     for (let j = 0; j < campaign.jobs.length; j++) {
  //       const job = campaign.jobs[j];
  //       num_influencer += job.social_media_has_jobs.length || 0;
  //       for (let k = 0; k < job.social_media_has_jobs.length; k++) {
  //         const socialJob = job.social_media_has_jobs[k];
  //         num_follower += socialJob.social_media_info.follower || 0;
  //         total_price += socialJob.price || 0;
  //       }
  //     }

  //     data.items[i].num_influencer = num_influencer;
  //     data.items[i].num_follower = num_follower;
  //     data.items[i].total_price = total_price * tax;
  //   }
  // }

  const handleClose = () => {
    setIsOpenProfile(false);
  };

  // const handlePageSizeChange = (newPageSize: string) => {
  //   const payload = {
  //     ...param,
  //     limit: +newPageSize,
  //     page: 1,
  //   };
  //   dispatch(filterMarketerCampaign(payload));
  //   setParam(payload);
  // };

  useEffect(() => {
    dispatch(getSocialMediaInfoGroupByAptitude());
    // dispatch(getDefaultWebsite());
    // dispatch(filterMarketerCampaign(param, campaignType));
  }, []);

  // useMemo(() => {
  //   if (!data || !Object.keys(data).length) return;
  //   const { totalCount, isPublish: dataIsPublish } = data;

  //   if (campaignType === 'PUBLISH' ? dataIsPublish : !dataIsPublish) {
  //     if (!totalCount) {
  //       const path =
  //         campaignType === 'PUBLISH'
  //           ? '/marketer/Create/PublicCampaign'
  //           : '/marketer/create-campaign';
  //       history.push(path);
  //     }
  //   }
  // }, [data]);
  if (!Object.keys(aptitude)?.length) return <OnLoading />;
  const getaptitudelogo = (id: any) => {
    //console.log('if', typeof id);
    switch (id) {
      case 1:
        return crypto;
      case 2:
        return finance;
      case 3:
        return ITproduct;
      case 4:
        return game;
      case 5:
        return food;
      case 6:
        return healthy;
      case 7:
        return beauty;
      case 8:
        return sport;
      case 9:
        return lifestyle;
      case 10:
        return travel;
      case 11:
        return pet;
      case 12:
        return car;
      case 13:
        return kid;
      case 14:
        return fetish;
      case 15:
        return house;
      case 16:
        return other;
      default:
        return userAvatar;
    }
  };
  const onGetLogoSocialByType = (type: SocialMediaType): string => {
    switch (type) {
      case SocialMediaType.FACEBOOK:
        return facebookLogo;
      case SocialMediaType.INSTAGRAM:
        return instagramLogo;
      case SocialMediaType.TWITTER:
        return twitterLogo;
      case SocialMediaType.YOUTUBE:
        return youtubeLogo;
      case SocialMediaType.TIKTOK:
        return tikTokLogo;
      default:
        return facebookLogo;
    }
  };
  const isMobile: any = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const renderCard = (items: any) => {
    let numberOfCards = 3;
    if (width > 250) numberOfCards = 3;
    if (width > 528) numberOfCards = 5;
    if (width > 556) numberOfCards = 3;
    if (width > 736) numberOfCards = 5;
    if (width > 963) numberOfCards = 7;
    if (width > 991) numberOfCards = 5;
    if (width > 1124) numberOfCards = 7;
    if (width > 1351) numberOfCards = 9;
    return (
      <>
        {items?.socialMediaInfo?.slice(0, numberOfCards).map((social: any) => {
          return (
            <>
              <span className="card-group mt-3">
                <div
                  className="add-social-card background-img-short "
                  style={{
                    display: 'flex',
                    position: 'relative',
                    cursor: `${isLoggedIn ? 'pointer' : 'default'}`,
                    borderRadius: '8%',
                    width: '150px',
                  }}
                  onClick={(e) => {
                    if (isLoggedIn) {
                      e.preventDefault();
                      setCardKey(social.key);
                      setIsOpenProfile(true);
                    }
                  }}
                >
                  <img
                    src={onGetLogoSocialByType(social?.social_media_type)}
                    alt="Social Logo"
                    className="logo-socials"
                    onLoad={(e) => {
                      e.currentTarget.classList.remove('skeleton');
                    }}
                    onError={(e) => {
                      e.currentTarget.classList.remove('skeleton');
                      e.currentTarget.src = userAvatar;
                    }}
                    width={20}
                    height={20}
                  />
                  <img
                    className="image-aptitude"
                    style={{ width: '60px', height: '60px' }}
                    src={social.image_profile_url || userAvatar}
                    alt="profile"
                    onError={(e) => {
                      e.currentTarget.classList.remove('skeleton');
                      e.currentTarget.src = userAvatar;
                    }}
                  />
                  <span className="image-title" style={{ height: '20px' }}>
                    {social.name}
                  </span>
                  <span className="image-title" style={{ marginTop: '5px' }}>
                    {formatter.nFormatter(social?.follower, 1)}
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'var(--bs-gray-600)',
                    }}
                  >
                    {t('Followers')}
                  </span>
                </div>
              </span>
            </>
          );
        })}
      </>
    );
  };
  return (
    <div
      style={{
        paddingTop: user.role === 'ADVERTISER' ? '6.3vh' : '',
        paddingLeft: user.role === 'ADVERTISER' ? '5.4vh' : '',
      }}
    >
      {isLoggedIn ? <></> : <HomePageNavBarDiscover />}
      {user.role === 'ADVERTISER' && (
        <Breadcrumb
          pageTitle={t('Discover')}
          menus={[
            { menu: menu.HOME, active: true },
            {
              menu: menu.DISCOVER,
              isSamePage: true,
            },
          ]}
        />
      )}
      <div className={` ${user.role === 'ADVERTISER' ? '' : 'page-content'} h-100`}>
        <MetaTags>
          <title>
            {t('page:Campaign')} | {config.appName}
          </title>
        </MetaTags>
        <Container fluid>
          <div className="marketer-campaign-container-discover">
            {user.role === 'PUBLISHER' && (
              <Breadcrumb
                pageTitle={t('Discover')}
                menus={[
                  { menu: menu.HOME, active: true },
                  {
                    menu: menu.DISCOVER,
                    isSamePage: true,
                  },
                ]}
              />
            )}
            {/* <div className="header-campaign-container">
            <MarketerCampaignStatusButtonGroup
              statusCount={data?.statusCount}
              param={param}
              setParam={setParam}
              filterFunction={filterMarketerCampaign}
            />
            <div className="search-add-campaign-container">
              <SearchBar
                placeholder={t('table:Search name or brand name')}
                handleSearch={handleSearch}
                handleClear={handleClear}
                disableClear
              />
              <button
                type="button"
                className="add-campaign-button"
                onClick={() => {
                  if (isPublish) {
                    history.push(`/marketer/Create/PublicCampaign`);
                  } else {
                    history.push(`/marketer/create-campaign`);
                  }
                }}
              >
                <div style={{ fontSize: '14px' }}>{t('button:Add campaign')}</div>
              </button>
            </div>
          </div> */}
            <>
              {aptitude != null ? (
                aptitude.map((items: any) => {
                  return (
                    <div>
                      <div className="expand">
                        <span>
                          <img
                            src={getaptitudelogo(items.aptitude.id)}
                            alt="icon"
                            width={50}
                            height={50}
                            style={{ borderRadius: '50%', marginRight: '10px' }}
                          />
                          <span
                            onClick={() => {
                              const params = {
                                aptitude_ids: [items.aptitude.id],
                                limit: 10,
                                page: 1,
                              };
                              history.push({
                                pathname: '/DiscoverAll',
                                state: params,
                              });
                            }}
                            className="aptitude-title"
                          >
                            {items.aptitude.name}
                          </span>
                        </span>
                        <span>
                          <i
                            onClick={() => {
                              const params = {
                                aptitude_ids: [items.aptitude.id],
                                limit: 10,
                                page: 1,
                              };
                              history.push({
                                pathname: '/DiscoverAll',
                                state: params,
                              });
                            }}
                            className="fas fa-chevron-right icon"
                          />
                        </span>
                      </div>
                      <div className="card-group mb-5">
                        {renderCard(items)}
                        {/* {items?.socialMediaInfo?.slice(0, 4).map((social: any) => {
                        return (
                          <>
                            <span className="card-group mt-3">
                              <div
                                className="add-social-card background-img-short "
                                style={{
                                  display: 'flex',
                                  position: 'relative',
                                  cursor: 'pointer',
                                  borderRadius: '8%',
                                  width: '150px',
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCardKey(social.key);
                                  setIsOpenProfile(true);
                                }}
                              >
                                <img
                                  src={onGetLogoSocialByType(
                                    social?.social_media_type,
                                  )}
                                  alt="Social Logo"
                                  className="logo-socials"
                                  onLoad={(e) => {
                                    e.currentTarget.classList.remove('skeleton');
                                  }}
                                  onError={(e) => {
                                    e.currentTarget.classList.remove('skeleton');
                                    e.currentTarget.src = userAvatar;
                                  }}
                                  width={20}
                                  height={20}
                                />
                                <img
                                  className="image-aptitude"
                                  style={{ width: '60px', height: '60px' }}
                                  src={social.image_profile_url || userAvatar}
                                  alt="profile"
                                  onError={(e) => {
                                    e.currentTarget.classList.remove('skeleton');
                                    e.currentTarget.src = userAvatar;
                                  }}
                                />
                                <span className="image-title">{social.name}</span>
                                <span
                                  className="image-title"
                                  style={{ marginTop: '10px' }}
                                >
                                  {formatter.nFormatter(social?.follower, 1)}
                                </span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    color: 'var(--bs-gray-600)',
                                  }}
                                >
                                  {t('Followers')}
                                </span>
                              </div>
                            </span>
                          </>
                        );
                      })} */}
                        <>
                          <span className="card-group mt-3">
                            <div
                              className="add-social-card background-img-short "
                              style={{
                                display: 'flex',
                                position: 'relative',
                                cursor: 'pointer',
                                borderRadius: '8%',
                                width: '150px',
                              }}
                              onClick={() => {
                                const params = {
                                  aptitude_ids: [items.aptitude.id],
                                  limit: 10,
                                  page: 1,
                                };
                                history.push({
                                  pathname: '/DiscoverAll',
                                  state: params,
                                });
                              }}
                            >
                              <span style={{ color: 'white' }}>{t('See More')}</span>
                            </div>
                          </span>
                        </>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}

              {/* <Pagination
              count={Math.ceil(data?.totalCount / param.limit)}
              page={param.page}
              color="standard"
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
            /> */}
            </>
          </div>
        </Container>
        {isLoggedIn ? (
          <Dialog
            maxWidth="lg"
            onClose={handleClose}
            open={isOpenProfile}
            PaperProps={{
              style: { position: 'relative', margin: '0 5px' },
            }}
            style={{ zIndex: 1050 }}
          >
            <span
              style={{
                position: 'absolute',
                right: '10px',
                top: '5px',
                fontSize: '25px',
                fontWeight: 'bold',
                cursor: 'pointer',
                zIndex: '1100',
              }}
              onClick={handleClose}
            >
              X
            </span>
            <ChannelDetailPage influencerKey={cardKey} />
          </Dialog>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Discover;
