import SuggestJob from './actionTypes';

interface SuggestJobState {
  suggestJobs: {};
}

export const INIT_STATE: SuggestJobState = {
  suggestJobs: {},
};

const SuggestJobs = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SuggestJob.GET_ALL_SUGGESS_JOB_SUCCESS:
      return {
        ...state,
        suggestJobs: action.payload,
      };
    case SuggestJob.GET_ALL_SUGGESS_JOB_FAIL:
      return {
        ...state,
        suggestJobs: action.payload,
      };
    default:
      return { ...state, data: action.payload };
  }
};

export default SuggestJobs;
