import { FormGroup } from 'reactstrap';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import './SelectCustom.style.scss';

const CurrencyOptions = ({ t, handleCurrencyChange }: any) => {
  const { currencies, currenciesLoading } = useSelector((state: any) => ({
    currencies: state.Currencies.currencies,
    currenciesLoading: state.Global.currenciesLoading,
  }));

  const [symbolOption, setSymbolOption] = useState('');

  const handleChange = (selectedOption: any) => {
    setSymbolOption(selectedOption);
    handleCurrencyChange(selectedOption.value);
  };

  const renderCurrenciesOption = () => {
    const options = [{ label: t('table:Select Currency'), value: '' }];

    if (!isEmpty(currencies)) {
      currencies.map(({ symbol }: any) => {
        options.push({
          label: splitUnderscoreWithTuple(symbol),
          value: symbol,
        });

        return symbol;
      });
    }

    return options;
  };

  return (
    <FormGroup className="flex-grow-1 flex-md-grow-0">
      <Select
        options={renderCurrenciesOption()}
        onChange={handleChange}
        defaultValue={symbolOption}
        placeholder={currenciesLoading ? t('Select loading') : t('Select')}
        disabled={currenciesLoading}
        className="wallet-history"
      />
    </FormGroup>
  );
};

export default withTranslation()(CurrencyOptions);
