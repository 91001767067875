import { gql } from '@apollo/client';
import { client } from '../client';

const creativeTypeGraphql = async () => {
  const query = gql`
    query creativeTypes {
      creativeTypes {
        id
        key
        name
      }
    }
  `;

  const result: any = await client.query({
    query,
  });

  return result.data.creativeTypes;
};
export default creativeTypeGraphql;
