/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum MarketerCampaignTypes {
  CREATE_MARKETER_CAMPAIGN = '@@marketer_campaign/CREATE_MARKETER_CAMPAIGN',
  CREATE_MARKETER_CAMPAIGN_SUCCESS = '@@marketer_campaign/CREATE_MARKETER_CAMPAIGN_SUCCESS',

  GET_MARKETER_CAMPAIGN = '@@marketer_campaign/GET_MARKETER_CAMPAIGN',
  GET_MARKETER_CAMPAIGN_SUCCESS = '@@marketer_campaign/GET_MARKETER_CAMPAIGN_SUCCESS',

  UPDATE_MARKETER_CAMPAIGN = '@@marketer_campaign/UPDATE_MARKETER_CAMPAIGN',
  UPDATE_MARKETER_CAMPAIGN_SUCCESS = '@@marketer_campaign/UPDATE_MARKETER_CAMPAIGN_SUCCESS',

  FILTER_MARKETER_CAMPAIGN = '@@marker_campaign/FILTER_MARKETER_CAMPAIGN',
  FILTER_MARKETER_CAMPAIGN_SUCCESS = '@@marker_campaign/FILTER_MARKETER_CAMPAIGN_SUCCESS',
}

export default MarketerCampaignTypes;
