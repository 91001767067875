/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum ReferralTypes {
  GET_REFERRAL_EMAIL = '@@referral/GET_REFERRAL_EMAIL',
  GET_REFERRAL_EMAIL_SUCCESS = '@@referral/GET_REFERRAL_EMAIL_SUCCESS',

  GET_REFERRAL_TRANSACTIONS = '@@referral/GET_REFERRAL_TRANSACTIONS',
  GET_REFERRAL_TRANSACTIONS_SUCCESS = '@@referral/GET_REFERRAL_TRANSACTIONS_SUCCESS',

  GET_REFERRAL_SYSTEM_TAX = '@@referral/GET_REFERRAL_SYSTEM_TAX',
  GET_REFERRAL_SYSTEM_TAX_SUCCESS = '@@referral/GET_REFERRAL_SYSTEM_TAX_SUCCESS',

  GET_REFERRAL_FAIL = '@@referral/GET_REFERRAL_FAIL',
}
