/* eslint-disable react/button-has-type */
import './CustomButton.style.scss';

interface AllButtonType {
  size: string;
  label: string;
  type?: 'submit' | 'reset' | 'button';
  status: string;
  handleClick?: any;
  isDisable?: boolean;
}

const AllButton = ({
  size,
  label,
  type,
  status,
  handleClick,
  isDisable,
}: AllButtonType) => (
  <div className="custom-btn">
    <button
      type={type}
      className={`btn-custom-5 btn-custom-${status}-${size}`}
      onClick={handleClick}
      disabled={isDisable}
    >
      <span>{label}</span>
    </button>
  </div>
);

AllButton.defaultProps = {
  type: 'submit',
  handleClick: null,
  isDisable: false,
};

export default AllButton;
