import CalculateSocialMediaTypes from './actionTypes';
import { SocialMediaType } from '../../components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';

// eslint-disable-next-line no-shadow
export enum ContentActive {
  // eslint-disable-next-line no-unused-vars
  UN_ACTIVE,
  // eslint-disable-next-line no-unused-vars
  ACTIVE,
}
export interface UserSocialProfile {
  id: string;
  type?: SocialMediaType | null;
  social_media_type?: SocialMediaType | null;
  name: string;
  fan_count: number;
  picture_url?: string;
  access_token: string;
  access_token_secret?: string;
  post_engagement?: number;
  job_score?: number;
  aptitude_ids?: number[];
  like?: number;
  profile_path?: string;
  rating?: number;
  share_content_price?: ContentActive;
  photo_content_price?: ContentActive;
  video_content_price?: ContentActive;
  introduce?: string;
  aptitudes?: string[];
  keyword_aptitudes?: string[];
  is_enable_share_price?: boolean;
  is_enable_photo_price?: boolean;
  is_enable_video_price?: boolean;
  key?: string;
  keyword_aptitude_ids?: number[];
  email?: string;
  expire_access_token?: string | null | Date;
}

interface CalculateSocialMediaModel {
  userProfileList: UserSocialProfile[];
  userProfileSelected: UserSocialProfile | null;
  userProfileIsLoading: boolean;
  setFacebookError: boolean;
}

export const INIT_STATE: CalculateSocialMediaModel = {
  userProfileList: [],
  userProfileIsLoading: true,
  userProfileSelected: null,
  setFacebookError: false,
};

const SocialMediaInfo = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CalculateSocialMediaTypes.SET_USER_PROFILE_LIST:
      return {
        ...state,
        userProfileList: action.payload,
        userProfileIsLoading: false,
      };
    case CalculateSocialMediaTypes.SET_USER_PROFILE_SELECTED:
      return {
        ...state,
        userProfileSelected: action.payload,
      };
    case CalculateSocialMediaTypes.SET_FACEBOOK_ERROR:
      return {
        ...state,
        setFacebookError: action.payload,
      };
    case CalculateSocialMediaTypes.SET_USER_PROFILE_STATUS:
      return {
        ...state,
        userProfileStatus: action.payload,
      };
    default:
      return state;
  }
};

export default SocialMediaInfo;
