import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import i18n from '../../i18n';

const campaignsStatus = [
  {
    title: i18n.t('All'),
    value: 'ALL',
  },
  {
    title: i18n.t('enumCampaign:Draft'),
    value: 'DRAFT',
  },
  {
    title: i18n.t('enumCampaign:In progress'),
    value: 'INPROGRESS',
  },
  {
    title: i18n.t('enumCampaign:Finished'),
    value: 'FINISHED',
  },
  {
    title: i18n.t('enumCampaign:Cancel'),
    value: 'CANCEL',
  },
];

const MarketerCampaignStatusButtonGroup = ({
  statusCount,
  param,
  setParam,
  filterFunction,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [title, setTitle] = useState(t('button:Filter'));
  const toggle = () => setDropdownOpen((prevState: any) => !prevState);
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          caret
          style={{
            width: '120px',
            height: '34px',
            background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
            borderRadius: '8px',
            border: 'none',
            color: 'white',
          }}
        >
          <i className="fa fa-filter" style={{ marginRight: '10px' }} />
          {t(`button:${title}`)}
        </DropdownToggle>
        <DropdownMenu>
          {campaignsStatus.map((item: any) => {
            let count = 0;
            if (statusCount) {
              for (let i = 0; i < statusCount?.length; i++) {
                const statusCountData = statusCount[i];

                if (item.value === statusCountData.status) {
                  count = statusCountData.count;
                  break;
                }
              }
            }

            return (
              <DropdownItem
                className={`btn outline-sm m-1 ${
                  param.status === item.value && 'active'
                }`}
                onClick={() => {
                  const payload = {
                    ...param,
                    page: 1,
                    status: item.value,
                  };
                  dispatch(filterFunction(payload));
                  setParam(payload);
                  setTitle(item.title);
                }}
                key={item.value}
              >
                {item.title} {count}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default MarketerCampaignStatusButtonGroup;
