import AptitudeTypes from './actionTypes';

export const createAptitude = (name: string) => ({
  type: AptitudeTypes.CREATE_APTITUDE,
  name,
});

export const getAptitude = () => ({
  type: AptitudeTypes.GET_APTITUDE,
});

export const getAptitudeSuccess = (payload: any) => ({
  type: AptitudeTypes.GET_APTITUDE_SUCCESS,
  payload,
});
