import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';

export const getAccessMetaToken = async (
  code: string,
  type: SocialMediaType,
  rURL: string | null = null,
) => {
  const url: string = `${
    config.backendUrl
  }social/meta/token?code=${code}&type=${type}${rURL ? `&url=${rURL}` : ''}`;

  const response: any = await fetch(url, {
    method: 'GET',
    redirect: 'follow',
  });

  return JSON.parse(await response.text());
};

export const getAccessMetaPersonalToken = async (
  code: string,
  type: SocialMediaType,
  rURL: string | null = null,
) => {
  const url: string = `${
    config.backendUrl
  }social/meta-personal/token?code=${code}&type=${type}${
    rURL ? `&url=${rURL}` : ''
  }`;

  const response: any = await fetch(url, {
    method: 'GET',
    redirect: 'follow',
  });

  return JSON.parse(await response.text());
};
