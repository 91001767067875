import { Col, Row, Card, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { layoutTheme } from 'src/constants/layout';
import InPageImg from '../Img/In-page.png';
import StickyImg from '../Img/Sticky.png';
import HeaderImg from '../Img/Header.png';
import SlideImg from '../Img/Slide.png';
import InPageImgDark from '../Img/In-page-dark.png';
import StickyImgDark from '../Img/Sticky-dark.png';
import HeaderImgDark from '../Img/Header-dark.png';
import SlideImgDark from '../Img/Slide-dark.png';

const SelectDevice = (props: any) => {
  const {
    device_platform,
    setDevicePlatform,
    banner_format,
    setBannerFormat,
    layoutMode,
    ad_format,
    dispatchDefaultCampaign,
    t,
  } = props;

  const bannerFormats = [
    {
      lightImage: InPageImg,
      darkImage: InPageImgDark,
      title: t('creative:In-Page'),
      desc: t('campaign:Small ads that surround website.'),
      format: 'IN_PAGE',
    },
    {
      lightImage: StickyImg,
      darkImage: StickyImgDark,
      title: t('creative:Sticky'),
      desc: t('campaign:Long ads that will stick anywhere on the screen.'),
      format: 'STICKY',
    },
    {
      lightImage: HeaderImg,
      darkImage: HeaderImgDark,
      title: t('creative:Premium Header'),
      desc: t('campaign:A large ad that sits at the top of the website.'),
      format: 'HEADER',
    },
    {
      lightImage: SlideImg,
      darkImage: SlideImgDark,
      title: t('creative:Slide'),
      desc: t('campaign:Small ads that will stick anywhere on the screen.'),
      format: 'SLIDE',
    },
  ];

  // const renderCardLg = () => {
  //   let menuWidth = 0;

  //   if (document.getElementsByClassName('vertical-menu').length) {
  //     menuWidth = document.getElementsByClassName('vertical-menu')[0].clientWidth;
  //   }

  //   if (width - menuWidth < 1250) {
  //     return 3;
  //   }

  //   return 2;
  // };

  const renderBannerFormat = () =>
    bannerFormats.map((banner: any, index: number) =>
      device_platform === 'MOBILE' && index === 2 ? null : (
        <Col
          sm={6}
          md={3}
          lg={3}
          className="mb-4 mb-md-0"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Card
            className={`main-card align-items-center banner-format-card ${
              banner_format === banner.format && 'card-hover'
            }`}
            onClick={() => {
              setBannerFormat(banner.format);
              dispatchDefaultCampaign({
                banner_format: banner.format,
              });
            }}
          >
            <div className="img-container mb-auto">
              <img
                src={
                  layoutMode === layoutTheme.DARKMODE
                    ? banner.darkImage
                    : banner.lightImage
                }
                alt="InPageImg"
              />
            </div>
            <div className="desc-container">
              <p className="font-size-18 fw-bold">{banner.title}</p>
              <p className="font-size-16 fw-normal mb-0">{banner.desc}</p>
            </div>
          </Card>
        </Col>
      ),
    );

  return (
    <div className="font-size-20 fw-bold">
      <Label className="input-label fs-5">
        {t('campaign:Select Device')}
        <span className="input-required">*</span>
      </Label>

      <div className="">
        <button
          type="button"
          className={`btn outline-sm me-2 ${
            device_platform === 'DESKTOP' ? 'active' : ''
          }`}
          onClick={() => {
            setDevicePlatform('DESKTOP');
            dispatchDefaultCampaign({ device_platform: 'DESKTOP' });
          }}
        >
          {t('campaign:Desktop')}
        </button>
        <button
          type="button"
          className={`btn outline-sm ${
            device_platform === 'MOBILE' ? 'active' : ''
          }`}
          onClick={() => {
            setDevicePlatform('MOBILE');
            dispatchDefaultCampaign({ device_platform: 'MOBILE' });
          }}
        >
          {t('campaign:Application')}
        </button>
      </div>

      {ad_format === 'BANNER' && (
        <div className="mt-4">
          <Label className="fs-5 input-label">
            {t('creative:Banner format')}
            <span className="input-required">*</span>
          </Label>
          <Row className="banner-type">{renderBannerFormat()}</Row>
        </div>
      )}
    </div>
  );
};
export default withTranslation()(SelectDevice);
