import { takeEvery, fork, put, all, call, delay } from 'redux-saga/effects';

// Login Redux States
import { isEmpty } from 'lodash';
import ChangePasswordTypes from './actionTypes';
import { changePasswordFailed, changePasswordConfirmFailed } from './actions';
import {
  changePasswordRequest as changePasswordRequestGql,
  changePasswordConfirm as changePasswordConfirmGql,
} from '../../../graphql/common/auth';
import { setLoading, unsetLoading } from '../../global/actions';
import { Response } from '../../../common/types';
import { getErrorMessageByErrorCode } from '../../../utils/error';
import notify from '../../../utils/notify';
import i18n from '../../../i18n';

function* changePasswordRequest({ payload: { data } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(changePasswordRequestGql, data);

    yield delay(800);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        changePasswordFailed(
          getErrorMessageByErrorCode(response.errors[0].error_code),
        ),
      );
    } else {
      notify(i18n.t('success:Change Password Request Success'), 'success');
    }
  } catch (error) {
    yield put(changePasswordFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* changePasswordConfirm({ payload: { data } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(changePasswordConfirmGql, data);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        changePasswordConfirmFailed(
          getErrorMessageByErrorCode(response.errors[0].error_code),
        ),
      );
    } else {
      if (data.type === 'approve') {
        notify(i18n.t('success:Change Password Approve Success'), 'success');
      } else {
        notify(i18n.t('success:Change Password Reject Success'), 'success');
      }
    }
  } catch (error) {
    yield put(changePasswordConfirmFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(
    ChangePasswordTypes.CHANGE_PASSWORD_REQUEST,
    changePasswordRequest,
  );
  yield takeEvery(
    ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM,
    changePasswordConfirm,
  );
}

function* changePasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default changePasswordSaga;
