import React from 'react';
import { ReactFlagsSelectCountry } from 'src/utils/country';
import mockFormData from './mockFormData';
import './mockJobRequirement.style.scss';

const options = ['food', 'social', 'beauty', 'travel', 'health', 'parenting'];

const Form: React.FC = () => {
  const { formData, handleChange, handleSubmit, useRadio } = mockFormData();

  const { onChangeRadio } = useRadio();

  return (
    <div className="mock-job-requirement page-content">
      <form
        className="mock-job-requirement-card"
        onSubmit={async (e: any) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="minPrice" className="label">
          Min Price:
          <input
            type="number"
            name="minPrice"
            id="minPrice"
            value={formData.minPrice}
            onChange={handleChange}
          />
        </label>
        <br />
        <label htmlFor="maxPrice">
          Max Price:
          <input
            type="number"
            name="maxPrice"
            id="maxPrice"
            value={formData.maxPrice}
            onChange={handleChange}
          />
        </label>
        <br />

        <label htmlFor="country">
          country Range:
          <select
            name="country"
            id="country"
            value={formData.country}
            onChange={(e: any) => {
              handleChange(e);
            }}
          >
            <option value="">Select an county</option>
            {Object.keys(ReactFlagsSelectCountry).map((shotCountry) => {
              const newCountry: any = { ...ReactFlagsSelectCountry };
              return (
                <option
                  key={newCountry[shotCountry]}
                  value={newCountry[shotCountry]}
                >
                  {newCountry[shotCountry]}
                </option>
              );
            })}
          </select>
        </label>

        <label htmlFor="ageRange">
          Age Range:
          <select
            name="ageRange"
            id="ageRange"
            value={formData.ageRange}
            onChange={(e: any) => {
              handleChange(e);
            }}
          >
            <option value="">Select an age range</option>
            <option value="18-24">18-24</option>
            <option value="25-34">25-34</option>
            <option value="35-44">35-44</option>
            <option value="45-54">45-54</option>
            <option value="55-64">55-64</option>
            <option value="65+">65+</option>
          </select>
        </label>
        <br />
        <label htmlFor="sex">
          Male
          <input
            type="checkbox"
            name="isMale"
            id="isMale"
            checked={formData.isMale}
            onChange={handleChange}
          />
          Female
          <input
            type="checkbox"
            name="isFeMale"
            id="isFeMale"
            checked={formData.isFeMale}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="lgbt">
          LGBT:
          <input
            type="checkbox"
            name="lgbt"
            id="lgbt"
            checked={formData.lgbt}
            onChange={handleChange}
          />
        </label>
        <br />

        <div className="radio-group">
          <div className="radio-column">
            {options.slice(0, 2).map((option) => {
              return (
                <label key={option} htmlFor="radio-option" className="radio-option">
                  <input
                    type="radio"
                    value={options.indexOf(option)}
                    name={option}
                    checked={formData?.aptitude?.includes(options.indexOf(option))}
                    onClick={(e: any) => {
                      onChangeRadio(e);
                    }}
                  />
                  {`${options.indexOf(option) + 1}.) `}
                  {option}
                </label>
              );
            })}
          </div>
          <div className="radio-column">
            {options.slice(2, 4).map((option) => (
              <label key={option} htmlFor="radio-option" className="radio-option">
                <input
                  type="radio"
                  value={options.indexOf(option)}
                  name={option}
                  checked={formData?.aptitude?.includes(options.indexOf(option))}
                  onClick={(e: any) => {
                    onChangeRadio(e);
                  }}
                />
                {`${options.indexOf(option) + 1}.) `}
                {option}
              </label>
            ))}
          </div>
          <div className="radio-column">
            {options.slice(4, 6).map((option) => (
              <label key={option} htmlFor="radio-option" className="radio-option">
                <input
                  type="radio"
                  value={options.indexOf(option)}
                  name={option}
                  checked={formData?.aptitude?.includes(options.indexOf(option))}
                  onClick={(e: any) => {
                    onChangeRadio(e);
                  }}
                />
                {`${options.indexOf(option) + 1}.) `}
                {option}
              </label>
            ))}
          </div>
        </div>

        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Form;
