import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { updateSocialMediaInfo } from 'src/graphql/advertiser/socialMediaInfo';
import { getOneSocialMediaInfo } from 'src/store/actions';
import * as Yup from 'yup';

const IntroduceControl = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const key = new URLSearchParams(search).get('channel');
  const dispatch = useDispatch();
  const { userProfile$, userProfileSelected$ } = useSelector((state: any) => {
    return {
      userProfile$: state.SocialMediaInfo.socialMediaInfoData,
      userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
    };
  });
  const initialStateForm: { introduce: string } = {
    introduce: '',
  };
  const [introduce, setIntroduce] = useState<{ introduce: string }>(
    initialStateForm,
  );
  const validationSchema = Yup.object({
    introduce: Yup.string()
      .required(t('influencer:channel_detail.required'))
      .max(500, t('influencer:channel_detail.introduce_max_length')),
  });
  const [editState, setEditState] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    setIntroduce({ introduce: userProfile$?.introduce ?? '' });
  }, [userProfile$]);

  // Functions
  const onSubmit = async (introduceData: string) => {
    await updateSocialMediaInfo({ key, introduce: introduceData });
    dispatch(getOneSocialMediaInfo(key || ''));
    setEditState(false);
  };

  const onCancel = () => {
    setIntroduce({ introduce: userProfile$?.introduce ?? '' });
    setEditState(false);
  };

  return {
    introduce,
    setIntroduce,
    editState,
    setEditState,
    validationSchema,
    onSubmit,
    onCancel,
    userProfileSelected$,
  };
};

export default IntroduceControl;
