import i18n from '../i18n';

// eslint-disable-next-line import/prefer-default-export
export function getErrorMessageByErrorCode(errorCode) {
  const jobIdPattern = /^\/job\/([a-zA-Z0-9_-]+)$/;
  if (jobIdPattern.test(window.location.pathname)) {
    const jobId = window.location.pathname.match(jobIdPattern)[1];
    return null;
  } else {
    const errorKey = `Error.${errorCode}`;
    return i18n.exists(errorKey) ? i18n.t(errorKey) : i18n.t('Error.0000');
  }
}
