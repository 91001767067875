import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSocialMediaInfo } from 'src/store/socialMediaInfo/actions';
import addSocial from 'src/assets/images/flupower/add-btn.png';
import playSocial from 'src/assets/images/flupower/play-btn.png';
import SocialCardComponent from './SocialCard/SocialCard.component';
import control, { SocialCard } from './SocialChannel.control';
import './SocialChannel.style.scss';

const SocialChannelComponent = ({ t }: any) => {
  const { socialCards, setSocialCards } = control();
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state: any) => state.SocialMediaInfo.socialMediaInfo);
  useEffect(() => {
    dispatch(getSocialMediaInfo());
  }, []);

  useEffect(() => {
    if (data?.length) {
      setSocialCards(data);
    }
  }, [data]);

  const openYouTubeVideo = () => {
    const youtubeVideoUrl = 'https://www.youtube.com/watch?v=MkuDU5xwxRk';
    window.open(youtubeVideoUrl, '_blank');
  };

  return (
    <>
      <p className="header" style={{ fontSize: '24px', color: 'white' }}>
        {t('influencer:dashboard.social_channel')}
      </p>
      <div
        className="social-group"
        style={
          (socialCards && socialCards?.length <= 0) || !socialCards
            ? { justifyContent: 'center' }
            : {}
        }
      >
        {/* <div
          className="add-social-card background-img-short"
          style={{
            display: 'flex',
            position: 'relative',
            cursor: 'pointer',
            borderRadius: '8%',
            width: '165px',
            justifyContent: 'flex-start',
          }}
          onClick={openYouTubeVideo}
        >
          <div className="dulation">
            <p className="text-add-social text-black">
              {' '}
              {t('How to connect social media')}{' '}
            </p>
            <img
              src={playSocial}
              alt="addsocial"
              width={75}
              className="play-add-social"
            />
          </div>
        </div> */}
        <div
          className="add-social-card background-img-short"
          style={{
            display: 'flex',
            position: 'relative',
            cursor: 'pointer',
            borderRadius: '8%',
            width: '165px',
            justifyContent: 'flex-start',
          }}
          onClick={(e) => {
            e.preventDefault();
            history.push('/influencer/create-channel-details');
          }}
        >
          <p className="text-add-social text-black">
            {t('Connect your social media')}
          </p>
          <img
            src={addSocial}
            alt="addsocial"
            width={75}
            className="play-add-social"
          />
        </div>

        {/* <Link to="/influencer/channel-add">
          <Card className="add-social-card ">
            <i className="fas fa-plus-circle icon" />
            <p>{t('influencer:dashboard.Add social channel')}</p>
          </Card>
        </Link> */}

        {socialCards &&
          socialCards.map((card: SocialCard) => (
            <SocialCardComponent key={card.key} card={card} />
          ))}
      </div>
    </>
  );
};

export default withTranslation()(SocialChannelComponent);
