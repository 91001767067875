import React from 'react';

import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MadeInHeavenTable from 'src/components/Admin/MadeInHeavenTable';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import menu from '../../constants/menu';

const MadeInHeavenPage = ({ t }: any) => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Form Validation | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.Speed Up Job')}
            menus={[{ menu: menu.DASHBOARD }, { menu: menu.SPEED_UP, active: true }]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <MadeInHeavenTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(MadeInHeavenPage);
