import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Nav, Row, CardHeader, NavItem, NavLink } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { getCreativeSizes as onGetCreativeSizes } from '../../store/actions';
import SetCreativeSize from '../Advertiser/settingSizeCreative/SetCreativeSize';

const EditCreativeSize = (props: any) => {
  const { t } = props;
  const dispatch = useDispatch();
  const [device_platform, setDevicePlatform] = useState<string>('DESKTOP');

  const { creativeSizes } = useSelector((state: any) => ({
    creativeSizes: state.Creatives.allCreativeSizes,
  }));

  useEffect(() => {
    dispatch(onGetCreativeSizes({ device_platform: 'DESKTOP' }));
  }, []);

  const creativeSizesForm = [
    {
      data: creativeSizes.bannerInPage,
      device_platform,
      creativeType: 'BANNER_IN_PAGE',
      title: 'Require In-page',
      type: 'BOTH',
    },
    {
      data: creativeSizes.bannerSticky,
      device_platform,
      creativeType: 'BANNER_STICKY',
      title: 'Require Sticky',
      type: 'BOTH',
    },
    {
      data: creativeSizes.bannerHeader,
      device_platform,
      creativeType: 'BANNER_HEADER',
      title: 'Require Header',
      type: 'BOTH',
    },
    {
      data: creativeSizes.bannerSlide,
      device_platform,
      creativeType: 'BANNER_SLIDE',
      title: 'Require Slide',
      type: 'BOTH',
    },
    {
      data: creativeSizes.nativeAdvertiser,
      device_platform,
      creativeType: 'NATIVE_ADVERTISER',
      title: 'Recommend Native Advertiser',
      type: 'BOTH',
    },
    {
      data: creativeSizes.nativePublisher,
      device_platform,
      creativeType: 'NATIVE_PUBLISHER',
      title: 'Recommend Native Publisher',
      type: 'BOTH',
    },
  ];

  const renderCreativeSizeForm = () => {
    return creativeSizesForm.map((item: any) => {
      if (item.type === 'BOTH') {
        return (
          <Col sm={12} md={6} className="mb-4">
            <SetCreativeSize
              data={item.data}
              device_platform={item.device_platform}
              creativeType={item.creativeType}
              title={item.title}
            />
          </Col>
        );
      } else {
        if (item.device_platform === item.type) {
          return (
            <Col sm={12} md={6} className="mb-4">
              <SetCreativeSize
                data={item.data}
                device_platform={item.device_platform}
                creativeType={item.creativeType}
                title={item.title}
              />
            </Col>
          );
        }
      }

      return true;
    });
  };

  return (
    <div className="p-4">
      <CardHeader className="px-0 border-bottom-0">
        <Nav className="nav-tabs-custom">
          <NavItem>
            <NavLink
              className={`cursor ${device_platform === 'DESKTOP' && 'active'} `}
              onClick={() => {
                setDevicePlatform('DESKTOP');
                dispatch(
                  onGetCreativeSizes({
                    device_platform: 'DESKTOP',
                  }),
                );
              }}
            >
              <h6 className="mb-0">{t('creative:Desktop')}</h6>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor ${device_platform === 'MOBILE' && 'active'} `}
              onClick={() => {
                setDevicePlatform('MOBILE');
                dispatch(
                  onGetCreativeSizes({
                    device_platform: 'MOBILE',
                  }),
                );
              }}
            >
              <h6 className="mb-0">{t('creative:Mobile')}</h6>
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <Row>{renderCreativeSizeForm()}</Row>
    </div>
  );
};
export default withTranslation()(EditCreativeSize);
