const exponent = (num, pow) => num ** pow;

const calculateFollowerPrice = (num, pow) => {
  // \left(\left(1.185\log \left(\sqrt{500000}\right)\right)^{4.15}\right)
  const eq = exponent(num, pow);
  return eq;
};

// eslint-disable-next-line import/prefer-default-export
export function calculateInfluencerPriceRate(socialPriceRate, follower, engagement) {
  const {
    offset,
    post_multiply,
    share_multiply,
    video_multiply,
    power,
    weight0,
    weight1,
  } = socialPriceRate;
  const _follower = follower ?? 1;
  const basePrice = calculateFollowerPrice(_follower, power);
  const A0Share = (basePrice * share_multiply || 0) + (+offset || 0);
  const A0Photo = (basePrice * post_multiply || 0) + (+offset || 0);
  const A0video = (basePrice * video_multiply || 0) + (+offset || 0);
  // const engagementPerFollower = engagement / _follower;
  // const engagementPercent =
  //   engagementPerFollower * 100 > 1 ? 1 : engagementPerFollower * 100;
  const share_content = A0Share * weight0 + A0Share * engagement * weight1;
  const post_content = A0Photo * weight0 + A0Photo * engagement * weight1;
  const video_content = A0video * weight0 + A0video * engagement * weight1;
  return {
    share_content,
    post_content,
    video_content,
  };
}
