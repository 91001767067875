import config from 'src/config';

const SwiftDoc = () => (
  <>
    <div>
      <h3>Prerequisites</h3>
      <ul>
        <li>Xcode version 14 or higher</li>
        <li>Swift version 5.6 or higher</li>
      </ul>
    </div>
    <div>
      <h3> Add Application </h3>
      <ul>
        <p>
          You can add an application in the
          <a
            href={`${config.appUrl}application/create`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {' Add Application page'}
          </a>
        </p>
      </ul>
    </div>
    <div>
      <h3>Requests ads code</h3>
      <ul>
        1. Go to
        <a
          href={`${config.appUrl}myApplication`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' Application Index page'}
        </a>
        <br />
        2. Clicking on the application name
        <br />
        3. Select banner format and creative size
        <br />
        4. Click on the request button
        <br />
      </ul>
    </div>
    <div>
      <h3>Code on your application (SwiftUI)</h3>
      xcode application File -&gt; Add Packages Search
      <mark>https://gitlab.com/skuberg-on-earth/Flupower/af-swift-ui</mark>
      <br /> -&gt; add package. Copy code from{' '}
      <a
        href={`${config.appUrl}myApplication`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {' Application Index page'}
      </a>{' '}
      to your project <br />
    </div>
    <br />
    <div>
      <h3>Code on your application (UIKit)</h3>
      xcode application File -&gt; Add Packages Search
      <mark>https://gitlab.com/skuberg-on-earth/Flupower/af-swift-kit</mark>
      <br /> -&gt; add package. Copy code from{' '}
      <a
        href={`${config.appUrl}myApplication`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {' Application Index page'}
      </a>{' '}
      to your project <br />
    </div>
  </>
);
export default SwiftDoc;
