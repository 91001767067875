import { withTranslation } from 'react-i18next';

const CompleteGuide = ({ t }: any) => (
  <div>
    {/* Complete Guide */}
    <strong className="font-size-20">
      {t('Complete the instructions in the')}
      <a
        href="https://Flupower.com/doc/mobile-ad"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t(` Flupower Mobile Ads guide `)}
      </a>
      {t('using this app key and ad key.')}
    </strong>
  </div>
);
export default withTranslation()(CompleteGuide);
