import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

const createCreativeGraphQL = async (createCreativeInput: any) => {
  const mutation = gql`
    mutation CreateCreative($createCreativeInput: CreateCreativeInput!) {
      createCreative(createCreativeInput: $createCreativeInput) {
        name
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        createCreativeInput,
      },
    });
    notify(i18n.t('success:Your creative has been created.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative has not been created.'), 'error');
  }
};

const createCreativeReduce = async (createCreativeParam: any) => {
  const {
    name,
    size,
    image_storage_key,
    campaign_keys,
    url_ad,
    title,
    description,
    isActive,
  } = createCreativeParam;

  const createCreativeInput = {
    name,
    size,
    image_storage_key,
    campaign_keys,
    url_ad,
    title,
    description,
    isActive,
  };

  const mutation = gql`
    mutation CreateCreative($createCreativeInput: CreateCreativeInput!) {
      createCreative(createCreativeInput: $createCreativeInput) {
        key
        name
      }
    }
  `;

  await client.mutate({
    mutation,
    variables: {
      createCreativeInput,
    },
  });
};

const creativesByUserIdGraphql = async () => {
  const query = gql`
    query {
      creatives {
        key
        name
        size
        creativeCampaigns {
          key
        }
        created_at

        image_storage {
          key
          url
        }
        user {
          email
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.creatives;
};

const getCreativesCampaignGraphql = async (campaignKey: string) => {
  const query = gql`
    query creativeCampaigns($campaignKey: String!) {
      creativeCampaigns(campaignKey: $campaignKey) {
        campaign {
          ad_format
          banner_format
          device_platform
        }
        items {
          key
          name
          url_ad
          isActive
          verifyStatus
          title
          description
          creative {
            key
            name
            size
            key
            image_storage {
              url
              key
            }
          }
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: {
      campaignKey,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.creativeCampaigns;
};

const updateCreativeGraphQL = async (updateCreativeInput: any) => {
  const { key, campaignIds, verifyStatus } = updateCreativeInput;
  const mutation = gql`
    mutation UpdateCreative(
      $key: Int!
      $campaignIds: [Int!]
      $verifyStatus: verifyStatus
    ) {
      updateCreative(
        updateCreativeInput: {
          key: $key
          campaignIds: $campaignIds
          verifyStatus: $verifyStatus
        }
      ) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
        campaignIds,
        verifyStatus,
      },
    });
    if (verifyStatus) {
      notify(i18n.t(`success:Creative has been ${verifyStatus}`), 'success');
    } else {
      notify(i18n.t('success:Your creative has been updated.'), 'success');
    }
  } catch (error) {
    notify(i18n.t('error:The creative has not been updated.'), 'error');
  }
};

const updateAdImageSizeGraphQL = async (updateAdImageSizeInput: any) => {
  const { key, width, height } = updateAdImageSizeInput;
  const mutation = gql`
    mutation UpdateAdImageSize($key: String!, $width: Int!, $height: Int!) {
      updateAdImageSize(
        updateAdImageSizeInput: { key: $key, width: $width, height: $height }
      ) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
        width,
        height,
      },
    });
    notify(i18n.t('success:Your creative size has been updated.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative size has not been updated.'), 'error');
  }
};

const updateCreativeCampaignGraphql = async (updateCreativeCampaignInput: any) => {
  const { isActive } = updateCreativeCampaignInput;

  const mutation = gql`
    mutation UpdateCreativeCampaign(
      $updateCreativeCampaign: UpdateCreativeCampaignInput!
    ) {
      updateCreativeCampaign(updateCreativeCampaignInput: $updateCreativeCampaign) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        updateCreativeCampaign: updateCreativeCampaignInput,
      },
    });
    if (isActive) {
      notify(i18n.t('success:Your creative has been Activated.'), 'success');
    } else if (isActive === false) {
      notify(i18n.t('success:Your creative has been Stopped.'), 'success');
    } else {
      notify(i18n.t('success:Creative has been Updated.'), 'success');
    }
  } catch (error) {
    notify(i18n.t('error:Creative update failed.'), 'error');
  }
};

const createCreativeCampaignGraphql = async (createCreativeCampaignInput: any) => {
  const mutation = gql`
    mutation CreateCreativeCampaign(
      $createCreativeCampaignInput: CreateCreativeCampaignInput!
    ) {
      createCreativeCampaign(
        createCreativeCampaignInput: $createCreativeCampaignInput
      ) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        createCreativeCampaignInput,
      },
    });

    notify(i18n.t('success:Your creative has been created.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative has not been created.'), 'error');
  }
};

const removeCreativeCampaignGraphql = async (key: string) => {
  const mutation = gql`
    mutation RemoveCreativeCampaign($key: String!) {
      removeCreativeCampaign(key: $key) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
      },
    });
    notify(i18n.t('success:Your creative has been deleted.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative has not been deleted.'), 'error');
  }
};

const FilterCreativeGraphql = async (payload: any) => {
  const GET_CREATIVE_TRANSACTION = gql`
        query FilterCreativeTable($payload: FilterCreativeInput!) {
            filterCreativeTable(filterCreativeInput: $payload) {
                items {
                    key
                    image_storage {
                        key
                        url
                    }
                    creativeCampaigns {
                        key
                        deleted_at
                    }
                    name
                    size
                    created_at
                    ${payload.verifyStatus ? 'user{key,email}' : ''}
                }
                totalCount
            }
        }
    `;

  const result: any = await client.query({
    query: GET_CREATIVE_TRANSACTION,
    variables: { payload },
    fetchPolicy: 'no-cache',
  });
  return result.data.filterCreativeTable;
};

const filterCreativeCampaignGraphql = async (payload: any) => {
  const GET_CREATIVE_TRANSACTION = gql`
    query filterCreativeCampaignTable(
      $filterCreativeCampaignInput: FilterCreativeCampaignInput
    ) {
      filterCreativeCampaignTable(
        filterCreativeCampaignInput: $filterCreativeCampaignInput
      ) {
        totalCount
        items {
          key
          verifyStatus
          created_at
          url_ad
          name
          campaign {
            name
            creative_types {
              name
            }
          }
          creative {
            name
            size
            image_storage {
              url
              key
            }
            user {
              email
            }
          }
        }
      }
    }
  `;

  const result: any = await client.query({
    query: GET_CREATIVE_TRANSACTION,
    variables: { filterCreativeCampaignInput: payload },
    fetchPolicy: 'no-cache',
  });

  return result.data.filterCreativeCampaignTable;
};

const removeCreativeGraphql = async (key: any) => {
  const mutation = gql`
    mutation RemoveCreative($key: String!) {
      removeCreative(key: $key) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
      },
    });
    notify(i18n.t('success:Creative has been deleted.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Creative has not been deleted.'), 'error');
  }
};

const getCreativeReportGraphql = async (payload: {
  search?: string;
  searchCreative?: string;
  limit?: number;
  page?: number;
  start_date?: string;
  end_date?: string;
  campaign_key?: string;
}) => {
  const query = gql`
    query CreativeCampaignDatum($payload: FilterTableInput!) {
      creativeCampaignDatum(filterTableInput: $payload) {
        totalCount
        items {
          currentDailyBudget
          dailyImpression
          clicks
          created_at
          campaignDatum {
            note
          }
          creativeCampaign {
            name
          }
          creative {
            name
            size
            image_storage {
              key
            }
          }
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { payload },
    fetchPolicy: 'no-cache',
  });
  return result.data.creativeCampaignDatum;
};

const adImageSizesWhereGraphql = async (payload: any) => {
  const { ad_image_type, device_platform } = payload;

  const query = gql`
    query AdImageSizesWhere(
      $ad_image_type: ad_image_type
      $device_platform: device_platform
    ) {
      adImageSizesWhere(
        ad_image_type: $ad_image_type
        device_platform: $device_platform
      ) {
        key
        width
        height
        ad_image_type
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: {
      ad_image_type,
      device_platform,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.adImageSizesWhere;
};

const createAdImageSizeGraphql = async (
  width: number,
  height: number,
  ad_image_type: string,
  device_platform: string,
) => {
  const mutation = gql`
    mutation CreateAdImageSize(
      $width: Int!
      $height: Int!
      $ad_image_type: ad_image_type!
      $device_platform: device_platform!
    ) {
      createAdImageSize(
        createAdImageSizeInput: {
          width: $width
          height: $height
          ad_image_type: $ad_image_type
          device_platform: $device_platform
        }
      ) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        width,
        height,
        ad_image_type,
        device_platform,
      },
    });
    notify(i18n.t('success:Your creative size has been created.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative size creation failed.'), 'error');
  }
};

const removeAdImageSizeGraphql = async (key: number) => {
  const mutation = gql`
    mutation RemoveAdImageSize($key: String!) {
      removeAdImageSize(key: $key) {
        key
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        key,
      },
    });

    notify(i18n.t('success:Your creative size has been removed.'), 'success');
  } catch (error) {
    notify(i18n.t('error:Your creative size has not been removed.'), 'error');
  }
};

export {
  createCreativeGraphQL,
  creativesByUserIdGraphql,
  updateCreativeGraphQL,
  FilterCreativeGraphql,
  createCreativeCampaignGraphql,
  removeCreativeCampaignGraphql,
  removeCreativeGraphql,
  adImageSizesWhereGraphql,
  createAdImageSizeGraphql,
  removeAdImageSizeGraphql,
  updateAdImageSizeGraphQL,
  updateCreativeCampaignGraphql,
  getCreativesCampaignGraphql,
  createCreativeReduce,
  getCreativeReportGraphql,
  filterCreativeCampaignGraphql,
};
