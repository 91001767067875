interface ResponseFacebook {
  id: string;
  name: string;
  picture_url?: string;
  access_token: string;
  fan_count: number;
  link?: string;
  email?: string;
}

const getFacebookUserPageManagement = async (
  userId: string,
  accessToken: string,
) => {
  const url: string = `https://graph.facebook.com/${userId}/accounts?fields=id,name,email,fan_count,link,picture.type(large){url,height,width},access_token&access_token=${accessToken}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  const pageList: ResponseFacebook[] = [];

  if (!response) return null;
  const responseText: string = await response.text();
  const datum: any =
    responseText && !!JSON.parse(responseText)?.data
      ? JSON.parse(responseText).data
      : null;

  if (!datum || datum?.length <= 0) return null;
  for (let i = 0; i < datum?.length; i++) {
    // eslint-disable-next-line no-unused-vars
    const { id, name, access_token, fan_count, picture, link, email } = datum[i];
    const pictureProfileUrl: string | undefined = picture?.data?.url || undefined;
    pageList.push({
      id,
      name,
      email,
      access_token,
      fan_count,
      picture_url: pictureProfileUrl,
      link,
    });
  }
  return pageList;
};

export default getFacebookUserPageManagement;
