import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

// import { createApplication, getApplicationTypes } from 'src/store/actions';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

// import Breadcrumbs
import { Col, Container, Row, FormGroup, CardBody, Card } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setLoading, unsetLoading } from 'src/store/global/actions';
import { createApplicationSql } from 'src/graphql/publisher/application';
import { useHistory } from 'react-router';
import GroupSelectButton from 'src/utils/GroupSelectButton';
import choice from 'src/assets/images/choice.png';
import config from '../../../config';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import menu from '../../../constants/menu';

const AddApplication = ({ t }: any) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [packageName, setPackageName] = useState('');
  const [storeId, setStoreId] = useState('');
  const history = useHistory();
  const [mobilePlatform, setMobilePlatform] = useState();
  const [supportedAppStore, setSupportedAppStore] = useState();

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
  );

  const initialValues = {
    name: '',
    packageName: '',
    storeId: '',
  };

  const handleSubmit = async () => {
    Swal.fire({
      title: t('swal:Are you sure?'),
      text: t(`swal: Do you want to create application "${name}"`),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        let store_id: string | null =
          mobilePlatform === 'IOS' ? storeId : packageName;
        if (supportedAppStore === false) {
          store_id = null;
        }
        const createAppResult = await createApplicationSql({
          name,
          store_id,
          mobile_platform: mobilePlatform || null,
        });

        if (createAppResult) {
          history.push('/myApplication');
        }

        dispatch(unsetLoading());
      } else {
        dispatch(unsetLoading());
      }
    });
  };

  useEffect(() => {
    if (mobilePlatform === 'IOS' && supportedAppStore) {
      setValidationSchema(
        Yup.object().shape({
          name: Yup.string().required('Required'),
          storeId: Yup.string().required('Required'),
        }),
      );
    }
    if (mobilePlatform === 'ANDROID' && supportedAppStore) {
      setValidationSchema(
        Yup.object().shape({
          name: Yup.string().required('Required'),
          packageName: Yup.string().required('Required'),
        }),
      );
    }

    if (supportedAppStore === false) {
      setValidationSchema(
        Yup.object().shape({
          name: Yup.string().required('Required'),
        }),
      );
    }
  }, [mobilePlatform, supportedAppStore]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('Page.Add Application')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Page.Add Application')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.APPLICATION, active: true },
          ]}
        />
        <Card>
          <CardBody>
            <Col lg={6} md={6} sm={8}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                render={({ errors, touched, handleChange }) => (
                  <FormikForm>
                    <Row>
                      <FormGroup className="mb-3">
                        <label htmlFor="name" className="input-label fs-5">
                          {t('Application Name')}
                          <span className="input-required">*</span>
                        </label>
                        <Field
                          name="name"
                          type="text"
                          id="name"
                          className={`form-control ${
                            errors.name && touched.name && 'is-invalid '
                          } `}
                          onChange={(event: any) => {
                            setName(event.currentTarget.value);
                            handleChange(event);
                          }}
                          placeholder={t('invalid.Required')}
                        />
                        {errors.name && (
                          <div className="invalid-message fs-6">
                            {t('Application name is require')}
                          </div>
                        )}
                      </FormGroup>
                    </Row>
                    <GroupSelectButton
                      title="Platform"
                      value={mobilePlatform}
                      setValue={setMobilePlatform}
                      isRequired
                      data={[
                        {
                          selfValue: 'ANDROID',
                          display: 'Android',
                        },
                        {
                          selfValue: 'IOS',
                          display: 'iOS',
                        },
                      ]}
                      className="mb-3"
                    />
                    <GroupSelectButton
                      title="Supported app store"
                      value={supportedAppStore}
                      setValue={setSupportedAppStore}
                      isRequired
                      data={[
                        {
                          selfValue: true,
                          display: t('Yes'),
                        },
                        {
                          selfValue: false,
                          display: t('No'),
                        },
                      ]}
                      className="mb-3"
                    />

                    {supportedAppStore && mobilePlatform === 'ANDROID' && (
                      <Row>
                        <FormGroup className="mb-3">
                          <label htmlFor="packageName" className="input-label fs-5">
                            {t('Application package name')}
                            <span className="input-required">*</span>
                          </label>
                          <Field
                            name="packageName"
                            type="text"
                            id="packageName"
                            className={`form-control ${
                              errors.packageName &&
                              touched.packageName &&
                              'is-invalid '
                            } `}
                            onChange={(event: any) => {
                              setPackageName(event.currentTarget.value);
                              handleChange(event);
                            }}
                            placeholder={t('invalid.Required')}
                          />
                          {errors.packageName && (
                            <div className="invalid-message fs-6">
                              {t('package name is require')}
                            </div>
                          )}
                        </FormGroup>
                      </Row>
                    )}
                    {supportedAppStore && mobilePlatform === 'IOS' && (
                      <Row>
                        <FormGroup className="mb-3">
                          <label htmlFor="storeId" className="input-label fs-5">
                            {t('inputLabel.Application store id')}
                            <span className="input-required">*</span>
                          </label>
                          <Field
                            name="storeId"
                            type="text"
                            id="storeId"
                            className={`form-control ${
                              errors.storeId && touched.storeId && 'is-invalid '
                            } `}
                            onChange={(event: any) => {
                              setStoreId(event.currentTarget.value);
                              handleChange(event);
                            }}
                            placeholder={t('invalid.Required')}
                          />
                          {errors.storeId && (
                            <div className="invalid-message fs-6">
                              {t('invalid.Store id is require')}
                            </div>
                          )}
                        </FormGroup>
                      </Row>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        !(mobilePlatform && supportedAppStore !== undefined) ||
                        !!errors.name ||
                        name?.length <= 0 ||
                        !!errors.packageName ||
                        !!errors.storeId ||
                        (supportedAppStore &&
                          mobilePlatform === 'IOS' &&
                          !storeId) ||
                        (supportedAppStore &&
                          mobilePlatform === 'ANDROID' &&
                          !packageName)
                      }
                    >
                      {t('button:Submit')}
                    </button>
                  </FormikForm>
                )}
              />
            </Col>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default withTranslation()(AddApplication);
