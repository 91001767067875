import { gql } from '@apollo/client';
import { client } from '../client';
import { UsersQuery } from '../../common/types';

const getUsers = async (queryInput: UsersQuery) => {
  const GET_USERS = gql`
    query GetUsers($input: UsersQueryInput!) {
      getUsers(input: $input) {
        items {
          uuid
          email
          social_name
          role
          email_verified_at
          created_at
          two_factor_secret
          isBan
          balance
        }
        totalCount
      }
    }
  `;
  try {
    const result: any = await client.query({
      query: GET_USERS,
      variables: {
        input: queryInput,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.getUsers;
  } catch (error: any) {
    return null;
  }
};

const getUserDetail = async (id: string) => {
  const GET_USER_DETAIL = gql`
    query GetUserDetail($id: String!) {
      getUserDetail(id: $id) {
        uuid
        email
        role
        email_verified_at
        created_at
        wallet {
          balance
          checksum_status
        }
        user_info {
          country_phone_digit
          phone_number
        }
        social_media_infos {
          image_profile_url
          name
          social_media_type
          key
        }
      }
    }
  `;

  try {
    const result: any = await client.query({
      query: GET_USER_DETAIL,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.getUserDetail;
  } catch (error: any) {
    return null;
  }
};

const checkUserEmail = async (email: string) => {
  const CHECK_USER_EMAIL = gql`
    query checkUserEmail($email: String!) {
      checkUserEmail(email: $email)
    }
  `;

  const result: any = await client.query({
    query: CHECK_USER_EMAIL,
    variables: {
      email,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.checkUserEmail;
};

const delete2FAGql = async (uuid: string) => {
  const mutation = gql`
    mutation Delete2Factor($uuid: String!) {
      delete2Factor(uuid: $uuid)
    }
  `;
  const response: any = client.mutate({
    mutation,
    variables: {
      uuid,
    },
    fetchPolicy: 'no-cache',
  });

  return response;
};

export { getUsers, getUserDetail, checkUserEmail, delete2FAGql };
