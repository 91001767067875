import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import useWindowSize from 'src/utils/windowSize';
import moment from 'moment';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import paramsFromURL from 'src/constants/paramsFromURL';
import { setLoading, unsetLoading } from 'src/store/global/actions';
import MetaTags from 'react-meta-tags';
import Trash from 'src/assets/images/Trash-01.png';
import {
  getAvailableCurrencies,
  getMarketerJobSummary,
  getGleamJobDataForEdit,
  getMarketerAvailableCurrencies,
} from 'src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'src/i18n';
import CustomIcon from 'src/assets/images/custom.svg';
import AddCampaignWidget from 'src/components/Advertiser/Widgets/AddCampaignWidget';
import CampaignSummaryWidget from 'src/components/Advertiser/Widgets/CampaignSummaryWidget';
import CampaignTotalWidget from 'src/components/Advertiser/Widgets/CampaignTotalWidget';
import PerformanceSummary from 'src/components/Advertiser/Widgets/PerformanceSummary';
import WalletWidget from 'src/components/Common/WalletWidget';
import {
  FullNameToShortName,
  ReactFlagsSelectCountry,
  shortPhoneDigit,
} from 'src/utils/country';
import config from 'src/config';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import { format } from 'date-fns';
import addSocial from 'src/assets/images/other/add-social.svg';
import './jobNew.style.scss';
import resizeImage from 'src/utils/resizeImage';
import './AddNewJob.style.scss';
import { useTranslation } from 'react-i18next';
import InstargramIcon from 'src/assets/images/home/ig@2x.27955f7.png';
import FacebookIcon from 'src/assets/images/home/fb@2x.4f64514.png';
import YoutubeIcon from 'src/assets/images/home/yt@2x.81f6272.png';
import TiktokIcon from 'src/assets/images/home/tiktok@2x.ae31098.png';
import TwitterIcon from 'src/assets/images/home/twitter-x-logo.png';
import Wallet from 'src/assets/images/wallet.png';
import influ from 'src/assets/images/influencer-2.jpg';
import DepositHistoryTable from 'src/components/Advertiser/DepositHistoryTable';
import RequestOwnCurrencyHistory from 'src/components/Advertiser/RequestOwnCurrencyHistory';
import CardLoading from 'src/components/Common/CardLoading';
import EditOwnCoin from 'src/components/Publisher/EditOwnCoin';
import RecentActivity from 'src/components/Publisher/RecentActivity';
import RequestCoinWithdraw from 'src/components/Publisher/RequestCoinWithdraw';
import WithdrawForm from 'src/components/Publisher/WithdrawForm';
import WithdrawHistory from 'src/components/Publisher/WithdrawHistory';
import React, { useEffect, useState, useRef } from 'react';
import CustomButton from 'src/components/Common/CustomButton';
import ReactQuill from 'react-quill';
import TextEditorInput from 'src/components/Common/input/textEditorInput';
import Select from 'react-select';
import Cropper from 'react-cropper';
import lgbtIcon from 'src/assets/images/flupower/activity-icon/lgbtq.png';
import {
  createGleamJobGQL,
  removeJobImageByKey,
  updateGleamJobGQL,
  uploadJobThumbnailImage,
} from 'src/graphql/advertiser/job';

import menIcon from 'src/assets/images/flupower/activity-icon/men.png';
import womenIcon from 'src/assets/images/flupower/activity-icon/women.png';
import notify from 'src/utils/notify';
import ReactFlagsSelect from 'react-flags-select';
import IconRadioInputComponent from 'src/components/shared/IconRadioInput/IconRadioInput.component';
import { getTaskTypeByGroup } from 'src/store/tasktype/action';
import { Button, Tooltip } from '@mui/material';
import IconRadioInputComponentNew from 'src/components/shared/IconRadioInput/IconRadioInput.component copy';
import choice from 'src/assets/images/choice.png';
import Swal from 'sweetalert2';
import ReactDatePicker from 'react-datepicker';
import { Editor } from 'react-draft-wysiwyg';
import control from './AddNewJob.control';
import TimeZone from '../../../../utils/timezone.json';
import DropdownSearch from '../MarketerJobCampaignDetail/DropdownPrizesToken';
import TopUpTable from './TopUpTable';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// eslint-disable-next-line import/order
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
// eslint-disable-next-line import/order
import { convertToHTML } from 'draft-convert';
// eslint-disable-next-line import/order
import AllButton from 'src/components/Common/AllButton';

enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
}

export enum JobType {
  INVITE_ONLY,
  PUBLISH,
}
export enum SaveType {
  DRAFT,
  PUBLISH,
}
interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  Categories: any;
}

const AddNewJob = () => {
  const { t } = useTranslation();
  const { height } = useWindowSize();

  const { marketerJob, jobEdit, marketerCurrencies } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
    currency: state.Currencies.availableCurrencies,
    jobEdit: state.MarketerJob.jobEdit,
    marketerCurrencies: state.Wallet.marketerCurrencies,
  }));

  const [availableCurrencies, setAvailableCurrencise] = useState<any>([]);

  const modules = {
    toolbar: [
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      // [{ script: 'sub' }, { script: 'super' }],
      // ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      // ['clean'],
    ],
  };

  useEffect(() => {
    if (marketerCurrencies?.length) {
      const defaultCurrency = marketerCurrencies.filter(
        (index: any) => index?.currency?.symbol === 'USDT_BEP20',
      );
      setAvailableCurrencise(marketerCurrencies);
      setDefaultCurrency(defaultCurrency[0].currency.id);
      setCurrencyId(defaultCurrency[0].currency.id);
      setCurrencySymbol(defaultCurrency[0].currency.symbol);
    }
  }, [marketerCurrencies, jobEdit]);

  const renderSetTypeSocial = (id: any) => {
    switch (id) {
      case 1:
        return { content_type: 'Like and Comment', type_social: 'FACEBOOK' };
      case 2:
        return { content_type: 'Review', type_social: 'FACEBOOK' };
      case 3:
        return { content_type: 'Share', type_social: 'FACEBOOK' };
      case 4:
        return { content_type: 'Photo', type_social: 'FACEBOOK' };
      case 5:
        return { content_type: 'Like and Comment', type_social: 'INSTAGRAM' };
      case 6:
        return { content_type: 'Photo', type_social: 'INSTAGRAM' };
      case 7:
        return { content_type: 'Like and Comment', type_social: 'TWITTER' };
      case 8:
        return { content_type: 'Photo', type_social: 'TWITTER' };
      case 9:
        return { content_type: 'Like and Comment', type_social: 'YOUTUBE' };
      case 10:
        return { content_type: 'Video', type_social: 'YOUTUBE' };
      case 11:
        return { content_type: 'SUBSCRIBE', type_social: 'YOUTUBE' };
      case 12:
        return { content_type: 'Video', type_social: 'TIKTOK' };
      case 13:
        return { content_type: 'Like and Comment', type_social: 'TIKTOK' };
      case 14:
        return { content_type: 'Custom', type_social: 'NOSOCIAL' };
      case 15:
        return { content_type: 'Like Page', type_social: 'FACEBOOK' };
      case 16:
        return { content_type: 'Share Group', type_social: 'FACEBOOK' };
      case 17:
        return { content_type: 'Quote', type_social: 'TWITTER' };
      default:
        return '';
    }
  };

  const rendorEditorState = (text: any) => {
    let _editorState: any;
    if (text) {
      const blockFromHTML = convertFromHTML(text);
      _editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blockFromHTML?.contentBlocks,
          blockFromHTML?.entityMap,
        ),
      );
    } else {
      _editorState = EditorState.createEmpty();
    }
    return _editorState;
  };

  const dispatch = useDispatch();
  // const [selectValue, setSelectValue] = useState<SocialMediaType | null>(null);
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(true);
  const [isPhotoSelect, setIsPhotoSelect] = useState(false);
  const [formSocial, setFormSocial] = useState<any>([]);
  const [newFormSocial, setNewFormSocial] = useState<any>([]);
  const history = useHistory();
  const [jobId]: any = paramsFromURL(['job']);

  const [tab, setTab] = useState(1);
  const ss = 'USDT_BEP20';
  const [IsDisableButtonCustom, setIsDisableButtonCustom] = useState(true);
  const [IsDisableButtonFacebook, setIsDisableButtonFacebook] = useState(false);
  const [IsDisableButtonInstagram, setIsDisableButtonInstagram] = useState(false);
  const [IsDisableButtonTwitter, setIsDisableButtonTwitter] = useState(false);
  const [IsDisableButtonYoutube, setIsDisableButtonYoutube] = useState(false);
  const [IsDisableButtonTiktok, setIsDisableButtonTiktok] = useState(false);
  const [isDisableCountry, setIsDisableCountry] = useState(true);

  const [historyTab, setHistoryTab] = useState(1);
  const [optionIsOpen, setOptionsIsOpen] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState(9);
  const [currencyId, setCurrencyId] = useState(9);
  const [currencySymbol, setCurrencySymbol] = useState<any>(
    !jobId ? 'USDT_BEP20' : defaultCurrency,
  );
  const [arrayCountry, setArrayOption] = useState<string[]>(['ALL']);
  const formControls: FormControl = {
    label: 'marketer:create_campaign.campaign_name',
    name: 'name',
    type: FormInputType.STRING,
    required: true,
    Categories: [...CategoriesMarketerCampaign],
  };

  const all = formControls.Categories.map((data: any) => data.value);
  const [intitalValue, setIntitialValue] = useState<any>({
    name: '',
    thumbnail_cover: '',
    work_order: '',
    currency_id: 9,
    date_submission: null,
    last_day_accept_job: null,
    last_day_influencer_submit: null,
    last_day_to_approve: null,
    last_day_approve_job: null,
    credit_term: 7,
    finish_internal_work: null,
    job_type: 'PUBLISH',
    is_male: true,
    is_female: true,
    is_lgbt: true,
    country_names: ['ALL'],
    aptitude_ids: all,
    fixed_price: null,
    topup_percent: null,
    saveType: null,
    job_tasks: [],
    timezone: 'Bangkok',
    timezone_offset: 420,
  });

  const [formValue, setFormValues] = useState(intitalValue);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const place = `${t(
    'Ex. Global #1 Match maker for Influencer & Marketer\nEveryone can be\nan Influencer',
  )}`;

  useEffect(() => {
    dispatch(getTaskTypeByGroup());
    dispatch(getMarketerAvailableCurrencies());
    if (jobId) {
      dispatch(getGleamJobDataForEdit(jobId));
    }
  }, []);

  useEffect(() => {
    if (jobEdit && jobEdit.job_tasks && jobId) {
      setFormValues(jobEdit);
      console.log('formValue', formValue);
      setNewFormSocial([
        ...jobEdit?.job_tasks.map((job: any) => {
          return { ...job, id: job.task_type_id };
        }),
      ]);
      const NewFormValue = [...jobEdit?.job_tasks];
      const NewFormValueArray = NewFormValue.map(
        ({ key, content_type, task_type, order, ...rest }: any) => rest,
      );
      const newForm = [
        ...NewFormValueArray.map((job: any) => ({
          ...job,
          ...renderSetTypeSocial(job?.task_type_id),
          editorState: rendorEditorState(job?.description),
        })),
      ];
      //console.log('newForm', newForm);
      //console.log('jobEdit', jobEdit.job_tasks);
      setEditorState(rendorEditorState(jobEdit.work_order));
      setFormSocial(newForm);
      if (jobEdit?.country_names?.length === 221) {
        setArrayOption(['ALL']);
        setIsDisableCountry(true);
      } else {
        setArrayOption(jobEdit?.country_names);
        setIsDisableCountry(false);
      }
      if (jobEdit.aptitude_ids?.length === formControls.Categories?.length) {
        setCheckAllProperty(true);
      } else {
        setCheckAllProperty(false);
      }
      setIsMale(jobEdit.is_male);
      setIsWomen(jobEdit.is_female);
      setIsLgbt(jobEdit.is_lgbt);
      settingCurrencySymbol();
    }
  }, [jobEdit]);
  console.log('formValue', formValue);
  const [IsMale, setIsMale] = useState(formValue.is_male);
  const [IsLgbt, setIsLgbt] = useState(formValue.is_female);
  const [IsWomen, setIsWomen] = useState(formValue.is_lgbt);
  const [IsFixedPrice, setFixedPrice] = useState(false);
  const { Texts, FlexCol, Radio, Btn, DivContent, GridBox } = control();
  const handleChangeTextEditor = (value: string) => {
    const obj: any = { ...formValue };
    if (obj) {
      obj.work_order = value;
    }
    setFormValues(obj);
  };

  const temp = [...TimeZone];
  const defaultSelected =
    temp.find((opt) => opt?.label.slice(12) === formValue?.timezone) ||
    '(UTC+07:00) Bangkok, Hanoi,Jakarta';
  const [IsEnterForm, setIsEnterForm] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ''; // This is necessary for some browsers to show a confirmation dialog

      // Custom confirmation message
      const confirmationMessage = 'Are you sure you want to leave this page?';

      // Show the custom confirmation dialog
      if (window.confirm(confirmationMessage)) {
        // User clicked "OK," you can perform any necessary cleanup or additional logic here
      } else {
        // User clicked "Cancel," prevent leaving the page
        e.preventDefault();
      }
    };
    if (IsEnterForm) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formValue]);

  const [selected, setSelected] = useState(defaultSelected);
  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);
  const onChange = (option: any) => {
    setSelected(option);
    const timeZoneLabel = option.label.split(') ');
    setFormValues({
      ...formValue,
      timezone: timeZoneLabel[1],
      timezone_offset: option?.offset * 60,
    });
  };
  const uploadImg = () => {
    return <div>{uploadJobImageView()}</div>;
  };

  const [selectedImage, setSelectedImage]: any = useState(null);
  const [cropper, setCropper]: any = useState(null);
  const [cropData, setCropData] = useState(null);
  const [jobImage, setJobImage] = useState<any>(
    marketerJob?.thumbnail_cover
      ? `${config.backendUrl}api/jobThumbnail/${marketerJob?.thumbnail_cover}`
      : '',
  );
  const handleFileSelected = async (e: any) => {
    const files: any = Array.from(e.target.files);

    const file = files[0];

    // const newFile = await fixImageSize({ file, width: 600, height: 400 });
    const reader = new FileReader();
    const imageTag: any = document.getElementById('jobImageRef');
    // imageTag.title = file?.name;

    reader.onload = (event) => {
      imageTag.src = event?.target?.result;
      setSelectedImage(event?.target?.result);
    };
    reader.readAsDataURL(file);
    setJobImage(file);
    e.target.files = null;

    // await uploadSocialJobImage(newSizeFile, file);
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const deleteUploadedImage = async () => {
    setJobImage('');
    setFormValues({ ...formValue, thumbnail_cover: '' });
    setSelectedImage(null);
    setCropData(null);
    setIsPhotoSelect(false);
  };
  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());

      const url = cropper.getCroppedCanvas().toDataURL();
      fetch(url)
        .then((res) => res.blob())
        .then(async (blob) => {
          const uuid = crypto.randomUUID();
          const file: any = new File([blob], uuid, { type: 'image/png' });

          setFormValues({ ...formValue, thumbnail_cover: file });

          const [jobId, mock]: any = ['mockImg'];
          const submitValue: any = {};
          if (file && typeof file === 'object') {
            if (marketerJob?.thumbnail_cover) {
              await removeJobImageByKey(marketerJob?.thumbnail_cover);
              submitValue['thumbnail_cover'] = '';
            }
            const files: any = file[0] || file;
            const newFile = await resizeImage({ file: files, maxSize: 600 });
            let thumbnail_cover: any;
            if (newFile) {
              thumbnail_cover = newFile;
            } else {
              thumbnail_cover = files;
            }

            const imageKey = await uploadJobThumbnailImage(thumbnail_cover, jobId);
            submitValue['thumbnail_cover'] = imageKey;
            setFormValues({
              ...formValue,
              thumbnail_cover: submitValue['thumbnail_cover'],
            });
          } else {
            submitValue['thumbnail_cover'] = marketerJob['thumbnail_cover'];
            setFormValues({
              ...formValue,
              thumbnail_cover: submitValue['thumbnail_cover'],
            });
          }
        });
    }
    setIsPhotoSelect(true);
  };
  //console.log('formvalue', formValue);
  const uploadJobImageView = () => {
    // if (marketerJob.job_type !== 'PUBLISH') return <></>;
    //กรณีที่เป็นแก้ไข
    return formValue.thumbnail_cover && jobId ? (
      <div style={{ position: 'relative', width: 'fit-content' }}>
        <button
          type="button"
          onClick={() => {
            deleteUploadedImage();
          }}
          style={{
            cursor: 'pointer',
            top: '8px',
            right: '16px',
            position: 'absolute',
            border: '0',
            backgroundColor: 'inherit',
          }}
        >
          <img src={Trash} alt="trash" style={{ width: '30px', height: '30px' }} />
        </button>
        <img
          id=""
          src={`${config.backendUrl}api/jobThumbnail/${formValue.thumbnail_cover}`}
          alt="Header Avatar"
          className=""
          style={{ maxWidth: '620px', width: '100%' }}
        />
      </div>
    ) : (
      <div style={{ width: '100%' }}>
        {!isPhotoSelect && !selectedImage && (
          <div
            style={{
              maxWidth: '620px',
              border: '1px solid rgb(148, 157, 157)',
              borderRadius: '10px',
              margin: '10px 0',
            }}
          >
            <input
              onChange={async (e: any) => {
                await handleFileSelected(e);
              }}
              ref={fileInputRef}
              type="file"
              multiple
              style={{
                display: 'none',
                width: '100%',
                height: '100%',
              }}
            />

            <div
              className="text-center"
              style={{
                padding: '0.5rem',
                maxWidth: '620px',
                position: 'relative',
              }}
            >
              <h5> {t('marketer:job_detail:Product Image')}</h5>
              <img
                id="jobImageRef"
                src={jobImage}
                alt="jobImage"
                style={{
                  maxHeight: '372px',
                  maxWidth: '620px',
                  // objectFit: 'cover',
                  aspectRatio: '6/4',
                  width: '100%',
                  height: '100%',
                  visibility: jobImage ? 'visible' : 'hidden',
                }}
              />
              <button
                type="button"
                onClick={() => {
                  deleteUploadedImage();
                }}
                style={{
                  cursor: 'pointer',
                  right: '0',
                  position: 'absolute',
                  visibility: jobImage ? 'visible' : 'hidden',
                  border: '0',
                  backgroundColor: 'inherit',
                }}
              >
                <img
                  src={Trash}
                  alt="trash"
                  style={{ width: '30px', height: '30px' }}
                />
              </button>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  fileInputRef?.current?.click();
                }}
              >
                <div>
                  <i
                    className="display-6 text-muted bx bxs-cloud-upload lg-4 "
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                  <h5 style={{ display: 'inline-block' }}>
                    {t('creative:Drop files here or click to upload')}
                  </h5>
                </div>
                <h6 style={{ color: 'red' }}>
                  {t('marketer:job_detail:Image file .jpg or .png')}
                </h6>
              </div>
            </div>
          </div>
        )}
        {selectedImage && (
          <>
            {!isPhotoSelect && (
              <Cropper
                // className="cropper"
                style={{
                  width: '100%',
                  height: '400px',
                  border: '1px solid',
                }}
                src={selectedImage}
                // viewMode={2}
                center={false}
                aspectRatio={6 / 4}
                zoomable={false}
                dragMode="move"
                // cropBoxResizable={false}
                initialAspectRatio={1}
                background={false}
                // viewMode={1}
                // minCropBoxHeight={10}
                // minCropBoxWidth={10}
                toggleDragModeOnDblclick={false}
                data={{
                  width: 600,
                  height: 400,
                }}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                // zoomable={false}
              />
            )}
            {!isPhotoSelect && (
              <button
                type="button"
                id="leftbutton"
                onClick={getCropData}
                className="crop-button"
                style={{ margin: '1rem 0' }}
              >
                <span>{t('button:Crop Image')}</span>
              </button>
            )}
            {cropData && (
              <div
                onClick={() => {
                  deleteUploadedImage();
                  setTimeout(() => {
                    fileInputRef?.current?.click();
                  }, 1000);
                }}
                style={{
                  maxWidth: '600px',
                  maxHeight: '400px',
                  border: '1px solid rgb(3, 3, 3)',
                  borderRadius: '10px',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <input
                  onChange={async (e: any) => {
                    await handleFileSelected(e);
                  }}
                  ref={fileInputRef}
                  type="file"
                  multiple
                  style={{
                    display: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                />
                {/* <button
                  type="button"
                  onClick={() => {
                    deleteUploadedImage();
                  }}
                  style={{
                    cursor: 'pointer',
                    top: '8px',
                    right: '16px',
                    position: 'absolute',
                    visibility: jobImage ? 'visible' : 'hidden',
                    border: '0',
                    backgroundColor: 'inherit',
                  }}
                >
                  <img
                    src={Trash}
                    alt="trash"
                    style={{ width: '30px', height: '30px' }}
                  />
                </button> */}

                <img
                  src={cropData}
                  alt="cropped"
                  className="crop-image"
                  style={{
                    maxWidth: '600px',
                    maxHeight: '400px',
                    // objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    // visibility: cropData ? 'visible' : 'hidden',
                  }}
                />
              </div>
            )}
            {/* {cropData && (
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {t('Crop')}
              </span>
            )} */}
          </>
        )}
      </div>
    );
  };

  const handleOptionChange = (option: any) => {
    setFormValues({ ...formValue, job_type: option });
  };

  const OnDescriptionChange = (e: any) => {
    setEditorState(e);
    setFormValues({
      ...formValue,
      work_order: convertToHTML(e.getCurrentContent()),
    });
  };

  // console.log('formvalue == ', formValue);
  const renderSetup = () => {
    return (
      <div style={{ color: 'white' }}>
        <div
          style={{
            padding: '10px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
            marginBottom: '10px',
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <b>{t('campaignNew.General Infomation')}</b>
          <div>{t('campaignNew.Specify the basic details of your campaign')}</div>
        </div>
        <div className="header-word">{t('campaignNew.Campaign Name')}</div>
        <input
          placeholder={t('campaignNew.Ex . ShibaCoin Marketing 1')}
          className="form-control form"
          style={{ width: '25%', marginBottom: '10px' }}
          type="text"
          value={formValue.name}
          onChange={(e) => {
            setFormValues({ ...formValue, name: e.currentTarget.value });
            if (formValue.name) {
              setIsEnterForm(true);
            } else {
              setIsEnterForm(false);
            }
          }}
        />
        <div style={{ width: '65%' }}>
          <div className="header-word">{t('campaignNew.Description')}</div>
          <div className="text-editer">
            {/* <ReactQuill
              placeholder={place}
              style={{ height: '120px' }}
              modules={modules}
              value={formValue.work_order}
              onChange={(e) => {
                setFormValues({ ...formValue, work_order: e });
              }}
            /> */}
            <Editor
              editorState={editorState}
              toolbarHidden
              editorStyle={{
                height: '120px',
                borderRadius: '10px',
                color: '#495057',
              }}
              editorClassName="editor-class"
              wrapperClassName="wrapper-class"
              toolbar={{
                options: [],
              }}
              hashtag={{
                separator: ' ',
                trigger: '#',
              }}
              placeholder={place}
              onEditorStateChange={(e: any) => OnDescriptionChange(e)}
            />
          </div>
        </div>

        <div className="header-word">{t('campaignNew.Cover image')}</div>
        {uploadImg()}

        <div
          className="item-date-picker-container"
          style={{ display: 'flex', flexDirection: 'row', padding: '2px' }}
        >
          <div className="item-date-picker">
            <div>{t('campaignNew.Start')}</div>
            <div className="md-form md-outline input-with-post-icon datepicker">
              {/* <ReactDatePicker
                selected={moment(formValue.date_submission).toDate()} // Use 'selected' instead of 'value'
                onChange={(date) => {
                  setFormValues({
                    ...formValue,
                    date_submission: moment(date).format('YYYY-MM-DD'),
                  });
                }}
              /> */}
              <input
                className="form-control"
                type="date"
                placeholder="DD-MM-YYYY"
                value={moment(formValue.date_submission).format('YYYY-MM-DD')}
                onChange={(e) =>
                  setFormValues({
                    ...formValue,
                    date_submission: e.currentTarget.value,
                  })
                }
              />
            </div>
          </div>
          <div className="item-date-picker">
            <div>{t('campaignNew.End')}</div>
            <input
              className="form-control"
              type="date"
              placeholder="DD-MM-YYYY"
              min={formValue.date_submission}
              value={moment(formValue.last_day_to_approve).format('YYYY-MM-DD')}
              onChange={(e) => {
                setFormValues({
                  ...formValue,
                  last_day_to_approve: e.currentTarget.value,
                  last_day_accept_job: e.currentTarget.value,
                  last_day_approve_job: e.currentTarget.value,
                  last_day_influencer_submit: e.currentTarget.value,
                });
                if (optionIsOpen) {
                  setFormValues({
                    ...formValue,
                    last_day_to_approve: e.currentTarget.value,
                  });
                }
              }}
            />
          </div>
          {/* <div className="item-date-picker" style={{ width: '250px' }}>
            <div>Timezone</div>
            <Select options={temp} value={selected} onChange={onChange} />
          </div> */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ margin: '10px 10px 10px 0px' }}>
            {t('campaignNew.Advanced')}
          </div>
          {optionIsOpen ? (
            <div
              onClick={() => {
                if (optionIsOpen) {
                  setFormValues({
                    ...formValue,
                    last_day_accept_job: formValue.last_day_to_approve,
                    last_day_approve_job: formValue.last_day_to_approve,
                    last_day_influencer_submit: formValue.last_day_to_approve,
                  });
                } else {
                  setFormValues({
                    ...formValue,
                    last_day_accept_job: null,
                    last_day_approve_job: null,
                    last_day_influencer_submit: null,
                  });
                }
                setOptionsIsOpen(!optionIsOpen);
              }}
            >
              <i className="fas fa-chevron-down" />
            </div>
          ) : (
            <div
              onClick={() => {
                if (optionIsOpen) {
                  setFormValues({
                    ...formValue,
                    last_day_accept_job: formValue.last_day_to_approve,
                    last_day_approve_job: formValue.last_day_to_approve,
                    last_day_influencer_submit: formValue.last_day_to_approve,
                  });
                } else {
                  setFormValues({
                    ...formValue,
                    last_day_accept_job: null,
                    last_day_approve_job: null,
                    last_day_influencer_submit: null,
                  });
                }
                setOptionsIsOpen(!optionIsOpen);
              }}
            >
              <i className="fas fa-chevron-right" />
            </div>
          )}
        </div>
        {optionIsOpen && (
          <div
            className="item-date-picker-container"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <div className="item-date-picker">
              <div>{t('campaignNew.Influencer apply campaign with in')}</div>
              <input
                className="form-control"
                type="date"
                placeholder="DD-MM-YYYY"
                max={formValue.last_day_to_approve}
                min={jobId ? formValue.date_submission : formValue.date_submission}
                value={moment(formValue.last_day_accept_job).format('YYYY-MM-DD')}
                onChange={(e) =>
                  setFormValues({
                    ...formValue,
                    last_day_accept_job: e.currentTarget.value,
                  })
                }
              />
            </div>
            <div className="item-date-picker">
              <div>{t('campaignNew.Marketer accept influencer')}</div>
              <input
                type="date"
                placeholder="DD-MM-YYYY"
                max={formValue.last_day_to_approve}
                min={
                  jobId ? formValue.date_submission : formValue.last_day_accept_job
                }
                value={moment(formValue?.last_day_approve_job).format('YYYY-MM-DD')}
                className="form-control"
                onChange={(e) =>
                  setFormValues({
                    ...formValue,
                    last_day_approve_job: e.currentTarget.value,
                  })
                }
              />
            </div>
            <div className="item-date-picker">
              <div>{t('campaignNew.Influencer submit job')}</div>
              <input
                placeholder="DD-MM-YYYY"
                max={formValue.last_day_to_approve}
                min={
                  jobId ? formValue.date_submission : formValue.last_day_approve_job
                }
                type="date"
                value={moment(formValue.last_day_influencer_submit).format(
                  'YYYY-MM-DD',
                )}
                className="form-control"
                onChange={(e) =>
                  setFormValues({
                    ...formValue,
                    last_day_influencer_submit: e.currentTarget.value,
                  })
                }
              />
            </div>
          </div>
        )}
        <div style={{ margin: '5px 0px 5px 0px' }}>
          {t('campaignNew.Release token after campaign finish in')}
        </div>
        <div style={{ margin: '5px 0px 5px 0px' }} className="day-estimate">
          <input
            style={{ width: '150px' }}
            min={3}
            max={30}
            type="number"
            className="form-control"
            placeholder="Mix 3 Max 30"
            value={formValue.credit_term || null}
            onChange={(e) => {
              setFormValues({
                ...formValue,
                credit_term: Number(e.currentTarget.value),
              });
            }}
          />
          <div>{t('campaignNew.Day')}</div>
        </div>
        <div
          style={{ marginTop: '20px' }}
          onClick={() => {
            setTab(2);
          }}
        >
          {' '}
          <AllButton size="sp" status="all" label={t('campaignNew.next')} />
        </div>
      </div>
    );
  };

  const onDelete = (item: any) => {
    // const _arrayClone = structuredClone(arrayCountry);
    const aaa = arrayCountry?.filter((i: any) => i !== item);
    setArrayOption(aaa);
  };

  const [checkAllProperty, setCheckAllProperty] = useState(true);

  const AllChoice = () => {
    const all = formControls.Categories.map((data: any) => data.value);

    setCheckAllProperty(!checkAllProperty);
    if (!checkAllProperty) {
      setFormValues({ ...formValue, aptitude_ids: all });
    } else {
      setFormValues({ ...formValue, aptitude_ids: [] });
    }
  };

  const renderUser = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          color: 'white',
        }}
      >
        <div>{t('campaignNew.Country')}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '10px',
            marginBottom: '10px',
          }}
        >
          {arrayCountry?.map((item: any, key: any) => {
            return (
              <div
                style={{
                  display: 'flex',
                  minWidth: '100px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '6px 2px 6px 11px',
                  borderRadius: '5px',
                  background: '#cdcbcb',
                  color: 'black',
                  fontSize: '11px',
                }}
              >
                {item}
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    cursor: 'pointer',
                    marginLeft: '7px',
                  }}
                  onClick={() => {
                    if (item === 'ALL') {
                      setIsDisableCountry(false);
                    }

                    onDelete(item);
                  }}
                >
                  X
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            marginLeft: '120px',
          }}
        >
          {/* <div
            onClick={() => {
              setIsDisableCountry(true);
              setArrayOption(['ALL']);
              setFormValues({ ...formValue, country_names: 'ALL' });
            }}
          >
            <AllButton size="sp" status="all" label={t('All')} />
          </div> */}
          <div style={{ width: '50%' }}>
            <ReactFlagsSelect
              searchable
              disabled={isDisableCountry}
              selectedSize={13}
              optionsSize={13}
              fullWidth
              searchPlaceholder="Search countries"
              onSelect={(label: any) => {
                const country: any = ReactFlagsSelectCountry;
                if (!arrayCountry.includes(country[label])) {
                  setArrayOption([...arrayCountry, country[label]]);
                }
                //console.log(arrayCountry);
                // setFormValues({
                //   ...formValue,
                //   [input.name]: country[label],
                //   short_country: label,
                // });
              }}
              selected={formValue.short_country}
            />
          </div>
        </div>
        <div>{t('campaignNew.Gender')}</div>
        <div
          style={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            marginLeft: '120px',
          }}
        >
          {/* <div
            onClick={() => {
              if (IsMale !== IsWomen || IsMale !== IsLgbt || IsWomen !== IsLgbt) {
                setIsMale(true);
                setIsWomen(true);
                setIsLgbt(true);
                setFormValues({
                  ...formValue,
                  is_male: true,
                  is_female: true,
                  is_lgbt: true,
                });
              } else {
                setFormValues({
                  ...formValue,
                  is_male: !IsMale,
                  is_female: !IsWomen,
                  is_lgbt: !IsLgbt,
                });
                setIsMale(!IsMale);
                setIsWomen(!IsWomen);
                setIsLgbt(!IsLgbt);
              }
            }}
          >
            <AllButton size="xxs" status="all" label={t('campaignNew.All')} />
          </div> */}
          <div
            className="gender-addjob"
            onClick={() => {
              setIsMale(!IsMale);
            }}
          >
            {IsMale ? (
              <span
              // onClick={() => {
              //   if (IsMale) {
              //     setFormValues({ ...formValue, is_male: true });
              //   } else {
              //     setFormValues({ ...formValue, is_male: false });
              //   }
              // }}
              >
                <i className="fas fa-check " />
              </span>
            ) : (
              <></>
            )}
            <div
              onClick={() => {
                if (!IsMale) {
                  setFormValues({ ...formValue, is_male: true });
                } else {
                  setFormValues({ ...formValue, is_male: false });
                }
              }}
            >
              <img
                src={menIcon}
                alt="men"
                width="80"
                height="80"
                style={{ backgroundColor: '#ABC9FF', borderRadius: '10px' }}
              />
            </div>
            {/* <Radio
              type="radio"
              name="aa"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              onClick={() => {
                if (!IsMale) {
                  setFormValues({ ...formValue, is_male: true });
                } else {
                  setFormValues({ ...formValue, is_male: false });
                }
                setIsMale(!IsMale);
              }}
              checked={formValue.is_male}
            /> */}
          </div>
          <div
            className="gender-addjob"
            onClick={() => {
              setIsWomen(!IsWomen);
            }}
          >
            {IsWomen ? (
              <span
              // onClick={() => {
              //   if (IsWomen) {
              //     setFormValues({ ...formValue, is_female: true });
              //   } else {
              //     setFormValues({ ...formValue, is_female: false });
              //   }
              // }}
              >
                <i className="fas fa-check " />
              </span>
            ) : (
              <></>
            )}
            <div
              onClick={() => {
                if (!IsWomen) {
                  setFormValues({ ...formValue, is_female: true });
                } else {
                  setFormValues({ ...formValue, is_female: false });
                }
              }}
            >
              <img
                src={womenIcon}
                alt="women"
                width="80"
                height="80"
                style={{ backgroundColor: '#FF8B8B', borderRadius: '10px' }}
              />
            </div>
            {/* <Radio
              type="radio"
              name="men"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              onClick={() => {
                if (!IsWomen) {
                  setFormValues({ ...formValue, is_female: true });
                } else {
                  setFormValues({ ...formValue, is_female: false });
                }
                setIsWomen(!IsWomen);
              }}
              checked={formValue.is_female}
            /> */}
          </div>
          <div
            className="gender-addjob"
            onClick={() => {
              setIsLgbt(!IsLgbt);
            }}
          >
            {IsLgbt ? (
              <span
              // onClick={() => {
              //   if (!IsLgbt) {
              //     setFormValues({ ...formValue, is_lgbt: true });
              //   } else {
              //     setFormValues({ ...formValue, is_lgbt: false });
              //   }
              // }}
              >
                <i className="fas fa-check " />
              </span>
            ) : (
              <></>
            )}
            <div
              onClick={() => {
                if (!IsLgbt) {
                  setFormValues({ ...formValue, is_lgbt: true });
                } else {
                  setFormValues({ ...formValue, is_lgbt: false });
                }
              }}
            >
              <img
                src={lgbtIcon}
                style={{
                  backgroundColor: '#red',
                  borderRadius: '10px',
                  border: 'solid 1px #e0e0e0',
                }}
                alt="lgbt"
                width="80"
                height="80"
              />
            </div>
            {/* <Radio
              type="radio"
              name="female"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              onClick={() => {
                if (!IsLgbt) {
                  setFormValues({ ...formValue, is_lgbt: true });
                } else {
                  setFormValues({ ...formValue, is_lgbt: false });
                }
                setIsLgbt(!IsLgbt);
              }}
              checked={formValue.is_lgbt}
            /> */}
          </div>
        </div>
        <div>{t('campaignNew.Influencer attitute')}</div>
        <div style={{ width: '600px' }}>
          {/* <FlexCol margin="5px 10%"> */}
          <div
            onClick={() => {
              AllChoice();
            }}
          >
            <AllButton size="sp" status="all" label={t('All')} />
          </div>
          {/* </FlexCol> */}
          <GridBox>
            {formControls.Categories.map((data: any) => (
              <IconRadioInputComponentNew
                id={data.value}
                imageUrl={data.icon}
                labels={data.label}
                background={data.background_color}
                labelSize="1em"
                isChecked={formValue?.aptitude_ids?.includes(data.value)}
                onClick={() => {
                  let categoryIds: any = [...formValue?.aptitude_ids];

                  if (categoryIds.includes(data.value)) {
                    categoryIds = categoryIds.filter(
                      (category: any) => category !== data.value,
                    );
                  } else categoryIds = [...categoryIds, data.value];
                  setFormValues({ ...formValue, aptitude_ids: categoryIds });
                }}
              />
            ))}
          </GridBox>
        </div>
        <div
          id="markedPoint"
          style={{ marginTop: '20px' }}
          onClick={() => {
            setTab(3);
          }}
        >
          {' '}
          <AllButton size="sp" status="all" label={t('campaignNew.next')} />
        </div>
      </div>
    );
  };
  const facebookDialog = () => {
    return <div className="facebook-dialog">Facebook shere</div>;
  };
  const form: any = 'x';
  const addFormSocial = (text: string, id: number) => {
    return <div>text</div>;
  };
  const facebookLikeCommentPlacholder = t('campaignNew.facebookPlaceHolder');
  const facebookShare = t('campaignNew.facebookSharePlaceHolder');
  const facebookReview = t('campaignNew.facebookReviewPlaceHolder');
  const facebookPost = t('campaignNew.facebookPostPlaceHolder');
  const facebookLike = t('campaignNew.facebookLikePlaceHolder');
  const facebookShareGroup = t('campaignNew.facebookShareGroup');

  // x
  const xLikeComment = t('campaignNew.xLikeComment');
  const xPost = t('campaignNew.xPost');
  // ig
  const igLikeComment = t('campaignNew.igLikeComment');
  const igPost = t('campaignNew.igPost');
  //youtube
  const youtubeLikeComment = t('campaignNew.youtubeLikeComment');
  const youtubeVideo = t('campaignNew.youtubeVideo');
  const youtubeSub = t('campaignNew.youtubeSub');

  // tiktok
  const tiktokVideo = t('campaignNew.tiktokVideo');
  const tiktokLikeComment = t('campaignNew.tiktokLikeComment');
  const renderIconSocial = (socialType: any) => {
    switch (socialType) {
      case 'FACEBOOK':
        return FacebookIcon;
      case 'TIKTOK':
        return TiktokIcon;
      case 'YOUTUBE':
        return YoutubeIcon;
      case 'TWITTER':
        return TwitterIcon;
      case 'INSTAGRAM':
        return InstargramIcon;
      case 'NOSOCIAL':
        return CustomIcon;
      default:
        return FacebookIcon;
    }
  };
  const renderColorSocial = (socialType: any) => {
    switch (socialType) {
      case 'FACEBOOK':
        return '#4267B2';
      case 'TIKTOK':
        return 'black';
      case 'YOUTUBE':
        return '#CD201F';
      case 'TWITTER':
        return 'black';
      case 'INSTAGRAM':
        return '#cc2366';
      case 'Custom':
        return '#dc7b1a';
      default:
        return '#dc7b1a';
    }
  };

  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false); // Default option is "auto"
  const removeItemSocial = (id: any) => {
    const updatedItems = [...formSocial];
    updatedItems.splice(id, 1);
    // const obj = formSocial.filter((value: any) => value[id] !== id);
    // setFormSocial(updatedItems);

    const ratePercent = Math.floor(100 / updatedItems?.length);
    const lastIndex = updatedItems?.length - 1;
    const lastValue = 100 - (updatedItems?.length - 1) * ratePercent;
    const updatedForm = updatedItems.map((element, index) => ({
      ...element,
      percent: index === lastIndex ? lastValue : ratePercent,
    }));
    setFormSocial([...updatedForm]);
    if (formSocial?.length === 0) {
      setIsAddTaskOpen(false);
    }
  };

  useEffect(() => {
    if (formSocial?.length < 1) {
      setIsDisableButtonFacebook(false);
      setIsDisableButtonInstagram(false);
      setIsDisableButtonTwitter(false);
      setIsDisableButtonYoutube(false);
      setIsDisableButtonTiktok(false);
      setIsDisableButtonCustom(true);
    }
  }, [formSocial]);

  const renderPlacholder = (social: any, type: any) => {
    if (social === 'FACEBOOK') {
      switch (type) {
        case 'Like and Comment':
          return facebookLikeCommentPlacholder;
        case 'Review':
          return facebookReview;
        case 'Share':
          return facebookShare;
        case 'Photo':
          return facebookPost;
        case 'Like page':
          return facebookLike;
        case 'Share Group':
          return facebookShareGroup;
        default:
          return '';
      }
    }
    if (social === 'TWITTER') {
      switch (type) {
        case 'Like and Comment':
          return xLikeComment;
        case 'Photo':
          return xPost;
        default:
          return '';
      }
    }
    if (social === 'INSTAGRAM') {
      switch (type) {
        case 'Like and Comment':
          return igLikeComment;
        case 'Photo':
          return igPost;
        default:
          return '';
      }
    }
    if (social === 'YOUTUBE') {
      switch (type) {
        case 'Like and Comment':
          return youtubeLikeComment;
        case 'Video':
          return youtubeVideo;
        case 'SUBSCRIBE':
          return youtubeSub;
        default:
          return '';
      }
    }
    if (social === 'TIKTOK') {
      switch (type) {
        case 'Like and Comment':
          return tiktokLikeComment;
        case 'Video':
          return tiktokVideo;
        default:
          return '';
      }
    }

    return '';
  };
  const renderHeight = (type: any, social: any) => {
    if (social === 'YOUTUBE') {
      switch (type) {
        case 'Video':
          return 240;
        case 'SUBSCRIBE':
          return 80;
        default:
          return 180;
      }
    } else if (social === 'TWITTER') {
      switch (type) {
        case 'Photo':
          return 80;
        case 'Share':
          return 80;
        default:
          return 180;
      }
    } else if (social === 'FACEBOOK' && type === 'Like and Comment') return 180;
    else {
      switch (type) {
        case 'Video':
          return 220;
        case 'Photo':
          return 370;
        case 'Like page':
          return 80;
        default:
          return 180;
      }
    }
  };
  const renderHeight2 = (type: any, social: any) => {
    if (social === 'YOUTUBE') {
      switch (type) {
        case 'Video':
          return 340;
        case 'SUBSCRIBE':
          return 180;
        default:
          return 280;
      }
    } else if (social === 'TWITTER') {
      switch (type) {
        case 'Photo':
          return 180;
        case 'Share':
          return 180;
        default:
          return 280;
      }
    } else {
      switch (type) {
        case 'Video':
          return 320;
        case 'Photo':
          return 470;
        case 'Like page':
          return 180;
        default:
          return 280;
      }
    }
  };

  const socialGroup = () => {
    return (
      <div className="group-social">
        {IsDisableButtonFacebook ? (
          <div className="text-muted font-size-28">
            <button
              type="button"
              className={`each-social ${
                IsDisableButtonFacebook ? 'disabledDiv' : ''
              }`}
              style={{ backgroundColor: '#4267B2', border: '0px' }}
              onClick={facebookDialog}
            >
              <img src={FacebookIcon} alt="facebook" width={15} height={15} />{' '}
              Facebook
            </button>
          </div>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <button
                type="button"
                className="each-social"
                style={{ backgroundColor: '#4267B2', border: '0px' }}
                onClick={facebookDialog}
              >
                <img src={FacebookIcon} alt="facebook" width={15} height={15} />{' '}
                Facebook
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-start w-25">
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Like and Comment',
                      type_social: 'FACEBOOK',
                      task_type_id: 1,
                      title: 'Like and Comment',
                      editorState: rendorEditorState(facebookLikeCommentPlacholder),
                      description: facebookLikeCommentPlacholder,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  }
                }}
              >
                {t('campaignNew.Like and Comment')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Review',
                      type_social: 'FACEBOOK',
                      task_type_id: 2,
                      title: 'Review this facebook page',
                      editorState: rendorEditorState(facebookReview),
                      description: facebookReview,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  }
                }}
              >
                {t('campaignNew.Review')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Share',
                      type_social: 'FACEBOOK',
                      task_type_id: 3,
                      title: 'Share to feed',
                      editorState: rendorEditorState(facebookShare),
                      description: facebookShare,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  }
                }}
              >
                {t('campaignNew.Share')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Photo',
                      type_social: 'FACEBOOK',
                      task_type_id: 4,
                      title: 'Post',
                      editorState: rendorEditorState(facebookPost),
                      description: facebookPost,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  }
                }}
              >
                {t('campaignNew.Post')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Like page',
                      type_social: 'FACEBOOK',
                      task_type_id: 15,
                      title: 'Like page',
                      editorState: rendorEditorState(facebookLike),
                      description: facebookLike,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  }
                }}
              >
                {t('campaignNew.Like page')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Share Group',
                      type_social: 'FACEBOOK',
                      task_type_id: 16,
                      title: 'Share to group',
                      editorState: rendorEditorState(facebookShareGroup),
                      description: facebookShareGroup,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  }
                }}
              >
                {t('campaignNew.Share Group')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {IsDisableButtonInstagram ? (
          <div className="text-muted font-size-28">
            <button
              type="button"
              disabled={IsDisableButtonInstagram}
              className={`each-social instagram ${
                IsDisableButtonInstagram ? 'disabledDiv' : ''
              }`}
              style={{ border: '0px' }}
            >
              {' '}
              <img
                src={InstargramIcon}
                alt="Instagram"
                width={15}
                height={15}
              />{' '}
              Instagram
            </button>
          </div>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <button
                type="button"
                className="each-social instagram "
                style={{ border: '0px' }}
              >
                <img src={InstargramIcon} alt="Instagram" width={15} height={15} />
                Instagram
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-start w-25">
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Like and Comment',
                      type_social: 'INSTAGRAM',
                      task_type_id: 5,
                      title: 'Like and Comment',
                      editorState: rendorEditorState(igLikeComment),
                      description: igLikeComment,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Like and Comment')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Photo',
                      type_social: 'INSTAGRAM',
                      task_type_id: 6,
                      title: 'Post',
                      editorState: rendorEditorState(igPost),
                      description: igPost,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Post')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {IsDisableButtonTwitter ? (
          <div className="text-muted font-size-28">
            <button
              type="button"
              className={`each-social ${
                IsDisableButtonTwitter ? 'disabledDiv' : ''
              }`}
              style={{ backgroundColor: 'black', border: '0px' }}
            >
              {' '}
              <img src={TwitterIcon} alt="Twitter" width={15} height={15} />X
            </button>{' '}
          </div>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <button
                type="button"
                className="each-social"
                style={{ backgroundColor: 'black', border: '0px' }}
              >
                {' '}
                <img src={TwitterIcon} alt="Twitter" width={15} height={15} />X
              </button>{' '}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-start w-25">
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Like and Comment',
                      type_social: 'TWITTER',
                      task_type_id: 7,
                      title: 'Like and Comment',
                      editorState: rendorEditorState(xLikeComment),
                      description: xLikeComment,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Like and Comment')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Photo',
                      type_social: 'TWITTER',
                      task_type_id: 8,
                      title: 'Post ',
                      editorState: rendorEditorState(xPost),
                      description: xPost,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Post')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Share',
                      type_social: 'TWITTER',
                      task_type_id: 17,
                      title: 'Quote',
                      editorState: rendorEditorState(xPost),
                      description: xPost,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Quote')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}

        {IsDisableButtonYoutube ? (
          <div className="text-muted font-size-28">
            <button
              type="button"
              className={`each-social ${
                IsDisableButtonYoutube ? 'disabledDiv' : ''
              }`}
              style={{ backgroundColor: '#CD201F', border: '0px' }}
            >
              {' '}
              <img src={YoutubeIcon} alt="Youtube" width={15} height={15} />
              Youtube
            </button>
          </div>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <button
                type="button"
                className="each-social"
                style={{ backgroundColor: '#CD201F', border: '0px' }}
              >
                {' '}
                <img src={YoutubeIcon} alt="Youtube" width={15} height={15} />
                Youtube
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-start w-25">
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Like and Comment',
                      type_social: 'YOUTUBE',
                      task_type_id: 9,
                      title: 'Like and Comment',
                      editorState: rendorEditorState(youtubeLikeComment),
                      description: youtubeLikeComment,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Like and Comment')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Video',
                      type_social: 'YOUTUBE',
                      task_type_id: 10,
                      title: 'Record Video',
                      editorState: rendorEditorState(youtubeVideo),
                      description: youtubeVideo,
                    },
                  ];

                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);

                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Video')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'SUBSCRIBE',
                      type_social: 'YOUTUBE',
                      task_type_id: 11,
                      title: 'Subscribe',
                      editorState: rendorEditorState(youtubeSub),
                      description: youtubeSub,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonTiktok(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Subscribe')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}

        {IsDisableButtonTiktok ? (
          <div className="text-muted font-size-28">
            <button
              type="button"
              className={`each-social ${IsDisableButtonTiktok ? 'disabledDiv' : ''}`}
              style={{ backgroundColor: 'black', border: '0px' }}
            >
              {' '}
              <img src={TiktokIcon} alt="Tiktok" width={15} height={15} />
              Tiktok
            </button>
          </div>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <button
                type="button"
                className="each-social"
                style={{ backgroundColor: 'black', border: '0px' }}
              >
                {' '}
                <img src={TiktokIcon} alt="Tiktok" width={15} height={15} />
                Tiktok
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-start w-25">
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Video',
                      type_social: 'TIKTOK',
                      task_type_id: 12,
                      title: 'Record Video',
                      editorState: rendorEditorState(tiktokVideo),
                      description: tiktokVideo,
                    },
                  ];
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Video')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const _formSocial = [
                    ...formSocial,
                    {
                      content_type: 'Like and Comment',
                      type_social: 'TIKTOK',
                      task_type_id: 13,
                      title: 'Like and Comment',
                      editorState: rendorEditorState(tiktokLikeComment),
                      description: tiktokLikeComment,
                    },
                  ];
                  // setFormSocial([
                  //   ...formSocial,
                  //   {
                  //     content_type: 'Like and Comment',
                  //     type_social: 'TIKTOK',
                  //     task_type_id: 13,
                  //     title: 'Like and Comment tiktok video',
                  //     description: `${tiktokYoutubeWorkOrder}`,
                  //     percent: formSocial ? 100 : 0,
                  //   },
                  // ]);
                  const ratePercent = Math.floor(100 / _formSocial?.length);
                  const lastIndex = _formSocial?.length - 1;
                  const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                  const updatedForm = _formSocial.map((element, index) => ({
                    ...element,
                    percent: index === lastIndex ? lastValue : ratePercent,
                  }));
                  setFormSocial([...updatedForm]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonCustom(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Like and Comment')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}

        {IsDisableButtonCustom ? (
          <div className="text-muted font-size-28 d-lg-none">
            <button
              type="button"
              className={`each-social ${IsDisableButtonCustom ? 'disabledDiv' : ''}`}
              style={{
                backgroundColor: '#dc7b1a',
                border: '0px',
              }}
            >
              <img
                src={CustomIcon}
                alt="Custom"
                width={15}
                height={15}
                style={{ borderRadius: '50%' }}
              />
              etc.
            </button>
          </div>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28 d-lg-none" tag="a">
              <button
                type="button"
                className={`each-social ${
                  IsDisableButtonCustom ? 'disabledDiv' : ''
                }`}
                style={{
                  backgroundColor: '#dc7b1a',
                  border: '0px',
                }}
              >
                <img
                  src={CustomIcon}
                  alt="Custom"
                  width={15}
                  height={15}
                  style={{ borderRadius: '50%' }}
                />
                Etc.
              </button>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu-start w-25"
              style={{ cursor: '' }}
            >
              <DropdownItem
                onClick={() => {
                  setFormSocial([
                    ...formSocial,
                    {
                      content_type: 'Custom',
                      type_social: 'NOSOCIAL',
                      task_type_id: 14,
                      title: 'Custom campaign',
                      editorState: rendorEditorState('NoSocial'),
                    },
                  ]);
                  if (selectedOption === 'fixed') {
                    setIsDisableButtonFacebook(true);
                    setIsDisableButtonInstagram(true);
                    setIsDisableButtonTwitter(true);
                    setIsDisableButtonYoutube(true);
                    setIsDisableButtonTiktok(true);
                  } else {
                    setIsDisableButtonFacebook(false);
                    setIsDisableButtonInstagram(false);
                    setIsDisableButtonTwitter(false);
                    setIsDisableButtonYoutube(false);
                    setIsDisableButtonTiktok(false);
                  }
                }}
              >
                {t('campaignNew.Post')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </div>
    );
  };

  const renderTask = () => {
    return (
      <div className="task-wrapper">
        <div style={{ color: 'white' }}>{t('campaignNew.+ Add a way to enter')}</div>
        <div className="group-social">
          {IsDisableButtonFacebook ? (
            <div className="text-muted font-size-28">
              <button
                type="button"
                className={`each-social ${
                  IsDisableButtonFacebook ? 'disabledDiv' : ''
                }`}
                style={{
                  backgroundColor: '#4267B2',
                  border: '0px',
                }}
                onClick={facebookDialog}
              >
                <img src={FacebookIcon} alt="facebook" width={15} height={15} />{' '}
                Facebook
              </button>
            </div>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <button
                  type="button"
                  className="each-social"
                  style={{
                    backgroundColor: '#4267B2',
                    border: '0px',
                    borderRadius: '50px',
                  }}
                  onClick={facebookDialog}
                >
                  <img src={FacebookIcon} alt="facebook" width={15} height={15} />{' '}
                  Facebook
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start w-25">
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Like and Comment',
                        type_social: 'FACEBOOK',
                        task_type_id: 1,
                        title: 'Like and Comment',
                        editorState: rendorEditorState(
                          facebookLikeCommentPlacholder,
                        ),
                        description: facebookLikeCommentPlacholder,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    }
                  }}
                >
                  {t('campaignNew.Like and Comment')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Review',
                        type_social: 'FACEBOOK',
                        task_type_id: 2,
                        title: 'Review',
                        editorState: rendorEditorState(facebookReview),
                        description: facebookReview,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    }
                  }}
                >
                  {t('campaignNew.Review')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Share',
                        type_social: 'FACEBOOK',
                        task_type_id: 3,
                        title: 'Share to feed',
                        editorState: rendorEditorState(facebookShare),
                        description: facebookShare,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    }
                  }}
                >
                  {t('campaignNew.Share')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Photo',
                        type_social: 'FACEBOOK',
                        task_type_id: 4,
                        title: 'Post',
                        editorState: rendorEditorState(facebookPost),
                        description: facebookPost,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    }
                  }}
                >
                  {t('campaignNew.Post')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Like page',
                        type_social: 'FACEBOOK',
                        task_type_id: 15,
                        title: 'Like page',
                        editorState: rendorEditorState(facebookLike),
                        description: facebookLike,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    }
                  }}
                >
                  {t('campaignNew.Like page')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Share Group',
                        type_social: 'FACEBOOK',
                        task_type_id: 16,
                        title: 'Share to group',
                        editorState: rendorEditorState(facebookShareGroup),
                        description: facebookShareGroup,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    }
                  }}
                >
                  {t('campaignNew.Share Group')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {IsDisableButtonInstagram ? (
            <div className="text-muted font-size-28">
              <button
                type="button"
                disabled={IsDisableButtonInstagram}
                className={`each-social instagram ${
                  IsDisableButtonInstagram ? 'disabledDiv' : ''
                }`}
                style={{ border: '0px' }}
              >
                {' '}
                <img
                  src={InstargramIcon}
                  alt="Instagram"
                  width={15}
                  height={15}
                />{' '}
                Instagram
              </button>
            </div>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <button
                  type="button"
                  className="each-social instagram "
                  style={{ border: '0px', borderRadius: '50px' }}
                >
                  <img src={InstargramIcon} alt="Instagram" width={15} height={15} />
                  Instagram
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start w-25">
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Like and Comment',
                        type_social: 'INSTAGRAM',
                        task_type_id: 5,
                        title: 'Like and Comment',
                        editorState: rendorEditorState(igLikeComment),
                        description: igLikeComment,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Like and Comment')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Photo',
                        type_social: 'INSTAGRAM',
                        task_type_id: 6,
                        title: 'Post',
                        editorState: rendorEditorState(igPost),
                        description: igPost,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Post')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {IsDisableButtonTwitter ? (
            <div className="text-muted font-size-28">
              <button
                type="button"
                className={`each-social ${
                  IsDisableButtonTwitter ? 'disabledDiv' : ''
                }`}
                style={{ backgroundColor: 'black', border: '0px' }}
              >
                {' '}
                <img src={TwitterIcon} alt="Twitter" width={15} height={15} />X
              </button>{' '}
            </div>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <button
                  type="button"
                  className="each-social"
                  style={{
                    backgroundColor: 'black',
                    border: '1px solid white',
                    borderRadius: '50px',
                  }}
                >
                  {' '}
                  <img src={TwitterIcon} alt="Twitter" width={15} height={15} />X
                </button>{' '}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start w-25">
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Like and Comment',
                        type_social: 'TWITTER',
                        task_type_id: 7,
                        title: 'Like and Comment',
                        editorState: rendorEditorState(xLikeComment),
                        description: xLikeComment,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Like and Comment')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Photo',
                        type_social: 'TWITTER',
                        task_type_id: 8,
                        title: 'Post ',
                        editorState: rendorEditorState(xPost),
                        description: xPost,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Post')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Share',
                        type_social: 'TWITTER',
                        task_type_id: 17,
                        title: 'Quote ',
                        editorState: rendorEditorState(xPost),
                        description: xPost,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Quote')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}

          {IsDisableButtonYoutube ? (
            <div className="text-muted font-size-28">
              <button
                type="button"
                className={`each-social ${
                  IsDisableButtonYoutube ? 'disabledDiv' : ''
                }`}
                style={{ backgroundColor: '#CD201F', border: '0px' }}
              >
                {' '}
                <img src={YoutubeIcon} alt="Youtube" width={15} height={15} />
                Youtube
              </button>
            </div>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <button
                  type="button"
                  className="each-social"
                  style={{
                    backgroundColor: '#CD201F',
                    border: '0px',
                    borderRadius: '50px',
                  }}
                >
                  {' '}
                  <img src={YoutubeIcon} alt="Youtube" width={15} height={15} />
                  Youtube
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start w-25">
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Like and Comment',
                        type_social: 'YOUTUBE',
                        task_type_id: 9,
                        title: 'Like and Comment',
                        editorState: rendorEditorState(youtubeLikeComment),
                        description: youtubeLikeComment,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Like and Comment')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Video',
                        type_social: 'YOUTUBE',
                        task_type_id: 10,
                        title: 'Record Video',
                        editorState: rendorEditorState(youtubeVideo),
                        description: youtubeVideo,
                      },
                    ];

                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);

                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Video')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'SUBSCRIBE',
                        type_social: 'YOUTUBE',
                        task_type_id: 11,
                        title: 'Subscribe',
                        editorState: rendorEditorState(youtubeSub),
                        description: youtubeSub,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonTiktok(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Subscribe')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}

          {IsDisableButtonTiktok ? (
            <div className="text-muted font-size-28">
              <button
                type="button"
                className={`each-social ${
                  IsDisableButtonTiktok ? 'disabledDiv' : ''
                }`}
                style={{ backgroundColor: 'black', border: '0px' }}
              >
                {' '}
                <img src={TiktokIcon} alt="Tiktok" width={15} height={15} />
                Tiktok
              </button>
            </div>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <button
                  type="button"
                  className="each-social"
                  style={{
                    backgroundColor: 'black',
                    border: '1px solid white',
                    borderRadius: '50px',
                  }}
                >
                  {' '}
                  <img src={TiktokIcon} alt="Tiktok" width={15} height={15} />
                  Tiktok
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start w-25">
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Video',
                        type_social: 'TIKTOK',
                        task_type_id: 12,
                        title: 'Record Video',
                        editorState: rendorEditorState(tiktokVideo),
                        description: tiktokVideo,
                      },
                    ];
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Video')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const _formSocial = [
                      ...formSocial,
                      {
                        content_type: 'Like and Comment',
                        type_social: 'TIKTOK',
                        task_type_id: 13,
                        title: 'Like and Comment',
                        editorState: rendorEditorState(tiktokLikeComment),
                        description: tiktokLikeComment,
                      },
                    ];
                    // setFormSocial([
                    //   ...formSocial,
                    //   {
                    //     content_type: 'Like and Comment',
                    //     type_social: 'TIKTOK',
                    //     task_type_id: 13,
                    //     title: 'Like and Comment tiktok video',
                    //     description: `${tiktokYoutubeWorkOrder}`,
                    //     percent: formSocial ? 100 : 0,
                    //   },
                    // ]);
                    const ratePercent = Math.floor(100 / _formSocial?.length);
                    const lastIndex = _formSocial?.length - 1;
                    const lastValue = 100 - (_formSocial?.length - 1) * ratePercent;
                    const updatedForm = _formSocial.map((element, index) => ({
                      ...element,
                      percent: index === lastIndex ? lastValue : ratePercent,
                    }));
                    setFormSocial([...updatedForm]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonCustom(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Like and Comment')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}

          {IsDisableButtonCustom ? (
            <div className="text-muted font-size-28 d-lg-none">
              <button
                type="button"
                className={`each-social ${
                  IsDisableButtonCustom ? 'disabledDiv' : ''
                }`}
                style={{
                  backgroundColor: '#dc7b1a',
                  border: '0px',
                }}
              >
                <img
                  src={CustomIcon}
                  alt="Custom"
                  width={15}
                  height={15}
                  style={{ borderRadius: '50%' }}
                />
                etc.
              </button>
            </div>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28 d-lg-none" tag="a">
                <button
                  type="button"
                  className={`each-social ${
                    IsDisableButtonCustom ? 'disabledDiv' : ''
                  }`}
                  style={{
                    backgroundColor: '#dc7b1a',
                    border: '0px',
                  }}
                >
                  <img
                    src={CustomIcon}
                    alt="Custom"
                    width={15}
                    height={15}
                    style={{ borderRadius: '50%' }}
                  />
                  Etc.
                </button>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-start w-25"
                style={{ cursor: '' }}
              >
                <DropdownItem
                  onClick={() => {
                    setFormSocial([
                      ...formSocial,
                      {
                        content_type: 'Custom',
                        type_social: 'NOSOCIAL',
                        task_type_id: 14,
                        title: 'Custom campaign',
                        editorState: rendorEditorState('NoSocial'),
                      },
                    ]);
                    if (selectedOption === 'fixed') {
                      setIsDisableButtonFacebook(true);
                      setIsDisableButtonInstagram(true);
                      setIsDisableButtonTwitter(true);
                      setIsDisableButtonYoutube(true);
                      setIsDisableButtonTiktok(true);
                    } else {
                      setIsDisableButtonFacebook(false);
                      setIsDisableButtonInstagram(false);
                      setIsDisableButtonTwitter(false);
                      setIsDisableButtonYoutube(false);
                      setIsDisableButtonTiktok(false);
                    }
                  }}
                >
                  {t('campaignNew.Post')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {formSocial.map((item: any, index: any) => {
            // let _editorState: any;
            // if (formSocial[index].description) {
            //   const blockFromHTML = convertFromHTML(formSocial[index].description);
            //   _editorState = EditorState.createWithContent(
            //     ContentState.createFromBlockArray(
            //       blockFromHTML?.contentBlocks,
            //       blockFromHTML?.entityMap,
            //     ),
            //   );
            // } else {
            //   _editorState = EditorState.createEmpty();
            // }
            // const _contentState = ContentState.createFromText(
            //   formSocial[index].description,
            // );
            // const raw = convertToRaw(_contentState);
            // const _html = contentFromHTML(formSocial[index].description);
            return (
              <div
                className="formSocialTask"
                style={{
                  height: `${renderHeight2(
                    item?.content_type,
                    item?.type_social,
                  )}px`,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    backgroundColor: `${renderColorSocial(item.type_social)}`,
                    padding: '10px',
                    borderRadius: '5px',
                    justifyContent: 'space-between',
                    border:
                      item.type_social === 'TWITTER' || item.type_social === 'TIKTOK'
                        ? '1px solid white'
                        : '0',
                  }}
                >
                  <div>
                    <img
                      src={renderIconSocial(item.type_social)}
                      alt="Tiktok"
                      width={25}
                      height={25}
                      style={{ borderRadius: '50%' }}
                    />
                    <span style={{ marginLeft: '10px' }}>
                      {item.content_type === 'Photo' ? 'Post' : item?.content_type}
                    </span>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'baseline', gap: '30px' }}
                  >
                    <div
                      className="form-percent"
                      style={{ display: 'flex', padding: '9px' }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>{`${
                        item.type_social !== 'NOSOCIAL'
                          ? 'Percent Prize'
                          : currencySymbol
                      }`}</span>
                      <Tooltip
                        title={
                          <>
                            <h4 style={{ color: 'white' }}>
                              {t('tooltip.percent head')}{' '}
                            </h4>
                            <h4 style={{ color: 'white' }}>
                              {t('tooltip.percent ex')}{' '}
                            </h4>
                            <h4 style={{ color: 'white' }}>
                              {t('tooltip.percent ex1')}{' '}
                            </h4>
                            <h4 style={{ color: 'white' }}>
                              {t('tooltip.percent ex2')}{' '}
                            </h4>
                            <h4 style={{ color: 'white' }}>
                              {t('tooltip.percent ex3')}{' '}
                            </h4>
                          </>
                        }
                        placement="right"
                      >
                        <i
                          className="fas fa-info-circle ms-2 font-size-16]"
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        value={floorToFixed(formSocial[index].percent || null, 2)}
                        onChange={(e) => {
                          const updatedFormSocial = [...formSocial];
                          updatedFormSocial[index] = {
                            ...updatedFormSocial[index],
                            percent: Number(e.currentTarget.value),
                          };
                          setFormSocial(updatedFormSocial);
                        }}
                        style={{ padding: '0px 6px', marginLeft: '8px' }}
                      />
                    </div>
                    <div
                      className="remove-social-form-button"
                      onClick={() => {
                        removeItemSocial(index);
                        if (formSocial?.length === 1) {
                          setIsAddTaskOpen(false);
                        }
                      }}
                    >
                      {t('campaignNew.Remove')}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  {/* <div className="data-form-each-social ">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',

                        width: '100%',
                        gap: '20px',
                      }}
                    >
                      {/* <div className="form-title" style={{ flex: '3' }}>
                        {t('campaignNew.Title')}
                        <input
                          className="form-control"
                          style={{ width: '100', maxWidth: '850px' }}
                          value={formSocial[index].title}
                          onChange={(e) => {
                            const updatedFormSocial = [...formSocial];
                            updatedFormSocial[index] = {
                              ...updatedFormSocial[index],
                              title: e.currentTarget.value,
                            };
                            setFormSocial(updatedFormSocial);
                          }}
                        />
                      </div> 
                       <div className="form-percent">
                        {`${
                          item.type_social !== 'NOSOCIAL'
                            ? 'Percent Prize'
                            : currencySymbol
                        }`}
                        <Tooltip
                          title={
                            <>
                              <h4 style={{ color: 'white' }}>
                                {t('tooltip.percent head')}{' '}
                              </h4>
                              <h4 style={{ color: 'white' }}>
                                {t('tooltip.percent ex')}{' '}
                              </h4>
                              <h4 style={{ color: 'white' }}>
                                {t('tooltip.percent ex1')}{' '}
                              </h4>
                              <h4 style={{ color: 'white' }}>
                                {t('tooltip.percent ex2')}{' '}
                              </h4>
                              <h4 style={{ color: 'white' }}>
                                {t('tooltip.percent ex3')}{' '}
                              </h4>
                            </>
                          }
                          placement="right"
                        >
                          <i
                            className="fas fa-info-circle ms-2 font-size-16]"
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          value={floorToFixed(formSocial[index].percent || null, 2)}
                          onChange={(e) => {
                            const updatedFormSocial = [...formSocial];
                            updatedFormSocial[index] = {
                              ...updatedFormSocial[index],
                              percent: Number(e.currentTarget.value),
                            };
                            setFormSocial(updatedFormSocial);
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div
                    className="text-editer"
                    style={{
                      height: `${renderHeight(
                        item?.content_type,
                        item.type_social,
                      )}px`,
                    }}
                  >
                    {/* <ReactQuill
                      style={{
                        height: `${renderHeight(
                          item?.content_type,
                          item.type_social,
                        )}px`,
                      }}
                      modules={modules}
                      placeholder={renderPlacholder(
                        item.type_social,
                        item.content_type,
                      )}
                      value={formSocial[index].description}
                      onChange={(e) => handleUpdateReactQuill(e, index)}
                      // onChange={(e) => {
                      //   handleUpdateReactQuill(e, index);
                      //   // const updatedFormSocial = [...formSocial];
                      //   // updatedFormSocial[index] = {
                      //   //   ...updatedFormSocial[index],
                      //   //   description: e,
                      //   // };
                      //   // setFormSocial(updatedFormSocial);
                      // }}
                    /> */}
                    <span style={{ color: 'white' }}>{t('campaignNew.Order')}</span>
                    <Editor
                      editorStyle={{
                        height: `${renderHeight(
                          item?.content_type,
                          item.type_social,
                        )}px`,
                      }}
                      //defaultContentState={_html}

                      editorState={item.editorState}
                      toolbarHidden
                      editorClassName="editor-class"
                      wrapperClassName="wrapper-class"
                      toolbar={{
                        options: [],
                      }}
                      hashtag={{
                        separator: ' ',
                        trigger: '#',
                      }}
                      placeholder={renderPlacholder(
                        item.type_social,
                        item.content_type,
                      )}
                      onEditorStateChange={(e: any) => OnEditorStateChange(e, index)}
                      handlePastedText={() => false}
                      //onChange={(e: any) => handleUpdateReactQuill(e, index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {formSocial?.length > 0 && (
          <div
            style={{ cursor: 'pointer', color: 'white' }}
            onClick={() => {
              setIsAddTaskOpen(true);
            }}
          >
            {t('campaignNew.+ Add more task')}
          </div>
        )}
        {isAddTaskOpen ? socialGroup() : <></>}
      </div>
    );
  };

  const OnEditorStateChange = (e: any, index: number) => {
    const updatedFormSocial = [...formSocial];
    updatedFormSocial[index] = {
      ...updatedFormSocial[index],
      description: convertToHTML(e.getCurrentContent()),
      editorState: e,
    };
    setFormSocial(updatedFormSocial);
  };

  const handleUpdateReactQuill = (val: any, index: number) => {
    // const description = val.replaceAll(/<\/?p[^>]*>/g, '').replace('<br>', '');
    // const updatedFormSocial = [...formSocial];
    // updatedFormSocial[index] = {
    //   ...updatedFormSocial[index],
    //   description,
    //   editorState: val
    // };
    // setFormSocial(updatedFormSocial);
  };

  const [selectedOption, setSelectedOption] = useState('auto'); // Default option is "auto"

  useEffect(() => {
    if (jobEdit && jobEdit.job_tasks && jobId) {
      setSelectedOption(jobEdit?.fixed_price !== null ? 'fixed' : 'auto');
      if (jobEdit?.fixed_price !== null) {
        if (jobEdit?.job_tasks?.length > 0) {
          switch (jobEdit.job_tasks[0].task_type.socialmedia_type) {
            case 'FACEBOOK':
              setIsDisableButtonFacebook(false);
              setIsDisableButtonInstagram(true);
              setIsDisableButtonTwitter(true);
              setIsDisableButtonYoutube(true);
              setIsDisableButtonTiktok(true);
              break;
            case 'INSTAGRAM':
              setIsDisableButtonFacebook(true);
              setIsDisableButtonInstagram(false);
              setIsDisableButtonTwitter(true);
              setIsDisableButtonYoutube(true);
              setIsDisableButtonTiktok(true);
              break;
            case 'TWITTER':
              setIsDisableButtonFacebook(true);
              setIsDisableButtonInstagram(true);
              setIsDisableButtonTwitter(false);
              setIsDisableButtonYoutube(true);
              setIsDisableButtonTiktok(true);
              break;
            case 'YOUTUBE':
              setIsDisableButtonFacebook(true);
              setIsDisableButtonInstagram(true);
              setIsDisableButtonTwitter(true);
              setIsDisableButtonYoutube(false);
              setIsDisableButtonTiktok(true);
              break;
            case 'TIKTOK':
              setIsDisableButtonFacebook(true);
              setIsDisableButtonInstagram(true);
              setIsDisableButtonTwitter(true);
              setIsDisableButtonYoutube(true);
              setIsDisableButtonTiktok(false);
              break;
            default:
              break;
          }
        }
      }
    }
  }, [jobEdit]);
  //console.log('selectedOption', selectedOption);
  const handleDropdownChange = (e: any) => {
    const selectedPrize = e.target.value;
    //console.log('selectedPrize', selectedPrize);
    if (formSocial?.length > 0) {
      Swal.fire({
        title: t('swal:Are you sure ?'),
        text: t(`swal:Change Prize`),
        imageUrl: choice,
        width: 300,
        imageHeight: 200,
        imageAlt: 'A tall image',
        showCancelButton: true,
        cancelButtonColor: 'red',
        confirmButtonColor: '#009a78',
        customClass: {},
        confirmButtonText: t(`button:Yes`),
        cancelButtonText: t('swal:Cancel'),
      }).then((result: any) => {
        if (result.isConfirmed) {
          setSelectedOption(selectedPrize); // Update the selected option when dropdown value changes
          if (selectedPrize === 'auto') {
            setFormValues({ ...formValue, fixed_price: null, topup_percent: 0 });
          } else {
            setFormValues({ ...formValue, fixed_price: 0, topup_percent: null });
          }
          setFormSocial([]);
        }
      });
    } else {
      setSelectedOption(selectedPrize); // Update the selected option when dropdown value changes
      setFormSocial([]);
      if (selectedPrize === 'auto') {
        setFormValues({ ...formValue, fixed_price: null, topup_percent: 0 });
      } else {
        setFormValues({ ...formValue, fixed_price: 0, topup_percent: null });
      }
    }
  };

  const settingCurrencySymbol = () => {
    if (marketerCurrencies?.length) {
      const dataReturn = marketerCurrencies.filter(
        (index: any) =>
          index.currency.id === formValue?.currency_id || formValue?.currency?.id,
      );

      if (dataReturn?.length) {
        setCurrencySymbol(dataReturn[0].currency.symbol);
        setCurrencyId(dataReturn[0].currency.id);
      }
    }
  };

  useEffect(() => {
    settingCurrencySymbol();
  }, [formValue.currency_id]);

  useEffect(() => {
    setFormValues({ ...formValue, country_names: arrayCountry });
  }, [arrayCountry]);

  useEffect(() => {
    //console.log('formSocials', formSocial);
    const formSocials = formSocial.map(
      ({ type_social, content_type, ...rest }: any) => rest,
    );

    setFormValues({ ...formValue, job_tasks: formSocials });
  }, [formSocial]);

  const renderPrice = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          color: 'white',
        }}
      >
        <div>
          <div>{t('campaignNew.Token')} </div>
          <div style={{ width: '50%' }}>
            {' '}
            <DropdownSearch
              options={marketerCurrencies}
              formData={formValue}
              setFormData={setFormValues}
              currencySymbol={currencySymbol}
              jobId={jobId}
            />{' '}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div>
            <div>{t('campaignNew.Prizes')}</div>

            <select
              id="priceType"
              className="form-control form-select border-radius-control"
              style={{ cursor: 'pointer' }}
              value={selectedOption}
              onChange={handleDropdownChange}
            >
              <option value="auto">{t('Auto')}</option>
              <option value="fixed">{t('Fixed')}</option>
            </select>
          </div>
          <div>
            {selectedOption === 'auto' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{t('campaignNew.Top up (%)')}</div>
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    pattern="/[^0-9.]/g"
                    step="any"
                    max={100}
                    min={0}
                    style={{ width: '200px' }}
                    value={formValue.topup_percent || null}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValue,
                        topup_percent: Number(e.currentTarget.value),
                        fixed_price: null,
                      });
                    }}
                    onKeyDown={(evt) => {
                      const restrictedKeys = ['e', 'E', '+', '-'];
                      if (restrictedKeys.includes(evt.key)) {
                        evt.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div style={{ visibility: 'hidden' }}>
                  {t('campaignNew.Fixedprice Rate')}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    className="form-control"
                    type="number"
                    pattern="/[^0-9.]/g"
                    min={0}
                    style={{ width: '200px' }}
                    value={formValue.fixed_price || null}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValue,
                        fixed_price: Number(e.currentTarget.value),
                        topup_percent: null,
                      });

                      // setFormValues({ ...formValue, topup_percent: null });
                    }}
                    onKeyDown={(evt) => {
                      const restrictedKeys = ['e', 'E', '+', '-'];
                      if (restrictedKeys.includes(evt.key)) {
                        evt.preventDefault();
                      }
                    }}
                  />
                  <div style={{ marginLeft: '5px' }}>{currencySymbol}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedOption === 'auto' && (
          <TopUpTable
            currency_id={currencyId}
            topup={formValue.topup_percent}
            currency={availableCurrencies}
          />
        )}

        <div
          style={{ marginTop: '20px' }}
          onClick={() => {
            setTab(4);
          }}
        >
          {' '}
          <AllButton size="sp" status="all" label={t('campaignNew.next')} />
        </div>
      </div>
    );
  };

  const onSubmit = async () => {
    const formSocials = formSocial.map(
      ({ type_social, content_type, ...rest }: any) => rest,
    );
    setFormValues({ ...formValue, job_tasks: formSocials });
  };

  const onSubmitDraft = async () => {
    dispatch(setLoading());
    if (!formValue.name) {
      notify(i18n.t('error:Please enter name'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }

    const _job_tasks = formValue.job_tasks;
    for (let i = 0; i < _job_tasks?.length; i++) {
      delete _job_tasks[i].editorState;
    }

    //console.log('prepare jobEdit', jobEdit);
    //console.log('prepare formValue', formValue);
    const payload = {
      ...(formValue.aptitude_ids !== jobEdit.aptitude_ids
        ? { aptitude_ids: formValue.aptitude_ids }
        : {}),
      ...(formValue.country_names !== jobEdit.country_names
        ? { country_names: formValue.country_names }
        : {}),
      ...(formValue.credit_term !== jobEdit.credit_term
        ? { credit_term: formValue.credit_term }
        : {}),
      ...(formValue.currency_id !== jobEdit.currency_id
        ? { currency_id: formValue.currency_id }
        : {}),
      ...(formValue.date_submission !== jobEdit.date_submission
        ? { date_submission: formValue.date_submission }
        : {}),
      ...(formValue.finish_internal_work !== jobEdit.finish_internal_work
        ? { finish_internal_work: formValue.finish_internal_work }
        : {}),
      ...(formValue.fixed_price !== null &&
      formValue.fixed_price !== jobEdit.fixed_price
        ? { fixed_price: formValue.fixed_price, topup_percent: null }
        : {}),
      ...(formValue.is_female !== jobEdit.is_female
        ? { is_female: formValue.is_female }
        : {}),
      ...(formValue.is_lgbt !== jobEdit.is_lgbt
        ? { is_lgbt: formValue.is_lgbt }
        : {}),
      ...(formValue.is_male !== jobEdit.is_male
        ? { is_male: formValue.is_male }
        : {}),
      job_tasks: _job_tasks,
      ...(formValue.job_type !== jobEdit.job_type
        ? { job_type: formValue.job_type }
        : {}),
      ...(formValue.last_day_accept_job !== jobEdit.last_day_accept_job
        ? { last_day_accept_job: formValue.last_day_accept_job }
        : {}),
      ...(formValue.last_day_approve_job !== jobEdit.last_day_approve_job
        ? { last_day_approve_job: formValue.last_day_approve_job }
        : {}),
      ...(formValue.last_day_influencer_submit !== jobEdit.last_day_influencer_submit
        ? { last_day_influencer_submit: formValue.last_day_influencer_submit }
        : {}),
      ...(formValue.last_day_to_approve !== jobEdit.last_day_to_approve
        ? { last_day_to_approve: formValue.last_day_to_approve }
        : {}),
      ...(formValue.name !== jobEdit.name ? { name: formValue.name } : {}),
      ...(formValue.thumbnail_cover !== jobEdit.thumbnail_cover
        ? { thumbnail_cover: formValue.thumbnail_cover }
        : {}),
      ...(formValue.timezone !== jobEdit.timezone
        ? { timezone: formValue.timezone }
        : {}),
      ...(formValue.timezone_offset !== jobEdit.timezone_offset
        ? { timezone_offset: formValue.timezone_offset }
        : {}),
      ...(formValue.topup_percent !== null &&
      formValue.topup_percent !== jobEdit.topup_percent
        ? { topup_percent: formValue.topup_percent, fixed_price: null }
        : {}),
      ...(formValue.work_order !== jobEdit.work_order
        ? { work_order: formValue.work_order }
        : {}),
    };
    //console.log('payload draft', payload);
    if (jobEdit && jobId) {
      await updateGleamJobGQL({ ...payload, key: jobId });
      notify(i18n.t('Save as draft success'), 'success');
      dispatch(unsetLoading());
      history.push('/campaigns/markerter/job');
    } else {
      await createGleamJobGQL({ ...formValue, saveType: 'DRAFT' });
      notify(i18n.t('Save as draft success'), 'success');
      dispatch(unsetLoading());
      history.push('/campaigns/markerter/job');
    }
  };

  const onSubmitLanch = async () => {
    dispatch(setLoading());
    if (!formValue.name) {
      notify(i18n.t('error:Please enter name'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }
    if (!formValue.work_order) {
      notify(i18n.t('error:Please enter work order'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }
    if (!formValue.thumbnail_cover) {
      notify(i18n.t('error:Please enter Thumbnail'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }
    if (!formValue.date_submission) {
      notify(i18n.t('error:Please enter start date'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }
    if (!formValue.last_day_approve_job) {
      setTab(1);
      notify(i18n.t('error:Please enter end date'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (formValue.last_day_approve_job < formValue.date_submission) {
      setTab(1);
      notify(i18n.t('error:Date start must less than date end'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (
      new Date(formValue.last_day_to_approve) <
        new Date(formValue.last_day_accept_job) ||
      new Date(formValue.last_day_to_approve) <
        new Date(formValue.last_day_influencer_submit) ||
      new Date(formValue.last_day_to_approve) <
        new Date(formValue.last_day_approve_job)
    ) {
      setTab(1);
      notify(i18n.t('error:Please enter Advanced date < end date'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (
      new Date(formValue.date_submission) >
        new Date(formValue.last_day_accept_job) ||
      new Date(formValue.date_submission) >
        new Date(formValue.last_day_influencer_submit) ||
      new Date(formValue.date_submission) > new Date(formValue.last_day_approve_job)
    ) {
      setTab(1);
      notify(i18n.t('error:Please enter Advanced date < end date'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!formValue.credit_term) {
      notify(i18n.t('error:Please enter credit term'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }
    if (formValue.credit_term < 3 || formValue.credit_term > 30) {
      notify(i18n.t('Please enter number between 3 - 30'), 'error');
      setTab(1);
      dispatch(unsetLoading());
      return;
    }
    // if (!formValue.is_male || !formValue.is_female || !formValue.is_lgbt) {
    //   notify(i18n.t('error:Please select at least 1 gender '), 'error');
    //   dispatch(unsetLoading());
    //   return;
    // }
    if (formValue.country_names?.length < 1) {
      notify(i18n.t('error:Please enter at least 1 country'), 'error');
      setTab(2);
      dispatch(unsetLoading());
      return;
    }
    // if (!formValue.timezone) {
    //   notify(i18n.t('error:Please enter select timezone'), 'error');
    //   dispatch(unsetLoading());
    //   return;
    // }
    if (formValue.aptitude_ids?.length < 1) {
      notify(i18n.t('error:Please enter select at least 1 aptitude'), 'error');
      setTab(2);
      dispatch(unsetLoading());
      return;
    }
    if (
      (selectedOption === 'auto' &&
        (formValue.topup_percent === null || formValue.topup_percent < 0)) ||
      (selectedOption !== 'auto' &&
        formValue.fixed_price !== null &&
        formValue.fixed_price < 0)
    ) {
      setTab(3);
      notify(i18n.t('error:Please enter topup percent'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (formValue.job_tasks?.length < 1) {
      notify(i18n.t('error:Please enter at least 1 job task'), 'error');
      setTab(4);
      dispatch(unsetLoading());
      return;
    }
    const _job_tasks = formValue.job_tasks;
    for (let i = 0; i < _job_tasks?.length; i++) {
      delete _job_tasks[i].editorState;
    }
    const payload = {
      ...(formValue.aptitude_ids !== jobEdit.aptitude_ids
        ? { aptitude_ids: formValue.aptitude_ids }
        : {}),
      ...(formValue.country_names !== jobEdit.country_names
        ? { country_names: formValue.country_names }
        : {}),
      ...(formValue.credit_term !== jobEdit.credit_term
        ? { credit_term: formValue.credit_term }
        : {}),
      ...(formValue.currency_id !== jobEdit.currency_id
        ? { currency_id: formValue.currency_id }
        : {}),
      ...(formValue.date_submission !== jobEdit.date_submission
        ? { date_submission: formValue.date_submission }
        : {}),
      ...(formValue.finish_internal_work !== jobEdit.finish_internal_work
        ? { finish_internal_work: formValue.finish_internal_work }
        : {}),
      ...(formValue.fixed_price && formValue.fixed_price !== jobEdit.fixed_price
        ? { fixed_price: formValue.fixed_price, topup_percent: null }
        : {}),
      ...(formValue.is_female !== jobEdit.is_female
        ? { is_female: formValue.is_female }
        : {}),
      ...(formValue.is_lgbt !== jobEdit.is_lgbt
        ? { is_lgbt: formValue.is_lgbt }
        : {}),
      ...(formValue.is_male !== jobEdit.is_male
        ? { is_male: formValue.is_male }
        : {}),
      job_tasks: _job_tasks,
      ...(formValue.job_type !== jobEdit.job_type
        ? { job_type: formValue.job_type }
        : {}),
      ...(formValue.last_day_accept_job !== jobEdit.last_day_accept_job
        ? { last_day_accept_job: formValue.last_day_accept_job }
        : {}),
      ...(formValue.last_day_approve_job !== jobEdit.last_day_approve_job
        ? { last_day_approve_job: formValue.last_day_approve_job }
        : {}),
      ...(formValue.last_day_influencer_submit !== jobEdit.last_day_influencer_submit
        ? { last_day_influencer_submit: formValue.last_day_influencer_submit }
        : {}),
      ...(formValue.last_day_to_approve !== jobEdit.last_day_to_approve
        ? { last_day_to_approve: formValue.last_day_to_approve }
        : {}),
      ...(formValue.name !== jobEdit.name ? { name: formValue.name } : {}),
      ...(formValue.thumbnail_cover !== jobEdit.thumbnail_cover
        ? { thumbnail_cover: formValue.thumbnail_cover }
        : {}),
      ...(formValue.timezone !== jobEdit.timezone
        ? { timezone: formValue.timezone }
        : {}),
      // ...(formValue.timezone_offset !== jobEdit.timezone_offset
      //   ? { timezone_offset: formValue.timezone_offset }
      //   : {}),
      ...(formValue.topup_percent &&
      formValue.topup_percent !== jobEdit.topup_percent
        ? { topup_percent: formValue.topup_percent, fixed_price: null }
        : {}),
      ...(formValue.work_order !== jobEdit.work_order
        ? { work_order: formValue.work_order }
        : {}),
    };
    //check percent
    const { job_tasks } = payload;
    let totalPercent = 0;
    for (let i = 0; i < job_tasks?.length; i++) {
      if (job_tasks[i].task_type_id !== 14) {
        totalPercent += job_tasks[i].percent;
      }
    }
    if (totalPercent !== 100) {
      notify(i18n.t(`Sum of percent task must be equal to 100`), 'error');
      dispatch(unsetLoading());
      return;
    }
    //end check percent
    if (jobEdit && jobId) {
      await updateGleamJobGQL({ ...payload, key: jobId, saveType: 'PUBLISH' });
      notify(i18n.t('Lunch success'), 'success');
      dispatch(unsetLoading());
      history.push('/campaigns/markerter/job');
    } else {
      await createGleamJobGQL({ ...formValue, saveType: 'PUBLISH' });
      notify(i18n.t('Lunch success'), 'success');
      dispatch(unsetLoading());
      history.push('/campaigns/markerter/job');
    }
  };
  //console.log('formSocial', formSocial);

  return (
    <div className="page-content" style={{ marginTop: '0px', paddingTop: '25px' }}>
      <MetaTags>
        <title>{t('Add Campaign')}</title>
      </MetaTags>
      {jobId ? (
        <h5 style={{ marginLeft: '5px' }}>{t('campaignNew.Edit Job')}</h5>
      ) : (
        <h5 style={{ marginLeft: '5px', color: 'white' }}>
          {t('campaignNew.Create New Campaign')}
        </h5>
      )}
      <Breadcrumb
        pageTitle={
          jobId ? t('campaignNew.Edit Job') : t('campaignNew.Create New Campaign')
        }
        menus={[
          { menu: menu.HOME, active: true },
          {
            menu: menu.CAMPAIGN,
            active: true,
          },
          {
            menu: menu.CREATE_CAMPAIGN,
            isSamePage: true,
          },
        ]}
      />

      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <div className="nav-scrollbar-none nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(1);
                      }}
                      className={`${tab === 1 && 'active'} py-3`}
                    >
                      <div>
                        <h6 className="mb-0">{t('campaignNew.1.Setup')}</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(2);
                      }}
                      className={`${tab === 2 && 'active'} py-3`}
                    >
                      <div>
                        <h6 className="mb-0">{t('campaignNew.2.User detail')}</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(3);
                      }}
                      className={`${tab === 3 && 'active'} py-3`}
                    >
                      <div>
                        <h6 className="mb-0">{t('campaignNew.3.Prizes')}</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(4);
                      }}
                      className={`${tab === 4 && 'active'} py-3`}
                    >
                      <div>
                        <h6 className="mb-0">{t('campaignNew.4.Task')}</h6>
                      </div>
                    </NavLink>
                  </NavItem>

                  <div className="addjob-save-button">
                    <div
                      onClick={() => {
                        onSubmit();
                        onSubmitDraft();
                      }}
                    >
                      <AllButton
                        size="sp"
                        status="all"
                        label={t('campaignNew.Save Draft')}
                      />
                    </div>
                    <div
                      onClick={() => {
                        onSubmit();
                        onSubmitLanch();
                      }}
                    >
                      <AllButton
                        size="sp"
                        status="all"
                        label={t('campaignNew.Submit')}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {/* eslint-disable-next-line no-nested-ternary */}
                {tab === 1 && renderSetup()}
                {tab === 2 && renderUser()}
                {tab === 3 && renderPrice()}
                {tab === 4 && renderTask()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddNewJob;
