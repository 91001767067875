import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import CustomButton from 'src/components/Common/CustomButton';
import menu from 'src/constants/menu';
import paramsFromURL from 'src/constants/paramsFromURL';

import control from './CreateMarketerJobCampaign.control';
import './CreateMarketerJobCampaign.style.scss';

const CreateMarketerJobCampaignPage = ({ jobType }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [jobId] = paramsFromURL(['job']);

  const { handleSubmit, validationSchema, inputs, formValues, data, dataIsCleared } =
    control();

  if (!dataIsCleared) return <></>;
  if (data?.isDisableEdit === true) {
    history.replace(`/Marketer/JobDetail?job=${jobId}`);
  }
  if (!data) return <></>;
  if (!((!Object.keys(data)?.length && !jobId) || data?.isDisableEdit === false)) {
    return <></>;
  }

  return (
    <div className="page-content">
      <div className="bread-crumb">
        <Breadcrumb
          pageTitle={t('Menus.Create Job')}
          menus={[
            { menu: menu.HOME, active: true },
            { menu: menu.CREATE_CAMPAIGN },
            { menu: menu.CREATE_JOB, isSamePage: true },
          ]}
        />
      </div>
      <div className="create-marketer-job-campaign-container">
        <div className="card-container">
          <Formik
            initialValues={formValues}
            onSubmit={() => handleSubmit(jobType)}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ errors, touched, handleChange }: any) => {
              return (
                <Form>
                  <h4 className="text-center mt-4">
                    {t('marketer:create_job_campaign.create_job')}
                  </h4>
                  <span style={{ fontSize: '14px' }}>
                    {inputs(errors, touched, handleChange)}
                  </span>
                  <div
                    className="mt-5"
                    style={{ textAlign: 'center', marginBottom: '1rem' }}
                  >
                    <CustomButton
                      status="submit"
                      size="s"
                      label={t('marketer:create_campaign.next')}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateMarketerJobCampaignPage;
