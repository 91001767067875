import { useEffect, useState } from 'react';
import { CardHeader, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SettingCpmForm from 'src/components/Admin/SettingCpmForm';
import { getDefaultCampaign as onGetDefaultCampaign } from '../../store/actions';

const SetDefaultCPMPage = ({ t }: any) => {
  const dispatch = useDispatch();

  const { defaultCampaign } = useSelector((state: any) => ({
    defaultCampaign: state.DefaultValue.allDefaultCampaigns,
  }));
  const [device_platform, setDevicePlatform] = useState<string>('DESKTOP');

  useEffect(() => {
    dispatchDefaultCampaign();
  }, []);

  const dispatchDefaultCampaign = () => {
    dispatch(
      onGetDefaultCampaign({
        device_platform,
      }),
    );
  };

  return (
    <div className="p-4">
      <CardHeader className="px-0 border-bottom-0">
        <Nav className="nav-tabs-custom">
          <NavItem>
            <NavLink
              className={`cursor ${device_platform === 'DESKTOP' && 'active'} `}
              onClick={() => {
                setDevicePlatform('DESKTOP');
                dispatch(
                  onGetDefaultCampaign({
                    device_platform: 'DESKTOP',
                  }),
                );
              }}
            >
              <h6 className="mb-0">{t('creative:Desktop')}</h6>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor ${device_platform === 'MOBILE' && 'active'} `}
              onClick={() => {
                setDevicePlatform('MOBILE');
                dispatch(
                  onGetDefaultCampaign({
                    device_platform: 'MOBILE',
                  }),
                );
              }}
            >
              <h6 className="mb-0">{t('creative:Mobile')}</h6>
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      {Object.keys(defaultCampaign)?.length !== 0 && (
        <Row className="mt-2">
          <Col xs={12} sm={6} md={4} lg={4}>
            <SettingCpmForm
              creativeType="In page"
              defaultMinimumCPM={defaultCampaign.bannerInPage.minimumCPM}
              defaultRecommendCPM={defaultCampaign.bannerInPage.recommendCPM}
              dispatchDefaultCampaign={dispatchDefaultCampaign}
              minimumCPMKey={defaultCampaign.bannerInPage.minimumCPMKey}
              recommendCPMKey={defaultCampaign.bannerInPage.recommendCPMKey}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={4}>
            <SettingCpmForm
              creativeType="Sticky"
              defaultMinimumCPM={defaultCampaign.bannerSticky.minimumCPM}
              defaultRecommendCPM={defaultCampaign.bannerSticky.recommendCPM}
              dispatchDefaultCampaign={dispatchDefaultCampaign}
              minimumCPMKey={defaultCampaign.bannerSticky.minimumCPMKey}
              recommendCPMKey={defaultCampaign.bannerSticky.recommendCPMKey}
            />
          </Col>
          {typeof defaultCampaign.bannerHeader !== 'undefined' && (
            <Col xs={12} sm={6} md={4} lg={4}>
              <SettingCpmForm
                creativeType="Header"
                defaultMinimumCPM={defaultCampaign.bannerHeader.minimumCPM}
                defaultRecommendCPM={defaultCampaign.bannerHeader.recommendCPM}
                dispatchDefaultCampaign={dispatchDefaultCampaign}
                minimumCPMKey={defaultCampaign.bannerHeader.minimumCPMKey}
                recommendCPMKey={defaultCampaign.bannerHeader.recommendCPMKey}
              />
            </Col>
          )}

          <Col xs={12} sm={6} md={4} lg={4}>
            <SettingCpmForm
              creativeType="Slide"
              defaultMinimumCPM={defaultCampaign.bannerSlide.minimumCPM}
              defaultRecommendCPM={defaultCampaign.bannerSlide.recommendCPM}
              dispatchDefaultCampaign={dispatchDefaultCampaign}
              minimumCPMKey={defaultCampaign.bannerSlide.minimumCPMKey}
              recommendCPMKey={defaultCampaign.bannerSlide.recommendCPMKey}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={4}>
            <SettingCpmForm
              creativeType="Native"
              defaultMinimumCPM={defaultCampaign.native.minimumCPM}
              defaultRecommendCPM={defaultCampaign.native.recommendCPM}
              dispatchDefaultCampaign={dispatchDefaultCampaign}
              minimumCPMKey={defaultCampaign.native.minimumCPMKey}
              recommendCPMKey={defaultCampaign.native.recommendCPMKey}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={4}>
            <SettingCpmForm
              creativeType="Pop"
              defaultMinimumCPM={defaultCampaign.pop.minimumCPM}
              defaultRecommendCPM={defaultCampaign.pop.recommendCPM}
              dispatchDefaultCampaign={dispatchDefaultCampaign}
              minimumCPMKey={defaultCampaign.pop.minimumCPMKey}
              recommendCPMKey={defaultCampaign.pop.recommendCPMKey}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default withTranslation()(SetDefaultCPMPage);
