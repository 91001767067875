import { Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import movementStatuses from '../../constants/movementTypes';
import withDrawStatuses from '../../constants/withdrawStatuses';

const MovementBadge = ({
  t,
  sign,
  transaction_type_id,
  withdraw_transaction,
}: any) => {
  const renderStatus = () => {
    if (transaction_type_id === movementStatuses.WITHDRAW) {
      if (
        sign === '+' &&
        withdraw_transaction.status === withDrawStatuses.APPROVED
      ) {
        return (
          <Badge className="bg-success text-light">{t(`table:Completed`)}</Badge>
        );
      } else {
        return sign === '+' ? (
          <Badge className="bg-warning">{t(`table:Requested`)}</Badge>
        ) : (
          <Badge className="bg-danger">{t(`table:Rejected`)}</Badge>
        );
      }
    } else {
      return <Badge className="bg-success text-light">{t(`table:Completed`)}</Badge>;
    }
  };

  return <>{renderStatus()}</>;
};

export default withTranslation()(MovementBadge);
