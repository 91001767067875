import { useCallback, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CustomTable from 'src/components/Common/CustomTable';
import DateTimeComponent from 'src/components/Common/DateTimeComponent';
import DateTimeTypes from 'src/constants/dateTimeTypes';
import { getWebsiteGraphDatum } from 'src/store/actions';

const WebsiteReports = ({
  ads_data,
  t,
  setDatumReportParam,
  datumReportParam,
}: any) => {
  const dispatch = useDispatch();
  const { graphLoading } = useSelector((state: any) => ({
    graphLoading: state.Global.graphLoading,
  }));
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState('DESC');
  const [sortBy, setSortBy] = useState('id');

  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = ['col-2'];
  const hiddenOnLG: any = [];

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: t('table:Last Updated'),
        accessor: ({ updated_at }, index: number) =>
          index === 0 ? (
            <span className="fw-bold">{t('Today')}</span>
          ) : (
            <DateTimeComponent
              dateTime={updated_at}
              dateTimeType={DateTimeTypes.RECENT_TRANSACTION_DATE_TIME}
            />
          ),
        id: 'updated_at',
      },
      {
        Header: t('table:Impression'),
        accessor: 'impression',
        id: 'impression',
      },
      {
        Header: t('table:Click'),
        accessor: 'click',
        id: 'click',
      },
      {
        Header: t('table:Revenue'),
        accessor: ({ revenue }) => (revenue ? floorToFixed(revenue, 4) : 0),
        id: 'revenue',
      },
      {
        Header: t('table:eCPM'),
        accessor: ({ revenue, total_impression }: any) =>
          total_impression
            ? floorToFixed((revenue / total_impression) * 1000, 4)
            : 0,
        id: 'eCpm',
      },
    ],
    [t, page],
  );

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;
    const payload = {
      ...datumReportParam,
      page: newPage,
      sortBy,
      sortType,
    };
    dispatch(getWebsiteGraphDatum(payload));
    setDatumReportParam(payload);
    setPage(newPage);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';
      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...datumReportParam,
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getWebsiteGraphDatum(payload));
      setDatumReportParam(datumReportParam);
      setPage(newPage);
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page, datumReportParam, setDatumReportParam],
  );

  return (
    <CustomTable
      headerCSS="fw-normal bg-header-table"
      firstRowStyle={{ backgroundColor: '#fff59d ', fontWeight: 'bold' }}
      columns={columns}
      data={ads_data.items || []}
      page={page}
      pageSize={pageSize}
      total={ads_data.totalCount}
      handlePageChange={handlePageChange}
      hiddenOnXS={hiddenOnXS}
      hiddenOnSM={hiddenOnSM}
      hiddenOnMD={hiddenOnMD}
      hiddenOnLG={hiddenOnLG}
      onSort={handleSort}
      isLoading={graphLoading}
    />
  );
};

export default withTranslation()(WebsiteReports);
