import { gql } from '@apollo/client';
import { client } from '../client';

// eslint-disable-next-line import/prefer-default-export
export const createBanAccountGql = async (input: any) => {
  const mutation = gql`
    mutation CreateBanAccount($input: CreateBanUserInput!) {
      createBanAccount(input: $input)
    }
  `;
  try {
    const response = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return response.data.createBanAccount;
  } catch (error) {
    return null;
  }
};

export const getBanListGql = async (input: any) => {
  const query = gql`
    query GetBanHistory($input: FilterBanInput!) {
      getBanHistory(input: $input) {
        items {
          day
          reason
          is_complete
          created_at
          release_date
          ban_by_admin {
            email
          }
        }
        totalCount
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return response.data.getBanHistory;
  } catch (error) {
    return null;
  }
};

export const checkIsBannedGql = async () => {
  const query = gql`
    query CheckBan {
      checkBan {
        isBan
        ban {
          day
          reason
          is_complete
          created_at
          release_date
        }
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return response.data.checkBan;
  } catch (error) {
    return null;
  }
};
