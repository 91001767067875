import { FormGroup } from 'reactstrap';
import { ErrorMessage } from 'formik';
import ErrorFocus from 'src/utils/ErrorFocus';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltips from '@mui/material/Tooltip';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import { createJobGql, updateJobGql } from 'src/graphql/advertiser/job';
import { setLoading, unsetLoading } from 'src/store/global/actions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { clearMarketerJob, getMarketerJob } from 'src/store/marketerJob/actions';
import FloatingInputComponent from 'src/components/shared/FloatingInput/FloatingInput.component';
import IconRadioInputComponent from 'src/components/shared/IconRadioInput/IconRadioInput.component';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  MarketerJobNewType,
  DescriptionJobNew,
  SocialMediaType,
} from 'src/store/marketerJobNew/reducer';
import {
  createMarketerJobNew,
  setDescriptionJobNew,
} from 'src/store/marketerJobNew/actions';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';

export type SocialJobType = {
  [key in SocialMediaType]?: {
    isSelected: boolean;
    SHARE?: {
      isSelected: boolean;
      work_order?: string;
      max_budget?: number;
      min_budget?: number;
      link_accompanying_work?: string;
      hashtag?: string; //รวมเป็น string ตัวเดียวคั่นด้วย ,
    };
    PHOTO?: {
      isSelected: boolean;
      work_order?: string;
      max_budget?: number;
      min_budget?: number;
      link_accompanying_work?: string;
      hashtag?: string; //รวมเป็น string ตัวเดียวคั่นด้วย ,
    };
    VIDEO?: {
      isSelected: boolean;
      work_order?: string;
      max_budget?: number;
      min_budget?: number;
      link_accompanying_work?: string;
      hashtag?: string; //รวมเป็น string ตัวเดียวคั่นด้วย ,
    };
  };
};

const init = {
  FACEBOOK: {
    isSelected: false,
    SHARE: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
    PHOTO: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
  },
  INSTAGRAM: {
    isSelected: false,
    SHARE: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
    PHOTO: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
  },
  TWITTER: {
    isSelected: false,
    SHARE: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
    PHOTO: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
  },
  YOUTUBE: {
    isSelected: false,
    VIDEO: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
  },
  TIKTOK: {
    isSelected: false,
    VIDEO: {
      isSelected: false,
      work_order: '',
      max_budget: 0,
      min_budget: 0,
      link_accompanying_work: '',
      hashtag: '#Flupower', //รวมเป็น '' ตัวเดียวคั่นด้วย ,
    },
  },
};
const CreateMarketerJobCampaignControl = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [campaignId, jobId] = paramsFromURL(['campaign', 'job']);
  const {
    descriptionJobNew,
    marketerJobNew,
  }: { descriptionJobNew: DescriptionJobNew; marketerJobNew: MarketerJobNewType } =
    useSelector((state: any) => state.MarketerCampaignJobsNew);

  const initialValues = {};

  const [formValues, setFormValues] = useState(init);

  useEffect(() => {
    const description = localStorage.getItem('descriptionJobNew');
    if (description) {
      dispatch(setDescriptionJobNew(JSON.parse(description)));
    }
    const socialSelect = localStorage.getItem('socialSelect');
    if (socialSelect) {
      setFormValues(JSON.parse(socialSelect));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('socialSelect', JSON.stringify(formValues));
  }, [formValues]);
  //? Functions
  const handleSubmit = async (e: any) => {
    // e.preventDefault();
    const marketerJobNew: MarketerJobNewType = {
      job_type: descriptionJobNew.job_type,
      marketer_campaign_key: descriptionJobNew.marketer_campaign_key,
      job: [],
    };
    const jobs: any = [];
    try {
      dispatch(setLoading());
      Object.keys(formValues).forEach((social: any) => {
        if ((formValues as any)[social].isSelected) {
          Object.keys((formValues as any)[social]).forEach((content: any) => {
            if ((formValues as any)[social][content].isSelected) {
              const { work_order } = (formValues as any)[social][content]!;
              const { min_budget } = (formValues as any)[social][content]!;
              const { max_budget } = (formValues as any)[social][content]!;
              if (!work_order) {
                throw new Error('work_order nullllllllllll');
              }

              if (descriptionJobNew.job_type === 'PUBLISH' && min_budget <= 0) {
                notify(i18n.t('min_budget is <= 0'), 'error');

                throw new Error('min_budget is <= 0');
              }
              if (descriptionJobNew.job_type === 'PUBLISH' && max_budget <= 0) {
                notify(i18n.t('max_budget is <= 0'), 'error');

                throw new Error('max_budget is <= 0');
              }
              if (
                descriptionJobNew.job_type === 'PUBLISH' &&
                min_budget > max_budget
              ) {
                notify(i18n.t('min_budget > max_budget'), 'error');

                throw new Error('min_budget > max_budget');
              }
              jobs.push({
                name: descriptionJobNew.name,
                content_type: content,
                thumbnail_cover: descriptionJobNew.thumbnail_cover,
                social_media_type: social,
                currency_id: descriptionJobNew.currency_id,
                work_order:
                  formValues && (formValues as any)[social][content]!.work_order,
                job_requirement:
                  descriptionJobNew.job_type === 'PUBLISH'
                    ? {
                        max_budget:
                          formValues &&
                          (formValues as any)[social]![content]!?.max_budget,
                        min_budget:
                          formValues &&
                          (formValues as any)[social]![content]!?.min_budget,
                        is_male: descriptionJobNew.is_male,
                        is_female: descriptionJobNew.is_female,
                        is_male_lgbt: descriptionJobNew.is_male_lgbt,
                        is_female_lgbt: descriptionJobNew.is_female_lgbt,
                        min_age: descriptionJobNew.min_age,
                        max_age: descriptionJobNew.max_age,
                        fixed_price: descriptionJobNew.fixed_price,
                        aptitude_ids: descriptionJobNew.aptitude_ids,
                        country_name: descriptionJobNew.country_name,
                      }
                    : null,
                link_accompanying_work:
                  formValues &&
                  (formValues as any)[social]![content]?.link_accompanying_work,
                hashtag:
                  formValues && (formValues as any)[social]![content]?.hashtag, //รวมเป็น string ตัวเดียวคั่นด้วย ,
                social_media_info: null,
                date_submission: '',
                last_day_accept_job: '',
                last_day_approve_job: '',
                last_day_influencer_submit: '',
                last_day_to_approve: '',
                influencer_post_internal_job: '',
                finish_internal_work: '',
              });
            }
          });
        }
      });
      const submitValues: any = {};
      submitValues.job_type = descriptionJobNew.job_type;
      submitValues.marketer_campaign_key = descriptionJobNew.marketer_campaign_key;
      submitValues.job = [...jobs];
      await dispatch(createMarketerJobNew(submitValues));
      dispatch(unsetLoading());
      localStorage.setItem('marketerJobNew', JSON.stringify(submitValues));
      localStorage.setItem('socialSelect', JSON.stringify(formValues));

      if (descriptionJobNew.job_type === 'INVITE_ONLY') {
        history.push('/Marketer/SelectInfluencer');
      } else {
        history.push('/Marketer/ScheduleJob');
      }
    } catch (error) {
      notify(error, 'error');
    }

    // const filterContent = filterSocial.filter((content)=>{
  };

  //? Return
  return {
    initialValues,
    handleSubmit,
    formValues,
    setFormValues,
  };
};

export default CreateMarketerJobCampaignControl;
