import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import selectStyle from '../Common/reactSelectStyle';

const CreativeSizeOptions = ({ handleSizeChange, creativeSizes, t }: any) => {
  const [optionGroup, setOptionGroup] = useState<any>([]);
  const { creativeSizesLoading } = useSelector((state: any) => ({
    creativeSizesLoading: state.Global.creativeSizesLoading,
  }));

  const handleChange = (e: any) => {
    let newOptionGroup: any;
    if (e.value !== '') {
      if (optionGroup[0].label !== t('All')) {
        newOptionGroup = [
          {
            label: t('All'),
            value: '',
          },
        ].concat(optionGroup);
        setOptionGroup(newOptionGroup);
      }
      handleSizeChange(e.value);
    } else {
      newOptionGroup = optionGroup;
      newOptionGroup.shift();
      handleSizeChange('');
      setOptionGroup(newOptionGroup);
    }
  };

  useEffect(() => {
    const optionForm = [];
    const bannerInPageOption: { label: string; value: string }[] = [];
    if (Object.keys(creativeSizes)?.length > 0) {
      const newSize: string[] = [];

      creativeSizes.bannerInPage.forEach(
        (option: { width: number; height: number }) => {
          if (!newSize.includes(`${option.width}x${option.height}`)) {
            bannerInPageOption.push({
              label: `${option.width}x${option.height}`,
              value: `${option.width}x${option.height}`,
            });
          }
        },
      );
    }
    optionForm.push({
      label: t('creative:Banner In Page'),
      options: bannerInPageOption,
    });

    const bannerStickyOption: { label: string; value: string }[] = [];
    if (Object.keys(creativeSizes)?.length > 0) {
      const newSize: string[] = [];

      creativeSizes.bannerSticky.forEach(
        (option: { width: number; height: number }) => {
          if (!newSize.includes(`${option.width}x${option.height}`)) {
            bannerStickyOption.push({
              label: `${option.width}x${option.height}`,
              value: `${option.width}x${option.height}`,
            });
          }
        },
      );
    }
    optionForm.push({
      label: t('creative:Banner Sticky'),
      options: bannerStickyOption,
    });

    const bannerHeaderOption: { label: string; value: string }[] = [];
    if (Object.keys(creativeSizes)?.length > 0) {
      const newSize: string[] = [];

      creativeSizes.bannerHeader.forEach(
        (option: { width: number; height: number }) => {
          if (!newSize.includes(`${option.width}x${option.height}`)) {
            bannerHeaderOption.push({
              label: `${option.width}x${option.height}`,
              value: `${option.width}x${option.height}`,
            });
          }
        },
      );
    }
    optionForm.push({
      label: t('creative:Banner Header'),
      options: bannerHeaderOption,
    });

    const bannerSlideOption: { label: string; value: string }[] = [];
    if (Object.keys(creativeSizes)?.length > 0) {
      const newSize: string[] = [];
      creativeSizes.bannerSlide.forEach(
        (option: { width: number; height: number }) => {
          if (!newSize.includes(`${option.width}x${option.height}`)) {
            newSize.push(`${option.width}x${option.height}`);

            bannerSlideOption.push({
              label: `${option.width}x${option.height}`,
              value: `${option.width}x${option.height}`,
            });
          }
        },
      );
    }

    optionForm.push({
      label: t('creative:Banner Slide'),
      options: bannerSlideOption,
    });

    const nativeOption: { label: string; value: string }[] = [];
    if (Object.keys(creativeSizes)?.length > 0) {
      const newSize: string[] = [];

      creativeSizes.nativeAdvertiser.forEach(
        (option: { width: number; height: number }) => {
          if (!newSize.includes(`${option.width}x${option.height}`)) {
            nativeOption.push({
              label: `${option.width}x${option.height}`,
              value: `${option.width}x${option.height}`,
            });
          }
        },
      );
    }

    optionForm.push({ label: t('creative:Native'), options: nativeOption });

    setOptionGroup(optionForm);
  }, [creativeSizes]);

  return (
    <FormGroup>
      <Select
        options={optionGroup}
        placeholder={
          creativeSizesLoading
            ? t('creative:Select size loading')
            : t('creative:Select size')
        }
        noOptionsMessage={() => t('creative:No options')}
        onChange={handleChange}
        styles={selectStyle()}
      />
    </FormGroup>
  );
};

export default withTranslation()(CreativeSizeOptions);
