import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { getApplicationGraphDatum, getWebsiteGraphDatum } from 'src/store/actions';
import check from 'src/assets/images/other/check.svg';
import DatePickerOptions from '../Table/DatePickerOptions';
import i18n from '../../i18n';
import useWindowDimensions from '../useWindowDimensions';

const ShortcutDateRange = ({
  datumReportParam,
  setDatumReportParam,
  isApplication,
}: any) => {
  const dispatch = useDispatch();
  const [range, setRange] = useState<String | null>('THIS MONTH');
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [showCustomDate, setShowCustomDate] = useState(false);
  const { windowWidth } = useWindowDimensions();
  const { t } = useTranslation();

  const dateRangeOptions = [
    'date.Today',
    'date.Last 7 days',
    'date.Last 30 days',
    'date.This month',
    'date.Last month',
    'date.This year',
    null,
  ].map((item) =>
    item
      ? { title: i18n.t(`${item}`), value: item.split('date.')[1].toUpperCase() }
      : { title: i18n.t('date.Custom'), value: null },
  );

  const getNewData = (newPayload: any, disableSetParam?: boolean) => {
    if (isApplication) {
      dispatch(getApplicationGraphDatum(newPayload));
    } else {
      dispatch(getWebsiteGraphDatum(newPayload));
    }
    if (!disableSetParam) {
      setDatumReportParam(newPayload);
    }
  };

  const handleStartDateChange = (date: Date) => {
    if (endDate) {
      getNewData({
        ...datumReportParam,
        log_range: generateDateRange(new Date(date), new Date(endDate)),
        log_start: new Date(date),
        shortcut_range: '',
      });
    }
    setStartDate(date);
  };

  useEffect(() => {
    getNewData(
      {
        ...datumReportParam,
        shortcut_range: 'THISMONTH',
      },
      true,
    );
  }, []);

  const handleEndDateChange = (date: any) => {
    if (startDate) {
      getNewData({
        ...datumReportParam,
        log_range: generateDateRange(new Date(startDate), new Date(date)),
        log_end: new Date(date),
        shortcut_range: '',
      });
    }
    setEndDate(date);
  };

  const generateDateRange = (start: Date, end: Date) => {
    if (end.getFullYear() - start.getFullYear() >= 1) return 'MONTHLY';
    return end.getMonth() - start.getMonth() > 2 ? 'MONTHLY' : 'DAILY';
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const shortCutSelected = (shortcut_range: string) => {
    getNewData({
      ...datumReportParam,
      log_range: shortcut_range === 'THIS YEAR' ? 'MONTHLY' : 'DAILY',
      shortcut_range: shortcut_range.replace(/\s/g, ''),
      log_start: '',
    });
    setShowCustomDate(false);
    setRange(shortcut_range);
  };

  const renderDateRangOptions = () =>
    dateRangeOptions.map((item) => (
      <button
        type="button"
        className={`btn-shortcut-range m-1 font-size-12 ${
          range === item.value && 'active'
        }`}
        style={{ width: 'fit-content', height: '30px', whiteSpace: 'nowrap' }}
        onClick={() => {
          if (item.value) {
            shortCutSelected(item.value);
          } else {
            setRange(item.value);
            setShowCustomDate(true);
          }
        }}
        key={item.value}
      >
        {range === item.value && (
          <img className="me-1 btn-check-icon" src={check} alt="check" />
        )}
        {item.title}
      </button>
    ));

  const renderCardOnLg = () => {
    let menuWidth = 0;
    if (document.getElementsByClassName('vertical-menu')?.length) {
      menuWidth = document.getElementsByClassName('vertical-menu')[0].clientWidth;
    }
    return windowWidth - menuWidth < 1100 ? 3 : 2;
  };

  return (
    <>
      <div className="mb-3 nav-scrollbar-none flex justify-content-start align-items-center flex-nowrap overflow-auto w-100">
        {renderDateRangOptions()}
      </div>
      <div>
        {showCustomDate && (
          <Row className="g-0 px-1">
            <Col sm={12} md={10} lg={8}>
              <Row className="justify-content-start">
                <Col md={3} lg={renderCardOnLg()}>
                  <DatePickerOptions
                    date={startDate}
                    handleChange={handleStartDateChange}
                    placeholder={t('date.Start Date')}
                    isSmall
                  />
                </Col>
                <Col md={3} lg={renderCardOnLg()}>
                  <DatePickerOptions
                    date={endDate}
                    handleChange={handleEndDateChange}
                    placeholder={t('date.End Date')}
                    isSmall
                  />
                </Col>
                <Col md={3} lg={2} className="p-lg-0">
                  <Button
                    className="btn btn-primary d-block btn-sm d-sm-inline-block w-100 mb-2 mb-sm-0"
                    type="button"
                    onClick={handleClearFilter}
                    style={{
                      padding: '0 10',
                    }}
                  >
                    {t('Clear')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ShortcutDateRange;
