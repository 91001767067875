import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle } from 'reactstrap';
import useWindowSize from 'src/utils/windowSize';
import Marketer from 'src/assets/images/flupower/mar_0.png';
import Influencer from 'src/assets/images/flupower/inf_0.png';
import Role from 'src/constants/enum/role';
import '../auth.style.scss';
import { useHistory } from 'react-router';

const SelectRole = ({ setRole, authType }: any) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const history = useHistory();
  const userAgentString = navigator.userAgent;

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1;
  const chromeAgent = userAgentString.indexOf('Chrome') > -1;
  if (chromeAgent && safariAgent) safariAgent = false;
  return (
    <>
      <div
        style={{ padding: '1.5rem', color: 'white' }}
        className="back-btn mb-4"
        onClick={() => {
          history.push('/');
          setRole(null);
        }}
      >
        <i className="mdi mdi-arrow-left" />
        {t('Back')}
      </div>
      <div className="auth-select-role-component-container">
        <h4 style={{ color: 'white' }}>
          {authType === 'register'
            ? t('Select Role Registration')
            : t('Select Role Login')}
        </h4>
        <div
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
          className="select-item-container mt-4"
        >
          {' '}
          <div className="select-item">
            <div
              className={`${
                safariAgent === true ? 'disable-flip-fixed' : 'flip-fixed'
              }`}
            >
              <div className="front">
                <Card
                  className="card-publisher"
                  onClick={() => {
                    setRole(Role.PUBLISHER);
                  }}
                >
                  <CardTitle
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '299px',
                      maxWidth: '347px',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      background: 'black',
                    }}
                  >
                    {/* <img
                      src={publisher}
                      style={{ maxHeight: '299px', maxWidth: '347px' }}
                      alt="advertiserImage"
                    /> */}
                    <img
                      src={Influencer}
                      alt="influencer"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </CardTitle>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: 700,
                          lineHeight: '44px',
                        }}
                      >
                        {t(`Influencer`)}
                      </span>
                    </div>
                    <p
                      style={{
                        color: 'white',
                        fontSize: '15px',
                        fontWeight: 600,
                        margin: 0,
                      }}
                    >
                      {t(
                        'Make money by promoting products or services / advertising on platform',
                      )}
                    </p>
                  </CardBody>
                </Card>
              </div>

              <div className="back">
                <Card
                  className="card-publisher-back"
                  onClick={() => {
                    setRole(Role.PUBLISHER);
                  }}
                >
                  <CardBody style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          height: '114px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 700,
                            height: 'fit-content',
                            textAlign: 'center',
                          }}
                        >
                          <div
                            style={{
                              border: '1px solid white',
                              marginBottom: '10px',
                            }}
                          />
                          {t(`Make money by promoting products or services`)}
                          <div
                            style={{ border: '1px solid white', marginTop: '10px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <div className="select-item">
            <div
              className={`${
                safariAgent === true ? 'disable-flip-fixed' : 'flip-fixed'
              }`}
            >
              <div className="front">
                <Card
                  className="card-advertiser"
                  onClick={() => {
                    setRole(Role.ADVERTISER);
                  }}
                >
                  <CardTitle
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '299px',
                      maxWidth: '347px',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      background: 'black',
                    }}
                  >
                    {/* <img src={advertiser} alt="advertiserImage" /> */}
                    <img
                      src={Marketer}
                      alt="marketer"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </CardTitle>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: 700,
                          lineHeight: '44px',
                        }}
                      >
                        {t(`Marketer`)}
                      </span>
                    </div>
                    <p
                      style={{
                        margin: '0',
                        color: 'white',
                        fontSize: '15px',
                        fontWeight: 600,
                      }}
                    >
                      {t(
                        'Create an advertisement or campaign to promote a product or service',
                      )}
                    </p>
                  </CardBody>
                </Card>
              </div>

              <div className="back">
                <Card
                  className="card-advertiser-back"
                  onClick={() => {
                    setRole(Role.ADVERTISER);
                  }}
                >
                  <CardBody style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: 700,
                          lineHeight: '44px',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{ border: '1px solid white', marginBottom: '10px' }}
                        />
                        {t(`Hire influencer to promote a product or service`)}
                        <div
                          style={{ border: '1px solid white', marginTop: '10px' }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectRole;
