import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAptitude } from 'src/store/actions';

const AddAptitude = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState<any>();

  const onSubmit = () => {
    dispatch(createAptitude(name));
  };

  return (
    <div className="page-content ms-4 mt-4">
      <h4> Add Aptitude</h4>
      <input
        type="text"
        onChange={(e): any => {
          setName(e.target.value);
        }}
        className="form-control w-50"
      />
      <button
        type="submit"
        className="btn btn-primary mt-4"
        onClick={() => {
          onSubmit();
        }}
      >
        submit
      </button>
    </div>
  );
};

export default AddAptitude;
