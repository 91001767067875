/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum WithdrawTypes {
  /** Withdraw */
  WITHDRAW_REQUEST = '@@withdraw/WITHDRAW_REQUEST',
  WITHDRAW_REQUEST_SUCCESS = '@@withdraw/WITHDRAW_REQUEST_SUCCESS',
  WITHDRAW_REQUEST_FAIL = '@@withdraw/WITHDRAW_REQUEST_FAIL',

  GET_WITHDRAW_REQUEST = '@@withdraw/GET_WITHDRAW_REQUEST',
  GET_WITHDRAW_REQUEST_SUCCESS = '@@withdraw/GET_WITHDRAW_REQUEST_SUCCESS',

  GET_RECENT_WITHDRAW_REQUEST = '@@withdraw/GET_RECENT_WITHDRAW_REQUEST',
  GET_RECENT_WITHDRAW_REQUEST_SUCCESS = '@@withdraw/GET_RECENT_WITHDRAW_REQUEST_SUCCESS',

  /** Admin User */
  GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST = '@@withdraw/GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST',
  GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST_SUCCESS = '@@withdraw/GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST_SUCCESS',
  GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST_FAIL = '@@withdraw/GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST_FAIL',

  APPROVE_WITHDRAW = '@@withdraw/APPROVE_WITHDRAW',
  REJECT_WITHDRAW = '@@withdraw/REJECT_WITHDRAW',

  WITHDRAW_ADMIN_REQUEST = '@@withdraw/WITHDRAW_ADMIN_REQUEST',
  WITHDRAW_ADMIN_REQUEST_SUCCESS = '@@withdraw/WITHDRAW_ADMIN_REQUEST_SUCCESS',
  WITHDRAW_ADMIN_REQUEST_FAIL = '@@withdraw/WITHDRAW_ADMIN_REQUEST_FAIL',
}
