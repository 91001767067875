import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row, FormGroup, CardBody, Card, CardHeader } from 'reactstrap';
import { Formik, Form as FormikForm, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
import { useDispatch } from 'react-redux';
import { getWebsiteRequestSize } from 'src/store/actions';
import { adImageSizesWhereGraphql } from 'src/graphql/advertiser/creative';
import { useParams } from 'react-router-dom';
import { createRequestCreativeSize } from 'src/graphql';
import AdFormat from '../../../constants/adFormatEnum';
import Dropdown from '../../CommonForBoth/Menus/Dropdown';

const initialValues = {
  width: '',
  height: '',
  adFormat: '',
};

const websiteSchema = Yup.object().shape({
  width: Yup.number().integer().min(1),
  height: Yup.number().integer().min(1),
  adFormat: Yup.string(),
});

const WebsiteCreativeSize = ({ t }: any) => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const [formatSelected, setFormatSelected] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [creativeSize, setCreativeSize] = useState([]);
  const [adsFormat, setAdsFormat] = useState();
  const [bannerFormat, setBannerFormat] = useState('');
  const [text_position, setTextPosition] = useState('');

  const nativeType = [
    {
      label: `Image above`,
      value: `IMAGE_ABOVE`,
    },
    {
      label: `Image on the side`,
      value: `IMAGE_ON_SIDE`,
    },
    {
      label: `Title above`,
      value: `TITLE_ABOVE`,
    },
    {
      label: `Text only`,
      value: `TEXT_ONLY`,
    },
  ];

  const [size, setSize]: any = useState({
    width: 0,
    height: 0,
  });

  const formatOptions = [
    {
      value: AdFormat[0],
      label: t('creative:Banner'),
    },
    {
      value: AdFormat[1],
      label: t('creative:Native'),
    },
    {
      value: AdFormat[2],
      label: t('creative:Pop up'),
    },
  ];
  const banner_format = [
    {
      value: 'BANNER_IN_PAGE',
      label: t('creative:In-Page'),
    },
    {
      value: 'BANNER_STICKY',
      label: t('creative:Sticky'),
    },
    {
      value: 'BANNER_HEADER',
      label: t('creative:Premium Header'),
    },
    {
      value: 'BANNER_SLIDE',
      label: t('creative:Slide'),
    },
  ];

  async function fetchImageSizes(ad_image_type: any) {
    const result = await adImageSizesWhereGraphql({
      ad_image_type,
    });

    const sizeOption: any = [];
    if (Object.keys(result)?.length > 0) {
      const newSize: string[] = [];
      result.forEach((option: { width: number; height: number }) => {
        if (!newSize.includes(`${option.width}x${option.height}`)) {
          newSize.push(`${option.width}x${option.height}`);

          sizeOption.push({
            label: `${option.width}x${option.height}`,
            value: `${option.width}x${option.height}`,
          });
        }
      });
    }
    setCreativeSize(sizeOption);
  }

  useEffect(() => {
    if (!formatSelected) return;

    const newStatus: any = formatSelected.value;
    if (newStatus === AdFormat[AdFormat.BANNER]) {
      if (bannerFormat) setCreativeSize(creativeSize);
      else setCreativeSize([]);
    } else if (newStatus === AdFormat[AdFormat.NATIVE]) {
      fetchImageSizes('NATIVE_PUBLISHER');
      setBannerFormat('');
    } else if (newStatus === AdFormat[AdFormat.POP]) {
      setBannerFormat('POPUP');
    }
    setSize({ width: 0, height: 0 });
    setAdsFormat(newStatus);
  }, [formatSelected]);

  const handleBannerFormatChange = async (event: any) => {
    const newBannerFormat = event.value;
    if (adsFormat !== 'POP') {
      fetchImageSizes(newBannerFormat);
    }
    setSize({
      width: 0,
      height: 0,
    });
    setBannerFormat(newBannerFormat);
  };

  const handleSizeChange = (event: any) => {
    const newSize = event.value;
    const adSize = newSize.split('x');
    setSize({
      width: adSize[0],
      height: adSize[1],
    });
  };

  const handleNativeTypeChange = (event: any) => {
    const newType = event.value;
    setTextPosition(newType);
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Request creative confirmation description', {
        adsFormat,
        bannerFormat,
        width: size.width,
        height: size.height,
      }),
      width: 300,
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let ads_format: any;
        if (bannerFormat === 'BANNER_IN_PAGE') {
          ads_format = 'IN_PAGE';
        } else if (bannerFormat === 'BANNER_STICKY') {
          ads_format = 'STICKY';
        } else if (bannerFormat === 'BANNER_HEADER') {
          ads_format = 'HEADER';
        } else if (bannerFormat === 'BANNER_SLIDE') {
          ads_format = 'SLIDE';
        } else if (bannerFormat === 'POPUP') {
          ads_format = 'POPUP';
        } else {
          ads_format = 'NULL';
        }

        const input: any = {
          website_id: +id,
          width: size.width,
          height: size.height,
          creative_format: adsFormat,
          banner_format: ads_format,
          text_position,
        };

        const payload = {
          limit: 10,
          page: 1,
          website_id: +id,
          sortBy: 'id',
          sortType: 'DESC',
        };

        await createRequestCreativeSize(input);
        // await dispatch(createWebsiteRequestSize(input));
        dispatch(getWebsiteRequestSize(payload));
        resetForm();
      }
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t('Page.Request Creative Size')}</h5>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={websiteSchema}
            render={() => (
              <FormikForm>
                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Col xs={12} sm={8} md={12} lg={8}>
                    <FormGroup
                      className="mb-3"
                      // style={{ marginRight: 100 }}
                    >
                      <label htmlFor="AdsFormat" style={{ fontWeight: 'bold' }}>
                        {t('table:AdsFormat')}
                      </label>
                      <Dropdown
                        options={formatOptions}
                        action={(event: any) => setFormatSelected(event)}
                        placeholder={t('Placeholder.selectAdsType')}
                        useValue
                        value={formatSelected}
                      />
                      <ErrorMessage
                        name="width"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  {adsFormat === AdFormat[0] ? (
                    <Col xs={12} sm={8} md={12} lg={8}>
                      <FormGroup className="mb-3">
                        <label
                          htmlFor="Banner format"
                          style={{ fontWeight: 'bold' }}
                        >
                          {t('creative:Banner format')}
                        </label>
                        <Dropdown
                          options={banner_format}
                          action={handleBannerFormatChange}
                          placeholder={t('Placeholder.selectBannerFormat')}
                        />
                        <ErrorMessage
                          name="width"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
                <Row>
                  <Col xs={12} sm={8} md={12} lg={8}>
                    {adsFormat !== AdFormat[2] && (
                      <>
                        {adsFormat === AdFormat[1] ? (
                          <FormGroup className="mb-3">
                            <label
                              htmlFor="Native Ad Type"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {t('Form.Native Ad Type')}
                            </label>

                            <Dropdown
                              options={nativeType}
                              action={handleNativeTypeChange}
                              placeholder={t('placeHolder.SelectNativeType')}
                            />
                          </FormGroup>
                        ) : (
                          ''
                        )}

                        <FormGroup>
                          <label
                            htmlFor="Creative size"
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {t('Form.Creative size')}
                          </label>

                          {adsFormat === AdFormat[1] ? (
                            <>
                              <div className="input-group">
                                <div
                                  className="input-group-text"
                                  style={{
                                    width: '70px',
                                  }}
                                >
                                  width
                                </div>
                                <Field
                                  name="width"
                                  type="text"
                                  autocomplete="off"
                                  className="form-control form-control-sm"
                                  value={size.width}
                                  onChange={(e: any) => {
                                    if (
                                      +e.currentTarget.value ||
                                      +e.currentTarget.value === 0
                                    ) {
                                      setSize({
                                        ...size,
                                        width: +e.currentTarget.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="input-group mt-2">
                                <div
                                  className="input-group-text"
                                  style={{
                                    width: '70px',
                                  }}
                                >
                                  height
                                </div>
                                <Field
                                  name="height"
                                  type="text"
                                  value={size.height}
                                  autocomplete="off"
                                  className="form-control form-control-sm"
                                  onChange={(e: any) => {
                                    if (
                                      +e.currentTarget.value ||
                                      +e.currentTarget.value === 0
                                    ) {
                                      setSize({
                                        ...size,
                                        height: +e.currentTarget.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <Dropdown
                              options={creativeSize}
                              action={handleSizeChange}
                              placeholder={t('Placeholder.selectAdsSize')}
                            />
                          )}
                        </FormGroup>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        disabled={
                          !(size.width && size.height) && adsFormat !== AdFormat[2]
                        }
                      >
                        {t('button:Request')}
                      </button>
                    </FormGroup>
                  </Col>
                </Row>
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default withTranslation()(WebsiteCreativeSize);
