import { useEffect, useRef, useCallback, useState } from 'react';

// Import Icons
import SimpleBar from 'simplebar-react';
import './AdminSideBar.style.scss';
// Import images

// i18n
import { withTranslation } from 'react-i18next';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAdminApproveLog } from 'src/store/actions';

const AdminSidebarContent = (props: any) => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const [approveLogCount, setApproveLogCount] = useState({
    advertiser_campaign: 0,
    new_influencer: 0,
    new_influencer_request: 0,
    new_job: 0,
    publisher_website: 0,
    withdraw: 0,
    delete_social: 0,
    approve_suspend_influencer_job: 0,
    new_own_currency: 0,
    report_job: 0,
    report_job_decline: 0,
  });
  // const { isMainMenu } = props;
  const { ApproveLog } = useSelector((state: any) => ({
    ApproveLog: state.ActivityLogs.allAdminApproveLog,
  }));

  const initialValue = 0;
  const approveLogCountArray = Object.values(approveLogCount);
  const sumNotify = approveLogCountArray.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    initialValue,
  );

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const checkApproveLog = () => {
    if (!ApproveLog?.length) return;
    const value: any = {
      advertiser_campaign: 0,
      new_influencer: 0,
      new_influencer_request: 0,
      new_job: 0,
      publisher_website: 0,
      withdraw: 0,
      delete_social: 0,
      approve_suspend_influencer_job: 0,
    };
    for (let i = 0; i < ApproveLog?.length; i++) {
      const log = ApproveLog[i];
      value[log.admin_approve_log.name] = log.value;
    }
    setApproveLogCount(value);
  };
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  useEffect(() => {
    if (!ApproveLog) return;
    checkApproveLog();
  }, [ApproveLog]);

  useEffect(() => {
    dispatch(getAllAdminApproveLog());

    const pathName = props.location.pathname;

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul: any = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items?.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <>
      <SimpleBar
        className="h-100"
        style={{ maxHeight: '100%', paddingTop: '75px' }}
        ref={ref}
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/" className="font-size-20">
                <i className="mdi mdi-home-variant" />
                <span>{props.t('Dashboard')}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="font-size-20 has-arrow">
                <i className="bx bx-pie-chart-alt" />
                <span>{props.t('System Balance')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/system-balance-overview">
                    {props.t('Menus.Overview')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/system-balance-log">
                    {props.t('Menus.System Balance Log')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/crypto-balance-log">
                    {props.t('Menus.Crypto Balance Log')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/role-balance-log">
                    {props.t('Menus.Role Balance Log')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/profit-balance-log">
                    {props.t('Menus.Profit Balance Log')}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow font-size-20">
                <i className="fas fa-user-friends" />
                <span>{props.t('Menus.User')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/users">{props.t('Menus.All User')}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow font-size-20">
                <i className="fas fa-unlock" />
                <span>{props.t('Menus.Approval')}</span>
                <div
                  className="notification-icon"
                  style={{ position: 'absolute', right: '5rem', top: '1rem' }}
                >
                  {sumNotify > 99 ? ' 99+' : sumNotify}
                </div>
              </Link>

              <ul className="sub-menu">
                <li className="admin-side-bar">
                  {approveLogCount.advertiser_campaign === 0 ||
                  approveLogCount.advertiser_campaign === null ? (
                    <Link to="/admin/approve/approvalAdvertiser">
                      {props.t('page:advertiser_campaign')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/approvalAdvertiser">
                      <div className="item-container">
                        {props.t('page:advertiser_campaign')}
                        <div className="notification-icon">
                          {approveLogCount?.advertiser_campaign > 99
                            ? ' 99+'
                            : approveLogCount.advertiser_campaign}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.publisher_website === 0 ||
                  approveLogCount.publisher_website === null ? (
                    <Link to="/admin/approve/publisher">
                      {props.t('page:publisher_website')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/publisher">
                      <div className="item-container">
                        {props.t('page:publisher_website')}
                        <div className="notification-icon">
                          {approveLogCount?.publisher_website > 99
                            ? ' 99+'
                            : approveLogCount.publisher_website}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.new_influencer === 0 ||
                  approveLogCount.new_influencer === null ? (
                    <Link to="/admin/approve/influencer">
                      {props.t('page:New Influencer Approval')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/influencer">
                      <div className="item-container">
                        {props.t('page:New Influencer Approval')}
                        <div className="notification-icon">
                          {approveLogCount?.new_influencer > 99
                            ? ' 99+'
                            : approveLogCount.new_influencer}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.new_influencer_request === 0 ||
                  approveLogCount.new_influencer_request === null ? (
                    <Link to="/admin/approve/influencer-request">
                      {props.t('page:Request update Price')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/influencer-request">
                      <div className="item-container">
                        {props.t('page:Request update Price')}
                        <div className="notification-icon">
                          {approveLogCount?.new_influencer_request > 99
                            ? ' 99+'
                            : approveLogCount.new_influencer_request}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>

                <li className="admin-side-bar">
                  {approveLogCount.new_influencer_request === 0 ||
                  approveLogCount.new_influencer_request === null ? (
                    <Link to="/admin/approve/influencer-follower">
                      {props.t('page:Request update Follower')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/influencer-follower">
                      <div className="item-container">
                        {props.t('page:Request update Follower')}
                        <div className="notification-icon">
                          {approveLogCount?.new_influencer_request > 99
                            ? ' 99+'
                            : approveLogCount.new_influencer_request}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.new_job === 0 ||
                  approveLogCount.new_job === null ? (
                    <Link to="/admin/approve/manage-job">
                      {props.t('page:Job Approval')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/manage-job">
                      <div className="item-container">
                        {props.t('page:Job Approval')}
                        <div className="notification-icon">
                          {approveLogCount?.new_job > 99
                            ? ' 99+'
                            : approveLogCount.new_job}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.withdraw === 0 ||
                  approveLogCount.withdraw === null ? (
                    <Link to="/admin/approve/withdraw">
                      {props.t('page:Withdraw Transactions Approval')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/withdraw">
                      <div className="item-container">
                        {props.t('page:Withdraw Transactions Approval')}
                        <div className="notification-icon">
                          {approveLogCount?.withdraw > 99
                            ? ' 99+'
                            : approveLogCount.withdraw}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  <Link to="/admin/approve/speed-up-job">
                    {props.t('page:Speed Up Job')}
                  </Link>
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.delete_social === 0 ||
                  approveLogCount.delete_social === null ? (
                    <Link to="/admin/approve/delete-social">
                      {props.t('page:Request Delete Social')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/delete-social">
                      <div className="item-container">
                        {props.t('page:Request Delete Social')}
                        <div className="notification-icon">
                          {approveLogCount?.delete_social > 99
                            ? ' 99+'
                            : approveLogCount.delete_social}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.approve_suspend_influencer_job === 0 ||
                  approveLogCount.approve_suspend_influencer_job === null ? (
                    <Link to="/admin/approve/suspend-influencer-post">
                      {props.t('Suspend Influencer Post')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/suspend-influencer-post">
                      <div className="item-container">
                        {props.t('Suspend Influencer Post')}
                        <div className="notification-icon">
                          {approveLogCount?.approve_suspend_influencer_job > 99
                            ? ' 99+'
                            : approveLogCount.approve_suspend_influencer_job}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                {/* ต้องให้พี่พจทำ log ให้ */}

                <li className="admin-side-bar">
                  {approveLogCount.new_own_currency === 0 ||
                  approveLogCount.new_own_currency === null ? (
                    <Link to="/admin/approve/currency-approval">
                      {props.t('page:Currency')}
                    </Link>
                  ) : (
                    <Link to="/admin/approve/currency-approval">
                      <div className="item-container">
                        {props.t('page:Currency')}
                        <div className="notification-icon">
                          {approveLogCount?.new_own_currency > 99
                            ? ' 99+'
                            : approveLogCount.new_own_currency}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.report_job === 0 ||
                  approveLogCount.report_job === null ? (
                    <Link to="/admin/reportJob">{props.t('page:Ban User')}</Link>
                  ) : (
                    <Link to="/admin/reportJob">
                      <div className="item-container">
                        {props.t('page:Ban User')}
                        <div className="notification-icon">
                          {approveLogCount?.report_job > 99
                            ? ' 99+'
                            : approveLogCount.report_job}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
                <li className="admin-side-bar">
                  {approveLogCount.report_job_decline === 0 ||
                  approveLogCount.report_job_decline === null ? (
                    <Link to="/admin/reportDeclineJob">
                      {props.t('page:Report Decline Job')}
                    </Link>
                  ) : (
                    <Link to="/admin/reportDeclineJob">
                      <div className="item-container">
                        {props.t('page:Report Decline Job')}
                        <div className="notification-icon">
                          {approveLogCount?.report_job_decline > 99
                            ? ' 99+'
                            : approveLogCount.report_job_decline}
                        </div>
                      </div>
                    </Link>
                  )}
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow font-size-20">
                <i className="fas fa-unlock" />
                <span>Influencer</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/manage-job">job</Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to="/#" className="has-arrow font-size-20">
                <i className="mdi mdi-grid-large" />
                <span>{props.t('Menus.Transaction')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/withdraw">
                    {props.t('Menus.Withdraw Transaction')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/deposit">
                    {props.t('Menus.Deposit Transaction')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/payment">
                    {props.t('Menus.Payment Transaction')}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/admin/movement-balance" className="font-size-20">
                <i className="mdi mdi-shuffle" />
                <span>{props.t('Menus.Movement Balance')}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow font-size-20">
                <i className="dripicons-gear" />
                <span>{props.t('Menus.Admin Settings')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/settings">{props.t('Menus.Overview')}</Link>
                </li>
                <li>
                  <Link to="/admin/settingAdvertiser">
                    {props.t('Page.Advertiser')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/setting-influencer">
                    {props.t('Page.Influencer Price Rate')}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/settingFAQs">{props.t('Page.FAQs')}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

export default withTranslation()(withRouter(AdminSidebarContent));
