import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { WalletTypes } from './actionTypes';

import {
  getWalletSuccess,
  getWalletFail,
  getSumBalanceWalletSuccess,
  getSumBalanceWalletFail,
  getUserWalletByCurrencySuccess,
  getAdminWalletSuccess,
  getSumBalanceWalletByUUIDSuccess,
  getMarketerAvailableCurrenciesSuccess,
  getBalanceWalletByUUIDSuccess,
} from './actions';

import {
  getAdminWalletGQL,
  getMarketerAvailableCurrenciesGQL,
  getSumBalanceWalletByUUIDGQL,
  getBalanceWalletByUUIDGQL,
  getSumBalanceWalletGQL,
  getUserWalletByCurrencyGQL,
  wallet,
} from '../../../graphql/common';
import { Wallet } from '../../../common/types';

function* fetchWallet() {
  try {
    const response: Wallet = yield call(wallet);

    yield put(getWalletSuccess(response));
  } catch (error) {
    yield put(getWalletFail(error));
  }
}

function* sumBalanceWalletByUUID({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getSumBalanceWalletByUUIDGQL, payload);

    yield put(getSumBalanceWalletByUUIDSuccess(response));
  } catch (error) {
    //
  }
}

function* fetchBalanceWalletByUUID({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getBalanceWalletByUUIDGQL, payload);

    yield put(getBalanceWalletByUUIDSuccess(response));
  } catch (error) {
    //
  }
}

function* sumBalanceWallet() {
  try {
    const response: Promise<any> = yield call(getSumBalanceWalletGQL);

    yield put(getSumBalanceWalletSuccess(response));
  } catch (error) {
    yield put(getSumBalanceWalletFail(error));
  }
}

function* fetchUserWalletCurrency({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getUserWalletByCurrencyGQL, payload);

    yield put(getUserWalletByCurrencySuccess(response));
  } catch (error) {
    //
  }
}

function* fetchAdminWallet() {
  try {
    const response: Promise<any> = yield call(getAdminWalletGQL);

    yield put(getAdminWalletSuccess(response));
  } catch (error) {
    //
  }
}

function* getMarketerAvailableCurrencies() {
  try {
    const response: Promise<any> = yield call(getMarketerAvailableCurrenciesGQL);

    yield put(getMarketerAvailableCurrenciesSuccess(response));
  } catch (error) {
    yield put(getMarketerAvailableCurrenciesSuccess(null));
  }
}

function* walletSaga() {
  yield takeEvery(WalletTypes.GET_WALLET, fetchWallet);
  yield takeEvery(WalletTypes.GET_SUM_BALANCE_WALLET, sumBalanceWallet);
  yield takeEvery(WalletTypes.GET_USER_WALLET_BY_CURRENCY, fetchUserWalletCurrency);
  yield takeEvery(WalletTypes.GET_ADMIN_WALLET, fetchAdminWallet);
  yield takeEvery(WalletTypes.GET_SUM_BALANCE_WALLET_UUID, sumBalanceWalletByUUID);
  yield takeEvery(WalletTypes.GET_BALANCE_WALLET_UUID, fetchBalanceWalletByUUID);
  yield takeEvery(
    WalletTypes.GET_MARKETER_AVAILABLE_CURRENCIES,
    getMarketerAvailableCurrencies,
  );
}

export default walletSaga;
