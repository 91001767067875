import './approvalStatus.style.scss';

export const Pending = ({ message }: { message: string }) => (
  <div className="approval-status">
    <button type="button" className=" btn-pending">
      <i className="fas fa-clock" />
      <span>{message}</span>
    </button>
  </div>
);

export const Approve = ({ message }: { message: string }) => (
  <div className="approval-status">
    <button type="button" className="btn-approved">
      <i className="fas fa-check" />
      <span>{message}</span>
    </button>
  </div>
);

export const Reject = ({ message }: { message: string }) => (
  <div className="approval-status">
    <button type="button" className="btn-rejected">
      <i className="fas fa-ban" />
      <span>{message}</span>
    </button>
  </div>
);
