import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CampaignStatus from '../Common/CampaignStatus';

const CampaignsDetailModal = ({ isOpen, handleToggle, data, t }: any) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        handleToggle(!isOpen);
      }}
      className="bgt-modal-fullscreen"
      backdrop="static"
    >
      {data && (
        <>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('campaign:Campaign Detail')}
            </h5>
            <button
              type="button"
              onClick={() => {
                handleToggle(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body p-0">
            <div className="table-responsive">
              <table className="table table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('campaign:Campaign Name')}
                    </th>
                    <td>{data.name}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('tableCreative:Ad type')}
                    </th>
                    <td>{data.banner_format}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('tableCreative:Device')}
                    </th>
                    <td>{data.device_platform}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('tableCreative:Creatives')}
                    </th>
                    <td>
                      <>
                        approved {data.adsActiveCount}, total {data.adsCount}
                      </>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('campaign:Total budget')}
                    </th>
                    <td>{data.max_budget ? data.max_budget : 'Unlimited'}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('campaign:Used')}
                    </th>
                    <td>
                      {data.currentBudget ? floorToFixed(data.currentBudget, 4) : 0}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('Status')}
                    </th>
                    <td>
                      <CampaignStatus status={data.status} />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('campaign:Daily Budget')}
                    </th>
                    <td>{data.budget}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      CPM
                    </th>
                    <td>{data.CPM}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      eCPM
                    </th>
                    <td> {data.eCPM}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      impression
                    </th>
                    <td>{data.impression}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('Clicks')}
                    </th>
                    <td>{data.click}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      CTR
                    </th>
                    <td>{data.CTR ? floorToFixed(data.CTR, 4) : 0}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                handleToggle(!isOpen);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('button:Close')}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default withTranslation()(CampaignsDetailModal);
