import React, { useEffect } from 'react';

import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getMovement as onGetMovement } from '../../store/movement/actions';
import menu from '../../constants/menu';
import MovementTransactionsTable from '../../components/Admin/MovementTransactionsTable';

const MovementBalancePage = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetMovement({ limit: 10, page: 1 }));
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{t('Page.Movement Transactions')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.Movement Transactions')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.ADMIN_MOVEMENT_BALANCE, active: true },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <MovementTransactionsTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(MovementBalancePage);
