import { Redirect } from 'react-router-dom';
import CreateMarketerCampaignPage from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/CreateMarketerCampaign.component';
import MarketerAccountPage from 'src/pages/Advertiser/Marketer/MarketerAccount/MarketerAccount.component';

import JobIndex from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/jobIndex';
import MarketerCampaignIndex from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/marketerCampaignIndex';
import SelectInfluencerPage from 'src/pages/Advertiser/Marketer/selectInfluencer/selectInfluencer.component';
import AddAptitude from 'src/pages/mock/addAptitude';
import AddMarketerCategory from 'src/pages/mock/addMarketerCategory';
import CreateMarketerPublicJob from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/CreateMarketerPublicJob/CreateMarketerPublicJob';
import Discover from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/Discover';
import DiscoverAll from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/DiscoverAll';
import JobIndexNew from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/jobIndexNew';
import CreateMarketerJobCampaignPageNewstep1 from 'src/pages/Advertiser/Marketer/MarketerJobCampaignDetail/CreateMarketerJobCampaignPageNewstep1.component';
import CreateMarketerJobCampaignPageNewstep2 from 'src/pages/Advertiser/Marketer/CreateMarketerJobCampaign/CreateMarketerJobCampaignNew2.component';
import AdvertiserDashboardNew from 'src/pages/Dashboard/AdvertiserDashboardNew';
import JobNew from 'src/pages/Advertiser/Marketer/Jobnew/jobNew';
import AddNewJob from 'src/pages/Advertiser/Marketer/Jobnew/AddNewJob';
import Template from 'src/pages/Advertiser/Marketer/Template/template';
import GleamJobReviewPage from 'src/components/Common/GleamJobReviewPage';
import GleamJobDetail from 'src/pages/mock/gleamJobDetail';
import CampaignsPage from '../pages/Advertiser/CampaignsPage';
import GalleryPage from '../pages/Advertiser/GalleryPage';
import SetupCampaignPage from '../pages/Advertiser/SetupCampaignPage';

// Dashboard
import CreateCampaignPage from '../pages/Advertiser/CreateCampaignPage';

import InfluencerPostDetailPage from '../pages/Advertiser/Marketer/CreateMarketerCampaign/InfluencerPostDetail/InfluencerPostDetail.component';
import CreateMarketerJobCampaignPage from '../pages/Advertiser/Marketer/CreateMarketerJobCampaign/CreateMarketerJobCampaign.component';
import MarketerDashboardPage from '../pages/Advertiser/Marketer/MarketerDashboard/MarketerDashboard.component';
import CreateMarketerJobCampaignPageNewstep4 from '../pages/Advertiser/Marketer/MarketerJobCampaignDetail/CreateMarketerJobCampaignPageNewstep4.component';
import AdvertiserDashboard from '../pages/Dashboard/AdvertiserDashboard';

// Authentication pages

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const advertiserRoutes: Array<RouteProps> = [
  {
    path: '/campaigns',
    exact: true,
    component: <CampaignsPage />,
  },
  // {
  //   path: '/campaigns/dashboard',
  //   exact: true,
  //   component: <AdvertiserDashboard />,
  // },
  {
    path: '/campaigns/dashboard',
    exact: true,
    component: <AdvertiserDashboardNew />,
  },
  {
    path: '/gallery',
    exact: true,
    component: <GalleryPage />,
  },
  {
    path: '/campaigns/:id',
    exact: true,
    component: <SetupCampaignPage />,
  },
  {
    path: '/campaigns/markerter/job',
    exact: true,
    component: <JobNew />,
  },
  {
    path: '/template',
    exact: true,
    component: <Template />,
  },
  {
    path: '/campaigns/jobnew/addnewjob',
    exact: true,
    component: <AddNewJob />,
  },
  {
    path: '/campaign/create',
    exact: true,
    component: <CreateCampaignPage />,
  },
  {
    path: '/marketer',
    exact: true,
    component: () => <Redirect to="marketer/dashboard" />,
  },
  {
    path: '/marketer/dashboard',
    component: <MarketerDashboardPage />,
  },
  {
    path: '/marketer/account',
    component: <MarketerAccountPage />,
  },
  {
    path: '/marketer/create-campaign',
    component: <CreateMarketerCampaignPage />,
  },
  {
    path: '/Marketer/CreateJob',
    component: <CreateMarketerJobCampaignPage />,
  },
  {
    path: '/Marketer/CreatePublicJob',
    component: <CreateMarketerJobCampaignPage jobType="PUBLISH" />,
  },
  // {
  //   path: '/Marketer/EditJobCampaign',
  //   component: <CreateMarketerJobCampaignPage />,
  // },
  {
    path: '/Marketer/SocialSelect',
    component: <CreateMarketerJobCampaignPageNewstep2 />,
  },
  {
    path: '/Marketer/AddJobDetail',
    component: <CreateMarketerJobCampaignPageNewstep1 />,
  },
  {
    path: '/Marketer/ScheduleJob',
    component: <CreateMarketerJobCampaignPageNewstep4 />,
  },
  {
    path: '/EditMarketerCampaign',
    component: <CreateMarketerCampaignPage />,
  },
  {
    path: '/EditMarketerPublicCampaign',
    component: <CreateMarketerCampaignPage campaignType="PUBLISH" />,
  },
  {
    path: '/marketer/campaigns',
    component: <MarketerAccountPage />,
  },
  {
    path: '/AddMarketerCategory',
    exact: true,
    component: <AddMarketerCategory />,
  },
  {
    path: '/AddAptitude',
    exact: true,
    component: <AddAptitude />,
  },

  {
    path: '/Marketer/SelectInfluencer',
    exact: true,
    component: <SelectInfluencerPage />,
  },
  {
    path: '/marketer/campaign',
    exact: true,
    component: <MarketerCampaignIndex />,
  },
  {
    path: '/MarketerPublicCampaign',
    exact: true,
    component: <JobIndex jobType="PUBLISH" />,
  },
  // {
  //   path: '/MarketerCampaign',
  //   exact: true,
  //   component: <JobIndex />,
  // },
  {
    path: '/MarketerCampaign',
    exact: true,
    component: <JobIndexNew />,
  },
  {
    path: '/InfluencerPostDetail',
    component: <InfluencerPostDetailPage />,
  },
  {
    path: '/marketer/PublicCampaign',
    component: <MarketerCampaignIndex campaignType="PUBLISH" />,
  },
  {
    path: '/marketer/Create/PublicCampaign',
    component: <CreateMarketerCampaignPage campaignType="PUBLISH" />,
  },

  { path: '/Marketer/JobRequirement', component: <CreateMarketerPublicJob /> },
  {
    path: '/campaigns/markerter/job/detail',
    component: <GleamJobDetail />,
  },
  {
    path: '/campaigns/markerter/job/publishDetail',
    component: <GleamJobDetail job_type="PUBLISH" />,
  },
];

export default advertiserRoutes;
