import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import WithdrawStatus from '../../Common/WithdrawStatus';

const WebsiteDetail = ({ Webdata }: any) => {
  const { t } = useTranslation();
  return (
    <Col lg={8}>
      <h5>{t('WebsiteURL')}</h5>
      <div style={{ display: 'flex' }}>
        <input
          className="form-control form-control mb-3"
          name="url"
          type="text"
          value={Webdata.url}
        />
        <a href={Webdata.url} target="_blank" rel="noreferrer">
          <i
            className="mdi mdi-link-variant font-size-26 ms-3"
            style={{ cursor: 'pointer' }}
          />
        </a>
      </div>
      <h5 className="mb-2">{t('Status')}</h5>
      <div className="mb-3" style={{ fontSize: 20 }}>
        <WithdrawStatus status={Webdata.status} />
      </div>
      <h5>{t('WebsiteAdded')}</h5>
      <input
        className="form-control form-control mb-3"
        name="url"
        type="text"
        value={new Date(Webdata.created_at).toLocaleDateString()}
      />
    </Col>
  );
};

export default WebsiteDetail;
