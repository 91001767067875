import { CurrencyTypes, CurrencyState } from './actionTypes';

export const INIT_STATE: CurrencyState = {
  currencies: [],
  availableCurrencies: {},
  filterCurrencies: [],
  error: {},
  availableNetworks: [],
  currenciesByMarketer: [],
};

const Currency = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CurrencyTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
      };
    case CurrencyTypes.GET_CURRENCIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CurrencyTypes.GET_AVAILABLE_CURRENCIES_SUCCESS:
      return {
        ...state,
        availableCurrencies: action.payload,
      };
    case CurrencyTypes.GET_FILTER_CURRENCY_SUCCESS:
      return {
        ...state,
        filterCurrencies: action.payload,
      };
    case CurrencyTypes.GET_AVAILABLE_NETWORK_SUCCESS:
      return {
        ...state,
        availableNetworks: action.payload,
      };
    case CurrencyTypes.GET_CURRENCIES_BY_MARKETER_SUCCESS:
      return {
        ...state,
        currenciesByMarketer: action.payload,
      };
    default:
      return state;
  }
};

export default Currency;
