import { Form } from 'reactstrap';
import { LegacyRef, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import './SearchBar.style.scss';

const SearchBar = ({
  t,
  placeholder,
  handleSearch,
  handleClear,
  disableClear,
  disablePadding,
}: any) => {
  // Ref
  const searchInput: LegacyRef<any> = useRef('');

  const handleClickSearch = (e: any) => {
    e.preventDefault();
    const val = searchInput.current.value ? `%${searchInput.current.value}%` : '';

    handleSearch(val);
  };

  const handleClickClear = () => {
    searchInput.current.value = '';
    handleClear();
  };

  return (
    <div className="search-bar-clear-container">
      <div className="block-container">
        <Form
          className={`app-search d-block ${disablePadding ? 'p-0' : ''}`}
          onSubmit={(e) => handleClickSearch(e)}
        >
          <div
            style={{
              marginRight: '16px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '15px' }}>
              <input
                style={{
                  background: 'white',
                  color: 'gray',
                  minWidth: '250px',
                  borderRadius: '15px',
                }}
                ref={searchInput}
                type="text"
                className="form-control text-color-primary search-bar-white"
                placeholder={placeholder}
              />
            </span>
            <span>
              {' '}
              <button
                className="btn border-0"
                type="submit"
                style={{
                  background: 'white',
                  height: '37px',
                  width: '40px',
                  borderRadius: '50%',
                  color: 'black',
                }}
              >
                <i className="bx bx-search-alt align-middle" />
              </button>
            </span>
          </div>
        </Form>

        {!disableClear && (
          <button
            className="clear-button search-bar-white"
            type="button"
            onClick={handleClickClear}
          >
            {t('table:Clear Search')}
          </button>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SearchBar);
