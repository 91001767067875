import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import useWindowSize from 'src/utils/windowSize';
import Typewriter from 'typewriter-effect';
import Role from 'src/constants/enum/role';
import logoAF from 'src/assets/images/flupower/logoheadfont.png';
import logoMiddleSection from 'src/assets/images/flupower/middle_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestJobOnHomePage } from 'src/store/marketerJob/actions';
import section3Social from 'src/assets/images/flupower/everysoial.png';
import IncreaseCredibility from 'src/assets/images/flupower/Increase-credibility.png';
import IncreaseParticipation from 'src/assets/images/flupower/Increase-participation.png';
import IncreaseAccessToCustomerGroups from 'src/assets/images/flupower/Increase-access-to-customer-groups.png';
import Tiktok from 'src/assets/images/flupower/tik-tok-logo.png';
import Twitter from 'src/assets/images/flupower/twitter-logo.png';
import Youtube from 'src/assets/images/flupower/youtube-logo.png';
import Facebook from 'src/assets/images/flupower/facebook-logo.png';
import footpagelogo from 'src/assets/images/flupower/footpagelogo.png';
import HomePageNavBar from './HomePageNavBar';
import InfluencerIcon from '../../assets/images/flupower/1000influencer.png';
import PlanningIcon from '../../assets/images/flupower/Asset_66_0.png';
import 'src/components/Home/HomePageContent.style.scss';

const HomePageContent = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const homeSecondRef: any = useRef(null);
  const dispatch = useDispatch();

  const scrollToHomeSecond = () => {
    if (homeSecondRef && homeSecondRef.current) {
      homeSecondRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const { states } = useSelector((state: any) => ({
    states: state.MarketerJob,
  }));
  useEffect(() => {
    dispatch(getSuggestJobOnHomePage());
  }, []);
  // console.log('states', states);

  return (
    <>
      <Helmet>
        <title>Flupower</title>
        <link rel="canonical" href="/homepage" />
      </Helmet>
      <div className="home-page">
        <Row className=" home-page-banner g-0 p-0">
          <div className=" d-flex flex-column" style={{ zIndex: '2' }}>
            <div style={{ color: 'black' }}>
              {' '}
              <HomePageNavBar />
            </div>
            <Row
              style={
                width <= 767
                  ? { marginLeft: '0px', marginRight: '0px' }
                  : { marginLeft: '137px', marginRight: '137px' }
              }
              className=" d-flex  "
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    alignItems: 'flex-start',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '900px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '3rem',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '28px',
                          fontWeight: 'bold',
                          marginTop: '20px',
                        }}
                      >
                        {t('home.Innovation Beyond Borders')}{' '}
                      </span>
                      <h3 className="font-typing mt-2" style={{ color: 'white' }}>
                        {t('home.WORLD-CLASS')}
                      </h3>
                      <h3 className="font-typing2">
                        <Typewriter
                          options={{
                            strings: [t('home.MARKETING SOLUTIONS')],
                            autoStart: true,
                            loop: true,
                            delay: 150,
                          }}
                        />
                      </h3>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '80%',
                      }}
                    >
                      <Link to={`/login/${Role.INFLUENCER.toLowerCase()}`}>
                        <div
                          className="btn btn-publisher btn-lg rounded-none me-4  d-flex justify-content-center align-items-center text-nowrap"
                          style={
                            width <= 405
                              ? { fontSize: '0.68rem', width: '25px' }
                              : { fontSize: '1rem', width: '25px' }
                          }
                        >
                          {t('home.Influencer/Publisher')}
                        </div>
                      </Link>
                      <Link to={`/login/${Role.MARKETER.toLowerCase()}`}>
                        <div
                          className="btn btn-advertiser btn-lg d-flex justify-content-center align-items-center text-nowrap"
                          style={
                            width <= 405
                              ? { fontSize: '0.68rem', width: '25px' }
                              : { fontSize: '1rem', width: '25px' }
                          }
                        >
                          {t('home.Marketer/Advertiser')}
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div
                    className=" img-logo-homepage"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '500px',
                      position: 'relative',
                      marginLeft: '80px',
                    }}
                  >
                    <img
                      className="logo-af"
                      src={logoAF}
                      alt="logoaf"
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* </Col> */}
            </Row>
          </div>
        </Row>
        <Row className="home-page-fourth-card justify-content-center align-items-center ">
          <img
            className="logo-middle-section-new"
            src={logoMiddleSection}
            alt="logo middle section"
          />
        </Row>
        {/* ----------section1---------------- */}
        <Row className="home-page-third justify-content-center g-0">
          <h2
            className="text-white fw-bold fw-md-normal"
            style={{ textAlign: 'center' }}
          >
            {t('Section3 Title')}
          </h2>
          <Row className="g-0 justify-content-around">
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img
                  src={InfluencerIcon}
                  alt="Influencer Icon"
                  style={{
                    width: '200px',
                    height: '200px',
                    marginBottom: '15px',
                  }}
                />
                <h1 className="fw-bold fw-md-normal fs-5" style={{ color: 'white' }}>
                  {t('Influencers')}
                </h1>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img
                  src={PlanningIcon}
                  alt="ad-2"
                  style={{
                    width: '200px',
                    height: '200px',
                    marginBottom: '15px',
                  }}
                />
                <h5 className="fw-bold fw-md-normal fs-5" style={{ color: 'white' }}>
                  {t(
                    'home.A comprehensive marketing plan optimized specifically for your project.',
                  )}
                </h5>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img
                  src={section3Social}
                  alt="ad-3"
                  style={{
                    width: '220px',
                    height: '130px',
                    marginBottom: '40px',
                    marginTop: '40px',
                  }}
                />
                <h1 className="fw-bold fw-md-normal fs-5" style={{ color: 'white' }}>
                  {t('home.Social Media Management')}
                </h1>
              </div>
            </Col>
          </Row>
        </Row>
        <Row className="home-page-second justify-content-center g-0">
          <h2
            className="mb-md-5 mb-0 fw-bold fw-md-normal "
            ref={homeSecondRef}
            style={{ width: '80%', color: 'white', textAlign: 'center' }}
          >
            {t('Section4 Title')}
          </h2>
          <Row className="g-0 justify-content-around">
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img
                  src={IncreaseCredibility}
                  alt="ad-1"
                  style={{
                    width: '220px',
                    height: '175px',
                    marginBottom: '15px',
                  }}
                />
                <h6 className="fw-bold fw-md-normal fs-5" style={{ color: 'white' }}>
                  {t('Increase Credibility')}
                </h6>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img
                  src={IncreaseParticipation}
                  alt="ad-2"
                  style={{
                    width: '220px',
                    height: '175px',
                    marginBottom: '15px',
                  }}
                />
                <h5 className="fw-bold fw-md-normal fs-5" style={{ color: 'white' }}>
                  {t('Increase Participation')}
                </h5>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img
                  src={IncreaseAccessToCustomerGroups}
                  alt="ad-3"
                  style={{
                    width: '220px',
                    height: '175px',
                    marginBottom: '15px',
                  }}
                />
                <h5 className="fw-bold fw-md-normal fs-5" style={{ color: 'white' }}>
                  {t('Increase Access To Customer Groups')}
                </h5>
              </div>
            </Col>
          </Row>
        </Row>
        <Row
          className="home-page-footer g-0"
          style={
            width <= 767
              ? { padding: '1.5em 0em 0em 1.5em' }
              : { padding: '2em 2em 0 2em' }
          }
        >
          <div className="container home-page-footer-item-container">
            <Col className="d-flex flex-column flex-sm-row">
              <div
                className="d-flex flex-grow-1"
                style={
                  width <= 767
                    ? { justifyContent: 'space-between', flexWrap: 'wrap' }
                    : {}
                }
              >
                <div className="mx-4">
                  <h6 className="font-size-18">{t('Products')}</h6>
                  <ul className="list-unstyled font-size-15">
                    <li>
                      <Link to="/register/marketer" className="text-body">
                        {t('home.Marketer/Advertiser')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/register/influencer" className="text-body">
                        {t('home.Influencer/Publisher')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="mx-4">
                  <ul className="list-unstyled font-size-15">
                    {/* <li>
                      <Link to="/contact" className="text-body">
                        {t('Contact')}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/portfolio" className="text-body">
                        {t('Showcase')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/faqs" className="text-body">
                        {t('FAQ')}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div
                className="footer-section"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <ul
                  className="d-flex list-unstyled mx-4"
                  style={
                    width <= 767
                      ? { padding: '0em' }
                      : { padding: '0em 0em 0em 0em' }
                  }
                >
                  <li className="mx-2">
                    <img
                      src={Twitter}
                      alt=""
                      style={{
                        width: '28px',
                        height: '28px',
                      }}
                    />
                  </li>
                  <li className="mx-2">
                    <img
                      src={Facebook}
                      alt=""
                      style={{
                        width: '28px',
                        height: '28px',
                      }}
                    />
                  </li>
                  <li className="mx-2">
                    <img
                      src={Tiktok}
                      alt=""
                      style={{
                        width: '28px',
                        height: '28px',
                      }}
                    />
                  </li>
                  <li className="mx-2">
                    <img
                      src={Youtube}
                      alt=""
                      style={{
                        width: '28px',
                        height: '28px',
                      }}
                    />
                  </li>
                </ul>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={footpagelogo} alt="logo" width="80px" style={{}} />
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default HomePageContent;
