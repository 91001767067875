//react
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
//react style
import { Row, FormGroup, Label } from 'reactstrap';
//general api
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
//component
import { layoutTheme } from 'src/constants/layout';
import CreativeType from 'src/components/Advertiser/CampaignSettings/CreativeType';
import ErrorFocus from 'src/utils/ErrorFocus';
import SelectDevice from '../../components/Advertiser/CampaignSettings/SelectDevice';
import Country from '../../components/Advertiser/CampaignSettings/Country';
import UploadCreativeDisplay from '../../components/Advertiser/UploadCreatives/UploadCreativeDisplay';
import UploadCreativeTable from '../../components/Advertiser/UploadCreatives/UploadCreativeTable';
import useWindowDimensions from '../../components/useWindowDimensions';
import AdvertiseType from '../../components/Advertiser/AdvertiseType';
import { createCreativeCampaignGraphql } from '../../graphql/advertiser/creative';
import {
  createCampaignGraphQL,
  uploadImage,
} from '../../graphql/advertiser/campaign';
//redux
import {
  createCreative as onCreateCreative,
  getCountries as onGetCountries,
  getDefaultCampaign as onGetDefaultCampaign,
  getCreativeType as onGetCreativeType,
} from '../../store/actions';
import { setLoading, unsetLoading } from '../../store/global/actions';
import AddIcon from '../../components/Advertiser/Img/add-icon.png';
import AddIconDark from '../../components/Advertiser/Img/add-icon-dark.png';

const CreateCampaignPage = ({ t }: any) => {
  //from react
  const dispatch = useDispatch();
  const history = useHistory();
  //from component
  const { windowWidth } = useWindowDimensions();
  //update campaign variable
  const [ad_format, setAdFormat] = useState('BANNER');
  const [banner_format, setBannerFormat] = useState('IN_PAGE');
  const [name, setName] = useState<string>();
  const [device_platform, setDevicePlatform] = useState('DESKTOP');
  const [CPM, setCPM] = useState<number>();
  const [isUnlimitedBudget, setIsUnlimitedBudget] = useState(true);
  const [budget, setBudget] = useState<number>();
  const [max_budget, setMaxBudget] = useState<number>();
  const [isAllCountry, setIsAllCountry] = useState(true);
  const [countryData, setCountryData] = useState<any>([]);
  const [typeData, setTypeData] = useState<any>([]);
  const [auto_run, setAutoRun] = useState(true);
  const [createCampaignSchema, setCreateCampaignSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      CPM: Yup.number().required('Required').positive('Make sure positive number'),
      budget: Yup.number()
        .required('Required')
        .positive('Make sure positive number'),
      max_budget: Yup.number().positive('Make sure positive number').nullable(true),
    }),
  );

  //file upload variable
  const [fileUploads, setFileUploads] = useState<any>([]);

  //redux
  const { countries, defaultCampaign, layoutMode, creativeType } = useSelector(
    (state: any) => ({
      creativeType: state.CreativeType.creativeType,
      countries: state.Countries.countries,
      defaultCampaign: state.DefaultValue.defaultCampaign,
      layoutMode: state.Layout.layoutMode,
    }),
  );

  const countryOption = countries.map((country: { name: string; key: string }) => ({
    label: country.name,
    value: country.name,
    key: country.key,
  }));

  const typeOption = creativeType.map((type: { name: string; key: string }) => ({
    label: type.name,
    value: type.name,
    key: type.key,
  }));

  //default value
  const initialValues = {
    name: '',
    CPM: '',
    budget: '',
    max_budget: '',
  };

  const inputRef: any = useRef(null);

  //submit
  const onSubmit = async () => {
    if (!typeData?.length && inputRef && inputRef.current) {
      const ElementTop = inputRef.current.children[0].children[0].offsetTop;
      const ElementHeight = inputRef.current.children[0].children[0].offsetHeight;
      window.scrollTo(0, (ElementTop + ElementHeight) / 2);
      return;
    }
    dispatch(setLoading());
    window.scrollTo(0, 0);
    const campaign = await createCampaign();

    if (campaign) {
      if (ad_format === 'POP') {
        for (let i = 0; i < fileUploads?.length; i++) {
          const { url_ad, isActive, nameAd } = fileUploads[i][0];
          createCreativeCampaignGraphql({
            creative_key: null,
            campaign_key: campaign.campaign_key,
            url_ad,
            isActive,
            name: nameAd,
          });
        }
        dispatch(unsetLoading());
        history.push(`/campaigns/${campaign.campaign_key}`);
      } else {
        if (fileUploads?.length) {
          await fileUploads.map(async (newFile: any, index: number) => {
            if (newFile[0]) {
              const bannerId = await uploadImage(newFile);

              const { nameAd, fileSize, url_ad, titleAd, descriptionAd, isActive } =
                newFile[0];

              const creative: any = {
                name: nameAd,
                size: fileSize,
                image_storage_key: bannerId.data.uploadImage,
                campaign_keys: [campaign.campaign_key],
                url_ad,
                title: titleAd,
                description: descriptionAd,
                isActive,
              };

              if (index === fileUploads?.length - 1) {
                creative.lastItem = true;
                creative.history = history;
                creative.campaign_key = campaign.campaign_key;
              }

              dispatch(onCreateCreative(creative));
            } else {
              const { data, url_ad, isActive, titleAd, descriptionAd } = newFile;

              await createCreativeCampaignGraphql({
                creative_key: data.key,
                campaign_key: campaign.campaign_key,
                url_ad,
                isActive,
                name: '',
                title: titleAd,
                description: descriptionAd,
              });

              if (index === fileUploads?.length - 1) {
                dispatch(unsetLoading());
                history.push(`/campaigns/${campaign.campaign_key}`);
              }
            }
          });
        } else {
          dispatch(unsetLoading());
          history.push(`/campaigns/${campaign.campaign_key}`);
        }
      }
    } else {
      dispatch(unsetLoading());
    }
  };
  const createCampaign = async () => {
    const countries_key = countryData.map((country: { key: number }) => country.key);
    const creative_types_key = typeData.map((type: { key: number }) => type.key);

    const submitData = {
      ad_format,
      name,
      device_platform: ad_format === 'BANNER' ? device_platform : null,
      CPM,
      budget,
      max_budget,
      countries_key,
      isAllCountry,
      creative_types_key,
      banner_format,
      auto_run,
    };

    if (countryData?.length === 0 || countryData?.length === countryOption?.length) {
      submitData.isAllCountry = true;
      submitData.countries_key = [];
    }

    const newCampaign = await createCampaignGraphQL(submitData);

    return newCampaign;
  };

  //general function
  const preventNonNumber = (evt: any) => {
    return (
      (evt.key === '-' || evt.key === 'e' || evt.key === '+') && evt.preventDefault()
    );
  };

  useEffect(() => {
    dispatch(onGetCountries());
    dispatch(onGetCreativeType());
    dispatchDefaultCampaign({});
  }, []);

  const dispatchDefaultCampaign = (payload: {
    device_platform?: string;
    banner_format?: string;
    ad_format?: string;
  }) => {
    const disPatchForm = { device_platform, banner_format, ad_format };

    if (payload.device_platform) {
      disPatchForm.device_platform = payload.device_platform;
    }
    if (payload.banner_format) {
      disPatchForm.banner_format = payload.banner_format;
    }
    if (payload.ad_format) {
      disPatchForm.ad_format = payload.ad_format;
    }

    dispatch(onGetDefaultCampaign(disPatchForm));
  };

  useEffect(() => {
    const newUpdateCampaignSchema = Yup.object().shape({
      name: Yup.string().required(t('invalid.Required')),
      CPM: Yup.number()
        .required(t('invalid.Required'))
        .positive(t('invalid.Make sure positive number'))
        .min(
          defaultCampaign.minimumCPM,
          t('invalid.must be greater than or equal', {
            min: defaultCampaign.minimumCPM,
          }),
        ),
      budget: Yup.number()
        .required(t('invalid.Required'))
        .positive(t('invalid.Make sure positive number'))
        .min(
          defaultCampaign.minimumBudget,
          t('invalid.must be greater than or equal', {
            min: defaultCampaign.minimumBudget,
          }),
        ),
      max_budget: Yup.number()
        .positive(t('invalid.Make sure positive number'))
        .min(
          defaultCampaign.minimumMaxBudget,
          t('invalid.must be greater than or equal', {
            min: defaultCampaign.minimumMaxBudget,
          }),
        )
        .nullable(true),
    });

    setCreateCampaignSchema(newUpdateCampaignSchema);
  }, [defaultCampaign]);

  return (
    <div className="page-content">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={createCampaignSchema}
      >
        {({ errors, touched, handleChange }) => (
          <FormikForm>
            <Row>
              <div className="p-2-rem-horizontal">
                <AdvertiseType
                  ad_format={ad_format}
                  setAdFormat={setAdFormat}
                  layoutMode={layoutMode}
                  dispatchDefaultCampaign={dispatchDefaultCampaign}
                />

                <p className="font-size-22 fw-bold mt-5">
                  {t('campaign:Campaign Settings')}
                </p>

                <div className="mt-4 px-4 pb-4">
                  <FormGroup>
                    <Label className="input-label fs-5">
                      {t('campaign:Campaign name')}
                      <span className="input-required">*</span>
                    </Label>
                    <Field
                      name="name"
                      type="text"
                      className={`form-control ${
                        errors.name && touched.name && 'is-invalid '
                      }`}
                      style={windowWidth > 991 ? { width: '66%' } : {}}
                      onChange={(e: any) => {
                        setName(e.currentTarget.value);
                        handleChange(e);
                      }}
                      placeholder={t('invalid.Required')}
                    />

                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-message fs-6"
                    />
                    <ErrorFocus name="name" />
                  </FormGroup>

                  <div className="mt-4" ref={inputRef}>
                    <CreativeType
                      name="creativeType"
                      typeData={typeData}
                      setTypeData={setTypeData}
                      typeOption={typeOption}
                    />
                  </div>
                  {ad_format !== 'NATIVE' && (
                    <div className="mt-4">
                      <SelectDevice
                        device_platform={device_platform}
                        setDevicePlatform={setDevicePlatform}
                        banner_format={banner_format}
                        setBannerFormat={setBannerFormat}
                        ad_format={ad_format}
                        layoutMode={layoutMode}
                        dispatchDefaultCampaign={dispatchDefaultCampaign}
                      />
                    </div>
                  )}
                  <FormGroup className="mt-4">
                    <Label className="fs-5 input-label">
                      {t('campaign:CPM Price')}
                      <span className="input-required">*</span>
                    </Label>
                    <div
                      className={`input-group ${windowWidth > 991 && 'w-40-per'}`}
                    >
                      <Field
                        name="CPM"
                        type="text"
                        autocomplete="off"
                        className={`form-control ${
                          errors.CPM && touched.CPM && 'is-invalid '
                        } ${windowWidth > 991 && 'w-40-per'}`}
                        onKeyDown={(evt: any) => {
                          preventNonNumber(evt);
                        }}
                        onChange={(e: any) => {
                          setCPM(+e.currentTarget.value);
                          handleChange(e);
                        }}
                        placeholder={t('invalid.Required')}
                      />
                      <div className="input-group-text">$ / CPM</div>
                    </div>
                    <ErrorMessage
                      name="CPM"
                      component="div"
                      className="invalid-message fs-6"
                    />
                    <ErrorFocus name="CPM" />
                    <p className="font-size-12 mt-2">
                      <p style={{ display: 'flex' }}>
                        {t('Minimum price')}
                        &nbsp;
                        <p style={{ color: '#009A78' }}>
                          {defaultCampaign.minimumCPM}$
                        </p>
                      </p>
                      <p style={{ display: 'flex' }}>
                        {t('Recommended price')}
                        &nbsp;
                        <p style={{ color: '#009A78' }}>
                          {defaultCampaign.recommendCPM}$
                        </p>
                      </p>
                    </p>
                  </FormGroup>
                  <p className="font-size-18 fw-bold">{t('campaign:Budget type')}</p>
                  <button
                    type="button"
                    className={`btn outline-sm ${isUnlimitedBudget && 'active'}`}
                    onClick={() => setIsUnlimitedBudget(true)}
                  >
                    {t('campaign:Unlimited')}
                  </button>
                  <button
                    type="button"
                    className={`btn outline-sm mx-2 ${
                      !isUnlimitedBudget && 'active'
                    }`}
                    onClick={() => setIsUnlimitedBudget(false)}
                  >
                    {t('campaign:Custom')}
                  </button>
                  <FormGroup className="mt-4">
                    <Label className="fs-5 input-label">
                      {t('campaign:Daily Budget')}
                      <span className="input-required">*</span>
                    </Label>
                    <div
                      className={`input-group ${windowWidth > 991 && 'w-40-per'}`}
                    >
                      <Field
                        name="budget"
                        type="text"
                        autocomplete="off"
                        className={`form-control ${
                          errors.budget && touched.budget && 'is-invalid'
                        }`}
                        onKeyDown={(evt: any) => {
                          preventNonNumber(evt);
                        }}
                        onChange={(e: any) => {
                          setBudget(+e.currentTarget.value);
                          handleChange(e);
                        }}
                        placeholder={t('invalid.Required')}
                      />
                      <div className="input-group-text">$ / {t('Day')}</div>
                    </div>
                    <ErrorMessage
                      name="budget"
                      component="div"
                      className="invalid-message fs-6"
                    />
                    <ErrorFocus name="budget" />
                    <p className="font-size-12 mt-2">
                      <p style={{ display: 'flex' }}>
                        {t('Minimum price')}&nbsp;
                        <p style={{ color: '#009A78' }}>
                          {' '}
                          {defaultCampaign.minimumBudget}$
                        </p>
                      </p>
                    </p>
                  </FormGroup>
                  {!isUnlimitedBudget && (
                    <FormGroup className="mt-4">
                      <Label className="input-label fs-5">
                        {t('campaign:Total budget')}
                      </Label>
                      <div
                        className={`input-group ${windowWidth > 991 && 'w-40-per'}`}
                      >
                        <Field
                          name="max_budget"
                          type="text"
                          autocomplete="off"
                          className={`form-control ${
                            errors.max_budget && touched.max_budget && 'is-invalid '
                          } ${windowWidth > 991 && 'w-40-per'}`}
                          onKeyDown={(evt: any) => {
                            preventNonNumber(evt);
                          }}
                          onChange={(e: any) => {
                            setMaxBudget(+e.currentTarget.value);

                            handleChange(e);
                          }}
                          placeholder={t('invalid.Required')}
                        />
                        <div className="input-group-text">$</div>
                      </div>
                      <ErrorMessage
                        name="max_budget"
                        component="div"
                        className="invalid-message fs-6"
                      />
                      <ErrorFocus name="max_budget" />
                      <p className="font-size-12 mt-2">
                        {t('Minimum price')} {defaultCampaign.minimumMaxBudget}
                        $
                        <br />
                        {t(
                          'campaign:The campaign is limited to the total budget amount.',
                        )}
                      </p>
                    </FormGroup>
                  )}
                  <Country
                    isAllCountry={isAllCountry}
                    setIsAllCountry={setIsAllCountry}
                    countryData={countryData}
                    setCountryData={setCountryData}
                    countryOption={countryOption}
                  />
                  <div className="mt-4">
                    {ad_format === 'POP' ? (
                      <p className="font-size-18 fw-bold">
                        {t('creative:Pop under ad setup')}
                      </p>
                    ) : (
                      <p className="font-size-18 fw-bold">
                        {t('creative:Upload creatives')}
                      </p>
                    )}
                    <UploadCreativeDisplay
                      fileUploads={fileUploads}
                      setFileUploads={setFileUploads}
                      UploadAdFormat={
                        ad_format === 'BANNER'
                          ? `BANNER_${banner_format}`
                          : ad_format
                      }
                      layoutMode={layoutMode}
                      device_platform={device_platform}
                    />
                    <UploadCreativeTable
                      fileUploads={fileUploads}
                      setFileUploads={setFileUploads}
                      UploadAdFormat={
                        ad_format === 'BANNER' ? banner_format : ad_format
                      }
                    />
                  </div>
                  <p className="font-size-18 fw-bold mt-4">
                    {t('campaign:Run campaign after validation')}
                  </p>
                  <button
                    type="button"
                    className={`btn outline-sm btn-width-medium ${
                      auto_run && 'active'
                    }`}
                    onClick={() => setAutoRun(true)}
                  >
                    {t('button:Yes')}
                  </button>
                  <button
                    type="button"
                    className={`btn outline-sm btn-width-medium ms-2 ${
                      !auto_run && 'active'
                    }`}
                    onClick={() => setAutoRun(false)}
                  >
                    {t('button:No')}
                  </button>
                  <div className="mt-2 font-size-14 mb-3">
                    {t(
                      `campaign:The campaign will ${
                        auto_run ? 'run' : 'not run'
                      } automatically after validation.`,
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary hover-cw gray-hover c-w my-4 mt-0"
                >
                  <p className="m-0 font-size-22">
                    <img
                      src={
                        layoutMode === layoutTheme.DARKMODE ? AddIconDark : AddIcon
                      }
                      alt="AddIconImage"
                      className="icon-xxl"
                    />{' '}
                    {t('campaign:Create Campaign')}
                  </p>
                </button>
              </div>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default withTranslation()(CreateCampaignPage);
