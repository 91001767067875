import { Col, Row } from 'reactstrap';
import { formatDateForm, EndDays } from 'src/utils/dateTime';
import './GleamJob.style.scss';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const GleamJobTimeline = ({ job }: any) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(true);
  const insert = (arr: any, index: any, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];
  let timelineViewData = [
    {
      date: job?.last_day_accept_job,
      dataString: t('Influencer apply campaign with in'),
    },
    {
      date: job?.last_day_influencer_submit,
      dataString: job?.influencer_post_internal_job
        ? t('Influencer submit work for review by')
        : t('Influencer submit job by'),
    },
    {
      date: job?.last_day_to_approve,
      dataString: t('Marketer approve work piece with in'),
    },
  ];

  if (job?.job_type === 'PUBLISH') {
    timelineViewData = insert(timelineViewData, 1, {
      date: job?.last_day_approve_job,
      dataString: t('Marketer accept influencer with in'),
    });
  }

  if (job?.influencer_post_internal_job) {
    timelineViewData.push({
      date: job?.influencer_post_internal_job,
      dataString: t('Influencer sunmit work piece with in'),
    });
  }

  return (
    <div className="gleam-job-timeline-container gleam-margin">
      <div className="gleam-job-timeline-panel">
        <h5 style={{ marginBottom: '0', color: 'white' }}>
          Timeline{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            {isOpen ? (
              <i className="fa fa-chevron-down fa-icon" style={{ padding: '0' }} />
            ) : (
              <i className="fa fa-chevron-right fa-icon" style={{ padding: '0' }} />
            )}
          </span>
        </h5>
        <div className="gleam-job-timeline">
          <Row>
            <Col>
              <i className="fa fa-calendar g-icon" />
              {t('Start on')} {formatDateForm(job?.date_submission)}
            </Col>
          </Row>
          <GleamTimelineOption isOpen={isOpen}>
            {timelineViewData.map((item) => {
              return (
                <Row>
                  <Col>
                    <i className="fa fa-calendar g-icon" />
                    {item.dataString} {formatDateForm(item.date)}
                  </Col>
                </Row>
              );
            })}
          </GleamTimelineOption>
          <Row>
            <Col>
              <i className="fa fa-calendar g-icon" />
              {t('Release token on')} {formatDateForm(job?.finish_internal_work)}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const GleamTimelineOption = styled.div<{ isOpen: boolean }>`
  /* border: 5px solid; */
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export default GleamJobTimeline;
