import BannerFormat from 'src/constants/banner-format-Emun';
import highlightText from '../Styles/HighlightText';

const nbsp = (count: number, data: string) => (
  <>
    {[...Array(count)].map(() => (
      <>&nbsp;&nbsp;&nbsp;</>
    ))}
    {data}
    <br />
  </>
);

const AndroidContent = ({
  adKey,
  appKey,
  adType,
}: {
  adKey: string;
  appKey: string;
  adType: BannerFormat;
}) => (
  <>
    {nbsp(0, 'override fun onCreate(savedInstanceState: Bundle?) {')}
    {nbsp(0, 'super.onCreate(savedInstanceState)')}
    {nbsp(1, 'setContentView(R.layout.activity_main)')}
    {nbsp(2, 'apiService = ApiService.retrofitBuild()')}
    {adType === BannerFormat.IN_PAGE ? (
      <>
        {nbsp(3, 'Inpage()')}
        {nbsp(3, '}')}
        {nbsp(3, 'private fun Inpage(){')}
        {nbsp(3, 'val call: Call<AFResponse> = ')}
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(4, `apiService.getAdsData("${appKey}", "${adKey}")`)}
        </span>
        {nbsp(2, ' call.enqueue(object: Callback<AFResponse> {')}
        {nbsp(
          3,
          'override fun onResponse(call: Call<AFResponse>, response: Response<AFResponse>) {',
        )}
      </>
    ) : (
      ''
    )}
    {adType === BannerFormat.HEADER ? (
      <>
        {nbsp(3, 'Header()')}
        {nbsp(3, '}')}
        {nbsp(3, 'private fun Header(){')}
        {nbsp(3, 'val call: Call<AFResponse> = ')}
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(4, `apiService.getAdsData("${appKey}", "${adKey}")`)}
        </span>
        {nbsp(2, ' call.enqueue(object: Callback<AFResponse> {')}
        {nbsp(
          3,
          'override fun onResponse(call: Call<AFResponse>, response: Response<AFResponse>) {',
        )}
      </>
    ) : (
      ''
    )}
    {adType === BannerFormat.STICKY ? (
      <>
        {nbsp(3, 'Sticky()')}
        {nbsp(3, '}')}
        {nbsp(3, 'private fun Sticky(){')}
        {nbsp(3, 'val call: Call<AFResponse> = ')}
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(4, `apiService.getAdsData("${appKey}", "${adKey}")`)}
        </span>
        {nbsp(2, ' call.enqueue(object: Callback<AFResponse> {')}
        {nbsp(
          3,
          'override fun onResponse(call: Call<AFResponse>, response: Response<AFResponse>) {',
        )}
      </>
    ) : (
      ''
    )}
    {adType === BannerFormat.SLIDE ? (
      <>
        {nbsp(3, 'Slide()')}
        {nbsp(3, '}')}
        {nbsp(3, 'private fun Slide(){')}
        {nbsp(3, 'val call: Call<AFResponse> = ')}
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(4, `apiService.getAdsData("${appKey}", "${adKey}")`)}
        </span>
        {nbsp(2, ' call.enqueue(object: Callback<AFResponse> {')}
        {nbsp(
          3,
          'override fun onResponse(call: Call<AFResponse>, response: Response<AFResponse>) {',
        )}
      </>
    ) : (
      ''
    )}
    {/* {adType ===
      BannerFormat.HEADER(
        <>
          {nbsp(3, 'Header()')}
          {nbsp(3, '}')}
          {nbsp(3, 'private fun Inpage(){')}
          {nbsp(3, 'val call: Call<AFResponse> = ')}
          {adType === BannerFormat.HEADER ? (
            <span style={{ display: 'block', ...highlightText() }}>
              {nbsp(5, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
            </span>
          ) : (
            ''
          )}
          {nbsp(4, 'Container(')}
          {nbsp(5, 'child: const FooterPage(),')}
          {nbsp(4, '),')}
          {adType === BannerFormat.SLIDE ? (
            <span>
              {nbsp(4, 'Column(')}
              {nbsp(5, 'mainAxisAlignment: MainAxisAlignment.end,')}
              {nbsp(5, 'children: [')}
              {nbsp(6, 'Row(')}
              {nbsp(7, 'mainAxisAlignment: MainAxisAlignment.end,')}
              {nbsp(7, 'children: [')}
              <span style={{ display: 'block', ...highlightText() }}>
                {nbsp(8, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
              </span>
              {nbsp(7, '],')}
              {nbsp(6, '),')}
              {nbsp(6, '// Sticky')}
              {nbsp(6, '//AFB(appKey: "example", adKey: "example")')}
              {nbsp(5, '],')}
              {nbsp(4, '), // Column')}
            </span>
          ) : (
            ''
          )}

          {adType === BannerFormat.STICKY ? (
            <span>
              {nbsp(4, 'Column(')}
              {nbsp(5, 'mainAxisAlignment: MainAxisAlignment.end,')}
              {nbsp(5, 'children: [')}
              {nbsp(6, 'Row(')}
              {nbsp(7, 'mainAxisAlignment: MainAxisAlignment.end,')}
              {nbsp(7, 'children: [')}
              {nbsp(8, '// Slide')}
              {nbsp(8, `//AFB(appKey: "example", adKey: "example")`)}
              {nbsp(7, '],')}
              {nbsp(6, '),')}
              <span style={{ display: 'block', ...highlightText() }}>
                {nbsp(6, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
              </span>
              {nbsp(5, '],')}
              {nbsp(4, '), // Column')}
            </span>
          ) : (
            ''
          )}
          {nbsp(3, `], // <Widget>[]`)}
          {nbsp(2, '), // Stack')}
          {nbsp(1, '), // Scaffold')}
          {nbsp(1, '), // SafeArea')}
          {nbsp(1, '}')}
        </>,
      )} */}
  </>
);

export default AndroidContent;
