import { AllowNotificationMailTypes } from './actionTypes';

export const getAllowNotificationMails = () => ({
  type: AllowNotificationMailTypes.GET_ALLOW_NOTIFICATION_MAIL,
});

export const getAllowNotificationMailsSuccess = (allowNotificationMail: any) => ({
  type: AllowNotificationMailTypes.GET_ALLOW_NOTIFICATION_MAIL_SUCCESS,
  payload: allowNotificationMail,
});

export const getAllowNotificationMailsFail = (error: any) => ({
  type: AllowNotificationMailTypes.GET_ALLOW_NOTIFICATION_MAIL_FAIL,
  payload: error,
});

export const updateAllowNotificationMails = (data: any) => ({
  type: AllowNotificationMailTypes.UPDATE_ALLOW_NOTIFICATION_MAIL,
  data,
});

export const updateAllowNotificationMailsSuccess = (allowNotificationMail: any) => ({
  type: AllowNotificationMailTypes.UPDATE_ALLOW_NOTIFICATION_MAIL_SUCCESS,
  payload: allowNotificationMail,
});

export const updateAllowNotificationMailsFail = (error: any) => ({
  type: AllowNotificationMailTypes.UPDATE_ALLOW_NOTIFICATION_MAIL_FAIL,
  payload: error,
});
