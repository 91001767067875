import { call, put, takeEvery } from 'redux-saga/effects';
import { MovementTypes } from './actionTypes';
import { getMovementBalanceTransactions } from '../../graphql/common/movement';
import { getMovementSuccess } from './actions';

function* getMovement({ payload: MovementTransactionsQuery }: any) {
  const response: Promise<any> = yield call(
    getMovementBalanceTransactions,
    MovementTransactionsQuery,
  );

  yield put(getMovementSuccess(response));
}

function* movementSaga() {
  // @ts-ignore
  yield takeEvery(MovementTypes.GET_MOVEMENT, getMovement);
}

export default movementSaga;
