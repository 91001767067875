import ReactQuill from 'react-quill';
import './textEditorInput.style.scss';

const TextEditorInput = ({
  setValue,
  value,
  handleChange,
  isInvalid,
  placeholder,
}: any) => {
  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'align',
    'color',
    'background',
  ];
  const textChange = (editor: any) => {
    let newEditor = editor;
    if (editor === '<p><br></p>') newEditor = '';
    setValue(newEditor);
    handleChange(newEditor);
  };

  return (
    <div className="text-editor-input-container">
      <ReactQuill
        placeholder={placeholder}
        theme="snow"
        modules={modules}
        formats={formats}
        className={`ql-editor ${isInvalid ? 'editor-error' : ''}`}
        onChange={textChange}
        value={value || ''}
      />
    </div>
  );
};

export default TextEditorInput;
