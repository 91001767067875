import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import i18n from '../../i18n';
import 'src/pages/Publisher/Influencer/jobIndex.style.scss';

enum FilterJobSortBy {
  RECENT,
  NAME,
}

const sortList = [
  {
    title: i18n.t('Recent Jobs'),
    value: FilterJobSortBy.RECENT,
  },
  {
    title: i18n.t('Brand Name (A-Z)'),
    value: FilterJobSortBy.NAME,
  },
];

const SortPublicJob = ({ param, handleStatusChange, disableValue }: any) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [title, setTitle] = useState(t('Sort By'));
  const toggle = () => setDropdownOpen((prevState: any) => !prevState);
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret
            style={{
              width: '120px',
              height: '34px',
              background: 'white',
              borderRadius: '8px',
              border: 'none',
              color: 'black',
            }}
            className="job-button-filter"
          >
            <i
              className="fa fa-sort-amount-down"
              style={{ marginRight: '10px', color: 'black' }}
            />
            {t(`button:${title}`)}
          </DropdownToggle>
          <DropdownMenu>
            {sortList.map((item: any) => {
              return !(disableValue?.length && disableValue.includes(item.value)) ? (
                <DropdownItem
                  onClick={() => {
                    handleStatusChange(item.value);
                  }}
                  className={`btn outline-sm m-1 `}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  key={item.value}
                >
                  {item.title}
                  {param?.sort === item.value && <i className="fas fa-check" />}
                </DropdownItem>
              ) : (
                <div key={null} />
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};
export default SortPublicJob;
