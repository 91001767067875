import { Collapse } from 'reactstrap';
import complete from 'src/assets/images/social/complete.svg';
import earning from 'src/assets/images/social/earning.svg';
import engagement from 'src/assets/images/social/engagement.svg';
import follower from 'src/assets/images/social/follower.svg';
import { useTranslation } from 'react-i18next';
import './jobDetailCollapse4.style.scss';
import earnmoney from 'src/assets/images/earnmoney.png';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { floorToFixed, removeDecimal } from 'src/common/data/mathToFixed';

const JobDetailCollapse4 = ({
  socialJobItem,
  setCollapse,
  collapse,
  marketerJob,
}: any) => {
  const { t, i18n } = useTranslation();
  const date = new Date(marketerJob.finish_internal_work);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const { socialPost } = useSelector((state: any) => ({
    socialPost: state.SocialMediaInfo.getSocialPost,
  }));

  useEffect(() => {
    if (collapse.collapse4) setIsCollapseOpen(true);
  }, []);

  return (
    <div className="job-detail-collapse4-container">
      <div
        className={`job-detail-flex-container ${
          isCollapseOpen ? 'border-radius-card-open' : 'border-radius-card-close'
        }`}
        onClick={() => setCollapse({ ...collapse, collapse4: !collapse.collapse4 })}
        style={{ cursor: 'pointer' }}
      >
        <div className="job-detail-flex">
          <div style={{ display: 'flex' }}>
            <div>
              {socialJobItem?.end_date && (
                <div className="icon-check">
                  <i
                    className="fa fa-check"
                    style={{
                      fontSize: '10px',
                      position: 'absolute',
                      top: '3px',
                      left: '2px',
                    }}
                  />
                </div>
              )}
              {!socialJobItem?.link_post ? (
                <h5 className="job-detail-number">4</h5>
              ) : (
                <h5 className="job-detail-number">3</h5>
              )}
            </div>
            <div>
              <h5 className="fs-5 mt-2">
                {t('marketer:job_detail:Collect statistics and earn money')}
              </h5>
            </div>
          </div>
          <div className="work-icon-container">
            <img
              src={earnmoney}
              alt=""
              style={{ width: '40px', marginRight: '45px' }}
            />
          </div>
        </div>
      </div>
      <Collapse
        isOpen={
          collapse.collapse4 &&
          !!socialJobItem?.post_date &&
          socialJobItem?.status !== 'CANCEL'
        }
        className="job-detail-collapse"
        onEntering={() => {
          setIsCollapseOpen(true);
        }}
        onExited={() => {
          setIsCollapseOpen(false);
        }}
      >
        <div className="influencer-card flex-center">
          <h5>
            {t(`influencer:job_detail:set post`)}{' '}
            {i18n.language === 'th'
              ? `${date.toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })} - ${new Date(
                  date.setMonth(date.getMonth() + 1),
                ).toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}`
              : `${date.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })} - ${new Date(
                  date.setMonth(date.getMonth() + 1),
                ).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}`}
          </h5>

          <div className="statistics-control">
            <div className="inner-statistics-control">
              <img src={follower} alt="follower" className="icon" />
              <p>
                {removeDecimal(
                  floorToFixed(
                    socialJobItem?.social_media_info?.follower,
                    1,
                  ) as string,
                )}{' '}
                {t(`influencer:job_detail:follower`)}
              </p>
              <img src={engagement} alt="engagement" className="icon" />
              <p>
                {removeDecimal(floorToFixed(socialPost?.engagement, 1) as string)}{' '}
                {t(`influencer:job_detail:engagement`)}
              </p>
            </div>
          </div>
          <div className="statistics-control">
            <div className="inner-statistics-control">
              <div className="icon">
                <img src={complete} alt="complete" className="icon" />
              </div>
              <p>
                {socialJobItem?.end_date
                  ? `${t(`influencer:job_detail:complete`)} ${new Date(
                      socialJobItem?.end_date,
                    )?.toLocaleDateString()}`
                  : `${t(`influencer:job_detail:No information yet`)}`}
              </p>

              <div className="icon">
                <img src={earning} alt="earning" className="icon" />
              </div>
              <div
                style={{
                  fontSize: '16px',
                  width: '90%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {socialJobItem?.status === 'FINISHED' ? (
                  <div style={{ display: 'flex' }}>
                    <div>{t(`influencer:job_detail:earning`)}</div>
                    <div style={{ color: 'rgb(0, 154, 120)', marginLeft: '5px' }}>
                      {floorToFixed(socialJobItem?.price, 2)}{' '}
                      {socialJobItem?.job?.currency?.main_symbol ?? 'USDT'}
                    </div>
                  </div>
                ) : (
                  `${t(`influencer:job_detail:No information yet`)}`
                )}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      {/* {!collapse.collapse4 && <hr />} */}
    </div>
  );
};

export default JobDetailCollapse4;
