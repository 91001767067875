import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignTodayTotal as onGetCampaignTodayTotal } from 'src/store/actions';
import { floorToFixed } from 'src/common/data/mathToFixed';

const CampaignTotalWidget = ({ t }: any) => {
  const dispatch = useDispatch();

  const { todayTotal } = useSelector((state: any) => ({
    todayTotal: state.Campaigns.todayTotal,
  }));

  useEffect(() => {
    dispatch(onGetCampaignTodayTotal());
  }, []);

  return (
    <div className="h-100-per p-0 mb-0">
      <Row className="justify-content-center h-100">
        <Col xs={12} style={{ height: 'auto' }}>
          <span className="font-size-20 fw-bold p-0 m-0">{t('Today Total')}</span>
        </Col>
        <Col xs={12} sm={6} className="pe-sm-1 pb-sm-1">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span style={{ color: '#009A78' }}>
                    {floorToFixed(todayTotal.spend, 2)} USDT
                  </span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('Spend')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-coins fs-1"
                  style={{ fontSize: '1.5em', color: '#7D9D9C' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6} className="ps-sm-1 pb-sm-1">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{todayTotal.impression}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('Impressions')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-eye fs-1"
                  style={{ fontSize: '1.5em', color: '#7D9D9C' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6} className="pe-sm-1 pt-sm-1">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{todayTotal.click}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('Clicks')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-mouse fs-1"
                  style={{ fontSize: '1.5em', color: '#7D9D9C' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6} className="ps-sm-1 pt-sm-1">
          <div className="card-shadow-sm px-3 py-2 w-100 h-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{floorToFixed(todayTotal.CTR, 2)}%</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('CTR')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-percent fs-1"
                  style={{ fontSize: '1.5em', color: '#7D9D9C' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        {/* <Col xs={12} className="pt-sm-1">
          <div className="card-shadow-sm w-100 h-100 bg-danger" />
        </Col> */}
      </Row>
    </div>
  );
};

export default withTranslation()(CampaignTotalWidget);
