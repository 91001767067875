import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  getLoggedInUser,
  getMarketerJobSummary,
  getSocialGleamJob,
  getSocialGleamJobForReview,
} from 'src/store/actions';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import MetaTags from 'react-meta-tags';
import { useHistory } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { formatDateForm } from 'src/utils/dateTime';
import { useTranslation } from 'react-i18next';
import GleamJobComponent from 'src/components/Common/GleamJobComponent';
import GleamJobTimeline from 'src/components/Common/GleamJobTimeline';
import GleamJobTaskHeaderInfluencer from './GleamJobTaskHeaderInfluencer';
import './GleamJob.style.scss';
import { InfluencerSocialIcon } from './status/InfluencerJobStatus';
// eslint-disable-next-line import/order
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import GleamJobTaskInfluencer from './GleamJobTaskInfluencer';
import GleamJobTaskMarketer from './GleamJobTaskMarketer';
// eslint-disable-next-line import/order
import Role from 'src/constants/enum/role';

const GleamJobReviewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [jobId, group, social_key] = paramsFromURL(['job', 'task', 'key']);
  const [progress, setProgress] = useState(0);
  const [totalTask, setTotalTask] = useState(0);
  const [paid, setPaid] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [status, setStatus] = useState('');
  const [data, setData] = useState<any>([]);
  const [round, setRound] = useState(0);

  const { jobSummay, socialGleamJboForReview, user } = useSelector((state: any) => ({
    jobSummay: state?.MarketerJob?.marketerJob,
    socialGleamJboForReview: state.SocialMediaInfo.socialGleamJboForReview,
    user: state.login.user,
  }));

  // console.log(user);

  useEffect(() => {
    // dispatch(getLoggedInUser());
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
    }
    if (group && social_key) {
      dispatch(getSocialGleamJobForReview({ key: group, social_key }));
    }
  }, []);

  useEffect(() => {
    if (socialGleamJboForReview) {
      let _progress = 0;
      let total_progress = 0;
      let _paid = 0;
      let total_paid = 0;
      let _status = 'CANCEL';
      for (let i = 0; i < socialGleamJboForReview?.socialHasJob?.length; i++) {
        if (socialGleamJboForReview?.socialHasJob[i]?.post_date) {
          _progress += 1;
          _paid +=
            socialGleamJboForReview?.socialHasJob[i]?.price +
            socialGleamJboForReview?.socialHasJob[i]?.tax;
        }
        total_paid +=
          socialGleamJboForReview?.socialHasJob[i]?.price +
          socialGleamJboForReview?.socialHasJob[i]?.tax;
        total_progress += 1;
        if (socialGleamJboForReview?.socialHasJob[i].status !== 'CANCEL') {
          _status = socialGleamJboForReview?.socialHasJob[i].status;
        }
      }

      setProgress(_progress);
      setPaid(_paid);
      setTotalPrice(total_paid);
      setTotalTask(total_progress);
      setStatus(_status);
    }
    if (round !== 0) {
      setData(socialGleamJboForReview);
    }
    setRound(round + 1);
  }, [socialGleamJboForReview]);
  console.log('user', user);
  const onGoBack = () => {
    // history.replace({
    //   pathname: `/campaigns/markerter/job/publishDetail?job=${jobId}`,
    // });
    // history.push(`/campaigns/markerter/job/publishDetail?job=${jobId}`);
    // state: { from: 'fromSignUp' },history.push({    // no need

    history.push(
      `/campaigns/markerter/job/publishDetail?job=${jobId}&tab=${'ReviewJob'}`,
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <i
        className="fa fa-chevron-left fa-icon"
        onClick={() => onGoBack()}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '5vh',
          color: 'white',
        }}
      />{' '}
      <div className="dashboard-wraper ">
        <MetaTags>
          <title>Review Job: {jobSummay?.name}</title>
        </MetaTags>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '14px',
            marginBottom: '20px',
            color: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              paddingTop: `${user.role === Role.ADVERTISER ? '4vh' : ''}`,
              paddingLeft: user.role === Role.ADVERTISER ? '2vh' : '',
            }}
          >
            <div
              style={{ cursor: 'pointer', paddingLeft: '0' }}
              onClick={() => {
                history.push('/');
              }}
            >
              {t('Home')}
            </div>
            <div>&gt; </div>
            <div
              style={{ cursor: 'pointer', paddingLeft: '0' }}
              onClick={() => {
                history.push('/campaigns/markerter/job');
              }}
            >
              {t('Campaign')}
            </div>
            <div>&gt; </div>
            <div
              onClick={() => {
                onGoBack();
              }}
              style={{ cursor: 'pointer' }}
            >
              {jobSummay?.name} <br />
            </div>
            <div>&gt; </div>
            <div>{t('table:Review job t')}</div>
          </div>
        </div>
        <Container fluid>
          {user.role === Role.ADMIN && (
            <>
              <GleamJobComponent job={jobSummay} is_working />
              <GleamJobTimeline job={jobSummay} />
            </>
          )}

          <div className="gleam-job-component gleam-margin">
            <div className="gleam-job-panel" style={{ maxWidth: '1000px' }}>
              <Row
                style={{
                  marginLeft: '0px',
                  marginRight: '0px',
                  minHeight: '55px',
                }}
              >
                <Col
                  className="gleam-job-header"
                  style={{
                    borderBottomColor: 'rgb(83, 198, 183)',
                    borderRight: '1px solid #e9edee',
                  }}
                >
                  <div style={{ padding: '4px' }}>
                    <h6>{t('table:Progress')}</h6>
                    <h5>
                      {progress} / {totalTask}
                    </h5>
                  </div>
                </Col>
                <Col
                  className="gleam-job-header"
                  style={{
                    borderBottomColor: '#495057',
                    borderRight: '1px solid #e9edee',
                  }}
                >
                  <div style={{ padding: '4px' }}>
                    <h6>{t('Review and approve with in')}</h6>
                    <h5>{formatDateForm(jobSummay?.last_day_to_approve)}</h5>
                  </div>
                </Col>
                <Col
                  className="gleam-job-header"
                  style={{ borderBottomColor: 'rgb(255, 188, 84)' }}
                >
                  <div style={{ padding: '4px' }}>
                    <h6>{t('marketer:create_campaign:Usage_Prize')}</h6>
                    <h5>
                      {floorToFixed(paid, 2)} / {floorToFixed(totalPrice, 2)}
                      {jobSummay?.currency?.main_symbol ?? 'USDT'}
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  marginLeft: '0px',
                  marginRight: '0px',
                  height: '66.67px',
                }}
              >
                <Col
                  className="gleam-job-header"
                  style={{
                    borderBottomColor: 'rgb(255, 188, 84)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <InfluencerSocialIcon
                    social_media_type={
                      socialGleamJboForReview?.social_info?.social_media_type
                    }
                  />
                  <img
                    style={{
                      width: `30px`,
                      height: `30px`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src={
                      socialGleamJboForReview?.social_info?.image_profile_url ||
                      userAvatar
                    }
                    alt="Influencer Profile"
                    onError={(e) => {
                      e.currentTarget.src = userAvatar;
                    }}
                  />
                  <div>
                    <h5 style={{ marginBottom: '0' }}>
                      {socialGleamJboForReview?.social_info?.name}
                    </h5>
                  </div>
                </Col>
              </Row>
              <div style={{ marginTop: '11px' }}>
                <div
                  className="gleam-job-task-container"
                  style={{
                    borderRadius: '6px',
                    paddingBottom: '0',
                  }}
                >
                  {data?.socialHasJob?.map((job: any) => {
                    return <GleamJobTaskMarketer job_tasks={job} open />;
                  })}
                </div>
                {/* {socialJobTasks?.map((item: any) => {
                  return (
                    <div>
                      <GleamJobTaskHeaderInfluencer jobTask={item} />
                    </div>
                  );
                })} */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default GleamJobReviewPage;
