import React from 'react';
import { Badge } from 'reactstrap';
import WithdrawStatuses from '../../constants/withdrawStatuses';

const WithdrawStatus = ({ status }: any) => {
  const renderStatus = () => {
    switch (status) {
      case WithdrawStatuses.PENDING:
        return <Badge className="bg-warning">{status}</Badge>;
      case WithdrawStatuses.APPROVED:
        return <Badge className="bg-success">{status}</Badge>;
      case WithdrawStatuses.REJECTED:
        return <Badge className="bg-danger">{status}</Badge>;
      default:
        return <p className="bg-warning text-light">{status}</p>;
    }
  };

  return <>{renderStatus()}</>;
};

export default WithdrawStatus;
