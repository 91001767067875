import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import currenciesSymbol from '../../../constants/currenciesSymbol';

const CryptosBalancePieChart = () => {
  const { cryptoWallets } = useSelector((state: any) => ({
    cryptoWallets: state.SystemBalance.cryptoWallets,
  }));

  const colorData = [];
  const seriesData = [];
  const labelData = [];

  const getPieChartColor = (symbol: string) => {
    let colorCode = '';

    switch (symbol) {
      case currenciesSymbol.BTC:
        colorCode = '#f2a900';
        break;
      case currenciesSymbol.ETH:
        colorCode = '#627EEA';
        break;
      case currenciesSymbol.USDT:
        colorCode = '#189871';
        break;
      case currenciesSymbol.XRP:
        colorCode = '#434c54';
        break;
      case currenciesSymbol.WIS:
        colorCode = '#007aa3';
        break;
      case currenciesSymbol.DOGE:
        colorCode = '#dfa005';
        break;
      default:
        colorCode = '';
    }

    return colorCode;
  };

  if (!isEmpty(cryptoWallets)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const wallet of cryptoWallets) {
      if (+wallet.FIAT_amount > 0) {
        colorData.push(getPieChartColor(wallet.symbol));
        seriesData.push(+wallet.FIAT_amount);
        labelData.push(wallet.symbol);
      }
    }
  }

  const options: Object = {
    chart: {
      width: 227,
      height: 227,
      type: 'pie',
    },
    labels: labelData,
    colors: colorData,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <>
      {!isEmpty(cryptoWallets) && (
        <ReactApexChart
          options={options}
          series={seriesData}
          type="pie"
          height="227"
        />
      )}
    </>
  );
};

export default CryptosBalancePieChart;
