import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Modal,
  CardHeader,
  Row,
  Col,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import useWindowSize from 'src/utils/windowSize';
import { transformAddressInShortFrom } from 'src/utils/address';
import CoinImage from '../Common/CoinImage';
import { getNetworkFromSymbol } from '../../utils/currency';
import CurrenciesSymbol from '../../constants/currenciesSymbol';
import { transformStringAmountInput } from '../../utils/input';
import './DepositAddress.style.scss';
import SearchBar from '../Table/SearchBar';

const DepositAddress = ({ t, addresses }: any) => {
  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const { width } = useWindowSize();
  const [index, setIndex] = useState(0);
  const [modalOpen, toggleModal] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied2) setCopied2(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied2]);

  useEffect(() => {
    if (addresses?.length > 0) {
      setIndex(0);
    }
  }, []);

  const handleCopy = (val: string) => {
    navigator.clipboard.writeText(val);

    setCopied(true);
  };

  const handleCopy2 = (val: string) => {
    navigator.clipboard.writeText(val);

    setCopied2(true);
  };

  const renderNetworkSelections = () => {
    const html: any = [];

    addresses.map((address: any, key: number) => {
      html.push(
        <div
          className="p-2"
          onClick={() => {
            setIndex(key);
            toggleModal(false);
          }}
        >
          <div className="address-chain mt-3 p-2 card-shadow-sm">
            <div>
              <div>
                <strong>{getNetworkFromSymbol(address.currency.symbol)}</strong>
              </div>
              <div>{address.currency.network}</div>
            </div>
          </div>
        </div>,
      );

      return true;
    });

    return html;
  };
  const cutFixed = (usdt: any) => {
    const decimal_number = usdt.split('.');
    const decimal_length = decimal_number[1] ? decimal_number[1]?.length : 0;
    let last_zero_count = 0;
    for (let i = decimal_length - 1; i >= 0; i--) {
      if (decimal_number[1][i] !== '0') {
        break;
      } else {
        last_zero_count += 1;
      }
    }
    const usdtString = parseFloat(usdt);

    const left =
      decimal_length - last_zero_count < 0 ? 0 : decimal_length - last_zero_count;

    return usdtString.toFixed(left);
  };

  const cutFixed2 = (usdt: any, decimal: any) => {
    let usdtString = usdt.toString();
    let decimal_number = usdtString.split('.');
    if (!decimal_number[1]) {
      usdtString = usdt.toFixed(decimal);
      decimal_number = usdtString.split('.');
    }
    const decimal_length = decimal_number[1] ? decimal_number[1]?.length : 0;
    let last_zero_count = 0;
    for (let i = decimal_length - 1; i >= 0; i--) {
      if (decimal_number[1][i] !== '0') {
        break;
      } else {
        last_zero_count += 1;
      }
    }
    const outUsdt = parseFloat(usdt);
    const left =
      decimal_length - last_zero_count < 0 ? 0 : decimal_length - last_zero_count;
    return outUsdt.toFixed(left);
  };

  return (
    <>
      {addresses?.length && (
        <Card
          style={{
            backgroundColor: 'white',
            width: '95%',
          }}
        >
          <CardBody className="overflow-hidden position-relative">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <h6
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  className="text-primary m-0 me-2"
                >
                  <div>
                    <CoinImage symbol={addresses[index].currency.symbol} size="md" />
                  </div>
                  <span
                    style={{
                      color: 'gray',
                      marginLeft: '5px',
                      fontWeight: 'bold',
                      display: `${width <= 455 ? 'none' : ''}`,
                      fontSize: `${width <= 455 ? '11px' : '15px'}`,
                    }}
                  >
                    {addresses[index].currency.main_symbol}
                  </span>
                  <span
                    style={{
                      marginLeft: '5px',
                      fontSize: `${width <= 455 ? '11px' : '15px'}`,
                    }}
                  >
                    {addresses[index].wallet?.balance}
                  </span>
                  <span
                    style={{
                      marginLeft: '5px',
                      fontSize: `${width <= 455 ? '11px' : '15px'}`,
                    }}
                  >
                    {addresses[index].currency.main_symbol}
                  </span>
                </h6>
              </div>
              <div>
                {addresses?.length > 1 ? (
                  <>
                    <span
                      style={{
                        cursor: 'pointer',
                        fontSize: `${width <= 375 ? '10px' : ''}`,
                      }}
                      className="badge bg-advertiser text-center p-2"
                      onClick={() => {
                        toggleModal(true);
                      }}
                    >
                      <i className="fas fa-exchange-alt mx-2" />
                      {/* {addresses[index].currency.network} */}
                      <i className="fas fa-chevron-circle-right mx-2 " />
                    </span>
                    <Modal
                      size="md"
                      isOpen={modalOpen}
                      className="modal-dialog-centered"
                    >
                      <Card className="mb-xl-0">
                        <CardHeader>
                          <h4>{t('Select Network')}</h4>
                          <p className="card-title-desc">{t('Ensure Network')}</p>
                          <button
                            type="button"
                            onClick={() => {
                              toggleModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </CardHeader>
                        <CardBody>{renderNetworkSelections()}</CardBody>
                      </Card>
                    </Modal>
                    {/* </select> */}
                  </>
                ) : (
                  addresses[index].currency.symbol?.length > 1 && (
                    <span
                      className="badge bg-advertiser text-center p-2"
                      style={{
                        cursor: 'no-drop',
                        fontSize: `${width <= 455 ? '9px' : ''}`,
                      }}
                    >
                      {width <= 400 ? '' : `${t('Network')} :`}{' '}
                      {width <= 400
                        ? ''
                        : `${getNetworkFromSymbol(
                            addresses[index].currency.symbol,
                          )}`}{' '}
                      {`${addresses[index].currency.network}`}
                    </span>
                  )
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: `${width <= 1600 ? 'column' : 'row'}`,
                justifyContent: 'space-between',
                alignItems: `${width <= 1600 ? 'start' : 'center'}`,
              }}
            >
              <div>
                <span
                  className="text-muted badge badge-soft-dark fs-6 fw-bold overflow-hidden text-center p-2"
                  style={{
                    // marginLeft: `${width <= 477 ? '' : '40px'}`,
                    marginTop: '10px',
                  }}
                >
                  {addresses[index].currency.is_marketer_currency ? (
                    <div style={{ fontSize: '9px' }}>
                      1 {addresses[index].currency.main_symbol}
                      <i className="fas fa-exchange-alt mx-2" />{' '}
                      {
                        // cutFixed(
                        //   addresses[index].currency.price_in_USDT.toFixed(
                        //     addresses[index].currency.decimals,
                        //   ),
                        // )
                        cutFixed2(
                          addresses[index].currency.price_in_USDT,
                          addresses[index].currency.decimals,
                        )
                      }
                      {' USDT'}
                    </div>
                  ) : (
                    <div style={{ fontSize: '9px' }}>
                      $
                      {transformStringAmountInput(
                        addresses[index].currency.rate_in_USD,
                      )}{' '}
                      <i className="fas fa-exchange-alt mx-2" /> 1{' '}
                      {addresses[index].currency.main_symbol}
                    </div>
                  )}
                </span>
              </div>
              <div>
                {addresses[index].currency.contract_address && (
                  <span
                    className="badge bg-advertiser text-center p-2"
                    style={{
                      cursor: 'no-drop',
                      fontSize: '10px',
                      marginTop: '5px',
                      marginBottom: '7px',
                    }}
                  >
                    {width <= 400 ? '' : `${t('Token address')} :`}{' '}
                    {width <= 400
                      ? `${transformAddressInShortFrom(
                          addresses[index].currency.contract_address,
                          6,
                        )}`
                      : `${addresses[index].currency.contract_address}`}
                  </span>
                )}
              </div>
            </div>
            {/* ///orther */}
            <div>
              <InputGroup>
                <Input
                  placeholder={addresses[index].address}
                  disabled
                  className="disabled-input-deposit"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    className="btn-deposit-addresses"
                    // color="primary"
                    onClick={() => {
                      handleCopy(addresses[index].address);
                    }}
                  >
                    <i className="fas fa-copy" />
                    &nbsp;{copied ? 'Copied' : 'Copy'}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              {addresses[index].currency.main_symbol === CurrenciesSymbol.XRP && (
                <InputGroup className="mt-3">
                  <Input
                    placeholder={addresses[index].tag}
                    disabled
                    className="disabled-input-deposit"
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="primary"
                      onClick={() => {
                        handleCopy2(addresses[index].tag);
                      }}
                    >
                      <i className="fas fa-copy" />
                      &nbsp;{copied2 ? 'Copied' : 'Copy'}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default withTranslation()(DepositAddress);
