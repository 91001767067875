import React, { useEffect, useState } from 'react';
import NewSocialProfile from 'src/components/Publisher/Influencer/ChannelDetail/NewSocialProfile';
import SocialInfluencerProfile from 'src/components/Publisher/Influencer/ChannelDetail/SocialInfluencerMock';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import { useDispatch } from 'react-redux';
import { getInfluencerPriceRate } from 'src/store/actions';
import { initialForm } from './control/formControl';

const ChannelDetailPage = (params: any) => {
  const dispatch = useDispatch();
  const [socialMediaLogo, setSocialMediaLogo] = useState('');
  const [formValues, setFormValues] = useState<UserSocialProfile>(initialForm);
  const key = null; // You might want to define the key dynamically

  useEffect(() => {
    const userData = {
      aptitude_ids: [5],
    };
    setFormValues((current: any) => ({
      ...current,
      ...userData,
    }));
    dispatch(getInfluencerPriceRate());
  }, []);

  return (
    <NewSocialProfile
      setFormValues={setFormValues}
      socialMediaLogo={socialMediaLogo}
      formValues={formValues}
    />
  );
};

export default ChannelDetailPage;
