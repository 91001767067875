import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { withTranslation } from 'react-i18next';
import { layoutTheme } from 'src/constants/layout';
import { useSelector } from 'react-redux';

const Country = (props: any) => {
  const {
    isAllCountry,
    setIsAllCountry,
    setCountryData,
    countryOption,
    countryData,
    fromSetup,
    t,
  } = props;

  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  const selectStyle = {
    control: (styles: any) => {
      return layoutMode === layoutTheme.DARKMODE
        ? {
            ...styles,
            backgroundColor: '#0d0f00',
            borderColor: '#474d4a',
            color: 'black',
            '&:hover': {
              borderColor: '#009a78',
            },
            boxShadow: 'none',
            cursor: 'pointer',
          }
        : {
            ...styles,
            borderColor: '#ced4d9',
            '&:hover': {
              borderColor: '#009a78',
            },
            boxShadow: 'none',
            cursor: 'pointer',
          };
    },
    option: (styles: any, optionProps: any) => {
      const newStyle = styles;
      newStyle[':active'].backgroundColor = '#009a78';
      const backgroundColor = layoutMode === layoutTheme.DARKMODE ? '#0d0f00' : '';
      const focused =
        layoutMode === layoutTheme.DARKMODE
          ? { color: optionProps.isFocused ? 'black' : 'a3958f' }
          : { color: optionProps.isFocused ? 'white' : 'black' };
      return {
        ...newStyle,
        backgroundColor: optionProps.isFocused ? '#009a78' : backgroundColor,
        ...focused,
      };
    },
    menu: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            backgroundColor: '#0d0f00',
          }
        : {
            ...provided,
          },
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#009a78',
    }),
    multiValueLabel: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            color: 'black',
          }
        : {
            ...provided,
            color: 'white',
          },

    multiValueRemove: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            color: 'black',
            '&:hover': {
              backgroundColor: '#037e63',
            },
          }
        : {
            ...provided,
            color: 'white',
            '&:hover': {
              backgroundColor: '#037e63',
            },
          },
  };

  const handleChange = (selectedOptions: React.SetStateAction<never[]>) => {
    setCountryData(selectedOptions);
  };
  const animatedComponents = makeAnimated();

  return (
    <div>
      {!fromSetup && (
        <Label className="input-label fs-5">
          {t('Countries')}
          <span className="input-required">*</span>
        </Label>
      )}
      <Row className="align-items-baseline">
        <Col className="flex-grow-1 d-flex flex-column flex-sm-row">
          <button
            type="button"
            className={`btn outline-sm me-0 me-sm-2 ${isAllCountry && 'active'}`}
            onClick={() => setIsAllCountry(true)}
          >
            {t('All countries')}
          </button>
          <button
            type="button"
            className={`btn outline-sm mt-2 mt-sm-0  ${!isAllCountry && 'active'}`}
            onClick={() => setIsAllCountry(false)}
          >
            {t('campaign:Custom')}
          </button>
        </Col>
        {!isAllCountry && (
          <Col className="d-flex justify-content-end">
            <button
              className="btn outline-sm"
              type="button"
              onClick={() => {
                setCountryData(countryOption);
              }}
            >
              {t('Select all')}
            </button>
          </Col>
        )}
      </Row>
      <Row>
        {!isAllCountry && (
          <Col md={12}>
            <Select
              className="mt-2"
              isMulti
              options={countryOption}
              value={countryData}
              classNamePrefix="select2-selection"
              closeMenuOnSelect={false}
              onChange={handleChange}
              components={animatedComponents}
              styles={selectStyle}
              placeholder={t('Select')}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
export default withTranslation()(Country);
