/* eslint-disable no-shadow */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getCampaignReport as onGetCampaignReport } from 'src/store/actions';
import { Column } from 'react-table';
import { useParams } from 'react-router';
import i18n from 'src/i18n';
import balanceLogRangeEnum from 'src/constants/balanceLogRangeEnum';
import { getDateFromDatePicker } from 'src/utils/dateTime';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import DateTimeComponent from '../Common/DateTimeComponent';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DatePickerOptions from '../Table/DatePickerOptions';

const CampaignPaymentReportTable = ({ t }: any) => {
  const { id }: any = useParams();

  const dispatch = useDispatch();

  const { campaignReport, campaignDatumLoading } = useSelector((state: any) => ({
    campaignReport: state.Campaigns.campaignReport,
    campaignDatumLoading: state.Global.campaignDatumLoading,
  }));

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const balanceLogRange = balanceLogRangeEnum.DAILY;

  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      campaign_key: id,
      start_date: startDate,
      end_date: endDate,
      page,
    };
  };

  useEffect(() => {
    dispatch(onGetCampaignReport(getDefaultPayload()));
  }, []);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? +value : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };

    dispatch(onGetCampaignReport(payload));
    setPage(newPage);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = +size;

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(),
      limit: newPageSize,
      page: newPage,
    };

    dispatch(onGetCampaignReport(payload));
    setPageSize(newPageSize);
    setPage(newPage);
  };
  const handleStartDateChange = (date: any) => {
    const payload = {
      ...getDefaultPayload(),
      start_date: getDateFromDatePicker(date, true, balanceLogRange),
      page: 1,
    };

    dispatch(onGetCampaignReport(payload));
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    const payload = {
      ...getDefaultPayload(),
      end_date: getDateFromDatePicker(date, false, balanceLogRange),
      page: 1,
    };
    dispatch(onGetCampaignReport(payload));
    setEndDate(date);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        sortBy: newSortBy,
        sortType: newSortType,
        page: newPage,
      };

      dispatch(onGetCampaignReport(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setPage(newPage);
    },
    [page, pageSize],
  );

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: t('table:No.'),
        accessor: (originalRow, rowIndex) => rowIndex + 1,
        width: 50,
        id: 'id',
      },

      {
        Header: t('Impression'),
        accessor: ({ dailyImpression }) => {
          return dailyImpression;
        },
        id: 'impression',
        width: 100,
      },
      {
        Header: t('Clicks'),
        accessor: ({ click }) => click,
        id: 'click',
        width: 50,
      },
      {
        Header: t('table:CTR%'),
        accessor: ({ CTR }: any) => {
          return CTR ? floorToFixed(CTR, 4) : 0;
        },
        id: 'CTR',
        width: 50,
      },
      {
        Header: t('Cost'),
        accessor: ({ currentDailyBudget }) =>
          currentDailyBudget ? floorToFixed(currentDailyBudget, 4) : 0,
        id: 'currentDailyBudget',
        width: 100,
      },
      {
        Header: t('Note'),
        accessor: ({ note }) => note,
        id: 'Note',
        disableSort: true,
      },
      {
        Header: t('table:Timestamp'),
        accessor: ({ created_at }) => {
          return (
            <DateTimeComponent
              dateTime={created_at}
              dateTimeType={DateTimeTypes.TRANSACTION_DETAIL_DATE_TIME}
            />
          );
        },
        width: 230,
        id: 'created_at',
      },
    ],
    [t, i18n],
  );

  return (
    <>
      <Row className="justify-content-between m-0">
        <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
          />
        </Col>
        <Col xs={12} md={9} lg={9}>
          <Row className="align-items-center justify-content-end">
            <Col sm={12} md={3} lg={2}>
              <DatePickerOptions
                date={startDate}
                endDate={endDate}
                handleChange={handleStartDateChange}
                placeholder={t('Start Date')}
                rangeType={balanceLogRange}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <DatePickerOptions
                date={endDate}
                startDate={startDate}
                handleChange={handleEndDateChange}
                placeholder={t('End Date')}
                rangeType={balanceLogRange}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={2} className="align-self-baseline" />
      </Row>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={campaignReport.items || []}
        page={page}
        pageSize={pageSize}
        total={campaignReport.totalCount || 10}
        handlePageChange={handlePageChange}
        onSort={handleSort}
        isLoading={campaignDatumLoading}
      />
    </>
  );
};

export default withTranslation()(CampaignPaymentReportTable);
