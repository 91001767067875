import React from 'react';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { transformStringAmountInput } from '../../../utils/input';
import { logDate } from '../../../utils/dateTime';

const RoleBalanceDetailModal = ({
  data,
  t,
  isOpen,
  handleToggle,
  handleClose,
  title,
}: any) => {
  const renderRoleAmount = () => {
    const htmls: any = [];

    if (!isEmpty(data.role_amount)) {
      // eslint-disable-next-line no-shadow
      data.role_amount.map((data: any) => {
        htmls.push(
          <tr>
            <th className="text-nowrap" scope="row">
              {data.role}
            </th>
            <td>${transformStringAmountInput(data.amount)}</td>
          </tr>,
        );

        return true;
      });
    }

    return htmls;
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title} : &nbsp;
          {data && (
            <>
              <span className="mx-2">
                {logDate(data.day, data.month, data.year)}
              </span>
              <span>(${data.amount})</span>
            </>
          )}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <div className="table-responsive">
          <table className="table table-nowrap mb-0 table-bordered">
            <thead>
              <tr>
                <th>{t('table:Role')}</th>
                <th>{t('table:Amount')}</th>
              </tr>
            </thead>
            {data && <tbody>{renderRoleAmount()}</tbody>}
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(RoleBalanceDetailModal);
