import { DepositAddressesTypes } from './actionTypes';

export const getDepositAddress = (payload: any) => ({
  type: DepositAddressesTypes.GET_DEPOSIT_ADDRESSES,
  payload,
});

export const getDepositAddressesSuccess = (addresses: any) => ({
  type: DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_SUCCESS,
  payload: addresses,
});

export const getDepositAddressesFail = (error: any) => ({
  type: DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_FAIL,
  payload: error,
});

export const getDepositAddressByUUID = (payload: any) => ({
  type: DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID,
  payload,
});

export const getDepositAddressesByUUIDSuccess = (addresses: any) => ({
  type: DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID_SUCCESS,
  payload: addresses,
});

export const getDepositAddressesByUUIDFail = (error: any) => ({
  type: DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID_FAIL,
  payload: error,
});
