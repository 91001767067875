import SuggestJob from './actionTypes';

export const getAllSuggestJob = () => ({
  type: SuggestJob.GET_ALL_SUGGESS_JOB,
});
export const getAllSuggestJobSuccess = (payload: any) => ({
  type: SuggestJob.GET_ALL_SUGGESS_JOB_SUCCESS,
  payload,
});
export const getAllSuggestJobFail = (payload: any) => ({
  type: SuggestJob.GET_ALL_SUGGESS_JOB_FAIL,
  payload,
});
