import { call, put, takeEvery } from 'redux-saga/effects';
import { DepositTypes } from './actionTypes';

import { getDepositTransactions } from '../../../graphql/advertiser/depositTransaction';
import { getAdminDepositTransactions } from '../../../graphql/admin/transaction';
import {
  getDepositTransactionsRequestSuccess,
  getAllDepositTransactionsRequestSuccess,
} from './actions';
import {
  setDepositTransactionsLoading,
  unsetDepositTransactionsLoading,
} from '../../global/actions';

function* getDepositTransactionsRequest({ payload: DepositTransactionsQuery }: any) {
  try {
    yield put(setDepositTransactionsLoading());
    const response: Promise<any> = yield call(
      getDepositTransactions,
      DepositTransactionsQuery,
    );

    yield put(getDepositTransactionsRequestSuccess(response));
  } catch (error) {
    // some thing
  } finally {
    yield put(unsetDepositTransactionsLoading());
  }
}

function* getAllDepositTransactionsRequest({
  payload: DepositTransactionsQuery,
}: any) {
  const response: Promise<any> = yield call(
    getAdminDepositTransactions,
    DepositTransactionsQuery,
  );

  yield put(getAllDepositTransactionsRequestSuccess(response));
}

function* depositSaga() {
  // @ts-ignore
  yield takeEvery(
    DepositTypes.GET_DEPOSIT_TRANSACTIONS_REQUEST,
    getDepositTransactionsRequest,
  );

  yield takeEvery(
    DepositTypes.GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST,
    getAllDepositTransactionsRequest,
  );
}

export default depositSaga;
