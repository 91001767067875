import { useState } from 'react';
import { Field } from 'formik';
import { withTranslation } from 'react-i18next';

const PasswordField = ({ t, name, placeholder }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Field
      id={name}
      name={name}
      render={({ field, form: { errors, touched } }: any) => (
        <>
          <div className="password-field-container">
            <input
              {...field}
              placeholder={placeholder}
              className={`form-control ${
                errors[name] && touched[name] ? 'is-invalid' : ''
              }`}
              type={showPassword ? 'text' : 'password'}
            />
            <div
              className="eye-toggle-container d-flex align-items-center"
              onClick={toggleShowPassword}
            >
              {showPassword ? (
                <div className="d-flex align-items-center text-primary">
                  <i className="far fa-eye-slash me-1 d-inline-block" />{' '}
                  <span>{t('Hide')}</span>
                </div>
              ) : (
                <div className="d-flex align-items-center text-primary">
                  <i className="far fa-eye eye-toggle-password me-1 d-inline-block" />
                  <span>{t('Show')}</span>
                </div>
              )}
            </div>
          </div>
          {errors[name] && touched[name] && (
            <div className="is-invalid">
              <div className="invalid-message d-block">{t(`${errors[name]}`)}</div>
            </div>
          )}
        </>
      )}
    />
  );
};

export default withTranslation()(PasswordField);
