import styled from 'styled-components';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import { OnLoading } from 'src/components/Common/Loading';

const ChannelLogoComponent = (props: any) => {
  if (props.socialLoading) return <OnLoading />;

  return (
    <ChannelLogo
      src={props.socialMediaLogo}
      alt="Channel Logo"
      className="channel-logo skeleton"
      onLoad={(e) => {
        e.currentTarget.classList.remove('skeleton');
      }}
      onError={(e) => {
        e.currentTarget.classList.remove('skeleton');
        e.currentTarget.src = facebookLogo;
      }}
    />
  );
};

const ChannelLogo = styled.img`
  position: absolute;
  object-fit: cover;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: -53px;
  left: 53%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  animation-delay: 2s;
  animation: channelLogo 0.5s linear alternate;
  background-color: #fbfaff;

  @keyframes channelLogo {
    0% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 130px;
      height: 130px;
    }
    25% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1.2);
      width: 130px;
      height: 130px;
    }
    45% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1);
      width: 130px;
      height: 130px;
    }
    100% {
      top: -53px;
      left: 55%;
      width: 40px;
      height: 40px;
    }
  }
`;

export default ChannelLogoComponent;
