/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Table,
} from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import config from 'src/config';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  updateRequestNewPriceGql,
  updateSocialMediaInfo,
} from 'src/graphql/advertiser/socialMediaInfo';
import {
  setLoading,
  requestNewPriceFilterTable,
  unsetLoading,
  getAllAdminApproveLog,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import User from 'src/assets/images/users/avatar-2.jpg';
import { floorToFixed } from 'src/common/data/mathToFixed';
import Dropdown from '../CommonForBoth/Menus/Dropdown';
import SearchBar from '../Table/SearchBar';

const ApproveInfluencerRequestNewPriceComponent = ({ verifyStatus }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);
  const defaultRequestModalData = {
    isOpen: false,
    video_content_price: 0,
    photo_content_price: 0,
    share_content_price: 0,
    recommend_share_price: 0,
    recommend_photo_price: 0,
    recommend_video_price: 0,
    social_media_info: {
      share_content_price: 0,
      photo_content_price: 0,
      video_content_price: 0,
    },
  };

  const [requestNewPrice, setRequestNewPrice] = useState<any>({
    share_content_price: 0,
    photo_content_price: 0,
    video_content_price: 0,
  });
  // useEffect(() => {
  //   setRequestNewPrice({
  //     ...requestNewPrice,
  //     share_content_price: requestNewPrice?.share_content_price,
  //     photo_content_price: requestNewPrice?.photo_content_price,
  //     video_content_price: requestNewPrice?.video_content_price,
  //   });
  // }, [requestNewPrice]);

  const [requestModalData, setRequestModalData] = useState(defaultRequestModalData);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    verify_status: newVerifyStatus,
  });
  const handleClose = () => {
    setRequestModalData({ ...requestModalData, isOpen: false });
  };
  const data = useSelector(
    (State: any) => State.SocialMediaInfo.requestNewPriceFilterTable,
  );
  const a = Math.floor(1.166 * 100) / 100;
  const displayModal = () => {
    const {
      isOpen,
      video_content_price,
      photo_content_price,
      share_content_price,
      recommend_share_price,
      recommend_photo_price,
      recommend_video_price,
      social_media_info,
    } = requestModalData;
    return (
      <Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
        <DialogTitle>{t('table:Request change price')}</DialogTitle>
        <DialogContent className="content-price-table-container">
          <Table bordered responsive hover style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>{t('table:content_type')}</th>
                <th>{t('table:current price')}</th>
                <th>{t('table:suggestion price')}</th>
                <th>{t('table:new price request')}</th>
              </tr>
            </thead>
            <tbody>
              {requestModalData.share_content_price && (
                <tr>
                  <td>Share</td>
                  <td className="text-success">
                    {floorToFixed(social_media_info?.share_content_price, 2)} USDT
                  </td>
                  <td className="text-success">
                    {floorToFixed(recommend_share_price, 2)} USDT
                  </td>
                  <td className="text-success">
                    {floorToFixed(share_content_price, 2)} USDT
                  </td>
                </tr>
              )}
              {photo_content_price && (
                <tr>
                  <td>Photo</td>
                  <td className="text-success">
                    {floorToFixed(social_media_info?.photo_content_price, 2)} USDT
                  </td>
                  <td className="text-success">
                    {floorToFixed(recommend_photo_price, 2)} USDT
                  </td>
                  <td className="text-success">
                    {floorToFixed(photo_content_price, 2)} USDT
                  </td>
                </tr>
              )}
              {video_content_price && (
                <tr>
                  <td>Video</td>
                  <td className="text-success">
                    {floorToFixed(social_media_info?.video_content_price, 2)} USDT
                  </td>
                  <td className="text-success">
                    {floorToFixed(recommend_video_price, 2)} USDT
                  </td>
                  <td className="text-success">
                    {floorToFixed(video_content_price, 2)} USDT
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </DialogContent>
      </Dialog>
    );
  };

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: '#009a78',
      customClass: {},
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());

        await updateRequestNewPriceGql({
          verify_status: verify,
          social_media_info_key: key,
        });

        dispatch(unsetLoading());
        dispatch(requestNewPriceFilterTable(param));
      }
    });
  };

  //table
  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('name'),
        accessor: ({ social_media_info }: any) => (
          <>
            {social_media_info?.profile_path ? (
              <a
                href={social_media_info?.profile_path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social_media_info?.name}
              </a>
            ) : (
              social_media_info?.name
            )}
          </>
        ),

        id: 'social_id',
        disableSort: true,
        width: 150,
      },

      {
        Header: t('table:social'),
        accessor: ({ social_media_info }: any) =>
          social_media_info?.social_media_type,
        id: 'social_media_type',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Request change price'),
        accessor: ({
          video_content_price,
          photo_content_price,
          share_content_price,
          recommend_share_price,
          recommend_photo_price,
          recommend_video_price,
          social_media_info,
        }: any) => (
          <>
            <button
              style={{ background: 'white' }}
              className="view-detail"
              type="button"
              onClick={() => {
                setRequestModalData({
                  isOpen: true,
                  video_content_price,
                  photo_content_price,
                  share_content_price,
                  recommend_share_price,
                  recommend_photo_price,
                  recommend_video_price,
                  social_media_info,
                });
              }}
            >
              {t('table:View request')}
            </button>
          </>
        ),
        id: 'video_content_price',
        disableSort: true,
        width: 180,
      },
      {
        Header: t(`table:view Influencer profile`),
        accessor: ({ social_media_info }: any) => (
          <a
            className="cursor  fw-bold view-profile"
            href="campaign"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/InfluencerChannel?channel=${social_media_info?.key}`);
            }}
          >
            {t(`table:view profile`)}
          </a>
        ),
        id: 'influencer_profile',
        width: 180,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:status'),
        accessor: ({ verify_status }: any) => {
          let badgeColor: string = 'warning';
          switch (verify_status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verify_status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        disableSort: true,
      },
      {
        Header: t('img'),
        accessor: ({ social_media_info }: any) => (
          <img
            src={
              !social_media_info?.image_profile_url
                ? User
                : social_media_info?.image_profile_url
            }
            alt="image_profile_url"
            style={{ width: 40, height: 40, borderRadius: '50%' }}
          />
        ),

        id: 'profile',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Action'),
        accessor: ({ verify_status, social_media_info }: any) => (
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {verify_status !== VerifyStatus[0] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('PENDING', social_media_info.key)}
                  className="text-warning"
                >
                  <i className="mdi mdi-account-clock text-warning" />
                  &nbsp;&nbsp;{t('table:PENDING')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[1] && (
                <DropdownItem
                  onClick={async () => {
                    clickVerifyStatus('APPROVED', social_media_info.key);
                    // updateRequestNewPriceGql({
                    //         social_media_info_key: formValues?.key,
                    //         [typeKey]: null,
                    //       });
                    //       dispatch(getInfluencerRequestNewPrice(formValues?.key));
                    //     }}
                  }}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[2] && (
                <DropdownItem
                  onClick={() =>
                    clickVerifyStatus('REJECTED', social_media_info.key)
                  }
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:created_at'),
        accessor: ({ social_media_info }: any) => {
          if (!social_media_info?.created_at) return 'null';
          const created_at_date = new Date(social_media_info?.created_at);
          const Day = created_at_date.getDate();
          const month = created_at_date.getMonth() + 1;
          const year = created_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'created_at',
        width: 100,
        disableSort: true,
      },
    ];
  }, [t, param.page, param.limit, param]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(requestNewPriceFilterTable(payload));
    setParam(payload);
  };

  const handleStatusChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      verify_status: event.value,
    };
    dispatch(requestNewPriceFilterTable(payload));
    setNewVerifyStatus(event.value);
    setParam(payload);
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...param,
      limit: +newPageSize,
      page: 1,
    };
    dispatch(requestNewPriceFilterTable(payload));
    setParam(payload);
  };

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('new_influencer_request');
    dispatch(getAllAdminApproveLog());
  };

  useEffect(() => {
    fetchApproveLog();
    dispatch(requestNewPriceFilterTable(param));
  }, []);

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  const handleSearch = (search: string) => {
    const payload = {
      ...param,
      name: search,
      page: 1,
    };
    dispatch(requestNewPriceFilterTable(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      name: '',
      page: 1,
    };
    dispatch(requestNewPriceFilterTable(payload));
    setParam(payload);
  };

  return (
    <div>
      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            {displayModal()}
            <Card className="h-100">
              <CardBody className="h-100">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handlePageSizeChange}
                    colors="white"
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '150px', marginRight: '20px' }}>
                      <Dropdown
                        options={Status}
                        action={handleStatusChange}
                        default_value={Status[3]}
                      />
                    </div>
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={data?.items || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={data?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApproveInfluencerRequestNewPriceComponent;
