import { CurrencyTypes } from './actionTypes';

export const getCurrencies = () => ({
  type: CurrencyTypes.GET_CURRENCIES,
});

export const getCurrenciesSuccess = (currencies: any) => ({
  type: CurrencyTypes.GET_CURRENCIES_SUCCESS,
  payload: currencies,
});

export const getCurrenciesFail = (error: any) => ({
  type: CurrencyTypes.GET_CURRENCIES_FAIL,
  payload: error,
});

export const toggleCurrencyAvailable = (symbol: string) => ({
  type: CurrencyTypes.TOGGLE_CURRENCY_AVAILABLE,
  payload: { symbol },
});

export const getAvailableCurrencies = () => ({
  type: CurrencyTypes.GET_AVAILABLE_CURRENCIES,
});

export const getAvailableCurrenciesSuccess = (currencies: any) => ({
  type: CurrencyTypes.GET_AVAILABLE_CURRENCIES_SUCCESS,
  payload: currencies,
});

export const getFilterCurrency = (payload: any) => ({
  type: CurrencyTypes.GET_FILTER_CURRENCY,
  payload,
});

export const getFiltreCurrencySuccess = (payload: any) => ({
  type: CurrencyTypes.GET_FILTER_CURRENCY_SUCCESS,
  payload,
});

export const getAvailableNetwork = () => ({
  type: CurrencyTypes.GET_AVAILABLE_NETWORK,
});

export const getAvailableNetworkSuccess = (payload: any) => ({
  type: CurrencyTypes.GET_AVAILABLE_NETWORK_SUCCESS,
  payload,
});

export const getCurrenciesByMarketerCurrencies = () => ({
  type: CurrencyTypes.GET_CURRENCIES_BY_MARKETER,
});

export const getCurrenciesByMarketerCurrenciesSuccess = (payload: any) => ({
  type: CurrencyTypes.GET_CURRENCIES_BY_MARKETER_SUCCESS,
  payload,
});

export const getMarketerCurrencies = () => ({
  type: CurrencyTypes.GET_MARKETER_CURRENCIES,
});

export const getMarketerCurrenciesSuccess = (payload: any) => ({
  type: CurrencyTypes.GET_MARKETER_CURRENCIES_SUCCESS,
  payload,
});
