const chartOption = ({ data, max_revenue_scale, max_impression_scale, t }: any) => ({
  grid: {
    zlevel: 0,
    x: 50,
    x2: 70,
    y: 30,
    y2: 30,
    borderWidth: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    borderColor: 'rgba(0,0,0,0)',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999',
      },
    },
  },
  color: ['#3b4ccd', '#02a499', '#4ca4e3'],
  legend: {
    data: [
      t('chart.Click'),
      t('chart.Impression'),
      `${data[3][0] ? `${t('chart:Earning')}` : ''}`,
    ],
    textStyle: {
      color: ['#74788d'],
    },
  },
  xAxis: [
    {
      type: 'category',
      data: data[2] || [],
      axisPointer: {
        type: 'shadow',
      },
      axisLine: {
        lineStyle: {
          color: '#74788d',
        },
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
      name: t('table:Earnings'),
      min: 0,
      max: max_revenue_scale,
      interval: max_revenue_scale / 10,
      axisLabel: {
        formatter: '$ {value}',
      },
      axisLine: {
        lineStyle: {
          color: '#74788d',
        },
      },
    },
    {
      type: 'value',
      name: t('table:Amount'),
      min: 0,
      max: max_impression_scale,
      interval: max_impression_scale / 10,
      axisLabel: {
        formatter: `{value} ${t('table:Times')}`,
      },
      axisLine: {
        lineStyle: {
          color: '#74788d',
        },
      },
    },
  ],
  series: [
    {
      name: t('chart.Click'),
      type: 'bar',
      yAxisIndex: 1,
      data: data[0] || [],
    },
    {
      name: t('chart.Impression'),
      type: 'bar',
      yAxisIndex: 1,
      data: data[1] || [],
    },
    {
      name: t('chart.Earning'),
      type: 'line',
      yAxisIndex: 0,
      data: data[3] || [],
    },
  ],
  textStyle: {
    color: ['#74788d'],
  },
});

export default chartOption;
