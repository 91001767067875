import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import notify from '../../../utils/notify';
import { WithdrawTypes } from './actionTypes';
import {
  withdrawRequest as withdrawRequestGql,
  getWithdrawTransactions,
  getRecentWithdrawTransactions,
  withdrawAdminRequest as withdrawAdminRequestGql,
} from '../../../graphql/publisher/withdraw';
import {
  approveWithdraw as approveWithdrawGql,
  rejectWithdraw as rejectWithdrawGql,
} from '../../../graphql/admin/withdraw';
import { getAdminWithdrawTransactions } from '../../../graphql/admin/transaction';
import {
  withdrawRequestFail,
  withdrawRequestSuccess,
  getWithdrawRequestSuccess,
  getRecentWithdrawRequestSuccess,
  getAllWithdrawTransactionsRequestSuccess,
  withdrawAdminRequestFail,
} from './actions';
import { getWallet } from '../wallet/actions';
import { setLoading, unsetLoading } from '../../global/actions';
import { getRecentWithdrawRequest as onGetRecentWithdrawRequest } from '../../actions';
import i18n from '../../../i18n';
import { Response } from '../../../common/types';

// eslint-disable-next-line require-yield
function* withdrawRequest({ payload: { data } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(withdrawRequestGql, data);
    yield delay(800);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      yield put(withdrawRequestSuccess());
      yield put(onGetRecentWithdrawRequest(10));
      yield put(getWallet());

      notify(i18n.t('success:Withdraw Request Success'), 'success');
    }
  } catch (error) {
    yield put(withdrawRequestFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* withdrawAdminRequest({ payload: { data } }: any) {
  try {
    yield put(setLoading());
    const response: Response = yield call(withdrawAdminRequestGql, data);
    yield delay(800);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      yield put(getWithdrawRequestSuccess(response));
      //yield put(onGetRecentWithdrawRequest(10));
      //yield put(getWallet());

      notify(i18n.t('success:Withdraw Request Success'), 'success');
    }
  } catch (error) {
    yield put(withdrawAdminRequestFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

// eslint-disable-next-line require-yield
function* getWithdrawRequest({ payload: WithdrawTransactionsQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getWithdrawTransactions,
      WithdrawTransactionsQuery,
    );
    yield put(getWithdrawRequestSuccess(response));
  } catch (error) {
    //
  }
}

function* getRecentWithdrawRequest({ payload: { limit } }: any) {
  try {
    const response: Promise<any> = yield call(getRecentWithdrawTransactions, limit);

    yield put(getRecentWithdrawRequestSuccess(response));
  } catch (error) {
    //
  }
}

function* getAllWithdrawTransactionsRequest({
  payload: WithdrawTransactionsQuery,
}: any) {
  const response: Promise<any> = yield call(
    getAdminWithdrawTransactions,
    WithdrawTransactionsQuery,
  );

  yield put(getAllWithdrawTransactionsRequestSuccess(response));
}

function* approveWithdraw({ payload: { uuid } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(approveWithdrawGql, uuid);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      notify(i18n.t('success:Withdraw Approve Success'), 'success');
    }
  } catch (error) {
    // Something here
  } finally {
    yield put(unsetLoading());
  }
}

function* rejectWithdraw({ payload: { uuid } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(rejectWithdrawGql, uuid);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      yield put(getWallet());

      notify(i18n.t('success:Withdraw Reject Success'), 'success');
    }
  } catch (error) {
    // Something here
  } finally {
    yield put(unsetLoading());
  }
}

function* withdrawSaga() {
  yield takeEvery(WithdrawTypes.WITHDRAW_REQUEST, withdrawRequest);
  yield takeEvery(WithdrawTypes.GET_WITHDRAW_REQUEST, getWithdrawRequest);
  yield takeEvery(
    WithdrawTypes.GET_RECENT_WITHDRAW_REQUEST,
    getRecentWithdrawRequest,
  );
  yield takeEvery(
    WithdrawTypes.GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST,
    getAllWithdrawTransactionsRequest,
  );
  yield takeEvery(WithdrawTypes.APPROVE_WITHDRAW, approveWithdraw);
  yield takeEvery(WithdrawTypes.REJECT_WITHDRAW, rejectWithdraw);
  yield takeEvery(WithdrawTypes.WITHDRAW_ADMIN_REQUEST, withdrawAdminRequest);
}

export default withdrawSaga;
