import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

export const updateJobRequirementGql = async (input: any) => {
  const mutation = gql`
    mutation input($input: UpdateRequirementInput!) {
      updateJobRequirement(updateJobRequirementInput: $input)
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
    });
    return res.data.updateJobRequirement;
  } catch (error) {
    return null;
  }
};

export const createJobRequirementGql = async (input: any) => {
  const mutation = gql`
    mutation input($input: CreateJobRequirementInput!) {
      createJobRequirement(input: $input) {
        min_budget
        max_budget
        is_male
        is_female
        is_male_lgbt
        is_female_lgbt
        min_age
        max_age
        fixed_price
        job {
          key
          name
        }
        aptitudes {
          id
          name
        }
        countries {
          name
          key
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
    });
    return res.data.createJobRequirement;
  } catch (error) {
    return null;
  }
};

export const filterFromRequirementsGql = async (input: any) => {
  const query = gql`
    query input($input: filterFromRequirements!) {
      filterFromRequirements(input: $input) {
        items {
          name
          social_key
          image_key
          price
          job {
            job_key
            social_job_key
            job_name
            brand_name
            product_name
            company_name
            job_status
            submit_date
            last_day_accept_job
            image_profile_key
            social_media_type
            content_type
            price
            thumbnail_cover
            fixed_price
            symbol
            network
            price_rate
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });

    return res.data.filterFromRequirements;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get filterFromRequirements error .'), 'error');
    return null;
  }
};

export const getJobRequirementByJobKey = async (job_key: string) => {
  const query = gql`
    query job_key($job_key: String!) {
      getJobRequirementByJobKey(job_key: $job_key) {
        max_budget
        min_budget
        is_male
        is_female
        is_male_lgbt
        is_female_lgbt
        min_age
        max_age
        fixed_price
        currency_id
        aptitudes {
          id
          name
        }
        countries {
          name
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { job_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getJobRequirementByJobKey;
  } catch (error) {
    notify(i18n.t('error:Get getJobRequirementByJobKey error .'), 'error');
    return null;
  }
};

export const suggestAndOtherJobCountGql = async () => {
  const query = gql`
    query SuggestAndOtherJobCount {
      suggestAndOtherJobCount {
        suggestJobs
        otherJobs
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'cache-first',
    });
    return res.data.suggestAndOtherJobCount;
  } catch (error: any) {
    notify(i18n.t(`error: ${error.message}`, 'error'));
    return null;
  }
};
