import { useEffect, useState } from 'react';
import { Col, Row, FormGroup, Label, UncontrolledAlert } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { loginUser, resendEmail } from '../../../store/auth/login/actions';
import config from '../../../config';
import PasswordField from '../../Common/PasswordField';

const initialValues = {
  email: '',
  password: '',
};

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('invalid.emailIsRequired'),
  password: Yup.string().required('invalid.Password is required'),
});

const LoginByEmail = ({ role }: any) => {
  let recaptcha: any;
  const [closeAlert, setCloseAlert] = useState<any>(true);
  const siteKey: any = config.recaptcha.SITE_KEY;
  const dispatch = useDispatch();
  const { t }: any = useTranslation();
  const history = useHistory();

  const [isRecaptchaSucceed, setRecaptcha] = useState(false);

  useEffect(() => {
    const USE_CAPTCHA = config.recaptcha.USE_CAPTCHA === 'true';

    if (!USE_CAPTCHA) setRecaptcha(true);
  }, []);

  const { error, message, errorCode } = useSelector((state: any) => ({
    error: state.login.error,
    errorCode: state.login.errorCode,
    message: state.login.message,
  }));

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    setSubmitting(true);

    if (!isRecaptchaSucceed) return;
    const data = {
      ...values,
      role,
    };

    resetForm();
    recaptcha.reset();

    localStorage.setItem('email', values.email);

    dispatch(loginUser(data, history));
  };

  const handleResendEmail = () => {
    const email = localStorage.getItem('email') ?? '';

    dispatch(resendEmail(email, role));
  };
  const closePadding = () => {};
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={loginSchema}
      render={({ errors, touched, isSubmitting }) => {
        return (
          <FormikForm>
            <Row>
              <Col md="12">
                {closeAlert && error && message && typeof message === 'string' && (
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#eda6a6',
                      padding: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{message}</div>
                    <div>
                      {errorCode && errorCode === '1005' && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
                        <a href="javascript:void(0);" onClick={handleResendEmail}>
                          {t('Button.Resend Email')}
                        </a>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        setCloseAlert(false);
                      }}
                    >
                      x
                    </div>
                  </div>
                )}
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="email" className="input-label">
                    {t('Form.Email')}
                  </Label>
                  <Field
                    name="email"
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email ? 'is-invalid' : ''
                    }`}
                    id="email"
                    placeholder={t('Form.Email Placeholder')}
                  />
                  {errors.email && (
                    <div className="invalid-message">
                      {t('invalid.emailIsRequired')}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="password" className="input-label">
                    {t('Form.Password')}
                  </Label>
                  <PasswordField name="password" placeholder={t('Form.Password')} />

                  <div className="mt-2 d-flex justify-content-end">
                    <Link to="/forgot" className="text-secondary text-hover-danger">
                      &nbsp;{t('Form.Forgot Password')}
                    </Link>
                  </div>
                </FormGroup>
              </Col>
              <Col md="12" className="d-flex flex-column align-items-center">
                <FormGroup className="mb-3">
                  <ReCAPTCHA
                    ref={(r) => {
                      recaptcha = r;
                    }}
                    sitekey={siteKey}
                    onChange={() => {
                      setRecaptcha(true);
                    }}
                    onExpired={() => {
                      setTimeout(() => {
                        recaptcha.reset();
                      }, 200);
                    }}
                  />
                </FormGroup>

                {isSubmitting && !isRecaptchaSucceed && (
                  <UncontrolledAlert color="danger" className="alert">
                    {t('Form.I am not a robot')}
                  </UncontrolledAlert>
                )}
              </Col>
            </Row>
            <button
              onClick={() => {
                setCloseAlert(true);
              }}
              className="btn  w-100 waves-effect waves-light"
              type="submit"
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '50px',
              }}
              disabled={!!errors.email || !!errors.password}
            >
              {t('Button.Login')}
            </button>
          </FormikForm>
        );
      }}
    />
  );
};
export default withRouter(LoginByEmail);
