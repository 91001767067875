const CodeBlock = ({
  copySuccess,
  handleCopy,
  textCopy,
  content,
}: {
  copySuccess: number;
  handleCopy: Function;
  textCopy: string;
  content: any;
}) => (
  <div
    style={{
      width: '100%',
      minHeight: '10rem',
      background: '#E8EAED',
      borderRadius: '5px',
      padding: '0.7em',
      position: 'relative',
    }}
  >
    <div style={{ position: 'absolute', top: 5, right: 5 }}>
      <button type="button" className="input-group-text">
        {copySuccess !== 1 ? (
          <i
            className="far fa-copy"
            onClick={() => {
              handleCopy(textCopy, 1);
            }}
          />
        ) : (
          <>
            <i className="fas fa-check-circle" />{' '}
          </>
        )}
      </button>
    </div>
    {content}
  </div>
);
export default CodeBlock;
