import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'react-table';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { softDeleteApplicationCreative } from 'src/graphql/publisher/application';
import { getApplicationCreativeFilter } from 'src/store/actions';
import CustomTable from '../Common/CustomTable';
import ScriptAppModal from './Application/ScriptAppModal/ScriptAppModal';

const ScriptAppIndex = ({ t, scriptError, setAppCreativeFilter }: any) => {
  const { id }: any = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedData, setSelectData] = useState();
  const dispatch = useDispatch();
  const limit = 5;
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const { applicationCreatives, applicationCreativeFilterLoading } = useSelector(
    (state: any) => ({
      applicationCreatives: state.Application.applicationCreative,
      applicationCreativeFilterLoading:
        state.Global.applicationCreativeFilterLoading,
    }),
  );

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Width'),
        accessor: ({ width }) => width || '-',
        id: 'width',
      },
      {
        Header: t('table:Height'),
        accessor: ({ height }) => height || '-',
        id: 'height',
      },
      {
        Header: t('table:Banner Ad format'),
        accessor: ({ banner_format }) => banner_format,
        id: 'creative_format',
        disableSort: true,
      },
      {
        Header: t('table:Ad Key'),
        accessor: (data) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectData(data);
                setModalOpen(true);
              }}
            >
              <i className="dripicons-code font-size-22" />
            </div>
          );
        },
        id: 'Ad Key',
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ key }) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => handleSubmit(key)}>
              <i className="mdi mdi-delete font-size-22" />
            </div>
          );
        },
        id: 'Action',
        disableSort: true,
      },
    ],
    [t, page, applicationCreatives],
  );

  const handleSubmit = async (key: string) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t(`Confirm modal.delete creative`),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await softDeleteApplicationCreative(key);

        if (applicationCreatives.items?.length === 1 && page !== 1) {
          const appCreativeFilterOnDelete = getApplicationCreativeFilter({
            ...getDefaultPayload(),
            page: page - 1,
          });
          dispatch(appCreativeFilterOnDelete);
          setAppCreativeFilter(appCreativeFilterOnDelete);
          setPage(page - 1);
        } else {
          dispatch(getApplicationCreativeFilter(getDefaultPayload()));
        }
      }
    });
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getDefaultPayload = () => {
    const appCreativeFilter = {
      limit,
      page,
      application_key: id,
      sortBy,
      sortType,
    };

    setAppCreativeFilter(appCreativeFilter);
    return appCreativeFilter;
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };
      dispatch(getApplicationCreativeFilter(payload));
      setAppCreativeFilter(payload);
      setPage(newPage);
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page],
  );

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };
    dispatch(getApplicationCreativeFilter(payload));
    setAppCreativeFilter(payload);
    setPage(newPage);
  };
  useEffect(() => {
    dispatch(getApplicationCreativeFilter(getDefaultPayload()));
  }, []);

  return (
    <>
      {scriptError ? (
        <div className="bg-tomato  c-w font-size-22 w-fit-content px-4 py-2 mb-4">
          <i className="mdi mdi-alert font-size-22 me-3 ms-2 c-w" />

          {t(
            'campaign:Your website has a script that does not match the requested script.',
          )}
        </div>
      ) : (
        ''
      )}
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={applicationCreatives.items || []}
        page={page}
        handlePageChange={handlePageChange}
        pageSize={limit}
        total={applicationCreatives.totalCount}
        marginTopClassName="mt-0"
        onSort={handleSort}
        isLoading={applicationCreativeFilterLoading}
      >
        <ScriptAppModal
          data={selectedData}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Mobile ad key')}
        />
      </CustomTable>
    </>
  );
};

export default withTranslation()(ScriptAppIndex);
