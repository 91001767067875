import moment from 'moment-timezone';
import config from 'src/config';
import i18n from '../i18n';

const monthsTH = [
  'ม.ค.',
  'ก.พ',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
];

const monthsEN = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function recentTransactionDateTime(dateTime, timezone) {
  return normalDateTime(dateTime, timezone);
}
export function recentTransactionDate(dateTime, timezone) {
  const d = timezone ? moment(dateTime).tz(timezone) : moment(dateTime).utc(false);

  return d.format('YYYY-MM-DD');
}
export function getDateTime(dateTime, timezone) {
  const d = timezone ? moment(dateTime).tz(timezone) : moment(dateTime).utc(false);

  return d.format('DD MMM YYYY');
}

export function getMonthName(dateTime) {
  const instance = moment(dateTime);

  return monthsTH[instance.format('M') - 1];
}

export function transactionDetailDateTime(dateTime, timezone) {
  const lang = i18n.language;

  const d = timezone ? moment(dateTime).tz(timezone) : moment(dateTime).utc(false);

  return lang === 'en'
    ? `${d.fromNow()} (${normalDateTime(dateTime, timezone)})`
    : `${getPastTime(d)} (${normalDateTime(dateTime, timezone)})`;
}

export function getNotificationPastTime(dateTime, timezone = null) {
  const lang = i18n.language;

  let date = new Date(dateTime);

  if (config.appEnv === 'local') {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);
  }

  let d = timezone ? moment(date).tz(timezone) : moment(date).utc(false);
  d = moment(date);

  return lang === 'en' ? d.fromNow() : getPastTime(d);
}

export function normalDateTime(dateTime, timezone) {
  let date = new Date(dateTime);

  if (config.appEnv === 'local') {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);
  }

  const d = timezone ? moment(date).tz(timezone) : moment(date).utc(false);

  return d.format('DD-MM-YYYY hh:mm a');
}
export function getDateTimeFullFormat(dateTime, timezone) {
  let date = new Date(dateTime);
  if (config.appEnv === 'local') {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);
  }

  const d = timezone ? moment(date).tz(timezone) : moment(date).utc(false);

  return d.format('DD-MM-YYYY hh:mm a');
}

export function getPastTime(dateTime) {
  const instance = moment(dateTime);
  const pastInSecs = Math.floor(Math.abs(instance.diff()) / 1000);

  const minutes = Math.floor(pastInSecs / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const year = Math.floor(months / 12);

  if (year) {
    return `${year} ปีที่แล้ว`;
  }
  if (months) {
    return `${months} เดือนที่แล้ว`;
  }
  if (days) {
    return `${days} วันที่แล้ว`;
  }
  if (hours) {
    return `${hours} ชั่วโมงที่แล้ว`;
  }
  if (minutes) {
    return `${minutes} นาทีที่แล้ว`;
  }
  return `${pastInSecs} วินาที่แล้ว`;
}

export function logDate(day, month, year) {
  const lang = i18n.language;

  if (lang === 'en') {
    if (day) {
      return `${day}/${month}/${year}`;
    } else {
      return `${monthsEN[month - 1]} ${year}`;
    }
  } else {
    if (day) {
      return `${day}/${month}/${year}`;
    } else {
      return `${monthsTH[month - 1]} ${year}`;
    }
  }
}

export function logGraphDate(day, month, year, lang) {
  const monthName = lang === 'en' ? monthsEN[month - 1] : monthsTH[month - 1];

  if (day) {
    return `${day}/${monthName}`;
  }

  return year ? `${monthName}/${year}` : monthName;
}

// eslint-disable-next-line no-unused-vars
export function getDateFromDatePicker(date, isStart, rangeType) {
  const instance = moment(date).utc(0);

  return instance.toISOString();
}

export const getStartDateByRange = (range) => {
  if (range) {
    const date = new Date();
    date.setDate(date.getDate() - range);
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }
  return null;
};

export const formatDateForm = (created_at) => {
  if (!created_at) return 'null';
  const created_at_date = new Date(created_at);
  const Day = created_at_date.getDate();
  const month = created_at_date.getMonth() + 1;
  const year = created_at_date.getFullYear();
  return `${Day}/${month}/${year}`;
};

export const calculateDayLeft = (finish_date) => {
  if (finish_date) {
    const finishDate = new Date(finish_date);
    const diffTime = finishDate.getTime() - Date.now();
    const diffDate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDate;
  }
  return null;
};

export const EndDays = (finish_date) => {
  const diffDate = calculateDayLeft(finish_date);
  if (diffDate === null) {
    return '';
  } else {
    if (diffDate > 0) {
      return `End in ${diffDate} days`;
    } else {
      return 'Time out';
    }
  }
};
