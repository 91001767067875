import { MovementTypes } from './actionTypes';
import { MovementTransactionsQuery } from '../../common/types';

export const getMovement = (payload: MovementTransactionsQuery) => {
  return {
    type: MovementTypes.GET_MOVEMENT,
    payload: { ...payload },
  };
};

export const getMovementSuccess = (transactions: any) => {
  return {
    type: MovementTypes.GET_MOVEMENT_SUCCESS,
    payload: transactions,
  };
};

export const getMovementFail = (error: any) => {
  return {
    type: MovementTypes.GET_MOVEMENT_FAIL,
    payload: error,
  };
};
