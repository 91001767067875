/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export enum DepositTypes {
  /** Normal User */
  GET_DEPOSIT_TRANSACTIONS_REQUEST = '@@deposit/GET_DEPOSIT_TRANSACTIONS_REQUEST',
  GET_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS = '@@deposit/GET_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS',
  GET_DEPOSIT_TRANSACTIONS_REQUEST_FAIL = '@@deposit/GET_DEPOSIT_TRANSACTIONS_REQUEST_FAIL',

  /** Admin User */
  GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST = '@@deposit/GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST',
  GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS = '@@deposit/GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS',
  GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_FAIL = '@@deposit/GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_FAIL',
}
