import RegisterTypes from './actionTypes';

const initialState = {
  error: false,
  message: null,
  loading: false,
  user: null,
  register_third_data: {},
};

const register = (state = initialState, action: any) => {
  switch (action.type) {
    case RegisterTypes.REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RegisterTypes.REGISTER_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: false,
        message: null,
      };
    case RegisterTypes.REGISTER_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        error: true,
        message: action.payload,
      };
    case RegisterTypes.VERIFY_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case RegisterTypes.VERIFY_EMAIL_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        error: false,
        message: null,
      };
    case RegisterTypes.VERIFY_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload,
      };
    case RegisterTypes.REGISTER_THIRD_PARTY:
      return {
        ...state,
        register_third_data: action.payload,
      };
    case RegisterTypes.CLEAR_REGISTER_THIRD_PARTY:
      return {
        ...state,
        register_third_data: {},
      };
    default:
      return { ...state };
  }
};

export default register;
