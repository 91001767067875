import { BalanceTypes } from './actionTypes';

const initialState = {
  sumWallets: null,
  sumCryptosBalance: null,
  sumProfitsBalance: null,
  cryptoWallets: [],
  sumWalletsRole: {},
  logTransactions: [],
  error: '',
  loading: false,
};

const SystemBalance = (state = initialState, action: any) => {
  switch (action.type) {
    case BalanceTypes.GET_SUM_WALLETS_SUCCESS:
      return {
        ...state,
        sumWallets: action.payload,
      };
    case BalanceTypes.GET_SUM_CRYPTOS_BALANCE_SUCCESS:
      return {
        ...state,
        sumCryptosBalance: action.payload,
      };
    case BalanceTypes.GET_SUM_PROFITS_BALANCE_SUCCESS:
      return {
        ...state,
        sumProfitsBalance: action.payload,
      };

    case BalanceTypes.GET_CRYPTO_WALLETS_SUCCESS:
      return {
        ...state,
        cryptoWallets: action.payload,
      };
    case BalanceTypes.GET_SUM_WALLETS_ROLE_SUCCESS:
      return {
        ...state,
        sumWalletsRole: action.payload,
      };
    case BalanceTypes.GET_SYSTEM_BALANCE_LOG_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        logTransactions: action.payload,
      };

    case BalanceTypes.GET_PROFIT_BALANCE_LOG_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        logTransactions: action.payload,
      };
    case BalanceTypes.GET_CRYPTO_BALANCE_LOG_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        logTransactions: action.payload,
      };
    case BalanceTypes.GET_ROLE_BALANCE_LOG_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        logTransactions: action.payload,
      };
    case BalanceTypes.FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default SystemBalance;
