import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'src/pages/Publisher/Influencer/jobIndex.style.scss';

// const jobsStatus = [
//   {
//     title: i18n.t('All'),
//     value: 'ALL',
//   },
//   {
//     title: i18n.t('button:Draft'),
//     value: 'DRAFT',
//   },
//   {
//     title: i18n.t('button:New Job'),
//     value: 'NEW_JOB',
//   },
//   {
//     title: i18n.t('button:In progress'),
//     value: 'INPROGRESS',
//   },
//   {
//     title: i18n.t('button:Finished'),
//     value: 'FINISHED',
//   },
//   {
//     title: i18n.t('button:Cancel'),
//     value: 'CANCEL',
//   },
// ];

const jobsStatus = [
  {
    title: 'All',
    value: null,
  },
  {
    title: 'Invite Only',
    value: 'INVITE_ONLY',
  },
  {
    title: 'Suggest Jobs',
    value: 'PUBLISH',
  },
];
const JobStatusButtonGroup = ({
  param,
  handleStatusChange,
  disableValue,
  statusCount,
}: any) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [title, setTitle] = useState(t('button:Filter'));
  const toggle = () => setDropdownOpen((prevState: any) => !prevState);
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret
            style={{
              width: '160px',
              height: '34px',
              background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
              borderRadius: '8px',
              border: 'none',
              color: 'white',
            }}
            className="job-button-filter"
          >
            <i className="fa fa-filter" style={{ marginRight: '10px' }} />
            {t(`button:${title}`)}
          </DropdownToggle>
          <DropdownMenu>
            {jobsStatus.map((item: any) => {
              let count = 0;
              if (statusCount) {
                for (let i = 0; i < statusCount?.length; i++) {
                  const statusCountData = statusCount[i];

                  if (item.value === statusCountData.status) {
                    count = statusCountData.count;
                    break;
                  }
                }
              }
              return !(disableValue?.length && disableValue.includes(item.value)) ? (
                <DropdownItem
                  onClick={() => {
                    handleStatusChange(item.value);
                    setTitle(item.title);
                  }}
                  className={`btn outline-sm m-1 ${
                    param.status === item.value && 'active'
                  }`}
                  key={item.value}
                >
                  {item.title}
                </DropdownItem>
              ) : (
                <div key={null} />
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};
export default JobStatusButtonGroup;
