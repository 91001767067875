/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 *  interface for Login Type
 */
const enum RegisterTypes {
  REGISTER_USER = '@@register/REGISTER_USER',
  REGISTER_USER_SUCCESSFUL = '@@register/REGISTER_USER_SUCCESSFUL',
  REGISTER_USER_FAILED = '@@register/REGISTER_USER_FAILED',
  VERIFY_EMAIL = '@@register/VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESSFUL = '@@register/VERIFY_EMAIL_SUCCESSFUL',
  VERIFY_EMAIL_FAILED = '@@register/VERIFY_EMAIL_FAILED',

  REGISTER_THIRD_PARTY = '@@register/REGISTER_THIRD_PARTY',
  CLEAR_REGISTER_THIRD_PARTY = '@@register/CLEAR_REGISTER_THIRD_PARTY',
}

export default RegisterTypes;
