import { gql } from '@apollo/client';
import { client } from '../client';

const getAuthorizeOAuthTokenTwitterUrl = async (callback: string | null) => {
  const query = gql`
    query getAuthorizeOAuthTokenTwitterUrl($callback: String!) {
      getAuthorizeOAuthTokenTwitterUrl(callback: $callback)
    }
  `;

  const result: any = await client.query({
    query,
    variables: { callback },
    fetchPolicy: 'no-cache',
  });

  return result.data.getAuthorizeOAuthTokenTwitterUrl;
};

const getRequestAccessTokenTwitter = async (
  oauth_token: string,
  oauth_verifier: string,
) => {
  const query = gql`
    query requestAccessTokenTwitter(
      $oauth_token: String!
      $oauth_verifier: String!
    ) {
      requestAccessTokenTwitter(
        oauth_token: $oauth_token
        oauth_verifier: $oauth_verifier
      )
    }
  `;

  const result: any = await client.query({
    query,
    variables: { oauth_token, oauth_verifier },
    fetchPolicy: 'no-cache',
  });

  return result.data.requestAccessTokenTwitter;
};

const getUserDataTwitter = async (
  token: string,
  token_secret: string,
  user_id: string,
) => {
  const query = gql`
    query getUserDataTwitter(
      $token: String!
      $token_secret: String!
      $user_id: String!
    ) {
      getUserDataTwitter(
        token: $token
        token_secret: $token_secret
        user_id: $user_id
      )
    }
  `;

  const result: any = await client.query({
    query,
    variables: { token, token_secret, user_id },
    fetchPolicy: 'no-cache',
  });

  return result.data.getUserDataTwitter;
};

const getUserDataTwitterV2 = async (
  token: string,
  token_secret: string,
  user_id: string,
) => {
  const query = gql`
    query getUserDataTwitterV2(
      $token: String!
      $token_secret: String!
      $user_id: String!
    ) {
      getUserDataTwitterV2(
        token: $token
        token_secret: $token_secret
        user_id: $user_id
      )
    }
  `;

  const result: any = await client.query({
    query,
    variables: { token, token_secret, user_id },
    fetchPolicy: 'no-cache',
  });

  return result.data.getUserDataTwitterV2;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getAuthorizeOAuthTokenTwitterUrl,
  getUserDataTwitter,
  getRequestAccessTokenTwitter,
  getUserDataTwitterV2,
};
