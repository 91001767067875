import BannerFormat from 'src/constants/banner-format-Emun';

// eslint-disable-next-line import/prefer-default-export
export const iosTextCopy = (adKey: string, appKey: string, adType: BannerFormat) =>
  adType === BannerFormat.IN_PAGE
    ? `struct ContentView: View {
  var body: some View {
    AFlib.InPage(appKey: "${appKey}", adKey: "${adKey}")
  }
}`
    : `struct RootApp: App {
  var body: some Scene {
    WindowGroup {
        ZStack {
          ContentView()
          VStack {
            ${
              adType === BannerFormat.HEADER
                ? `AFlib.Header(appKey: "${appKey}", adKey: "${adKey}").padding()`
                : `// AFlib.Header(appKey: "example", adKey: "example").padding()`
            }
            Spacer()
            HStack {
              Spacer()
              ${
                adType === BannerFormat.SLIDE
                  ? `AFlib.Slide(appKey: "${appKey}", adKey: "${adKey}")`
                  : `// AFlib.Slide(appKey: "example", adKey: "example")`
              }
              ${
                adType === BannerFormat.STICKY
                  ? `AFlib.Sticky(appKey: "${appKey}", adKey: "${adKey}").padding()`
                  : `// AFlib.Sticky(appKey: "example", adKey: "example").padding()`
              }
              Spacer().frame(height:0)
            }
          }
        }
    }
  }
}`;

// eslint-disable-next-line import/prefer-default-export
export const androidTextCopy = (
  adKey: string,
  appKey: string,
  adType: BannerFormat,
): string =>
  adType === BannerFormat.IN_PAGE
    ? `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1958294196095627"
      crossorigin="anonymous"><∖script>

<ins class="adsbygoogle"
    style="display:block"
    data-ad-format="fluid"
    data-ad-key="${adKey}"
    data-ad-client="ca-pub-195839182048476393028"
><\\ins>

<script>(adsbyafbrother = window.adsbyafbrother || []).push()<\\script>
`
    : ``;

// eslint-disable-next-line import/prefer-default-export
export const reactNativeTextCopy = (
  adKey: string,
  appKey: string,
  adType: BannerFormat,
): string =>
  adType === BannerFormat.IN_PAGE
    ? `const App: () => Node = () => {
  return (
    <SafeAreaView style={{ backgroundColor: Colors.darker }}>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        style={{ backgroundColor: Colors.darker }}
      >
        <InPageAd appKey="${appKey}" adKey="${adKey}" />
      </ScrollView>
    </SafeAreaView>
  );
};`
    : `const App: () => Node = () => {
  return (
    <SafeAreaView style={backgroundStyle}>
      ${
        adType === BannerFormat.HEADER
          ? `<HeaderAd appKey="${appKey}" adKey="${adKey}" />`
          : `{/* <HeaderAd appKey="${appKey}" adKey="${adKey}" /> */}`
      }
      ${
        adType === BannerFormat.STICKY
          ? `<StickyAd appKey="${appKey}" adKey="${adKey}" />`
          : `{/* <StickyAd appKey="${appKey}" adKey="${adKey}" /> */}`
      }
      ${
        adType === BannerFormat.SLIDE
          ? `<SlideAd appKey="${appKey}" adKey="${adKey}" />`
          : `{/* <SlideAd appKey="${appKey}" adKey="${adKey}" /> */}`
      }
    </SafeAreaView>
  );
};`;
// eslint-disable-next-line import/prefer-default-export
export const flutterTextCopy = (
  adKey: string,
  appKey: string,
  adType: BannerFormat,
): string => {
  return adType === BannerFormat.IN_PAGE
    ? `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1958294196095627"
      crossorigin="anonymous"><∖script>

<ins class="adsbygoogle"
    style="display:block"
    data-ad-format="fluid"
    data-ad-key="${adKey}"
    data-ad-client="ca-pub-195839182048476393028"
><\\ins>

<script>(adsbyafbrother = window.adsbyafbrother || []).push()<\\script>
`
    : ``;
};

// eslint-disable-next-line import/prefer-default-export
export const iosKitTextCopy = (
  adKey: string,
  appKey: string,
  adType: BannerFormat,
): string => `import AFLib

class someController: UIViewController {
  override func viewDidLoad() {
    ${
      adType === BannerFormat.HEADER
        ? `view.addSubview(AFlib.header(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: 0))`
        : `// view.addSubview(AFlib.header(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: 0))`
    }
    ${
      adType === BannerFormat.SLIDE
        ? `view.addSubview(AFlib.slide(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: -60))`
        : `// view.addSubview(AFlib.slide(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: -60))`
    }
    ${
      adType === BannerFormat.STICKY
        ? `view.addSubview(AFlib.sticky(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: -50))`
        : `// view.addSubview(AFlib.sticky(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: -50))`
    }
    ${
      adType === BannerFormat.IN_PAGE
        ? `view.addSubview(AFlib.inPage(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: 0))`
        : `// view.addSubview(AFlib.inPage(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: 0))`
    }
  }
}`;
