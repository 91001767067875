interface ResponseFacebook {
  id: string;
  name: string;
  picture_url?: string;
  access_token: string;
  fan_count: number;
  link?: string;
  email?: string;
  permissions?: any;
}

const getFacebookUserData = async (access_token: string) => {
  const url: string = `https://graph.facebook.com/v16.0/me?fields=id,name,permissions,email,link,picture.type(large){url}&access_token=${access_token}`;

  const response = await fetch(url, {
    method: 'GET',
  });

  const responseText: string = await response.text();

  const { id, name, picture, email, link, permissions }: any =
    JSON.parse(responseText);

  const pictureProfileUrl: string = picture?.data?.url;

  let allEnabledPermission = true;
  for (let i = 0; i < permissions?.data?.length; i++) {
    const { permission, status } = permissions.data[i];
    if (permission === 'user_posts' || permission === 'user_link') {
      if (status === 'declined') {
        allEnabledPermission = false;
        break;
      }
    }
  }

  if (!allEnabledPermission) {
    try {
      await fetch(
        `https://graph.facebook.com/v16.0/${id}/permissions?access_token=${access_token}`,
        {
          method: 'DELETE',
          redirect: 'follow',
        },
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  if (!id || !name) return null;
  const responseFacebook: ResponseFacebook = {
    id,
    name,
    picture_url: pictureProfileUrl,
    access_token,
    fan_count: 0,
    link: link || null,
    email,
    permissions,
  };
  return responseFacebook;
};

export default getFacebookUserData;
