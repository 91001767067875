/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OnLoading } from 'src/components/Common/Loading';
import Role from 'src/constants/enum/role';
import socialMediaType from 'src/constants/enum/socialMediaType';
import {
  getFacebookData,
  getInstagramData,
  getTiktokPostData,
  getTwitterPostData,
  getYoutubeVideoData,
} from 'src/store/actions';
import {
  getFacebookDataGql,
  getInstagramDataGql,
  getTiktokPostDataGql,
  getTwitterPostDataGql,
  getYoutubeVideoDataGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import FacebookPostView from './postView/facebookPostView';
import InstagramPostView from './postView/instagramPostView';
import TiktokPostView from './postView/tiktokPostView';
import TwitterPostView from './postView/twitterPostView';
import YoutubePostView from './postView/youtubePostView';

const JobDetailPost = ({ socialJobItem }: any) => {
  const dispatch = useDispatch();
  const [_socialPost, setSocialPost] = useState<any>({});
  const { socialPost, socialJobData, onSocialMediaInfoLoading, user } = useSelector(
    (state: any) => ({
      socialPost: state.SocialMediaInfo.getSocialPost,
      socialJobData: state.SocialMediaInfo.socialJobCardData,
      onSocialMediaInfoLoading: state.SocialMediaInfo.onSocialMediaInfoLoading,
      user: state.login.user,
    }),
  );
  const { t } = useTranslation();
  useEffect(() => {}, []);
  if (!socialPost) {
    return <OnLoading />;
  }

  const queryData = async (path: string, key: string, social_media_type: any) => {
    switch (social_media_type) {
      case socialMediaType.FACEBOOK:
        const facebookRes = await getFacebookDataGql(path, key);
        setSocialPost(facebookRes);
        break;
      case socialMediaType.INSTAGRAM:
        const igRes = await getFacebookDataGql(path, key);
        setSocialPost(igRes);
        break;
      case socialMediaType.TWITTER:
        const twitterRes = await getTwitterPostDataGql(path, key);
        setSocialPost(twitterRes);
        break;
      case socialMediaType.YOUTUBE:
        const youtubeRes = await getYoutubeVideoDataGql(path, key);
        setSocialPost(youtubeRes);
        break;
      case socialMediaType.TIKTOK:
        const tiktokRes = await getTiktokPostDataGql(path, key);
        setSocialPost(tiktokRes);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (socialJobItem?.link_post) {
      queryData(
        socialJobItem?.link_post,
        socialJobItem?.social_media_info?.key,
        socialJobItem?.social_media_info?.social_media_type,
      );
    }
  }, [socialJobData, onSocialMediaInfoLoading]);

  useEffect(() => {
    setSocialPost(socialPost);
  }, [socialPost]);

  const displayPostView = (type: socialMediaType) => {
    switch (type) {
      case socialMediaType.FACEBOOK:
        return <FacebookPostView socialPost={_socialPost} />;
      case socialMediaType.INSTAGRAM:
        return <InstagramPostView socialPost={_socialPost} />;
      case socialMediaType.TWITTER:
        return <TwitterPostView socialPost={_socialPost} />;
      case socialMediaType.TIKTOK:
        return <TiktokPostView socialPost={_socialPost} />;
      case socialMediaType.YOUTUBE:
        return <YoutubePostView socialPost={_socialPost} />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className="influencer-job-detail-container pointer"
      onClick={() => {
        window.open(_socialPost.source);
      }}
    >
      <div className="influencer-card-post">
        {user.role === Role.ADVERTISER ? (
          <div className="campaign-name-title">{t('Influencer Post t')}</div>
        ) : (
          <div className="campaign-name-title">{t('Your Post t')}</div>
        )}
        <hr />
        {displayPostView(socialJobItem?.social_media_info?.social_media_type)}
      </div>
      {/* <div>{_socialPost.key}</div> */}
    </div>
  );
};

export default JobDetailPost;
