import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  getAllAdminApproveLog,
  requestDeleteSocialMediaFilterTable,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
import config from 'src/config';
import MetaTags from 'react-meta-tags';
import { updateRequestDeleteSocialMedia } from 'src/graphql/advertiser/socialMediaInfo';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';

const DeleteSocialMediaComponent = ({ verifyStatus }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    verify_status: newVerifyStatus,
  });

  const data = useSelector(
    (State: any) => State.SocialMediaInfo.requestSocialMediaFilterTable,
  );

  useEffect(() => {
    fetchApproveLog();
    dispatch(requestDeleteSocialMediaFilterTable(param));
  }, []);

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await updateRequestDeleteSocialMedia({
          verify_status: verify,
          social_media_info_key: key,
        });
        dispatch(unsetLoading());
        dispatch(requestDeleteSocialMediaFilterTable(param));
      }
    });
  };

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('delete_social');
    dispatch(getAllAdminApproveLog());
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(requestDeleteSocialMediaFilterTable(payload));
    setParam(payload);
  };

  const handleStatusChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      verify_status: event.value,
    };
    dispatch(requestDeleteSocialMediaFilterTable(payload));
    setNewVerifyStatus(event.value);
  };

  const handleSearch = (search: string) => {
    const payload = {
      ...param,
      name: search,
      page: 1,
    };
    dispatch(requestDeleteSocialMediaFilterTable(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      name: '',
      page: 1,
    };
    dispatch(requestDeleteSocialMediaFilterTable(payload));
    setParam(payload);
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...param,
      limit: +newPageSize,
      page: 1,
    };
    dispatch(requestDeleteSocialMediaFilterTable(payload));
    setParam(payload);
  };

  //table
  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('name'),
        accessor: ({ social_media_info }: any) => social_media_info.name,
        id: 'name',
        disableSort: true,
        width: 150,
      },

      {
        Header: t('table:social'),
        accessor: ({ social_media_info }: any) =>
          social_media_info.social_media_type,
        id: 'social_media_type',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('marketer:create_campaign:status'),
        accessor: ({ verify_status }: any) => {
          let badgeColor: string = 'warning';
          switch (verify_status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verify_status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        disableSort: true,
      },
      {
        Header: t('img'),
        accessor: ({ social_media_info }: any) => (
          <img
            src={social_media_info.image_profile_url}
            alt="image_profile_url"
            style={{ width: 40, height: 40, borderRadius: '50%' }}
          />
        ),

        id: 'profile',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Action'),
        accessor: ({ social_media_info, verify_status }: any) => (
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {verify_status !== VerifyStatus[0] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('PENDING', social_media_info.key)}
                  className="text-warning"
                >
                  <i className="mdi mdi-account-clock text-warning" />
                  &nbsp;&nbsp;{t('table:PENDING')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[1] && (
                <DropdownItem
                  onClick={() =>
                    clickVerifyStatus('APPROVED', social_media_info.key)
                  }
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[2] && (
                <DropdownItem
                  onClick={() =>
                    clickVerifyStatus('REJECTED', social_media_info.key)
                  }
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:created_at'),
        accessor: ({ created_at }: any) => {
          if (!created_at) return 'null';
          const created_at_date = new Date(created_at);
          const Day = created_at_date.getDate();
          const month = created_at_date.getMonth() + 1;
          const year = created_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'created_at',
        disableSort: true,
        width: 100,
      },
    ];
  }, [t, param.page, param.limit, param]);

  return (
    <div>
      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card className="h-100">
              <CardBody className="h-100">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handlePageSizeChange}
                    colors="white"
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={data?.items || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={data?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeleteSocialMediaComponent;
