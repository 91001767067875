import {
  AllowNotificationMailTypes,
  AllowNotificationMailState,
} from './actionTypes';

export const INIT_STATE: AllowNotificationMailState = {
  allowNotificationMails: [],
  error: {},
};

const AllowNotificationMails = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AllowNotificationMailTypes.GET_ALLOW_NOTIFICATION_MAIL_SUCCESS:
      return {
        ...state,
        allowNotificationMails: action.payload,
      };
    case AllowNotificationMailTypes.GET_ALLOW_NOTIFICATION_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case AllowNotificationMailTypes.UPDATE_ALLOW_NOTIFICATION_MAIL_SUCCESS:
      return {
        ...state,
        allowNotificationMails: action.payload,
      };
    case AllowNotificationMailTypes.UPDATE_ALLOW_NOTIFICATION_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AllowNotificationMails;
