import React, { useEffect, useState } from 'react';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  createJobRequirementGql,
  getJobRequirementByJobKey,
  updateJobRequirementGql,
} from 'src/graphql/advertiser/job-requirement';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';

interface FormData {
  minPrice: number;
  maxPrice: number;
  ageRange: string;
  isMale: boolean;
  isFeMale: boolean;
  lgbt: boolean;
  country?: string;
  aptitude_ids?: number[];
}

const useForm = () => {
  const [jobId]: any = paramsFromURL(['job']);

  const [formData, setFormData] = useState<any>({
    minPrice: 0,
    maxPrice: 0,
    ageRange: '',
    isMale: false,
    isFeMale: false,
    lgbt: false,
    country: 'ALL',
    aptitude_ids: [],
  });

  const [selected, setSelected] = useState<number[]>([]);

  const [haveOldData, setHaveOldData] = useState(false);

  const getRequirementData = async () => {
    const requirementData = await getJobRequirementByJobKey(jobId);
    if (!requirementData) return;
    const aptitude_ids = requirementData?.aptitudes?.map(
      (eachAptitude_ids: any) => eachAptitude_ids?.id,
    );
    setSelected(aptitude_ids);
    let ageRange: any =
      `${requirementData.min_age}-${requirementData.max_age}` || '';
    if (requirementData.min_age === 0) {
      ageRange = 'All';
    } else if (requirementData.min_age === 1) {
      ageRange = '< 18';
    } else if (requirementData.min_age === 66) {
      ageRange = '> 65';
    }
    setFormData({
      minPrice: requirementData.min_budget || 0,
      maxPrice: requirementData.max_budget || 0,
      ageRange,
      isMale: requirementData.is_male || false,
      isFeMale: requirementData.is_female || false,
      lgbt: requirementData.is_male_lgbt || requirementData.is_female_lgbt || false,
      country:
        requirementData?.countries?.length > 1
          ? 'ALL'
          : requirementData?.countries[0]?.name,
      aptitude_ids: aptitude_ids || [],
    });
    setHaveOldData(true);
  };

  useEffect(() => {
    getRequirementData();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      aptitude_ids,
      country,
      isFeMale,
      isMale,
      lgbt,
      maxPrice,
      minPrice,
      fixed_price,
    } = formData;
    let { ageRange } = formData;

    const isEmpty =
      !(ageRange && country && parseInt(maxPrice, 10)) ||
      !(isMale || isFeMale || lgbt) ||
      aptitude_ids?.length <= 0;
    if (isEmpty) {
      notify(i18n.t('Please complete form .'), 'error');
      return;
    }
    if (ageRange === i18n.t('sort.All')) {
      ageRange = '0-120';
    } else if (ageRange === '< 18') {
      ageRange = '1-17';
    } else if (ageRange === '> 65') {
      ageRange = '66-120';
    }
    const [minAge, maxAge] = ageRange?.split('-');

    const mainData = {
      max_budget: formData.maxPrice ? +formData.maxPrice : 0,
      min_budget: formData.minPrice ? +formData.minPrice : 0,
      is_male: formData.isMale,
      is_female: formData.isFeMale,
      is_male_lgbt: (formData.isMale && formData.lgbt) || formData.lgbt,
      is_female_lgbt: (formData.isFeMale && formData.lgbt) || formData.lgbt,
      min_age: minAge ? +minAge : 0,
      max_age: maxAge ? +maxAge : 0,
      fixed_price: formData.fixed_price,
      job_key: jobId,
      aptitude_ids: formData.aptitude_ids,
      country_name: formData.country,
    };

    if (!haveOldData) {
      const res = await createJobRequirementGql(mainData);

      if (res) {
        window.location.href = `/JobSummary?job=${jobId}`;
      }
    } else {
      await updateJobRequirementGql(mainData);
      window.location.href = `/JobSummary?job=${jobId}`;
    }
  };

  const useRadio = () => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (selected.includes(+value)) {
        setSelected(selected.filter((option) => option !== +value));
        setFormData({
          ...formData,
          aptitude_ids: selected.filter((option) => option !== +value),
        });
      } else {
        setSelected([...selected, +value]);
        setFormData({
          ...formData,
          aptitude_ids: [...selected, +value],
        });
      }
    };

    return {
      selected,
      onChangeRadio: handleRadioChange,
    };
  };

  return { formData, handleChange, handleSubmit, useRadio, setFormData };
};

export default useForm;
