import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CustomTable from '../Common/CustomTable';
import { getReferralTransactions } from '../../store/actions';
import PageSizeOptions from '../Table/PageSizeOptions';

const ReferralTransactionsTable = ({ t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Redux
  const { txn } = useSelector((state: any) => ({
    txn: state.Referral.referralTransactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(defaultPageSize);

    dispatch(
      getReferralTransactions({
        limit: defaultPageSize,
        page: 1,
      }),
    );
  }, []);

  // const getEmail = (email: any) => {
  //   const splits = email.split('@');

  //   const emailFirst = [...splits[0]].map((char: string, index: any) => {
  //     return index === 0 ? char.toUpperCase() : '*';
  //   });

  //   return `${emailFirst.join('')}@${splits[1]}`;
  // };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getReferralTransactions(payload));

    setPageSize(newPageSize);
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Email'),
        accessor: ({ child }) => {
          // return getEmail(child.email);
          return child.email;
        },
        id: 'email',
      },
      {
        Header: t('table:Role'),
        accessor: ({ child }) => {
          return t(child.role);
        },
        id: 'role',
      },
      {
        Header: t('table:Earnings'),
        accessor: ({ total_earnings }) => {
          return floorToFixed(total_earnings, 2);
        },
        id: 'earnings',
      },
      {
        Header: t('table:CreateAt'),
        accessor: ({ last_updated_at }) => {
          return new Date(last_updated_at)?.toLocaleDateString();
        },
        id: 'create at',
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(getReferralTransactions(payload));
  };

  // eslint-disable-next-line no-shadow
  const getDefaultPayload = (page: number) => {
    setPage(page);

    return {
      limit: pageSize,
      page,
    };
  };

  const hiddenOnXS: any = ['col-5', 'col-6', 'col-7'];
  const hiddenOnSM: any = ['col-5', 'col-6', 'col-7'];
  const hiddenOnMD: any = ['col-6', 'col-7'];
  const hiddenOnLG: any = [];

  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
      >
        <Row className="justify-content-between m-0">
          <Col xs={12} md={4} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
              colors="white"
            />
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(ReferralTransactionsTable);
