import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import { setUserProfileList, setUserProfileStatus } from 'src/store/actions';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import {
  getAccessMetaPersonalToken,
  getAccessMetaToken,
} from '../getAccessMetaToken';
import getFacebookUserPageManagement from './facebookPageData';
import getFacebookUserData from './facebookUserData';

export const onFacebookProcess = async (param: any, rUrl: string | null = null) => {
  await onProcess({ ...param, type: SocialMediaType.FACEBOOK }, rUrl);
};

export const onFacebookPersonalProcess = async (
  param: any,
  rUrl: string | null = null,
) => {
  await onProcess(
    {
      ...param,
      type: SocialMediaType.FACEBOOK_PERSONAL,
    },
    rUrl,
  );
};

const onProcess = async (
  { params, dispatch, history, type }: any,
  rUrl: string | null = null,
) => {
  try {
    const code: string | null = params.get('code');

    const userListArr: UserSocialProfile[] = [];
    if (!code) throw new Error('Not found code');

    const response =
      type === SocialMediaType.FACEBOOK_PERSONAL
        ? await getAccessMetaPersonalToken(code, type, rUrl)
        : await getAccessMetaToken(code, type, rUrl);

    const { token: accessToken, expire } = response;
    // get profile account
    const userData = await getFacebookUserData(accessToken);
    if (!userData) {
      dispatch(setUserProfileStatus(`Not found facebook's user data.`));
      throw new Error('Not found user data');
    }
    // get path profile account
    const { id, access_token, link, permissions } = userData;

    if (type === SocialMediaType.FACEBOOK_PERSONAL) {
      let haveUserPost = false;
      let haveUserLink = false;

      for (let i = 0; i < permissions.data?.length; i++) {
        const permissionData = permissions.data[i];

        if (permissionData.permission === 'user_posts') {
          if (permissionData.status === 'granted') haveUserPost = true;
        }
        if (permissionData.permission === 'user_link') {
          if (permissionData.status === 'granted') haveUserLink = true;
        }
      }

      if (haveUserPost && haveUserLink) {
        userListArr.push({
          ...userData,
          profile_path: link,
          social_media_type: SocialMediaType.FACEBOOK_PERSONAL,
          expire_access_token: expire,
        });
      }

      if (userListArr?.length === 0) {
        dispatch(setUserProfileList(userListArr));
        if (!haveUserPost) {
          dispatch(setUserProfileStatus('Please grant "user_posts" permission'));
        } else if (!haveUserLink) {
          dispatch(setUserProfileStatus('Please grant "user_link" permission'));
        }
      } else {
        dispatch(setUserProfileList(userListArr));
        dispatch(setUserProfileStatus('PASS'));
      }
      return;
    }

    // get page profile
    const pageDatum = (await getFacebookUserPageManagement(id, access_token)) || [];

    for (let i = 0; i < pageDatum?.length; i++) {
      userListArr.push({
        ...pageDatum[i],
        profile_path: pageDatum[i]?.link,
        social_media_type: SocialMediaType.FACEBOOK,
        expire_access_token: expire,
      });
    }
    if (userListArr?.length === 0) {
      dispatch(setUserProfileList(userListArr));
      dispatch(
        setUserProfileStatus(
          'Please select "Pages" from facebook or accecpt all permission.',
        ),
      );
    } else {
      dispatch(setUserProfileList(userListArr));
      dispatch(setUserProfileStatus('PASS'));
    }

    return;
  } catch (error) {
    history.replace('/influencer/dashboard');
    throw new Error(`${error}`);
  }
};
