import { CampaignsTypes, CampaignState } from './actionTypes';

export const INIT_STATE: CampaignState = {
  campaigns: [],
  campaignsByUserId: [],
  campaign: {},
  campaignReport: {},
  todayTotal: {},
  statusSummary: {},
  transactions: [],
  campaignExcludeWebsites: [],
  SumDailyCurrentBudget: 0,
  error: {},
  loading: false,
};

const Campaigns = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CampaignsTypes.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
      };

    case CampaignsTypes.GET_SUM_DAILY_CURRENT_BUDGET_SUCCESS:
      return {
        ...state,
        SumDailyCurrentBudget: action.payload,
      };
    case CampaignsTypes.GET_CAMPAIGNS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CampaignsTypes.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        id: action.id,
      };
    case CampaignsTypes.GET_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CampaignsTypes.GET_CAMPAIGN_REPORT_SUCCESS:
      return {
        ...state,
        campaignReport: action.payload,
      };

    case CampaignsTypes.GET_CAMPAIGN_TODAY_TOTAL_SUCCESS:
      return {
        ...state,
        todayTotal: action.payload,
      };
    case CampaignsTypes.GET_CAMPAIGN_STATUS_SUMMARY_SUCCESS:
      return {
        ...state,
        statusSummary: action.payload,
      };
    case CampaignsTypes.GET_CAMPAIGN_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CampaignsTypes.GET_CAMPAIGNS_FILTER_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };

    case CampaignsTypes.CAMPAIGN_EXCLUDE_WEBSITES_SUCCESS:
      return {
        ...state,
        campaignExcludeWebsites: action.payload,
        id: action.id,
      };

    default:
      return state;
  }
};

export default Campaigns;
