import { gql } from '@apollo/client';
import { client } from '../client';

async function getDepositAddresses(input: any) {
  const query = gql`
    query GetDepositAddresses($input: FilterDepositAddress!) {
      getDepositAddresses(input: $input) {
        address
        tag
        currency {
          name
          symbol
          main_symbol
          network
          rate_in_USD
          price_in_USDT
          is_marketer_currency
          decimals
          contract_address
        }
        wallet {
          uuid
          balance
          checksum_status
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { input },
    fetchPolicy: 'no-cache',
  });

  return result.data.getDepositAddresses;
}

async function getDepositAddressesByUUIDGql(uuid: string) {
  const query = gql`
    query GetDepositAddressesByUUID($uuid: String!) {
      getDepositAddressesByUUID(uuid: $uuid) {
        address
        tag
        currency {
          symbol
          main_symbol
          network
          rate_in_USD
        }
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { uuid },
    fetchPolicy: 'no-cache',
  });

  return result.data.getDepositAddressesByUUID;
}

async function checkAddressIsInternal(address: string) {
  const query = gql`
    query checkAddressIsInternal($address: String!) {
      checkAddressIsInternal(address: $address)
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { address },
      fetchPolicy: 'no-cache',
    });
    return res.data.checkAddressIsInternal;
  } catch (error) {
    return null;
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getDepositAddresses, getDepositAddressesByUUIDGql, checkAddressIsInternal };
