import TwoFactorTypes from './actionTypes';

const initialState = {
  data: {},
  isEnabled: false,
  isRequestToDisabled: false,
  error: false,
  message: null,
  loading: false,
};

const twoFactor = (state = initialState, action: any) => {
  switch (action.type) {
    case TwoFactorTypes.GET_TWO_FACTOR_SECRET:
      return {
        ...state,
        loading: true,
      };
    case TwoFactorTypes.ENABLE_TWO_FACTOR:
      return {
        ...state,
        loading: true,
      };
    case TwoFactorTypes.GET_TWO_FACTOR_SECRET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: null,
        data: action.payload,
      };
    case TwoFactorTypes.GET_TWO_FACTOR_STATUS:
      return {
        ...state,
        loading: true,
      };
    case TwoFactorTypes.GET_TWO_FACTOR_STATUS_SUCCESS:
      return {
        ...state,
        isEnabled: action.payload.enableStatus,
        isRequestToDisabled: action.payload.requestDisableStatus,
        loading: false,
        error: false,
        message: null,
      };
    default:
      return { ...state };
  }
};

export default twoFactor;
