/* eslint-disable react/jsx-curly-brace-presence */
import BannerFormat from 'src/constants/banner-format-Emun';
import highlightText from '../Styles/HighlightText';

const nbsp = (count: number, data: string) => (
  <>
    {[...Array(count)].map(() => (
      <>&nbsp;&nbsp;&nbsp;</>
    ))}
    {data}
    <br />
  </>
);

const IosContent = ({
  adKey,
  appKey,
  adType,
}: {
  adKey: string;
  appKey: string;
  adType: BannerFormat;
}) =>
  adType === BannerFormat.IN_PAGE ? (
    <>
      {nbsp(0, 'struct ContentView: View {')}
      {nbsp(1, 'var body: some View {')}
      <span style={{ display: 'block', ...highlightText() }}>
        {nbsp(2, `AFlib.InPage(appKey: "${appKey}", adKey: "${adKey}")`)}
      </span>
      {nbsp(1, '}')}
      {nbsp(0, '}')}
    </>
  ) : (
    <>
      {nbsp(0, 'struct RootApp: App {')}
      {nbsp(1, 'var body: some Scene {')}
      {nbsp(2, 'WindowGroup {')}
      {nbsp(3, 'ZStack {')}
      {nbsp(4, 'ContentView()')}
      {nbsp(4, 'VStack {')}

      {adType === BannerFormat.HEADER ? (
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(5, `AFlib.Header(appKey: "${appKey}", adKey: "${adKey}").padding()`)}
        </span>
      ) : (
        nbsp(5, `// AFlib.Header(appKey: "example", adKey: "example").padding()`)
      )}

      {nbsp(5, 'Spacer()')}

      {nbsp(5, ' HStack {')}
      {nbsp(6, 'Spacer()')}

      {adType === BannerFormat.SLIDE ? (
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(6, `AFlib.Slide(appKey: "${appKey}", adKey: "${adKey}")`)}
        </span>
      ) : (
        nbsp(6, `// AFlib.Slide(appKey: "example", adKey: "example")`)
      )}

      {adType === BannerFormat.STICKY ? (
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(6, `AFlib.Sticky(appKey: "${appKey}", adKey: "${adKey}").padding()`)}
        </span>
      ) : (
        nbsp(6, `// AFlib.Sticky(appKey: "example", adKey: "example").padding()`)
      )}

      {nbsp(6, 'Spacer().frame(height:0)')}
      {nbsp(5, '}')}
      {nbsp(4, '}')}
      {nbsp(3, '}')}
      {nbsp(2, '}')}
      {nbsp(1, '}')}
      {nbsp(0, '}')}
    </>
  );

export default IosContent;
