import { gql } from '@apollo/client';
import Role from 'src/constants/enum/role';
import { client } from '../client';

const registerThirdParty = async (param: any) => {
  const mutation = gql`
    mutation register_third_party($param: CreateRegisterThirdPartyInput!) {
      register_third_party(input: $param) {
        accessToken
        isNewUser
        user {
          uuid
          email
          role
          referral_code
          timezone
          email_verified_at
          image_storage {
            key
          }
          verified_key
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });

    return res.data.register_third_party;
  } catch (error) {
    return null;
  }
};

const register = async (data: any) => {
  const REGISTER = gql`
    mutation Register($data: RegisterInput!) {
      register(input: $data) {
        email
        role
      }
    }
  `;

  const response = await client.mutate({
    mutation: REGISTER,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};
const getSocialMediaInfoImageGql = async () => {
  const query = gql`
    query {
      getAllSocialMediaInfo {
        key
        image_profile_url
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });

    return res.data.getAllSocialMediaInfo;
  } catch (error) {
    return null;
  }
};
const login = async (data: any) => {
  const LOGIN = gql`
    mutation Login($data: LoginInput!) {
      login(input: $data) {
        accessToken
        user {
          uuid
          email
          role
          email_verified_at
          referral_code
          timezone
          image_storage {
            key
          }
          verified_key
        }
      }
    }
  `;

  const response = await client.mutate({
    mutation: LOGIN,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const logout = async () => {
  const LOGOUT = gql`
    mutation Logout {
      logout
    }
  `;

  const response = await client.mutate({
    mutation: LOGOUT,
    errorPolicy: 'all',
  });

  return response;
};

const verifyMail = async (data: any) => {
  const VERIFY_MAIL = gql`
    mutation VerifyMail($data: VerifyMailInput!) {
      verifyMail(input: $data)
    }
  `;

  const response = await client.mutate({
    mutation: VERIFY_MAIL,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const forgotPassword = async (data: any) => {
  const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($data: ForgotPasswordInput!) {
      forgotPassword(input: $data) {
        email
      }
    }
  `;

  const response = await client.mutate({
    mutation: FORGOT_PASSWORD,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const resetPassword = async (data: any) => {
  const RESET_PASSWORD = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
      resetPassword(input: $data) {
        email
      }
    }
  `;

  const response = await client.mutate({
    mutation: RESET_PASSWORD,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const resendEmail = async (email: string, role: Role) => {
  const RESEND_EMAIL = gql`
    mutation ResendEmail($email: String!, $role: role!) {
      resendEmail(email: $email, role: $role) {
        email
      }
    }
  `;

  const response = await client.mutate({
    mutation: RESEND_EMAIL,
    variables: {
      email,
      role,
    },
    errorPolicy: 'all',
  });

  return response;
};

const changePasswordRequest = async (data: any) => {
  const CHANGE_PASSWORD_REQUEST = gql`
    mutation ChangePasswordRequest($data: ChangePasswordRequestInput!) {
      changePasswordRequest(input: $data)
    }
  `;

  const response = await client.mutate({
    mutation: CHANGE_PASSWORD_REQUEST,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const changePasswordConfirm = async (data: any) => {
  const CHANGE_PASSWORD_CONFIRM = gql`
    mutation ChangePasswordConfirm($data: ChangePasswordConfirmInput!) {
      changePasswordConfirm(input: $data)
    }
  `;

  const response = await client.mutate({
    mutation: CHANGE_PASSWORD_CONFIRM,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

// eslint-disable-next-line import/prefer-default-export
export {
  register,
  login,
  logout,
  verifyMail,
  forgotPassword,
  resetPassword,
  resendEmail,
  changePasswordRequest,
  changePasswordConfirm,
  registerThirdParty,
  getSocialMediaInfoImageGql,
};
