import { Col, Row } from 'reactstrap';
import AddCampaignWidget from 'src/components/Advertiser/Widgets/AddCampaignWidget';
import CampaignSummaryWidget from 'src/components/Advertiser/Widgets/CampaignSummaryWidget';
import CampaignTotalWidget from 'src/components/Advertiser/Widgets/CampaignTotalWidget';
import PerformanceSummary from 'src/components/Advertiser/Widgets/PerformanceSummary';
import WalletWidget from 'src/components/Common/WalletWidget';

const AdvertiserDashboard = () => {
  return (
    <div className="page-content">
      <Row className="align-items-stretch">
        <Col sm={12} md={4}>
          <div className="card-shadow-sm">
            <WalletWidget />
          </div>
        </Col>
        <Col sm={12} md={8}>
          <CampaignSummaryWidget />
        </Col>
      </Row>
      <Row className="each-item-mt-20px">
        <Col sm={12} md={4}>
          <div className="card-shadow-sm">
            <AddCampaignWidget />
          </div>
        </Col>
        <Col sm={12} md={8}>
          <CampaignTotalWidget />
        </Col>
      </Row>
      <div className="my-3">
        <div className="card-shadow-sm">
          <PerformanceSummary />
        </div>
      </div>
    </div>
  );
};

export default AdvertiserDashboard;
