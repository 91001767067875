import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketerCampaign } from 'src/store/actions';
import { setLoading, unsetLoading } from 'src/store/global/actions';
import {
  removeJobImageByKey,
  uploadJobThumbnailImage,
} from 'src/graphql/advertiser/job';

import paramsFromURL from 'src/constants/paramsFromURL';
import notify from 'src/utils/notify';
import * as Yup from 'yup';
import i18n from 'src/i18n';
import resizeImage from 'src/utils/resizeImage';
import { DescriptionJobNew } from 'src/store/marketerJobNew/reducer';
import { setDescriptionJobNew } from 'src/store/marketerJobNew/actions';

// Assets

import {
  formControlShare,
  formControlPhoto,
  formControlVideo,
} from './FormControlCampaign';

const MarketerJobCampaignDetailControl = () => {
  const [jobId, mock]: any = paramsFromURL(['job', 'mock']);
  const [key]: any = paramsFromURL(['campaign']);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validationSchema, setValidateSchema] = useState({});
  const initialValues: DescriptionJobNew = {
    job_type: 'INVITE_ONLY',
    marketer_campaign_key: '',
    country_name: null,
    aptitude_ids: [],
    is_male: null,
    is_female: null,
    is_male_lgbt: null,
    is_female_lgbt: null,
    min_age: null,
    max_age: null,
    fixed_price: null,
    currency_id: null,
    name: '',
    thumbnail_cover: '',
  };
  const [formValues, setFormValues] = useState(initialValues);

  // eslint-disable-next-line no-unused-vars
  const [minDate, setMinDate] = useState(Date.now());

  //? Logics
  // const marketerJob = useSelector((state: any) => state.MarketerJob.marketerJob);
  // const data = useSelector((state: any) => state.MarketerCampaign.marketerCampaign);
  // const keys = useSelector((state: any) => ({
  //   keys: state.MarketerJob.marketerJob,
  // }));
  // console.log(keys);
  const { influencerData, marketerJob, data } = useSelector((state: any) => ({
    influencerData: state.Users.userInfo,
    marketerJob: state.MarketerJob.marketerJob,
    data: state.MarketerCampaign.marketerCampaign,
  }));

  useEffect(() => {
    dispatch(getMarketerCampaign(key));
  }, [key]);
  useEffect(() => {
    setValidateSchema((prevState) => ({
      ...prevState,
      ...checkValidateSchema(),
    }));
  }, [marketerJob?.job_type]);

  const formControl = () => {
    switch (marketerJob.content_type) {
      case 'PHOTO':
        return formControlPhoto;
      case 'SHARE':
        return formControlShare;
      case 'VIDEO':
        if (marketerJob.social_media_type === 'TIKTOK') {
          return formControlPhoto;
        }
        return formControlVideo;
      default:
        return formControlPhoto;
    }
  };

  const handleSubmit = async (e: any) => {
    dispatch(setLoading());
    const newFormValues: any = formValues;
    const submitValue: any = {};
    if (formValues.fixed_price) {
      if (formValues!.fixed_price < 0) {
        notify(i18n.t('Fixed price must > 0 '), 'error');
        dispatch(unsetLoading());
        return;
      }
    }
    if (formValues.job_type === 'PUBLISH') {
      if (
        !formValues.is_male &&
        !formValues.is_female &&
        !formValues.is_female_lgbt &&
        !formValues.is_male_lgbt
      ) {
        notify(i18n.t('error:Please select your gender'), 'error');
        dispatch(unsetLoading());
        return;
      }
      if (formValues['aptitude_ids']) {
        if (formValues.aptitude_ids?.length <= 0) {
          notify(i18n.t('error:Please select your aptitude'), 'error');
          dispatch(unsetLoading());
          return;
        }
      }
    }

    if (!formValues['thumbnail_cover']) {
      notify(i18n.t('error:Please upload your image'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!formValues['currency_id']) {
      notify(i18n.t('you have to choose currency'), 'error');
      dispatch(unsetLoading());
      return;
    }
    for (let i = 0; i < Object.keys(formValues)?.length; i++) {
      const submitInput = Object.keys(formValues)[i];
      if (marketerJob[submitInput] !== newFormValues[submitInput]) {
        submitValue[submitInput] = newFormValues[submitInput];
      }
    }
    // await removeJobImageByKey(key);

    if (
      formValues['thumbnail_cover'] &&
      typeof formValues['thumbnail_cover'] === 'object'
    ) {
      if (marketerJob?.thumbnail_cover) {
        await removeJobImageByKey(marketerJob?.thumbnail_cover);
        submitValue['thumbnail_cover'] = '';
      }
      const file: any =
        formValues['thumbnail_cover'][0] || formValues['thumbnail_cover'];
      const newFile = await resizeImage({ file, maxSize: 600 });
      let thumbnail_cover: any;
      if (newFile) {
        thumbnail_cover = newFile;
      } else {
        thumbnail_cover = formValues['thumbnail_cover'];
      }

      const imageKey = await uploadJobThumbnailImage(thumbnail_cover, jobId);
      submitValue['thumbnail_cover'] = imageKey;
    } else {
      submitValue['thumbnail_cover'] = marketerJob['thumbnail_cover'];
    }
    // await setTimeout(() => {
    //   alert('Hello');
    // }, 1000);
    await dispatch(
      setDescriptionJobNew({ ...submitValue, marketer_campaign_key: key }),
    );
    dispatch(unsetLoading());
    localStorage.setItem(
      'descriptionJobNew',
      JSON.stringify({ ...submitValue, marketer_campaign_key: key }),
    );
    localStorage.removeItem('socialSelect');
    history.push('/Marketer/SocialSelect');
  };

  const checkValidateSchema = () => {
    switch (formValues.job_type) {
      case 'PUBLISH':
        return {};

      case 'INVITE_ONLY':
        return {
          name: Yup.string().required(
            i18n.t('marketer:create_job_campaign.required'),
          ),
          // aptitude_ids: Yup.array().min(
          //   1,
          //   i18n.t('marketer:create_job_campaign.required'),
          // ),
        };
      default:
        return {};
    }
  };

  //? Return
  return {
    initialValues,
    handleSubmit,
    validationSchema,
    data,
    marketerJob,
    formValues,
    setFormValues,
  };
};

export default MarketerJobCampaignDetailControl;

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // REVIEW_BEFORE_POSTING,
  // eslint-disable-next-line no-unused-vars
  DATE_INPUT,
  // eslint-disable-next-line no-unused-vars
  TEXT_EDITOR_INPUT,
  // eslint-disable-next-line no-unused-vars
  REVIEW_TYPE,
}

// interface Category {
//   label: string;
//   value: string | number | boolean;
//   icon: any;
//   icon_color?: string;
//   background_color?: string;
// }
