import { getAuthorizeOAuthTokenTwitterUrl } from 'src/graphql/auth/twitter';

const onTwitterConnect = async (path: string | null = 'social-calculate') => {
  const url = await getAuthorizeOAuthTokenTwitterUrl(path);
  if (!url) {
    throw new Error('Hi! I am error');
  }
  window.location.href = url;
};

export default onTwitterConnect;
