import { Pagination, Tooltip } from '@mui/material';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { calculateInfluencerPriceRate } from 'src/utils/influencerPriceRate';
import { getInfluencerPriceRate } from 'src/store/actions';
import CustomTable from '../../../../components/Common/CustomTable';

const TopUpTableAdmin: any = (topup: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [influencerPriceRate, setInfluencerPriceRate] = useState<any>([]);
  const [followerInput, setFollowerInput] = useState<any>();
  const [engagement, setEngagement] = useState<number>(0.1);
  const [contentPrice, setContentPrice] = useState<any>();
  const [dataAll, setDataAll] = useState<any>([]);

  useEffect(() => {
    dispatch(getInfluencerPriceRate());
  }, []);

  const { influencerPriceRate$ } = useSelector((state: any) => ({
    influencerPriceRate$: state.SocialMediaInfo.influencerPriceRate,
  }));
  useEffect(() => {
    if (influencerPriceRate$?.length > 0) {
      setInfluencerPriceRate(influencerPriceRate$);
    }
  }, [influencerPriceRate$]);
  const data = [
    { name: 'Facebook' },
    { name: 'Instagram' },
    { name: 'Tiktok' },
    { name: 'Youtube' },
    { name: 'X' },
  ];

  useEffect(() => {
    calculateTopupTable(followerInput, engagement);
  }, [engagement, followerInput, topup]);

  const obj: any = [];
  const calculateTopupTable = (follower = 0, engagement = 0) => {
    for (let i = 0; i < influencerPriceRate?.length; i++) {
      const content_price = calculateInfluencerPriceRate(
        influencerPriceRate[i],
        follower,
        engagement,
      );
      const content_priceTop = {
        share_content:
          content_price.share_content +
          content_price.share_content * (topup.topup / 100),
        post_content:
          content_price.post_content +
          content_price.post_content * (topup.topup / 100),
        video_content:
          content_price.video_content +
          content_price.video_content * (topup.topup / 100),
      };
      switch (influencerPriceRate[i]?.social_type) {
        case 'FACEBOOK':
          obj.push({ name: 'Facebook', ...content_priceTop });
          break;
        case 'INSTAGRAM':
          obj.push({ name: 'Instagram', ...content_priceTop });
          break;
        case 'TWITTER':
          obj.push({ name: 'X', ...content_priceTop });
          break;
        case 'YOUTUBE':
          obj.push({ name: 'Youtube', ...content_priceTop });
          break;
        case 'TIKTOK':
          obj.push({ name: 'Tiktok', ...content_priceTop });
          break;
        default:
          break;
      }
    }
    setDataAll(obj);
  };
  const x = 100;
  const columns = useMemo(() => {
    return [
      {
        Header: t('Social Name'),
        accessor: ({ name }: any) => <div>{name}</div>,
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Share'),
        accessor: ({ name, share_content }: any) => {
          return name === 'Facebook' ? (
            <div>{floorToFixed(share_content, 2)}</div>
          ) : (
            <div>-</div>
          );
        },
        id: 'contract_address',
        width: 50,
        disableSort: true,
      },

      {
        Header: t('Like&Comment'),
        accessor: ({ share_content }: any) => (
          <div>{floorToFixed(share_content, 2)}</div>
        ),
        id: 'symbol',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Like&Subscribe'),
        accessor: ({ name, share_content }: any) => {
          return name === 'Facebook' || name === 'Youtube' ? (
            <div>{floorToFixed(share_content, 2)}</div>
          ) : (
            <div>-</div>
          );
        },
        id: 'main_symbol',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Review'),
        accessor: ({ name, share_content }: any) => {
          return name === 'Facebook' ? (
            <div>{floorToFixed(share_content, 2)}</div>
          ) : (
            <div>-</div>
          );
        },
        id: 'Network',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Post'),
        accessor: ({ name, post_content }: any) => {
          return name === 'Facebook' || name === 'Instagram' || name === 'X' ? (
            <div>{floorToFixed(post_content, 2)}</div>
          ) : (
            <div>-</div>
          );
        },
        id: 'price_in_USDT',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Video'),
        accessor: ({ name, video_content }: any) => {
          return name === 'Youtube' || name === 'Tiktok' ? (
            <div>{floorToFixed(video_content, 2)}</div>
          ) : (
            <div>-</div>
          );
        },
        width: 50,
        id: 'user_detail',
        disableSort: true,
      },
    ];
  }, [t]);
  const [inputVa, setInputVa] = useState(followerInput);
  return (
    <div>
      {/* <Row
        xs={10}
        className="gleam-job-container-header bb"
        style={{ width: '100%', marginLeft: '2px' }}
      >
        <Col>{t('Like&Comment')}</Col>
        <Col>{t('Like&Comment')}</Col>
        <Col>{t('Like&Subscribe')}</Col>
        <Col>{t('Review')}</Col>
        <Col>{t('Post')}</Col>
        <Col>{t('Video')}</Col>
      </Row>
      {data.map((item: any) => {
        return (
          <Row
            xs={12}
            className="gleam-job-container"
            style={{ width: '100%', marginLeft: '2px' }}
          >
            <Col className="col-center">{item.name}</Col>
            <Col className="col-center">xx</Col>
            <Col className="col-center">{item.name}</Col>
            <Col className="col-center">xx</Col>
            <Col className="col-center">{item.name}</Col>
            <Col className="col-center">xx</Col>
            <Col className="col-center">{item.name}</Col>
          </Row>
        );
      })} */}
      <div className="center" style={{ marginTop: '20px' }}>
        {' '}
        <h5 className="text-white">{t('campaignNew.Prize Table')}</h5>
      </div>
      <CustomTable
        showPaginationBottom={false}
        columns={columns || []}
        data={followerInput ? dataAll : data}
        hidePagination
        // page={param.page}
        // pageSize={param.limit}
        // total={currency?.totalCount}
        // handlePageChange={handlePageChange}
        headerCSS="fw-normal bg-header-table"
      />

      <div style={{ display: 'flex', gap: '20px', justifyContent: 'start' }}>
        <div>
          <div className="text-white">{t('campaignNew.Follower')}</div>
          <input
            min={1}
            onKeyDown={(e) => {
              // Prevent the input of '+' and '-' characters
              if (e.key === '+' || e.key === '-') {
                e.preventDefault();
              }
            }}
            className="form-control"
            style={{ maxWidth: '280px', width: '100%' }}
            type="number"
            value={inputVa || null}
            onChange={(e) => {
              if (Number(e.currentTarget.value) < 0) {
                setInputVa(0);
              } else {
                setInputVa(Number(e.currentTarget.value));
                setFollowerInput(Number(e.currentTarget.value));
              }
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="text-white">{t('campaignNew.Engagement')}</div>
            <Tooltip
              title={
                <>
                  <h4 style={{ color: 'white' }}>
                    {t('campaignNew.EngagementTooltip')}
                  </h4>
                </>
              }
              placement="right"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>

          <select
            className="form-control"
            style={{ maxWidth: '280px', width: '100%' }}
            onChange={(e) => {
              switch (e.currentTarget.value) {
                case 'Low':
                  setEngagement(0.1);
                  break;
                case 'Medium':
                  setEngagement(0.5);
                  break;
                case 'High':
                  setEngagement(1);
                  break;
                default:
                  setEngagement(0.1);
              }
            }}
          >
            <option value="Low">{t('label.Low')}</option>
            <option value="Medium">{t('label.Medium')}</option>
            <option value="High">{t('label.High')}</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TopUpTableAdmin;
