import React from 'react';
import { Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const ChecksumBadge = ({ checksum_status }: any) => {
  const renderStatus = () => {
    return checksum_status ? (
      <Badge className="bg-success text-light font-size-12">True</Badge>
    ) : (
      <Badge className="bg-danger text-light font-size-12">False</Badge>
    );
  };

  return <>{renderStatus()}</>;
};

export default withTranslation()(ChecksumBadge);
