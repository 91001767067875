import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Arrow_down as ArrowDown } from 'src/assets/images/other/arrow-icon';

// i18n
import languages from '../../../common/languages';

const LanguageDropdown = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState<string>('');
  const [menu, setMenu] = useState<boolean>(false);

  useEffect(() => {
    const currentLanguage: any = localStorage.getItem('I18N_LANGUAGE');
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang: string) => {
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
    window.location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className={`btn ${props.bodyCSS} p-0`}
          tag="button"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            color: 'white',
            // width: 40,
          }}
          caret
        >
          <span className={`align-middle ${props.textCSS} font-size-16`}>
            {`${selectedLang.toUpperCase()}`}
          </span>
          {/* <img
                        src={arrow_down}
                        alt="Arrow down"
                        style={{ paddingLeft: 10, color: 'red' }}
                    /> */}
          {/* <div style={{ marginLeft: 10 }}>
            <ArrowDown fill={props.fill} />
          </div> */}
        </DropdownToggle>
        <DropdownMenu className="language-switch">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={async () => {
                await changeLanguageAction(key);
              }}
              className={`notify-item ${selectedLang === key ? 'active' : 'none'}`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Skote"
                className="me-1"
                height="12"
              />
              <span className="align-middle">{get(languages, `${key}.label`)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
