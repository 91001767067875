import { WebsiteTypes, WebsiteState } from './actionTypes';

export const INIT_STATE: WebsiteState = {
  website: [],
  websiteInfo: [],
  websiteReport: [],
  website_type: [],
  website_types_by_website: [],
  earningTransaction: [],
  adminEarningTransaction: [],
  websiteTransaction: [],
  websiteTransaction_by_date: [],
  websiteGraphDatum: [],
  allEarningTransaction: [],
  requestSize: [],
  pending_approval: [],
  error: {},
};

const Websitelist = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case WebsiteTypes.GET_WEBSITE_SUCCESS:
      return {
        ...state,
        website: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_PENDING_APPROVAL_SUCCESS:
      return {
        ...state,
        pending_approval: action.payload,
      };
    case WebsiteTypes.GET_PENDING_APPROVAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const WebsiteInfo = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case WebsiteTypes.GET_INFO_WEBSITE_SUCCESS:
      return {
        ...state,
        websiteInfo: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_GRAPH_DATUM_SUCCESS:
      return {
        ...state,
        websiteGraphDatum: action.payload,
      };
    case WebsiteTypes.GET_INFO_WEBSITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_REPORT_SUCCESS:
      return {
        ...state,
        websiteReport: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_REQUEST_SIZE_SUCCESS:
      return {
        ...state,
        requestSize: action.payload,
      };
    case WebsiteTypes.GET_REQUEST_SIZE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_EARNING_TRANSACTION_SUCCESS:
      return {
        ...state,
        earningTransaction: action.payload,
      };
    case WebsiteTypes.GET_EARNING_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_ADMIN_EARNING_TRANSACTION_SUCCESS:
      return {
        ...state,
        adminEarningTransaction: action.payload,
      };
    case WebsiteTypes.GET_ADMIN_EARNING_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TRANSACTION_LOG_SUCCESS:
      return {
        ...state,
        websiteTransaction: action.payload,
      };
    case WebsiteTypes.GET_ALL_EARNING_TRANSACTION_SUCCESS:
      return {
        ...state,
        allEarningTransaction: action.payload,
      };
    case WebsiteTypes.GET_ALL_EARNING_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TYPES_SUCCESS:
      return {
        ...state,
        website_type: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        websiteTransaction_by_date: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TRANSACTION_BY_DATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TYPES_WEBSITE_SUCCESS:
      return {
        ...state,
        website_types_by_website: action.payload,
      };
    case WebsiteTypes.GET_WEBSITE_TYPES_WEBSITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { Websitelist, WebsiteInfo };
