import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createMarketerCategoryGql,
  getMarketerCategoriesGql,
} from 'src/graphql/advertiser/marketerCategory';

import { setLoading, unsetLoading } from '../actions';
import { getMarketerCategorySuccess } from './actions';
import MarketerCategoryTypes from './actionTypes';

function* createMarketerCategory({ name }: any) {
  try {
    yield put(setLoading());
    yield call(createMarketerCategoryGql, name);
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getMarketerCategory() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getMarketerCategoriesGql);

    yield put(getMarketerCategorySuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* marketerCategories() {
  yield takeEvery(
    MarketerCategoryTypes.CREATE_MARKETER_CATEGORY,
    createMarketerCategory,
  );
  yield takeEvery(MarketerCategoryTypes.GET_MARKETER_CATEGORY, getMarketerCategory);
}

export default marketerCategories;
