import { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { withTranslation } from 'react-i18next';
import { getAdminEarningTransaction } from 'src/store/website/actions';
import {
  getSumBalanceWalletByUUID,
  getBalanceWalletByUUID,
} from 'src/store/actions';
import { getUserDetail } from '../../store/users/actions';
import MovementTransactionsTable from './MovementTransactionsTable';
import { getMovement } from '../../store/movement/actions';
import WithdrawTransactionsTable from './WithdrawTransactionsTable';
import AdminEarningHistory from './EarningHistory';
import UserProfileComponent from './UserProfileComponent';
import ReferralEarningsTable from './ReferralEarningsTable';
import SocialMediaTable from './SocialMediaTable';
import DepositAddressTable from './DepositAddressTable';
import BanHistoryTable from './BanHistoryTable';
import JobsHistoryTable from './JobsHistoryTable';
import WalletTable from './WalletTable';

const PublisherDetailComponent = ({ t }: any) => {
  const dispatch = useDispatch();
  const { uuid }: any = useParams();

  useEffect(() => {
    dispatch(getUserDetail({ id: uuid }));
    dispatch(getMovement({ limit: 10, page: 1, userId: uuid }));
    dispatch(getAdminEarningTransaction({ limit: 10, page: 1, user_id: uuid }));
    dispatch(getSumBalanceWalletByUUID(uuid));
    dispatch(getBalanceWalletByUUID(uuid));
  }, [uuid]);

  const [activeTab, toggleTab] = useState(1);

  const renderTabs = () => {
    return (
      <Nav className="nav-tabs-custom-publisher card-header-tabs border-top">
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 1,
              },
              'text-white',
            )}
            onClick={() => {
              toggleTab(1);
            }}
          >
            {t('Menus.Movement Balance')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 2,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(2);
            }}
          >
            {t('Menus.Withdraw Transaction')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 3,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(3);
            }}
          >
            {t('Menus.Earning History')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 4,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(4);
            }}
          >
            {t('Menus.Referral')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 5,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(5);
            }}
          >
            {t('Menus.Social Media')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 6,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(6);
            }}
          >
            {t('Menus.Deposit Address')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 7,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(7);
            }}
          >
            {t('Menus.Ban History')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 8,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(8);
            }}
          >
            {t('Menus.Job History')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={classnames(
              {
                'active-tab-admin': activeTab === 9,
              },
              'px-3 text-white',
            )}
            onClick={() => {
              toggleTab(9);
            }}
          >
            {t('Menus.Wallets')}
          </NavLink>
        </NavItem>
      </Nav>
    );
  };

  const renderTabsContent = () => {
    return (
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <MovementTransactionsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={2}>
          <WithdrawTransactionsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={3}>
          <AdminEarningHistory userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={4}>
          <ReferralEarningsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={5}>
          <SocialMediaTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={6}>
          <DepositAddressTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={7}>
          <BanHistoryTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={8}>
          <JobsHistoryTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={9}>
          <WalletTable userId={uuid} tab={activeTab} />
        </TabPane>
      </TabContent>
    );
  };

  return (
    <>
      <Card>
        <CardBody className="p-0" style={{ marginBottom: '20px' }}>
          <Row className="g-0">
            <div className="col-sm order-2 order-sm-1 p-4">
              <UserProfileComponent />
            </div>
          </Row>
          {renderTabs()}
        </CardBody>
      </Card>
      {renderTabsContent()}
    </>
  );
};

export default withTranslation()(PublisherDetailComponent);
