import { Modal, CardBody, TabContent, TabPane } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import BannerFormat from 'src/constants/banner-format-Emun';
import CodeBlock from './CodeBlock';
import CompleteGuide from './CompleteGuide';
import NavbarHeaderAdKey from './NavbarHeaderAdKey';
import tabHeaders from './Utils/TabHeaders';
import tabContents from './Utils/TabContents';

const ScriptAppModal = ({
  isOpen,
  handleToggle,
  data,
  handleClose,
  t,
  title,
}: any) => {
  const [copySuccess, setCopySuccess] = useState(0);
  const [headerPillsTab, setHeaderPillsTab] = useState(1);

  const adKey: string = data?.key;
  const appKey: string = data?.application?.application_key;
  const adType: BannerFormat = BannerFormat[
    data?.banner_format as any
  ] as unknown as BannerFormat;

  // Copy to clipboard
  const handleCopy = (val: string, copyButton: number) => {
    navigator.clipboard.writeText(val);
    if (copyButton === 1) setCopySuccess(1);
    else setCopySuccess(2);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copySuccess) setCopySuccess(0);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [copySuccess]);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0 font-size-24" id="myModalLabel">
          {t(`${title}`)}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body p-10">
        <CardBody>
          <div className="nav-tabs-custom nav-scrollbar-none">
            {tabHeaders.map((tab: { label: string; tabId: number }) => (
              <NavbarHeaderAdKey
                key={uniqueId()}
                label={tab.label}
                tabId={tab.tabId}
                headerPillsTab={headerPillsTab}
                setHeaderPillsTab={setHeaderPillsTab}
              />
            ))}
          </div>
          <TabContent className="text-muted" activeTab={headerPillsTab}>
            {tabContents(adKey, appKey, adType).map(
              (content: { tabId: number; content: any; copyTextFunc: any }) => (
                <>
                  <TabPane tabId={content.tabId}>
                    <div style={{ padding: '0.5em 1em' }}>
                      <CompleteGuide />
                      <CodeBlock
                        copySuccess={copySuccess}
                        handleCopy={handleCopy}
                        textCopy={content.copyTextFunc(adKey, appKey, adType)}
                        content={content.content}
                      />
                    </div>
                  </TabPane>
                </>
              ),
            )}
          </TabContent>
        </CardBody>
      </div>
    </Modal>
  );
};

export default withTranslation()(ScriptAppModal);

// const App: () => Node = () => {
//   return (
//      <SafeAreaView style={backgroundStyle}>
//         //<HeaderAd appKey="appKeyExample" adKey="adKeyExample" />
//         AFlib.Sticky(appKey: "vatqb0ke2", adKey: "8hn19686n").padding()
//         //<SlideAd appKey="appKeyExample" adKey="adKeyExample" />
//      <SafeAreaView/>
//   );
// };

// const App: () => Node = () => {
//   return (
//     <SafeAreaView style={backgroundStyle}>
//       {/* <HeaderAd appKey="na3g3y3fq" adKey="rp4abc2qg" /> */}
//       <StickyAd appKey="vatqb0ke2" adKey="8hn19686n" />
//       {/* <SlideAd appKey="na3g3y3fq" adKey="aoy54dlb0" /> */}
//     </SafeAreaView>
//   );
// };
