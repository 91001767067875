import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import choice from 'src/assets/images/choice.png';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import {
  getAllowNotificationMails as onGetAllowNotificationMails,
  updateAllowNotificationMails as onUpdateAllowNotificationMails,
} from '../../../store/actions';

const EmailNotificationsSetting = ({ t }: any) => {
  const dispatch = useDispatch();

  const { allowNotificationMail, user } = useSelector((state: any) => ({
    allowNotificationMail: state.AllowNotificationMails.allowNotificationMails,
    user: state.login.user,
  }));

  useEffect(() => {
    dispatch(onGetAllowNotificationMails());
  }, []);

  const handleToggleNotificationMail = (settingStatus: string) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t(`swal:${settingStatus} mail will be disabled`),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        if (settingStatus === 'Market') {
          dispatch(
            onUpdateAllowNotificationMails({
              allow_market_mail: !allowNotificationMail.allow_market_mail,
            }),
          );
        } else if (settingStatus === 'Balance is low') {
          dispatch(
            onUpdateAllowNotificationMails({
              allow_balance_low_mail: !allowNotificationMail.allow_balance_low_mail,
            }),
          );
        } else if (settingStatus === 'Out of budget') {
          dispatch(
            onUpdateAllowNotificationMails({
              allow_campaign_out_of_budget_mail:
                !allowNotificationMail.allow_campaign_out_of_budget_mail,
            }),
          );
        } else if (settingStatus === 'Finished') {
          dispatch(
            onUpdateAllowNotificationMails({
              allow_campaign_finished_mail:
                !allowNotificationMail.allow_campaign_finished_mail,
            }),
          );
        }
      }
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h5
            className="m-0"
            style={{ fontSize: '16px', padding: '10px 10px 10px 15px' }}
          >
            {t('System Mail')}
          </h5>
        </CardHeader>

        <CardBody>
          <Row>
            <Col lg={3}>
              <Card className="mb-0 mt-2">
                <CardBody style={{ backgroundColor: 'white' }}>
                  <div className="d-flex">
                    <span className="d-flex align-items-center flex-grow-1 mx-2">
                      <h5
                        className="m-0 fw-normal"
                        style={{ fontSize: '16px', color: 'black' }}
                      >
                        {t('Marketing')}
                      </h5>
                    </span>
                    <div className="form-check form-switch form-switch-lg">
                      <input
                        type="checkbox"
                        className="form-check-input black-color"
                        checked={allowNotificationMail.allow_market_mail}
                        onChange={() => {
                          handleToggleNotificationMail('Market');
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {user.role === 'ADVERTISER' && (
        <Card>
          <CardHeader>
            <h5
              className="m-0"
              style={{ fontSize: '16px', padding: '10px 10px 10px 15px' }}
            >
              {t('Activity Mail Notification')}
            </h5>
          </CardHeader>
          <CardBody>
            <Row>
              {['Balance is low', 'Out of budget', 'Finished'].map(
                (message: string) => {
                  let activeNotifications = false;
                  if (message === 'Balance is low') {
                    activeNotifications =
                      allowNotificationMail.allow_balance_low_mail;
                  } else if (message === 'Out of budget') {
                    activeNotifications =
                      allowNotificationMail.allow_campaign_out_of_budget_mail;
                  } else if (message === 'Finished') {
                    activeNotifications =
                      allowNotificationMail.allow_campaign_finished_mail;
                  }
                  return (
                    <Col lg={3}>
                      <Card className="mb-0">
                        <CardBody style={{ backgroundColor: 'white' }}>
                          <div className="d-flex">
                            <span className="d-flex align-items-center flex-grow-1 mx-2">
                              <h5
                                className="m-0 fw-normal"
                                style={{ fontSize: '16px', color: 'black' }}
                              >
                                {t(`ActivityLogs.${message}`)}
                              </h5>
                            </span>
                            <div className="form-check form-switch form-switch-lg">
                              <input
                                type="checkbox"
                                className="form-check-input black-color"
                                checked={activeNotifications}
                                onChange={() => {
                                  handleToggleNotificationMail(message);
                                }}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                },
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default withTranslation()(withRouter(EmailNotificationsSetting));
