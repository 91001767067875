/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback } from 'react';
import './CreateMarketerPublicJobMock.style.scss';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tooltips from '@mui/material/Tooltip';
import SortByStyle from 'src/components/Advertiser/Models/SelectInfuencer/SortBy.style';
import { ReactFlagsSelectCountry } from 'src/utils/country';
import { getAllAptitude } from 'src/store/actions';
import ReactFlagsSelect from 'react-flags-select';
import InfluencerChoiceComponent from 'src/components/Advertiser/Models/SelectInfuencer/SortByInfuencer/Influencer/InfluencerChoice.component';
import lgbtIcon from 'src/assets/images/lgbt_icon.png';

import menIcon from 'src/assets/images/men_icon.png';
import womenIcon from 'src/assets/images/women_icon.png';
import { Card, Label, Tooltip } from 'reactstrap';

const DivContent = styled.div`
  // overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  // margin-bottom: 5rem;
  // background-color: #fbfaff;
`;

const Texts = styled.p<{
  fontsize?: string;
  // background?: string;
  height?: string;
  margin?: string;
  fontweight?: string;
}>`
  font-size: ${({ fontsize }) => fontsize};
  font-weight: ${({ fontweight }) => fontweight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: ${({ margin }) => margin};

  @media (max-width: 450px) {
    font-size: 18px;
  }
`;
const Select = styled.select<{
  width?: string;
  height?: string;
  borderradius?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: auto;
  cursor: pointer;
  // background-color: #fbfaff;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 0.2rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 90%;
  height: 100px;
  margin: -10px auto 10px;
  // background-color: red;
`;

const Option = styled.option<{
  width?: string;
  height?: string;
  borderradius?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: auto;
  background-color: #fbfaff;
  :hover {
    background-color: #abb2b9;
  }
`;

const Btn = styled.button<{
  width?: string;
  height?: string;
  borderradius?: string;
  background?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 20px auto;
  background-color: ${({ background }) => background};
  border: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  :hover {
    background-color: #0a598a;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
`;
// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyType {
  // eslint-disable-next-line no-unused-vars
  FOLLOWER = 'FOLLOWER',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'INFLUENCER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum County {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  Thailand = 'Thailand',
  // eslint-disable-next-line no-unused-vars
  OTHER = 'OTHER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'MEN',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'WOMEN',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyInfluencer {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  FOLLOWERS = 'follower',
  // eslint-disable-next-line no-unused-vars
  RATING = 'rating',
  // eslint-disable-next-line no-unused-vars
  COST = 'photo_content_price',
}
// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
}
interface FormControl {
  label: string;
  color: string;
  iconClassName: any;
  type: FormInputType;
  required?: boolean;
  name: string;
  icon: any;
}

const formControls: FormControl[] = [
  {
    label: 'Men',
    iconClassName: 'fas fa-mars',
    color: '#FFFFFF',
    icon: menIcon,
    type: FormInputType.STRING,
    required: true,
    name: 'is_male',
  },
  {
    label: 'Women',
    iconClassName: 'fas fa-venus',
    name: 'is_female',
    color: '#FFFFFF',
    icon: womenIcon,
    type: FormInputType.STRING,
    required: true,
  },

  // {
  //   label: 'lgbt',
  //   iconClassName: 'fas fa-transgender-alt',
  //   name: 'LGBT',
  //   color: '#f7df81',
  //   type: FormInputType.IS_LGBT,
  //   required: true,
  // },
];
// interface FormData {
//   minPrice: number;
//   maxPrice: number;
//   ageRange: string;
//   gender: string;
//   sexualOrientation: string;
// }
const { FlexRow, FlexCol } = SortByStyle();
// const CreateMarketerPublicJob: React.FC = () => {
//   const [formData, setFormData] = useState<FormData>({
//     minPrice: 0,
//     maxPrice: 0,
//     ageRange: '',
//     gender: '',
//     sexualOrientation: '',
//   });

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { iconClassName, name } = event.target;
//     setFormData({
//       ...formData,
//       [iconClassName]: name,
//     });
//   };

const LgbtRadioInput = ({ setFormData, t }: any) => {
  const [lgbtChecked, setLgbtChecked] = useState(false);

  const updateFormData = useCallback(() => {}, [lgbtChecked]);

  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      is_male_lgbt: lgbtChecked,
      is_female_lgbt: lgbtChecked,
    }));
  }, [updateFormData, lgbtChecked]);

  const onClick = () => {
    setLgbtChecked(!lgbtChecked);
  };
  return (
    <div className="radio radio-input" onClick={onClick}>
      <input id="radio lgbt" type="radio" checked={lgbtChecked} />
      <img
        className="image-full"
        width={40}
        height={40}
        src={lgbtIcon}
        alt="icon radio"
      />
      <i className="fas fa-check" />
      {/* <Label for="radio lgbt" style={{ marginTop: '10px' }}>
        {t('LGBT')}
      </Label> */}
    </div>
  );
};

const CustomRadioInput = ({ setFormData, formData, data, t }: any) => {
  const checked = formData[data?.name];
  const onClick = () => {
    setFormData({
      ...formData,
      [data.name]: !formData[data?.name],
    });
  };
  return (
    <div className="radio radio-input" id="gender" onClick={onClick}>
      <input id={`radio ${data.name}`} type="radio" checked={checked} />

      <img
        src={data.icon}
        alt="icon radio"
        className="image-icon"
        style={{
          background: data.color,
        }}
      />
      <i className="fas fa-check " />
      <Label for={`radio ${data.name}`}>{t(data.label)}</Label>
    </div>
  );
};
// eslint-disable-next-line react/prop-types
const CreateMarketerPublicJobMock = ({ formData, setFormData }: any) => {
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const [IsOpenFixed, setIsOpenFixed] = useState(false);
  const { t } = useTranslation();
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
    aptitudeData: state.SocialMediaInfo.aptitudes,
  }));

  useEffect(() => {
    dispatch(getAllAptitude());
    setFormData({ ...formData, fixed_price: null });
    // console.log(opt);
  }, []);
  const [checkFixedPrice, setCheckFixedPrice] = useState(false);

  const price =
    marketerJob?.content_type === 'SHARE'
      ? 'share_content_price'
      : 'photo_content_price';
  const ageRanges: { label: string; min_age: number; max_age: number }[] = [
    { label: t('sort.All'), min_age: -1, max_age: 120 },
    { label: '< 18', min_age: 0, max_age: 17 },
    { label: '18-24', min_age: 18, max_age: 24 },
    { label: '25-34', min_age: 25, max_age: 34 },
    { label: '35-44', min_age: 35, max_age: 44 },
    { label: '45-54', min_age: 45, max_age: 54 },
    { label: '55-64', min_age: 55, max_age: 64 },
    { label: '> 65', min_age: 65, max_age: 120 },
  ];
  const propertyInfluencer: { label: string; name: any }[] = [
    { label: t('sort.All'), name: PropertyInfluencer.ALL },
    { label: t('sort.Follower'), name: PropertyInfluencer.FOLLOWERS },
    { label: t('sort.Rating'), name: PropertyInfluencer.RATING },
    { label: t('sort.Price'), name: price },
  ];

  // const AllChoice = () => {
  //   const all = [...CategoriesMarketerCampaign].map((data: any) => data.name);

  //   setCheckAllProperty(!checkAllProperty);
  //   if (!checkAllProperty) {
  //     setFilterForm({ ...filterForm, aptitude_ids: all });
  //   } else {
  //     setFilterForm({ ...filterForm, aptitude_ids: [] });
  //   }
  // };
  // const { formData, handleChange, handleSubmit, setFormData } = mockFormData();
  useEffect(() => {
    // if (formData) {
    const ele: any = document.getElementById('ageRange');
    // const opt = ele.options[0];
    // opt.name = '25-34';

    ele.name = formData.ageRange;
    // }
  }, []);
  const resetCountry = () => {
    setFormData({ ...formData, country_name: 'ALL' });
    setSelected('');
  };

  const handleKeyPress = (event: any) => {
    return (
      (event.charCode !== 8 && event.charCode === 0) ||
      (event.charCode >= 48 && event.charCode <= 57)
    );
  };

  return (
    <div className="create-marketer-public-container-mock">
      <Card
        style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}
      >
        <DivContent>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
          >
            <FlexCol>
              <Texts margin="16px 10px 10px 20px" fontsize="20px" fontweight="bold">
                {`${t('marketer:create_job_campaign:fixed_price')} ( optional )`}
              </Texts>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button
                  type="button"
                  className={`button-type-job ${
                    IsOpenFixed === false && 'btn-bg-active'
                  }`}
                  style={{
                    position: 'relative',
                    width: '180px',
                    marginLeft: '30px',
                    borderRadius: '25px',
                    border: 'solid 2px transparent',
                  }}
                  onClick={(e) => {
                    setIsOpenFixed(false);
                    setFormData({ ...formData, fixed_price: null });
                  }}
                >
                  <span>{t('Auto')}</span>
                  {IsOpenFixed === false && <i className="fas fa-check " />}
                </button>
                <button
                  type="button"
                  className={`button-type-job ${
                    IsOpenFixed === true && 'btn-bg-active'
                  }`}
                  style={{
                    position: 'relative',
                    width: '180px',
                    marginLeft: '30px',
                    borderRadius: '25px',
                    border: 'solid 2px transparent',
                  }}
                  onClick={(e) => {
                    setIsOpenFixed(true);
                  }}
                >
                  <span>{t('Fixed price')}</span>
                  {IsOpenFixed === true && <i className="fas fa-check " />}
                </button>
                <div style={{ margin: '0px' }}>
                  <Tooltips title={t('for set default price')} placement="top-start">
                    <i
                      className="fas fa-info-circle ms-2 font-size-16"
                      style={{
                        cursor: 'pointer',
                      }}
                      id="tooltipInfo"
                    />
                  </Tooltips>
                </div>
              </div>
              {IsOpenFixed ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', width: '50%', marginTop: '14px' }}>
                    <input
                      className="form-control"
                      min={0.00000000000000000000000000000000000001}
                      onKeyPress={handleKeyPress}
                      type="number"
                      value={formData.fixed_price}
                      step="any"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          fixed_price: Number(e.target.value),
                        });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </FlexCol>
            <FlexCol>
              <Texts margin="16px 10px 10px 20px" fontsize="20px" fontweight="bold">
                {t('marketer:create_job_campaign:Influencer_country')}
              </Texts>
              <div
                style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
              >
                <div
                  style={{
                    width: '250px',
                    marginTop: '12px',
                    textAlign: 'end',
                    marginLeft: '16px',
                  }}
                >
                  <ReactFlagsSelect
                    searchable
                    placeholder={
                      formData?.country_name !== 'ALL'
                        ? formData?.country_name
                        : t('marketer:create_job_campaign:Search all country')
                    }
                    selectedSize={16}
                    optionsSize={16}
                    searchPlaceholder={t(
                      'marketer:create_job_campaign:Search country',
                    )}
                    onSelect={(label: any) => {
                      const country: any = ReactFlagsSelectCountry;
                      setFormData({ ...formData, country_name: country[label] });

                      setSelected(label);
                      const rfsBtn = document.getElementById('rfs-btn');
                      if (!rfsBtn) return;
                      rfsBtn.click();
                    }}
                    showOptionLabel
                    selected={selected}
                  />
                  <span
                    style={{
                      textDecoration: 'underline',
                      color: 'rgb(0, 201, 255)',
                      cursor: 'pointer',
                    }}
                    onClick={resetCountry}
                  >
                    {t('Reset Country')}
                  </span>
                </div>
              </div>
            </FlexCol>

            <FlexCol margin="20px 0">
              <Texts margin="16px 10px 10px 20px" fontsize="20px" fontweight="bold">
                {t('marketer:create_job_campaign:Gender')}
              </Texts>
              <FlexRow justifycontent="flex-start" width="100%">
                <GridBox style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* {formControls.map((data: any, index) => (
                    <IconRadioInputComponent
                      id={index.toString()}
                      icon={data.iconClassName}
                      label={t(`marketer:create_job_campaign:${data.label}`)}
                      background={data.color}
                      labelSize="1em"
                      isChecked={formData[data?.name]}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          [data.name]: !formData[data?.name],
                        });
                      }}
                    />
                  ))} */}
                  <div className="radio-container">
                    {formControls.map((data) => {
                      return (
                        <CustomRadioInput
                          setFormData={setFormData}
                          formData={formData}
                          data={data}
                          t={t}
                        />
                      );
                    })}

                    <LgbtRadioInput
                      setFormData={setFormData}
                      formData={formData}
                      t={t}
                    />
                  </div>
                </GridBox>
              </FlexRow>
            </FlexCol>
            <FlexCol>
              <Texts margin="16px 10px 10px 20px" fontsize="20px" fontweight="bold">
                {t('marketer:create_job_campaign:Age')}
              </Texts>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Select
                  id="ageRange"
                  width="250px"
                  height="50px"
                  style={{ textAlign: 'center', fontSize: '16px' }}
                  borderradius="5px"
                  onChange={(e: any) => {
                    const targetValue = e.target.value;

                    let minAge = 0;
                    let maxAge = 120;

                    if (targetValue.includes('< ')) {
                      maxAge = +e.target.value.split('< ')[1];
                    } else if (targetValue.includes('-')) {
                      const ageRangeValue = e.target.value.split('-');
                      minAge = +ageRangeValue[0];
                      maxAge = +ageRangeValue[1];
                    } else if (targetValue.includes('> ')) {
                      minAge = +e.target.value.split('> ')[1];
                      maxAge = 120;
                    } else {
                      minAge = 0;
                      maxAge = 120;
                    }

                    setFormData({ ...formData, min_age: minAge, max_age: maxAge });
                    // setFormData({
                    //   ...formData,
                    //   ageRange: e.target.name,
                    // });
                  }}
                >
                  {ageRanges.map((ageRange: any) => (
                    <Option
                      value={ageRange.label}
                      width="100%"
                      height="30px"
                      selected={formData.ageRange === ageRange.label}
                    >
                      {ageRange.label}
                    </Option>
                  ))}
                </Select>
                <div
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    margin: '1rem 0 0',
                  }}
                >
                  {t('marketer:create_job_campaign:note')} :{' '}
                  {t('marketer:create_job_campaign:select_age_influencers')}
                </div>
              </div>
            </FlexCol>

            <InfluencerChoiceComponent
              form={formData}
              setForm={setFormData}
              property={propertyInfluencer}
              toggle
            />

            {/* <FlexCol width="100px" style={{ margin: 'auto' }}>
              <div style={{ marginBottom: '2rem' }}>
                <CustomButton
                  handleClick={(e: any) => {
                    handleSubmit(e);
                  }}
                  size="s"
                  status="submit"
                  label={t('marketer:create_job_campaign:Submit')}
                />
              </div>
            </FlexCol> */}
          </div>
        </DivContent>
      </Card>
    </div>
  );
};

export default CreateMarketerPublicJobMock;
