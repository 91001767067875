import OwnCurrencyTypes from './actionTypes';

interface OwnCurrencyState {
  ownCurrencies: any[];
  marketerOwnCurrencies: any[];
}

export const INIT_STATE: OwnCurrencyState = {
  ownCurrencies: [],
  marketerOwnCurrencies: [],
};

const OwnCurrency = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case OwnCurrencyTypes.GET_FILTER_OWN_CURRENCY_SUCCESS:
      return {
        ...state,
        ownCurrencies: action.payload,
      };
    case OwnCurrencyTypes.GET_FILTER_MARKETER_OWN_CURRENCY_SUCCESS:
      return {
        ...state,
        marketerOwnCurrencies: action.payload,
      };
    default:
      return state;
  }
};

export default OwnCurrency;
