/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 *  interface for Login Type
 */
enum ProfileTypes {
  EDIT_PROFILE = '@@profile/EDIT_PROFILE',
  PROFILE_SUCCESS = '@@profile/PROFILE_SUCCESS',
  PROFILE_ERROR = '@@profile/PROFILE_ERROR',
  RESET_PROFILE_FLAG = '@@profile/RESET_PROFILE_FLAG',
}

export default ProfileTypes;
