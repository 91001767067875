import Pagination from '@mui/material/Pagination';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import user1 from 'src/assets/images/users/avatar-1.jpg';
import ShareIcon from '@mui/icons-material/Share';
import 'src/pages/AllPage.style.scss';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import ShowMoreText from 'react-show-more-text';
import logo from 'src/assets/images/flupower/logo-headpage.png';
import { useEffect, useState } from 'react';
import { getAllPostDataGql } from 'src/graphql/advertiser/socialMediaInfo';
import { OnLoading } from 'src/components/Common/Loading';
import SocialMediaType from 'src/constants/enum/socialMediaType';
import { Dialog } from '@mui/material';
import CustomButton from 'src/components/Common/CustomButton';

const ExampleInfluencerWork = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [sharePost, setSharePost] = useState('');
  const { isLoggedIn } = useSelector((state: any) => ({
    isLoggedIn: state.login.isLoggedIn,
  }));
  const { t } = useTranslation();

  const onGetPostData = async () => {
    const postData = await getAllPostDataGql({ page: 1, limit: 10 });
    setData(postData.items);
    setTotalCount(postData.total);
  };
  const handlePageChange = async (e: any, value: any) => {
    const newPage = value;
    const postData = await getAllPostDataGql({ page: newPage, limit: 10 });
    setData(postData.items);
    setTotalCount(postData.total);
  };
  const handleClose = () => {
    setIsOpenShare(false);
    setSharePost('');
  };
  useEffect(() => {
    onGetPostData();
  }, []);

  const SocialIcon = (social_media_type: any) => {
    switch (social_media_type) {
      case SocialMediaType.FACEBOOK:
        return facebookLogo;
      case SocialMediaType.INSTAGRAM:
        return instagramLogo;
      case SocialMediaType.TIKTOK:
        return tikTokLogo;
      case SocialMediaType.TWITTER:
        return twitterLogo;
      case SocialMediaType.YOUTUBE:
        return youtubeLogo;
      default:
        return '';
    }
  };
  if (!data) return <OnLoading />;
  // if (!isLoggedIn) window.location.replace(`/`);
  return (
    <>
      {!isLoggedIn && (
        <div
          style={{ display: 'flex', padding: '1.5rem', backgroundColor: '#1a1a1a' }}
        >
          <Link to="/" style={{ color: 'white' }}>
            <i className="mdi mdi-arrow-left" /> {t('Back To Homepage')}
          </Link>
        </div>
      )}
      <div
        className={isLoggedIn ? 'page-content' : ''}
        style={{ backgroundColor: '#1a1a1a' }}
      >
        {/* <Pagination
          count={Math.ceil(totalCount / 10)}
          color="standard"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
          boundaryCount={2}
        /> */}
        <div className="example-influencer-work-container" style={{ zIndex: 2 }}>
          {!isLoggedIn && (
            <div style={{ textAlign: 'center' }}>
              <img src={logo} alt="" width="200" className="mb-3" />
              <h5 style={{ color: 'white' }}>{t(`Portfolio of influencer's`)}</h5>
            </div>
          )}
          {data.map((item: any) => {
            if (!item?.post_images_url?.length && !item.caption) return <></>;
            return (
              <div className="card-post-container">
                <div className="post-header-container">
                  <div className="header-description-container">
                    <img
                      className="influencer-profile"
                      src={item.influencer_image_url || user1}
                      alt=""
                    />
                    {item?.post_images_url?.length ? (
                      <div className="header-text">
                        <>
                          <div
                            className="influencer-name"
                            style={{ fontSize: '18px' }}
                          >
                            {item.influencer_name}
                          </div>
                          <div className="post-description">
                            <ShowMoreText
                              lines={2}
                              more="See more"
                              less="See less"
                              className="content-css"
                              anchorClass="show-more-text-css"
                              expanded={false}
                              truncatedEndingComponent="... "
                            >
                              {item.caption}
                            </ShowMoreText>
                          </div>
                        </>
                      </div>
                    ) : (
                      <div className="influencer-name-lg-header">
                        <div className="influencer-name-lg">
                          {item.influencer_name}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="content-type">
                    <img
                      className="content-icon"
                      src={SocialIcon(item.social_media_type)}
                      alt=""
                    />
                  </div>
                </div>
                {item?.post_images_url?.length ? (
                  <Carousel
                    arrows
                    centerMode={false}
                    focusOnSelect={false}
                    infinite={false}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 1,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 1,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 1,
                      },
                    }}
                    showDots
                    slidesToSlide={1}
                  >
                    {item?.post_images_url.map((image: any) => (
                      <div
                        style={{
                          height: 'auto',
                          width: '100%',
                          textAlign: 'center',
                          background: '#DADADB',
                          borderTop: ' 1px solid rgb(218, 217, 217)',
                          borderBottom: ' 1px solid rgb(218, 217, 217)',
                        }}
                      >
                        <img src={image} alt="" className="post-image" />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className="lg-comment">
                    <p> {item.caption}</p>
                  </div>
                )}
                <div className="footer-container">
                  <div className="footer-description-container">
                    <img
                      className="company-profile"
                      src={item.marketer_image_url || user1}
                      alt=""
                    />
                    <div className="footer-text">
                      <div className="company-name" style={{ fontSize: '18px' }}>
                        {item.brand_name}
                      </div>
                      <div className="company-description">
                        <ShowMoreText
                          lines={2}
                          more="See more"
                          less="See less"
                          className="content-css"
                          anchorClass="show-more-text-css"
                          expanded={false}
                          truncatedEndingComponent="... "
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.product_detail,
                            }}
                          />
                        </ShowMoreText>
                      </div>
                    </div>
                  </div>
                  <div className="share-container">
                    <ShareIcon
                      style={{ cursor: 'pointer' }}
                      sx={{
                        '&:hover': {
                          transform: 'scale(1.25)',
                        },
                      }}
                      onClick={() => {
                        setIsOpenShare(true);
                        setSharePost(item.original_url);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <Dialog
            maxWidth="md"
            onClose={handleClose}
            open={isOpenShare}
            PaperProps={{
              style: {
                margin: '0 5px',
                padding: '20px 30px',
              },
            }}
          >
            <div className="title-modal-share">Copy and share this post</div>
            <div className="share-modal">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="link-share-box">{sharePost}</div>
                <div
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(sharePost);
                  }}
                >
                  <CustomButton status="submit" size="xxs" label={t('Copy')} />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        <Pagination
          count={Math.ceil(totalCount / 10)}
          color="standard"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
          boundaryCount={2}
        />
      </div>
    </>
  );
};
export default ExampleInfluencerWork;
