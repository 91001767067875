import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import { DepositAddressesTypes } from './actionTypes';

import {
  getDepositAddressesByUUIDFail,
  getDepositAddressesByUUIDSuccess,
  getDepositAddressesFail,
  getDepositAddressesSuccess,
} from './actions';

import {
  getDepositAddresses,
  getDepositAddressesByUUIDGql,
} from '../../../graphql/advertiser/depositAddress';

function* fetchDepositAddresses({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getDepositAddresses, payload);

    yield put(getDepositAddressesSuccess(response));
  } catch (error) {
    yield put(getDepositAddressesFail(error));
  }
}

function* fetchDepositAddressesByUUID({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getDepositAddressesByUUIDGql, payload);
    yield put(getDepositAddressesByUUIDSuccess(response));
  } catch (error) {
    yield put(getDepositAddressesByUUIDFail(error));
  }
}

function* depositAddressesSaga() {
  yield takeEvery(
    DepositAddressesTypes.GET_DEPOSIT_ADDRESSES,
    fetchDepositAddresses,
  );
  yield takeEvery(
    DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID,
    fetchDepositAddressesByUUID,
  );
}

export default depositAddressesSaga;
