import BannerFormat from 'src/constants/banner-format-Emun';
import highlightText from '../Styles/HighlightText';

const nbsp = (count: number, data: string) => (
  <>
    {[...Array(count)].map(() => (
      <>&nbsp;&nbsp;&nbsp;</>
    ))}
    {data}
    <br />
  </>
);

const ReactNativeContent = ({
  adKey,
  appKey,
  adType,
}: {
  adKey: string;
  appKey: string;
  adType: BannerFormat;
}) => {
  return adType === BannerFormat.IN_PAGE ? (
    <>
      {nbsp(0, 'const App: () => Node = () => {')}
      {nbsp(1, 'return (')}
      {nbsp(3, `<SafeAreaView style={{ backgroundColor: Colors.darker }}>`)}
      {nbsp(4, '<ScrollView')}
      {nbsp(5, 'contentInsetAdjustmentBehavior="automatic"')}
      {nbsp(5, 'style={{ backgroundColor: Colors.darker }}')}
      {nbsp(4, '>')}
      <span style={{ display: 'block', ...highlightText() }}>
        {nbsp(5, `<InPageAd appKey="${appKey}" adKey="${adKey}" />`)}
      </span>
      {nbsp(4, '</ScrollView>')}
      {nbsp(3, '</SafeAreaView>')}
      {nbsp(1, ');')}
      {nbsp(0, '};')}
    </>
  ) : (
    <>
      {nbsp(0, 'const App: () => Node = () => {')}
      {nbsp(1, 'return (')}
      {nbsp(2, '<SafeAreaView style={backgroundStyle}>')}
      {adType === BannerFormat.HEADER ? (
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(3, `<HeaderAd appKey="${appKey}" adKey="${adKey}" />`)}
        </span>
      ) : (
        nbsp(3, `{/* <HeaderAd appKey="appKeyExample" adKey="adKeyExample" /> */}`)
      )}
      {adType === BannerFormat.STICKY ? (
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(3, `<StickyAd appKey="${appKey}" adKey="${adKey}" />`)}
        </span>
      ) : (
        nbsp(3, `{/* <StickyAd appKey="${appKey}" adKey="${adKey}" /> */}`)
      )}
      {adType === BannerFormat.SLIDE ? (
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(3, `<SlideAd appKey="${appKey}" adKey="${adKey}" />`)}
        </span>
      ) : (
        nbsp(3, `{/* <SlideAd appKey="appKeyExample" adKey="adKeyExample" /> */}`)
      )}
      {nbsp(2, '<SafeAreaView/>')}
      {nbsp(1, ');')}
      {nbsp(0, '};')}
    </>
  );
};
export default ReactNativeContent;
