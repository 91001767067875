import MarketerCampaignTypes from './actionTypes';

export const createMarketerCampaign = (payload: any) => ({
  type: MarketerCampaignTypes.CREATE_MARKETER_CAMPAIGN,
  payload,
});

export const createMarketerCampaignSuccess = (payload: any) => ({
  type: MarketerCampaignTypes.CREATE_MARKETER_CAMPAIGN_SUCCESS,
  payload,
});

export const getMarketerCampaign = (key: string) => ({
  type: MarketerCampaignTypes.GET_MARKETER_CAMPAIGN,
  key,
});

export const getMarketerCampaignSuccess = (payload: any) => ({
  type: MarketerCampaignTypes.GET_MARKETER_CAMPAIGN_SUCCESS,
  payload,
});

export const updateMarketerCampaign = (payload: any) => ({
  type: MarketerCampaignTypes.UPDATE_MARKETER_CAMPAIGN,
  payload,
});

export const updateMarketerCampaignSuccess = (payload: any) => ({
  type: MarketerCampaignTypes.UPDATE_MARKETER_CAMPAIGN_SUCCESS,
  payload,
});

export const filterMarketerCampaign = (payload: any, campaignType?: string) => ({
  type: MarketerCampaignTypes.FILTER_MARKETER_CAMPAIGN,
  payload,
  campaignType,
});

export const filterMarketerCampaignSuccess = (
  payload: any,
  campaignType?: string,
) => ({
  type: MarketerCampaignTypes.FILTER_MARKETER_CAMPAIGN_SUCCESS,
  payload,
  campaignType,
});
