import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddHashtagCard = (props: any) => {
  const [userTitle, setUserName] = useState('');
  const { t } = useTranslation();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setUserName('');
  };
  const hashtag = '#';
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            type="text"
            name=""
            id="userName"
            className="form-control form-group-append"
            style={{ width: '90px' }}
            placeholder="Hashtag"
            onChange={(event) => {
              setUserName(event.target.value);
              event.preventDefault();
            }}
            value={userTitle}
          />
          <button
            type="submit"
            onClick={(event) => {
              props.add(hashtag + userTitle);
              setUserName('');
              event.preventDefault();
            }}
            className="collapse-hashtag"
          >
            {t('Add')}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddHashtagCard;
