import { call, put, takeEvery } from 'redux-saga/effects';
import {
  filterMarketerRequestOwnCurrencyGQL,
  filterRequestOwnCurrencyGQL,
} from 'src/graphql/advertiser/own-currency';
import OwnCurrencyTypes from './actionTypes';
import { setLoading, unsetLoading } from '../actions';
import {
  getFilterMarketerOwnCurrencySuccess,
  getFilterOwnCurrencySuccess,
} from './actions';

function* filterOwnCurrency({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(filterRequestOwnCurrencyGQL, payload);

    yield put(getFilterOwnCurrencySuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* filterMarketerOwnCurrency({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      filterMarketerRequestOwnCurrencyGQL,
      payload,
    );

    yield put(getFilterMarketerOwnCurrencySuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* own_currency() {
  yield takeEvery(OwnCurrencyTypes.GET_FILTER_OWN_CURRENCY, filterOwnCurrency);
  yield takeEvery(
    OwnCurrencyTypes.GET_FILTER_MARKETER_OWN_CURRENCY,
    filterMarketerOwnCurrency,
  );
}

export default own_currency;
