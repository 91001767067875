/* eslint-disable react/prop-types */
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout/index';

const AuthMiddleWare = ({
  route,
  showNav,
  isAuthProtected,
  preventRoles,
  role,
  preventOnLoggedIn,
  type,
}) => {
  const { isLoggedIn, user } = useSelector((state) => ({
    isLoggedIn: state.login.isLoggedIn,
    user: state.login.user,
  }));

  return (
    <Route
      exact
      key={`${route.path}-auth`}
      path={route.path}
      render={(props) => {
        if (isAuthProtected) {
          if (!user) {
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />;
          }

          if (!isLoggedIn || !user) {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            );
          }

          if (type === 'common') {
            const forbidden_by_prevent = preventRoles?.includes(user.role);
            if (forbidden_by_prevent) {
              return (
                <Redirect
                  to={{
                    pathname: '/forbidden',
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else {
            const forbidden_by_user_role = role ? user?.role !== role : true;

            if (forbidden_by_user_role) {
              return (
                <Redirect
                  to={{
                    pathname: '/forbidden',
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        }

        if (isLoggedIn && preventOnLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }

        if (type === 'auth') {
          if (showNav && isLoggedIn) {
            return (
              <Layout className="page-content">
                {typeof route.component === 'function' ? (
                  <route.component {...props} />
                ) : (
                  route.component
                )}
              </Layout>
            );
          }
          return <route.component {...props} />;
        }

        return (
          <Layout className="page-content">
            {typeof route.component === 'function' ? (
              <route.component {...props} />
            ) : (
              route.component
            )}
          </Layout>
        );
      }}
    />
  );
};

export default AuthMiddleWare;
