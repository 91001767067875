//react
import { useState } from 'react';
//react style
import {
  Container,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
//general api
import { useTranslation } from 'react-i18next';
//component
import menu from 'src/constants/menu';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import DeleteSocialMediaComponent from 'src/components/Admin/DeleteSocialMediaComponent';

const RequestDeleteSocialMedia = () => {
  //state variables
  const [tab, setTab] = useState('1');
  const { t }: any = useTranslation();
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Page.Request Delete Social Media')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.REQUEST_DELETE_SOCIAL_MEDIA, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${tab === '1' && 'tab-active-black'}`}
                      onClick={() => {
                        setTab('1');
                      }}
                    >
                      <h5>{t('swal:Pending Approval')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${tab === '2' && 'tab-active-black'}`}
                      onClick={() => {
                        setTab('2');
                      }}
                    >
                      <h5>{t('swal:All Approval')}</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                {tab === '1' && (
                  <DeleteSocialMediaComponent verifyStatus="PENDING" />
                )}

                {tab === '2' && <DeleteSocialMediaComponent />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestDeleteSocialMedia;
