import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { transformStringAmountInput } from '../../../utils/input';
import CoinImage from '../../Common/CoinImage';
import { splitUnderscoreWithTuple } from '../../../utils/currency';
import { logDate } from '../../../utils/dateTime';

const CryptoBalanceLogDetailModal = ({
  data,
  t,
  isOpen,
  handleToggle,
  handleClose,
  title,
}: any) => {
  const renderCryptoAmount = () => {
    const htmls: any = [];

    if (!isEmpty(data.crypto_amount)) {
      // eslint-disable-next-line no-shadow
      data.crypto_amount.map((data: any) => {
        htmls.push(
          <tr>
            <th className="text-nowrap" scope="row">
              {data.symbol}
            </th>
            <td>
              <div className="d-flex align-items-center">
                <span className="m">
                  {transformStringAmountInput(data.CRYPTO_amount)}
                </span>
                <span className="mx-2">
                  ($
                  {transformStringAmountInput(data.FIAT_amount)})
                </span>
                <CoinImage size="sm" symbol={data.symbol} />
                <span className="mx-2">{data.symbol}</span>
              </div>
            </td>
            <td>
              ${transformStringAmountInput(data.FIAT_rate)} (
              {splitUnderscoreWithTuple(data.symbol)}-USD)
            </td>
          </tr>,
        );

        return true;
      });
    }

    return htmls;
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title} : &nbsp;
          {data && (
            <>
              <span className="mx-2">
                {logDate(data.day, data.month, data.year)}
              </span>
              <span>(${data.amount})</span>
            </>
          )}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <div className="table-responsive">
          <table className="table table-nowrap mb-0 table-bordered">
            <thead>
              <tr>
                <th>{t('table:Symbol')}</th>
                <th>{t('table:Amount')}</th>
                <th>{t('table:Fiat Rate')}</th>
              </tr>
            </thead>
            {data && <tbody>{renderCryptoAmount()}</tbody>}
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(CryptoBalanceLogDetailModal);
