/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum Role {
  ADVERTISER = 'ADVERTISER',
  PUBLISHER = 'PUBLISHER',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  MARKETER = 'MARKETER',
  INFLUENCER = 'INFLUENCER',
}

export default Role;
