import React from 'react';
import { Col, Row, FormGroup, Label, UncontrolledAlert } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { forgotPassword } from '../../store/auth/forgotpwd/actions';
import i18n from '../../i18n';

const initialValues = {
  email: '',
};

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('invalid.email must be a valid email'))
    .required(i18n.t('invalid.Required')),
});

const ForgotPasswordForm = ({ t, history }: any) => {
  const dispatch = useDispatch();

  const { error, message } = useSelector((state: any) => ({
    error: state.forgotPassword.error,
    message: state.forgotPassword.message,
  }));

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    setSubmitting(true);

    const data = {
      ...values,
    };

    resetForm();
    dispatch(forgotPassword(data, history));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={loginSchema}
        render={({ errors, touched }) => (
          <FormikForm>
            <Row>
              <Col md="12">
                {error && message && (
                  <UncontrolledAlert color="danger" className="alert">
                    {message}
                  </UncontrolledAlert>
                )}
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="email" className="input-label">
                    {t('Form.Email')}
                  </Label>
                  <Field
                    name="email"
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email ? 'is-invalid' : ''
                    }`}
                    id="email"
                    placeholder={t('Form.Email Placeholder')}
                  />

                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-message"
                  />
                </FormGroup>
              </Col>
            </Row>

            <button
              className="btn btn-primary w-100 waves-effect waves-light"
              type="submit"
            >
              {t('Button.Forgot Password')}
            </button>
          </FormikForm>
        )}
      />
    </>
  );
};
export default withTranslation()(withRouter(ForgotPasswordForm));
