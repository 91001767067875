import Role from 'src/constants/enum/role';
import RegisterTypes from './actionTypes';

export const registerUser = (data: any, history: any) => {
  return {
    type: RegisterTypes.REGISTER_USER,
    payload: { data, history },
  };
};

export const registerUserSuccessful = (user: any) => {
  return {
    type: RegisterTypes.REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (errorMessage: any) => {
  return {
    type: RegisterTypes.REGISTER_USER_FAILED,
    payload: errorMessage,
  };
};

export const verifyEmail = (data: any, history: any, role: any) => {
  return {
    type: RegisterTypes.VERIFY_EMAIL,
    payload: { data, history, role },
  };
};

export const verifyEmailSuccessful = () => {
  return {
    type: RegisterTypes.VERIFY_EMAIL_SUCCESSFUL,
    payload: null,
  };
};

export const verifyEmailFailed = (errorMessage: any) => {
  return {
    type: RegisterTypes.VERIFY_EMAIL_FAILED,
    payload: errorMessage,
  };
};

export const setRegisterThirdPartyData = (payload: {
  role: Role | string;
  timezone: string;
  ref?: string | null;
}) => ({
  type: RegisterTypes.REGISTER_THIRD_PARTY,
  payload,
});

export const clearRegisterThirdPartyData = () => ({
  type: RegisterTypes.CLEAR_REGISTER_THIRD_PARTY,
});
