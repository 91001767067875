import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Collapse } from 'reactstrap';
import config from 'src/config';
import paramsFromURL from 'src/constants/paramsFromURL';
import { removeSocialJobImageByKey } from 'src/graphql/advertiser/socialMediaInfo';
import { getMarketerJob, getSocialJob } from 'src/store/actions';
import useImageLoaded from 'src/utils/useImageLoaded';
import {
  ButtonStyle,
  FlexRow,
  NumberStyle,
  FlexCol,
  Texts,
  CheckIcon,
  HeaderCollapse,
  Title,
  Timeline,
  TimelineContainer,
  TimelineContent,
  TransFromHtmlString,
} from './influencerJobTimeLineDetail.style';

const influencerJobTimeLineDetail = () => {
  const dispatch = useDispatch();
  const [jobId, socialJobId]: any = paramsFromURL(['job', 'detail']);
  const [ref, loaded, onLoad] = useImageLoaded();
  const { t }: any = useTranslation();

  const { socialJobItem, marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
    socialJobItem: state.SocialMediaInfo.socialJobItem,
  }));

  const [formSubmit, setFormSubmit] = useState<any>();
  const [collapse, setCollapse] = useState({
    collapse1: false,
    collapse2: false,
    collapse3: false,
    collapse4: false,
  });

  const [exampleMessage, setExampleMessage] = useState('');

  const workOrderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (workOrderRef.current) {
      workOrderRef.current.innerHTML = marketerJob?.work_order;
    }
  }, [workOrderRef.current, marketerJob]);

  const deleteUploadedImage = async (key: string) => {
    await removeSocialJobImageByKey(key);
    dispatch(getSocialJob(socialJobId));
  };
  useEffect(() => {
    if (!Object.keys(socialJobItem)?.length) return;
    setExampleMessage(socialJobItem.example_message);
    setFormSubmit({ ...formSubmit, submit_job_link: socialJobItem.link_post });

    if (!socialJobItem.accept_date) {
      setCollapse({ ...collapse, collapse1: true });
      return;
    }
    if (!socialJobItem.influencer_submit_date) {
      setCollapse({ ...collapse, collapse2: true });
      return;
    }
    if (!socialJobItem.post_date) {
      setCollapse({ ...collapse, collapse3: true });
      return;
    }

    setCollapse({ ...collapse, collapse4: true });
  }, [socialJobItem]);

  useEffect(() => {
    dispatch(getMarketerJob(jobId));
    dispatch(getSocialJob(socialJobId));
  }, [jobId]);

  const ImagePreview = () => {
    const [index, setIndex] = useState(0);
    return (
      <>
        <div
          style={{
            border: '1px solid rgb(148, 157, 157)',
            borderRadius: '8px',
            margin: '10px 0',
            padding: '10px auto',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <i
              className="fas fa-trash"
              style={{
                border: '2px solid red',
                borderRadius: '8px',
                color: 'red',
                padding: '1rem',
                fontSize: '1.5rem',
                position: 'absolute',
                top: '5px',
                right: '5px',
                backgroundColor: 'white',
                cursor: 'pointer',
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              }}
              onClick={() => {
                deleteUploadedImage(socialJobItem?.image_storages[index]?.key);
              }}
            />
            <i
              className="fas fa-chevron-right"
              style={{
                border: '2px solid #3baf96',
                borderRadius: '8px',
                color: '#3baf96',
                padding: '1rem',
                fontSize: '1.5rem',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '5px',
                backgroundColor: 'white',
                cursor:
                  index + 1 < socialJobItem?.image_storages?.length
                    ? 'pointer'
                    : 'no-drop',
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              }}
              onClick={() => {
                if (index + 1 < socialJobItem?.image_storages?.length)
                  setIndex((current) => current + 1);
              }}
            />
            <i
              className="fas fa-chevron-left"
              style={{
                border: '2px solid #3baf96',
                borderRadius: '8px',
                color: '#3baf96',
                padding: '1rem',
                fontSize: '1.5rem',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '5px',
                backgroundColor: 'white',
                cursor:
                  index !== 0 && index + 1 <= socialJobItem?.image_storages?.length
                    ? 'pointer'
                    : 'no-drop',
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              }}
              onClick={() => {
                if (
                  index !== 0 &&
                  index + 1 <= socialJobItem?.image_storages?.length
                )
                  setIndex((current) => current - 1);
              }}
            />
            <div
              style={{
                width: '100%',
                position: 'absolute',
                bottom: '5px',
                display: 'flex',
                justifyContent: 'center',
                gap: '0.2rem',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: 'fit-content',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                  padding: '0.2rem 0.5rem',
                  lineHeight: '100%',
                }}
              >
                {socialJobItem?.image_storages.map(
                  (value: any, indexItem: number) => {
                    return (
                      <i
                        className={
                          index === indexItem ? 'fas fa-circle' : 'far fa-circle'
                        }
                        style={{
                          margin: '0 5px',
                          color: '#3baf96',
                          fontSize: '1.5rem',
                        }}
                      />
                    );
                  },
                )}
              </div>
            </div>

            {!loaded && (
              <div
                className="skeleton"
                style={{
                  maxHeight: '500px',
                  maxWidth: '500px',
                  height: '100%',
                  width: '100%',
                  marginLeft: index !== 0 ? '10px' : '',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                }}
              />
            )}

            <a
              href={`${config.backendUrl}api/socialJob/${socialJobItem?.image_storages[index]?.key}`}
              target="_blank"
              rel="noreferrer"
              style={{ height: '100%', width: '100%' }}
            >
              <img
                key={socialJobItem?.image_storages[index]?.key}
                src={`${config.backendUrl}api/socialJob/${socialJobItem?.image_storages[index]?.key}`}
                ref={ref}
                onLoad={onLoad}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                }}
                alt="job examination"
              />
            </a>
          </div>
        </div>
      </>
    );
  };

  if (!marketerJob || !Object.keys(socialJobItem)?.length) return <></>;
  // if (!Object.keys(marketerJob).length) return <></>;
  const timelineViewData = [
    {
      date: marketerJob?.date_submission,
      dataString: 'send job to influencer',
    },
    {
      date: marketerJob?.last_day_accept_job,
      dataString: 'influencer accept job',
    },
    {
      date: marketerJob?.last_day_influencer_submit,
      dataString: 'Last day influencer submit work for review',
    },
    {
      date: marketerJob?.last_day_to_approve,
      dataString: 'Last day review and approve',
    },
    {
      date: marketerJob?.influencer_post_internal_job,
      dataString: 'influencer submit job',
    },
    {
      date: marketerJob?.finish_internal_work,
      dataString: 'state completed',
    },
  ];

  return (
    <div className="page-content">
      {!marketerJob.isDisableEdit && marketerJob && (
        <>
          <div className="text-center">
            <h1 className="d-inline-block">1 -</h1>
            <h1 className="d-inline-block ms-2">2 - </h1>
            <h1
              className="d-inline-block ms-2"
              style={{
                borderRadius: '50%',
                border: '1px solid',
                width: '40px',
                height: '40px',
              }}
            >
              3
            </h1>
          </div>
          <h3 className="text-center">{t('Recheck your job details')}</h3>
        </>
      )}

      <Card
        className="mt-4 w-50 m-auto p-3"
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset',
        }}
      >
        <FlexRow
          justifyContent="space-between"
          onClick={() =>
            setCollapse({ ...collapse, collapse1: !collapse.collapse1 })
          }
          style={{ cursor: 'pointer' }}
        >
          <FlexRow>
            <div style={{ position: 'relative' }}>
              {socialJobItem?.accept_date && (
                <CheckIcon>
                  <i className="fa fa-check" />
                </CheckIcon>
              )}
              <NumberStyle>1</NumberStyle>
            </div>
            <HeaderCollapse>{t('marketer:job_detail:New Job')}</HeaderCollapse>
          </FlexRow>

          <ButtonStyle
            type="button"
            onClick={() =>
              setCollapse({ ...collapse, collapse1: !collapse.collapse1 })
            }
          >
            <i className="fas fa-caret-down" />
          </ButtonStyle>
        </FlexRow>

        <Collapse isOpen={collapse.collapse1}>
          <div className="row" style={{ padding: '1rem 1.5rem' }}>
            <div className="col-12 col-md-6">
              <Title
                style={{
                  // color: '#5D6D6D',
                  // margin: '-5px auto 0 auto',
                  width: '120px',
                  // height: '35px',
                  // border: 'none',
                  // background: 'red',
                }}
              >
                {t('marketer:job_detail:Campaign name')}
              </Title>

              <div className="row">
                <h4
                  style={{
                    color: '#5D6D6D',
                    // margin: '-10px auto 0 auto',
                    maxWidth: '150px',
                    // backgroundColor: 'red',
                  }}
                >
                  {marketerJob?.marketer_campaign?.name}
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <Title>{t('marketer:job_detail:Product name')}</Title>
              <div className="row">
                <h4
                  style={{
                    color: '#5D6D6D',
                    // margin: '-10px auto 0 auto',
                    maxWidth: '150px',
                    // backgroundColor: 'red',
                  }}
                >
                  {marketerJob?.marketer_campaign?.product_name}
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <Title>{t('marketer:job_detail:About your brand/company')}</Title>
              <h4 style={{ color: '#5D6D6D', margin: '-10px auto 20px auto' }}>
                {marketerJob?.marketer_campaign?.product_detail}
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <Title>{t('marketer:job_detail:Job name')}</Title>
              <h4 style={{ color: '#5D6D6D', margin: '-10px auto 20px auto' }}>
                {marketerJob?.name}
              </h4>
            </div>
            <div className="col-12 col-md-6">
              <Title>{t('marketer:job_detail:#Hashtag')}</Title>
              <div className="row">
                <h4
                  style={{
                    color: '#5D6D6D',
                    margin: '-10px 0 20px 0',
                    maxWidth: '150px',
                    // backgroundColor: 'red',
                    textAlign: 'start',
                  }}
                >
                  {marketerJob?.hashtag}
                </h4>
              </div>
            </div>
            <div className="col-12">
              <Title>{t('marketer:job_detail:Brief to influencer')}</Title>
              <TransFromHtmlString>
                <div ref={workOrderRef} />
              </TransFromHtmlString>
            </div>
            <Title style={{ marginTop: '1.5rem' }}>
              {t('marketer:job_detail:Job timeline')}
            </Title>
            <FlexCol style={{ justifyContent: 'start' }}>
              <Timeline>
                {timelineViewData.map((item) => {
                  const date = new Date(item?.date).toDateString();
                  const isSameOrAfter =
                    new Date(new Date().toDateString()) >=
                    new Date(new Date(date).toDateString());
                  return (
                    <TimelineContainer
                      borderColor={isSameOrAfter ? '#04ffc9' : '#A2B5BB'}
                      backgroundColor={isSameOrAfter ? '#04ffc9' : '#A2B5BB'}
                    >
                      <TimelineContent>
                        <Texts
                          height="15px"
                          margin="0 auto 0 auto"
                          fontsize="1.2rem"
                          color="#5D6D6D"
                        >
                          {date.slice(8, 10)} {date.slice(3, 8)}
                        </Texts>
                        <h5 style={{ color: '#5D6D6D' }}>
                          {t(`marketer:job_detail:${item.dataString}`)}
                        </h5>
                      </TimelineContent>
                    </TimelineContainer>
                  );
                })}
              </Timeline>
            </FlexCol>
          </div>
        </Collapse>
        <hr />
        <FlexRow
          justifyContent="space-between"
          onClick={() =>
            setCollapse({ ...collapse, collapse2: !collapse.collapse2 })
          }
          style={{ cursor: 'pointer' }}
        >
          <FlexRow>
            <div style={{ position: 'relative' }}>
              {socialJobItem?.influencer_submit_date && (
                <CheckIcon>
                  <i className="fa fa-check" />
                </CheckIcon>
              )}
              <NumberStyle>2</NumberStyle>{' '}
            </div>
            <HeaderCollapse>
              {t('marketer:job_detail:Send for examination')}
            </HeaderCollapse>
          </FlexRow>
          <ButtonStyle
            type="button"
            onClick={() =>
              setCollapse({ ...collapse, collapse3: !collapse.collapse3 })
            }
          >
            <i className="fas fa-caret-down" />
          </ButtonStyle>
        </FlexRow>
        <Collapse
          isOpen={collapse.collapse2 && socialJobItem?.accept_date}
          style={{ padding: '1rem 1.5rem' }}
        >
          <label
            htmlFor="description"
            style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
          >
            {t('marketer:job_detail.description')}
          </label>
          <textarea
            name="description"
            id="description"
            onChange={(e: any) => {
              setExampleMessage(e.target.value);
            }}
            placeholder={t('marketer:job_detail.Enter your description it here.')}
            value={exampleMessage}
            style={{
              width: '100%',
              height: '100px',
              borderRadius: '5px',
              border: '1px solid #949d9d',
              padding: '10px 20px',
              outline: 'none',
            }}
            readOnly
          />
          {socialJobItem.image_storages?.length !== 0 ? <ImagePreview /> : <></>}
        </Collapse>
        <hr />
        <FlexRow
          justifyContent="space-between"
          onClick={() =>
            setCollapse({ ...collapse, collapse3: !collapse.collapse3 })
          }
          style={{ cursor: 'pointer' }}
        >
          <FlexRow>
            <div style={{ position: 'relative' }}>
              {socialJobItem?.post_date && (
                <CheckIcon>
                  <i className="fa fa-check" />
                </CheckIcon>
              )}
              <NumberStyle>3</NumberStyle>
            </div>
            <HeaderCollapse>
              {t('marketer:job_detail:Submit Your Post Link')}
            </HeaderCollapse>
          </FlexRow>
          <ButtonStyle
            type="button"
            onClick={() =>
              setCollapse({ ...collapse, collapse3: !collapse.collapse3 })
            }
          >
            <i className="fas fa-caret-down" />
          </ButtonStyle>
        </FlexRow>
        <Collapse
          isOpen={collapse.collapse3 && socialJobItem?.influencer_submit_date}
          style={{ padding: '1rem 1.5rem' }}
        >
          {!socialJobItem?.post_date ? (
            <p
              style={{
                color: 'red',
                fontSize: '2em',
                fontWeight: 'bold',
                textAlign: 'center',
                margin: '1rem auto',
              }}
            >
              **รอ Marketer ตรวจงาน
            </p>
          ) : (
            <div style={{ fontSize: '2em', width: '100%', textAlign: 'center' }}>
              <a href={formSubmit?.submit_job_link} target="_blank" rel="noreferrer">
                {formSubmit?.submit_job_link}
              </a>
            </div>
          )}
        </Collapse>
        <hr />
        <FlexRow
          justifyContent="space-between"
          onClick={() =>
            setCollapse({ ...collapse, collapse4: !collapse.collapse4 })
          }
          style={{ cursor: 'pointer' }}
        >
          <FlexRow>
            <div style={{ position: 'relative' }}>
              {socialJobItem?.end_date && (
                <CheckIcon>
                  <i className="fa fa-check" />
                </CheckIcon>
              )}
              <NumberStyle>4</NumberStyle>
            </div>
            <HeaderCollapse>
              {t('marketer:job_detail:Collect statistics and earn money')}
            </HeaderCollapse>
          </FlexRow>
          <ButtonStyle
            type="button"
            onClick={() =>
              setCollapse({ ...collapse, collapse4: !collapse.collapse4 })
            }
          >
            <i className="fas fa-caret-down" />
          </ButtonStyle>
        </FlexRow>
        <Collapse isOpen={collapse.collapse4 && socialJobItem?.post_date}>
          <FlexRow style={{ textAlign: 'start' }}>
            <Texts
              fontsize="1.5rem"
              margin="0 10px"
              fontWeight="bold"
              style={{ minWidth: '200px' }}
            >
              {t('marketer:job_detail:Job Submit')} :
            </Texts>
            <Texts fontsize="1.5rem" margin="0 10px">
              {new Date(socialJobItem?.accept_date).toLocaleDateString()}
            </Texts>
          </FlexRow>
          <FlexRow>
            <Texts
              fontsize="1.5rem"
              margin="0 10px"
              fontWeight="bold"
              style={{ textAlign: 'start', minWidth: '200px' }}
            >
              {t('marketer:job_detail:Influencer Accept')} :
            </Texts>
            <Texts fontsize="1.5rem" margin="0 10px">
              {new Date(socialJobItem?.accept_date).toLocaleDateString()}
            </Texts>
          </FlexRow>
          <FlexRow>
            <Texts
              fontsize="1.5rem"
              margin="0 10px"
              fontWeight="bold"
              style={{ textAlign: 'start', minWidth: '200px' }}
            >
              {t('marketer:job_detail:Influencer Post')} :
            </Texts>
            <Texts fontsize="1.5rem" margin="0 10px">
              {new Date(socialJobItem?.post_date).toLocaleDateString()}
            </Texts>
          </FlexRow>
          <FlexRow>
            <Texts
              fontsize="1.5rem"
              margin="0 10px"
              fontWeight="bold"
              style={{ textAlign: 'start', minWidth: '200px' }}
            >
              {t('marketer:job_detail:Job End')} :
            </Texts>
            <Texts fontsize="1.5rem" margin="0 10px">
              {socialJobItem?.end_date
                ? new Date(socialJobItem?.end_date)?.toLocaleDateString()
                : 'ยังไม่มีข้อมูล'}
            </Texts>
          </FlexRow>
          <FlexRow>
            <Texts
              fontsize="1.5rem"
              margin="0 10px"
              fontWeight="bold"
              style={{ textAlign: 'start', minWidth: '200px' }}
            >
              {t('marketer:job_detail:Accept Money')} :
            </Texts>
            <Texts fontsize="1.5rem" margin="0 10px">
              {socialJobItem?.status === 'FINISHED'
                ? socialJobItem?.price
                : 'ยังไม่มีข้อมูล'}
            </Texts>
          </FlexRow>
        </Collapse>
      </Card>
    </div>
  );
};

export default influencerJobTimeLineDetail;
