import i18n from 'src/i18n';
import NavData from '../type/navData.type';

const publisherNavData: NavData = {
  role: 'Publisher',
  title: 'Publisher',
  items: [
    {
      label: i18n.t('influencer:My Website'),
      description: i18n.t('influencer:Earn money from your website with ads.'),
      path: '/mywebsite',
      icon: <i className="fas fa-window-maximize" />,
    },
    {
      label: i18n.t('influencer:My Application'),
      description: i18n.t('influencer:Earn money from your mobile apps with ads.'),
      path: '/myApplication',
      icon: <i className="fas fa-mobile-alt" />,
    },
  ],
};
export default publisherNavData;
