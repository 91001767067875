import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import WithdrawTransactionsTable from '../../components/Admin/WithdrawTransactionsTable';
import menu from '../../constants/menu';

const WithdrawTransactionPage = ({ t }: any) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(1);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Form Validation | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.Withdraw Transactions')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.WITHDRAW_TRANSACTIONS, active: true },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(1);
                        }}
                        className={`${tab === 1 && 'tab-active-black'}`}
                      >
                        <h5>{t('Menus.Pending Requests')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(2);
                        }}
                        className={`${tab === 2 && 'tab-active-black'}`}
                      >
                        <h5>{t('Menus.All Requests')}</h5>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <WithdrawTransactionsTable tab={tab} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(WithdrawTransactionPage);
