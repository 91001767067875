import styled from 'styled-components';
import './GleamJob.style.scss';
import { calculateDayLeft } from 'src/utils/dateTime';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col } from 'reactstrap';

import GleamJobTaskInfluencer from 'src/components/Common/GleamJobTaskInfluencer';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  InfluencerContentType,
  InfluencerSocialIcon,
} from './status/InfluencerJobStatus';

const GleamJobTaskHeaderInfluencer = ({ jobTask }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [jobId, tasked, key] = paramsFromURL(['job', 'task', 'key']);
  // eslint-disable-next-line no-unneeded-ternary
  const [isTaskOpen, setisTaskOpen] = useState(key === null ? false : true);
  const [disableChat, setDisableChat] = useState<boolean>(false);

  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));

  useEffect(() => {
    const dayLeft = calculateDayLeft(marketerJob?.finish_internal_work);
    if (dayLeft === null) {
      setDisableChat(true);
    } else {
      if (dayLeft > 0) {
        setDisableChat(false);
      } else {
        setDisableChat(true);
      }
    }
  }, [marketerJob]);

  const backgroundColorStatus = (status: string) => {
    switch (status) {
      case 'DRAFT':
        return '#979797';
      case 'NEW_JOB':
      case 'INPROGRESS':
        return 'rgb(83, 198, 183)';
      case 'FINISHED':
        return 'rgb(34, 148, 237)';
      case 'CANCEL':
        return '#ff7784';
      case 'SUSPENDED':
        return '#ffbb54';
      case 'DECLINE':
        return '#ff7784';
      default:
        return '';
    }
  };

  const rendorStatus = () => {
    const { socialHasJob } = jobTask;
    let status = 'CANCEL';
    for (let i = 0; i < socialHasJob?.length; i++) {
      if (socialHasJob[i].status !== 'CANCEL') {
        if (status !== 'SUSPENDED') {
          status = socialHasJob[i].status;
        }
      }
    }
    let status_txt = status;
    if (status === 'DRAFT') {
      status_txt = t('PENDING');
    } else if (status === 'INPROGRESS') {
      status_txt = t('Approve');
    } else if (status === 'DECLINE') {
      status_txt = t('Decline');
    } else if (status === 'CANCEL') {
      status_txt = t('Rejected');
    }

    return (
      <div
        className="gleam-job-status center"
        style={{
          width: '250px',
          backgroundColor: `${backgroundColorStatus(status)}`,
        }}
      >
        <b>{t(status_txt)}</b>
      </div>
    );
  };

  const renderChat = (unreadMessage: number) => {
    if (unreadMessage > 0) {
      if (unreadMessage > 9) {
        return (
          <>
            <div
              className="notification-icon"
              style={{ margin: 'auto', width: '24px', height: '24px' }}
            >
              9
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              className="notification-icon"
              style={{ margin: 'auto', width: '24px', height: '24px' }}
            >
              {unreadMessage}
            </div>
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <div style={{ marginBottom: '11px' }}>
      <div
        className="gleam-job-task-container"
        style={{
          cursor: 'default',
          borderRadius: '6px',
          paddingBottom: '0',
        }}
      >
        <div
          className={
            isTaskOpen ? 'gleam-job-task-header-open' : 'gleam-job-task-header'
          }
          style={{ position: 'relative' }}
          onClick={() => {
            setisTaskOpen(!isTaskOpen);
          }}
        >
          <div className="gleam-jobs-task-header  " style={{ alignItems: 'center' }}>
            <div className="col-v-center col-center" style={{ paddingLeft: '16px' }}>
              <InfluencerSocialIcon
                social_media_type={
                  jobTask?.socialHasJob[0]?.job_task.task_type?.socialmedia_type
                }
                size={24}
              />
              {renderChat(jobTask?.unreadMessage)}
            </div>
            <div>
              <div className="campaign-name-title" style={{ textAlign: 'center' }}>
                {jobTask?.social_info?.social_media_type === 'NOSOCIAL'
                  ? ''
                  : jobTask?.social_info?.name}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{rendorStatus()}</div>
              <div style={{ cursor: 'pointer' }}>
                {isTaskOpen ? (
                  <i
                    className="fa fa-chevron-down fa-icon"
                    style={{ paddingRight: '0 0 0 16px' }}
                  />
                ) : (
                  <i
                    className="fa fa-chevron-right fa-icon"
                    style={{ paddingRight: '0 0 0 16px' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <GleamJobTaskDetail isOpen={isTaskOpen}>
          <div className="gleam-job-task-detail" style={{ paddingBottom: '15px' }}>
            {jobTask?.socialHasJob.map((task: any) => (
              <GleamJobTaskInfluencer job_tasks={task} />
            ))}
            {key === null && !disableChat && (
              <div
                style={{
                  padding: '6px',
                  // border: '1px solid',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  width: 'fit-content',
                  // borderRadius: '5px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/campaign/JobDetail/CampaignChat?job=${jobId}&task=${tasked}&key=${jobTask?.social_info?.key}`,
                  );
                }}
              >
                <i className="fa fa-comments" aria-hidden="true" />{' '}
                {t('Contact  marketer')}
              </div>
            )}
          </div>
        </GleamJobTaskDetail>
      </div>
    </div>
  );
};

const GleamJobTaskDetail = styled.div<{ isOpen: boolean }>`
  // border: #e9e9ef 1px solid;
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  border
`;

export default GleamJobTaskHeaderInfluencer;
