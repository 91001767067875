import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialJob } from 'src/store/actions';
import { useHistory } from 'react-router';
import { updateSocialMediaHasJobGql } from 'src/graphql/advertiser/socialMediaInfo';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { OnLoading } from 'src/components/Common/Loading';
import JobDetailImagePreview from 'src/components/Publisher/Influencer/JobDetail/jobDetailImagePreview';
import Swal from 'sweetalert2';

// Assets
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import choice from 'src/assets/images/choice.png';

// Styles And Control
import ShowMoreText from 'react-show-more-text';
import { useTranslation } from 'react-i18next';
import paramsFromURL from 'src/constants/paramsFromURL';
import CustomButton from 'src/components/Common/CustomButton';
import controls from './InfluencerPostDetail.control';
import styles from './InfluencerPostDetail.style';
import 'react-multi-carousel/lib/styles.css';

const {
  CardContainer,
  ChannelLogo,
  InfluencerPostDetailContainer,
  Profile,
  NameUser,
  MessagePost,
} = styles();

//Fix Params
const InfluencerPostDetailPage = () => {
  controls();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [marketerMessage, setMarketerMessage] = useState('');
  const history = useHistory();
  const [key, jobKey]: any = paramsFromURL(['detail', 'job']);
  const socialJobItem = useSelector(
    (state: any) => state.SocialMediaInfo.socialJobItem,
  );
  // Effects
  useEffect(() => {
    dispatch(getSocialJob(key));
    setMarketerMessage(socialJobItem?.example_message);
  }, [key, socialJobItem?.example_message]);
  // Functions

  const onApproveInfluencerPost = async () => {
    const res: any = {
      key,
      approve_image_keys: selectedImages,
      marketer_massage: marketerMessage,
      marketer_approve_date: new Date(),
    };
    if (res.approve_image_keys?.length > 0) {
      await updateSocialMediaHasJobGql(res);
      if (res) {
        dispatch(getSocialJob(key));
        notify(i18n.t('success:Job has approved.'), 'success');
        history.push(`/Marketer/JobDetail?job=${jobKey}`);
      } else notify(i18n.t('error:Job has not approve .'), 'error');
    } else
      notify(
        i18n.t('error:Job has not approve please select post image .'),
        'error',
      );
  };

  const onRejectInfluencerPost = async () => {
    const res: any = await updateSocialMediaHasJobGql({
      key,
      status: 'CANCEL',
    });
    if (res) {
      dispatch(getSocialJob(key));
      notify(i18n.t('success:Job has rejected.'), 'success');
      history.push(`/Marketer/JobDetail?job=${jobKey}`);
    } else notify(i18n.t('error:Job has not reject .'), 'error');
  };

  // eslint-disable-next-line no-unused-vars
  const SocialContent = (social_media_type: any) => {
    switch (socialJobItem?.social_media_info?.social_media_type) {
      case 'FACEBOOK':
        return t('marketer:job_detail.Post caption');
      case 'INSTAGRAM':
        return t('marketer:job_detail.Post caption');
      case 'TIKTOK':
        return t('marketer:job_detail.Video description');
      case 'TWITTER':
        return t('marketer:job_detail.Post caption');
      case 'YOUTUBE':
        return t('marketer:job_detail.Video description');
      default:
        return t('marketer:job_detail.Post caption');
    }
  };
  // eslint-disable-next-line no-unused-vars
  const SocialIcon = (social_media_type: any) => {
    switch (socialJobItem?.social_media_info?.social_media_type) {
      case 'FACEBOOK':
        return facebookLogo;
      case 'INSTAGRAM':
        return instagramLogo;
      case 'TIKTOK':
        return tikTokLogo;
      case 'TWITTER':
        return twitterLogo;
      case 'YOUTUBE':
        return youtubeLogo;
      default:
        return tikTokLogo;
    }
  };
  // View
  // const ImagePreview = () => {
  //   return <></>;
  // };
  if (!socialJobItem) return <OnLoading />;
  return (
    <div className="page-content">
      <InfluencerPostDetailContainer>
        <CardContainer>
          <Profile
            src={socialJobItem?.social_media_info?.image_profile_url}
            alt="Influencer Profile"
            className="skeleton"
            onLoad={(e) => {
              e.currentTarget.classList.remove('skeleton');
            }}
            onError={(e) => {
              e.currentTarget.classList.remove('skeleton');
              e.currentTarget.src = userAvatar;
            }}
          />
          <ChannelLogo
            src={`${SocialIcon(
              socialJobItem?.social_media_info?.social_media_type,
            )}`}
            alt="Channel Logo"
            className="skeleton"
            onLoad={(e) => {
              e.currentTarget.classList.remove('skeleton');
            }}
            onError={(e) => {
              e.currentTarget.classList.remove('skeleton');
              e.currentTarget.src = facebookLogo;
            }}
          />
          <NameUser className="skeleton-text">
            {socialJobItem?.social_media_info?.name}
          </NameUser>
          <MessagePost>
            {socialJobItem?.image_storages?.length > 0 &&
            !socialJobItem?.marketer_approve_date ? (
              <>
                <label
                  htmlFor="select photo"
                  // style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                >
                  <span style={{ color: 'red' }}> * </span>
                  {t(
                    'marketer:job_detail.Please select the photo that you want influencer to post',
                  )}
                </label>
                <JobDetailImagePreview
                  items={socialJobItem}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                />
              </>
            ) : (
              <>
                <label
                  htmlFor="selected images"
                  style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  {t('marketer:job_detail.* Your selected images')}
                </label>
                <JobDetailImagePreview items={socialJobItem} collapse2={false} />
              </>
            )}

            <div style={{ margin: '10px 0' }}>
              {socialJobItem?.marketer_approve_date ? (
                <>
                  <label
                    htmlFor="description"
                    style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                  >
                    {`${SocialContent(
                      socialJobItem?.social_media_info?.social_media_type,
                    )}`}
                  </label>
                  <div style={{ fontSize: '1.05rem', wordBreak: 'break-all' }}>
                    <ShowMoreText
                      lines={3}
                      more="See more"
                      less="See less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      expanded={false}
                      truncatedEndingComponent="... "
                    >
                      {socialJobItem?.marketer_massage}
                    </ShowMoreText>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      htmlFor="description"
                      // style={{
                      //   fontSize: '1.5rem',
                      //   fontWeight: 'bold',
                      //   marginBottom: '0',
                      // }}
                    >
                      {t('marketer:job_detail.Post caption')}
                    </label>
                  </div>
                  <textarea
                    value={marketerMessage}
                    onChange={(e: any) => {
                      setMarketerMessage(e.target.value);
                    }}
                    style={{
                      width: '100%',
                      height: '100px',
                      borderRadius: '5px',
                      border: '1px solid #949d9d',
                      padding: '10px 20px',
                      outline: 'none',
                    }}
                  />
                </>
              )}
            </div>
          </MessagePost>

          {!socialJobItem.marketer_approve_date &&
            socialJobItem.status === 'INPROGRESS' && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '30px 0 10px 0',
                }}
              >
                <div
                  style={{ marginRight: '15px' }}
                  onClick={async (e: any) => {
                    await Swal.fire({
                      width: 300,
                      title: t('swal:Are you sure ?'),
                      text: t(
                        "swal:Review your photos and posts before pressing Verify. If you press confirm You won't be able to go back!",
                      ),
                      imageUrl: choice,
                      imageHeight: 200,
                      imageAlt: 'A tall image',
                      customClass: {},
                      cancelButtonColor: 'red',
                      showCancelButton: true,
                      confirmButtonColor: '#009a78',
                      confirmButtonText: t('button:Yes'),
                      cancelButtonText: t('swal:Cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        await e.preventDefault();
                        onApproveInfluencerPost();
                      }
                    });
                  }}
                >
                  <CustomButton
                    size="mds"
                    status="submit"
                    label={t('button:Edit & Confirm')}
                  />
                </div>
                <div
                  onClick={async (e: any) => {
                    await Swal.fire({
                      width: 300,
                      title: t('swal:Are you sure ?'),
                      // text: t("swal:You won't be able to revert this!"),
                      text: t('swal:Want to cancel your job!'),
                      imageUrl: choice,
                      imageHeight: 200,
                      imageAlt: 'A tall image',
                      customClass: {},
                      cancelButtonColor: 'red',
                      showCancelButton: true,
                      confirmButtonColor: '#009a78',
                      confirmButtonText: t('button:Yes'),
                      cancelButtonText: t('swal:Cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        await e.preventDefault();
                        onRejectInfluencerPost();
                      }
                    });
                  }}
                >
                  <CustomButton
                    size="s"
                    status="cancel"
                    label={t('swal:Cancel job')}
                  />
                </div>
              </div>
            )}
        </CardContainer>
      </InfluencerPostDetailContainer>
    </div>
  );
};

export default InfluencerPostDetailPage;
