import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import ProfileTypes from './actionTypes';
import { profileError, profileSuccess } from './actions';

function* editProfile({ payload: { user } }: any) {
  try {
    // something here
    yield put(profileSuccess(user));
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(ProfileTypes.EDIT_PROFILE, editProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
