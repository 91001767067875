import { CreativeTypeTypes, CreativeTypeState } from './actionTypes';

export const INIT_STATE: CreativeTypeState = {
  creativeType: [],
  error: {},
};

const CreativeType = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CreativeTypeTypes.GET_CREATIVE_TYPE_SUCCESS:
      return {
        ...state,
        creativeType: action.payload,
      };
    case CreativeTypeTypes.GET_CREATIVE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default CreativeType;
