import { WalletTypes } from './actionTypes';

export const getWallet = () => ({
  type: WalletTypes.GET_WALLET,
});

export const getWalletSuccess = (wallet: any) => ({
  type: WalletTypes.GET_WALLET_SUCCESS,
  payload: wallet,
});

export const getWalletFail = (error: any) => ({
  type: WalletTypes.GET_WALLET_FAIL,
  payload: error,
});

export const getSumBalanceWallet = () => ({
  type: WalletTypes.GET_SUM_BALANCE_WALLET,
});

export const getSumBalanceWalletSuccess = (payload: any) => ({
  type: WalletTypes.GET_SUM_BALANCE_WALLET_SUCCESS,
  payload,
});

export const getSumBalanceWalletFail = (error: any) => ({
  type: WalletTypes.GET_SUM_BALANCE_WALLET_FAIL,
  payload: error,
});

export const getUserWalletByCurrency = (payload: any) => ({
  type: WalletTypes.GET_USER_WALLET_BY_CURRENCY,
  payload,
});

export const getUserWalletByCurrencySuccess = (payload: any) => ({
  type: WalletTypes.GET_USER_WALLET_BY_CURRENCY_SUCESS,
  payload,
});

export const getAdminWallet = () => ({
  type: WalletTypes.GET_ADMIN_WALLET,
});

export const getAdminWalletSuccess = (payload: any) => ({
  type: WalletTypes.GET_ADMIN_WALLET_SUCCESS,
  payload,
});

export const getSumBalanceWalletByUUID = (payload: any) => ({
  type: WalletTypes.GET_SUM_BALANCE_WALLET_UUID,
  payload,
});

export const getBalanceWalletByUUID = (payload: any) => ({
  type: WalletTypes.GET_BALANCE_WALLET_UUID,
  payload,
});

export const getBalanceWalletByUUIDSuccess = (payload: any) => ({
  type: WalletTypes.GET_BALANCE_WALLET_UUID_SUCCESS,
  payload,
});

export const getSumBalanceWalletByUUIDSuccess = (payload: any) => ({
  type: WalletTypes.GET_SUM_BALANCE_WALLET_UUID_SUCCESS,
  payload,
});

export const getMarketerAvailableCurrencies = () => ({
  type: WalletTypes.GET_MARKETER_AVAILABLE_CURRENCIES,
});

export const getMarketerAvailableCurrenciesSuccess = (payload: any) => ({
  type: WalletTypes.GET_MARKETER_AVAILABLE_CURRENCIES_SUCCESS,
  payload,
});
