import { gql } from '@apollo/client';
import { client } from '../client';

const approveWithdraw = async (uuid: string) => {
  const APPROVE_WITHDRAW = gql`
    mutation ApproveWithdraw($uuid: String!) {
      approveWithdraw(uuid: $uuid)
    }
  `;

  return client.mutate({
    mutation: APPROVE_WITHDRAW,
    variables: {
      uuid,
    },
  });
};

const rejectWithdraw = async (uuid: string) => {
  const REJECT_WITHDRAW = gql`
    mutation RejectWithdraw($uuid: String!) {
      rejectWithdraw(uuid: $uuid)
    }
  `;

  return client.mutate({
    mutation: REJECT_WITHDRAW,
    variables: {
      uuid,
    },
  });
};

// eslint-disable-next-line import/prefer-default-export
export { approveWithdraw, rejectWithdraw };
