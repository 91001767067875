import { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
import { useDispatch, useSelector } from 'react-redux';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import { changeTimezone } from '../../store/users/actions';
import { changelayoutMode } from '../../store/layout/actions';

const AccountSetting = ({ t }: any) => {
  const dispatch = useDispatch();
  const { user, layoutType } = useSelector((state: any) => ({
    user: state.login.user,
    layoutMode: state.Layout.layoutMode,
    layoutType: state.Layout.layoutType,
  }));

  const [setSelectedTimezone]: any = useState(
    user.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  // const onChangeLayoutMode = (value: any) => {
  //   if (changelayoutMode) {
  //     dispatch(changelayoutMode(value, layoutType));
  //   }
  // };

  // eslint-disable-next-line no-unused-vars
  const onChangeTimeZone = (timezone: any) => {
    setSelectedTimezone(timezone);

    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Change Timezone Confirm', { timezone }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(changeTimezone(timezone));
      }
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0" style={{ padding: '10px 10px 10px 15px' }}>
            <h5 className="m-0" style={{ fontSize: '16px' }}>
              {t('Account')}
            </h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="select-wrapper">
            <Col md={6}>
              <table className="table mb-0 account-table">
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('Language')}
                    </th>
                    <td>
                      <LanguageDropdown bodyCSS="dropdown-language" />
                    </td>
                  </tr>
                  <tr>
                    {/* <th className="text-nowrap" scope="row">
                      {t('Theme')}
                    </th> */}
                    <td>
                      {/* <LightDark
                        layoutMode={layoutMode}
                        onChangeLayoutMode={onChangeLayoutMode}
                      /> */}
                    </td>
                  </tr>
                  {/* <tr>
                    <th className="text-nowrap" scope="row">
                      {t('Change Timezone')}
                    </th>
                    <td>
                      <TimeZoneDropdown />
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default withTranslation()(AccountSetting);
