import { Col, Row, FormGroup, Label, UncontrolledAlert } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { resetPassword } from '../../store/auth/forgotpwd/actions';
import PasswordCheckList from '../Common/PasswordCheckList';
import PasswordField from '../Common/PasswordField';

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
};

const withdrawSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"#'(),./;:<=>[\]^_`{|}~\\])[A-Za-z\d@$!%*?&"#'(),./:;<=>[\]^_`{|}~\\]{8,}$/,
      'Password must include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and at least 8 characters long.',
    )
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'invalid.Password must match')
    .required('Password Confirmation is required'),
});

const ResetPasswordForm = ({ t, history, location }: any) => {
  const dispatch = useDispatch();
  const string = location.search.replace(/\+/g, '%2b');
  const { token, email } = queryString.parse(string);

  const { error, message } = useSelector((state: any) => ({
    error: state.forgotPassword.error,
    message: state.forgotPassword.message,
  }));

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    setSubmitting(true);

    const data = {
      ...values,
      token,
    };

    resetForm();

    dispatch(resetPassword(data, history));
  };

  return (
    <>
      <Formik
        initialValues={{ ...initialValues, email }}
        onSubmit={onSubmit}
        validationSchema={withdrawSchema}
        render={({ errors, touched, values }) => (
          <FormikForm>
            <Row>
              <Col md="12">
                {error && message && (
                  <UncontrolledAlert color="danger" className="alert">
                    {message}
                  </UncontrolledAlert>
                )}
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="email">{t('Form.Email')}</Label>
                  <Field
                    name="email"
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email ? 'is-invalid' : ''
                    }`}
                    id="email"
                    placeholder={t('Form.Email Placeholder')}
                    value={email}
                    disabled
                  />

                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="password">{t('Form.Password')}</Label>
                  <PasswordField name="password" placeHolder={t('Form.Password')} />
                  <PasswordCheckList password={values.password} />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="passwordConfirmation">
                    {t('Form.Password Confirmation')}
                  </Label>
                  <PasswordField
                    name="passwordConfirmation"
                    placeholder={t('Form.Password Confirmation Placeholder')}
                  />
                </FormGroup>
              </Col>
            </Row>

            <button
              className="btn btn-primary w-100 waves-effect waves-light"
              type="submit"
            >
              {t('Button.Reset')}
            </button>
          </FormikForm>
        )}
      />
    </>
  );
};
export default withTranslation()(withRouter(ResetPasswordForm));
