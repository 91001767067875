import { WalletTypes, WalletState } from './actionTypes';

export const INIT_STATE: WalletState = {
  wallet: {},
  error: {},
  sumWallet: {},
  eachWallet: [],
  adminWallet: [],
  marketerCurrencies: [],
};

const Wallet = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case WalletTypes.GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload,
      };
    case WalletTypes.GET_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WalletTypes.GET_SUM_BALANCE_WALLET_SUCCESS:
      return {
        ...state,
        sumWallet: action.payload,
      };
    case WalletTypes.GET_SUM_BALANCE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case WalletTypes.GET_USER_WALLET_BY_CURRENCY_SUCESS:
      return {
        ...state,
        wallet: action.payload,
      };
    case WalletTypes.GET_ADMIN_WALLET_SUCCESS:
      return {
        ...state,
        adminWallet: action.payload,
      };
    case WalletTypes.GET_SUM_BALANCE_WALLET_UUID_SUCCESS:
      return {
        ...state,
        sumWallet: action.payload,
      };
    case WalletTypes.GET_BALANCE_WALLET_UUID_SUCCESS:
      return {
        ...state,
        eachWallet: action.payload,
      };
    case WalletTypes.GET_MARKETER_AVAILABLE_CURRENCIES_SUCCESS:
      return {
        ...state,
        marketerCurrencies: action.payload,
      };
    default:
      return state;
  }
};

export default Wallet;
