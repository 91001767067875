import { withTranslation } from 'react-i18next';
import SelectButton from './SelectButton';

const GroupSelectButton = ({
  t,
  title,
  value,
  setValue,
  isRequired,
  data,
  className,
}: any) => (
  <div className={className || ''}>
    <label htmlFor={title} className="input-label fs-5">
      {t(title)}
      {isRequired ? <span className="input-required">*</span> : <></>}
    </label>
    <div>
      {data.map(({ selfValue, display }: any) => (
        <SelectButton
          value={value}
          setValue={setValue}
          selfValue={selfValue}
          display={display}
        />
      ))}
    </div>
  </div>
);

export default withTranslation()(GroupSelectButton);
