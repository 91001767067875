import { gql } from '@apollo/client';
import { client } from '../client';

const getWebsite = async (
  limit: number = 10,
  page: number = 1,
  url: string = '',
  status: string = '',
  sortBy: any,
  sortType: any,
) => {
  const GET_WEBSITE_LIST = gql`
    query websites(
      $limit: Int!
      $page: Int!
      $url: String
      $status: String
      $sortBy: String
      $sortType: String
    ) {
      websites(
        limit: $limit
        page: $page
        url: $url
        status: $status
        sortBy: $sortBy
        sortType: $sortType
      ) {
        items {
          id
          url
          status
          website_categories
          created_at
          revenue
          current_revenue
          user {
            uuid
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_WEBSITE_LIST,
    variables: {
      limit,
      page,
      url,
      status,
      sortBy,
      sortType,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.websites;
};

const getWebsiteGraphDatum = async (payload: {
  website_id: number;
  limit: number;
  page: number;
  log_range: string;
  log_start?: string;
  log_end?: string;
  shortcut_range?: string;
  sortBy?: string;
  sortType?: string;
}) => {
  const query: any = gql`
    query getWebsiteDatumGraph($payload: GraphWebsiteDatum!) {
      getWebsiteDatumGraph(input: $payload) {
        graphItems {
          total_click
          total_impression
          revenue
          updated_at
        }
        graphType
        items {
          impression
          click
          revenue
          updated_at
        }
        totalCount
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      payload,
    },
    fetchPolicy: 'network-only',
  });

  return result.data.getWebsiteDatumGraph;
};

const getWebsiteById = async (websiteId: number) => {
  const query = gql`
    query website($id: Int!) {
      website(id: $id) {
        id
        url
        status
        created_at
        website_key
        revenue
        current_revenue
        scriptError
        website_types {
          id
          name
        }
        CreativeWebsite {
          creative_format
          key
        }
      }
    }
  `;

  // QueryById
  const result: any = await client.query({
    query,
    variables: {
      id: websiteId,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.website;
};

const createWebsite = async (
  url: string = '',
  status: string = '',
  userId: number,
  category: string = 'Empty',
  typeIndex: any = [],
) => {
  const CREATE_WEBSITE = gql`
    mutation createWebsite(
      $url: String!
      $status: Website_status!
      $user_id: Int!
      $website_categories: String
      $website_typeIds: [Int!]!
    ) {
      createWebsite(
        createWebsiteTypeInput: {
          url: $url
          status: $status
          user_id: $user_id
          website_categories: $website_categories
        }
        website_typeIds: $website_typeIds
      ) {
        url
      }
    }
  `;

  const result = await client.mutate({
    mutation: CREATE_WEBSITE,
    variables: {
      url,
      status,
      user_id: +userId,
      website_categories: category,
      website_typeIds: typeIndex,
    },
    errorPolicy: 'all',
  });
  return result;
};

const updateWebsite = async (id: any, status: any) => {
  const mutation = gql`
    mutation updateWebsite($id: Int!, $status: Website_status!) {
      updateWebsite(updateWebsiteInput: { id: $id, status: $status }) {
        url
      }
    }
  `;

  const result = await client.mutate({
    mutation,
    variables: {
      id: +id,
      status,
    },
    fetchPolicy: 'no-cache',
  });
  return result;
};

const deleteWebsite = async (id: any) => {
  const DELETE_WEBSITE = gql`
    mutation removeWebsite($id: Int!) {
      removeWebsite(id: $id) {
        id
      }
    }
  `;

  const result: any = await client.mutate({
    mutation: DELETE_WEBSITE,
    variables: { id: +id },
    errorPolicy: 'all',
  });

  return result.data;
};

const getWebsiteReports = async (
  limit: number,
  page: number,
  website_id: number,
  sortBy: any,
  sortType: any,
) => {
  const GET_WEBSITE_REPORT = gql`
    query getWebTransaction(
      $id: Int!
      $limit: Int
      $page: Int
      $sortBy: String
      $sortType: String
    ) {
      getWebTransaction(
        id: $id
        limit: $limit
        page: $page
        sortBy: $sortBy
        sortType: $sortType
      ) {
        id
        website_id
        creative_format
        total_click
        total_impression
        updated_at
        revenue
        ecpm
      }
    }
  `;

  const result: any = await client.query({
    query: GET_WEBSITE_REPORT,
    variables: { id: website_id, limit, page, sortBy, sortType },
    fetchPolicy: 'no-cache',
  });
  return result.data.getWebTransaction;
};

const getRequestCreativeSize = async (
  limit: number,
  page: number,
  website_id: number,
  sortBy: string,
  sortType: string,
) => {
  const GET_REQUEST_SIZE = gql`
    query getRequestSize(
      $id: Int!
      $limit: Int
      $page: Int
      $sortBy: String
      $sortType: String
    ) {
      getRequestSize(
        id: $id
        limit: $limit
        page: $page
        sortBy: $sortBy
        sortType: $sortType
      ) {
        items {
          id
          width
          height
          creative_format
          banner_format
          key
          website {
            website_key
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_REQUEST_SIZE,
    variables: { id: website_id, limit: 5, page, sortBy, sortType },
    fetchPolicy: 'no-cache',
  });

  return result.data.getRequestSize;
};

const createRequestCreativeSize = async (input: any) => {
  const {
    website_id,
    width,
    height,
    creative_format,
    banner_format,
    text_position,
  } = input;
  const mutation = gql`
    mutation createRequestSize(
      $website_id: Int!
      $width: Int!
      $height: Int!
      $creative_format: ad_format!
      $banner_format: banner_format!
      $text_position: text_position
    ) {
      createRequestSize(
        createWebsiteRequestSizeInput: {
          website_id: $website_id
          width: $width
          height: $height
          creative_format: $creative_format
          banner_format: $banner_format
          text_position: $text_position
        }
      ) {
        id
        width
        height
        creative_format
      }
    }
  `;

  const result = await client.mutate({
    mutation,
    variables: {
      website_id: +website_id,
      width: +width,
      height: +height,
      creative_format,
      banner_format,
      text_position: text_position || null,
    },
    errorPolicy: 'all',
  });
  return result;
};

const deleteRequestCreativeSize = async (id: any) => {
  const mutation = gql`
    mutation removeRequestSize($id: Int!) {
      removeRequestSize(id: $id) {
        id
      }
    }
  `;

  const result = await client.mutate({
    mutation,
    variables: {
      id: +id,
    },
    errorPolicy: 'all',
  });

  return result;
};

const getCreativeType = async () => {
  const query = gql`
    query creativeTypes {
      creativeTypes {
        id
        name
      }
    }
  `;

  const result = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.creativeTypes;
};

const updateWebsiteDisableCreativeType = async (
  website_id: number,
  website_typeIds: Array<[]>,
) => {
  const mutation = gql`
    mutation editWebsiteDisableCrativeType(
      $website_id: Int!
      $website_typeIds: [Int!]!
    ) {
      editWebsiteDisableCrativeType(
        website_id: $website_id
        website_typeIds: $website_typeIds
      ) {
        id
      }
    }
  `;

  const result = await client.mutate({
    mutation,
    variables: {
      website_id,
      website_typeIds,
    },
    errorPolicy: 'all',
  });

  return result;
};

const getPendingApproval = async (
  limit: any,
  page: any,
  url?: string,
  sortBy?: string,
  sortType?: string,
) => {
  const query = gql`
    query getPendingApproval(
      $limit: Int!
      $page: Int!
      $url: String
      $sortBy: String
      $sortType: String
    ) {
      getPendingApproval(
        limit: $limit
        page: $page
        url: $url
        sortBy: $sortBy
        sortType: $sortType
      ) {
        items {
          id
          url
          status
          created_at
          website_categories
          user {
            uuid
          }
          website_types {
            name
          }
        }
        totalCount
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      limit,
      page,
      url,
      sortBy,
      sortType,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.getPendingApproval;
};

const getAutoAdWebsiteCreative = async (website_id: any) => {
  const query = gql`
    query getAutoAdWebsiteCreative($id: Int!) {
      getAutoAdWebsiteCreative(id: $id) {
        id
        key
        creative_format
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      id: website_id,
    },
    fetchPolicy: 'no-cache',
  });

  return result;
};

const checkWebsiteByUserId = async (website_id: any) => {
  const mutate = gql`
    mutation filterWebsiteByUserid($websiteId: Int!) {
      filterWebsiteByUserid(websiteId: $websiteId) {
        Status
        Message
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: mutate,
      variables: {
        websiteId: website_id,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.filterWebsiteByUserid;
  } catch (error) {
    return {
      Status: 'NOT_FOUND',
      Message: 'Website not found',
    };
  }
};

export {
  getWebsite,
  getWebsiteById,
  createWebsite,
  updateWebsite,
  deleteWebsite,
  getWebsiteReports,
  getRequestCreativeSize,
  createRequestCreativeSize,
  deleteRequestCreativeSize,
  getCreativeType,
  updateWebsiteDisableCreativeType,
  getPendingApproval,
  getAutoAdWebsiteCreative,
  getWebsiteGraphDatum,
  checkWebsiteByUserId,
};
