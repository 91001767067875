import { useSelector } from 'react-redux';
import moment from 'moment';
import config from 'src/config';
import { useEffect, useRef } from 'react';
import user1 from '../../assets/images/users/avatar-1.jpg';
import { Message } from './ChatDetails';
import MessageInput from './MessageInput';

export default function Messages({
  messages,
  send,
  typing,
  read,
}: {
  messages: Message[];
  send: (val: string) => void;
  typing: string;
  read: () => void;
}) {
  const ref = useRef<null | HTMLElement>(null);
  const myDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (myDivRef.current) {
      myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
    }
  }, [messages]);

  const handleClick = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
    }
  };
  const {
    data,
    totalCount,
    defaultTax,
    socialMediaInfo,
    user,
    wallet,
    job,
    task,
    influencerUser,
    campaign,
    socialGleamJboForReview,
  } = useSelector((state: any) => ({
    data: state.SocialMediaInfo.socialJob?.items,
    totalCount: state.SocialMediaInfo.socialJob?.totalCount,
    socialMediaInfo: state.login.socialMediaInfoImage,
    // campaign_key: state.SocialMediaInfo.socialJob?.marketer_campaign_key,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    user: state.login.user,
    wallet: state?.Wallet?.wallet,
    job: state?.MarketerJob?.marketerJob,
    socialGleamJboForReview: state.SocialMediaInfo.socialGleamJboForReview,
    task: state.SocialMediaInfo.socialHasJobTask,
    campaign: state.MarketerCampaignJobsNew.marketerJobNew.marketer_campaign,
    influencerUser: state.MarketerCampaignJobsNew.marketerJobNew.marketer_campaign,
    // campaign: state.MarketerCampaignJobsNew.marketerJobNewmarketer_campaign.user,
  }));
  console.log('messages', messages);

  const imageProfileMarketer = () => {
    if (campaign) {
      if (campaign?.user?.image_storage?.key) {
        return (
          <img
            src={`${config.backendUrl}api/profile/${campaign?.user?.image_storage?.key}`}
            alt="Header Avatar"
            className="navbar-profile-image"
            width="40"
            height="40"
            style={{ borderRadius: '50%' }}
          />
        );
      }
      return (
        <img
          src={user1}
          alt="Header Avatar"
          className="navbar-profile-image"
          style={{ width: '32px', height: '32px', borderRadius: '50%' }}
        />
      );
    }
    return null;
  };
  const imageProfileInfluencer = () => {
    // if (socialMediaInfo?.length && !user?.image_storage?.key) {
    //   return (
    //     <img
    //       src={socialMediaInfo[0]?.image_profile_url}
    //       alt="Header Avatar"
    //       width="40"
    //       height="40"
    //       style={{ borderRadius: '50%' }}
    //     />
    //   );
    // }
    if (socialGleamJboForReview) {
      if (socialGleamJboForReview) {
        return (
          <img
            src={socialGleamJboForReview?.social_info?.image_profile_url}
            alt="Header Avatar"
            className="navbar-profile-image"
            width="40"
            height="40"
            style={{ borderRadius: '50%' }}
          />
        );
      }
      return (
        <img
          src={user1}
          alt="Header Avatar"
          className="navbar-profile-image"
          style={{ width: '32px', height: '32px', borderRadius: '50%' }}
        />
      );
    }
    return null;
  };
  const imageProfile = () => {
    if (socialMediaInfo?.length && !user?.image_storage?.key) {
      return (
        <img
          src={socialMediaInfo[0]?.image_profile_url}
          alt="Header Avatar"
          width="40"
          height="40"
          style={{ borderRadius: '50%' }}
        />
      );
    }
    if (user?.image_storage?.key) {
      return (
        <img
          src={`${config.backendUrl}api/profile/${user?.image_storage?.key}`}
          alt="Header Avatar"
          className="navbar-profile-image"
          width="40"
          height="40"
          style={{ borderRadius: '50%' }}
        />
      );
    }
    return (
      <img
        src={user1}
        alt="Header Avatar"
        className="navbar-profile-image"
        style={{ width: '32px', height: '32px', borderRadius: '50%' }}
      />
    );
  };
  console.log('massages', messages);
  console.log('user', user);
  return (
    <div>
      <div>
        {messages && (
          <div className="content">
            <div className="container p-0">
              <div className="card">
                <div className="row g-0">
                  <div>
                    <div className="py-2 px-4 border-bottom  d-lg-block">
                      <div className="d-flex align-items-center py-1">
                        <div className="position-relative">
                          {user.role === 'PUBLISHER'
                            ? imageProfileMarketer()
                            : imageProfileInfluencer()}
                        </div>
                        <div
                          className="flex-grow-1 pl-3"
                          style={{ marginLeft: '10px' }}
                        >
                          <strong style={{ color: 'white' }}>
                            {user.role === 'PUBLISHER'
                              ? campaign?.user?.user_info?.company
                              : socialGleamJboForReview?.social_info?.name}
                          </strong>
                        </div>
                      </div>
                    </div>

                    <div className="position-relative">
                      <div
                        className="chat-messages p-4"
                        style={{
                          maxHeight: '450px',
                          height: '100%',
                          overflowY: 'scroll',
                        }}
                        ref={myDivRef}
                      >
                        {messages.map((message: Message) => (
                          <div>
                            {user.uuid === message.uuid ? (
                              <div>
                                {' '}
                                <div className="chat-message-right pb-4">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'end',
                                      marginBottom: '5px',
                                      marginLeft: '30px',
                                    }}
                                  >
                                    {imageProfile()}
                                    <div className="text-muted small text-nowrap mt-2">
                                      {moment(message.created_at).format('LT')}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: '#53c5b7',
                                      height: 'fit-content',
                                      width: 'fit-content',
                                      maxWidth: '300px',
                                    }}
                                    className="flex-shrink-1 rounded right py-2 px-3 mr-3"
                                  >
                                    <div className="font-weight-bold mb-1">You</div>
                                    {message.message}
                                    {message.is_read ? '( Read )' : ''}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {' '}
                                <div className="chat-message-left pb-4">
                                  <div style={{ marginRight: '30px' }}>
                                    {user.role === 'PUBLISHER'
                                      ? imageProfileMarketer()
                                      : imageProfileInfluencer()}
                                    <div className="text-muted small text-nowrap mt-2">
                                      {moment(message.created_at).format('LT')}
                                    </div>
                                  </div>
                                  <div
                                    className="flex-shrink-1 bg-light rounded py-2 px-3 ml-3"
                                    style={{
                                      height: 'fit-content',
                                      width: 'fit-content',
                                      maxWidth: '300px',
                                    }}
                                  >
                                    <div className="font-weight-bold mb-1">
                                      {message.name}
                                    </div>
                                    {message.message}
                                    {/* {message.is_read ? '( Read )' : ''} */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex-grow-0 py-3 px-4 border-top">
                      {typing}
                      <MessageInput send={send} read={read} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
