import { Row, Col } from 'reactstrap';
import { calculateDayLeft } from 'src/utils/dateTime';
import config from 'src/config';
import './GleamJob.style.scss';
import { useDispatch, useSelector } from 'react-redux';
import Role from 'src/constants/enum/role';
import resizeImage from 'src/utils/resizeImage';
import { updateMarketerJob, uploadProfile } from 'src/store/actions';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import {
  removeJobImageByKey,
  uploadJobThumbnailImage,
} from 'src/graphql/advertiser/job';
import Trash from 'src/assets/images/Trash-01.png';
import { floorToFixed } from 'src/common/data/mathToFixed';

const GleamJobHeader = ({ job, fixPrice }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const [jobThumbnail, setJobThumbnail] = useState('');
  const [isPhotoSelect, setIsPhotoSelect] = useState(false);
  const [selectedImage, setSelectedImage]: any = useState(null);
  const [cropper, setCropper]: any = useState(null);
  const [cropData, setCropData] = useState(null);
  const [jobImage, setJobImage] = useState<any>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setJobThumbnail(job?.thumbnail_cover);
    setJobImage(
      job?.thumbnail_cover
        ? `${config.backendUrl}api/jobThumbnail/${job?.thumbnail_cover}`
        : '',
    );
  }, [job]);
  const handleFileSelected = async (e: any) => {
    const files: any = Array.from(e.target.files);

    const file = files[0];

    // const newFile = await fixImageSize({ file, width: 600, height: 400 });
    const reader = new FileReader();
    const imageTag: any = document.getElementById('jobImageRef');
    // imageTag.title = file?.name;

    reader.onload = (event) => {
      imageTag.src = event?.target?.result;
      setSelectedImage(event?.target?.result);
    };
    reader.readAsDataURL(file);
    setJobImage(file);
    e.target.files = null;

    // await uploadSocialJobImage(newSizeFile, file);
  };

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());

      const url = cropper.getCroppedCanvas().toDataURL();
      fetch(url)
        .then((res) => res.blob())
        .then(async (blob) => {
          const uuid = crypto.randomUUID();
          const file: any = new File([blob], uuid, { type: 'image/png' });
          setJobThumbnail(file);
          //setFormValues({ ...formValue, thumbnail_cover: file });

          const [jobId, mock]: any = ['mockImg'];
          const submitValue: any = {};
          if (file && typeof file === 'object') {
            if (job?.thumbnail_cover) {
              await removeJobImageByKey(job?.thumbnail_cover);
              submitValue['thumbnail_cover'] = '';
            }
            const files: any = file[0] || file;
            const newFile = await resizeImage({ file: files, maxSize: 600 });
            let thumbnail_cover: any;
            if (newFile) {
              thumbnail_cover = newFile;
            } else {
              thumbnail_cover = files;
            }

            const imageKey = await uploadJobThumbnailImage(thumbnail_cover, jobId);
            submitValue['thumbnail_cover'] = imageKey;
            setJobThumbnail(submitValue['thumbnail_cover']);
            // setFormValues({
            //   ...formValue,
            //   thumbnail_cover: submitValue['thumbnail_cover'],
            // });
          } else {
            submitValue['thumbnail_cover'] = job['thumbnail_cover'];
            setJobThumbnail(submitValue['thumbnail_cover']);
            // setFormValues({
            //   ...formValue,
            //   thumbnail_cover: submitValue['thumbnail_cover'],
            // });
          }
          if (submitValue['thumbnail_cover']) {
            dispatch(
              updateMarketerJob({
                key: job.key,
                thumbnail_cover: submitValue['thumbnail_cover'],
              }),
            );
          }
        });
    }
    setIsPhotoSelect(true);
  };

  const deleteUploadedImage = () => {
    setJobImage('');
    setJobThumbnail('');
    setSelectedImage(null);
    setCropData(null);
    setIsPhotoSelect(false);
  };

  const uploadJobImageView = () => {
    // if (marketerJob.job_type !== 'PUBLISH') return <></>;
    //กรณีที่เป็นแก้ไข
    return jobThumbnail && job?.key ? (
      <div style={{ position: 'relative', width: 'fit-content' }}>
        <button
          type="button"
          onClick={() => {
            deleteUploadedImage();
          }}
          style={{
            cursor: 'pointer',
            top: '8px',
            right: '16px',
            position: 'absolute',
            border: '0',
            backgroundColor: 'inherit',
          }}
        >
          <img src={Trash} alt="trash" style={{ width: '30px', height: '30px' }} />
        </button>
        <img
          id=""
          src={`${config.backendUrl}api/jobThumbnail/${jobThumbnail}`}
          alt="jobThumbnail"
          style={{ maxWidth: '600px' }}
        />
      </div>
    ) : (
      <div style={{ width: '100%' }}>
        {!isPhotoSelect && !selectedImage && (
          <div
            style={{
              maxWidth: '620px',
              border: '1px solid rgb(148, 157, 157)',
              borderRadius: '10px',
              margin: '10px 0',
            }}
          >
            <input
              onChange={async (e: any) => {
                await handleFileSelected(e);
              }}
              ref={fileInputRef}
              type="file"
              multiple
              style={{
                display: 'none',
                width: '100%',
                height: '100%',
              }}
            />

            <div
              className="text-center"
              style={{
                padding: '0.5rem',
                maxWidth: '620px',
                position: 'relative',
              }}
            >
              <h5> {t('marketer:job_detail:Product Image')}</h5>
              <img
                id="jobImageRef"
                src={jobImage}
                alt="jobImage"
                style={{
                  maxHeight: '372px',
                  maxWidth: '620px',
                  // objectFit: 'cover',
                  aspectRatio: '6/4',
                  width: '100%',
                  height: '100%',
                  visibility: jobImage ? 'visible' : 'hidden',
                }}
              />
              <button
                type="button"
                onClick={() => {
                  deleteUploadedImage();
                }}
                style={{
                  cursor: 'pointer',
                  right: '0',
                  position: 'absolute',
                  visibility: jobImage ? 'visible' : 'hidden',
                  border: '0',
                  backgroundColor: 'inherit',
                }}
              >
                <img
                  src={Trash}
                  alt="trash"
                  style={{ width: '30px', height: '30px' }}
                />
              </button>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  fileInputRef?.current?.click();
                }}
              >
                <div>
                  <i
                    className="display-6 text-muted bx bxs-cloud-upload lg-4 "
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                  <h5 style={{ display: 'inline-block' }}>
                    {t('creative:Drop files here or click to upload')}
                  </h5>
                </div>
                <h6 style={{ color: 'red' }}>
                  {t('marketer:job_detail:Image file .jpg or .png')}
                </h6>
              </div>
            </div>
          </div>
        )}
        {selectedImage && (
          <>
            {!isPhotoSelect && (
              <Cropper
                // className="cropper"
                style={{
                  width: '100%',
                  height: '400px',
                  border: '1px solid',
                }}
                src={selectedImage}
                // viewMode={2}
                center={false}
                aspectRatio={6 / 4}
                zoomable={false}
                dragMode="move"
                // cropBoxResizable={false}
                initialAspectRatio={1}
                background={false}
                // viewMode={1}
                // minCropBoxHeight={10}
                // minCropBoxWidth={10}
                toggleDragModeOnDblclick={false}
                data={{
                  width: 600,
                  height: 400,
                }}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                // zoomable={false}
              />
            )}
            {!isPhotoSelect && (
              <button
                type="button"
                id="leftbutton"
                onClick={getCropData}
                className="crop-button"
                style={{ margin: '1rem 0' }}
              >
                <span>{t('button:Crop Image')}</span>
              </button>
            )}
            {cropData && (
              <div
                onClick={() => {
                  deleteUploadedImage();
                  setTimeout(() => {
                    fileInputRef?.current?.click();
                  }, 1000);
                }}
                style={{
                  maxWidth: '600px',
                  maxHeight: '400px',
                  border: '1px solid rgb(3, 3, 3)',
                  borderRadius: '10px',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <input
                  onChange={async (e: any) => {
                    await handleFileSelected(e);
                  }}
                  ref={fileInputRef}
                  type="file"
                  multiple
                  style={{
                    display: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                />
                {/* <button
                  type="button"
                  onClick={() => {
                    deleteUploadedImage();
                  }}
                  style={{
                    cursor: 'pointer',
                    top: '8px',
                    right: '16px',
                    position: 'absolute',
                    visibility: jobImage ? 'visible' : 'hidden',
                    border: '0',
                    backgroundColor: 'inherit',
                  }}
                >
                  <img
                    src={Trash}
                    alt="trash"
                    style={{ width: '30px', height: '30px' }}
                  />
                </button> */}

                <img
                  src={cropData}
                  alt="cropped"
                  className="crop-image"
                  style={{
                    maxWidth: '600px',
                    maxHeight: '400px',
                    // objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    // visibility: cropData ? 'visible' : 'hidden',
                  }}
                />
              </div>
            )}
            {/* {cropData && (
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {t('Crop')}
              </span>
            )} */}
          </>
        )}
      </div>
    );
  };

  const DayLeft = (finishDate: any) => {
    const day_left = calculateDayLeft(finishDate);
    if (day_left === null) {
      return <></>;
    } else {
      if (day_left > 0) {
        return (
          <div style={{ padding: '4px' }}>
            <h3 style={{ marginBottom: '1px' }}>{day_left}</h3>
            <h6 style={{ marginBottom: '0' }}>Days Left</h6>
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              padding: '4px',
            }}
          >
            <h3 style={{ marginBottom: '2px' }}>Time Out</h3>
          </div>
        );
      }
    }
  };

  const renderJobImageView = () => {
    if (user && user.role === Role.ADMIN) {
      return uploadJobImageView();
    } else {
      return (
        <img
          src={`${config.backendUrl}api/jobThumbnail/${jobThumbnail}`}
          alt={job?.thumbnail_cover}
          style={{
            width: '100%',
            maxWidth: '600px',
            height: 'auto',
            border: '1px solid #e9edee',
          }}
        />
      );
    }
  };

  return (
    <div>
      <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
        <Col
          className="gleam-job-header"
          style={{
            borderBottomColor: 'rgb(83, 198, 183)',
            borderRight: '1px solid #e9edee',
          }}
        >
          <div>
            {job?.is_gleam_job ? (
              <div style={{ padding: '4px' }}>
                <h3 style={{ marginBottom: '2px' }}>
                  {job?.job_tasks ? job.job_tasks?.length : 0}
                </h3>
                <h6 style={{ marginBottom: '0px' }}>
                  {job?.job_tasks?.length > 1 ? 'Tasks' : 'Tasks'}
                </h6>
              </div>
            ) : (
              <div style={{ padding: '4px' }}>
                <h3 style={{ marginBottom: '2px' }}>1</h3>
                <h6 style={{ marginBottom: '0px' }}>Task</h6>
              </div>
            )}
          </div>
        </Col>
        <Col
          className="gleam-job-header"
          style={{ borderBottomColor: '#495057', borderRight: '1px solid #e9edee' }}
        >
          {DayLeft(job?.finish_internal_work)}
        </Col>
        <Col
          className="gleam-job-header"
          style={{ borderBottomColor: 'rgb(255, 188, 84)' }}
        >
          {job?.job_requirement?.fixed_price !== null ? (
            <div style={{ padding: '4px' }}>
              <h3 style={{ marginBottom: '2px' }}>
                {floorToFixed(fixPrice(job?.job_requirement?.fixed_price), 2)}
              </h3>
              <h6 style={{ marginBottom: '0px' }}>
                {job?.currency?.main_symbol ?? 'USDT'}
              </h6>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                flexFlow: 'column',
                padding: '4px',
              }}
            >
              <h3 style={{ marginBottom: '2px' }}>N/A</h3>
              <h6 style={{ marginBottom: '0px' }}>
                {job?.currency?.main_symbol ?? 'USDT'}
              </h6>
            </div>
          )}
        </Col>
      </Row>
      <div className="gleam-job-name">
        <h3 style={{ marginBottom: '2px' }}>{job?.name}</h3>
      </div>
      <div>
        {user ? (
          renderJobImageView()
        ) : (
          <img
            src={`${config.backendUrl}api/jobThumbnail/${jobThumbnail}`}
            alt={job?.thumbnail_cover}
            style={{
              width: '100%',
              maxWidth: '600px',
              height: 'auto',
              border: '1px solid #e9edee',
            }}
          />
        )}
        {/* {user.role === Role.ADMIN ? (
          uploadJobImageView()
        ) : (
          <img
            src={`${config.backendUrl}api/jobThumbnail/${jobThumbnail}`}
            alt={job?.thumbnail_cover}
            style={{
              width: '100%',
              maxWidth: '600px',
              height: 'auto',
              border: '1px solid #e9edee',
            }}
          />
        )} */}
      </div>
    </div>
  );
};

export default GleamJobHeader;
