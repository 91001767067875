import { FaqsTypes, FaqState } from './actionTypes';

export const INIT_STATE: FaqState = {
  faqs: {},
  error: {},
  loading: false,
};

const Faqs = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case FaqsTypes.GET_FAQS_FILTER_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
      };
    case FaqsTypes.GET_FAQS_SEARCH_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
      };

    default:
      return state;
  }
};

export default Faqs;
