import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import { useDispatch, useSelector } from 'react-redux';
import control from './InfluencerChoice.style';
import IconRadioInputComponent from '../../../../../shared/IconRadioInput/IconRadioInput.component';

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
}

interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  Categories: any;
}

const { Texts, FlexCol, Radio, Btn, DivContent, GridBox } = control();

const formControls: FormControl = {
  label: 'marketer:create_campaign.campaign_name',
  name: 'name',
  type: FormInputType.STRING,
  required: true,
  Categories: [...CategoriesMarketerCampaign],
};
const InfluencerChoiceComponent = ({ form, setForm }: any) => {
  const { t } = useTranslation();
  const [checkAllProperty, setCheckAllProperty] = useState(false);
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));

  const AllChoice = () => {
    const all = formControls.Categories.map((data: any) => data.value);

    setCheckAllProperty(!checkAllProperty);
    if (!checkAllProperty) {
      setForm({ ...form, aptitude_ids: all });
    } else {
      setForm({ ...form, aptitude_ids: [] });
    }
  };

  return (
    <div>
      <FlexCol margin="20px 0">
        <Texts margin="20px 10px 0 16px" fontsize="20px" fontweight="bold">
          {t('marketer:create_job_campaign:Influencer aptitude')}
        </Texts>
      </FlexCol>
      {/* <FlexCol margin="5px 10%"> */}
      <Radio
        type="radio"
        value="Influencer"
        name="aa"
        width="20px"
        height="20px"
        margin="5px auto 5px auto"
        onClick={() => AllChoice()}
        checked={checkAllProperty}
      />{' '}
      <span style={{ fontSize: '1rem', fontWeight: '400', color: 'black' }}>
        {t('marketer:create_job_campaign:Select all')}
      </span>
      {/* </FlexCol> */}
      <GridBox>
        {formControls.Categories.map((data: any) => (
          <IconRadioInputComponent
            id={data.value}
            imageUrl={data.icon}
            labels={data.label}
            background={data.background_color}
            labelSize="1em"
            isChecked={form?.aptitude_ids?.includes(data.value)}
            onClick={() => {
              let categoryIds: any = [...form?.aptitude_ids];

              if (categoryIds.includes(data.value)) {
                categoryIds = categoryIds.filter(
                  (category: any) => category !== data.value,
                );
              } else categoryIds = [...categoryIds, data.value];
              setForm({ ...form, aptitude_ids: categoryIds });
            }}
          />
        ))}
      </GridBox>
    </div>
  );
};

export default InfluencerChoiceComponent;
