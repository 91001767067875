import { Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Status from '../../constants/campaignStatusesEnum';

const CampaignStatus = ({ t, status }: any) => {
  const renderStatus = () => {
    switch (status) {
      case Status[0]:
        return (
          <Badge style={{ background: '#09B34D', fontSize: '15px' }}>
            {t(`enumCampaign:${status}`)}
          </Badge>
        );
      case Status[1]:
        return (
          <Badge className="bg-info font-size-12">
            {t(`enumCampaign:${status}`)}
          </Badge>
        );
      case Status[2]:
        return (
          <Badge className="bg-warning font-size-12">
            {t(`enumCampaign:OUT OF BUDGET`)}
          </Badge>
        );
      case Status[3]:
        return (
          <Badge className="bg-danger font-size-12">
            {t(`enumCampaign:${status}`)}
          </Badge>
        );

      case Status[4]:
        return (
          <Badge className="bg-primary font-size-12">
            {t(`enumCampaign:${status}`)}
          </Badge>
        );
      case Status[5]:
        return (
          <Badge className="bg-dark font-size-12">
            {t(`enumCampaign:${status}`)}
          </Badge>
        );
      case Status[6]:
        return (
          <Badge style={{ background: '#FA8072', fontSize: '15px' }}>
            {t(`enumCampaign:${status}`)}
          </Badge>
        );
      case Status[7]:
        return (
          <Badge
            style={{
              background: '#09B34D',
              fontSize: '15px',
              // textShadow: '1px -1px 0px #bababa, -1px 1px 0px #ffffff',
              fontWeight: 'bold',
            }}
          >
            {t(`enumCampaign:${status}`)}
          </Badge>
        );
      default:
        return (
          <Badge style={{ background: '#09B34D', fontSize: '15px' }}>
            {t(`enumCampaign:INPROGRESS`)}
          </Badge>
        );
    }
  };

  return <>{renderStatus()}</>;
};

export default withTranslation()(CampaignStatus);
