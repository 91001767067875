import { floorToFixed } from 'src/common/data/mathToFixed';
import { SocialMediaType } from '../SocialChannel.control';
import facebookLogo from '../../../../../../assets/images/facebook-logo.png';
import instagramLogo from '../../../../../../assets/images/instagram-logo.png';
import twitterLogo from '../../../../../../assets/images/twitter_logo.png';
import youtubeLogo from '../../../../../../assets/images/youtube-logo.png';
import tikTokLogo from '../../../../../../assets/images/tiktok-logo.png';
import facebookPageLogo from '../../../../../../assets/images/facebook_page.png';

const SocialCardControl = () => {
  const onFilterPrice = (
    share_post_prices: number[],
    videoPrice: number,
    type: SocialMediaType,
  ): string => {
    const priceNotNull: number[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const price of share_post_prices) {
      // eslint-disable-next-line no-continue
      if (!price) continue;
      priceNotNull.push(price);
    }
    if (priceNotNull?.length <= 0) return '0';
    const maxPrice: number = Math.max(...priceNotNull);
    const minPrice: number = Math.min(...priceNotNull);
    switch (type) {
      case SocialMediaType.YOUTUBE:
        return `${floorToFixed(videoPrice, 2)} USDT`;
      case SocialMediaType.TIKTOK:
        return `${floorToFixed(videoPrice, 2)} USDT`;
      default:
        if (maxPrice === minPrice) return `${floorToFixed(maxPrice, 2)} USDT`;
        return `${floorToFixed(minPrice, 2)} - ${floorToFixed(maxPrice, 2)}  USDT`;
    }
  };

  const onGetLogoSocialByType = (type: SocialMediaType): string => {
    switch (type) {
      case SocialMediaType.FACEBOOK:
        return facebookLogo;
      case SocialMediaType.INSTAGRAM:
        return instagramLogo;
      case SocialMediaType.TWITTER:
        return twitterLogo;
      case SocialMediaType.YOUTUBE:
        return youtubeLogo;
      case SocialMediaType.TIKTOK:
        return tikTokLogo;
      default:
        return facebookLogo;
    }
  };

  const onGetLogoSocialByTypeRenew = (type: SocialMediaType): string => {
    switch (type) {
      case SocialMediaType.FACEBOOK_PERSONAL:
        return facebookLogo;
      case SocialMediaType.FACEBOOK:
        return facebookPageLogo;
      case SocialMediaType.INSTAGRAM:
        return instagramLogo;
      case SocialMediaType.TWITTER:
        return twitterLogo;
      case SocialMediaType.YOUTUBE:
        return youtubeLogo;
      case SocialMediaType.TIKTOK:
        return tikTokLogo;
      default:
        return facebookLogo;
    }
  };

  return { onFilterPrice, onGetLogoSocialByType, onGetLogoSocialByTypeRenew };
};

export default SocialCardControl;
