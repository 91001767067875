import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { layoutTheme } from 'src/constants/layout';
import logo from '../../../assets/images/flupower/sleeplogo.png';
import logoWhite from '../../../assets/images/flupower/footpagelogo.png';

import control from './PrivacyPolicy.control';
import './PrivacyPolicy.style.scss';

const PrivacyPolicyPage = () => {
  const { layoutMode } = control();
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container text-white">
      <ul className="bg-bubbles">
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      <Link to="/" className="d-block auth-logo" style={{ zIndex: 2 }}>
        <img
          src={layoutMode === layoutTheme.DARKMODE ? logoWhite : logo}
          alt=""
          height="28"
        />
      </Link>
      <div className="card">
        <span className="header">{t('Privacy & Policy')}</span>
        <hr />
        <div className="content-container">
          <p className="content">{t('1. Introduction')}</p>
          <p className="content">
            {t(
              'The Company is Flupower Limited., who is the intermediate between the Influencer and the Marketer. The Company needs to create a new powerful communication.',
            )}
          </p>
          <p className="content">
            {t(
              'The Company believes that building awareness from the user experience coupled with brand engagement is essential to making communication meaningful, memorable, and beyond conventional advertising. These will cause a new communication phenomenon more efficient and more accurate measurement.',
            )}
          </p>
          <p className="content">
            {t(
              'Due to the intermediary between Influencer and Marketer, the Company is obliged to collect your information to adopt and/or communicate to people interested in participating in the operating system of Flupower (“Flupower Platform”). The Company, Flupower Limited., is a Data Controller in accordance with the Personal Data Protection Act B.E. 2562',
            )}
          </p>
          <p className="content">
            {t(
              'Concerning the privacy of those of you who are honored to create a new communication phenomenon. We have established this Privacy Policy to inform all of you who are the Influencer are aware of the following matters',
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t('Introduction')}</li>
              <li>{t('Who is the Company?')}</li>
              <li>{t('The Company collects your information for what purpose?')}</li>
              <li>
                {t('What information does the Company collect to use or transmit?')}
              </li>
              <li>{t('Whom can the Company allow access to your information?')}</li>
              <li>
                {t(
                  'How does the Company consider the rights of the person who owns the information?',
                )}
              </li>
              <li>{t('Usage, Maintenance, Protection')}</li>
              <li>{t('Policy amendment.')}</li>
            </ul>
          </p>
          <p className="content">
            {t(
              'This Privacy Policy applies to information the Company has collected from',
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t('Flupower Platform')}</li>
              <li>
                {t(
                  'Emails, texts, and other electronic messages you communicate with the Flupower Platform.',
                )}
              </li>
              <li>
                {t(
                  'Mobile application and computer that you download the Flupower Platform with an interoperable operating system solely without having to go through a browser (non-browser-based interaction) between you and the Flupower Platform.',
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t('This Privacy Policy does not apply to information obtained from')}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(
                  'Offline channels or through other means operated by the Company or third party (including affiliates and its subsidiaries), or',
                )}
              </li>
              <li>
                {t(
                  'The application, the content (including advertisements), or any means of third-party (including affiliates and its subsidiaries) may link or access the Flupower Platform.',
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              `The Company asks you to read this Privacy Policy carefully to understand the Company's policy, procedures, and practices for handling your information. If you see that the policy, procedures, and practices do not meet your desires, you can choose not to use the Flupower Platform. However, you consider that the Company's policy, procedures, and practices, adequately protect your information, the Company would like you to confirm your acknowledgment and accept this policy by pressing the “Accept” button displayed on your screen before using Flupower Platform.`,
            )}
          </p>
          <p className="content">
            {t(
              `The Company would like to inform you whether the Company may resolve changes to this Privacy Policy to comply with the rules, regulations, announcements, or laws that will occur in the future (Please find in the topic Policy Amendment). The Company will do its best to notify you of such changes (without regard to the Company's obligations). The Company would like to encourage you to read the Privacy Policy from time to time to understand the policy, procedures, and practices regarding your information. If the Flupower Platform provides notice of a policy amendment and you still consider that your information is properly protected from the amended policy, the Company would like you to accept this Privacy Policy (Revised Edition) by pressing the “Accept” button displayed on your screen before using Flupower Platform further`,
            )}
          </p>
          <p className="content">
            {t(
              `If you are a person under 20 years of age, the Company would like to inform you that after you press the 'Accept' button, the Company may have an additional process for you which the Company will notify you according to the contact channel you specified.`,
            )}
          </p>
          <p className="content">
            {t(
              `However, in some cases where you give consent to the Company to control your personal data, you can withdraw such consent provided to the Company at any time by the means and channels set forth by the Company in Section 2. Such withdrawal of consent will not affect the Company's actions that have been performed prior to receiving the notice of withdrawing your consent.`,
            )}
          </p>
          <p className="content">{t(`2. Who is the Company?`)}</p>
          <p className="content">
            {t(
              `Flupower Limited. was registered as a Limited Partnership under Hongkong laws on December 31, 2021, to operate marketing services through online media. The Company's head office is located at 11/F WHEELOCK HSE 20 PEDDER ST CENTRAL, HONG KONG 100020`,
            )}
          </p>
          <p className="content">
            {t(
              `You can contact the Company as a data controller to inquire more about the Privacy Policy and the Company's practices in relation to the protection of personal data or request that the Company take any action via email:contact@Flupower.com`,
            )}
          </p>
          <p className="content">
            {t(`3. The Company collects your information for what purpose?`)}
          </p>
          <p className="content">
            {t(
              `The Company collects your information and use it for the following purposes`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t(`For creating a username.`)}</li>
              <li>{t(`For hiring and working on the Flupower Platform.`)}</li>
              <li>
                {t(
                  `For presenting the Flupower Platform and content on the Flupower Platform to you.`,
                )}
              </li>
              <li>
                {t(
                  `For providing information, products, or services that you request from the Company.`,
                )}
              </li>
              <li>
                {t(
                  `For contacting between you and the Company and/or other people regarding the Company's products and service and of the third-party, which may include presenting your information in different places for advertising and promoting yourself according to the Flupower Platform's working style throughout your continued use of the Flupower Platform.`,
                )}
              </li>
              <li>
                {t(
                  `For concluding a statistical database about the Company's services.`,
                )}
              </li>
              <li>
                {t(
                  `For providing notices to you about account / subscription, including notices of expiration and renewal.`,
                )}
              </li>
              <li>
                {t(
                  `To fulfill the Company's obligations and enforce our rights arising from any contract that the Company has done to you, including invoicing and billing.`,
                )}
              </li>
              <li>
                {t(
                  `To notify you of any changes on the Flupower Platform or any products or services presented or provided through the Flupower Platform.`,
                )}
              </li>
              <li>
                {t(
                  `To enable you to participate in the use of the Flupower Platform in a special feature.`,
                )}
              </li>
              <li>
                {t(
                  `For disclosure to the Company's group and its partners (meaning companies in which Flupower holds shares or a company that acquires shares of Flupower or a company that enters into a business contract with Flupower) for the benefit of offering products and services to you.`,
                )}
              </li>
              <li>
                {t(
                  `To collect the data as the basis for designing new services, selecting the proper marketing campaign, and presenting the new marketing campaign.`,
                )}
              </li>
              <li>
                {t(
                  `To disclose to the Revenue Department and comply with the duty of tax withholder and retain data for a period specified by the laws.`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              `Suppose the Company uses automatic data collection technologies. In that case, it may allow the Company to obtain a statistical database that includes personal information. The Company may maintain or link such information with personal information collected by the Company by other means or receiving information from third parties. The information obtained will help the Company to improve the Flupower Platform to provide better services and more in line with the needs, which enables the Company to perform the following actions:`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(
                  `Evaluate the number and usage patterns of your Company's followers.`,
                )}
              </li>
              <li>
                {t(
                  `Keep information about your preferences. It allows the Company to adapt the Flupower Platform to suit your interests.`,
                )}
              </li>
              <li>{t(`Speed up your search.`)}</li>
              <li>{t(`Remember when you use the Flupower Platform again.`)}</li>
            </ul>
          </p>
          <p className="content">
            {t(`4. What information does the Company collect for use or transfer?`)}
          </p>
          <p className="content">
            {t(
              `To provide the Company's service, the Company must ask you to fill in your information before using the Flupower Platform. If you do not provide data to the Company, it may result in the Company being unable to offer products and services that are suitable and meet your needs. Sometimes, the Company is obliged to collect your information from other sources (other than gathering information from you), which the Company will notify you within a period required by laws and in the event that the Company will use your said information to disclose the Company will inform you before the disclosure of that information for the first time.`,
            )}
          </p>
          <p className="content">
            {t(
              `The Company has guidelines and methods for collecting your information as follows:`,
            )}
          </p>
          <p className="content">{t(`4.1 Data collection`)}</p>
          <p className="content">
            {t(`The Company will collect the following information:`)}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(
                  `Any information which makes you personally identifiable, whether directly or indirectly, is regarded as ''Personal Information'' `,
                )}
              </li>
              <li>
                {t(
                  `Information about you, including your name, surname, age, interest, aptitude, and other properties. Suppose the Company needs to collect sensitive personal information, including gender, race, ethnicity, belief and /or opinion on different aspects of sexual behavior, personal history, and health information. In that case, the Company will inform you of the purpose of use and ask for your consent again.`,
                )}
              </li>
              <li>
                {t(
                  `Contact information such as email address, working address, telephone number, social media account.`,
                )}
              </li>
              <li>
                {t(
                  `Social media contact information, number of friends, number of posts, number of interactions with friends for your post.`,
                )}
              </li>
              <li>
                {t(
                  `consumer behavior, lifestyle, health information, preference information, opinions, and/or`,
                )}
              </li>
              <li>
                {t(
                  `Your personal information that is made public with your consent, such as the personal information you display through social media, including but not limited to your profile photo on your computer's network address (IP address), Brief biographical details on your account (Bio).`,
                )}
              </li>
              <li>
                {t(
                  `Any other information that does not identify you personally can be used in combination to identify you. It may include personal information such as the number of followers on social media and product offerings or your service until able to identify you and/or information about your internet connection and the device you use to access the Flupower Platform and usage data.`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">{t(`4.2 Storage method`)}</p>
          <p className="content">
            {t(`The Company collects your information in the following ways:`)}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(
                  `By means of communicating with you directly, and you provide information through that communication method.`,
                )}
              </li>
              <li>
                {t(
                  `With an automated method of entering the Flupower Platform through the website. The information obtained automatically may include usage details, addresses on a computer's network (IP addresses), and information obtained through cookies and other tracking technologies.`,
                )}
              </li>
              <li>
                {t(
                  `By means of contacting other parties such as the Marketers or other persons associated with the Flupower Platform and including its affiliates; group companies, authorized person of the Company to operate under the Flupower Platform and their business partners.`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              `The information which the Company receives from or through the Flupower Platform in various forms. These include information that we may use automatic data collection technologies to collect that information which consists of`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(
                  `The information that you have filled in forms on the Flupower Platform, including information provided while`,
                )}
              </li>
              <li>{t(`Register for the Flupower Platform.`)}</li>
              <li>{t(`Subscribing the Company's services.`)}</li>
              <li>{t(`Public messages over the internet.`)}</li>
              <li>{t(`Requesting additional services; or`)}</li>
              <li>
                {t(`Information you report problems with the Flupower Platform.`)}
              </li>
              <li>
                {t(
                  `Records and copies of all forms of communication with each other, including email communications.`,
                )}
              </li>
              <li>
                {t(
                  `Details of transactions you make through the Flupower Platform, such as your operations through the Flupower Platform.`,
                )}
              </li>
              <li>
                {t(
                  `Details of the implementation of your order. You may be requested to provide financial information prior to placing an order through the Flupower Platform.`,
                )}
              </li>
              <li>{t(`Your search terms are listed on the Flupower Platform.`)}</li>
              <li>
                {t(
                  `Information that you published or displayed in public of the Flupower Platform (“Notice“), or send information to the other party using the Flupower Platform or third parties (“User Content“)`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              'when you publish Notice and/or User Content of other users via the Flupower Platform, the Company assumes you already know and accept the risks that may arise as though you can configure the privacy settings for such information by logging into your personal account. The Company would like to inform you that no security measure is perfect or inaccessible.',
            )}
          </p>
          <p className="content">
            {t(
              'The Company cannot control the actions of other users who may publish Notice and/or User Content you provide to others, so the Company cannot and do not guarantee that an unwanted person will not see the publication and/or transmission of User Content. However, the Company has realized and fully complied with international information and technology security standards.',
            )}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(
                  `Details of your visit to the Flupower Platform also include traffic data, location data, logs, other communication information, and the sources you access and use on the Flupower Platform.`,
                )}
                {t(
                  `Information about computers or your internet connection also includes IP addresses, operating systems, and browser type.`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">{t(`4.3 Storage period`)}</p>
          <p className="content">
            {t(
              `For your benefit, the Company will collect your personal information for as long as is reasonably necessary to achieve the objectives stated in this Privacy Policy, to establish and use or defend against legal claims. However, if there are any legal requirements, we may need to retain your personal data longer than that period.`,
            )}
          </p>
          <p className="content">
            {t(
              `In addition, the Company would like to inform you that if you have exercised your right to delete your personal data, it will be destroyed immediately, and the Company will not be able to recover it in any case.`,
            )}
          </p>
          <p className="content">
            {t(`5. Whom does the Company allow access to your information?`)}
          </p>
          <p className="content">
            {t(`5.1 Use, disclosure, or transmission of information`)}
          </p>
          <p className="content">
            {t(
              `In addition to collecting the personal information, the Company is obliged to use, disclose or transmit your information to the Influencer or the Marketer or any other person related to the Flupower Platform whether in the country or abroad, including:`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t(`Subsidiaries, Affiliates, Group companies.`)}</li>
              <li>{t(`Government agency.`)}</li>
              <li>
                {t(
                  `The authorized person of the Company to operate under the Flupower Platform and their business partners.`,
                )}
              </li>
              <li>
                {t(
                  `Contractors, service providers, or other third parties that support the Company's business enter into a contract to provide and/or receive services from the Company, whether located in the country or abroad. Such entities agree to comply with this Privacy Policy, including without limitation Influencer Management Company, Influencer Manager ( if you are applying for service under Influencer Management Company or Influencer Manager), the technology company, telecommunication company, software development company, information technology security company, communication or PR Company, logistic company.`,
                )}
              </li>
              <li>
                {t(
                  `Buyer or other successors, in the event of a merger, divestment, general restructuring, reorganization, liquidation or sale, or other transfer of partial assets or all of us whether by doing business as usual or as part of any bankruptcy, liquidation, or similar process which personal data held by the Company in connection with the Flupower Platform for users is included in the transferred assets.`,
                )}
              </li>
              <li>
                {t(
                  `Third parties who desire to market product or service to you when you consent to disclose your information for such purposes.`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              `5.2 Sending or transferring information to persons in other countries`,
            )}
          </p>
          <p className="content">
            {t(
              `From time to time, the Company may need to send or transfer your information to persons in other countries in order to carry out the purposes specified in the Privacy Policy. However, the Company will use its best efforts to disclose or transfer your personal information to such persons or servers in other countries that are a destination country with adequate personal data protection standards or equivalent to Hongkong.`,
            )}
          </p>
          <p className="content">
            {t(
              `In the event that the Company requests for your consent to transfer personal data abroad by informing you to consider and have received your consent. You can revoke such consent through the channels specified by the Company in Section 2.`,
            )}
          </p>
          <p className="content">
            {t(
              `5.3 Use of Cookies by third parties and other tracking technologies.`,
            )}
          </p>
          <p className="content">
            {t(
              `Some contents and applications, including advertisements on the Flupower Platform, are operated by third parties, including advertising networks (ad networks) and server, content provider, and application service providers. These third parties may use cookies solely or in conjunction with other websites, beacons, or tracking technologies to collect information about you when you use the Flupower Platform. The information they collect may relate to your personal information, or service providers may collect information. These include personal information about online activities over time or from the Flupower Platforms and other online services. They may use this information to provide ads that match their interests (behavioral) of the content of interest. The Company will request your consent once again.`,
            )}
          </p>
          <p className="content">{t(`5.4 Other Disclosures`)}</p>
          <p className="content">
            <ul>
              <li>
                {t(
                  `To comply with a court order, laws, or legal process, including to act on the request of legal authorities or governmental.`,
                )}
              </li>
              <li>
                {t(
                  `To enforce the Company's Terms of Use and other contracts, including invoicing and billing.`,
                )}
              </li>
              <li>
                {t(
                  `If the Company deems that disclosure is necessary or appropriate for protecting rights, property, or the safety of the Company, its customers, or other persons also includes exchanging information between the Company and other organizations for fraud prevention purposes and reducing the risk of default (credit risk).`,
                )}
              </li>
              <li>
                {t(
                  `To achieve objectives related to historical documentation or archival for public benefit or relating to research studies or statistics which have provided appropriate safeguards.`,
                )}
              </li>
              <li>
                {t(`To protect the rights and freedoms of personal data subjects.`)}
              </li>
              <li>
                {t(
                  `To prevent or suppress a danger to the life, body, or health of a person.`,
                )}
              </li>
              <li>
                {t(
                  `It is necessary to perform duties in carrying out the mission for the Company's public interest or performing duties in the exercise of state powers given to the Company.`,
                )}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              `6. How does the Company consider the rights of the owner of the information?`,
            )}
          </p>
          <p className="content">
            {t(`As the owner, you have the rights as follows;`)}
          </p>
          <p className="content">{t(`6.1 Right to access your personal data.`)}</p>
          <p className="content">
            {t(
              `You can access your personal data within the responsibility of the Company to check and change your personal information and visit your personal account page by logging into the Flupower Platform at any time.`,
            )}
          </p>
          <p className="content">
            {t(
              `The appropriate access or use of the information on the Flupower Platform, including User Content is subject to the Terms of Use of the Company.`,
            )}
          </p>
          <p className="content">
            {t(
              `6.2 Right to request disclosure of the acquisition of personal data by indirect means.`,
            )}
          </p>
          <p className="content">
            {t(
              `If the Company has collected information from other sources as specified by you in Section 4, you may request that the Company disclose such information acquisition in accordance with the methods and channels specified by the Company in Section 2.`,
            )}
          </p>
          <p className="content">
            {t(
              `6.3 Right to object to the collection, use, or disclosure of your personal data.`,
            )}
          </p>
          <p className="content">
            {t(
              `The Company recognizes the importance of your personal information so that you can object to the collection, use, or disclosure of your personal information to the Company at any time unless the Company has a legitimate right to reject your objection.`,
            )}
          </p>
          <p className="content">
            {t(
              `6.4 Right to delete or request destruction or temporarily suspend or convert data into an anonymous data format.`,
            )}
          </p>
          <p className="content">
            {t(
              `Unless the Company has the legal right to refuse or the Company is obliged to comply with the specific laws or the personal information that the Company collects for freedom of expression, you may delete your information from the Flupower Platform and keep a copy of your information, including requesting the Company to suspend the use of your personal information temporarily.`,
            )}
          </p>
          <p className="content">
            {t(
              `Suppose the Company does not comply with the policy or relevant laws. In that case, you can ask the Company to destroy or temporarily suspend or convert the information into an anonymous data format unless the laws require the Company to continue maintaining that information.`,
            )}
          </p>
          <p className="content">
            {t(
              `6.5 Right to request to update information completely and does not cause misunderstandings.`,
            )}
          </p>
          <p className="content">
            {t(
              `In the event that your personal information is not current, incomplete, which may cause misunderstandings to third parties, you can continue to update your personal information completely and not be offensive at any time through the Flupower Platform by yourself. However, if the Company has an error or has problems or obstacles causing you not to do this by yourself, you can inform the Company to proceed according to the methods and channels specified by the Company in section 2.`,
            )}
          </p>
          <p className="content">
            {t(`6.6 Right to complain to relevant agencies`)}
          </p>
          <p className="content">
            {t(
              `Suppose the Company is a data controller following the definition in the Personal Data Protection Act. If the Company does not comply with your request or exercise of rights, you, as the personal data subject, have the right to submit a complaint with the Company to the relevant authorities and are responsible for it.`,
            )}
          </p>
          <p className="content">
            {t(
              `The Company would like to clarify that the exercise of your rights as shown in this section 6 must not cause damage to the rights and freedoms of other people. If it is such a case or in the case of the Company is required to comply with the laws or court order, the Company reserves the right not to process your request.`,
            )}
          </p>
          <p className="content">
            {t(`7. Use, maintenance, protection of personal data.`)}
          </p>
          <p className="content">
            {t(
              `The Company may use the following automatic data collection technologies:`,
            )}
          </p>
          <p className="content">
            {t(
              `7.1 Save information about visiting the website or cookies or browser cookies`,
            )}
          </p>
          <p className="content">
            {t(
              `Cookies are small files stored on your computer's hard drive. You may refuse to accept browser cookies made by activating the appropriate settings on your browser. However, if you select a setting like this, you may not be able to access some of the Flupower Platform because the Flupower Platform creates Cookies coming up, so you have to adjust the browser's rejection of the Cookies that the Flupower Platform created.`,
            )}
          </p>
          <p className="content">{t(`7.2 Web Beacons.`)}</p>
          <p className="content">
            {t(
              `The Company's emails may contain small electronic files known name in Web Beacons operators are sometimes known as gifs, pixel tags, and single-pixel gifs, which allows the Company to count users receiving the Company's emails.`,
            )}
          </p>
          <p className="content">
            {t(
              `7.3 Choices on how to use the Flupower Platform and disclose your information.`,
            )}
          </p>
          <p className="content">
            {t(
              `The Company is committed to providing various options to you for the personal information you provide to the Company. The Company has established mechanisms for you to provide control over your information as follows`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t(`Tracking technologies and advertising`)}</li>
            </ul>
          </p>
          <p className="content">
            {t(
              `You can set your browser to reject all or some browser cookies or to alert you when there are transmission cookies. To study how to deal with the settings for Flash Cookies, please visit our website and locations for Flash Player on the Flupower Platform of Adobe's Flupower Platform. If you cancel work or reject cookies, please note that some parts of this website may not be accessible or not working properly.`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>
                {t(`Disclosure of your information for third party advertising`)}
              </li>
            </ul>
          </p>
          <p className="content">
            {t(
              `If you do not want the Company to share your personal information with third parties that are not affiliated company or non-representative for the promotion, you can withdraw your consent. You can also revoke your consent at any time by logging in to the Flupower Platform and making adjustments in the user preferences section of your account, by selecting or unchecking the relevant settings box, or by sending an email to contact@Flupower.com with your wishes.`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t(`Offer promotion`)}</li>
            </ul>
          </p>
          <p className="content">
            {t(
              `If you do not wish your email address/contact information to be used for promotional purposes or our services or of a third party, you can choose to withdraw your consent or log in to the Flupower Platform to modify the user preferences of your account by selecting or canceling related settings box or by sending an email to contact@Flupower.com with your wishes.`,
            )}
          </p>
          <p className="content">
            {t(
              `If you receive promotional emails from the Company, you may send a reply email to request that you cancel sending other emails in the future. Such cancellation does not apply to previously provided information due to purchase, warranty registration, services or products, or other transactions.`,
            )}
          </p>
          <p className="content">
            <ul>
              <li>{t(`Targeting advertising`)}</li>
            </ul>
          </p>
          <p className="content">
            {t(
              `Where you have given the Company your consent to collect, use, and disclose to deliver ads based on the audience's preferences, which is the advertiser's target audience. (advertisers' target-follower preferences), you can choose to revoke your consent. You can adjust user preferences for advertisements in your account by selecting or deselecting the corresponding settings or by sending an email to contact@Flupower.com with your wishes.`,
            )}
          </p>
          <p className="content">
            {t(
              `The Company has no collection control measures or use of your information by third parties to perform interest-based advertising. However, these third parties may do so that you can choose not to have your information collected or being used in this way.`,
            )}
          </p>
          <p className="content">{t(`8. Amendment of Privacy Policy`)}</p>
          <p className="content">
            {t(
              `The Company reserves the right to amend or change the Privacy Policy (including Revised Edition) in accordance with the rules, regulations, announcements, or laws that will occur in the future. The Company will announce any changes to this Privacy Policy on the Flupower Platform website. If there is a material change on how to take care of that personal information, the Company will remind on the main the Flupower Platform, the date of the Privacy Policy was last updated it will be displayed at the top of the page of the Flupower Platform. The Company would like to remind you that for your benefit, you will be required to monitor the Flupower Platform regularly to be aware of any changes that might happen, including the policy (if any), and you have to make sure (as the duty) the emails provided on the Flupower Platform are up to date and can be used to send and receive messages as usual.`,
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
