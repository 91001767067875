import styled from 'styled-components';
import Selection from 'react-select';

const InfluencerChoiceStyle = () => {
  const Radio = styled.input<{
    width?: string;
    margin?: string;
    background?: string;
    height?: string;
  }>`
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background: ${({ background }) => background};
    cursor: pointer;
    :disabled {
      cursor: no-drop;
    }
  `;
  const FlexRow = styled.nav<{
    width?: string;
    marginTop?: string;
    background?: string;
    height?: string;
    boxshadow?: string;
    justifycontent?: string;
    borderTop?: string;
  }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({ justifycontent }) => justifycontent};
    align-items: center;
    position: relative;
    padding-top: 10px;
    text-align: center;
    box-shadow: ${({ boxshadow }) => boxshadow};
    background: ${({ background }) => background};
    @media screen and (min-width: 500px) {
      width: ${({ width }) => width};
    }
    @media screen and (max-width: 499px) {
      width: 100%;
    }
    margin-top: ${({ marginTop }) => marginTop};

    height: ${({ height }) => height};
    border-top: ${({ borderTop }) => borderTop};
  `;
  const DivContent = styled.form<{
    width?: string;
    marginTop?: string;
    background?: string;
    height?: string;
    boxshadow?: string;
    justifycontent?: string;
    borderTop?: string;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifycontent }) => justifycontent};
    height: 550px;
    text-align: start;
    margin-bottom: 1rem;
    border-top: ${({ borderTop }) => borderTop};
    // background-color: #fbfaff;
    margin-top: ${({ marginTop }) => marginTop};
    overflow-y: auto;
    overflow-x: hidden;
  `;
  const GridBox = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(6, 1fr);
    gap: 0.1rem;
    justify-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    margin: 10px auto;
    margin-left: 108px;
    // background: green;
    @media (max-width: 460px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  `;

  const FlexCol = styled.div<{
    width?: string;
    // background?: string;
    height?: string;
    margin?: string;
    borderTop?: string;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // padding: 10px;
    text-align: center;
    // background: green;
    // width: 300px;
    border-top: ${({ borderTop }) => borderTop};
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  `;
  const Texts = styled.p<{
    fontsize?: string;
    // background?: string;
    height?: string;
    margin?: string;
    fontweight?: string;
  }>`
    font-size: ${({ fontsize }) => fontsize};
    font-weight: ${({ fontweight }) => fontweight};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: ${({ margin }) => margin};

    @media (max-width: 450px) {
      font-size: 18px;
    }
  `;
  const Select = styled.select<{
    width?: string;
    height?: string;
    borderradius?: string;
  }>`
    // position: absolute;
    // object-fit: cover;
    border-radius: ${({ borderradius }) => borderradius};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: auto;
    cursor: pointer;
    // background-color: #fbfaff;
  `;
  const Option = styled.option<{
    width?: string;
    height?: string;
    borderradius?: string;
  }>`
    // position: absolute;
    // object-fit: cover;
    border-radius: ${({ borderradius }) => borderradius};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: auto;
    background-color: #fbfaff;
    :hover {
      background-color: #abb2b9;
    }
  `;

  const Btn = styled.button<{
    width?: string;
    height?: string;
    borderradius?: string;
    background?: string;
  }>`
    // position: absolute;
    // object-fit: cover;
    border-radius: ${({ borderradius }) => borderradius};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: 20px auto;
    background-color: ${({ background }) => background};
    border: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    :hover {
      background-color: #0a598a;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
  `;
  const SelectionStyle = styled(Selection)`
    width: 90%;
    height: 30px;
    margin: 20px auto;
    cursor: pointer;
    // background-color: #fbfaff;
  `;

  return {
    DivContent,
    Texts,
    FlexRow,
    FlexCol,
    Radio,
    GridBox,
    Select,
    Option,
    Btn,
    SelectionStyle,
  };
};

export default InfluencerChoiceStyle;
