/* eslint-disable no-unused-vars */
/**
 *  interface for Login Type
 */
// eslint-disable-next-line no-shadow
const enum TwoFactorTypes {
  GET_TWO_FACTOR_SECRET = '@@two_factor/GET_TWO_FACTOR_SECRET',
  GET_TWO_FACTOR_SECRET_SUCCESS = '@@two_factor/GET_TWO_FACTOR_SECRET_SUCCESS',

  GET_TWO_FACTOR_STATUS = '@@two_factor/GET_TWO_FACTOR_STATUS',
  GET_TWO_FACTOR_STATUS_SUCCESS = '@@two_factor/GET_TWO_FACTOR_STATUS_SUCCESS',

  ENABLE_TWO_FACTOR = '@@two_factor/ENABLE_TWO_FACTOR',

  DISABLE_TWO_FACTOR_REQUEST = '@@two_factor/DISABLE_TWO_FACTOR_REQUEST',
  DISABLE_TWO_FACTOR_CONFIRM = '@@two_factor/DISABLE_TWO_FACTOR_CONFIRM',
}

export default TwoFactorTypes;
