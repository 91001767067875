import noPostImage from 'src/assets/images/no-post-image.jpg';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
// import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useTranslation } from 'react-i18next';
import { floorToFixed, removeDecimal } from 'src/common/data/mathToFixed';

const InstagramPostView = ({ socialPost }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          backgroundColor: '#eeeded',
          width: '100%',
          padding: 10,
          borderRadius: '8px',
          wordBreak: 'break-all',
        }}
      >
        {socialPost.source}
      </div>
      <div className="influencer-post-content">
        <div className="influencer-post-video-content">
          <div className="image-post-influencer-control">
            <img
              src={socialPost.thumbnails || noPostImage}
              alt="influencer"
              className="image-post-influencer"
            />
          </div>
          {socialPost.message && (
            <div
              style={{
                backgroundColor: '#eeeded',
                width: '100%',
                padding: 10,
                borderRadius: '8px',
                wordBreak: 'break-all',
                marginTop: '10px',
              }}
            >
              {socialPost.message}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="influencer-impression-count">
        <FavoriteBorderIcon className="icon" />
        <div className="text">
          {removeDecimal(floorToFixed(socialPost.like, 1) as string)}{' '}
          {t('marketer:job_detail:favorites')}
        </div>
        <ChatBubbleOutlineOutlinedIcon className="icon" />
        <div className="text">
          {removeDecimal(floorToFixed(socialPost.comment, 1) as string)}{' '}
          {t('marketer:job_detail:comments')}
        </div>
      </div>
    </>
  );
};

export default InstagramPostView;
