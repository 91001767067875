import { Modal, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import config from 'src/config';

const ScriptModal = ({ title, isOpen, handleToggle, handleClose, data, t }: any) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const { WebsiteInfo } = useSelector((state: any) => ({
    RequestSize: state.WebsiteInfo.requestSize,
    WebsiteInfo: state.WebsiteInfo.websiteInfo,
  }));

  let manual_ad_script: any;
  if (data) {
    manual_ad_script = `<script async src="${
      config.reactAppAdApiUrl
    }manual-render-ads.js"></script> <ins class="Flupower" style="width: ${
      data.width ? `${data.width}px` : 0
    };height: ${data.height ? `${data.height}px` : 0}${
      data.banner_format === 'SLIDE'
        ? '; position: -webkit-sticky; position: sticky;top: 0;'
        : ''
    }" data-web-key="${
      data.website.website_key ? data.website.website_key : ''
    }" data-creative-web-key="${data.key ? data.key : ''}"></ins>`;
  }

  const textAreaRef = useRef<HTMLInputElement>(null);

  // Copy to clipboard
  const handleCopy = (val: string, copyButton: number) => {
    navigator.clipboard.writeText(val);

    if (copyButton === 1) {
      setCopySuccess(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copySuccess) setCopySuccess(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copySuccess]);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0 font-size-24" id="myModalLabel">
          {t(`${title}`)}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body p-10">
        <CardBody>
          <label className="font-size-20" htmlFor="script-header">
            <strong>{t('script header')}</strong>
          </label>
          <p className="font-size-18">{t('script desc')}</p>
          <code className="input-group">
            {data ? (
              <input
                className="form-control "
                ref={textAreaRef}
                type="text"
                value={
                  WebsiteInfo.status === 'APPROVED'
                    ? manual_ad_script
                    : t('Waiting for approve')
                }
                readOnly
              />
            ) : (
              ''
            )}

            <button
              type="button"
              className="input-group-text"
              onClick={() => {
                handleCopy(
                  WebsiteInfo.status === 'APPROVED'
                    ? manual_ad_script
                    : 'Waiting for approve',
                  1,
                );
              }}
            >
              {!copySuccess ? (
                <i className="far fa-copy" />
              ) : (
                <>
                  <i className="fas fa-check-circle" />{' '}
                </>
              )}
            </button>
          </code>
        </CardBody>
      </div>
    </Modal>
  );
};

export default withTranslation()(ScriptModal);
