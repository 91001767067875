import MarketerCampaignSummaryWidgetComponent from 'src/components/Advertiser/Marketer/MarketerDashboard/MarketerCampaignSummaryWidget/MarketerCampaignSummaryWidget.component';
import MarketerCampaignIndex from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/marketerCampaignIndex';
import WalletWidget from 'src/components/Common/WalletWidget';
import './MarketerDashboard.style.scss';

const MarketerDashboardPage = () => {
  return (
    <div className="page-content">
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="card-shadow-sm">
            <WalletWidget />
          </div>
        </div>
        <div className="col-12 col-md-8">
          <MarketerCampaignSummaryWidgetComponent />
        </div>
      </div>
      <div className="col-12">
        <MarketerCampaignIndex />
      </div>
    </div>
  );
};

export default MarketerDashboardPage;
