/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 *  interface for Login Type
 */
const enum ForgotPwdTypes {
  FORGOT_PASSWORD = '@@forgot/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = '@@forgot/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = '@@forgot/FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD = '@@forgot/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = '@@forgot/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = '@@forgot/RESET_PASSWORD_ERROR',
}

export default ForgotPwdTypes;
