export const floorToFixed = (number: number | string, fixed: number) => {
  if (!number || !fixed) return 0;
  // const multiplyFixed = 10 ** fixed;
  const numeric =
    typeof number === 'number' ? number : parseFloat(number.replace(/,/g, ''));
  const numString = (numeric || 0).toFixed(fixed);

  return insertComma(numString);
};

export const roundToFixed = (number: number | string, fixed: number) => {
  if (!number || !fixed) return 0;
  const multiplyFixed = 10 ** fixed;
  const numeric = typeof number === 'number' ? number : +number;
  // const numString = (
  //   Math.round((numeric || 0) * multiplyFixed) / multiplyFixed
  // ).toFixed(fixed);
  const numString = (numeric || 0).toFixed(fixed);
  return insertComma(numString);
};

export const removeDecimal = (value: any) => {
  const intValue = value?.toString().split('.')[0];
  const decimal = value?.toString().split('.')[1];
  if (parseInt(decimal, 10) === 0) {
    return intValue;
  }
  return value;
};

const insertComma = (numString: string) => {
  const [numInt, decimal] = numString.split('.');
  const commaInt = (+numInt).toLocaleString();
  return `${commaInt}.${decimal}`;
};
