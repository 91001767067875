import MarketerCategoryTypes from './actionTypes';

export const createMarketerCategory = (name: string) => ({
  type: MarketerCategoryTypes.CREATE_MARKETER_CATEGORY,
  name,
});

export const getMarketerCategory = () => ({
  type: MarketerCategoryTypes.GET_MARKETER_CATEGORY,
});

export const getMarketerCategorySuccess = (payload: any) => ({
  type: MarketerCategoryTypes.GET_MARKETER_CATEGORY_SUCCESS,
  payload,
});
