import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

export const createDeleteThirdPartyData = async (third_party_data_type: any) => {
  const mutation = gql`
    mutation createDeleteThirdPartyData($third_party_data_type: thirdPartyData!) {
      createDeleteThirdPartyData(third_party_data_type: $third_party_data_type)
    }
  `;

  const res = await client.mutate({
    mutation,
    variables: { third_party_data_type },
    fetchPolicy: 'no-cache',
  });

  if (res.data.createDeleteThirdPartyData) {
    notify(i18n.t('success:A request for deletion has been received.'), 'success');
  } else {
    notify(
      i18n.t('error:The request to delete the data already exists in the system.'),
      'error',
    );
  }
};

export const createSocialMediaInfoGql = async (param: any) => {
  const mutation = gql`
    mutation createSocialMediaInfo($param: CreateSocialMediaInfoInput!) {
      createSocialMediaInfo(createSocialMediaInfoInput: $param) {
        name
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    return res.data.createSocialMediaInfo;
  } catch (error) {
    return null;
  }
};

export const createRegisterSocialMediaInfo = async (param: any) => {
  const mutation = gql`
    mutation createRegisterSocialMediaInfo($param: CreateSocialMediaInfoInput!) {
      createRegisterSocialMediaInfo(createSocialMediaInfoInput: $param) {
        name
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    return res.data.createRegisterSocialMediaInfo;
  } catch (error) {
    return null;
  }
};

export const getYoutubeVideoDataGql = async (
  path: string,
  social_key: string,
  disableError?: boolean,
) => {
  const query = gql`
    query getYoutubeVideoData($social_key: String!, $path: String!) {
      getYoutubeVideoData(social_key: $social_key, path: $path) {
        view
        like
        comment
        engagement
        title
        description
        thumbnails
        publishedAt
        isOwner
        privacyStatus
        source
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { path, social_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getYoutubeVideoData;
  } catch (error) {
    if (!disableError)
      notify(i18n.t('error:get youtube video data error .'), 'error');
    return null;
  }
};

export const getTwitterPostDataGql = async (
  path: string,
  social_key: string,
  disableError?: boolean,
) => {
  const query = gql`
    query getTwitterPostData($social_key: String!, $path: String!) {
      getTwitterPostData(social_key: $social_key, path: $path) {
        like
        retweet
        reply
        engagement
        message
        publishedAt
        isOwner
        privacyStatus
        thumbnails
        source
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { path, social_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getTwitterPostData;
  } catch (error) {
    if (!disableError) notify(i18n.t('error:get twitter data error .'), 'error');
    return null;
  }
};

export const getFacebookDataGql = async (path: string, social_key: string) => {
  const query = gql`
    query getFacebookPostData($social_key: String!, $path: String!) {
      getFacebookPostData(social_key: $social_key, path: $path) {
        like
        love
        wow
        haha
        sad
        angry
        comment
        share
        engagement
        message
        thumbnails
        publishedAt
        isOwner
        privacyStatus
        source
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { path, social_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getFacebookPostData;
  } catch (error) {
    notify(i18n.t('error:get facebook post data error .'), 'error');
    return null;
  }
};

export const getInstagramDataGql = async (path: string, social_key: string) => {
  const query = gql`
    query getInstagramPostData($social_key: String!, $path: String!) {
      getInstagramPostData(social_key: $social_key, path: $path) {
        message
        thumbnails
        publishedAt
        privacyStatus
        isOwner
        source
        like
        comment
        engagement
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { path, social_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getInstagramPostData;
  } catch (error) {
    notify(i18n.t('error:get instagram post data error .'), 'error');
    return null;
  }
};

export const getTiktokPostDataGql = async (path: string, social_key: string) => {
  const query = gql`
    query getTiktokPostData($path: String!, $social_key: String!) {
      getTiktokPostData(path: $path, social_key: $social_key) {
        like
        view
        share
        comment
        engagement
        title
        video_description
        thumbnails
        publishedAt
        isOwner
        source
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { path, social_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getTiktokPostData;
  } catch (error) {
    notify(i18n.t('error:get tiktok data error .'), 'error');
    return null;
  }
};

export const getOneSocialMediaInfoGql = async (key: string) => {
  const query = gql`
    query getSocialMediaInfo($key: String!) {
      getSocialMediaInfo(key: $key) {
        key
        social_media_type
        name
        image_profile_url
        follower
        post_engagement
        job_score
        like
        rating
        profile_path
        share_content_price
        photo_content_price
        video_content_price
        is_enable_share_price
        is_enable_photo_price
        is_enable_video_price
        introduce
        aptitudes {
          id
        }
        keyword_aptitudes {
          id
        }
        like_per_post
        comment_per_post
        share_per_post
        view_per_post
        engagement_per_post
        active_follower
        impression
        reach
        audience {
          male
          female
          age {
            range
            percentage
          }
          location {
            country
            percentage
          }
        }
        isOwner
        nextUpdate
        engagement_percent
        isPendinUpdatePrize
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getSocialMediaInfo;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer data error .'), 'error');
    return null;
  }
};

export const getSocialJobGql = async (key: string) => {
  const query = gql`
    query getSocialJob($key: String!) {
      getSocialJob(key: $key) {
        key
        accept_date
        influencer_submit_date
        marketer_approve_date
        post_date
        end_date
        cancel_job_reason
        example_message
        marketer_massage
        price
        status
        link_post
        approve_job_date
        reject_message
        image_storages {
          key
          marketer_verified
        }
        social_media_info {
          key
          image_profile_url
          name
          follower
          social_media_type
        }
        job {
          name
          currency {
            main_symbol
          }
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getSocialJob;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get data error .'), 'error');
    return null;
  }
};

export const getSocialMediaInfoGql = async () => {
  const query = gql`
    query {
      getAllSocialMediaInfo {
        key
        social_media_type
        name
        image_profile_url
        follower
        post_engagement
        job_score
        verify_status
        rating
        share_content_price
        photo_content_price
        video_content_price
        social_id
        user {
          email
          user_info {
            gender
          }
        }
        aptitudes {
          name
        }
        keyword_aptitudes {
          name
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'cache-first',
    });

    return res.data.getAllSocialMediaInfo;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer data error .'), 'error');
    return null;
  }
};

export const getAllPostDataGql = async (params: any) => {
  const query = gql`
    query getAllPostData($params: FilterPostDatumInput!) {
      getAllPostData(filterPostDatumInput: $params) {
        items {
          caption
          original_url
          post_images_url
          influencer_name
          influencer_image_url
          social_media_type
          brand_name
          marketer_image_url
          product_detail
        }
        total
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
      variables: { params },
    });

    return res.data.getAllPostData;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer data error .'), 'error');
    return null;
  }
};

export const createRequestNewPriceGql = async (inputs: any) => {
  const mutation = gql`
    mutation createRequestNewPrice($inputs: CreateRequestNewPriceInput!) {
      createRequestNewPrice(inputs: $inputs) {
        share_content_price
        verify_status
        video_content_price
        photo_content_price
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      fetchPolicy: 'no-cache',
      variables: { inputs },
    });
    if (res)
      notify(
        i18n.t('success:A request for new price has been received.'),
        'success',
      );
    return res.data.createRequestNewPrice;
  } catch (error) {
    notify(i18n.t('error:Request error.'), 'error');
    return null;
  }
};

export const deleteRequestPriceGql = async (params: any) => {
  const mutation = gql`
    mutation deleteRequestPriceBySocialKey($params: String!) {
      deleteRequestPriceBySocialKey(key: $params)
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      fetchPolicy: 'no-cache',
      variables: { params },
    });
    if (res)
      notify(
        i18n.t('success:A cancel request for new price has been removed.'),
        'success',
      );
    return res.data.deleteRequestPriceBySocialKey;
  } catch (error) {
    notify(i18n.t('error:Request error.'), 'error');
    return null;
  }
};

export const linkSocialMediaToJobGql = async (param: any) => {
  const mutation = gql`
    mutation linkSocialMediaToJob($param: LinkSocialMediaToJobInput!) {
      LinkSocialMediaToJob(linkSocialMediaToJobInput: $param) {
        key
        social_media_info {
          social_id
          key
          name
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });

    return res.data.linkSocialMediaToJob;
  } catch (error) {
    return error;
  }
};

export const linkSocialMediaToMarketerJobGql = async (param: any) => {
  const mutation = gql`
    mutation LinkSocialMediaToMarketerJob(
      $param: LinkSocialMediaToMarketerJobInput!
    ) {
      LinkSocialMediaToMarketerJob(linkSocialMediaToJobInput: $param) {
        key
        social_media_info {
          social_id
          key
          name
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });

    return res.data.LinkSocialMediaToMarketerJob;
  } catch (error) {
    return error;
  }
};

export const socialHasJobByJobKeyGql = async (input: any) => {
  const query = gql`
    query socialHasJobByJobKey($input: SocialHasJobByJobKeyInput!) {
      socialHasJobByJobKey(socialHasJobByJobKeyInput: $input) {
        items {
          key
          job {
            key
            name
            currency {
              symbol
              network
              rate_in_USD
              price_in_USDT
              is_marketer_currency
            }
          }
          social_media_info {
            key
            name
            image_profile_url
            social_media_type
            profile_path
            follower
          }
          link_post
          status
          price
          tax
          submit_date
          influencer_submit_date
          accept_date
          approve_job_date
          cancel_job_reason
          post_date
          rating
          isFavorite
          end_date
          is_match
          day_left
        }
        totalCount
        marketer_campaign_key
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });

    return res.data.socialHasJobByJobKey;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get job detail error .'), 'error');
    return null;
  }
};

export const requestNewPriceFilterTableGql = async (inputs: any) => {
  const query = gql`
    query requestNewPriceFilterTable($inputs: FilterRequestNewPriceInput!) {
      requestNewPriceFilterTable(inputs: $inputs) {
        totalCount
        items {
          verify_status
          share_content_price
          photo_content_price
          video_content_price
          recommend_share_price
          recommend_photo_price
          recommend_video_price
          social_media_info {
            name
            key
            image_profile_url
            verify_status
            created_at
            profile_path
            social_media_type
            social_id
            follower
            post_engagement
            share_content_price
            photo_content_price
            video_content_price
          }
        }
      }
    }
  `;

  try {
    const res = await client.query({
      query,
      variables: { inputs },
      fetchPolicy: 'no-cache',
    });
    return res.data.requestNewPriceFilterTable;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get request NewPrice Filter Table error .'), 'error');
    return null;
  }
};

export const filterNewFollowTableGql = async (input: any) => {
  const query = gql`
    query input($input: FilterNewFollowInput!) {
      filterNewFollowTable(inputs: $input) {
        items {
          key
          new_follower
          verify_status
          social_media_info {
            name
            key
            image_profile_url
            verify_status
            created_at
            profile_path
            social_media_type
            social_id
            follower
            post_engagement
            share_content_price
            photo_content_price
            video_content_price
          }
        }
        totalCount
      }
    }
  `;

  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterNewFollowTable;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get request New Follow Table error .'), 'error');
    return null;
  }
};

export const socialMediaFilterTableGql = async (param: any) => {
  const query = gql`
    query socialMediaFilterTable($param: FilterSocialMediaInput!) {
      socialMediaFilterTable(filterSocialMediaInput: $param) {
        items {
          key
          social_media_type
          follower
          name
          image_profile_url
          follower
          social_id
          introduce
          post_engagement
          job_score
          profile_path
          rating
          created_at
          verify_status
          share_content_price
          photo_content_price
          video_content_price
          aptitudes {
            id
            name
          }
          user {
            uuid
            email
            user_info {
              gender
              country
              date_of_birth
            }
          }
        }
        totalCount
      }
    }
  `;

  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.socialMediaFilterTable;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get social media error .'), 'error');
    return null;
  }
};

export const getSocialMediaInfoByUUIDGQL = async (param: any) => {
  const query = gql`
    query getSocialMediaInfoByUUID($param: FilterUserSocialMediaInput!) {
      getSocialMediaInfoByUUID(filterUserSocialMediaInput: $param) {
        items {
          key
          social_media_type
          name
          image_profile_url
          social_id
          profile_path
          created_at
          verify_status
          isDelete
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.getSocialMediaInfoByUUID;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get social media error .'), 'error');
    return null;
  }
};

export const filterGql = async (param: any) => {
  const query = gql`
    query socialHasJobFilterTable($param: FilterSocialHasJobInput!) {
      socialHasJobFilterTable(filterSocialHasJobInput: $param) {
        items {
          key
          status
          verify_status
          price
          tax
          cancel_job_reason
          link_post
          post_date
          submit_date
          accept_date
          end_date
          influencer_submit_date
          social_media_info {
            name
            image_profile_url
            key
            social_media_type
            user {
              id
              uuid
            }
          }
          job {
            id
            name
            key
            social_media_type
            content_type
            date_submission
            job_type
            marketer_campaign {
              brand_name
              product_name
              user {
                email
                user_info {
                  company
                }
              }
            }
            currency {
              symbol
              network
              main_symbol
            }
          }
        }
        totalCount
        statusCount {
          status
          count
        }
      }
    }
  `;

  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.socialHasJobFilterTable;
  } catch (error) {
    //notify(i18n.t('error:Get Job error .'), 'error');
    return null;
  }
};

export const createRequestNewFollowerGql = async (
  social_job_key: string,
  follower: number,
) => {
  const mutation = gql`
    mutation createRequestNewFollower($social_job_key: String!, $follower: Int!) {
      createRequestNewFollower(
        social_job_key: $social_job_key
        follower: $follower
      ) {
        verify_status
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { social_job_key, follower },
      fetchPolicy: 'no-cache',
    });
    return res.data.createRequestNewFollower;
  } catch (error) {
    return null;
  }
};

export const rejectSocialJob = async (social_job_key: any, reject_message: any) => {
  const mutation = gql`
    mutation rejectSocialJob($social_job_key: String!, $reject_message: String!) {
      rejectSocialJob(
        social_job_key: $social_job_key
        reject_message: $reject_message
      ) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { social_job_key, reject_message },
    });
    return res.data.rejectSocialJob;
  } catch (error) {
    return null;
  }
};

export const updaterNewFollowGql = async (input: any) => {
  const mutation = gql`
    mutation input($input: UpdateNewFollowInput) {
      updaterNewFollow(inputs: $input) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
    });
    return res.data.updaterNewFollow;
  } catch (error) {
    return null;
  }
};

export const addNewPostGql = async (social_job_key: string, path: string) => {
  const mutation = gql`
    mutation addNewPost($social_job_key: String!, $path: String!) {
      addNewPost(social_job_key: $social_job_key, path: $path) {
        caption
        original_url
        post_datum_images {
          url
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { social_job_key, path },
      fetchPolicy: 'no-cache',
    });
    return res.data.addNewPost;
  } catch (error) {
    return null;
  }
};

export const updateSocialMediaHasJobGql = async (param: any) => {
  const mutation = gql`
    mutation updateSocialMediaHasJob($param: UpdateSocialMediaHasJobInput!) {
      updateSocialMediaHasJob(updateSocialMediaHasJobInput: $param) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.updateSocialMediaHasJob;
  } catch (error) {
    return null;
  }
};

export const removeSocialJobImageByKey = async (key: string) => {
  const mutation = gql`
    mutation removeImageByKey($key: String!) {
      removeImageByKey(key: $key, imageType: "socialJob") {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { key },
    });
    return res.data.updateSocialMediaHasJob;
  } catch (error) {
    return null;
  }
};

export const getAllAptitude = async () => {
  const query = gql`
    query getAllAptitude {
      getAllAptitude {
        name
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });

    return res.data.getAllAptitude;
  } catch (error) {
    notify(i18n.t('error:Get aptitudes data error .'), 'error');
    return null;
  }
};

export const getAllKeywordAptitude = async () => {
  const query = gql`
    query getAllKeywordAptitude {
      getAllKeywordAptitude {
        id
        name
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });

    return res.data.getAllKeywordAptitude;
  } catch (error) {
    notify(i18n.t('error:Get keyword aptitudes data error .'), 'error');
    return null;
  }
};
export const getInfluencerRequestNewPriceGql = async (key: string) => {
  const query = gql`
    query findOneRequestPriceBySocialKey($key: String!) {
      findOneRequestPriceBySocialKey(key: $key) {
        verify_status
        share_content_price
        photo_content_price
        video_content_price
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });

    return res.data.findOneRequestPriceBySocialKey;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer price rate error .'), 'error');
    return null;
  }
};

export const getFollowerBySocialKeyGql = async (social_job_key: string) => {
  const query = gql`
    query social_job_key($social_job_key: String!) {
      getFollowerBySocialKey(social_job_key: $social_job_key) {
        key
        new_follower
        verify_status
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { social_job_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getFollowerBySocialKey;
  } catch (error) {
    return null;
  }
};

export const getInfluencerPriceRateGql = async () => {
  const query = gql`
    query {
      influencerPriceRate {
        social_type
        multiply
        start_rank
        end_rank
        engagement_multiply
        share_multiply
        post_multiply
        video_multiply
        power
        offset
        like_post
        comment_post
        share_post
        weight0
        weight1
        day
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });

    return res.data.influencerPriceRate;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer price rate error .'), 'error');
    return null;
  }
};

export const updateSocialMediaInfo = async (param: any) => {
  const mutation = gql`
    mutation updateSocialMediaInfo($param: UpdateSocialMediaInfoInput!) {
      updateSocialMediaInfo(updateSocialMediaInfoInput: $param) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    notify(i18n.t('success:Your request has been updated.'), 'success');
    return res.data.updateSocialMediaInfo;
  } catch (error) {
    notify(i18n.t('error:The request has not been updated.'), 'error');
    return null;
  }
};

export const updateRequestNewPriceGql = async (inputs: any) => {
  const mutation = gql`
    mutation updateRequestNewPrice($inputs: UpdateRequestPriceRateInput!) {
      updateRequestNewPrice(inputs: $inputs)
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: { inputs },
      fetchPolicy: 'no-cache',
    });
    notify(i18n.t('success:Your request has been updated.'), 'success');
  } catch (error) {
    notify(i18n.t('error:The request has not been updated.'), 'error');
  }
};

export const updateSocialPriceRate = async (inputs: any) => {
  const mutation = gql`
    mutation updateSocialPriceRate($inputs: UpdateInfluencerPriceRateInput!) {
      updateSocialPriceRate(updateInfluencerPriceRateInput: $inputs) {
        social_type
        multiply
        start_rank
        end_rank
        engagement_multiply
        share_multiply
        post_multiply
        video_multiply
        power
        offset
        like_post
        comment_post
        share_post
        weight0
        weight1
        day
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { inputs },
      fetchPolicy: 'no-cache',
    });
    notify(i18n.t('success:Update social price rate success.'), 'success');
    return res.data.updateSocialPriceRate;
  } catch (error) {
    notify(i18n.t('error:Update social price rate failed.'), 'error');
    return null;
  }
};

export const socialJobSummaryGql = async () => {
  const query = gql`
    query {
      socialJobSummary {
        brand_name_count
        sum_income
        job_count
        user_balance
      }
    }
  `;
  try {
    const res = await client.query({
      query,
    });

    return res.data.socialJobSummary;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer data error .'), 'error');
    return null;
  }
};

export const socialJobCountGql = async () => {
  const query = gql`
    query {
      socialJobCount {
        job_count
      }
    }
  `;
  try {
    const res = await client.query({
      query,
    });

    return res.data.socialJobCount;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get influencer data error .'), 'error');
    return null;
  }
};

export const renewSocialMediaInfoAccessToken = async (params: any) => {
  const mutation = gql`
    mutation renewSocialMediaAccessToken($params: [CreateSocialMediaInfoInput!]!) {
      renewSocialMediaAccessToken(input: $params) {
        message
        is_success
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { params },
    });
    return res.data.renewSocialMediaAccessToken;
  } catch (error) {
    return null;
  }
};

export const getExpiredSocialMediaTokenGql = async (key: any) => {
  const input = {
    key,
  };
  const query = gql`
    query getExpiredSocialMediaToken($input: ExpiredSocialMediaInfoInput!) {
      getExpiredSocialMediaToken(input: $input) {
        items {
          type
          socialMediaInfos {
            name
            social_media_type
            social_id
            key
            image_profile_url
            profile_path
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.getExpiredSocialMediaToken;
  } catch (error) {
    return null;
  }
};

export const batchUpdateSocialMediaPriceGql = async (params: any) => {
  const mutation = gql`
    mutation batchUpdateSocialPriceBySocialMediaType(
      $params: UpdateInfluencerPriceRateInput!
    ) {
      batchUpdateSocialPriceBySocialMediaType(input: $params) {
        message
        is_success
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { params },
    });
    return res.data.batchUpdateSocialPrice;
  } catch (error) {
    return null;
  }
};

export const createDeleteSocialMedia = async (social_key: any) => {
  const mutation = gql`
    mutation createDeleteSocialMedia($social_key: String!) {
      createDeleteSocialMedia(social_key: $social_key) {
        message
        is_success
      }
    }
  `;

  const res = await client.mutate({
    mutation,
    variables: { social_key },
    fetchPolicy: 'no-cache',
  });

  if (res.data.createDeleteSocialMedia.is_success) {
    notify(i18n.t(`success:${res.data.createDeleteSocialMedia.message}`), 'success');
  } else {
    notify(i18n.t(`error:${res.data.createDeleteSocialMedia.message}`), 'error');
  }
};

export const filterRequestDeleteSocialTable = async (param: any) => {
  const query = gql`
    query filterRequestDeleteSocialTable(
      $param: FilterRequestDeleteSocialMediaInput!
    ) {
      filterRequestDeleteSocialTable(inputs: $param) {
        items {
          id
          verify_status
          created_at
          social_media_info {
            name
            image_profile_url
            key
            social_media_type
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterRequestDeleteSocialTable;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get Delete Social Error .'), 'error');
    return null;
  }
};

export const updateRequestDeleteSocialMedia = async (inputs: any) => {
  const mutation = gql`
    mutation updateRequestDeleteSocialMedia(
      $inputs: UpdateRequestDeleteSocialMediaInput!
    ) {
      updateRequestDeleteSocialMedia(inputs: $inputs) {
        message
        is_success
      }
    }
  `;

  try {
    const response = await client.mutate({
      mutation,
      variables: { inputs },
      fetchPolicy: 'no-cache',
    });
    const data = response.data.updateRequestDeleteSocialMedia;
    if (data.is_success === true) {
      notify(i18n.t(`success:${data.message}`), 'success');
    } else {
      notify(i18n.t(`error:${data.message}`), 'error');
    }
  } catch (error) {
    notify(i18n.t('error:The request has not been updated.'), 'error');
  }
};

export const createTempManySocialMediaInfoGql = async (param: any) => {
  const mutation = gql`
    mutation createTempSocialMediaInfo($param: [CreateSocialMediaInfoInput!]!) {
      createTempSocialMediaInfo(createSocialMediaInfoInputs: $param) {
        message
        is_success
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    const data = res.data.createTempSocialMediaInfo;
    if (data.is_success === false) {
      notify(i18n.t(`error:${data.message}`), 'error');
    }
    return data;
  } catch (error) {
    return null;
  }
};

export const getSocialMediaInfoGroupByAptitudeGql = async () => {
  const query = gql`
    query {
      getSocialMediaInfoGroupByAptitude {
        aptitude {
          name
          id
        }
        socialMediaInfo {
          name
          social_media_type
          profile_path
          image_profile_url
          key
          post_engagement
          follower
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
    });
    return res.data.getSocialMediaInfoGroupByAptitude;
  } catch (error) {
    return null;
  }
};

export const checkSocialAlreadyHasJobGQL = async (
  job_key: string,
  social_key: string,
) => {
  const query = gql`
    query checkSocialAlreadyHasJob($job_key: String!, $social_key: String!) {
      checkSocialAlreadyHasJob(job_key: $job_key, social_key: $social_key) {
        isHasJob
        social_job_key
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { job_key, social_key },
      fetchPolicy: 'no-cache',
    });

    return res.data.checkSocialAlreadyHasJob;
  } catch (error) {
    return null;
  }
};

export const calculateSocialMediaPriceGQL = async (params: any) => {
  const query = gql`
    query calculateSocialMediaPrice($params: RecommendContentPriceInput!) {
      calculateSocialMediaPrice(input: $params) {
        recommend_share_price
        recommend_photo_price
        recommend_video_price
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { params },
      fetchPolicy: 'no-cache',
    });

    return res.data.calculateSocialMediaPrice;
  } catch (error) {
    return null;
  }
};

export const requesPriceOnSuggestJobGQL = async (
  social_job_key: string,
  request_price: number,
) => {
  const mutation = gql`
    mutation requesPriceOnSuggestJob(
      $social_job_key: String!
      $request_price: Float!
    ) {
      requesPriceOnSuggestJob(
        social_job_key: $social_job_key
        request_price: $request_price
      ) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { social_job_key, request_price },
      fetchPolicy: 'no-cache',
    });
    return res.data.requesPriceOnSuggestJob;
  } catch (error) {
    return null;
  }
};

export const getBasePriceAndEngagementPerFollowerGQL = async (input: any) => {
  const query = gql`
    query GetBasePriceAndEngagementPerFollower(
      $input: BasePriceEngagementPerFollowerInput!
    ) {
      getBasePriceEngagementPerFollower(input: $input) {
        socialName
        basePrice
        engagementPerFollower
        follower
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });

    return res.data.getBasePriceEngagementPerFollower;
  } catch (error) {
    return null;
  }
};

export const updateSocialMediaInfoFrom3rdPartyGQL = async (key: any) => {
  const mutation = gql`
    mutation updateSocialMediaInfoFrom3rdParty($key: String!) {
      updateSocialMediaInfoFrom3rdParty(key: $key) {
        key
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { key },
    });
    return res.data.updateSocialMediaInfoFrom3rdParty;
  } catch (error) {
    return null;
  }
};

export const checkSocialMediaCallAPIGQL = async (key: string, social_id: string) => {
  const query = gql`
    query CheckCallSocialMediaAPI($key: String!, $social_id: String!) {
      checkCallSocialMediaAPI(key: $key, social_id: $social_id)
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { key, social_id },
      fetchPolicy: 'no-cache',
    });
    return response.data.checkCallSocialMediaAPI;
  } catch (error) {
    return null;
  }
};

export const deleteNotSelectedFacebook = async (keys: string[]) => {
  const mutation = gql`
    mutation deleteNotSelectFacebook($keys: [String!]!) {
      deleteNotSelectFacebook(keys: $keys)
    }
  `;
  try {
    const response = await client.mutate({
      mutation,
      variables: { keys },
    });
    return response.data.deleteNotSelectFacebook;
  } catch (error) {
    return null;
  }
};

export const socialMediaApplyGleamJobGQL = async (input: any) => {
  const mutation = gql`
    mutation socialMediaApplyGleamJob($input: LinkSocialMediaToMarketerJobInput!) {
      socialMediaApplyGleamJob(input: $input) {
        key
        social_media_info {
          social_id
          key
          name
        }
        group
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.socialMediaApplyGleamJob;
  } catch (error) {
    return null;
  }
};

export const filterGleamGQL = async (param: any) => {
  const query = gql`
    query socialHasGleamJobFilter($param: FilterSocialHasJobInput!) {
      socialHasGleamJobFilter(filterSocialHasJobInput: $param) {
        items {
          social_media_has_job {
            key
            group
            status
            price
            tax
            link_post
            post_date
            created_at
            job {
              id
              name
              job_type
              key
              date_submission
              content_type
              social_media_type
              last_day_influencer_submit
              is_gleam_job
              currency {
                id
                main_symbol
                symbol
                network
              }
              marketer_campaign {
                brand_name
                product_name
                user {
                  email
                  user_info {
                    company
                  }
                }
              }
            }
          }
          social_media_info {
            key
            name
            profile_path
            social_media_type
            image_profile_url
            user {
              email
            }
          }
          job_task_count
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.socialHasGleamJobFilter;
  } catch (error) {
    return null;
  }
};

export const socialHasGleamJobRequestByJobKeyGQL = async (param: any) => {
  const query = gql`
    query socialHasGleamJobRequestByJobKey($param: SocialHasJobByJobKeyInput!) {
      socialHasGleamJobRequestByJobKey(socialHasJobByJobKeyInput: $param) {
        items {
          key
          group
          status
          verify_status
          price
          tax
          job_key
          symbol
          names
          image_profile_url
          social_media_key
          job_task_count
          is_match
          approve_job_date
          social_media_types
          social_media_info_has_job {
            social_media_info {
              key
              name
              social_media_type
              image_profile_url
              follower
            }
            social_media_has_job {
              items {
                key
                group
                price
                tax
                is_match
                approve_job_date
                status
                verify_status
                accept_date
              }
            }
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.socialHasGleamJobRequestByJobKey;
  } catch (error) {
    return null;
  }
};

export const approveSocialGleamJob = async (input: any) => {
  const mutation = gql`
    mutation approveSocialGleamJob($input: UpdateSocialMediaHasJobInput!) {
      approveSocialGleamJob(input: $input) {
        key
        group
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
    });
    return res.data.approveSocialGleamJob;
  } catch (error) {
    return null;
  }
};

export const rejectSocialGleamJob = async (input: any) => {
  const mutation = gql`
    mutation rejectSocialGleamJob($input: UpdateSocialMediaHasJobInput!) {
      rejectSocialGleamJob(input: $input) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
    });
    return res.data.rejectSocialJob;
  } catch (error) {
    return null;
  }
};

export const getSocialGleamJobGQL = async (key: string) => {
  const query = gql`
    query getSocialGleamJob($key: String!) {
      getSocialGleamJob(key: $key) {
        social_info {
          name
          key
          image_profile_url
          social_media_type
        }
        socialHasJob {
          key
          price
          tax
          link_post
          example_message
          id_post
          submit_date
          accept_date
          post_date
          approve_job_date
          marketer_approve_date
          influencer_submit_date
          status
          cancel_job_reason
          reject_message
          social_media_info {
            name
            key
            image_profile_url
            social_media_type
          }
          job_task {
            id
            key
            title
            description
            percent
            task_type {
              id
              content_type
              socialmedia_type
            }
          }
          group
        }
        unreadMessage
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return res.data.getSocialGleamJob;
  } catch (error) {
    return null;
  }
};

export const getSocialGleamJobForReviewGQL = async (input: any) => {
  const query = gql`
    query getSocialGleamJobForReview($input: SocialGleamJobInput!) {
      getSocialGleamJobForReview(input: $input) {
        social_info {
          name
          key
          image_profile_url
          social_media_type
        }
        socialHasJob {
          key
          price
          tax
          link_post
          example_message
          id_post
          submit_date
          accept_date
          post_date
          approve_job_date
          marketer_approve_date
          influencer_submit_date
          status
          cancel_job_reason
          reject_message
          social_media_info {
            name
            key
            image_profile_url
            social_media_type
          }
          job_task {
            id
            key
            title
            description
            percent
            task_type {
              id
              content_type
              socialmedia_type
            }
          }
          group
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.getSocialGleamJobForReview;
  } catch (error) {
    return null;
  }
};

export const socialHasGleamJobReviewByJobKeyGQL = async (param: any) => {
  const query = gql`
    query socialHasGleamJobReviewByJobKey($param: SocialHasJobByJobKeyInput!) {
      socialHasGleamJobReviewByJobKey(socialHasJobByJobKeyInput: $param) {
        items {
          group
          job_key
          progress
          price
          names
          status
          last_day_to_approve
          marketer_approve_date
          social_media_types
          social_key
          post_date
          unreadMessage
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.socialHasGleamJobReviewByJobKey;
  } catch (error) {
    return null;
  }
};

export const updateSocialMediaHasGleamJobGql = async (param: any) => {
  const mutation = gql`
    mutation updateSocialMediaHasGleamJob($param: UpdateSocialMediaHasJobInput!) {
      updateSocialMediaHasGleamJob(updateSocialMediaHasJobInput: $param) {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.updateSocialMediaHasGleamJob;
  } catch (error) {
    return null;
  }
};

export const addNewGleamPostGql = async (param: any) => {
  const mutation = gql`
    mutation addNewGleamPost($param: AddNewGleamPostInput!) {
      addNewGleamPost(input: $param) {
        caption
        original_url
        post_datum_images {
          url
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.addNewPost;
  } catch (error) {
    return null;
  }
};

export const marketerSumPendingBalanceGQL = async () => {
  const query = gql`
    query marketerSumPendingBalance {
      marketerSumPendingBalance {
        balance
        checksum_status
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.marketerSumPendingBalance;
  } catch (error) {
    return null;
  }
};

export const influencerActivePendingJobCountGQL = async () => {
  const query = gql`
    query influencerActivePendingJobCount {
      influencerActivePendingJobCount {
        inprogress
        pending
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.influencerActivePendingJobCount;
  } catch (error) {
    return null;
  }
};

export const requestUpdateInfluencerPrizeRateWithEngagementGQL = async (
  key: string,
) => {
  const mutation = gql`
    mutation requestUpdateInfluencerPrizeRateWithEngagement($key: String!) {
      requestUpdateInfluencerPrizeRateWithEngagement(key: $key) {
        id
        key
        name
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return res.data.requestUpdateInfluencerPrizeRateWithEngagement;
  } catch (error) {
    return null;
  }
};

export const autoAcceptJobRequest = async (input: any) => {
  const mutation = gql`
    mutation autoAcceptJobRequest($input: SocialGleamJobAutoAccept!) {
      autoAcceptJobRequest(input: $input) {
        message
        is_success
      }
    }
  `;
  try {
    const response = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return response.data.autoAcceptJobRequest;
  } catch (error) {
    return null;
  }
};

export const reportJobDeclineGQL = async (input: any) => {
  const mutation = gql`
    mutation reportJobDecline($input: ReportJobDeclineInput!) {
      reportJobDecline(input: $input) {
        id
        social_media_has_job_id
        created_at
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.reportJobDecline;
  } catch (error) {
    return null;
  }
};

export const getUserHasReportGQL = async (key: string) => {
  const query = gql`
    query checkUserHasReport($key: String!) {
      checkUserHasReport(key: $key)
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return res.data.checkUserHasReport;
  } catch (error) {
    return null;
  }
};

export const getAdminReportJobDeclineGQL = async (input: any) => {
  const query = gql`
    query adminReportJobDeclineList($input: FilterReportDeclineJobInput!) {
      adminReportJobDeclineList(input: $input) {
        items {
          id
          social_media_has_job_id
          created_at
          status
          totalCount
          social_media_has_job {
            social_media_info {
              social_id
              name
              social_media_type
              image_profile_url
            }
            job {
              id
              name
              key
            }
            key
            link_post
            reject_message
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.adminReportJobDeclineList;
  } catch (error) {
    return null;
  }
};

export const adminUpdateReportDecline = async (input: any) => {
  const mutation = gql`
    mutation adminUpdateReportDecline($input: AdminUpdateReportJobDeclineInput!) {
      adminUpdateReportDecline(input: $input)
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.adminUpdateReportDecline;
  } catch (error) {
    return null;
  }
};

export const getSocialGleamJobByKeyGQL = async (key: string) => {
  const query = gql`
    query getSocialGleamJobByKey($key: String!) {
      getSocialGleamJobByKey(key: $key) {
        key
        price
        tax
        link_post
        example_message
        id_post
        submit_date
        accept_date
        post_date
        approve_job_date
        marketer_approve_date
        influencer_submit_date
        status
        cancel_job_reason
        reject_message
        social_media_info {
          name
          key
          image_profile_url
          social_media_type
        }
        job_task {
          id
          key
          title
          description
          percent
          task_type {
            id
            content_type
            socialmedia_type
          }
        }
        group
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return response.data.getSocialGleamJobByKey;
  } catch (error) {
    return null;
  }
};
