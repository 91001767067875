import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { getCreativeType as onGetCreativeType } from 'src/store/creativeType/actions';
import { addTypeRelationGraphQL } from '../../../graphql/advertiser/campaign';
import {
  getCampaign as onGetCampaign,
  setLoading,
  unsetLoading,
} from '../../../store/actions';
import CreativeType from './CreativeType';

const CreativeTypeSetting = ({ t }: any) => {
  //from react
  const { id }: any = useParams();
  const dispatch = useDispatch();

  const [typeData, setTypeData] = useState<any>([]);
  //general variable
  let typeOption: any[] = [];
  //redux
  const { creativeType, campaign } = useSelector((state: any) => ({
    creativeType: state.CreativeType.creativeType,
    campaign: state.Campaigns.campaign,
  }));

  //default values
  for (let i = 0; i < creativeType?.length; i++) {
    typeOption = [
      ...typeOption,
      {
        label: creativeType[i].name,
        value: creativeType[i].name,
        id: creativeType[i].id,
      },
    ];
  }

  //submit
  const onSubmit = async (e: any) => {
    dispatch(setLoading());
    e.preventDefault();

    const typeDataIndex = [];
    for (let i = 0; i < typeData?.length; i++) {
      typeDataIndex.push(typeData[i].id);
    }

    await addTypeRelationGraphQL(typeDataIndex, campaign.id);

    refreshData();
    dispatch(unsetLoading());
  };
  //set data
  const refreshData = () => {
    dispatch(onGetCreativeType());
    dispatch(onGetCampaign(id));
  };

  useEffect(() => {
    dispatch(onGetCreativeType());
    dispatch(onGetCampaign(id));
  }, []);

  useEffect(() => {
    if (campaign.creative_types) {
      let originalType: any[] = [];
      for (let i = 0; i < campaign.creative_types?.length; i++) {
        originalType = [
          ...originalType,
          {
            label: campaign.creative_types[i].name,
            value: campaign.creative_types[i].name,
            id: campaign.creative_types[i].id,
          },
        ];
      }
      setTypeData(originalType);
    }
  }, [campaign]);

  return (
    <div className="px-4">
      <Card className="p-4 mt-4">
        <p className="font-size-22 fw-bold ">{t('Campaign Category')}</p>
        <div className="mt-3">
          <CreativeType
            fromSetup
            setTypeData={setTypeData}
            typeData={typeData}
            typeOption={typeOption}
            campaign={campaign}
          />
        </div>

        <button
          type="submit"
          className="btn btn-success mt-3 fw-bold w-max-content"
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          {t('button:Save Change')}
        </button>
      </Card>
    </div>
  );
};

export default withTranslation()(CreativeTypeSetting);
