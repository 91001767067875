import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import menu from 'src/constants/menu';
import { useTranslation } from 'react-i18next';
import GleamJobComponent from 'src/components/Common/GleamJobComponent';
import GleamJobTimeline from 'src/components/Common/GleamJobTimeline';
import paramsFromURL from 'src/constants/paramsFromURL';
import { getMarketerJobSummary, getSocialGleamJob } from 'src/store/actions';
import GleamJobTaskHeaderInfluencer from 'src/components/Common/GleamJobTaskHeaderInfluencer';
import Breadcrumb from 'src/components/Common/Breadcrumb';

const JobDetailWork = () => {
  const dispatch = useDispatch();
  const [jobId, tasked] = paramsFromURL(['job', 'task']);
  const { job, task } = useSelector((state: any) => ({
    job: state?.MarketerJob?.marketerJob,
    task: state.SocialMediaInfo.socialHasJobTask,
  }));
  const { t } = useTranslation();
  const [isTaskOpen, setisTaskOpen] = useState(false);
  useEffect(() => {
    if (tasked) {
      dispatch(getSocialGleamJob(tasked));
    }
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
    }
  }, []);

  return (
    <div>
      {' '}
      <div style={{ marginLeft: '40px' }}>
        {' '}
        <Breadcrumb
          pageTitle={t('Template')}
          menus={[
            { menu: menu.HOME, active: true },
            {
              menu: menu.JOBS,
              active: true,
            },
            {
              menu: menu.JOB_DETAIL,
              isSamePage: true,
            },
          ]}
        />
      </div>
      <Row>
        <Col lg="6" md="12" xs="12">
          <GleamJobComponent job={job} is_working />
          <GleamJobTimeline job={job} />
        </Col>
        <Col lg="6" md="12" xs="12">
          {task &&
            task.map((jobTask: any) => {
              return <GleamJobTaskHeaderInfluencer jobTask={jobTask} />;
            })}

          {/* {job?.job_tasks && (
            <div style={{ paddingBottom: '13px' }}>
              {job?.job_tasks?.map((job_task: any) => {
                return <GleamJobTaskInfluencer job_task={job_task} />;
              })}
            </div>
          )} */}
        </Col>
      </Row>
    </div>
  );
};
const GleamJobTaskDetail = styled.div<{ isOpen: boolean }>`
  /* border: 5px solid; */
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export default JobDetailWork;
