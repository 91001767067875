//react
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//react style
import { Container, Row, Col } from 'reactstrap';
//general api
import { useTranslation } from 'react-i18next';
//component
import menu from 'src/constants/menu';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import paramsFromURL from 'src/constants/paramsFromURL';
import { getMarketerJobSummary, getSocialGleamJobByKey } from 'src/store/actions';
import GleamJobComponent from 'src/components/Common/GleamJobComponent';
import GleamJobTimeline from 'src/components/Common/GleamJobTimeline';
import GleamJobTaskMarketer from 'src/components/Common/GleamJobTaskMarketer';

const ReportDeclineJobDetail = () => {
  const dispatch = useDispatch();
  const [jobId, key] = paramsFromURL(['job', 'key']);
  const { job, socialJob } = useSelector((state: any) => ({
    job: state?.MarketerJob?.marketerJob,
    socialJob: state?.SocialMediaInfo.socialGleamJobItem,
  }));
  const { t } = useTranslation();

  useEffect(() => {
    if (key) {
      dispatch(getSocialGleamJobByKey(key));
    }
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
    }
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Page.Report Decline Job')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.REPORT_DECLINE_JOB },
            { menu: menu.REPORT_DECLINE_JOB_DETAIL, active: true },
          ]}
        />
        <Row>
          <Col lg="6" md="12" xs="12">
            <GleamJobComponent job={job} is_working />
            <GleamJobTimeline job={job} />
          </Col>
          <Col lg="6" md="12" xs="12">
            <GleamJobTaskMarketer job_tasks={socialJob} open />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportDeclineJobDetail;
