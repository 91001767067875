import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import Ad1 from '../../assets/images/home/place-ad-1.png';
import Ad2 from '../../assets/images/home/place-ad-2.png';
import Ad3 from '../../assets/images/home/place-ad-3.png';
import Ad4 from '../../assets/images/home/place-ad-4.png';

const CarouselPage = ({ t }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const items = [
    {
      id: 1,
      image: Ad1,
      header: `${t('Place Ad Item1 Heading')}`,
      des: `${t('Place Ad Item1 DESC')}`,
    },
    {
      id: 2,
      image: Ad2,
      header: `${t('Place Ad Item2 Heading')}`,
      des: `${t('Place Ad Item2 DESC')}`,
    },
    {
      id: 3,
      image: Ad3,
      header: `${t('Place Ad Item3 Heading')}`,
      des: `${t('Place Ad Item3 DESC')}`,
    },
    {
      id: 4,
      image: Ad4,
      header: `${t('Place Ad Item4 Heading')}`,
      des: `${t('Place Ad Item4 DESC')}`,
    },
  ];
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div
          className="carousel-item active "
          style={{ padding: '10px 120px 10px 120px' }}
        >
          <div className="testi-contain text-black">
            {/* <i className="bx bxs-quote-alt-left text-success display-6" /> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="home-page-second__ad-item"
                style={{
                  background: 'white',
                  borderRadius: '15px',
                  padding: 'px 10px 20px 10px',
                  boxShadow:
                    '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)',
                }}
              >
                <img
                  alt="itemImage"
                  src={item.image}
                  style={{ width: '500px', marginBottom: '15px' }}
                />
              </div>
              <h3
                className="mt-4 fw-medium  lh-base text-white"
                style={{ visibility: 'hidden' }}
              >
                {t('Carousel DESC')}
              </h3>
              <h2 className="text-center text-line-height text-white">
                {item.header}
              </h2>
              <h4
                style={{ maxWidth: '900px', width: '100%' }}
                className="text-center text-line-height text-white"
              >
                {item.des}
              </h4>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <>
      <div className="col-xxl-8 col-lg-8 col-md-7">
        <div className="auth-bg pt-md-5 p-4 d-flex" style={{ width: '100%' }}>
          {/* <div className="bg-overlay bg-overlay-main-green" />
          <ul className="bg-bubbles">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul> */}
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-7">
              <div className="p-0 p-sm-4 px-xl-0">
                {/* <div
                  id="reviewcarouselIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                  >
                    {slides}
                  </Carousel>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(CarouselPage);
