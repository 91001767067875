import { ActivityLogTypes, ActivityLogState } from './actionTypes';

export const INIT_STATE: ActivityLogState = {
  recentLogs: [],
  logs: [],
  error: {},
  allAdminApproveLog: {},
};

const ActivityLogs = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ActivityLogTypes.GET_RECENT_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        recentLogs: action.payload,
      };
    case ActivityLogTypes.NEW_ACTIVITY_LOG:
      // eslint-disable-next-line no-case-declarations
      const newLogs = [...state.recentLogs];
      newLogs.unshift(action.payload);
      return {
        ...state,
        recentLogs: newLogs,
      };
    case ActivityLogTypes.GET_ACTIVITY_LOG_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        logs: action.payload,
      };
    case ActivityLogTypes.GET_ACTIVITY_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ActivityLogTypes.CLEAR_ACTIVITY_LOGS:
      return INIT_STATE;
    case ActivityLogTypes.MARK_AS_ALL_READ:
      return {
        ...state,
      };
    case ActivityLogTypes.MARK_AS_ALL_SUCCESS:
      return {
        ...state,
      };
    case ActivityLogTypes.GET_ALL_ADMIN_APPROVE_LOG_SUCCESS:
      return {
        ...state,
        allAdminApproveLog: action.payload,
      };
    case ActivityLogTypes.MARK_AS_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ActivityLogTypes.ADD_ADMIN_APPROVE_LOG:
      return {
        ...state,
        allAdminApproveLog: action.payload,
      };
    default:
      return state;
  }
};

export default ActivityLogs;
