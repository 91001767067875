/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 *  interface for Login Type
 */
const enum ChangePasswordTypes {
  CHANGE_PASSWORD_REQUEST = '@@change_pwd/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_REQUEST_SUCCESS = '@@change_pwd/CHANGE_PASSWORD_REQUEST_SUCCESS',
  CHANGE_PASSWORD_REQUEST_ERROR = '@@change_pwd/CHANGE_PASSWORD_REQUEST_ERROR',

  CHANGE_PASSWORD_CONFIRM = '@@change_pwd/CHANGE_PASSWORD_CONFIRM',
  CHANGE_PASSWORD_CONFIRM_SUCCESS = '@@change_pwd/CHANGE_PASSWORD_CONFIRM_SUCCESS',
  CHANGE_PASSWORD_CONFIRM_ERROR = '@@change_pwd/CHANGE_PASSWORD_CONFIRM_ERROR',
}

export default ChangePasswordTypes;
