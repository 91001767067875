import { call, put, takeEvery } from 'redux-saga/effects';
import { PublishType } from './actionTypes';

import { getPublishTransactions } from '../../../graphql/admin/transaction';
import { getAllPublishTransactionsRequestSuccess } from './actions';

function* getAllPublish({
  payload: { limit, page, search, userId, isCampaign, jobType },
}: any) {
  const response: Promise<any> = yield call(
    getPublishTransactions,
    limit,
    page,
    search,
    userId,
    isCampaign,
    jobType,
  );
  yield put(getAllPublishTransactionsRequestSuccess(response));
}

export function* publishSaga() {
  yield takeEvery(PublishType.GET_ALL_PUBLISH_TRANSACTIONS_REQUEST, getAllPublish);
}

export default publishSaga;
