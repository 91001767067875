/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum MarketerCampaignStatus {
  DRAFT = 'DRAFT',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  CANCEL = 'CANCEL',
}

export default MarketerCampaignStatus;
