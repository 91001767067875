import { WithdrawTypes } from './actionTypes';
import { WithdrawTransactionsQuery } from '../../../common/types';

export const withdrawRequest = (data: any) => {
  return {
    type: WithdrawTypes.WITHDRAW_REQUEST,
    payload: { data },
  };
};

export const withdrawRequestSuccess = () => {
  return {
    type: WithdrawTypes.WITHDRAW_REQUEST_SUCCESS,
  };
};

export const withdrawRequestFail = (error: any) => {
  return {
    type: WithdrawTypes.WITHDRAW_REQUEST_FAIL,
    payload: error,
  };
};

export const getWithdrawRequest = (payload: WithdrawTransactionsQuery) => {
  return {
    type: WithdrawTypes.GET_WITHDRAW_REQUEST,
    payload: { ...payload },
  };
};

export const getWithdrawRequestSuccess = (transactions: any) => {
  return {
    type: WithdrawTypes.GET_WITHDRAW_REQUEST_SUCCESS,
    payload: transactions,
  };
};

export const getRecentWithdrawRequest = (limit: number) => {
  return {
    type: WithdrawTypes.GET_RECENT_WITHDRAW_REQUEST,
    payload: { limit },
  };
};

export const getRecentWithdrawRequestSuccess = (transactions: any) => {
  return {
    type: WithdrawTypes.GET_RECENT_WITHDRAW_REQUEST_SUCCESS,
    payload: transactions,
  };
};

export const getAllWithdrawTransactionsRequest = (
  payload: WithdrawTransactionsQuery,
) => {
  return {
    type: WithdrawTypes.GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST,
    payload: { ...payload },
  };
};

export const getAllWithdrawTransactionsRequestSuccess = (transactions: any) => {
  return {
    type: WithdrawTypes.GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST_SUCCESS,
    payload: transactions,
  };
};

export const approveWithdraw = (uuid: string) => {
  return {
    type: WithdrawTypes.APPROVE_WITHDRAW,
    payload: { uuid },
  };
};

export const rejectWithdraw = (uuid: string) => {
  return {
    type: WithdrawTypes.REJECT_WITHDRAW,
    payload: { uuid },
  };
};

export const withdrawAdminRequest = (data: any) => {
  return {
    type: WithdrawTypes.WITHDRAW_ADMIN_REQUEST,
    payload: { data },
  };
};

export const withdrawAdminRequestSuccess = () => {
  return {
    type: WithdrawTypes.WITHDRAW_ADMIN_REQUEST_SUCCESS,
  };
};

export const withdrawAdminRequestFail = (error: any) => {
  return {
    type: WithdrawTypes.WITHDRAW_ADMIN_REQUEST_FAIL,
    payload: error,
  };
};
