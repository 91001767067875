/* eslint-disable react/require-default-props */
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import IconRadioInputComponent from 'src/components/shared/IconRadioInput/IconRadioInput.component';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { FormGroup } from 'reactstrap';
import { Dispatch, SetStateAction, useEffect } from 'react';
import ErrorFocus from 'src/utils/ErrorFocus';
import { useTranslation } from 'react-i18next';
// Store
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';

// Control & Style
import { getMarketerCategory } from 'src/store/actions';
import { useDispatch } from 'react-redux';
import controls from './Aptitudes.control';
import pageStyles from '../../../../../pages/Publisher/Influencer/ChannelDetail/ChannelDetail.styled';

const {
  TitleStyle,
  FlexRow,
  ButtonEdit,
  ButtonSave,
  ButtonCancel,
  AptitudeGridContainer,
} = pageStyles();

const AptitudesComponent = ({
  formValues,
  setFormValues,
  ShowBtn,
  userProfile,
  disableEdit,
  unlimited,
}: {
  formValues?: UserSocialProfile;
  setFormValues?: Dispatch<SetStateAction<UserSocialProfile>>;
  ShowBtn?: string;
  userProfile?: any;
  disableEdit?: any;
  unlimited?: boolean;
}) => {
  const {
    editState,
    setEditState,
    onSubmit,
    // validationSchema,
    userProfileSelected$,
    userProfile$,
  } = controls();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let limited = 5;
  if (unlimited) {
    limited = 100;
  }

  useEffect(() => {
    dispatch(getMarketerCategory());
  }, []);

  if (userProfileSelected$)
    return (
      <>
        <TitleStyle>{t('influencer:account:aptitude')}</TitleStyle>
        <span style={{ textAlign: 'center', color: 'red' }}>
          * {t('influencer:account:You can select 2 - 5 aptitudes')}
        </span>
        <FlexRow width="80%">
          <FormGroup className="text-center w-100">
            <AptitudeGridContainer
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {CategoriesMarketerCampaign?.map((aptitude) => {
                return (
                  <IconRadioInputComponent
                    key={`radio ${aptitude?.value}`}
                    id={`radio ${aptitude?.value}`}
                    onClick={() => {
                      if (!formValues || !setFormValues) return;

                      let newForm = formValues.aptitude_ids || [];
                      if (newForm?.includes(aptitude.value)) {
                        newForm = newForm.filter(
                          (value) => value !== aptitude.value,
                        );
                      } else {
                        if (newForm?.length >= limited) return;
                        newForm.push(aptitude.value);
                      }
                      setFormValues((current) => ({
                        ...current,
                        aptitude_ids: newForm,
                      }));
                    }}
                    isChecked={
                      formValues?.aptitude_ids?.includes(aptitude.value) || false
                    }
                    imageUrl={aptitude.icon}
                    labels={aptitude.label}
                    // background={aptitude.background_color}
                    // iconColor={aptitude.icon_color}
                  />
                );
              })}
            </AptitudeGridContainer>
            <ErrorMessage
              name="aptitudes"
              component="div"
              className="invalid-message"
            />
            <ErrorFocus name="aptitudes" />
          </FormGroup>
        </FlexRow>
      </>
    );

  if (!formValues || !setFormValues) return <></>;
  if (!Object.keys(formValues)?.length || !formValues?.aptitudes == null)
    return <></>;
  if (editState)
    return (
      <>
        <TitleStyle>{t('influencer:account:aptitude')}</TitleStyle>
        <FlexRow width="80%">
          <Formik
            initialValues={formValues}
            onSubmit={async () => {
              if (!formValues.key) return;
              await onSubmit({
                key: formValues.key,
                aptitude_ids: formValues.aptitude_ids,
              });
            }}
            // validationSchema={validationSchema}
            enableReinitialize
          >
            {() => {
              return (
                <FormikForm className="w-100">
                  <span style={{ textAlign: 'center', color: 'red' }}>
                    * {t('influencer:account:You can select 2 - 5 aptitudes')}
                  </span>
                  <FormGroup className="text-start">
                    <AptitudeGridContainer>
                      {CategoriesMarketerCampaign?.map((aptitude) => {
                        return (
                          <IconRadioInputComponent
                            key={`radio ${aptitude?.value}`}
                            id={`radio ${aptitude?.value}`}
                            onClick={() => {
                              let newForm = formValues?.aptitude_ids || [];
                              if (newForm?.includes(aptitude.value)) {
                                newForm = newForm.filter(
                                  (value) => value !== aptitude.value,
                                );
                              } else {
                                if (newForm?.length >= limited) return;
                                newForm.push(aptitude.value);
                              }

                              setFormValues((current) => ({
                                ...current,
                                aptitude_ids: newForm,
                              }));
                            }}
                            isChecked={
                              formValues?.aptitude_ids?.includes(aptitude.value) ||
                              false
                            }
                            imageUrl={aptitude.icon}
                            labels={aptitude.label}
                            // background={aptitude.background_color}
                            // iconColor={aptitude.icon_color}
                          />
                        );
                      })}
                    </AptitudeGridContainer>
                  </FormGroup>
                  <ButtonCancel
                    type="button"
                    onClick={() => {
                      const aptitude_ids = userProfile?.aptitudes.map(
                        (aptitude: any) => aptitude.id,
                      );
                      setFormValues({ ...formValues, aptitude_ids });
                      setEditState(false);
                    }}
                    style={{ marginRight: '1rem' }}
                  >
                    <span>{t('influencer:account:cancel')}</span>
                  </ButtonCancel>
                  <ButtonSave type="submit">
                    <span>{t('influencer:account:submit')}</span>
                  </ButtonSave>
                </FormikForm>
              );
            }}
          </Formik>
        </FlexRow>
      </>
    );
  if (userProfile$)
    return (
      <>
        <TitleStyle>{t('influencer:account:aptitude')}</TitleStyle>
        <FlexRow width="90%">
          <AptitudeGridContainer columnsLength={formValues?.aptitude_ids?.length}>
            {formValues?.aptitude_ids?.map((aptitude_id: any) => {
              const findCategory = CategoriesMarketerCampaign.find(
                (category) => category.value === aptitude_id,
              );

              return (
                <IconRadioInputComponent
                  key={`radio ${findCategory?.value}`}
                  id={`radio ${findCategory?.value}`}
                  imageUrl={findCategory?.icon}
                  labels={findCategory?.label}
                  // background={findCategory?.background_color}
                  // iconColor={findCategory?.icon_color}
                />
              );
            })}
          </AptitudeGridContainer>
        </FlexRow>
        {!disableEdit && userProfile.isOwner === true ? (
          <div style={{ display: ShowBtn ? `${ShowBtn}` : 'block' }}>
            <ButtonEdit onClick={() => setEditState(true)}>
              <i
                className="fas fa-pen"
                style={{
                  color: 'white',
                  margin: '5px 5px 0 0',
                }}
              />
              <span
                style={{
                  color: 'white',
                }}
              >
                {t('influencer:account:edit')}
              </span>
            </ButtonEdit>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  return (
    <>
      <TitleStyle>{t('influencer:account:aptitude')}</TitleStyle>
      <FlexRow width="90%">
        <AptitudeGridContainer columnsLength={formValues?.aptitude_ids?.length}>
          {formValues?.aptitude_ids?.map((aptitude_id: any) => {
            const findCategory = CategoriesMarketerCampaign.find(
              (category) => category.value === aptitude_id,
            );

            return (
              <IconRadioInputComponent
                key={`radio ${findCategory?.value}`}
                id={`radio ${findCategory?.value}`}
                icon={findCategory?.icon}
                label={findCategory?.label}
                // background={findCategory?.background_color}
                // iconColor={findCategory?.icon_color}
              />
            );
          })}
        </AptitudeGridContainer>
      </FlexRow>
      {!disableEdit && (
        <div style={{ display: ShowBtn ? `${ShowBtn}` : 'block' }}>
          <ButtonEdit onClick={() => setEditState(true)}>
            <i
              className="fas fa-pen"
              style={{
                color: 'white',
                margin: '5px 5px 0 0',
              }}
            />
            <span
              style={{
                color: 'white',
              }}
            >
              {t('influencer:account:edit')}
            </span>
          </ButtonEdit>
        </div>
      )}
    </>
  );
};

export default AptitudesComponent;
