/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CustomTable from '../Common/CustomTable';
import {
  getMovement,
  getMovement as onGetMovement,
} from '../../store/movement/actions';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import TransactionDetailModal from '../Table/TransactionDetailModal';
import movementTypes from '../../constants/movementTypes';
import MovementBadge from '../../components/Common/MovementBadge';
import { transformAddressInShortFrom } from '../../utils/address';
import MovementTypeOptions from '../Table/MovementTypeOptions';
import { transformStringAmountInput } from '../../utils/input';
import transactionModalTypes from '../../constants/transactionModalTypes';
import PaymentDetailModal from '../Table/PaymentDetailModal';
import EarningDetailModal from './EarningDetailModal';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';
import ReferralDetailModal from '../Table/ReferralDetailModal';

const MovementTransactionsTable = ({ t, userId, tab }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  let moreDetails;

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const [type, setType] = useState(movementTypes.ALL);
  const [selectRow, setSelectRow] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [param, setParam] = useState({
    page,
    limit: pageSize,
    userId,
    search,
    type,
    sortBy,
    sortType,
  });

  // Redux
  const { txn } = useSelector((state: any) => ({
    txn: state.movement.transactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(defaultPageSize);
    setType(movementTypes.ALL);
    setParam({
      ...param,
      page: 1,
      limit: defaultPageSize,
      type: movementTypes.ALL,
    });
    // Fetch Data With Default Payload When Tab Changed
    dispatch(getMovement({ limit: 10, page: 1, userId }));
  }, [tab]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);

    if (txn) {
      if (txn.items) {
        switch (txn.items[rowIndex].transaction_type_id) {
          case movementTypes.WITHDRAW:
            setSelectRow(txn.items[rowIndex].withdraw_transaction);
            break;
          case movementTypes.DEPOSIT:
            setSelectRow(txn.items[rowIndex].deposit_transaction);
            break;
          case movementTypes.PAYMENT:
            setSelectRow(txn.items[rowIndex].advertiser_payment_transaction);
            break;
          case movementTypes.EARNING:
            setSelectRow(txn.items[rowIndex].publisher_earning_transaction);
            break;
          case movementTypes.REFERRAL:
            setSelectRow(txn.items[rowIndex].referral_earning_transaction);
            break;
          default:
            setSelectRow(null);
        }
      }
    }
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(onGetMovement(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleTypeChange = (type: string) => {
    const payload = {
      ...getDefaultPayload(1),
      type,
    };

    dispatch(onGetMovement(payload));
    setType(type);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(onGetMovement(payload));
    setSearch(search);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(onGetMovement(payload));
    setSearch('');
    setParam(payload);
  };

  const renderCurrency = (originalRow: any) => {
    const {
      transaction_type_id,
      withdraw_transaction,
      deposit_transaction,
      advertiser_payment_transaction,
      locked_key,
      amount,
    } = originalRow;

    const prefix = locked_key.split('_')[0];
    if (prefix === 'cwf' || prefix === 'cxlwf') {
      return `USDT`;
    } else if (prefix === 'fee') {
      return `USDT`;
    }
    switch (transaction_type_id) {
      case movementTypes.WITHDRAW:
        return withdraw_transaction?.currency.main_symbol ?? 'USDT';
      case movementTypes.DEPOSIT:
        return deposit_transaction?.currency.main_symbol ?? 'USDT';
      case movementTypes.PAYMENT:
        return (
          advertiser_payment_transaction?.social_media_has_job?.job?.currency
            ?.main_symbol ?? 'USDT'
        );
      default:
        return 'USDT';
    }
  };

  const renderAmount = (originalRow: any) => {
    const {
      transaction_type_id,
      sign,
      amount,
      locked_key,
      withdraw_transaction,
      deposit_transaction,
      advertiser_payment_transaction,
      referral_earning_transaction,
      publisher_earning_transaction,
    } = originalRow;

    const prefix = locked_key.split('_')[0];
    if (prefix === 'cwf' || prefix === 'cxlwf') {
      return sign === '+' ? `-${amount} USDT` : `+${amount} USDT`;
    } else if (prefix === 'fee') {
      return `${sign}${amount} USDT`;
    }
    switch (transaction_type_id) {
      case movementTypes.WITHDRAW:
        return sign === '+'
          ? `-${transformStringAmountInput(withdraw_transaction.CRYPTO_amount)} ${
              withdraw_transaction.currency.main_symbol
            }`
          : `+${transformStringAmountInput(withdraw_transaction.CRYPTO_amount)} ${
              withdraw_transaction.currency.main_symbol
            }`;
      case movementTypes.DEPOSIT:
        return `+${transformStringAmountInput(deposit_transaction.CRYPTO_amount)} ${
          deposit_transaction.currency.main_symbol
        }`;
      case movementTypes.PAYMENT:
        return `-${transformStringAmountInput(
          advertiser_payment_transaction.FIAT_amount,
        )} ${
          advertiser_payment_transaction.social_media_has_job.job.currency
            .main_symbol
        }`;
      case movementTypes.REFERRAL:
        return `+${transformStringAmountInput(
          referral_earning_transaction.amount,
        )} USDT`;
      case movementTypes.EARNING:
        return `+${transformStringAmountInput(
          publisher_earning_transaction.FIAT_amount,
        )} USDT`;
      default:
        return '';
    }
  };
  const dataTimezone = (created_at: any) => {
    return moment(created_at);
  };
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ created_at }) => {
          return (
            <DateTimeComponent
              dateTime={dataTimezone(created_at)}
              dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
            />
          );
        },
        id: 'created_at',
      },
      {
        Header: t('table:Email'),
        accessor: (originalRow) => {
          const {
            withdraw_transaction,
            deposit_transaction,
            transaction_type_id,
            advertiser_payment_transaction,
            publisher_earning_transaction,
            referral_earning_transaction,
            locked_key,
          } = originalRow;
          const prefix = locked_key.split('_')[0];
          if (prefix === 'fee') {
            return 'ADMIN FEE';
          }
          switch (transaction_type_id) {
            case movementTypes.WITHDRAW:
              return withdraw_transaction.user.email;
            case movementTypes.DEPOSIT:
              return deposit_transaction.user.email;
            case movementTypes.PAYMENT:
              return advertiser_payment_transaction.user.email;
            case movementTypes.EARNING:
              return publisher_earning_transaction.user.email;
            case movementTypes.REFERRAL:
              return referral_earning_transaction.referral.parent.email;
            default:
              return '';
          }
        },
        id: 'email',
        disableSortBy: true,
      },
      {
        Header: t('table:Role'),
        accessor: (originalRow) => {
          const {
            withdraw_transaction,
            deposit_transaction,
            transaction_type_id,
            advertiser_payment_transaction,
            publisher_earning_transaction,
            referral_earning_transaction,
          } = originalRow;

          switch (transaction_type_id) {
            case movementTypes.WITHDRAW:
              return t(withdraw_transaction.user.role);
            case movementTypes.DEPOSIT:
              return t(deposit_transaction.user.role);
            case movementTypes.PAYMENT:
              return t(advertiser_payment_transaction.user.role);
            case movementTypes.EARNING:
              return t(publisher_earning_transaction.user.role);
            case movementTypes.REFERRAL:
              return t(referral_earning_transaction.referral.parent.role);
            default:
              return '';
          }
        },
        id: 'role',
        width: 100,
        disableSortBy: true,
      },
      {
        Header: t('table:Transaction UUID'),
        accessor: (originalRow) => {
          const {
            withdraw_transaction,
            deposit_transaction,
            transaction_type_id,
            advertiser_payment_transaction,
            publisher_earning_transaction,
          } = originalRow;

          switch (transaction_type_id) {
            case movementTypes.WITHDRAW:
              return transformAddressInShortFrom(withdraw_transaction.uuid, 8);
            case movementTypes.DEPOSIT:
              return transformAddressInShortFrom(deposit_transaction.uuid, 8);
            case movementTypes.PAYMENT:
              return transformAddressInShortFrom(
                advertiser_payment_transaction.uuid,
                8,
              );
            case movementTypes.EARNING:
              return transformAddressInShortFrom(
                publisher_earning_transaction.uuid,
                8,
              );
            default:
              return '-';
          }
        },
        id: 'uuid',
        disableSortBy: true,
      },
      {
        Header: t('table:Transaction Type'),
        accessor: ({ transaction_type_id }) => {
          return t(`table:${transaction_type_id}`);
        },
        id: 'type',
        width: 100,
        disableSortBy: true,
      },
      {
        Header: t('table:Status'),
        accessor: ({
          sign,
          transaction_type_id,
          withdraw_transaction,
          deposit_transaction,
        }) => {
          return (
            <MovementBadge
              sign={sign}
              transaction_type_id={transaction_type_id}
              withdraw_transaction={withdraw_transaction}
              deposit_transaction={deposit_transaction}
            />
          );
        },
        id: 'status',
        width: 100,
        disableSortBy: true,
      },
      {
        Header: t('table:Opening Balance'),
        accessor: (originalRow) => {
          return `${transformStringAmountInput(
            originalRow.opening_balance,
          )} ${renderCurrency(originalRow)}`;
        },
        id: 'opening_balance',
        width: 150,
      },
      {
        Header: t('table:Amount'),
        accessor: (originalRow) => {
          return renderAmount(originalRow);
        },
        id: 'amount',
        width: 150,
        disableSortBy: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ withdraw_transaction, deposit_transaction }, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="fas fa-file-alt fa-1x c-pointer" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-start">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isEmpty(userId) && (
                    <>
                      {withdraw_transaction && (
                        <>
                          <Link
                            to={`/admin/users/${withdraw_transaction.user.uuid}`}
                          >
                            <DropdownItem>
                              <i className="fas fa-user" />
                              &nbsp;&nbsp;
                              {t('table:User Detail')}
                            </DropdownItem>
                          </Link>
                          <div className="dropdown-divider" />
                        </>
                      )}

                      {deposit_transaction && (
                        <>
                          <Link to={`/admin/users/${deposit_transaction.user.uuid}`}>
                            <DropdownItem>
                              <i className="fas fa-user" />
                              &nbsp;&nbsp;
                              {t('table:User Detail')}
                            </DropdownItem>
                          </Link>
                          <div className="dropdown-divider" />
                        </>
                      )}
                    </>
                  )}

                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('table:Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'action',
        width: 100,
        disableSortBy: true,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(onGetMovement(payload));
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS: any = ['created_at', 'email', 'role', 'uuid'];
  const hiddenOnSM: any = ['email', 'role', 'uuid'];
  const hiddenOnMD: any = ['role', 'uuid'];
  const hiddenOnLG: any = [];

  const modalType = // eslint-disable-next-line no-nested-ternary
    !isEmpty(txn) && !isEmpty(txn.items)
      ? txn.items[selectedRow].withdraw_transaction
        ? transactionModalTypes.WITHDRAW
        : transactionModalTypes.DEPOSIT
      : null;

  if (txn && selectRow) {
    if (
      txn.items[selectedRow].transaction_type_id === movementTypes.WITHDRAW ||
      txn.items[selectedRow].transaction_type_id === movementTypes.DEPOSIT
    ) {
      moreDetails = (
        <TransactionDetailModal
          data={selectRow}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Deposit Detail')}
          modalType={modalType}
        />
      );
    } else if (
      txn.items[selectedRow].transaction_type_id === movementTypes.REFERRAL
    ) {
      moreDetails = (
        <ReferralDetailModal
          data={selectRow}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Referral Detail')}
        />
      );
    } else if (
      txn.items[selectedRow].transaction_type_id === movementTypes.PAYMENT
    ) {
      moreDetails = (
        <PaymentDetailModal
          data={selectRow}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Payment Detail')}
        />
      );
    } else {
      moreDetails = (
        <EarningDetailModal
          data={selectRow}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Earning Detail')}
        />
      );
    }
  }

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetMovement(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, type, page, pageSize],
  );

  if (!txn) return <></>;
  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        {moreDetails}
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
              colors="white"
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={4} lg={3} style={{ marginRight: '40px' }}>
                <MovementTypeOptions
                  defaultValue={type}
                  handleTypeChange={handleTypeChange}
                />
              </Col>
              <Col sm={12} md={8} lg={5}>
                <SearchBar
                  placeholder={t('table:Search Placeholder')}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(MovementTransactionsTable);
