import { call, put, takeEvery } from 'redux-saga/effects';
import { ActivityLogTypes } from './actionTypes';
import {
  getRecentActivityLogsSuccess,
  getActivityLogsFail,
  getActivityLogTransactionsSuccess,
  markAsAllReadSuccess,
  markAsAllReadFail,
  getAllAdminApproveLogSuccess,
} from './actions';
import {
  getRecentActivityLogs,
  getActivityLogTransactions,
  getAllAdminApproveLogGql,
  markAsAllRead as markAsAllReadGql,
} from '../../graphql/common/activity-logs';
import { setActivityLogLoading, unsetActivityLogLoading } from '../global/actions';

function* fetchRecentActivityLogs() {
  try {
    const response: Promise<any> = yield call(getRecentActivityLogs);

    yield put(getRecentActivityLogsSuccess(response));
  } catch (error) {
    yield put(getActivityLogsFail(error));
  }
}

function* getAllAdminApproveLog() {
  try {
    const response: Promise<any> = yield call(getAllAdminApproveLogGql);

    yield put(getAllAdminApproveLogSuccess(response));
  } catch (error) {
    ///
  }
}

function* fetchActivityLogTransactions({
  payload: ActivityLogTransactionsQuery,
}: any) {
  try {
    yield put(setActivityLogLoading());
    const response: Promise<any> = yield call(
      getActivityLogTransactions,
      ActivityLogTransactionsQuery,
    );

    yield put(getActivityLogTransactionsSuccess(response));
  } catch (error) {
    yield put(getActivityLogsFail(error));
  } finally {
    yield put(unsetActivityLogLoading());
  }
}

function* markAsAllRead() {
  try {
    const responseMask: Promise<any> = yield call(markAsAllReadGql);
    const response: Promise<any> = yield call(getRecentActivityLogs);
    yield put(markAsAllReadSuccess(responseMask));
    yield put(getRecentActivityLogsSuccess(response));
  } catch (error) {
    // Error
    yield put(markAsAllReadFail());
  }
}

function* activityLogsSaga() {
  yield takeEvery(
    ActivityLogTypes.GET_RECENT_ACTIVITY_LOGS,
    fetchRecentActivityLogs,
  );
  yield takeEvery(ActivityLogTypes.GET_ALL_ADMIN_APPROVE_LOG, getAllAdminApproveLog);

  yield takeEvery(
    ActivityLogTypes.GET_ACTIVITY_LOG_TRANSACTIONS,
    fetchActivityLogTransactions,
  );

  yield takeEvery(ActivityLogTypes.MARK_AS_ALL_READ, markAsAllRead);
}

export default activityLogsSaga;
