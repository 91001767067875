/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import {
  getAllAdminApproveLog,
  getReportJob,
  setLoading,
  unsetLoading,
  getAdminReportJobDecline,
  socialMediaFilterTable,
} from 'src/store/actions';
import { Dialog, DialogContent } from '@mui/material';
import {
  updateSocialMediaHasGleamJobGql,
  updateSocialMediaHasJobGql,
  adminUpdateReportDecline,
} from 'src/graphql/advertiser/socialMediaInfo';
import Dropdown from 'src/components/CommonForBoth/Menus/Dropdown';
import SearchBar from 'src/components/Table/SearchBar';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import { banReportJobSQL, ignoreReportJobGQL } from 'src/graphql/advertiser/job';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { Approve, Reject } from 'src/components/Common/approval/approvalStatus';

const ReportDeclineJobPage = ({ verifyStatus, t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);
  //default payload
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: newVerifyStatus,
    social_media_type: null,
    sortBy: null,
    sortType: null,
    search: null,
  });

  const { declinedJob } = useSelector((state: any) => ({
    declinedJob: state.SocialMediaInfo.declinedJob,
  }));

  console.log('declinedJob', declinedJob);

  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  useEffect(() => {
    // fetchApproveLog();
    //dispatchจากหลังบ้าน
    resetApproveLogValueByName('report_job_decline');
    dispatch(getAdminReportJobDecline(getDefaultPayload(1)));
  }, []);

  const clickVerifyStatus = async (
    verify: string,
    id: string,
    marketerApproveDate?: Date,
  ) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(setLoading());
          const res = await adminUpdateReportDecline({
            id,
            status: verify,
            marketer_approve_date: marketerApproveDate,
          });
          if (res) {
            dispatch(getAdminReportJobDecline(getDefaultPayload(1)));
            dispatch(unsetLoading());
          } else {
            dispatch(unsetLoading());
          }
        } catch (error) {
          dispatch(unsetLoading());
        }

        // dispatch(socialMediaFilterTable(param));
      }
    });
  };

  const clickIgnoreJob = async (job_id: number) => {
    try {
      dispatch(setLoading());
      const res = await ignoreReportJobGQL(job_id);
      if (res) {
        dispatch(getAdminReportJobDecline(getDefaultPayload(1)));
        notify(i18n.t('success:ignore job success'), 'success');
        dispatch(unsetLoading());
      } else {
        notify(i18n.t('error:ignore job fail'), 'error');
        dispatch(unsetLoading());
      }
    } catch (error) {
      notify(i18n.t('error:ignore job fail'), 'error');
      dispatch(unsetLoading());
    }
  };

  const renderReportStatus = (status: string, report_status: string) => {
    return report_status ? t(report_status) : t(status);
  };

  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('marketer:job_detail:Job_name'),
        accessor: ({ social_media_has_job }) => {
          return social_media_has_job.job.name;
        },
        id: 'job_name',
        disableSort: true,
      },
      {
        Header: t('table:Social Name'),
        accessor: ({ social_media_has_job }) => {
          return social_media_has_job.social_media_info.name;
        },
        id: 'social_name',
        disableSort: true,
      },
      {
        Header: t('table:Social'),
        accessor: ({ social_media_has_job }: any) =>
          social_media_has_job.social_media_info.social_media_type,
        id: 'social_media_type',
        disableSort: true,
      },
      {
        Header: t('table:Reason'),
        accessor: ({ social_media_has_job }: any) =>
          social_media_has_job.reject_message,
        id: 'reason',
        disableSort: true,
      },
      {
        Header: t('table:view detail'),
        accessor: ({ social_media_has_job }: any) => {
          const path = `/admin/reportDeclineJob/Detail?job=${social_media_has_job.job.key}&key=${social_media_has_job.key}`;
          return (
            <a className="cursor  fw-bold view-detail" href={path} target="blank">
              {t(`table:view detail`)}
            </a>
          );
        },
        id: 'detail',
        disableSort: true,
      },

      {
        Header: t('marketer:create_campaign:status'),
        accessor: ({ status }: any) => {
          let badgeColor: string = 'warning';
          switch (status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ id, status }: any) => {
          if (status === VerifyStatus[1]) {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Approve message="Approve" />
              </div>
            );
          } else if (status === VerifyStatus[2]) {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Reject message="Rejected" />
              </div>
            );
          } else {
            return (
              <UncontrolledDropdown className="">
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="bx bx-pencil" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    onClick={() => clickVerifyStatus('APPROVED', id, new Date())}
                    className="text-success"
                  >
                    <i className="mdi mdi-account-check text-success" />
                    &nbsp;&nbsp;{t('table:Approve')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => clickVerifyStatus('REJECTED', id)}
                    className="text-danger"
                  >
                    <i className="mdi mdi-account-cancel text-danger" />
                    &nbsp;&nbsp;{t('table:Reject')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          }
        },
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ],
    [t, declinedJob],
  );

  const data = React.useMemo(() => {
    return !isEmpty(declinedJob) ? declinedJob.items : [];
  }, [declinedJob]);

  console.log('data', declinedJob.items);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(1),
      page: newPage,
    };

    dispatch(getAdminReportJobDecline(payload));
    setPage(newPage);
    setParam(payload);
  };

  const handleStatusChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      status: event.value,
    };
    dispatch(getAdminReportJobDecline(payload));
    setNewVerifyStatus(event.value);
    setParam(payload);
  };

  const handleSocialChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      social_media_type: event.value,
    };
    dispatch(getAdminReportJobDecline(payload));
    setNewVerifyStatus(event.value);
    setParam(payload);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getAdminReportJobDecline(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  const Socials = [
    { value: 'TWITTER', label: t('TWITTER') },
    { value: 'FACEBOOK', label: t('FACEBOOK') },
    { value: 'INSTAGRAM', label: t('INSTAGRAM') },
    { value: 'TIKTOK', label: t('TIKTOK') },
    { value: 'YOUTUBE', label: t('YOUTUBE') },
    { value: null, label: t('All') },
  ];

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(getAdminReportJobDecline(payload));
    setSearch(val);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(getAdminReportJobDecline(payload));
    setSearch('');
    setParam(payload);
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getAdminReportJobDecline(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, page, pageSize],
  );

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('report_job_decline');
    dispatch(getAllAdminApproveLog());
  };

  if (!declinedJob) return <></>;

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={declinedJob.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '150px', marginRight: '20px' }}>
              <div className="text-white">{t('Social')}</div>
              <Dropdown
                options={Socials}
                action={handleSocialChange}
                default_value={Socials[3]}
              />
            </div>
            <div style={{ width: '150px', marginRight: '20px' }}>
              <div className="text-white">{t('Status')}</div>
              <Dropdown
                options={Status}
                action={handleStatusChange}
                default_value={Status[5]}
              />
            </div>
            <SearchBar
              placeholder={t('table:Search Name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(ReportDeclineJobPage);
