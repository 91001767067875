import { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { layoutTheme } from '../../../constants/layout';
import YellowPlus from '../Img/icons/yellowPlus.png';
import YellowPlusDark from '../Img/icons/yellowPlusDark.png';

const AddCampaignWidget = ({ t }: any) => {
  const history = useHistory();
  const [hover, setHover] = useState(false);

  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`mb-0 ${hover && 'card-hover'}`}
      style={{ minHeight: '200px' }}
      onClick={() => {
        history.push('/campaign/create');
      }}
    >
      <CardBody className="d-flex justify-content-center align-items-center flex-wrap">
        <img
          src={layoutMode === layoutTheme.LIGHTMODE ? YellowPlus : YellowPlusDark}
          alt="NewCampaign"
          className="img-lg"
        />
        <p className="font-size-20 fw-bold text-center m-0 mx-5 p-0">
          {t("campaign:Let's Create Your First")}
          <br />
          {t('campaign:Advertisement. (New Campaign)')}
        </p>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(AddCampaignWidget);
