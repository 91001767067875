import { useEffect, useRef, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Card } from 'reactstrap';
import HeaderJobDetail from 'src/components/Publisher/Influencer/JobDetail/headerJobDetail';
import JobDetailCollapse1 from 'src/components/Publisher/Influencer/JobDetail/jobDetailCollapse1';
import JobDetailCollapse2 from 'src/components/Publisher/Influencer/JobDetail/jobDetailCollapse2';
import JobDetailCollapse3 from 'src/components/Publisher/Influencer/JobDetail/jobDetailCollapse3';
import JobDetailCollapse4 from 'src/components/Publisher/Influencer/JobDetail/jobDetailCollapse4';
import paramsFromURL from 'src/constants/paramsFromURL';
import i18n from 'src/i18n';
import {
  clearExpiredSocialMediaToken,
  getExpiredSocialMediaToken,
  getMarketerJob,
  getMarketerJobSummary,
  getSocialJob,
  resetSocialJob,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import notify from 'src/utils/notify';
import './influencerJobDetail.style.scss';
import JobDetailPreview from 'src/components/Publisher/Influencer/JobDetail/jobDetailPreview';
import JobDetailWork from 'src/components/Publisher/Influencer/JobDetail/jobDetailWork';
import { getAvaliableSocialForGleamJobPublicGQL } from 'src/graphql/advertiser/job';
import Header from 'src/components/Layout/Header';

const InfluencerJobDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [socialJobId, able_get_job, group_key]: any = paramsFromURL([
    'detail',
    'able_get_job',
    'task',
  ]);

  let [jobId]: any = paramsFromURL(['job']);

  const { influencerData, socialJobItem, marketerJob, socialMediaExpired, user } =
    useSelector((state: any) => ({
      socialJobItem: state.SocialMediaInfo.socialJobItem,
      marketerJob: state.MarketerJob.marketerJob,
      influencerData: state.Users.userInfo,
      socialMediaExpired: state.SocialMediaInfo.socialMediaInfoExpiredToken,
      user: state.login.user,
    }));
  const [formSubmit, setFormSubmit] = useState<any>();
  const [collapse, setCollapse] = useState({
    collapse1: false,
    collapse2: false,
    collapse3: false,
    collapse4: false,
  });

  const { _jobId }: any = useParams();
  if (user === null) {
    jobId = _jobId;
  }

  const [exampleMessage, setExampleMessage] = useState('');
  useEffect(() => {
    if (socialJobItem && !Object.keys(socialJobItem)?.length) return;
    setExampleMessage(socialJobItem?.example_message);
    setFormSubmit({ ...formSubmit, submit_job_link: socialJobItem?.link_post });

    dispatch(getExpiredSocialMediaToken(socialJobItem.social_media_info.key));
    if (!socialJobItem?.accept_date) {
      setCollapse({ ...collapse, collapse1: true });
      return;
    }
    if (!socialJobItem?.influencer_submit_date) {
      setCollapse({ ...collapse, collapse2: true });
      return;
    }
    if (!socialJobItem?.post_date) {
      setCollapse({ ...collapse, collapse3: true });
      return;
    }

    setCollapse({ ...collapse, collapse4: true });
  }, [socialJobItem]);

  useEffect(() => {
    if (socialMediaExpired?.items?.length > 0 && socialJobItem.social_media_info) {
      const checkExist = socialMediaExpired.items.filter((social: { key: any }) => {
        return social.key === socialJobItem.social_media_info.key;
      });
      if (checkExist) {
        setTimeout(() => {
          notify(
            i18n.t('error:Some access token of your social media were expired.'),
            'warning',
          );
          history.push('/influencer/renew');
        }, 500);
      }
    }
  }, [socialMediaExpired]);

  useEffect(() => {
    if (socialJobId) {
      dispatch(getSocialJob(socialJobId));
    } else {
      setCollapse({ ...collapse, collapse1: true });
    }

    dispatch(getMarketerJobSummary(jobId));
  }, []);

  const workOrderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (workOrderRef.current) {
      workOrderRef.current.innerHTML = marketerJob?.work_order;
    }
    console.log('marketerJob', marketerJob);
    // TODO: recheck 'PAUSE' status. Why redirect to homepage?
    // if (!socialJobId) {
    //   if (marketerJob && marketerJob.status === 'PAUSE') {
    //     window.location.replace(`/`);
    //   }
    // }
    // Else block is omitted intentionally, meaning it does nothing
  }, [workOrderRef.current, marketerJob]);

  // if (!marketerJob) return <></>;
  // if (!Object.keys(socialJobItem).length) return <></>;
  if (!socialJobItem) return <></>;

  return (
    <>
      <>
        {socialJobItem && !group_key && Object.keys(socialJobItem)?.length ? (
          <HeaderJobDetail marketerJob={marketerJob} socialJobItem={socialJobItem} />
        ) : (
          <>
            {user === null ? <Header /> : null}

            <div style={{ marginTop: '70px', backgroundColor: '#1a1a1a' }} />
          </>
        )}
        <div
          className="page-content influencer-job-detail-container"
          style={{
            paddingTop: '20px',
          }}
        >
          {socialJobId ? (
            <Card className="influencer-timeline-card">
              <JobDetailCollapse1
                collapse={collapse}
                marketerJob={marketerJob}
                workOrderRef={workOrderRef}
                socialJobItem={socialJobItem}
                setCollapse={setCollapse}
                able_get_job={able_get_job}
              />
              <>
                {!socialJobItem?.link_post && (
                  <JobDetailCollapse2
                    collapse={collapse}
                    socialJobItem={socialJobItem}
                    setCollapse={setCollapse}
                    setExampleMessage={setExampleMessage}
                    exampleMessage={exampleMessage}
                    marketerJob={marketerJob}
                  />
                )}

                <JobDetailCollapse3
                  setCollapse={setCollapse}
                  collapse={collapse}
                  socialJobItem={socialJobItem}
                  formSubmit={formSubmit}
                  setFormSubmit={setFormSubmit}
                />

                <JobDetailCollapse4
                  socialJobItem={socialJobItem}
                  setCollapse={setCollapse}
                  collapse={collapse}
                  marketerJob={marketerJob}
                />
              </>
            </Card>
          ) : (
            <>{group_key ? <JobDetailWork /> : <JobDetailPreview />}</>
          )}
        </div>
      </>
    </>
  );
};

export default InfluencerJobDetail;
