import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link, useParams, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import done from '../assets/images/done.png';
import { changePasswordConfirm } from '../store/auth/changepassword/actions';

const ChangePasswordConfirmPage = ({ location, t, history }: any) => {
  const dispatch = useDispatch();
  const { type }: any = useParams();
  const { id, token, signature } = queryString.parse(location.search);

  useEffect(() => {
    const data = {
      type,
      id,
      token,
      signature,
    };

    dispatch(changePasswordConfirm(data, history));
  }, []);

  return (
    <div className="my-5 pt-5">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-5">
              <h1 className="display-1 fw-semibold">
                2<span className="text-primary mx-2">0</span>0
              </h1>
              <h4 className="text-uppercase">{type} change password successfully</h4>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary" to="/">
                  {t('Back To Dashboard')}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row justify-content-center">
          <div className="col-md-10 col-xl-8">
            <div>
              <img src={done} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(withRouter(ChangePasswordConfirmPage));
