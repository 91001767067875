import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import CustomButton from 'src/components/Common/CustomButton';
import control from './SortBy.style';

const DivContent = styled.div`
  // overflow: auto;
  height: 100%;
  text-align: center;
  // margin-bottom: 5rem;
  // background-color: #fbfaff;
`;

// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyType {
  // eslint-disable-next-line no-unused-vars
  FOLLOWER = 'FOLLOWER',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'INFLUENCER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum County {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  Thailand = 'Thailand',
  // eslint-disable-next-line no-unused-vars
  OTHER = 'OTHER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'MEN',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'WOMEN',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyInfluencer {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  FOLLOWERS = 'follower',
  // eslint-disable-next-line no-unused-vars
  RATING = 'rating',
  // eslint-disable-next-line no-unused-vars
  COST = 'photo_content_price',
}

// interface FilterForm {
//   propertyType: PropertyType;
//   country?: string;
//   gender?: Gender;
//   max_age?: number;
//   min_age?: number;
//   aptitude_ids: string[];
//   limit?: number;
//   pages?: number;
//   sort_by?: string;
//   name?: string;
// }

const { ModalStyle, Texts, FlexRow, FlexCol, CloseBtn } = control();
const ModalBan = (props: any) => {
  const [selected, setSelected] = useState('');
  const { isOpen, toggle, uuid, onBanAccount } = props;
  const { t } = useTranslation();
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));
  const intitailValue = {
    uuid_ban: '',
    day: 0,
    reason: '',
  };
  const [formValues, setFormValues] = useState(intitailValue);

  return (
    <ModalStyle isOpen={isOpen} toggle={toggle} centered size="lg">
      <DivContent>
        <Texts fontsize="1.5rem" margin="10px auto">
          {t('Ban')}
        </Texts>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '10px' }}>{t('Day')}</span>
            <input
              min={1}
              className="form-control"
              style={{ width: '80px', margin: '10px 0px 10px 10px' }}
              type="number"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  day: Number(e.target.value),
                  uuid_ban: uuid,
                });
              }}
            />
          </div>
          <div>{t('Tell users why we have to ban')}</div>
          <textarea
            className="form-control"
            style={{ width: '70%', height: '90px', margin: '10px 0 10px 0' }}
            onChange={(e) => {
              setFormValues({ ...formValues, reason: e.target.value });
            }}
          />
          <div
            style={{
              margin: '10px 0 10px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            <div
              onClick={(e) => {
                onBanAccount(formValues);
              }}
            >
              <CustomButton label={t('Confirm')} status="submit" size="ssss" />
            </div>
            <div
              onClick={async (e) => {
                toggle();
              }}
            >
              <CustomButton label={t('Cancel')} status="cancel" size="sss" />
            </div>
          </div>
        </div>
      </DivContent>
    </ModalStyle>
  );
};

export default ModalBan;
