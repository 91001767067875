import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialJobByJobKey } from 'src/store/actions';
import { requesPriceOnSuggestJobGQL } from 'src/graphql/advertiser/socialMediaInfo';
import { floorToFixed } from 'src/common/data/mathToFixed';
import Role from 'src/constants/enum/role';

interface RequestProps {
  price: any;
  symbol: any;
  tax: any;
  social_key: any;
  status: any;
  approve_job_date: any;
  isPublish: any;
  payload: any;
}

const RequestPrice = ({
  price,
  symbol,
  tax,
  social_key,
  status,
  approve_job_date,
  isPublish,
  payload,
}: RequestProps) => {
  const [changePriceTab, setChangePriceTab] = useState(true);
  const [Prices, setPrices] = useState(Number(price + tax));
  const [isChangeNumber, setIsChangeNumber] = useState(false);
  const [total, setTotal] = useState(0);
  const symbolSprit = (symbol: any) => {
    const xx = symbol.split('_');
    return xx[0];
  };
  const { data, defaultTax, user, wallet } = useSelector((state: any) => ({
    data: state.SocialMediaInfo.socialJob?.items,
    // campaign_key: state.SocialMediaInfo.socialJob?.marketer_campaign_key,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    user: state.login.user,
    wallet: state?.Wallet?.wallet,
  }));

  const dispatch = useDispatch();
  const openRequestPrice = (
    price: any,
    tax: any,
    status: any,
    approve_job_date: any,
  ) => {
    const showfix = () => (
      <span
        onClick={() => {
          setChangePriceTab(false);
        }}
      >
        <i className="bx bx-pencil" style={{ cursor: 'pointer' }} />
      </span>
    );

    return (
      <>
        <span>
          {isChangeNumber === false
            ? `${floorToFixed(price + tax, 2)} ${symbolSprit(symbol)}`
            : `${floorToFixed(Prices, 2)} ${symbolSprit(symbol)}`}
        </span>
        {status === 'CANCEL' ||
        approve_job_date !== null ||
        user.role === Role.ADMIN ||
        !isPublish
          ? ''
          : showfix()}
      </>
    );
  };
  const closeRequestPrice = (price: number, tax: number, key: any) => {
    const handleInputChange = (e: any) => {
      const inputValue = e.target.value;
      const validInput = /^\d*\.?\d*$/.test(inputValue);
      if (!validInput) {
        e.target.value = '';
      }
      setTotal(parseFloat(e.target.value));
    };
    return (
      <>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <input
            min={0.0000000000000000000000001}
            type="text"
            onChange={handleInputChange}
            style={{ width: '50px', marginRight: '10px' }}
          />
          <span>{symbolSprit(symbol)}</span>
        </span>
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <i
            className="fas fa-check"
            onClick={() => {
              requestNewPrice(key, +total);
              dispatch(getSocialJobByJobKey(payload));
              setPrices(+total);
              setChangePriceTab(true);
              setIsChangeNumber(true);
            }}
            style={{
              cursor: 'pointer',
              backgroundColor: 'green',
              padding: '5px',
              color: 'white',
              borderRadius: '50%',
            }}
          />
          <i
            onClick={() => {
              setChangePriceTab(true);
            }}
            className="fas fa-ban"
            style={{
              cursor: 'pointer',
              backgroundColor: 'red',
              padding: '5px',
              color: 'white',
              borderRadius: '50%',
            }}
          />
        </span>
      </>
    );
  };
  const requestNewPrice = async (social_job_key: any, request_price: number) => {
    const res: any = await requesPriceOnSuggestJobGQL(social_job_key, request_price);
    return res;
  };
  return (
    <div style={{ color: 'rgb(0, 154, 120)' }}>
      {changePriceTab === true
        ? openRequestPrice(price, tax, status, approve_job_date)
        : closeRequestPrice(price, tax, social_key)}
    </div>
  );
};

export default RequestPrice;
