import { call, put, takeEvery } from 'redux-saga/effects';

import {
  allowNotificationMailGraphql,
  updateAllowNotificationMail,
} from 'src/graphql/common/allow-notification-mail';

import { AllowNotificationMailTypes } from './actionTypes';

import {
  getAllowNotificationMailsSuccess,
  getAllowNotificationMailsFail,
  updateAllowNotificationMailsSuccess,
  updateAllowNotificationMailsFail,
} from './actions';

import { setLoading, unsetLoading } from '../actions';

function* fetchAllowNotificationMails() {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(allowNotificationMailGraphql);

    yield put(getAllowNotificationMailsSuccess(response));
  } catch (error) {
    yield put(getAllowNotificationMailsFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchUpdateAllowNotificationMails(payload: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(
      updateAllowNotificationMail,
      payload.data,
    );
    if (response) {
      yield put(updateAllowNotificationMailsSuccess(response));
    }
  } catch (error) {
    yield put(updateAllowNotificationMailsFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* allowNotificationMails() {
  yield takeEvery(
    AllowNotificationMailTypes.GET_ALLOW_NOTIFICATION_MAIL,
    fetchAllowNotificationMails,
  );
  yield takeEvery(
    AllowNotificationMailTypes.UPDATE_ALLOW_NOTIFICATION_MAIL,
    fetchUpdateAllowNotificationMails,
  );
}

export default allowNotificationMails;
