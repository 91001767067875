import { takeEvery, fork, put, all, call, delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import {
  register as registerGql,
  verifyMail as verifyMailGql,
} from '../../../graphql/common/auth';

// Account Redux states
import RegisterTypes from './actionTypes';
import {
  registerUserSuccessful,
  registerUserFailed,
  verifyEmailFailed,
  verifyEmailSuccessful,
} from './actions';
import { setLoading, unsetLoading } from '../../global/actions';
import { Response } from '../../../common/types';
import { getErrorMessageByErrorCode } from '../../../utils/error';
import notify from '../../../utils/notify';
import i18n from '../../../i18n';

function* registerUser({ payload: { data, history } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(registerGql, data);

    yield delay(800);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        registerUserFailed(
          getErrorMessageByErrorCode(response.errors[0].error_code),
        ),
      );
    }

    if (!isEmpty(response) && !isEmpty(response.data)) {
      yield put(registerUserSuccessful(response.data.register));

      history.push('/confirm-mail', { from: 'saga', role: data.role });
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* verifyMail({ payload: { data, history, role } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(verifyMailGql, data);

    yield delay(800);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        verifyEmailFailed(getErrorMessageByErrorCode(response.errors[0].error_code)),
      );
    } else {
      yield put(verifyEmailSuccessful());
      notify(i18n.t('success:Verify Email Success'), 'success');
      const roleText = role === '0' ? 'INFLUENCER' : 'MARKETER';
      history.push(`/login/${roleText.toLowerCase()}`);
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

export function* watchUserRegister() {
  yield takeEvery(RegisterTypes.REGISTER_USER, registerUser);
  yield takeEvery(RegisterTypes.VERIFY_EMAIL, verifyMail);
}

function* registerSaga() {
  yield all([fork(watchUserRegister)]);
}

export default registerSaga;
