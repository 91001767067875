import { gql } from '@apollo/client';
import { WebsiteTransactionQuery } from 'src/common/types';
import { client } from '../client';

// Publisher
const getEarningTransaction = async (
  limit: any = 10,
  page: any = 1,
  user_id?: any,
  email?: any,
  website_id?: any,
  application_key?: any,
  sortBy?: any,
  sortType?: any,
) => {
  const GET_EARNING_TRANSACTION = gql`
    query publisherEarningTransaction(
      $limit: Int!
      $page: Int!
      $user_id: String
      $email: String
      $website_id: Int
      $application_key: String
      $sortBy: String
      $sortType: String
    ) {
      publisherEarningTransaction(
        input: {
          limit: $limit
          page: $page
          userId: $user_id
          email: $email
          sortBy: $sortBy
          sortType: $sortType
        }
        website_id: $website_id
        application_key: $application_key
      ) {
        items {
          id
          uuid
          user_id
          website_id
          wallet_id
          FIAT_amount
          created_at
          updated_at
          movement {
            transaction_type_id
            opening_balance
          }
          website {
            url
          }
          application {
            name
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_EARNING_TRANSACTION,
    variables: {
      limit,
      page,
      userId: user_id,
      email,
      website_id: +website_id,
      application_key,
      sortBy,
      sortType,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.publisherEarningTransaction;
};

// Admin
const getAdminEarningTransaction = async (
  limit: any = 10,
  page: any = 1,
  user_id?: any,
) => {
  const GET_ADMIN_EARNING_TRANSACTION = gql`
    query adminPublisherEarningTransaction(
      $limit: Int!
      $page: Int!
      $user_id: String
    ) {
      adminPublisherEarningTransaction(
        input: { limit: $limit, page: $page, userId: $user_id }
      ) {
        items {
          id
          uuid
          user_id
          social_media_has_job_id
          website_id
          wallet_id
          FIAT_amount
          created_at
          updated_at
          movement {
            transaction_type_id
            opening_balance
          }
          website {
            url
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_ADMIN_EARNING_TRANSACTION,
    variables: {
      limit,
      page,
      user_id,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.adminPublisherEarningTransaction;
};

export const adminPublisherEarningTransactionDetail = async (
  social_media_has_job_id: number,
) => {
  const query = gql`
    query adminPublisherEarningTransactionDetail($social_media_has_job_id: Float!) {
      adminPublisherEarningTransactionDetail(
        social_media_has_job_id: $social_media_has_job_id
      ) {
        job {
          name
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: {
        social_media_has_job_id,
      },
      fetchPolicy: 'no-cache',
    });
    return res.data.adminPublisherEarningTransactionDetail;
  } catch (error: any) {
    // if (error?.message !== 'Unauthorized')
    // notify(i18n.t('error:Get filterFromRequirements error .'), 'error');
    return error;
  }
};
// Admin
// const adminPublisherEarningTransactionDetail = async (
//   social_media_has_job_id: number,
// ) => {
//   console.log('adminPublisherEarningTransactionDetail');
//   const GET_ADMIN_EARNING_TRANSACTION_DETAIL = gql`
//     query adminPublisherEarningTransactionDetail($social_media_has_job_id: Int!) {
//       adminPublisherEarningTransactionDetail(
//         social_media_has_job_id: $social_media_has_job_id }
//       ) {
//         job {
//             name
//         }
//     }
//   `;

//   const result: any = await client.query({
//     query: GET_ADMIN_EARNING_TRANSACTION_DETAIL,
//     variables: {
//       social_media_has_job_id,
//     },
//     fetchPolicy: 'no-cache',
//   });

//   return result.data.adminPublisherEarningTransactionDetail;
// };

// Admin
// const adminPublisherEarningTransactionDetailKey = async (
//   social_media_has_job_id: number,
// ) => {
//   const GET_ADMIN_EARNING_TRANSACTION_DETAIL_KEY = gql`
//     query adminPublisherEarningTransactionDetailKey($social_media_has_job_id: Int!) {
//       adminPublisherEarningTransactionDetailKey(
//         social_media_has_job_id: $social_media_has_job_id }
//       ) {
//         key
//         job {
//             key
//         }
//         social_media_info {
//             key
//         }
//     }
//   `;

//   const result: any = await client.query({
//     query: GET_ADMIN_EARNING_TRANSACTION_DETAIL_KEY,
//     variables: {
//       social_media_has_job_id,
//     },
//     fetchPolicy: 'no-cache',
//   });

//   return result.data.adminPublisherEarningTransactionDetailKey;
// };

const getWebsiteTransactionsGraph = async (queryInput: WebsiteTransactionQuery) => {
  const GET_WEBSITE_LOG_TRANSACTIONS = gql`
    query websiteLogTransactions($input: WebsiteLogTransactionsQueryInput!) {
      websiteLogTransactions(input: $input) {
        graphItems {
          creative_format
          total_click
          total_impression
          revenue
          updated_at
        }
      }
    }
  `;

  const result: any = await client.query({
    query: GET_WEBSITE_LOG_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.websiteLogTransactions;
};

const getEarningTransactionByUser = async (queryInput: WebsiteTransactionQuery) => {
  const GET_EARNING_TRANSACTION_BY_USER = gql`
    query allEarningTransactions($input: WebsiteLogTransactionsQueryInput!) {
      allEarningTransactions(input: $input) {
        graphItems {
          total_click
          total_impression
          revenue
          updated_at
        }
      }
    }
  `;

  const result: any = await client.query({
    query: GET_EARNING_TRANSACTION_BY_USER,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.allEarningTransactions;
};

const getWebsiteTransactionByDate = async (website_id: number, date: string) => {
  const query = gql`
    query getWebsiteTransactionByDate($website_id: Int!, $date: String!) {
      getWebsiteTransactionByDate(website_id: $website_id, date: $date) {
        creative_format
        total_click
        total_impression
        revenue
        creative_format
        ecpm
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      website_id: +website_id,
      date,
    },
  });
  return result.data.getWebsiteTransactionByDate;
};

export {
  getEarningTransaction,
  getAdminEarningTransaction,
  getWebsiteTransactionsGraph,
  getEarningTransactionByUser,
  getWebsiteTransactionByDate,
};
