import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function MessageInput({
  send,
  read,
}: {
  send: (val: string) => void;
  read: () => void;
}) {
  const [value, setValue] = useState('');
  const handleSubmit = (value: any) => {
    if (value) {
      send(value);
    } else {
      return;
    }

    // Send the message (e.g., to a server or handle locally)
    // Add the message to the messages state

    setValue(''); // Clear the input field
  };
  const handleEnterPress = (e: any) => {
    if (e.key === 'Enter') {
      // You can submit the data or perform an action here
      if (value) {
        send(value);
      }
      setValue(''); // Clear the input field
    }
  };
  const { t } = useTranslation();

  return (
    <div style={{ background: 'white', color: 'black' }}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder={t('Type your message')}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onKeyDown={handleEnterPress}
          onMouseEnter={() => {
            read();
          }}
        />
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => {
            handleSubmit(value);
          }}
        >
          {t('Send')}
        </button>
      </div>
    </div>
  );
}
