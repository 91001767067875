import BanType from './actionTypes';

interface BanState {
  banList: any[];
  checkIsBan: any;
}

export const INIT_STATE: BanState = {
  banList: [],
  checkIsBan: null,
};

const Ban = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case BanType.GET_BAN_HISTORY_SUCCESS:
      return {
        ...state,
        banList: action.payload,
      };
    case BanType.CHECK_IS_BANNED_SUCCESS:
      return {
        ...state,
        checkIsBan: action.payload,
      };
    default:
      return state;
  }
};

export default Ban;
