import AptitudeTypes from './actionTypes';

export const INIT_STATE = {
  aptitude: {},
  error: {},
  loading: false,
};

const Aptitudes = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AptitudeTypes.GET_APTITUDE_SUCCESS:
      return {
        ...state,
        aptitude: action.payload,
      };

    default:
      return state;
  }
};

export default Aptitudes;
