import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  filterSocialGleamJob,
  getSocialMediaInfoByUUID,
  socialJobCardData,
} from 'src/store/actions';
import Role from 'src/constants/enum/role';
import { Dialog, DialogContent, Rating } from '@mui/material';
import { pink } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { floorToFixed } from 'src/common/data/mathToFixed';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import SocialMediaHasJobJobStatuseOptions from '../Table/SocialMediaHasJobJobStatusOptions';
import JobStatusButtonGroup from '../Advertiser/jobStatusButtonGroup';
import { Approve, Pending, Reject } from '../Common/approval/approvalStatus';
import JobDetailPost from '../Publisher/Influencer/JobDetail/jobDetailPost';
import { InfluencerSocialIcon } from '../Common/status/InfluencerJobStatus';

export enum JobStatus {
  DRAFT = 'DRAFT',
  NEW_JOB = 'NEW_JOB',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  CANCEL = 'CANCEL',
  ALL = 'ALL',
}

export enum JobType {
  INVITE_ONLY = 'INVITE_ONLY',
  PUBLISH = 'PUBLISH',
}

const JobsHistoryTable = ({ t, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [status, setStatus] = useState('');
  const [job_type, setJobType] = useState('');
  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  const [param, setParam] = useState<any>({
    uuid: userId,
    limit: 10,
    page: 1,
    search: '',
    sortBy: null,
    sortType: null,
    status: null,
    job_type: null,
  });

  const { txn, userDetail } = useSelector((state: any) => ({
    txn: state.SocialMediaInfo.filterG,
    userDetail: state.Users.userDetail,
  }));

  useEffect(() => {
    dispatch(filterSocialGleamJob(getDefaultPayload(1)));
  }, []);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...param,
      page: newPage,
    };

    dispatch(filterSocialGleamJob(payload));
    setPage(newPage);
    setParam(payload);
  };
  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };
    setPage(1);
    dispatch(filterSocialGleamJob(payload));
    setParam(payload);
    setPageSize(newPageSize);
  };

  // const handleTypeChange = (type: string) => {
  //   const payload = {
  //     ...getDefaultPayload(1),
  //     socialMediaType: type,
  //   };

  //   dispatch(getSocialMediaInfoByUUID(payload));
  //   setSocialMediaType(type);
  // };

  const handleStatusChange = (_job_type: string) => {
    const payload = {
      ...param,
      page: 1,
      job_type: _job_type,
    };
    setPage(1);
    setParam(payload);
    setJobType(_job_type);
    dispatch(filterSocialGleamJob(payload));
  };

  const handleJobStatusChange = (_status: string) => {
    const payload = {
      ...param,
      page: 1,
      status: _status,
    };
    setPage(1);
    setParam(payload);
    setStatus(_status);
    dispatch(filterSocialGleamJob(payload));
  };

  const handleSearch = (val: string) => {
    setPage(1);
    const payload = {
      ...param,
      search: val,
    };
    dispatch(filterSocialGleamJob(payload));
    setSearch(search);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(filterSocialGleamJob(payload));
    setSearch('');
    setParam(payload);
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, link_post, social_media_info } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <JobDetailPost socialJobItem={postModalData} />
        </DialogContent>
      </Dialog>
    );
  };
  const getDefaultPayload = (pages: number) => {
    setPage(pages);
    setParam({
      ...param,
      page: pages,
    });

    return {
      ...param,
      page: pages,
    };
  };

  const backgroundColorStatus = (status: string) => {
    switch (status) {
      case 'DRAFT':
        return '#979797';
      case 'NEW_JOB':
      case 'INPROGRESS':
        return 'rgb(83, 198, 183)';
      case 'FINISHED':
        return 'rgb(34, 148, 237)';
      case 'CANCEL':
        return '#ff7784';
      case 'SUSPENDED':
        return '#ffbb54';
      default:
        return '';
    }
  };

  const rendorStatus = (_status: string) => {
    let status_txt = _status;
    if (_status === 'DRAFT') {
      status_txt = t('PENDING');
    } else if (_status === 'INPROGRESS') {
      status_txt = t('ACTIVE');
    } else if (_status === 'CANCEL') {
      status_txt = t('Rejected');
    } else {
      status_txt = t(_status);
    }

    return (
      <div>
        <div
          className="gleam-job-status center"
          style={{
            width: '250px',
            backgroundColor: `${backgroundColorStatus(_status)}`,
            margin: '0 auto',
          }}
        >
          <b>{t(status_txt)}</b>
        </div>
      </div>
    );
  };

  const rendorSocialImage = (social_media_info: any) => {
    const social_key: any[] = [];
    const social_image: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (
        !social_key.includes(social_media_info[i].key) &&
        social_media_info[i].social_media_type !== 'NOSOCIAL'
      ) {
        social_key.push(social_media_info[i].key);
        social_image.push(social_media_info[i].image_profile_url);
      }
    }
    return (
      <>
        {social_image?.map((url: any) => {
          return (
            <img
              src={url}
              alt="icons"
              className="image-icon"
              style={{
                width: '30px',
                height: '30px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              onError={(e) => {
                e.currentTarget.src = userAvatar;
              }}
            />
          );
        })}
      </>
    );
  };

  const rendorSocialName = (social_media_info: any) => {
    const social_key: any[] = [];
    const social_name: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (
        !social_key.includes(social_media_info[i].key) &&
        social_media_info[i].social_media_type !== 'NOSOCIAL'
      ) {
        social_key.push(social_media_info[i].key);
        social_name.push(social_media_info[i].name);
      }
    }
    return social_name.join(',');
  };

  const rendorSocialMediaType = (social_media_info: any) => {
    const social_type: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (!social_type.includes(social_media_info[i].social_media_type)) {
        social_type.push(social_media_info[i].social_media_type);
      }
    }
    return (
      <>
        {social_type?.map((socialType: any) => {
          return <InfluencerSocialIcon social_media_type={socialType} />;
        })}
      </>
    );
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('marketer:job_detail:Job_name'),
        accessor: ({ social_media_has_job }: any) => {
          return social_media_has_job?.job?.name;
        },
        id: 'job_name',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Influencer photo')}</div>,
        accessor: ({ social_media_info }: any) => {
          return rendorSocialImage(social_media_info);
        },
        id: 'influencer_image',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Influencer name')}</div>,
        accessor: ({ social_media_info }: any) =>
          rendorSocialName(social_media_info),
        id: 'influencer_name',
        disableSort: true,
      },
      {
        Header: t('table:social'),
        accessor: ({ social_media_info }: any) =>
          rendorSocialMediaType(social_media_info),
        id: 'social_media_type',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Prize'),
        accessor: ({ social_media_has_job }: any) => {
          return `${social_media_has_job?.price.toFixed(2)} ${
            social_media_has_job?.job?.currency?.main_symbol
          }`;
        },
        id: 'prize',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t(`table:Influencer Post`)}</div>,
        accessor: ({ social_media_has_job, social_media_info }: any) =>
          social_media_has_job?.job?.is_gleam_job ? (
            <>
              {social_media_has_job?.post_date ? (
                <a
                  href={`/admin/job/review?job=${social_media_has_job?.job?.key}&task=${social_media_has_job?.group}&key=${social_media_info[0]?.key}`}
                >
                  <button
                    style={{ background: 'white' }}
                    className="link-job"
                    type="button"
                  >
                    {t('table:View post')}
                  </button>
                </a>
              ) : (
                <Pending message="pending" />
              )}
            </>
          ) : (
            <>
              {social_media_has_job?.post_date ? (
                <button
                  style={{ background: 'white' }}
                  className="link-job"
                  type="button"
                  onClick={() => {
                    dispatch(
                      socialJobCardData({
                        social_media_type: social_media_info[0]?.social_media_type,
                        link_post: social_media_has_job?.link_post,
                        social_key: social_media_info[0]?.key,
                      }),
                    );
                    setPostModalData({
                      isOpen: true,
                      link_post: social_media_has_job?.link_post,
                      social_media_info: social_media_info[0],
                    });
                  }}
                >
                  {t('table:View post')}
                </button>
              ) : (
                <Pending message="pending" />
              )}
            </>
          ),
        id: 'influencer_post_date',
        disableSort: true,
      },
      {
        Header: t('table:Status'),
        accessor: ({ social_media_has_job }: any) => {
          return rendorStatus(social_media_has_job?.status);
        },
        id: 'status',
        disableSort: true,
      },
    ];
  }, [t, txn]);

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(filterSocialGleamJob(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, page, pageSize],
  );

  if (!txn) return <></>;

  return (
    <>
      {displayModal()}
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Row className="align-items-center justify-content-end">
              {/* <Col sm={12} md={4} lg={3}>
                <JobStatusButtonGroup
                  param={param}
                  handleStatusChange={handleStatusChange}
                  statusCount={data?.statusCount}
                />
              </Col> */}
              <Col sm={12} md={4} lg={3} style={{ marginRight: '40px' }}>
                <SocialMediaHasJobJobStatuseOptions
                  defaultValue={status}
                  handleStatusChange={handleJobStatusChange}
                />
              </Col>
              <Col sm={12} md={4} lg={5}>
                <SearchBar
                  placeholder={t('table:Social Media')}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(JobsHistoryTable);
