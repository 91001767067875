import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { getAllInviteOnlyTransactionsRequest } from 'src/store/finance/inviteOnly/actions';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import PaymentDetailModal from '../Table/PaymentDetailModal';

import control from '../Publisher/Influencer/Dashboard/SocialChannel/SocialCard/SocialCard.control';

const InviteOnlyTransactionTable = ({ t, tab, userId }: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const { onGetLogoSocialByType } = control();

  // Redux
  const { txn } = useSelector((state: any) => ({
    txn: state.inviteOnly.transactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(10);

    dispatch(
      getAllInviteOnlyTransactionsRequest({
        limit: 10,
        page: 1,
        userId,
        isCampaign: false,
        jobType: 'INVITE_ONLY',
      }),
    );
  }, [tab]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getAllInviteOnlyTransactionsRequest(payload));

    setPageSize(newPageSize);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(getAllInviteOnlyTransactionsRequest(payload));
    setSearch(val);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(getAllInviteOnlyTransactionsRequest(payload));
    setSearch('');
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Uuid'),
        accessor: ({ uuid }) => uuid,
        width: 200,
        id: 'col-7',
      },
      {
        Header: t('table:Amount'),
        accessor: ({ FIAT_amount, tax }) => (FIAT_amount + tax).toFixed(2),
        id: 'col-4',
      },
      {
        Header: t('table:Email'),
        accessor: ({ user }) => user?.email,
        id: 'col-5',
      },
      {
        Header: t('table:Job name'),
        accessor: ({ social_media_has_job }) => social_media_has_job?.job?.name,
        id: 'col-6',
      },
      {
        Header: t('table:Social'),
        accessor: ({ social_media_has_job }) => {
          return (
            <div className="social-card-img">
              <img
                className="logo"
                src={onGetLogoSocialByType(
                  social_media_has_job?.social_media_info?.social_media_type,
                )}
                alt="Social Logo"
                width={40}
              />
            </div>
          );
        },
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(getAllInviteOnlyTransactionsRequest(payload));
  };

  const getDefaultPayload = (defaultPage: number) => {
    setPage(defaultPage);

    return {
      limit: pageSize,
      page: defaultPage,
      search,
      userId,
      isCampaign: false,
      jobType: 'INVITE_ONLY',
    };
  };

  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenColumn={userId ? ['col-3'] : []}
      >
        <PaymentDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Payment Detail')}
        />
        <Row className="justify-content-between m-0">
          <Col xs={12} md={2} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
              colors="white"
            />
          </Col>
          <Col xs={12} md={10} lg={10}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={8} lg={5}>
                <SearchBar
                  placeholder={
                    userId
                      ? t('Search Campaign name')
                      : t('Search Email or Campaign name')
                  }
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(InviteOnlyTransactionTable);
