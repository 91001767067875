import { DepositTypes } from './actionTypes';
import { DepositTransactionsQuery } from '../../../common/types';

export const getDepositTransactionsRequest = (payload: DepositTransactionsQuery) => {
  return {
    type: DepositTypes.GET_DEPOSIT_TRANSACTIONS_REQUEST,
    payload: { ...payload },
  };
};

export const getDepositTransactionsRequestSuccess = (transactions: any) => {
  return {
    type: DepositTypes.GET_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS,
    payload: transactions,
  };
};

export const getDepositTransactionsRequestFail = (error: any) => {
  return {
    type: DepositTypes.GET_DEPOSIT_TRANSACTIONS_REQUEST_FAIL,
    payload: error,
  };
};

export const getAllDepositTransactionsRequest = (payload: {
  limit: number;
  page: number;
  currencySymbol?: string;
  search?: string;
  status?: string;
  userId?: string;
}) => {
  return {
    type: DepositTypes.GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST,
    payload: { ...payload },
  };
};

export const getAllDepositTransactionsRequestSuccess = (transactions: any) => {
  return {
    type: DepositTypes.GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS,
    payload: { ...transactions },
  };
};

export const getAllDepositTransactionsRequestFail = (errorMessage: any) => {
  return {
    type: DepositTypes.GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_FAIL,
    payload: errorMessage,
  };
};
