import { Card, Col, Row } from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
//component
import { layoutTheme } from 'src/constants/layout';
import BannerImg from './Img/banner.png';
import NativeImg from './Img/native.png';
import PopImg from './Img/pop.png';
import BannerImgDark from './Img/banner-dark.png';
import NativeImgDark from './Img/native-dark.png';
import PopImgDark from './Img/pop-dark.png';

const AdvertiseType = (props: any) => {
  //from parent
  const { ad_format, setAdFormat, layoutMode, t } = props;
  // const { width } = window.screen;

  const advertiseTypes = [
    {
      lightImage: BannerImg,
      darkImage: BannerImgDark,
      title: t('creative:Banner Ads'),
      desc: t('creative:Create Banner ads from picture or Gif.'),
      format: 'BANNER',
    },
    {
      lightImage: NativeImg,
      darkImage: NativeImgDark,
      title: t('creative:Native Ads'),
      desc: t('creative:Create ads with same format as Website.'),
      format: 'NATIVE',
    },
    {
      lightImage: PopImg,
      darkImage: PopImgDark,
      title: t('creative:Pop-under'),
      desc: t('creative:Create ads by make ads in new tabs.'),
      format: 'POP',
    },
  ];

  // const renderCardLg = () => {
  //   let menuWidth = 0;

  //   if (document.getElementsByClassName('vertical-menu').length) {
  //     menuWidth = document.getElementsByClassName('vertical-menu')[0].clientWidth;
  //   }

  //   if (width - menuWidth < 800) return 4;

  //   if (width - menuWidth < 1250) return 3;

  //   return 2;
  // };

  const renderAdvertiseType = () => {
    return advertiseTypes.map((item: any, index: number) => {
      return (
        <Col sm={index === 0 ? 12 : 6} md={3} lg={3}>
          <Card
            className={`main-card cursor align-items-center advertiser-type-card ${
              ad_format === item.format && 'card-hover'
            }`}
            onClick={() => setAdFormat(item.format)}
          >
            <div className="img-container mb-auto">
              <img
                src={
                  layoutMode === layoutTheme.DARKMODE
                    ? item.darkImage
                    : item.lightImage
                }
                alt="BannerImg"
              />
            </div>
            <div className="desc-container">
              <p className="font-size-20 mb-2 fw-bold">{item.title}</p>
              <p className="font-size-14 mb-0">{item.desc}</p>
            </div>
          </Card>
        </Col>
      );
    });
  };

  return (
    <div>
      <p className="font-size-22 fw-bold">{t('creative:Advertise Type')}</p>
      <Row className="each-item-mt-20px advertise-type">{renderAdvertiseType()}</Row>
    </div>
  );
};

export default withTranslation()(AdvertiseType);
