import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { getWebsites } from 'src/store/website/actions';
import WithdrawStatus from 'src/components/Common/WithdrawStatus';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { deleteWebsite } from 'src/graphql';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CustomTable from '../../Common/CustomTable';
import SearchBar from '../../Table/SearchBar';
import Dropdown from '../../CommonForBoth/Menus/Dropdown';

const WebsiteTable = ({ t }: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [status, setStatus] = useState('ALL');
  const [search, setSearch] = useState('');
  const [sortType, setSortType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const Status = [
    {
      value: '0',
      label: t('table:PENDING'),
    },
    { value: '1', label: t('table:APPROVED') },
    { value: '2', label: t('table:REJECTED') },
    { value: '', label: t('All') },
  ];

  const { website, websitesLoading } = useSelector((state: any) => ({
    website: state.Websitelist.website,
    websitesLoading: state.Global.websitesLoading,
  }));

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: t('table:Url'),
        accessor: ({ url, id }) => (
          <div
            style={{
              width: 250,
              paddingLeft: 20,
              textAlign: 'left',
            }}
          >
            <Link to={`/mywebsite/websiteinfo/${id}`}>
              <label className="label-link" htmlFor="web-url">
                {url?.length > 50 ? `${url.slice(0, 50)}... ` : url}
              </label>
            </Link>
          </div>
        ),
        id: 'url',
        rowCss: 'mt-3',
        width: 250,
      },
      {
        Header: t('table:Status'),
        accessor: ({ status: webStatus }) => <WithdrawStatus status={webStatus} />,
        id: 'status',
        rowCss: 'mt-3',
        width: 100,
      },
      {
        Header: t('table:Earnings/day'),
        accessor: (data: any) => (
          <div>{`${floorToFixed(data.current_revenue, 2)}  USDT`}</div>
        ),
        id: 'current_revenue',
        rowCss: 'mt-3',
        width: 150,
      },
      {
        Header: t('table:Maxpayout'),
        accessor: ({ revenue }) => <div>{`${floorToFixed(revenue, 2)}  USDT`}</div>,
        id: 'revenue',
        rowCss: 'mt-3',
        width: 150,
      },
      {
        Header: t('table:CreateAt'),
        accessor: (date: any) => new Date(date.created_at).toLocaleDateString(),
        id: 'created_at',
        rowCss: 'mt-3',
        width: 100,
      },
      {
        Header: t('table:Action'),
        accessor: ({ id, url }) => (
          <button
            type="button"
            onClick={() => submit_delete(id, url)}
            style={{
              width: 40,
              height: 40,
              color: '#0c6cff',
              backgroundColor: '#dedef7',
              border: 0,
              borderRadius: 50,
            }}
          >
            <i className="mdi mdi-delete font-size-22" />
          </button>
        ),
        id: 'action',
        rowCss: 'mt-2',
        width: 150,
      },
    ],
    [t, page],
  );

  const data = useMemo(() => {
    return website.items;
  }, [website]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      limit: pageSize,
      page: newPage,
      status,
    };

    dispatch(getWebsites(payload));
    setPage(newPage);
  };

  const handleStatusChange = (event: any) => {
    const newStatus = event.value;
    const payload = {
      limit: pageSize,
      page,
      status: `${newStatus}`,
    };

    setSearch('');
    dispatch(getWebsites(payload));
    setStatus(newStatus);
  };

  const handleSearch = (val: string) => {
    const payload = {
      limit: pageSize,
      page: 1,
      status,
      url: val,
    };

    dispatch(getWebsites(payload));
  };

  const handleClear = () => {
    const payload = {
      limit: pageSize,
      page,
      search: '',
    };

    dispatch(getWebsites(payload));
    setSearch('');
  };

  // Submit Delete Website
  const submit_delete = (id: number, url: string) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t(`Confirm modal.delete website`, { url }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteWebsite(+id);
        const payload = {
          ...getDefaultPayload(1),
        };

        if (response) {
          notify(i18n.t('success:Delete Website Success'), 'success');
          dispatch(getWebsites(payload));
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // console.log('Cancel');
      }
    });
  };

  const getDefaultPayload = (newPage: number) => {
    setPage(newPage);

    return {
      limit: pageSize,
      page: newPage,
      status,
      url: search,
    };
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getWebsites(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [search, status, page, pageSize],
  );

  const hiddenOnXS: any = ['col-5'];
  const hiddenOnSM: any = ['col-5'];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  return (
    <>
      <Row className="justify-content-between m-0">
        <Col
          xs={5}
          sm={8}
          md={5}
          lg={5}
          className="d-flex align-items-center mb-3 mb-md-0"
        >
          <Link to="/mywebsite/addwebsite">
            <Button type="button" className="btn btn-info">
              {t('button:AddWeb')}
            </Button>
          </Link>
        </Col>

        <Col xs={12} sm={12} md={12} lg={7}>
          <Row lg={2} className="align-items-center justify-content-sm-between ">
            <Col sm={3} md={3} lg={3}>
              <FormGroup>
                <Dropdown
                  options={Status}
                  action={handleStatusChange}
                  placeholder={t('placeHolder.SelectStatus')}
                  default_value={Status[3]}
                />
              </FormGroup>
            </Col>
            <Col sm={7} md={6} lg={9}>
              <Row className="align-items-center">
                <Col sm={12} md={12} lg={12}>
                  <SearchBar
                    placeholder={t('Form.Search URL')}
                    handleSearch={handleSearch}
                    handleClear={handleClear}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data || []}
        page={page}
        pageSize={pageSize}
        total={website.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
        isLoading={websitesLoading}
      />
    </>
  );
};

export default withTranslation()(WebsiteTable);
