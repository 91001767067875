import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { OnLoading } from 'src/components/Common/Loading';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import { checkDuplicateSocial as checkDuplicateSocialGql } from 'src/graphql/default-value';
import i18n from 'src/i18n';
import { setFacebookError, setUserProfileSelected } from 'src/store/actions';
import notify from 'src/utils/notify';
import ProfileListComponent from './ProfileList/ProfileList.component';
import {
  onFacebookProcess,
  onFacebookPersonalProcess,
} from './socialProcess/meta/facebook/facebookProcess';
import {
  onInstagramPageProcess,
  onInstagramPersonalProcess,
} from './socialProcess/meta/instagram/instagramProcess';
import onTikTokProcess from './socialProcess/tiktok/tiktokProcess';
import onTwitterProcess from './socialProcess/twitter/twitterProcess';
import onYoutubeProcess from './socialProcess/youtube/youtubeProcess';

const SocialCalculatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const { type: paramType, token }: any = useParams();
  const type: string | null = params.get('type');
  const { search } = useLocation();

  const { userProfileList$, userProfileIsLoading$, userProfileStatus$ } =
    useSelector((state: any) => ({
      userProfileList$: state.CalculateSocialMedia.userProfileList,
      userProfileIsLoading$: state.CalculateSocialMedia.userProfileIsLoading,
      userProfileStatus$: state.CalculateSocialMedia.userProfileStatus,
    }));

  const socialProcess = async () => {
    switch (paramType) {
      case SocialMediaType.FACEBOOK:
        return onFacebookProcess({ params, dispatch, history });
      case SocialMediaType.FACEBOOK_PERSONAL:
        return onFacebookPersonalProcess({ params, dispatch, history });
      case SocialMediaType.TWITTER:
        return onTwitterProcess({ params, dispatch, history });
      case SocialMediaType.INSTAGRAM:
        return onInstagramPageProcess({ params, dispatch, history });
      case SocialMediaType.INSTAGRAM_PERSONAL:
        return onInstagramPersonalProcess({ params, dispatch, history });
      case SocialMediaType.YOUTUBE: {
        const email: string | null = params.get('email');
        return onYoutubeProcess({ token, dispatch, email });
      }
      case SocialMediaType.TIKTOK: {
        return onTikTokProcess({ search, dispatch });
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!userProfileStatus$) {
      return;
    }
    if (userProfileStatus$ !== 'PASS') {
      notify(i18n.t(userProfileStatus$), 'error');
      history.replace('/influencer/dashboard');
    }
  }, [userProfileStatus$]);

  useEffect(() => {
    if (userProfileList$?.length === 0) {
      // notify(i18n.t('Cannot get data from this social media'), 'error');
      // history.replace('/influencer/dashboard');
      return;
    }
    if (type === 'select') return;
    const checkDuplicateSocial = async () => {
      if (userProfileList$?.length === 1) {
        const checkDuplicate = await checkDuplicateSocialGql(
          userProfileList$[0]?.id,
        );
        if (checkDuplicate) {
          notify(i18n.t('This social media has already used'), 'error');
          history.replace('/influencer/channel-add');
          return;
        }
        dispatch(setUserProfileSelected(userProfileList$[0]));
        history.push('/influencer/create-channel-detail');
      }
      if (!userProfileList$?.length && !userProfileIsLoading$) {
        history.push('/influencer/channel-add');
        if (paramType.includes(SocialMediaType.FACEBOOK)) {
          dispatch(setFacebookError(true));
          setTimeout(() => {
            dispatch(setFacebookError(false));
          }, 3000);
        }
      }
    };
    checkDuplicateSocial();
  }, [userProfileList$]);

  useEffect(() => {
    if (!paramType && userProfileList$.length < 1) {
      history.replace('/influencer/dashboard');
      return;
    }
    socialProcess();
  }, [windowUrl]);

  if ((userProfileList$?.length < 2 && type !== 'select') || !userProfileList$) {
    return <OnLoading />;
  }

  return (
    <div className="page-content">
      <ProfileListComponent />
    </div>
  );
};

export default SocialCalculatePage;
