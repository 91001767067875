/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import {
  filterSocialGleamJob,
  setLoading,
  socialJobCardData,
  unsetLoading,
} from 'src/store/actions';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import { Dialog, DialogContent } from '@mui/material';
import {
  updateSocialMediaHasGleamJobGql,
  updateSocialMediaHasJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import { useHistory } from 'react-router';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBar';
import PageSizeOptions from '../Table/PageSizeOptions';
import { Pending } from '../Common/approval/approvalStatus';
import JobDetailPost from '../Publisher/Influencer/JobDetail/jobDetailPost';
import { InfluencerSocialIcon } from '../Common/status/InfluencerJobStatus';
import Dropdown from '../../components/CommonForBoth/Menus/Dropdown';

const MadeInHeavenTable = ({ t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [actionStatus, setActionStatus] = useState(null);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: actionStatus,
    isSpeedUp: true,
    search,
  });

  const { txn } = useSelector((state: any) => ({
    txn: state.SocialMediaInfo.filterG,
  }));
  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  useEffect(() => {
    dispatch(filterSocialGleamJob(getDefaultPayload(1)));
  }, []);

  const clickVerifyStatus = async (
    verify: string,
    socialJob: any,
    social_key: string,
  ) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (socialJob?.job?.is_gleam_job) {
          dispatch(setLoading());
          const payload = {
            status: verify,
            key: socialJob?.group,
            end_date: verify === 'FINISHED' ? new Date() : null,
            cancel_job_reason: verify === 'CANCEL' ? 'BY_ADMIN' : null,
            social_key,
          };

          await updateSocialMediaHasGleamJobGql(payload);
          dispatch(unsetLoading());
          dispatch(filterSocialGleamJob(param));
        } else {
          dispatch(setLoading());
          await updateSocialMediaHasJobGql({
            status: verify,
            key: socialJob?.key,
            end_date: verify === 'FINISHED' ? new Date() : null,
            cancel_job_reason: verify === 'CANCEL' ? 'BY_ADMIN' : null,
          });
          dispatch(unsetLoading());
          dispatch(filterSocialGleamJob(param));
        }
      }
    });
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, link_post, social_media_info } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <JobDetailPost socialJobItem={postModalData} />
        </DialogContent>
      </Dialog>
    );
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  const rendorSocialImage = (social_media_info: any) => {
    const social_key: any[] = [];
    const social_image: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (
        !social_key.includes(social_media_info[i].key) &&
        social_media_info[i].social_media_type !== 'NOSOCIAL'
      ) {
        social_key.push(social_media_info[i].key);
        social_image.push(social_media_info[i].image_profile_url);
      }
    }
    return (
      <>
        {social_image?.map((url: any) => {
          return (
            <img
              src={url}
              alt="icons"
              className="image-icon"
              style={{
                width: '30px',
                height: '30px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              onError={(e) => {
                e.currentTarget.src = userAvatar;
              }}
            />
          );
        })}
      </>
    );
  };

  const rendorSocialName = (social_media_info: any) => {
    const social_key: any[] = [];
    const social_name: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (
        !social_key.includes(social_media_info[i].key) &&
        social_media_info[i].social_media_type !== 'NOSOCIAL'
      ) {
        social_key.push(social_media_info[i].key);
        social_name.push(social_media_info[i].name);
      }
    }
    return social_name.join(',');
  };

  const rendorSocialMediaType = (social_media_info: any) => {
    const social_type: any[] = [];
    for (let i = 0; i < social_media_info?.length; i++) {
      if (!social_type.includes(social_media_info[i].social_media_type)) {
        social_type.push(social_media_info[i].social_media_type);
      }
    }
    return (
      <>
        {social_type?.map((socialType: any) => {
          return <InfluencerSocialIcon social_media_type={socialType} />;
        })}
      </>
    );
  };

  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('marketer:job_detail:Job_name'),
        accessor: ({ social_media_has_job }) => {
          return social_media_has_job?.job?.name;
        },
        id: 'job_name',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Influencer photo')}</div>,
        accessor: ({ social_media_info }: any) => {
          return rendorSocialImage(social_media_info);
        },
        id: 'influencer_image',
        width: 120,
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Influencer name')}</div>,
        accessor: ({ social_media_info }: any) =>
          rendorSocialName(social_media_info),
        id: 'influencer_name',
        disableSort: true,
      },
      {
        Header: t('table:social'),
        accessor: ({ social_media_info }: any) =>
          rendorSocialMediaType(social_media_info),
        id: 'social_media_type',
        disableSort: true,
        width: 100,
      },
      {
        Header: <div className="text-header">{t(`table:Influencer Post`)}</div>,
        accessor: ({ social_media_has_job, social_media_info }: any) =>
          social_media_has_job?.job?.is_gleam_job ? (
            <>
              {social_media_has_job?.post_date ? (
                <a
                  href={`/admin/job/review?job=${social_media_has_job?.job?.key}&task=${social_media_has_job?.group}&key=${social_media_info[0]?.key}`}
                >
                  <button
                    style={{ background: 'white' }}
                    className="link-job"
                    type="button"
                  >
                    {t('table:View post')}
                  </button>
                </a>
              ) : (
                <Pending message="pending" />
              )}
            </>
          ) : (
            <>
              {social_media_has_job?.post_date ? (
                <button
                  style={{ background: 'white' }}
                  className="link-job"
                  type="button"
                  onClick={() => {
                    dispatch(
                      socialJobCardData({
                        social_media_type: social_media_info[0]?.social_media_type,
                        link_post: social_media_has_job?.link_post,
                        social_key: social_media_info[0]?.key,
                      }),
                    );
                    setPostModalData({
                      isOpen: true,
                      link_post: social_media_has_job?.link_post,
                      social_media_info: social_media_info[0],
                    });
                  }}
                >
                  {t('table:View post')}
                </button>
              ) : (
                <Pending message="pending" />
              )}
            </>
          ),
        id: 'influencer_post_date',
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ social_media_has_job, social_media_info }: any) =>
          social_media_has_job.status === 'FINISHED' ||
          social_media_has_job.status === 'CANCEL' ? (
            social_media_has_job.status
          ) : (
            <UncontrolledDropdown className="">
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <i className="bx bx-pencil" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() =>
                    clickVerifyStatus(
                      'FINISHED',
                      social_media_has_job,
                      social_media_info[0]?.key,
                    )
                  }
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    clickVerifyStatus(
                      'CANCEL',
                      social_media_has_job,
                      social_media_info[0]?.key,
                    )
                  }
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ],
    [t, txn, modalOpen],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(1),
      page: newPage,
    };

    dispatch(filterSocialGleamJob(payload));
    setPage(newPage);
    setParam(payload);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(filterSocialGleamJob(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(filterSocialGleamJob(payload));
    setSearch(val);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(filterSocialGleamJob(payload));
    setSearch('');
    setParam(payload);
  };
  // เพิ่มมา
  const handleStatusChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      status: event.value,
    };
    dispatch(filterSocialGleamJob(payload));
    setActionStatus(event.value);
    setParam(payload);
  };
  // เพิ่มมา
  const Status = [
    { value: 'INPROGRESS', label: t('table:INPROGRESS') },
    { value: 'CANCEL', label: t('table:CANCEL') },
    { value: 'FINISHED', label: t('table:FINISHED') },
  ];

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(filterSocialGleamJob(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, page, pageSize],
  );

  if (!txn) return <></>;

  return (
    <>
      {displayModal()}
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '150px', marginRight: '20px' }}>
              <Dropdown
                options={Status}
                action={handleStatusChange}
                default_value={Status[0]}
              />
            </div>
            <SearchBar
              placeholder={t('table:Search Name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(MadeInHeavenTable);
