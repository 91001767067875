/* eslint-disable no-nested-ternary */
import { Col, Card, Tooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import {
  getSumDailyCurrentBudget as onGetSumDailyCurrentBudget,
  getWallet,
  getAdminWallet,
} from 'src/store/actions';
import moment from 'moment';
import { roleText } from '../../constants/role';
import { transformStringAmountInput } from '../../utils/input';
import Wallet from '../../assets/images/wallet.png';

const WalletWidget = ({ t }: any) => {
  const dispatch = useDispatch();
  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);

  const { wallet, role, sumDailyCurrentBudget, influencerData, adminWallet } =
    useSelector((state: any) => ({
      wallet: state.Wallet.wallet,
      role: state.login.user.role,
      sumDailyCurrentBudget: state.Campaigns.SumDailyCurrentBudget,
      influencerData: state.Users.userInfo,
      adminWallet: state.Wallet.adminWallet,
    }));

  useEffect(() => {
    dispatch(onGetSumDailyCurrentBudget());
    dispatch(getWallet());
    if (role === 'ADMIN') dispatch(getAdminWallet());
  }, []);

  const mockData = [
    { key: '1', name: 'LUNA', price: '1225.45' },
    { key: '2', name: 'USDT', price: '125.45' },
    { key: '2', name: 'BNB', price: '15.45' },
  ];
  const timezone = moment.tz.guess();
  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {adminWallet?.length === 0 && (
          <Card
            className="h-100-per text-center p-4 mb-4 mb-md-0 d-flex flex-column justify-content-evenly"
            style={{
              height: '220px',
              width: '800px',
            }}
          >
            <p className="color-primary font-size-26 fw-bold">
              {t('Balance')}
              {role === 'PUBLISHER' ? (
                <>
                  <i
                    className="fas fa-info-circle ms-2 font-size-16"
                    style={{ cursor: 'pointer' }}
                    id="tooltipInfo"
                  />
                  <Tooltip
                    placement="right"
                    isOpen={toggleTooltip}
                    target="tooltipInfo"
                    toggle={() => setToggleTooltip(!toggleTooltip)}
                  >
                    <span className="fs-6">
                      {t('tooltip.balance update info')}
                      {currentTimeZone}
                    </span>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </p>
            <div
              className="mt-4 font-size-40"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={Wallet}
                alt="WalletIconImage"
                className="icon-34 me-3 mb-1"
              />
              <p style={{ color: '#009A78', fontWeight: 'bold', marginTop: '1rem' }}>
                {!isEmpty(wallet)
                  ? transformStringAmountInput(
                      wallet.balance - sumDailyCurrentBudget,
                    )
                  : 0}{' '}
                USDT
              </p>
            </div>
            <div className="mt-2">
              {role === roleText.ADVERTISER ? (
                <Link to="/wallet" className="btn btn-primary btn-sm mt-5 w-xl">
                  {t('Deposit Funds')}
                  <i className="mdi mdi-arrow-right ms-1" />
                </Link>
              ) : role === roleText.ADMIN ? (
                <></>
              ) : (
                <Link to="/wallet" className="btn btn-primary btn-sm mt-4 w-xl">
                  {t('Withdraw Funds')}
                  <i className="mdi mdi-arrow-right ms-1" />
                </Link>
              )}
            </div>
          </Card>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexWrap: 'wrap',
          gap: '20px',
          padding: '20px',
        }}
      >
        {adminWallet &&
          adminWallet.map((item: any) => {
            return (
              <Card
                className="h-100-per text-center p-3 mb-4 mb-md-0 d-flex flex-column justify-content-center"
                style={{
                  height: '220px',
                  width: '253.5px',
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'row', gap: '0rem' }}
                  className="center-x-y"
                >
                  <img
                    src={Wallet}
                    alt="WalletIconImage"
                    className="icon-34 me-3   mb-0"
                  />
                  <p className="color-primary font-size-20 fw-bold  mb-0">
                    {item.symbol}
                    {role === 'PUBLISHER' ? (
                      <>
                        <i
                          className="fas fa-info-circle ms-2 font-size-16"
                          style={{ cursor: 'pointer' }}
                          id="tooltipInfo"
                        />
                        <Tooltip
                          placement="right"
                          isOpen={toggleTooltip}
                          target="tooltipInfo"
                          toggle={() => setToggleTooltip(!toggleTooltip)}
                        >
                          <span className="fs-6">
                            {t('tooltip.balance update info')}
                            {currentTimeZone}
                          </span>
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
                <div
                  className="mt-4 font-size-20"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#009A78',
                      fontWeight: 'bold',
                      marginTop: '1rem',
                    }}
                  >
                    {!isEmpty(wallet) ? transformStringAmountInput(item.balance) : 0}{' '}
                    {item.symbol}
                  </p>
                </div>
                <div className="mt-2">
                  {role === roleText.ADVERTISER ? (
                    <Link to="/wallet" className="btn btn-primary btn-sm mt-5 w-xl">
                      {t('Deposit Funds')}
                      <i className="mdi mdi-arrow-right ms-1" />
                    </Link>
                  ) : role === roleText.ADMIN ? (
                    <></>
                  ) : (
                    <Link to="/wallet" className="btn btn-primary btn-sm mt-4 w-xl">
                      {t('Withdraw Funds')}
                      <i className="mdi mdi-arrow-right ms-1" />
                    </Link>
                  )}
                </div>
              </Card>
            );
          })}
      </div>
    </div>
  );
};

export default withTranslation()(WalletWidget);
