import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import { Tooltip } from '@mui/material';
import postlink from 'src/assets/images/post-link.png';
import {
  // getFacebookDataGql,
  // getTwitterPostDataGql,
  // getYoutubeVideoDataGql,
  // getTiktokPostDataGql,
  addNewPostGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import choice from 'src/assets/images/choice.png';
import ShowMoreText from 'react-show-more-text';
import i18n from 'src/i18n';
import { LinkInput } from 'src/pages/Advertiser/Marketer/InfluencerJobDetail/influencerJobDetail.style';
import { getSocialJob, setLoading, unsetLoading } from 'src/store/actions';
import notify from 'src/utils/notify';
import paramsFromURL from 'src/constants/paramsFromURL';
import CustomButton from 'src/components/Common/CustomButton';
import Swal from 'sweetalert2';
import JobDetailImagePreview from './jobDetailImagePreview';
// import { SocialMediaType } from '../Dashboard/SocialChannel/SocialChannel.control';
import './jobDetailCollapse3.style.scss';
import JobDetailPost from './jobDetailPost';

const JobDetailCollapse3 = ({
  setCollapse,
  collapse,
  socialJobItem,
  formSubmit,
  setFormSubmit,
}: any) => {
  const { t } = useTranslation();
  const [socialJobId]: any = paramsFromURL(['detail']);
  const dispatch = useDispatch();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  useEffect(() => {
    if (collapse.collapse3) setIsCollapseOpen(true);
  }, []);
  // const getPostData = async (
  //   socialType: SocialMediaType,
  //   link_post: string,
  //   socialKey: string,
  // ) => {
  //   switch (socialType) {
  //     case 'TWITTER':
  //       return getTwitterPostDataGql(link_post, socialKey, true);
  //     case 'FACEBOOK':
  //       return getFacebookDataGql(link_post, socialKey);
  //     case 'YOUTUBE':
  //       return getYoutubeVideoDataGql(link_post, socialKey, true);
  //     case 'TIKTOK':
  //       return getTiktokPostDataGql(link_post, socialKey);
  //     default:
  //       notify(i18n.t('error:Job has not accept .'), 'error');
  //       return null;
  //   }
  // };
  // eslint-disable-next-line no-unused-vars
  const socialContent = (social_media_type: any) => {
    switch (socialJobItem?.social_media_info?.social_media_type) {
      case 'FACEBOOK':
        return t('marketer:job_detail.Post caption');
      case 'INSTAGRAM':
        return t('marketer:job_detail.Post caption');
      case 'TIKTOK':
        return t('marketer:job_detail.Video description');
      case 'TWITTER':
        return t('marketer:job_detail.Post caption');
      case 'YOUTUBE':
        return t('marketer:job_detail.Video description');
      default:
        return t('marketer:job_detail.Post caption');
    }
  };

  const checkExamplePostLinkToolTip = () => {
    switch (socialJobItem?.social_media_info?.social_media_type) {
      case 'FACEBOOK':
        return `${t(
          'marketer:job_detail.Example URL Link',
        )} https://www.facebook.com/FlupowerOfficial/posts/pfbid02m7...`;
      case 'INSTAGRAM':
        return `${t(
          'marketer:job_detail.Example URL Link',
        )} https://www.instagram.com/p/CkFTX...`;
      case 'TIKTOK':
        return `${t(
          'marketer:job_detail.Example URL Link',
        )} https://www.tiktok.com/@af12brother/video/715162...`;
      case 'TWITTER':
        return `${t(
          'marketer:job_detail.Example URL Link',
        )} https://twitter.com/AF_Brother_/status/1598...`;
      case 'YOUTUBE':
        return `${t(
          'marketer:job_detail.Example URL Link',
        )} https://www.youtube.com/watch?v=jrhh2&ab_channel=AFbrotherOfficial`;
      default:
        return '';
    }
  };

  const onSubmitPost = async () => {
    const link_post = formSubmit.submit_job_link;

    if (!link_post) {
      notify(i18n.t('error:Please upload the post link.'), 'error');
      return;
    }

    // const postData = await getPostData(
    //   socialJobItem?.social_media_info?.social_media_type,
    //   link_post,
    //   socialJobItem?.social_media_info?.key,
    // );
    dispatch(setLoading());

    const postData = await addNewPostGql(socialJobId, link_post);

    if (!postData) {
      notify(i18n.t('error:Incorrect post link.'), 'error');
      dispatch(unsetLoading());
      return;
    }

    notify(i18n.t('success:Job has accepted.'), 'success');

    setCollapse({ ...collapse, collapse1: false });
    dispatch(getSocialJob(socialJobId));
    dispatch(unsetLoading());
  };

  return (
    <div className="job-detail-collapse3-container">
      <div
        className={`job-detail-flex-container ${
          isCollapseOpen ? 'border-radius-card-open' : 'border-radius-card-close'
        }`}
        onClick={() => setCollapse({ ...collapse, collapse3: !collapse.collapse3 })}
        style={{ cursor: 'pointer' }}
      >
        <div className="job-detail-flex">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {socialJobItem?.post_date && (
                <div className="icon-check">
                  <i
                    className="fa fa-check"
                    style={{
                      fontSize: '10px',
                      position: 'absolute',
                      top: '3px',
                      left: '2px',
                    }}
                  />
                </div>
              )}
              {!socialJobItem?.link_post ? (
                <h5 className="job-detail-number">3</h5>
              ) : (
                <h5 className="job-detail-number">2</h5>
              )}
            </div>
            <div>
              <h3 className="fs-5 mt-2">
                {t('marketer:job_detail:Submit Your Post Link')}
              </h3>
            </div>
          </div>
          <div className="work-icon-container">
            <img
              src={postlink}
              alt=""
              style={{ width: '40px', marginRight: '45px' }}
            />
          </div>
        </div>
      </div>

      <Collapse
        isOpen={
          collapse.collapse3 &&
          !!socialJobItem?.influencer_submit_date &&
          socialJobItem?.status !== 'CANCEL'
        }
        className="job-detail-collapse"
        onEntering={() => {
          setIsCollapseOpen(true);
        }}
        onExited={() => {
          setIsCollapseOpen(false);
        }}
      >
        <div className="influencer-card">
          {!socialJobItem?.post_date ? (
            <>
              {socialJobItem?.marketer_approve_date ? (
                <>
                  <label
                    htmlFor="Post images"
                    style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                  >
                    {t('marketer:job_detail.Post images')}
                  </label>
                  <JobDetailImagePreview
                    items={socialJobItem}
                    socialJobId={socialJobId}
                  />
                  <label
                    htmlFor="Marketer description"
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      marginTop: '5px',
                      marginBottom: '0',
                    }}
                  >
                    {socialContent(
                      socialJobItem?.social_media_info?.social_media_type,
                    )}
                  </label>
                  <div style={{ fontSize: '1.05rem', wordBreak: 'break-all' }}>
                    <ShowMoreText
                      lines={3}
                      more="See more"
                      less="See less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      expanded={false}
                      truncatedEndingComponent="... "
                    >
                      {socialJobItem?.marketer_massage}
                    </ShowMoreText>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      htmlFor="link_post"
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingTop: '5px',
                      }}
                    >
                      <span style={{ color: 'red', marginRight: '5px' }}> * </span>
                      {t(
                        'marketer:job_detail:Copy your post link and send it here.',
                      )}
                    </label>
                    <Tooltip title={checkExamplePostLinkToolTip()} placement="right">
                      <i
                        className="fas fa-info-circle ms-2 font-size-16"
                        style={{
                          cursor: 'pointer',
                        }}
                        id="tooltipInfo"
                      />
                    </Tooltip>
                  </div>
                  <LinkInput
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '0rem',
                      fontSize: '1rem',
                    }}
                    id="link_post"
                    disabled={!socialJobItem?.marketer_approve_date}
                    type="text"
                    value={formSubmit?.submit_job_link || ''}
                    onChange={(e: any) => {
                      setFormSubmit({
                        ...formSubmit,
                        submit_job_link: e.target.value,
                      });
                    }}
                  />
                  {!socialJobItem?.post_date && (
                    <div
                      className="d-flex justify-content-center align-items-center w-100"
                      style={{ height: 'fit-content', margin: '15px 0px' }}
                    >
                      <CustomButton
                        handleClick={async (e: any) => {
                          await Swal.fire({
                            width: 300,
                            title: t('swal:Are you sure ?'),
                            text: t("swal:You won't be able to revert this!"),
                            imageUrl: choice,
                            imageHeight: 200,
                            imageAlt: 'A tall image',
                            customClass: {},
                            cancelButtonColor: 'red',
                            showCancelButton: true,
                            confirmButtonColor: '#009a78',
                            confirmButtonText: t('button:Yes'),
                            cancelButtonText: t('swal:Cancel'),
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              e.preventDefault();
                              onSubmitPost();
                            }
                          });
                        }}
                        size="xs"
                        status="submit"
                        label={t('marketer:job_detail:Submit Link')}
                      />
                    </div>
                  )}
                </>
              ) : (
                <p
                  style={{
                    padding: '20px 0px',
                    marginBottom: '0',
                    color: 'green',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {t('influencer:Waiting for the Marketer to check')}
                </p>
              )}
            </>
          ) : (
            <JobDetailPost socialJobItem={socialJobItem} />
          )}
        </div>
      </Collapse>
    </div>
  );
};
export default JobDetailCollapse3;
