//react
import React, { useState } from 'react';
//react style
import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  NavItem,
  NavLink,
} from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
//component
import MetaTags from 'react-meta-tags';
import EditCreativeSize from '../../components/Admin/EditCreativeSize';
import SetDefaultCPMPage from './SetDefaultCPMPage';
import SetDefaultBudgetPage from './SetDefaultBudgetPage';
import config from '../../config';
import menu from '../../constants/menu';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const manageAdvertiserPage = ({ t }: any) => {
  //state variables
  const [manageAdvertiserTab, setManageCreativeTab] = useState('1');

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('Page.Admin Settings Advertiser')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          pageTitle={t('Page.Admin Settings Advertiser')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.ADMIN_SETTINGS_ADVERTISER, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      className={`cursor ${
                        manageAdvertiserTab === '1' && 'active'
                      } `}
                      onClick={() => {
                        setManageCreativeTab('1');
                      }}
                    >
                      <h5 className="mb-0">
                        {t('creative:Set Default Creative Size')}
                      </h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`cursor ${
                        manageAdvertiserTab === '2' && 'active'
                      } `}
                      onClick={() => {
                        setManageCreativeTab('2');
                      }}
                    >
                      <h5 className="mb-0">{t('Set Default CPM')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`cursor ${
                        manageAdvertiserTab === '3' && 'active'
                      } `}
                      onClick={() => {
                        setManageCreativeTab('3');
                      }}
                    >
                      <h5 className="mb-0">{t('Set Default Budget')}</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                {manageAdvertiserTab === '1' && <EditCreativeSize />}
                {manageAdvertiserTab === '2' && <SetDefaultCPMPage />}
                {manageAdvertiserTab === '3' && <SetDefaultBudgetPage />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(manageAdvertiserPage);
