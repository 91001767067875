import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactFlagsSelectCountry } from 'src/utils/country';
import ReactFlagsSelect from 'react-flags-select';
import { useState } from 'react';
import InfluencerChoice from './SortByInfuencer/Influencer/InfluencerChoice';
import control from './SortBy.style';
import FollowChoiceDiscover from './SortbyFollow/Follow/FollowChoiceDiscover';

const DivContent = styled.div`
  // overflow: auto;
  height: 100%;
  text-align: center;
  // margin-bottom: 5rem;
  // background-color: #fbfaff;
`;

// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyType {
  // eslint-disable-next-line no-unused-vars
  FOLLOWER = 'FOLLOWER',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'INFLUENCER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum County {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  Thailand = 'Thailand',
  // eslint-disable-next-line no-unused-vars
  OTHER = 'OTHER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'MEN',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'WOMEN',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyInfluencer {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  FOLLOWERS = 'follower',
  // eslint-disable-next-line no-unused-vars
  RATING = 'rating',
  // eslint-disable-next-line no-unused-vars
  COST = 'photo_content_price',
}

// interface FilterForm {
//   propertyType: PropertyType;
//   country?: string;
//   gender?: Gender;
//   max_age?: number;
//   min_age?: number;
//   aptitude_ids: string[];
//   limit?: number;
//   pages?: number;
//   sort_by?: string;
//   name?: string;
// }

const { ModalStyle, Texts, FlexRow, FlexCol, CloseBtn } = control();
const SortByAptitude = (props: any) => {
  const [selected, setSelected] = useState('');
  const { isOpen, toggle, filterForm, setFilterForm } = props;
  const { t } = useTranslation();
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));
  const price =
    marketerJob?.content_type === 'SHARE'
      ? 'share_content_price'
      : 'photo_content_price';
  const ageRange: { label: string; min_age: number; max_age: number }[] = [
    { label: t('sort.All'), min_age: -1, max_age: 120 },
    { label: '< 18', min_age: 0, max_age: 17 },
    { label: '18-24', min_age: 18, max_age: 24 },
    { label: '25-34', min_age: 25, max_age: 34 },
    { label: '35-44', min_age: 35, max_age: 44 },
    { label: '45-54', min_age: 45, max_age: 54 },
    { label: '55-64', min_age: 55, max_age: 64 },
    { label: '> 65', min_age: 65, max_age: 120 },
  ];
  const propertyInfluencer: { label: string; value: any }[] = [
    { label: t('sort.All'), value: PropertyInfluencer.ALL },
    { label: t('sort.Follower'), value: PropertyInfluencer.FOLLOWERS },
    { label: t('sort.Rating'), value: PropertyInfluencer.RATING },
    { label: t('sort.Price'), value: price },
  ];

  const onChangeValue = (event: any) => {
    setFilterForm({ ...filterForm, propertyType: event.target.value });
  };

  const resetCountry = () => {
    setFilterForm({ ...filterForm, country: '' });
    setSelected('');
  };

  return (
    <ModalStyle isOpen={isOpen} toggle={toggle} centered size="lg">
      <DivContent>
        <CloseBtn type="button" onClick={toggle}>
          x
        </CloseBtn>
        <Texts fontsize="1.5rem" margin="10px auto">
          {t('marketer:create_job_campaign:filter influencer from')}
        </Texts>
        <FlexRow
          justifycontent="center"
          width="100%"
          onChange={(e) => onChangeValue(e)}
        >
          {/* <FlexCol margin="0 10%">
            <Radio
              type="radio"
              value={PropertyType.FOLLOWER}
              name="gender"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              defaultChecked={filterForm.propertyType === PropertyType.FOLLOWER}
            />{' '}
            {t('marketer:create_job_campaign:Features of influencers')}
          </FlexCol> */}
          {/* <FlexCol margin="0 10%">
            <Radio
              type="radio"
              value={PropertyType.INFLUENCER}
              name="gender"
              width="20px"
              height="20px"
              margin="0 auto 5px auto"
              defaultChecked={filterForm.propertyType === PropertyType.INFLUENCER}
            />{' '}
            {t('marketer:create_job_campaign:Influencer aptitude')}
          </FlexCol> */}
        </FlexRow>

        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FlexCol>
            <Texts margin="20px 10px 10px 10px" fontsize="20px">
              {t('marketer:create_job_campaign:Influencer_country')}
            </Texts>
            <div
              style={{
                width: '250px',
                marginTop: '12px',
                marginLeft: '14px',
                textAlign: 'end',
              }}
            >
              <ReactFlagsSelect
                searchable
                placeholder={
                  filterForm?.country !== 'ALL'
                    ? filterForm?.country
                    : t('marketer:create_job_campaign:Search all country')
                }
                selectedSize={16}
                optionsSize={16}
                searchPlaceholder={t('marketer:create_job_campaign:Search country')}
                onSelect={(label: any) => {
                  const country: any = ReactFlagsSelectCountry;
                  setFilterForm({ ...filterForm, country: country[label] });
                  setSelected(label);
                  const rfsBtn = document.getElementById('rfs-btn');
                  if (!rfsBtn) return;
                  rfsBtn.click();
                }}
                showOptionLabel
                selected={selected}
              />
              <span
                style={{
                  textDecoration: 'underline',
                  color: 'rgb(0, 201, 255)',
                  cursor: 'pointer',
                }}
                onClick={resetCountry}
              >
                {t('Reset Country')}
              </span>
            </div>
          </FlexCol>
        </div>

        <FollowChoiceDiscover
          form={filterForm}
          setForm={setFilterForm}
          ageRange={ageRange}
          toggle={toggle}
        />
      </DivContent>
    </ModalStyle>
  );
};

export default SortByAptitude;
