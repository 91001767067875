import { Formik, Form } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import stepCreate from 'src/assets/images/Step1.png';
import Trash from 'src/assets/images/Trash-01.png';
import stepSummary from 'src/assets/images/Step4.png';
import stepChooseInfu from 'src/assets/images/Step3.png';
import stepSelectSocial from 'src/assets/images/Step2.png';
import CustomButton from 'src/components/Common/CustomButton';
import menu from 'src/constants/menu';
import paramsFromURL from 'src/constants/paramsFromURL';
import { Collapse, Progress } from 'reactstrap';
import InstargramIcon from 'src/assets/images/home/ig@2x.27955f7.png';
import FacebookIcon from 'src/assets/images/home/fb@2x.4f64514.png';
import YoutubeIcon from 'src/assets/images/home/yt@2x.81f6272.png';
import TiktokIcon from 'src/assets/images/home/tiktok@2x.ae31098.png';
import TwitterIcon from 'src/assets/images/home/tw@2x.f2d449e.png';
import { SocialMediaType } from 'src/store/marketerJobNew/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { setDescriptionJobNew } from 'src/store/marketerJobNew/actions';
import control, { SocialJobType } from './CreateMarketerJobCampaignNew2.control';
import './CreateMarketerJobCampaign.style.scss';
import './CreateMarketerJobCampaignNew2.style.scss';
import SubCollapse from './SubCollapse.component';

export interface SelectedValueType {
  social: SocialMediaType;
  subSocialJob: Array<'SHARE' | 'PHOTO' | 'VIDEO'>;
}

interface SocialMedia {
  icon: string;
  socialName: SocialMediaType;
  sub: Array<string>;
  disable: boolean;
  name: string;
}

const socialmedias = [
  {
    icon: FacebookIcon,
    socialName: SocialMediaType.FACEBOOK,
    sub: ['SHARE', 'PHOTO'],
    disable: false,
    name: 'facebook',
  },
  {
    icon: InstargramIcon,
    socialName: SocialMediaType.INSTAGRAM,
    sub: ['SHARE', 'PHOTO'],
    disable: false,
    name: 'instagram',
  },
  {
    icon: TwitterIcon,
    socialName: SocialMediaType.TWITTER,
    sub: ['SHARE', 'PHOTO'],
    disable: false,
    name: 'twitter',
  },
  {
    icon: YoutubeIcon,
    socialName: SocialMediaType.YOUTUBE,
    sub: ['VIDEO'],
    disable: false,
    name: 'youtube',
  },
  {
    icon: TiktokIcon,
    socialName: SocialMediaType.TIKTOK,
    sub: ['VIDEO'],
    disable: false,
    name: 'video',
  },
];

const CreateMarketerJobCampaignPageNewstep2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector((state: any) => ({
    data: state.MarketerCampaignJobsNew,
  }));
  const [jobType, setJobType] = useState<'INVITE_ONLY' | 'PUBLISH' | ''>(
    data.descriptionJobNew.job_type,
  );
  //('INVITE_ONLY');
  const [campaignId, jobId] = paramsFromURL(['campaign', 'job']);
  const [isOpen, setIsOpen] = useState<Array<SocialMediaType | null>>([]);
  const { handleSubmit, formValues, setFormValues, initialValues } = control();

  //test
  const [selectedValue, setSelectedValue] = useState<
    Array<SelectedValueType | null>
  >([]);

  useEffect(() => {
    const description = localStorage.getItem('descriptionJobNew');
    if (description && !jobType) {
      dispatch(setDescriptionJobNew(JSON.parse(description)));
      setJobType(data.descriptionJobNew.job_type);
    }
  }, [data]);

  const handleOptionChangeInvite = (social: SocialMediaType) => {
    const obj: any = { ...formValues };
    if (obj) {
      Object.keys(obj).forEach((field) => {
        if (field === social) {
          obj![social]!.isSelected = true;
        } else {
          obj![field]!.isSelected = false;
        }
        Object.keys(obj[field]).forEach((content) => {
          if (content !== 'isSelected') {
            obj![field][content]!.isSelected = false;
          }
        });
      });
    }
    let opens = [...isOpen];
    if (!isOpen.includes(social)) {
      opens = [];
      opens.push(social);
    }

    setIsOpen(opens);
    setFormValues(obj);
  };
  const handleOptionChangeSuggest = (social: SocialMediaType) => {
    const obj = { ...formValues };
    if (obj[social]) {
      obj![social]!.isSelected = !obj[social]?.isSelected;
    }
    setFormValues(obj);
  };

  const handleCopy = ({
    social,
    index,
  }: {
    social: SocialMediaType;
    index: number;
  }) => {
    const obj: any = { ...formValues };
    if (obj) {
      const socialSelected = obj[social].isSelected;
      const shareSelected = obj[social]['SHARE']?.isSelected;
      const photoSelected = obj[social]['PHOTO']?.isSelected;
      const videoSelected = obj[social]['VIDEO']?.isSelected;
      const canCopy =
        socialSelected && (shareSelected || photoSelected || videoSelected);
      if (canCopy) {
        if (social === SocialMediaType.TWITTER) {
          obj[Object.keys(obj)[index + 1]] = {
            // ...obj[social],
            isSelected: { ...obj[social].isSelected },
            VIDEO: (shareSelected && {
              ...obj[social]['SHARE'],
            }) ||
              (photoSelected && { ...obj[social]['PHOTO'] }) || {
                ...obj[social]['YOUTUBE'].VIDEO,
              },
          };
        } else if (social === SocialMediaType.YOUTUBE) {
          obj[Object.keys(obj)[index + 1]] = {
            ...obj[social],
            isSelected: { ...obj[social].isSelected },
            VIDEO: { ...obj[social].VIDEO },
          };
        } else {
          obj[Object.keys(obj)[index + 1]] = {
            ...obj[social],
            isSelected: { ...obj[social].isSelected },
            SHARE: { ...obj[social].SHARE },
            PHOTO: { ...obj[social].PHOTO },
            VIDEO: { ...obj[social].VIDEO },
          };
        }
      }
    }
    setFormValues(obj);
  };

  const stepDisplaySuggestJob = () => {
    return (
      <div className="stepper-container">
        <>
          <img src={stepCreate} alt="stepCreate" className="stepper-active-img" />

          <div className="stepper-dot" />
        </>
        <>
          <img
            src={stepChooseInfu}
            alt="stepChooseInfu"
            className="stepper-active-img"
          />

          <div className="stepper-dot" />
        </>

        <img src={stepSummary} alt="stepSummary" className="stepper-active-img" />
      </div>
    );
  };

  const stepDisplayInviteOnly = () => {
    return (
      <div className="stepper-container">
        <>
          <img src={stepCreate} alt="stepCreate" className="stepper-active-img" />

          <div className="stepper-dot" />
        </>
        <>
          <img
            src={stepSelectSocial}
            alt="stepCreate"
            className="stepper-active-img"
          />

          <div className="stepper-dot" />
        </>
        <>
          <img
            src={stepChooseInfu}
            alt="stepChooseInfu"
            className="stepper-active-img"
          />

          <div className="stepper-dot" />
        </>

        <img src={stepSummary} alt="stepSummary" className="stepper-active-img" />
      </div>
    );
  };

  const showCopyButton = ({
    social,
    index,
  }: {
    social: SocialMediaType;
    index: number;
  }) => {
    return social === 'TIKTOK' ? (
      <></>
    ) : (
      <div className="allv" onClick={() => handleCopy({ social, index })}>
        <div className="collapse-detail-copy budget-title">
          <div>{t('Copy Detail to Next social')}</div>
          <i className="fas fa-solid fa-copy" />
        </div>
      </div>
    );
  };
  const suggestJobForm = () => {
    return (
      <div className="page-container">
        <label className="form-title">
          {t('STEP 2  ')}
          <span>:</span>
          {t(' Select socialmedia to create job')}
        </label>
        {socialmedias.map((item: SocialMedia, i: number) => {
          return (
            <Fragment key={`main ${i}`}>
              <div className="tab">
                <div className="tab-left">
                  <input
                    type="Checkbox"
                    className="check-box-social"
                    onChange={() => {
                      handleOptionChangeSuggest(item.socialName);
                    }}
                    onClick={() => {
                      let opens = [...isOpen];
                      if (isOpen.includes(item.socialName)) {
                        opens = opens.filter((value) => value !== item.socialName);
                      } else {
                        opens.push(item.socialName);
                      }
                      setIsOpen(opens);
                    }}
                    checked={formValues && formValues[item.socialName]?.isSelected}
                  />
                  <div>
                    <img
                      style={{ marginLeft: '10px' }}
                      src={item.icon}
                      width={40}
                      height={40}
                      className="tab-social-icon"
                      alt="instagramicon"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    let opens = [...isOpen];
                    if (isOpen.includes(item.socialName)) {
                      opens = opens.filter((value) => value !== item.socialName);
                    } else {
                      opens.push(item.socialName);
                    }
                    setIsOpen(opens);
                  }}
                >
                  <div style={{ paddingRight: '10px' }}>
                    {isOpen.includes(item.socialName) ? t('Close') : t('Expand')}
                  </div>
                  <i
                    onClick={() => {
                      let opens = [...isOpen];
                      if (isOpen.includes(item.socialName)) {
                        opens = opens.filter((value) => value !== item.socialName);
                      } else {
                        opens.push(item.socialName);
                      }
                      setIsOpen(opens);
                    }}
                    className={`${
                      isOpen.includes(item.socialName)
                        ? 'fas fa-chevron-up'
                        : 'fas fa-chevron-down'
                    }`}
                  />
                </div>
              </div>
              <div className="all-collapse-container">
                <Collapse
                  isOpen={isOpen.includes(item.socialName)}
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                >
                  {item.sub.map((subItem: string, index: number) => (
                    <SubCollapse
                      key={`sub ${index}`}
                      title={subItem as 'SHARE' | 'PHOTO' | 'VIDEO'}
                      job_type={jobType}
                      social={item.socialName}
                      setFormValues={setFormValues}
                      formValues={formValues}
                    />
                  ))}
                  {showCopyButton({ social: item.socialName, index: i })}
                </Collapse>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  };
  const inviteOnlyForm = () => {
    return (
      <div className="page-container">
        <label className="form-title">
          {t('STEP 2  ')}
          <span>:</span>
          {t(' Select socialmedia to create job')}
        </label>
        {socialmedias.map((item: SocialMedia, index: number) => {
          return (
            <>
              <div className="tab">
                <div className="tab-left">
                  <input
                    type="radio"
                    value={item.name}
                    className="check-box-social"
                    onChange={() => handleOptionChangeInvite(item.socialName)}
                    checked={formValues && formValues[item.socialName]?.isSelected}
                    onClick={() => {
                      let opens = [...isOpen];
                      if (isOpen.includes(item.socialName)) {
                        opens = opens.filter((value) => value !== item.socialName);
                      } else {
                        opens.push(item.socialName);
                      }
                      setIsOpen(opens);
                    }}
                  />
                  <div>
                    <img
                      style={{ marginLeft: '10px' }}
                      src={item.icon}
                      width={40}
                      height={40}
                      className="tab-social-icon"
                      alt="instagramicon"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    let opens = [...isOpen];
                    if (isOpen.includes(item.socialName)) {
                      opens = opens.filter((value) => value !== item.socialName);
                    } else {
                      opens.push(item.socialName);
                    }
                    setIsOpen(opens);
                  }}
                >
                  <div style={{ paddingRight: '10px' }}>
                    {isOpen.includes(item.socialName) ? t('Close') : t('Expand')}
                  </div>
                  <i
                    onClick={() => {
                      let opens = [...isOpen];
                      if (isOpen.includes(item.socialName)) {
                        opens = opens.filter((value) => value !== item.socialName);
                      } else {
                        opens.push(item.socialName);
                      }
                      setIsOpen(opens);
                    }}
                    className={`${
                      isOpen.includes(item.socialName)
                        ? 'fas fa-chevron-up'
                        : 'fas fa-chevron-down'
                    }`}
                  />
                </div>
              </div>
              <div className="all-collapse-container">
                <Collapse
                  isOpen={isOpen.includes(item.socialName)}
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                >
                  {item.sub.map((subItem: string) => (
                    <SubCollapse
                      title={subItem as 'SHARE' | 'PHOTO' | 'VIDEO'}
                      job_type={jobType}
                      social={item.socialName}
                      setFormValues={setFormValues}
                      formValues={formValues}
                    />
                  ))}
                </Collapse>
              </div>
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div className="page-content">
      <div className="bread-crumb">
        <Breadcrumb
          pageTitle={
            jobType === 'PUBLISH'
              ? t('Menus.Suggested Jobs')
              : t('Menus.Invite-Only Job')
          }
          menus={[]}
        />

        {jobType === 'PUBLISH' ? stepDisplaySuggestJob() : stepDisplayInviteOnly()}
        <Progress
          style={{
            transform: 'scale(0.8)',
            height: '25px',
            width: '100%',
            borderRadius: '44px',
            background: '#D9D9D9',
          }}
          max="3"
          value={jobType === 'PUBLISH' ? 1.55 : 1.11}
        />
        <Formik initialValues={[]} onSubmit={handleSubmit}>
          {({ values, handleChange, handleSubmit }: any) => {
            return (
              <form onSubmit={handleSubmit}>
                {' '}
                {jobType === 'PUBLISH' ? suggestJobForm() : inviteOnlyForm()}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <button type="submit" className=" button-next">
                    {t('Next')}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateMarketerJobCampaignPageNewstep2;
