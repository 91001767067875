import { useTranslation } from 'react-i18next';
import EnumStatus from 'src/constants/enum/influencerJob';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import inprogress_status from 'src/assets/images/card-status-inprogress.png';
import cancel_status from 'src/assets/images/card-status-cancel.png';
import finish_status from 'src/assets/images/card-status-finish.png';
import draft_status from 'src/assets/images/card-status-draft.png';
import new_job_status from 'src/assets/images/new-job-status.png';
import nosocial from 'src/assets/images/custom.svg';

import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import ContentType from 'src/constants/enum/contentType';
import './InfluencerJobStatus.style.scss';

const SocialIcon = (social_media_type: any) => {
  switch (social_media_type) {
    case SocialMediaType.FACEBOOK:
      return facebookLogo;
    case SocialMediaType.INSTAGRAM:
      return instagramLogo;
    case SocialMediaType.TIKTOK:
      return tikTokLogo;
    case SocialMediaType.TWITTER:
      return twitterLogo;
    case SocialMediaType.YOUTUBE:
      return youtubeLogo;
    case SocialMediaType.NOSOCIAL:
      return nosocial;
    default:
      return nosocial;
  }
};

const SocialContentType = (content_type: any) => {
  switch (content_type) {
    case ContentType.SHARE:
      return 'fas fa-share';
    case ContentType.VIDEO:
      return 'fas fa-video';
    case ContentType.PHOTO:
      return 'fas fa-image';
    case ContentType.LIVE:
      return 'fas fa-video';
    case ContentType.LIKE_AND_COMMENT:
      return 'far fa-comment-dots';
    case ContentType.REVIEW:
      return 'fas fa-pencil-square-o';
    case ContentType.SUBSCRIBE:
      return 'fas fa-plus-circle';
    case ContentType.LIKE_PAGE:
      return 'fa fa-thumbs-up';
    case ContentType.SHARE_GROUP:
      return 'fa fa-users';
    default:
      return 'fas fa-video';
  }
};
const SocialContentTypeColor = (content_type: any) => {
  switch (content_type) {
    case ContentType.SHARE:
      return '#3AB0FF';
    case ContentType.VIDEO:
      return '#FF0000';
    case ContentType.PHOTO:
      return '#C689C6';
    case ContentType.LIVE:
      return '#FF0000';
    case ContentType.LIKE_AND_COMMENT:
      return '#57e665';
    case ContentType.REVIEW:
      return '#758ef0';
    case ContentType.SUBSCRIBE:
      return '#ff6190';
    case ContentType.LIKE_PAGE:
      return '#3AB0FF';
    case ContentType.SHARE_GROUP:
      return '#3AB0FF';
    default:
      return '#FF0000';
  }
};
const InfluencerJobStatusColor = (status: any) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return '#839aa8';
    case EnumStatus.NEW_JOB:
      return '#2295EE';
    case EnumStatus.INPROGRESS:
      return '#EBA937';
    case EnumStatus.FINISHED:
      return '#009a78';
    case EnumStatus.CANCEL:
      return '#ff0000';
    case EnumStatus.PAUSE:
      return '#EBA937';
    default:
      return '#839aa8';
  }
};
const InfluencerJobStatusIcon = (status: any) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return 'fas fa-circle';
    case EnumStatus.NEW_JOB:
      return 'fas fa-circle';
    case EnumStatus.INPROGRESS:
      return 'fas fa-adjust';
    case EnumStatus.FINISHED:
      return 'fas fa-check-circle';
    case EnumStatus.CANCEL:
      return 'fas fa-ban';
    case EnumStatus.PAUSE:
      return 'fas fa-adjust';
    default:
      return '';
  }
};
const InfluencerSocialIcon = ({ social_media_type, size = 30 }: any) => {
  return (
    <>
      <img
        src={SocialIcon(social_media_type)}
        alt="icons"
        className="image-icon"
        style={{
          background: 'white',
          width: `${size}px`,
          height: `${size}px`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
    </>
  );
};

const InfluencerContentType = ({ content_type, size = 30 }: any) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: `${SocialContentTypeColor(content_type)}`,
          borderRadius: '50%',
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <i
          className={`${SocialContentType(content_type)}`}
          style={{
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '25px',
            justifyContent: 'center',
            width: '22px',
            borderRadius: '0px',
          }}
        />
      </div>
    </>
  );
};

const InfluencerJobStatusTag = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return 'linear-gradient(180deg, rgba(163, 163, 163, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.NEW_JOB:
      return 'linear-gradient(180deg, rgba(97, 179, 243, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.INPROGRESS:
      return 'linear-gradient(180deg, rgba(255, 188, 84, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.FINISHED:
      return 'linear-gradient(180deg, rgba(80, 208, 185, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.CANCEL:
      return 'linear-gradient(180deg, rgba(255, 140, 151, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.PAUSE:
      return 'linear-gradient(180deg, rgba(255, 188, 84, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    default:
      return '';
  }
};

const InfluencerJobBackGroundColor = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return 'linear-gradient(271.74deg,rgba(255, 255, 255, 0.3) 30.83%,rgba(255, 255, 255, 0) 68.18%),#ffbc54';
    //return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.3) 30.83%, rgba(255, 255, 255, 0) 68.18%), #A3A3A3';
    case EnumStatus.NEW_JOB:
      return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.3) 30.83%, rgba(255, 255, 255, 0) 68.18%), #2295EE';
    case EnumStatus.SUSPENDED:
    case EnumStatus.INPROGRESS:
      return 'linear-gradient(271.74deg,rgba(255, 255, 255, 0.3) 30.83%,rgba(255, 255, 255, 0) 68.18%),#ffbc54';
    case EnumStatus.FINISHED:
      return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.4) 30.83%, rgba(255, 255, 255, 0) 68.18%), #50D0B9';
    case EnumStatus.CANCEL:
      return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.3) 30.83%, rgba(255, 255, 255, 0) 68.18%), #FF8C97';
    case EnumStatus.PAUSE:
      return 'linear-gradient(271.74deg,rgba(255, 255, 255, 0.3) 30.83%,rgba(255, 255, 255, 0) 68.18%),#ffbc54';
    default:
      return '';
  }
};

const InfluencerStatusBackGroundImage = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return draft_status;
    case EnumStatus.NEW_JOB:
      return new_job_status;
    case EnumStatus.INPROGRESS:
      return inprogress_status;
    case EnumStatus.FINISHED:
      return finish_status;
    case EnumStatus.CANCEL:
      return cancel_status;
    case EnumStatus.PAUSE:
      return inprogress_status;
    default:
      return draft_status;
  }
};
const InfluencerJobStatus = ({ status, linkpost }: any) => {
  const { t } = useTranslation();
  let _status = status;
  if (status === EnumStatus.DRAFT) {
    _status = 'WAITING';
  } else if (status === EnumStatus.INPROGRESS && linkpost) {
    _status = 'COLLECTING';
  }
  return (
    // <Card
    //   style={{
    //     background: 'white',
    //     height: '42px',
    //     width: '100%',
    //     borderRadius: '12px',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   }}
    // >
    <div className="product-name-left">
      <div style={{ textAlign: 'right' }}>
        <span style={{ marginLeft: '2px', textAlign: 'center' }}>
          <i
            className={`${InfluencerJobStatusIcon(status)}`}
            style={{
              color: `${InfluencerJobStatusColor(status)}`,
              height: '20px',
              width: '22px',
              borderRadius: '0px',
            }}
          />
        </span>
        {t(`enum:${_status}`)}
      </div>
    </div>
    //  </Card>
  );
};

export {
  InfluencerJobStatus,
  InfluencerSocialIcon,
  InfluencerJobStatusTag,
  InfluencerJobBackGroundColor,
  InfluencerStatusBackGroundImage,
  InfluencerContentType,
  InfluencerJobStatusColor,
};
