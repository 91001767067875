import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from '../../i18n';

const campaignsStatus = [
  {
    title: i18n.t('All'),
    value: null,
    summaryKey: 'all',
  },
  {
    title: i18n.t('enumCampaign:Active'),
    value: 'ACTIVE',
    summaryKey: 'active',
  },
  {
    title: i18n.t('enumCampaign:Pause'),
    value: 'PAUSED',
    summaryKey: 'paused',
  },
  {
    title: i18n.t('enumCampaign:Out of budget'),
    value: 'OUT_OF_BUDGET',
    summaryKey: 'outOfBudget',
  },
  {
    title: i18n.t('enumCampaign:Finished'),
    value: 'FINISHED',
    summaryKey: 'finished',
  },
  {
    title: i18n.t('enumCampaign:Draft'),
    value: 'DRAFT',
    summaryKey: 'draft',
  },
  {
    title: i18n.t('enumCampaign:Archived'),
    value: 'ARCHIVED',
    summaryKey: 'archived',
  },
];

const CampaignStatusButtonGroup = ({
  statusSelected,
  handleStatusChange,
  statusSummary,
}: any) => {
  const { campaignStateSummaryLoading } = useSelector((state: any) => ({
    campaignStateSummaryLoading: state.Global.campaignStateSummaryLoading,
  }));

  const renderCampaignsStatus = () => {
    return campaignsStatus.map((item: any) => {
      return (
        <button
          type="button"
          className={`btn outline-sm m-1 ${
            statusSelected === item.value && 'active'
          }`}
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => handleStatusChange(item.value)}
          disabled={campaignStateSummaryLoading}
        >
          {item.title} {statusSummary[item.summaryKey]}
        </button>
      );
    });
  };

  return (
    <>
      <div className="nav-scrollbar-none">{renderCampaignsStatus()}</div>
    </>
  );
};

export default withTranslation()(CampaignStatusButtonGroup);
