import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

export const createAptitudeGql = async (name: string) => {
  const mutation = gql`
    mutation CreateAptitude($name: String!) {
      createAptitude(createAptitudeInput: { name: $name }) {
        name
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        name,
      },
    });
    notify(i18n.t('success:success'), 'success');
  } catch (error) {
    notify(i18n.t('error:error'), 'error');
  }
};

export const getAptitudesGql = async () => {
  const query = gql`
    query {
      getAllAptitude {
        id
        name
      }
    }
  `;

  try {
    const res = await client.query({
      query,
    });
    return res.data.getAllAptitude;
  } catch (error) {
    notify(i18n.t('error:Get aptitude error .'), 'error');
    return null;
  }
};
