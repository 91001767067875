/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum OwnCurrencyTypes {
  GET_FILTER_OWN_CURRENCY = '@@own_currency/GET_FILTER_OWN_CURRENCY',
  GET_FILTER_OWN_CURRENCY_SUCCESS = '@@own_currency/GET_FILTER_OWN_CURRENCY_SUCCESS',
  GET_FILTER_MARKETER_OWN_CURRENCY = '@@own_currency/GET_FILTER_MARKETER_OWN_CURRENCY',
  GET_FILTER_MARKETER_OWN_CURRENCY_SUCCESS = '@@own_currency/GET_FILTER_MARKETER_OWN_CURRENCY_SUCCESS',
}

export default OwnCurrencyTypes;
