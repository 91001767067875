import { CreativesTypes, CreativeState } from './actionTypes';

export const INIT_STATE: CreativeState = {
  creativesByUserId: [],
  transactions: [],
  creativeSizes: [],
  allCreativeSizes: [],
  creativeCampaign: {},
  filterCreativeCampaign: {},
  creativeReport: {},
  error: {},
  loading: false,
};

const Creatives = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CreativesTypes.GET_CREATIVE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        creativesByUserId: action.payload,
        id: action.id,
      };

    case CreativesTypes.GET_CREATIVE_REPORT_SUCCESS:
      return {
        ...state,
        creativeReport: action.payload,
      };

    case CreativesTypes.FILTER_CREATIVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        filterCreativeCampaign: action.payload,
      };

    case CreativesTypes.GET_CREATIVES_CAMPAIGN_SUCCESS:
      return {
        ...state,
        creativeCampaign: action.payload,
      };
    case CreativesTypes.GET_CREATIVE_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CreativesTypes.GET_CREATIVE_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case CreativesTypes.GET_CREATIVE_SIZES_SUCCESS:
      return {
        ...state,
        creativeSizes: action.payload,
      };
    case CreativesTypes.GET_ALL_CREATIVE_SIZES_SUCCESS:
      return {
        ...state,
        allCreativeSizes: action.payload,
      };
    default:
      return state;
  }
};

export default Creatives;
