/* eslint-disable react/jsx-curly-brace-presence */
import BannerFormat from 'src/constants/banner-format-Emun';
import highlightText from '../Styles/HighlightText';

const nbsp = (count: number, data: string) => (
  <>
    {[...Array(count)].map(() => (
      <>&nbsp;&nbsp;&nbsp;</>
    ))}
    {data}
    <br />
  </>
);

const IosKitContent = ({
  adKey,
  appKey,
  adType,
}: {
  adKey: string;
  appKey: string;
  adType: BannerFormat;
}) => (
  <>
    {nbsp(0, 'import AFLib')}
    {nbsp(0, '')}
    {nbsp(0, 'class someController: UIViewController {')}
    {nbsp(1, 'override func viewDidLoad() {')}
    {adType === BannerFormat.HEADER ? (
      <span style={{ display: 'block', ...highlightText() }}>
        {nbsp(
          2,
          `view.addSubview(AFlib.header(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: 0))`,
        )}
      </span>
    ) : (
      nbsp(
        2,
        `// view.addSubview(AFlib.header(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: 0))`,
      )
    )}
    {adType === BannerFormat.SLIDE ? (
      <span style={{ display: 'block', ...highlightText() }}>
        {nbsp(
          2,
          `view.addSubview(AFlib.slide(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: -60))`,
        )}
      </span>
    ) : (
      nbsp(
        2,
        `// view.addSubview(AFlib.slide(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: -60))`,
      )
    )}
    {adType === BannerFormat.STICKY ? (
      <span style={{ display: 'block', ...highlightText() }}>
        {nbsp(
          2,
          `view.addSubview(AFlib.sticky(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: -50))`,
        )}
      </span>
    ) : (
      nbsp(
        2,
        `// view.addSubview(AFlib.sticky(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: -50))`,
      )
    )}
    {adType === BannerFormat.IN_PAGE ? (
      <span style={{ display: 'block', ...highlightText() }}>
        {nbsp(
          2,
          `view.addSubview(AFlib.inPage(appKey:"${appKey}", adKey:"${adKey}",x: 0 ,y: 0))`,
        )}
      </span>
    ) : (
      nbsp(
        2,
        `// view.addSubview(AFlib.inPage(appKey:"appKeyExample", adKey:"adKeyExample",x: 0 ,y: 0))`,
      )
    )}
    {nbsp(1, '}')}
    {nbsp(0, '}')}
  </>
);

export default IosKitContent;
