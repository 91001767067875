/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import {
  filterSocialJob,
  setLoading,
  socialJobCardData,
  unsetLoading,
} from 'src/store/actions';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import { Dialog, DialogContent } from '@mui/material';
import { updateSocialMediaHasJobGql } from 'src/graphql/advertiser/socialMediaInfo';
import { useHistory } from 'react-router';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBar';
import PageSizeOptions from '../Table/PageSizeOptions';
import { Pending } from '../Common/approval/approvalStatus';
import JobDetailPost from '../Publisher/Influencer/JobDetail/jobDetailPost';
import control from '../Publisher/Influencer/Dashboard/SocialChannel/SocialCard/SocialCard.control';

const SuspendSocialJobTable = ({ t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const { onGetLogoSocialByType } = control();

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'SUSPENDED',
    isSpeedUp: true,
    search,
  });

  const { txn } = useSelector((state: any) => ({
    txn: state.SocialMediaInfo.filter,
  }));

  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  useEffect(() => {
    fetchSuspendInfluencerPost();
  }, []);

  const fetchSuspendInfluencerPost = async () => {
    await resetApproveLogValueByName('approve_suspend_influencer_job');
    dispatch(filterSocialJob(getDefaultPayload(1)));
  };

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await updateSocialMediaHasJobGql({
          status: verify,
          key,
          cancel_job_reason: verify === 'CANCEL' ? 'BY_ADMIN' : null,
        });
        dispatch(unsetLoading());
        dispatch(filterSocialJob(param));
      }
    });
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, link_post, social_media_info } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <JobDetailPost socialJobItem={postModalData} />
        </DialogContent>
      </Dialog>
    );
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('marketer:job_detail:Job_name'),
        accessor: ({ job }) => {
          return job.name;
        },
        id: 'job_name',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Influencer photo')}</div>,
        accessor: ({ social_media_info }: any) => (
          <img
            src={social_media_info.image_profile_url || userAvatar}
            alt="icons"
            className="image-icon"
            style={{
              width: '30px',
              height: '30px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        ),
        id: 'influencer_image',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Influencer name')}</div>,
        accessor: ({ social_media_info }: any) => social_media_info.name,
        id: 'influencer_name',
        disableSort: true,
      },
      {
        Header: t('table:social'),
        accessor: ({ social_media_info }: any) => {
          return (
            <div className="social-card-img">
              <img
                className="logo"
                src={onGetLogoSocialByType(social_media_info?.social_media_type)}
                alt="Social Logo"
                width={40}
              />
            </div>
          );
        },
        id: 'social_media_type',
        disableSort: true,
        width: 50,
      },
      {
        Header: <div className="text-header">{t(`table:user detail`)}</div>,
        accessor: ({ social_media_info }: any) => {
          return (
            <a
              className="cursor  fw-bold view-profile"
              href={`users/${social_media_info.user.uuid}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/users/${social_media_info.user.uuid}`);
              }}
            >
              {t(`table:user detail`)}
            </a>
          );
        },
        id: 'user_detail',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t(`table:Influencer Post`)}</div>,
        accessor: ({ link_post, social_media_info, post_date }: any) =>
          post_date ? (
            <button
              style={{ background: 'white' }}
              className="link-job"
              type="button"
              onClick={() => {
                dispatch(
                  socialJobCardData({
                    social_media_type: social_media_info?.social_media_type,
                    link_post,
                    social_key: social_media_info?.key,
                  }),
                );
                setPostModalData({
                  isOpen: true,
                  link_post,
                  social_media_info,
                });
              }}
            >
              {t('table:View post')}
            </button>
          ) : (
            <Pending message="pending" />
          ),
        id: 'influencer_post_date',
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ key, status }: any) => {
          return (
            <UncontrolledDropdown className="">
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <i className="bx bx-pencil" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => clickVerifyStatus('INPROGRESS', key)}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => clickVerifyStatus('CANCEL', key)}
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ],
    [t, txn, modalOpen],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(1),
      page: newPage,
    };

    dispatch(filterSocialJob(payload));
    setPage(newPage);
    setParam(payload);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(filterSocialJob(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(filterSocialJob(payload));
    setSearch(val);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(filterSocialJob(payload));
    setSearch('');
    setParam(payload);
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(filterSocialJob(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, page, pageSize],
  );

  if (!txn) return <></>;

  return (
    <>
      {displayModal()}
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SearchBar
              placeholder={t('table:Search Name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(SuspendSocialJobTable);
