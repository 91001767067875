import { Card, FormGroup, Label } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import useWindowSize from 'src/utils/windowSize';
import date_step2 from 'src/assets/images/date_step2.png';
import paramsFromURL from 'src/constants/paramsFromURL';
import ContentType from 'src/constants/enum/contentType';
import moment from 'moment';
import date_step7 from 'src/assets/images/date_step7.png';
import date_step1 from 'src/assets/images/date_step1.png';
import date_step3 from 'src/assets/images/date_step3.png';
import date_step4 from 'src/assets/images/date_step4.png';
import date_step5 from 'src/assets/images/date_step5.png';
import date_step6 from 'src/assets/images/date_step6.png';
import i18n from 'src/i18n';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import { MarketerJobNewType } from 'src/store/marketerJobNew/reducer';
import {
  createMarketerJobNew,
  createMarketerJobsNew,
} from 'src/store/marketerJobNew/actions';
import { createMarketerJobGql } from 'src/graphql/advertiser/job';
import Swal from 'sweetalert2';
import { clearMarketerJob, setLoading, unsetLoading } from 'src/store/actions';
import {
  validationSchemaPhoto,
  validationSchemaShare,
  validationSchemaVideo,
} from './ValidateCampaign';

// Assets

import {
  FormControl,
  formControlShare,
  formControlPhoto,
  formControlVideo,
} from './FormControlCampaign';

const MarketerJobCampaignDetailControl = () => {
  const dispatch = useDispatch();
  const { t }: any = useTranslation();
  const history = useHistory();
  const { width } = useWindowSize();
  const [validationSchema, setValidateSchema] = useState({});
  const initialValues = {
    date_submission: '',
    last_day_accept_job: '',
    last_day_approve_job: '',
    last_day_influencer_submit: '',
    last_day_to_approve: '',
    influencer_post_internal_job: '',
    finish_internal_work: '',
  };
  const [formValues, setFormValues] = useState(initialValues);

  // eslint-disable-next-line no-unused-vars
  const [minDate, setMinDate] = useState(Date.now());

  //? Logics
  const marketerJobNew: MarketerJobNewType = useSelector(
    (state: any) => state.MarketerCampaignJobsNew.marketerJobNew,
  );

  const { influencerData } = useSelector((state: any) => ({
    influencerData: state.Users.userInfo,
  }));
  // useEffect(() => {
  //   dispatch(getMarketerJob(jobId));
  // }, []);

  useEffect(() => {
    setValidateSchema((prevState) => ({
      ...prevState,
      ...checkValidateSchema(),
    }));
    const values = localStorage.getItem('marketerJobNew');
    if (values) {
      // setFormValues(JSON.parse(values));
      dispatch(createMarketerJobNew(JSON.parse(values)));
    }
  }, [marketerJobNew?.job_type]);

  const formRender = [
    {
      label: 'Date of submission of work to influencers',
      name: 'date_submission',
      type: FormInputType.DATE_INPUT,
      MinDate: Date.now(),
      icon: date_step1,
      backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
      // 'linear-gradient(to right top, #3aad96, #33a896, #2da395, #279e94, #229993)',
      iconColor: '#FFFFFF',
      textColor: '#495057',
    },
    {
      label: 'The last day the influencer has to accept the job.',
      name: 'last_day_accept_job',
      type: FormInputType.DATE_INPUT,
      MinDate: '',
      icon: date_step7,
      backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
      iconColor: '#FFFFFF',
      textColor: '#495057',
    },
    {
      label: 'Last day that influencers must submit their work for review.',
      name: 'last_day_influencer_submit',
      type: FormInputType.DATE_INPUT,
      MinDate: '',
      icon: date_step3,
      backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
      iconColor: '#FFFFFF',
      textColor: '#495057',
    },
    {
      label: `Last day to review and approve influencer's work.`,
      name: 'last_day_to_approve',
      type: FormInputType.DATE_INPUT,
      MinDate: '',
      icon: date_step4,
      backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
      iconColor: '#FFFFFF',
      textColor: '#495057',
    },
    {
      label: 'Influencers post internal jobs.',
      name: 'influencer_post_internal_job',
      type: FormInputType.DATE_INPUT,
      MinDate: '',
      icon: date_step5,
      backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
      iconColor: '#FFFFFF',
      textColor: '#495057',
    },
    {
      label: 'Collect statistics and finish the internal work.',
      name: 'finish_internal_work',
      type: FormInputType.DATE_INPUT,
      MinDate: '',
      icon: date_step6,
      backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
      iconColor: '#FFFFFF',
      textColor: '#495057',
    },
  ];

  const formControl = () => {
    switch (marketerJobNew.job_type) {
      case 'PUBLISH':
        return formRender;
      case 'INVITE_ONLY':
        return formRender;
      default:
        return formRender;
    }
  };

  const formControlData: any = formControl();

  //? Functions
  const convertLocalToUTCDate = (d: any) => {
    // eslint-disable-next-line no-param-reassign
    d = new Date(d);
    // eslint-disable-next-line no-param-reassign
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    return d;
  };

  const handleSubmit = async (e: any) => {
    const newFormValues: any = formValues;
    let submitValue = { ...marketerJobNew };

    const newJobs = submitValue.job.map((job) => {
      // eslint-disable-next-line no-param-reassign
      delete job.isSelected;
      return { ...job, ...newFormValues };
    });
    submitValue = { ...submitValue, job: newJobs };
    // dispatch(createMarketerJobsNew(submitValue));

    if (marketerJobNew.job_type === 'INVITE_ONLY') {
      dispatch(setLoading());
      const res = await createMarketerJobGql({ ...submitValue });
      dispatch(unsetLoading());
      dispatch(clearMarketerJob());
      history.push(`/JobSummary?job=${res.items[0].key}`);
    } else if (marketerJobNew.job_type === 'PUBLISH') {
      await Swal.fire({
        title: t('swal:Are you sure ?'),
        text: t("swal:You won't be able to revert this!"),
        imageUrl: choice,
        imageHeight: 200,
        width: 300,
        imageAlt: 'A tall image',
        customClass: {},
        cancelButtonColor: 'red',
        showCancelButton: true,
        confirmButtonColor: '#009a78',
        confirmButtonText: t('button:Yes'),
        cancelButtonText: t('swal:Cancel'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(setLoading());
          await createMarketerJobGql({ ...submitValue });
          dispatch(unsetLoading());
          dispatch(clearMarketerJob());
          window.location.replace(
            `/MarketerCampaign?campaign=${marketerJobNew.marketer_campaign_key}`,
          );
        }
      });
    }

    localStorage.removeItem('marketerJobNew');
    localStorage.removeItem('descriptionJobNew');
    // dispatch(setLoading());
    // const res = await createMarketerJobGql({ ...submitValue });
    // dispatch(unsetLoading());
    // if (marketerJobNew.job_type === 'INVITE_ONLY') {
    //   history.push(`/JobSummary?job=${res.items[0].key}`);
    // } else {
    //   window.location.replace(
    //     `/MarketerCampaign?campaign=${marketerJobNew.marketer_campaign_key}`,
    //   );
    // }

    // if (!formValues['thumbnail_cover'] && marketerJob.job_type === 'PUBLISH') {
    //   notify(i18n.t('error:You maximum images upload is 10 images'), 'error');
    //   return;
    // }
    // for (let i = 0; i < Object.keys(formValues).length; i++) {
    //   const submitInput = Object.keys(formValues)[i];
    //   if (marketerJob[submitInput] !== newFormValues[submitInput]) {
    //     submitValue[submitInput] = newFormValues[submitInput];
    //   }
    // }
    // // await removeJobImageByKey(key);

    // if (
    //   formValues['thumbnail_cover'] &&
    //   typeof formValues['thumbnail_cover'] === 'object'
    // ) {
    //   if (marketerJob?.thumbnail_cover) {
    //     await removeJobImageByKey(marketerJob?.thumbnail_cover);
    //     submitValue['thumbnail_cover'] = '';
    //   }
    //   const file: any =
    //     formValues['thumbnail_cover'][0] || formValues['thumbnail_cover'];
    //   const newFile = await resizeImage({ file, maxSize: 600 });
    //   let thumbnail_cover: any;
    //   if (newFile) {
    //     thumbnail_cover = newFile;
    //   } else {
    //     thumbnail_cover = formValues['thumbnail_cover'];
    //   }

    //   const imageKey = await uploadJobThumbnailImage(thumbnail_cover, jobId);
    //   submitValue['thumbnail_cover'] = imageKey;
    // } else {
    //   submitValue['thumbnail_cover'] = marketerJob['thumbnail_cover'];
    // }

    // // const res = await updateJobGql({
    // //   ...submitValue,
    // //   key: jobId,
    // // });

    // console.log({
    //   ...submitValue,
    //   key: jobId,
    // });

    // if (mock) {
    //   if (res.key) {
    //     dispatch(updateMarketerJobSuccess(res));
    //   }
    //   if (marketerJob.job_type === 'PUBLISH') {
    //     history.push(`/mock/jobRequirement?job=${res.key}`);
    //   } else {
    //     history.push(`/Marketer/SelectInfluencer?job=${res.key}`);
    //   }
    //   return;
    // }

    // if (res.key) {
    //   dispatch(updateMarketerJobSuccess(res));
    //   // window.location.href = `/marketer/${res.key}/selectInfluencer`;
    //   if (marketerJob.job_type === 'PUBLISH') {
    //     history.push(`/Marketer/JobRequirement?job=${res.key}`);
    //   } else {
    //     history.push(`/Marketer/SelectInfluencer?job=${res.key}`);
    //   }
    // }
  };

  const validateDate = {
    date_submission: Yup.date().required(
      i18n.t('marketer:create_job_campaign.required'),
    ),
    last_day_accept_job: Yup.date().required(
      i18n.t('marketer:create_job_campaign.required'),
    ),
    last_day_influencer_submit: Yup.date().required(
      i18n.t('marketer:create_job_campaign.required'),
    ),
    last_day_to_approve: Yup.date().required(
      i18n.t('marketer:create_job_campaign.required'),
    ),
    influencer_post_internal_job: Yup.date().required(
      i18n.t('marketer:create_job_campaign.required'),
    ),
  };

  const checkValidateSchema = () => {
    switch (marketerJobNew.job_type) {
      case 'PUBLISH':
        return {
          ...validateDate,
          last_day_approve_job: Yup.date().required(
            i18n.t('marketer:create_job_campaign.required'),
          ),
        };

      case 'INVITE_ONLY':
        return validateDate;

      default:
        return {};
    }
  };

  // const checkValidateSchema = () => {
  //   switch (marketerJobNew.content_type) {
  //     case ContentType.PHOTO:
  //       return validationSchemaPhoto;

  //     case ContentType.SHARE:
  //       return validationSchemaShare;

  //     case ContentType.VIDEO:
  //       return validationSchemaVideo;

  //     default:
  //       return {};
  //   }
  // };

  const minDateByKey = (keyName: string): Date => {
    const date = new Date(formValues.influencer_post_internal_job);
    const finishDate = new Date(date.setMonth(date.getMonth() + 1));
    const newDate = new Date();

    if (marketerJobNew.job_type === 'PUBLISH') {
      if (keyName === 'last_day_influencer_submit') {
        return new Date(formValues.last_day_approve_job);
      }
      if (keyName === 'last_day_approve_job') {
        return new Date(formValues.last_day_accept_job);
      }
    }

    switch (keyName) {
      case 'date_submission':
        return new Date(newDate.setDate(newDate.getDate()));
      case 'last_day_accept_job':
        return new Date(formValues.date_submission);
      case 'last_day_influencer_submit':
        return new Date(formValues.last_day_accept_job);
      case 'last_day_to_approve':
        return new Date(formValues.last_day_influencer_submit);
      case 'influencer_post_internal_job':
        return new Date(formValues.last_day_to_approve);
      case 'finish_internal_work':
        return finishDate;
      default:
        return new Date();
    }
  };

  const maxDateByKey = (keyName: string) => {
    const date = new Date(formValues.influencer_post_internal_job);
    const finishDate = new Date(date.setMonth(date.getMonth() + 1));

    if (marketerJobNew.job_type === 'PUBLISH') {
      if (keyName === 'last_day_accept_job') {
        return new Date(formValues.last_day_approve_job);
      }
      if (keyName === 'last_day_approve_job') {
        return new Date(formValues.last_day_influencer_submit);
      }
    }

    switch (keyName) {
      case 'date_submission':
        return new Date(formValues.last_day_accept_job);
      case 'last_day_accept_job':
        return new Date(formValues.last_day_influencer_submit);
      case 'last_day_influencer_submit':
        return new Date(formValues.last_day_to_approve);
      case 'last_day_to_approve':
        return new Date(formValues.influencer_post_internal_job);
      case 'influencer_post_internal_job':
        return null;
      case 'finish_internal_work':
        return finishDate;
      default:
        return new Date();
    }
  };
  const dateInput = ({
    input,
    errors,
    touched,
    disabled,
  }: {
    input: FormControl;
    errors: any;
    touched: any;
    disabled?: boolean;
  }): any => {
    let value;
    if (formValues[input.name as keyof typeof formValues]) {
      value = formValues[input.name as keyof typeof formValues] as string;
      value = new Date(value).toLocaleDateString();
    }
    const newFormValues: any = formValues;

    if (typeof input.name !== 'string') return <></>;
    const timezone = moment.tz.guess();
    const startTime = moment('0:00 AM', 'h:mm A');
    const currentTimeZone = moment(startTime)
      .add(influencerData.timezone_offset, 'minutes')
      .format('HH:mm A');
    return (
      <>
        <FormGroup className="form-date-container">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label
              className={`w-100 `}
              style={{
                fontSize: '18px',
                fontWeight: 600,
                color: input.textColor,
              }}
            >
              {t(`marketer:create_job_campaign:${input.label}`)}
              {input.required ? <span className="input-required">*</span> : ''}
            </Label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {input.name !== 'finish_internal_work' ? (
                <>
                  <label
                    htmlFor={input.name}
                    style={{
                      cursor: disabled ? 'no-drop' : 'pointer',
                      marginBottom: 0,
                    }}
                  >
                    <Card className="card-date-container-selected">
                      <div className="card-date-selected-label">
                        {value || 'XX/XX/XXXX'}
                      </div>
                    </Card>
                  </label>
                  <label htmlFor={input.name} style={{ marginBottom: 0 }}>
                    {' '}
                    <Card
                      style={{
                        width: '37px',
                        height: 'auto',
                        background: '#FFFFFF',
                        border: '2px solid #21603E',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '14px',
                        cursor: disabled ? 'no-drop' : 'pointer',
                      }}
                    >
                      <i
                        className="fas fa-calendar-alt"
                        style={{
                          padding: '6px',
                          fontSize: '16px',
                          color: '#21603E',
                        }}
                      />
                    </Card>
                  </label>
                  <DatePicker
                    id={input.name}
                    autoComplete="off"
                    name={input.name}
                    selected={
                      newFormValues[input.name]
                        ? new Date(newFormValues[input.name])
                        : undefined
                    }
                    value={
                      newFormValues[input.name]
                        ? new Date(newFormValues[input.name]).toDateString()
                        : undefined
                    }
                    className={`w-100 d-none ${
                      errors[input.name] && touched[input.name] ? 'is-invalid' : ''
                    }`}
                    onChange={async (e: any) => {
                      setMinDate(e);
                      let dateString: string = '';
                      if (input.name === 'influencer_post_internal_job') {
                        const date = new Date(e);
                        dateString = new Date(
                          date.setMonth(date.getMonth() + 1),
                        ).toString();
                      }
                      // resetDate(input.name);
                      setFormValues({
                        ...formValues,
                        [input.name]: convertLocalToUTCDate(e),
                        finish_internal_work:
                          input.name === 'influencer_post_internal_job'
                            ? dateString
                            : formValues.finish_internal_work,
                      });
                    }}
                    withPortal
                    minDate={minDateByKey(input.name)}
                    maxDate={maxDateByKey(input.name)}
                    // eslint-disable-next-line react/jsx-boolean-value
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={80}
                    showYearDropdown
                    disabled={disabled}
                  />{' '}
                  <span
                    style={{
                      padding: '6px',
                      fontSize: '16px',
                      color: '#21603E',
                      marginLeft: '15px',
                    }}
                  >
                    {currentTimeZone}
                    <span style={{ visibility: 'hidden' }}> {timezone}</span>
                  </span>
                </>
              ) : (
                <>
                  <Card
                    className="card-date-container-selected"
                    style={{
                      cursor: disabled ? 'no-drop' : 'pointer',
                      marginRight: '1.5rem',
                    }}
                  >
                    <label
                      htmlFor={input.name}
                      className="card-date-selected-label"
                      style={{
                        cursor: 'no-drop',
                      }}
                    >
                      {value || 'XX/XX/XXXX'}
                    </label>
                  </Card>
                  <span
                    style={{
                      // display: 'flex',
                      padding: '6px',
                      fontSize: '16px',
                      color: '#21603E',
                      marginBottom: `${width > 450 ? '25px' : '0'}`,
                      alignItems: `${width > 450 ? 'center' : ''}`,
                      justifyContent: 'center',
                    }}
                  >
                    {currentTimeZone}
                    <span style={{ visibility: 'hidden' }}> {timezone}</span>
                  </span>
                </>
              )}
            </div>
          </div>
        </FormGroup>
      </>
    );
  };

  //? UI Display
  const inputs = (errors: any, touched: boolean, handleChange: any) => {
    let newForm = [];
    if (marketerJobNew.job_type !== 'PUBLISH') {
      newForm = [...formControlData];
    } else {
      for (let i = 0; i < formControlData?.length; i++) {
        const form: any = formControlData[i];
        newForm.push(form);

        if (form.name === 'last_day_accept_job') {
          newForm.push({
            label: 'The last day marketer confirm job.',
            name: 'last_day_approve_job',
            type: FormInputType.DATE_INPUT,
            MinDate: '',
            icon: date_step2,
            backgroundColor:
              'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
            iconColor: '#FFFFFF',
            textColor: '#495057',
          });
        }
      }
    }

    return (
      <>
        <div style={{ paddingBottom: '10px', width: '100%' }}>
          {/* {newForm.map((input: any) => {
            switch (input.type) {
              case FormInputType.STRING:
                return textInput(input, errors, touched, handleChange);
              case FormInputType.DATE_INPUT:
                return <div key={input.name} />;
              case FormInputType.TEXT_EDITOR_INPUT:
                return textEditorInput(input, errors, touched, handleChange);
              case FormInputType.REVIEW_TYPE:
                return ReviewOption(input, errors, touched);
              default:
                return textInput(input, errors, touched, handleChange);
            }
          })} */}
        </div>
        <div style={{ textAlign: 'start', width: '100%' }}>
          <span
            // style={{
            //   margin: '10px 0 10px 0 ',
            //   width: '100%',
            //   fontSize: '20px',
            //   fontWeight: 'bold',
            //   paddingLeft: '15px',
            //   color: '#a983fb',
            // }}
            className="header-job-title mt-4"
          >
            {t(`marketer:create_job_campaign:Date Time Line`)}
          </span>
          <div className="timeline">
            {newForm.map((input: FormControl) => {
              if (input.type !== FormInputType.DATE_INPUT)
                return <div key={input.name} />;
              let value;
              if (formValues[input.name as keyof typeof formValues]) {
                value = formValues[input.name as keyof typeof formValues] as string;
                value = new Date(value).toDateString();
              }
              return (
                <div
                  className="timeline-container"
                  key={input.name}
                  style={{ fontSize: '16px' }}
                >
                  <Card
                    className="timeline-contents"
                    style={{
                      background: `${
                        (value && 'white') || `${errors[input.name] && 'white'}`
                      }`,
                      border: errors[input.name] ? '2px solid red' : 'none',
                    }}
                  >
                    <img
                      src={input.icon}
                      alt={input.icon}
                      style={{ width: '80px', height: '80px' }}
                    />
                    <div className="font-date-input">
                      {dateInput({
                        input,
                        errors,
                        touched,
                        disabled: Number.isNaN(minDateByKey(input.name).getTime()),
                      })}
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  //? Return
  return {
    initialValues,
    handleSubmit,
    validationSchema,
    inputs,
    marketerJob: marketerJobNew,
    formValues,
    setFormValues,
  };
};

export default MarketerJobCampaignDetailControl;

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // REVIEW_BEFORE_POSTING,
  // eslint-disable-next-line no-unused-vars
  DATE_INPUT,
  // eslint-disable-next-line no-unused-vars
  TEXT_EDITOR_INPUT,
  // eslint-disable-next-line no-unused-vars
  REVIEW_TYPE,
}

// interface Category {
//   label: string;
//   value: string | number | boolean;
//   icon: any;
//   icon_color?: string;
//   background_color?: string;
// }
