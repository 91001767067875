import { UsersTypes } from './actionTypes';

const initialState = {
  users: [],
  userDetail: {},
  error: '',
  loading: false,
  userInfo: { dataReceived: false, image_storage: null },
};

const Users = (state = initialState, action: any) => {
  switch (action.type) {
    case UsersTypes.REMOVE_USER_STORE:
      return {
        ...initialState,
      };
    case UsersTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case UsersTypes.GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UsersTypes.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      };
    case UsersTypes.CREATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          dataReceived: false,
        },
      };
    case UsersTypes.CREATE_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...action.payload,
          dataReceived: true,
          image_storage: state?.userInfo?.image_storage,
        },
      };
    case UsersTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...action.payload,
          dataReceived: true,
        },
      };
    case UsersTypes.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...action.payload,
          dataReceived: true,
          image_storage: state?.userInfo?.image_storage,
        },
      };

    default:
      return state;
  }
};

export default Users;
