import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  NavLink,
  NavItem,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';
import CreativeTypeSetting from 'src/components/Advertiser/CampaignSettings/CreativeTypeSetting';
import menu from 'src/constants/menu';
import Creative from '../../components/Advertiser/CampaignSettings/Creative';
import Geo from '../../components/Advertiser/CampaignSettings/Geo';
import ManageWeb from '../../components/Advertiser/CampaignSettings/ManageWeb';
import Overview from '../../components/Advertiser/CampaignSettings/Overview';
import CampaignReport from '../../components/Advertiser/CampaignSettings/Report';
import { getCampaign as onGetCampaign } from '../../store/actions';
import config from '../../config';
import Breadcrumb from '../../components/Common/Breadcrumb';

const SetupCampaignPage = ({ t }: any) => {
  const { id }: any = useParams();

  const dispatch = useDispatch();
  //status variable
  const [selectSettingTab, setSelectSettingTab] = useState('1');

  const { campaign } = useSelector((state: any) => ({
    campaign: state.Campaigns.campaign,
  }));
  useEffect(() => {
    dispatch(onGetCampaign(id));
  }, []);
  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {campaign.name} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={`${t('Page.Campaign')} : ${campaign.name}`}
          menus={[
            { menu: menu.CAMPAIGN },
            {
              menu: menu.CAMPAIGN_DETAIL,
              active: true,
              id,
            },
          ]}
        />

        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <div className="nav-tabs-custom nav-scrollbar-none">
                  <NavItem className="ms-2 me-2">
                    <NavLink
                      className={`cursor color-primary ${
                        selectSettingTab === '1' && 'bg-color-primary'
                      } `}
                      onClick={() => {
                        setSelectSettingTab('1');
                      }}
                    >
                      <h5>{t('campaign:Overview')}</h5>
                    </NavLink>
                  </NavItem>

                  <NavItem className="ms-2 me-2">
                    <NavLink
                      className={`cursor color-primary ${
                        selectSettingTab === '2' && 'bg-color-primary'
                      } `}
                      onClick={() => {
                        setSelectSettingTab('2');
                      }}
                    >
                      <h5>{t('creative:Creatives')}</h5>
                    </NavLink>
                  </NavItem>

                  <NavItem className="ms-2 me-2">
                    <NavLink
                      className={`cursor color-primary ${
                        selectSettingTab === '3' && 'bg-color-primary'
                      } `}
                      onClick={() => {
                        setSelectSettingTab('3');
                      }}
                    >
                      <h5>{t('Target Audience')}</h5>
                    </NavLink>
                  </NavItem>

                  {/* <NavItem className="ms-2 me-2">
                                        <NavLink
                                            className={`cursor color-primary ${
                                                selectSettingTab === '4' &&
                                                'bg-color-primary'
                                            } `}
                                            onClick={() => {
                                                setSelectSettingTab('4');
                                            }}
                                        >
                                            <h5>{t('Campaign Category')}</h5>
                                        </NavLink>
                                    </NavItem> */}

                  <NavItem className="ms-2 me-2">
                    <NavLink
                      className={`cursor color-primary ${
                        selectSettingTab === '5' && 'bg-color-primary'
                      } `}
                      onClick={() => {
                        setSelectSettingTab('5');
                      }}
                    >
                      <h5>{t('Reports')}</h5>
                    </NavLink>
                  </NavItem>
                </div>
              </CardHeader>
              <CardBody>
                <div className="mt-4">
                  {selectSettingTab === '1' && (
                    <Overview setSelectSettingTab={setSelectSettingTab} />
                  )}
                  {selectSettingTab === '2' && <Creative />}
                  {selectSettingTab === '3' && (
                    <>
                      <Geo />
                      <ManageWeb />
                    </>
                  )}

                  {selectSettingTab === '4' && <CreativeTypeSetting />}
                  {selectSettingTab === '5' && (
                    <CampaignReport campaign={campaign} />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(SetupCampaignPage);
