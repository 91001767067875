import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { roleText } from '../../../constants/role';

const SumWalletsRolePieChart = ({ t }: any) => {
  const { sumWalletsRole } = useSelector((state: any) => ({
    sumWalletsRole: state.SystemBalance.sumWalletsRole,
  }));
  let series: any = [];
  const labels = [
    t(roleText.ADVERTISER),
    t(roleText.PUBLISHER),
    t(roleText.ADMIN),
    t(roleText.HOLD),
    t(roleText.FEE),
  ];

  if (!isEmpty(sumWalletsRole)) {
    series = [
      +sumWalletsRole.advertiser_sum,
      +sumWalletsRole.publisher_sum,
      +sumWalletsRole.admin_sum,
      +sumWalletsRole.hold_sum,
      +sumWalletsRole.fee_sum,
    ];
  }

  const piechartColors = ['#FD6A02', '#4CBB17', '#0080FE', '#28415a', '#e3ef33'];

  const options: Object = {
    chart: {
      width: 227,
      height: 227,
      type: 'pie',
    },
    labels,
    colors: piechartColors,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <>
      {!isEmpty(sumWalletsRole) && (
        <ReactApexChart options={options} series={series} type="pie" height="227" />
      )}
    </>
  );
};

export default withTranslation()(SumWalletsRolePieChart);
