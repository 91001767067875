import { Card, CardBody, Col, Row } from 'reactstrap';

//import Charts
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DashboardProps } from '../../../common/types';
import { transformStringAmountInput } from '../../../utils/input';

const SystemBalanceWidgets = ({ t }: any) => {
  const { sumWallets, sumCryptosBalance, sumProfitsBalance } = useSelector(
    (state: any) => ({
      sumWallets: state.SystemBalance.sumWallets,
      sumCryptosBalance: state.SystemBalance.sumCryptosBalance,
      sumProfitsBalance: state.SystemBalance.sumProfitsBalance,
    }),
  );

  const WidgetsData: Array<DashboardProps> = [
    {
      id: 1,
      title: t('System Balance'),
      price: sumWallets ? transformStringAmountInput(sumWallets) : 0,
      rank: '',
      isDoller: true,
      postFix: '',
      statusColor: 'success',
      series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    },
    {
      id: 2,
      title: `${t('Crypto Balance')} (Fairmarketcoin)`,
      price: sumCryptosBalance ? transformStringAmountInput(sumCryptosBalance) : 0,
      rank: '',
      isDoller: true,
      postFix: '',
      statusColor: 'danger',
      series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12],
    },
    {
      id: 3,
      title: `${t('Profit Balance')}`,
      price: sumProfitsBalance ? transformStringAmountInput(sumProfitsBalance) : 0,
      rank: '',
      isDoller: true,
      postFix: '',
      statusColor: 'success',
      series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12],
    },
  ];

  return (
    <>
      {(WidgetsData || []).map((widget: any, key: number) => {
        let widgetPath;
        if (key === 0) {
          widgetPath = '/admin/system-balance-log';
        } else if (key === 1) {
          widgetPath = '/admin/crypto-balance-log';
        } else if (key === 2) {
          widgetPath = '/admin/profit-balance-log';
        } else {
          widgetPath = '/admin/system-balance-log';
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col xl={3} md={6} key={key}>
            <Link to={widgetPath}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <span className="text-muted mb-3 lh-1 d-block text-truncate">
                        {widget['title']}
                      </span>
                      <h4 className="mb-3">
                        <span className="counter-value">
                          {widget['price']}
                          {widget['isDoller'] && '  USDT'}
                          {widget['postFix']}
                        </span>
                      </h4>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span
                      className={`badge badge-soft-${widget['statusColor']} text-${widget['statusColor']}`}
                    >
                      {widget['rank']}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
        );
      })}
    </>
  );
};

export default withTranslation()(SystemBalanceWidgets);
