import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Label } from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import config from 'src/config';
// import Role from 'src/constants/enum/role';
import { getAvailableNetwork } from 'src/store/currencies/actions';
import { validateAddress, transformAddressInShortFrom } from '../../utils/address';
import { transformStringAmountInput } from '../../utils/input';
import { withdrawAdminRequest } from '../../store/finance/withdraw/actions';
import currenciesSymbol from '../../constants/currenciesSymbol';
// import { splitUnderscoreWithTuple } from '../../utils/currency';
// import { getMinimumWithdrawConfig } from '../../store/default-values/actions';
import CustomButton from '../Common/CustomButton';
import { getFee, getWithdrawAndFeeWallet } from '../../graphql/common/index';

const initialValues = {
  symbol: '',
  network: '',
  fromAddress: '',
  address: '',
  tag: '',
  amount: '',
};

const withdrawSchema: any = Yup.object().shape({
  symbol: Yup.string().required(),
  network: Yup.string().required(),
  fromAddress: Yup.string().required(),
  address: Yup.string().required(),
  tag: Yup.string().when('symbol', {
    is: (val: any) => val === currenciesSymbol.XRP,
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  amount: Yup.string().required(),
});

const AdminWithdrawForm = ({ t, currencies }: any) => {
  const dispatch = useDispatch();

  const [symbol, setSymbol] = useState('');
  const [isAddressValid, setValidAddress] = useState(true);
  const [isValidWalletBalance, setValidWalletBalance] = useState(true);
  const [isValidMinWithdraw, setValidMinWithdraw] = useState(true);
  const [currencyId, setCurrencyId] = useState<any>(+9);
  const [networkSelect, setNetworkSelect] = useState('');
  const [fee, setFee] = useState(0);

  // {
  //   internal_transfer_fee: 0,
  //   internal_transfer_fee_is_percent: false,
  //   external_transfer_fee: 0,
  //   external_transfer_fee_is_percent: false,
  //   external_deposit_fee: 0,
  //   external_deposit_fee_is_percent: false,
  //   isInternal: false,
  // }

  const { wallet, minimumWithdrawConfig, user, network } = useSelector(
    (state: any) => ({
      wallet: state.Wallet.wallet,
      twoFactorEnabled: state.twoFactor.isEnabled,
      minimumWithdrawConfig: state.DefaultValue.minimumWithdrawConfig,
      user: state.login.user,
      network: state.Currencies.availableNetworks,
    }),
  );

  console.log('isAddressValid', isAddressValid);
  useEffect(() => {
    // const x = async () => {
    //   const data = await getFee({
    //     symbol: 'XRP',
    //     address: 'rUmF3HKaDWjRSatsQ1nfDTMocuuZQ4ntnM',
    //   });
    //   console.log('data', data);
    // };
    // x();
    dispatch(getAvailableNetwork());
  }, []);

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    if (isAddressValid) {
      Swal.fire({
        title: t('swal:Are you sure'),
        text: t('swal:Withdraw Request Confirm', {
          amount: values.amount,
          symbol,
          address: transformAddressInShortFrom(values.address, 6),
        }),
        imageUrl: choice,
        width: 300,
        imageHeight: 200,
        imageAlt: 'A tall image',
        customClass: {},
        cancelButtonColor: 'red',
        showCancelButton: true,
        confirmButtonText: t('button:Yes'),
        cancelButtonText: t('button:No'),
        confirmButtonColor: '#009a78',
      }).then((result) => {
        if (result.isConfirmed) {
          const newValues = { ...values };
          newValues.amount = parseFloat(newValues.amount.replace(/,/g, ''));
          // console.log('Withdraw payload', newValues);
          dispatch(withdrawAdminRequest(newValues));

          resetForm();
          setSubmitting(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setSubmitting(false);
        }
      });
    }
  };

  /**
   * Check Address's Format By Symbol
   *
   * @param address
   * @param symbol
   */
  const handleCheckAddress = async (address: string, addressSymbol: string) => {
    let isValid = true;

    if (config.appEnv === 'production') {
      isValid = validateAddress(address, addressSymbol);
    }

    if (address && addressSymbol) {
      setValidAddress(isValid);
      setSymbol(addressSymbol);
      if (isValid) {
        const data = await getWithdrawAndFeeWallet(
          addressSymbol,
          networkSelect,
          address,
        );
        setFee(data.fee);
      }
    }
  };

  const handleAmountChange = (value: string, setFieldValue: any) => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));

    // if (value) {
    //   setValidWalletBalance(parsedValue <= wallet.balance);

    //   if (minimumWithdrawConfig && symbol) {
    //     setValidMinWithdraw(parsedValue >= +minimumWithdrawConfig.estimate_in_USD);
    //   }
    // }

    setFieldValue('amount', transformStringAmountInput(value));
  };

  const renderAvailableCurrencies = () => {
    const arr: any = [];
    // console.log('available', currencies);
    for (let i = 0; i < currencies?.length; i++) {
      const currency = currencies[i].symbol;
      arr.push(<option value={currency}>{currency}</option>);
    }

    return arr;
  };

  const renderCurrencies = () => {
    const arr: any = [];
    for (let i = 0; i < network?.length; i++) {
      arr.push(<option value={network[i]}>{network[i]}</option>);
    }

    return arr;
  };

  const renderAddressError = (errors: any) => {
    let errorText: string = '';
    let html: any = '';

    if (!isAddressValid) {
      if (!isEmpty(errors.address)) {
        errorText = errors.address;
      } else if (!isEmpty(errors.fromAddress)) {
        errorText = errors.fromAddress;
      } else {
        errorText = symbol
          ? t('error:Invalid Address Symbol Format', { symbol })
          : t('error:Invalid Address Format');
      }

      html = <div className="invalid-feedback">{errorText}</div>;
    } else {
      html = (
        <ErrorMessage name="address" component="div" className="invalid-feedback" />
      );
    }

    return html;
  };

  const renderAmountError = (errors: any) => {
    let errorText: string = '';
    let html: any = '';

    if (!isValidWalletBalance) {
      if (!isEmpty(errors.amount)) {
        errorText = errors.amount;
      } else {
        errorText = t('Error.Amount More Than Balance');
      }

      html = <div className="invalid-feedback">{errorText}</div>;
    } else if (!isValidMinWithdraw) {
      if (!isEmpty(errors.amount)) {
        errorText = errors.amount;
      } else {
        errorText = t('Error.Amount Must Be More Than', {
          min: minimumWithdrawConfig.estimate_in_USD,
        });
      }

      html = <div className="invalid-feedback">{errorText}</div>;
    } else {
      html = (
        <ErrorMessage name="amount" component="div" className="invalid-feedback" />
      );
    }

    return html;
  };

  // const calculateFee = (amount: any) => {
  //   const a = parseFloat(amount.replace(/,/g, ''));
  //   let total = 0.0;
  //   if (fee.external_transfer_fee_is_percent) {
  //     total = a * (fee.external_transfer_fee / 100);
  //     return total;
  //   }
  //   if (fee.internal_transfer_fee_is_percent) {
  //     total = a * (fee.internal_transfer_fee / 100);
  //     return total;
  //   }
  //   if (!fee.isInternal && fee.internal_transfer_fee) {
  //     total = fee.internal_transfer_fee;
  //   } else if (!fee.isInternal && fee.external_transfer_fee) {
  //     total = fee.external_transfer_fee;
  //   } else if (!fee.isInternal && fee.external_deposit_fee) {
  //     total = fee.external_deposit_fee;
  //   }
  //   return total;
  // };

  const getMaxAmount = (setFieldValue: any) => {
    const maxAmount = transformStringAmountInput(wallet.balance);
    setValidWalletBalance(maxAmount <= wallet.balance);

    if (minimumWithdrawConfig && symbol) {
      setValidMinWithdraw(maxAmount >= +minimumWithdrawConfig.estimate_in_USD);
    }
    setFieldValue('amount', transformStringAmountInput(wallet.balance));
  };
  return (
    <>
      <Card>
        <CardHeader>
          <h6>{t('Cryptocurrency Withdraw')}</h6>
          <p className="card-title-desc">{t('Cryptocurrency Withdraw DESC')}</p>
        </CardHeader>
        <CardBody>
          <Formik
            validationSchema={withdrawSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ errors, values, touched, setFieldValue, handleChange }) => (
              <FormikForm>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="symbol">{t('Coin')}</Label>
                          <Field
                            as="select"
                            name="symbol"
                            className={`form-control ${
                              errors.symbol && touched.symbol ? 'is-invalid' : ''
                            } arrow-dropdown`}
                            // onBlur={() => {
                            //   handleCheckAddress(values.address, values.symbol);
                            //

                            onChange={(e: any) => {
                              const { value } = e.currentTarget.value;
                              // setCurrencyId(currencies.filter(item=> !))
                              // console.log('value = ==', e.currentTarget.value);
                              const re = currencies.filter(
                                (values: any) =>
                                  values?.symbol === e.currentTarget.value,
                              );
                              setCurrencyId(re[0].id);
                              // console.log('eee', currencyId);
                              handleChange(e);
                            }}
                          >
                            {' '}
                            <option value="">
                              {t('Select your coin to withdraw')}
                            </option>
                            {renderAvailableCurrencies()}
                          </Field>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="symbol">{t('Withdraw Method')}</Label>
                          <Field
                            as="select"
                            name="network"
                            className={`form-control ${
                              errors.symbol && touched.symbol ? 'is-invalid' : ''
                            } arrow-dropdown`}
                            // onBlur={() => {
                            //   handleCheckAddress(values.address, values.symbol);
                            // }}
                            onChange={(e: any) => {
                              const { value } = e.currentTarget;
                              setNetworkSelect(value);
                              setSymbol(value);
                              handleCheckAddress(values.address, value);
                              handleChange(e);
                            }}
                          >
                            <option value="">
                              {t('Select your withdraw method')}
                            </option>
                            {renderCurrencies()}
                          </Field>
                          <Label htmlFor="fromAddress">{t('From Address')}</Label>
                          <Field
                            name="fromAddress"
                            type="text"
                            className={`form-control ${
                              !isAddressValid ||
                              (errors.fromAddress && touched.fromAddress)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="fromAddress"
                            onBlur={() => {
                              handleCheckAddress(values.fromAddress, values.symbol);
                            }}
                            autocomplete="off"
                          />
                          {renderAddressError(errors)}
                          <Label htmlFor="address">{t('Withdraw Address')}</Label>
                          <Field
                            name="address"
                            type="text"
                            className={`form-control ${
                              !isAddressValid || (errors.address && touched.address)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="address"
                            onBlur={() => {
                              handleCheckAddress(values.address, values.symbol);
                            }}
                            autocomplete="off"
                          />
                          {renderAddressError(errors)}
                        </FormGroup>
                      </Col>

                      <ErrorMessage
                        name="symbol"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  {/* <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="tag">{t('Withdraw Tag')}</Label>
                      <Field
                        name="tag"
                        type="text"
                        className={`form-control ${
                          errors.tag && touched.tag ? 'is-invalid' : ''
                        }`}
                        placeholder={t('Withdraw Tag Placeholder')}
                        id="tag"
                        autocomplete="off"
                        disabled={symbol !== currenciesSymbol.XRP}
                      />
                      <ErrorMessage
                        name="tag"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col> */}
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="amount">{t('Form.Amount')}</Label>
                      <div style={{ position: 'relative' }}>
                        <Field
                          name="amount"
                          type="text"
                          className={`form-control ${
                            !isValidMinWithdraw ||
                            !isValidWalletBalance ||
                            (errors.amount && touched.amount)
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="amount"
                          onChange={(e: any) => {
                            handleAmountChange(e.currentTarget.value, setFieldValue);
                          }}
                          autocomplete="off"
                          aria-describedby="minWithdrawValueHelp"
                        />{' '}
                        {renderAmountError(errors)}
                      </div>
                      <div className="form-control-plaintext">
                        &nbsp;&nbsp;
                        {!isEmpty(wallet) && wallet.balance && (
                          <>
                            <strong className="text-decoration available-balance text-primary font-size-15">
                              {`Fee ${fee} USDT `}
                            </strong>
                          </>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <CustomButton
                  size="xxs"
                  status="submit"
                  label={t('Button.Submit')}
                />
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default withTranslation()(AdminWithdrawForm);
