import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Card, CardBody, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { getEarningTransaction } from 'src/store/actions';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CustomTable from '../Common/CustomTable';

const DashboardEarningHistory = ({ t }: any) => {
  //* Variables
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState('DESC');
  const [sortBy, setSortBy] = useState('id');
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Website / Application'),
        accessor: ({ website, application }) =>
          website ? website?.url : application?.name,
        id: 'url',
        disableSort: true,
      },
      {
        Header: t('table:Type'),
        accessor: ({ website }) => {
          return website ? (
            <Badge className="bg-success fs-6 fw-bold" style={{ width: '80px' }}>
              {t('Website')}
            </Badge>
          ) : (
            <Badge className="bg-warning fs-6 fw-bold" style={{ width: '80px' }}>
              {t('Application')}
            </Badge>
          );
        },
        id: 'web',
        disableSort: true,
      },
      {
        Header: t('table:Price'),
        accessor: ({ FIAT_amount }) => {
          return `${floorToFixed(FIAT_amount, 2)} USDT`;
        },
        id: 'FIAT_amount',
      },
    ],
    [t],
  );
  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  //* Selectors
  const { earning_transaction, earningTransactionLoading } = useSelector(
    (state: any) => ({
      earning_transaction: state.WebsiteInfo.earningTransaction,
      earningTransactionLoading: state.Global.earningTransactionLoading,
    }),
  );

  //* Functions
  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;
    const payload = {
      limit: 5,
      page: newPage,
      sortBy,
      sortType,
    };

    dispatch(getEarningTransaction(payload));
    setPage(newPage);
  };

  //* Callback
  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';
      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;
      const payload = {
        limit: 5,
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getEarningTransaction(payload));
      setPage(newPage);
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page],
  );

  useEffect(() => {
    dispatch(getEarningTransaction({ limit: 5, page: 1 }));
  }, []);

  return (
    <Col sm={12}>
      <Card className="h-240 mb-0">
        <CardBody>
          <div
            className="justify-content-between"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <p className="font-size-20 fw-bold">{t('Menus.Earning History')}</p>
          </div>
          <div>
            <CustomTable
              headerCSS="fw-normal bg-header-table"
              columns={columns}
              data={earning_transaction.items || []}
              page={page}
              pageSize={5}
              total={earning_transaction.totalCount}
              handlePageChange={handlePageChange}
              hiddenOnXS={hiddenOnXS}
              hiddenOnSM={hiddenOnSM}
              hiddenOnMD={hiddenOnMD}
              hiddenOnLG={hiddenOnLG}
              onSort={handleSort}
              marginTopClassName="mt-0"
              isLoading={earningTransactionLoading}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default withTranslation()(DashboardEarningHistory);
