import { useState } from 'react';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';
import {
  removeAdImageSizeGraphql,
  updateAdImageSizeGraphQL,
} from 'src/graphql/advertiser/creative';
import choice from 'src/assets/images/choice.png';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import {
  getCreativeSizes as onGetCreativeSizes,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import SimpleBar from 'simplebar-react';
import { withTranslation } from 'react-i18next';

const SettingSizeDisplay = ({ data, device_platform, t }: any) => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState<number | null>(null);

  const [updateData, setUpdateData] = useState<any>({
    key: 0,
    width: 0,
    height: 0,
  });

  const initialValues = {
    width: '',
    height: '',
  };

  const numValidate = Yup.number()
    .required('Required')
    .positive('Make sure positive number');

  const AdSizeSchema = Yup.object().shape({
    width: numValidate,
    height: numValidate,
  });

  const onUpdate = async () => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t('swal:Do you want to update!'),
      width: 300,
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await updateAdImageSizeGraphQL({
          key: updateData.key,
          width: +updateData.width,
          height: +updateData.height,
        });
        dispatch(unsetLoading());
        dispatch(onGetCreativeSizes({ device_platform }));
      }
    });
  };

  const onSubmit = () => {};
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={AdSizeSchema}
      >
        {({ errors, touched, handleChange }) => (
          <FormikForm>
            <SimpleBar className="table-responsive" style={{ maxHeight: '200px' }}>
              {(data || []).map((size: any, index: number) => {
                const { key, width, height } = size;
                return (
                  <Row className="mt-2" key={key}>
                    <Col sm={1} className="font-size-20">
                      <div className="avatar-sm">
                        <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-14 ">
                          {index + 1}
                        </span>
                      </div>
                    </Col>
                    <Col sm={4} className="text-center">
                      <FormGroup>
                        {isEdit === index ? (
                          <>
                            <Field
                              value={updateData.width}
                              name="width"
                              type="text"
                              className={`form-control  form-control-sm ${
                                errors.width && touched.width && 'is-invalid '
                              }`}
                              onChange={(e: any) => {
                                setUpdateData({
                                  ...updateData,
                                  width: e.currentTarget.value,
                                });
                                handleChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="width"
                              component="div"
                              className="invalid-feedback"
                            />
                          </>
                        ) : (
                          width
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={1} className="text-center font-size-20">
                      x
                    </Col>
                    <Col sm={4} className="text-center">
                      <FormGroup>
                        {isEdit === index ? (
                          <>
                            <Field
                              value={updateData.height}
                              name="height"
                              type="text"
                              className={`form-control  form-control-sm ${
                                errors.height && touched.height && 'is-invalid '
                              }`}
                              onChange={(e: any) => {
                                setUpdateData({
                                  ...updateData,
                                  height: e.currentTarget.value,
                                });
                                handleChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="height"
                              component="div"
                              className="invalid-feedback"
                            />
                          </>
                        ) : (
                          height
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={2} className="text-center">
                      {isEdit === index ? (
                        <>
                          <div className="avatar-sm inline-block">
                            <button
                              className="btn-invisible btn-xs"
                              type="button"
                              onClick={() => {
                                setIsEdit(null);
                              }}
                            >
                              <div className="avatar-sm">
                                <span className="avatar-bin bg-soft-primary rounded-circle">
                                  <i className="mdi mdi-content-save-off font-size-18 text-danger" />
                                </span>
                              </div>
                            </button>
                          </div>
                          <div className="avatar-sm inline-block">
                            <button
                              className="btn-invisible btn-xs"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsEdit(null);
                                onUpdate();
                              }}
                            >
                              <div className="avatar-sm">
                                <span className="avatar-bin bg-soft-primary rounded-circle">
                                  <i className="mdi mdi-content-save font-size-18 text-success" />
                                </span>
                              </div>
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="avatar-sm inline-block">
                            <span
                              className="avatar-bin bg-soft-primary rounded-circle"
                              onKeyDown={() => {}}
                              role="button"
                              tabIndex={0}
                              onClick={async () => {
                                await Swal.fire({
                                  width: 300,
                                  title: t('swal:Are you sure ?'),
                                  text: t("swal:You won't be able to revert this!"),
                                  imageUrl: choice,
                                  imageHeight: 200,
                                  imageAlt: 'A tall image',
                                  customClass: {},
                                  cancelButtonColor: 'red',
                                  showCancelButton: true,
                                  confirmButtonColor: '#009a78',

                                  confirmButtonText: t('button:Yes'),
                                  cancelButtonText: t('swal:Cancel'),
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    dispatch(setLoading());
                                    await removeAdImageSizeGraphql(key);
                                    dispatch(unsetLoading());

                                    dispatch(
                                      onGetCreativeSizes({
                                        device_platform,
                                      }),
                                    );
                                  }
                                });
                              }}
                            >
                              <i className="mdi mdi-delete font-size-18 text-danger" />
                            </span>
                          </div>
                          <div className="avatar-sm inline-block">
                            <span
                              className="avatar-bin bg-soft-primary rounded-circle"
                              onKeyDown={() => {}}
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                setIsEdit(index);
                                setUpdateData({
                                  key,
                                  width,
                                  height,
                                });
                              }}
                            >
                              <i className="mdi mdi-square-edit-outline font-size-18 text-info" />
                            </span>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </SimpleBar>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default withTranslation()(SettingSizeDisplay);
