/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum BannerFormat {
  IN_PAGE,
  STICKY,
  HEADER,
  SLIDE,
  NULL,
}

export default BannerFormat;
