import { ApplicationsTypes, ApplicationState } from './actionTypes';

export const INIT_STATE: ApplicationState = {
  applications: {},
  application: {},
  applicationCreative: {},
  applicationEarningTransaction: [],
  applicationGraphDatum: [],
  applicationFilterDatum: [],
  error: {},
  loading: false,
};

const Applications = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ApplicationsTypes.GET_APPLICATIONS_FILTER_SUCCESS:
      return {
        ...state,
        applications: action.payload,
      };
    case ApplicationsTypes.GET_APPLICATION_BY_KEY_SUCCESS:
      return {
        ...state,
        application: action.payload,
      };
    case ApplicationsTypes.GET_APPLICATION_BY_KEY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ApplicationsTypes.GET_APPLICATION_CREATIVE_FILTER_SUCCESS:
      return {
        ...state,
        applicationCreative: action.payload,
      };
    case ApplicationsTypes.GET_APPLICATION_GRAPH_DATUM_SUCCESS:
      return {
        ...state,
        applicationGraphDatum: action.payload,
      };
    case ApplicationsTypes.CLEAR_APP_GRAPH_DATUM:
      return {
        ...state,
        applicationGraphDatum: null,
      };
    case ApplicationsTypes.GET_APPLICATION_GRAPH_DATUM_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ApplicationsTypes.GET_FILTER_APPLICATION_DATUM_TABLE_SUCCESS:
      return {
        ...state,
        applicationFilterDatum: action.payload,
      };
    case ApplicationsTypes.GET_FILTER_APPLICATION_DATUM_TABLE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ApplicationsTypes.GET_PUBLISHER_EARNING_TRANSACTION_SUCCESS:
      return {
        ...state,
        applicationEarningTransaction: action.payload,
      };
    case ApplicationsTypes.GET_PUBLISHER_EARNING_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Applications;
