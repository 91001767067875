import React, { useEffect, useState } from 'react';
import './mockJobRequireMentTable.style.scss';
import styleComponent from './jobSummary.style';

const MockJobRequirementTable = ({ requirements }: any) => {
  const [rows, setRows] = useState<any>([]);
  const { Texts, FlexRow, FlexCol } = styleComponent();
  useEffect(() => {
    if (!requirements) return;
    const requirementTable: any = {};
    // requirementTable = requirements?.map((req: any) => ({
    requirementTable['Min Budget'] = requirements.min_budget;
    requirementTable['Max Budget'] = requirements.max_budget;
    if (requirements.fixed_price !== null) {
      requirementTable['Fixed price'] = requirements.fixed_price;
    }
    requirementTable['Male'] = requirements.is_male;
    requirementTable['Female'] = requirements.is_female;
    requirementTable['Male LGBTQ+'] = requirements.is_male_lgbt;
    requirementTable['Female LGBTQ+'] = requirements.is_female_lgbt;
    requirementTable['Min Age'] = requirements.min_age;
    requirementTable['Max Age'] = requirements.max_age;
    requirementTable['Aptitudes'] = requirements.aptitudes;
    requirementTable['Country'] = requirements.countries;

    // }));
    if (requirementTable?.Country?.length > 1) {
      requirementTable.Country = [{ name: 'All' }];
    }
    const newRows = [];
    for (let i = 0; i < Object.keys(requirementTable)?.length; i++) {
      const requirementKey = Object.keys(requirementTable)[i];
      let value = requirementTable[requirementKey];
      if (requirementKey === 'Aptitudes') {
        value = '';
        for (let j = 0; j < requirementTable[requirementKey]?.length; j++) {
          const element = requirementTable[requirementKey][j];
          if (j === requirementTable[requirementKey]?.length - 1) {
            value = `${value}${element.name}`;
          } else {
            value = `${value}${element.name}, `;
          }
        }
      }
      if (requirementKey === 'Country') {
        value = '';
        for (let k = 0; k < requirementTable[requirementKey]?.length; k++) {
          const element = requirementTable[requirementKey][k];
          if (k === requirementTable[requirementKey]?.length - 1) {
            value = `${value}${element.name}`;
          } else {
            value = `${value}${element.name},`;
          }
        }

        value = value || 'All';
      }

      newRows.push({ name: requirementKey, value });
    }

    setRows(newRows);
  }, [requirements]);

  if (!rows?.length) return <></>;

  return (
    <FlexCol width="60%" margin="2rem 0 2rem 0">
      {rows.map((row: any, index: any) => {
        let borderRadius = '';
        if (index === 0) borderRadius = '20px 20px 0 0';
        else if (index === rows?.length - 1) borderRadius = '0  0 20px 20px';
        return (
          <FlexRow
            width="80%"
            height="100%"
            margin="auto"
            border="2px solid #EBF6F6"
            borderRadius={borderRadius}
            justifycontent="space-between"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Texts
              fontsize="1rem"
              margin="0.5rem 0rem 0.5rem 1.5rem"
              style={{
                overflow: 'initial',
                textOverflow: 'initial',
                whiteSpace: 'initial',
              }}
            >
              <i className="fas fa-chalkboard-teacher" /> {row.name} :
            </Texts>
            <Texts
              fontsize="1rem"
              margin="0.5rem 1.5rem"
              style={{
                overflow: 'initial',
                textOverflow: 'initial',
                whiteSpace: 'initial',
                wordBreak: 'break-word',
              }}
            >
              {row.value && row.value}
              {row.value === true && (
                <i className="fas fa-check-circle" style={{ color: 'green' }} />
              )}
              {row.value === false && '-'}
            </Texts>
          </FlexRow>
        );
      })}
    </FlexCol>
  );
};

interface TableRowProps {
  name: string;
  value: string;
}

const TableRow: React.FC<TableRowProps> = ({ name, value }) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{value.toString()}</td>
    </tr>
  );
};

export default MockJobRequirementTable;
