import MetaTags from 'react-meta-tags';
import { Row, Col, Container } from 'reactstrap';

import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// import images
import useWindowSize from 'src/utils/windowSize';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo-colorful.svg';
import CarouselPage from './CarouselPage';
import ForgotPasswordForm from '../../components/Authentication/ForgotPasswordForm';
import config from '../../config';
import logoWhite from '../../assets/images/logo-white.svg';
import { layoutTheme } from '../../constants/layout';

const ForgotPassword = ({ t }: any) => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  const { width } = useWindowSize();

  return (
    <>
      <MetaTags>
        <title>Forget Password | {config.appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src={
                            layoutMode === layoutTheme.DARKMODE ? logoWhite : logo
                          }
                          alt=""
                          height="28"
                        />{' '}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{t('Page.Forgot Password')}</h5>
                        <p className="text-muted mt-2">
                          {t('Page.Reset Password With', {
                            name: config.appName,
                          })}
                        </p>
                      </div>

                      <ForgotPasswordForm />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {width <= 767 ? <></> : <CarouselPage />}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(withRouter(ForgotPassword));
