//react
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//react style
import {
  Row,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  CardHeader,
  Card,
  NavLink,
  NavItem,
} from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
import { Column } from 'react-table';
//component
import { removeCampaignGraphql } from 'src/graphql/advertiser/campaign';
import choice from 'src/assets/images/choice.png';
import Swal from 'sweetalert2';
import useWindowDimensions from 'src/components/useWindowDimensions';
import CampaignStatusButtonGroup from 'src/components/Advertiser/CampaignStatusButtonGroup';
import MetaTags from 'react-meta-tags';
import { floorToFixed } from 'src/common/data/mathToFixed';
import PageSizeOptions from '../../components/Table/PageSizeOptions';
import CampaignsDetailModal from '../../components/Table/CampaignsDetailModal';
import AdFormat from '../../constants/adFormatEnum';
import SearchBar from '../../components/Table/SearchBar';
import CampaignStatus from '../../components/Common/CampaignStatus';
import CustomTable from '../../components/Common/CustomTable';
//redux
import {
  getCampaignsFilter as onGetCampaignsFilter,
  getCampaignStatusSummary as onGetCampaignStatusSummary,
  setLoading,
  unsetLoading,
} from '../../store/actions';
import config from '../../config';
import menu from '../../constants/menu';
import Breadcrumb from '../../components/Common/Breadcrumb';

const CampaignsPage = ({ t }: any) => {
  //from react
  const dispatch = useDispatch();
  const history = useHistory();
  //state variables
  const [creativeTypeTab, SetCreativeTypeTab] = useState(AdFormat.BANNER);
  const [statusSelected, setStatusSelected] = useState<String | null>('ALL');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isMoreInfo, setIsMoreInfo] = useState(false);
  const [moreInfoData, setMoreInfoData] = useState<any>();
  const [nameSearch, setNameSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  //general variable
  const { windowWidth } = useWindowDimensions();
  const { Campaigns, statusSummary, campaignFilterLoading } = useSelector(
    (state: any) => {
      const campaignsItem = state.Campaigns.transactions.items;
      if (campaignsItem) {
        for (let i = 0; i < campaignsItem?.length; i++) {
          campaignsItem[i].linkPath = `campaigns/${campaignsItem[i].id}`;
        }
      }
      const campaignsData = {
        items: campaignsItem,
        totalCount: state.Campaigns.transactions.totalCount,
      };
      return {
        Campaigns: campaignsData,
        statusSummary: state.Campaigns.statusSummary,
        campaignFilterLoading: state.Global.campaignFilterLoading,
      };
    },
  );

  //general function
  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      page,
      status: statusSelected,
      name: nameSearch,
      ad_format: AdFormat[creativeTypeTab],
    };
  };
  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };
    dispatch(onGetCampaignsFilter(payload));
    setPage(newPage);
  };
  const handleStatusChange = (status: string | null) => {
    const payload = {
      ...getDefaultPayload(),
      page: 1,
      status,
    };
    dispatch(onGetCampaignsFilter(payload));
    setStatusSelected(status);
  };

  const handleAdFormatChange = (adFormat: string) => {
    const payload = {
      ...getDefaultPayload(),
      ad_format: adFormat,
    };
    dispatch(onGetCampaignsFilter(payload));

    dispatch(onGetCampaignStatusSummary(adFormat));
    switch (adFormat) {
      case AdFormat[0]:
        return SetCreativeTypeTab(AdFormat.BANNER);
      case AdFormat[1]:
        return SetCreativeTypeTab(AdFormat.NATIVE);
      case AdFormat[2]:
        return SetCreativeTypeTab(AdFormat.POP);
      default:
        return SetCreativeTypeTab(AdFormat.BANNER);
    }
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...getDefaultPayload(),
      name: searchInput,
    };
    dispatch(onGetCampaignsFilter(payload));
    setNameSearch(searchInput);
  };
  const handleMoreDetail = (index: number) => {
    setIsMoreInfo(true);
    Campaigns.items[index].index = index;
    setMoreInfoData(Campaigns.items[index]);
  };
  const handleClear = () => {
    setNameSearch('');
    const payload = {
      ...getDefaultPayload(),
      name: '',
    };
    dispatch(onGetCampaignsFilter(payload));
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...getDefaultPayload(),
      limit: +newPageSize,
      page: 1,
    };
    dispatch(onGetCampaignsFilter(payload));
    setPageSize(+newPageSize);
    setPage(1);
  };
  //html form
  const adTypeForm = (campaign: any) => {
    const { ad_format, adsActiveCount, adsCount, device_platform, banner_format } =
      campaign;
    return (
      <>
        {ad_format === 'BANNER'
          ? t(`enumCampaign:${banner_format}`)
          : t(`enumCampaign:${ad_format}`)}
        , ({adsActiveCount}/{adsCount})
        <br />
        {device_platform && <>{t(`enumCampaign:${device_platform}`)}</>}
      </>
    );
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetCampaignsFilter(payload));
      setPage(newPage);
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page, pageSize],
  );

  const totalBudgetForm = (max_budget: number, currentBudget: number) => {
    return (
      <>
        <div>{max_budget ? `$ ${max_budget}` : t('campaign:Unlimited')}</div>
        <div>
          {t('Used')}: ${currentBudget ? floorToFixed(currentBudget, 2) : 0}
        </div>
      </>
    );
  };

  //table
  const columns: Column<any>[] = React.useMemo(() => {
    return [
      {
        Header: t('campaign:Campaign Name'),
        accessor: ({ name }) => name,
        Cell: ({ cell }: any) => (
          <a
            className="cursor hover-cp fw-bold "
            href="campaign"
            onClick={(e) => {
              e.preventDefault();
              history.push(`campaigns/${cell.row.original.campaign_key}`);
            }}
          >
            {cell.row.original.name}
          </a>
        ),
        id: 'name',
        width: 150,
      },
      {
        Header: t('tableCreative:Ad type'),
        accessor: (campaign) => adTypeForm(campaign),
        id: 'adType',
        width: 150,
        disableSort: true,
      },
      {
        Header: t('campaign:Total budget'),
        accessor: ({ max_budget }) => max_budget,
        id: 'max_budget',
        Cell: ({ cell }: any) =>
          totalBudgetForm(
            cell.row.original.max_budget,
            cell.row.original.currentBudget,
          ),
      },
      {
        Header: t('Status'),
        accessor: ({ status }) => <CampaignStatus status={status} />,
        id: 'Status',
        disableSort: true,
        width: 60,
      },
      {
        Header: t('campaign:Budget'),
        accessor: ({ budget }) => budget,
        id: 'budget',
        width: 60,
      },
      {
        Header: 'CPM',
        accessor: ({ CPM }) => CPM,
        id: 'CPM',
        width: 60,
      },
      {
        Header: 'eCPM',
        accessor: ({ eCPM }) => eCPM,
        id: 'eCPM',
        width: 60,
      },
      {
        Header: t('Impressions'),
        accessor: ({ impression }) => impression,
        id: 'impression',
        width: 60,
      },
      {
        Header: t('Clicks'),
        accessor: ({ click }) => click,
        id: 'click',
        width: 60,
      },
      {
        Header: 'CTR',
        accessor: ({ CTR }) => (CTR ? `${floorToFixed(CTR, 2)}%` : '0%'),
        id: 'CTR',
        width: 60,
      },
      {
        Header: t('table:Action'),
        accessor: ({ campaign_key }, index) => (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={async () => {
                  await Swal.fire({
                    width: 300,
                    title: t('swal:Are you sure ?'),
                    text: t("swal:You won't be able to revert this!"),
                    imageUrl: choice,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    customClass: {},
                    cancelButtonColor: 'red',
                    showCancelButton: true,
                    confirmButtonColor: '#009a78',
                    confirmButtonText: t('button:Yes'),
                    cancelButtonText: t('button:No'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      dispatch(setLoading());
                      await removeCampaignGraphql(campaign_key);
                      dispatch(onGetCampaignsFilter(getDefaultPayload()));
                      dispatch(
                        onGetCampaignStatusSummary(AdFormat[creativeTypeTab]),
                      );
                      dispatch(unsetLoading());
                    }
                  });
                }}
              >
                <i className="fas fa-trash icon-red" />
                &nbsp;&nbsp;{t('table:Delete')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push(`campaigns/${campaign_key}`);
                }}
              >
                <i className="bx bx-pencil" />
                &nbsp;&nbsp;{t('Edit')}
              </DropdownItem>
              <div className="dropdown-divider" />
              <DropdownItem
                onClick={() => {
                  handleMoreDetail(index);
                }}
              >
                <i className="fas fa-file-alt" />
                &nbsp;&nbsp;{t('Detail')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ];
  }, [t, page, Campaigns]);
  const hiddenOnXS: any = [
    'Total budget',
    'adType',
    'Status',
    'Clicks',
    'CTR',
    'eCPM',
  ];
  const hiddenOnSM: any = ['Total budget', 'adType', 'Status'];
  const hiddenOnMD: any = ['Total budget'];
  const hiddenOnLG: any = [];

  //set data
  useEffect(() => {
    dispatch(onGetCampaignsFilter(getDefaultPayload()));
    dispatch(onGetCampaignStatusSummary(AdFormat[creativeTypeTab]));
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('page:Campaign')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('page:Campaign')}
          menus={[{ menu: menu.DASHBOARD }, { menu: menu.CAMPAIGN, active: true }]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <div className="nav-tabs-custom nav-scrollbar-none">
                  <NavItem className="">
                    <NavLink
                      className={`${
                        creativeTypeTab === AdFormat.BANNER && 'active'
                      } `}
                      onClick={() => {
                        handleAdFormatChange(AdFormat[0]);
                      }}
                    >
                      <h5 className="mb-0">
                        {windowWidth > 666
                          ? t('creative:Banner Ads')
                          : t('creative:Banner')}
                      </h5>
                    </NavLink>
                  </NavItem>
                  <NavItem className="ms-2 me-2 font-size-12">
                    <NavLink
                      className={`${
                        creativeTypeTab === AdFormat.NATIVE && 'active'
                      } `}
                      onClick={() => handleAdFormatChange(AdFormat[1])}
                    >
                      <h5 className="mb-0">
                        {windowWidth > 666
                          ? t('creative:Native Ads')
                          : t('creative:Native')}
                      </h5>
                    </NavLink>
                  </NavItem>
                  <NavItem className="ms-2 me-2">
                    <NavLink
                      className={`${creativeTypeTab === AdFormat.POP && 'active'} `}
                      onClick={() => handleAdFormatChange(AdFormat[2])}
                    >
                      <h5 className="mb-0">
                        {windowWidth > 666
                          ? t('creative:Pop - under')
                          : t('creative:Pop')}
                      </h5>
                    </NavLink>
                  </NavItem>
                </div>
              </CardHeader>
              <CardBody>
                <div className="p-40px-horizontal-responsive">
                  <Row>
                    <Col>
                      <CampaignStatusButtonGroup
                        statusSelected={statusSelected}
                        handleStatusChange={handleStatusChange}
                        statusSummary={statusSummary}
                      />
                    </Col>
                    <Col xs={12} sm={2} className="align-self-baseline">
                      <button
                        type="button"
                        className={`btn btn-info fw-normal ${
                          windowWidth > 576 ? 'fe' : 'w-100 mt-2'
                        }`}
                        onClick={() => {
                          history.push(`/campaign/create`);
                        }}
                      >
                        {t('button:Add campaign')}
                      </button>
                    </Col>
                  </Row>
                  <div className="mt-3">
                    <Row className="justify-content-between">
                      <Col
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        className="d-flex align-items-center"
                      >
                        <PageSizeOptions
                          defaultValue={pageSize}
                          handlePageSizeChange={handlePageSizeChange}
                        />
                      </Col>
                      <Col md={1} lg={3} />
                      <Col xs={12} md={8} lg={6}>
                        <Row>
                          <Col sm={12} md={3} />
                          <Col sm={12} md={9}>
                            <SearchBar
                              placeholder={t('table:Search Name')}
                              handleSearch={handleSearch}
                              handleClear={handleClear}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>

                <CardBody>
                  <CustomTable
                    columns={columns}
                    data={Campaigns.items || []}
                    page={page}
                    pageSize={pageSize}
                    total={Campaigns.totalCount || 1}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                    hiddenOnXS={hiddenOnXS}
                    hiddenOnSM={hiddenOnSM}
                    hiddenOnMD={hiddenOnMD}
                    hiddenOnLG={hiddenOnLG}
                    onSort={handleSort}
                    isLoading={campaignFilterLoading}
                  />
                  <CampaignsDetailModal
                    isOpen={isMoreInfo}
                    handleToggle={setIsMoreInfo}
                    data={moreInfoData}
                    totalBudgetForm={totalBudgetForm}
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(CampaignsPage);
