import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

const getFAQFilter = async (filterFAQInput: {
  search?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
}) => {
  const GET_FAQ_FILTER = gql`
    query FilterFAQInput($filterFAQInput: FilterFAQInput) {
      faqFilterTable(filterFAQInput: $filterFAQInput) {
        items {
          key
          title
          detail
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_FAQ_FILTER,
    variables: { filterFAQInput },
    fetchPolicy: 'no-cache',
  });

  return result.data.faqFilterTable;
};

const getFAQSearch = async (search: string) => {
  const GET_FAQ_SEARCH = gql`
    query SearchFaq($search: String!) {
      searchFaq(search: $search) {
        key
        title
        detail
      }
    }
  `;

  const result: any = await client.query({
    query: GET_FAQ_SEARCH,
    variables: { search },
    fetchPolicy: 'no-cache',
  });

  return result.data.searchFaq;
};

const updateFaq = async (updateFaqInput: {
  key: string;
  title?: string;
  detail?: string;
}) => {
  const MUTATION_FAQ_UPDATE = gql`
    mutation updateFaq($updateFaqInput: UpdateFaqInput!) {
      updateFaq(updateFaqInput: $updateFaqInput) {
        key
      }
    }
  `;

  try {
    const result: any = await client.mutate({
      mutation: MUTATION_FAQ_UPDATE,
      variables: { updateFaqInput },
      fetchPolicy: 'no-cache',
    });

    notify(i18n.t('success:FAQ has been updated.'), 'success');
    return result.data.updateFaq;
  } catch (error) {
    notify(i18n.t('error:FAQ has not been updated.'), 'error');
    return '';
  }
};

const createFaq = async (createFaqInput: { title: string; detail: string }) => {
  const MUTATION_FAQ_CREATE = gql`
    mutation createFaq($createFaqInput: CreateFaqInput!) {
      createFaq(createFaqInput: $createFaqInput) {
        key
      }
    }
  `;

  try {
    const result: any = await client.mutate({
      mutation: MUTATION_FAQ_CREATE,
      variables: { createFaqInput },
      fetchPolicy: 'no-cache',
    });

    notify(i18n.t('success:FAQ has been created.'), 'success');
    return result.data.createFaq;
  } catch (error) {
    notify(i18n.t('error:FAQ has not been created.'), 'error');
    return '';
  }
};

const removeFaq = async (key: string) => {
  const MUTATION_FAQ_REMOVE = gql`
    mutation removeFaq($key: String!) {
      removeFaq(key: $key)
    }
  `;
  try {
    await client.mutate({
      mutation: MUTATION_FAQ_REMOVE,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    notify(i18n.t('success:FAQ has been removed.'), 'success');
  } catch (error) {
    notify(i18n.t('error:FAQ has not been removed.'), 'error');
  }
};

export { getFAQFilter, updateFaq, createFaq, removeFaq, getFAQSearch };
