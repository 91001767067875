import { call, put, takeEvery } from 'redux-saga/effects';
import { BalanceTypes } from './actionTypes';
import {
  getSumWalletsSuccess,
  getSumCryptosBalanceSuccess,
  getCryptoWalletsSuccess,
  getSumWalletsRoleSuccess,
  getSystemBalanceLogTransactionsSuccess,
  getCryptoBalanceLogTransactionsSuccess,
  getRoleBalanceLogTransactionsSuccess,
  fail,
  getSumProfitsBalanceSuccess,
  getProfitBalanceLogTransactionsSuccess,
} from './actions';
import {
  getSumWallets as getSumWalletsGql,
  getSumCryptosBalance as getSumCryptosBalanceGql,
  getCryptoWallets as getCryptoWalletsGql,
  getSumWalletsRole as getSumWalletsRoleGql,
  getSystemBalanceLogTransactions as getSystemBalanceLogTransactionsGql,
  getCryptoBalanceLogTransactions as getCryptoBalanceLogTransactionsGql,
  getRoleBalanceLogTransactions as getRoleBalanceLogTransactionsGql,
  getSumProfitsBalance as getSumProfitsBalanceGql,
  getProfitBalanceLogTransactions as getProfitBalanceLogTransactionsGql,
} from '../../graphql/admin/systembalance';

function* getSumWallets() {
  try {
    const response: Promise<any> = yield call(getSumWalletsGql);

    yield put(getSumWalletsSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getSumCryptosBalance() {
  try {
    const response: Promise<any> = yield call(getSumCryptosBalanceGql);

    yield put(getSumCryptosBalanceSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getSumProfitsBalance() {
  try {
    const response: Promise<any> = yield call(getSumProfitsBalanceGql);

    yield put(getSumProfitsBalanceSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getCryptoWallets() {
  try {
    const response: Promise<any> = yield call(getCryptoWalletsGql);

    yield put(getCryptoWalletsSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getSumWalletsRole() {
  try {
    const response: Promise<any> = yield call(getSumWalletsRoleGql);

    yield put(getSumWalletsRoleSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getSystemBalanceLogTransactions({ payload: LogTransactionsQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getSystemBalanceLogTransactionsGql,
      LogTransactionsQuery,
    );

    yield put(getSystemBalanceLogTransactionsSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getProfitBalanceLogTransactions({ payload: LogTransactionsQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getProfitBalanceLogTransactionsGql,
      LogTransactionsQuery,
    );

    yield put(getProfitBalanceLogTransactionsSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getCryptoBalanceLogTransactions({ payload: LogTransactionsQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getCryptoBalanceLogTransactionsGql,
      LogTransactionsQuery,
    );

    yield put(getCryptoBalanceLogTransactionsSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* getRoleBalanceLogTransactions({ payload: LogTransactionsQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getRoleBalanceLogTransactionsGql,
      LogTransactionsQuery,
    );

    yield put(getRoleBalanceLogTransactionsSuccess(response));
  } catch (e) {
    yield put(fail(e));
  }
}

function* systemBalanceSaga() {
  // @ts-ignore
  yield takeEvery(BalanceTypes.GET_SUM_WALLETS, getSumWallets);
  yield takeEvery(BalanceTypes.GET_SUM_CRYPTOS_BALANCE, getSumCryptosBalance);
  yield takeEvery(BalanceTypes.GET_SUM_PROFITS_BALANCE, getSumProfitsBalance);
  yield takeEvery(BalanceTypes.GET_CRYPTO_WALLETS, getCryptoWallets);
  yield takeEvery(BalanceTypes.GET_SUM_WALLETS_ROLE, getSumWalletsRole);
  yield takeEvery(
    BalanceTypes.GET_SYSTEM_BALANCE_LOG_TRANSACTIONS,
    getSystemBalanceLogTransactions,
  );
  yield takeEvery(
    BalanceTypes.GET_PROFIT_BALANCE_LOG_TRANSACTIONS,
    getProfitBalanceLogTransactions,
  );
  yield takeEvery(
    BalanceTypes.GET_CRYPTO_BALANCE_LOG_TRANSACTIONS,
    getCryptoBalanceLogTransactions,
  );
  yield takeEvery(
    BalanceTypes.GET_ROLE_BALANCE_LOG_TRANSACTIONS,
    getRoleBalanceLogTransactions,
  );
}

export default systemBalanceSaga;
