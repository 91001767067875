import { useEffect, useState } from 'react';
import 'react-dragswitch/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NewSocialProfile from 'src/components/Publisher/Influencer/ChannelDetail/NewSocialProfile';
import SocialInfluencerProfile from 'src/components/Publisher/Influencer/ChannelDetail/SocialInfluencerMock';
import paramsFromURL from 'src/constants/paramsFromURL';

import {
  getAllAptitude,
  getAllKeywordAptitude,
  getExpiredSocialMediaToken,
  getInfluencerPriceRate,
  getOneSocialMediaInfo,
  setUserProfileSelected,
  clearExpiredSocialMediaToken,
} from 'src/store/actions';
import { getExpiredSocialMediaTokenGql } from 'src/graphql/advertiser/socialMediaInfo';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { initialForm } from './control/formControl';
import onCheckLogoBySocialType from './control/onCheckLogoBySocialType';

const ChannelDetailPage = (params: any) => {
  const dispatch = useDispatch();
  const [paramsKey] = paramsFromURL(['channel']);
  const key = params?.influencerKey || paramsKey;
  const history = useHistory();

  const { userProfile$, userProfileSelected$, socialMediaExpired } = useSelector(
    (state: any) => ({
      userProfile$: state.SocialMediaInfo.socialMediaInfoData,
      userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
      onSocialMediaInfoLoading$: state.SocialMediaInfo.onSocialMediaInfoLoading,
      socialMediaExpired: state.SocialMediaInfo.socialMediaInfoExpiredToken,
    }),
  );
  const [socialMediaLogo, setSocialMediaLogo] = useState();
  const [formValues, setFormValues] = useState<UserSocialProfile>(initialForm);
  const [expireChecked, setexpireChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!key && !userProfileSelected$) {
      history.replace('/influencer/dashboard');
      return;
    }
    dispatch(getAllAptitude());
    dispatch(getAllKeywordAptitude());
    if (!key) return;
    dispatch(setUserProfileSelected(null));
    dispatch(getOneSocialMediaInfo(key));
    // dispatch(getExpiredSocialMediaToken(key));

    const checkTokenExpired = async (key: any) => {
      try {
        const isExpired = await getExpiredSocialMediaTokenGql(key);
        if (isExpired.items.length > 0) {
          setexpireChecked(true);
          return true;
        } else {
          setexpireChecked(false);
          return false;
        }
      } catch (error) {
        console.error('Error:', error);
        setexpireChecked(false);
        return false;
      }
    };
    checkTokenExpired(key);
  }, []);
  useEffect(() => {
    if (!userProfile$) return;
    if (!userProfileSelected$ && Object.keys(userProfile$)?.length) {
      const aptitude_ids = userProfile$?.aptitudes.map(
        (aptitude: any) => aptitude.id,
      );

      const keyword_aptitude_ids = userProfile$?.keyword_aptitudes.map(
        (keyword_aptitude: any) => keyword_aptitude.id,
      );

      setFormValues((current) => ({
        ...current,
        ...userProfile$,
        aptitude_ids: aptitude_ids || [],
        keyword_aptitude_ids: keyword_aptitude_ids || [],
      }));
    }
    const { social_media_type } = userProfile$;

    onCheckLogoBySocialType(social_media_type, setSocialMediaLogo);
  }, [userProfile$]);

  const onUserProfileChange = async () => {
    if (!userProfileSelected$) return;

    const userData = userProfileSelected$;

    if (!userData) return;
    const { social_media_type } = userData;
    setFormValues((current: any) => ({
      ...current,
      ...userData,
    }));
    onCheckLogoBySocialType(social_media_type, setSocialMediaLogo);
  };

  useEffect(() => {
    onUserProfileChange();

    if (!userProfileSelected$) return;
    const mediaType = userProfileSelected$?.social_media_type;

    if (mediaType.includes('_PERSONAL')) {
      dispatch(getInfluencerPriceRate());
    } else {
      dispatch(getInfluencerPriceRate());
    }
  }, [userProfileSelected$]);

  useEffect(() => {
    if (expireChecked) {
      setTimeout(() => {
        notify(
          i18n.t('error:Some access token of your social media were expired.'),
          'warning',
        );
        history.push('/influencer/renew');
      }, 500);
    }
  }, [expireChecked]);

  // useEffect(() => {
  //   console.log('socialMediaExpired-chanelpage', socialMediaExpired?.items?.length);
  //   //เงื่ออนไขผิด socialMediaExpired?.items?.length ที่รับมาผิด
  //   if (socialMediaExpired?.items?.length > 0) {
  //     setTimeout(() => {
  //       notify(
  //         i18n.t('error:Some access token of your social media were expired.'),
  //         'warning',
  //       );
  //       history.push('/influencer/renew');
  //     }, 500);
  //   }
  // }, [socialMediaExpired]);

  switch (true) {
    case !key:
      return (
        <NewSocialProfile
          setFormValues={setFormValues}
          socialMediaLogo={socialMediaLogo}
          formValues={formValues}
        />
      );

    default:
      return (
        <SocialInfluencerProfile
          setFormValues={setFormValues}
          socialMediaLogo={socialMediaLogo}
          formValues={formValues}
          currentKey={key}
        />
      );
  }
};

export default ChannelDetailPage;
