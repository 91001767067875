// import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import Role from 'src/constants/enum/role';
import Warning from 'src/assets/images/other/warning.png';
import advertiserNavData from './navData/advertiserNavData';
import influencerNavData from './navData/influencerNavData';
import marketerNavData from './navData/marketerNavData';
import publisherNavData from './navData/publisherNavData';
import './SubNavBar.style.scss';

const SubNavBar = ({ setRoleSubNavBar, roleSubNavBar }: any) => {
  if (!roleSubNavBar) return <></>;
  // const [suggestJobCount, setSuggestJobCount] = useState(0);
  const data = useSelector((state: any) => ({
    socialmediainfo: state.SocialMediaInfo.socialMediaInfo,
    filter: state.SocialMediaInfo.filter,
    suggestjobs: state?.SuggestJobs?.suggestJobs,
  }));
  const subNavData = () => {
    switch (roleSubNavBar) {
      case Role.ADVERTISER:
        return advertiserNavData;
      case Role.MARKETER:
        return marketerNavData;
      case Role.INFLUENCER:
        return influencerNavData;
      case Role.PUBLISHER:
        return publisherNavData;
      default:
        return {
          role: '',
          title: '',
          items: [],
        };
    }
  };

  const { items } = subNavData();

  const checkAlert = () => {
    if (
      data?.filter?.totalCount > 0 ||
      roleSubNavBar === 'ADVERTISER' ||
      roleSubNavBar === 'MARKETER'
    ) {
      return 'disable-alert';
    }
    return 'alert';
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        style={{
          position: 'absolute',
          height: 'auto',
          width: '600px',
          padding: '30px 20px 10px 20px',
          borderRadius: '10px',
        }}
        className="sub-nav-bar-container"
        onMouseEnter={() => {
          setRoleSubNavBar(roleSubNavBar);
        }}
        onMouseLeave={() => {
          setRoleSubNavBar('');
        }}
      >
        <div
          className={`${
            checkAlert()
            // (data?.filter?.totalCount !== 0 || data?.socialmediainfo?.length < 0) &&
            // 'disable-alert'
          }`}
        >
          <img
            style={{ position: 'absolute', top: '25%', left: '30%' }}
            src={Warning}
            alt="alert"
            width="20px"
            height="20px"
          />
        </div>
        <div
          className={`${
            roleSubNavBar === 'ADVERTISER' ||
            roleSubNavBar === 'MARKETER' ||
            data.suggestjobs.suggestJobs <= 0
              ? 'disable-suggest-job'
              : 'alert-suggest-job'
          }`}
        >
          <span>{data.suggestjobs.suggestJobs}</span>
        </div>
        <div
          className={`${
            (roleSubNavBar === 'ADVERTISER' && 'arrow-up-right-advertiser') ||
            (roleSubNavBar === 'MARKETER' && 'arrow-up-right') ||
            (roleSubNavBar === 'PUBLISHER' && 'arrow-up-right') ||
            (roleSubNavBar === 'INFLUENCER' && 'arrow-up-left')
          }
          }`}
        />
        <div className="sub-nav-data-new">
          {items?.map((item) => (
            <Link to={item.path} className="sub-nav-content" key={item.label}>
              {item.icon && <div className="sub-nav-icon">{item.icon || <></>}</div>}
              <div className="sub-nav-item">
                <h3>{item.label}</h3>
                <p style={{ marginTop: '5px' }}>{item.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default SubNavBar;
