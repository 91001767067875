/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum Status {
  ACTIVE, //green
  PAUSED, //yellow
  OUT_OF_BUDGET, //red
  FINISHED, //green
  DRAFT, //gray
  ARCHIVED, //blue
  CANCEL,
  INPROGRESS,
}

export default Status;
