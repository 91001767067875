import { useSelector } from 'react-redux';

const TermsOfUseControl = () => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  return { layoutMode };
};

export default TermsOfUseControl;
