import { registerThirdParty } from 'src/graphql/common/auth';
import { clearRegisterThirdPartyData, loginSuccess } from 'src/store/actions';

const authByThirdParty = async ({
  data,
  email,
  dispatch,
  history,
  name,
  social_id,
  loginType,
}: any) => {
  const { role, timezone, ref } = data;

  const loginData = await registerThirdParty({
    email,
    role,
    timezone,
    ref: ref || null,
    name: name || null,
    social_id,
    loginType,
  });

  if (loginData) {
    const d = new Date();
    localStorage.setItem('access-token', loginData?.accessToken);
    localStorage.setItem('last-silent-refresh', d.getTime().toString());

    dispatch(loginSuccess(loginData?.user));
    // notify(i18n.t('success:Login Success'), 'success');
    // history.push('/');
    dispatch(clearRegisterThirdPartyData());
  } else {
    history.push('/');
    window.scrollTo(0, 0);
  }
};

export default authByThirdParty;
