const config = {
  appUrl: process.env.REACT_APP_URL,
  appName: process.env.REACT_APP_NAME,
  appEnv: process.env.REACT_APP_ENV,
  firebase: {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
  },
  google: {
    API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    SECRET: process.env.REACT_APP_GOOGLE_SECRET_KEY,
  },
  facebook: {
    APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    disabled: process.env.REACT_APP_DISABLE_FB_CHAT,
    id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  },
  facebookConsumer: {
    id: process.env.REACT_APP_FACEBOOK_CONSUMER_CLIENT_ID,
  },
  facebookLogin: {
    id: process.env.REACT_APP_FACEBOOK_LOGIN_ID,
  },
  graphql: {
    URI: `${process.env.REACT_APP_BACKEND_URL}graphql/`,
  },
  recaptcha: {
    SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITEKEY,
    USE_CAPTCHA: process.env.REACT_APP_USE_CAPTCHA,
  },
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  blogUrl: process.env.REACT_APP_BLOG_URL,
  reactAppAdApiUrl: process.env.REACT_APP_AD_API_URL,
  superadmin: process.env.REACT_APP_SUPER_ADMIN,
  backendSocket: process.env.REACT_APP_BACKEND_WS,
};

export default config;
