import { FaqsTypes } from './actionTypes';

export const getFaqsFilter = (payload: {
  search?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
}) => {
  return {
    type: FaqsTypes.GET_FAQS_FILTER,
    payload,
  };
};

export const getFaqsSearch = (search: string) => {
  return {
    type: FaqsTypes.GET_FAQS_SEARCH,
    payload: search,
  };
};

export const getFaqsFilterSuccess = (faqsFilter: any) => {
  return {
    type: FaqsTypes.GET_FAQS_FILTER_SUCCESS,
    payload: faqsFilter,
  };
};
