import { ApolloClient, InMemoryCache, HttpLink, from, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { some, isEmpty } from 'lodash';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { useHistory } from 'react-router';
import config from '../config';
import notify from '../utils/notify';
import { getErrorMessageByErrorCode } from '../utils/error';
import configureStore from '../store/index';
import { getLoggedInUser, logoutUser } from '../store/auth/login/actions';

const checkLastSilentRefreshPassed = () => {
  const dateTime = new Date();
  const timestamp = dateTime.getTime();
  const lastSilentRefresh = localStorage.getItem('last-silent-refresh') ?? '';

  return (
    lastSilentRefresh &&
    timestamp - parseInt(lastSilentRefresh, 10) >
      // @ts-ignore
      +process.env.REACT_APP_SILENT_REFRESH
  );
};

const customFetch = async (uri: any, options: any) => {
  const accessToken = localStorage.getItem('access-token') ?? '';
  const { operationName } = JSON.parse(options.body);

  const dateTime = new Date();
  const timestamp = dateTime.getTime();
  const preventOperationsName = ['RefreshToken', 'Login', 'Logout'];

  if (!preventOperationsName.includes(operationName)) {
    if (checkLastSilentRefreshPassed()) {
      fetch(uri, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
                            mutation RefreshToken {
                                refreshToken {
                                    accessToken
                                }
                            }
                        `,
        }),
      })
        .then((r) => r.json())
        .then((data) => {
          localStorage.setItem(
            'access-token',
            data?.data?.refreshToken?.accessToken,
          );
          localStorage.setItem('last-silent-refresh', timestamp.toString());
        });
    }
  }
  // eslint-disable-next-line no-param-reassign
  options.headers['Authorization'] = `Bearer ${accessToken}`;

  return fetch(`${uri}/graph/graphql?opname=${operationName}`, options);
};

const httpLink = new HttpLink({
  uri: config.graphql.URI,
  credentials: 'include',
  fetch: customFetch,
});

const accessToken = localStorage.getItem('access-token') ?? '';
const headDomain = config.appEnv === 'local' ? 'http://' : 'https://';
const uri = config.appEnv === 'local' ? 'ws' : 'wss';
const backendHost = config?.backendUrl?.split(headDomain)[1];

// const newWSLink = new WebSocketLink({
//   uri: `${uri}://${backendHost}graphql`,
//   options: {
//     reconnect: true,
//   },
// });

const wsLink = new WebSocketLink(
  new SubscriptionClient(`${uri}://${backendHost}graphql`, {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      authorization: `Bearer ${accessToken}`,
    },
  }),
);

let unAuthenticatedCount = 0;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && unAuthenticatedCount === 0) {
    graphQLErrors.forEach((error: any) => {
      if (error.error_code === '600') return;
      if (!some(error.error_code, isEmpty)) {
        // notify(getErrorMessageByErrorCode(error.error_code), 'error');
        // console.log(error.error_code);
        if (error.error_code === 'UNAUTHENTICATED') {
          notify(getErrorMessageByErrorCode(error.error_code), 'error');
          unAuthenticatedCount += 1;
          const { store } = configureStore({});

          store.dispatch(logoutUser());
        } else if (error.error_code === '1007') {
          notify(getErrorMessageByErrorCode(error.error_code), 'error');
          const { store } = configureStore({});
          //const history = useHistory();
          store.dispatch(getLoggedInUser());
          //history.replace('/');
        } else {
          notify(getErrorMessageByErrorCode(error.error_code), 'error');
        }
      }
    });
  }

  if (networkError) throw new Error(`[Network error]: ${networkError}`);
});
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
// If you provide a link chain to ApolloClient, you
// don't provide the `uri` option.
// eslint-disable-next-line import/prefer-default-export
export const client = new ApolloClient({
  // The `from` function combines an array of individual links
  // into a link chain
  link: from([errorLink, link]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
