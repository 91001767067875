import ForgotPwdTypes from './actionTypes';

const initialState = {
  error: false,
  message: '',
};

const forgotPassword = (state = initialState, action: any) => {
  switch (action.type) {
    case ForgotPwdTypes.FORGOT_PASSWORD:
      return {
        ...state,
        error: false,
        message: '',
      };
    case ForgotPwdTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        message: '',
      };
    case ForgotPwdTypes.FORGOT_PASSWORD_ERROR:
      return { ...state, error: true, message: action.payload };
    case ForgotPwdTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: '',
        error: false,
      };
    case ForgotPwdTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload,
        error: true,
      };
    default:
      return { ...state };
  }
};

export default forgotPassword;
