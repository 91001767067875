import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  CardBody,
  FormGroup,
  Card,
  Popover,
  PopoverBody,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import Swal from 'sweetalert2';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getProfitConfigs, updateConfig } from 'src/store/actions';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import { transformStringAmountInput } from '../../utils/input';

const initialValues = {
  key: '',
  value: '',
};

const valueSchema = Yup.object().shape({
  key: Yup.string().required('Required'),
  value: Yup.number().positive(),
});

const FeeSettingComponent = ({ t }: any) => {
  const dispatch = useDispatch();

  const [popOverIndex, setPopOverIndex] = useState(-1);

  const { configs } = useSelector((state: any) => ({
    configs: state.DefaultValue.systemProfitConfigs,
  }));

  const handleConfigValueChange = (value: any, setFieldValue: any) => {
    setFieldValue('value', transformStringAmountInput(value));
  };

  useEffect(() => {
    dispatch(getProfitConfigs());
  }, []);

  const handleSubmit = (values: any) => {
    Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t('swal:Do you want to update!'),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('swal:Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        // eslint-disable-next-line no-param-reassign
        values.value = parseFloat(values.value.replace(/,/g, '')).toString();

        dispatch(updateConfig(values.key, values.value));
      }
    });
  };

  return (
    <Row>
      {configs &&
        configs.map((config: any, key: number) => {
          return (
            <Col lg={4}>
              <Card>
                <CardBody className="overflow-hidden position-relative">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <h6 className="text-secondary text-center mb-0 me-1">
                        {t(`${splitUnderscoreWithTuple(config.name)}`)}
                      </h6>
                      <i
                        className="mdi mdi-information-outline"
                        id={`pop-${key}`}
                        onClick={() => {
                          setPopOverIndex(key);
                        }}
                      />
                    </div>
                    <Popover
                      placement="top"
                      isOpen={popOverIndex === key}
                      target={`pop-${key}`}
                      toggle={() => {
                        setPopOverIndex(-1);
                      }}
                    >
                      <PopoverBody>{t(`${config.name}_DESC`)}</PopoverBody>
                    </Popover>{' '}
                    <Formik
                      initialValues={{
                        ...initialValues,
                        key: config.name,
                        value: config.value,
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={valueSchema}
                      render={({ errors, touched, setFieldValue }) => (
                        <FormikForm>
                          <Row className="d-flex justify-content-center">
                            <Field name="key" type="hidden" id="key" />
                            <Col md="8">
                              <FormGroup className="mb-3">
                                <div className="input-group">
                                  <Field
                                    name="value"
                                    type="text"
                                    id="value"
                                    className={`form-control form-control-lg${
                                      errors.value && touched.value && 'is-invalid '
                                    } `}
                                    onChange={(event: any) => {
                                      handleConfigValueChange(
                                        event.currentTarget.value,
                                        setFieldValue,
                                      );
                                    }}
                                  />
                                  <div className="input-group-text">%</div>
                                  <ErrorMessage
                                    name="url"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={8}>
                              <div className="d-grid">
                                <button
                                  className="btn btn-primary btn-sm"
                                  type="submit"
                                >
                                  {t('button:Update')}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </FormikForm>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default withTranslation()(FeeSettingComponent);
