import MarketerCampaignTypes from './actionTypes';

export const INIT_STATE = {
  marketerCampaign: {},
  filter: {},
  error: {},
  loading: false,
};

const MarketerMarkCampaigns = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case MarketerCampaignTypes.GET_MARKETER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        marketerCampaign: action.payload,
      };
    case MarketerCampaignTypes.UPDATE_MARKETER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        marketerCampaign: action.payload,
      };
    case MarketerCampaignTypes.CREATE_MARKETER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        marketerCampaign: action.payload,
      };

    case MarketerCampaignTypes.FILTER_MARKETER_CAMPAIGN_SUCCESS:
      if (action.campaignType === 'PUBLISH') {
        return {
          ...state,
          filter: { ...action.payload, isPublish: true },
        };
      }
      return {
        ...state,
        filter: { ...action.payload, isPublish: false },
      };

    default:
      return state;
  }
};

export default MarketerMarkCampaigns;
