import { InviteOnlyType } from './actionTypes';

export const getAllInviteOnlyTransactionsRequest = (payload: {
  limit: number;
  page: number;
  search?: string;
  userId?: string;
  isCampaign: boolean;
  jobType: string;

  // sortBy?: string;
  // sortType?: string;
}) => {
  return {
    type: InviteOnlyType.GET_ALL_INVITE_ONLY_TRANSACTIONS_REQUEST,
    payload: { ...payload },
  };
};

export const getAllInviteOnlyTransactionsRequestSuccess = (transactions: any) => {
  return {
    type: InviteOnlyType.GET_ALL_INVITE_ONLY_TRANSACTIONS_REQUEST_SUCCESS,
    payload: { ...transactions },
  };
};

export const getAllInviteOnlyTransactionsRequestFail = (errorMessage: any) => {
  return {
    type: InviteOnlyType.GET_ALL_INVITE_ONLY_TRANSACTIONS_REQUEST_FAIL,
    payload: errorMessage,
  };
};
