import {
  Col,
  Row,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changePasswordRequest } from '../../../../store/auth/changepassword/actions';
import PasswordCheckList from '../../PasswordCheckList';
import PasswordField from '../../PasswordField';
import i18n from '../../../../i18n';
import './ChangePasswordForm.style.scss';
import CustomButton from '../../CustomButton';
import notify from '../../../../utils/notify';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const withdrawSchema = Yup.object().shape({
  currentPassword: Yup.string().required(i18n.t('invalid.Required')),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"#'(),./;:<=>[\]^_`{|}~\\])[A-Za-z\d@$!%*?&"#'(),./:;<=>[\]^_`{|}~\\]{8,}$/,
      ' ',
    )
    .required('invalid.Password is required'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'invalid.Password must match')
    .required('invalid.Password Confirmation is required'),
});

const ChangePasswordForm = ({ t, history }: any) => {
  const dispatch = useDispatch();

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    if (values.currentPassword === values.newPassword) {
      notify(i18n.t('New password must not match with an old password'), 'error');
    } else {
      setSubmitting(true);
      const data = {
        ...values,
      };
      resetForm();
      dispatch(changePasswordRequest(data, history));
    }
  };

  return (
    <>
      <Card className="change-password-form-container">
        <CardHeader>
          <CardTitle className="mb-0">
            <h5
              className="m-0"
              style={{ fontSize: '16px', padding: '10px 10px 10px 15px' }}
            >
              {t('Page.Change Password')}
            </h5>
          </CardTitle>
        </CardHeader>
        <CardBody className="card-body">
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={onSubmit}
            validationSchema={withdrawSchema}
            render={({ values }) => (
              <FormikForm>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label
                        htmlFor="currentPassword"
                        className="input-label fs-6"
                        style={{ color: 'white' }}
                      >
                        {t('Form.Current Password')}
                      </Label>
                      <PasswordField
                        name="currentPassword"
                        placeholder={t('Form.Current Password Placeholder')}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label
                        htmlFor="newPassword"
                        className="input-label fs-6"
                        style={{ color: 'white' }}
                      >
                        {t('Form.New Password')}
                      </Label>
                      <PasswordField
                        name="newPassword"
                        placeholder={t('Form.New Password Placeholder')}
                      />

                      <PasswordCheckList password={values.newPassword} />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label
                        htmlFor="newPasswordConfirmation"
                        className="input-label fs-6"
                        style={{ color: 'white' }}
                      >
                        {t('Form.New Password Confirmation')}
                      </Label>

                      <PasswordField
                        name="newPasswordConfirmation"
                        placeholder={t('Form.New Password Confirmation Placeholder')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <CustomButton
                    size="mdp"
                    status="submit"
                    label={t('Button.Submit')}
                  />
                </div>
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default withTranslation()(withRouter(ChangePasswordForm));
