/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum WebsiteTypes {
  /** Get Website */
  GET_WEBSITE = '@@website/GET_WEBSITE',
  GET_WEBSITE_SUCCESS = '@@website/GET_WEBSITE_SUCCESS',
  GET_WEBSITE_FAIL = '@@website/GET_WEBSITE_FAIL',

  GET_INFO_WEBSITE = '@@website/GET_INFO_WEBSITE',
  GET_INFO_WEBSITE_SUCCESS = '@@website/GET_INFO_WEBSITE_SUCCESS',
  GET_INFO_WEBSITE_FAIL = '@@website/GET_INFO_WEBSITE_FAIL',

  GET_WEBSITE_REPORT = '@@website/GET_WEBSITE_REPORT',
  GET_WEBSITE_REPORT_SUCCESS = '@@website/GET_WEBSITE_REPORT_SUCCESS',
  GET_WEBSITE_REPORT_FAIL = '@@website/GET_WEBSITE_REPORT_FAIL',

  CREATE_WEBSITE = '@@website/CREATE_WEBSITE',
  CREATE_WEBSITE_SUCCESS = '@@website/CREATE_WEBSITE_SUCCESS',
  CREATE_WEBSITE_FAIL = '@@website/CREATE_WEBSITE_FAIL',

  UPDATE_WEBSITE_STATUS = '@@website/UPDATE_WEBSITE_STATUS',
  UPDATE_WEBSITE_STATUS_SUCCESS = '@@website/UPDATE_WEBSITE_STATUS_SUCCESS',
  UPDATE_WEBSITE_STATUS_FAIL = '@@website/UPDATE_WEBSITE_STATUS_FAIL',

  GET_REQUEST_SIZE = '@@website/GET_REQUEST_SIZE',
  GET_REQUEST_SIZE_SUCCESS = '@@website/GET_REQUEST_SIZE_SUCCESS',
  GET_REQUEST_SIZE_FAIL = '@@website/GET_REQUEST_SIZE_FAIL',

  CREATE_REQUEST_SIZE = '@@website/CREATE_REQUEST_SIZE',
  CREATE_REQUEST_SIZE_SUCCESS = '@@website/CREATE_REQUEST_SIZE_SUCCESS',
  CREATE_REQUEST_SIZE_FAIL = '@@website/CREATE_REQUEST_SIZE_FAIL',

  GET_EARNING_TRANSACTION = '@@website/GET_EARNING_TRANSACTION',
  GET_EARNING_TRANSACTION_SUCCESS = '@@website/GET_EARNING_TRANSACTION_SUCCESS',
  GET_EARNING_TRANSACTION_FAIL = '@@website/GET_EARNING_TRANSACTION_FAIL',

  GET_ADMIN_EARNING_TRANSACTION = '@@website/GET_ADMIN_EARNING_TRANSACTION',
  GET_ADMIN_EARNING_TRANSACTION_SUCCESS = '@@website/GET_ADMIN_EARNING_TRANSACTION_SUCCESS',
  GET_ADMIN_EARNING_TRANSACTION_FAIL = '@@website/GET_ADMIN_EARNING_TRANSACTION_FAIL',

  GET_WEBSITE_TRANSACTION_LOG = '@@website/GET_WEBSITE_TRANSACTION_LOG',
  GET_WEBSITE_TRANSACTION_LOG_SUCCESS = '@@website/GET_WEBSITE_TRANSACTION_LOG_SUCCESS',

  GET_ALL_EARNING_TRANSACTION = '@@website/GET_ALL_EARNING_TRANSACTION',
  GET_ALL_EARNING_TRANSACTION_SUCCESS = '@@website/GET_ALL_EARNING_TRANSACTION_SUCCESS',
  GET_ALL_EARNING_TRANSACTION_FAIL = '@@website/GET_ALL_EARNING_TRANSACTION_FAIL',

  GET_WEBSITE_TYPES = '@@website/GET_WEBSITE_TYPES',
  GET_WEBSITE_TYPES_SUCCESS = '@@website/GET_WEBSITE_TYPES_SUCCESS',
  GET_WEBSITE_TYPES_FAIL = '@@website/GET_WEBSITE_TYPES_FAIL',

  GET_WEBSITE_TRANSACTION_BY_DATE = '@@website/GET_WEBSITE_TRANSACTION_BY_DATE',
  GET_WEBSITE_TRANSACTION_BY_DATE_SUCCESS = '@@website/GET_WEBSITE_TRANSACTION_BY_DATE_SUCCESS',
  GET_WEBSITE_TRANSACTION_BY_DATE_FAIL = '@@website/GET_WEBSITE_TRANSACTION_BY_DATE_FAIL',

  GET_PENDING_APPROVAL = '@@website/GET_PENDING_APPROVAL',
  GET_PENDING_APPROVAL_SUCCESS = '@@website/GET_PENDING_APPROVAL_SUCCESS',
  GET_PENDING_APPROVAL_FAIL = '@@website/GET_PENDING_APPROVA_FAILL',

  GET_WEBSITE_TYPES_WEBSITE = '@@website/GET_WEBSITE_TYPES',
  GET_WEBSITE_TYPES_WEBSITE_SUCCESS = '@@website/GET_WEBSITE_TYPES_WEBSITE_SUCCESS',
  GET_WEBSITE_TYPES_WEBSITE_FAIL = '@@website/GET_WEBSITE_TYPES_WEBSITE_FAIL',

  GET_WEBSITE_GRAPH_DATUM = '@@website/GET_WEBSITE_GRAPH_DATUM',
  GET_WEBSITE_GRAPH_DATUM_SUCCESS = '@@website/GET_WEBSITE_GRAPH_DATUM_SUCCESS',
}

export interface WebsiteState {
  website: Array<any>;
  websiteInfo: Array<any>;
  websiteReport: Array<any>;
  website_type: Array<any>;
  website_types_by_website: Array<any>;
  earningTransaction: Array<any>;
  adminEarningTransaction: Array<any>;
  websiteTransaction: Array<any>;
  websiteTransaction_by_date: Array<any>;
  allEarningTransaction: Array<any>;
  requestSize: Array<any>;
  websiteGraphDatum: Array<any>;
  pending_approval: Array<any>;
  error: Object;
}
