import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import config from 'src/config';
import Role from 'src/constants/enum/role';
import { layoutTheme } from 'src/constants/layout';
import useWindowSize from 'src/utils/windowSize';
import { useEffect } from 'react';
import logo from '../../../assets/images/flupower/footpagelogo.png';
import logoWhite from '../../../assets/images/flupower/logo-headpage.png';
import '../auth.style.scss';
import RegisterByEmail from './RegisterByEmail';
import RegisterByThirdParty from './RegisterByThirdParty';

const RegisterForm = ({
  setRole,
  role,
  path,
}: {
  setRole: any;
  role: Role | null;
  path: any;
}) => {
  const { t } = useTranslation();
  const { layoutMode } = useSelector((state: any) => state.Layout);
  const { width } = useWindowSize();
  useEffect(() => {
    if (role === Role.INFLUENCER) {
      setRole(Role.PUBLISHER);
    }
    if (role === Role.MARKETER) {
      setRole(Role.ADVERTISER);
    }
  }, [role]);
  return (
    <Col
      lg={4}
      md={5}
      className="auth-by-email-container"
      style={{ backgroundColor: 'white' }}
    >
      <Link to="/" className="d-block auth-logo">
        <span
          className="material-symbols-rounded"
          style={{ fontSize: '38px', color: 'black' }}
        >
          arrow_left_alt
        </span>
      </Link>

      <div className="mb-1 text-center">
        <Link to="/" className="d-block auth-logo">
          <img
            src={layoutMode === layoutTheme.DARKMODE ? logoWhite : logo}
            alt="Flupower"
            height="80"
          />
        </Link>
      </div>

      <div className="auth-content my-auto">
        <div className="text-center">
          <h5>{t('Page.Register Account')}</h5>
          <p
            className="text-muted"
            style={width <= 768 ? { marginTop: '-0.3rem' } : {}}
          >
            {t('Page.Register Account With', {
              name: config.appName,
            })}
          </p>
        </div>

        <RegisterByThirdParty role={role} path={path} />

        <div className="needs-validation custom-form mt-4 pt-2">
          <RegisterByEmail role={role} />
        </div>
        <div className="mt-3" style={{ textAlign: 'center' }}>
          <p className="text-muted mb-0">
            {t('Form.Have an account')}
            &nbsp;
            <Link
              to={`/login/${role}`}
              className="text-primary fw-semibold text-black"
            >
              {t('Button.Login')}
            </Link>
          </p>
        </div>
      </div>
    </Col>
  );
};

export default RegisterForm;
