import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getSocialMediaInfoGql,
  renewSocialMediaInfoAccessToken,
} from 'src/graphql/advertiser/socialMediaInfo';
import i18n from 'src/i18n';
import {
  getExpiredSocialMediaToken,
  getSocialMediaInfo,
  setUserProfileList,
  setUserProfileSelected,
} from 'src/store/actions';
import notify from 'src/utils/notify';

const RenewListControl = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userProfileList$, socialMediaInfo } = useSelector((state: any) => ({
    userProfileList$: state.CalculateSocialMedia.userProfileList,
    socialMediaInfo: state.SocialMediaInfo.socialMediaInfo,
  }));

  const [renewUserProfileList, setRenewUserProfileList] = useState(userProfileList$);

  const onSelectUserProfile = async (input: any) => {
    const payload = [];
    const _input = input ?? renewUserProfileList;
    const _renewUserProfileList = await getAndSetRenewUserProfileList(_input);
    //const _renewUserProfileList = input != null ? input : renewUserProfileList;
    for (let i = 0; i < _renewUserProfileList?.length; i++) {
      const renewUer = _renewUserProfileList[i];
      let { social_media_type } = renewUer;
      if (social_media_type?.includes('_PERSONAL')) {
        [social_media_type] = social_media_type.split('_PERSONAL');
      }
      payload.push({
        social_media_type,
        name: renewUer.name,
        follower: renewUer.fan_count,
        post_engagement: null,
        job_score: null,
        rating: null,
        like: null,
        share_content_price: null,
        photo_content_price: null,
        video_content_price: null,
        introduce: renewUer.introduce,
        aptitude_ids: null,
        keyword_aptitude_ids: null,
        image_profile_url: renewUer.picture_url,
        access_token: renewUer.access_token,
        access_token_secret: renewUer?.access_token_secret,
        expire_access_token: renewUer?.expire_access_token,
        is_enable_share_price: null,
        is_enable_photo_price: null,
        is_enable_video_price: null,
        social_id: renewUer.id,
        profile_path: renewUer.profile_path,
      });
    }

    const res = await renewSocialMediaInfoAccessToken(payload);
    dispatch(setUserProfileList([]));
    if (res) {
      if (res.is_success === true) {
        notify(i18n.t(res.message), 'success');
      } else {
        notify(i18n.t(res.message), 'error');
      }
    }
    //history.push('/influencer/dashboard');
  };

  // Constructor
  useEffect(() => {
    dispatch(setUserProfileSelected(null));
    //dispatch(getSocialMediaInfo());
  }, []);

  useEffect(() => {
    if (socialMediaInfo?.length > 0) {
      const social_ids = socialMediaInfo.map((social: any) => {
        return social.social_id;
      });
      const social_names = socialMediaInfo.map((social: any) => {
        return social.name;
      });

      const social_emails = socialMediaInfo.map((social: any) => {
        return social.user.email;
      });

      const existUserProfileList = [];
      for (let i = 0; i < userProfileList$?.length; i++) {
        if (social_ids.includes(userProfileList$[i].id)) {
          existUserProfileList.push(userProfileList$[i]);
        } else if (social_emails.includes(userProfileList$[i].email)) {
          existUserProfileList.push(userProfileList$[i]);
        } else if (social_names.includes(userProfileList$[i].name)) {
          existUserProfileList.push(userProfileList$[i]);
        }
      }
      setRenewUserProfileList(existUserProfileList);
      //onSelectUserProfile(existUserProfileList);
    }
  }, [socialMediaInfo]);

  const getAndSetRenewUserProfileList = async (input: any) => {
    const social_media_infos = await getSocialMediaInfoGql();
    const social_ids = social_media_infos.map((social: any) => {
      return social.social_id;
    });
    const social_names = social_media_infos.map((social: any) => {
      return social.name;
    });

    const social_emails = social_media_infos.map((social: any) => {
      return social.user.email;
    });

    const existUserProfileList = [];
    for (let i = 0; i < input?.length; i++) {
      if (social_ids.includes(input[i].id)) {
        existUserProfileList.push(input[i]);
      } else if (social_emails.includes(input[i].email)) {
        existUserProfileList.push(input[i]);
      } else if (social_names.includes(input[i].name)) {
        existUserProfileList.push(input[i]);
      }
    }
    console.log('existUserProfileList', existUserProfileList);
    setRenewUserProfileList(existUserProfileList);
    return existUserProfileList;
  };

  return { onSelectUserProfile, renewUserProfileList };
};

export default RenewListControl;
