import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'react-table';
import {
  FormGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllAdminApproveLog,
  getWebsites,
  updateWebsiteStatus,
} from 'src/store/actions';
import WithdrawStatus from 'src/components/Common/WithdrawStatus';
import Swal from 'sweetalert2';
import { unsetLoading } from 'src/store/global/actions';
import withdrawStatuses from 'src/constants/withdrawStatuses';
import { Link } from 'react-router-dom';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBar';
import WebsiteDetail from './Website/WebsiteDetail';
import Dropdown from '../CommonForBoth/Menus/Dropdown';

const ApproveWebsite = ({ t }: any) => {
  const [modalStandard, setmodalStandard] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [website, setWebsite] = useState({
    id: '',
    status: '',
    category: '',
  });
  const [status, setStatus] = useState('');
  const isValid = true;
  const [nameSearch, setNameSearch] = useState('');
  const [sortType, setSortType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [websiteDetail, setWebsiteDetail] = useState();

  const dispatch = useDispatch();

  const { WebsiteList } = useSelector((state: any) => ({
    WebsiteList: state.Websitelist.website,
  }));

  const Status = [
    {
      value: '0',
      label: t('table:PENDING'),
    },
    { value: '1', label: t('table:APPROVED') },
    { value: '2', label: t('table:REJECTED') },
    { value: '', label: t('All') },
  ];

  const Website_data = WebsiteList.items;

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('WebsiteURL'),
        accessor: ({ url }) => {
          return (
            <div
              style={{
                // width: 100,
                paddingLeft: 50,
                textAlign: 'left',
              }}
            >
              {url}
            </div>
          );
        },
        id: 'url',
      },
      {
        Header: t('Status'),
        accessor: ({ status: approveStatus }: any) => {
          return <WithdrawStatus status={approveStatus} />;
        },
        id: 'status',
      },
      {
        Header: t('WebsiteAdded'),
        accessor: ({ created_at }: any) => {
          return new Date(created_at).toLocaleDateString();
        },
        id: 'created_at',
      },
      {
        Header: t('table:Action'),
        accessor: (originalRow) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="mdi mdi-dots-vertical" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-start">
                  <>
                    <Link to={`/admin/users/${originalRow.user.uuid}`}>
                      <DropdownItem>
                        <i className="fas fa-user" />
                        &nbsp;&nbsp;
                        {t('table:User Detail')}
                      </DropdownItem>
                    </Link>
                    <div className="dropdown-divider" />
                  </>
                  {originalRow.status !== withdrawStatuses.PENDING && (
                    <DropdownItem
                      onClick={() =>
                        onSubmit(originalRow.id, originalRow.url, 'PENDING')
                      }
                      className="text-warning"
                    >
                      <i className="mdi mdi-account-clock text-warning" />
                      &nbsp;&nbsp;{t('table:PENDING')}
                    </DropdownItem>
                  )}
                  {originalRow.status !== withdrawStatuses.APPROVED && (
                    <DropdownItem
                      onClick={() =>
                        onSubmit(originalRow.id, originalRow.url, 'APPROVED')
                      }
                      className="text-success"
                    >
                      <i className="mdi mdi-account-check text-success" />
                      &nbsp;&nbsp;{t('table:Approve')}
                    </DropdownItem>
                  )}
                  {originalRow.status !== withdrawStatuses.REJECTED && (
                    <DropdownItem
                      onClick={() =>
                        onSubmit(originalRow.id, originalRow.url, 'REJECTED')
                      }
                      className="text-danger"
                    >
                      <i className="mdi mdi-account-cancel text-danger" />
                      &nbsp;&nbsp;{t('table:Reject')}
                    </DropdownItem>
                  )}

                  <div className="dropdown-divider" />

                  <DropdownItem
                    onClick={() => {
                      handleGetDetail();
                      setWebsiteDetail(originalRow);
                      setWebsite({
                        id: originalRow.id,
                        status: originalRow.status,
                        category: '',
                      });
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('table:Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'action',
        disableSortBy: true,
      },
    ],
    [t, page, modalStandard],
  );

  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = ['col-2'];
  const hiddenOnLG: any = [];

  const tog_standard = () => setmodalStandard(!modalStandard);

  const handleGetDetail = () => {
    setmodalStandard(true);
  };

  const handlePageChange = (event: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      limit: pageSize,
      page: newPage,
      status,
    };

    dispatch(getWebsites(payload));
    setPage(newPage);
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.currentTarget.value, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      limit: newPageSize,
      page: newPage,
      status,
    };

    dispatch(getWebsites(payload));

    setPageSize(newPageSize);
    setPage(newPage);
  };

  const handleStatusChange = (event: any) => {
    const newStatus = event.value;
    const payload = {
      limit: pageSize,
      page,
      url: nameSearch,
      status: newStatus,
    };

    dispatch(getWebsites(payload));
    setStatus(newStatus);
  };

  const handleSearch = (val: string) => {
    const payload = {
      limit: pageSize,
      page: 1,
      url: val,
      status,
    };
    dispatch(getWebsites(payload));
    setNameSearch(nameSearch);
  };

  const handleClear = () => {
    const payload = {
      limit: pageSize,
      page,
      search: '',
    };

    dispatch(getWebsites(payload));
    setNameSearch('');
  };

  const onSubmit = async (
    website_id: number,
    url: string,
    approveStatus: string,
  ) => {
    if (isValid) {
      if (website.category !== 'Empty') {
        Swal.fire({
          title: t('swal:Are you sure'),
          imageUrl: choice,
          width: 300,
          imageHeight: 200,
          imageAlt: 'A tall image',
          customClass: {},
          cancelButtonColor: 'red',
          showCancelButton: true,
          cancelButtonText: t('button:No'),
          confirmButtonText: t('button:Yes'),
          confirmButtonColor: '#009a78',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(updateWebsiteStatus({ id: website_id, status: approveStatus }));
            dispatch(getWebsites({ page: 1, limit: 10 }));
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            dispatch(unsetLoading());
          }
        });
      } else {
        Swal.fire({
          title: t('swal:Are you sure'),
          text: 'Category is empty',
          imageUrl: choice,
          width: 300,
          imageHeight: 200,
          imageAlt: 'A tall image',
          customClass: {},
          cancelButtonColor: 'red',
          showCancelButton: true,
          confirmButtonText: t('button:Yes'),
          cancelButtonText: t('button:No'),
          confirmButtonColor: '#009a78',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(updateWebsiteStatus({ id: website_id, status: approveStatus }));
            dispatch(getWebsites({ page: 1, limit: 10 }));
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            dispatch(unsetLoading());
          }
        });
      }
    }
  };

  const getDefaultPayload = (newPage: number) => {
    setPage(newPage);

    return {
      limit: pageSize,
      page,
      status,
      nameSearch,
    };
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getWebsites(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [nameSearch, status, page, pageSize],
  );
  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('publisher_website');
    dispatch(getAllAdminApproveLog());
  };
  useEffect(() => {
    fetchApproveLog();
    dispatch(getWebsites({ page: 1, limit: 10 }));
    return () => {};
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: '0 10px',
        }}
      >
        <FormGroup className="flex-grow-1 flex-md-grow-0">
          <select className="form-control" onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </FormGroup>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '150px', marginRight: '20px' }}>
            <FormGroup>
              <Dropdown
                options={Status}
                action={handleStatusChange}
                default_value={Status[3]}
              />
            </FormGroup>
          </div>
          <SearchBar
            placeholder={t('Form.Search URL')}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
        </div>
      </div>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={Website_data || []}
        page={page}
        pageSize={pageSize}
        total={WebsiteList.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <Modal
          isOpen={modalStandard}
          toggle={() => {
            tog_standard();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('Website Detail')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodalStandard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <WebsiteDetail Webdata={websiteDetail} />
          </div>
        </Modal>
      </CustomTable>
    </>
  );
};

export default withTranslation()(ApproveWebsite);
