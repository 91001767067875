/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum CampaignsTypes {
  GET_CAMPAIGNS = '@@campaign/GET_CAMPAIGNS',
  GET_CAMPAIGNS_SUCCESS = '@@campaign/GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAIL = '@@campaign/GET_CAMPAIGNS_FAIL',

  GET_CAMPAIGN_TODAY_TOTAL = '@@campaign/GET_CAMPAIGN_TODAY_TOTAL',
  GET_CAMPAIGN_TODAY_TOTAL_SUCCESS = '@@campaign/GET_CAMPAIGN_TODAY_TOTAL_SUCCESS',

  GET_CAMPAIGNS_FILTER = '@@campaign/GET_CAMPAIGNS_FILTER',
  GET_CAMPAIGNS_FILTER_SUCCESS = '@@campaign/GET_CAMPAIGNS_FILTER_SUCCESS',

  GET_CAMPAIGN = '@@campaign/GET_CAMPAIGN',
  GET_CAMPAIGN_SUCCESS = '@@campaign/GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAIL = '@@campaign/GET_CAMPAIGN_FAIL',

  GET_CAMPAIGN_REPORT = '@@campaign/GET_CAMPAIGN_REPORT',
  GET_CAMPAIGN_REPORT_SUCCESS = '@@campaign/GET_CAMPAIGN_REPORT_SUCCESS',
  GET_CAMPAIGN_REPORT_FAIL = '@@campaign/GET_CAMPAIGN_REPORT_FAIL',

  CAMPAIGN_EXCLUDE_WEBSITES = '@@campaign/CAMPAIGN_EXCLUDE_WEBSITES',
  CAMPAIGN_EXCLUDE_WEBSITES_SUCCESS = '@@campaign/CAMPAIGN_EXCLUDE_WEBSITES_SUCCESS',

  GET_CAMPAIGN_STATUS_SUMMARY = '@@campaign/GET_CAMPAIGN_STATUS_SUMMARY',
  GET_CAMPAIGN_STATUS_SUMMARY_SUCCESS = '@@campaign/GET_CAMPAIGN_STATUS_SUMMARY_SUCCESS',

  GET_SUM_DAILY_CURRENT_BUDGET = '@@campaign/GET_SUM_DAILY_CURRENT_BUDGET',
  GET_SUM_DAILY_CURRENT_BUDGET_SUCCESS = '@@campaign/GET_SUM_DAILY_CURRENT_BUDGET_SUCCESS',
}

export interface CampaignState {
  campaigns: Array<any>;
  campaignsByUserId: Array<any>;
  campaign: Object;
  campaignExcludeWebsites: Array<any>;
  campaignReport: Object;
  transactions: Array<any>;
  SumDailyCurrentBudget: number;
  todayTotal: Object;
  statusSummary: Object;
  error: Object;
  loading: Boolean;
}
