import { TaskTypesTypes } from './actionTypes';

const initialState = {
  task_types: [],
};

const TaskTypes = (state = initialState, action: any) => {
  switch (action.type) {
    case TaskTypesTypes.GET_TASKTYPE_GROUP_SUCCESS:
      return {
        ...state,
        task_types: action.payload,
      };
    default:
      return state;
  }
};

export default TaskTypes;
