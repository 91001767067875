import { TFunction, withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Role from 'src/constants/enum/role';
import { useHistory } from 'react-router';
import notify from 'src/utils/notify';
import { getExpiredSocialMediaToken } from 'src/store/socialMediaInfo/actions';
import config from '../../../../config';
import SocialChannelComponent from '../../../../components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.component';
import OverviewComponent from '../../../../components/Publisher/Influencer/Dashboard/Overview/Overview.component';

import './InfluencerDashboard.style.scss';
import controls from './InfluencerDashboard.control';

interface props {
  t: TFunction;
}
const InfluencerDashboardPage = ({ t }: props) => {
  controls();
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, socialMediaExpired } = useSelector((state: any) => ({
    user: state.login.user,
    socialMediaExpired: state.SocialMediaInfo.socialMediaInfoExpiredToken,
  }));

  // useEffect(() => {
  //   if (user?.role && user?.role === Role.PUBLISHER) {
  //     dispatch(getExpiredSocialMediaToken(null));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (user?.role && user?.role === Role.PUBLISHER) {
  //     // if (!socialMediaExpired) {
  //     //   dispatch(getExpiredSocialMediaToken(null));
  //     // }
  //     if (socialMediaExpired?.items?.length > 0) {
  //       setOpen(true);
  //       setTimeout(() => {
  //         notify(
  //           t('error:Some access token of your social media were expired.'),
  //           'warning',
  //         );
  //         history.push('/influencer/renew');
  //       }, 500);
  //       //dispatch(getExpiredSocialMediaToken(null));
  //     }
  //   }
  // }, [socialMediaExpired]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {t('Page.influencer_dashboard')} | {config.appName}
          </title>
        </MetaTags>
        {/* <BackButtonTopLeftComponent /> */}
        <div className="content-container">
          <div className="overview-container">
            <OverviewComponent />
          </div>
          <div className="social-channel-container">
            <SocialChannelComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(InfluencerDashboardPage);
