import { gql } from '@apollo/client';
import { client } from '../client';

// eslint-disable-next-line no-unused-vars
const generateTwoFactorSecret = async (data: any) => {
  const GENERATE_TWO_FACTOR_SECRET = gql`
    query GenerateTwoFactorSecret {
      generateTwoFactorSecret {
        secret
        otpauthUrl
      }
    }
  `;

  const response: any = await client.query({
    query: GENERATE_TWO_FACTOR_SECRET,
    fetchPolicy: 'no-cache',
  });

  return response.data.generateTwoFactorSecret;
};

const enableTwoFactor = async (code: string, secret: string) => {
  const ENABLE_TWO_FACTOR = gql`
    mutation EnableTwoFactor($code: String!, $secret: String!) {
      enableTwoFactor(input: { code: $code, secret: $secret })
    }
  `;

  return client.mutate({
    mutation: ENABLE_TWO_FACTOR,
    variables: {
      code,
      secret,
    },
    errorPolicy: 'all',
  });
};

const disableTwoFactorRequest = async (code: string) => {
  const DISABLE_TWO_FACTOR = gql`
    mutation DisableTwoFactorRequest($code: String!) {
      disableTwoFactorRequest(input: { code: $code })
    }
  `;

  return client.mutate({
    mutation: DISABLE_TWO_FACTOR,
    variables: {
      code,
    },
    errorPolicy: 'all',
  });
};

const disableTwoFactorConfirm = async (data: any) => {
  const DISABLE_TWO_FACTOR_CONFIRM = gql`
    mutation DisableTwoFactorConfirm($data: DisableTwoFactorConfirmInput!) {
      disableTwoFactorConfirm(input: $data)
    }
  `;

  return client.mutate({
    mutation: DISABLE_TWO_FACTOR_CONFIRM,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });
};

const getTwoFactorStatus = async () => {
  const GET_TWO_FACTOR_STATUS = gql`
    query GetTwoFactorStatus {
      getTwoFactorStatus {
        enableStatus
        requestDisableStatus
      }
    }
  `;

  const response: any = await client.query({
    query: GET_TWO_FACTOR_STATUS,
    fetchPolicy: 'no-cache',
  });

  return response.data.getTwoFactorStatus;
};

// eslint-disable-next-line import/prefer-default-export
export {
  generateTwoFactorSecret,
  enableTwoFactor,
  disableTwoFactorRequest,
  getTwoFactorStatus,
  disableTwoFactorConfirm,
};
