import { Col, Row } from 'reactstrap';
import AddCampaignWidget from 'src/components/Advertiser/Widgets/AddCampaignWidget';
import CampaignSummaryWidget from 'src/components/Advertiser/Widgets/CampaignSummaryWidget';
import CampaignTotalWidget from 'src/components/Advertiser/Widgets/CampaignTotalWidget';
import PerformanceSummary from 'src/components/Advertiser/Widgets/PerformanceSummary';
import WalletWidget from 'src/components/Common/WalletWidget';
import addSocial from 'src/assets/images/other/add-social.svg';
import './template.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import Wallet from 'src/assets/images/wallet.png';
import influ from 'src/assets/images/comimg-soon.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Template = () => {
  const userName = 'Tinnaphob';
  const money = 10;
  const walletSymbol = 'USDT';
  const { t } = useTranslation();

  return (
    <div className="page-content" style={{ paddingTop: '4vh' }}>
      <div className="dashboard-wraper ">
        <Breadcrumb
          pageTitle={t('Template')}
          menus={[
            { menu: menu.HOME, active: true },
            {
              menu: menu.TEMPLATE,
              isSamePage: true,
            },
          ]}
        />

        <div className="maketer-template-card-wraper ">
          <Link to="/campaigns/jobnew/addnewjob" style={{ alignItems: 'center' }}>
            <div className="card-template" style={{ background: '#53c6b7' }}>
              <div
                style={{
                  height: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <i
                  className="fa fa-plus-circle"
                  style={{
                    color: 'white',
                    fontSize: '100px',
                    margin: 'auto',
                  }}
                />
              </div>
              <div style={{ color: 'white' }}>Add Custom Campaign</div>
            </div>
          </Link>
          <div className="card-template ">
            <img
              className="template-img "
              src={influ}
              alt="Influencer"
              width={280}
              height={200}
              style={{ border: '2px #e9edee solid' }}
            />
            <div>Coming Soon</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
