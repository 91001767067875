import WAValidator from 'wallet-address-validator';
import TronValidator from 'tron-wallet-hd';
import CurrenciesSymbol from '../constants/currenciesSymbol';

// eslint-disable-next-line import/prefer-default-export
export function validateAddress(address, symbol) {
  let isValid;

  switch (symbol) {
    case CurrenciesSymbol.CAS:
    case CurrenciesSymbol.WIS_ERC20:
    case CurrenciesSymbol.USDT_ERC20:
    case CurrenciesSymbol.USDT_BEP20:
    case CurrenciesSymbol.ETH:
      isValid = WAValidator.validate(address, CurrenciesSymbol.ETH);
      break;
    case CurrenciesSymbol.USDT_TRC20:
    case CurrenciesSymbol.WIS_TRC20:
      isValid = TronValidator.utils.validateAddress(address);
      break;
    default:
      isValid = WAValidator.validate(address, CurrenciesSymbol.ETH);
  }

  return isValid;
}

export function transformAddressInShortFrom(address, count) {
  return `${address.slice(0, count)}...${address.slice(-count)}`;
}
