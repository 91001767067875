const ReactFlagsSelectCountry = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia, Plurinational State of',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Democratic Republic of the Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  PF: 'French Polynesia',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Republic of Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Republic of Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela, Bolivarian Republic of',
  VN: 'Viet Nam',
  VI: 'Virgin Islands',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

const FullNameToShortName: any = {
  Afghanistan: 'AF',
  Albania: 'AL',
  Algeria: 'DZ',
  'American Samoa': 'AS',
  Andorra: 'AD',
  Angola: 'AO',
  Anguilla: 'AI',
  'Antigua and Barbuda': 'AG',
  Argentina: 'AR',
  Armenia: 'AM',
  Aruba: 'AW',
  Australia: 'AU',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  'Bolivia, Plurinational State of': 'BO',
  'Bosnia and Herzegovina': 'BA',
  Botswana: 'BW',
  Brazil: 'BR',
  'British Indian Ocean Territory': 'IO',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Cambodia: 'KH',
  Cameroon: 'CM',
  Canada: 'CA',
  'Cape Verde': 'CV',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  China: 'CN',
  Colombia: 'CO',
  Comoros: 'KM',
  Congo: 'CG',
  'Democratic Republic of the Congo': 'CD',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  "Côte d'Ivoire": 'CI',
  Croatia: 'HR',
  Cuba: 'CU',
  Curaçao: 'CW',
  Cyprus: 'CY',
  'Czech Republic': 'CZ',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Ethiopia: 'ET',
  'Falkland Islands (Malvinas)': 'FK',
  'Faroe Islands': 'FO',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  'French Polynesia': 'PF',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Greece: 'GR',
  Greenland: 'GL',
  Grenada: 'GD',
  Guam: 'GU',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Haiti: 'HT',
  Honduras: 'HN',
  'Hong Kong': 'HK',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  'Iran, Islamic Republic of': 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jersey: 'JE',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  'North Korea': 'KP',
  'South Korea': 'KR',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  "Lao People's Democratic Republic": 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Macao: 'MO',
  'Republic of Macedonia': 'MK',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  'Marshall Islands': 'MH',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mexico: 'MX',
  'Micronesia, Federated States of': 'FM',
  'Republic of Moldova': 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Netherlands: 'NL',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: 'NU',
  'Norfolk Island': 'NF',
  'Northern Mariana Islands': 'MP',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  'Palestinian Territory': 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Pitcairn: 'PN',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Romania: 'RO',
  Russia: 'RU',
  Rwanda: 'RW',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  Samoa: 'WS',
  'San Marino': 'SM',
  'Sao Tome and Principe': 'ST',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Singapore: 'SG',
  'Sint Maarten': 'SX',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Islands': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  Sudan: 'SD',
  Suriname: 'SR',
  Swaziland: 'SZ',
  Sweden: 'SE',
  Switzerland: 'CH',
  Syria: 'SY',
  Taiwan: 'TW',
  Tajikistan: 'TJ',
  Tanzania: 'TZ',
  Thailand: 'TH',
  Togo: 'TG',
  Tokelau: 'TK',
  Tonga: 'TO',
  'Trinidad and Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  Turkmenistan: 'TM',
  'Turks and Caicos Islands': 'TC',
  Tuvalu: 'TV',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  'United States': 'US',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Vanuatu: 'VU',
  'Venezuela, Bolivarian Republic of': 'VE',
  'Viet Nam': 'VN',
  'Virgin Islands': 'VI',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
};

const shortPhoneDigit: any = {
  '+1': [
    'us',
    'ag',
    'ai',
    'as',
    'bb',
    'bm',
    'bs',
    'ca',
    'dm',
    'do',
    'gd',
    'gu',
    'jm',
    'kn',
    'ky',
    'lc',
    'mp',
    'ms',
    'pr',
    'sx',
    'tc',
    'tt',
    'vc',
    'vg',
    'vi',
  ],
  '+7': ['ru', 'kz'],
  '+20': ['eg'],
  '+27': ['za'],
  '+30': ['gr'],
  '+31': ['nl'],
  '+32': ['be'],
  '+33': ['fr'],
  '+34': ['es'],
  '+36': ['hu'],
  '+39': ['it', 'va'],
  '+40': ['ro'],
  '+41': ['ch'],
  '+43': ['at'],
  '+44': ['gb', 'gg', 'im', 'je'],
  '+45': ['dk'],
  '+46': ['se'],
  '+47': ['no', 'sj'],
  '+48': ['pl'],
  '+49': ['de'],
  '+51': ['pe'],
  '+52': ['mx'],
  '+53': ['cu'],
  '+54': ['ar'],
  '+55': ['br'],
  '+56': ['cl'],
  '+57': ['co'],
  '+58': ['ve'],
  '+60': ['my'],
  '+61': ['au', 'cc', 'cx'],
  '+62': ['id'],
  '+63': ['ph'],
  '+64': ['nz'],
  '+65': ['sg'],
  '+66': ['th'],
  '+81': ['jp'],
  '+82': ['kr'],
  '+84': ['vn'],
  '+86': ['cn'],
  '+90': ['tr'],
  '+91': ['in'],
  '+92': ['pk'],
  '+93': ['af'],
  '+94': ['lk'],
  '+95': ['mm'],
  '+98': ['ir'],
  '+211': ['ss'],
  '+212': ['ma', 'eh'],
  '+213': ['dz'],
  '+216': ['tn'],
  '+218': ['ly'],
  '+220': ['gm'],
  '+221': ['sn'],
  '+222': ['mr'],
  '+223': ['ml'],
  '+224': ['gn'],
  '+225': ['ci'],
  '+226': ['bf'],
  '+227': ['ne'],
  '+228': ['tg'],
  '+229': ['bj'],
  '+230': ['mu'],
  '+231': ['lr'],
  '+232': ['sl'],
  '+233': ['gh'],
  '+234': ['ng'],
  '+235': ['td'],
  '+236': ['cf'],
  '+237': ['cm'],
  '+238': ['cv'],
  '+239': ['st'],
  '+240': ['gq'],
  '+241': ['ga'],
  '+242': ['cg'],
  '+243': ['cd'],
  '+244': ['ao'],
  '+245': ['gw'],
  '+246': ['io'],
  '+247': ['ac'],
  '+248': ['sc'],
  '+249': ['sd'],
  '+250': ['rw'],
  '+251': ['et'],
  '+252': ['so'],
  '+253': ['dj'],
  '+254': ['ke'],
  '+255': ['tz'],
  '+256': ['ug'],
  '+257': ['bi'],
  '+258': ['mz'],
  '+260': ['zm'],
  '+261': ['mg'],
  '+262': ['re', 'yt'],
  '+263': ['zw'],
  '+264': ['na'],
  '+265': ['mw'],
  '+266': ['ls'],
  '+267': ['bw'],
  '+268': ['sz'],
  '+269': ['km'],
  '+290': ['sh', 'ta'],
  '+291': ['er'],
  '+297': ['aw'],
  '+298': ['fo'],
  '+299': ['gl'],
  '+350': ['gi'],
  '+351': ['pt'],
  '+352': ['lu'],
  '+353': ['ie'],
  '+354': ['is'],
  '+355': ['al'],
  '+356': ['mt'],
  '+357': ['cy'],
  '+358': ['fi', 'ax'],
  '+359': ['bg'],
  '+370': ['lt'],
  '+371': ['lv'],
  '+372': ['ee'],
  '+373': ['md'],
  '+374': ['am'],
  '+375': ['by'],
  '+376': ['ad'],
  '+377': ['mc'],
  '+378': ['sm'],
  '+380': ['ua'],
  '+381': ['rs'],
  '+382': ['me'],
  '+383': ['xk'],
  '+385': ['hr'],
  '+386': ['si'],
  '+387': ['ba'],
  '+389': ['mk'],
  '+420': ['cz'],
  '+421': ['sk'],
  '+423': ['li'],
  '+500': ['fk'],
  '+501': ['bz'],
  '+502': ['gt'],
  '+503': ['sv'],
  '+504': ['hn'],
  '+505': ['ni'],
  '+506': ['cr'],
  '+507': ['pa'],
  '+508': ['pm'],
  '+509': ['ht'],
  '+590': ['gp', 'bl', 'mf'],
  '+591': ['bo'],
  '+592': ['gy'],
  '+593': ['ec'],
  '+594': ['gf'],
  '+595': ['py'],
  '+596': ['mq'],
  '+597': ['sr'],
  '+598': ['uy'],
  '+599': ['cw', 'bq'],
  '+670': ['tl'],
  '+672': ['nf'],
  '+673': ['bn'],
  '+674': ['nr'],
  '+675': ['pg'],
  '+676': ['to'],
  '+677': ['sb'],
  '+678': ['vu'],
  '+679': ['fj'],
  '+680': ['pw'],
  '+681': ['wf'],
  '+682': ['ck'],
  '+683': ['nu'],
  '+685': ['ws'],
  '+686': ['ki'],
  '+687': ['nc'],
  '+688': ['tv'],
  '+689': ['pf'],
  '+690': ['tk'],
  '+691': ['fm'],
  '+692': ['mh'],
  '+850': ['kp'],
  '+852': ['hk'],
  '+853': ['mo'],
  '+855': ['kh'],
  '+856': ['la'],
  '+880': ['bd'],
  '+886': ['tw'],
  '+960': ['mv'],
  '+961': ['lb'],
  '+962': ['jo'],
  '+963': ['sy'],
  '+964': ['iq'],
  '+965': ['kw'],
  '+966': ['sa'],
  '+967': ['ye'],
  '+968': ['om'],
  '+970': ['ps'],
  '+971': ['ae'],
  '+972': ['il'],
  '+973': ['bh'],
  '+974': ['qa'],
  '+975': ['bt'],
  '+976': ['mn'],
  '+977': ['np'],
  '+992': ['tj'],
  '+993': ['tm'],
  '+994': ['az'],
  '+995': ['ge'],
  '+996': ['kg'],
  '+998': ['uz'],
};

export { ReactFlagsSelectCountry, FullNameToShortName, shortPhoneDigit };
