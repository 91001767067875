import { gql } from '@apollo/client';

import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

export const createMarketerCampaignGql = async (param: any) => {
  const mutation = gql`
    mutation createMarketerCampaign($param: CreateMarketerCampaignInput!) {
      createMarketerCampaign(createMarketerCampaignInput: $param) {
        key
        name
        brand_name
        product_name
        product_detail
        marketerCategories {
          name
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    notify(i18n.t('success:Campaign has been created'), 'success');
    return res.data.createMarketerCampaign;
  } catch (error) {
    notify(i18n.t('error:Campaign has not been created'), 'error');
    return null;
  }
};

export const getMarketerCampaignsGql = async (key: string) => {
  const query = gql`
    query getMarketerCampaign($key: String!) {
      getMarketerCampaign(key: $key) {
        name
        brand_name
        product_name
        product_detail
        key
        marketerCategories {
          id
        }
      }
    }
  `;

  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getMarketerCampaign;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get marketer campaign error .'), 'error');
    return null;
  }
};

export const filterQql = async (param: any) => {
  const query = gql`
    query MarketerCampaignFilterTable($param: FilterMarketerCampaignInput!) {
      MarketerCampaignFilterTable(filterMarketerCampaignInput: $param) {
        items {
          id
          name
          brand_name
          num_job
          key
          status
          jobs {
            name
            social_media_has_jobs {
              price
              social_media_info {
                name
                follower
              }
            }
          }
        }
        totalCount
        statusCount {
          status
          count
        }
      }
    }
  `;

  try {
    const res = await client.query({
      query,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.MarketerCampaignFilterTable;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get marketer campaign error .'), 'error');
    return null;
  }
};
export const updateMarketerCampaignGql = async (param: any) => {
  const mutation = gql`
    mutation updateMarketerCampaign($param: UpdateMarketerCampaignInput!) {
      updateMarketerCampaign(updateMarketerCampaignInput: $param) {
        key
        name
        brand_name
        product_name
        product_detail
        marketerCategories {
          id
        }
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.updateMarketerCampaign;
  } catch (error) {
    return null;
  }
};
