import { call, put, takeEvery } from 'redux-saga/effects';
import { suggestAndOtherJobCountGql } from '../../graphql/common/index';

import { getAllSuggestJobFail, getAllSuggestJobSuccess } from './actions';
import SuggestJob from './actionTypes';

function* getSuggestJob() {
  try {
    const response: Promise<any> = yield call(suggestAndOtherJobCountGql);
    yield put(getAllSuggestJobSuccess(response));
  } catch (error) {
    yield put(getAllSuggestJobFail(null));
  }
}

function* suggestJob() {
  yield takeEvery(SuggestJob.GET_ALL_SUGGESS_JOB, getSuggestJob);
}

export default suggestJob;
