import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Default Translation
import translationENG from './locales/eng/translation.json';
import translationTH from './locales/th/translation.json';

// Success
import successENG from './locales/eng/success.json';
import successTH from './locales/th/success.json';

// Error
import errorENG from './locales/eng/error.json';
import errorTH from './locales/th/error.json';

// Sweet Alert
import swalENG from './locales/eng/swal.json';
import swalTH from './locales/th/swal.json';

// Button
import buttonENG from './locales/eng/button.json';
import buttonTH from './locales/th/button.json';

// Page
import pageENG from './locales/eng/page.json';
import pageTH from './locales/th/page.json';

// Menu
import menuENG from './locales/eng/menu.json';
import menuTH from './locales/th/menu.json';

// CustomTable
import tableENG from './locales/eng/table.json';
import tableTH from './locales/th/table.json';

// Creative
import creativeTH from './locales/th/creative/translation.json';
import enumCreativeTH from './locales/th/creative/enum.json';
import tableCreativeTH from './locales/th/creative/table.json';

// Campaign
import campaignTH from './locales/th/campaign/translation.json';
import enumCampaignTH from './locales/th/campaign/enum.json';

// Enum
import enumTH from './locales/th/enum.json';
import enumENG from './locales/eng/enum.json';

// Influencer
import influencerTH from './locales/th/influencer/influencer.json';
import influencerEN from './locales/eng/influencer/influencer.json';

// Marketer
import marketerTH from './locales/th/marketer/marketer.json';
import marketerEN from './locales/eng/marketer/marketer.json';

import aptitudeTH from './locales/th/aptitude/aptitude.json';

// the translations
const resources: any = {
  en: {
    translation: translationENG,
    success: successENG,
    error: errorENG,
    swal: swalENG,
    button: buttonENG,
    page: pageENG,
    menu: menuENG,
    table: tableENG,
    enum: enumENG,
    influencer: influencerEN,
    marketer: marketerEN,
  },
  th: {
    translation: translationTH,
    success: successTH,
    error: errorTH,
    swal: swalTH,
    button: buttonTH,
    page: pageTH,
    menu: menuTH,
    table: tableTH,
    enum: enumTH,
    creative: creativeTH,
    enumCreative: enumCreativeTH,
    tableCreative: tableCreativeTH,
    campaign: campaignTH,
    enumCampaign: enumCampaignTH,
    influencer: influencerTH,
    marketer: marketerTH,
    aptitude: aptitudeTH,
  },
};

const language: any = localStorage.getItem('I18N_LANGUAGE');
if (!language) {
  localStorage.setItem('I18N_LANGUAGE', 'en');
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('I18N_LANGUAGE') || 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
