import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Breadcrumb from '../../components/Common/Breadcrumb';
import menu from '../../constants/menu';
import { getUserDetail } from '../../store/users/actions';
import { roleText as role } from '../../constants/role';
import AdvertiserDetailComponent from '../../components/Admin/AdvertiserDetailComponent';
import PublisherDetailComponent from '../../components/Admin/PublisherDetailComponent';
import AdminDetailComponent from '../../components/Admin/AdminDetailComponent';

const UserDetailPage = ({ t }: any) => {
  const dispatch = useDispatch();
  const { uuid }: any = useParams();

  useEffect(() => {
    dispatch(getUserDetail({ id: uuid }));
  }, [uuid]);

  const { userDetail } = useSelector((state: any) => ({
    userDetail: state.Users.userDetail,
  }));

  const renderUserDetailsComponent = () => {
    switch (userDetail?.role) {
      case role.ADVERTISER:
        return <AdvertiserDetailComponent />;
      case role.PUBLISHER:
        return <PublisherDetailComponent />;
      case role.ADMIN:
        return <AdminDetailComponent />;
      default:
        return <AdminDetailComponent />;
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Form Validation | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            pageTitle={t('User Detail')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.USER_DETAIL, active: true, id: uuid },
            ]}
          />

          <Row>
            <Col lg={12}>{renderUserDetailsComponent()}</Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(UserDetailPage);
