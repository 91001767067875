import React from 'react';

const flutterDoc = () => {
  return (
    <>
      {/* +++++++++++++++++ Prerequisites +++++++++++++++++ */}
      <h3>Prerequisites</h3>
      <ul>
        <li>Flutter 1.17.0 or higher</li>
        <li>Android</li>
        <ul>
          <li>Android Studio 4.2 or higher</li>
          <li>Target Android API level 19 or higher</li>
          <li>Set compileSdkVersion to 28 or higher</li>
        </ul>
        <li>IOS</li>
        <ul>
          <li>Latest version of Xcode with enabled command-line tools</li>
        </ul>
      </ul>
      <ul>
        <li>
          Recommended:&nbsp;
          <a href="/register" target="_blank" rel="noreferrer">
            Create Flupower account ( Publisher )
          </a>
          &nbsp;and&nbsp;
          <a href="/application/create" target="_blank" rel="noreferrer">
            add an Android / iOS app
          </a>
        </li>
      </ul>
      {/* +++++++++++++++++ Set up app +++++++++++++++++ */}
      <h3>Set up app in Flupower</h3>
      <ol style={{ marginBottom: 5 }}>
        <li>
          Sign in to your Flupower account at{' '}
          <a href="https://Flupower.com/" target="_blank" rel="noreferrer">
            https://Flupower.com/
          </a>
        </li>
        <li>
          Click <p style={{ fontWeight: 'bold', margin: 0 }}>My application</p> in
          the side bar
        </li>
        <li>
          Click <p style={{ fontWeight: 'bold', margin: 0 }}>Add application</p>
        </li>
        <li>Enter your app name</li>
        <li>Select platform of your app (Android / iOS)</li>
        <li>
          Select <p style={{ fontWeight: 'bold', margin: 0 }}>Yes</p> if your app is
          publish to app store or play store
        </li>
        <li>Submit</li>
      </ol>
      <h3>Import the Mobile Ads SDK</h3>
      <ul>
        <li>
          Include the <mark>Flupower Mobile Ads SDK for Flutter plugin</mark> in your
          Flutter project.
        </li>
      </ul>
      <br />
      <h3>Initialize the Mobile Ads SDK</h3>
      <div style={{ backgroundColor: '#283142' }}>
        <pre style={{ padding: 20 }}>
          <code style={{ color: '#FFFFFF' }}>
            AFB(appkey: example, adkey: example)
          </code>
        </pre>
      </div>
    </>
  );
};

export default flutterDoc;
