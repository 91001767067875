import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DisableJobEdit } from 'src/graphql/advertiser/job';
import {
  getDefaultWebsite,
  getMarketerJobSummary,
  getUserInfo,
  updateMarketerJob,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import notify from 'src/utils/notify';
import UserInfoFillModal from 'src/components/Common/UserInfoFillModal';
import { Card, Progress } from 'reactstrap';
import stepCreate from 'src/assets/images/step-create.png';
import stepSummary from 'src/assets/images/step-summary.png';
import stepChooseInfu from 'src/assets/images/step-choose-infu.png';
import 'src/pages/Advertiser/Marketer/jobSummary/jobSummary.style.scss';
import { formatDateForm } from 'src/utils/dateTime';
import date_step1 from 'src/assets/images/date_step1.png';
import date_step7 from 'src/assets/images/date_step7.png';
import date_step2 from 'src/assets/images/date_step2.png';
import date_step3 from 'src/assets/images/date_step3.png';
import date_step4 from 'src/assets/images/date_step4.png';
import date_step5 from 'src/assets/images/date_step5.png';
import date_step6 from 'src/assets/images/date_step6.png';
import choice from 'src/assets/images/choice.png';
import CustomButton from 'src/components/Common/CustomButton';
import { floorToFixed } from 'src/common/data/mathToFixed';
import paramsFromURL from 'src/constants/paramsFromURL';
import config from 'src/config';
import moment from 'moment';
import styleComponent from './jobSummary.style';
import '../MarketerJobCampaignDetail/stepper.style.scss';
import MockJobRequirementTable from './mockJobRequirementTable';

const { Texts, FlexRow, FlexCol } = styleComponent();

const JobSummary = () => {
  const dispatch = useDispatch();
  const [jobId]: any = paramsFromURL(['job']);

  const { t }: any = useTranslation();
  const history = useHistory();
  const { data, defaultTax, influencerData } = useSelector((state: any) => ({
    data: state.MarketerJob.marketerJob,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    influencerData: state.Users.userInfo,
    // marketerJobNew: state.MarketerCampaignJobsNew.marketerJobNew,
  }));

  const userInfo = useSelector((state: any) => state.Users.userInfo);
  const [open, setOpen] = useState<boolean>(false);
  const [switchCheck, setSwitchCheck] = useState(true);
  const tax = defaultTax / 100 + 1;
  const haveDataInDataBase = userInfo ? Object.keys(userInfo)?.length > 1 : true;
  const workOrderRef = useRef<HTMLDivElement>(null);

  const checkUserInfo = () => {
    setTimeout(() => {
      notify(t('error:please fill your information.'), 'warning');
      history.push('/profile');
    }, 500);
  };
  useEffect(() => {
    dispatch(getDefaultWebsite());
    dispatch(getUserInfo());
    dispatch(getMarketerJobSummary(jobId));
  }, [jobId]);

  // useEffect(() => {
  //   const values = localStorage.getItem('marketerJobNew');
  //   if (values) {
  //     dispatch(createMarketerJobNew(JSON.parse(values)));
  //   }
  // }, [marketerJobNew?.job_type]);

  useEffect(() => {
    if (workOrderRef.current) {
      workOrderRef.current.innerHTML = data?.work_order;
    }
    if (data) {
      if (data.status === 'PAUSE') {
        setSwitchCheck(false);
      } else {
        setSwitchCheck(true);
      }
    }
  }, [workOrderRef.current, data]);

  if (!data) return <></>;

  const price = data.budget;
  const { follower } = data;
  // for (let i = 0; i < data?.social_media_has_jobs?.length; i++) {
  //   const social_job = data?.social_media_has_jobs[i];
  //   //let value = 0;
  //   // if (data?.content_type === 'SHARE')
  //   //   value = social_job?.social_media_info?.share_content_price || 0;
  //   // if (data?.content_type === 'PHOTO')
  //   //   value = social_job?.social_media_info?.photo_content_price || 0;
  //   // if (data?.content_type === 'VIDEO')
  //   //   value = social_job?.social_media_info?.video_content_price || 0;
  //   //price += value * tax || 0;
  //   price += social_job.price + social_job.tax || 0;
  //   follower += social_job?.social_media_info?.follower || 0;
  // }

  const setPauseStatusOn = () => {
    const payload = {
      key: jobId,
      status: 'INPROGRESS',
    };
    dispatch(updateMarketerJob(payload));
  };
  const setPauseStatusOff = () => {
    const payload = {
      key: jobId,
      status: 'PAUSE',
    };
    dispatch(updateMarketerJob(payload));
  };

  let timelineViewData = [
    {
      date: data?.date_submission,
      dataString: 'send job to influencer',
      icon: date_step1,
    },
    {
      date: data?.last_day_accept_job,
      dataString: 'influencer accept job',
      icon: date_step7,
    },
    {
      date: data?.last_day_influencer_submit,
      dataString: 'Last day influencer submit work for review',
      icon: date_step3,
    },
    {
      date: data?.last_day_to_approve,
      dataString: 'Last day review and approve',
      icon: date_step4,
    },
    {
      date: data?.influencer_post_internal_job,
      dataString: 'influencer submit job',
      icon: date_step5,
    },
    {
      date: data?.finish_internal_work,
      dataString: 'state completed',
      icon: date_step6,
    },
  ];

  const insert = (arr: any, index: number, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  if (data?.job_type === 'PUBLISH') {
    timelineViewData = insert(timelineViewData, 2, {
      date: data?.last_day_approve_job,
      dataString: 'The last day marketer confirm job.',
      icon: date_step2,
    });
  }

  const stepDisplay = () => {
    return (
      <div className="stepper-container">
        <>
          <span className="stepper-span">
            <img src={stepCreate} alt="stepCreate" className="stepper-img" />
          </span>
          <div className="stepper-dot-active" />
        </>
        <>
          <span className="stepper-span">
            <img
              src={stepChooseInfu}
              alt="stepChooseInfluencer"
              className="stepper-img-influencer"
            />
          </span>
          <div className="stepper-dot-active" />
        </>
        <span className="stepper-active-span">
          <img src={stepSummary} alt="stepSummary" className="stepper-active-img" />
        </span>
      </div>
    );
  };

  if (!data) return <></>;

  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');
  const timezone = moment.tz.guess();
  return (
    <div className="page-content">
      {!data.isDisableEdit && data && (
        <>
          <div className="stepper-progressbar-container">
            <div style={{ marginBottom: '38px' }}> {stepDisplay()}</div>
            <Progress
              style={{
                transform: 'scale(0.8)',
                height: '25px',
                width: '100%',
                borderRadius: '44px',
                background: '#D9D9D9',
              }}
              max="3"
              value="3"
            />
            <div
              style={{
                marginTop: '24px',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '20px',
                letterSpacing: '0em',
                color: '#495057',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              STEP 5 : {t('marketer:job_detail:Recheck your job details')}
            </div>
          </div>
        </>
      )}

      <div>
        <div className="influencer-summary-container">
          <div className="job-summary-card" style={{ padding: '1rem 1.5rem' }}>
            <div className="title-head">
              {t('marketer:job_detail:Campaign name')}
            </div>

            <div className="detail-text">{data?.marketer_campaign?.name}</div>

            <div className="title-head">
              {t('marketer:create_campaign:Brand name')}
            </div>
            <div className="detail-text">{data?.marketer_campaign?.brand_name}</div>
            <div className="title-head">{t('marketer:job_detail:Product name')}</div>
            <div className="detail-text">
              {data?.marketer_campaign?.product_name}
            </div>

            <>
              <div className="title-head">
                {t('marketer:create_job_campaign:Product detail')}
              </div>
              <div
                className="detail-text"
                dangerouslySetInnerHTML={{
                  __html: data?.marketer_campaign?.product_detail,
                }}
              />
            </>
            <>
              <div className="title-head">{t('marketer:job_detail:Job_name')}</div>

              <div className="detail-text">{data?.name}</div>
            </>
            {data?.thumbnail_cover && (
              <>
                <div className="title-head" style={{ marginBottom: '0.5rem' }}>
                  {t('marketer:job_detail:Product Image')}
                </div>

                <img
                  src={`${config.backendUrl}api/jobThumbnail/${data?.thumbnail_cover}`}
                  alt={data?.thumbnail_cover}
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    height: 'auto',
                  }}
                />
              </>
            )}
            {data?.link_share && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:create_job_campaign:Link_share')}
                </div>

                <div className="detail-text">{data?.link_share}</div>
              </>
            )}
            {data?.work_order && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head" style={{ marginTop: '0.5rem' }}>
                  {t('marketer:create_job_campaign:work_order')}
                </div>
                <div
                  className="text-editor-display"
                  style={{ wordBreak: 'break-all', color: 'white' }}
                >
                  <div ref={workOrderRef} />
                </div>
              </>
            )}
            <>
              {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
              <div className="title-head" style={{ marginTop: '0.5rem' }}>
                {t('marketer:job_detail:#Hashtag')}
              </div>

              <div className="detail-text" style={{ color: 'white' }}>
                {data?.hashtag}
              </div>
            </>
            {data?.message_to_influencer && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:create_job_campaign:Message_to_influencer')}
                </div>

                <div className="detail-text">{data?.message_to_influencer}</div>
              </>
            )}
            {data?.target_group && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:create_job_campaign:target_group')}
                </div>

                <div className="detail-text">{data?.target_group}</div>
              </>
            )}
            {data?.highlight_of_product && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:create_job_campaign:Highlights_product')}
                </div>
                <div className="detail-text">{data?.highlight_of_product}</div>
              </>
            )}
            {data?.link_accompanying_work && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:job_detail:link_accompanying_work')}
                </div>

                <a
                  href={data?.link_accompanying_work}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.link_accompanying_work}
                </a>
              </>
            )}
            {data?.forbidden_phrase && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:job_detail:word_forbidden')}
                </div>

                <div className="detail-text">{data?.forbidden_phrase}</div>
              </>
            )}
            {data?.market_challenge && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:create_job_campaign:Market_Challenges')}
                </div>

                <div className="detail-text">{data?.market_challenge}</div>
              </>
            )}
            {data?.brands_prohibit && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:job_detail:brands_prohibit')}
                </div>

                <div className="detail-text">{data?.brands_prohibit}</div>
              </>
            )}
            {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
            <div className="title-head" style={{ marginTop: '1rem' }}>
              {t('marketer:job_detail:Job timeline')}
            </div>
            <div className="time-line-div">
              <div className="timeline">
                {timelineViewData.map((item) => {
                  return (
                    <div className="timeline-container" key={item.dataString}>
                      <Card className="timeline-content">
                        <img
                          src={item.icon}
                          alt={item.icon}
                          style={{ width: '80px', height: '80px' }}
                        />
                        <div className="icon-group-time-line">
                          <h5 style={{ color: 'white' }}>
                            {t(`marketer:job_detail:${item.dataString}`)}
                          </h5>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '0.5rem',
                            }}
                          >
                            <Card className="card-date-container-selected">
                              <label
                                className="card-date-selected-label"
                                htmlFor="date-select"
                              >
                                {formatDateForm(item?.date)}
                              </label>
                            </Card>
                            <Card
                              style={{
                                width: '50px',
                                height: '30px',
                                background: '#FFFFFF',
                                border: '2px solid #21603E',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '14px',
                                cursor: 'no-drop',
                              }}
                            >
                              <label
                                style={{
                                  marginBottom: '0',
                                  fontSize: '16px',
                                  cursor: 'no-drop',
                                  color: '#21603E',
                                }}
                                htmlFor="calendar"
                              >
                                {' '}
                                <i className="fas fa-calendar-alt" />
                              </label>
                            </Card>
                            <span
                              style={{
                                fontSize: '16px',
                                color: 'white',
                                marginLeft: '15px',
                                marginBottom: '17px',
                              }}
                            >
                              {currentTimeZone}
                              <span style={{ visibility: 'hidden' }}>
                                {timezone}
                              </span>
                            </span>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              style={{
                // borderTop: '1px solid #C9DDDD',
                width: '100%',
                // margin: '2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'white',
              }}
            >
              {data?.job_type !== 'PUBLISH' ? (
                <FlexCol width="60%" margin="1rem">
                  <FlexRow
                    marginTop="10px"
                    width="80%"
                    height="100%"
                    margin="auto"
                    border="2px solid #EBF6F6"
                    borderRadius="20px 20px 0  0 "
                    justifycontent="space-between"
                  >
                    <Texts fontsize="1rem" margin="0.5rem 1.5rem">
                      <i className="fas fa-chalkboard-teacher" />{' '}
                      {t('marketer:create_job_campaign:Influencer')} :
                    </Texts>
                    <Texts fontsize="1rem" margin="0.5rem 1.5rem">
                      {data?.totalInfluencer}
                    </Texts>
                  </FlexRow>
                  <FlexRow
                    marginTop="0"
                    width="80%"
                    height="100%"
                    margin="auto"
                    border="2px solid #EBF6F6"
                    justifycontent="space-between"
                  >
                    <Texts fontsize="1rem" margin="0.5rem 1.5rem">
                      <i className="fas fa-users" />{' '}
                      {t('marketer:create_job_campaign:Follower')} :{' '}
                    </Texts>
                    <Texts fontsize="1rem" margin="0.5rem 1.5rem">
                      {follower}
                    </Texts>
                  </FlexRow>
                  <FlexRow
                    width="80%"
                    height="100%"
                    margin="0 auto 10px auto"
                    border="2px solid #EBF6F6 "
                    borderRadius="0  0 20px 20px"
                    justifycontent="space-between"
                  >
                    <Texts fontsize="1rem" margin="0.5rem 1.5rem">
                      <i className="fas fa-coins" />{' '}
                      {t('marketer:create_job_campaign:Price')} :{' '}
                    </Texts>
                    <Texts fontsize="1rem" margin="0.5rem 1.5rem" color="#009A78">
                      <div style={{ color: '#009A78' }}>
                        {floorToFixed(price, 2)}{' '}
                        {data?.currency?.main_symbol ?? 'USDT'}
                      </div>
                    </Texts>
                  </FlexRow>
                </FlexCol>
              ) : (
                <MockJobRequirementTable requirements={data?.job_requirement} />
              )}

              {!data.isDisableEdit && data && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <UserInfoFillModal open={open} setOpen={setOpen} />
                  <div
                    style={{ marginBottom: '1rem' }}
                    onClick={async () => {
                      if (haveDataInDataBase === true) {
                        await Swal.fire({
                          width: 300,
                          title: t('swal:Are you sure ?'),
                          text: t("swal:You won't be able to revert this!"),
                          imageUrl: choice,
                          imageHeight: 200,
                          imageAlt: 'A tall image',
                          customClass: {},
                          cancelButtonColor: 'red',
                          showCancelButton: true,
                          confirmButtonColor: '#009a78',
                          confirmButtonText: t('button:Yes'),
                          cancelButtonText: t('swal:Cancel'),
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            const res = await DisableJobEdit(jobId);

                            if (res) {
                              window.location.replace(
                                `/Marketer/JobDetail?job=${jobId}`,
                              );
                              // localStorage.removeItem('marketerJobNew');
                              // localStorage.removeItem('descriptionJobNew');
                              // if (data?.job_type === 'PUBLISH') {
                              //   history.push(
                              //     `/MarketerPublicCampaign?campaign=${data?.marketer_campaign?.key}`,
                              //   );
                              // } else {
                              //   history.push(
                              //     `/MarketerCampaign?campaign=${data?.marketer_campaign?.key}`,
                              //   );
                              //   history.push(`/Marketer/JobDetail?job=${jobId}`);
                              // }
                            }
                          }
                        });
                      } else {
                        checkUserInfo();
                      }
                    }}
                  >
                    <CustomButton
                      size="md"
                      status="submit"
                      label={t('marketer:job_detail:Confirm and Submit')}
                    />
                  </div>
                </div>
              )}
            </div>
            {data?.job_type === 'PUBLISH' ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  color: 'white',
                }}
              >
                <div>{t('Close sign up from influencer')}</div>
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    checked={switchCheck}
                    onChange={() => {
                      if (switchCheck === false) {
                        setSwitchCheck(true);
                        // setPauseStatusOff();
                        setPauseStatusOn();
                      }
                      if (switchCheck === true) {
                        setSwitchCheck(false);
                        setPauseStatusOff();
                      }
                    }}
                  />
                  <span style={{ fontSize: '16px' }}>
                    {' '}
                    {switchCheck === true ? 'On' : 'Off'}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSummary;
