// Authentication pages
import authByFacebook from 'src/components/Authentication/ThirdParty/authByFacebook';
import authByGoogle from 'src/components/Authentication/ThirdParty/authByGoogle';
import Banned from 'src/components/Banned.component';
import Discover from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/Discover';
import DiscoverAll from 'src/pages/Advertiser/Marketer/CreateMarketerCampaign/DiscoverAll';
import JobSummary from 'src/pages/Advertiser/Marketer/jobSummary/jobSummary';
import ExampleInfluencerWork from 'src/pages/ExampleInfluencerWork';
import JobDetail from 'src/pages/mock/jobDetail';
import MockJobRequirement from 'src/pages/mock/mockJobRequirement/mockJobRequirement';
import ChannelDetailPage from 'src/pages/Publisher/Influencer/ChannelDetail/ChannelDetail.component';
import RemoveData from 'src/pages/RemoveData';
import ChatWraper from 'src/components/Common/ChatWraper';
import GleamJobReviewPage from 'src/components/Common/GleamJobReviewPage';
import { roleText } from '../constants/role';
import DocIndex from '../pages/appDoc/docIndex';
import ConfirmMail from '../pages/Authentication/ConfirmMail';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ResetPassword from '../pages/Authentication/ResetPassword';
import VerifyEmail from '../pages/Authentication/VerifyEmail';
import ContactPage from '../pages/ContactPage';
import FAQPage from '../pages/FAQPage';
import HomePage from '../pages/HomePage';
import NotificationPage from '../pages/NotificationPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicy/PrivacyPolicy/PrivacyPolicy.component';
import TermsOfUsePage from '../pages/PrivacyPolicy/TermsOfUse/TermsOfUse.component';
import ProfilePage from '../pages/Profile';
import ReferralPage from '../pages/ReferralPage';
import WalletPage from '../pages/WalletPage';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  preventRoles?: Array<any>;
  preventOnLoggedIn?: boolean;
  showNav?: boolean;
}

const commonRoutes: Array<RouteProps> = [
  { path: '/profile', component: ProfilePage },
  { path: '/banned', component: <Banned /> },
  { path: '/notifications', component: NotificationPage },
  {
    path: '/wallet',
    exact: true,
    component: WalletPage,
    // preventRoles: [roleText.ADMIN],
  },
  {
    path: '/InfluencerChannel',
    exact: true,
    component: ChannelDetailPage,
  },

  {
    path: '/referral',
    exact: true,
    component: ReferralPage,
    preventRoles: [roleText.ADMIN],
  },
  {
    path: '/Marketer/JobDetail',
    component: JobDetail,
    preventRoles: [roleText.PUBLISHER],
  },
  {
    path: '/campaign/JobDetail/CampaignChat',
    component: ChatWraper,
    preventRoles: [roleText.ADMIN],
  },
  {
    path: '/campaigns/markerter/job/review',
    component: <GleamJobReviewPage />,
  },
  {
    path: '/Marketer/JobPublicDetail',
    component: <JobDetail jobType="PUBLISH" />,
    preventRoles: [roleText.PUBLISHER],
  },
  {
    path: '/remove-data',
    exact: true,
    component: RemoveData,
    preventRoles: [roleText.ADMIN],
  },

  {
    path: '/JobSummary',
    exact: true,
    component: JobSummary,
    preventRoles: [roleText.PUBLISHER],
  },

  { path: '/doc/mobile-ad', component: DocIndex },
  { path: '/mock/jobRequirement', component: <MockJobRequirement /> },
];

const authRoutes: Array<RouteProps> = [
  { path: '/', component: HomePage },
  {
    path: '/auth-facebook',
    component: authByFacebook,
  },
  {
    path: '/auth-google/:type/token/:token',
    component: authByGoogle,
  },
  { path: '/terms-of-use', component: TermsOfUsePage },
  { path: '/privacy-policy', component: PrivacyPolicyPage },
  { path: '/login', component: Login, preventOnLoggedIn: true },
  { path: '/doc/mobile-ad', component: DocIndex },
  { path: '/logout', component: Logout },
  { path: '/forgot', component: ForgotPassword, preventOnLoggedIn: true },
  { path: '/reset-password', component: ResetPassword },
  { path: '/register', component: Register, preventOnLoggedIn: true },
  { path: '/register/:role', component: Register, preventOnLoggedIn: true },
  { path: '/login/:role', component: Login, preventOnLoggedIn: true },
  { path: '/confirm-mail', component: ConfirmMail, preventOnLoggedIn: true },
  { path: '/verify', component: VerifyEmail },
  { path: '/faqs', component: FAQPage },
  { path: '/contact', component: ContactPage },
  {
    path: '/portfolio',
    showNav: true,
    component: ExampleInfluencerWork,
  },
  {
    path: '/Discover',
    showNav: true,
    component: Discover,
  },
  {
    path: '/DiscoverAll',
    showNav: true,
    component: DiscoverAll,
  },
];

export { commonRoutes, authRoutes };
