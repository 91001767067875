/* eslint-disable no-unused-vars */
import { useState } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line no-shadow
export enum SocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
}

export interface Aptitude {
  key: string;
  name: string;
}

export interface KeywordAptitude {
  key: string;
  name: string;
}

export interface ImageStorage {
  key: string;
  url: string;
}

export interface SocialCard {
  video_content_price: number;
  key: string;
  social_media_type: SocialMediaType;
  name: string;
  follower: number;
  post_engagement: number;
  job_score: number;
  rating: number;
  like: number;
  share_content_price: number;
  user: any;
  photo_content_price: number;
  introduce: string;
  aptitudes: Aptitude[];
  keyword_aptitudes: KeywordAptitude[];
  image_profile_url: string;
}

const fakeSocialCard: SocialCard[] = [];

export const SelectInfluencerControl = () => {
  const [socialCards, setSocialCards] = useState<SocialCard[]>(fakeSocialCard || []);

  return {
    socialCards,
    setSocialCards,
  };
};

export const FlexRow = styled.nav<{
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  background?: string;
  height?: string;
  boxshadow?: string;
  justifycontent?: string;
  borderTop?: string;
  borderRadius?: string;
  padding?: string;
  flexdirection?: string;
}>`
  display: flex;
  flex-direction: ${({ flexdirection }) => flexdirection};
  flex-wrap: wrap;
  justify-content: ${({ justifycontent }) => justifycontent};
  align-items: center;
  position: relative;
  padding-top: 10px;
  text-align: center;
  box-shadow: ${({ boxshadow }) => boxshadow};
  background: ${({ background }) => background};
  @media screen and (min-width: 500px) {
    width: ${({ width }) => width};
  }
  @media screen and (max-width: 573px) {
    width: 100%;
  }
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  height: ${({ height }) => height};
  border-top: ${({ borderTop }) => borderTop};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
`;
export const Pagecontent = styled.div`
  height: 100%;
  text-align: center;
  margin-bottom: 5rem;
  // background-color: #fbfaff;
`;

export const FlexCol = styled.div<{
  width?: string;
  // background?: string;
  height?: string;
  margin?: string;
  borderTop?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 10px;
  text-align: center;
  // background: green;
  // width: 300px;
  border-top: ${({ borderTop }) => borderTop};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
export const Texts = styled.p<{
  fontsize?: string;
  // background?: string;
  height?: string;
  margin?: string;
}>`
  font-size: ${({ fontsize }) => fontsize};
  // font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: ${({ margin }) => margin};
`;
export const ImgTag = styled.img<{
  width?: string;
  height?: string;
  borderradius?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 0 10px;
  background-color: #fbfaff;
`;
export const ImgSocial = styled.img<{
  width?: string;
  height?: string;
  borderradius?: string;
}>`
  position: relative;
  // object-fit: cover;
  top: -65px;
  left: 24px;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  // margin: 0 10px;
  background-color: #fbfaff;
`;
export const CardStyle = styled.div<{
  width?: string;
  height?: string;
}>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 10px 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 0.2s ease-in-out;
  padding: 0.8rem;

  :hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
`;
export const ButtonStyle = styled.button`
  font-size: 1rem;
  padding: 3px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #999999;
  border-radius: 15px;
  width: 70px;
  height: 30px;
  cursor: pointer;
  background: transparent;
  transition: all 0.2s ease-in-out;

  :hover {
    border: 1px solid #3baf96;
    background: green;
    color: #ffffff;
  }
`;
export const ButtonRequest = styled.button`
  font-size: 12px;
  padding: 3px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #999999;
  border-radius: 15px;
  max-width: 100px;
  height: 30px;
  cursor: pointer;
  background: transparent;
  transition: all 0.2s ease-in-out;
`;

export const ButtonSubmit = styled.button`
  background: #3baf96;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  min-width: 8rem;
  font-size: 1.2em;
  font-weight: bold;
  outline: none;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 0;
  transition: all 0.1s ease-in-out;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  color: #ffffff;

  &:hover {
    transform: scale(1.05) !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  &:active {
    transform: scale(0.95) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
`;

export const InputSearch = styled.input`
  width: 100%;
  max-width: 360px;
  padding: 5px 10px;
  height: 45px;
  border: none;
  border-radius: 8px 0 0 8px;
  outline: none;
  color: #000000;
  background: #edeff3;

  :focus {
    color: #000000;
  }
  /* @media screen and (max-width: 499px) {
    width: 80%;
  } */
`;
export const SearchBtn = styled.button`
  width: 100%;
  max-width: 50px;
  height: 45px;
  border: none;
  background: #fff;
  text-align: center;
  color: #000000;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;

  /* @media screen and (max-width: 499px) {
    width: 10%; 
  }*/
`;

export const SelectInfu = styled.div`
  display: flex;
  width: 100%;
  max-width: 260px;
  height: 40px;
  border: none;
  border-right: 1px solid #000000;
  background: none;
  text-align: center;
  color: #000;
  border-radius: 5px 0 0 5px;
  cursor: pointer;

  font-size: 20px;
  /* @media screen and (max-width: 499px) {
    width: 45%;
  } */
`;
export const SelectSort = styled.div`
  width: 100%;
  max-width: 150px;
  height: 40px;
  border: none;
  background: none;
  text-align: center;
  border: none;
  color: #000;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;

  /* @media screen and (max-width: 499px) {
    width: 45%;
  } */
`;
export const BtnSort = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  background: none;
  text-align: center;
  color: #000;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
`;
