import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Default } from 'react-toastify/dist/utils';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import noCampaignImage from 'src/assets/images/flupower/no-campaign-image.png';
import usdtLogo from 'src/assets/images/usdt-logo.png';
import { floorToFixed } from 'src/common/data/mathToFixed';
import JobStatusButtonGroup from 'src/components/Advertiser/jobStatusButtonGroup';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { OnLoading } from 'src/components/Common/Loading';
import {
  InfluencerContentType,
  InfluencerJobBackGroundColor,
  InfluencerJobStatus,
  InfluencerSocialIcon,
} from 'src/components/Common/status/InfluencerJobStatus';
import avatar from 'src/assets/images/users/avatar-1.jpg';
import SearchBar from 'src/components/Table/SearchBarWhite';
import config from 'src/config';
import menu from 'src/constants/menu';
import {
  filterSocialGleamJob,
  socialJobCardData,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import capitalize from 'src/utils/captilalize';
import { formatDateForm, normalDateTime } from 'src/utils/dateTime';
import './jobIndex.style.scss';
import CoinImage from 'src/components/Common/CoinImage';
import { filterGleamGQL } from 'src/graphql/advertiser/socialMediaInfo';

const JobIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tab, setTab] = useState(1);
  const [data, setData] = useState<any>({});
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'INPROGRESS',
  });

  const _data = useSelector((State: any) => State.SocialMediaInfo.filterG);

  const fetchGleamJob = async (payload: any) => {
    try {
      dispatch(setLoading());
      const response = await filterGleamGQL(payload);
      setData(response);
    } catch (error) {
      setData({});
    } finally {
      dispatch(unsetLoading());
    }
  };

  console.log('data', data);

  useEffect(() => {
    fetchGleamJob(param);
  }, []);

  const { influencerData } = useSelector((state: any) => ({
    // marketerJob: state.MarketerJob.marketerJob,
    influencerData: state.Users.userInfo,
  }));
  const handleStatusChange = async (job_type: string) => {
    const payload = {
      ...param,
      page: 1,
      job_type,
    };
    //dispatch(filterSocialGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const handlePageChange = async (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    // dispatch(filterSocialGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const handleSearch = async (searchInput: string) => {
    const payload = {
      ...param,
      search: searchInput,
    };
    // dispatch(filterSocialGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const handleClear = async () => {
    const payload = {
      ...param,
      search: '',
    };
    // dispatch(filterSocialGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  // const handlePageSizeChange = (newPageSize: string) => {
  //   const payload = {
  //     ...param,
  //     limit: +newPageSize,
  //     page: 1,
  //   };
  //   dispatch(filterSocialJob(payload));
  //   setParam(payload);
  // };

  useEffect(() => {
    dispatch({ type: '@@social_media_info/CLEAR_EXPIRED_SOCIAL_MEDIA_TOKEN' });
    dispatch({ type: '@@social_media_info/RESET_SOCIAL_JOB' });
    // dispatch(filterSocialGleamJob(param));
  }, []);
  if (!data) return <OnLoading />;
  else if (data && !Object.keys(data)?.length) return <OnLoading />;

  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');

  const getJobType = (value: any) => {
    switch (value) {
      case 'PUBLISH':
        return 'Suggested job';
      case 'INVITE_ONLY':
        return 'Invite only';
      default:
        return '';
    }
  };

  return (
    <div className="page-content">
      <div className="bread-crumb-20" style={{ position: 'relative' }}>
        <Breadcrumb
          pageTitle={t('table:Job')}
          menus={[{ menu: menu.HOME, active: true }, { menu: menu.JOBS }]}
        />
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div
          className="search-filter-container job-influ-page"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <JobStatusButtonGroup
              param={param}
              handleStatusChange={handleStatusChange}
              statusCount={data?.statusCount}
            /> */}
          <div style={{ visibility: 'hidden' }}>dd</div>
          <SearchBar
            placeholder={t('table:Search Name')}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
        </div>
      </div>

      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>

      <Container fluid>
        <div
          className="job-index-influencer"
          style={{ maxWidth: '610px', width: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardHeader>
              <div className="nav-scrollbar-none nav-tabs-custom mb-3 nav-jobs">
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                      setTab(1);
                      // dispatch(
                      //   filterSocialGleamJob({ ...param, status: 'INPROGRESS' }),
                      // );
                      await fetchGleamJob({
                        ...param,
                        status: 'INPROGRESS',
                        page: 1,
                      });
                      setParam({ ...param, status: 'INPROGRESS', page: 1 });
                    }}
                    className={`${tab === 1 && 'tab-active'}`}
                  >
                    <h5 className="mb-0">{t('In progress')}</h5>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                      setTab(2);
                      //dispatch(filterSocialGleamJob({ ...param, status: 'DRAFT' }));
                      await fetchGleamJob({ ...param, status: 'DRAFT', page: 1 });
                      setParam({ ...param, status: 'DRAFT', page: 1 });
                    }}
                    className={`${tab === 2 && 'tab-active'}`}
                  >
                    <h5 className="mb-0">{t('Pending')}</h5>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                      setTab(3);
                      // dispatch(
                      //   filterSocialGleamJob({ ...param, status: 'FINISHED' }),
                      // );
                      await fetchGleamJob({ ...param, status: 'FINISHED', page: 1 });
                      setParam({ ...param, status: 'FINISHED', page: 1 });
                    }}
                    className={`${tab === 3 && 'tab-active'}`}
                  >
                    <h5 className="mb-0">{t('Completed')}</h5>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setTab(4);
                      dispatch(
                        filterSocialGleamJob({ ...param, status: 'DECLINE' }),
                      );
                      setParam({ ...param, status: 'DECLINE' });
                    }}
                    className={`${tab === 4 && 'active'}`}
                  >
                    <h5 className="mb-0">{t('Decline')}</h5>
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                      setTab(4);
                      //dispatch(filterSocialGleamJob({ ...param, status: 'CANCEL' }));
                      await fetchGleamJob({ ...param, status: 'CANCEL', page: 1 });
                      setParam({ ...param, status: 'CANCEL', page: 1 });
                    }}
                    className={`${tab === 4 && 'tab-active'}`}
                  >
                    <h5 className="mb-0">{t('Canceled')}</h5>
                  </NavLink>
                </NavItem>
              </div>
            </CardHeader>
          </div>

          {!data?.totalCount ? (
            <>
              <div className="no-campaign-container">
                <img
                  src={noCampaignImage}
                  className="no-campaign-image-container"
                  alt="noCampaignImage"
                />
                <div className="text-container-new">
                  <div className="text-top" style={{ textAlign: 'center' }}>
                    {t('table:You do not have any jobs right now')}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {data?.items?.map((items: any) => {
                const { social_media_has_job, social_media_info, job_task_count } =
                  items;
                const { is_gleam_job } = social_media_has_job.job;
                const path = is_gleam_job
                  ? `/JobDetail?job=${items?.social_media_has_job?.job?.key}&task=${items?.social_media_has_job?.group}`
                  : `/JobDetail?job=${items?.social_media_has_job?.job?.key}&detail=${items?.social_media_has_job?.key}`;
                return (
                  <div style={{ padding: '2px' }}>
                    <div
                      style={{
                        margin: 'auto',
                        marginTop: '4px',
                      }}
                      key={items.key}
                      className="card-job-index"
                    >
                      <a href={path}>
                        <Card
                          className="card-new"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`${path}`);
                            dispatch(
                              socialJobCardData({
                                social_media_type:
                                  items?.social_media_has_job?.job
                                    ?.social_media_type,
                                link_post: items?.link_post,
                                social_key: items?.social_media_info?.key,
                              }),
                            );
                          }}
                          style={{
                            paddingBottom: '10px',
                          }}
                        >
                          <CardTitle
                            style={{
                              background: 'white',
                              borderTopLeftRadius: '18px',
                              borderTopRightRadius: '18px',
                              borderRadius: '18px',
                              margin: '0px',
                              padding: '10px 0px 10px 0px',
                              width: '100%',
                            }}
                          >
                            <div className="card-detail-container">
                              {/* ทั้งก้อนบน */}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  gap: '4px',
                                  alignItems: 'flex-end',
                                  flexWrap: 'nowrap',
                                  width: '100%',
                                  // borderRight: '0.1px solid gray',
                                  padding: '0 15px 0 15px',
                                  minHeight: '135px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    className="gapped"
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      height: '100%',
                                    }}
                                  >
                                    <div
                                      className="brand-name"
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',

                                        height: '64px',
                                      }}
                                    >
                                      {is_gleam_job
                                        ? social_media_has_job?.job?.name
                                        : items?.social_media_has_job?.job
                                            ?.marketer_campaign?.brand_name}
                                      <div
                                        className="product-name"
                                        style={{ marginBottom: '3px' }}
                                      >
                                        {is_gleam_job
                                          ? social_media_has_job?.job
                                              ?.marketer_campaign?.user?.user_info
                                              ?.company
                                          : items?.social_media_has_job?.job?.name}
                                      </div>
                                    </div>
                                    <div
                                      className="company-name"
                                      style={{ color: 'gray' }}
                                    >
                                      {!is_gleam_job && (
                                        <>
                                          {t('influencer:From')}:{' '}
                                          {
                                            items?.social_media_has_job?.job
                                              ?.marketer_campaign?.user?.user_info
                                              ?.company
                                          }
                                          {'  '}
                                        </>
                                      )}
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: 'bold',
                                          color: 'gray',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        ({' '}
                                        {getJobType(
                                          items?.social_media_has_job?.job?.job_type,
                                        )}{' '}
                                        )
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-detail-job-right ">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      height: '64px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div className="price-all">
                                      <div className="prices">
                                        {floorToFixed(
                                          items?.social_media_has_job?.price,
                                          2,
                                        )}{' '}
                                        <span className="currency">
                                          {items?.social_media_has_job?.job?.currency
                                            ?.main_symbol ?? 'USDT'}
                                        </span>
                                      </div>
                                      <CoinImage
                                        symbol={
                                          items?.social_media_has_job?.job?.currency
                                            ?.main_symbol
                                        }
                                        size="s"
                                      />
                                      {/* <img
                                        style={{
                                          borderRadius: '50%',
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        // src={items.social_media_info.image_profile_url}
                                        src={usdtLogo}
                                        alt={items.social_media_info.name}
                                      /> */}
                                    </div>
                                    <div
                                      style={{
                                        overflow: 'hidden',
                                      }}
                                    >
                                      <InfluencerJobStatus
                                        status={items?.social_media_has_job?.status}
                                        style={{ maxWidth: '200px' }}
                                        linkpost={
                                          items?.social_media_has_job?.link_post
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ color: 'gray', fontSize: '14px' }}>
                                    {normalDateTime(
                                      social_media_has_job?.created_at,
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* ก้อนล่าง */}
                              <div className="card-detail-social">
                                <div className="card-detail-social-side">
                                  {is_gleam_job ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          flexDirection: 'column',
                                          minWidth: '81.25px',
                                        }}
                                      >
                                        <h3>{job_task_count}</h3>
                                        <h6>
                                          {job_task_count > 1 ? 'Tasks' : 'Task'}
                                        </h6>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          flexDirection: 'row',
                                          flexWrap: 'wrap',
                                          minWidth: '81.25px',
                                        }}
                                      >
                                        {social_media_info.map((social_job: any) => {
                                          return (
                                            <img
                                              style={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                              }}
                                              src={
                                                social_job?.image_profile_url ||
                                                avatar
                                              }
                                              alt={social_job?.name}
                                              onError={(e) => {
                                                e.currentTarget.src = avatar;
                                              }}
                                            />
                                          );
                                        })}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          flexDirection: 'column',
                                          minWidth: '81.25px',
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                          }}
                                          src={
                                            items?.social_media_info[0]
                                              ?.image_profile_url || avatar
                                          }
                                          alt={items?.social_media_info[0].name}
                                        />
                                        <div
                                          className="influencer-name"
                                          style={{
                                            marginTop: '5px',
                                            overflow: 'hidden',
                                            width: '100%',
                                            maxWidth: '81.25px',
                                          }}
                                        >
                                          {capitalize(
                                            items.social_media_info[0].name,
                                          )}
                                        </div>
                                      </div>{' '}
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          minWidth: '81.25px',
                                        }}
                                      >
                                        <div
                                          className="row-social-media"
                                          style={{
                                            display: 'flex',
                                            width: '100%',
                                            margin: '0px',
                                            padding: '0px',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <InfluencerSocialIcon
                                            social_media_type={
                                              items?.social_media_has_job?.job
                                                ?.social_media_type
                                            }
                                          />
                                          <InfluencerContentType
                                            content_type={
                                              items?.social_media_has_job?.job
                                                ?.content_type
                                            }
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div className="social-type">
                                            {capitalize(
                                              items?.social_media_has_job?.job
                                                ?.social_media_type,
                                            )}
                                          </div>
                                          <div className="content-type">
                                            {capitalize(
                                              items?.social_media_has_job?.job
                                                ?.content_type,
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </CardTitle>
                        </Card>
                      </a>
                    </div>
                  </div>
                );
              })}
              <Pagination
                count={Math.ceil(data?.totalCount / param.limit)}
                page={param.page}
                color="standard"
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
                boundaryCount={2}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default JobIndex;
