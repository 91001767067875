import BannerFormat from 'src/constants/banner-format-Emun';
import AndroidContent from '../ScriptContent/AndroidContent';
import FlutterContent from '../ScriptContent/FlutterContent';
import IosContent from '../ScriptContent/IosContent';
import IosKitContent from '../ScriptContent/IosKitContent';
import ReactNativeContent from '../ScriptContent/ReactNativeContent';
import {
  iosTextCopy,
  androidTextCopy,
  reactNativeTextCopy,
  flutterTextCopy,
  iosKitTextCopy,
} from '../Utils/TextCopy';

const tabContents = (adKey: string, appKey: string, adType: BannerFormat) => [
  {
    tabId: 1,
    content: <IosContent adKey={adKey} appKey={appKey} adType={adType} />,
    copyTextFunc: iosTextCopy,
  },
  {
    tabId: 2,
    content: <AndroidContent adKey={adKey} appKey={appKey} adType={adType} />,
    copyTextFunc: androidTextCopy,
  },
  {
    tabId: 3,
    content: <ReactNativeContent adKey={adKey} appKey={appKey} adType={adType} />,
    copyTextFunc: reactNativeTextCopy,
  },
  {
    tabId: 4,
    content: <FlutterContent adKey={adKey} appKey={appKey} adType={adType} />,
    copyTextFunc: flutterTextCopy,
  },
  {
    tabId: 5,
    content: <IosKitContent adKey={adKey} appKey={appKey} adType={adType} />,
    copyTextFunc: iosKitTextCopy,
  },
];

export default tabContents;
