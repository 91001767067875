import { useTranslation } from 'react-i18next';
import React from 'react';
import usdtLogo from 'src/assets/images/usdt-logo.png';
import { floorToFixed } from 'src/common/data/mathToFixed';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import {
  InfluencerContentType,
  InfluencerJobBackGroundColor,
  InfluencerJobStatus,
  InfluencerSocialIcon,
} from 'src/components/Common/status/InfluencerJobStatus';
import menu from 'src/constants/menu';
// import 'src/components/Publisher/Influencer/JobDetail/jobDetailCollapse1.style.scss';
import capitalize from 'src/utils/captilalize';
import './headerJobDetail.scss';
import CoinImage from 'src/components/Common/CoinImage';

const HeaderJobDetail = ({ marketerJob, socialJobItem }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(true);

  // const resize = () => {
  //   if (!isOpen) {
  //     return (
  //       <span
  //         className="show-more"
  //         style={{
  //           display: 'flex',
  //           position: 'absolute',
  //           left: '46.5%',
  //           fontSize: '12px',
  //           fontWeight: 'bold',
  //         }}
  //       >
  //         Show more
  //       </span>
  //     );
  //   } else {
  //     return (
  //       <div
  //         className="show-more"
  //         style={{
  //           display: 'flex',
  //           position: 'absolute',
  //           left: '46.5%',
  //           fontSize: '12px',
  //           fontWeight: 'bold',
  //         }}
  //       >
  //         Show less
  //       </div>
  //     );
  //   }
  // };
  return (
    <>
      <div
        style={{
          display: 'flex',
          background: '#fff',
          marginTop: '100px',
          minHeight: '89px',
          color: 'black',
          flexDirection: 'column',
        }}
      >
        <div className="bread-crumb">
          <Breadcrumb
            pageTitle={t('Menus:My Jobs')}
            menus={[
              { menu: menu.HOME, active: true },
              { menu: menu.JOBS },
              { menu: menu.MY_JOBS, isSamePage: true },
            ]}
          />
        </div>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="header-job-detail"
          style={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="brand-name-title"
              style={{ fontSize: '20px' }}
            >
              {marketerJob?.marketer_campaign?.brand_name}
            </div>
            {/* <div
              style={{
                display: 'block',
                width: 'auto',
                padding: 30,
              }}
            > */}
            {/* <Collapse isOpen={isOpen}>
                <div
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  className="product-detail"
                  style={{ fontSize: '14px', wordBreak: 'break-all' }}
                  dangerouslySetInnerHTML={{
                    __html: marketerJob?.marketer_campaign?.product_detail,
                  }}
                />
              </Collapse> */}
            {/* </div> */}
            {/* {resize()} */}
            <div className="company-name" style={{ fontSize: '1rem' }}>
              {t('From')}: {marketerJob?.marketer_campaign?.user?.user_info?.company}
            </div>
          </div>
          <div style={{ paddingTop: '10px', paddingRight: '50px' }}>
            <InfluencerJobStatus status={socialJobItem?.status} />
            {socialJobItem?.reject_message ? (
              <div className="cancel-reason">
                {t(`enum:Marketer_reason`)}
                {': '}
                {socialJobItem.reject_message}
              </div>
            ) : (
              <>
                {socialJobItem?.cancel_job_reason && (
                  <div className="cancel-reason">
                    {t(`enum:${socialJobItem?.cancel_job_reason}`)}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          background: `${InfluencerJobBackGroundColor(socialJobItem?.status)}`,
          width: '100%',
          height: '89px',
        }}
      >
        <div
          className="header-job-detail"
          style={{
            margin: 'auto',
          }}
        >
          <div className="group-social">
            <div className="column-group">
              <img
                style={{
                  border: '2px solid white',
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                src={socialJobItem?.social_media_info?.image_profile_url}
                alt={socialJobItem?.social_media_info?.name}
              />
              <div
                className="influencer-names"
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis !important',
                  overflow: 'hidden !important',
                  width: '100px !important',
                }}
              >
                {capitalize(socialJobItem?.social_media_info?.name)}
              </div>
            </div>
            <div className="column-group">
              <div className="row-social-media">
                <InfluencerContentType content_type={marketerJob?.content_type} />
                <InfluencerSocialIcon
                  social_media_type={marketerJob?.social_media_type}
                />
              </div>

              <div className="social-type">
                {capitalize(marketerJob?.social_media_type)}
              </div>
              <div className="content-type">
                {capitalize(marketerJob?.content_type)}
              </div>
            </div>
            <div className="column-group">
              <CoinImage
                symbol={socialJobItem?.job?.currency?.main_symbol}
                size="xxl"
              />
              {/* <img
                style={{
                  border: '2px solid white',
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                }}
                src={usdtLogo}
                alt={socialJobItem.price}
              /> */}
              <div className="prices" style={{ fontSize: '16px', color: 'white' }}>
                {floorToFixed(socialJobItem?.price, 2)}{' '}
                {socialJobItem?.job?.currency?.main_symbol ?? 'USDT'}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderJobDetail;
