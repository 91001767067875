import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/windowSize';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import * as formatter from 'src/utils/convert-value';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import SortByAptitude from 'src/components/Advertiser/Models/SelectInfuencer/SortByAptitude';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import noCampaign from 'src/assets/images/no-campaign-image.png';
import { Container } from 'reactstrap';
import { OnLoading } from 'src/components/Common/Loading';
import SocialMediaType from 'src/constants/enum/socialMediaType';
import './discover.style.scss';
import SearchBar from 'src/components/Table/SearchBar';

import config from 'src/config';
import {
  getDefaultWebsite,
  getSocialMediaInfoGroupByAptitude,
  socialMediaFilterTable,
} from 'src/store/actions';
import { filterMarketerCampaign } from 'src/store/marketerCampaign/actions';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import { Link } from 'react-router-dom';

import { Dialog } from '@mui/material';
import ChannelDetailPage from 'src/pages/Publisher/Influencer/ChannelDetail/ChannelDetail.component';

enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'MEN',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'WOMEN',
}
// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyInfluencer {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  FOLLOWERS = 'follower',
  // eslint-disable-next-line no-unused-vars
  RATING = 'rating',
  // eslint-disable-next-line no-unused-vars
  COST = 'photo_content_price',
}
// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyType {
  // eslint-disable-next-line no-unused-vars
  FOLLOWER = 'FOLLOWER',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'INFLUENCER',
}
interface FilterForm {
  propertyType?: PropertyType | undefined;
  country?: string;
  gender?: Gender;
  max_age?: number;
  aptitude_ids: string[];
  min_age?: number;
  limit?: number;
  pages?: number;
  sort_by?: string;
  name?: string;
  sortType?: string;
}
const DiscoverAll = ({ campaignType }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpenProfile(false);
  };
  const { width } = useWindowSize();
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [cardKey, setCardKey] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nameAptitude, setNameAptitude] = useState('');
  const [cards, setCards] = useState<any>();
  const [inputName, setInputName] = useState('');
  const isPublish = campaignType === 'PUBLISH';
  const location = useLocation<{ aptitude_ids: Array<string> }>();
  const localdata = location?.state;
  const initialStateForm: FilterForm = {
    aptitude_ids: [localdata.aptitude_ids[0]],
    name: inputName,
    limit: 10,
    sort_by: 'follower',
    sortType: 'DESC',
  };
  const [filterForm, setFilterForm] = useState<FilterForm>(initialStateForm);
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'ALL',
    sort_by: 'follower',
    sortType: 'DESC',
  });

  const { data, socialMediaInfo, social, isLoggedIn } = useSelector(
    (state: any) => ({
      data: state.MarketerCampaign.filter,
      socialMediaInfo: state.SocialMediaInfo.socialMediaFilterTable?.items,
      social: state.SocialMediaInfo.socialMediaFilterTable?.totalCount,
      isLoggedIn: state.login.isLoggedIn,
    }),
  );
  // const [paramx, setParamx] = useState<any>({
  //   aptitude_ids: [1],
  // });

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...filterForm,
      page: newPage,
    };
    setCards(social);
    setParam(payload);
    dispatch(socialMediaFilterTable(payload));
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...localdata,
      name: searchInput,
    };
    dispatch(socialMediaFilterTable(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      search: '',
    };
    dispatch(filterMarketerCampaign(payload, campaignType));
    setParam(payload);
  };

  // const handlePageSizeChange = (newPageSize: string) => {
  //   const payload = {
  //     ...param,
  //     limit: +newPageSize,
  //     page: 1,
  //   };
  //   dispatch(filterMarketerCampaign(payload));
  //   setParam(payload);
  // };

  useEffect(() => {
    dispatch(socialMediaFilterTable(filterForm));
    //dispatch(getSocialMediaInfoGroupByAptitude());
    //dispatch(getDefaultWebsite());
    //dispatch(filterMarketerCampaign(param, campaignType));
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
    // setFilterForm(initialStateForm);
  };

  useEffect(() => {
    if (socialMediaInfo) setNameAptitude(socialMediaInfo[0]?.aptitudes[0].name);
  }, [socialMediaInfo]);

  const notFound = () => {
    return (
      <div className="mid">
        <img src={noCampaign} alt="" />
        <div className="no-camaign-title mt-2"> no influencer to show</div>
      </div>
    );
  };

  if (!socialMediaInfo) return <OnLoading />;
  const onGetLogoSocialByType = (type: SocialMediaType): string => {
    switch (type) {
      case SocialMediaType.FACEBOOK:
        return facebookLogo;
      case SocialMediaType.INSTAGRAM:
        return instagramLogo;
      case SocialMediaType.TWITTER:
        return twitterLogo;
      case SocialMediaType.YOUTUBE:
        return youtubeLogo;
      case SocialMediaType.TIKTOK:
        return tikTokLogo;
      default:
        return facebookLogo;
    }
  };

  return (
    <div className="page-content" style={{ width: `${width > 1895 ? '90%' : ''} ` }}>
      <Link to="/Discover">Back</Link>
      <MetaTags>
        <title>
          {nameAptitude} | {config.appName}
        </title>
      </MetaTags>

      <Container fluid>
        <div className="marketer-campaign-container-discover ">
          <Breadcrumb
            pageTitle={nameAptitude}
            menus={[{ menu: menu.HOME, active: true }, { menu: menu.DISCOVER }]}
          />
          {/* <div className="header-campaign-container">
            <MarketerCampaignStatusButtonGroup
              statusCount={data?.statusCount}
              param={param}
              setParam={setParam}
              filterFunction={filterMarketerCampaign}
            />
          </div> */}
          <div className="header-discover-all">
            <button
              type="button"
              className="filter-button"
              style={{ marginTop: '20px' }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <i className="fa fa-filter" style={{ marginRight: '8px' }} />
              <span style={{ fontSize: '13px' }}>{t('Filter')}</span>
            </button>
            <SearchBar
              placeholder={t('table:Search name or brand name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
              disableClear
            />
          </div>
          <>
            <div
              className="card-group mb-4"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {socialMediaInfo &&
                socialMediaInfo?.map((items: any) => {
                  return (
                    <>
                      <span className="card-group mt-3">
                        <div
                          className="add-social-card background-img-short "
                          style={{
                            display: 'flex',
                            position: 'relative',
                            cursor: 'pointer',
                            borderRadius: '8%',
                            width: '150px',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setCardKey(items.key);
                            setIsOpenProfile(true);
                          }}
                        >
                          <img
                            src={onGetLogoSocialByType(items?.social_media_type)}
                            alt="Social Logo"
                            className="logo-socials"
                            onLoad={(e) => {
                              e.currentTarget.classList.remove('skeleton');
                            }}
                            onError={(e) => {
                              e.currentTarget.classList.remove('skeleton');
                              e.currentTarget.src = userAvatar;
                            }}
                            width={20}
                            height={20}
                          />
                          <img
                            className="image-aptitude"
                            style={{ width: '60px', height: '60px' }}
                            src={items.image_profile_url || userAvatar}
                            alt="profile"
                            onError={(e) => {
                              e.currentTarget.classList.remove('skeleton');
                              e.currentTarget.src = userAvatar;
                            }}
                          />
                          <span className="image-title" style={{ height: '20px' }}>
                            {items.name}
                          </span>
                          <span className="image-title" style={{ marginTop: '5px' }}>
                            {formatter.nFormatter(items?.follower, 1)}
                          </span>
                          <span style={{ fontWeight: 'bold', color: 'gray' }}>
                            {t('Followers')}
                          </span>
                        </div>
                      </span>
                    </>
                  );
                })}
              {socialMediaInfo?.length ? '' : notFound()}
            </div>
            {/* <Pagination
              count={Math.ceil(data?.totalCount / param.limit)}
              page={param.page}
              color="standard"
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
            /> */}
          </>
        </div>
      </Container>
      <Pagination
        count={Math.ceil(social / 10)}
        page={param.page}
        color="standard"
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
        boundaryCount={2}
      />
      <SortByAptitude
        isOpen={isOpen}
        toggle={toggle}
        setFilterForm={setFilterForm}
        filterForm={filterForm}
      />
      {isLoggedIn ? (
        <Dialog
          maxWidth="lg"
          onClose={handleClose}
          open={isOpenProfile}
          PaperProps={{
            style: { position: 'relative', margin: '0 5px' },
          }}
          style={{ zIndex: 1050 }}
        >
          <span
            style={{
              position: 'absolute',
              right: '10px',
              top: '5px',
              fontSize: '25px',
              fontWeight: 'bold',
              cursor: 'pointer',
              zIndex: '1100',
            }}
            onClick={handleClose}
          >
            X
          </span>
          <ChannelDetailPage influencerKey={cardKey} />
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DiscoverAll;
