import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik';
import Swal from 'sweetalert2';
import {
  getSuggestJobBudgetConfigs,
  updateConfig,
} from '../../store/default-values/actions';
import CoinImage from '../Common/CoinImage';
import { transformStringAmountInput } from '../../utils/input';

const initialValues = {
  symbol: '',
  min_key: '',
  min_value: '',
  max_key: '',
  max_value: '',
};

const settingSchema = Yup.object().shape({
  min_key: Yup.string().required('Required'),
  min_value: Yup.string().required('Required'),
  max_key: Yup.string().required('Required'),
  max_value: Yup.string().required('Required'),
});

const SuggestJobBudgetsSettingComponent = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSuggestJobBudgetConfigs());
  }, []);

  const { configs } = useSelector((state: any) => ({
    configs: state.DefaultValue.suggestJobBudgetConfigs,
  }));

  const onSubmit = (values: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Setting Min/Max Budget Confirm', {
        symbol: values.symbol,
      }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        // eslint-disable-next-line no-param-reassign
        values.min_value = parseFloat(values.min_value.replace(/,/g, '')).toString();
        // eslint-disable-next-line no-param-reassign
        values.max_value = parseFloat(values.max_value.replace(/,/g, '')).toString();

        dispatch(updateConfig(values.min_key, values.min_value));
        dispatch(updateConfig(values.max_key, values.max_value));
      }
    });
  };

  const handleConfigValueChange = (
    field: string,
    value: string,
    setFieldValue: any,
  ) => {
    setFieldValue(field, transformStringAmountInput(value));
  };

  return (
    <Row>
      <div className="p-2 text-danger">*{t('Budget Setting Helper')}</div>
      {configs &&
        configs.map((config: any) => {
          return (
            <Col lg={4}>
              <Card>
                <CardBody className="overflow-hidden position-relative">
                  <div className="d-flex flex-column">
                    <h5 className="text-secondary text-center mb-1">
                      <span className="logo-sm mx-1">
                        <CoinImage symbol={config.symbol} size="md" />
                        &nbsp;&nbsp;
                        {config.symbol}
                      </span>
                    </h5>

                    <Formik
                      initialValues={{
                        ...initialValues,
                        symbol: config.symbol,
                        min_key: config.min.name,
                        min_value: config.min.value,
                        max_key: config.max.name,
                        max_value: config.max.value,
                      }}
                      onSubmit={onSubmit}
                      validationSchema={settingSchema}
                      render={({ errors, touched, setFieldValue }) => (
                        <FormikForm>
                          <Row className="d-flex justify-content-center">
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Field name="min_key" type="hidden" id="min_key" />
                              </FormGroup>
                            </Col>
                            <Col md="8">
                              <FormGroup className="mb-3">
                                <div className="input-group text-white">
                                  <Label htmlFor="min_value">{t('Min')}</Label>
                                  <Field
                                    name="min_value"
                                    type="text"
                                    className={`form-control ${
                                      errors.min_value && touched.min_value
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    id="min_value"
                                    autocomplete="off"
                                    aria-describedby="configValueHelp"
                                    onChange={(e: any) => {
                                      handleConfigValueChange(
                                        'min_value',
                                        e.currentTarget.value,
                                        setFieldValue,
                                      );
                                    }}
                                  />
                                  <div className="input-group-text">USDT</div>
                                  <ErrorMessage
                                    name="value"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Field name="max_key" type="hidden" id="max_key" />
                              </FormGroup>
                            </Col>
                            <Col md="8">
                              <FormGroup className="mb-3">
                                <div className="input-group text-white">
                                  <Label htmlFor="max_value">{t('Max')}</Label>
                                  <Field
                                    name="max_value"
                                    type="text"
                                    className={`form-control ${
                                      errors.max_value && touched.max_value
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    id="max_value"
                                    autocomplete="off"
                                    aria-describedby="configValueHelp"
                                    onChange={(e: any) => {
                                      handleConfigValueChange(
                                        'max_value',
                                        e.currentTarget.value,
                                        setFieldValue,
                                      );
                                    }}
                                  />
                                  <div className="input-group-text">USDT</div>
                                  <ErrorMessage
                                    name="value"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg={8}>
                              <div className="d-grid">
                                <button
                                  className="btn btn-primary btn-sm"
                                  type="submit"
                                >
                                  {t('button:Update')}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </FormikForm>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default withTranslation()(SuggestJobBudgetsSettingComponent);
