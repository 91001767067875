import { BalanceTypes } from './actionTypes';
import { LogTransactionsQuery } from '../../common/types';

export const getSumWallets = () => {
  return {
    type: BalanceTypes.GET_SUM_WALLETS,
  };
};

export const getSumWalletsSuccess = (sum: any) => {
  return {
    type: BalanceTypes.GET_SUM_WALLETS_SUCCESS,
    payload: sum,
  };
};

export const getSumCryptosBalance = () => {
  return {
    type: BalanceTypes.GET_SUM_CRYPTOS_BALANCE,
  };
};

export const getSumCryptosBalanceSuccess = (sum: any) => {
  return {
    type: BalanceTypes.GET_SUM_CRYPTOS_BALANCE_SUCCESS,
    payload: sum,
  };
};

export const getSumProfitsBalance = () => {
  return {
    type: BalanceTypes.GET_SUM_PROFITS_BALANCE,
  };
};

export const getSumProfitsBalanceSuccess = (sum: any) => {
  return {
    type: BalanceTypes.GET_SUM_PROFITS_BALANCE_SUCCESS,
    payload: sum,
  };
};

export const getCryptoWallets = () => {
  return {
    type: BalanceTypes.GET_CRYPTO_WALLETS,
  };
};

export const getCryptoWalletsSuccess = (wallets: any) => {
  return {
    type: BalanceTypes.GET_CRYPTO_WALLETS_SUCCESS,
    payload: wallets,
  };
};

export const getSumWalletsRole = () => {
  return {
    type: BalanceTypes.GET_SUM_WALLETS_ROLE,
  };
};

export const getSumWalletsRoleSuccess = (data: any) => {
  return {
    type: BalanceTypes.GET_SUM_WALLETS_ROLE_SUCCESS,
    payload: data,
  };
};

export const getSystemBalanceLogTransactions = (payload: LogTransactionsQuery) => {
  return {
    type: BalanceTypes.GET_SYSTEM_BALANCE_LOG_TRANSACTIONS,
    payload: { ...payload },
  };
};

export const getSystemBalanceLogTransactionsSuccess = (transactions: any) => {
  return {
    type: BalanceTypes.GET_SYSTEM_BALANCE_LOG_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};

export const getProfitBalanceLogTransactions = (payload: LogTransactionsQuery) => {
  return {
    type: BalanceTypes.GET_PROFIT_BALANCE_LOG_TRANSACTIONS,
    payload: { ...payload },
  };
};

export const getProfitBalanceLogTransactionsSuccess = (transactions: any) => {
  return {
    type: BalanceTypes.GET_PROFIT_BALANCE_LOG_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};

export const getCryptoBalanceLogTransactions = (payload: LogTransactionsQuery) => {
  return {
    type: BalanceTypes.GET_CRYPTO_BALANCE_LOG_TRANSACTIONS,
    payload: { ...payload },
  };
};

export const getCryptoBalanceLogTransactionsSuccess = (transactions: any) => {
  return {
    type: BalanceTypes.GET_CRYPTO_BALANCE_LOG_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};

export const getRoleBalanceLogTransactions = (payload: LogTransactionsQuery) => {
  return {
    type: BalanceTypes.GET_ROLE_BALANCE_LOG_TRANSACTIONS,
    payload: { ...payload },
  };
};

export const getRoleBalanceLogTransactionsSuccess = (transactions: any) => {
  return {
    type: BalanceTypes.GET_ROLE_BALANCE_LOG_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};

export const fail = (error: any) => {
  return {
    type: BalanceTypes.FAIL,
    payload: error,
  };
};
