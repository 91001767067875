import { DefaultValuesState, DefaultValuesTypes } from './actionTypes';

export const INIT_STATE: DefaultValuesState = {
  defaultCampaign: [],
  allDefaultCampaigns: {},
  defaultWebsite: [],
  minWithdrawConfigs: [],
  feeWithdrawConfigs: [],
  systemProfitConfigs: [],
  minimumWithdrawConfig: {},
  tax: 0,
  error: {},
  loading: false,
  suggestJobBudgetConfigs: [],
};

const DefaultValue = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case DefaultValuesTypes.GET_DEFAULT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        defaultCampaign: action.payload,
      };
    case DefaultValuesTypes.GET_ALL_DEFAULT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        allDefaultCampaigns: action.payload,
      };

    case DefaultValuesTypes.GET_DEFAULT_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DefaultValuesTypes.GET_DEFAULT_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: true,
        defaultWebsite: action.payload,
      };
    case DefaultValuesTypes.GET_DEFAULT_WEBSITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIGS_SUCCESS:
      return {
        ...state,
        minWithdrawConfigs: action.payload,
      };
    case DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DefaultValuesTypes.GET_FEE_WITHDRAW_CONFIGS_SUCCESS:
      return {
        ...state,
        feeWithdrawConfigs: action.payload,
      };
    case DefaultValuesTypes.GET_FEE_WITHDRAW_CONFIGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIG_SUCCESS:
      return {
        ...state,
        minimumWithdrawConfig: action.payload,
      };

    case DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIG_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DefaultValuesTypes.GET_DEFAULT_VALUE_BY_KEY_SUCCESS:
      return {
        ...state,
        tax: +action.payload?.value,
      };
    case DefaultValuesTypes.GET_PROFIT_CONFIGS_SUCCESS:
      return {
        ...state,
        systemProfitConfigs: action.payload,
      };
    case DefaultValuesTypes.GET_SUGGEST_JOB_BUDGET_CONFIGS_SUCCESS:
      return {
        ...state,
        suggestJobBudgetConfigs: action.payalod,
      };
    default:
      return state;
  }
};

export default DefaultValue;
