import { isEmpty } from 'lodash';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import { setUserProfileList, setUserProfileStatus } from 'src/store/actions';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import { getAccessMetaToken } from '../getAccessMetaToken';
import getInstagramAccessToken from './getInstagramAccessToken';
import {
  getInstagramPageIds,
  getInstagramUserData,
  getInstagramUserId,
} from './instagramUserData';

export const onInstagramPageProcess = async (
  { params, dispatch }: any,
  rURL: string | null = null,
) => {
  const code: string | null = params.get('code');
  if (!code) {
    dispatch(setUserProfileStatus(`You canceled to connnect to Instagram`));
    throw new Error('Not found code');
  }
  const userListArr: UserSocialProfile[] = [];

  const access_token = await getAccessMetaToken(
    code,
    SocialMediaType.INSTAGRAM,
    rURL,
  );

  const pageIds = await getInstagramPageIds(access_token.token);
  //console.log('pageIds: ', pageIds);
  const resUserIds = await getInstagramUserId(access_token.token, pageIds);
  //console.log('resUserIds: ', resUserIds);
  const { user_ids, error_message } = resUserIds;

  const resUserData = await getInstagramUserData(access_token.token, user_ids);
  //console.log('resUserData: ', resUserData);
  const { user_data, getInstagramUserData_error } = resUserData;
  for (let i = 0; i < user_data?.length; i++) {
    const { id, followers_count, profile_picture_url, username }: any = user_data[i];

    const userDatum: any = {
      id,
      access_token: access_token.token,
      access_token_secret: '',
      fan_count: followers_count,
      name: username,
      social_media_type: SocialMediaType.INSTAGRAM,
      picture_url: profile_picture_url,
      profile_path: `https://www.instagram.com/${username}/`,
    };

    userListArr.push(userDatum);
  }
  // console.log('IG error_message: ', error_message);
  // console.log('IG getInstagramUserData_error', getInstagramUserData_error);
  if (userListArr?.length === 0) {
    dispatch(setUserProfileList(userListArr));
    if (pageIds?.length === 0) {
      dispatch(
        setUserProfileStatus(
          'Please select "Pages" from facebook to connect your business Instagram',
        ),
      );
    } else if (!isEmpty(error_message)) {
      dispatch(setUserProfileStatus(error_message));
    } else if (!isEmpty(getInstagramUserData_error)) {
      dispatch(setUserProfileStatus(getInstagramUserData_error));
    } else {
      dispatch(setUserProfileStatus(`Cannot get Instagram's user data`));
    }
  } else {
    dispatch(setUserProfileList(userListArr));
    dispatch(setUserProfileStatus('PASS'));
  }
};

export const onInstagramPersonalProcess = async ({ params, dispatch }: any) => {
  const code: string | null = params.get('code');
  if (!code) throw new Error('Not found code');
  const instagramData = await getInstagramAccessToken(code);

  const userDatum: any = {
    id: instagramData.id,
    access_token: instagramData.accessToken,
    access_token_secret: '',
    fan_count: 0,
    name: instagramData.full_name,
    social_media_type: SocialMediaType.INSTAGRAM,
    picture_url: instagramData.profile_picture_url,
    profile_path: `https://www.instagram.com/${instagramData.username}/`,
  };
  dispatch(setUserProfileList([userDatum]));
  dispatch(setUserProfileStatus('PASS'));
};
