import { useSelector } from 'react-redux';

// import Breadcrumbs
import { Container } from 'reactstrap';

import { roleText as role } from '../../constants/role';

import AdvertiserDashboard from './AdvertiserDashboard';
import PublisherDashboard from './PublisherDashboard';
import AdminDashboard from './AdminDashboard';

const Dashboard = () => {
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));

  const renderDashboard = () => {
    switch (user?.role) {
      case role.ADVERTISER:
        return <AdvertiserDashboard />;
      case role.PUBLISHER:
        return <PublisherDashboard />;
      case role.ADMIN:
        return <AdminDashboard />;
      default:
        return <AdminDashboard />;
    }
  };

  return <Container fluid>{renderDashboard()}</Container>;
};

export default Dashboard;
