import { call, put, takeEvery } from 'redux-saga/effects';

import { getFAQFilter, getFAQSearch } from 'src/graphql/admin/faq';
import { FaqsTypes } from './actionTypes';

import { setLoading, unsetLoading } from '../actions';
import { getFaqsFilterSuccess } from './actions';

function* getFaqsFilter({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getFAQFilter, payload);
    yield put(getFaqsFilterSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}
function* getFaqsSearch({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getFAQSearch, payload);
    yield put(getFaqsFilterSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* faqs() {
  yield takeEvery(FaqsTypes.GET_FAQS_FILTER, getFaqsFilter);
  yield takeEvery(FaqsTypes.GET_FAQS_SEARCH, getFaqsSearch);
}

export default faqs;
