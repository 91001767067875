/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum MarketerJobTypes {
  CREATE_MARKETER_JOB = '@@marketer_job/CREATE_MARKETER_JOB',
  CREATE_MARKETER_JOB_SUCCESS = '@@marketer_job/CREATE_MARKETER_JOB_SUCCESS',

  GET_MARKETER_JOB = '@@marketer_job/GET_MARKETER_JOB',
  GET_MARKETER_JOB_SUCCESS = '@@marketer_job/GET_MARKETER_JOB_SUCCESS',

  UPDATE_MARKETER_JOB = '@@marketer_job/UPDATE_MARKETER_JOB',
  UPDATE_MARKETER_JOB_SUCCESS = '@@marketer_job/UPDATE_MARKETER_JOB_SUCCESS',

  FILTER_JOB = '@@marker_campaign/FILTER_JOB',
  FILTER_JOB_SUCCESS = '@@marker_campaign/FILTER_JOB_SUCCESS',

  CLEAR_MARKETER_JOB = '@@marker_campaign/CLEAR_MARKETER_JOB',

  GET_MARKETER_JOB_SUMMARY = '@@marketer_job/GET_MARKETER_JOB_SUMMARY',

  MARKETER_JOB_COUNT = '@@marketer_job/MARKETER_JOB_COUNT',
  MARKETER_JOB_COUNT_SUCCESS = '@@marketer_job/MARKETER_JOB_COUNT_SUCCESS',

  SUGGEST_JOBS_HOMEPAGE = '@@marketer_job/SUGGEST_JOBS_HOMEPAGE',
  SUGGEST_JOBS_HOMEPAGE_SUCCESS = '@@marketer_job/SUGGEST_JOBS_HOMEPAGE_SUCCESS',

  GET_GLEAM_JOB_SUMMARY = '@@marketer_job/GET_GLEAM_JOB_SUMMARY',
  GET_GLEAM_JOB_SUMMARY_SUCCESS = '@@marketer_job/GET_GLEAM_JOB_SUMMARY',

  GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB = '@@marketer_job/GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB',
  GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB_SUCCESS = '@@marketer_job/GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB_SUCCESS',

  FILTER_GLEAM_JOB = '@@marketer_job/FILTER_GLEAM_JOB',
  FILTER_GLEAM_JOB_SUCCESS = '@@marketer_job/FILTER_GLEAM_JOB_SUCCESS',

  GET_GLEAM_JOB_DATA_FOR_EDIT = '@@marketer_job/GET_GLEAM_JOB_DATA_FOR_EDIT',
  GET_GLEAM_JOB_DATA_FOR_EDIT_SUCCESS = '@@marketer_job/GET_GLEAM_JOB_DATA_FOR_EDIT_SUCCESS',

  GET_MARKETER_FOLLOW_UP = '@@marketer_job/GET_MARKETER_FOLLOW_UP',
  GET_MARKETER_FOLLOW_UP_SUCCESS = '@@marketer_job/GET_MARKETER_FOLLOW_UP_SUCCESS',

  GET_REPORT_JOB = '@@marketer_job/GET_REPORT_JOB',
  GET_REPORT_JOB_SUCCESS = '@@marketer_job/GET_REPORT_JOB_SUCCESS',

  GET_REPORT_JOB_DETAIL = '@@marketer_job/GET_REPORT_JOB_DETAIL',
  GET_REPORT_JOB_DETAIL_SUCCESS = '@@marketer_job/GET_REPORT_JOB_DETAIL_SUCCESS',
}

export default MarketerJobTypes;
