/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum FaqsTypes {
  GET_FAQS_FILTER = '@@faq/GET_FAQS_FILTER',
  GET_FAQS_FILTER_SUCCESS = '@@faq/GET_FAQS_FILTER_SUCCESS',

  GET_FAQS_SEARCH = '@@faq/GET_FAQS_SEARCH',
  GET_FAQS_SEARCH_SUCCESS = '@@faq/GET_FAQS_SEARCH_SUCCESS',
}

export interface FaqState {
  faqs: Object;
  error: Object;
  loading: Boolean;
}
