import { gql } from '@apollo/client';
import { client } from '../client';

const countriesGraphql = async () => {
  const query = gql`
    query Countries {
      countries {
        key
        name
      }
    }
  `;

  const result: any = await client.query({
    query,
  });

  return result.data.countries;
};
export default countriesGraphql;
