import { gql } from '@apollo/client';
import config from 'src/config';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from './client';
import { logout } from './common/auth';

// Get Users
const GET_USERS = gql`
  query GetUsers {
    users {
      name
      email
      password
    }
  }
`;
export const getUser = () => {
  client.query({
    query: GET_USERS,
  });
};

// Create User
const CREATE_USER = gql`
  mutation createUser($name: String!, $email: String!, $password: String!) {
    createUser(
      createUserInput: { name: $name, email: $email, password: $password }
    ) {
      name
      email
      password
    }
  }
`;
export const createUser = () => {
  client.mutate({
    mutation: CREATE_USER,
    variables: {
      // name: '',
      // email: '',
      // password: ''
    },
  });
};

// Update User
export const updateUser = () => {};

// Delete User
const DELETE_USER = gql`
  mutation removeUser($id: Int!) {
    removeUser(id: $id) {
      email
    }
  }
`;
export const deleteUser = () => {
  client
    .mutate({
      mutation: DELETE_USER,
      variables: {
        // id: 1
      },
    })
    .then((res) => res.data);
};

export const createUserInfoGql = async (payload: any) => {
  const mutation = gql`
    mutation createUserInfo($payload: CreateUserInfoInput!) {
      createUserInfo(createUserInfoInput: $payload) {
        phone_number
        name
        company
        gender
        isLGBT
        nationality
        date_of_birth
        address
        company_type
        postcode
        province
        country
        address_phone_number
        country_phone_digit
        timezone
        timezone_offset
      }
    }
  `;

  try {
    const result: any = await client.mutate({
      mutation,
      variables: { payload },
      fetchPolicy: 'no-cache',
    });
    notify(i18n.t('success:user info has been create'), 'success');
    return result.data.createUserInfo;
  } catch (error) {
    notify(i18n.t('error:user info has not been create.'), 'error');
    return null;
  }
};

export const createRegisterUserInfoGql = async (payload: any) => {
  const mutation = gql`
    mutation createRegisterUserInfo($payload: CreateUserInfoInput!) {
      createRegisterUserInfo(createUserInfoInput: $payload) {
        phone_number
        name
        company
        gender
        isLGBT
        nationality
        date_of_birth
        address
        company_type
        postcode
        province
        country
        address_phone_number
        country_phone_digit
        timezone
        timezone_offset
      }
    }
  `;

  try {
    const result: any = await client.mutate({
      mutation,
      variables: { payload },
      fetchPolicy: 'no-cache',
    });
    return result.data.createRegisterUserInfo;
  } catch (error) {
    return null;
  }
};

export const getUserInfoGql = async () => {
  const query = gql`
    query {
      getUserInfo {
        phone_number
        name
        company
        company_type
        gender
        isLGBT
        nationality
        date_of_birth
        address
        postcode
        province
        country
        address_phone_number
        country_phone_digit
        timezone
        timezone_offset
      }
    }
  `;

  const result: any = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result ? result.data.getUserInfo : {};
};

export const updateUserInfoGql = async (payload: any) => {
  const mutation = gql`
    mutation updateUserInfoInput($payload: UpdateUserInfoInput!) {
      updateUserInfo(updateUserInfoInput: $payload) {
        phone_number
        name
        company
        gender
        isLGBT
        nationality
        date_of_birth
        address
        postcode
        company_type
        province
        country
        address_phone_number
        country_phone_digit
        timezone
        timezone_offset
      }
    }
  `;

  try {
    const result: any = await client.mutate({
      mutation,
      variables: { payload },
    });
    notify(i18n.t('success:user info has been update'), 'success');
    return result.data.updateUserInfo;
  } catch (error) {
    notify(i18n.t('error:user info has not been update.'), 'error');
    return null;
  }
};

export const uploadProfile = async (fileData: any) => {
  const file = fileData[0] || fileData;

  const formData = new FormData();
  formData.append(
    'operations',
    '{"query":"mutation uploadProfile($file:Upload!) {\\n uploadProfile(file:$file)\\n}", "variables": { "file": null }}\n',
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);

  const access_token = localStorage.getItem('access-token');
  if (access_token) {
    const headers = new Headers();
    headers.append('authorization', `Bearer ${access_token}`);

    const response = await fetch(<any>config.graphql.URI, {
      method: 'POST',
      headers,
      body: formData,
      redirect: 'follow',
    });

    const responseText: any = await response.text();

    const responseJson = JSON.parse(responseText);

    return linkImageStorageToUser(responseJson.data.uploadProfile);
  } else {
    await logout();
    localStorage.removeItem('access-token');
    localStorage.removeItem('last-silent-refresh');
    window.location.href = '/';
    return null;
  }
};

const linkImageStorageToUser = async (image_storage_key: string) => {
  const mutation = gql`
    mutation linkImageStorageToUser($image_storage_key: String!) {
      linkImageStorageToUser(image_storage_key: $image_storage_key)
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: { image_storage_key },
    });

    return image_storage_key;
  } catch (error) {
    notify(i18n.t('error:Profile not been update.'), 'error');
    return null;
  }
};

export const uploadSocialJobImage = async (fileData: any, key: string) => {
  const file = fileData[0] || fileData;

  const formData = new FormData();
  formData.append(
    'operations',
    `{"query":"mutation uploadSocialJobImage($file:Upload!,$key:String) {\\n uploadSocialJobImage(file:$file,key:$key)\\n}", "variables": { "file": null ,"key": "${key}"}}\n`,
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);

  const access_token = localStorage.getItem('access-token');
  if (access_token) {
    const headers = new Headers();
    headers.append('authorization', `Bearer ${access_token}`);

    try {
      const res = await fetch(<any>config.graphql.URI, {
        method: 'POST',
        headers,
        body: formData,
        redirect: 'follow',
      });
      const resText = await res.text();
      const resData = JSON.parse(resText);
      return resData.data.uploadSocialJobImage;
    } catch (error) {
      notify(i18n.t('error:Upload image error .'), 'error');
      return null;
    }
  } else {
    await logout();
    localStorage.removeItem('access-token');
    localStorage.removeItem('last-silent-refresh');
    window.location.href = '/';
    return null;
  }
};
