import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { floorToFixed } from 'src/common/data/mathToFixed';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { OnLoading } from 'src/components/Common/Loading';
import menu from 'src/constants/menu';
import {
  socialJobSummary,
  influencerActivePendingJobCount,
} from 'src/store/actions';
import { getAllSuggestJob } from 'src/store/suggestJob/actions';
import control from './Overview.control';
import './Overview.style.scss';
// eslint-disable-next-line import/order
import { Tooltip } from '@mui/material';

const OverviewComponent = ({ t }: any) => {
  control();
  const dispatch = useDispatch();
  const {
    socialJobSummary: socialJobSummary$,
    data,
    status,
  } = useSelector((state: any) => ({
    socialJobSummary: state.SocialMediaInfo.socialJobSummary,
    data: state?.SuggestJobs?.suggestJobs,
    status: state.SocialMediaInfo.influencerActivePendingCount,
  }));

  useEffect(() => {
    dispatch(socialJobSummary());
    dispatch(getAllSuggestJob());
    dispatch(influencerActivePendingJobCount());
  }, []);
  if (socialJobSummary$) {
    if (!Object.keys(socialJobSummary$)?.length) return <OnLoading />;
  }

  return (
    <>
      <div className="bread-crumb">
        <Breadcrumb
          pageTitle={t('Menus:Overview')}
          menus={[{ menu: menu.HOME, active: true }, { menu: menu.OVERVIEW }]}
        />
      </div>
      <div className="overview-content ">
        <div className="bg-white card-overview card-overview-profit">
          <span className="header" style={{ color: 'black' }}>
            {t('influencer:Total Balance')}
          </span>
          <span
            className="bold"
            style={{
              color: 'black',
              fontSize: '2.5rem',
            }}
          >{`${floorToFixed(socialJobSummary$?.user_balance, 2)} USDT`}</span>
          <div style={{ display: 'flex', color: 'black' }}>
            <span>{`${t('Pending')}`}</span>
            <span style={{ color: 'black' }}>{`${floorToFixed(
              socialJobSummary$?.sum_income,
              2,
            )} USDT`}</span>
          </div>
          <div style={{ height: '5px' }}> </div>
        </div>
        <div>
          <div
            className="card-overview card-overview-works bg-white"
            style={{ flexDirection: 'column', justifyContent: 'start' }}
          >
            <div>
              <div
                className="header"
                style={{
                  marginTop: '7px',
                  fontSize: '18px',
                  color: 'black',
                }}
              >
                {t('Menus.My Jobs')}
              </div>
            </div>

            <Link
              to="/influencer/publish-job"
              className="available-job"
              style={{ fontWeight: 'bold' }}
            >
              <div className="display-justify">
                <div style={{ color: 'black' }}>
                  {t('Available jobs    ')} {'        '}
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '20px',
                  }}
                >
                  {data?.suggestJobs ?? 0}
                </div>
              </div>
            </Link>
            <Link
              to="/influencer/job"
              className="available-job"
              style={{ bottom: '47px', fontWeight: 'bold' }}
            >
              <div className="display-justify" style={{ gap: '90px' }}>
                <div style={{ color: 'black', textAlign: 'start' }}>
                  {' '}
                  {t('In progress')}{' '}
                </div>
                <div style={{ color: 'black', fontSize: '20px' }}>
                  {status?.inprogress ?? 0}
                </div>
              </div>
            </Link>
            <Link
              to="/influencer/job"
              className="available-job"
              style={{ bottom: '79px', fontWeight: 'bold' }}
            >
              <div className="display-justify" style={{ gap: '50px' }}>
                <div style={{ color: 'black' }}>{t('Pending')} </div>
                <div style={{ color: 'black', fontSize: '20px' }}>
                  {status?.pending ?? 0}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(OverviewComponent);
