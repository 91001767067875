import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SystemBalanceWidgets from '../../components/Admin/SystemBalance/SystemBalanceWidgets';
import {
  getSumWallets,
  getSumCryptosBalance,
  getCryptoWallets,
  getSumWalletsRole,
  getSumProfitsBalance,
} from '../../store/systembalance/actions';
import CryptosBalance from '../../components/Admin/SystemBalance/CryptosBalance';
import SumWalletsRoleBalance from '../../components/Admin/SystemBalance/SumWalletsRoleBalance';
import menu from '../../constants/menu';

const options: Object = {
  chart: {
    height: 50,
    type: 'line',
    toolbar: { show: false },
  },
  colors: ['#5156be'],
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter() {
          return '';
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const SystemBalanceOverviewPage = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSumWallets());
    dispatch(getSumCryptosBalance());
    dispatch(getCryptoWallets());
    dispatch(getSumWalletsRole());
    dispatch(getSumProfitsBalance());
  }, []);

  return (
    <>
      <div className="page-content admin-balance">
        <MetaTags>
          <title>{t('Page.System Balance Overview')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.System Balance Overview')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.SYSTEM_BALANCE_OVERVIEW, active: true },
            ]}
          />

          <Row>
            <SystemBalanceWidgets options={options} />
          </Row>
          <Row>
            <SumWalletsRoleBalance />
            <CryptosBalance />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(SystemBalanceOverviewPage);
