import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

const getFeeWithdrawConfigsGql = async () => {
  const GET_FEE_WITHDRAW_CONFIGS = gql`
    query getFeeConfigs {
      getFeeConfigs {
        name
        value
      }
    }
  `;

  const result = await client.query({
    query: GET_FEE_WITHDRAW_CONFIGS,
    fetchPolicy: 'no-cache',
  });
  return result.data.getFeeConfigs;
};

const checkDuplicateSocial = async (social_id: String) => {
  const CHECK_DUPLICATE_SOCIAL = gql`
    query checkDuplicateSocial($social_id: String!) {
      checkDuplicateSocial(social_id: $social_id)
    }
  `;
  const result = await client.query({
    query: CHECK_DUPLICATE_SOCIAL,
    variables: {
      social_id,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.checkDuplicateSocial;
};

const getMinimumWithdrawConfigs = async () => {
  const GET_MINIMUM_WITHDRAW_CONFIGS = gql`
    query GetMinimumWithdrawConfigs {
      getMinimumWithdrawConfigs {
        name
        value
        rate_in_USD
        estimate_in_USD
        logo
      }
    }
  `;

  const result = await client.query({
    query: GET_MINIMUM_WITHDRAW_CONFIGS,
    fetchPolicy: 'no-cache',
  });

  return result.data.getMinimumWithdrawConfigs;
};

const getMinimumWithdrawConfig = async (symbol: string) => {
  const GET_MINIMUM_WITHDRAW_CONFIG = gql`
    query GetMinimumWithdrawConfig($symbol: String!) {
      getMinimumWithdrawConfig(symbol: $symbol) {
        name
        value
        rate_in_USD
        estimate_in_USD
      }
    }
  `;

  const result = await client.query({
    query: GET_MINIMUM_WITHDRAW_CONFIG,
    variables: {
      symbol,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.getMinimumWithdrawConfig;
};
const getDefaultValueByKeyGql = async (key: string) => {
  const GET_DEFAULT_VALUE_BY_KEY = gql`
    query getDefaultValueByKey($key: String!) {
      getDefaultValueByKey(key: $key) {
        value
      }
    }
  `;

  const result = await client.query({
    query: GET_DEFAULT_VALUE_BY_KEY,
    variables: {
      key,
    },
    fetchPolicy: 'no-cache',
  });
  return result.data.getDefaultValueByKey;
};

const updateFeeConfig = async (input: any) => {
  const UPDATE_CONFIG = gql`
    mutation updateConfig($input: UpdateConfigInput!) {
      updateConfig(input: $input)
    }
  `;
  try {
    const response: any = client.mutate({
      mutation: UPDATE_CONFIG,
      variables: {
        input,
      },
      errorPolicy: 'all',
    });
    notify(i18n.t('success:Config Changed Success .'), 'success');
    return response;
  } catch (error) {
    notify(i18n.t('error:Config Changed Fail .'), 'error');
    return null;
  }
};

const updateConfig = async (data: any) => {
  const UPDATE_CONFIG = gql`
    mutation UpdateConfig($data: UpdateConfigInput!) {
      updateConfig(input: $data)
    }
  `;

  const response: any = client.mutate({
    mutation: UPDATE_CONFIG,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const getProfitConfigs = async () => {
  const GET_SYSTEM_PROFIT_CONFIGS = gql`
    query GetSystemProfitConfigs {
      getSystemProfitConfigs {
        name
        value
      }
    }
  `;

  const result = await client.query({
    query: GET_SYSTEM_PROFIT_CONFIGS,
    fetchPolicy: 'no-cache',
  });

  return result.data.getSystemProfitConfigs;
};

const getMinMaxBudgetConfig = async () => {
  const query = gql`
    query GetMinMaxBudgetConfig {
      getMinMaxBudgetConfig {
        symbol
        min {
          name
          value
        }
        max {
          name
          value
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.getMinMaxBudgetConfig;
  } catch {
    return null;
  }
};

// eslint-disable-next-line import/prefer-default-export
export {
  getMinimumWithdrawConfigs,
  getMinimumWithdrawConfig,
  updateConfig,
  getProfitConfigs,
  getDefaultValueByKeyGql,
  getFeeWithdrawConfigsGql,
  updateFeeConfig,
  checkDuplicateSocial,
  getMinMaxBudgetConfig,
};
