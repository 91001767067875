import MarketerJobTypes from './actionTypes';

export const createMarketerJob = (payload: any) => ({
  type: MarketerJobTypes.CREATE_MARKETER_JOB,
  payload,
});

export const createMarketerJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.CREATE_MARKETER_JOB_SUCCESS,
  payload,
});

export const getMarketerJob = (key: string) => ({
  type: MarketerJobTypes.GET_MARKETER_JOB,
  key,
});

export const getMarketerJobSummary = (key: string) => ({
  type: MarketerJobTypes.GET_MARKETER_JOB_SUMMARY,
  key,
});

export const getMarketerJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_MARKETER_JOB_SUCCESS,
  payload,
});

export const updateMarketerJob = (payload: any) => ({
  type: MarketerJobTypes.UPDATE_MARKETER_JOB,
  payload,
});

export const updateMarketerJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.UPDATE_MARKETER_JOB_SUCCESS,
  payload,
});

export const filterJob = (payload: any) => ({
  type: MarketerJobTypes.FILTER_JOB,
  payload,
});

export const filterJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.FILTER_JOB_SUCCESS,
  payload,
});

export const clearMarketerJob = () => ({
  type: MarketerJobTypes.CLEAR_MARKETER_JOB,
});

export const marketerJobCount = () => ({
  type: MarketerJobTypes.MARKETER_JOB_COUNT,
});

export const marketerJobCountSuccess = (payload: any) => ({
  type: MarketerJobTypes.MARKETER_JOB_COUNT,
  payload,
});

export const getSuggestJobOnHomePage = () => ({
  type: MarketerJobTypes.SUGGEST_JOBS_HOMEPAGE,
});

export const getSuggestJobOnHomePageSuccess = (payload: any) => ({
  type: MarketerJobTypes.SUGGEST_JOBS_HOMEPAGE_SUCCESS,
  payload,
});

export const getGleamJobSummary = (key: string) => ({
  type: MarketerJobTypes.GET_GLEAM_JOB_SUMMARY,
  key,
});

export const getGleamJobSummarySuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_GLEAM_JOB_SUMMARY_SUCCESS,
  payload,
});

export const getAvaliableSocialForGleamJob = (key: string) => ({
  type: MarketerJobTypes.GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB,
  key,
});

export const getAvaliableSocialForGleamJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB_SUCCESS,
  payload,
});

export const filterGleamJob = (payload: any) => ({
  type: MarketerJobTypes.FILTER_GLEAM_JOB,
  payload,
});

export const filterGleamJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.FILTER_GLEAM_JOB_SUCCESS,
  payload,
});

export const getGleamJobDataForEdit = (key: string) => ({
  type: MarketerJobTypes.GET_GLEAM_JOB_DATA_FOR_EDIT,
  key,
});

export const getGleamJobDataForEditSuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_GLEAM_JOB_DATA_FOR_EDIT_SUCCESS,
  payload,
});

export const getMarketerFollowUp = (payload: any) => ({
  type: MarketerJobTypes.GET_MARKETER_FOLLOW_UP,
  payload,
});

export const getMarketerFollowUpSuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_MARKETER_FOLLOW_UP_SUCCESS,
  payload,
});

export const getReportJob = (payload: any) => ({
  type: MarketerJobTypes.GET_REPORT_JOB,
  payload,
});

export const getReportJobSuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_REPORT_JOB_SUCCESS,
  payload,
});

export const getReportJobDetail = (payload: any) => ({
  type: MarketerJobTypes.GET_REPORT_JOB_DETAIL,
  payload,
});

export const getReportJobDetaiSuccess = (payload: any) => ({
  type: MarketerJobTypes.GET_REPORT_JOB_DETAIL_SUCCESS,
  payload,
});
