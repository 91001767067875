import { GlobalTypes } from './actionTypes';

export const setLoading = () => {
  return {
    type: GlobalTypes.SET_LOADING,
  };
};

export const unsetLoading = () => {
  return {
    type: GlobalTypes.UNSET_LOADING,
  };
};

export const setEarningTransactionLoading = () => {
  return {
    type: GlobalTypes.SET_EARNING_TRANSACTION_LOADING,
  };
};

export const unsetEarningTransactionLoading = () => {
  return {
    type: GlobalTypes.UNSET_EARNING_TRANSACTION_LOADING,
  };
};

export const setGraphLoading = () => {
  return {
    type: GlobalTypes.SET_GRAPH_LOADING,
  };
};

export const unsetGraphLoading = () => {
  return {
    type: GlobalTypes.UNSET_GRAPH_LOADING,
  };
};

export const setWebsitesLoading = () => {
  return {
    type: GlobalTypes.SET_WEBSITES_LOADING,
  };
};

export const unsetWebsitesLoading = () => {
  return {
    type: GlobalTypes.UNSET_WEBSITES_LOADING,
  };
};

export const setApplicationsLoading = () => {
  return {
    type: GlobalTypes.SET_APPLICATIONS_LOADING,
  };
};

export const unsetApplicationsLoading = () => {
  return {
    type: GlobalTypes.UNSET_APPLICATIONS_LOADING,
  };
};

export const setRequestSizeLoading = () => {
  return {
    type: GlobalTypes.SET_REQUEST_SIZE_LOADING,
  };
};

export const unsetRequestSizeLoading = () => {
  return {
    type: GlobalTypes.UNSET_REQUEST_SIZE_LOADING,
  };
};

export const setApplicationCreativeFilterLoading = () => {
  return {
    type: GlobalTypes.SET_APPLICATION_CREATIVE_FILTER_LOADING,
  };
};

export const unsetApplicationCreativeFilterLoading = () => {
  return {
    type: GlobalTypes.UNSET_APPLICATION_CREATIVE_FILTER_LOADING,
  };
};

export const setCampaignDatumLoading = () => {
  return {
    type: GlobalTypes.SET_CAMPAIGN_DATUM_LOADING,
  };
};

export const unsetCampaignDatumLoading = () => {
  return {
    type: GlobalTypes.UNSET_CAMPAIGN_DATUM_LOADING,
  };
};

export const setCampaignFilterLoading = () => {
  return {
    type: GlobalTypes.SET_CAMPAIGN_FILTER_LOADING,
  };
};

export const unsetCampaignFilterLoading = () => {
  return {
    type: GlobalTypes.UNSET_CAMPAIGN_FILTER_LOADING,
  };
};

export const setCampaignStateSummaryLoading = () => {
  return {
    type: GlobalTypes.SET_CAMPAIGN_STATE_SUMMARY_LOADING,
  };
};

export const unsetCampaignStateSummaryLoading = () => {
  return {
    type: GlobalTypes.UNSET_CAMPAIGN_STATE_SUMMARY_LOADING,
  };
};

export const setCreativeRequestLoading = () => {
  return {
    type: GlobalTypes.SET_CREATIVE_REQUEST_LOADING,
  };
};

export const unsetCreativeRequestLoading = () => {
  return {
    type: GlobalTypes.UNSET_CREATIVE_REQUEST_LOADING,
  };
};

export const setCreativeSizesLoading = () => {
  return {
    type: GlobalTypes.SET_CREATIVE_SIZES_LOADING,
  };
};

export const unsetCreativeSizesLoading = () => {
  return {
    type: GlobalTypes.UNSET_CREATIVE_SIZES_LOADING,
  };
};

export const setCreativeCampaignsLoading = () => {
  return {
    type: GlobalTypes.SET_CREATIVE_CAMPAIGNS_LOADING,
  };
};

export const unsetCreativeCampaignsLoading = () => {
  return {
    type: GlobalTypes.UNSET_CREATIVE_CAMPAIGNS_LOADING,
  };
};

export const setCreativeReportLoading = () => {
  return {
    type: GlobalTypes.SET_CREATIVE_REPORT_LOADING,
  };
};

export const unsetCreativeReportLoading = () => {
  return {
    type: GlobalTypes.UNSET_CREATIVE_REPORT_LOADING,
  };
};

export const setDepositTransactionsLoading = () => {
  return {
    type: GlobalTypes.SET_DEPOSIT_TRANSACTIONS_LOADING,
  };
};

export const unsetDepositTransactionsLoading = () => {
  return {
    type: GlobalTypes.UNSET_DEPOSIT_TRANSACTIONS_LOADING,
  };
};

export const setCurrenciesLoading = () => {
  return {
    type: GlobalTypes.SET_CURRENCIES_LOADING,
  };
};

export const unsetCurrenciesLoading = () => {
  return {
    type: GlobalTypes.UNSET_CURRENCIES_LOADING,
  };
};

export const setActivityLogLoading = () => {
  return {
    type: GlobalTypes.SET_ACTIVITY_LOG_LOADING,
  };
};

export const unsetActivityLogLoading = () => {
  return {
    type: GlobalTypes.UNSET_ACTIVITY_LOG_LOADING,
  };
};
