import { GlobalTypes } from './actionTypes';

const initialState = {
  loading: false,
  earningTransactionLoading: false,
  graphLoading: false,
  websitesLoading: false,
  applicationsLoading: false,
  requestSizeLoading: false,
  applicationCreativeFilterLoading: false,
  campaignDatumLoading: false,
  campaignFilterLoading: false,
  campaignStateSummaryLoading: false,
  creativeRequestLoading: false,
  creativeSizesLoading: false,
  creativeCampaignsLoading: false,
  creativeReportLoading: false,
  depositTransactionsLoading: false,
  currenciesLoading: false,
  activityLogLoading: false,
};

const Global = (state = initialState, action: any) => {
  switch (action.type) {
    case GlobalTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GlobalTypes.UNSET_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GlobalTypes.SET_EARNING_TRANSACTION_LOADING:
      return {
        ...state,
        earningTransactionLoading: true,
      };
    case GlobalTypes.UNSET_EARNING_TRANSACTION_LOADING:
      return {
        ...state,
        earningTransactionLoading: false,
      };
    case GlobalTypes.SET_GRAPH_LOADING:
      return {
        ...state,
        graphLoading: true,
      };
    case GlobalTypes.UNSET_GRAPH_LOADING:
      return {
        ...state,
        graphLoading: false,
      };
    case GlobalTypes.SET_WEBSITES_LOADING:
      return {
        ...state,
        websitesLoading: true,
      };
    case GlobalTypes.UNSET_WEBSITES_LOADING:
      return {
        ...state,
        websitesLoading: false,
      };
    case GlobalTypes.SET_APPLICATIONS_LOADING:
      return {
        ...state,
        applicationsLoading: true,
      };
    case GlobalTypes.UNSET_APPLICATIONS_LOADING:
      return {
        ...state,
        applicationsLoading: false,
      };
    case GlobalTypes.SET_REQUEST_SIZE_LOADING:
      return {
        ...state,
        requestSizeLoading: true,
      };
    case GlobalTypes.UNSET_REQUEST_SIZE_LOADING:
      return {
        ...state,
        requestSizeLoading: false,
      };
    case GlobalTypes.SET_APPLICATION_CREATIVE_FILTER_LOADING:
      return {
        ...state,
        applicationCreativeFilterLoading: true,
      };
    case GlobalTypes.UNSET_APPLICATION_CREATIVE_FILTER_LOADING:
      return {
        ...state,
        applicationCreativeFilterLoading: false,
      };
    case GlobalTypes.SET_CAMPAIGN_DATUM_LOADING:
      return {
        ...state,
        campaignDatumLoading: true,
      };
    case GlobalTypes.UNSET_CAMPAIGN_DATUM_LOADING:
      return {
        ...state,
        campaignDatumLoading: false,
      };
    case GlobalTypes.SET_CAMPAIGN_FILTER_LOADING:
      return {
        ...state,
        campaignFilterLoading: true,
      };
    case GlobalTypes.UNSET_CAMPAIGN_FILTER_LOADING:
      return {
        ...state,
        campaignFilterLoading: false,
      };
    case GlobalTypes.SET_CAMPAIGN_STATE_SUMMARY_LOADING:
      return {
        ...state,
        campaignStateSummaryLoading: true,
      };
    case GlobalTypes.UNSET_CAMPAIGN_STATE_SUMMARY_LOADING:
      return {
        ...state,
        campaignStateSummaryLoading: false,
      };
    case GlobalTypes.SET_CREATIVE_REQUEST_LOADING:
      return {
        ...state,
        creativeRequestLoading: true,
      };
    case GlobalTypes.UNSET_CREATIVE_REQUEST_LOADING:
      return {
        ...state,
        creativeRequestLoading: false,
      };
    case GlobalTypes.SET_CREATIVE_SIZES_LOADING:
      return {
        ...state,
        creativeSizesLoading: true,
      };
    case GlobalTypes.UNSET_CREATIVE_SIZES_LOADING:
      return {
        ...state,
        creativeSizesLoading: false,
      };
    case GlobalTypes.SET_CREATIVE_CAMPAIGNS_LOADING:
      return {
        ...state,
        creativeCampaignsLoading: true,
      };
    case GlobalTypes.UNSET_CREATIVE_CAMPAIGNS_LOADING:
      return {
        ...state,
        creativeCampaignsLoading: false,
      };
    case GlobalTypes.SET_CREATIVE_REPORT_LOADING:
      return {
        ...state,
        creativeReportLoading: true,
      };
    case GlobalTypes.UNSET_CREATIVE_REPORT_LOADING:
      return {
        ...state,
        creativeReportLoading: false,
      };
    case GlobalTypes.SET_DEPOSIT_TRANSACTIONS_LOADING:
      return {
        ...state,
        depositTransactionsLoading: true,
      };
    case GlobalTypes.UNSET_DEPOSIT_TRANSACTIONS_LOADING:
      return {
        ...state,
        depositTransactionsLoading: false,
      };
    case GlobalTypes.SET_CURRENCIES_LOADING:
      return {
        ...state,
        currenciesLoading: true,
      };
    case GlobalTypes.UNSET_CURRENCIES_LOADING:
      return {
        ...state,
        currenciesLoading: false,
      };
    case GlobalTypes.SET_ACTIVITY_LOG_LOADING:
      return {
        ...state,
        activityLogLoading: true,
      };
    case GlobalTypes.UNSET_ACTIVITY_LOG_LOADING:
      return {
        ...state,
        activityLogLoading: false,
      };
    default:
      return state;
  }
};

export default Global;
