import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { layoutTheme } from 'src/constants/layout';
import ModelUploadFormNewFile from '../Models/ModelUploadFormNewFile';
import ModelUploadFormGallery from '../Models/ModelUploadFormGallery';
import {
  getCreativesByUserId as onGetCreativesByUserId,
  getCreativeSizes as onGetCreativeSizes,
} from '../../../store/actions';
import Upload from '../Img/upload.png';
import Gallery from '../Img/gallery.png';
import UploadDark from '../Img/upload-dark.png';
import GalleryDark from '../Img/gallery-dark.png';

const UploadCreativeDisplay = (props: any) => {
  const {
    fileUploads,
    setFileUploads,
    isInstantUpload,
    refreshData,
    layoutMode,
    UploadAdFormat,
    device_platform,
    t,
  } = props;
  const dispatch = useDispatch();

  const [file, setFile] = useState<any>();
  const [fromGallery, setFromGallery] = useState(false);
  const [isOpenModelNewCreative, setIsOpenModelNewCreative] = useState(false);
  const [isOpen, setIsOpenGallery] = useState(false);

  const { creativesByUserId, creativeSizes, creativeSizesLoading } = useSelector(
    (state: any) => {
      const creativeData = state.Creatives.creativeSizes.map((creativeSize: any) => {
        return {
          ...creativeSize,
          dimensions: `${creativeSize.width}x${creativeSize.height}`,
        };
      });

      let longStringDimensions = '';
      for (let i = 0; i < creativeData?.length; i++) {
        longStringDimensions = `${longStringDimensions} ${creativeData[i].dimensions},`;
      }

      const rowState = { item: creativeData, longString: longStringDimensions };

      return {
        creativesByUserId: state.Creatives.creativesByUserId,
        creativeSizes: rowState,
        creativeSizesLoading: state.Global.creativeSizesLoading,
      };
    },
  );

  useEffect(() => {
    if (UploadAdFormat !== 'POP') {
      dispatch(
        onGetCreativeSizes({
          ad_image_type:
            UploadAdFormat === 'NATIVE'
              ? `${UploadAdFormat}_ADVERTISER`
              : UploadAdFormat,
          device_platform,
        }),
      );
    }
  }, [UploadAdFormat, device_platform]);

  if (UploadAdFormat === 'POP') {
    return (
      <>
        <button
          onClick={() => {
            setIsOpenModelNewCreative(!isOpenModelNewCreative);
            setFromGallery(false);
            setFile(false);
          }}
          type="button"
          className="btn btn-primary hover-cw gray-hover float-left"
        >
          {t('button:New pop up creative')}
        </button>
        <ModelUploadFormNewFile
          isOpen={isOpenModelNewCreative}
          setIsOpen={setIsOpenModelNewCreative}
          fileUploads={fileUploads}
          setFileUploads={setFileUploads}
          isInstantUpload={isInstantUpload}
          fromGallery={fromGallery}
          file={file}
          refreshData={refreshData}
          setFromGallery={setFromGallery}
          creativeSizes={creativeSizes}
          isPopCreative
        />
        <br />
        <br />
      </>
    );
  } else {
    return (
      <>
        {creativeSizesLoading ? (
          <>
            <div className="mt-4">
              <Row className="text-center mt-4 justify-content-evenly">
                <Col sm={12} md={5} className="mb-4 mb-md-0">
                  <Card className="cursor h-100-per gray-border mb-0 skeleton">
                    <CardBody
                      style={{ padding: '2rem 3rem' }}
                      className="btn-upload-bounce"
                    />
                  </Card>
                </Col>
                <Col sm={12} md={5}>
                  <Card className="cursor h-100-per gray-border mb-0 skeleton">
                    <CardBody
                      style={{ padding: '2rem 3rem' }}
                      className="btn-upload-bounce"
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <Row className="text-center mt-4 justify-content-evenly">
              <Col sm={12} md={5} className="mb-4 mb-md-0">
                <Card
                  className="cursor h-100-per gray-border mb-0"
                  onClick={() => {
                    setIsOpenModelNewCreative(!isOpenModelNewCreative);
                    setFromGallery(false);
                    setFile(false);
                  }}
                >
                  <CardBody
                    style={{ padding: '2rem 3rem' }}
                    className="btn-upload-bounce"
                  >
                    <img
                      src={layoutMode === layoutTheme.DARKMODE ? UploadDark : Upload}
                      alt="UploadImg"
                      className="h-mx-100 w-auto w-mx-100-per"
                    />
                    <p className="font-size-20 fw-bold mt-3">
                      {t('creative:Upload new creative')}
                    </p>
                    <p className="mt-3">
                      {UploadAdFormat !== 'NATIVE'
                        ? t(`creative:The required size of the creative is`)
                        : t(`creative:The recommended size of the creative is`)}
                      {creativeSizes.longString}
                      <br />
                      {t('creative:Maximum file size 300KB')} (
                      {t('creative:only JPG, JPEG, PNG, GIF')})
                    </p>
                  </CardBody>
                </Card>

                <ModelUploadFormNewFile
                  isOpen={isOpenModelNewCreative}
                  setIsOpen={setIsOpenModelNewCreative}
                  fileUploads={fileUploads}
                  setFileUploads={setFileUploads}
                  isInstantUpload={isInstantUpload}
                  fromGallery={fromGallery}
                  file={file}
                  UploadAdFormat={UploadAdFormat}
                  refreshData={refreshData}
                  setFromGallery={setFromGallery}
                  creativeSizes={creativeSizes}
                />
              </Col>
              <Col sm={12} md={5}>
                <Card
                  className="cursor h-100-per gray-border mb-0"
                  onClick={() => {
                    dispatch(onGetCreativesByUserId());
                    setIsOpenGallery(!isOpen);
                  }}
                >
                  <CardBody
                    style={{ padding: '2rem 3rem' }}
                    className="btn-upload-bounce"
                  >
                    <img
                      src={
                        layoutMode === layoutTheme.DARKMODE ? GalleryDark : Gallery
                      }
                      alt="GalleryImg"
                      className="h-mx-100 w-auto w-mx-100-per"
                    />

                    <p className="font-size-20 fw-bold mt-3">
                      {t('creative:Select from gallery')}
                    </p>

                    <p className="mt-3">
                      {t('creative:choose existed creative')}
                      <br /> {t('creative:from a previous campaign.')}
                    </p>
                  </CardBody>
                </Card>
                <ModelUploadFormGallery
                  isOpen={isOpen}
                  toggleModel={() => {
                    if (isOpen) {
                      setFromGallery(false);
                    }
                    setIsOpenGallery(!isOpen);
                  }}
                  updateCreative
                  setFromGallery={setFromGallery}
                  setIsOpenModelNewCreative={setIsOpenModelNewCreative}
                  creatives={creativesByUserId}
                  fileUploads={fileUploads}
                  setFileUploads={setFileUploads}
                  setFile={setFile}
                  creativeSizes={creativeSizes}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
};
export default withTranslation()(UploadCreativeDisplay);
