import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const LanguageDropdownLeft = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState<string>('');
  const [menu, setMenu] = useState<boolean>(false);

  useEffect(() => {
    const currentLanguage: any = localStorage.getItem('I18N_LANGUAGE');
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang: string) => {
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
    window.location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  const [language, setLanguage] = useState(false);
  const [languageState, setLanguageState] = useState('TH');

  // console.log('languages', languages);
  return (
    <>
      <div
        style={{ display: 'flex', cursor: 'pointer' }}
        onClick={() => {
          setLanguage(!language);
        }}
        className="fs-5 "
      >
        {selectedLang.toUpperCase()}
        <div style={{ marginLeft: 10 }}>
          <i className={`fa fa-chevron-${!language ? 'right' : 'left'}`} />
        </div>
      </div>
      {language && (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '10px',
            }}
          >
            <div
              onClick={async () => {
                await changeLanguageAction('th');
                setLanguageState('TH');
                setLanguage(false);
              }}
              className="fs-5 "
              style={{
                background: selectedLang === 'th' ? 'black' : 'white',
                color: selectedLang === 'th' ? 'white' : 'black',
                cursor: 'pointer',
                paddingLeft: '5px',
                paddingRight: '5px',
                borderRadius: '5px',
                height: '25px',
              }}
            >
              TH
            </div>
            <div
              onClick={async () => {
                await changeLanguageAction('en');
                setLanguageState('EN');
                setLanguage(false);
              }}
              className="fs-5 "
              style={{
                background: selectedLang === 'en' ? 'black' : 'white',
                color: selectedLang === 'en' ? 'white' : 'black',
                cursor: 'pointer',
                paddingLeft: '5px',
                paddingRight: '5px',
                borderRadius: '5px',
                height: '25px',
              }}
            >
              EN
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(LanguageDropdownLeft);
