import { useSelector } from 'react-redux';

const PrivacyPolicyControl = () => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  return { layoutMode };
};

export default PrivacyPolicyControl;
