import { isEmpty } from 'lodash';
import { type } from 'os';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { getSocialMediaInfoByUUID } from 'src/store/actions';
import { checkSocialMediaCallAPIGQL } from 'src/graphql/advertiser/socialMediaInfo';
import { Dialog, DialogContent } from '@mui/material';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import SocialMediaTypeOptions from '../Table/SocialMediaTypeOptions';
import avatar from '../../assets/images/users/avatar-1.jpg';

const SocialMediaTable = ({ t, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [socialMediaType, setSocialMediaType] = useState<any>(null);

  const defaultPostModalData = {
    isOpen: false,
    message: '',
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);

  const [param, setParam] = useState<any>({
    uuid: userId,
    limit: 10,
    page: 1,
    socialMediaType,
    name: search,
  });

  const { txn } = useSelector((state: any) => ({
    txn: state.SocialMediaInfo.socialMediaInfoByUUID,
  }));

  useEffect(() => {
    dispatch(getSocialMediaInfoByUUID(getDefaultPayload(1)));
  }, []);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...param,
      page: newPage,
    };

    dispatch(getSocialMediaInfoByUUID(payload));
    setPage(newPage);
    setParam(payload);
  };
  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getSocialMediaInfoByUUID(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleTypeChange = (input: string) => {
    const _type = input === 'ALL' ? null : input;

    const payload = {
      ...getDefaultPayload(1),
      socialMediaType: _type,
    };

    dispatch(getSocialMediaInfoByUUID(payload));
    setSocialMediaType(_type);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      name: val,
    };

    dispatch(getSocialMediaInfoByUUID(payload));
    setSearch(search);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      name: '',
    };

    dispatch(getSocialMediaInfoByUUID(payload));
    setSearch('');
    setParam(payload);
  };

  const getDefaultPayload = (pages: number) => {
    setPage(pages);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, message } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <h3>{message}</h3>
        </DialogContent>
      </Dialog>
    );
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('name'),
        accessor: (items: any) =>
          items.proflie_path ? (
            <a href={items?.profile_path} target="_blank" rel="noopener noreferrer">
              {items?.name}
            </a>
          ) : (
            items?.name
          ),
        id: 'social_id',
        disableSort: true,
        width: 150,
      },

      {
        Header: t('table:social'),
        accessor: ({ social_media_type }: any) => social_media_type,
        id: 'social_media_type',
        disableSort: true,
        width: 100,
      },

      {
        Header: t(`table:view Influencer profile`),
        accessor: (item: any) => (
          <a
            className="cursor  fw-bold view-profile"
            href={`/InfluencerChannel?channel=${item?.key}`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/InfluencerChannel?channel=${item?.key}`);
            }}
          >
            {t(`table:view profile`)}
          </a>
        ),
        id: 'influencer_profile',
        width: 180,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:status'),
        accessor: ({ verify_status }: any) => {
          let badgeColor: string = 'warning';
          switch (verify_status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verify_status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        disableSort: true,
      },
      {
        Header: t('delete'),
        accessor: ({ isDelete }: any) => {
          let badgeColor: string = 'warning';
          switch (isDelete) {
            case false:
              badgeColor = 'success';
              break;
            case true:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'success';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${isDelete ? 'Yes' : 'No'}`)}
              </span>
            </div>
          );
        },
        id: 'delete',
        disableSort: true,
      },
      {
        Header: t('img'),
        accessor: ({ image_profile_url }: any) => (
          <img
            src={image_profile_url || avatar}
            alt="image_profile_url"
            style={{ width: 40, height: 40, borderRadius: '50%' }}
          />
        ),

        id: 'profile',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Test'),
        accessor: ({ social_id, key, isDelete }: any) => {
          if (isDelete) {
            return <></>;
          } else {
            return (
              <button
                style={{ background: 'white' }}
                className="link-job"
                type="button"
                onClick={async () => {
                  const res = await checkSocialMediaCallAPIGQL(key, social_id);
                  if (res) {
                    setPostModalData({
                      ...postModalData,
                      isOpen: true,
                      message: res,
                    });
                  }
                }}
              >
                {t('table:Test')}
              </button>
            );
          }
        },
        id: 'test',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:created_at'),
        accessor: ({ created_at }: any) => {
          if (!created_at) return 'null';
          const created_at_date = new Date(created_at);
          const Day = created_at_date.getDate();
          const month = created_at_date.getMonth() + 1;
          const year = created_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'created_at',
        disableSort: true,
      },
    ];
  }, [t, txn]);

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getSocialMediaInfoByUUID(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, page, pageSize],
  );

  if (!txn) return <></>;

  return (
    <>
      {displayModal()}
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={4} lg={3} style={{ marginRight: '40px' }}>
                <SocialMediaTypeOptions
                  defaultValue={type}
                  handleTypeChange={handleTypeChange}
                />
              </Col>
              <Col sm={12} md={8} lg={5}>
                <SearchBar
                  placeholder={t('table:Social Media')}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(SocialMediaTable);
