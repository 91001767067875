import { gql } from '@apollo/client';
import { client } from '../client';
import { LogTransactionsQuery } from '../../common/types';

const getSumWallets = async () => {
  const GET_SUM_WALLETS = gql`
    query GetSumSystemBalance {
      getSumSystemBalance {
        sum
      }
    }
  `;

  const result: any = await client.query({
    query: GET_SUM_WALLETS,
    fetchPolicy: 'no-cache',
  });

  return result.data.getSumSystemBalance.sum;
};

const getSumCryptosBalance = async () => {
  const GET_SUM_CRYPTOS_BALANCE = gql`
    query GetSumCryptosBalance {
      getSumCryptosBalance {
        sum
      }
    }
  `;

  const result: any = await client.query({
    query: GET_SUM_CRYPTOS_BALANCE,
    fetchPolicy: 'no-cache',
  });

  return result.data.getSumCryptosBalance.sum;
};

const getSumProfitsBalance = async () => {
  const GET_SUM_PROFITS_BALANCE = gql`
    query {
      getSumProfits {
        sum
      }
    }
  `;

  const result: any = await client.query({
    query: GET_SUM_PROFITS_BALANCE,
    fetchPolicy: 'no-cache',
  });

  return result.data.getSumProfits.sum;
};

const getCryptoWallets = async () => {
  const GET_CRYPTO_WALLETS = gql`
    query GetCryptoWallets {
      getCryptoWallets {
        symbol
        CRYPTO_amount
        FIAT_amount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_CRYPTO_WALLETS,
    fetchPolicy: 'no-cache',
  });

  return result.data.getCryptoWallets;
};

const getSumWalletsRole = async () => {
  const GET_SUM_WALLETS_ROLE = gql`
    query GetSumWalletsRole {
      getSumWalletsRole {
        advertiser_sum
        publisher_sum
        admin_sum
        fee_sum
        hold_sum
      }
    }
  `;

  const result: any = await client.query({
    query: GET_SUM_WALLETS_ROLE,
    fetchPolicy: 'no-cache',
  });

  return result.data.getSumWalletsRole;
};

const getSystemBalanceLogTransactions = async (queryInput: LogTransactionsQuery) => {
  const GET_SYSTEM_BALANCE_LOG_TRANSACTIONS = gql`
    query SystemBalanceLogTransactions($input: LogTransactionsQueryInput!) {
      systemBalanceLogTransactions(input: $input) {
        graphItems {
          amount
          day
          month
          year
        }
        items {
          amount
          day
          month
          year
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_SYSTEM_BALANCE_LOG_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.systemBalanceLogTransactions;
};

const getProfitBalanceLogTransactions = async (queryInput: LogTransactionsQuery) => {
  const GET_PROFIT_BALANCE_LOG_TRANSACTIONS = gql`
    query ProfitBalanceLogTransactions($input: LogTransactionsQueryInput!) {
      profitBalanceLogTransactions(input: $input) {
        graphItems {
          amount
          day
          month
          year
        }
        items {
          amount
          day
          month
          year
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_PROFIT_BALANCE_LOG_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.profitBalanceLogTransactions;
};

const getCryptoBalanceLogTransactions = async (queryInput: LogTransactionsQuery) => {
  const GET_CRYPTO_BALANCE_LOG_TRANSACTIONS = gql`
    query CryptoBalanceLogTransactions($input: LogTransactionsQueryInput!) {
      cryptoBalanceLogTransactions(input: $input) {
        graphItems {
          amount
          crypto_amount {
            symbol
            CRYPTO_amount
            FIAT_amount
            FIAT_rate
          }
          day
          month
          year
        }
        items {
          amount
          crypto_amount {
            symbol
            CRYPTO_amount
            FIAT_amount
            FIAT_rate
          }
          day
          month
          year
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_CRYPTO_BALANCE_LOG_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.cryptoBalanceLogTransactions;
};

const getRoleBalanceLogTransactions = async (queryInput: LogTransactionsQuery) => {
  const GET_ROLE_BALANCE_LOG_TRANSACTIONS = gql`
    query RoleBalanceLogTransactions($input: LogTransactionsQueryInput!) {
      roleBalanceLogTransactions(input: $input) {
        graphItems {
          amount
          role_amount {
            role
            amount
          }
          day
          month
          year
        }
        items {
          amount
          role_amount {
            role
            amount
          }
          day
          month
          year
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_ROLE_BALANCE_LOG_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.roleBalanceLogTransactions;
};

export {
  getSumWallets,
  getSumCryptosBalance,
  getCryptoWallets,
  getSumWalletsRole,
  getSystemBalanceLogTransactions,
  getProfitBalanceLogTransactions,
  getCryptoBalanceLogTransactions,
  getRoleBalanceLogTransactions,
  getSumProfitsBalance,
};
