import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Col, FormGroup, Label, Modal } from 'reactstrap';
import config from 'src/config';
import ImageAutoBalance from 'src/components/Common/ImageAutoBalance';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../graphql/advertiser/campaign';
import {
  createCreativeCampaignGraphql,
  createCreativeGraphQL,
} from '../../../graphql/advertiser/creative';
import { setLoading, unsetLoading } from '../../../store/actions';
import ImageFromUploadDisplay from '../UploadCreatives/ImageFromUploadDisplay';

const ModelUploadFormNewFile = (props: any) => {
  const dispatch = useDispatch();

  const {
    isOpen,
    setIsOpen,
    fileUploads,
    setFileUploads,
    isInstantUpload,
    fromGallery,
    file,
    refreshData,
    creativeSizes,
    isPopCreative,
    UploadAdFormat,
    t,
  } = props;
  const [isActive, setIsActive] = useState(true);
  const [nameAd, setNameAd] = useState<any>('');
  const [url_ad, setUrlAd] = useState<any>('');
  const [titleAd, setTitleAd] = useState<any>('');
  const [descriptionAd, setDescriptionAd] = useState<any>('');
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isMissFileSize, setIsMissFileSize] = useState(true);
  const [isMissDimension, setIsMissDimension] = useState(true);
  const [initialValues, setInitialValues] = useState<any>();
  const [CreateCreativeSchema, setCreateCreativeSchema] = useState<any>();

  const { campaign } = useSelector((state: any) => ({
    campaign: state.Campaigns.campaign,
  }));

  const onSubmit = async () => {
    dispatch(setLoading());
    if (isInstantUpload) {
      if (fromGallery) {
        await createCreativeCampaignGraphql({
          creative_key: file.key,
          campaign_key: campaign.campaign_key,
          url_ad,
          isActive,
          name: '',
          title: titleAd,
          description: descriptionAd,
        });
        setNameAd('');
        setUrlAd('');
        setTitleAd('');
        setDescriptionAd('');
        await refreshData();
      } else {
        if (isPopCreative) {
          createCreativeCampaignGraphql({
            creative_key: null,
            campaign_key: campaign.campaign_key,
            url_ad,
            isActive,
            name: nameAd,
            title: titleAd,
            description: descriptionAd,
          });
        } else {
          const jsonFile = await uploadImage(selectedFiles[0]);

          if (jsonFile !== null) {
            const creative = {
              name: nameAd,
              size: selectedFiles[0].fileSize,
              image_storage_key: jsonFile.data.uploadImage,
              campaign_keys: campaign.campaign_key,
              url_ad,
              isActive,
              title: titleAd,
              description: descriptionAd,
            };
            await createCreativeGraphQL(creative);
          }
        }

        setUrlAd('');
        setTitleAd('');
        setDescriptionAd('');
        await refreshData();
      }
      setIsActive(true);
      setIsOpen(false);
    } else {
      if (fromGallery) {
        const callBackData = {
          data: file,
          isActive,
          url_ad,
          titleAd: titleAd || '',
          descriptionAd: descriptionAd || '',
        };

        const files = [...fileUploads];

        files.push(callBackData);

        setFileUploads(files);
      } else {
        const InfoFile = isPopCreative ? [{}] : [...selectedFiles];
        InfoFile[0].nameAd = nameAd;
        InfoFile[0].url_ad = url_ad;
        InfoFile[0].isActive = isActive;
        InfoFile[0].titleAd = titleAd || '';
        InfoFile[0].descriptionAd = descriptionAd || '';

        setFileUploads([...fileUploads, InfoFile]);
      }

      setSelectedFiles([]);
      setIsActive(true);
      setIsOpen(false);
      setNameAd('');
      setUrlAd('');
      setTitleAd('');
      setDescriptionAd('');
    }
    dispatch(unsetLoading());
  };

  useEffect(() => {
    setSelectedFiles([]);
    if (UploadAdFormat === 'NATIVE') {
      setInitialValues({
        name: `${fromGallery && file.name}`,
        url: '',
        title: '',
        description: '',
      });
      setCreateCreativeSchema(
        Yup.object().shape({
          name: Yup.string().required(t('invalid.Required')),
          url: Yup.string()
            .required(t('invalid.Required'))
            .url(
              t(
                `invalid.The URL field is invalid. Make sure to include the protocol (HTTP/HTTPS)`,
              ),
            ),
          title: Yup.string().required(t('invalid.Required')),
          description: Yup.string().required(t('invalid.Required')).max(250),
        }),
      );
    } else {
      setInitialValues({
        name: `${fromGallery && file.name}`,
        url: '',
      });
      setCreateCreativeSchema(
        Yup.object().shape({
          name: Yup.string().required(t('invalid.Required')),
          url: Yup.string()
            .required(t('invalid.Required'))
            .url(
              t(
                `invalid.The URL field is invalid. Make sure to include the protocol (HTTP/HTTPS)`,
              ),
            ),
        }),
      );
    }
  }, [creativeSizes]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
        if (fromGallery) {
          setNameAd('');
          setUrlAd('');
          setTitleAd('');
          setDescriptionAd('');
        }
      }}
      size={
        (isMissDimension || isMissFileSize) && selectedFiles?.length ? 'lg' : 'md'
      }
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t('creative:New Creative')}</h5>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={CreateCreativeSchema}
      >
        {({ errors, touched, handleChange }) => (
          <FormikForm>
            <div className="modal-body p-4 mb-0">
              {!isPopCreative && (
                <>
                  {!fromGallery ? (
                    <ImageFromUploadDisplay
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      isMissFileSize={isMissFileSize}
                      setIsMissFileSize={setIsMissFileSize}
                      isMissDimension={isMissDimension}
                      setIsMissDimension={setIsMissDimension}
                      creativeSizes={creativeSizes}
                      isNative={UploadAdFormat === 'NATIVE'}
                    />
                  ) : (
                    <div
                      style={{
                        textAlign: 'center',
                        border: '1px solid',
                      }}
                    >
                      {file && (
                        <Col className="align-self-center">
                          <ImageAutoBalance
                            href={`${config.backendUrl}creatives/display/${file.image_storage.key}`}
                            alt="ad image"
                            src={`${config.backendUrl}creatives/display/${file.image_storage.key}`}
                            sizeImage={400}
                            width={+file.size.split('x')[0]}
                            height={+file.size.split('x')[1]}
                          />
                        </Col>
                      )}
                    </div>
                  )}
                </>
              )}

              <FormGroup>
                <Label className="input-label fs-5" htmlFor="name">
                  {t('creative:Creative Name')}
                  <span className="input-required">*</span>
                </Label>
                <Field
                  name="name"
                  type="text"
                  className={`form-control  ${
                    errors.name && touched.name && 'is-invalid '
                  }`}
                  onChange={(e: any) => {
                    setNameAd(e.target.value);
                    handleChange(e);
                  }}
                  value={fromGallery ? file.name : nameAd}
                  disabled={fromGallery}
                  placeholder={t('invalid.Required')}
                />

                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-message fs-6"
                />
              </FormGroup>
              <FormGroup className="mt-2">
                <Label className="input-label fs-5" htmlFor="url">
                  {t('creative:Landing URL')}
                  <span className="input-required">*</span>
                </Label>
                <Field
                  name="url"
                  type="text"
                  className={`form-control ${
                    errors.url && touched.url && 'is-invalid '
                  } `}
                  value={url_ad}
                  onChange={(e: any) => {
                    setUrlAd(e.target.value);
                    handleChange(e);
                  }}
                  placeholder={t('invalid.Required')}
                />

                <ErrorMessage
                  name="url"
                  component="div"
                  className="invalid-message fs-6"
                />
              </FormGroup>

              {UploadAdFormat === 'NATIVE' && (
                <>
                  <FormGroup className="mt-2">
                    <Label className="input-label fs-5" htmlFor="title">
                      {t('creative:Title')}
                      <span className="input-required">*</span>
                    </Label>
                    <Field
                      name="title"
                      type="text"
                      className={`form-control ${
                        errors.title && touched.title && 'is-invalid '
                      } `}
                      value={titleAd}
                      onChange={(e: any) => {
                        setTitleAd(e.target.value);
                        handleChange(e);
                      }}
                      placeholder={t('invalid.Required')}
                    />

                    <ErrorMessage
                      name="title"
                      component="div"
                      className="invalid-message fs-6"
                    />
                  </FormGroup>
                  <FormGroup className="mt-2">
                    <Label className="input-label fs-5" htmlFor="description">
                      {t('creative:Description')}
                      <span className="input-required">*</span>
                    </Label>
                    <Field
                      name="description"
                      type="textarea"
                      as="textarea"
                      className={`form-control ${
                        errors.description && touched.description && 'is-invalid '
                      } `}
                      value={descriptionAd}
                      onChange={(e: any) => {
                        setDescriptionAd(e.target.value);
                        handleChange(e);
                      }}
                      placeholder={t('invalid.Required')}
                    />

                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-message fs-6"
                    />
                  </FormGroup>
                </>
              )}
              <div className="form-check form-switch form-switch-lg mt-3" dir="ltr">
                <label
                  className="input-label fs-5 form-check-label"
                  htmlFor="isActive"
                >
                  {t('Active')}
                </label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={isActive}
                  onClick={() => setIsActive(!isActive)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (fromGallery) {
                    setNameAd('');
                    setUrlAd('');
                  }
                }}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t('button:Cancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  (!nameAd && !file) ||
                  ((isMissDimension || isMissFileSize) &&
                    !fromGallery &&
                    !isPopCreative)
                }
              >
                {t('button:Add')}
              </button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
export default withTranslation()(ModelUploadFormNewFile);
