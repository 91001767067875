import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Column } from 'react-table';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import SearchBar from 'src/components/Table/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { getStartDateByRange } from 'src/utils/dateTime';
import DateTimeComponent from 'src/components/Common/DateTimeComponent';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { getCampaignReport as onGetCampaignReport } from '../../../store/actions';
import DateTimeTypes from '../../../constants/dateTimeTypes';

const PerformanceSummary = ({ t }: any) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(7);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  const { campaignReport, campaignDatumLoading } = useSelector((state: any) => ({
    campaignReport: state.Campaigns.campaignReport,
    campaignDatumLoading: state.Global.campaignDatumLoading,
  }));

  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      page,
      search,
      start_date: getStartDateByRange(startDate),
    };
  };

  const handleStartDateChange = (startDateRange: number) => {
    const payload = {
      ...getDefaultPayload(),
      start_date: getStartDateByRange(startDateRange),
    };
    dispatch(onGetCampaignReport(payload));
    setStartDate(startDateRange);
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };
    dispatch(onGetCampaignReport(payload));
    setPage(newPage);
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...getDefaultPayload(),
      search: searchInput,
    };
    dispatch(onGetCampaignReport(payload));
    setSearch(searchInput);
  };

  const handleClear = () => {
    setSearch('');
    const payload = {
      ...getDefaultPayload(),
      search: '',
    };
    dispatch(onGetCampaignReport(payload));
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...getDefaultPayload(),
      limit: +newPageSize,
      page: 1,
    };
    dispatch(onGetCampaignReport(payload));
    setPageSize(+newPageSize);
    setPage(1);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        sortBy: newSortBy,
        sortType: newSortType,
        page: newPage,
      };

      dispatch(onGetCampaignReport(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setPage(newPage);
    },
    [page, pageSize],
  );

  //table
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:No.'),
        accessor: (originalRow, rowIndex) => pageSize * (page - 1) + (rowIndex + 1),
        width: 100,
        id: 'id',
      },
      {
        Header: t('campaign:Campaign Name'),
        accessor: ({ campaign }) => campaign.name,
        id: 'name',
        width: 150,
      },
      {
        Header: t('table:Spend'),
        accessor: ({ currentDailyBudget }) =>
          currentDailyBudget ? floorToFixed(currentDailyBudget, 2) : 0,
        width: 150,
        id: 'currentDailyBudget',
      },
      {
        Header: t('table:Impression'),
        accessor: ({ impression }) => impression,
        width: 150,
        id: 'impression',
      },
      {
        Header: t('table:Click'),
        accessor: ({ click }) => click,
        width: 150,
        id: 'click',
      },
      {
        Header: t('CTR'),
        accessor: ({ CTR }) => (CTR ? `${floorToFixed(CTR, 2)}%` : '0%'),
        width: 150,
        id: 'CTR',
      },
      {
        Header: t('table:Recording Date'),
        accessor: ({ created_at }) => created_at,
        Cell: ({ cell }: any) => (
          <DateTimeComponent
            dateTime={cell.row.original.created_at}
            dateTimeType={DateTimeTypes.RECENT_TRANSACTION_DATE_TIME}
          />
        ),
        id: 'created_at',
        width: 150,
      },
    ],
    [t, page],
  );

  useEffect(() => {
    dispatch(onGetCampaignReport(getDefaultPayload()));
  }, []);

  return (
    <Col>
      <Card className="h-240 mb-0">
        <CardBody className="ms-2">
          <h3 className="font-size-20 mb-4">{t('Daily Performance')}</h3>
          <button
            type="button"
            className={`btn outline-sm font-size-12 ${startDate === 7 && 'active'}`}
            onClick={() => handleStartDateChange(7)}
          >
            7 {t('Days')}
          </button>
          <button
            type="button"
            className={`btn outline-sm ms-1 font-size-12  ${
              startDate === 30 && 'active'
            }`}
            onClick={() => handleStartDateChange(30)}
          >
            30 {t('Days')}
          </button>
          <button
            type="button"
            className={`btn outline-sm ms-1 font-size-12  ${
              startDate === 0 && 'active'
            }`}
            onClick={() => handleStartDateChange(0)}
          >
            {t('All')}
          </button>

          <Row className="justify-content-between m-0 mt-3">
            <Col xs={12} md={2} className="d-flex align-items-center mb-3 mb-md-0">
              <FormGroup className="flex-grow-1 flex-md-grow-0">
                <PageSizeOptions
                  defaultValue={pageSize}
                  sizes={[5, 10, 25, 50, 100]}
                  handlePageSizeChange={handlePageSizeChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={10} lg={5}>
              <Row className="align-items-center">
                <Col sm={12} md={5} lg={3} />
                <Col sm={12} md={7} lg={9}>
                  <SearchBar
                    placeholder={t('campaign:Search Campaign Name')}
                    handleSearch={handleSearch}
                    handleClear={handleClear}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="me-2">
            <CustomTable
              columns={columns}
              data={campaignReport.items || []}
              page={page}
              headerCSS="fw-normal bg-header-table"
              pageSize={pageSize}
              total={campaignReport.totalCount || 1}
              handlePageChange={handlePageChange}
              onSort={handleSort}
              isLoading={campaignDatumLoading}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default withTranslation()(PerformanceSummary);
