import { useSelector } from 'react-redux';
import { layoutTheme } from 'src/constants/layout';
import Select from 'react-select';

const Dropdown = ({ options, action, placeholder, useValue, value }: any) => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const selectStyle = {
    control: (styles: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...styles,
            backgroundColor: '#0d0f00',
            borderColor: '#474d4a',
            color: '#a3958f',
            '&:hover': {
              borderColor: '#009a78',
            },
            boxShadow: 'none',
          }
        : {
            ...styles,
            borderColor: '#ced4d9',
            '&:hover': {
              borderColor: '#009a78',
            },
            boxShadow: 'none',
          },
    option: (styles: any, optionProps: any) => {
      const newStyle = styles;
      newStyle[':active'].backgroundColor = '#009a78';
      const backgroundColor = layoutMode === layoutTheme.DARKMODE ? '#0d0f00' : '';
      return {
        ...newStyle,
        backgroundColor: optionProps.isFocused ? '#009a78' : backgroundColor,
        color: optionProps.isFocused ? 'black' : '#a3958f',
      };
    },
    menu: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            backgroundColor: '#0d0f00',
          }
        : {
            ...provided,
          },
    singleValue: (provided: any) => ({
      ...provided,
      color: '#a3958f',
    }),
  };
  return useValue ? (
    <Select
      options={options}
      placeholder={placeholder}
      onChange={action}
      styles={selectStyle}
      value={value}
    />
  ) : (
    <Select
      options={options}
      placeholder={placeholder}
      onChange={action}
      styles={selectStyle}
    />
  );
};

export default Dropdown;
