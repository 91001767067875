/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum CurrencyTypes {
  GET_CURRENCIES = '@@currency/GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS = '@@currency/GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAIL = '@@currency/GET_CURRENCIES_FAIL',

  GET_AVAILABLE_CURRENCIES = '@@currency/GET_AVAILABLE_CURRENCIES',
  GET_AVAILABLE_CURRENCIES_SUCCESS = '@@currency/GET_AVAILABLE_CURRENCIES_SUCCESS',
  GET_AVAILABLE_CURRENCIES_FAIL = '@@currency/GET_AVAILABLE_CURRENCIES_FAIL',

  TOGGLE_CURRENCY_AVAILABLE = '@@currency/TOGGLE_CURRENCY_AVAILABLE',

  GET_FILTER_CURRENCY = '@@currency/GET_FILTER_CURRENCY',
  GET_FILTER_CURRENCY_SUCCESS = '@@currency/GET_FILTER_CURRENCY_SUCCESS',

  GET_AVAILABLE_NETWORK = '@@currency/GET_AVAILABLE_NETWORK',
  GET_AVAILABLE_NETWORK_SUCCESS = '@@currency/GET_AVAILABLE_NETWORK_SUCCESS',

  GET_MARKETER_CURRENCIES = '@@currency/GET_MARKETER_CURRENCIES',
  GET_MARKETER_CURRENCIES_SUCCESS = '@@currency/GET_MARKETER_CURRENCIES_SUCCESS',

  GET_CURRENCIES_BY_MARKETER = '@@currency/GET_CURRENCIES_BY_MARKETER',
  GET_CURRENCIES_BY_MARKETER_SUCCESS = '@@currency/GET_CURRENCIES_BY_MARKETER',
}

export interface CurrencyState {
  currencies: any;
  availableCurrencies: any;
  error: Object;
  filterCurrencies: any[];
  availableNetworks: any[];
  currenciesByMarketer: any[];
}
