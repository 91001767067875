import noPostImage from 'src/assets/images/no-post-image.jpg';
import facebookAngry from 'src/assets/images/social/facebook/angry.svg';
import facebookHaha from 'src/assets/images/social/facebook/haha.svg';
import facebookLike from 'src/assets/images/social/facebook/like.svg';
import facebookLove from 'src/assets/images/social/facebook/love.svg';
import facebookSad from 'src/assets/images/social/facebook/sad.svg';
import facebookWow from 'src/assets/images/social/facebook/wow.svg';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import facebookShare from 'src/assets/images/social/facebook/share.svg';
import { useTranslation } from 'react-i18next';
import { floorToFixed, removeDecimal } from 'src/common/data/mathToFixed';

const FacebookPostView = ({ socialPost }: any) => {
  const { t } = useTranslation();

  const facebookIconFirst = [
    {
      src: facebookLike,
      alt: 'facebook like icon',
      name: 'Like',
      value: socialPost.like,
    },
    {
      src: facebookLove,
      alt: 'facebook love icon',
      name: 'Love',
      value: socialPost.love,
    },
    {
      src: facebookHaha,
      alt: 'facebook haha icon',
      name: 'Haha',
      value: socialPost.haha,
    },
  ];

  const facebookIconSecond = [
    {
      src: facebookWow,
      alt: 'facebook wow icon',
      name: 'Wow',
      value: socialPost.wow,
    },
    {
      src: facebookSad,
      alt: 'facebook sad icon',
      name: 'Sad',
      value: socialPost.sad,
    },
    {
      src: facebookAngry,
      alt: 'facebook angry icon',
      name: 'Angry',
      value: socialPost.angry,
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: '#eeeded',
          width: '100%',
          padding: 10,
          borderRadius: '8px',
          wordBreak: 'break-all',
        }}
      >
        {socialPost.source}
      </div>
      <div className="influencer-post-content">
        <div className="influencer-post-video-content">
          <div className="image-post-influencer-control">
            <img
              src={socialPost.thumbnails || noPostImage}
              alt="influencer"
              className="image-post-influencer"
            />
          </div>
          {socialPost.message && (
            <div
              style={{
                backgroundColor: '#eeeded',
                width: '100%',
                padding: 10,
                borderRadius: '8px',
                wordBreak: 'break-all',
                marginTop: '10px',
              }}
            >
              {socialPost.message}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="influencer-post-count-first">
        {facebookIconFirst.map((facebookIcon) => {
          const { src, alt, name, value } = facebookIcon;

          return !facebookIcon ? (
            <div className="count-control" key={name} />
          ) : (
            <div className="count-control" key={name}>
              <img src={src} alt={alt} className="icon" />
              <div className="text">
                {value || 0} {name}
              </div>
            </div>
          );
        })}
      </div>
      <div className="influencer-post-count-first" style={{ marginTop: '10px' }}>
        {facebookIconSecond.map((facebookIcon) => {
          const { src, alt, name, value } = facebookIcon;

          return facebookIcon.name === 'null' ? (
            <div className="count-control" key={name} />
          ) : (
            <div className="count-control" key={name}>
              <img src={src} alt={alt} className="icon" />
              <div className="text">
                {value || 0} {name}
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="influencer-impression-count">
        <ChatBubbleOutlineOutlinedIcon className="icon" />
        <div className="text">
          {removeDecimal(floorToFixed(socialPost.comment || 0, 1) as string)}{' '}
          {t('marketer:job_detail:comments')}
        </div>
        <img src={facebookShare} alt="facebookShare" className="icon" />
        <div className="text">
          {removeDecimal(floorToFixed(socialPost.share || 0, 1) as string)}{' '}
          {t('marketer:job_detail:share')}
        </div>
      </div>
    </>
  );
};

export default FacebookPostView;
