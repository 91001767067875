import './GleamJob.style.scss';
import ContentType from 'src/constants/enum/contentType';
import JobDetailPost from '../Publisher/Influencer/JobDetail/jobDetailPost';
import GleamJobImagePreview from './GleamJobImagePreview';

const GleamJobTaskPost = ({ socialHasJob }: any) => {
  const old_content = [ContentType.PHOTO, ContentType.SHARE, ContentType.VIDEO];
  // eslint-disable-next-line no-unused-vars
  const new_content = [
    ContentType.LIKE_AND_COMMENT,
    ContentType.REVIEW,
    ContentType.SUBSCRIBE,
  ];

  const rendorDetail = () => {
    const { content_type, socialmedia_type } = socialHasJob?.job_task?.task_type;
    if (socialmedia_type === 'NOSOCIAL') {
      return <GleamJobImagePreview socialHasJob={socialHasJob} />;
    } else if (
      old_content.includes(content_type) &&
      socialmedia_type !== 'TWITTER' &&
      socialmedia_type !== 'FACEBOOK' &&
      socialmedia_type !== 'INSTAGRAM' &&
      socialmedia_type !== 'YOUTUBE' &&
      socialmedia_type !== 'TIKTOK'
    ) {
      return <JobDetailPost socialJobItem={socialHasJob} />;
    } else {
      return <GleamJobImagePreview socialHasJob={socialHasJob} />;
    }
  };

  return <div className="">{rendorDetail()}</div>;
};

export default GleamJobTaskPost;
