import { Formik, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';
import capitalize from 'src/utils/captilalize';
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import { Card, Label, Progress } from 'reactstrap';
import './MarketerJobCampaignDetail.style.scss';
import './stepper.style.scss';
import stepCreate from 'src/assets/images/Step1.png';
import Trash from 'src/assets/images/Trash-01.png';
import stepSummary from 'src/assets/images/Step4.png';
import stepChooseInfu from 'src/assets/images/Step3.png';
import stepSelectSocial from 'src/assets/images/Step2.png';
import CustomButton from 'src/components/Common/CustomButton';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';

import config from 'src/config';

import control from './CreateMarketerJobCampaignPageNewstep4.control';
import MockJobRequirementTable from '../jobSummary/mockJobRequirementTable';

const CreateMarketerJobCampaignPageNewstep4 = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    validationSchema,
    inputs,
    marketerJob,
    formValues,
    setFormValues,
  } = control();

  const stepDisplay = () => {
    return (
      <div className="stepper-container">
        <>
          <img src={stepCreate} alt="stepCreate" className="stepper-img" />

          <div className="stepper-dot" />
        </>
        {marketerJob.job_type === 'INVITE_ONLY' && (
          <>
            <img src={stepSelectSocial} alt="stepCreate" className="stepper-img" />

            <div className="stepper-dot" />
          </>
        )}
        <>
          <img src={stepChooseInfu} alt="stepChooseInfu" className="stepper-img" />

          <div className="stepper-dot" />
        </>

        <img src={stepSummary} alt="stepSummary" className="stepper-img" />
      </div>
    );
  };

  return (
    <div className="page-content text-center">
      <div className="stepper-progressbar-container">
        <div style={{ marginBottom: '38px' }}>{stepDisplay()}</div>
        <Progress
          style={{
            transform: 'scale(0.8)',
            height: '25px',
            width: '100%',
            borderRadius: '44px',
            background: '#D9D9D9',
          }}
          max="3"
          value="3"
        />
        <div
          style={{
            marginTop: '24px',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '20px',
            letterSpacing: '0em',
            color: '#495057',
          }}
        >
          {marketerJob.job_type === 'INVITE_ONLY'
            ? t('marketer:create_job_campaign:Campaign step4')
            : t('marketer:create_job_campaign:Campaign step3')}
        </div>
      </div>

      <div className="job-detail-container">
        <div className="card-container">
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={() => {
              return Yup.object(validationSchema);
            }}
          >
            {({ errors, touched, handleChange }: any) => {
              return (
                <>
                  <FormikForm>
                    {inputs(errors, touched, handleChange)}
                    <div
                      className="mt-5 text-center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <CustomButton
                        status="submit"
                        size="s"
                        label={t('marketer:create_job_campaign:Submit')}
                      />
                    </div>
                  </FormikForm>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateMarketerJobCampaignPageNewstep4;
