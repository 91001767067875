import { isEmpty } from 'lodash';

export const getInstagramPageIds = async (access_token: string) => {
  try {
    const url: string = `https://graph.facebook.com/v15.0/me/accounts?access_token=${access_token}`;

    const response = await fetch(url, {
      method: 'GET',
    });

    const responseText: string = await response.text();
    const { data }: any = JSON.parse(responseText);
    if (!data) return [];
    const pageId = data.map((datum: any) => datum?.id);
    return pageId;
  } catch (error) {
    return [];
  }
};

export const getInstagramUserId = async (
  access_token: string,
  page_ids: number[],
) => {
  const promisesResponses = [];
  let error_message: string = '';
  for (let i = 0; i < page_ids?.length; i++) {
    const page_id = page_ids[i];
    try {
      const url: string = `https://graph.facebook.com/v15.0/${page_id}?fields=instagram_business_account&access_token=${access_token}`;

      const responses = fetch(url, {
        method: 'GET',
      });
      promisesResponses.push(responses);
    } catch (error: any) {
      error_message = error?.message;
      promisesResponses.push(null);
    }
  }

  const responses: any = await Promise.all(promisesResponses);

  const promiseTexts = [];
  for (let i = 0; i < responses?.length; i++) {
    const response = responses[i];
    if (response) {
      const text = response?.text();
      promiseTexts.push(text);
    }
  }
  const texts = await Promise.all(promiseTexts);

  const user_ids = [];
  for (let i = 0; i < texts?.length; i++) {
    const text = texts[i];
    const jsonData: any = JSON.parse(text);
    user_ids.push(jsonData?.instagram_business_account?.id);
  }

  return { user_ids, error_message };
};

export const getInstagramUserData = async (
  access_token: string,
  user_ids: number[],
) => {
  const promisesResponses = [];
  let error_message = '';

  for (let i = 0; i < user_ids?.length; i++) {
    const user_id = user_ids[i];
    if (user_id) {
      try {
        const url: string = `https://graph.facebook.com/v15.0/${user_id}?fields=id,name,followers_count,media_count,profile_picture_url,username&access_token=${access_token}`;

        const responses = fetch(url, {
          method: 'GET',
        });
        promisesResponses.push(responses);
      } catch (error: any) {
        error_message = error?.message;
        promisesResponses.push(null);
      }
    }
  }

  if (promisesResponses?.length === 0) {
    error_message = 'Please select Instagram business.';
  }

  if (!isEmpty(error_message)) {
    return { user_data: [], getInstagramUserData_error: error_message };
  }

  const responses: any = await Promise.all(promisesResponses);

  const promiseTexts = [];
  for (let i = 0; i < responses?.length; i++) {
    const response = responses[i];
    if (response) {
      const text = response?.text();
      promiseTexts.push(text);
    }
  }
  const texts = await Promise.all(promiseTexts);

  const user_data = [];
  for (let i = 0; i < texts?.length; i++) {
    const text = texts[i];
    const jsonData: any = JSON.parse(text);
    if (!jsonData?.error) {
      user_data.push(jsonData);
    }
  }
  return { user_data, getInstagramUserData_error: error_message };
};
