import React from 'react';
import { withTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import WithdrawStatuses from '../../../constants/withdrawStatuses';

const WebsiteStatus = ({ status, t }: any) => {
  const renderStatus = () => {
    switch (status) {
      case WithdrawStatuses.PENDING:
        return <Badge className="bg-warning">{t(`table:${status}`)}</Badge>;
      case WithdrawStatuses.APPROVED:
        return <Badge className="bg-success">{t(`table:${status}`)}</Badge>;
      case WithdrawStatuses.REJECTED:
        return <Badge className="bg-danger">{t(`table:${status}`)}</Badge>;
      default:
        return <p className="bg-warning text-light">{t(`table:${status}`)}</p>;
    }
  };

  return <>{renderStatus()}</>;
};

export default withTranslation()(WebsiteStatus);
