import { gql } from '@apollo/client';
import { client } from '../client';
import { MovementTransactionsQuery } from '../../common/types';

const getMovementBalanceTransactions = async (
  queryInput: MovementTransactionsQuery,
) => {
  const GET_MOVEMENT_BALANCE_TRANSACTIONS = gql`
    query GetMovementBalanceTransactions($input: MovementQueryInput!) {
      getMovementBalanceTransactions(input: $input) {
        items {
          uuid
          transaction_type_id
          opening_balance
          sign
          checksum_status
          locked_key
          amount
          created_at
          withdraw_transaction {
            uuid
            tx_hash
            address
            CRYPTO_amount
            FIAT_amount
            FIAT_rate
            created_at
            updated_at
            status
            checksum_status
            currency {
              name
              symbol
              main_symbol
              explorer_link
            }
            user {
              uuid
              email
              role
            }
          }
          deposit_transaction {
            uuid
            tx_hash
            address
            CRYPTO_amount
            FIAT_amount
            FIAT_rate
            created_at
            updated_at
            checksum_status
            currency {
              name
              symbol
              main_symbol
              explorer_link
            }
            user {
              uuid
              role
              email
            }
          }
          advertiser_payment_transaction {
            id
            FIAT_amount
            uuid
            user {
              uuid
              role
              email
            }
            campaignDatums {
              dailyImpression
              CPM
              creativeCampaignDatums {
                uuid
                dailyImpression
                currentDailyBudget
                creativeCampaign {
                  name
                }
                creative {
                  name
                  size
                  image_storage {
                    key
                  }
                }
              }
            }
            campaign {
              id
              name
              CPM
              user {
                email
              }
            }
            social_media_has_job {
              job {
                currency {
                  main_symbol
                  symbol
                }
              }
            }
            created_at
          }
          publisher_earning_transaction {
            id
            FIAT_amount
            uuid
            social_media_has_job_id
            user {
              uuid
              role
              email
            }
            website {
              url
            }
            created_at
          }
          referral_earning_transaction {
            id
            uuid
            amount
            created_at
            referral {
              parent {
                email
                role
              }
              child {
                email
                role
              }
            }
          }
        }
        totalCount
      }
    }
  `;
  try {
    const response: any = await client.query({
      query: GET_MOVEMENT_BALANCE_TRANSACTIONS,
      variables: {
        input: queryInput,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.getMovementBalanceTransactions;
  } catch (error: any) {
    return null;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getMovementBalanceTransactions };
