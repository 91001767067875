import { useState } from 'react';
import { Col } from 'reactstrap';
import useWindowDimensions from '../useWindowDimensions';
import ApplicationCreativeSize from './ApplicationCreativeSize';
import ScriptAppIndex from './scriptAppIndex';

const AdUnit = () => {
  const [appCreativeFilter, setAppCreativeFilter] = useState({});
  const { windowWidth } = useWindowDimensions();

  const renderCardWebOnLg = (index: number) => {
    let menuWidth = 0;
    if (document.getElementsByClassName('vertical-menu')?.length) {
      menuWidth = document.getElementsByClassName('vertical-menu')[0].clientWidth;
    }
    if (windowWidth - menuWidth < 1000) return 12;
    return index === 1 ? 4 : 8;
  };
  return (
    <>
      <Col xs={12} md={5} lg={renderCardWebOnLg(1)}>
        <ApplicationCreativeSize appCreativeFilter={appCreativeFilter} />
      </Col>
      <Col xs={12} md={7} lg={renderCardWebOnLg(2)}>
        <ScriptAppIndex
          setAppCreativeFilter={setAppCreativeFilter}
          scriptError={false}
        />
      </Col>
    </>
  );
};

export default AdUnit;
