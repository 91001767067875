import { gql } from '@apollo/client';
import { client } from './client';

const GET_CAMPAIGNS = gql`
  query campaigns {
    campaigns {
      campaign_key
      name
      url
      CPM
      budget
      status
      ad_format
      device_platform
      banner_format
    }
  }
`;

// Get Campaign
const getCampaign = () => {
  client.query({
    query: GET_CAMPAIGNS,
  });
};

const createCampaign = () => {};

export { getCampaign, createCampaign };
