import { UsersTypes } from './actionTypes';
import { UsersQuery } from '../../common/types';

export const getUsers = (payload: UsersQuery) => {
  return {
    type: UsersTypes.GET_USERS,
    payload: { ...payload },
  };
};

export const removeUserStore = () => ({
  type: UsersTypes.REMOVE_USER_STORE,
});

export const getUserDetail = (payload: { id: string }) => {
  return {
    type: UsersTypes.GET_USER_DETAIL,
    payload: { ...payload },
  };
};

export const getUsersSuccess = (users: any) => {
  return {
    type: UsersTypes.GET_USERS_SUCCESS,
    payload: users,
  };
};

export const getUserDetailsSuccess = (userDetail: any) => {
  return {
    type: UsersTypes.GET_USER_DETAIL_SUCCESS,
    payload: userDetail,
  };
};

export const getUsersFail = (error: any) => {
  return {
    type: UsersTypes.GET_USERS_FAIL,
    payload: error,
  };
};

export const changeTimezone = (timezone: string) => {
  return {
    type: UsersTypes.CHANGE_TIMEZONE,
    payload: timezone,
  };
};

export const createUserInfo = (payload: any) => ({
  type: UsersTypes.CREATE_USER_INFO,
  payload,
});

export const createUserInfoSuccess = (payload: any) => ({
  type: UsersTypes.CREATE_USER_INFO_SUCCESS,
  payload,
});

export const getUserInfo = (unLoading?: boolean) => ({
  type: UsersTypes.GET_USER_INFO,
  unLoading,
});

export const getUserInfoSuccess = (payload: any) => ({
  type: UsersTypes.GET_USER_INFO_SUCCESS,
  payload,
});

export const updateUserInfo = (payload: {
  phone_number?: string;
  // user_name?: string;
  name?: string;
  gender?: string;
  isLGBT?: boolean;
  nationality?: string;
  date_of_birth?: Date;
  address?: string;
  postcode?: string;
  province?: string;
  country?: string;
  address_phone_number?: string;
  timezone?: string;
  timezone_offset?: number;
}) => ({
  type: UsersTypes.UPDATE_USER_INFO,
  payload,
});

export const updateUserInfoSuccess = (payload: any) => ({
  type: UsersTypes.UPDATE_USER_INFO_SUCCESS,
  payload,
});

export const uploadProfile = (payload: any) => ({
  type: UsersTypes.UPLOAD_PROFILE,
  payload,
});

export const delete2FA = (payload: any) => ({
  type: UsersTypes.DELETE_2FA,
  payload,
});
