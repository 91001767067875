import { ReferralTypes } from './actionTypes';
import { ReferralTransactionsQuery } from '../../common/types';

export const getReferralEmail = (ref: any) => {
  return {
    type: ReferralTypes.GET_REFERRAL_EMAIL,
    payload: ref,
  };
};

export const getReferralEmailSuccess = (email: any) => {
  return {
    type: ReferralTypes.GET_REFERRAL_EMAIL_SUCCESS,
    payload: email,
  };
};

export const getReferralTransactions = (payload: ReferralTransactionsQuery) => {
  return {
    type: ReferralTypes.GET_REFERRAL_TRANSACTIONS,
    payload: { ...payload },
  };
};

export const getReferralTransactionsSuccess = (referralTransactions: any) => ({
  type: ReferralTypes.GET_REFERRAL_TRANSACTIONS_SUCCESS,
  payload: referralTransactions,
});

export const getReferralFail = (error: any) => {
  return {
    type: ReferralTypes.GET_REFERRAL_FAIL,
    payload: error,
  };
};

export const getSystemTaxReferral = () => ({
  type: ReferralTypes.GET_REFERRAL_SYSTEM_TAX,
});

export const getSystemTaxReferralSuccess = (payload: any) => ({
  type: ReferralTypes.GET_REFERRAL_SYSTEM_TAX_SUCCESS,
  payload,
});
