import React, { useEffect } from 'react';

import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getAllDepositTransactionsRequest as onGetAllDepositTransactionsRequest } from '../../store/finance/deposit/actions';
import DepositTransactionsTable from '../../components/Admin/DepositTransactionsTable';
import menu from '../../constants/menu';

const DepositPage = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetAllDepositTransactionsRequest({ limit: 10, page: 1 }));
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Form Validation | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.External Deposit Transactions')}
            menus={[{ menu: menu.DASHBOARD }, { menu: menu.DEPOSIT, active: true }]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DepositTransactionsTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(DepositPage);
