import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import paramsFromURL from 'src/constants/paramsFromURL';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLoggedInUser,
  getMarketerJobSummary,
  getSocialGleamJob,
  getSocialGleamJobForReview,
} from 'src/store/actions';
import useWindowSize from 'src/utils/windowSize';
import { useHistory } from 'react-router';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { formatDateForm } from 'src/utils/dateTime';
import { useTranslation } from 'react-i18next';
import Role from 'src/constants/enum/role';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import ChatDetails from './ChatDetails';
import GleamJobTaskMarketer from './GleamJobTaskMarketer';
import { InfluencerSocialIcon } from './status/InfluencerJobStatus';
import './ChatDetails.style.scss';
import GleamJobTaskHeaderInfluencer from './GleamJobTaskHeaderInfluencer';
import GleamJobTaskMarketerChat from './GleamJobTaskMarketerChat';

const ChatWraper = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const history = useHistory();
  const [jobId, group, social_key] = paramsFromURL(['job', 'task', 'key']);
  const { task, jobSummay, user, socialGleamJboForReview } = useSelector(
    (state: any) => ({
      jobSummay: state?.MarketerJob?.marketerJob,
      socialGleamJboForReview: state.SocialMediaInfo.socialGleamJboForReview,
      user: state.login.user,
      task: state.SocialMediaInfo.socialHasJobTask,
    }),
  );
  const [progress, setProgress] = useState(0);
  const [totalTask, setTotalTask] = useState(0);
  const [paid, setPaid] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [status, setStatus] = useState('');
  const [data, setData] = useState<any>([]);
  const [role, setRole] = useState<any>(user.Role);
  const [round, setRound] = useState(0);
  const [jobTask, setjobTask] = useState([]);
  const [selectInfo, setSelectInfo] = useState(false);

  useEffect(() => {
    if (task?.length) {
      const taskk = task.filter(
        (value: any) => social_key === value.social_info.key,
      );
      if (taskk?.length) {
        setjobTask(taskk);
      }
    }
  }, [task]);

  useEffect(() => {
    // dispatch(getLoggedInUser(''));
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
    }

    if (group && social_key && user.role === 'ADVERTISER') {
      dispatch(getSocialGleamJobForReview({ key: group, social_key }));
    }
  }, []);
  useEffect(() => {
    if (socialGleamJboForReview) {
      let _progress = 0;
      let total_progress = 0;
      let _paid = 0;
      let total_paid = 0;
      let _status = 'CANCEL';
      for (let i = 0; i < socialGleamJboForReview?.socialHasJob?.length; i++) {
        if (socialGleamJboForReview?.socialHasJob[i]?.post_date) {
          _progress += 1;
          _paid +=
            socialGleamJboForReview?.socialHasJob[i]?.price +
            socialGleamJboForReview?.socialHasJob[i]?.tax;
        }
        total_paid +=
          socialGleamJboForReview?.socialHasJob[i]?.price +
          socialGleamJboForReview?.socialHasJob[i]?.tax;
        total_progress += 1;
        if (socialGleamJboForReview?.socialHasJob[i].status !== 'CANCEL') {
          _status = socialGleamJboForReview?.socialHasJob[i].status;
        }
      }

      setProgress(_progress);
      setPaid(_paid);
      setTotalPrice(total_paid);
      setTotalTask(total_progress);
      setStatus(_status);
    }
    if (round !== 0) {
      setData(socialGleamJboForReview);
    }
    setRound(round + 1);
  }, [socialGleamJboForReview]);

  const onGoBack = () => {
    // history.replace({
    //   pathname: `/campaigns/markerter/job/publishDetail?job=${jobId}`,
    // });
    // history.push(`/campaigns/markerter/job/publishDetail?job=${jobId}`);
    // state: { from: 'fromSignUp' },history.push({    // no need
    // console.log('ddsdsd,', user.role);
    if (user.role === 'ADVERTISER') {
      history.push(
        `/campaigns/markerter/job/publishDetail?job=${jobId}&tab=${'ReviewJob'}`,
      );
    } else {
      history.push(`/JobDetail?job=${jobId}&task=${group}`);
    }
  };

  return (
    <div
      style={{
        padding: '15px',
        marginTop: `${
          user.role === 'ADVERTISER' || user.role === 'MARKETER' ? '0px' : '100px'
        }`,
        backgroundColor: '#1a1a1a',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginLeft: '40px',
        }}
      >
        <i
          className="fa fa-chevron-left fa-icon"
          onClick={() => onGoBack()}
          style={{ cursor: 'pointer', paddingLeft: '0', color: 'white' }}
        />{' '}
        <div style={{ cursor: 'pointer', paddingLeft: '0', color: 'white' }}>
          {t('Chat')}
        </div>
      </div>
      {width > 992 && (
        <Row>
          {user.role === 'ADVERTISER' && (
            <>
              <Col lg="7" md="12" xs="12">
                <div className="gleam-job-component gleam-margin">
                  <div className="gleam-job-panel" style={{ maxWidth: '1000px' }}>
                    <Row
                      style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                        minHeight: '55px',
                      }}
                    >
                      <Col
                        className="gleam-job-header"
                        style={{
                          borderBottomColor: 'rgb(83, 198, 183)',
                          borderRight: '1px solid #e9edee',
                        }}
                      >
                        <div style={{ padding: '4px' }}>
                          <h6>{t('table:Progress')}</h6>
                          <h5>
                            {progress} / {totalTask}
                          </h5>
                        </div>
                      </Col>
                      <Col
                        className="gleam-job-header"
                        style={{
                          borderBottomColor: '#495057',
                          borderRight: '1px solid #e9edee',
                        }}
                      >
                        <div style={{ padding: '4px' }}>
                          <h6>{t('Review and approve with in')}</h6>
                          <h5>{formatDateForm(jobSummay?.last_day_to_approve)}</h5>
                        </div>
                      </Col>
                      <Col
                        className="gleam-job-header"
                        style={{ borderBottomColor: 'rgb(255, 188, 84)' }}
                      >
                        <div style={{ padding: '4px' }}>
                          <h6>{t('marketer:create_campaign:Usage_Prize')}</h6>
                          <h5>
                            {floorToFixed(paid, 2)} / {floorToFixed(totalPrice, 2)}
                            {jobSummay?.currency?.main_symbol ?? 'USDT'}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginLeft: '0px',
                        marginRight: '0px',
                        height: '66.67px',
                      }}
                    >
                      <Col
                        className="gleam-job-header"
                        style={{
                          borderBottomColor: 'rgb(255, 188, 84)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <InfluencerSocialIcon
                          social_media_type={
                            socialGleamJboForReview?.social_info?.social_media_type
                          }
                        />
                        <img
                          style={{
                            width: `30px`,
                            height: `30px`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          src={
                            socialGleamJboForReview?.social_info
                              ?.image_profile_url || userAvatar
                          }
                          alt="Influencer Profile"
                          onError={(e) => {
                            e.currentTarget.src = userAvatar;
                          }}
                        />
                        <div>
                          <h5 style={{ marginBottom: '0' }}>
                            {socialGleamJboForReview?.social_info?.name}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                    <div style={{ marginTop: '11px' }}>
                      <div
                        className="gleam-job-task-container"
                        style={{
                          borderRadius: '6px',
                          paddingBottom: '0',
                        }}
                      >
                        {data?.socialHasJob?.map((job: any) => {
                          return <GleamJobTaskMarketerChat job_tasks={job} open />;
                        })}
                      </div>
                      {/* {socialJobTasks?.map((item: any) => {
                  return (
                    <div>
                      <GleamJobTaskHeaderInfluencer jobTask={item} />
                    </div>
                  );
                })} */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="5" md="12" xs="12">
                <ChatDetails />
              </Col>
            </>
          )}
          {user.role === 'PUBLISHER' && (
            <>
              <Col lg="6" md="12" xs="12">
                {jobTask &&
                  jobTask.map((_jobTask: any) => {
                    return <GleamJobTaskHeaderInfluencer jobTask={_jobTask} />;
                  })}
              </Col>
              <Col lg="6" md="12" xs="12">
                <ChatDetails />
              </Col>
            </>
          )}
        </Row>
      )}
      {width < 992 && (
        <div>
          {selectInfo === true ? (
            <>
              {user.role === 'ADVERTISER' && (
                <>
                  <Col lg="7" md="12" xs="12">
                    <div className="gleam-job-component gleam-margin">
                      <div
                        className="gleam-job-panel"
                        style={{ maxWidth: '1000px' }}
                      >
                        <Row
                          style={{
                            marginLeft: '0px',
                            marginRight: '0px',
                            minHeight: '55px',
                          }}
                        >
                          <Col
                            className="gleam-job-header"
                            style={{
                              borderBottomColor: 'rgb(83, 198, 183)',
                              borderRight: '1px solid #e9edee',
                            }}
                          >
                            <div style={{ padding: '4px' }}>
                              <h6>{t('table:Progress')}</h6>
                              <h5>
                                {progress} / {totalTask}
                              </h5>
                            </div>
                          </Col>
                          <Col
                            className="gleam-job-header"
                            style={{
                              borderBottomColor: '#495057',
                              borderRight: '1px solid #e9edee',
                            }}
                          >
                            <div style={{ padding: '4px' }}>
                              <h6>{t('Review and approve with in')}</h6>
                              <h5>
                                {formatDateForm(jobSummay?.last_day_to_approve)}
                              </h5>
                            </div>
                          </Col>
                          <Col
                            className="gleam-job-header"
                            style={{ borderBottomColor: 'rgb(255, 188, 84)' }}
                          >
                            <div style={{ padding: '4px' }}>
                              <h6>{t('marketer:create_campaign:Usage_Prize')}</h6>
                              <h5>
                                {floorToFixed(paid, 2)} /{' '}
                                {floorToFixed(totalPrice, 2)}
                                {jobSummay?.currency?.main_symbol ?? 'USDT'}
                              </h5>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginLeft: '0px',
                            marginRight: '0px',
                            height: '66.67px',
                          }}
                        >
                          <Col
                            className="gleam-job-header"
                            style={{
                              borderBottomColor: 'rgb(255, 188, 84)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <InfluencerSocialIcon
                              social_media_type={
                                socialGleamJboForReview?.social_info
                                  ?.social_media_type
                              }
                            />
                            <img
                              style={{
                                width: `30px`,
                                height: `30px`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                              src={
                                socialGleamJboForReview?.social_info
                                  ?.image_profile_url || userAvatar
                              }
                              alt="Influencer Profile"
                              onError={(e) => {
                                e.currentTarget.src = userAvatar;
                              }}
                            />
                            <div>
                              <h5 style={{ marginBottom: '0' }}>
                                {socialGleamJboForReview?.social_info?.name}
                              </h5>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ marginTop: '11px' }}>
                          <div
                            className="gleam-job-task-container"
                            style={{
                              borderRadius: '6px',
                              paddingBottom: '0',
                            }}
                          >
                            {data?.socialHasJob?.map((job: any) => {
                              return (
                                <GleamJobTaskMarketerChat job_tasks={job} open />
                              );
                            })}
                          </div>
                          {/* {socialJobTasks?.map((item: any) => {
                  return (
                    <div>
                      <GleamJobTaskHeaderInfluencer jobTask={item} />
                    </div>
                  );
                })} */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )}
              {user.role === 'PUBLISHER' && (
                <>
                  <Col lg="6" md="12" xs="12">
                    {jobTask &&
                      jobTask.map((_jobTask: any) => {
                        return <GleamJobTaskHeaderInfluencer jobTask={_jobTask} />;
                      })}
                  </Col>
                </>
              )}
            </>
          ) : (
            <Col lg="7" md="12" xs="12">
              <ChatDetails />
            </Col>
          )}
          <div className="toggle-change-detail select-info">
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setSelectInfo(true)}
            >
              <span>
                <i className="fas fa-info" style={{ marginRight: '5px' }} />
              </span>
              <span>{t('Information')}</span>
            </div>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setSelectInfo(false)}
            >
              <span>
                <i className="fas fa-solid fa-comment " />
              </span>
              <span> {t('Chat')}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWraper;
