import BanType from './actionTypes';

export const getBanHistory = (payload: any) => ({
  type: BanType.GET_BAN_HISTORY,
  payload,
});

export const getBanHistorySuccess = (payload: any) => ({
  type: BanType.GET_BAN_HISTORY_SUCCESS,
  payload,
});

export const checkIsBanned = () => ({
  type: BanType.CHECK_IS_BANNED,
});

export const checkIsBannedSuccess = (payload: any) => ({
  type: BanType.CHECK_IS_BANNED_SUCCESS,
  payload,
});
