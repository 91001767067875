/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import {
  getAllAdminApproveLog,
  getReportJob,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import { Dialog, DialogContent } from '@mui/material';
import {
  updateSocialMediaHasGleamJobGql,
  updateSocialMediaHasJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import SearchBar from 'src/components/Table/SearchBar';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import { banReportJobSQL, ignoreReportJobGQL } from 'src/graphql/advertiser/job';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';

const BanUserTransactionPage = ({ t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: null,
    sortBy: null,
    sortType: null,
    search: null,
  });

  const { txn } = useSelector((state: any) => ({
    txn: state.MarketerJob.reportJob,
  }));

  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  useEffect(() => {
    fetchApproveLog();
    dispatch(getReportJob(getDefaultPayload(1)));
  }, []);

  const clickBanJob = async (job_id: number) => {
    await Swal.fire({
      title: t('Ban'),
      text: t(`Tell marketer why you ban ( If any )`),
      input: 'textarea',
      inputPlaceholder: 'input here',
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      width: 600,
      imageHeight: 200,
      imageAlt: 'A tall image',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: '#009a78',
      customClass: {
        container: 'accept-swal',
      },
      confirmButtonText: t('swal:confirm'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const reason = result.value;
        try {
          dispatch(setLoading());
          const res = await banReportJobSQL({ job_id, ban_reason: reason });
          if (res) {
            dispatch(getReportJob(getDefaultPayload(1)));
            notify(i18n.t('success:ban job success'), 'success');
            dispatch(unsetLoading());
          } else {
            notify(i18n.t('error:ignore job fail'), 'error');
            dispatch(unsetLoading());
          }
        } catch (error) {
          notify(i18n.t('error:ignore job fail'), 'error');
          dispatch(unsetLoading());
        }
      }
    });
  };

  const clickIgnoreJob = async (job_id: number) => {
    try {
      dispatch(setLoading());
      const res = await ignoreReportJobGQL(job_id);
      if (res) {
        dispatch(getReportJob(getDefaultPayload(1)));
        notify(i18n.t('success:ignore job success'), 'success');
        dispatch(unsetLoading());
      } else {
        notify(i18n.t('error:ignore job fail'), 'error');
        dispatch(unsetLoading());
      }
    } catch (error) {
      notify(i18n.t('error:ignore job fail'), 'error');
      dispatch(unsetLoading());
    }
  };

  const renderReportStatus = (status: string, report_status: string) => {
    return report_status ? t(report_status) : t(status);
  };

  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('marketer:job_detail:Job_name'),
        accessor: ({ name }) => {
          return name;
        },
        id: 'job_name',
        disableSort: true,
      },
      {
        Header: t('table:Marketer Email'),
        accessor: ({ marketer_campaign }: any) => {
          return (
            <a href={`/admin/users/${marketer_campaign.user.uuid}`} target="blank">
              {marketer_campaign.user.email}
            </a>
          );
        },
        id: 'marketer_email',
        disableSort: true,
      },
      {
        Header: t('table:Report Count'),
        accessor: ({ reportJobCount }: any) => {
          return reportJobCount;
        },
        id: 'reportJobCount',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:view detail'),
        accessor: ({ key }: any) => {
          const path = `/admin/reportJobDetail/${key}`;
          return (
            <a className="cursor  fw-bold view-detail" href={path} target="blank">
              {t(`table:view detail`)}
            </a>
          );
        },
        id: 'view_detail_influencers',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ report_status, status, id }: any) =>
          status === 'FINISHED' || report_status ? (
            renderReportStatus(status, report_status)
          ) : (
            <UncontrolledDropdown className="">
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <i className="bx bx-pencil" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    clickBanJob(id);
                  }}
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('Ban')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    clickIgnoreJob(id);
                  }}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('Ignore')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(1),
      page: newPage,
    };

    dispatch(getReportJob(payload));
    setPage(newPage);
    setParam(payload);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getReportJob(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(getReportJob(payload));
    setSearch(val);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(getReportJob(payload));
    setSearch('');
    setParam(payload);
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getReportJob(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [search, page, pageSize],
  );

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('report_job');
    dispatch(getAllAdminApproveLog());
  };

  if (!txn) return <></>;

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SearchBar
              placeholder={t('table:Search Name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(BanUserTransactionPage);
