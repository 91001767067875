import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import config from 'src/config';
import ImageAutoBalance from 'src/components/Common/ImageAutoBalance';
import choice from 'src/assets/images/choice.png';
import { useParams } from 'react-router';
import {
  removeCreativeCampaignGraphql,
  updateCreativeCampaignGraphql,
} from '../../../graphql/advertiser/creative';
import VerifyStatus from '../../../constants/verifyStatusEnum';
import CustomTable from '../../Common/CustomTable';
import UploadCreativeDisplay from '../UploadCreatives/UploadCreativeDisplay';
import {
  getCreativesCampaign as onGetCreativesCampaign,
  setCreativeCampaignsLoading,
  unsetCreativeCampaignsLoading,
} from '../../../store/actions';

const Creative = ({ t }: any) => {
  const { id }: any = useParams();

  //from react
  const dispatch = useDispatch();
  const [isActiveCheckbox, setIsActiveCheckbox] = useState<any>({});
  //redux
  const { creativesCampaign, creativeCampaignsLoading } = useSelector(
    (state: any) => ({
      creativesCampaign: state.Creatives.creativeCampaign,
      creativeCampaignsLoading: state.Global.creativeCampaignsLoading,
    }),
  );
  //table

  const changeActiveCreativeCampaign = (e: any, index: number, key: string) => {
    Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(
        `swal:Your creative will be ${
          isActiveCheckbox[`checkbox${index}`] ? 'not active' : 'active'
        }`,
      ),
      width: 300,
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const cloneActive = { ...isActiveCheckbox };

        setIsActiveCheckbox(!cloneActive[`checkbox${index}`]);

        dispatch(setCreativeCampaignsLoading());
        await updateCreativeCampaignGraphql({
          key,
          isActive: !cloneActive[`checkbox${index}`],
        });
        dispatch(onGetCreativesCampaign(id));
        dispatch(unsetCreativeCampaignsLoading());
      }
    });
  };

  const columnsPop: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('tableCreative:Creative'),
        accessor: ({ name }) => name,
        rowCss: 'mt-3',
        id: 'Creative Id',
        width: 150,
      },
      {
        Header: t('Active'),
        accessor: ({ verifyStatus }) => verifyStatus,
        Cell: ({ cell }: any) => (
          <div
            className="form-check-center form-switch form-switch-lg mt-2"
            dir="ltr"
          >
            <input
              disabled={cell.row.original.verifyStatus !== VerifyStatus[1]}
              type="checkbox"
              defaultChecked={cell.row.original.isActive}
              className="form-check-input "
              onClick={(e) => {
                e.preventDefault();
                changeActiveCreativeCampaign(
                  e,
                  cell.row.index,
                  cell.row.original.key,
                );
              }}
            />
          </div>
        ),
        width: 150,
        id: 'Active',
      },
      {
        Header: 'URL',
        accessor: ({ url_ad }) => url_ad,
        Cell: ({ cell }: any) => {
          const { url_ad } = cell.row.original;
          const urlOnclick = url_ad;
          const arrUrlAd = url_ad.match(/.{1,35}/g);
          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {arrUrlAd[0]} {urlOnclick?.length > 35 && '...'}
            </a>
          );
        },
        rowCss: 'mt-3',
        width: 180,
        id: 'URL',
      },
      {
        Header: t('table:Action'),
        accessor: (creative) => (
          <div className="mt-3">
            <div className="avatar-40 inline-block">
              <span
                className="avatar-bin bg-gainsboro rounded-circle"
                onKeyDown={() => {}}
                onClick={async () => {
                  await Swal.fire({
                    width: 300,
                    title: t('swal:Are you sure ?'),
                    text: t("swal:You won't be able to revert this!"),
                    imageUrl: choice,
                    imageHeight: 200,
                    customClass: {},
                    imageAlt: 'A tall image',
                    cancelButtonColor: 'red',
                    showCancelButton: true,
                    confirmButtonColor: '#009a78',
                    confirmButtonText: t('button:Yes'),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      dispatch(setCreativeCampaignsLoading());
                      await removeCreativeCampaignGraphql(creative.key);
                      dispatch(onGetCreativesCampaign(id));
                      dispatch(unsetCreativeCampaignsLoading());
                    }
                  });
                }}
                role="button"
                tabIndex={0}
              >
                <i className="fas fa-trash font-size-16 text-primary" />
              </span>
            </div>
          </div>
        ),
        disableSort: true,
        id: 'Action',
        width: 150,
      },
    ],
    [t, isActiveCheckbox],
  );

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('Preview'),
        accessor: ({ creative }) => {
          return (
            <div className="pic-frame">
              <div className="pic-middle">
                <ImageAutoBalance
                  href={`${config.backendUrl}creatives/display/${creative.image_storage.key}`}
                  alt="ad image"
                  src={`${config.backendUrl}creatives/display/${creative.image_storage.key}`}
                  sizeImage={50}
                  width={+creative.size.split('x')[0]}
                  height={+creative.size.split('x')[1]}
                  size="md"
                />
              </div>
            </div>
          );
        },
        disableSort: true,
        id: 'Preview',
        width: 150,
      },
      {
        Header: t('tableCreative:Creative'),
        accessor: ({ creative }) => creative.name,
        rowCss: 'mt-3',
        id: 'Creative Id',
      },
      {
        Header: t('Active'),
        accessor: ({ verifyStatus }) => verifyStatus,
        Cell: ({ cell }: any) => (
          <div className="form-check form-switch form-switch-lg mt-2 ms-4" dir="ltr">
            <input
              disabled={cell.row.original.verifyStatus !== VerifyStatus[1]}
              type="checkbox"
              defaultChecked={cell.row.original.isActive}
              className="form-check-input"
              onClick={(e) => {
                e.preventDefault();
                changeActiveCreativeCampaign(
                  e,
                  cell.row.index,
                  cell.row.original.key,
                );
              }}
            />
          </div>
        ),
        width: 150,
        id: 'Active',
      },
      {
        Header: t('tableCreative:Creative Size'),
        accessor: ({ creative }) => creative.size,
        rowCss: 'mt-3',
        id: 'Creative Size',
      },
      {
        Header: t('Status'),
        accessor: ({ verifyStatus }) => {
          let badgeColor: string = 'warning';
          switch (verifyStatus) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-3">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verifyStatus}`)}
              </span>
            </div>
          );
        },
        disableSort: true,
        id: 'Status',
        width: 150,
      },
      {
        Header: 'URL',
        accessor: ({ url_ad }) => url_ad,
        Cell: ({ cell }: any) => {
          const { url_ad } = cell.row.original;
          const urlOnclick = url_ad;
          const arr_url_ad = url_ad.match(/.{1,35}/g);
          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {arr_url_ad[0]} {urlOnclick?.length > 35 && '...'}
            </a>
          );
        },
        rowCss: 'mt-3',
        width: 180,
        id: 'URL',
      },
      {
        Header: t('table:Action'),
        accessor: (creative) => (
          <div className="mt-3">
            <div className="avatar-40 inline-block">
              <span
                className="avatar-bin bg-gainsboro rounded-circle"
                onKeyDown={() => {}}
                onClick={async () => {
                  await Swal.fire({
                    width: 300,
                    title: t('swal:Are you sure ?'),
                    text: t("swal:You won't be able to revert this!"),
                    imageUrl: choice,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    customClass: {},
                    cancelButtonColor: 'red',
                    showCancelButton: true,
                    confirmButtonColor: '#009a78',
                    confirmButtonText: t('button:Yes'),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      dispatch(setCreativeCampaignsLoading());
                      await removeCreativeCampaignGraphql(creative.key);
                      dispatch(onGetCreativesCampaign(id));
                      dispatch(unsetCreativeCampaignsLoading());
                    }
                  });
                }}
                role="button"
                tabIndex={0}
              >
                <i className="fas fa-trash font-size-16 text-primary" />
              </span>
            </div>
          </div>
        ),
        disableSort: true,
        id: 'Action',
        width: 150,
      },
    ],
    [t, isActiveCheckbox],
  );

  const columnsNative: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('Preview'),
        accessor: ({ creative }) => {
          return (
            <div className="pic-frame">
              <div className="pic-middle">
                <ImageAutoBalance
                  href={`${config.backendUrl}creatives/display/${creative.image_storage.key}`}
                  alt="ad image"
                  src={`${config.backendUrl}creatives/display/${creative.image_storage.key}`}
                  sizeImage={50}
                  width={+creative.size.split('x')[0]}
                  height={+creative.size.split('x')[1]}
                  size="md"
                />
              </div>
            </div>
          );
        },
        disableSort: true,
        id: 'Preview',
        width: 150,
      },
      {
        Header: t('tableCreative:Creative'),
        accessor: ({ creative }) => creative.name,
        rowCss: 'mt-3',
        id: 'Creative Id',
        width: 150,
      },
      {
        Header: t('Active'),
        accessor: ({ verifyStatus }) => verifyStatus,
        Cell: ({ cell }: any) => (
          <div className="form-check form-switch form-switch-lg mt-2 ms-4" dir="ltr">
            <input
              disabled={cell.row.original.verifyStatus !== VerifyStatus[1]}
              type="checkbox"
              defaultChecked={cell.row.original.isActive}
              className="form-check-input"
              onClick={(e) => {
                e.preventDefault();
                changeActiveCreativeCampaign(
                  e,
                  cell.row.index,
                  cell.row.original.key,
                );
              }}
            />
          </div>
        ),
        width: 100,
        id: 'Active',
      },
      {
        Header: t('tableCreative:Title'),
        accessor: ({ title }) => title,
        rowCss: 'mt-3',
        id: 'title',
        width: 150,
      },
      {
        Header: t('tableCreative:Description'),
        accessor: ({ description }) => description,
        rowCss: 'mt-3',
        width: 200,
        id: 'description',
      },
      {
        Header: t('tableCreative:Size'),
        accessor: ({ creative }) => creative.size,
        rowCss: 'mt-3',
        width: 100,
        id: 'Creative Size',
      },
      {
        Header: t('Status'),
        accessor: ({ verifyStatus }) => {
          let badgeColor: string = 'warning';
          switch (verifyStatus) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-3">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verifyStatus}`)}
              </span>
            </div>
          );
        },
        disableSort: true,
        id: 'Status',
        width: 100,
      },
      {
        Header: 'URL',
        accessor: ({ url_ad }) => url_ad,
        Cell: ({ cell }: any) => {
          const { url_ad } = cell.row.original;
          const urlOnclick = url_ad;
          const arrUrlAd = url_ad.match(/.{1,35}/g);
          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {arrUrlAd[0]} {urlOnclick?.length > 35 && '...'}
            </a>
          );
        },
        rowCss: 'mt-3',
        width: 150,
        id: 'URL',
      },
      {
        Header: t('table:Action'),
        accessor: (creative) => (
          <div className="mt-3">
            <div className="avatar-40 inline-block">
              <span
                className="avatar-bin bg-gainsboro rounded-circle"
                onKeyDown={() => {}}
                onClick={async () => {
                  await Swal.fire({
                    width: 300,
                    title: t('swal:Are you sure ?'),
                    text: t("swal:You won't be able to revert this!"),
                    imageUrl: choice,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    customClass: {},
                    cancelButtonColor: 'red',
                    showCancelButton: true,
                    confirmButtonColor: '#009a78',
                    confirmButtonText: t('button:Yes'),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      dispatch(setCreativeCampaignsLoading());
                      await removeCreativeCampaignGraphql(creative.key);
                      dispatch(onGetCreativesCampaign(id));
                      dispatch(unsetCreativeCampaignsLoading());
                    }
                  });
                }}
                role="button"
                tabIndex={0}
              >
                <i className="fas fa-trash font-size-16 text-primary" />
              </span>
            </div>
          </div>
        ),
        disableSort: true,
        id: 'Action',
        width: 150,
      },
    ],
    [t, isActiveCheckbox],
  );

  //set data
  const refreshData = () => {
    dispatch(onGetCreativesCampaign(id));
  };
  useEffect(() => {
    dispatch(onGetCreativesCampaign(id));
  }, []);

  useEffect(() => {
    if (Object.keys(creativesCampaign)?.length) {
      const cloneActive = { ...isActiveCheckbox };

      creativesCampaign.items.map(
        async (newCreativeCampaign: any, index: number) => {
          cloneActive[`checkbox${index}`] = newCreativeCampaign.isActive;
        },
      );
      setIsActiveCheckbox(cloneActive);
    }
  }, [creativesCampaign]);

  let selectedColumn;
  if (Object.keys(creativesCampaign)?.length > 0) {
    switch (creativesCampaign.campaign.ad_format) {
      case 'POP':
        selectedColumn = columnsPop;
        break;
      case 'NATIVE':
        selectedColumn = columnsNative;
        break;
      default:
        selectedColumn = columns;
        break;
    }
  } else {
    selectedColumn = columns;
  }

  return (
    <div className="px-4">
      <p className="font-size-30 fw-bold">{t('creative:Upload creatives')}</p>
      {Object.keys(creativesCampaign)?.length > 0 ? (
        <>
          <div className="mt-4">
            <UploadCreativeDisplay
              isInstantUpload
              updateCreative
              refreshData={refreshData}
              UploadAdFormat={
                creativesCampaign.campaign.ad_format === 'BANNER'
                  ? `BANNER_${creativesCampaign.campaign.banner_format}`
                  : creativesCampaign.campaign.ad_format
              }
              device_platform={creativesCampaign.campaign.device_platform}
              isPopCreative={creativesCampaign.campaign.ad_format === 'POP'}
            />
          </div>
          <div className="mt-4">
            <CustomTable
              columns={selectedColumn}
              data={creativesCampaign.items || []}
              autoSort
              hidePagination
              headerCSS="fw-normal bg-header-table"
              isLoading={creativeCampaignsLoading}
            />
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <Row className="text-center mt-4 justify-content-evenly">
              <Col sm={12} md={5} className="mb-4 mb-md-0">
                <Card className="cursor h-100-per gray-border mb-0 skeleton">
                  <CardBody
                    style={{ padding: '2rem 3rem' }}
                    className="btn-upload-bounce"
                  />
                </Card>
              </Col>
              <Col sm={12} md={5}>
                <Card className="cursor h-100-per gray-border mb-0 skeleton">
                  <CardBody
                    style={{ padding: '2rem 3rem' }}
                    className="btn-upload-bounce"
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <CustomTable
              columns={selectedColumn}
              data={creativesCampaign.items || []}
              autoSort
              hidePagination
              headerCSS="fw-normal bg-header-table"
              isLoading={creativeCampaignsLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(Creative);
