import ProgressBar from 'react-customizable-progressbar';
import './ProgressBarCircle.style.scss';
import 'react-circular-progressbar/dist/styles.css';
import * as formatter from 'src/utils/convert-value';
import { floorToFixed } from 'src/common/data/mathToFixed';

interface prop {
  text: string | number;
  progress_color: string;
  // eslint-disable-next-line react/no-unused-prop-types
  background_color: string;
  percent: number;
  // eslint-disable-next-line react/no-unused-prop-types
  animation_timer: string;
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
}

const ProgressBarCircleComponent = ({ percent, progress_color, text }: prop) => {
  const newPercent = 100;

  return (
    <div className="progress-bar-container">
      <ProgressBar
        // className="progress-bars"
        progress={percent > 100 ? newPercent : percent}
        radius={100}
        // initialAnimation
        // transition="1.5s ease 0.5s"
        // trackTransition="0s ease"
        strokeColor={progress_color}
        trackStrokeWidth={20}
        strokeWidth={20}
      >
        <div className="indicator">
          <div className="progress-number">{floorToFixed(text, 2)} %</div>
        </div>
      </ProgressBar>
    </div>
  );
};

export default ProgressBarCircleComponent;
