/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum GlobalTypes {
  SET_LOADING = '@@global/SET_LOADING',
  UNSET_LOADING = '@@global/UNSET_LOADING',
  SET_EARNING_TRANSACTION_LOADING = '@@global/SET_EARNING_TRANSACTION_LOADING',
  UNSET_EARNING_TRANSACTION_LOADING = '@@global/UNSET_EARNING_TRANSACTION_LOADING',
  SET_GRAPH_LOADING = '@@global/SET_GRAPH_LOADING',
  UNSET_GRAPH_LOADING = '@@global/UNSET_GRAPH_LOADING',
  SET_WEBSITES_LOADING = '@@global/SET_WEBSITES_LOADING',
  UNSET_WEBSITES_LOADING = '@@global/UNSET_WEBSITES_LOADING',
  SET_APPLICATIONS_LOADING = '@@global/SET_APPLICATIONS_LOADING',
  UNSET_APPLICATIONS_LOADING = '@@global/UNSET_APPLICATIONS_LOADING',
  SET_REQUEST_SIZE_LOADING = '@@global/SET_REQUEST_SIZE_LOADING',
  UNSET_REQUEST_SIZE_LOADING = '@@global/UNSET_REQUEST_SIZE_LOADING',
  SET_APPLICATION_CREATIVE_FILTER_LOADING = '@@global/SET_APPLICATION_CREATIVE_FILTER_LOADING',
  UNSET_APPLICATION_CREATIVE_FILTER_LOADING = '@@global/UNSET_APPLICATION_CREATIVE_FILTER_LOADING',
  SET_CAMPAIGN_DATUM_LOADING = '@@global/SET_CAMPAIGN_DATUM_LOADING',
  UNSET_CAMPAIGN_DATUM_LOADING = '@@global/UNSET_CAMPAIGN_DATUM_LOADING',
  SET_CAMPAIGN_FILTER_LOADING = '@@global/SET_CAMPAIGN_FILTER_LOADING',
  UNSET_CAMPAIGN_FILTER_LOADING = '@@global/UNSET_CAMPAIGN_FILTER_LOADING',
  SET_CAMPAIGN_STATE_SUMMARY_LOADING = '@@global/SET_CAMPAIGN_STATE_SUMMARY_LOADING',
  UNSET_CAMPAIGN_STATE_SUMMARY_LOADING = '@@global/UNSET_CAMPAIGN_STATE_SUMMARY_LOADING',
  SET_CREATIVE_REQUEST_LOADING = '@@global/SET_CREATIVE_REQUEST_LOADING',
  UNSET_CREATIVE_REQUEST_LOADING = '@@global/UNSET_CREATIVE_REQUEST_LOADING',
  SET_CREATIVE_SIZES_LOADING = '@@global/SET_CREATIVE_SIZES_LOADING',
  UNSET_CREATIVE_SIZES_LOADING = '@@global/UNSET_CREATIVE_SIZES_LOADING',
  SET_CREATIVE_CAMPAIGNS_LOADING = '@@global/SET_CREATIVE_CAMPAIGNS_LOADING',
  UNSET_CREATIVE_CAMPAIGNS_LOADING = '@@global/UNSET_CREATIVE_CAMPAIGNS_LOADING',
  SET_CREATIVE_REPORT_LOADING = '@@global/SET_CREATIVE_REPORT_LOADING',
  UNSET_CREATIVE_REPORT_LOADING = '@@global/UNSET_CREATIVE_REPORT_LOADING',
  SET_DEPOSIT_TRANSACTIONS_LOADING = '@@global/SET_DEPOSIT_TRANSACTIONS_LOADING',
  UNSET_DEPOSIT_TRANSACTIONS_LOADING = '@@global/UNSET_DEPOSIT_TRANSACTIONS_LOADING',
  SET_CURRENCIES_LOADING = '@@global/SET_CURRENCIES_LOADING',
  UNSET_CURRENCIES_LOADING = '@@global/UNSET_CURRENCIES_LOADING',
  SET_ACTIVITY_LOG_LOADING = '@@global/SET_ACTIVITY_LOG_LOADING',
  UNSET_ACTIVITY_LOG_LOADING = '@@global/UNSET_ACTIVITY_LOG_LOADING',
}
