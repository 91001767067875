import React from 'react';
import { Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import WithdrawStatuses from '../../constants/withdrawStatuses';

const WithdrawStatus = ({ t, status }: any) => {
  const renderStatus = () => {
    switch (status) {
      case WithdrawStatuses.PENDING:
        return (
          <Badge className="bg-warning font-size-12">{t(`table:${status}`)}</Badge>
        );
      case WithdrawStatuses.FINISHED:
        return (
          <Badge className="bg-success font-size-12">{t(`table:${status}`)}</Badge>
        );
      case WithdrawStatuses.APPROVED:
        return (
          <Badge className="bg-success font-size-12">{t(`table:${status}`)}</Badge>
        );
      case WithdrawStatuses.REJECTED:
        return (
          <Badge className="bg-danger font-size-12">{t(`table:${status}`)}</Badge>
        );
      default:
        return (
          <Badge className="bg-success text-light">{t(`table:APPROVED`)}</Badge>
        );
    }
  };

  return <>{renderStatus()}</>;
};

export default withTranslation()(WithdrawStatus);
