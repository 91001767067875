import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import choice from 'src/assets/images/choice.png';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import './ViewChannelDetail/ViewChannelDetail.style.scss';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import config from 'src/config';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  filterJob,
  getAllAdminApproveLog,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import Role from 'src/constants/enum/role';
import marketerCampaign from 'src/constants/enum/marketerCampaign';
import { updateJobGql } from 'src/graphql/advertiser/job';
import SearchBar from 'src/components/Table/SearchBar';
import Dropdown from '../../../components/CommonForBoth/Menus/Dropdown';

const ManageJob = ({ verifyStatus }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    exclude_status: marketerCampaign.DRAFT,
    userRole: Role.ADMIN,
    verify_status: newVerifyStatus,
    search: null,
  });

  const data = useSelector((State: any) => State.MarketerJob.filter);

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await updateJobGql({
          verify_status: verify,
          key,
        });
        dispatch(unsetLoading());
        dispatch(filterJob(param));
      }
    });
  };

  //table
  const columns = useMemo(() => {
    return [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('marketer:job_detail:Job_name'),
        accessor: (item: any) => item?.name,
        id: 'job_name',
        disableSort: true,
      },
      {
        Header: t('table:social'),
        accessor: (item: any) => item?.social_media_type,
        id: 'social',
        disableSort: true,
      },
      {
        Header: t('table:marketer_company'),
        accessor: (item: any) => item?.marketer_campaign?.user?.user_info?.company,
        id: 'marketer_company',
        width: 155,
        disableSort: true,
      },
      {
        Header: t('table:view job detail'),
        accessor: (item: any) => {
          const path = item?.is_gleam_job
            ? `/admin/approve/manage-job/publishDetail?job=${item?.key}`
            : `/Marketer/JobDetail?job=${item?.key}`;
          return (
            <a
              className="cursor  fw-bold view-detail"
              href={path}
              onClick={(e) => {
                e.preventDefault();
                history.push(path);
              }}
            >
              {t(`table:view detail`)}
            </a>
          );
        },
        id: 'view_detail_influencers',
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:status'),
        accessor: (item: any) => {
          let badgeColor: string = 'warning';
          switch (item?.verify_status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${item.verify_status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        disableSort: true,
      },

      {
        Header: t('marketer:create_campaign:Order date'),
        accessor: (item: any) => {
          if (!item) return 'null';
          if (!item?.created_at) return 'null';
          const order_date = new Date(item?.created_at);
          const Day = order_date.getDate();
          const month = order_date.getMonth() + 1;
          const year = order_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'order_date',
        width: 100,
      },

      {
        Header: t('table:Action'),
        accessor: ({ key, verify_status }: any) => (
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {verify_status !== VerifyStatus[0] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('PENDING', key)}
                  className="text-warning"
                >
                  <i className="mdi mdi-account-clock text-warning" />
                  &nbsp;&nbsp;{t('table:PENDING')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[1] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('APPROVED', key)}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[2] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('REJECTED', key)}
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ];
  }, [t, param.page, param.limit, param]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    // dispatch(filterSocialJob(payload));
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...param,
      limit: +newPageSize,
      page: 1,
    };
    // dispatch(filterSocialJob(payload));
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handleStatusChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      verify_status: event.value,
    };
    // dispatch(filterSocialJob(payload));
    dispatch(filterJob(payload));
    setNewVerifyStatus(event.value);
    setParam(payload);
  };
  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('new_job');
    dispatch(getAllAdminApproveLog());
  };
  useEffect(() => {
    fetchApproveLog();
    dispatch(filterJob(param));
    // dispatch(filterSocialJob(param));
  }, []);

  const handleSearch = (searchText: string) => {
    const payload = {
      ...param,
      search: searchText,
      page: 1,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      search: '',
      verify_status: null,
      page: 1,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  return (
    <div>
      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card className="h-100">
              <CardBody className="h-100">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    padding: '0 10px',
                  }}
                >
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handlePageSizeChange}
                    colors="white"
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ width: '150px', marginRight: '20px' }}>
                      {verifyStatus !== 'PENDING' && (
                        <Dropdown
                          options={Status}
                          action={handleStatusChange}
                          default_value={Status[3]}
                        />
                      )}
                    </div>
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={data?.items || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={data?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ManageJob;
