import { gql } from '@apollo/client';
import { client } from '../client';

// Common Graphql Methods Goes Here (Methods used by all users)
const wallet = async () => {
  const query = gql`
    query Wallet {
      wallet {
        id
        balance
        checksum_status
      }
    }
  `;

  const result = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.wallet;
};

const getSumBalanceWalletGQL = async () => {
  const query = gql`
    query GetSumBalanceWallet {
      getSumBalanceWallet {
        balance
        checksum_status
      }
    }
  `;
  try {
    const result = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return result.data.getSumBalanceWallet;
  } catch (error) {
    return null;
  }
};

const getSumBalanceWalletByUUIDGQL = async (uuid: any) => {
  const query = gql`
    query getSumBalanceWalletByUUID($uuid: String!) {
      getSumBalanceWalletByUUID(uuid: $uuid) {
        balance
        checksum_status
      }
    }
  `;
  try {
    const result = await client.query({
      query,
      variables: { uuid },
      fetchPolicy: 'no-cache',
    });
    return result.data.getSumBalanceWalletByUUID;
  } catch (error) {
    return null;
  }
};

const getBalanceWalletByUUIDGQL = async (uuid: any) => {
  const query = gql`
    query getBalanceWalletByUUID($uuid: String!) {
      getBalanceWalletByUUID(uuid: $uuid) {
        symbol
        balance
        checksum_status
      }
    }
  `;
  try {
    const result = await client.query({
      query,
      variables: { uuid },
      fetchPolicy: 'no-cache',
    });
    return result.data.getBalanceWalletByUUID;
  } catch (error) {
    return null;
  }
};

const getFee = async (getWithdrawFeeInput: any) => {
  const query = gql`
    query GetWithdrawFee($getWithdrawFeeInput: WithdrawFeeInput!) {
      getWithdrawFee(getWithdrawFeeInput: $getWithdrawFeeInput) {
        internal_transfer_fee
        internal_transfer_fee_is_percent
        external_transfer_fee
        external_transfer_fee_is_percent
        external_deposit_fee
        external_deposit_fee_is_percent
        isInternal
      }
    }
  `;
  const result = await client.query({
    query,
    variables: { getWithdrawFeeInput },
    fetchPolicy: 'no-cache',
  });
  return result.data.getWithdrawFee;
};
// const getsuggestAndOtherJobCount = async (User: any) => {
//   const GET_SUGGEST_JOB = gql`
//   query SuggestAndOtherJobCount($user :User
//   ){suggestAndOtherJobCount(user:$user
//   ){
//     suggestJobs
//     otherJobs
//   }
// }
// }`;
//   const user = '';
//   const result = await client.query({
//     query: GET_SUGGEST_JOB,
//     fetchPolicy: 'no-cache',
//   });
//   return result.data;
// };
const suggestAndOtherJobCountGql = async () => {
  const query = gql`
    query suggestAndOtherGleamJobCount {
      suggestAndOtherGleamJobCount {
        suggestJobs
        otherJobs
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.suggestAndOtherGleamJobCount;
  } catch (error: any) {
    return null;
  }
};

const currencies = async () => {
  const GET_CURRENCIES = gql`
    query Currencies {
      currencies {
        id
        symbol
        is_available
      }
    }
  `;

  const result = await client.query({
    query: GET_CURRENCIES,
    fetchPolicy: 'no-cache',
  });

  return result.data.currencies;
};

const availableCurrencies = async () => {
  const GET_AVAILABLE_CURRENCIES = gql`
    query AvailableCurrencies {
      availableCurrencies {
        id
        symbol
        network
        rate_in_USD
        price_in_USDT
        is_marketer_currency
      }
    }
  `;

  const result = await client.query({
    query: GET_AVAILABLE_CURRENCIES,
    fetchPolicy: 'no-cache',
  });

  return result.data.availableCurrencies;
};

const toggleCurrencyAvailable = async (symbol: string) => {
  const TOGGLE_CURRENCY_AVAILABLE = gql`
    mutation ToggleCurrencyAvailable($symbol: String!) {
      toggleCurrencyAvailable(symbol: $symbol)
    }
  `;

  return client.mutate({
    mutation: TOGGLE_CURRENCY_AVAILABLE,
    variables: {
      symbol,
    },
  });
};

const changeTimezone = async (timezone: string) => {
  const CHANGE_TIME_ZONE = gql`
    mutation ChangeTimeZone($timezone: String!) {
      changeTimezone(timezone: $timezone)
    }
  `;

  return client.mutate({
    mutation: CHANGE_TIME_ZONE,
    variables: {
      timezone,
    },
  });
};

const getLoggedInUser = async () => {
  const GET_LOGGEDIN_USER = gql`
    query GetLoggedInUser {
      getLoggedInUser {
        uuid
        email
        role
        referral_code
        timezone
      }
    }
  `;

  const result = await client.query({
    query: GET_LOGGEDIN_USER,
    fetchPolicy: 'no-cache',
  });

  return result.data.getLoggedInUser;
};

const filterCurrenciesGQL = async (payload: any) => {
  const query = gql`
    query FilterCurrencies($input: FilterCurrencyInput!) {
      filterCurrencies(input: $input) {
        totalCount
        items {
          id
          name
          symbol
          main_symbol
          network
          explorer_link
          rate_in_USD
          is_available
          is_marketer_currency
          contract_address
          decimals
          favor
          ref_link
          price_in_USDT
          owner {
            uuid
          }
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input: payload },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterCurrencies;
  } catch (error) {
    return null;
  }
};

const getAvailableNetworkGQL = async () => {
  const query = gql`
    query GetAvailableNetwork {
      getAvailableNetwork
    }
  `;
  try {
    const response = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return response.data.getAvailableNetwork;
  } catch (error) {
    return null;
  }
};

const getWithdrawAndFeeWallet = async (
  currency: string,
  network: string,
  address: string,
) => {
  const query = gql`
    query GetWithdrawAndFeeWallet(
      $currency: String!
      $network: String!
      $address: String!
    ) {
      getWithdrawAndFeeWallet(
        currency: $currency
        network: $network
        address: $address
      ) {
        withdrawWallet {
          balance
          symbol
        }
        feeWallet {
          balance
          symbol
        }
        fee
        minimumWithdraw
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { currency, network, address },
      fetchPolicy: 'no-cache',
    });
    return res.data.getWithdrawAndFeeWallet;
  } catch (error) {
    return null;
  }
};

const getCurrenciesByMarketerGQL = async () => {
  const query = gql`
    query {
      getCurrenciesByMarketer {
        id
        name
        main_symbol
        network
        contract_address
        decimals
        price_in_USDT
        ref_link
        logo
      }
    }
  `;
  try {
    const result = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return result.data.getCurrenciesByMarketer;
  } catch (error) {
    return null;
  }
};

const getMarketerCurrenciesGQL = async () => {
  const query = gql`
    query MarketerCurrencies {
      marketerCurrencies {
        id
        name
        main_symbol
        network
        contract_address
        decimals
        minimumWithdraw
        price_in_USDT
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.marketerCurrencies;
  } catch (error) {
    return null;
  }
};

const getCurrencyByIdGQL = async (id: any) => {
  const query = gql`
    query getCurrencyById($id: Int!) {
      getCurrencyById(id: $id) {
        name
        symbol
        main_symbol
        network
        rate_in_USD
        is_marketer_currency
        decimals
        price_in_USDT
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    return response.data.getCurrencyById;
  } catch (error) {
    return null;
  }
};

const getUserWalletByCurrencyGQL = async (currency_id: any) => {
  console.log('getUserWalletByCurrencyGQL', currency_id);
  const query = gql`
    query getUserWalletByCurrency($currency_id: Int!) {
      getUserWalletByCurrency(currency_id: $currency_id) {
        id
        balance
        checksum
        symbol
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { currency_id },
      fetchPolicy: 'no-cache',
    });
    return response.data.getUserWalletByCurrency;
  } catch (error) {
    return null;
  }
};

const getAdminWalletGQL = async () => {
  const query = gql`
    query GetAdminWallet {
      getAdminWallet {
        balance
        symbol
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.getAdminWallet;
  } catch (error) {
    return null;
  }
};

const getCurrencyAndMinMaxBudgetGQL = async (id: any) => {
  const query = gql`
    query getCurrencyAndMinMaxBudget($id: Int!) {
      getCurrencyAndMinMaxBudget(id: $id) {
        currency {
          name
          symbol
          main_symbol
          network
          rate_in_USD
          is_marketer_currency
          decimals
          price_in_USDT
        }
        minBudget {
          name
          value
        }
        maxBudget {
          name
          value
        }
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    return response.data.getCurrencyAndMinMaxBudget;
  } catch (error) {
    return null;
  }
};

const getCurrencyLogoBySymbol = async (symbol: any) => {
  const query = gql`
    query getCurrencyLogoBySymbol($symbol: String!) {
      getCurrencyLogoBySymbol(symbol: $symbol)
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { symbol },
      fetchPolicy: 'no-cache',
    });

    return res.data.getCurrencyLogoBySymbol;
  } catch (error) {
    return null;
  }
};

const networks = async () => {
  const query = gql`
    query Networks {
      networks {
        id
        name
        network_id
        is_available
      }
    }
  `;
  try {
    const result = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return result.data.networks;
  } catch (error) {
    return null;
  }
};

const toggleNetworkAvailable = async (id: number) => {
  const mutation = gql`
    mutation ToggleNetworkAvailable($id: Int!) {
      toggleNetworkAvailable(id: $id)
    }
  `;
  try {
    const res = client.mutate({
      mutation,
      variables: {
        id,
      },
    });
    return res;
  } catch (error) {
    return null;
  }
};

const getMarketerAvailableCurrenciesGQL = async () => {
  const query = gql`
    query marketerAvailableCurencies {
      marketerAvailableCurencies {
        currency {
          id
          symbol
          main_symbol
          is_marketer_currency
          price_in_USDT
          rate_in_USD
        }
        wallet {
          uuid
          checksum_status
          balance
        }
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return res.data.marketerAvailableCurencies;
  } catch (error) {
    return null;
  }
};

export {
  wallet,
  currencies,
  toggleCurrencyAvailable,
  availableCurrencies,
  changeTimezone,
  getLoggedInUser,
  getFee,
  suggestAndOtherJobCountGql,
  // getsuggestAndOtherJobCount,
  filterCurrenciesGQL,
  getSumBalanceWalletGQL,
  getAvailableNetworkGQL,
  getWithdrawAndFeeWallet,
  getCurrenciesByMarketerGQL,
  getMarketerCurrenciesGQL,
  getCurrencyByIdGQL,
  getUserWalletByCurrencyGQL,
  getAdminWalletGQL,
  getSumBalanceWalletByUUIDGQL,
  getBalanceWalletByUUIDGQL,
  getCurrencyAndMinMaxBudgetGQL,
  getCurrencyLogoBySymbol,
  networks,
  toggleNetworkAvailable,
  getMarketerAvailableCurrenciesGQL,
};
