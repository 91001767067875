import React from 'react';
import { FormGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import socialMediaTypesEnum from '../../constants/enum/socialMediaType';

const SocialMediaTypeOptions = ({ t, defaultValue, handleTypeChange }: any) => {
  const handleChange = (e: any) => {
    const status = e.currentTarget.value;

    handleTypeChange(status);
  };

  const renderSocialMediaTypesOption = () => {
    return Object.keys(socialMediaTypesEnum).map((key: string) => {
      const text = key === 'ALL' ? t('table:Select Type') : t(`table:${key}`);

      return (
        <option
          // @ts-ignore
          value={socialMediaTypesEnum[key]}
          defaultValue={defaultValue}
        >
          {text}
        </option>
      );
    });
  };

  return (
    <FormGroup>
      <select className="mb-3 mb-md-0 form-control" onChange={handleChange}>
        {renderSocialMediaTypesOption()}
      </select>
    </FormGroup>
  );
};

export default withTranslation()(SocialMediaTypeOptions);
