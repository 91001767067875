/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum DepositAddressesTypes {
  /** Get deposit addresses */
  GET_DEPOSIT_ADDRESSES = '@@deposit_address/GET_DEPOSIT_ADDRESSES',
  GET_DEPOSIT_ADDRESSES_SUCCESS = '@@deposit_address/GET_DEPOSIT_ADDRESSES_SUCCESS',
  GET_DEPOSIT_ADDRESSES_FAIL = '@@deposit_address/GET_DEPOSIT_ADDRESSES_FAIL',

  GET_DEPOSIT_ADDRESSES_BY_UUID = '@@deposit_address/GET_DEPOSIT_ADDRESSES_BY_UUID',
  GET_DEPOSIT_ADDRESSES_BY_UUID_SUCCESS = '@@deposit_address/GET_DEPOSIT_ADDRESSES_BY_UUID_SUCCESS',
  GET_DEPOSIT_ADDRESSES_BY_UUID_FAIL = '@@deposit_address/GET_DEPOSIT_ADDRESSES_BY_UUID_FAIL',
}

export interface DepositAddressState {
  addresses: Array<any>;
  error: Object;
  loading: boolean;
}
