import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getCryptoBalanceLogTransactions } from '../../store/systembalance/actions';
import CryptoBalanceLogTransactionsTable from '../../components/Admin/SystemBalance/CryptoBalanceLogTransactionsTable';
import CryptoBalanceChart from '../../components/Admin/SystemBalance/CryptoBalanceChart';
import balanceLogRangeEnum from '../../constants/balanceLogRangeEnum';
import menu from '../../constants/menu';

const CryptoBalanceLogPage = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCryptoBalanceLogTransactions({
        limit: 10,
        page: 1,
        log_range: balanceLogRangeEnum.DAILY,
      }),
    );
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{t('Page.Crypto Balance Log')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.Crypto Balance Log')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.CRYPTO_BALANCE_LOG, active: true },
            ]}
          />
          <div className="card-title-desc text-white">
            {t('Crypto Balance Log Helper')}
          </div>

          <Row className="justify-content-center">
            <Col className="mb-4" xl={8}>
              <CryptoBalanceChart />
            </Col>
            <Col xl={12}>
              <CryptoBalanceLogTransactionsTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(CryptoBalanceLogPage);
