import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { Response } from 'src/common/types';
import {
  getWebsite,
  getWebsiteById,
  getWebsiteReports,
  updateWebsite,
  createWebsite as createWeb,
  createRequestCreativeSize,
  getRequestCreativeSize,
  getEarningTransaction,
  getAdminEarningTransaction,
  getWebsiteTransactionsGraph,
  getEarningTransactionByUser,
  getCreativeType,
  getWebsiteTransactionByDate,
  getPendingApproval,
} from 'src/graphql';
// Crypto Redux States
import notify from 'src/utils/notify';

import { getErrorMessageByErrorCode } from 'src/utils/error';
import { setLoading, unsetLoading } from 'src/store/global/actions';

import i18n from 'src/i18n';
import { getWebsiteGraphDatum } from 'src/graphql/publisher/website';
import {
  getWebsitesSuccess,
  getWebsitesFail,
  getInfoWebsiteSuccess,
  getInfoWebsiteFail,
  getWebsiteReportSuccess,
  getWebsiteReportFail,
  updateWebsiteStatusFail,
  createWebsiteFail,
  createWebsiteRequestSizeSuccess,
  createWebsiteRequestSizeFail,
  getWebsiteRequestSizeSuccess,
  getWebsiteRequestSizeFail,
  getEarningTransactionSuccess,
  getEarningTransactionFail,
  getAdminEarningTransactionSuccess,
  getAdminEarningTransactionFail,
  getWebsiteTransactionLogSuccess,
  getAllEarningTransactionSuccess,
  getAllEarningTransactionFail,
  getWebsiteTypesSuccess,
  getWebsiteTypesFail,
  getWebsiteTransactionByDateSuccess,
  getWebsiteTransactionByDateFail,
  getPendingApprovalSuccess,
  getPendingApprovalFail,
  getWebsiteGraphDatumSuccess,
} from './actions';
import { WebsiteTypes } from './actionTypes';
import {
  setEarningTransactionLoading,
  unsetEarningTransactionLoading,
  setGraphLoading,
  unsetGraphLoading,
  setWebsitesLoading,
  unsetWebsitesLoading,
  setRequestSizeLoading,
  unsetRequestSizeLoading,
} from '../global/actions';

// Include Both Helper File with needed methods

function* fetchWebsite({
  payload: { limit, page, url, status, sortBy, sortType },
}: any) {
  try {
    yield put(setWebsitesLoading());
    const response: Promise<any> = yield call(
      getWebsite,
      limit,
      page,
      url,
      status,
      sortBy,
      sortType,
    );

    yield put(getWebsitesSuccess(response));
  } catch (error) {
    yield put(getWebsitesFail(error));
  } finally {
    yield put(unsetWebsitesLoading());
  }
}

function* getWebsiteInfo(action: any) {
  try {
    const response: Promise<any> = yield call(getWebsiteById, action.id);
    yield put(getInfoWebsiteSuccess(response, action.id));
  } catch (error) {
    yield put(getInfoWebsiteFail(error));
  }
}

function* getWebsiteReport({
  payload: { limit, page, website_id, sortBy, sortType },
}: any) {
  try {
    const response: Promise<any> = yield call(
      getWebsiteReports,
      limit,
      page,
      website_id,
      sortBy,
      sortType,
    );
    yield put(getWebsiteReportSuccess(response));
  } catch (error) {
    yield put(getWebsiteReportFail(error));
  }
}

function* createWebsite({
  payload: { url, status, userId, category, typeIndex, history },
}: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(
      createWeb,
      url,
      status,
      userId,
      category,
      typeIndex,
    );

    yield delay(800);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        createWebsiteFail(getErrorMessageByErrorCode(response.errors[0].error_code)),
      );
    }

    if (!isEmpty(response) && !isEmpty(response.data)) {
      notify(i18n.t('success:Website add success'), 'success');
      history.push('/mywebsite');
    }
  } catch (error) {
    yield put(createWebsiteFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* updateWebsiteStatus({ payload: { id, status } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(updateWebsite, id, status);

    yield delay(800);

    if (!isEmpty(response) && !isEmpty(response.data)) {
      notify(i18n.t('success:Status update success'), 'success');
    }
  } catch (error) {
    yield put(updateWebsiteStatusFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* getWebsiteRequestSize({
  payload: { limit, page, website_id, sortBy, sortType },
}: any) {
  try {
    yield put(setRequestSizeLoading());
    const response: Response = yield call(
      getRequestCreativeSize,
      limit,
      page,
      website_id,
      sortBy,
      sortType,
    );

    yield put(getWebsiteRequestSizeSuccess(response));
  } catch (error) {
    yield put(getWebsiteRequestSizeFail(error));
  } finally {
    yield put(unsetRequestSizeLoading());
  }
}

function* createWebsiteRequestSize({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(createRequestCreativeSize, payload);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        createWebsiteRequestSizeFail(
          getErrorMessageByErrorCode(response.errors[0].error_code),
        ),
      );
    }

    if (!isEmpty(response) && !isEmpty(response.data)) {
      notify(i18n.t('success:Add size success'), 'success');
    }

    yield put(createWebsiteRequestSizeSuccess(response));
  } catch (error) {
    yield put(createWebsiteRequestSizeFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchEarningTransaction({
  payload: {
    limit,
    page,
    user_id,
    email,
    website_id,
    application_key,
    sortBy,
    sortType,
  },
}: any) {
  try {
    yield put(setEarningTransactionLoading());
    const response: Promise<any> = yield call(
      getEarningTransaction,
      limit,
      page,
      user_id,
      email,
      website_id,
      application_key,
      sortBy,
      sortType,
    );

    yield put(getEarningTransactionSuccess(response));
  } catch (error) {
    yield put(getEarningTransactionFail(error));
  } finally {
    yield put(unsetEarningTransactionLoading());
  }
}

function* fetchAdminEarningTransaction({ payload: { limit, page, user_id } }: any) {
  try {
    const response: Promise<any> = yield call(
      getAdminEarningTransaction,
      limit,
      page,
      user_id,
    );

    yield put(getAdminEarningTransactionSuccess(response));
  } catch (error) {
    yield put(getAdminEarningTransactionFail(error));
  }
}

function* fetchWebsiteTransactionLog({ payload: WebsiteTransactionQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getWebsiteTransactionsGraph,
      WebsiteTransactionQuery,
    );

    yield put(getWebsiteTransactionLogSuccess(response));
  } catch (error) {
    // yield put(fail(error));
  }
}

function* fetchAllEarningTransaction({ payload: WebsiteTransactionQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getEarningTransactionByUser,
      WebsiteTransactionQuery,
    );

    yield put(getAllEarningTransactionSuccess(response));
  } catch (error) {
    yield put(getAllEarningTransactionFail(error));
  }
}

function* fetchWebsiteTypes() {
  try {
    const response: Promise<any> = yield call(getCreativeType);

    yield put(getWebsiteTypesSuccess(response));
  } catch (error) {
    yield put(getWebsiteTypesFail(error));
  }
}

function* fetchWebsiteTransactionByDate({ payload: { website_id, date } }: any) {
  try {
    const response: Promise<any> = yield call(
      getWebsiteTransactionByDate,
      website_id,
      date,
    );

    yield put(getWebsiteTransactionByDateSuccess(response));
  } catch (error) {
    yield put(getWebsiteTransactionByDateFail(error));
  }
}

function* getPendingApprove({
  payload: { limit, page, url, sortBy, sortType },
}: any) {
  try {
    const response: Promise<any> = yield call(
      getPendingApproval,
      limit,
      page,
      url,
      sortBy,
      sortType,
    );

    yield put(getPendingApprovalSuccess(response));
  } catch (error) {
    yield put(getPendingApprovalFail(error));
  }
}

function* fetchWebsiteGraphDatum({ payload }: any) {
  try {
    yield put(setGraphLoading());
    const response: Promise<any> = yield call(getWebsiteGraphDatum, payload);

    yield put(getWebsiteGraphDatumSuccess(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  } finally {
    yield put(unsetGraphLoading());
  }
}

function* websitesSaga() {
  yield takeEvery(WebsiteTypes.GET_WEBSITE, fetchWebsite);
  yield takeEvery(WebsiteTypes.GET_INFO_WEBSITE, getWebsiteInfo);
  yield takeEvery(WebsiteTypes.GET_WEBSITE_REPORT, getWebsiteReport);
  yield takeEvery(WebsiteTypes.UPDATE_WEBSITE_STATUS, updateWebsiteStatus);
  yield takeEvery(WebsiteTypes.CREATE_WEBSITE, createWebsite);
  yield takeEvery(WebsiteTypes.GET_REQUEST_SIZE, getWebsiteRequestSize);
  yield takeEvery(WebsiteTypes.CREATE_REQUEST_SIZE, createWebsiteRequestSize);
  yield takeEvery(WebsiteTypes.GET_EARNING_TRANSACTION, fetchEarningTransaction);
  yield takeEvery(
    WebsiteTypes.GET_ADMIN_EARNING_TRANSACTION,
    fetchAdminEarningTransaction,
  );
  yield takeEvery(
    WebsiteTypes.GET_WEBSITE_TRANSACTION_LOG,
    fetchWebsiteTransactionLog,
  );
  yield takeEvery(
    WebsiteTypes.GET_ALL_EARNING_TRANSACTION,
    fetchAllEarningTransaction,
  );
  yield takeEvery(WebsiteTypes.GET_WEBSITE_TYPES, fetchWebsiteTypes);
  yield takeEvery(
    WebsiteTypes.GET_WEBSITE_TRANSACTION_BY_DATE,
    fetchWebsiteTransactionByDate,
  );

  yield takeEvery(WebsiteTypes.GET_WEBSITE_GRAPH_DATUM, fetchWebsiteGraphDatum);

  yield takeEvery(WebsiteTypes.GET_PENDING_APPROVAL, getPendingApprove);
}

export default websitesSaga;
