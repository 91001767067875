import { call, put, takeEvery } from 'redux-saga/effects';
import { ReferralTypes } from './actionTypes';
import {
  getReferralEmailByRefCode,
  getReferralTransactions as getReferralTransactionsGql,
  getSystemTaxReferralGql,
} from '../../graphql/common/referral';
import {
  getReferralEmailSuccess,
  getReferralTransactionsSuccess,
  getSystemTaxReferralSuccess,
} from './actions';

// eslint-disable-next-line require-yield
function* getReferralEmail({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getReferralEmailByRefCode, payload);

    yield put(getReferralEmailSuccess(response));
  } catch (e) {
    // something here
  }
}

function* getSystemTaxReferral() {
  try {
    const response: Promise<any> = yield call(getSystemTaxReferralGql);

    yield put(getSystemTaxReferralSuccess(response));
  } catch (e) {
    // something here
  }
}

function* getReferralTransactions({ payload: ReferralTransactionsQuery }: any) {
  try {
    const response: Promise<any> = yield call(
      getReferralTransactionsGql,
      ReferralTransactionsQuery,
    );

    yield put(getReferralTransactionsSuccess(response));
  } catch (e) {
    // something here
  }
}

function* referralSaga() {
  // @ts-ignore
  yield takeEvery(ReferralTypes.GET_REFERRAL_EMAIL, getReferralEmail);
  yield takeEvery(ReferralTypes.GET_REFERRAL_SYSTEM_TAX, getSystemTaxReferral);
  yield takeEvery(ReferralTypes.GET_REFERRAL_TRANSACTIONS, getReferralTransactions);
}

export default referralSaga;
