import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useHistory } from 'react-router';
import menu from 'src/constants/menu';
import './Banned.style.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CustomButton from './Common/CustomButton';

const Banned = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState('12/05/2023 00.00am');
  const history = useHistory();
  const [ban_reason, setBanReason] = useState('You have rejected too many jobs.');

  const { ban } = useSelector((state: any) => ({
    ban: state.Ban.checkIsBan,
  }));

  useEffect(() => {
    if (!ban) {
      history.push('/');
    } else {
      const { release_date, reason } = ban.ban;
      const release_at_date = new Date(release_date);
      setBanReason(reason);
      setDate(
        `${release_at_date.getDate()}/${
          release_at_date.getMonth() + 1
        }/${release_at_date.getFullYear()}`,
      );
    }
  }, [ban]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('page:Banned')}</title>
      </MetaTags>
      <Container fluid>
        <div className="marketer-campaign-container-discover">
          <Breadcrumb
            pageTitle={t('Banned')}
            menus={[{ menu: menu.HOME, active: false }]}
          />
          <div className="main-page-banned">
            <div className="main">{t('You have banned')}</div>
            <div>
              {t('Because')} {ban_reason}
            </div>
            <div>
              {t('You will be unbanned on')}
              {'   '} {date}
            </div>
            <div className="button-logout-contact">
              <div
                onClick={() => {
                  history.push('/contact');
                }}
              >
                <CustomButton
                  label={t('Contact Admin')}
                  status="submit"
                  size="sss"
                />
              </div>
              <div
                onClick={() => {
                  history.push('/logout');
                }}
              >
                <CustomButton label={t('Logout')} status="cancel" size="s" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Banned;
