import { SetStateAction, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Row, CardBody, FormGroup, Label } from 'reactstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Select from 'react-select';
import selectStyle from 'src/components/Common/reactSelectStyle';
import {
  getDefaultCampaign as onGetDefaultCampaign,
  getCampaign,
  setLoading,
  unsetLoading,
  getCreativeType as onGetCreativeType,
} from '../../../store/actions';
import {
  addTypeRelationGraphQL,
  updateCampaignGraphQL,
} from '../../../graphql/advertiser/campaign';
import useWindowDimensions from '../../useWindowDimensions';
import CreativeType from './CreativeType';

const OverviewPage = ({ t, setSelectSettingTab }: any) => {
  //from react
  const { id }: any = useParams();
  const dispatch = useDispatch();
  //from component
  const { windowWidth } = useWindowDimensions();
  //state variables

  //update campaign variable
  const history = useHistory();
  const defaultOptions = [
    { label: t('status.Draft'), value: 'DRAFT', disabled: true },
    { label: t('status.Active'), value: 'ACTIVE', disabled: true },
    { label: t('status.Paused'), value: 'PAUSED', disabled: true },
    { label: t('status.OutOfBudget'), value: 'OUT_OF_BUDGET', disabled: true },
    { label: t('status.Finished'), value: 'FINISHED', disabled: true },
    { label: t('status.Archived'), value: 'ARCHIVED', disabled: true },
  ];
  const [status, setStatus] = useState('');
  const [oldStatus, setOldStatus] = useState('');
  const [name, setName] = useState<string>('');
  const [device_platform, setDevicePlatform] = useState('DESKTOP');
  const [CPM, setCPM] = useState<number>(0);
  const [isUnlimitedBudget, setIsUnlimitedBudget] = useState(true);
  const [budget, setBudget] = useState<number>(0);
  const [max_budget, setMaxBudget] = useState<number | null>(null);
  const [isHaveCreative, setIsHaveCreative] = useState(true);
  const [isEnoughBalance, setIsEnoughBalance] = useState(true);
  const [typeData, setTypeData] = useState<any>([]);

  const [options, setOption] = useState(defaultOptions);

  const [updateCampaignSchema, setUpdateCampaignSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required(t('invalid.Required')),
      CPM: Yup.number()
        .required(t('invalid.Required'))
        .positive(t('invalid.Make sure positive number')),
      budget: Yup.number()
        .required(t('invalid.Required'))
        .positive(t('invalid.Make sure positive number')),
      max_budget: Yup.number().nullable(true),
    }),
  );

  // redux
  const { campaign, defaultCampaign, creativeType } = useSelector((state: any) => ({
    campaign: state.Campaigns.campaign,
    defaultCampaign: state.DefaultValue.defaultCampaign,
    balance: state.Wallet.wallet.balance,
    creativeType: state.CreativeType.creativeType,
  }));

  const typeOption = creativeType.map((type: { name: string; key: string }) => ({
    label: type.name,
    value: type.name,
    key: type.key,
  }));

  //default value
  const [initialValues, setInitialValues] = useState<any>({
    name: '',
    CPM: '',
    budget: '',
    max_budget: '',
  });

  //general function
  const preventNonNumber = (evt: any) => {
    return (
      (evt.key === '-' || evt.key === 'e' || evt.key === '+') && evt.preventDefault()
    );
  };
  //submit
  const onSubmit = async () => {
    await Swal.fire({
      title: t('swal:Do you want to save the changes?'),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      showCancelButton: true,
      customClass: {},
      confirmButtonColor: '#009a78',
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(setLoading());
        const submitData: any = { campaign_key: campaign.campaign_key };
        if (campaign.status !== status) {
          submitData.status = status;
        }
        if (campaign.name !== name) {
          submitData.name = name;
        }
        if (campaign.device_platform !== device_platform) {
          submitData.device_platform = device_platform;
        }
        if (campaign.CPM !== CPM) {
          submitData.CPM = +CPM;
        }
        if (campaign.budget !== budget) {
          submitData.budget = budget;
        }
        if (campaign.max_budget !== max_budget) {
          submitData.max_budget = max_budget;
        }

        if (isUnlimitedBudget) {
          if (campaign.max_budget) {
            submitData.max_budget = null;
          }
        }
        const typeDataKeys = typeData.map((type: { key: string }) => type.key);

        await addTypeRelationGraphQL(typeDataKeys, campaign.campaign_key);

        const updateCampaign = await updateCampaignGraphQL(submitData);
        setData(updateCampaign);
        dispatch(getCampaign(id));
        dispatch(unsetLoading());
      }
    });
  };

  //set data
  const setData = (campaignData: any) => {
    const initialData = {
      name: campaignData.name,
      CPM: campaignData.CPM,
      budget: campaignData.budget,
      max_budget: campaignData.max_budget,
    };
    setInitialValues(initialData);
    setStatus(campaignData.status);
    setOldStatus(campaignData.status);
    setName(campaignData.name);
    setDevicePlatform(campaignData.device_platform);
    setCPM(campaignData.CPM);
    setBudget(campaignData.budget);

    if (campaignData.max_budget) {
      setMaxBudget(campaignData.max_budget);
      setIsUnlimitedBudget(false);
    } else {
      setIsUnlimitedBudget(true);
    }

    setIsHaveCreative(campaignData.isHaveCreative);
    setIsEnoughBalance(campaignData.isEnoughBalance);
  };

  useEffect(() => {
    dispatch(getCampaign(id));
    dispatch(onGetCreativeType());
  }, []);

  useEffect(() => {
    setData(campaign);
    if (Object.keys(campaign)?.length !== 0) {
      if (campaign.device_platform === null) {
        campaign.device_platform = 'DESKTOP';
      }
      dispatch(
        onGetDefaultCampaign({
          device_platform: campaign.device_platform,
          banner_format: campaign.banner_format,
          ad_format: campaign.ad_format,
        }),
      );
    }
  }, [campaign]);

  useEffect(() => {
    const newStatus = defaultOptions;
    if (oldStatus === 'DRAFT') {
      newStatus[0].disabled = false;
      newStatus[1].disabled = false;
      newStatus[5].disabled = false;
    } else if (oldStatus === 'ACTIVE') {
      newStatus[1].disabled = false;
      newStatus[2].disabled = false;
      newStatus[4].disabled = false;
    } else if (oldStatus === 'PAUSED') {
      newStatus[2].disabled = false;
      newStatus[1].disabled = false;
      newStatus[4].disabled = false;
    } else if (oldStatus === 'FINISHED') {
      newStatus[4].disabled = false;
      newStatus[1].disabled = false;
      newStatus[5].disabled = false;
    } else if (oldStatus === 'OUT_OF_BUDGET') {
      newStatus[3].disabled = false;
      newStatus[1].disabled = false;
      newStatus[4].disabled = false;
    } else if (oldStatus === 'ARCHIVED') {
      newStatus[5].disabled = false;
    }

    setOption(newStatus);
  }, [oldStatus]);

  useEffect(() => {
    const yubForm = {
      name: Yup.string().required(t('invalid.Required')),
      CPM: Yup.number()
        .required(t('invalid.Required'))
        .positive(t('invalid.Make sure positive number'))
        .min(
          defaultCampaign.minimumCPM,
          t('invalid.must be greater than or equal', {
            min: defaultCampaign.minimumCPM,
          }),
        ),
      budget: Yup.number()
        .required(t('invalid.Required'))
        .positive(t('invalid.Make sure positive number'))
        .min(
          defaultCampaign.minimumBudget,
          t('invalid.must be greater than or equal', {
            min: defaultCampaign.minimumBudget,
          }),
        ),
      max_budget: Yup.number().nullable(true),
    };
    if (max_budget) {
      yubForm.max_budget = Yup.number()
        .min(
          defaultCampaign.minimumMaxBudget,
          t('invalid.must be greater than or equal', {
            min: defaultCampaign.minimumMaxBudget,
          }),
        )
        .nullable(true);
    }

    const newUpdateCampaignSchema = Yup.object().shape(yubForm);

    setUpdateCampaignSchema(newUpdateCampaignSchema);
  }, [defaultCampaign, max_budget]);
  //html condition

  useEffect(() => {
    if (campaign.creative_types) {
      const originalType = campaign.creative_types.map(
        (creative_type: { name: string; key: string }) => {
          return {
            label: creative_type.name,
            value: creative_type.name,
            key: creative_type.key,
          };
        },
      );

      setTypeData(originalType);
    }
  }, [campaign]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={updateCampaignSchema}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <FormikForm>
              <Row>
                {typeof isHaveCreative === 'boolean' && (
                  <>
                    <div className="d-flex flex-column align-items-start">
                      {!isHaveCreative && (
                        <div className="bg-tomato w-auto c-w font-size-22 px-4 py-2 mb-4">
                          <i
                            className={`mdi mdi-alert font-size-22 me-3 me-3 ms-2 c-w${
                              windowWidth < 1120 && 'ms-4'
                            }`}
                          />

                          {t('campaign:Your campaign is not complete.')}

                          <a
                            className="ms-2 cursor a-cw fw-bold c-w"
                            href="Creatives"
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectSettingTab('2');
                            }}
                          >
                            {t('campaign:Add Creative')}
                          </a>
                        </div>
                      )}

                      {!isEnoughBalance && (
                        <div className="bg-tomato w-auto c-w font-size-22 px-4 py-2 mb-4">
                          <i
                            className={`mdi mdi-alert font-size-22 me-3 ms-2 c-w${
                              windowWidth < 1120 && 'ms-4'
                            }`}
                          />

                          {t('campaign:Not enough funds in the wallet.')}

                          <a
                            className={`cursor a-cw fw-bold c-w ${
                              !isHaveCreative ? 'ms-4 me-1' : 'ms-2'
                            }`}
                            href="Creatives"
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`/wallet`);
                            }}
                          >
                            {t('campaign:Add Funds')}
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="mt-4 px-4">
                  <h2 className="font-size-30 fw-bold">{t('General Details')}</h2>
                  <div>
                    <FormGroup className="mt-4">
                      <Label className="input-label fs-5">
                        {t('campaign:Campaign Status')}
                        <span className="input-required">*</span>
                      </Label>

                      <Select
                        options={options}
                        placeholder={t('creative:Campaign Status')}
                        isDisabled={
                          !isEnoughBalance ||
                          !isHaveCreative ||
                          oldStatus === 'ARCHIVED'
                        }
                        className={`${windowWidth > 991 ? 'w-25' : ''}`}
                        styles={selectStyle()}
                        value={{
                          label: t(`enumCampaign:${status}`),
                          value: status,
                        }}
                        onChange={(e: { value: SetStateAction<string> }) => {
                          setStatus(e.value);
                        }}
                        isOptionDisabled={(option: { disabled: any }) =>
                          option.disabled
                        }
                      />
                    </FormGroup>
                    <FormGroup className="mt-4">
                      <Label className="input-label fs-5">
                        {t('campaign:Campaign name')}
                        <span className="input-required">*</span>
                      </Label>
                      <Field
                        name="name"
                        type="text"
                        className={`form-control ${
                          errors.name && touched.name && 'is-invalid'
                        } ${windowWidth > 991 && 'w-50'}`}
                        value={name || ''}
                        onChange={(e: any) => {
                          setName(e.currentTarget.value);
                          handleChange(e);
                        }}
                        placeholder={t('invalid.Required')}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-message fs-6"
                      />
                    </FormGroup>
                    <div className={`mt-4 ${windowWidth > 991 ? 'w-50' : ''}`}>
                      <CreativeType
                        setTypeData={setTypeData}
                        typeData={typeData}
                        typeOption={typeOption}
                        campaign={campaign}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4 px-4">
                  <h2 className="font-size-30 fw-bold">
                    {t('campaign:Campaign Settings')}
                  </h2>

                  <FormGroup className="mt-4">
                    <Label className="input-label fs-5">
                      {t('campaign:CPM Price')}
                      <span className="input-required">*</span>
                    </Label>
                    <div className={`input-group ${windowWidth > 991 && 'w-50'}`}>
                      <Field
                        name="CPM"
                        type="text"
                        className={`form-control ${
                          errors.CPM && touched.CPM && 'is-invalid'
                        } ${windowWidth > 991 && 'w-50'}`}
                        onKeyDown={(evt: any) => {
                          preventNonNumber(evt);
                        }}
                        value={CPM || ''}
                        onChange={(e: any) => {
                          setCPM(e.currentTarget.value);
                          handleChange(e);
                        }}
                        placeholder={t('invalid.Required')}
                      />
                      <div className="input-group-text">$ / CPM</div>
                    </div>
                    <ErrorMessage
                      name="CPM"
                      component="div"
                      className="invalid-message fs-6"
                    />
                    <p className="font-size-12 mt-2">
                      {t('Minimum price')} {defaultCampaign.minimumCPM}$
                      <br />
                      {t('Recommended price')} {defaultCampaign.recommendCPM}$
                    </p>
                  </FormGroup>
                  <Label className="input-label fs-5">
                    {t('campaign:Budget')}
                    <span className="input-required">*</span>
                  </Label>
                  <ul className="nav nav-pills">
                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={`font-size-12 ${
                            isUnlimitedBudget
                              ? 'btn btn-primary active'
                              : 'btn btn-primary'
                          }`}
                          onClick={() => {
                            setIsUnlimitedBudget(true);
                          }}
                        >
                          {t('campaign:Unlimited')}
                        </button>
                        <button
                          type="button"
                          className={`font-size-12 ${
                            isUnlimitedBudget === false
                              ? 'btn btn-primary active'
                              : 'btn btn-primary'
                          }`}
                          onClick={() => setIsUnlimitedBudget(false)}
                        >
                          {t('campaign:Custom')}
                        </button>
                      </div>
                    </div>
                  </ul>
                  <FormGroup className="mt-4">
                    <Label className="input-label fs-5">
                      {t('campaign:Daily Budget')}
                      <span className="input-required">*</span>
                    </Label>
                    <div className={`input-group ${windowWidth > 991 && 'w-50'}`}>
                      <Field
                        name="budget"
                        type="text"
                        className={`form-control ${
                          errors.budget && touched.budget && 'is-invalid'
                        }`}
                        onKeyDown={(evt: any) => {
                          preventNonNumber(evt);
                        }}
                        value={budget || ''}
                        onChange={(e: any) => {
                          setBudget(+e.currentTarget.value);
                          handleChange(e);
                        }}
                        placeholder={t('invalid.Required')}
                      />
                      <div className="input-group-text">$ / CPM</div>
                    </div>
                    <ErrorMessage
                      name="budget"
                      component="div"
                      className="invalid-message fs-6"
                    />
                    <p className="font-size-12 mt-2">
                      {t('Minimum price')} {defaultCampaign.minimumBudget}$
                    </p>
                  </FormGroup>

                  {!isUnlimitedBudget && (
                    <FormGroup className="mt-4">
                      <Label className="input-label fs-5">
                        {t('campaign:Total budget')}
                        <span className="input-required">*</span>
                      </Label>
                      <div className={`input-group ${windowWidth > 991 && 'w-50'}`}>
                        <Field
                          name="max_budget"
                          type="text"
                          className={`form-control form-control-lg ${
                            errors.max_budget && touched.max_budget && 'is-invalid'
                          } ${windowWidth > 991 && 'w-50'}`}
                          onKeyDown={(evt: any) => {
                            preventNonNumber(evt);
                          }}
                          value={max_budget || ''}
                          onChange={(e: any) => {
                            setMaxBudget(+e.currentTarget.value);
                            handleChange(e);
                          }}
                          placeholder={t('invalid.Required')}
                        />
                        <div className="input-group-text">$, {t('USD')}</div>
                      </div>
                      <ErrorMessage
                        name="max_budget"
                        component="div"
                        className="invalid-message fs-6"
                      />
                      <p className="font-size-12 mt-2">
                        {t('Minimum price')} {defaultCampaign.minimumMaxBudget}
                        $
                        <br />
                        {t(
                          'campaign:The campaign is limited to the total budget amount.',
                        )}
                      </p>
                    </FormGroup>
                  )}
                </div>
                <CardBody>
                  <button
                    disabled={
                      !typeData?.length ||
                      !!errors.name ||
                      !!errors.CPM ||
                      !!errors.budget ||
                      (!isUnlimitedBudget && !!errors.max_budget)
                    }
                    type="submit"
                    className="btn mt-4 btn-success mt-0"
                  >
                    {t('button:Save Change')}
                  </button>
                </CardBody>
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default withTranslation()(OverviewPage);
