import { Container, Row, Col } from 'reactstrap';
import config from '../../config';

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col md={6} className="text-color-primary">
            {new Date().getFullYear()} © {config.appName}.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
