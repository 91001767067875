import { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import useWindowSize from 'src/utils/windowSize';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUsers as onGetUsers } from '../../store/users/actions';
import UsersTable from '../../components/Admin/UsersTable';
import './admin.style.scss';

const UserPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(onGetUsers({ limit: 10, page: 1 }));
  }, []);

  const { width } = useWindowSize();
  return (
    <div className={`${width > 192 ? 'page-content' : ''}`}>
      <MetaTags>
        <title>{t('Page.Admin Users')}</title>
      </MetaTags>
      <UsersTable />
    </div>
  );
};

export default UserPage;
