import { TaskTypesTypes } from './actionTypes';

export const getTaskTypeByGroup = () => {
  return {
    type: TaskTypesTypes.GET_TASKTYPE_GROUP,
  };
};

export const getTaskTypeByGroupSuccess = (payload: any) => {
  return {
    type: TaskTypesTypes.GET_TASKTYPE_GROUP_SUCCESS,
    payload,
  };
};
