import { call, put, takeEvery } from 'redux-saga/effects';

import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { CreativesTypes } from './actionTypes';

import {
  getCreativesByUserIdSuccess,
  getCreativesByUserIdFail,
  getCreativesRequestSuccess,
  getCreativeSizesSuccess,
  getAllCreativeSizesSuccess,
  getCreativesCampaignSuccess,
  filterCreativeCampaignSuccess,
  getCreativeReportSuccess,
} from './actions';

import {
  adImageSizesWhereGraphql,
  createCreativeReduce,
  creativesByUserIdGraphql,
  getCreativesCampaignGraphql,
  FilterCreativeGraphql,
  filterCreativeCampaignGraphql,
  getCreativeReportGraphql,
} from '../../graphql/advertiser/creative';
import { setLoading, unsetLoading } from '../actions';
import {
  setCreativeRequestLoading,
  unsetCreativeRequestLoading,
  setCreativeSizesLoading,
  unsetCreativeSizesLoading,
  setCreativeCampaignsLoading,
  unsetCreativeCampaignsLoading,
  setCreativeReportLoading,
  unsetCreativeReportLoading,
} from '../global/actions';

function* fetchCreativesByUserId() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(creativesByUserIdGraphql);
    yield put(getCreativesByUserIdSuccess(response));
  } catch (error) {
    yield put(getCreativesByUserIdFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchGetCreativesCampaign({ campaignKey }: any) {
  try {
    yield put(setCreativeCampaignsLoading());
    const response: Promise<any> = yield call(
      getCreativesCampaignGraphql,
      campaignKey,
    );
    yield put(getCreativesCampaignSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetCreativeCampaignsLoading());
  }
}

function* fetchCreativeReport({ payload }: any) {
  try {
    yield put(setCreativeReportLoading());

    const response: Promise<any> = yield call(getCreativeReportGraphql, payload);

    yield put(getCreativeReportSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetCreativeReportLoading());
  }
}

function* fetchFilterCreativeCampaign({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      filterCreativeCampaignGraphql,
      payload,
    );
    yield put(filterCreativeCampaignSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getCreativesRequest({ payload }: any) {
  try {
    yield put(setCreativeRequestLoading());
    const response: Promise<any> = yield call(FilterCreativeGraphql, payload);
    yield put(getCreativesRequestSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetCreativeRequestLoading());
  }
}

function* fetchCreativeCampaign({ payload }: any) {
  try {
    yield call(createCreativeReduce, payload);
  } catch (error) {
    notify(i18n.t('error:Your creative has not been created.'), 'error');
  } finally {
    if (payload.lastItem) {
      yield put(unsetLoading());
      payload.history.push(`/campaigns/${payload.campaign_key}`);
    }
  }
}

function* fetchCreativeSizes({ payload }: any) {
  try {
    yield put(setCreativeSizesLoading());

    const creativeSize = payload;
    if (payload.ad_image_type) {
      const response: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      yield put(getCreativeSizesSuccess(response));
    } else {
      const allCreativeSizes: any = {};

      creativeSize.ad_image_type = 'BANNER_IN_PAGE';
      const bannerInPageResponse: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      allCreativeSizes.bannerInPage = bannerInPageResponse;

      creativeSize.ad_image_type = 'BANNER_STICKY';
      const bannerStickyResponse: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      allCreativeSizes.bannerSticky = bannerStickyResponse;

      creativeSize.ad_image_type = 'BANNER_HEADER';
      const bannerHeaderResponse: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      allCreativeSizes.bannerHeader = bannerHeaderResponse;

      creativeSize.ad_image_type = 'BANNER_SLIDE';
      const bannerSlideResponse: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      allCreativeSizes.bannerSlide = bannerSlideResponse;

      creativeSize.ad_image_type = 'NATIVE_ADVERTISER';
      const nativeAdvertiserResponse: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      allCreativeSizes.nativeAdvertiser = nativeAdvertiserResponse;

      creativeSize.ad_image_type = 'NATIVE_PUBLISHER';
      const nativePublisherResponse: Promise<any> = yield call(
        adImageSizesWhereGraphql,
        creativeSize,
      );
      allCreativeSizes.nativePublisher = nativePublisherResponse;

      yield put(getAllCreativeSizesSuccess(allCreativeSizes));
    }
  } catch (error) {
    // something here
  } finally {
    yield put(unsetCreativeSizesLoading());
  }
}

function* creatives() {
  yield takeEvery(CreativesTypes.GET_CREATIVE_REQUEST, getCreativesRequest);
  yield takeEvery(CreativesTypes.GET_CREATIVE_BY_USER_ID, fetchCreativesByUserId);
  yield takeEvery(CreativesTypes.GET_CREATIVE_SIZES, fetchCreativeSizes);

  yield takeEvery(CreativesTypes.CREATE_CREATIVE, fetchCreativeCampaign);

  yield takeEvery(CreativesTypes.GET_CREATIVES_CAMPAIGN, fetchGetCreativesCampaign);

  yield takeEvery(CreativesTypes.GET_CREATIVE_REPORT, fetchCreativeReport);

  yield takeEvery(
    CreativesTypes.FILTER_CREATIVE_CAMPAIGN,
    fetchFilterCreativeCampaign,
  );
}

export default creatives;
