import { gql } from '@apollo/client';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';

const createApplicationSql = async (payload: {
  name: string;
  store_id?: string | null;
  mobile_platform: string | null;
}) => {
  const mutation = gql`
    mutation createApplicationInput($payload: CreateApplicationInput!) {
      createApplication(createApplicationInput: $payload) {
        name
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: { payload },
    });
    return true;
  } catch (error) {
    if (payload.mobile_platform === 'ANDROID') {
      notify(i18n.t('error:Invalid package name.'), 'error');
    } else {
      notify(i18n.t('error:Invalid store id.'), 'error');
    }
    return false;
  }
};

const getApplicationFilterByUser = async (payload: {
  search?: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
}) => {
  const query = gql`
    query FilterApplicationInput($payload: FilterApplicationInput!) {
      filterApplicationByUser(filterApplicationInput: $payload) {
        items {
          application_key
          name
          status
          revenue
          current_revenue
          store_name
          mobile_platform
          created_at
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { payload },
    fetchPolicy: 'no-cache',
  });

  return result.data.filterApplicationByUser;
};

const filterApplicationCreativeApplicationKey = async (payload: {
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
  application_key: string;
}) => {
  const query = gql`
    query FilterApplicationCreativeApplicationKey(
      $payload: FilterApplicationCreativeInput!
    ) {
      filterApplicationCreativeApplicationKey(
        filterApplicationCreativeInput: $payload
      ) {
        items {
          key
          width
          height
          banner_format
          application {
            application_key
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { payload },
    fetchPolicy: 'no-cache',
  });

  return result.data.filterApplicationCreativeApplicationKey;
};

const GetApplicationByKey = async (application_key: string) => {
  const query = gql`
    query GetApplicationByKey($application_key: String!) {
      getApplicationByKey(application_key: $application_key) {
        name
        status
        eCPM
        current_click
        current_impression
        current_revenue
        update_at
        created_at
      }
    }
  `;

  const result: any = await client.query({
    query,
    variables: { application_key },
    fetchPolicy: 'no-cache',
  });

  return result.data.getApplicationByKey;
};

const softDeleteApplication = async (application_key: string) => {
  const mutation = gql`
    mutation softDeleteApplication($application_key: String!) {
      softDeleteApplication(application_key: $application_key)
    }
  `;
  try {
    await client.mutate({
      mutation,
      variables: { application_key },
    });
    notify(i18n.t('success:Application has been deleted'), 'success');
  } catch (error) {
    notify(i18n.t('error:Application has not been delete.'), 'error');
  }
};

const softDeleteApplicationCreative = async (key: string) => {
  const mutation = gql`
    mutation SoftDeleteApplicationCreative($key: String!) {
      softDeleteApplicationCreative(key: $key)
    }
  `;
  try {
    await client.mutate({
      mutation,
      variables: { key },
    });
    notify(i18n.t('success:Application creative has been deleted'), 'success');
  } catch (error) {
    notify(i18n.t('error:Application creative has not been delete.'), 'error');
  }
};

const createApplicationCreative = async (payload: string) => {
  const mutation = gql`
    mutation CreateApplicationCreative($payload: CreateApplicationCreativeInput!) {
      createApplicationCreative(createApplicationCreativeInput: $payload)
    }
  `;
  try {
    await client.mutate({
      mutation,
      variables: { payload },
    });
    notify(i18n.t('success:Application has been create'), 'success');
  } catch (error) {
    notify(i18n.t('error:Application has not been create.'), 'error');
  }
};

const getApplicationGraphDatum = async (payload: {
  application_key: any;
  limit: number;
  page: number;
  log_range: string;
  log_start?: string;
  log_end?: string;
  shortcut_range?: string;
  sortBy?: string;
  sortType?: string;
}) => {
  const query: any = gql`
    query getApplicationDatumGraph($payload: GraphApplicationDatum!) {
      getApplicationDatumGraph(input: $payload) {
        graphItems {
          total_click
          total_impression
          eCPM
          revenue
          updated_at
        }
        graphType
        items {
          impression
          click
          revenue
          eCPM
          updated_at
        }
        totalCount
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      payload,
    },
    fetchPolicy: 'network-only',
  });

  return result.data.getApplicationDatumGraph;
};

const getFilterApplicationDatumTable = async (
  application_key: any,
  search?: string,
  limit?: number,
  page?: number,
  sortBy?: string,
  sortType?: string,
) => {
  const input: any = {
    application_key,
    limit: Number(limit) || 10,
    page: Number(page) || 1,
    search,
    sortBy,
    sortType,
  };
  const query: any = gql`
    query filterApplicationDatumTable(
      $filterApplicationDatumInput: FilterApplicationDatumInput
    ) {
      filterApplicationDatumTable(
        filterApplicationDatumInput: $filterApplicationDatumInput
      ) {
        items {
          impression
          click
          revenue
          eCPM
          updated_at
        }
        totalCount
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      filterApplicationDatumInput: input,
    },
    fetchPolicy: 'network-only',
  });

  return result.data.filterApplicationDatumTable;
};

const getPublisherEarningTransaction = async (
  application_key: any,
  limit?: number,
  page?: number,
  sortBy?: string,
  sortType?: string,
) => {
  const input: any = {
    limit: Number(limit) || 10,
    page: Number(page) || 1,
    sortBy,
    sortType,
  };
  const query: any = gql`
    query publisherEarningTransaction(
      $application_key: String
      $input: TransactionsQueryInput!
    ) {
      publisherEarningTransaction(application_key: $application_key, input: $input) {
        items {
          uuid
          user_id
          wallet_id
          FIAT_amount
          created_at
          updated_at
          application_id
          movement {
            transaction_type_id
            opening_balance
            __typename
          }
          website {
            url
          }
        }
        totalCount
      }
    }
  `;

  const result = await client.query({
    query,
    variables: {
      application_key,
      input,
    },
    fetchPolicy: 'network-only',
  });

  return result.data.publisherEarningTransaction;
};

const checkApplicationByUserId = async (app_id: any) => {
  const mutate = gql`
    mutation filterAppByUserid($appKey: String!) {
      filterAppByUserid(appKey: $appKey) {
        Status
        Message
      }
    }
  `;

  const result = await client.mutate({
    mutation: mutate,
    variables: {
      appKey: app_id,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.filterAppByUserid;
};

export {
  createApplicationSql,
  getApplicationFilterByUser,
  softDeleteApplication,
  GetApplicationByKey,
  createApplicationCreative,
  softDeleteApplicationCreative,
  filterApplicationCreativeApplicationKey,
  getApplicationGraphDatum,
  getFilterApplicationDatumTable,
  getPublisherEarningTransaction,
  checkApplicationByUserId,
};
