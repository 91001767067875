import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import InviteOnlyTransactionsTable from 'src/components/Admin/InviteOnlyTransactionsTable';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';

const InviteOnlyPage = ({ t }: any) => {
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Page.invite Transactions')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.PAYMENT_TRANSACTION, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card className="mt-3">
              <CardBody>
                <InviteOnlyTransactionsTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(InviteOnlyPage);
