import { Category } from 'src/common/data/categories-marketer-campaign';
import date_step7 from 'src/assets/images/date_step7.png';
import date_step1 from 'src/assets/images/date_step1.png';
import date_step3 from 'src/assets/images/date_step3.png';
import date_step4 from 'src/assets/images/date_step4.png';
import date_step5 from 'src/assets/images/date_step5.png';
import date_step6 from 'src/assets/images/date_step6.png';
import i18n from 'src/i18n';
// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // REVIEW_BEFORE_POSTING,
  // eslint-disable-next-line no-unused-vars
  DATE_INPUT,
  // eslint-disable-next-line no-unused-vars
  TEXT_EDITOR_INPUT,
  // eslint-disable-next-line no-unused-vars
  REVIEW_TYPE,
}

const formControlPhoto: FormControl[] = [
  // {
  //   label: 'Review Format',
  //   name: 'review_format',
  //   type: FormInputType.STRING,
  //   disabled: true,
  // },
  // {
  //   label: 'marketer:create_campaign.Review Options',
  //   name: 'review_options',
  //   type: FormInputType.REVIEW_TYPE,
  //   tooltip: i18n.t('select the review option that you want influencer to review'),
  // },
  {
    label: 'work_order',
    name: 'work_order',
    type: FormInputType.TEXT_EDITOR_INPUT,
    required: true,
    tooltip: i18n.t(
      'button:Specify the work order you want the influencer to do, for example, taking a photo with the product.',
    ),
  },
  {
    label: 'target_group',
    name: 'target_group',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_target_group',
  },
  {
    label: 'Product_name_review',
    name: 'product_name',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_review',
  },
  {
    label: 'Market_Challenges',
    name: 'market_challenge',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Market_Challenges',
  },
  {
    label: 'Highlights_product',
    name: 'highlight_of_product',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Highlights_product',
  },
  {
    label: 'words_sentences',
    name: 'forbidden_phrase',
    type: FormInputType.STRING,
    input_type: 'textarea',
    tooltip: 'tooltip_words_sentences',
  },
  {
    label: 'Hashtag_word',
    name: 'hashtag',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Hashtag_word',
  },
  {
    label: 'Link_download',
    name: 'link_accompanying_work',
    type: FormInputType.STRING,
    input_type: 'textarea',
    tooltip: 'tooltip_Link_download',
  },
  {
    label: 'Influencer_prohibited',
    name: 'brands_prohibit',
    type: FormInputType.STRING,
    input_type: 'textarea',
    // tooltip: 'tooltip_Influencer_prohibited',
  },
  // {
  //   label: 'work inspection',
  //   name: 'is_review_before_posting',
  //   type: FormInputType.REVIEW_BEFORE_POSTING,
  //   categories: [
  //     {
  //       label: 'NEED',
  //       value: false,
  //       icon: 'fas fa-check',
  //       background_color: '#94B49F',
  //     },
  //     {
  //       label: 'DO NOT NEED',
  //       value: true,
  //       icon: 'fas fa-times',
  //       background_color: '#ECB390',
  //     },
  //   ],
  // },
  {
    label: 'Date of submission of work to influencers',
    name: 'date_submission',
    type: FormInputType.DATE_INPUT,
    MinDate: Date.now(),
    icon: date_step1,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    // 'linear-gradient(to right top, #3aad96, #33a896, #2da395, #279e94, #229993)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'The last day the influencer has to accept the job.',
    name: 'last_day_accept_job',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step7,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Last day that influencers must submit their work for review.',
    name: 'last_day_influencer_submit',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step3,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: `Last day to review and approve influencer's work.`,
    name: 'last_day_to_approve',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step4,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Influencers post internal jobs.',
    name: 'influencer_post_internal_job',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step5,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Collect statistics and finish the internal work.',
    name: 'finish_internal_work',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step6,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
];

const formControlShare: FormControl[] = [
  {
    label: 'Link_share',
    name: 'link_share',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_URL_link',
  },
  {
    label: 'Hashtag_word',
    name: 'hashtag',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Hashtag_word',
  },
  {
    label: 'Message_to_influencer',
    name: 'message_to_influencer',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_message_to_influencer',
  },
  {
    label: 'words_sentences',
    name: 'forbidden_phrase',
    type: FormInputType.STRING,
    input_type: 'textarea',
    tooltip: 'tooltip_words_sentences',
  },
  {
    label: 'Date of submission of work to influencers',
    name: 'date_submission',
    type: FormInputType.DATE_INPUT,
    MinDate: Date.now(),
    icon: date_step1,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'The last day the influencer has to accept the job.',
    name: 'last_day_accept_job',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step7,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Last day that influencers must submit their work for review.',
    name: 'last_day_influencer_submit',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step3,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: `Last day to review and approve influencer's work.`,
    name: 'last_day_to_approve',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step4,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Influencers post internal jobs.',
    name: 'influencer_post_internal_job',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step5,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Collect statistics and finish the internal work.',
    name: 'finish_internal_work',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step6,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
];

const formControlVideo: FormControl[] = [
  // {
  //   label: 'marketer:create_campaign.Review Options',
  //   name: 'review_options',
  //   type: FormInputType.REVIEW_TYPE,
  //   tooltip: i18n.t('select the review option that you want influencer to review'),
  // },
  {
    label: 'work_order',
    name: 'work_order',
    type: FormInputType.TEXT_EDITOR_INPUT,
    required: true,
  },
  {
    label: 'target_group',
    name: 'target_group',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_target_group',
  },
  {
    label: 'Product_name_review',
    name: 'product_name',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_review',
  },
  {
    label: 'Market_Challenges',
    name: 'market_challenge',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Market_Challenges',
  },
  {
    label: 'Highlights_product',
    name: 'highlight_of_product',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Highlights_product',
  },
  {
    label: 'words_sentences',
    name: 'forbidden_phrase',
    type: FormInputType.STRING,
    input_type: 'textarea',
    tooltip: 'tooltip_words_sentences',
  },
  {
    label: 'Hashtag_word',
    name: 'hashtag',
    type: FormInputType.STRING,
    input_type: 'textarea',
    required: true,
    tooltip: 'tooltip_Hashtag_word',
  },

  // {
  //   Youtube Tag
  // },
  {
    label: 'Link_download',
    name: 'link_accompanying_work',
    type: FormInputType.STRING,
    input_type: 'textarea',
    tooltip: 'tooltip_Link_download',
  },
  {
    label: 'Influencer_prohibited',
    name: 'brands_prohibit',
    type: FormInputType.STRING,
    input_type: 'textarea',
  },
  {
    label: 'Date of submission of work to influencers',
    name: 'date_submission',
    type: FormInputType.DATE_INPUT,
    MinDate: Date.now(),
    icon: date_step1,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'The last day the influencer has to accept the job.',
    name: 'last_day_accept_job',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step7,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Last day that influencers must submit their work for review.',
    name: 'last_day_influencer_submit',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step3,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: `Last day to review and approve influencer's work.`,
    name: 'last_day_to_approve',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step4,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Influencers post internal jobs.',
    name: 'influencer_post_internal_job',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step5,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
  {
    label: 'Collect statistics and finish the internal work.',
    name: 'finish_internal_work',
    type: FormInputType.DATE_INPUT,
    MinDate: '',
    icon: date_step6,
    backgroundColor: 'linear-gradient(122.98deg, #82E3D6 21.41%, #A983FB 79.1%)',
    iconColor: '#FFFFFF',
    textColor: '#495057',
  },
];
export interface FormControl {
  label: string;
  name: string;
  type: FormInputType;
  required?: boolean;
  disabled?: boolean;
  max_length?: number;
  min_length?: number;
  categories?: Category[];
  input_type?: string;
  MinDate?: any;
  tooltip?: string;
  icon?: string;
  iconColor?: string;
  textColor?: string;
  backgroundColor?: string;
}

// eslint-disable-next-line import/prefer-default-export
export { formControlShare, formControlPhoto, formControlVideo };
