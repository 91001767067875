const ImageAutoBalance = (props: any) => {
  const { href, alt, src, sizeImage, width, height, size } = props;

  let scaleY = 1;
  if (size === 'md') {
    if (width / height > 3) {
      scaleY = 1.5;
    }
  }
  const autoImageWidth = (
    <img
      style={{
        width: 'auto',
        height: (sizeImage / (width / height)) * scaleY,
        marginTop: 10,
        marginBottom: 10,
      }}
      alt={alt}
      src={src}
    />
  );

  const autoImageHeight = (
    <img
      style={{
        width: sizeImage / (height / width),
        height: 'auto',
        marginTop: 10,
        marginBottom: 10,
      }}
      alt={alt}
      src={src}
    />
  );

  if (width >= height) {
    return href ? (
      <a href={href} target="_blank" rel="noreferrer">
        {autoImageWidth}
      </a>
    ) : (
      autoImageWidth
    );
  } else {
    return href ? (
      <a href={href} target="_blank" rel="noreferrer">
        {autoImageHeight}
      </a>
    ) : (
      autoImageHeight
    );
  }
};

export default ImageAutoBalance;
