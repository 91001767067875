import { Modal, Card, CardBody } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { withTranslation } from 'react-i18next';
import config from 'src/config';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';
import ImageAutoBalance from '../Common/ImageAutoBalance';

const PaymentDetailModal = ({
  title,
  isOpen,
  handleToggle,
  handleClose,
  data,
  t,
}: any) => {
  let impression = 0;
  let cpm = 0;
  let avg_cmp = 0;
  const creativeCampaignDatums = [];
  if (data) {
    const { campaignDatums } = data;
    if (campaignDatums) {
      for (let i = 0; i < campaignDatums?.length; i++) {
        impression += campaignDatums[i].dailyImpression;
        cpm += campaignDatums[i].CPM;
        for (let j = 0; j < campaignDatums[i].creativeCampaignDatums?.length; j++) {
          creativeCampaignDatums.push(campaignDatums[i].creativeCampaignDatums[j]);
        }
      }
      avg_cmp = cpm / campaignDatums?.length;
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {data && (
        <div className="modal-body p-0">
          <div className="table-responsive">
            <table className="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:ID')}:
                  </th>
                  <td>{data.uuid}</td>
                </tr>

                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Email')}:
                  </th>
                  <td>
                    {data?.campaign?.user
                      ? data?.campaign?.user?.email
                      : data?.user?.email}
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('campaign:Campaign name')}:
                  </th>
                  <td>{data?.campaign?.name}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Impression')}:
                  </th>
                  <td>{impression}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:AVG CPM')}:
                  </th>
                  <td>{avg_cmp}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Amount')}:
                  </th>
                  <td>{Math.round(data.FIAT_amount * 1000) / 1000}$</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Timestamp')}:
                  </th>
                  <td>
                    <DateTimeComponent
                      dateTime={data.created_at}
                      dateTimeType={DateTimeTypes.TRANSACTION_DETAIL_DATE_TIME}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Number of creatives')}:
                  </th>
                  <td>{creativeCampaignDatums?.length}</td>
                </tr>
              </tbody>
            </table>
            {creativeCampaignDatums?.length && (
              <Card
                style={{
                  height: '100%',
                  margin: '10px',
                  border: '0',
                }}
              >
                <CardBody>
                  <h4>{t('creative:Creatives')}</h4>
                  <SimpleBar
                    className="table-responsive px-3"
                    style={{ maxHeight: '220px' }}
                  >
                    <table className="table table-nowrap mb-0 text-center">
                      <tbody>
                        <tr>
                          <th>{t('table:Name')}</th>
                          <th>{t('table:Preview')}</th>
                          <th>{t('table:Amount')}</th>
                          <th>{t('table:Impression')}</th>
                        </tr>
                        {creativeCampaignDatums.map((creativeDatum: any) => {
                          const { creative, creativeCampaign } = creativeDatum;

                          let image_storage;
                          if (creative) {
                            image_storage = creative.image_storage;
                          }

                          return (
                            <tr key={creativeDatum.uuid}>
                              {creative ? (
                                <td>{creative.name}</td>
                              ) : (
                                <td>{creativeCampaign.name}</td>
                              )}

                              <td className="p-2">
                                <div className="pic-frame">
                                  <div className="pic-middle">
                                    {creative ? (
                                      <ImageAutoBalance
                                        href={`${config.backendUrl}creatives/display/${image_storage.key}`}
                                        alt="ad image"
                                        src={`${config.backendUrl}creatives/display/${image_storage.key}`}
                                        sizeImage={50}
                                        width={+creative.size.split('x')[0]}
                                        height={+creative.size.split('x')[1]}
                                        className="mt-0"
                                        size="md"
                                      />
                                    ) : (
                                      <div>Pop-under</div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>{creativeDatum.currentDailyBudget}</td>
                              <td>{creativeDatum.dailyImpression}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </SimpleBar>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withTranslation()(PaymentDetailModal);
