import { gql } from '@apollo/client';
import { client } from '../client';
import { WithdrawTransactionsQuery } from '../../common/types';

const withdrawRequest = async (data: any) => {
  const CREATE_WITHDRAW_REQUEST = gql`
    mutation CreateWithdrawRequest($data: CreateWithdrawInput!) {
      createWithdrawRequest(createWithdrawInput: $data)
    }
  `;

  const response: any = client.mutate({
    mutation: CREATE_WITHDRAW_REQUEST,
    variables: {
      data,
    },
    errorPolicy: 'all',
  });

  return response;
};

const withdrawAdminRequest = async (data: any) => {
  console.log('withdrawAdminRequest data', data);
  const CREATE_WITHDRAW_ADMIN_REQUEST = gql`
    mutation CreateAdminWithdrawInput($data: CreateAdminWithdrawInput!) {
      createAdminWithDrawRequest(createAdminWithdrawInput: $data)
    }
  `;

  const response: any = client.mutate({
    mutation: CREATE_WITHDRAW_ADMIN_REQUEST,
    variables: {
      data,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  return response;
};

const getRecentWithdrawTransactions = async (limit: number = 10) => {
  const GET_RECENT_WITHDRAW_TRANSACTION = gql`
    query GetRecentWithdrawTransactions($limit: Int!) {
      recentWithdrawTransactions(limit: $limit) {
        address
        CRYPTO_amount
        FIAT_amount
        FIAT_rate
        created_at
        updated_at
        status
        currency {
          symbol
        }
      }
    }
  `;

  const result: any = await client.query({
    query: GET_RECENT_WITHDRAW_TRANSACTION,
    variables: {
      limit,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.recentWithdrawTransactions;
};

const getWithdrawTransactions = async (queryInput: WithdrawTransactionsQuery) => {
  const GET_WITHDRAW_TRANSACTION = gql`
    query GetWithdrawTransactions($input: TransactionsQueryInput!) {
      withdrawTransactions(input: $input) {
        items {
          uuid
          tx_hash
          address
          CRYPTO_amount
          FIAT_amount
          FIAT_rate
          created_at
          updated_at
          status
          real_txHash
          currency {
            name
            symbol
            explorer_link
          }
          user {
            email
          }
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_WITHDRAW_TRANSACTION,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.withdrawTransactions;
};

// eslint-disable-next-line import/prefer-default-export
export {
  withdrawRequest,
  getWithdrawTransactions,
  getRecentWithdrawTransactions,
  withdrawAdminRequest,
};
