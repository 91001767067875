import { useSelector } from 'react-redux';
import useWindowSize from 'src/utils/windowSize';
import { roleText as role } from '../../constants/role';
import AdminSidebarContent from './AdminSidebarContent';
import AdvertiserSidebarContent from './AdvertiserSidebarContent';
import PublisherSidebarContent from './PublisherSidebarContent';
import SidebarContent from './sidebarContent';
import AdvertiserSidebarContentNew from './AdvertiserSidebarContentNew';

// eslint-disable-next-line no-unused-vars
const Sidebar = (props: any) => {
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const { width } = useWindowSize();
  // eslint-disable-next-line no-unused-vars
  const renderSidebarContent = () => {
    switch (user.role) {
      case role.ADVERTISER:
        return <AdvertiserSidebarContent />;
      // return <AdvertiserSidebarContentNew />;
      case role.PUBLISHER:
        return <PublisherSidebarContent />;
      case role.ADMIN:
        return <AdminSidebarContent />;
      default:
        return <SidebarContent />;
    }
  };

  return user.role !== role.ADVERTISER ? (
    <div className="vertical-menu">
      {renderSidebarContent()}
      {/* <AdminSidebarContent /> */}
    </div>
  ) : (
    <div
      style={{
        width: `${width <= 992 ? '0px' : '300px'}`,
        position: 'fixed',
        zIndex: '1000',
        height: `${width <= 992 ? '200vh' : '10vh'}`,
        // display: `${width <= 992 ? 'none' : ''}`,
      }}
    >
      <AdvertiserSidebarContentNew />
    </div>
  );
};

export default Sidebar;
