/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum SuggestJob {
  GET_ALL_SUGGESS_JOB = '@@suggest/GET_ALL_SUGGESS_JOB',

  GET_ALL_SUGGESS_JOB_SUCCESS = '@@suggest/GET_ALL_SUGGESS_JOB_SUCCESS',

  GET_ALL_SUGGESS_JOB_FAIL = '@@suggest/GET_ALL_SUGGESS_JOB_FAIL',
}

export default SuggestJob;
