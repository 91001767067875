import { CountriesTypes, CountryState } from './actionTypes';

export const INIT_STATE: CountryState = {
  countries: [],
  error: {},
};

const Countries = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CountriesTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };
    case CountriesTypes.GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Countries;
