import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import {
  addCountryRelationGraphQL,
  updateCampaignGraphQL,
} from '../../../graphql/advertiser/campaign';
import Country from './Country';
import {
  getCountries as onGetCountries,
  getCampaign as onGetCampaign,
  setLoading,
  unsetLoading,
} from '../../../store/actions';

const Geo = ({ t }: any) => {
  //from react
  const { id }: any = useParams();
  const dispatch = useDispatch();
  //update geo variable
  const [isAllCountry, setIsAllCountry] = useState<boolean>(true);
  const [countryData, setCountryData] = useState<any>([]);

  //general variable
  //redux
  const { countries, campaign } = useSelector((state: any) => ({
    countries: state.Countries.countries,
    campaign: state.Campaigns.campaign,
  }));

  //default values
  const countryOption = countries.map((country: { name: string; key: string }) => ({
    label: country.name,
    value: country.name,
    key: country.key,
  }));

  //submit
  const onSubmit = async (e: any) => {
    dispatch(setLoading());
    e.preventDefault();

    const countryDataKeys = await countryData.map(
      (country: { key: string }) => country.key,
    );

    const submitData = {
      campaign_key: campaign.campaign_key,
      isAllCountry,
    };

    if (isAllCountry || countryData?.length === countryOption?.length) {
      setIsAllCountry(true);
      await addCountryRelationGraphQL([], campaign.campaign_key);
      submitData.isAllCountry = true;
    } else {
      await addCountryRelationGraphQL(countryDataKeys, campaign.campaign_key);
    }

    await updateCampaignGraphQL(submitData);

    refreshData();
    dispatch(unsetLoading());
  };
  //set data
  const refreshData = () => {
    dispatch(onGetCountries());
    dispatch(onGetCampaign(id));
  };
  useEffect(() => {
    dispatch(onGetCountries());
    dispatch(onGetCampaign(id));
  }, []);
  useEffect(() => {
    setIsAllCountry(campaign.isAllCountry);
    if (campaign.countries) {
      const originalCountries = campaign.countries.map(
        (country: { name: string; key: string }) => ({
          label: country.name,
          value: country.name,
          key: country.key,
        }),
      );

      setCountryData(originalCountries);
    }
  }, [campaign]);

  return (
    <Row className="p-4">
      <Col md={6}>
        <h5 className="font-size-22 fw-bold">{t('Target Countries')}</h5>
        <Country
          fromSetup
          setIsAllCountry={setIsAllCountry}
          isAllCountry={isAllCountry}
          setCountryData={setCountryData}
          countryData={countryData}
          countryOption={countryOption}
          campaign={campaign}
        />
        <button
          disabled={!isAllCountry && countryData?.length === 0}
          type="submit"
          className="btn btn-success mt-3 fw-bold w-max-content"
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          {t('button:Save Change')}
        </button>
      </Col>
      <Col md={6} className="mt-4 mt-md-0 p-0 p-md-4">
        <div className="instruction-container">
          <h5 className="font-size-22">{t('Instruction')}</h5>
          <p>{t('Target Countries Instructions')}</p>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(Geo);
