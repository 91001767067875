/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum ActivityLogTypes {
  GET_RECENT_ACTIVITY_LOGS = '@@activity_log/GET_RECENT_ACTIVITY_LOGS',
  GET_RECENT_ACTIVITY_LOGS_SUCCESS = '@@activity_log/GET_RECENT_ACTIVITY_LOGS_SUCCESS',

  GET_ACTIVITY_LOG_TRANSACTIONS = '@@activity_log/GET_ACTIVITY_LOG_TRANSACTIONS',
  GET_ACTIVITY_LOG_TRANSACTIONS_SUCCESS = '@@activity_log/GET_ACTIVITY_LOG_TRANSACTIONS_SUCCESS',

  GET_ALL_ADMIN_APPROVE_LOG_SUCCESS = '@@activity_log/GET_ALL_ADMIN_APPROVE_LOG_SUCCESS',
  GET_ALL_ADMIN_APPROVE_LOG = '@@activity_log/GET_ALL_ADMIN_APPROVE_LOG',

  MARK_AS_ALL_READ = '@@activity_log/MARK_AS_ALL_READ',
  MARK_AS_ALL_SUCCESS = '@@activity_log/MARK_AS_ALL_SUCCESS',
  MARK_AS_ALL_FAIL = '@@activity_log/MARK_AS_ALL_FAIL',

  GET_ACTIVITY_LOGS_FAIL = '@@activity_log/GET_ACTIVITY_LOGS_FAIL',
  NEW_ACTIVITY_LOG = '@@activity_log/NEW_ACTIVITY_LOG',

  CLEAR_ACTIVITY_LOGS = '@@activity_log/CLEAR_ACTIVITY_LOGS',

  ADD_ADMIN_APPROVE_LOG = '@@activity_log/ADD_ADMIN_APPROVE_LOG',
}

export interface ActivityLogState {
  recentLogs: any[];
  logs: any[];
  error: Object;
  allAdminApproveLog: Object;
}
