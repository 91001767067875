import queryString from 'query-string';
import GoogleLogin from 'react-google-login';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';
import facebookIcon from 'src/assets/images/social/facebook/facebook-icon.svg';

const RegisterByThirdParty = ({ path, role }: any) => {
  let ref: any = '';
  if (path && role) {
    const { ref: refParam } = queryString.parse(path.search);
    ref = refParam;
  }

  const onFacebookConnect = async () => {
    const permissionScope: string = 'public_profile,email';

    // const permissionScope: string = 'public_profile';

    const clientId: string | undefined = config.facebook.id;
    // const clientId: string | undefined = '1437065540541231';
    if (!clientId) return;

    const redirectUrl: string = `${config.appUrl}auth-facebook`;
    const state = `${role}-ref${ref || null}`;
    window.location.href = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${clientId}&scope=${permissionScope}&redirect_uri=${redirectUrl}&state=${state}`;
  };

  const onConnectYoutube = async (access_token: string, email: string) => {
    const state = `${role}-ref${ref || null}`;
    const redirectUrl: string = `/auth-google/${SocialMediaType.YOUTUBE}/token/${access_token}/?state=${state}&email=${email}`;
    window.location.href = redirectUrl;
  };

  return (
    <div className="register-third-party-container">
      <div className="third-party-content">
        <GoogleLogin
          className="google-button"
          // clientId="372494151787-v07s9qqqqdjmikak3frhns4gkt1a8bf3.apps.googleusercontent.com"
          clientId="1002989356746-8c62gaec6lm7g7pomjn9vvhm44sgp2b8.apps.googleusercontent.com"
          onSuccess={async (res: any) => {
            await onConnectYoutube(res.accessToken, res.profileObj.email);
          }}
          scope="email"
          onFailure={() => {}}
          cookiePolicy="single_host_origin"
          accessType="online"
        />
      </div>
    </div>
  );
};

export default RegisterByThirdParty;
