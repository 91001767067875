/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum CreativesTypes {
  GET_CREATIVE_BY_USER_ID = '@@creative/GET_CREATIVE_BY_USER_ID',
  GET_CREATIVE_BY_USER_ID_SUCCESS = '@@creative/GET_CREATIVE_BY_USER_ID_SUCCESS',
  GET_CREATIVE_BY_USER_ID_FAIL = '@@creative/GET_CREATIVE_BY_USER_ID_FAIL',

  GET_CREATIVES_CAMPAIGN = '@@creative/GET_CREATIVES_CAMPAIGN',
  GET_CREATIVES_CAMPAIGN_SUCCESS = '@@creative/GET_CREATIVES_CAMPAIGN_SUCCESS',

  GET_CREATIVE_REQUEST = '@@creative/GET_CREATIVE_REQUEST',
  GET_CREATIVE_REQUEST_SUCCESS = '@@creative/GET_CREATIVE_REQUEST_SUCCESS',

  GET_CREATIVE_SIZES = '@@creative/GET_CREATIVE_SIZES',
  GET_CREATIVE_SIZES_SUCCESS = '@@creative/GET_CREATIVE_SIZES_SUCCESS',
  GET_ALL_CREATIVE_SIZES_SUCCESS = '@@creative/GET_ALL_CREATIVE_SIZES_SUCCESS',

  CREATE_CREATIVE = '@@creative/CREATE_CREATIVE',

  FILTER_CREATIVE_CAMPAIGN = '@@creative/FILTER_CREATIVE_CAMPAIGN',
  FILTER_CREATIVE_CAMPAIGN_SUCCESS = '@@creative/FILTER_CREATIVE_CAMPAIGN_SUCCESS',

  GET_CREATIVE_REPORT = '@@creative/GET_CREATIVE_REPORT',
  GET_CREATIVE_REPORT_SUCCESS = '@@creative/GET_CREATIVE_REPORT_SUCCESS',
  GET_CREATIVE_REPORT_FAIL = '@@creative/GET_CREATIVE_REPORT_FAIL',
}

export interface CreativeState {
  creativesByUserId: Array<any>;
  transactions: Array<any>;
  creativeSizes: Array<any>;
  allCreativeSizes: Array<any>;
  creativeCampaign: Object;
  filterCreativeCampaign: Object;
  creativeReport: Object;
  error: Object;
  loading: Boolean;
}
