import { takeEvery, fork, put, all, call, delay } from 'redux-saga/effects';

// Login Redux States
import { isEmpty } from 'lodash';
import notify from 'src/utils/notify';
import ForgotPwdTypes from './actionTypes';
import { forgotPasswordFailed, resetPasswordFailed } from './actions';
import {
  forgotPassword as forgotPasswordGql,
  resetPassword as resetPasswordGql,
} from '../../../graphql/common/auth';
import { setLoading, unsetLoading } from '../../global/actions';
import { Response } from '../../../common/types';
import { getErrorMessageByErrorCode } from '../../../utils/error';
import i18n from '../../../i18n';

// If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgotPassword({ payload: { data } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(forgotPasswordGql, data);

    yield delay(800);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        forgotPasswordFailed(
          getErrorMessageByErrorCode(response.errors[0].error_code),
        ),
      );
    } else {
      notify(i18n.t('success:Reset Password Request Success'), 'success');
    }
  } catch (error) {
    yield put(forgotPasswordFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* resetPassword({ payload: { data, history } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(resetPasswordGql, data);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        resetPasswordFailed(
          getErrorMessageByErrorCode(response.errors[0].error_code),
        ),
      );
    } else {
      notify(i18n.t('success:Reset Password Success'), 'success');
      history.push('/login');
    }
  } catch (error) {
    yield put(resetPasswordFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(ForgotPwdTypes.FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(ForgotPwdTypes.RESET_PASSWORD, resetPassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
