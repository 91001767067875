import { ReferralTypes } from './actionTypes';

const initialState = {
  referralEmail: '',
  referralTransactions: [],
  referralSystemTax: '',
  error: '',
  loading: false,
};

const referral = (state = initialState, action: any) => {
  switch (action.type) {
    case ReferralTypes.GET_REFERRAL_EMAIL_SUCCESS:
      return {
        ...state,
        referralEmail: action.payload,
      };
    case ReferralTypes.GET_REFERRAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        referralTransactions: action.payload,
      };
    case ReferralTypes.GET_REFERRAL_SYSTEM_TAX_SUCCESS:
      return {
        ...state,
        referralSystemTax: action.payload,
      };
    case ReferralTypes.GET_REFERRAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default referral;
