import i18n from '../../i18n';

const rules = [
  {
    title: i18n.t('1 Upper Case'),
    regex: /[A-Z]/,
  },
  {
    title: i18n.t('1 Lower Case'),
    regex: /[a-z]/,
  },
  {
    title: i18n.t('1 Number'),
    regex: /[0-9]/,
  },
  {
    title: i18n.t('1 Special Character'),
    regex: /[+@$!%*?&"#'(),./:;<=>[\]^_`{|}~\\]/,
  },
  {
    title: i18n.t('At lease 8 characters long'),
    length: 8,
  },
];

const PasswordCheckList = ({ password }: any) => {
  const renderRules = (pass: string) => {
    return rules.map((item: any) => {
      let matched = false;

      if (item.regex) {
        if (pass.match(item.regex)?.length) {
          matched = true;
        }
      } else {
        matched = pass?.length >= item?.length;
      }

      return (
        <span className="font-size-10" key={item.title}>
          <span className="me-2">
            {matched ? (
              <i
                className="fas fa-check text-success"
                style={{ width: '15px', height: '15px' }}
              />
            ) : (
              <i
                className="fas fa-times text-danger"
                style={{ width: '15px', height: '15px' }}
              />
            )}
          </span>
          {item.title}
        </span>
      );
    });
  };

  return <div className="d-flex flex-column px-4 py-2">{renderRules(password)}</div>;
};

export default PasswordCheckList;
