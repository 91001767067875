import { CreativesTypes } from './actionTypes';

export const createCreative = (payload: any) => ({
  type: CreativesTypes.CREATE_CREATIVE,
  payload,
});

export const getCreativesByUserId = () => ({
  type: CreativesTypes.GET_CREATIVE_BY_USER_ID,
});

export const getCreativesByUserIdSuccess = (creativesByUserId: any) => ({
  type: CreativesTypes.GET_CREATIVE_BY_USER_ID_SUCCESS,
  payload: creativesByUserId,
});

export const getCreativesByUserIdFail = (error: any) => ({
  type: CreativesTypes.GET_CREATIVE_BY_USER_ID_FAIL,
  payload: error,
});

export const getCreativesRequest = (payload: {
  limit?: number;
  page?: number;
  name?: string;
  size?: string;
  verifyStatus?: string;
}) => {
  return {
    type: CreativesTypes.GET_CREATIVE_REQUEST,
    payload: { ...payload },
  };
};

export const getCreativesRequestSuccess = (transactions: any) => {
  return {
    type: CreativesTypes.GET_CREATIVE_REQUEST_SUCCESS,
    payload: transactions,
  };
};

export const getCreativeSizes = (payload: {
  ad_image_type?: string;
  device_platform?: string;
}) => ({
  type: CreativesTypes.GET_CREATIVE_SIZES,
  payload,
});

export const getCreativeSizesSuccess = (creativeSizes: any) => ({
  type: CreativesTypes.GET_CREATIVE_SIZES_SUCCESS,
  payload: creativeSizes,
});

export const getAllCreativeSizesSuccess = (allCreativeSizes: any) => ({
  type: CreativesTypes.GET_ALL_CREATIVE_SIZES_SUCCESS,
  payload: allCreativeSizes,
});

export const getCreativesCampaign = (campaignKey: any) => ({
  type: CreativesTypes.GET_CREATIVES_CAMPAIGN,
  campaignKey,
});

export const getCreativesCampaignSuccess = (creativesCampaign: any) => ({
  type: CreativesTypes.GET_CREATIVES_CAMPAIGN_SUCCESS,
  payload: creativesCampaign,
});

export const filterCreativeCampaign = (payload: any) => ({
  type: CreativesTypes.FILTER_CREATIVE_CAMPAIGN,
  payload,
});

export const filterCreativeCampaignSuccess = (filterCreativeCampaignRes: any) => ({
  type: CreativesTypes.FILTER_CREATIVE_CAMPAIGN_SUCCESS,
  payload: filterCreativeCampaignRes,
});

export const getCreativeReport = (payload: {
  searchCreative?: string;
  search?: string;
  limit?: number;
  page?: number;
  start_date?: string | null;
  end_date?: string | null;
  campaign_key?: string;
}) => ({
  type: CreativesTypes.GET_CREATIVE_REPORT,
  payload,
});

export const getCreativeReportSuccess = (creativeReport: any) => ({
  type: CreativesTypes.GET_CREATIVE_REPORT_SUCCESS,
  payload: creativeReport,
});
