import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { isEmpty, has } from 'lodash';
import { withTranslation } from 'react-i18next';
import { logGraphDate } from '../../../utils/dateTime';
import currenciesSymbol from '../../../constants/currenciesSymbol';
import { layoutTheme } from '../../../constants/layout';

const SystemBalanceChart = ({ i18n }: any) => {
  const labels = [];
  const graphData = [];
  const cryptoData: any = [];
  let maxAmount = 0;
  let datasets: any = [];

  const { logTransactions, layoutMode } = useSelector((state: any) => ({
    logTransactions: state.SystemBalance.logTransactions,
    layoutMode: state.Layout.layoutMode,
  }));

  const getBorderColor = (symbol: string) => {
    let colorCode = '';

    switch (symbol) {
      case currenciesSymbol.BTC:
        colorCode = '#f2a900';
        break;
      case currenciesSymbol.ETH:
        colorCode = '#627EEA';
        break;
      case currenciesSymbol.USDT:
        colorCode = '#189871';
        break;
      case currenciesSymbol.XRP:
        colorCode = '#434c54';
        break;
      case currenciesSymbol.WIS:
        colorCode = '#007aa3';
        break;
      case currenciesSymbol.DOGE:
        colorCode = '#dfa005';
        break;
      default:
        colorCode = '';
    }

    return colorCode;
  };

  if (!isEmpty(logTransactions)) {
    // eslint-disable-next-line no-restricted-syntax
    for (let i = logTransactions.graphItems?.length - 1; i >= 0; i--) {
      const txn = logTransactions.graphItems[i];

      if (!isEmpty(txn.crypto_amount)) {
        for (let j = 0; j < txn.crypto_amount?.length; j++) {
          if (txn.crypto_amount[j]) {
            if (!has(cryptoData, txn.crypto_amount[j].symbol)) {
              cryptoData[txn.crypto_amount[j].symbol] = [];
              cryptoData[txn.crypto_amount[j].symbol].push(
                txn.crypto_amount[j].FIAT_amount,
              );
            } else {
              cryptoData[txn.crypto_amount[j].symbol].push(
                txn.crypto_amount[j].FIAT_amount,
              );
            }
          }
        }
      }

      if (txn.amount > maxAmount) {
        maxAmount = txn.amount;
      }

      labels.push(logGraphDate(txn.day, txn.month, txn.year, i18n.language));
      graphData.push(txn.amount);
    }

    Object.keys(cryptoData).forEach((key) => {
      datasets.push({
        label: key,
        data: cryptoData[key],
        type: 'line',
        fill: false,
        borderColor: getBorderColor(key),
      });
    });
  }

  datasets = [
    ...datasets,
    {
      label: 'Balance',
      fill: true,
      backgroundColor: 'rgba(0, 154, 120, 0.8)',
      data: graphData,
      type: 'bar',
    },
  ];

  const data = {
    labels,
    datasets,
  };

  const option = {
    responsive: true,
    legend: {
      labels: {
        fontColor: layoutMode === layoutTheme.DARKMODE ? '#adb5bd' : 'white',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: layoutMode === layoutTheme.DARKMODE ? '#adb5bd' : 'white',
          },
          gridLines: {
            color: '#adb5bd',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: layoutMode === layoutTheme.DARKMODE ? '#adb5bd' : 'white',
          },
          gridLines: {
            color: '#adb5bd',
          },
        },
      ],
    },
  };

  return <Line data={data} options={option} />;
};

export default withTranslation()(SystemBalanceChart);
