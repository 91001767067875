import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { OnLoading } from 'src/components/Common/Loading';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import i18n from 'src/i18n';
import { setFacebookError } from 'src/store/actions';
import notify from 'src/utils/notify';
import RenewListComponent from './RenewList/RenewList.component';
import {
  onFacebookProcess,
  onFacebookPersonalProcess,
} from '../SocialCalculate/socialProcess/meta/facebook/facebookProcess';
import {
  onInstagramPageProcess,
  onInstagramPersonalProcess,
} from '../SocialCalculate/socialProcess/meta/instagram/instagramProcess';
import onTikTokProcess from '../SocialCalculate/socialProcess/tiktok/tiktokProcess';
import onTwitterProcess from '../SocialCalculate/socialProcess/twitter/twitterProcess';
import onYoutubeProcess from '../SocialCalculate/socialProcess/youtube/youtubeProcess';

const SocialRenewPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const { type: paramType, token }: any = useParams();
  const type: string | null = params.get('type');
  const { search } = useLocation();

  const { userProfileList$, userProfileIsLoading$, userProfileStatus$ } =
    useSelector((state: any) => ({
      userProfileList$: state.CalculateSocialMedia.userProfileList,
      userProfileIsLoading$: state.CalculateSocialMedia.userProfileIsLoading,
      userProfileStatus$: state.CalculateSocialMedia.userProfileStatus,
    }));

  const socialProcess = async () => {
    switch (paramType) {
      case SocialMediaType.FACEBOOK:
        return onFacebookProcess({ params, dispatch, history }, 'social-renew');
      case SocialMediaType.FACEBOOK_PERSONAL:
        return onFacebookPersonalProcess(
          { params, dispatch, history },
          'social-renew',
        );
      case SocialMediaType.TWITTER:
        return onTwitterProcess({ params, dispatch, history }, 'social-renew');
      case SocialMediaType.INSTAGRAM:
        return onInstagramPageProcess({ params, dispatch, history }, 'social-renew');
      case SocialMediaType.INSTAGRAM_PERSONAL:
        return onInstagramPersonalProcess({ params, dispatch, history });
      case SocialMediaType.YOUTUBE: {
        const email: string | null = params.get('email');
        return onYoutubeProcess({ token, dispatch, email, history }, 'social-renew');
      }
      case SocialMediaType.TIKTOK: {
        return onTikTokProcess({ search, dispatch });
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!userProfileStatus$) {
      return;
    }
    if (userProfileStatus$ !== 'PASS') {
      notify(i18n.t(userProfileStatus$), 'error');
      history.replace('/influencer/dashboard');
    }
  }, [userProfileStatus$]);

  useEffect(() => {
    if (userProfileList$?.length === 0) return;
    if (type === 'select') return;
    if (!userProfileList$?.length && !userProfileIsLoading$) {
      history.push('/influencer/dashboard');
      if (paramType.includes(SocialMediaType.FACEBOOK)) {
        dispatch(setFacebookError(true));
        setTimeout(() => {
          dispatch(setFacebookError(false));
        }, 3000);
      }
    }
  }, [userProfileList$]);

  useEffect(() => {
    if (!paramType && userProfileList$?.length < 1) {
      history.replace('/influencer/dashboard');
      return;
    }
    socialProcess();
  }, [windowUrl]);

  if ((userProfileList$?.length < 1 && type !== 'select') || !userProfileList$) {
    return <OnLoading />;
  }

  return (
    <div className="page-content">
      <RenewListComponent />
    </div>
  );
};

export default SocialRenewPage;
