import noPostImage from 'src/assets/images/no-post-image.jpg';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import iconShare from 'src/assets/images/social/facebook/share.svg';
import { useTranslation } from 'react-i18next';

const TiktokPostView = ({ socialPost }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          backgroundColor: '#eeeded',
          width: '100%',
          padding: 10,
          borderRadius: '8px',
          wordBreak: 'break-all',
        }}
      >
        {socialPost.source}
      </div>
      <div className="influencer-post-content">
        <div className="header-post-title">{socialPost.title}</div>
        <div className="influencer-post-video-content">
          <div className="image-post-influencer-control">
            <img
              src={socialPost.thumbnails || noPostImage}
              alt="influencer"
              className="image-post-influencer"
            />
          </div>
          {socialPost.video_description && (
            <div
              style={{
                backgroundColor: '#eeeded',
                width: '100%',
                padding: 10,
                borderRadius: '8px',
                wordBreak: 'break-all',
                marginTop: '10px',
              }}
            >
              {socialPost.video_description}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="influencer-impression-count">
        <ChatBubbleOutlineOutlinedIcon className="icon" />
        <div className="text">
          {socialPost.comment} {t('marketer:job_detail:comments')}
        </div>
        <ThumbUpOutlinedIcon className="icon" />
        <div className="text">
          {socialPost.like} {t('marketer:job_detail:likes')}
        </div>
        <RemoveRedEyeOutlinedIcon className="icon" />
        <div className="text">
          {socialPost.view} {t('marketer:job_detail:views')}
        </div>
        <img src={iconShare} alt="iconShare" className="icon" />
        <div className="text">
          {socialPost.share} {t('marketer:job_detail:share')}
        </div>
      </div>
    </>
  );
};
export default TiktokPostView;
