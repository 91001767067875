import { useSelector } from 'react-redux';
import { logGraphDate } from 'src/utils/dateTime';
import ReactEcharts from 'echarts-for-react';
import { withTranslation } from 'react-i18next';
import chartOption from './Application/ApplicationReports/chartOption';
import ShortcutDateRange from './ShortcutDateRange';

const ReportChart = ({
  graph_datum,
  t,
  i18n,
  setDatumReportParam,
  datumReportParam,
}: any) => {
  const data: any = [[], [], [], []];
  const { graphLoading } = useSelector((state: any) => state.Global);

  for (let i = 0; i < graph_datum?.graphItems?.length; i++) {
    const txn = graph_datum?.graphItems[i];
    const date_time = new Date(txn?.updated_at);
    const m = date_time.getMonth() + 1;
    const y = date_time.getFullYear();
    const d = date_time.getDate();

    const graphDate =
      graph_datum?.graphType === 'DAILY'
        ? logGraphDate(d, m + 1, y, i18n.language)
        : logGraphDate(null, m + 1, null, i18n.language);

    data[0].push(txn?.total_click);
    data[1].push(txn?.total_impression);
    data[2].push(graphDate);
    data[3].push(txn?.revenue);
  }

  const max_revenue_scale = Math.ceil((Math.max(...data[3]) * 1.2) / 10) * 10;
  const max_impression_scale = Math.ceil((Math.max(...data[1]) * 1.2) / 100) * 100;

  const getOption = chartOption({
    data,
    max_revenue_scale,
    max_impression_scale,
    t,
  });

  const loadingOption = {
    text: 'Loading...',
    color: '#4413c2',
    textColor: '#270240',
    maskColor: 'rgba(203, 203, 203, 0.3)',
    zlevel: 0,
  };

  return (
    <>
      <div className="mb-3">
        <ShortcutDateRange
          isWebsite={false}
          setDatumReportParam={setDatumReportParam}
          datumReportParam={datumReportParam}
        />
      </div>
      <div>
        <ReactEcharts
          style={{ height: '20rem', width: '100%', padding: 0 }}
          option={getOption}
          loadingOption={loadingOption}
          showLoading={graphLoading}
        />
      </div>
    </>
  );
};
export default withTranslation()(ReportChart);
