import { PublishType } from './actionTypes';

export const getAllPublishTransactionsRequest = (payload: {
  limit: number;
  page: number;
  search?: string;
  userId?: string;
  isCampaign: boolean;
  jobType: string;

  // sortBy?: string;
  // sortType?: string;
}) => {
  return {
    type: PublishType.GET_ALL_PUBLISH_TRANSACTIONS_REQUEST,
    payload: { ...payload },
  };
};

export const getAllPublishTransactionsRequestSuccess = (transactions: any) => {
  return {
    type: PublishType.GET_ALL_PUBLISH_TRANSACTIONS_REQUEST_SUCCESS,
    payload: { ...transactions },
  };
};

export const getAllPublishTransactionsRequestFail = (errorMessage: any) => {
  return {
    type: PublishType.GET_ALL_PUBLISH_TRANSACTIONS_REQUEST_FAIL,
    payload: errorMessage,
  };
};
