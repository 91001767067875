import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Modal,
  UncontrolledDropdown,
} from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import withdrawStatuses from 'src/constants/withdrawStatuses';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import {
  getAllAdminApproveLog,
  getPendingApproval,
  getWebsites,
  unsetLoading,
  updateWebsiteStatus,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBar';
import WebsiteDetail from './Website/WebsiteDetail';
import WithdrawStatus from './WithdrawStatus';

const PendingApproval = ({ t }: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [url, setUrl] = useState('');
  const [modalStandard, setmodalStandard] = useState(false);
  const [sortType, setSortType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const isValid = true;
  const [websiteDetail, setWebsiteDetail] = useState();

  const tog_standard = () => setmodalStandard(!modalStandard);

  const { PendingApprove } = useSelector((state: any) => ({
    PendingApprove: state.Websitelist.pending_approval,
  }));

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('WebsiteURL'),
        accessor: ({ url: webUrl }: any) => {
          return (
            <div
              style={{
                paddingLeft: 50,
                textAlign: 'left',
              }}
            >
              {webUrl}
            </div>
          );
        },
        id: 'url',
      },
      {
        Header: t('Status'),
        accessor: ({ status }) => {
          return <WithdrawStatus status={status} />;
        },
        id: 'status',
      },
      {
        Header: t('WebsiteAdded'),
        accessor: ({ created_at }) => {
          return new Date(created_at).toLocaleDateString();
        },
        id: 'created_at',
      },
      {
        Header: t('table:Action'),
        accessor: (originalRow) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="text-muted font-size-28" tag="a">
                <i className="mdi mdi-dots-vertical" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-start">
                <>
                  <Link to={`/admin/users/${originalRow.user.uuid}`}>
                    <DropdownItem>
                      <i className="fas fa-user" />
                      &nbsp;&nbsp;
                      {t('table:User Detail')}
                    </DropdownItem>
                  </Link>
                  <div className="dropdown-divider" />
                </>
                {originalRow.status !== withdrawStatuses.PENDING && (
                  <DropdownItem
                    onClick={() =>
                      onSubmit(originalRow.id, originalRow.url, 'PENDING')
                    }
                    className="text-warning"
                  >
                    <i className="mdi mdi-account-clock text-warning" />
                    &nbsp;&nbsp;{t('table:PENDING')}
                  </DropdownItem>
                )}
                {originalRow.status !== withdrawStatuses.APPROVED && (
                  <DropdownItem
                    onClick={() =>
                      onSubmit(originalRow.id, originalRow.url, 'APPROVED')
                    }
                    className="text-success"
                  >
                    <i className="mdi mdi-account-check text-success" />
                    &nbsp;&nbsp;{t('table:Approve')}
                  </DropdownItem>
                )}
                {originalRow.status !== withdrawStatuses.REJECTED && (
                  <DropdownItem
                    onClick={() =>
                      onSubmit(originalRow.id, originalRow.url, 'REJECTED')
                    }
                    className="text-danger"
                  >
                    <i className="mdi mdi-account-cancel text-danger" />
                    &nbsp;&nbsp;{t('table:Reject')}
                  </DropdownItem>
                )}
                <div className="dropdown-divider" />
                <DropdownItem
                  onClick={() => {
                    setmodalStandard(true);
                    setWebsiteDetail(originalRow);
                  }}
                >
                  <i className="fas fa-file-alt" />
                  &nbsp;&nbsp;{t('table:Detail')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
        id: 'action',
        disableSortBy: true,
      },
    ],
    [t, page],
  );

  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = ['col-2'];
  const hiddenOnLG: any = [];

  const onSubmit = async (website_id: number, submitUrl: string, status: string) => {
    if (isValid) {
      if (status === 'APPROVED') {
        Swal.fire({
          title: t('swal:Are you sure'),
          text: t('swal:Website approve confirm', {
            url: submitUrl,
          }),
          imageUrl: choice,
          width: 300,
          imageHeight: 200,
          imageAlt: 'A tall image',
          customClass: {},
          cancelButtonColor: 'red',
          showCancelButton: true,
          cancelButtonText: t('button:No'),
          confirmButtonText: t('button:Yes'),
          confirmButtonColor: '#009a78',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(updateWebsiteStatus({ id: website_id, status }));
            dispatch(getWebsites({ page: 1, limit: 10 }));
            dispatch(getPendingApproval({ page: 1, limit: 10 }));
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            dispatch(unsetLoading());
          }
        });
      } else {
        Swal.fire({
          title: t('swal:Are you sure'),
          text: t('swal:Website reject confirm', {
            url: submitUrl,
          }),
          imageUrl: choice,
          width: 300,
          imageHeight: 200,
          imageAlt: 'A tall image',
          customClass: {},
          cancelButtonColor: 'red',
          showCancelButton: true,
          cancelButtonText: t('button:No'),
          confirmButtonText: t('button:Yes'),
          confirmButtonColor: '#009a78',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(updateWebsiteStatus({ id: website_id, status }));
            dispatch(getWebsites({ page: 1, limit: 10 }));
            dispatch(getPendingApproval({ page: 1, limit: 10 }));
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            dispatch(unsetLoading());
          }
        });
      }
    }
  };

  const handleSearch = (val: string) => {
    const payload = {
      limit: pageSize,
      page,
      url: val,
      sortBy,
      sortType,
    };

    dispatch(getPendingApproval(payload));
    setUrl(url);
  };

  const handleClear = () => {
    setUrl('');
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.currentTarget.value, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    setPageSize(newPageSize);
    setPage(newPage);
  };

  const getDefaultPayload = (newPage: number) => {
    setPage(newPage);

    return {
      limit: pageSize,
      page,
      url,
    };
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getPendingApproval(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [url, page, pageSize],
  );

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      limit: pageSize,
      page: newPage,
      url,
      sortBy,
      sortType,
    };

    dispatch(getPendingApproval(payload));
    setPage(newPage);
  };
  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('publisher_website');
    dispatch(getAllAdminApproveLog());
  };
  useEffect(() => {
    fetchApproveLog();
    dispatch(getPendingApproval({ limit: 10, page: 1, url, sortBy, sortType }));
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: '0 10px',
        }}
      >
        <FormGroup className="flex-grow-1 flex-md-grow-0">
          <select className="form-control" onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </FormGroup>

        <SearchBar
          placeholder={t('Form.Search URL')}
          handleSearch={handleSearch}
          handleClear={handleClear}
        />
      </div>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={PendingApprove.items || []}
        page={page}
        pageSize={pageSize}
        total={PendingApprove.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <Modal
          isOpen={modalStandard}
          toggle={() => {
            tog_standard();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('Website Detail')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodalStandard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <WebsiteDetail Webdata={websiteDetail} />
          </div>
        </Modal>
      </CustomTable>
    </>
  );
};

export default withTranslation()(PendingApproval);
