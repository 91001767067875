import { gql } from '@apollo/client';
import { client } from '../client';

const getDefaultValueWebsite = async () => {
  const query = gql`
    query {
      getDefaultWebsite {
        valueAddedTax
      }
    }
  `;

  const result: any = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.getDefaultWebsite;
};

const updateDefaultValueWebsite = async (updateDefaultWebsiteInput: any) => {
  const { valueAddedTax } = updateDefaultWebsiteInput;

  const mutation = gql`
        mutation updateDefaultWebsiteInput(
            ${valueAddedTax && '$valueAddedTax: Float'}
        ) {
          updateDefaultWebsite(
            updateDefaultWebsiteInput: {
                    ${valueAddedTax && 'valueAddedTax: $valueAddedTax'}
                }
            ) {
              valueAddedTax
            }
        }
    `;
  const result = await client.mutate({
    mutation,
    variables: {
      valueAddedTax: +valueAddedTax,
    },
  });

  return result.data.updateDefaultWebsite;
};

export { getDefaultValueWebsite, updateDefaultValueWebsite };
