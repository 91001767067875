import ChangePasswordTypes from './actionTypes';

const initialState = {
  error: false,
  message: '',
};

const changePassword = (state = initialState, action: any) => {
  switch (action.type) {
    case ChangePasswordTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: false,
        message: '',
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        message: '',
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_REQUEST_ERROR:
      return { ...state, error: true, message: action.payload };
    case ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM:
      return {
        ...state,
        error: false,
        message: '',
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        message: '',
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_CONFIRM_ERROR:
      return { ...state, error: true, message: action.payload };
    default:
      return { ...state };
  }
};

export default changePassword;
