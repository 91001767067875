import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignStatusSummary } from 'src/store/actions';

const MarketerCampaignSummaryWidgetControl = () => {
  const dispatch = useDispatch();

  const { statusSummary } = useSelector((state: any) => ({
    statusSummary: state.Campaigns.statusSummary,
  }));

  useEffect(() => {
    dispatch(getCampaignStatusSummary(''));
  }, []);

  return { statusSummary };
};

export default MarketerCampaignSummaryWidgetControl;
