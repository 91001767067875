import ProfileTypes from './actionTypes';

const initialState = {
  error: '',
  success: null,
};

const profile = (state = initialState, action: any) => {
  switch (action.type) {
    case ProfileTypes.EDIT_PROFILE:
      return { ...state };
    case ProfileTypes.PROFILE_SUCCESS:
      return { ...state, success: action.payload };
    case ProfileTypes.PROFILE_ERROR:
      return { ...state, error: action.payload };

    case ProfileTypes.RESET_PROFILE_FLAG:
      return { ...state, success: null };
    default:
      return { ...state };
  }
};

export default profile;
