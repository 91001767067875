/* eslint-disable no-unused-vars */
import styled from 'styled-components';

const ButtonStyle = styled.button`
  font-size: 1.2em;
  // font-weight: bold;
  padding: 3px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  border-radius: 15px;
  margin-top: -10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: transparent;
`;
const ButtonSubmit = styled.button`
  background: #3baf96;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  min-width: 8rem;
  font-size: 1.2em;
  font-weight: bold;
  outline: none;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 10px 0;
  transition: all 0.1s ease-in-out;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  color: #ffffff;

  &:hover {
    transform: scale(1.05) !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  &:active {
    transform: scale(0.95) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
`;
const ButtonReject = styled.button`
  background: #f08080;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  min-width: 8rem;
  font-size: 1.2em;
  font-weight: bold;
  outline: none;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 10px 0;
  transition: all 0.1s ease-in-out;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  color: #ffffff;

  &:hover {
    transform: scale(1.05) !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  &:active {
    transform: scale(0.95) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
`;
const NumberStyle = styled.h1`
  background: #3baf96;
  width: 40px;
  height: 40px;
  padding: auto;
  margin-right: 10px;
  border-radius: 50%;
  color: white;
`;
const FlexRow = styled.nav<{
  width?: string;
  marginTop?: string;
  background?: string;
  height?: string;
  boxshadow?: string;
  justifyContent?: string;
  borderTop?: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  position: relative;
  padding-top: 10px;
  text-align: center;
  box-shadow: ${({ boxshadow }) => boxshadow};
  background: ${({ background }) => background};
  @media screen and (min-width: 500px) {
    width: ${({ width }) => width};
  }
  @media screen and (max-width: 499px) {
    width: 100%;
  }
  margin-top: ${({ marginTop }) => marginTop};

  height: ${({ height }) => height};
  border-top: ${({ borderTop }) => borderTop};
`;

const PreviewImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  width: 100%;
  text-align: center;
  margin: 0;
  border: 3px solid #3baf96;
  padding: auto;
  border-radius: 5px;
  // background-color: #fbfaff;
`;
const UploadBtn = styled.input`
  height: 35px;
  margin: auto;
  border: none;
  padding: auto;
  border-radius: 5px;
  cursor: pointer;

  //   background-color: #fbfaff;
`;
const LabelStyle = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  //   margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
  background: pink;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;
  font-weight: bold;
  line-height: 1.2;
`;
const LinkInput = styled.input`
  height: 40px;
  width: 100%;
  font-size: 1.5em;
  outline: none;
  margin: auto;
  color: #323535;
  border: 1px solid #949d9d;
  padding: auto;
  border-radius: 5px;
  padding: 10px;
`;
const Texts = styled.p<{
  fontsize?: string;
  // background?: string;
  height?: string;
  margin?: string;
  fontWeight?: string;
  width?: string;
}>`
  width: ${({ width }) => width};
  font-size: ${({ fontsize }) => fontsize};
  font-weight: ${({ fontWeight }) => fontWeight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: ${({ margin }) => margin};
`;

const FlexCol = styled.div<{
  width?: string;
  // background?: string;
  height?: string;
  margin?: string;
  borderTop?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 10px;
  text-align: center;
  //   background: green;
  // width: 300px;
  border-top: ${({ borderTop }) => borderTop};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const CheckIcon = styled.div`
  background-color: red;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 1px solid #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  text-align: center;
  line-height: 23px;
`;

const HeaderCollapse = styled.h1`
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  text-align: left;
  // overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  
  @media only screen and (max-width: 1150px) {
    font-size:1.5rem;
   @media only screen and (max-width: 550px) {
    max-width:95%
    word-break: break-all;
    margin-bottom:0.5rem;
`;

const Title = styled.h5`
  color: #a2b5bb;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  /* background-color: red; */

  &::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: black;
    top: 0;
    bottom: 0;
    left: 10%;
    margin-left: -3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  /* @media screen and (max-width: 600px) {
    &::after {
      left: 31px;
    }
  } */
`;

const TimelineContainer = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  padding: 10px 7vh;
  position: relative;
  background-color: inherit;
  width: 100%;
  left: 10%;

  &::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'black'};
    border: 4px solid ${({ borderColor }) => borderColor ?? 'black'};
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    left: -12px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  &::before {
    content: ' ';
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid rgba(60, 64, 67, 0.3);
    border-width: 10px 10px 10px 0;
    border-color: transparent rgba(60, 64, 67, 0.3) transparent transparent;
  }

  /* @media screen and (max-width: 600px) {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    left: 0%;

    &::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
      left: 15px;
    }
  } */
`;

const TimelineContent = styled.div`
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const TransFromHtmlString = styled.div`
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  line-height: 1.2;
  outline: none;
  padding: 12px 15px;
  tab-size: 4px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  span {
    line-height: 1.5;
  }
  br {
    content: '';
    margin: 2em;
    display: block;
    font-size: 75%;
  }
`;

export {
  ButtonStyle,
  ButtonSubmit,
  FlexCol,
  FlexRow,
  NumberStyle,
  PreviewImg,
  UploadBtn,
  LinkInput,
  LabelStyle,
  Texts,
  CheckIcon,
  HeaderCollapse,
  Title,
  Timeline,
  TimelineContainer,
  TimelineContent,
  ButtonReject,
  TransFromHtmlString,
};
