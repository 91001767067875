import { gql } from '@apollo/client';
import { client } from '../client';
import { DepositTransactionsQuery } from '../../common/types';

const getDepositTransactions = async (queryInput: DepositTransactionsQuery) => {
  const GET_DEPOSIT_TRANSACTIONS = gql`
    query GetDepositTransactions($input: TransactionsQueryInput!) {
      depositTransactions(input: $input) {
        items {
          uuid
          tx_hash
          address
          CRYPTO_amount
          FIAT_amount
          FIAT_rate
          created_at
          updated_at
          currency {
            name
            symbol
            explorer_link
          }
          user {
            email
          }
          fromAddress
        }
        totalCount
      }
    }
  `;

  const result: any = await client.query({
    query: GET_DEPOSIT_TRANSACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'network-only',
  });

  return result.data.depositTransactions;
};

// eslint-disable-next-line import/prefer-default-export
export { getDepositTransactions };
