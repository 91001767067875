import { Formik, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import CustomButton from 'src/components/Common/CustomButton';
import menu from 'src/constants/menu';

import control from './CreateMarketerCampaign.control';
import './CreateMarketerCampaign.style.scss';
// eslint-disable-next-line import/order
import { useEffect } from 'react';

const CreateMarketerCampaignPage = ({ campaignType }: any) => {
  const { t } = useTranslation();

  const { handleSubmit, validationSchema, inputs, formValues } = control();

  useEffect(() => {
    localStorage.removeItem('descriptionJobNew');
  }, []);

  return (
    <div className="page-content">
      <div className="bread-crumb">
        <Breadcrumb
          pageTitle={t('Menus.Create Campaign')}
          menus={[{ menu: menu.HOME, active: true }, { menu: menu.CREATE_CAMPAIGN }]}
        />
      </div>
      <div className="marketer-create-campaign-container">
        <div className="card-container">
          <Formik
            initialValues={formValues}
            onSubmit={() => handleSubmit(campaignType)}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ errors, touched, handleChange }: any) => {
              return (
                <FormikForm>
                  <h4 className="text-center mt-4">
                    {t('marketer:create_campaign.create_campaign')}
                  </h4>
                  {inputs(errors, touched, handleChange)}
                  {window.location.pathname.includes('/EditMarketerCampaign') ||
                  window.location.pathname.includes(
                    '/EditMarketerPublicCampaign',
                  ) ? (
                    <div className="mt-3 text-center">
                      <CustomButton
                        status="submit"
                        size="s"
                        label={t('marketer:create_campaign.save')}
                      />
                    </div>
                  ) : (
                    <div
                      className="mt-3 text-center"
                      style={{ margin: '1rem 0 1rem 0' }}
                    >
                      <CustomButton
                        status="submit"
                        size="s"
                        label={t('marketer:create_campaign.next')}
                      />
                    </div>
                  )}
                </FormikForm>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateMarketerCampaignPage;
