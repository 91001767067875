import { InviteOnlyType } from './actionTypes';

const initialState = {
  transactions: [],
  error: '',
  loading: false,
};

export const inviteOnly = (state = initialState, action: any) => {
  switch (action.type) {
    case InviteOnlyType.GET_ALL_INVITE_ONLY_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };

    default:
      return state;
  }
};

export default inviteOnly;
