import { CreativeTypeTypes } from './actionTypes';

export const getCreativeType = () => ({
  type: CreativeTypeTypes.GET_CREATIVE_TYPE,
});

export const getCreativeTypeSuccess = (creativeType: any) => ({
  type: CreativeTypeTypes.GET_CREATIVE_TYPE_SUCCESS,
  payload: creativeType,
});

export const getCreativeTypeFail = (error: any) => ({
  type: CreativeTypeTypes.GET_CREATIVE_TYPE_FAIL,
  payload: error,
});
