import MarketerJobTypes from './actionTypes';

export const INIT_STATE = {
  marketerJob: {},
  filter: [],
  error: {},
  loading: false,
  marketerJobCount: {},
  suggestHomepage: {},
  availableSocialForJob: {},
  jobEdit: {},
  followUp: {},
  reportJob: [],
  reportJobDetail: [],
};

const MarketerMarkCampaigns = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case MarketerJobTypes.GET_MARKETER_JOB_SUCCESS:
      return {
        ...state,
        marketerJob: action.payload,
      };
    case MarketerJobTypes.CREATE_MARKETER_JOB_SUCCESS:
      return {
        ...state,
        marketerJob: action.payload,
      };

    case MarketerJobTypes.UPDATE_MARKETER_JOB_SUCCESS:
      return {
        ...state,
        marketerJob: action.payload,
      };

    case MarketerJobTypes.FILTER_JOB_SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };
    case MarketerJobTypes.CLEAR_MARKETER_JOB:
      return {
        ...state,
        marketerJob: {},
      };
    case MarketerJobTypes.MARKETER_JOB_COUNT_SUCCESS:
      return {
        ...state,
        marketerJobCount: action.payload,
      };
    case MarketerJobTypes.SUGGEST_JOBS_HOMEPAGE_SUCCESS:
      return {
        ...state,
        suggestHomepage: action.payload,
      };
    case MarketerJobTypes.GET_GLEAM_JOB_SUMMARY_SUCCESS:
      return {
        ...state,
        marketerJob: action.payload,
      };
    case MarketerJobTypes.GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB_SUCCESS:
      return {
        ...state,
        availableSocialForJob: action.payload,
      };
    case MarketerJobTypes.FILTER_GLEAM_JOB_SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };
    case MarketerJobTypes.GET_GLEAM_JOB_DATA_FOR_EDIT_SUCCESS:
      return {
        ...state,
        jobEdit: action.payload,
      };
    case MarketerJobTypes.GET_MARKETER_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUp: action.payload,
      };
    case MarketerJobTypes.GET_REPORT_JOB_SUCCESS:
      return {
        ...state,
        reportJob: action.payload,
      };
    case MarketerJobTypes.GET_REPORT_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        reportJobDetail: action.payload,
      };
    default:
      return state;
  }
};

export default MarketerMarkCampaigns;
