import { useSelector } from 'react-redux';
import { layoutTheme } from 'src/constants/layout';

const selectStyle = () => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  const styleData = {
    control: (styles: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...styles,
            backgroundColor: '#0d0f00',
            borderColor: '#474d4a',
            color: '#a3958f',
            '&:hover': {
              borderColor: '#009a78',
            },
            boxShadow: 'none',
          }
        : {
            ...styles,
            borderColor: '#ced4d9',
            '&:hover': {
              borderColor: '#009a78',
            },
            boxShadow: 'none',
          },
    option: (styles: any, optionProps: any) => {
      const newStyle = styles;
      newStyle[':active'].backgroundColor = '#009a78';
      const backgroundColor = layoutMode === layoutTheme.DARKMODE ? '#0d0f00' : '';
      const disableColor =
        layoutMode === layoutTheme.DARKMODE ? '#a3958f25' : '#a3958f';
      let color = '';

      if (optionProps.isFocused) {
        color = 'black';
      } else {
        if (layoutMode === layoutTheme.DARKMODE) {
          color = '#a3958f';
        } else {
          color = 'black';
        }
      }

      return {
        ...newStyle,
        backgroundColor: optionProps.isFocused ? '#009a78' : backgroundColor,
        color: optionProps.isDisabled ? disableColor : color,
      };
    },

    menu: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            backgroundColor: '#0d0f00',
          }
        : {
            ...provided,
          },
    singleValue: (provided: any) =>
      layoutMode === layoutTheme.DARKMODE
        ? {
            ...provided,
            color: '#a3958f',
          }
        : { color: 'dark' },
  };

  return styleData;
};

export default selectStyle;
