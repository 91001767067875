import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';

const onConnectYoutube = async (
  access_token: string,
  path: string | null = 'social-calculate',
) => {
  const redirectUrl: string = `/influencer/${path}/${SocialMediaType.YOUTUBE}/token/${access_token}`;
  window.location.href = redirectUrl;
};

export default onConnectYoutube;
