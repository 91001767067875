import { Input, Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SetStateAction, useState } from 'react';
import { updateFaq } from 'src/graphql/admin/faq';
import { useDispatch } from 'react-redux';
import { setLoading, unsetLoading } from 'src/store/actions';

const FaqModal = ({
  isOpen,
  handleToggle,
  handleClose,
  data,
  refreshData,
  t,
}: any) => {
  const dispatch = useDispatch();
  const { title, detail } = data;
  const [editKey, setEditKey] = useState('');
  const [editData, setEditData] = useState();
  const editForm = (
    key: SetStateAction<string>,
    faqData: any,
    isNumber?: boolean,
  ) => {
    return (
      <td className="custom-modal-title">
        <div className="avatar-sm text-center">
          {editKey !== key ? (
            <span
              className="avatar-bin bg-soft-primary rounded-circle ms-4"
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              onClick={() => {
                setEditKey(key);
                setEditData(faqData);
              }}
            >
              <i className="mdi mdi-square-edit-outline font-size-18 text-info" />
            </span>
          ) : (
            <div className="w-max-content">
              <div className="avatar-sm inline-block">
                <button
                  className="btn-invisible btn-xs"
                  type="button"
                  onClick={() => {
                    setEditKey('');
                  }}
                >
                  <div className="avatar-sm">
                    <span className="avatar-bin bg-soft-primary rounded-circle">
                      <i className="mdi mdi-content-save-off font-size-18 text-danger" />
                    </span>
                  </div>
                </button>
              </div>
              <div className="avatar-sm inline-block">
                <button
                  className="btn-invisible btn-xs"
                  type="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                    dispatch(setLoading());
                    const updateInput: any = { key: data.key };
                    if (editData) {
                      updateInput[`${editKey}`] = isNumber ? +editData : editData;
                      await updateFaq(updateInput);
                    }
                    await refreshData();
                    setEditKey('');
                    handleClose();
                    dispatch(unsetLoading());
                  }}
                >
                  <div className="avatar-sm">
                    <span className="avatar-bin bg-soft-primary rounded-circle">
                      <i className="mdi mdi-content-save font-size-18 text-success" />
                    </span>
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </td>
    );
  };
  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">FAQ detail</h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0 table-responsive">
        <table className="table mb-0">
          {data && (
            <tbody>
              <tr>
                <th>{t('table:Title')}:</th>
                {editKey === 'title' ? (
                  <td>
                    <Input
                      type="text"
                      className="form-control"
                      value={editData}
                      onChange={(e: any) => {
                        setEditData(e.target.value);
                      }}
                    />
                  </td>
                ) : (
                  <td>{title}</td>
                )}
                {editForm('title', title)}
              </tr>

              <tr>
                <th>{t('table:Detail')}:</th>
                {editKey === 'detail' ? (
                  <td className="w-75">
                    <Input
                      type="textarea"
                      className="form-control"
                      value={editData}
                      onChange={(e: any) => {
                        setEditData(e.target.value);
                      }}
                    />
                  </td>
                ) : (
                  <td className="w-75">{detail}</td>
                )}
                {editForm('detail', detail)}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </Modal>
  );
};

export default withTranslation()(FaqModal);
