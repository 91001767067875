import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { getActivityLogTransactions } from '../../store/actions';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from './DateTimeComponent';

const NotificationsTable = () => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // Redux
  const { notiDetail } = useSelector((state: any) => ({
    txn: state.ActivityLogs.logs,
    activityLogLoading: state.Global.activityLogLoading,
    notiDetail: state.ActivityLogs.recentLogs,
  }));

  useEffect(() => {
    dispatch(
      getActivityLogTransactions({
        limit: defaultPageSize,
        page: 1,
      }),
    );
  }, []);
  const checkCurrentURL = (redirectURL: string, state?: any) => {
    const currentURL = window.location.pathname + window.location.search;
    if (currentURL === redirectURL) {
      history.go(0);
    } else {
      history.push(redirectURL, state);
    }
  };

  const jobPath = (context: any) => {
    const is_gleam_job = context?.is_gleam_job ?? false;
    if (is_gleam_job) {
      return `/JobDetail?job=${context?.job_key}&task=${context?.social_job_group}`;
    } else {
      return `/JobDetail?job=${context?.job_key}&detail=${context?.social_job_key}`;
    }
  };

  const logNotification = (logData: any) => {
    const { activity_log_type, context, activity_log_key } = logData;
    const defaultLog = {
      message: t(`ActivityLogs.Abbr.${activity_log_key}`, context),
      icon: <i className="bx bx-notification" />,
      click: () => {
        window.location.replace('/notifications');
      },
    };
    switch (activity_log_type) {
      case 'CAMPAIGN_NOTIFICATION':
        return {
          ...defaultLog,
          message: t(`ActivityLogs.${context.message}`),
        };
      case 'PUBLISHER_EARNING': {
        const messageArr: string[] = context.message.split(' ');
        const profit: number = +messageArr[4].split('$')[1];
        const newBalance: number = +messageArr[10].split('$')[1];
        const type: string = messageArr[6].split('.')[0];
        return {
          ...defaultLog,
          message: t(`ActivityLogs.Your have been pain from ${type}`, {
            FIAT_amount: profit,
            newBalance,
          }),
        };
      }
      case 'NEW_JOB':
        return {
          ...defaultLog,
          message: t('New Job Message', {
            jobName: context?.job_name,
            company: context?.marketer_user_company,
          }),
          icon: <i className="fa fa-briefcase fa-icon" />,
          click: () => {
            checkCurrentURL(jobPath(context));
          },
        };

      case 'REVIEW_JOB':
        return {
          ...defaultLog,
          message: t('You have new job review from', {
            influencerName: context?.influencer_name,
          }),
          icon: <i className="fa fa-clipboard fa-icon" />,
          click: () => {
            history.replace(
              `/campaigns/markerter/job/publishDetail?job=${
                context?.job_key
              }&tab=${'ReviewJob'}`,
            );
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          },
        };

      case 'APPROVE_JOB':
        return {
          ...defaultLog,
          message: t('Your job has been approved'),
          icon: <i className="fa fa-check fa-icon" />,
          click: () => {
            checkCurrentURL(jobPath(context));
          },
        };
      case 'CANCELED_JOB':
        if (context.marketer_company != null) {
          return {
            ...defaultLog,
            message: t('MARKETER_CANCELED_JOB_NOTIFICATION', {
              jobName: context?.job_name,
              marketerCom: context?.marketer_company,
            }),
            icon: <i className="fa fa-ban fa-icon" />,
            click: () => {
              checkCurrentURL(jobPath(context));
            },
          };
        } else {
          return {
            ...defaultLog,
            message: t('INFLUENCER_CANCELED_JOB_NOTIFICATION', {
              jobName: context?.job_name,
              influencerName: context?.influencer_name,
            }),
            icon: <i className="fa fa-ban fa-icon" />,
            click: () => {
              checkCurrentURL(`/Marketer/JobDetail?job=${context?.job_key}`);
            },
          };
        }
      case 'FINISHED_JOB':
        return {
          ...defaultLog,
          message: t('job has been finished your payment is', {
            jobName: context?.job_name,
            payMent: floorToFixed(context?.payment, 2),
          }),
          icon: <i className="fa fa-check fa-icon" />,
          click: () => {
            checkCurrentURL(`/Marketer/JobDetail?job=${context.job_key}`);
          },
        };
      case 'FINISHED_JOB_AND_EARNING':
        return {
          ...defaultLog,
          message: t('FINISHED_JOB_AND_EARNING_NOTIFICATION', {
            jobName: context?.job_name,
            Earning: floorToFixed(context?.earning, 2),
            company: context?.marketer_user_company,
            baLance: floorToFixed(context?.balance_now, 2),
          }),
          icon: <i className="fa fa-check fa-icon" />,
          click: () => {
            checkCurrentURL(jobPath(context));
          },
        };
      case 'NEW_SOCIAL_MEDIA':
        return {
          ...defaultLog,
          message: t('NEW_SOCIAL_MEDIA_NOTIFICATION', {
            influencerName: context?.influencer_name,
          }),
          icon: <i className="fa fa-check fa-icon" />,
          click: () => {
            checkCurrentURL('/admin/approve/influencer');
          },
        };
      case 'NEW_JOB_ADMIN':
        return {
          ...defaultLog,
          message: t('NEW_JOB_ADMIN_NOTIFICATION', {
            jobName: context?.job_name,
          }),
          icon: <i className="fa fa-check fa-icon" />,
          click: () => {
            checkCurrentURL('/admin/approve/manage-job');
          },
        };
      case 'WITHDRAWAL':
        if (activity_log_key === 'Withdrawal Rejected') {
          return {
            ...defaultLog,
            message: t('ActivityLogs.Abbr.Withdrawal Rejected', {
              FIAT_amount: floorToFixed(context?.FIAT_amount, 2),
              fee: floorToFixed(context?.fee, 2),
            }),
            icon: <i className="fa fa-ban fa-icon" />,
            click: () => {
              checkCurrentURL(`/wallet`, 'withdraw');
            },
          };
        }
        if (activity_log_key === 'Withdrawal In Finished') {
          return {
            ...defaultLog,
            message: t('ActivityLogs.Abbr.Withdrawal In Finished', {
              FIAT_amount: floorToFixed(context?.FIAT_amount, 2),
              fee: floorToFixed(context?.fee, 2),
            }),
            icon: <i className="fa fa-check fa-icon" />,
            click: () => {
              checkCurrentURL(`/wallet`, 'withdraw');
            },
          };
        }

        return defaultLog;
      case 'NEW_WITHDRAWAL_REQUEST':
        if (activity_log_key === 'New Withdrawal Request') {
          return {
            ...defaultLog,
            message: t('ActivityLogs.Abbr.New Withdrawal Request', {
              FIAT_amount: floorToFixed(context?.FIAT_amount, 2),
              fee: floorToFixed(context?.fee, 2),
            }),
            icon: <i className="fa fa-briefcase fa-icon" />,
            click: () => {
              checkCurrentURL('/admin/approve/withdraw');
            },
          };
        }
        return defaultLog;
      case 'POST_JOB':
        if (activity_log_key === 'Post job') {
          return {
            ...defaultLog,
            message: t('ActivityLogs.Abbr.You have post job from', {
              influencerName: context?.influencer_name,
            }),
            icon: <i className="fa fa-briefcase fa-icon" />,
            click: () => {
              history.replace(
                `/campaigns/markerter/job/publishDetail?job=${
                  context?.job_key
                }&tab=${'ReviewJob'}`,
              );
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            },
          };
        }
        return defaultLog;
      case 'PUBLISH_NEW_INFLUENCER':
        if (activity_log_key === 'Post job') {
          return {
            ...defaultLog,
            message: t('ActivityLogs.Abbr.accept your job', {
              influencerName: context?.influencer_name,
            }),
            icon: <i className="fa fa-briefcase fa-icon" />,

            click: () => {
              history.replace(
                `/campaigns/markerter/job/publishDetail?job=${
                  context.job_key
                }&tab=${'Request'}`,
              );
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            },
          };
        }
        return defaultLog;
      case 'PUBLISH_ACCEPT_INFLUENCER':
        if (activity_log_key === 'Accept Publish Job') {
          return {
            ...defaultLog,
            message: t('ActivityLogs.Abbr.Your public job request', {
              jobName: context?.job_name,
            }),
            icon: <i className="fa fa-briefcase fa-icon" />,
            click: () => {
              checkCurrentURL(jobPath(context));
            },
          };
        }
        return defaultLog;
      case 'PUBLISH_CANCEL_INFLUENCER':
        if (activity_log_key === 'Canceled job') {
          if (context?.message !== '') {
            return {
              ...defaultLog,
              message: t(
                'ActivityLogs.Abbr.Your public job request rejected because',
                {
                  jobName: context?.job_name,
                  message: context?.message,
                },
              ),
              icon: <i className="fa fa-briefcase fa-icon" />,
              click: () => {
                checkCurrentURL(jobPath(context));
              },
            };
          } else {
            return {
              ...defaultLog,
              message: t('ActivityLogs.Abbr.Your public job request rejected', {
                jobName: context?.job_name,
              }),
              icon: <i className="fa fa-briefcase fa-icon" />,
              click: () => {
                checkCurrentURL(jobPath(context));
              },
            };
          }
        }
        return defaultLog;
      default:
        return defaultLog;
    }
  };

  const renderNotifications = () => {
    const notifications: any = [];
    for (let i = 0; i < notiDetail?.length; i++) {
      const log = notiDetail[i];
      const { message, icon, click } = logNotification(log);
      notifications.push(
        <div className="card-body" style={{ padding: '0px' }}>
          <div
            className="text-reset notification-item"
            style={{ cursor: 'pointer' }}
            key={`${log.activity_log_key}${log.created_at}`}
            onClick={() => {
              click();
            }}
          >
            <div className="d-flex">
              <div className="avatar-sm me-3">
                <span className="avatar-title-noti rounded-circle">{icon}</span>
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-0" style={{ color: 'rgb(0, 154, 120)' }}>
                  {t(`ActivityLogs.Types.${log.activity_log_type}`)}
                </h6>
                <div className="text-white font-size-12">
                  <p className="mb-0">{message}</p>
                  <p className="mb-0">
                    <i className="mdi mdi-clock-outline" />{' '}
                    <DateTimeComponent
                      dateTime={log.created_at}
                      dateTimeType={DateTimeTypes.GET_NOTIFICATION_PAST_TIME}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>,
      );
    }

    return notifications;
  };
  return <div>{renderNotifications()}</div>;
};

export default NotificationsTable;
