/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { client } from '../client';

export const getTaskTypeGroupBySocialTypeGQL = async () => {
  const query = gql`
    query getTaskTypebyGroup {
      getTaskTypebyGroup {
        socialType
        tasks {
          id
          content_type
          socialmedia_type
        }
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      fetchPolicy: 'cache-first',
    });
    return response.data.getTaskTypebyGroup;
  } catch (error) {
    return null;
  }
};
