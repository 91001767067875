import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik';
import Swal from 'sweetalert2';
import { updateFeeConfig } from 'src/graphql/default-value';
import {
  getFeeWithdrawConfigs,
  getMinimumWithdrawConfigs,
} from '../../store/default-values/actions';
import CoinImage from '../Common/CoinImage';
import {
  getCurrencySymbolFromFeeWithdrawConfigKey,
  splitUnderscoreWithTuple,
} from '../../utils/currency';
import { transformStringAmountInput } from '../../utils/input';

const initialValues = {
  key: '',
  value: '',
};

const settingSchema = Yup.object().shape({
  key: Yup.string().required('Required'),
  value: Yup.string().required('Required'),
});

const FeeWithdrawConfigSettingComponent = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMinimumWithdrawConfigs());
    dispatch(getFeeWithdrawConfigs());
  }, []);

  const feeConfigs = useSelector(
    (state: any) => state.DefaultValue.feeWithdrawConfigs,
  );

  const onSubmit = (values: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Setting Fee Withdraw Confirm', {
        symbol: getCurrencySymbolFromFeeWithdrawConfigKey(values.key),
      }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // eslint-disable-next-line no-param-reassign
        await updateFeeConfig({ key: values.key, value: values.value });
        dispatch(getFeeWithdrawConfigs());
      }
    });
  };

  const getCurrencySymbolByConfigKey = (key: string) => {
    const splitString = key.split('_');
    return splitString?.length === 4
      ? `${splitString[splitString?.length - 2]}_${
          splitString[splitString?.length - 1]
        }`
      : splitString[splitString?.length - 1];
  };
  //   const renderCurrencyRate = (config: any) => {
  //     return `${getCurrencySymbolFromMinWithdrawConfigKey(
  //       config.name,
  //     )}-USD : ${transformStringAmountInput(config.rate_in_USD)}`;
  //   };
  //   const renderEstimateConfigValueInUSD = (config: any) => {
  //     return `${t('Estimate Config Value In USD')} : ≈ $${transformStringAmountInput(
  //       config.estimate_in_USD,
  //     )}`;
  //   };
  const handleConfigValueChange = (value: string, setFieldValue: any) => {
    setFieldValue('value', transformStringAmountInput(value));
  };

  return (
    <Row>
      <div className="p-2 text-danger">
        *{t('Minimum Withdrawal Setting Helper')}
      </div>
      {feeConfigs &&
        feeConfigs.map((config: any) => {
          return (
            <Col lg={4}>
              <Card>
                <CardBody className="overflow-hidden position-relative">
                  <div className="d-flex flex-column">
                    <h5 className="text-secondary text-center mb-1">
                      <span className="logo-sm mx-1">
                        <CoinImage
                          symbol={getCurrencySymbolByConfigKey(config.name)}
                          size="md"
                        />
                        &nbsp;&nbsp;
                        {splitUnderscoreWithTuple(config.name)}
                      </span>
                    </h5>
                    <span className="align-self-center mb-1">
                      {/* {renderCurrencyRate(config)} */}
                    </span>
                    <span className="align-self-center">
                      {/* {renderEstimateConfigValueInUSD(config)} */}
                    </span>
                    <Formik
                      initialValues={{
                        ...initialValues,
                        key: config.name,
                        value: config.value,
                      }}
                      onSubmit={onSubmit}
                      validationSchema={settingSchema}
                      render={({ errors, touched, setFieldValue }) => (
                        <FormikForm>
                          <Row className="d-flex justify-content-center">
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Field name="key" type="hidden" id="key" />
                              </FormGroup>
                            </Col>
                            <Col md="8">
                              <FormGroup className="mb-3">
                                <div className="input-group">
                                  <Field
                                    name="value"
                                    type="text"
                                    className={`form-control ${
                                      errors.value && touched.value
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    id="value"
                                    autocomplete="off"
                                    aria-describedby="configValueHelp"
                                    onChange={(e: any) => {
                                      handleConfigValueChange(
                                        e.currentTarget.value,
                                        setFieldValue,
                                      );
                                    }}
                                  />
                                  <div className="input-group-text">
                                    {/* {getCurrencySymbolFromFeeWithdrawConfigKey(
                                      config.name,
                                    )} */}
                                    USD
                                  </div>
                                  <ErrorMessage
                                    name="value"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg={8}>
                              <div className="d-grid">
                                <button
                                  className="btn btn-primary btn-sm"
                                  type="submit"
                                >
                                  {t('button:Update')}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </FormikForm>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default withTranslation()(FeeWithdrawConfigSettingComponent);
