import { Col, Row } from 'reactstrap';
import AddCampaignWidget from 'src/components/Advertiser/Widgets/AddCampaignWidget';
import CampaignSummaryWidget from 'src/components/Advertiser/Widgets/CampaignSummaryWidget';
import CampaignTotalWidget from 'src/components/Advertiser/Widgets/CampaignTotalWidget';
import PerformanceSummary from 'src/components/Advertiser/Widgets/PerformanceSummary';
import './AdvertiserDashboard.style.scss';
import useWindowSize from 'src/utils/windowSize';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WalletWidget from 'src/components/Common/WalletWidget';
import Wallet from 'src/assets/images/wallet.png';
import influ from 'src/assets/images/comimg-soon.png';
import blog1 from 'src/assets/images/Blog1.jpeg';
import blog2 from 'src/assets/images/Blog2.jpeg';
import blog3 from 'src/assets/images/Blog3.jpeg';
import blog4 from 'src/assets/images/Blog4.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  filterGleamJob,
  getSumBalanceWallet,
  marketerSumPendingBalance,
  getMarketerFollowUp,
} from 'src/store/actions';
import { floorToFixed } from 'src/common/data/mathToFixed';

const AdvertiserDashboardNew = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const walletSymbol = 'USDT';
  const [totalBalance, setTotalBalance] = useState<any>(0);
  const [pendingBalance, setPendingBalance] = useState<any>(0);
  const [activeJob, setActiveJob] = useState(0);
  const [totalJob, setTotalJob] = useState(0);
  const [pendingAccept, setPendingAccept] = useState(0);
  const [penddingApprove, setPendingApprove] = useState(0);
  const { user, userInfo, wallet, marketerPendingBalance, filter, followUp } =
    useSelector((state: any) => ({
      user: state.login.user,
      userInfo: state.Users.userInfo,
      wallet: state.Wallet.sumWallet,
      marketerPendingBalance: state.SocialMediaInfo.marketerPendingBalance,
      filter: state.MarketerJob.filter,
      followUp: state.MarketerJob.followUp,
    }));

  useEffect(() => {
    dispatch(getSumBalanceWallet());
    dispatch(marketerSumPendingBalance());
    dispatch(
      filterGleamJob({
        limit: 10,
        page: 1,
        status: 'INPROGRESS',
      }),
    );
    dispatch(getMarketerFollowUp({ key: null }));
  }, [user]);

  useEffect(() => {
    if (wallet?.balance) {
      const balance = floorToFixed(wallet?.balance, 2);
      setTotalBalance(balance);
    }
  }, [wallet]);

  useEffect(() => {
    if (marketerPendingBalance?.balance) {
      const balance = floorToFixed(marketerPendingBalance?.balance, 2);
      setPendingBalance(balance);
    }
  }, [marketerPendingBalance]);

  useEffect(() => {
    if (filter?.statusCount !== null) {
      let allStatusCount = 0;
      setActiveJob(filter?.totalCount);
      for (let i = 0; i < filter?.statusCount?.length; i++) {
        if (filter?.statusCount[i]?.status === 'ALL') {
          allStatusCount = filter?.statusCount[i]?.count;
        }
      }
      setTotalJob(allStatusCount);
    }
  }, [filter]);

  useEffect(() => {
    if (followUp) {
      setPendingAccept(followUp?.pendingAccept ?? 0);
      setPendingApprove(followUp?.pendingReviewApprove ?? 0);
    }
  }, [followUp]);

  return (
    <div
      className="page-content"
      style={{
        paddingTop: `${width < 993 ? '4vh' : '2vh'}`,
        justifyContent: `${width < 646 ? 'center' : ''}`,
      }}
    >
      <div className="dashboard-wraper " id="dashboard-page">
        <h4>{t('Menus.Home')} </h4>
        <p className="ml-2">
          {t('dashboard.Welcome back')} {userInfo.name} 👋🏼
        </p>
        <h4>{t('dashboard.Get to know our apps')}</h4>
        <p className="ml-2">
          {t('dashboard.Use our suite of platform to reach all your growth goals.')}{' '}
          👋🏼
        </p>
        {/* Card Dashboard */}
        <div className="maketer-dashboard-card-wraper ">
          <div className="card-dashboard">
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <i className="fas fa-wallet font-size-20" />
              <p className="title-card">{t('dashboard.Total Balance')}</p>
            </div>
            <div className="center" style={{ marginTop: '20px' }}>
              <h2 style={{ marginBottom: '0px' }}>
                <b style={{ color: 'black' }}> {totalBalance} </b>
                {walletSymbol}
              </h2>
            </div>
            <div className="center">
              <p style={{ marginBottom: '0px' }}>
                <b style={{ color: 'black' }}>
                  {t('Pending')} : {pendingBalance} {walletSymbol}
                </b>
              </p>
            </div>
            <a href="/wallet" className="goto">
              <div className="button-job" style={{ display: 'block' }}>
                {t('dashboard.Go to wallet')}
              </div>
            </a>
          </div>
          <div
            className="card-dashboard"
            // style={{ background: 'linear-gradient(143deg, #b6bcd6 0%, white 48%)' }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <i
                className="fa fa-bullhorn font-size-20"
                style={{ marginLeft: '8px', color: 'white' }}
              />
              <p className="title-card">{t('Menus.Activity')}</p>
            </div>
            <div className="center">
              <h2 style={{ marginBottom: '0px' }}>
                <b style={{ color: 'black' }}>{totalJob}</b>
              </h2>
            </div>
            <div className="center">
              <p>
                <b style={{ color: 'black' }}>
                  {t('dashboard.Active activity')}: {activeJob}
                </b>
              </p>
            </div>
            <a href="/campaigns/markerter/job" className="goto">
              <div className="button-job">{t('dashboard.Go to activity')}</div>
            </a>
          </div>
          <div className="card-dashboard">
            <div style={{ display: 'flex', gap: '10px' }}>
              {' '}
              <i
                className="fa fa-calendar-check-o font-size-20"
                style={{ marginLeft: '8px', color: 'white' }}
              />
              <p className="title-card">{t('dashboard.Follow up activity')}</p>
            </div>
            <div style={{ marginTop: '28px' }}>
              <b style={{ color: 'black' }}>
                {t('dashboard.Pending accept')}: {pendingAccept}
              </b>
            </div>
            <div>
              <b>
                {t('dashboard.Pending review & approve')}: {penddingApprove}
              </b>
            </div>
            <a href="/campaigns/markerter/job" className="goto">
              <div className="button-job">{t('dashboard.Go to activity')}</div>
            </a>
          </div>
        </div>
        {/* template */}
        {/* <h4 style={{ marginTop: '20px' }}>{t('dashboard.Our template')}</h4>
        <div className="maketer-template-card-wraper ">
          <div className="card-template">
            <img
              alt="template-logo"
              className="template-img"
              src={influ}
              width={280}
              height={200}
              style={{ border: '2px #e9edee solid' }}
            />
            <div>Coming soon</div>
          </div>
        </div> */}
        {/* Guide */}
      </div>
    </div>
  );
};

export default AdvertiserDashboardNew;
