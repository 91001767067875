import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Card, CardBody, CardTitle } from 'reactstrap';
import noCampaignImage from 'src/assets/images/no-campaign-image.png';
import JobStatusButtonGroup from 'src/components/Advertiser/jobStatusButtonGroup';
import SearchBar from 'src/components/Table/SearchBar';
import config from 'src/config';
import Pagination from '@mui/material/Pagination';
import { filterJob } from 'src/store/marketerJob/actions';
import moment from 'moment';
import {
  InfluencerJobBackGroundColor,
  InfluencerJobStatus,
  InfluencerContentType,
  InfluencerSocialIcon,
} from 'src/components/Common/status/InfluencerJobStatus';
import './JobIndex.style.scss';
import { getDefaultWebsite } from 'src/store/actions';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import paramsFromURL from 'src/constants/paramsFromURL';
import { floorToFixed } from 'src/common/data/mathToFixed';

interface LocationState {
  camPaignName?: string;
}

const JobIndex = ({ jobType }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPublish = jobType === 'PUBLISH';

  const location = useLocation<LocationState>();
  const camPaignName: any = location?.state?.camPaignName || '';

  const [key] = paramsFromURL(['campaign']);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'ALL',
    marketer_campaign_key: key,
  });
  const { data, defaultTax, influencerData } = useSelector((state: any) => ({
    data: state.MarketerJob.filter,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    influencerData: state.Users.userInfo,
  }));
  const tax = defaultTax / 100 + 1;

  if (data?.items) {
    if (Object.keys(data.items)?.length) {
      for (let j = 0; j < data.items?.length; j++) {
        let num_influencer = 0;
        let num_follower = 0;
        let total_price = 0;
        const job = data.items[j];
        num_influencer += job.social_media_has_jobs?.length || 0;
        for (let k = 0; k < job.social_media_has_jobs?.length; k++) {
          const socialJob = job.social_media_has_jobs[k];
          num_follower += socialJob.social_media_info.follower || 0;
          total_price += socialJob.price || 0;
        }
        job.num_influencer = num_influencer;
        job.num_follower = num_follower;
        job.total_price = total_price * tax;
      }
    }
  }
  // const primeZone = moment.tz('2013-11-18 00:00:00', 'Iceland');
  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');

  const handleStatusChange = (status: string) => {
    const payload = {
      ...param,
      page: 1,
      status,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };
  const capitalize = (s: any) => {
    // eslint-disable-next-line func-names
    return s.toLowerCase().replace(/\b./g, function (a: any) {
      return a.toUpperCase();
    });
  };
  const handleSearch = (searchInput: string) => {
    const payload = {
      ...param,
      search: searchInput,
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      search: '',
    };
    dispatch(filterJob(payload));
    setParam(payload);
  };

  useEffect(() => {
    dispatch(getDefaultWebsite());
    dispatch(filterJob(param));
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>
      <div className="job-container">
        <Breadcrumb
          pageTitle={t(isPublish ? 'Menus.Suggested Jobs' : 'Menus.Invite-Only Job')}
          menus={[
            { menu: menu.HOME, active: true },
            {
              menu: isPublish
                ? menu.MARKETER_ANNOUNCEMENT_CAMPAIGN
                : menu.MARKETER_INVITEONLY_CAMPAIGN,
            },
            {
              menu: isPublish
                ? menu.MARKETER_CAMPAIGN_JOB_ANNOUNCEMENT
                : menu.MARKETER_CAMPAIGN_JOB,
              isSamePage: true,
            },
          ]}
        />

        <div className="job-header">
          <div className="dropdown-status-job-container">
            <div className="edit-campaign-container">
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                {camPaignName}
              </span>
              <button
                className="edit-campaign-button-container"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  if (isPublish) {
                    history.push(`/EditMarketerPublicCampaign?edit=${key}`);
                  } else {
                    history.push(`/EditMarketerCampaign?edit=${key}`);
                  }
                }}
              >
                <i
                  className="bx bx-edit"
                  style={{
                    cursor: 'pointer',
                    color: `white`,
                    fontSize: '16px',
                  }}
                />
                <div style={{ padding: '0px 10px' }}>Edit</div>
              </button>
            </div>
          </div>

          <div className="search-add-job-container">
            <JobStatusButtonGroup
              param={param}
              handleStatusChange={handleStatusChange}
              statusCount={data?.statusCount}
            />
            <SearchBar
              placeholder={t('table:Search Name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
              disableClear
            />
            <button
              type="button"
              className="add-job-button"
              onClick={() => {
                if (!key) return;
                if (jobType === 'PUBLISH') {
                  history.push(`/Marketer/CreatePublicJob?campaign=${key}`);
                } else {
                  history.push(`/Marketer/CreateJob?campaign=${key}`);
                }
              }}
            >
              <div style={{ fontSize: '14px', width: '60px' }}>
                {t('button:Add Job')}
              </div>
            </button>
          </div>
        </div>
        {!data?.totalCount ? (
          <>
            <div className="no-campaign-container">
              <img
                src={noCampaignImage}
                className="no-campaign-image-container"
                alt="noCampaignImage"
              />
              <div className="text-container">
                <div className="text-top">{t('table:There’s no job in here')}</div>
                <div
                  className="text-bottom"
                  onClick={() => {
                    if (!key) return;
                    if (jobType === 'PUBLISH') {
                      history.push(`/Marketer/CreatePublicJob?campaign=${key}`);
                    } else {
                      history.push(`/Marketer/CreateJob?campaign=${key}`);
                    }
                  }}
                >
                  {t('table:Create New Job')}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {data?.items?.map((items: any) => {
              return (
                <div
                  style={{ margin: 'auto' }}
                  key={items.key}
                  className="card-job-index"
                >
                  <>
                    <Card
                      className="card"
                      style={{
                        background: `${InfluencerJobBackGroundColor(items.status)}`,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (items.isDisableEdit) {
                          if (isPublish) {
                            history.push(
                              `/Marketer/JobPublicDetail?job=${items.key}`,
                            );
                          } else {
                            history.push(`/Marketer/JobDetail?job=${items.key}`);
                          }
                        } else {
                          history.push(`/Marketer/EditJobCampaign?job=${items.key}`);
                        }
                      }}
                    >
                      <CardTitle
                        style={{
                          background: 'white',
                          borderTopLeftRadius: '18px',
                          borderTopRightRadius: '18px',
                          padding: '0',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            flexWrap: 'nowrap',
                            padding: '10px 30px 10px 30px',
                          }}
                        >
                          <div>
                            <div className="job-name-title">{items.name}</div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  marginRight: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <InfluencerSocialIcon
                                  social_media_type={items.social_media_type}
                                />
                                <InfluencerContentType
                                  content_type={items.content_type}
                                />
                              </div>
                              <div>
                                <div className="social-text">
                                  {capitalize(items.social_media_type)}
                                </div>
                                <div className="social-text">
                                  {capitalize(items.content_type)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <InfluencerJobStatus
                              status={items.status}
                              style={{ maxWidth: '200px' }}
                            />
                            <div className="social-text">{currentTimeZone}</div>
                          </div>
                        </div>

                        {jobType === 'PUBLISH' && (
                          <div style={{ padding: '0' }}>
                            <img
                              src={`${config.backendUrl}api/jobThumbnail/${items?.thumbnail_cover}`}
                              alt={items?.thumbnail_cover}
                              style={{
                                width: '100%',
                                height: 'auto',
                              }}
                            />
                          </div>
                        )}
                      </CardTitle>
                      <CardBody>
                        <div className="group-footer">
                          <div className="column-group">
                            <div className="price-num">
                              {floorToFixed(items.total_price, 2)} USDT
                            </div>
                            <div className="price">
                              {t('marketer:create_campaign.Price')}
                            </div>
                          </div>
                          <div className="column-group">
                            <div className="influencer-num">
                              {items.num_influencer}
                            </div>
                            <div className="influencer">
                              {t('marketer:create_campaign.Influencer')}
                            </div>
                          </div>
                          <div className="column-group">
                            <div className="follower-num">{items.num_follower}</div>
                            <div className="follower">
                              {t('marketer:create_campaign.Followers')}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </>
                </div>
              );
            })}
          </>
        )}

        <Pagination
          count={Math.ceil(data?.totalCount / param.limit)}
          page={param.page}
          color="standard"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
          boundaryCount={2}
        />
      </div>
    </div>
  );
};

export default JobIndex;
