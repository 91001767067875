/* eslint-disable no-shadow */
//react
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//react style
import {
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
//graphql
//component
import config from 'src/config';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import CustomTable from '../Common/CustomTable';
import { updateCreativeCampaignGraphql } from '../../graphql/advertiser/creative';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
//redux
import {
  filterCreativeCampaign as onFilterCreativeCampaign,
  getAllAdminApproveLog,
  setLoading,
  unsetLoading,
} from '../../store/actions';
import ImageAutoBalance from '../Common/ImageAutoBalance';
import DateTimeComponent from '../Common/DateTimeComponent';
import DateTimeTypes from '../../constants/dateTimeTypes';
import Dropdown from '../CommonForBoth/Menus/Dropdown';

const ApproveCampaign = (props: any) => {
  //from parent
  const { t, verifyStatus } = props;
  //from react
  const dispatch = useDispatch();
  //state variables
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [nameSearch, setNameSearch] = useState('');
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);
  //redux
  const { filterCreativeCampaign } = useSelector((state: any) => ({
    filterCreativeCampaign: state.Creatives.filterCreativeCampaign,
  }));
  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  //general function
  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      page,
      name: nameSearch,
      verifyStatus: newVerifyStatus,
    };
  };

  const handleStatusChange = (event: any) => {
    const payload = {
      ...getDefaultPayload(),
      page: 1,
      verifyStatus: event.value,
    };
    dispatch(onFilterCreativeCampaign(payload));
    setNewVerifyStatus(event.value);
    setPage(1);
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };
    dispatch(onFilterCreativeCampaign(payload));
    setPage(newPage);
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...getDefaultPayload(),
      name: searchInput,
    };
    dispatch(onFilterCreativeCampaign(payload));
    setNameSearch(searchInput);
  };

  const handleClear = () => {
    setNameSearch('');
    const payload = {
      ...getDefaultPayload(),
      name: '',
    };
    dispatch(onFilterCreativeCampaign(payload));
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...getDefaultPayload(),
      limit: +newPageSize,
      page: 1,
    };
    dispatch(onFilterCreativeCampaign(payload));
    setPageSize(+newPageSize);
    setPage(1);
  };
  const clickVerifyStatus = async (verifyStatus: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verifyStatus} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await updateCreativeCampaignGraphql({
          verifyStatus,
          key,
        });

        dispatch(unsetLoading());
        const payload = {
          ...getDefaultPayload(),
        };
        dispatch(onFilterCreativeCampaign(payload));
      }
    });
  };

  //table
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Preview'),
        Cell: ({ cell }: any) => {
          const { creative } = cell.row.original;
          if (creative) {
            return (
              <div className="pic-frame">
                <div className="pic-middle">
                  <ImageAutoBalance
                    href={`${config.backendUrl}creatives/display/${creative.image_storage.key}`}
                    alt="ad image"
                    src={`${config.backendUrl}creatives/display/${creative.image_storage.key}`}
                    sizeImage={50}
                    width={+creative.size.split('x')[0]}
                    height={+creative.size.split('x')[1]}
                    size="md"
                  />
                </div>
              </div>
            );
          } else {
            return <div className="mt-3">POP-UNDER</div>;
          }
        },
        accessor: ({ creative }) => {
          if (creative) {
            return creative;
          } else {
            return '';
          }
        },
        width: 80,
        id: 'preview',
        disableSort: true,
      },
      {
        Header: t('table:Campaign Type'),
        accessor: ({ campaign }) => {
          if (campaign.creative_types?.length) {
            let campaignType = campaign.creative_types[0].name;
            for (let i = 1; i < campaign.creative_types?.length; i++) {
              campaignType = `${campaignType}, ${campaign.creative_types[i].name}`;
            }
            return campaignType;
          }
          return 'NULL';
        },
        rowCss: 'mt-3',
        disableSort: true,
        width: 150,
        id: 'campaignType',
      },
      {
        Header: t('tableCreative:Creative Name'),
        accessor: ({ creative, name }) => {
          if (creative) {
            return creative.name;
          } else {
            return name;
          }
        },
        rowCss: 'mt-3',
        width: 150,
        id: 'creative',
      },
      {
        Header: t('tableCreative:URL'),
        accessor: (url_ad) => url_ad,
        Cell: ({ cell }: any) => {
          const { url_ad } = cell.row.original;
          const urlOnclick = url_ad;
          const arrUrlAd = url_ad.match(/.{1,35}/g);
          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {arrUrlAd[0]} {urlOnclick?.length > 35 && '...'}
            </a>
          );
        },
        rowCss: 'mt-3',
        id: 'URL',
      },
      {
        Header: t('table:size'),
        accessor: ({ creative }) => {
          if (creative) {
            return creative.size;
          } else {
            return 'NULL';
          }
        },
        rowCss: 'mt-3',
        id: 'size',
        width: 50,
      },
      {
        Header: t('Created At'),
        accessor: ({ created_at }) => {
          return (
            <div className="mt-3">
              <DateTimeComponent
                dateTime={created_at}
                dateTimeType={DateTimeTypes.RECENT_TRANSACTION_DATE_TIME}
              />
            </div>
          );
        },
        width: 180,
        id: 'col-5',
      },
      {
        Header: t('Status'),
        accessor: ({ verifyStatus }) => {
          let badgeColor: string = 'warning';
          switch (verifyStatus) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-3">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verifyStatus}`)}
              </span>
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Status',
      },
      {
        Header: t('table:Action'),
        accessor: ({ key, verifyStatus }) => (
          <UncontrolledDropdown className="mt-3">
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {verifyStatus !== VerifyStatus[0] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('PENDING', key)}
                  className="text-warning"
                >
                  <i className="mdi mdi-account-clock text-warning" />
                  &nbsp;&nbsp;{t('table:PENDING')}
                </DropdownItem>
              )}
              {verifyStatus !== VerifyStatus[1] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('APPROVED', key)}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
              )}
              {verifyStatus !== VerifyStatus[2] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('REJECTED', key)}
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
    ],
    [t, page, filterCreativeCampaign],
  );
  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  //set data

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('advertiser_campaign');
    dispatch(getAllAdminApproveLog());
  };
  useEffect(() => {
    const payload = {
      ...getDefaultPayload(),
    };
    fetchApproveLog();
    dispatch(onFilterCreativeCampaign(payload));
  }, []);
  return (
    <CardBody>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <PageSizeOptions
          defaultValue={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          colors="white"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '150px', marginRight: '20px' }}>
            {verifyStatus !== 'PENDING' && (
              <Dropdown
                options={Status}
                action={handleStatusChange}
                default_value={Status[3]}
              />
            )}
          </div>
          <SearchBar
            placeholder={t('creative:Search Creative Name')}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
        </div>
      </div>
      <CustomTable
        columns={columns}
        data={filterCreativeCampaign.items || []}
        page={page}
        pageSize={pageSize}
        total={filterCreativeCampaign.totalCount || 1}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        headerCSS="fw-normal bg-header-table"
        autoSort
      />
    </CardBody>
  );
};
export default withTranslation()(ApproveCampaign);
