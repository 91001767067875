import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import CustomButton from 'src/components/Common/CustomButton';
import menu from 'src/constants/menu';
import config from 'src/config';
import { uploadSocialProfileImage } from 'src/graphql/advertiser/job';
import { createSocialMediaInfoGql } from 'src/graphql/advertiser/socialMediaInfo';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';

import facebookLogo from 'src/assets/images/facebook-logo.png';
import twitterLogo from 'src/assets/images/x-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tiktokLogo from 'src/assets/images/tiktok-logo.png';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
// import { validationSchema } from 'src/pages/Publisher/Influencer/ChannelDetail/control/formControl';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { SocialMediaType } from '../Dashboard/SocialChannel/SocialChannel.control';
import AptitudesComponent from './Aptitudes/AptitudesMannual.component';
import ChannelLogoComponent from './ChannelLogo';
import { ChanelDetailContainer, NameUser, PageContent } from './SocialProfile.style';

const NewSocialProfile = ({ setFormValues, formValues }: any) => {
  const { t } = useTranslation();
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [socialMediaLogos, setSocialMediaLogos] = useState('');
  const [fileImage, setFileImage] = useState<string | undefined>(userAvatar);

  const [nameError, setNameError] = useState(false);
  const [socialMediaError, setSocialMediaError] = useState(false);
  const [followerError, setFollowerError] = useState(false);
  const [profilePathError, setProfilePathError] = useState(false);
  const [introduceError, setIntroduceError] = useState(false);

  //gen id
  function generateUniqueID() {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 22; // Adjust the length as needed
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  //set fromValues
  useEffect(() => {
    const generatedId = generateUniqueID();
    setFormValues((current: any) => ({
      ...current,
      id: generatedId,
      email: '',
      expire_access_token: '',
      video_content_price: 0,
    }));
  }, []);

  // Function to handle image upload
  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.size > 300 * 1024) {
        notify(i18n.t('error:Image size must be less than 300 KB.'), 'error');
        return;
      }
      setFileImage(URL.createObjectURL(selectedFile));
      const imagesURL = await uploadSocialProfileImage(selectedFile);
      const imagesPath = `${config.backendUrl}api/socialProfile/${imagesURL}`;
      setFormValues((current: any) => ({
        ...current,
        picture_url: imagesPath,
      }));
    }
  };

  const handleNameChange = (e: any) => {
    const { value } = e.target;
    setFormValues((current: any) => ({
      ...current,
      name: value,
    }));
  };

  const handleFollowerChange = (e: any) => {
    const { value } = e.target;
    setFormValues((current: any) => ({
      ...current,
      follower: Number(value),
    }));
  };

  const handleProfilePathChange = (e: any) => {
    const { value } = e.target;
    setFormValues((current: any) => ({
      ...current,
      profile_path: value,
    }));
  };

  const handleIntroduce = (e: any) => {
    const { value } = e.target;
    setFormValues((current: any) => ({
      ...current,
      introduce: value,
    }));
  };

  // Function to handle logo social change
  const handleSocialMediaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    setSelectedSocialMedia(selectedOption);

    // Set the corresponding logo based on the selected social media
    switch (selectedOption) {
      case 'facebook':
        setSocialMediaLogos(facebookLogo);
        setFormValues((current: any) => ({
          ...current,
          social_media_type: SocialMediaType.FACEBOOK,
        }));
        break;
      case 'twitter':
        setSocialMediaLogos(twitterLogo);
        setFormValues((current: any) => ({
          ...current,
          social_media_type: SocialMediaType.TWITTER,
        }));
        break;
      case 'instagram':
        setSocialMediaLogos(instagramLogo);
        setFormValues((current: any) => ({
          ...current,
          social_media_type: SocialMediaType.INSTAGRAM,
        }));
        break;
      case 'youtube':
        setSocialMediaLogos(youtubeLogo);
        setFormValues((current: any) => ({
          ...current,
          social_media_type: SocialMediaType.YOUTUBE,
        }));
        break;
      case 'tiktok':
        setSocialMediaLogos(tiktokLogo);
        setFormValues((current: any) => ({
          ...current,
          social_media_type: SocialMediaType.TIKTOK,
        }));
        break;
      default:
        setSocialMediaLogos('');
        break;
    }
  };

  const { userProfile$ } = useSelector((state: any) => ({
    userProfile$: state.SocialMediaInfo.socialMediaInfoData,
  }));

  // if (!userProfileSelected$) return <OnLoading />;

  // onSubmit function เพื่อนำข้อมูลไปยิง createSocialMediaInfoGql และรีเทรินออกมา setUserProfileList
  const onSubmit = async () => {
    if (!formValues.name) {
      setNameError(true);
      return;
    }

    if (!formValues.social_media_type) {
      setSocialMediaError(true);
      return;
    }

    if (!formValues.follower || formValues.follower < 1) {
      setFollowerError(true);
      return;
    }

    if (!formValues.profile_path) {
      setProfilePathError(true);
      return;
    }

    if (!formValues.introduce) {
      setIntroduceError(true);
      return;
    }
    try {
      await createSocialMediaInfoGql({
        social_media_type: formValues.social_media_type,
        name: formValues.name,
        follower: formValues.follower,
        post_engagement: formValues.post_engagement,
        job_score: formValues.job_score,
        rating: formValues.rating,
        like: formValues.like,
        share_content_price: formValues.share_content_price,
        photo_content_price: formValues.photo_content_price,
        video_content_price: formValues.video_content_price,
        introduce: formValues.introduce,
        aptitude_ids: formValues.aptitude_ids,
        image_profile_url: formValues.picture_url,
        access_token: formValues.access_token,
        access_token_secret: formValues.access_token_secret,
        expire_access_token: formValues?.expire_access_token,
        is_enable_share_price: formValues.is_enable_share_price,
        is_enable_photo_price: formValues.is_enable_photo_price,
        is_enable_video_price: formValues.is_enable_video_price,
        social_id: formValues.id,
        profile_path: formValues.profile_path,
      });
      window.location.href = '/';
    } catch (error) {
      console.error('Error creating social media info:', error);
    }
  };

  return (
    <PageContent className="page-content">
      <div className="bread-crumb">
        <Breadcrumb
          pageTitle={t('Menus:Create Social Channel')}
          menus={[
            { menu: menu.HOME, active: true },
            { menu: menu.OVERVIEW },
            { menu: menu.CREATESOCIALCHANNEL, isSamePage: true },
          ]}
        />
      </div>
      <ChanelDetailContainer>
        <CardContainer className="card-container">
          <div className="image-container">
            <Profile
              src={fileImage}
              alt="Influencer Profile"
              className="profile skeleton"
              onLoad={(e) => {
                e.currentTarget.classList.remove('skeleton');
              }}
              onError={(e) => {
                e.currentTarget.classList.remove('skeleton');
                e.currentTarget.src = userAvatar;
              }}
            />
            <ChannelLogoComponent socialMediaLogo={socialMediaLogos} />
          </div>
          <Formik initialValues={formValues} onSubmit={onSubmit}>
            {({ errors, touched }) => {
              return (
                <Form style={{ textAlign: 'center' }}>
                  <StaticContainer>
                    <div className="image-container-new">
                      <div className="profile-social-new-section">
                        <span className="title-social-profile">profile picture</span>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="upload-images-profile-input"
                        />
                      </div>
                      <NameUser className="name">
                        <div className="profile-social-new-section">
                          <span className="title-social-profile">
                            social media name
                          </span>
                          <div className="input-valid-position">
                            {nameError && (
                              <div className="invalid-message-new">
                                * Social media name is required
                              </div>
                            )}
                            <input
                              type="text"
                              name="name"
                              id="username"
                              placeholder="Username"
                              onChange={handleNameChange}
                              className={`upload-images-profile ${
                                nameError ? 'is-invalid' : ''
                              }`}
                            />
                          </div>
                        </div>
                      </NameUser>
                      <div className="profile-social-new-section">
                        <span className="title-social-profile">Social Media</span>
                        <div className="input-valid-position">
                          {socialMediaError && (
                            <div className="invalid-message-new">
                              * Social media is required
                            </div>
                          )}
                          <select
                            value={selectedSocialMedia}
                            onChange={handleSocialMediaChange}
                            name="social_media_type"
                            className={`upload-images-profile-select ${
                              socialMediaError ? 'is-invalid' : ''
                            }`}
                          >
                            <option value="">select social media</option>
                            <option value="facebook">Facebook</option>
                            <option value="twitter">x</option>
                            <option value="instagram">Instagram</option>
                            <option value="youtube">YouTube</option>
                            <option value="tiktok">TikTok</option>
                          </select>
                        </div>
                      </div>
                      <FollowerContainer>
                        <div className="profile-social-new-section">
                          <span className="title-social-profile">follower</span>
                          <div className="input-valid-position">
                            {followerError && (
                              <div className="invalid-message-new">
                                * follower is required
                              </div>
                            )}
                            <input
                              type="number"
                              id="follower"
                              name="follower"
                              min={1}
                              onChange={handleFollowerChange}
                              className={`upload-images-profile ${
                                followerError ? 'is-invalid' : ''
                              }`}
                            />
                          </div>
                        </div>
                      </FollowerContainer>
                      <div className="profile-social-new-section">
                        <span className="title-social-profile">link social</span>
                        <div className="input-valid-position">
                          {profilePathError && (
                            <div className="invalid-message-new">
                              * follower is required
                            </div>
                          )}
                          <input
                            type="url"
                            id="profile_path"
                            name="profile_path"
                            className={`upload-images-profile ${
                              profilePathError ? 'is-invalid' : ''
                            }`}
                            onChange={handleProfilePathChange}
                          />
                        </div>
                      </div>
                    </div>
                  </StaticContainer>
                  <div style={{ marginBottom: '40px' }}> </div>
                  <PriceContainer>
                    <AptitudesComponent
                      formValues={formValues}
                      setFormValues={setFormValues}
                      userProfile={userProfile$}
                    />
                  </PriceContainer>
                  <PriceContainer>
                    <div className="profile-social-new-section-last">
                      <div className="input-valid-position">
                        <div className="title-social-profile">
                          Introduce Yourself
                        </div>
                        {introduceError && (
                          <div className="invalid-message-new">
                            * follower is required
                          </div>
                        )}
                        <input
                          type="textarea"
                          id="introduce"
                          name="introduce"
                          className={`upload-images-profile ${
                            introduceError ? 'is-invalid' : ''
                          }`}
                          onChange={handleIntroduce}
                        />
                      </div>
                    </div>
                  </PriceContainer>
                  <CustomButton
                    status="submit"
                    size="lg"
                    label={t('influencer:account:send_request')}
                  />
                </Form>
              );
            }}
          </Formik>
        </CardContainer>
      </ChanelDetailContainer>
    </PageContent>
  );
};

const FollowerContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  .btn-edit {
    margin-left: 1rem;
    color: white;
    font-size: 14px;
    width: 90px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    cursor: pointer;
  }

  img {
    width: 35px;
    height: 35px;
    @media (max-width: 500px) {
      width: 32px;
      height: 32px;
    }
  }
  span {
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

const PriceContainer = styled.div`
  text-align: center;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 1px solid rgb(221, 218, 218);
  margin-top: 0.5rem;
`;

const CardContainer = styled.div`
  position: relative;
  height: max-content;
  min-height: 300px;
  width: 100%;
  max-width: 50rem;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #fbfaff;
`;

const StaticContainer = styled.div`
  position: relative;
  background-color: #fbfaff;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  margin-top: 70px;
  /* border: 2px solid pink; */
  .btn {
    margin-left: 1rem;
    color: white;
    font-size: 14px;
    width: 90px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    cursor: pointer;
  }
  span {
    @media (max-width: 500px) {
      /* font-size: px; */
    }
  }
`;

const Profile = styled.img`
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  animation-delay: 2.5s;
  animation: profile 0.5s linear alternate;

  @keyframes profile {
    0% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(0.5);
      width: 130px;
      height: 130px;
    }
    100% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1);
      width: 130px;
      height: 130px;
    }
  }
`;

export default NewSocialProfile;
