/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum SocialMediaInfoTypes {
  GET_SOCIAL_MEDIA_INFO = '@@social_media_info/GET_SOCIAL_MEDIA_INFO',
  GET_SOCIAL_MEDIA_INFO_SUCCESS = '@@social_media_info/GET_SOCIAL_MEDIA_INFO_SUCCESS',

  GET_ONE_SOCIAL_MEDIA_INFO = '@@social_media_info/GET_ONE_SOCIAL_MEDIA_INFO',
  GET_ONE_SOCIAL_MEDIA_INFO_SUCCESS = '@@social_media_info/GET_ONE_SOCIAL_MEDIA_INFO_SUCCESS',

  GET_SOCIAL_JOB_BY_JOB_KEY = '@@social_media_info/GET_SOCIAL_JOB_BY_JOB_KEY',
  GET_SOCIAL_JOB_BY_JOB_KEY_SUCCESS = '@@social_media_info/GET_SOCIAL_JOB_BY_JOB_KEY_SUCCESS',

  GET_ALL_APTITUDE = '@@social_media_info/GET_ALL_APTITUDE',
  GET_ALL_APTITUDE_SUCCESS = '@@social_media_info/GET_ALL_APTITUDE_SUCCESS',
  GET_ALL_APTITUDE_FAIL = '@@social_media_info/GET_ALL_APTITUDE_FAIL',

  GET_ALL_KEYWORD_APTITUDE = '@@social_media_info/GET_ALL_KEYWORD_APTITUDE',
  GET_ALL_KEYWORD_APTITUDE_SUCCESS = '@@social_media_info/GET_ALL_KEYWORD_APTITUDE_SUCCESS',
  GET_ALL_KEYWORD_APTITUDE_FAIL = '@@social_media_info/GET_ALL_KEYWORD_APTITUDE_FAIL',
  FILTER_SOCIAL_JOB = '@@marker_campaign/FILTER_SOCIAL_JOB',
  FILTER_SOCIAL_JOB_SUCCESS = '@@marker_campaign/FILTER_SOCIAL_JOB_SUCCESS',

  GET_SOCIAL_JOB = '@@social_media_info/GET_SOCIAL_JOB',
  GET_SOCIAL_JOB_SUCCESS = '@@social_media_info/GET_SOCIAL_JOB_SUCCESS',

  GET_INFLUENCER_PRICE_RATE = '@@social_media_info/GET_INFLUENCER_PRICE_RATE',
  GET_INFLUENCER_PRICE_RATE_SUCCESS = '@@social_media_info/GET_INFLUENCER_PRICE_RATE_SUCCESS',

  GET_INFLUENCER_REQUEST_NEW_PRICE = '@@social_media_info/GET_INFLUENCER_REQUEST_NEW_PRICE',
  GET_INFLUENCER_REQUEST_NEW_PRICE_SUCCESS = '@@social_media_info/GET_INFLUENCER_REQUEST_NEW_PRICE_SUCCESS',

  SOCIAL_MEDIA_FILTER_TABLE = '@@social_media_info/SOCIAL_MEDIA_FILTER_TABLE',
  SOCIAL_MEDIA_FILTER_TABLE_SUCCESS = '@@social_media_info/SOCIAL_MEDIA_FILTER_TABLE_SUCCESS',

  GET_REQUEST_NEW_PRICE_FILTER_TABLE = '@@social_media_info/GET_REQUEST_NEW_PRICE_FILTER_TABLE',
  GET_REQUEST_NEW_PRICE_FILTER_TABLE_SUCCESS = '@@social_media_info/GET_REQUEST_NEW_PRICE_FILTER_TABLE_SUCCESS',

  GET_YOUTUBE_VIDEO_DATA = '@@social_media_info/GET_YOUTUBE_VIDEO_DATA',
  GET_SOCIAL_POST_SUCCESS = '@@social_media_info/GET_SOCIAL_POST_SUCCESS',

  GET_FACEBOOK_DATA = '@@social_media_info/GET_FACEBOOK_DATA',
  GET_INSTAGRAM_DATA = '@@social_media_info/GET_INSTAGRAM_DATA',
  GET_TIKTOK_DATA = '@@social_media_info/GET_TIKTOK_DATA',
  GET_TWITTER_DATA = '@@social_media_info/GET_TWITTER_DATA',

  SOCIAL_JOB_CARD_DATA = '@@social_media_info/SOCIAL_JOB_CARD_DATA',

  SOCIAL_JOB_SUMMARY = '@@social_media_info/SOCIAL_JOB_SUMMARY',
  SOCIAL_JOB_SUMMARY_SUCCESS = '@@social_media_info/SOCIAL_JOB_SUMMARY_SUCCESS',

  GET_SOCIAL_MEDIA_INFO_By_UUID = '@@social_media_info/GET_SOCIAL_MEDIA_INFO_By_UUID',
  GET_SOCIAL_MEDIA_INFO_By_UUID_SUCCESS = '@@social_media_info/GET_SOCIAL_MEDIA_INFO_By_UUID_SUCCESS',

  GET_EXPIRED_SOCIAL_MEDIA_TOKEN = '@@social_media_info/GET_EXPIRED_SOCIAL_MEDIA_TOKEN',
  GET_EXPIRED_SOCIAL_MEDIA_TOKEN_SUCCESS = '@@social_media_info/GET_EXPIRED_SOCIAL_MEDIA_TOKEN_SUCCESS',
  CLEAR_EXPIRED_SOCIAL_MEDIA_TOKEN = '@@social_media_info/CLEAR_EXPIRED_SOCIAL_MEDIA_TOKEN',

  SOCIAL_JOB_COUNT = '@@social_media_info/SOCIAL_JOB_COUNT',
  SOCIAL_JOB_COUNT_SUCCESS = '@@social_media_info/SOCIAL_JOB_COUNT_SUCCESS',

  REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE = '@@social_media_info/REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE',
  REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE_SUCCESS = '@@social_media_info/REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE_SUCCESS',

  GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE = '@@social_media_info/GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE',
  GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE_SUCCESS = '@@social_media_info/GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE_SUCCESS',

  RESET_SOCIAL_JOB = '@@social_media_info/RESET_SOCIAL_JOB',

  GET_RECOMMEND_CONTENT_PRICE = '@@social_media_info/GET_RECOMMEND_CONTENT_PRICE,',
  GET_RECOMMEND_CONTENT_PRICE_SUCCESS = '@@social_media_info/GET_RECOMMEND_CONTENT_PRICE_SUCCESS',

  UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY = '@@social_media_info/UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY',
  UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY_SUCCESS = '@@social_media_info/UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY_SUCCESS',

  FILTER_SOCIAL_GLEAM_JOB = '@@marker_campaign/FILTER_SOCIAL_GLEAM_JOB',
  FILTER_SOCIAL_GLEAM_JOB_SUCCESS = '@@marker_campaign/FILTER_SOCIAL_GLEAM_JOB_SUCCESS',

  GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY',
  GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY_SUCCESS = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY_SUCCESS',

  GET_SOCIAL_GLEAM_JOB_FOR_WORK = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_FOR_WORK',
  GET_SOCIAL_GLEAM_JOB_FOR_WORK_SUCCESS = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_FOR_WORK_SUCCESS',

  GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY',
  GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY_SUCCESS = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY_SUCCESS',

  MARKETER_SUM_PENDING_BALANCE = '@@social_media_info/MARKETER_SUM_PENDING_BALANCE',
  MARKETER_SUM_PENDING_BALANCE_SUCCESS = '@@social_media_info/MARKETER_SUM_PENDING_BALANCE_SUCCESS',

  GET_SOCIAL_GLEAM_JOB_FOR_REVIEW = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_FOR_REVIEW',
  GET_SOCIAL_GLEAM_JOB_FOR_REVIEW_SUCCESS = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_FOR_REVIEW_SUCCESS',

  INFLUENCER_ACTIVE_PENDING_JOB_COUNT = '@@marketer_job/INFLUENCER_ACTIVE_PENDING_JOB_COUNT',
  INFLUENCER_ACTIVE_PENDING_JOB_COUNT_SUCCESS = '@@marketer_job/INFLUENCER_ACTIVE_PENDING_JOB_COUNT_SUCCESS',

  REPORT_JOB_DECLINE = '@@social_media_info/REPORT_JOB_DECLINE',
  REPORT_JOB_DECLINE_SUCCESS = '@@social_media_info/REPORT_JOB_DECLINE_SUCCESS',

  GET_REPORT_JOB_DECLINE = '@@social_media_info/GET_REPORT_JOB_DECLINE',
  GET_REPORT_JOB_DECLINE_SUCCESS = '@@social_media_info/GET_REPORT_JOB_DECLINE_SUCCESS',

  GET_ADMIN_REPORT_JOB_DECLINE = '@@social_media_info/GET_ADMIN_REPORT_JOB_DECLINE',
  GET_ADMIN_REPORT_JOB_DECLINE_SUCCESS = '@@social_media_info/GET_ADMIN_REPORT_JOB_DECLINE_SUCCESS',

  ADMIN_UPDATE_REPORT_DECLINE = '@@social_media_info/ADMIN_UPDATE_REPORT_DECLINE',
  ADMIN_UPDATE_REPORT_DECLINE_SUCCESS = '@@social_media_info/ADMIN_UPDATE_REPORT_DECLINE_SUCCESS',

  GET_SOCIAL_GLEAM_JOB_BY_KEY = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_BY_KEY',
  GET_SOCIAL_GLEAM_JOB_BY_KEY_SUCCESS = '@@social_media_info/GET_SOCIAL_GLEAM_JOB_BY_KEY_SUCCESS',
}

export default SocialMediaInfoTypes;
