import { DepositAddressesTypes, DepositAddressState } from './actionTypes';

export const INIT_STATE: DepositAddressState = {
  addresses: [],
  error: {},
  loading: false,
};

const DepositAddresses = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case DepositAddressesTypes.GET_DEPOSIT_ADDRESSES:
      return {
        ...state,
        loading: true,
      };
    case DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID:
      return {
        ...state,
        loading: true,
      };
    case DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case DepositAddressesTypes.GET_DEPOSIT_ADDRESSES_BY_UUID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default DepositAddresses;
