import { withTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import appStatus from 'src/constants/appStatus';

const AppVerifyStatus = ({ status, t }: any) =>
  status === appStatus.GETTING_READY ? (
    <Badge className="bg-success font-size-12">{t('table:GETTING_READY')}</Badge>
  ) : (
    <Badge className="bg-dark font-size-12">{t('table:REQUIRES_REVIEW')}</Badge>
  );

export default withTranslation()(AppVerifyStatus);
