/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum JobStatus {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  NEW_JOB = 'NEW_JOB',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  CANCEL = 'CANCEL',
}

export default JobStatus;
