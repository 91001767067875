import BannerFormat from 'src/constants/banner-format-Emun';
import highlightText from '../Styles/HighlightText';

const nbsp = (count: number, data: string) => (
  <>
    {[...Array(count)].map(() => (
      <>&nbsp;&nbsp;&nbsp;</>
    ))}
    {data}
    <br />
  </>
);

const FlutterContent = ({
  adKey,
  appKey,
  adType,
}: {
  adKey: string;
  appKey: string;
  adType: BannerFormat;
}) => (
  <>
    {nbsp(0, '@override')}
    {nbsp(0, 'Widget build(BuildContext context) {')}
    {nbsp(1, 'return SafeArea(')}
    {nbsp(2, 'child: Scaffold(')}
    {adType === BannerFormat.IN_PAGE ? (
      <>
        {nbsp(3, 'body: ')}
        <span style={{ display: 'block', ...highlightText() }}>
          {nbsp(4, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
        </span>
        {nbsp(2, '), // Scaffold')}
        {nbsp(1, '), // SafeArea')}
        {nbsp(0, '}')}
      </>
    ) : (
      <>
        {nbsp(3, 'body: Stack(')}
        {nbsp(3, 'children: <Widget>[')}
        {adType === BannerFormat.HEADER ? (
          <span style={{ display: 'block', ...highlightText() }}>
            {nbsp(5, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
          </span>
        ) : (
          ''
        )}
        {nbsp(4, 'Container(')}
        {nbsp(5, 'child: const FooterPage(),')}
        {nbsp(4, '),')}
        {adType === BannerFormat.SLIDE ? (
          <span>
            {nbsp(4, 'Column(')}
            {nbsp(5, 'mainAxisAlignment: MainAxisAlignment.end,')}
            {nbsp(5, 'children: [')}
            {nbsp(6, 'Row(')}
            {nbsp(7, 'mainAxisAlignment: MainAxisAlignment.end,')}
            {nbsp(7, 'children: [')}
            <span style={{ display: 'block', ...highlightText() }}>
              {nbsp(8, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
            </span>
            {nbsp(7, '],')}
            {nbsp(6, '),')}
            {nbsp(6, '// Sticky')}
            {nbsp(6, '//AFB(appKey: "example", adKey: "example")')}
            {nbsp(5, '],')}
            {nbsp(4, '), // Column')}
          </span>
        ) : (
          ''
        )}

        {adType === BannerFormat.STICKY ? (
          <span>
            {nbsp(4, 'Column(')}
            {nbsp(5, 'mainAxisAlignment: MainAxisAlignment.end,')}
            {nbsp(5, 'children: [')}
            {nbsp(6, 'Row(')}
            {nbsp(7, 'mainAxisAlignment: MainAxisAlignment.end,')}
            {nbsp(7, 'children: [')}
            {nbsp(8, '// Slide')}
            {nbsp(8, `//AFB(appKey: "example", adKey: "example")`)}
            {nbsp(7, '],')}
            {nbsp(6, '),')}
            <span style={{ display: 'block', ...highlightText() }}>
              {nbsp(6, `AFB(appKey: "${appKey}", adKey: "${adKey}")`)}
            </span>
            {nbsp(5, '],')}
            {nbsp(4, '), // Column')}
          </span>
        ) : (
          ''
        )}
        {nbsp(3, `], // <Widget>[]`)}
        {nbsp(2, '), // Stack')}
        {nbsp(1, '), // Scaffold')}
        {nbsp(1, '), // SafeArea')}
        {nbsp(1, '}')}
      </>
    )}
  </>
);
export default FlutterContent;
