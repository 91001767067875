/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum AllowNotificationMailTypes {
  GET_ALLOW_NOTIFICATION_MAIL = '@@country/GET_ALLOW_NOTIFICATION_MAIL',
  GET_ALLOW_NOTIFICATION_MAIL_SUCCESS = '@@country/GET_ALLOW_NOTIFICATION_MAIL_SUCCESS',
  GET_ALLOW_NOTIFICATION_MAIL_FAIL = '@@country/GET_ALLOW_NOTIFICATION_MAIL_FAIL',

  UPDATE_ALLOW_NOTIFICATION_MAIL = '@@country/UPDATE_ALLOW_NOTIFICATION_MAIL',
  UPDATE_ALLOW_NOTIFICATION_MAIL_SUCCESS = '@@country/UPDATE_ALLOW_NOTIFICATION_MAIL_SUCCESS',
  UPDATE_ALLOW_NOTIFICATION_MAIL_FAIL = '@@country/UPDATE_ALLOW_NOTIFICATION_MAIL_FAIL',
}

export interface AllowNotificationMailState {
  allowNotificationMails: Array<any>;
  error: Object;
}
