import { call, put, takeEvery } from 'redux-saga/effects';

import { getDefaultValueWebsite } from 'src/graphql/default-value/default-website';
import { isEmpty } from 'lodash';
import {
  getMinimumWithdrawConfigs as getMinimumWithdrawConfigsGql,
  updateConfig as updateConfigGql,
  getMinimumWithdrawConfig as getMinimumWithdrawConfigGql,
  getProfitConfigs as getProfitConfigsGql,
  getDefaultValueByKeyGql,
  getFeeWithdrawConfigsGql,
  getMinMaxBudgetConfig,
} from '../../graphql/default-value';

import { getDefaultCampaignGraphql } from '../../graphql/default-value/default-campaign';

import { DefaultValuesTypes } from './actionTypes';

import {
  getDefaultCampaignSuccess,
  getDefaultCampaignFail,
  getDefaultWebsiteSuccess,
  getDefaultWebsiteFail,
  getMinimumWithdrawConfigsSuccess,
  getMinimumWithdrawConfigsFail,
  getMinimumWithdrawConfigs,
  getMinimumWithdrawConfigSuccess,
  getMinimumWithdrawConfigFail,
  getProfitConfigsSuccess,
  getAllDefaultCampaignSuccess,
  getDefaultValueByKeySuccess,
  getFeeWithdrawConfigsSuccess,
  getFeeWithdrawConfigsFail,
  getSuggestJobBudgetConfigsSuccess,
} from './actions';

import { setLoading, unsetLoading } from '../actions';
import { Response } from '../../common/types';
import notify from '../../utils/notify';
import i18n from '../../i18n';

function* fetchDefaultCampaign({ payload }: any) {
  try {
    yield put(setLoading());

    if (payload.banner_format || payload.ad_format) {
      const response: Promise<any> = yield call(getDefaultCampaignGraphql, payload);
      yield put(getDefaultCampaignSuccess(response));
    } else {
      const allDefaultCampaigns: any = {};
      const newPayload: any = {
        device_platform: payload.device_platform,
        ad_format: 'BANNER',
        banner_format: 'IN_PAGE',
      };

      const bannerInPage: Promise<any> = yield call(
        getDefaultCampaignGraphql,
        newPayload,
      );
      allDefaultCampaigns.bannerInPage = bannerInPage;

      newPayload.banner_format = 'STICKY';
      const bannerSticky: Promise<any> = yield call(
        getDefaultCampaignGraphql,
        newPayload,
      );
      allDefaultCampaigns.bannerSticky = bannerSticky;

      if (payload.device_platform === 'DESKTOP') {
        newPayload.banner_format = 'HEADER';
        const bannerHeader: Promise<any> = yield call(
          getDefaultCampaignGraphql,
          newPayload,
        );
        allDefaultCampaigns.bannerHeader = bannerHeader;
      }

      newPayload.banner_format = 'SLIDE';
      const bannerSlide: Promise<any> = yield call(
        getDefaultCampaignGraphql,
        newPayload,
      );
      allDefaultCampaigns.bannerSlide = bannerSlide;

      newPayload.banner_format = null;
      newPayload.ad_format = 'NATIVE';

      const native: Promise<any> = yield call(getDefaultCampaignGraphql, newPayload);
      allDefaultCampaigns.native = native;

      newPayload.ad_format = 'POP';
      const pop: Promise<any> = yield call(getDefaultCampaignGraphql, newPayload);
      allDefaultCampaigns.pop = pop;

      yield put(getAllDefaultCampaignSuccess(allDefaultCampaigns));
    }
  } catch (error) {
    yield put(getDefaultCampaignFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchDefaultWebsite() {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(getDefaultValueWebsite);
    yield put(getDefaultWebsiteSuccess(response));
  } catch (error) {
    yield put(getDefaultWebsiteFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchMinimumWithdrawConfigs() {
  try {
    const response: Promise<any> = yield call(getMinimumWithdrawConfigsGql);

    yield put(getMinimumWithdrawConfigsSuccess(response));
  } catch (error) {
    // Something here
    yield put(getMinimumWithdrawConfigsFail(error));
  }
}

function* fetchFeeWithdrawConfigs() {
  try {
    const response: Promise<any> = yield call(getFeeWithdrawConfigsGql);

    yield put(getFeeWithdrawConfigsSuccess(response));
  } catch (error) {
    // Something here
    yield put(getFeeWithdrawConfigsFail(error));
  }
}

function* fetchMinimumWithdrawConfig({ payload: { symbol } }: any) {
  try {
    const response: Promise<any> = yield call(getMinimumWithdrawConfigGql, symbol);

    yield put(getMinimumWithdrawConfigSuccess(response));
  } catch (error) {
    // Something here
    yield put(getMinimumWithdrawConfigFail(error));
  }
}
function* fetchDefaultValueByKey({ key }: any) {
  try {
    const response: Promise<any> = yield call(getDefaultValueByKeyGql, key);

    yield put(getDefaultValueByKeySuccess(response));
  } catch (error) {
    // Something here
  }
}

function* updateConfig({ payload: { key, value } }: any) {
  try {
    const data = {
      key,
      value,
    };
    const response: Response = yield call(updateConfigGql, data);

    if (!isEmpty(response) && isEmpty(response.errors)) {
      yield put(getMinimumWithdrawConfigs());

      notify(i18n.t('success:Config Changed Success'), 'success');
    }
  } catch (error) {
    // Something here
  }
}

function* fetchProfitConfigs() {
  try {
    const response: Promise<any> = yield call(getProfitConfigsGql);

    yield put(getProfitConfigsSuccess(response));
  } catch (error) {
    // Something here
  }
}

function* fetchSuggestJobBudgetConfigs() {
  try {
    const response: Promise<any> = yield call(getMinMaxBudgetConfig);

    yield put(getSuggestJobBudgetConfigsSuccess(response));
  } catch (error) {
    //
  }
}

function* defaultValuesSaga() {
  yield takeEvery(DefaultValuesTypes.GET_DEFAULT_CAMPAIGN, fetchDefaultCampaign);
  yield takeEvery(DefaultValuesTypes.GET_DEFAULT_WEBSITE, fetchDefaultWebsite);
  yield takeEvery(
    DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIGS,
    fetchMinimumWithdrawConfigs,
  );
  yield takeEvery(
    DefaultValuesTypes.GET_FEE_WITHDRAW_CONFIGS,
    fetchFeeWithdrawConfigs,
  );

  yield takeEvery(DefaultValuesTypes.UPDATE_CONFIG, updateConfig);
  yield takeEvery(
    DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIG,
    fetchMinimumWithdrawConfig,
  );
  yield takeEvery(
    DefaultValuesTypes.GET_DEFAULT_VALUE_BY_KEY,
    fetchDefaultValueByKey,
  );
  yield takeEvery(DefaultValuesTypes.GET_PROFIT_CONFIGS, fetchProfitConfigs);
  yield takeEvery(
    DefaultValuesTypes.GET_SUGGEST_JOB_BUDGET_CONFIGS,
    fetchSuggestJobBudgetConfigs,
  );
}

export default defaultValuesSaga;
