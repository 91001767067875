import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from 'src/config';
import Role from 'src/constants/enum/role';
import { useHistory } from 'react-router';
import user1 from '../../../assets/images/flupower/user-influ.png';
import { roleText } from '../../../constants/role';
import './ProfileMenu.style.scss';

const ProfileMenu = () => {
  const { user, socialMediaInfo, userInfo } = useSelector((state: any) => ({
    user: state.login.user,
    socialMediaInfo: state.login.socialMediaInfoImage,
    userInfo: state.Users.userInfo,
  }));
  const { t } = useTranslation();
  const history = useHistory();
  const imageProfile = () => {
    if (!user?.image_storage) {
      return (
        <img
          src={user1}
          alt="Header Avatar"
          className={`navbar-profile-image ${
            user?.role === 'PUBLISHER' ? 'border-pic-profile' : ''
          }`}
        />
      );
    }
    if (user?.image_storage?.key) {
      return (
        <img
          src={`${config.backendUrl}api/profile/${user?.image_storage?.key}`}
          alt="Header Avatar"
          className={`navbar-profile-image ${
            user?.role === 'PUBLISHER' ? 'border-pic-profile' : ''
          }`}
        />
      );
    }
    return (
      <img
        src={user1}
        alt="Header Avatar"
        className={`navbar-profile-image ${
          user?.role === 'PUBLISHER' ? 'border-pic-profile' : ''
        }`}
      />
    );
  };

  const [menu, setMenu] = useState<boolean>(false);

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block profile-menu-container"
    >
      <DropdownToggle
        className="btn header-item"
        id="page-header-user-dropdown"
        // style={{ padding: 0, height: 'fit-content', marginRight: '20px' }}
        tag="button"
      >
        {imageProfile()}

        {/* {!userInfo?.name ? <ErrorIcon color="error" fontSize="small" /> : <></>} */}
        {user ? (
          !userInfo?.name &&
          user.role !== Role.ADMIN && (
            <div className="navbar-profile-chevron-top-container">
              <div className="navbar-profile-chevron-down">
                <div>
                  <ErrorIcon color="error" fontSize="small" />
                </div>
              </div>
            </div>
          )
        ) : (
          <></>
        )}

        <div className="navbar-profile-chevron-down-container">
          <div className="navbar-profile-chevron-down">
            <i
              className="fas fa-solid fa-chevron-down"
              style={{ color: '#202121', fontSize: 10 }}
            />
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {user ? (
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-20 align-middle me-1" />
            <span>{t('Account')}</span>
            <div style={{ float: 'right' }}>
              {!userInfo?.name && user.role !== Role.ADMIN && (
                <ErrorIcon color="error" fontSize="small" />
              )}
            </div>
          </Link>
        ) : (
          <div
            className="dropdown-item"
            onClick={() => {
              history.push('/login');
            }}
          >
            <i className="bx bx-user font-size-20 align-middle me-1" />
            <span>{t('Profile')}</span>
          </div>
        )}
        {user ? (
          <>
            {user.role === roleText.ADVERTISER && (
              <>
                <div className="dropdown-divider" />
                <Link to="/wallet" className="dropdown-item">
                  <i className="fas  fa-wallet font-size-18 align-middle me-2" />
                  <span>{t('Wallet')}</span>
                </Link>
              </>
            )}

            {user.role === roleText.PUBLISHER && (
              <>
                <div className="dropdown-divider" />
                <Link to="/wallet" className="dropdown-item">
                  <i className="fas  fa-wallet font-size-18 align-middle me-2" />
                  <span>{t('Wallet')}</span>
                </Link>
              </>
            )}

            {user.role !== roleText.ADMIN && (
              <>
                <div className="dropdown-divider" />
                <Link to="/referral" className="dropdown-item">
                  <i className="bx bx-group font-size-20 align-middle me-1" />
                  <span>{t('Invite Friends')}</span>
                </Link>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="dropdown-divider" />
        {user ? (
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-20 align-middle me-1 text-danger" />
            <span>{t('Logout')}</span>
          </Link>
        ) : (
          <div
            className="dropdown-item"
            onClick={() => {
              history.push('/login');
            }}
          >
            <i className="bx bx-power-off font-size-20 align-middle me-1 text-danger" />
            <span>{t('Logout')}</span>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(ProfileMenu);
