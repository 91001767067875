/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
const enum FormInputType {
  STRING,
  GENDER,
  IS_LGBT,
  BIRTH_DAY,
  HEADER,
  HR,
  COUNTRY,
  MARKETER_CATEGORIES,
  TEST,
  TIMEZONE,
}

export default FormInputType;
