const roleText = {
  ADVERTISER: 'ADVERTISER',
  PUBLISHER: 'PUBLISHER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  HOLD: 'Hold',
  FEE: 'Fee',
};

const roleNumeric = {
  ADVERTISER: 0,
  PUBLISHER: 1,
  ADMIN: 2,
  OWNER: 3,
  HOLD: 4,
  FEE: 5,
};

export { roleText, roleNumeric };
