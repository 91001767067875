/* eslint-disable no-unused-vars */
/**
 *  interface for Login Type
 */
// eslint-disable-next-line no-shadow
const enum LoginTypes {
  GET_SOCIAL_MEDIA_INFO_IMAGE = '@@login/GET_SOCIAL_MEDIA_INFO_IMAGE',
  GET_SOCIAL_MEDIA_INFO_IMAGE_SUCCESS = '@@login/GET_SOCIAL_MEDIA_INFO_IMAGE_SUCCESS',
  LOGIN_USER = '@@login/LOGIN_USER',
  LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS',
  LOGIN_FAILED = '@@login/LOGIN_FAILED',
  LOGOUT_USER = '@@login/LOGOUT_USER',
  LOGOUT_USER_SUCCESS = '@@login/LOGOUT_USER_SUCCESS',
  RESEND_EMAIL = '@@login/RESEND_EMAIL',
  RESEND_FAILED = '@@login/RESEND_FAILED',
  RESEND_SUCCESS = '@@login/RESEND_SUCCESS',
  CLEAR_ERROR_MESSAGE = '@@login/CLEAR_ERROR_MESSAGE',
  GET_LOGGEDIN_USER = '@@login/GET_LOGGEDIN_USER',
  GET_LOGGEDIN_USER_SUCCESS = '@@login/GET_LOGGEDIN_USER_SUCCESS',
  UPLOAD_PROFILE_SUCCESS = '@@login/UPLOAD_PROFILE_SUCCESS',
}

export default LoginTypes;
