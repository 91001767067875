import { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { transformStringAmountInput } from '../../utils/input';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const ReferralDetailModal = ({
  title,
  isOpen,
  handleToggle,
  handleClose,
  data,
  t,
}: any) => {
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied1) setCopied1(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied1]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied2) setCopied2(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied2]);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <div className="table-responsive">
          <table className="table table-nowrap mb-0">
            {data && (
              <tbody>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:ID')}:
                  </th>
                  <td>{data.uuid}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Timestamp')}:
                  </th>
                  <td>
                    <code>
                      <DateTimeComponent
                        dateTime={data.created_at}
                        dateTimeType={DateTimeTypes.TRANSACTION_DETAIL_DATE_TIME}
                      />
                    </code>
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Parent')}:
                  </th>
                  <td>{data.referral.parent.email}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Child')}:
                  </th>
                  <td>{data.referral.child.email}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Amount')}:
                  </th>
                  <td colSpan={6}>${transformStringAmountInput(data.amount)}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(ReferralDetailModal);
