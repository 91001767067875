import Role from 'src/constants/enum/role';
import LoginTypes from './actionTypes';

export const loginUser = (data: any, history: any) => {
  return {
    type: LoginTypes.LOGIN_USER,
    payload: { data, history },
  };
};

export const getSocialMediaInfoImage = (unLoading?: boolean) => ({
  type: LoginTypes.GET_SOCIAL_MEDIA_INFO_IMAGE,
  unLoading,
});

export const getSocialMediaInfoImageSuccess = (payload: any) => ({
  type: LoginTypes.GET_SOCIAL_MEDIA_INFO_IMAGE_SUCCESS,
  payload,
});

export const loginSuccess = (user: any) => {
  return {
    type: LoginTypes.LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailed = (error: any) => {
  const errorMessage = error?.message;
  const errorCode = error?.error_code;
  return {
    type: LoginTypes.LOGIN_FAILED,
    payload: { errorMessage, errorCode },
  };
};

export const logoutUser = (history: any = null) => {
  return {
    type: LoginTypes.LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LoginTypes.LOGOUT_USER_SUCCESS,
    payload: null,
  };
};

export const resendEmail = (email: string = '', role: Role) => {
  return {
    type: LoginTypes.RESEND_EMAIL,
    payload: { email, role },
  };
};

export const resendEmailFailed = (errorMessage: any, errorCode: string = '') => {
  return {
    type: LoginTypes.RESEND_FAILED,
    payload: { errorMessage, errorCode },
  };
};

export const resendEmailSuccess = () => {
  return {
    type: LoginTypes.RESEND_SUCCESS,
    payload: null,
  };
};

export const clearErrorMessage = () => {
  return {
    type: LoginTypes.CLEAR_ERROR_MESSAGE,
    payload: null,
  };
};

export const getLoggedInUser = () => {
  return {
    type: LoginTypes.GET_LOGGEDIN_USER,
    payload: null,
  };
};

export const getLoggedInUserSuccess = (user: any) => {
  return {
    type: LoginTypes.GET_LOGGEDIN_USER_SUCCESS,
    payload: user,
  };
};

export const uploadProfileSuccess = (payload: any) => ({
  type: LoginTypes.UPLOAD_PROFILE_SUCCESS,
  payload,
});
