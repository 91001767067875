import { Card } from '@mui/material';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import { useDispatch } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';
import { createAdImageSizeGraphql } from 'src/graphql/advertiser/creative';
import Swal from 'sweetalert2';
import {
  getCreativeSizes as onGetCreativeSizes,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import * as Yup from 'yup';
import SettingSizeDisplay from './SettingSizeDisplay';

const SetCreativeSize = ({ t, data, creativeType, title, device_platform }: any) => {
  const dispatch = useDispatch();

  const initialValues = {
    width: '',
    height: '',
  };

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  const numValidate = Yup.number()
    .required('Required')
    .positive('Make sure positive number');

  const AdSizeSchema = Yup.object().shape({
    width: numValidate,
    height: numValidate,
  });

  const onSubmit = async () => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t('swal:Do you want to create new size!'),
      width: 300,
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await createAdImageSizeGraphql(
          +width,
          +height,
          creativeType,
          device_platform,
        );

        dispatch(unsetLoading());
        dispatch(onGetCreativeSizes({ device_platform }));
      }
    });
  };

  return (
    <Card className="p-3" style={{ height: '352px' }}>
      <h5 className="mb-4">{t(`campaign:${title}`)}</h5>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={AdSizeSchema}
      >
        {({ errors, touched, handleChange }) => (
          <FormikForm>
            <Row className="g-0 p-2 justify-content-evenly">
              <Col sm={1} className="font-size-20 me-3 mb-2">
                <div className="avatar-sm">
                  <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-14">
                    {t('New')}
                  </span>
                </div>
              </Col>
              <Col sm={3} className="text-center">
                <FormGroup>
                  <Row>
                    <Col xs={12}>
                      <Field
                        name="width"
                        type="text"
                        className={`form-control  form-control-sm ${
                          errors.width && touched.width && 'is-invalid '
                        }`}
                        onChange={(e: any) => {
                          setWidth(e.currentTarget.value);
                          handleChange(e);
                        }}
                        placeholder={t('width')}
                      />
                      <ErrorMessage
                        name="width"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col sm={1} className="text-center font-size-20">
                x
              </Col>
              <Col sm={3} className="text-center">
                <FormGroup>
                  <Row>
                    <Col xs={12}>
                      <Field
                        name="height"
                        type="text"
                        className={`form-control  form-control-sm ${
                          errors.height && touched.height && 'is-invalid '
                        }`}
                        placeholder={t('height')}
                        onChange={(e: any) => {
                          setHeight(e.currentTarget.value);
                          handleChange(e);
                        }}
                      />
                      <ErrorMessage
                        name="height"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col sm={1} className="text-center ms-3">
                <button className="btn-invisible btn-xs" type="submit">
                  <div className="avatar-sm-2 inline-block">
                    <span className="avatar-bin bg-soft-primary rounded-circle">
                      <i className="mdi mdi-content-save font-size-28 text-success" />
                    </span>
                  </div>
                </button>
              </Col>
            </Row>
            <SettingSizeDisplay data={data} device_platform={device_platform} />
          </FormikForm>
        )}
      </Formik>
    </Card>
  );
};

export default withTranslation()(SetCreativeSize);
