import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CampaignPaymentReportTable from 'src/components/Admin/CampaignPaymentReportTable';
import CreativePaymentReportTable from 'src/components/Admin/CreativePaymentReportTable';

const CampaignReportPage = ({ t }: any) => {
  const [isAllData, setIsAllData] = useState<boolean>(true);
  const { campaign } = useSelector((state: any) => ({
    campaign: state.Campaigns.campaign,
  }));

  return (
    <div className="p-2-rem-horizontal">
      <Row lg={12}>
        <Col xs={12} md={10}>
          <Row className="g-0">
            <Card>
              <CardBody className="p-4">
                <h6 className="font-size-16 fw-normal">{t('Name')}</h6>
                <h5 className="font-size-22">{campaign.name}</h5>
                <hr />
                <Row>
                  <Col md={10}>
                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      <div>
                        <h6 className="font-size-16 fw-normal">
                          {t('Impressions served')}
                        </h6>
                        <h5 className="font-size-20">
                          {campaign.totalImpression || 0}
                        </h5>
                      </div>
                      <div>
                        <h6 className="font-size-16 fw-normal">
                          {t('Budget used')}
                        </h6>
                        <h5 className="font-size-20">
                          $
                          {campaign.totalBudget
                            ? floorToFixed(campaign.totalBudget, 2)
                            : 0}
                        </h5>
                      </div>
                      <div>
                        <h6 className="font-size-16 fw-normal">{t('Clicks')}</h6>
                        <h5 className="font-size-20">
                          {campaign.totalClick} (
                          {campaign.totalImpression
                            ? floorToFixed(
                                (campaign.totalClick / campaign.totalImpression) *
                                  100,
                                2,
                              )
                            : 0}
                          ) %
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Col>
      </Row>

      <div className="mt-4 mb-4">
        <button
          type="button"
          className={`btn outline-sm ms-2 ${isAllData ? 'active' : ''}`}
          onClick={() => setIsAllData(true)}
        >
          {t('All')}
        </button>
        <button
          type="button"
          className={`btn outline-sm mt-sm-0 ms-2 ${!isAllData ? 'active' : ''}`}
          onClick={() => setIsAllData(false)}
        >
          {t('Summary')}
        </button>
      </div>

      {isAllData ? <CreativePaymentReportTable /> : <CampaignPaymentReportTable />}
    </div>
  );
};

export default withTranslation()(CampaignReportPage);
