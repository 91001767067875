import styled from 'styled-components';
import { Modal } from 'reactstrap';

const SortStyle = () => {
  const Btn = styled.button<{
    width?: string;
    height?: string;
    borderradius?: string;
    background?: string;
  }>`
    // position: absolute;
    // object-fit: cover;
    border-radius: ${({ borderradius }) => borderradius};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: 20px auto;
    background-color: ${({ background }) => background};
    border: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    :hover {
      background-color: #0a598a;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
  `;
  const ModalStyle = styled(Modal)`
    max-width: 450px;
    width: 100%;
    @media only screen and (max-width: 599px) {
      width: 90%;
      margin: 20px auto;
    }
  `;
  const Redio = styled.input<{
    width?: string;
    margin?: string;
    background?: string;
    height?: string;
  }>`
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background: ${({ background }) => background};
    cursor: pointer;
    :disabled {
      cursor: no-drop;
    }
    @media screen and (max-width: 599px) {
      margin: auto 0 auto 10px;
      width: 25px;
      height: 25px;
    }
  `;
  const FlexRow = styled.nav<{
    width?: string;
    marginTop?: string;
    background?: string;
    height?: string;
    boxshadow?: string;
    justifycontent?: string;
    borderTop?: string;
  }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({ justifycontent }) => justifycontent};
    align-items: center;
    position: relative;
    padding-top: 10px;
    text-align: center;
    box-shadow: ${({ boxshadow }) => boxshadow};
    background: ${({ background }) => background};
    @media screen and (min-width: 500px) {
      width: ${({ width }) => width};
    }
    @media screen and (max-width: 499px) {
      width: 100%;
    }
    margin-top: ${({ marginTop }) => marginTop};

    height: ${({ height }) => height};
    border-top: ${({ borderTop }) => borderTop};
  `;
  const DivContent = styled.div`
    // overflow: auto;
    height: 100%;
    text-align: center;
    // margin-bottom: 5rem;
    // background-color: #fbfaff;
  `;

  const FlexCol = styled.div<{
    width?: string;
    // background?: string;
    height?: string;
    margin?: string;
    borderTop?: string;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 10px;
    text-align: center;
    // background: green;
    // width: 300px;
    border-top: ${({ borderTop }) => borderTop};
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  `;
  const Texts = styled.p<{
    fontsize?: string;
    // background?: string;
    height?: string;
    margin?: string;
  }>`
    font-size: ${({ fontsize }) => fontsize};
    // font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: ${({ margin }) => margin};
  `;

  const ButtonStyle = styled.button`
    font-size: 1.2em;
    // font-weight: bold;
    padding: 3px 8px;
    overflow: hidden;
    border-radius: 15px;
    border: none;
    margin: 3px;
    width: 90px;
    height: 30px;
    cursor: pointer;
    background: #f8f9f9;
    &.active {
      background: #fff;
      color: black;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    :focus {
      background: red;
      color: black;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
  `;

  const CloseBtn = styled.button`
    position: absolute;
    right: -15px;
    top: -15px;
    width: 30px;
    height: 30px;
    background: #ffff;
    text-align: center;
    // color: #333;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding-bottom: 20px;
    @media screen and (max-width: 499px) {
      width: 10%;
    }
  `;
  return {
    ModalStyle,
    DivContent,
    Texts,
    FlexRow,
    FlexCol,
    Redio,
    CloseBtn,
    ButtonStyle,
    Btn,
  };
};

export default SortStyle;
