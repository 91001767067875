import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getInfluencerPriceRate,
  getSocialMediaInfo,
  setUserProfileSelected,
} from 'src/store/actions';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import { createTempManySocialMediaInfoGql } from 'src/graphql/advertiser/socialMediaInfo';
import ContentPriceControl from 'src/components/Publisher/Influencer/ChannelDetail/ContentPrice/ContentPrice.control';

const ProfileListControl = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userProfileList$, socialMediaInfo, influencerPriceRate$ } = useSelector(
    (state: any) => ({
      userProfileList$: state.CalculateSocialMedia.userProfileList,
      socialMediaInfo: state.SocialMediaInfo.socialMediaInfo,
      influencerPriceRate$: state.SocialMediaInfo.influencerPriceRate,
    }),
  );

  const { calculatePrice } = ContentPriceControl();

  const [newUserProfileList, setNewUserProfileList] = useState(userProfileList$);
  const onSelectUserProfile = (user: UserSocialProfile) => {
    dispatch(setUserProfileSelected(user));
    history.push('/influencer/create-channel-detail');
  };

  const onCreateTempManySocialMediaInfo = async () => {
    const payload = [];
    for (let i = 0; i < newUserProfileList?.length; i++) {
      const newUer = newUserProfileList[i];
      let { social_media_type } = newUer;
      if (social_media_type?.includes('_PERSONAL')) {
        [social_media_type] = social_media_type.split('_PERSONAL');
      }
      let formValue = {
        social_media_type,
        name: newUer.name,
        follower: newUer.fan_count,
        post_engagement: 0,
        job_score: 0,
        rating: 0,
        like: 0,
        share_content_price: 0,
        photo_content_price: 0,
        video_content_price: 0,
        introduce: newUer.introduce,
        aptitude_ids: [12],
        keyword_aptitude_ids: null,
        image_profile_url: newUer.picture_url,
        access_token: newUer.access_token,
        access_token_secret: newUer?.access_token_secret,
        expire_access_token: newUer?.expire_access_token,
        is_enable_share_price: true,
        is_enable_photo_price: true,
        is_enable_video_price: true,
        social_id: newUer.id,
        profile_path: newUer.profile_path,
      };
      formValue = calculatePriceRate(formValue);
      payload.push(formValue);
    }
    await createTempManySocialMediaInfoGql(payload);
    dispatch(getSocialMediaInfo());
  };

  const calculatePriceRate = (formValue: any) => {
    const influRate = influencerPriceRate$.filter((f: any) => {
      return f.social_type === formValue.social_media_type;
    });
    if (influRate) {
      const {
        multiply,
        end_rank,
        engagement_multiply,
        share_multiply,
        post_multiply,
        video_multiply,
        power,
        offset,
      } = influRate[0];
      const SocialCalculate = calculatePrice({
        follower: formValue?.follower || 0,
        engagement: formValue?.post_engagement || 1,
        start_rank: influRate,
        multiply,
        end_rank,
        engagement_multiply,
        share_multiply,
        post_multiply,
        video_multiply,
        power,
        offset,
      });
      return {
        ...formValue,
        share_content_price: SocialCalculate.share_price,
        photo_content_price: SocialCalculate.post_price,
        video_content_price: SocialCalculate.video_price,
      };
    }
    return formValue;
  };
  // Constructor
  useEffect(() => {
    dispatch(setUserProfileSelected(null));
    dispatch(getSocialMediaInfo());
    dispatch(getInfluencerPriceRate());
  }, []);

  useEffect(() => {
    const social_ids = socialMediaInfo.map((social: any) => {
      return social.social_id;
    });

    const n_UserProfileList = [];
    for (let i = 0; i < userProfileList$?.length; i++) {
      if (!social_ids.includes(userProfileList$[i].id)) {
        n_UserProfileList.push(userProfileList$[i]);
      }
    }
    setNewUserProfileList(n_UserProfileList);
    if (n_UserProfileList?.length < 1) {
      history.replace('/influencer/dashboard');
    }
    if (n_UserProfileList?.length === 1) {
      dispatch(setUserProfileSelected(n_UserProfileList[0]));
      history.push('/influencer/create-channel-detail');
    }
  }, [socialMediaInfo]);

  return {
    onSelectUserProfile,
    onCreateTempManySocialMediaInfo,
    newUserProfileList,
  };
};

export default ProfileListControl;
