import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createJobGql,
  filterGleamJobGQL,
  filterGql,
  filterReportJobDetailGQL,
  filterReportJobGQL,
  followUpGQL,
  getAvaliableSocialForGleamJobGQL,
  getGleamJobSummaryGQL,
  getJobGql,
  getJobSummaryGql,
  getMarketerJobCountGQL,
  getSuggestJobOnHomePageGql,
  gleamJobDataForEditGQL,
  updateJobGql,
} from 'src/graphql/advertiser/job';

import { setLoading, unsetLoading } from '../actions';
import {
  filterGleamJobSuccess,
  filterJobSuccess,
  getAvaliableSocialForGleamJobSuccess,
  getGleamJobDataForEditSuccess,
  getGleamJobSummarySuccess,
  getMarketerFollowUpSuccess,
  getMarketerJobSuccess,
  getReportJobDetaiSuccess,
  getReportJobSuccess,
  getSuggestJobOnHomePageSuccess,
  marketerJobCountSuccess,
  updateMarketerJobSuccess,
} from './actions';
import MarketerJobTypes from './actionTypes';

function* createMarketerJob({ payload }: any) {
  try {
    yield put(setLoading());

    yield call(createJobGql, payload);
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* updateMarketerJob({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(updateJobGql, payload);

    yield put(updateMarketerJobSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getMarketerJob({ key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getJobGql, key);

    yield put(getMarketerJobSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getMarketerJobSummary({ key }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getJobSummaryGql, key);
    yield put(getMarketerJobSuccess(response));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(unsetLoading());
  }
}

function* filter({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(filterGql, payload);

    yield put(filterJobSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getMarketerJobCount() {
  try {
    const response: Promise<any> = yield call(getMarketerJobCountGQL);

    yield put(marketerJobCountSuccess(response));
  } catch (error) {
    //
  }
}

function* getSuggestJobsHomepage() {
  try {
    const response: Promise<any> = yield call(getSuggestJobOnHomePageGql);

    yield put(getSuggestJobOnHomePageSuccess(response));
  } catch (error) {
    //
  }
}

function* getGleamJobSummary({ key }: any) {
  try {
    const response: Promise<any> = yield call(getGleamJobSummaryGQL, key);

    yield put(getGleamJobSummarySuccess(response));
  } catch (error) {
    console.log('getGleamJobSummary', error);
  }
}

function* getAvaliableSocialForGleamJob({ key }: any) {
  try {
    const response: Promise<any> = yield call(getAvaliableSocialForGleamJobGQL, key);

    yield put(getAvaliableSocialForGleamJobSuccess(response));
  } catch (error) {
    //
  }
}

function* filterGleamJob({ payload }: any) {
  try {
    const response: Promise<any> = yield call(filterGleamJobGQL, payload);

    yield put(filterGleamJobSuccess(response));
  } catch (error) {
    console.log(error);
  }
}

function* getGleamJobDataForEdit({ key }: any) {
  try {
    const response: Promise<any> = yield call(gleamJobDataForEditGQL, key);
    yield put(getGleamJobDataForEditSuccess(response));
  } catch (error) {
    //
  }
}

function* getMarketerFollowUp({ payload }: any) {
  try {
    const response: Promise<any> = yield call(followUpGQL, payload);
    yield put(getMarketerFollowUpSuccess(response));
  } catch (error) {
    yield put(getMarketerFollowUpSuccess(null));
  }
}

function* getReportJob({ payload }: any) {
  try {
    const response: Promise<any> = yield call(filterReportJobGQL, payload);
    console.log(response);
    yield put(getReportJobSuccess(response));
  } catch (error) {
    yield put(getReportJobSuccess(null));
  }
}

function* getReportJobDetail({ payload }: any) {
  try {
    const response: Promise<any> = yield call(filterReportJobDetailGQL, payload);
    yield put(getReportJobDetaiSuccess(response));
  } catch (error) {
    yield put(getReportJobDetaiSuccess(null));
  }
}

function* marketerMarkCampaigns() {
  yield takeEvery(MarketerJobTypes.CREATE_MARKETER_JOB, createMarketerJob);
  yield takeEvery(MarketerJobTypes.UPDATE_MARKETER_JOB, updateMarketerJob);

  yield takeEvery(MarketerJobTypes.GET_MARKETER_JOB, getMarketerJob);

  yield takeEvery(MarketerJobTypes.FILTER_JOB, filter);
  yield takeEvery(MarketerJobTypes.GET_MARKETER_JOB_SUMMARY, getMarketerJobSummary);
  yield takeEvery(MarketerJobTypes.MARKETER_JOB_COUNT, getMarketerJobCount);
  yield takeEvery(MarketerJobTypes.SUGGEST_JOBS_HOMEPAGE, getSuggestJobsHomepage);
  yield takeEvery(MarketerJobTypes.GET_GLEAM_JOB_SUMMARY, getGleamJobSummary);
  yield takeEvery(
    MarketerJobTypes.GET_AVALIABLE_SOCIAL_FOR_GLEAM_JOB,
    getAvaliableSocialForGleamJob,
  );
  yield takeEvery(MarketerJobTypes.FILTER_GLEAM_JOB, filterGleamJob);
  yield takeEvery(
    MarketerJobTypes.GET_GLEAM_JOB_DATA_FOR_EDIT,
    getGleamJobDataForEdit,
  );
  yield takeEvery(MarketerJobTypes.GET_MARKETER_FOLLOW_UP, getMarketerFollowUp);

  yield takeEvery(MarketerJobTypes.GET_REPORT_JOB, getReportJob);
  yield takeEvery(MarketerJobTypes.GET_REPORT_JOB_DETAIL, getReportJobDetail);
}

export default marketerMarkCampaigns;
