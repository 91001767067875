import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { OnLoading } from 'src/components/Common/Loading';
import authByThirdParty from './authByThirdParty';

const authByGoogle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  const socialProcess = async () => {
    const state: string | null = params.get('state');
    const stateData = state?.split('-ref');
    const role: string = stateData ? stateData[0] : '';
    const ref: string = stateData ? stateData[1] : '';
    const registerData = {
      role,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ref,
    };

    const email: string | null = params.get('email');

    if (registerData.role && email) {
      await authByThirdParty({
        data: registerData,
        email,
        dispatch,
        history,
        loginType: 'GOOGLE',
      });
    }
  };

  useEffect(() => {
    socialProcess();
  }, []);

  return <OnLoading />;
};

export default authByGoogle;
