import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCreativeType as onGetCreativeType } from 'src/store/creativeType/actions';
import { useParams } from 'react-router';
import { updateWebsiteDisableCreativeType } from 'src/graphql';
import CreativeType from '../../Advertiser/CampaignSettings/CreativeType';

const WebsiteTypeSetting = ({ t }: any) => {
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const [itemSelected, setItemSelected] = useState<any>([]);

  const { WebsiteInfo, creativeType } = useSelector((state: any) => ({
    WebsiteInfo: state.WebsiteInfo.websiteInfo,
    creativeType: state.CreativeType.creativeType,
  }));

  const WEBSITE_TYPES = creativeType.map((type: any) => {
    return {
      value: type.name,
      label: type.name,
      id: type.id,
    };
  });

  const onSubmit = () => {
    const typeDataIndex = [];
    for (let i = 0; i < itemSelected?.length; i++) {
      typeDataIndex.push(itemSelected[i].id);
    }

    updateWebsiteDisableCreativeType(+id, typeDataIndex);
  };

  useEffect(() => {
    dispatch(onGetCreativeType());

    if (WebsiteInfo.website_types) {
      let originalType: any[] = [];
      for (let i = 0; i < WebsiteInfo.website_types?.length; i++) {
        originalType = [
          ...originalType,
          {
            label: WebsiteInfo.website_types[i].name,
            value: WebsiteInfo.website_types[i].name,
            id: WebsiteInfo.website_types[i].id,
          },
        ];
      }
      setItemSelected(originalType);
    }
  }, [WebsiteInfo]);

  return (
    <div className="disable-category" style={{ height: 400 }}>
      <Col className="mb-4">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <label
            htmlFor="choices-multiple-remove-button"
            className="form-label font-size-16 text-muted"
          >
            {t('DisableAds')}
          </label>
        </div>
        <CreativeType
          fromSetup
          setTypeData={setItemSelected}
          typeData={itemSelected}
          typeOption={WEBSITE_TYPES}
        />
      </Col>
      <button type="submit" className="btn btn-primary" onClick={() => onSubmit()}>
        {t('button:Submit')}
      </button>
    </div>
  );
};

export default withTranslation()(WebsiteTypeSetting);
