import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaFilterTable } from 'src/store/actions';
import control from './Sort.style';

const { ModalStyle, DivContent, Texts, FlexRow, FlexCol, Redio, CloseBtn, Btn } =
  control();

const ButtonStyle = styled.button`
  font-size: 1.2em;
  // font-weight: bold;
  padding: 3px 8px;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  margin: 3px;
  width: 90px;
  height: 30px;
  cursor: pointer;
  background: #a7b6b6;
  &.active {
    background: #fff;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  :focus {
    background: #fff;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
`;
const Sort = (props: any) => {
  const { t } = useTranslation();
  const userCountValue: number[] = [10, 20, 30];

  const { isOpen, toggle } = props;

  const [valueRadio, setValueRadio] = useState('Follow');

  const [showUserCount, setShowUserCount] = useState(userCountValue[0]);
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));
  const onChangeValue = (event: any) => {
    setValueRadio(event.target.value);
  };
  return (
    <ModalStyle
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="sm"
      // style={{ maxWidth: '450px', width: '80%' }}
    >
      <DivContent>
        <CloseBtn type="button" onClick={toggle}>
          x
        </CloseBtn>
        <Texts fontsize="1.5rem" margin="10px auto">
          {t('marketer:create_job_campaign:Sort_by')}
        </Texts>

        <FlexCol margin="0" width="100%" onChange={(e) => onChangeValue(e)}>
          <FlexRow
            borderTop="1px solid black"
            width="95%"
            justifycontent="flex-stat"
          >
            <FlexRow
              justifycontent="center"
              style={{ margin: '0 30px', top: '-10px' }}
              // background="red"
            >
              <Redio
                type="radio"
                value="recommend"
                name="gender"
                width="20px"
                height="20px"
                margin="0 auto 5px auto"
                defaultValue={valueRadio}
                onChange={() => {
                  setForm({ ...form, sort_by: null });
                }}
              />{' '}
              <Texts margin="0 10px" fontsize="1rem">
                {t('marketer:create_job_campaign:Influencers recommend')}
              </Texts>
            </FlexRow>
          </FlexRow>
          <FlexRow
            borderTop="1px solid black"
            width="95%"
            justifycontent="flex-stat"
          >
            <FlexRow
              justifycontent="center"
              style={{ margin: '0 30px', top: '-10px' }}
              // background="red"
            >
              <Redio
                type="radio"
                value="rating"
                name="rating"
                width="20px"
                height="20px"
                margin="0 auto 5px auto"
                defaultValue={valueRadio}
                onChange={() => {
                  setForm({ ...form, sort_by: null });
                }}
              />{' '}
              <Texts margin="0 10px" fontsize="1rem">
                {t('marketer:create_job_campaign:Influencer rating')}
              </Texts>
            </FlexRow>
          </FlexRow>
          <FlexRow
            borderTop="1px solid black"
            width="95%"
            justifycontent="flex-stat"
            style={{
              margin: '0 auto 10px auto',
              borderBottom: '1px solid black',
            }}
          >
            <FlexRow
              justifycontent="center"
              style={{ margin: '0 30px', top: '-10px' }}
              // background="red"
            >
              <Redio
                type="radio"
                value="follower"
                name="gender"
                width="20px"
                height="20px"
                margin="0 auto 5px auto"
                defaultValue={valueRadio}
                onChange={(e) => {
                  setForm({ ...form, sort_by: e.target.value });
                }}
              />{' '}
              <Texts margin="0 10px" fontsize="1rem">
                {t(
                  'marketer:create_job_campaign:Influencers with a lot of followers',
                )}
              </Texts>
            </FlexRow>
          </FlexRow>
          <Texts margin="5px 10px" fontsize="1rem">
            {t('marketer:create_job_campaign:Number of display')}
          </Texts>
          <FlexRow width="95%" justifycontent="center" marginTop="-20px">
            <FlexRow
              width="75%"
              background="#A7B6B6"
              justifycontent="center"
              style={{ margin: '10px auto', borderRadius: '10px', padding: '10px' }}
              id="myDIV"
            >
              {userCountValue.map((value: number) => (
                <ButtonStyle
                  className={showUserCount === value ? 'btn active' : 'btn'}
                  type="button"
                  key={value}
                  onClick={() => {
                    setShowUserCount(value);
                    setForm({ ...form, limit: value });
                  }}
                >
                  {value}
                </ButtonStyle>
              ))}
            </FlexRow>
          </FlexRow>
        </FlexCol>
        <FlexCol>
          <Btn
            type="submit"
            width="150px"
            height="40px"
            borderradius="10px"
            background="#3DB5FF"
            onClick={(e: any) => {
              e.preventDefault();
              const submitForm = {
                ...form,
                aptitude_ids: [],
                social_media_type: marketerJob.social_media_type,
                content_type: marketerJob.content_type,
              };

              dispatch(socialMediaFilterTable(submitForm));
              toggle();
            }}
          >
            {t('marketer:create_job_campaign:Submit')}
          </Btn>
        </FlexCol>
      </DivContent>
    </ModalStyle>
  );
};
export default Sort;
