import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  NavItem,
  Row,
  NavLink,
} from 'reactstrap';
import AddCampaignWidget from 'src/components/Advertiser/Widgets/AddCampaignWidget';
import CampaignSummaryWidget from 'src/components/Advertiser/Widgets/CampaignSummaryWidget';
import CampaignTotalWidget from 'src/components/Advertiser/Widgets/CampaignTotalWidget';
import PerformanceSummary from 'src/components/Advertiser/Widgets/PerformanceSummary';
import WalletWidget from 'src/components/Common/WalletWidget';
import addSocial from 'src/assets/images/other/add-social.svg';
import './jobNew.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import { Button, Tooltip } from '@mui/material';
import Wallet from 'src/assets/images/wallet.png';
import influ from 'src/assets/images/influencer-2.jpg';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearMarketerJob,
  filterGleamJob,
  getDefaultWebsite,
  getLoggedInUser,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import { OnLoading } from 'src/components/Common/Loading';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import config from 'src/config';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import Pagination from '@mui/material/Pagination';
import SearchBar from 'src/components/Table/SearchBar';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { filterGleamJobGQL } from 'src/graphql/advertiser/job';
import JobNewTable from './JobNewTable';

const JobNew = () => {
  const userName = 'Tinnaphob';
  const money = 10;
  const walletSymbol = 'USDT';
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [marketerJob, setMarketerJob] = useState<any>(null);
  const [tab, setTab] = useState(1);
  const [activeTab, toggleTab] = useState(1);
  const [data, setData] = useState<any>({});
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'INPROGRESS',
  });

  const { _data, defaultTax } = useSelector((state: any) => ({
    _data: state.MarketerJob.filter,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
  }));
  const tax = defaultTax / 100 + 1;

  const fetchGleamJob = async (payload: any) => {
    try {
      dispatch(setLoading());
      const response = await filterGleamJobGQL(payload);
      setData(response);
    } catch (error) {
      setData({});
    } finally {
      dispatch(unsetLoading());
    }
  };

  useEffect(() => {
    // dispatch(getLoggedInUser());
    //dispatch(filterGleamJob(param));
    fetchGleamJob(param);
    dispatch(getDefaultWebsite());
  }, []);

  useEffect(() => {
    if (data?.statusCount) {
      console.log('status count');
      let allStatusCount = 0;
      for (let i = 0; i < data?.statusCount?.length; i++) {
        allStatusCount += data?.statusCount[i]?.count ?? 0;
      }

      setMarketerJob(data);

      return;
    }
    setMarketerJob(data);
  }, [data]);

  // if (marketerJob?.items) {
  //   if (Object.keys(marketerJob.items).length) {
  //     for (let j = 0; j < marketerJob.items.length; j++) {
  //       let num_influencer = 0;
  //       let num_follower = 0;
  //       let total_price = 0;
  //       const job = marketerJob.items[j];
  //       const keys: any[] = [];
  //       num_influencer += job.social_media_has_jobs?.length || 0;
  //       for (let k = 0; k < job.social_media_has_jobs?.length; k++) {
  //         const socialJob = job.social_media_has_jobs[k];
  //         if (!keys.includes(socialJob.social_media_info.key)) {
  //           keys.push(socialJob.social_media_info.key);
  //           num_follower += socialJob.social_media_info.follower || 0;
  //         }
  //         total_price += socialJob.price || 0;
  //       }
  //       job.num_influencer = num_influencer;
  //       job.num_follower = num_follower;
  //       job.total_price = total_price * tax;
  //     }
  //   }
  // }

  const handlePageSizeChange = async (newPageSize: string) => {
    const payload = {
      ...param,
      limit: +newPageSize,
      page: 1,
    };
    // dispatch(filterSocialJob(payload));
    //dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const handlePageChange = async (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    // dispatch(filterSocialJob(payload));
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const handleSearch = async (searchInput: string) => {
    const payload = {
      ...param,
      search: searchInput,
    };
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const handleClear = async () => {
    const payload = {
      ...param,
      search: '',
    };
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const inprogress = async () => {
    const payload = {
      ...param,
      status: 'INPROGRESS',
      page: 1,
    };
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };
  const draft = async () => {
    const payload = {
      ...param,
      status: 'DRAFT',
      page: 1,
    };
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };
  const finished = async () => {
    const payload = {
      ...param,
      status: 'FINISHED',
      page: 1,
    };
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };
  const cancel = async () => {
    const payload = {
      ...param,
      status: 'CANCEL',
      page: 1,
    };
    // dispatch(filterGleamJob(payload));
    await fetchGleamJob(payload);
    setParam(payload);
  };

  const renderTabs = () => {
    return (
      <div className="nav-scrollbar-none nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ minWidth: '120px' }}
            onClick={() => {
              toggleTab(1);
              setTab(1);
              inprogress();
            }}
            className={`${tab === 1 && 'active'}`}
          >
            <div style={{ display: 'flex', fontSize: '', justifyContent: 'center' }}>
              <h6>{t('StatusCampaign.Inprogess')}</h6>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ minWidth: '100px' }}
            className={`${tab === 2 && 'active'}`}
            onClick={() => {
              toggleTab(2);
              setTab(2);
              draft();
            }}
          >
            <div style={{ display: 'flex', fontSize: '', justifyContent: 'center' }}>
              <h6>{t('StatusCampaign.Draft')}</h6>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ minWidth: '100px' }}
            className={`${tab === 3 && 'active'}`}
            onClick={() => {
              toggleTab(3);
              setTab(3);
              finished();
            }}
          >
            <div style={{ display: 'flex', fontSize: '', justifyContent: 'center' }}>
              <h6>{t('StatusCampaign.Finished')}</h6>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ width: '100px' }}
            className={`${tab === 4 && 'active'}`}
            onClick={() => {
              toggleTab(4);
              setTab(4);
              cancel();
            }}
          >
            <div style={{ display: 'flex', fontSize: '', justifyContent: 'center' }}>
              <h6>{t('StatusCampaign.Cancel')}</h6>
            </div>
          </NavLink>
        </NavItem>
      </div>
    );
  };

  const rendorVerifyStatus = (
    status: string,
    verify_status: string,
    report_status: string,
    ban_reason: string,
  ) => {
    if (status === 'FINISHED' || status === 'CANCEL') {
      if (report_status === 'BAN') {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <>{t('BAN')}</>
            <Tooltip
              title={
                <h4 style={{ color: 'white' }}>{ban_reason ?? 'no reason'} </h4>
              }
              placement="right"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16]"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>
        );
      } else {
        return t(verify_status);
      }
    } else {
      return t(verify_status);
    }
  };

  if (marketerJob == null) return <OnLoading />;
  return (
    <div className="page-content" style={{ paddingTop: '4vh' }}>
      <div className="dashboard-wraper">
        <Breadcrumb
          pageTitle={t('Campaign')}
          menus={[
            { menu: menu.HOME, active: true },
            {
              menu: menu.CAMPAIGN,
              isSamePage: true,
            },
          ]}
        />
        <MetaTags>
          <title>Campaign</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <div style={{ backgroundColor: 'white' }}>
                  <CardBody>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 10px',
                        backgroundColor: 'white',
                      }}
                    >
                      {renderTabs()}
                      <PageSizeOptions
                        defaultValue={10}
                        handlePageSizeChange={handlePageSizeChange}
                        colors="black"
                      />
                      <SearchBar
                        placeholder={t('table:Search Name')}
                        handleSearch={handleSearch}
                        handleClear={handleClear}
                      />
                    </div>
                  </CardBody>
                </div>
                <CardBody>
                  <div
                    className="add_gleam-job-container"
                    style={{ marginTop: '5px' }}
                  >
                    <a
                      onClick={() => {
                        dispatch(clearMarketerJob());
                        localStorage.removeItem('descriptionJobNew');
                      }}
                      href="/campaigns/jobnew/addnewjob"
                    >
                      <div className="add-button">
                        {/* <img width={50} height={50} src={addSocial} alt="add-social" /> */}
                        <div className="menu-add-button">
                          <span> + {t('Menus.Create Activity')}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div style={{ width: '100%' }}>
                    <JobNewTable activeTab={activeTab} data={data} />
                  </div>
                  <Pagination
                    count={Math.ceil(marketerJob?.totalCount / param.limit)}
                    page={param.page}
                    color="standard"
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    style={{
                      width: 'fit-content',
                      margin: 'auto',
                      marginTop: '30px',
                    }}
                    boundaryCount={2}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default JobNew;
