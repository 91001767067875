import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import controls from './ProfileList.control';

const ProfileListComponent = () => {
  const history = useHistory();
  const {
    onSelectUserProfile,
    onCreateTempManySocialMediaInfo,
    newUserProfileList,
  } = controls();
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          maxWidth: '50rem',
          width: '100%',
          height: '100%',
          maxHeight: '50rem',
          background: '#ffffff',
          padding: '2rem',
          borderRadius: '5px',
          textAlign: 'center',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        }}
      >
        <h3 style={{ textAlign: 'center', marginBottom: '1rem' }}>
          {t('Submit your social media ')}
        </h3>
        <div className="row gap-2 justify-content-center">
          {newUserProfileList.map((user: UserSocialProfile) => (
            <div
              key={user.id}
              className="col-12 col-sm-6 col-lg-4"
              style={{
                position: 'relative',
                width: '200px',
                minHeight: '230px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                borderRadius: '5px',
                padding: '1rem',
              }}
            >
              <img
                src={user.picture_url}
                alt="Profile User"
                style={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                  border: '1px solid #ffffff',
                }}
              />
              <h4
                className="mt-4"
                style={{
                  width: '100%',
                  height: '1.2rem',
                }}
              >
                {user.name}
              </h4>

              <div style={{ position: 'absolute', bottom: '10px' }}>
                <button
                  type="button"
                  style={{
                    marginTop: '3rem',
                    cursor: 'pointer',
                    borderRadius: '3rem',
                    padding: '0 0.5rem',
                    width: 'auto',
                    height: 'auto',
                    border: 'solid 3px transparent',
                    backgroundImage:
                      'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa)',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'content-box, border-box',
                    boxShadow:
                      '2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                  }}
                  onClick={() => onSelectUserProfile(user)}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      background: '#1c8326',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('Submit')}
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          style={{
            marginTop: '3rem',
            cursor: 'pointer',
            borderRadius: '3rem',
            padding: '0 0.5rem',
            background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
            width: 'auto',
            height: 'auto',
            border: 'solid 3px transparent',
            boxShadow:
              '2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
          }}
          onClick={() => {
            onCreateTempManySocialMediaInfo();
            history.push('/influencer/dashboard');
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Back to Dashboard
          </span>
        </button>
      </div>
    </div>
  );
};

export default ProfileListComponent;
