import i18n from 'src/i18n';
import NavData from '../type/navData.type';

const advertiserNavData: NavData = {
  role: 'Advertiser',
  title: 'Create advertisements on the website or mobile',
  items: [
    {
      label: i18n.t('marketer:create_campaign.Dashboard'),
      description: i18n.t(
        'marketer:create_campaign.Summary wallet and campaign data',
      ),
      path: '/campaigns/dashboard',
      icon: <i className="fas fa-tachometer-alt" />,
    },
    {
      label: i18n.t('marketer:create_campaign.Create Campaign'),
      description: i18n.t('marketer:create_campaign.Create ads and upload'),
      path: '/campaign/create',
      icon: <i className="fas fa-solid fa-folder-plus" />,
    },
    {
      label: i18n.t('marketer:create_campaign.Campaign'),
      description: i18n.t('marketer:create_campaign.Manage ads'),
      path: '/campaigns',
      icon: <i className="fas fa-solid fa-folder" />,
    },
    {
      label: i18n.t('marketer:create_campaign.Gallery'),
      description: i18n.t(
        'marketer:create_campaign.All of the ads photos that you upload',
      ),
      path: '/gallery',
      icon: <i className="fas fa-solid fa-images" />,
    },
  ],
};

export default advertiserNavData;
