import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getFilterCurrency } from 'src/store/actions';
import MetaTags from 'react-meta-tags';
import { useHistory } from 'react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import CoinImage from '../Common/CoinImage';

const CurrencyTransactionPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { currency } = useSelector((state: any) => ({
    currency: state.Currencies.filterCurrencies,
  }));
  // const [verifyStatus, setVerifyStatus] = useState(Status || null);
  const [param, setParam] = useState<any>({
    page: 1,
    limit: 10,
  });

  const handleChangePageSize = (newPagesize: string) => {
    const payload = { ...param, limit: +newPagesize };
    dispatch(getFilterCurrency(payload));
    setParam(payload);
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : 0;
    const payload = {
      ...param,
      page: newPage,
    };

    dispatch(getFilterCurrency(payload));
    setParam(payload);
  };
  const handleSearch = (keyword: string) => {
    const payload = { ...param, name: keyword };

    dispatch(getFilterCurrency(payload));
    setParam(payload);
  };
  const handleClear = () => {
    const payload = { ...param, name: '', page: 1, limit: '' };

    dispatch(getFilterCurrency(payload));
    setParam(payload);
  };

  useEffect(() => {
    // dispatch(getCurrencies());
    dispatch(getFilterCurrency(param));
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: t('ID'),
        accessor: ({ id }: any) => <div>{id}</div>,
        id: 'id',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Token Name'),
        accessor: ({ name }: any) => <div>{name}</div>,
        id: 'name',
        width: 50,
        disableSort: true,
      },

      {
        Header: t('Token Symbol'),
        accessor: ({ symbol }: any) => <div>{symbol}</div>,
        id: 'symbol',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Main Symbol'),
        accessor: ({ main_symbol }: any) => <div>{main_symbol}</div>,
        id: 'main_symbol',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Contract Address'),
        accessor: ({ contract_address }: any) => <div>{contract_address}</div>,
        id: 'contract_address',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Network'),
        accessor: ({ network }: any) => <div>{network}</div>,
        id: 'Network',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Price in USDT'),
        accessor: ({ price_in_USDT, is_marketer_currency, rate_in_USD }: any) => (
          <div>{is_marketer_currency ? price_in_USDT : rate_in_USD}</div>
        ),
        id: 'price_in_USDT',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Owner'),
        accessor: ({ owner }: any) => {
          return (
            owner && (
              <a
                className="cursor  fw-bold view-profile"
                href={`users/${owner?.uuid}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/admin/users/${owner?.uuid}`);
                }}
              >
                {t(`table:user detail`)}
              </a>
            )
          );
        },
        id: 'user_detail',
        disableSort: true,
      },
      {
        Header: t('Verify Status'),
        accessor: ({ is_available }: any) => (
          <div>{is_available ? 'Available' : 'Not Available'}</div>
        ),
        id: 'is_available',
        width: 50,
        disableSort: true,
      },
    ];
  }, [t, param.page, param.limit, param, currency]);

  const data = React.useMemo(() => {
    return !isEmpty(currency) ? currency.items : [];
  }, [currency]);
  return (
    <div>
      <MetaTags>
        <title>{t('Currency Approval')}</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card className="h-100">
              <CardBody className="h-100">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handleChangePageSize}
                    colors="white"
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                {/*dsdw */}
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={data || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={currency?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CurrencyTransactionPage;
