/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { getReportJobDetail } from 'src/store/actions';
import { useParams } from 'react-router';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';

const ReportJobDetailTransactionPage = ({ t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const { key }: any = useParams();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [param, setParam] = useState<any>({
    job_key: key,
    limit: 10,
    page: 1,
    reportType: null,
  });

  useEffect(() => {
    setParam({ ...param, job_key: key });
    dispatch(getReportJobDetail({ ...param, job_key: key }));
  }, [key]);

  const { txn } = useSelector((state: any) => ({
    txn: state.MarketerJob.reportJobDetail,
  }));

  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('marketer:create_campaign:No'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 40,
        disableSort: true,
      },
      {
        Header: t('table:Reporter Email'),
        accessor: ({ user }) => {
          return (
            <a href={`/admin/users/${user.uuid}`} target="blank">
              {user.email}
            </a>
          );
        },
        id: 'user_email',
        disableSort: true,
      },
      {
        Header: t('table:Report Type'),
        accessor: ({ reportType }: any) => {
          return t(reportType);
        },
        id: 'reporterType',
        disableSort: true,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(1),
      page: newPage,
    };

    dispatch(getReportJobDetail(payload));
    setPage(newPage);
    setParam(payload);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getReportJobDetail(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  if (!txn) return <></>;

  return (
    <>
      <h3 className="text-white">
        {txn.items ? txn?.items[0]?.job?.name : 'Job Name'}
      </h3>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            colors="white"
          />
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(ReportJobDetailTransactionPage);
