import i18n from 'src/i18n';
import NavData from '../type/navData.type';

const marketerNavData: NavData = {
  role: 'Marketer',
  title: 'Create campaign and job to deal with influencer',
  items: [
    {
      label: i18n.t('Menus.Invite-Only Campaign'),
      description: i18n.t('marketer:create_campaign.Manage and tracking campaign'),
      path: '/marketer/campaign',
      icon: <i className="fas fa-solid fa-folder" />,
    },
    {
      label: i18n.t('Menus.Suggested Campaign'),
      description: i18n.t(
        'marketer:create_campaign.Create campaign of job by product category',
      ),
      path: '/marketer/PublicCampaign',
      icon: <i className="fas fa-solid fa-folder-plus" />,
    },
  ],
};

export default marketerNavData;
