import { call, put, takeEvery } from 'redux-saga/effects';

import {
  filterApplicationCreativeApplicationKey,
  GetApplicationByKey,
  getApplicationFilterByUser,
  getApplicationGraphDatum,
  getFilterApplicationDatumTable,
  getPublisherEarningTransaction,
} from 'src/graphql/publisher/application';
import { ApplicationsTypes } from './actionTypes';

import { setLoading, unsetLoading } from '../actions';
import {
  getApplicationByKeyFail,
  getApplicationByKeySuccess,
  getApplicationCreativeFilterSuccess,
  getApplicationGraphDatumFail,
  getApplicationGraphDatumSuccess,
  getApplicationsFilterSuccess,
  getFilterApplicationDatumFail,
  getFilterApplicationDatumSuccess,
  getPublisherEarningTransactionFail,
  getPublisherEarningTransactionSuccess,
} from './actions';
import {
  setApplicationsLoading,
  setGraphLoading,
  unsetGraphLoading,
  unsetApplicationsLoading,
  setApplicationCreativeFilterLoading,
  unsetApplicationCreativeFilterLoading,
} from '../global/actions';

function* getApplicationsFilter({ payload }: any) {
  try {
    yield put(setApplicationsLoading());
    const response: Promise<any> = yield call(getApplicationFilterByUser, payload);
    yield put(getApplicationsFilterSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetApplicationsLoading());
  }
}

function* getApplicationByKey({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(GetApplicationByKey, payload);
    yield put(getApplicationByKeySuccess(response));
  } catch (error) {
    // something here
    yield put(getApplicationByKeyFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* getApplicationCreativeFilter({ payload }: any) {
  try {
    yield put(setApplicationCreativeFilterLoading());
    const response: Promise<any> = yield call(
      filterApplicationCreativeApplicationKey,
      payload,
    );
    yield put(getApplicationCreativeFilterSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetApplicationCreativeFilterLoading());
  }
}

// function* getApplicationReport({
//     payload: { limit, page, website_id, sortBy, sortType },
// }: any) {
//     try {
//         const response: Promise<any> = yield call(
//             getApplicationReport,
//             limit,
//             page,
//             website_id,
//             sortBy,
//             sortType,
//         );
//         yield put(getApplicationReportSuccess(response));
//     } catch (error) {
//         yield put(getApplicationReportFail(error));
//     }
// }

function* fetchApplicationGraphDatum({ payload }: any) {
  try {
    yield put(setGraphLoading());
    const response: Promise<any> = yield call(getApplicationGraphDatum, payload);

    yield put(getApplicationGraphDatumSuccess(response));
  } catch (error) {
    yield put(getApplicationGraphDatumFail(error));
  } finally {
    yield put(unsetGraphLoading());
  }
}

function* fetchFilterApplicationDatum({
  payload: { application_key, limit, page, search, sortBy, sortType },
}: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      getFilterApplicationDatumTable,
      application_key,
      search,
      limit,
      page,
      sortBy,
      sortType,
    );

    yield put(getFilterApplicationDatumSuccess(response));
  } catch (error) {
    yield put(getFilterApplicationDatumFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchPublisherEarningTransaction({
  payload: { application_key, limit, page, sortBy, sortType },
}: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(
      getPublisherEarningTransaction,
      application_key,
      limit,
      page,
      sortBy,
      sortType,
    );

    yield put(getPublisherEarningTransactionSuccess(response));
  } catch (error) {
    yield put(getPublisherEarningTransactionFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* applications() {
  yield takeEvery(ApplicationsTypes.GET_APPLICATIONS_FILTER, getApplicationsFilter);
  yield takeEvery(ApplicationsTypes.GET_APPLICATION_BY_KEY, getApplicationByKey);
  yield takeEvery(
    ApplicationsTypes.GET_APPLICATION_CREATIVE_FILTER,
    getApplicationCreativeFilter,
  );
  // yield takeEvery(ApplicationsTypes.GET_APPLICATION_REPORT, getApplicationReport);
  yield takeEvery(
    ApplicationsTypes.GET_APPLICATION_GRAPH_DATUM,
    fetchApplicationGraphDatum,
  );
  yield takeEvery(
    ApplicationsTypes.GET_FILTER_APPLICATION_DATUM_TABLE,
    fetchFilterApplicationDatum,
  );
  yield takeEvery(
    ApplicationsTypes.GET_PUBLISHER_EARNING_TRANSACTION,
    fetchPublisherEarningTransaction,
  );
}

export default applications;
