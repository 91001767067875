/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Aptitude, KeywordAptitude } from 'src/store/socialMediaInfo/reducer';

// eslint-disable-next-line no-shadow
export enum SocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  INSTAGRAM_PERSONAL = 'INSTAGRAM_PERSONAL',
  FACEBOOK_PERSONAL = 'FACEBOOK_PERSONAL',
  NOSOCIAL = 'NOSOCIAL',
}

export interface SocialCard {
  key: number;
  social_media_type: SocialMediaType;
  name: string;
  follower: number;
  post_engagement: number;
  job_score: number;
  rating: number;
  like: number;
  share_content_price: number;
  photo_content_price: number;
  video_content_price: number;
  introduce: string;
  aptitudes: Aptitude[];
  keyword_aptitudes: KeywordAptitude[];
  image_profile_url: string;
  verify_status: string;
}

const SocialChannelControl = () => {
  const [socialCards, setSocialCards] = useState<SocialCard[]>([]);

  return { socialCards, setSocialCards };
};

export default SocialChannelControl;
