import TwoFactorTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const generateTwoFactorSecret = () => {
  return {
    type: TwoFactorTypes.GET_TWO_FACTOR_SECRET,
    payload: null,
  };
};

export const generateTwoFactorSecretSuccess = (data: any) => {
  return {
    type: TwoFactorTypes.GET_TWO_FACTOR_SECRET_SUCCESS,
    payload: data,
  };
};

export const enableTwoFactor = (code: string, secret: string) => {
  return {
    type: TwoFactorTypes.ENABLE_TWO_FACTOR,
    payload: { code, secret },
  };
};

export const disableTwoFactorRequest = (code: string) => {
  return {
    type: TwoFactorTypes.DISABLE_TWO_FACTOR_REQUEST,
    payload: { code },
  };
};

export const getTwoFactorStatus = () => {
  return {
    type: TwoFactorTypes.GET_TWO_FACTOR_STATUS,
    payload: null,
  };
};

export const getTwoFactorStatusSuccess = (
  enableStatus: boolean,
  requestDisableStatus: boolean,
) => {
  return {
    type: TwoFactorTypes.GET_TWO_FACTOR_STATUS_SUCCESS,
    payload: { enableStatus, requestDisableStatus },
  };
};

export const disableTwoFactorConfirm = (data: any) => {
  return {
    type: TwoFactorTypes.DISABLE_TWO_FACTOR_CONFIRM,
    payload: { data },
  };
};
