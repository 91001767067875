import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import menu from 'src/constants/menu';
import { CardBody, Col, Container, Row, Card, CardHeader } from 'reactstrap';
import config from 'src/config';
import AdUnit from 'src/components/Publisher/adUnit';
import ApplicationInfoNavbar from 'src/components/Publisher/ApplicationInfoNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getApplicationByKey,
  getApplicationGraphDatum,
  getEarningTransaction,
} from 'src/store/actions';
import ApplicationReportChart from 'src/components/Publisher/Application/ApplicationReports/ApplicationReportChart';
import { ApplicationCardInfo } from 'src/components/Publisher/Application/ApplicationCardInfo';
import ApplicationReports from 'src/components/Publisher/Application/ApplicationReports/ReportsTable/ApplicationReports';
import EarningHistory from 'src/components/Publisher/EarningHistory';
import Breadcrumb from '../../../components/Common/Breadcrumb';

const ApplicationInfo = ({ t }: any) => {
  //* Params
  const { id }: { id: string } = useParams();

  //* Variables
  const dispatch = useDispatch();
  const [headerPillsTab, setHeaderPillsTab] = useState(1);
  const [datumReportParam, setDatumReportParam] = useState({
    application_key: id,
    limit: 10,
    page: 1,
    log_range: 'DAILY',
    shortcut_range: 'THISMONTH',
  });

  //* Selectors
  const { application, applicationGraphDatum } = useSelector(
    (state: any) => state.Application,
  );
  const { earningTransaction } = useSelector((state: any) => ({
    earningTransaction: state.WebsiteInfo.earningTransaction,
  }));

  useEffect(() => {
    if (headerPillsTab === 3) {
      const payload: any = {
        ...datumReportParam,
        log_range: 'DAILY',
        shortcut_range: 'THISMONTH',
      };
      dispatch(getApplicationGraphDatum(payload));
    }
  }, [headerPillsTab]);

  const infoContent = () => {
    switch (headerPillsTab) {
      case 1:
        return (
          <Row className="flex-column flex-md-row">
            <AdUnit />
          </Row>
        );
      case 2:
        return (
          <>
            <div className="mb-3">
              <ApplicationReportChart
                graph_datum={applicationGraphDatum}
                setDatumReportParam={setDatumReportParam}
                datumReportParam={datumReportParam}
              />
            </div>
            <div>
              <ApplicationReports
                ads_data={{
                  items: applicationGraphDatum?.items,
                  totalCount: applicationGraphDatum?.totalCount,
                }}
                setDatumReportParam={setDatumReportParam}
                datumReportParam={datumReportParam}
              />
            </div>
          </>
        );
      case 3:
        return <EarningHistory earning_history={earningTransaction} isApplication />;
      default:
        return <></>;
    }
  };

  //* Effects
  useEffect(() => {
    dispatch(getApplicationByKey(id));
  }, []);

  //* Functions
  const onCallPublisherHistory = () => {
    const payload: any = {
      application_key: id,
      limit: 10,
      page: 1,
      sortBy: 'created_at',
      sortType: 'DESC',
    };
    dispatch(getEarningTransaction(payload));
  };

  const renderApplicationInfo = () => {
    return (
      <div className="page-content">
        <MetaTags>
          <title>
            {t('Page.Application Info')} | {config.appName}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            pageTitle={t('Page.Application Info')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.APPLICATIONINFO, active: true },
            ]}
          />

          <Card>
            <CardHeader>
              <ApplicationInfoNavbar
                headerPillsTab={headerPillsTab}
                setHeaderPillsTab={setHeaderPillsTab}
                onCallPublisherHistory={onCallPublisherHistory}
              />
            </CardHeader>
            <CardBody>
              <Row lg={12}>
                <Col xs={12}>
                  <ApplicationCardInfo application={application} t={t} />
                </Col>
                <Col xs={12}>
                  <CardBody className="p-0">{infoContent()}</CardBody>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  };

  return <>{renderApplicationInfo()}</>;
};

export default withTranslation()(ApplicationInfo);
