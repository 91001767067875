import React, { useEffect, useRef, useCallback } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// i18n
import { withTranslation } from 'react-i18next';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';

const AdvertiserSidebarContent = (props: any) => {
  const { t } = props;
  const ref = useRef<any>();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul: any = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items?.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li>
              <Link
                to="/"
                className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
              >
                <i className="mdi mdi-home-variant d-flex justify-content-start align-items-center" />
                <span>{t('menu:dashboard')}</span>
              </Link>
            </li> */}

            <li>
              <Link
                to="/#"
                className="has-arrow fs-4 d-flex justify-content-start align-items-center bg-hover-green"
              >
                <i className="mdi mdi-signal-cellular-3 d-flex justify-content-start align-items-center" />
                <span>{t('menu:advertiser')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link
                    to="/campaigns/dashboard"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="fas fa-tachometer-alt d-inline me-2 d-flex justify-content-start align-items-center" />
                    {t('menu:dashboard')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/campaign/create"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="bx bx-add-to-queue d-inline me-2 d-flex justify-content-start align-items-center" />
                    {t('menu:new_campaign')}
                  </Link>
                </li>

                <li>
                  <Link
                    to="/campaigns"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="bx bx-shopping-bag d-flex justify-content-start align-items-center" />
                    {t('menu:campaigns')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gallery"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="bx bx-photo-album d-flex justify-content-start align-items-center" />
                    {t('menu:my_creatives')}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/#"
                className="has-arrow fs-4 d-flex justify-content-start align-items-center bg-hover-green"
              >
                <i className="fas fa-bullhorn d-flex justify-content-start align-items-center" />
                <span>{t('menu:marketer')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link
                    to="/marketer/dashboard"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="fas fa-tachometer-alt d-inline me-2 d-flex justify-content-start align-items-center" />
                    {t('menu:dashboard')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/marketer/account"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="fas fa-user d-inline me-2 d-flex justify-content-start align-items-center" />
                    {t('menu:user_info')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/marketer/create-campaign"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="bx bx-add-to-queue d-flex justify-content-start align-items-center" />
                    {t('menu:new_campaign')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/marketer/campaign"
                    className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                  >
                    <i className="bx bx-shopping-bag d-flex justify-content-start align-items-center" />
                    {t('menu:campaigns')}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

export default withTranslation()(withRouter(AdvertiserSidebarContent));
