import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import GleamJobPublicComponent from 'src/components/Common/GleamJobPublicComponent';
import GleamJobTimeline from 'src/components/Common/GleamJobTimeline';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  getAvaliableSocialForGleamJob,
  getDefaultWebsite,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import '../../../Common/GleamJob.style.scss';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import choice from 'src/assets/images/choice.png';

import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import CustomButton from 'src/components/Common/CustomButton';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import {
  socialMediaApplyGleamJobGQL,
  getExpiredSocialMediaTokenGql,
  updateSocialMediaHasJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import { floorToFixed } from 'src/common/data/mathToFixed';
import ContentType from 'src/constants/enum/contentType';
import ReportType from 'src/constants/enum/ReportType';
import {
  checkUserhasReportGQL,
  userReportJobGQL,
  getAvaliableSocialForGleamJobPublicGQL,
} from 'src/graphql/advertiser/job';
import Role from 'src/constants/enum/role';
import { useHistory, useParams } from 'react-router';
import { SocialMediaType } from '../Dashboard/SocialChannel/SocialChannel.control';
//import { result } from 'lodash';

const JobDetailPreview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [able_get_job]: any = paramsFromURL(['able_get_job']);
  const { jobId }: any = useParams();
  const [socialInfo, setSocialInfo] = useState<any>([]);
  const [socialInfoPublic, setSocialInfoPublic] = useState<any>([]);
  const [socialInfoDict, setSocialInfoDict] = useState<any>(
    new Map<string, Number>(),
  );
  const [selectedSocial, setSelectedSocial] = useState<any>([]);
  const [selectedSocialPublic, setSelectedSocialPublic] = useState(-1);
  const [estimatePrize, setEstimatePrize] = useState<any>(0.0);
  const [hasNoSocial, setHasNoSocial] = useState(false);
  const [numCustom, setNumCustom] = useState(0);
  const [sumCustom, setSumCustom] = useState(0);
  const [isReport, setIsReport] = useState(false);
  const [tax, setTax] = useState(0);
  const [data, setData] = useState<any>([]);
  const { user, defaultTax, jobSummary } = useSelector((state: any) => ({
    jobSummary: state.MarketerJob.availableSocialForJob,
    user: state.login.user,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
  }));
  const history = useHistory();
  const MAX_CUSTOM = 10;

  // if (jobId === null) {
  //   const { _jobId }: any = useParams();
  //   jobId = _jobId;
  // }
  console.log(jobId);
  const getData = async () => {
    try {
      const fetchedData = await getAvaliableSocialForGleamJobPublicGQL(jobId);
      setData(fetchedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    dispatch(getDefaultWebsite());

    getData();

    checkWasReport();
  }, []);

  const checkWasReport = async () => {
    const result = await checkUserhasReportGQL(jobId);
    if (result) {
      setIsReport(result);
    } else {
      setIsReport(false);
    }
  };

  useEffect(() => {
    if (data?.job?.job_tasks) {
      const socialMediaType = data.job.job_tasks[0]?.task_type?.socialmedia_type;
      if (socialMediaType) {
        setSocialInfoPublic([socialMediaType]); // Set as an array to maintain consistency
      }
    }
  }, [user, data]);

  useEffect(() => {
    if (jobSummary?.social_media_infos?.items) {
      const { job_tasks, job_requirement } = jobSummary?.job;
      const _socialInfo = [];
      const _social_info: Map<string, any> = new Map();
      for (let i = 0; i < jobSummary?.social_media_infos?.items?.length; i++) {
        _socialInfo.push({
          social_media_info: jobSummary.social_media_infos.items[i],
          selected: false,
        });
        let sum_social_prize = 0;
        for (let j = 0; j < job_tasks?.length; j++) {
          if (
            job_tasks[j].task_type.socialmedia_type ===
            jobSummary.social_media_infos.items[i].social_media_type
          ) {
            if (job_requirement.fixed_price !== null) {
              if (user.role === Role.PUBLISHER) {
                const originalPrice =
                  job_requirement.fixed_price - job_requirement.fixed_price * tax;
                sum_social_prize += originalPrice * (job_tasks[j].percent / 100);
              } else {
                sum_social_prize +=
                  job_requirement.fixed_price * (job_tasks[j].percent / 100);
              }
            } else {
              const topup = (+job_requirement?.topup_percent ?? 0) / 100;
              switch (job_tasks[j].task_type.content_type) {
                case ContentType.PHOTO:
                  // eslint-disable-next-line no-case-declarations
                  const base_photo =
                    jobSummary.social_media_infos.items[i].photo_content_price -
                    jobSummary.social_media_infos.items[i].photo_content_price * tax;
                  // eslint-disable-next-line no-case-declarations
                  const new_photo = base_photo * (job_tasks[j].percent / 100);
                  sum_social_prize =
                    sum_social_prize + new_photo + new_photo * topup;
                  break;
                case ContentType.VIDEO:
                  // eslint-disable-next-line no-case-declarations
                  const base_video =
                    jobSummary.social_media_infos.items[i].video_content_price -
                    jobSummary.social_media_infos.items[i].video_content_price * tax;
                  // eslint-disable-next-line no-case-declarations
                  const new_video = base_video * (job_tasks[j].percent / 100);
                  sum_social_prize =
                    sum_social_prize + new_video + new_video * topup;
                  break;
                default:
                  // eslint-disable-next-line no-case-declarations
                  const base_share =
                    jobSummary.social_media_infos.items[i].share_content_price -
                    jobSummary.social_media_infos.items[i].share_content_price * tax;
                  // eslint-disable-next-line no-case-declarations
                  const new_share = base_share * (job_tasks[j].percent / 100);

                  sum_social_prize =
                    sum_social_prize + new_share + new_share * topup;
                  break;
              }
            }
          }
        }

        if (job_requirement.fixed_price === null) {
          sum_social_prize = jobSummary?.job?.currency?.is_marketer_currency
            ? sum_social_prize / (+jobSummary?.job?.currency?.price_in_USDT ?? 1)
            : sum_social_prize / (+jobSummary?.job?.currency?.rate_in_USD ?? 1);
        }
        _social_info.set(
          jobSummary.social_media_infos.items[i].key,
          sum_social_prize,
        );
      }
      let sum_custom_price = 0;
      for (let i = 0; i < jobSummary?.job?.job_tasks?.length; i++) {
        if (
          jobSummary?.job?.job_tasks[i].task_type.socialmedia_type ===
          SocialMediaType.NOSOCIAL
        ) {
          sum_custom_price +=
            jobSummary?.job?.job_tasks[i].percent +
            (jobSummary?.job?.job_tasks[i].percent *
              jobSummary?.job?.job_requirement?.topup_percent) /
              100;
          setHasNoSocial(true);
        }
      }
      sum_custom_price = jobSummary?.job?.currency?.is_marketer_currency
        ? sum_custom_price / (+jobSummary?.job?.currency?.price_in_USDT ?? 1)
        : sum_custom_price / (+jobSummary?.job?.currency?.rate_in_USD ?? 1);
      setSumCustom(sum_custom_price);
      setSocialInfo(_socialInfo);
      setSocialInfoDict(_social_info);
    }
  }, [jobSummary]);

  useEffect(() => {
    if (defaultTax !== null) {
      const _tax = defaultTax / 100;
      setTax(_tax);
    }
  }, [defaultTax]);

  const onGetLogoSocialByType = (type: SocialMediaType): string => {
    switch (type) {
      case SocialMediaType.FACEBOOK:
        return facebookLogo;
      case SocialMediaType.INSTAGRAM:
        return instagramLogo;
      case SocialMediaType.TWITTER:
        return twitterLogo;
      case SocialMediaType.YOUTUBE:
        return youtubeLogo;
      case SocialMediaType.TIKTOK:
        return tikTokLogo;
      default:
        return facebookLogo;
    }
  };

  const handleNumOfCustom = (e: any) => {
    const { value } = e.target;
    if (/^[0-9]*$/.test(value)) {
      const _value = value > MAX_CUSTOM ? MAX_CUSTOM : value;
      const diff = _value - numCustom;
      let new_estimateprize = estimatePrize + sumCustom * diff;
      // new_estimateprize = jobSummary?.job?.currency?.is_marketer_currency
      //   ? new_estimateprize / (+jobSummary?.job?.currency?.price_in_USDT ?? 1)
      //   : new_estimateprize / (+jobSummary?.job?.currency?.rate_in_USD ?? 1);
      new_estimateprize = +floorToFixed(new_estimateprize, 2);
      setEstimatePrize(new_estimateprize);
      setNumCustom(_value);
    }
  };

  const handleKeyPress = (e: any) => {
    return (
      (e.charCode !== 8 && e.charCode === 0) ||
      (e.charCode >= 48 && e.charCode <= 57)
    );
  };

  const checkTokenExpiredForSelectedSocial = async (selectedSocial: any) => {
    const isExpired = await Promise.all(
      selectedSocial.map(async (key: any) => {
        const result = await getExpiredSocialMediaTokenGql(key);
        return result.items.length > 0;
      }),
    );

    return isExpired.some(Boolean) ? 1 : 0;
  };

  const onClickSocialMedia = async (key: string) => {
    let checkedSocial = selectedSocial;
    const social_info_prize = socialInfoDict.get(key);
    // const currency_custom_prize = jobSummary?.job?.currency?.is_marketer_currency
    //   ? social_info_prize / (+jobSummary?.job?.currency?.price_in_USDT ?? 1)
    //   : social_info_prize / (+jobSummary?.job?.currency?.rate_in_USD ?? 1);

    if (checkedSocial.includes(key)) {
      checkedSocial = checkedSocial.filter((f: string) => f !== key);
      const new_estimateprize = +estimatePrize - social_info_prize;
      setEstimatePrize(new_estimateprize.toFixed(2));
    } else {
      checkedSocial = [...checkedSocial, key];
      const new_estimateprize = +estimatePrize + social_info_prize;
      setEstimatePrize(new_estimateprize.toFixed(2));
    }
    setSelectedSocial(checkedSocial);
  };

  const accept = async (job_key: string, apply_socials: any) => {
    dispatch(setLoading());
    // const checkExpired = await checkTokenExpiredForSelectedSocial(selectedSocial);
    // if (checkExpired) {
    //   history.push('/influencer/renew');
    // } else {
    const social_media_info = [];
    for (let i = 0; i < apply_socials?.length; i++) {
      social_media_info.push({
        social_media_info_key: apply_socials[i],
        price: null,
      });
    }
    const newSocialJob = await socialMediaApplyGleamJobGQL({
      job_key,
      social_media_info,
      num_custom: +numCustom,
      end_date: new Date(),
    });

    if (!newSocialJob?.length) {
      notify(
        i18n.t(`error:${newSocialJob.toString().split('Error: ')[1]}`),
        'error',
      );
      dispatch(unsetLoading());
      return;
    }
    if (newSocialJob?.length > 0) {
      window.location.replace(
        `/JobDetail?job=${job_key}&task=${newSocialJob[0].group}`,
      );
      notify(i18n.t('success:Job has accepted.'), 'success');
    }
    // }
    dispatch(unsetLoading());
  };

  const handleReport = async (reason: string, job_key: string) => {
    try {
      dispatch(setLoading());
      const res = await userReportJobGQL({ reportType: reason, job_key });
      if (res) {
        notify(i18n.t('success:REPORT_SUCCESS'), 'success');
        await checkWasReport();
        dispatch(unsetLoading());
      } else {
        notify(i18n.t('error:REPORT_FAIL'), 'error');
        dispatch(unsetLoading());
      }
    } catch (error) {
      notify(i18n.t('error:REPORT_FAIL'), 'error');
      dispatch(unsetLoading());
    }
  };

  const firstSocialMedia = socialInfoPublic[0];

  const fixedPrice = (price: number) => {
    return price - price * tax;
  };

  return (
    <div>
      <Row>
        <Col lg="7" md="12">
          <GleamJobPublicComponent job={data?.job} />
        </Col>
        <Col lg="5" md="12">
          <GleamJobTimeline job={data?.job} />

          <div className="gleam-job-timeline-container gleam-margin">
            <div className="gleam-job-timeline-panel">
              <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <h5 className="text-white">Select Social Media</h5>
              </div>
              <div className="gleam-job-timeline">
                <div
                  className="g-influencer-image-container"
                  style={{ width: 'fit-content', display: 'flex', flexWrap: 'wrap' }}
                >
                  <div className="image-control">
                    <div
                      className={`radio-control ${
                        selectedSocialPublic === 0 ? 'selected' : ''
                      }`}
                      onClick={() =>
                        setSelectedSocialPublic(selectedSocialPublic === 0 ? -1 : 0)
                      }
                    >
                      {selectedSocialPublic === 0 && (
                        <span className="social-checked">
                          <i
                            className="fas fa-check"
                            style={{
                              color: '#ffffff',
                            }}
                          />
                        </span>
                      )}
                      <img
                        src={onGetLogoSocialByType(firstSocialMedia)}
                        alt="Social Logo"
                        className="logo-socials"
                        width={20}
                        height={20}
                      />
                      <img src={userAvatar} alt="profile" />
                    </div>
                    <span className="g-image-title" style={{ height: '20px' }}>
                      social name
                    </span>
                  </div>
                </div>
              </div>
              {hasNoSocial && (
                <div style={{ paddingTop: '27px', paddingBottom: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h5 style={{ marginBottom: '0' }}>
                      Number of custom task to apply:
                    </h5>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: '150px' }}
                      min="0"
                      max={MAX_CUSTOM}
                      value={numCustom}
                      onChange={handleNumOfCustom}
                      onKeyDown={handleKeyPress}
                    />
                  </div>
                </div>
              )}
              <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <h5 className="text-white">
                  Estimate prize:{' '}
                  {data?.job?.job_requirement?.fixed_price !== 0 &&
                  data?.job?.job_requirement?.fixed_price !== null &&
                  data?.job?.job_requirement?.fixed_price !== undefined
                    ? floorToFixed(
                        fixedPrice(data?.job?.job_requirement?.fixed_price),
                        2,
                      )
                    : 0}{' '}
                  {data?.job?.currency?.main_symbol ?? 'USDT'}
                </h5>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                }}
              >
                <CustomButton
                  handleClick={async (e: any) => {
                    history.push('/login');
                  }}
                  size="xs"
                  status="submit"
                  label={t('Apply job')}
                />
                {!isReport && (
                  <UncontrolledDropdown className="">
                    <DropdownToggle className="text-muted font-size-28" tag="a">
                      <CustomButton size="xs" label={t('Report')} status="cancel" />
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={() => {
                          history.push('/login');
                        }}
                        className="text-danger"
                      >
                        {t(ReportType.OBSCENITY)}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          history.push('/login');
                        }}
                        className="text-danger"
                      >
                        {t(ReportType.ILLEGAL)}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JobDetailPreview;
