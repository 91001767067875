import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import SettingBudgetForm from 'src/components/Admin/SettingBudgetForm';
import { getDefaultCampaign as onGetDefaultCampaign } from 'src/store/actions';

const SetDefaultBudgetPage = () => {
  const dispatch = useDispatch();

  const { defaultCampaign } = useSelector((state: any) => ({
    defaultCampaign: state.DefaultValue.defaultCampaign,
  }));

  useEffect(() => {
    dispatch(
      onGetDefaultCampaign({
        device_platform: 'DESKTOP',
        banner_format: 'IN_PAGE',
        ad_format: 'BANNER',
      }),
    );
  }, []);

  return (
    <div className="mt-5">
      <Row className="mt-2">
        <Col xs={12} md={6}>
          {Object.keys(defaultCampaign)?.length > 0 && (
            <SettingBudgetForm
              defaultMinimumBudget={defaultCampaign.minimumBudget}
              defaultMinimumMaxBudget={defaultCampaign.minimumMaxBudget}
              minimumBudgetKey={defaultCampaign.minimumCPMKey}
              minimumMaxBudgetKey={defaultCampaign.minimumMaxBudgetKey}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default SetDefaultBudgetPage;
