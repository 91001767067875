import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './AlertNew.style.scss';

const AlertNew = () => {
  const { t }: any = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      {isOpen && (
        <div
          className="new-alert"
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <i className="mdi mdi-alert-outline align-middle text-warning me-3" />
            {t('Alert.Enable 2FA Before Withdraw')}
            &nbsp;&nbsp;
            <Link
              to={{
                pathname: '/profile',
                state: 2,
              }}
            >
              {t('Button.Profile Page')}
            </Link>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(false)}>
            x
          </div>
        </div>
      )}
    </>
  );
};
export default AlertNew;
