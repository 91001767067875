import { call, put, takeEvery } from 'redux-saga/effects';
import { createAptitudeGql, getAptitudesGql } from 'src/graphql/advertiser/aptitude';

import { getAptitudeSuccess, setLoading, unsetLoading } from '../actions';
import AptitudeTypes from './actionTypes';

function* createAptitude({ name }: any) {
  try {
    yield put(setLoading());
    yield call(createAptitudeGql, name);
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* getAptitude() {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getAptitudesGql);
    yield put(getAptitudeSuccess(response));
  } catch (error) {
    // something here
  } finally {
    yield put(unsetLoading());
  }
}

function* aptitudes() {
  yield takeEvery(AptitudeTypes.CREATE_APTITUDE, createAptitude);
  yield takeEvery(AptitudeTypes.GET_APTITUDE, getAptitude);
}

export default aptitudes;
