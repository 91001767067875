import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import CustomTable from '../Common/CustomTable';
import { getAllPaymentTransactionsRequest as onGetAllPaymentTransactionsRequest } from '../../store/finance/payment/actions';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import PaymentDetailModal from '../Table/PaymentDetailModal';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const PaymentTransactionsTable = ({ t, tab, userId }: any) => {
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');

  // Redux
  const { txn } = useSelector((state: any) => ({
    txn: state.Payment.transactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(10);

    dispatch(
      onGetAllPaymentTransactionsRequest({
        limit: 10,
        page: 1,
        userId,
        isCampaign: true,
      }),
    );
  }, [tab]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(onGetAllPaymentTransactionsRequest(payload));

    setPageSize(newPageSize);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(onGetAllPaymentTransactionsRequest(payload));
    setSearch(val);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(onGetAllPaymentTransactionsRequest(payload));
    setSearch('');
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ created_at }) => {
          return (
            <DateTimeComponent
              dateTime={created_at}
              dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
            />
          );
        },
        width: 200,
        id: 'col-7',
      },
      {
        Header: t('table:Email'),
        accessor: ({ campaign }) => campaign?.user?.email,
        id: 'col-3',
      },
      {
        Header: t('table:CampaignName'),
        accessor: ({ campaign }) => campaign?.name,
        id: 'col-4',
      },
      {
        Header: t('table:Amount'),
        accessor: ({ FIAT_amount }) => Math.round(FIAT_amount * 1000) / 1000,
        id: 'col-5',
      },
      {
        Header: t('table:Payment in creatives'),
        accessor: ({ creativeCampaignDatums }) =>
          creativeCampaignDatums ? creativeCampaignDatums?.length : 0,
        id: 'col-6',
      },
      {
        Header: t('table:Action'),
        accessor: (originalRow, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="fas fa-file-alt fa-1x c-pointer" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-start">
                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'col-1',
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(onGetAllPaymentTransactionsRequest(payload));
  };

  const getDefaultPayload = (defaultPage: number) => {
    setPage(defaultPage);

    return {
      limit: pageSize,
      page: defaultPage,
      search,
      userId,
      isCampaign: true,
    };
  };

  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenColumn={userId ? ['col-3'] : []}
      >
        <PaymentDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Payment Detail')}
        />
        <Row className="justify-content-between m-0">
          <Col xs={12} md={2} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
              colors="white"
            />
          </Col>
          <Col xs={12} md={10} lg={10}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={8} lg={5}>
                <SearchBar
                  placeholder={
                    userId
                      ? t('Search Campaign name')
                      : t('Search Email or Campaign name')
                  }
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(PaymentTransactionsTable);
