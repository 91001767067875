import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import DateTimeComponent from 'src/components/Common/DateTimeComponent';
import WebsiteStatus from 'src/components/Publisher/Website/WebsiteStatus';
import DateTimeTypes from '../../../../constants/dateTimeTypes';

const WebCardInfo = ({ t }: any) => {
  const websiteData = useSelector((state: any) => state.WebsiteInfo.websiteInfo);

  return (
    <Card>
      <CardBody className="p-4">
        <h6 className="font-size-16 fw-normal">{t('WebsiteURL')}</h6>
        <div className="d-flex align-items-center">
          <h5 className="font-size-22 me-2 mb-0">{websiteData.url}</h5>
        </div>
        <hr />
        <Row>
          <Col md={10}>
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <div>
                <h6 className="font-size-16 fw-normal">{t('Status')}</h6>
                <h5 className="font-size-20">
                  <WebsiteStatus status={websiteData.status} />
                </h5>
              </div>
              <div>
                <h6 className="font-size-16 fw-normal">{t('table:Earnings/day')}</h6>
                <h5 className="font-size-20">${websiteData.current_revenue}</h5>
              </div>
              <div>
                <h6 className="font-size-16 fw-normal">{t('WebsiteAdded')}</h6>
                <h5 className="font-size-20">
                  <DateTimeComponent
                    dateTime={websiteData.created_at}
                    dateTimeType={DateTimeTypes.NORMAL_DATE_TIME}
                  />
                </h5>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(WebCardInfo);
