import { useEffect, useRef, useCallback } from 'react';
import SimpleBar from 'simplebar-react';
import { withTranslation } from 'react-i18next';
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';

const PublisherSidebarContent = (props: any) => {
  const { t } = props;
  const ref = useRef<any>();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul: any = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items?.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  return (
    <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {/* <li>
            <Link
              to="/"
              className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
            >
              <i className="mdi mdi-home-variant me-1 d-flex justify-content-start align-items-center" />
              <span>{props.t('Dashboard')}</span>
            </Link>
          </li> */}

          <li>
            <Link
              to="/#"
              className="has-arrow fs-4 d-flex justify-content-start align-items-center bg-hover-green"
            >
              <i className="fas fa-ad me-1 d-flex justify-content-start align-items-center" />
              <span>{t('menu:publisher')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/mywebsite"
                  className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                >
                  <i className="far fa-window-maximize me-1 d-flex justify-content-center align-items-center" />
                  <span>{props.t('menu:my_website')}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/myApplication"
                  className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                >
                  <i className="fas fa-mobile-alt me-1 d-flex justify-content-center align-items-center" />
                  <span>{props.t('menu:my_application')}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link
              to="/#"
              className="has-arrow fs-4 d-flex justify-content-start align-items-center bg-hover-green"
            >
              <i className="fas fa-bullhorn me-1 d-flex justify-content-start align-items-center" />
              <span>{props.t('menu:influencer')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/influencer/dashboard"
                  className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                >
                  <i className="fas fa-chart-line me-1 d-flex justify-content-center align-items-center" />
                  <span>{props.t('menu:overview')}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/influencer/account"
                  className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                >
                  <i className="fas fa-user me-1 d-flex justify-content-center align-items-center" />
                  <span>{props.t('menu:user_info')}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/influencer/job"
                  className="fs-4 d-flex justify-content-start align-items-center bg-hover-green"
                >
                  <i className="fas fa-tasks me-1 d-flex justify-content-center align-items-center" />
                  <span>{props.t('menu:my_works')}</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </SimpleBar>
  );
};

export default withTranslation()(withRouter(PublisherSidebarContent));
