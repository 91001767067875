import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import paramsFromURL from 'src/constants/paramsFromURL';
import choice from 'src/assets/images/choice.png';
import background from 'src/assets/images/background-socialinfluencer.png';
import follower from 'src/assets/images/flupower/user-dashboard.png';
// import activefollower from 'src/assets/images/flupower/user-dashboard.png';
import insightLogo from 'src/assets/images/insightpng.png';
import audienceLogo from 'src/assets/images/Audience-follower.png';
import dropdown from 'src/assets/images/arrow-down-sign-to-navigate-black.png';
import lessdetails from 'src/assets/images/arrow-down-sign-to-navigate-blackup.png';
import like from 'src/assets/images/data.png';
import engagement from 'src/assets/images/line-chart.png';
import reach from 'src/assets/images/meeting.png';
import view from 'src/assets/images/eye3.png';
import impression from 'src/assets/images/Pon.png';
import likeperpost from 'src/assets/images/like1234.png';
import comment from 'src/assets/images/talking.png';
import { floorToFixed } from 'src/common/data/mathToFixed';
import share from 'src/assets/images/structure.png';
import {
  createRequestNewFollowerGql,
  getFollowerBySocialKeyGql,
  requestUpdateInfluencerPrizeRateWithEngagementGQL,
  updateSocialMediaInfo,
} from 'src/graphql/advertiser/socialMediaInfo';
import { getOneSocialMediaInfo, setLoading, unsetLoading } from 'src/store/actions';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import Role from 'src/constants/enum/role';
import Swal from 'sweetalert2';
import * as formatter from 'src/utils/convert-value';
import ProgressBarCircleComponent from 'src/components/shared/ProgressBarCircle/ProgressBarCircle.component';
import notify from 'src/utils/notify';
import { SocialMediaType } from '../Dashboard/SocialChannel/SocialChannel.control';
import {
  //   CardContainer,
  ChanelDetailContainer,
  NameUser,
  PageContent,
  // PriceContainer,
  //   StaticContainer,
} from './SocialProfile.style';
import ImageProfileComponent from './ImageProfile';
import ChannelLogoComponent from './ChannelLogo';
import ContentPriceComponent from './ContentPrice/ContentPrice.component';
import AptitudesComponent from './Aptitudes/Aptitudes.component';
import IntroduceComponent from './Introduce/Introduce.component';

const SocialInfluencerProfile = ({
  setFormValues,
  socialMediaLogo,
  formValues,
  // eslint-disable-next-line no-unused-vars
  socialLoading,
  currentKey = null,
}: any) => {
  const {
    userProfile$,
    userProfileSelected$,
    influencerPriceRate$,
    user,
    onSocialMediaInfoLoading$,
  } = useSelector((state: any) => ({
    userProfile$: state.SocialMediaInfo.socialMediaInfoData,
    userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
    influencerPriceRate$: state.SocialMediaInfo.influencerPriceRate,
    user: state.login.user,
    onSocialMediaInfoLoading$: state.SocialMediaInfo.onSocialMediaInfoLoading,
  }));
  const profile = userProfile$;
  const { t } = useTranslation();
  const [editFollower, setEditFollower] = useState(false);
  const [editNumFollower, setEditNumFollower] = useState(0);
  const [toggleMore, setToggleMore] = useState(false);
  const [keyParam]: any = paramsFromURL(['channel']);
  const key = currentKey || keyParam;
  const dispatch = useDispatch();
  const [newFollower, setNewFollower] = useState({
    new_follower: 0,
    key: '',
    verify_status: VerifyStatus.PENDING,
  });
  useEffect(() => {
    getNewFollower();
    dispatch(getOneSocialMediaInfo(key));
  }, []);

  useEffect(() => {
    setFormValues({ ...formValues, fan_count: userProfile$?.follower });
  }, [userProfile$]);
  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (param: any) => {
    await updateSocialMediaInfo(param);
    dispatch(getOneSocialMediaInfo(key));
  };

  const getNewFollower = async () => {
    const newFollowerData = await getFollowerBySocialKeyGql(key);
    setNewFollower(newFollowerData);
  };

  const handleMore = () => setToggleMore((prev) => !prev);

  const handleUpdateNewEngagement = async () => {
    try {
      dispatch(setLoading());
      const res = await requestUpdateInfluencerPrizeRateWithEngagementGQL(key);
      if (res) {
        dispatch(getOneSocialMediaInfo(key));
      }
    } catch (error) {
      notify(error, 'error');
    } finally {
      dispatch(unsetLoading());
    }
  };
  return (
    <PageContent className="page-content">
      <div className="bread-crumb">
        {/* {user?.role === Role.PUBLISHER && (
          <Breadcrumb
            pageTitle={t('Menus:Social Channel')}
            menus={[
              { menu: menu.HOME, active: true },
              { menu: menu.OVERVIEW },
              { menu: menu.SOCIAL_CHANNEL, isSamePage: true },
            ]}
          />
        )} */}
      </div>
      <ChanelDetailContainer>
        <CardContainer
        //   className="card-container"
        >
          <ImageProfileComponent
            userProfile={userProfile$}
            socialLoading={onSocialMediaInfoLoading$}
          />
          <ChannelLogoComponent
            socialMediaLogo={socialMediaLogo}
            socialLoading={onSocialMediaInfoLoading$}
          />
          <NameContainer className="static-container">
            <NameUser className="name">
              {formValues?.profile_path ? (
                <a
                  href={formValues?.profile_path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {profile?.name}
                </a>
              ) : (
                profile?.name
              )}
            </NameUser>
          </NameContainer>
          <StaticContainer>
            {!editFollower && (
              <FollowerContainer>
                <img src={follower} alt="" />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span>Follower</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{formatter.nFormatter(profile?.follower, 1)}</span>
                    {profile?.social_media_type === SocialMediaType.FACEBOOK &&
                    user.role === Role.PUBLISHER &&
                    userProfile$.isOwner === true ? (
                      <>
                        <button
                          type="button"
                          className="btn-edit"
                          disabled={userProfileSelected$}
                          style={{
                            cursor: userProfileSelected$ ? 'no-drop' : 'pointer',
                          }}
                          onClick={() => {
                            setEditFollower(true);
                            setEditNumFollower(formValues?.follower);
                          }}
                        >
                          <i
                            className="fas fa-pen"
                            style={{
                              color: 'white',
                            }}
                          />
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {newFollower?.new_follower ? (
                    <>
                      <div>last request : {newFollower?.new_follower}</div>
                      <div> {`(${newFollower?.verify_status})`}</div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </FollowerContainer>
            )}
            {editFollower && (
              <div
                className="follower"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <>
                  <i className="fas fa-users icon" />
                  <input
                    min={1}
                    name="fan_count"
                    type="text"
                    onFocus={(e) => {
                      e.target.type = 'number';
                    }}
                    onBlur={(e) => {
                      e.target.type = 'text';
                    }}
                    onChange={(e) => {
                      const { value }: any = e.target;
                      setEditNumFollower(+value);
                    }}
                    value={editNumFollower}
                    style={{
                      display: 'block',
                      width: '100%',
                      maxWidth: '180px',
                      outline: 'none',
                      border: '1px solid #CFD2CF',
                      borderRadius: '8px',
                      textAlign: 'center',
                      marginTop: 15,
                    }}
                  />
                  <p style={{ marginTop: 15 }}>
                    {t('marketer:create_job_campaign:follower')}
                  </p>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <button
                      style={
                        {
                          //   fontSize: '1.2em',
                          //   overflow: 'hidden',
                          //   textOverflow: 'ellipsis',
                          //   whiteSpace: 'nowrap',
                          //   border: '1px solid #333',
                          //   borderRadius: '15px 0 0 15px',
                          //   margin: '10px auto 10px auto',
                          //   width: '90px',
                          //   height: '30px',
                          // cursor: 'pointer',
                          //   background: '#fbfaff',
                        }
                      }
                      className="btn"
                      type="button"
                      onClick={() => {
                        setEditFollower(false);
                      }}
                    >
                      <span>{t('influencer:account:cancel')}</span>
                    </button>
                    <button
                      // style={{
                      //   fontSize: '1.2em',
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis',
                      //   whiteSpace: 'nowrap',
                      //   border: '1px solid #333',
                      //   borderRadius: '0 15px 15px 0',
                      //   margin: '10px auto 10px auto',
                      //   width: '90px',
                      //   height: '30px',
                      //   cursor: 'pointer',
                      //   background: '#fbfaff',
                      // }}
                      className="btn"
                      onClick={async () => {
                        await Swal.fire({
                          title: t('swal:Are you sure ?'),
                          text: t("swal:You won't be able to revert this!"),
                          imageUrl: choice,
                          width: 300,
                          imageHeight: 200,
                          imageAlt: 'A tall image',
                          showCancelButton: true,
                          confirmButtonColor: '#009a78',
                          confirmButtonText: t('button:Yes'),
                          cancelButtonText: t('swal:Cancel'),
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            // await onSubmit({
                            //   key: formValues.key,
                            //   follower: editNumFollower,
                            //   verify_status: 'PENDING',
                            // });

                            await createRequestNewFollowerGql(
                              formValues.key,
                              editNumFollower,
                            );

                            setEditFollower(false);
                            await getNewFollower();
                          }
                        });
                      }}
                      type="button"
                    >
                      <span>{t('influencer:account:submit')}</span>
                    </button>
                  </div>
                </>
              </div>
            )}
            <ActiveFollowerContainer>
              <img src={follower} alt="" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {' '}
                <span>Active Follower</span>
                <ProgressBarCircleComponent
                  key={uniqueId()}
                  animation_timer="1.2s"
                  background_color="#DFDFDE"
                  percent={profile?.engagement_percent}
                  progress_color="#9FB4FF"
                  text={profile?.engagement_percent}
                  name="active_follower"
                />
              </div>
            </ActiveFollowerContainer>

            <button
              type="button"
              onClick={() => {
                handleMore();
                // dispatch(getOneSocialMediaInfo(key));
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontWeight: 'bold',
                border: 'none',
                backgroundColor: 'transparent',
                // marginTop: '1rem',
              }}
            >
              more details
              <img src={dropdown} alt="" width={20} />
            </button>
            <MoreDetailContainer toggleMore={toggleMore}>
              <InsightContainer>
                <InsightLogo>
                  <img
                    src={insightLogo}
                    alt=""
                    style={{
                      width: '100px',
                      height: '100px',
                      position: 'absolute',
                      top: '-3rem',
                      // left: '17rem',
                    }}
                  />
                  <span>Insights</span>
                </InsightLogo>
                <div>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={like}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>{formatter.nFormatter(profile?.like, 1)}</span>
                    </IconBox>
                    <span>Average like</span>
                  </InsightsBox>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={engagement}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>
                        {formatter.nFormatter(profile?.engagement_per_post, 1)}
                      </span>
                    </IconBox>
                    <span>Average engagement</span>
                  </InsightsBox>
                </div>
                <div>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={reach}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>{formatter.naFormatter(profile?.reach, 1)}</span>
                    </IconBox>
                    <span>Reach</span>
                  </InsightsBox>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={impression}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>{formatter.naFormatter(profile?.impression, 1)}</span>
                    </IconBox>
                    <span>Impression</span>
                  </InsightsBox>
                </div>
                <div>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={likeperpost}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>{formatter.naFormatter(profile?.like_per_post, 1)}</span>
                    </IconBox>
                    <span>Like (Per Post)</span>
                  </InsightsBox>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={comment}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>
                        {formatter.naFormatter(profile?.comment_per_post, 1)}
                      </span>
                    </IconBox>
                    <span>Comment (Per Post)</span>
                  </InsightsBox>
                </div>
                <div>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={view}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>{formatter.naFormatter(profile?.view_per_post, 1)}</span>
                    </IconBox>
                    <span>View (Per Video)</span>
                  </InsightsBox>
                  <InsightsBox>
                    <IconBox>
                      <img
                        src={share}
                        alt=""
                        style={{ width: '30px', height: '30px' }}
                      />
                      <span>
                        {formatter.naFormatter(profile?.share_per_post, 1)}
                      </span>
                    </IconBox>
                    <span>Share (Per Post)</span>
                  </InsightsBox>
                </div>
              </InsightContainer>
              <AudienceContainer>
                <AudienceLogo>
                  <img
                    src={audienceLogo}
                    alt=""
                    style={{
                      width: '100px',
                      height: '100px',
                      position: 'absolute',
                      top: '-3rem',
                      // left: '17rem',
                    }}
                  />
                  <span>Audience Follower</span>
                </AudienceLogo>
                <div>
                  <AudienceBox>
                    <span>Location</span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {profile?.audience?.location.map((item: any) => {
                        return (
                          <span style={{ fontWeight: 'bold' }}>{item.country}</span>
                        );
                      })}
                    </span>
                  </AudienceBox>
                  <section
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                      marginTop: '1.5rem',
                    }}
                  >
                    {profile?.audience?.location.map((item: any) => {
                      return (
                        <IndicatorBar
                          value={formatter.nFormatter(item?.percentage, 2)}
                        />
                      );
                    })}
                  </section>

                  <AudienceBox
                    style={{
                      marginTop: '1.5rem',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {profile?.audience?.location.map((item: any) => {
                        return (
                          <span style={{ fontWeight: 'bold' }}>
                            {floorToFixed(item?.percentage, 2)}%
                          </span>
                        );
                      })}
                    </span>
                  </AudienceBox>
                </div>
                <div>
                  <AudienceBox>
                    <span>Age</span>
                    <span style={{ fontWeight: 'bold' }}>
                      {profile?.audience?.age?.range ?? 'N/A'}
                    </span>
                  </AudienceBox>
                  <section
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                      marginTop: '1.5rem',
                    }}
                  >
                    <IndicatorBar
                      value={formatter.nFormatter(
                        profile?.audience?.age?.percentage,
                        2,
                      )}
                    />
                  </section>

                  <AudienceBox
                    style={{
                      marginTop: '1.5rem',
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {formatter.naFormatter(profile?.audience?.age?.percentage, 2)}
                      {profile?.audience?.age?.percentag ? '%' : ''}
                    </span>
                  </AudienceBox>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <AudienceBox>
                    <span>Gender</span>
                    <span style={{ fontWeight: 'bold' }}>M</span>
                    <span style={{ fontWeight: 'bold' }}>F</span>
                  </AudienceBox>
                  <section
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.7rem',
                      marginTop: '1.5rem',
                    }}
                  >
                    <IndicatorBar
                      value={formatter.nFormatter(profile?.audience?.male, 2)}
                    />
                    <IndicatorBar
                      value={formatter.nFormatter(profile?.audience?.female, 2)}
                    />
                  </section>

                  <AudienceBox
                    style={{
                      marginTop: '1.5rem',
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {formatter.naFormatter(profile?.audience?.male, 2)}
                      {profile?.audience?.male ? '%' : ''}
                    </span>
                    <span style={{ fontWeight: 'bold' }}>
                      {formatter.naFormatter(profile?.audience?.female, 2)}
                      {profile?.audience?.female ? '%' : ''}
                    </span>
                  </AudienceBox>
                </div>
              </AudienceContainer>
              <button
                type="button"
                onClick={handleMore}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <img src={lessdetails} alt="" width={20} />
                Less details
              </button>
            </MoreDetailContainer>
          </StaticContainer>
          <PriceContainer className="price-container">
            <ContentPriceComponent
              userProfile={userProfile$}
              formValues={formValues}
              setFormValues={setFormValues}
              influencerPriceRate={influencerPriceRate$}
              disableBtn={user?.role !== Role.PUBLISHER}
              handleUpdateNewEngagement={handleUpdateNewEngagement}
            />
          </PriceContainer>
          <PriceContainer>
            <AptitudesComponent
              formValues={formValues}
              setFormValues={setFormValues}
              userProfile={userProfile$}
              disableEdit={user?.role !== Role.PUBLISHER}
            />
          </PriceContainer>
          <PriceContainer>
            <IntroduceComponent
              disableEdit={
                user?.role !== Role.PUBLISHER || userProfile$.isOwner !== true
              }
            />
          </PriceContainer>
        </CardContainer>
      </ChanelDetailContainer>
    </PageContent>
  );
};

const ActiveFollowerContainer = styled.div`
  display: flex;
  /* gap: 0.5rem; */
  margin-top: 0.5rem;

  img {
    width: 35px;
    height: 35px;
    @media (max-width: 500px) {
      width: 32px;
      height: 32px;
    }
  }
  span {
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;
const FollowerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;

  .btn-edit {
    margin-left: 1rem;
    color: white;
    font-size: 14px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 2rem;
    background: black;
    cursor: pointer;
  }

  img {
    width: 35px;
    height: 35px;
    @media (max-width: 500px) {
      width: 32px;
      height: 32px;
    }
  }
  span {
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

const AudienceBox = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100px;

  @media (max-width: 500px) {
    margin-top: 5px;
  }
  span {
    min-width: 35px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;

const AudienceLogo = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  /* position: relative;
  top: -1rem; */
  span {
    font-size: 30px;
    font-weight: bold;
    color: white;

    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
`;

const IconBox = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  span {
    font-weight: bold;
  }
`;

const InsightsBox = styled.span`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  line-height: 2rem;

  @media (max-width: 500px) {
    min-width: 100px;
  }
  span {
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;

const PriceContainer = styled.div`
  text-align: center;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 1px solid rgb(221, 218, 218);
  margin-top: 0.5rem;
`;

const IndicatorBar = styled.span<{ value: any }>`
  height: 10px;
  background: white;
  border-radius: 5px;
  /* border: 3px solid; */
  width: ${({ value }) => (value ? `${value}%` : '0')};
  /* padding: 0 2rem; */

  @media (max-width: 500px) {
    height: 7px;
  }
`;

const MoreDetailContainer = styled.div<{ toggleMore: boolean }>`
  /* border: 5px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  max-height: ${({ toggleMore }) => (toggleMore ? '880px' : '0')};
  overflow: hidden;
  opacity: ${({ toggleMore }) => (toggleMore ? '1' : '0')};
  transition: all 0.5s ease;
`;

const InsightLogo = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  /* position: relative;
  top: -1rem; */
  span {
    font-size: 30px;
    font-weight: bold;
    color: white;

    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
`;

const AudienceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border-radius: 10px;
  background-color: black !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  div {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 2rem;
    padding: 0 2rem;
    border-bottom: 1px solid rgb(221, 218, 218);
    /* border-width: 80%; */
    width: 80%;

    @media (max-width: 550px) {
      width: 100%;
    }
    & > span:nth-last-of-type(1) {
      margin-left: auto;
    }
  }
`;

const InsightContainer = styled.div`
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  border-radius: 10px;
  /* border: 2px solid yellow; */
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px solid; */
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const CardContainer = styled.div`
  position: relative;
  height: max-content;
  min-height: 300px;
  width: 100%;
  max-width: 50rem;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #fbfaff;
`;

const NameContainer = styled.div`
  text-align: center;
  padding-top: 4rem;
  width: 100%;
  /* border: 2px solid black; */
`;

const StaticContainer = styled.div`
  position: relative;
  background-color: #fbfaff;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  /* border: 2px solid pink; */
  .btn {
    margin-left: 1rem;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    width: 90px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    cursor: pointer;
  }
  span {
    @media (max-width: 500px) {
      /* font-size: px; */
    }
  }
`;

export default SocialInfluencerProfile;
