import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'reactstrap';
import { HeaderCollapse } from 'src/pages/Advertiser/Marketer/InfluencerJobDetail/influencerJobDetail.style';
import resizeImage from 'src/utils/resizeImage';
import { updateSocialMediaHasJobGql } from 'src/graphql/advertiser/socialMediaInfo';
import choice from 'src/assets/images/choice.png';
import ShowMoreText from 'react-show-more-text';
import { useDispatch } from 'react-redux';
import { getSocialJob } from 'src/store/actions';
import { uploadSocialJobImage } from 'src/graphql/users';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import example from 'src/assets/images/example.png';
import paramsFromURL from 'src/constants/paramsFromURL';
import CustomButton from 'src/components/Common/CustomButton';
import Swal from 'sweetalert2';
import JobDetailImagePreview from './jobDetailImagePreview';
import './jobDetailCollapse2.style.scss';

const JobDetailCollapse2 = ({
  collapse,
  socialJobItem,
  setCollapse,
  setExampleMessage,
  exampleMessage,
  marketerJob,
}: any) => {
  const dispatch = useDispatch();
  const [socialJobId]: any = paramsFromURL(['detail']);
  const [imageKeys, setImageKeys] = useState<any>([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  useEffect(() => {
    if (collapse.collapse2) setIsCollapseOpen(true);
    dispatch(getSocialJob(socialJobId));
  }, []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const handleFileSelected = async (e: any) => {
    const files: any = Array.from(e.target.files);
    const uploadedImageKey = [];
    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      // eslint-disable-next-line no-await-in-loop
      const newSizeFile = await resizeImage({ file, maxSize: 500 });
      if (socialJobItem?.image_storages?.length + files?.length <= 10) {
        // eslint-disable-next-line no-await-in-loop
        const imageKey = await uploadSocialJobImage(newSizeFile, socialJobId);

        const uploadedData = { key: imageKey, marketer_verified: false };
        if (imageKey) {
          uploadedImageKey.push(uploadedData);
        }
      }
    }
    if (socialJobItem?.image_storages?.length + files?.length > 10)
      notify(i18n.t('error:You maximum images upload is 10 images'), 'error');
    setImageKeys([...imageKeys, ...uploadedImageKey]);
    // dispatch(getSocialJob(socialJobId));
  };

  // http://localhost:3001/api/socialJob/
  const onSubmitExample = async () => {
    const hasImage =
      socialJobItem?.image_storages[0]?.key ||
      (imageKeys?.length > 0 ? imageKeys : false);
    const hasMessage = /(.|\s)*\S(.|\s)*/.test(exampleMessage) && !!exampleMessage;

    // console.log(/^.*[\S].*$/.test(exampleMessage));

    // console.log(socialJobItem?.image_storages[0]?.key);
    // console.log(haveImage);

    if (!hasImage || !hasMessage) {
      if (!hasImage) notify(i18n.t('error:Please upload example images .'), 'error');
      if (!hasMessage)
        notify(i18n.t('error:Please enter your post caption .'), 'error');
      return;
    }
    const res: any = await updateSocialMediaHasJobGql({
      key: socialJobId,
      example_message: exampleMessage,
      influencer_submit_date: new Date(),
    });
    setCollapse({ ...collapse, collapse2: false });
    dispatch(getSocialJob(socialJobId));
    if (res) {
      notify(i18n.t('success:Job has accepted.'), 'success');
    } else notify(i18n.t('error:Job has not accept .'), 'error');
  };

  // eslint-disable-next-line no-unused-vars
  const socialContent = (social_media_type: any) => {
    switch (socialJobItem?.social_media_info?.social_media_type) {
      case 'FACEBOOK':
        return t('marketer:job_detail.Post caption');
      case 'INSTAGRAM':
        return t('marketer:job_detail.Post caption');
      case 'TIKTOK':
        return t('marketer:job_detail.Video description');
      case 'TWITTER':
        return t('marketer:job_detail.Post caption');
      case 'YOUTUBE':
        return t('marketer:job_detail.Video description');
      default:
        return t('marketer:job_detail.Post caption');
    }
  };

  return (
    <div className="job-detail-collapse2-container ">
      <div
        className={`job-detail-flex-container ${
          isCollapseOpen ? 'border-radius-card-open' : 'border-radius-card-close'
        }`}
        onClick={() => setCollapse({ ...collapse, collapse2: !collapse.collapse2 })}
        style={{ cursor: 'pointer' }}
      >
        <div className="job-detail-flex">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {socialJobItem?.influencer_submit_date && (
                <div className="icon-check">
                  <i
                    className="fa fa-check"
                    style={{
                      fontSize: '10px',
                      position: 'absolute',
                      top: '3px',
                      left: '2px',
                    }}
                  />
                </div>
              )}

              <h5 className="job-detail-number">2</h5>
            </div>
            <div>
              <h3 className=" fs-5 mt-2">
                {t('marketer:job_detail:Send for examination')}
              </h3>
            </div>
          </div>
          <div className="work-icon-container">
            <img
              src={example}
              alt=""
              style={{ width: '40px', marginRight: '45px', marginBottom: '3px' }}
            />
          </div>
        </div>
      </div>
      <Collapse
        isOpen={
          collapse.collapse2 &&
          !!socialJobItem?.accept_date &&
          socialJobItem?.status !== 'CANCEL'
        }
        className="job-detail-collapse"
        onEntering={() => {
          setIsCollapseOpen(true);
        }}
        onExited={() => {
          setIsCollapseOpen(false);
        }}
      >
        {!(marketerJob.job_type === 'PUBLISH' && !socialJobItem.approve_job_date) ? (
          <div className="influencer-card">
            {collapse.collapse2 && socialJobItem?.accept_date && (
              <>
                <label
                  htmlFor="upload images"
                  style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  {!socialJobItem?.marketer_approve_date && (
                    <span style={{ color: 'red' }}> * </span>
                  )}
                  {t('marketer:job_detail:* Your upload images')}
                </label>
                <span style={{ color: 'red', marginLeft: '0.5rem' }}>
                  ({t('For the marketer to review your work')})
                </span>
                <JobDetailImagePreview
                  items={socialJobItem}
                  socialJobId={socialJobId}
                  collapse2={collapse.collapse2}
                  newImageKeys={imageKeys}
                  setNewImageKeys={setImageKeys}
                />
              </>
            )}
            {!socialJobItem?.marketer_approve_date && (
              <div
                style={{
                  cursor: `${
                    socialJobItem?.image_storages?.length >= 10
                      ? 'no-drop'
                      : 'pointer'
                  }`,
                  width: '100%',
                  border: '1px solid rgb(148, 157, 157)',
                  borderRadius: '10px',
                  margin: '10px 0',
                }}
                onClick={() => {
                  fileInputRef?.current?.click();
                }}
              >
                <input
                  onChange={async (e: any) => {
                    await handleFileSelected(e);
                  }}
                  ref={fileInputRef}
                  disabled={socialJobItem?.image_storages?.length >= 10}
                  type="file"
                  multiple
                  style={{
                    display: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                />

                <div className="text-center">
                  <div>
                    <i
                      className="display-6 text-muted bx bxs-cloud-upload me-4 "
                      style={{ display: 'inline-block' }}
                    />
                    <h4 style={{ display: 'inline-block' }}>
                      {t('creative:Drop files here or click to upload')}
                    </h4>
                  </div>
                  <h6 style={{ color: 'red' }}>
                    {t('marketer:job_detail:**Up to 10 images can be added.')}
                  </h6>
                </div>
              </div>
            )}
            {!socialJobItem?.marketer_approve_date ? (
              <>
                <label
                  htmlFor="description"
                  style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  <span style={{ color: 'red' }}> * </span>
                  {socialContent(
                    socialJobItem?.social_media_info?.social_media_type,
                  )}
                </label>
                <Tooltip
                  title={t(
                    'marketer:create_job_campaign.Post caption for example, Flupower collects websites and influencers advertising through cryptocurrency.',
                  )}
                  placement="right"
                >
                  <i
                    className="fas fa-info-circle ms-2 font-size-16"
                    style={{
                      cursor: 'pointer',
                    }}
                    id="tooltipInfo"
                  />
                </Tooltip>
                <textarea
                  name="description"
                  id="description"
                  onChange={(e: any) => {
                    setExampleMessage(e.target.value);
                  }}
                  placeholder={t('marketer:job_detail.Enter your post caption here')}
                  value={exampleMessage || ''}
                  style={{
                    width: '100%',
                    height: '100px',
                    borderRadius: '5px',
                    border: '1px solid #949d9d',
                    padding: '10px 20px',
                    outline: 'none',
                  }}
                />
                <div
                  className="d-flex justify-content-center align-items-center w-100"
                  style={{ height: 'fit-content' }}
                >
                  <CustomButton
                    handleClick={async (e: any) => {
                      await Swal.fire({
                        width: 300,
                        title: t('swal:Are you sure ?'),
                        text: t("swal:You won't be able to revert this!"),
                        imageUrl: choice,
                        imageHeight: 200,
                        imageAlt: 'A tall image',
                        customClass: {},
                        cancelButtonColor: 'red',
                        showCancelButton: true,
                        confirmButtonColor: '#009a78',
                        confirmButtonText: t('button:Yes'),
                        cancelButtonText: t('button:No'),
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          e.preventDefault();
                          onSubmitExample();
                        }
                      });
                    }}
                    size="s"
                    status="submit"
                    label={
                      !socialJobItem?.influencer_submit_date
                        ? t('marketer:job_detail:Send')
                        : t('marketer:job_detail:Update')
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <label
                  htmlFor="description"
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginTop: '5px',
                    marginBottom: '0',
                  }}
                >
                  {socialContent(
                    socialJobItem?.social_media_info?.social_media_type,
                  )}
                </label>
                <div style={{ fontSize: '1.05rem', wordBreak: 'break-all' }}>
                  <ShowMoreText
                    lines={3}
                    more="See more"
                    less="See less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    truncatedEndingComponent="... "
                  >
                    {exampleMessage}
                  </ShowMoreText>
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            style={{
              height: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'green',
            }}
          >
            {t('marketer:job_detail:Waiting for marketer approve job')}
          </div>
        )}
      </Collapse>
      {/* {!collapse.collapse2 && <hr />} */}
    </div>
  );
};

export default JobDetailCollapse2;
