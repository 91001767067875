import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FormGroup, CardBody, Card, CardHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import { adImageSizesWhereGraphql } from 'src/graphql/advertiser/creative';
import { useParams } from 'react-router';
import choice from 'src/assets/images/choice.png';
import { createApplicationCreative } from 'src/graphql/publisher/application';
import { getApplicationCreativeFilter } from 'src/store/actions';
import { useDispatch } from 'react-redux';
import Dropdown from '../CommonForBoth/Menus/Dropdown';
import useWindowDimensions from '../useWindowDimensions';

const ApplicationCreativeSize = ({ t, appCreativeFilter }: any) => {
  const { id }: { id: string } = useParams();
  const [creativeSize, setCreativeSize] = useState([]);
  const [creativeSizeSelected, setCreativeSizeSelected] = useState<any>(null);
  const [bannerFormat, setBannerFormat] = useState('');
  const dispatch = useDispatch();
  const { windowWidth } = useWindowDimensions();

  const [size, setSize]: any = useState({
    width: 0,
    height: 0,
  });

  const banner_format = [
    {
      value: 'IN_PAGE',
      label: t('creative:In-Page'),
    },
    {
      value: 'STICKY',
      label: t('creative:Sticky'),
    },
    {
      value: 'HEADER',
      label: t('creative:Premium Header'),
    },
    {
      value: 'SLIDE',
      label: t('creative:Slide'),
    },
  ];

  async function fetchImageSizes(ad_image_type: any) {
    const result = await adImageSizesWhereGraphql({
      ad_image_type: `BANNER_${ad_image_type}`,
      device_platform: 'MOBILE',
    });

    const sizeOption: any = [];
    if (Object.keys(result)?.length > 0) {
      const newSize: string[] = [];
      result.forEach((option: { width: number; height: number }) => {
        if (!newSize.includes(`${option.width}x${option.height}`)) {
          newSize.push(`${option.width}x${option.height}`);

          sizeOption.push({
            label: `${option.width}x${option.height}`,
            value: `${option.width}x${option.height}`,
          });
        }
      });
    }
    setCreativeSize(sizeOption);
  }

  const handleBannerFormatChange = async (event: any) => {
    fetchImageSizes(event.value);
    setSize({
      width: 0,
      height: 0,
    });
    setBannerFormat(event);
  };

  const handleSizeChange = (event: any) => {
    setCreativeSizeSelected(event);
  };

  const onSubmit = async () => {
    Swal.fire({
      title: t('swal:Are you sure'),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const input: any = {
          application_key: id,
          width: +size.width,
          height: +size.height,
          banner_format: (bannerFormat as any).value,
        };
        await createApplicationCreative(input);
        dispatch(getApplicationCreativeFilter(appCreativeFilter));
      }
    });
  };

  useEffect(() => {
    setCreativeSizeSelected(null);
    setSize({
      width: 0,
      height: 0,
    });
  }, [bannerFormat]);

  useEffect(() => {
    if (bannerFormat && creativeSizeSelected) {
      const newSize = creativeSizeSelected?.value;
      const adSize = newSize.split('x');
      setSize({
        width: adSize[0],
        height: adSize[1],
      });
    }
  }, [creativeSizeSelected]);

  return (
    <Card>
      <CardHeader>
        <h5>{t('Page.Request Creative')}</h5>
      </CardHeader>
      <CardBody>
        <FormGroup className={`mb-3 ${windowWidth > 1234 && 'w-80-per'}`}>
          <label
            htmlFor="Banner format"
            style={{ fontWeight: 'bold' }}
            className="input-label"
          >
            {t('creative:Banner format')}
          </label>
          <Dropdown
            options={banner_format}
            action={handleBannerFormatChange}
            placeholder={t('Placeholder.selectBannerFormat')}
            useValue
            value={bannerFormat}
          />
        </FormGroup>
        <FormGroup className={`${windowWidth > 1234 && 'w-80-per'}`}>
          <label
            htmlFor="Creative size"
            style={{
              fontWeight: 'bold',
            }}
            className="input-label"
          >
            {t('Form.Creative size')}
          </label>

          <Dropdown
            options={creativeSize}
            action={handleSizeChange}
            placeholder={t('Placeholder.selectAdsSize')}
            useValue
            value={creativeSizeSelected}
          />
          {!(size.width && size.height) && bannerFormat && (
            <div style={{ color: 'red', marginBottom: '0' }} className="mt-2">
              <span className="invalid-message ">
                {t('Please select creative size')}
              </span>
            </div>
          )}
        </FormGroup>

        <FormGroup>
          <button
            onClick={onSubmit}
            type="button"
            className="btn btn-primary mt-4"
            disabled={!(size.width && size.height)}
          >
            {t('button:Request')}
          </button>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(ApplicationCreativeSize);
