/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export enum UsersTypes {
  GET_USERS = '@@users/GET_USERS',
  GET_USERS_SUCCESS = '@@users/GET_USERS_SUCCESS',
  GET_USERS_FAIL = '@@users/GET_USERS_FAIL',

  GET_USER_DETAIL = '@@users/GET_USER_DETAIL',
  GET_USER_DETAIL_SUCCESS = '@@users/GET_USER_DETAIL_SUCCESS',

  CHANGE_TIMEZONE = '@@users/CHANGE_TIMEZONE',

  CREATE_USER_INFO = '@@users/CREATE_USER_INFO',
  CREATE_USER_INFO_SUCCESS = '@@users/CREATE_USER_INFO_SUCCESS',

  GET_USER_INFO = '@@users/GET_USER_INFO',
  GET_USER_INFO_SUCCESS = '@@users/GET_USER_INFO_SUCCESS',

  UPDATE_USER_INFO = '@@users/UPDATE_USER_INFO',
  UPDATE_USER_INFO_SUCCESS = '@@users/UPDATE_USER_INFO_SUCCESS',

  UPLOAD_PROFILE = '@@users/UPLOAD_PROFILE',
  UPLOAD_PROFILE_SUCCESS = '@@users/UPLOAD_PROFILE_SUCCESS',

  REMOVE_USER_STORE = '@@users/REMOVE_USER_STORE',

  DELETE_2FA = '@@/users/DELETE_2FA',
}
