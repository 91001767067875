/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import './CreateMarketerPublicJob.style.scss';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SortByStyle from 'src/components/Advertiser/Models/SelectInfuencer/SortBy.style';
import { ReactFlagsSelectCountry } from 'src/utils/country';
import { getAllAptitude } from 'src/store/actions';
import ReactFlagsSelect from 'react-flags-select';
import InfluencerChoiceComponent from 'src/components/Advertiser/Models/SelectInfuencer/SortByInfuencer/Influencer/InfluencerChoice.component';
import mockFormData from 'src/pages/mock/mockJobRequirement/mockFormData';
import CustomButton from 'src/components/Common/CustomButton';
import lgbtIcon from 'src/assets/images/lgbt_icon.svg';
import menIcon from 'src/assets/images/men_icon.svg';
import womenIcon from 'src/assets/images/women_icon.svg';
import { Label } from 'reactstrap';

const DivContent = styled.div`
  // overflow: auto;
  height: 100%;
  text-align: center;
  // margin-bottom: 5rem;
  // background-color: #fbfaff;
`;

const Texts = styled.p<{
  fontsize?: string;
  // background?: string;
  height?: string;
  margin?: string;
  fontweight?: string;
}>`
  font-size: ${({ fontsize }) => fontsize};
  font-weight: ${({ fontweight }) => fontweight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: ${({ margin }) => margin};
`;
const Select = styled.select<{
  width?: string;
  height?: string;
  borderradius?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: auto;
  cursor: pointer;
  // background-color: #fbfaff;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 0.2rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 90%;
  height: 100px;
  margin: -10px auto 10px;
  // background-color: red;
`;

const Option = styled.option<{
  width?: string;
  height?: string;
  borderradius?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: auto;
  background-color: #fbfaff;
  :hover {
    background-color: #abb2b9;
  }
`;

const Btn = styled.button<{
  width?: string;
  height?: string;
  borderradius?: string;
  background?: string;
}>`
  // position: absolute;
  // object-fit: cover;
  border-radius: ${({ borderradius }) => borderradius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 20px auto;
  background-color: ${({ background }) => background};
  border: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  :hover {
    background-color: #0a598a;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
`;
// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyType {
  // eslint-disable-next-line no-unused-vars
  FOLLOWER = 'FOLLOWER',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'INFLUENCER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum County {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  Thailand = 'Thailand',
  // eslint-disable-next-line no-unused-vars
  OTHER = 'OTHER',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'MEN',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'WOMEN',
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyInfluencer {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  FOLLOWERS = 'follower',
  // eslint-disable-next-line no-unused-vars
  RATING = 'rating',
  // eslint-disable-next-line no-unused-vars
  COST = 'photo_content_price',
}
// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
  IS_LGBT,
}
interface FormControl {
  label: string;
  color: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  value: string;
  icon: any;
}
const formControls: FormControl[] = [
  {
    label: 'Men',
    name: 'fas fa-mars',
    color: '#ABC9FF',
    icon: menIcon,
    type: FormInputType.STRING,
    required: true,
    value: 'isMale',
  },
  {
    label: 'Women',
    name: 'fas fa-venus',
    value: 'isFeMale',
    color: '#FF8B8B',
    icon: womenIcon,
    type: FormInputType.STRING,
    required: true,
  },
  {
    label: 'lgbt',
    name: 'fas fa-transgender-alt',
    value: 'lgbt',
    color: '#f7df81',
    type: FormInputType.IS_LGBT,
    required: true,
    icon: lgbtIcon,
  },
  // {
  //   label: 'lgbt',
  //   name: 'fas fa-transgender-alt',
  //   value: 'LGBT',
  //   color: '#f7df81',
  //   type: FormInputType.IS_LGBT,
  //   required: true,
  // },
];
// interface FormData {
//   minPrice: number;
//   maxPrice: number;
//   ageRange: string;
//   gender: string;
//   sexualOrientation: string;
// }
const { ModalStyle, FlexRow, FlexCol, Radio, CloseBtn } = SortByStyle();
// const CreateMarketerPublicJob: React.FC = () => {
//   const [formData, setFormData] = useState<FormData>({
//     minPrice: 0,
//     maxPrice: 0,
//     ageRange: '',
//     gender: '',
//     sexualOrientation: '',
//   });

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

const CreateMarketerPublicJob = () => {
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
    aptitudeData: state.SocialMediaInfo.aptitudes,
  }));

  useEffect(() => {
    dispatch(getAllAptitude());

    // console.log(opt);
  }, []);

  const price =
    marketerJob?.content_type === 'SHARE'
      ? 'share_content_price'
      : 'photo_content_price';
  const ageRange: { label: string; min_age: number; max_age: number }[] = [
    { label: t('sort.All'), min_age: -1, max_age: 120 },
    { label: '< 18', min_age: 0, max_age: 17 },
    { label: '18-24', min_age: 18, max_age: 24 },
    { label: '25-34', min_age: 25, max_age: 34 },
    { label: '35-44', min_age: 35, max_age: 44 },
    { label: '45-54', min_age: 45, max_age: 54 },
    { label: '55-64', min_age: 55, max_age: 64 },
    { label: '> 65', min_age: 65, max_age: 120 },
  ];
  const propertyInfluencer: { label: string; value: any }[] = [
    { label: t('sort.All'), value: PropertyInfluencer.ALL },
    { label: t('sort.Follower'), value: PropertyInfluencer.FOLLOWERS },
    { label: t('sort.Rating'), value: PropertyInfluencer.RATING },
    { label: t('sort.Price'), value: price },
  ];

  // const AllChoice = () => {
  //   const all = [...CategoriesMarketerCampaign].map((data: any) => data.value);

  //   setCheckAllProperty(!checkAllProperty);
  //   if (!checkAllProperty) {
  //     setFilterForm({ ...filterForm, aptitude_ids: all });
  //   } else {
  //     setFilterForm({ ...filterForm, aptitude_ids: [] });
  //   }
  // };
  const { formData, handleChange, handleSubmit, setFormData } = mockFormData();
  useEffect(() => {
    // if (formData) {
    const ele: any = document.getElementById('ageRange');
    // const opt = ele.options[0];
    // opt.value = '25-34';

    ele.value = formData.ageRange;
    // }
  }, []);

  const resetCountry = () => {
    setFormData({ ...formData, country: 'ALL' });
    setSelected('');
  };

  return (
    <div className="page-content create-marketer-public-container">
      <div className="card-public-container">
        <DivContent>
          <Texts fontsize="20px" margin="30px auto">
            {t('marketer:create_job_campaign:Set your budget')}
          </Texts>
          <div className="card-public-label">
            <div className="card-public-min-max">
              <label htmlFor="minPrice">{t('Minimum price')} :</label>
              <input
                min={0}
                type="number"
                id="minPrice"
                name="minPrice"
                style={{ width: '5rem', textAlign: 'center' }}
                value={formData.minPrice}
                onChange={handleChange}
                onWheel={(event) => event.currentTarget.blur()}
              />
              <label>USDT</label>
            </div>
            <div className="card-public-min-max">
              <label htmlFor="maxPrice">{t('Maximum price')} :</label>
              <input
                min={0}
                type="number"
                id="maxPrice"
                name="maxPrice"
                style={{
                  width: '5rem',
                  textAlign: 'center',
                }}
                value={formData.maxPrice}
                onChange={handleChange}
                onWheel={(event) => event.currentTarget.blur()}
              />
              <label>USDT</label>
            </div>
          </div>

          <div
            style={{
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
          >
            <FlexCol>
              <Texts margin="20px 10px 10px 10px" fontsize="20px">
                {t('marketer:create_job_campaign:Influencer_country')}
              </Texts>

              <div style={{ width: '250px', marginTop: '12px', textAlign: 'end' }}>
                <ReactFlagsSelect
                  searchable
                  placeholder={
                    formData?.country !== 'ALL'
                      ? formData?.country
                      : t('marketer:create_job_campaign:Search all country')
                  }
                  selectedSize={16}
                  optionsSize={16}
                  searchPlaceholder={t(
                    'marketer:create_job_campaign:Search country',
                  )}
                  onSelect={(label: any) => {
                    const country: any = ReactFlagsSelectCountry;
                    setFormData({ ...formData, country: country[label] });
                    // console.log(country[label]);
                    setSelected(label);
                    const rfsBtn = document.getElementById('rfs-btn');
                    if (!rfsBtn) return;
                    rfsBtn.click();
                  }}
                  showOptionLabel
                  selected={selected}
                />
                <span
                  style={{
                    textDecoration: 'underline',
                    color: 'rgb(0, 201, 255)',
                    cursor: 'pointer',
                  }}
                  onClick={resetCountry}
                >
                  {t('Reset Country')}
                </span>
              </div>
            </FlexCol>

            <FlexCol margin="20px 0">
              <Texts fontsize="20px">
                {t('marketer:create_job_campaign:Gender')}
              </Texts>
              <FlexRow justifycontent="flex-start" width="50%">
                <GridBox style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* {formControls.map((data: any, index) => (
                    <IconRadioInputComponent
                      id={index.toString()}
                      icon={data.name}
                      label={t(`marketer:create_job_campaign:${data.label}`)}
                      background={data.color}
                      labelSize="1em"
                      isChecked={formData[data?.value]}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          [data.value]: !formData[data?.value],
                        });
                      }}
                    />
                  ))} */}
                  <div className="radio-container">
                    {formControls.map((data) => {
                      const checked = formData[data?.value];
                      const onClick = () => {
                        setFormData({
                          ...formData,
                          [data.value]: !formData[data?.value],
                        });
                      };
                      return data.label !== 'lgbt' ? (
                        <div
                          className="radio radio-input"
                          id="gender"
                          onClick={onClick}
                        >
                          <input
                            id={`radio ${data.value}`}
                            type="radio"
                            checked={checked}
                          />

                          <img
                            src={data.icon}
                            alt="icon radio"
                            className="image-icon"
                            style={{
                              background: data.color,
                            }}
                          />
                          <i className="fas fa-check " />
                          <Label for={`radio ${data.value}`}>{t(data.label)}</Label>
                        </div>
                      ) : (
                        <div className="border-left">
                          <div className="radio radio-input" onClick={onClick}>
                            <input id="radio lgbt" type="radio" checked={checked} />
                            <img
                              className="image-full"
                              src={lgbtIcon}
                              alt="icon radio"
                            />
                            <i className="fas fa-check" />
                            <Label for="radio lgbt">{t('LGBT')}</Label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="radio-container">
                    <div className="border-left">
                      <div className="radio radio-input">
                        <input id="radio lgbt" type="radio" />
                        <img
                          className="image-full"
                          src={lgbtIcon}
                          alt="icon radio"
                        />
                        <i className="fas fa-check" />
                        <Label for="radio lgbt">{t('LGBT')}</Label>
                      </div>
                    </div>
                  </div> */}
                </GridBox>
              </FlexRow>
            </FlexCol>
            <FlexCol>
              <Texts fontsize="20px">{t('marketer:create_job_campaign:Age')}</Texts>
              <FlexCol width="100%">
                <Select
                  id="ageRange"
                  width="40%"
                  height="50px"
                  style={{ textAlign: 'center', fontSize: '16px' }}
                  borderradius="5px"
                  onChange={(e: any) => {
                    setFormData({
                      ...formData,
                      ageRange: e.target.value,
                    });
                  }}
                >
                  {ageRange.map((value: any) => (
                    <Option
                      value={value.label}
                      width="100%"
                      height="30px"
                      selected={formData.ageRange === value.label}
                    >
                      {value.label}
                    </Option>
                  ))}
                </Select>
                <div
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    margin: '1rem 0 0 0',
                  }}
                >
                  {t('marketer:create_job_campaign:note')} :{' '}
                  {t('marketer:create_job_campaign:select_age_influencers')}
                </div>
              </FlexCol>
            </FlexCol>

            <InfluencerChoiceComponent
              form={formData}
              setForm={setFormData}
              property={propertyInfluencer}
              toggle
            />

            <FlexCol width="100px" style={{ margin: 'auto' }}>
              <div style={{ marginBottom: '2rem' }}>
                <CustomButton
                  handleClick={(e: any) => {
                    handleSubmit(e);
                  }}
                  size="s"
                  status="submit"
                  label={t('marketer:create_job_campaign:Submit')}
                />
              </div>
            </FlexCol>
          </div>
        </DivContent>
      </div>
    </div>
  );
};

export default CreateMarketerPublicJob;
