import React from 'react';
import { render } from 'react-dom';

// Import Apollo Client
import { ApolloProvider } from '@apollo/client';

import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { client } from './graphql/client';
import configureStore from './store/index';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from './components/Common/Loading';

const { store, persistor } = configureStore({});

render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Loading />
            <ToastContainer />
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);
