import { isEmpty } from 'lodash';
import { type } from 'os';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { getDepositAddressByUUID } from 'src/store/actions';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import CoinImage from '../Common/CoinImage';

const DepositAddressTable = ({ t, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  const [param, setParam] = useState<any>({
    uuid: userId,
    limit: 10,
    page: 1,
  });

  const { txn } = useSelector((state: any) => ({
    txn: state.DepositAddresses.addresses,
  }));

  useEffect(() => {
    dispatch(getDepositAddressByUUID(userId));
  }, []);

  // const handlePageChange = (e: any, value: any) => {
  //   const newPage = value ? parseInt(value, 10) : page;

  //   const payload = {
  //     ...param,
  //     page: newPage,
  //   };

  //   dispatch(getSocialMediaInfoByUUID(payload));
  //   setPage(newPage);
  //   setParam(payload);
  // };
  // const handlePageSizeChange = (size: string) => {
  //   const newPageSize = parseInt(size, 10);

  //   const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
  //   const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

  //   const payload = {
  //     ...getDefaultPayload(newPage),
  //     limit: newPageSize,
  //   };

  //   dispatch(getSocialMediaInfoByUUID(payload));

  //   setPageSize(newPageSize);
  // };

  const getDefaultPayload = (pages: number) => {
    setPage(pages);

    return {
      uuid: userId,
      limit: pageSize,
      page,
      name: search,
    };
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,
        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('address'),
        accessor: ({ address }: any) => address,
        id: 'address',
        disableSort: true,
        width: 150,
      },
      {
        Header: t('symbol'),
        accessor: ({ currency }: any) => currency.symbol,
        id: 'currency.symbol',
        disableSort: true,
        width: 100,
      },
      {
        Header: t(`main symbol`),
        accessor: ({ currency }: any) => (
          <CoinImage symbol={currency.main_symbol} size="md" />
        ),
        id: 'currency.main_symbol',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('network'),
        accessor: ({ currency }: any) => currency.network,
        id: 'currency.network',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('rate_in_usd'),
        accessor: ({ currency }: any) => currency.rate_in_USD,
        id: 'currency.rate_in_usd',
        width: 100,
        disableSort: true,
      },
    ];
  }, [t, txn]);

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn : [];
  }, [txn]);

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  // const handleSort = useCallback(
  //   (sorting: any) => {
  //     const newSortBy = sorting[0] ? sorting[0].id : '';
  //     const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

  //     const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

  //     const payload = {
  //       ...getDefaultPayload(newPage),
  //       sortBy: newSortBy,
  //       sortType: newSortType,
  //     };

  //     dispatch(getSocialMediaInfoByUUID(payload));
  //     setSortBy(newSortBy);
  //     setSortType(newSortType);
  //     setParam(payload);
  //   },
  //   [search, page, pageSize],
  // );

  if (!txn) return <></>;

  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        // page={page}
        // pageSize={pageSize}
        // total={txn.totalCount}
        // handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        // onSort={handleSort}
      >
        {/* <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              // handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
        </Row> */}
      </CustomTable>
    </>
  );
};

export default withTranslation()(DepositAddressTable);
