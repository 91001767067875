/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Field } from 'formik';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Tooltip } from 'reactstrap';
import './FloatingInput.style.scss';

interface FloatingInputProps {
  name?: string;
  value?: string | number;
  isInvalid?: boolean;
  label: string;
  onChange?: Function;
  className?: any;
  style?: any;
  disabled?: boolean;
  type?: string;
  isRequired?: boolean;
  tooltip?: string;
}

const FloatingInputComponent = (props: FloatingInputProps) => {
  const { t } = useTranslation();
  const {
    name,
    isInvalid,
    label,
    onChange,
    value,
    className,
    style,
    disabled,
    type,
    isRequired,
    tooltip,
  } = props;

  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);

  const [inputHeight, setInputHeight] = useState<number>(48);
  const inputHeightFocus = 48;
  const [inputHeightCal, setInputHeightCal] = useState<number>(48);

  const topFocusNoValue: number = -35;
  const [topFocus, setTopFocus] = useState<number>(-35);
  const [topFocusInvalid, setTopFocusInvalid] = useState<number>(-35);
  const [topUnFocus, setTopUnFocus] = useState<number>(-45);

  const [customProperty, setCustomProperty] = useState<any>({
    '--topFocus': `${topFocus}%`,
    '--topFocusInvalid': `${topFocusInvalid}%`,
    '--topUnFocus': `${topUnFocus}%`,
    '--topFocusNoValue': `${topFocusNoValue}%`,
  });

  const [rows, setRows] = useState<number>(0);

  const ref = useRef<any>();
  // --top-focus=5, --top-un-focus
  useLayoutEffect(() => {
    if (ref.current) {
      const refWidth: number = ref.current.clientWidth;
      const labelLength: number = label?.length;
      const numberTextLayer: number = Math.ceil(labelLength / (refWidth / 8));
      const height: number = 48 * numberTextLayer - 26 * (numberTextLayer - 1);
      setInputHeightCal(height);
      if (!value) setInputHeight(height);

      if (numberTextLayer > 1) {
        const topFocusValue: number = topFocus + numberTextLayer * 3.33333333;
        const topFocusInvalidValue: number =
          topFocusInvalid + numberTextLayer * 1.66666667;
        const topUnFocusValue: number = -45;
        // const topUnFocusValue: number = topUnFocus + numberTextLayer * 5;

        setTopFocus(topFocusValue);
        setTopFocusInvalid(topFocusInvalidValue);
        setTopUnFocus(topUnFocusValue);
        setCustomProperty({
          '--topFocus': `${topFocusValue}%`,
          '--topFocusInvalid': `${topFocusInvalidValue}%`,
          '--topUnFocus': `${topUnFocusValue}%`,
          '--topFocusNoValue': `${topFocusNoValue}%`,
        });
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const textRowCount = value ? value.toString().split('\n')?.length : 0;
      setRows(textRowCount + 1);
    }, 100);
  }, [value]);

  const actionOnTextArea = (e: any) => {
    if (e.key !== 'Enter' && e.key !== 'Backspace') return;

    setTimeout(() => {
      const textRowCount = value ? value.toString().split('\n')?.length : 0;

      if (e.key === 'Backspace') setRows(textRowCount);
      else setRows(textRowCount + 1);
    }, 100);
  };

  const checkingTextLength = () => {
    return (
      <p
        style={{
          textAlign: 'end',
          fontSize: '0.8rem',
          fontWeight: 'lighter',
        }}
      >
        {t('number of characters')}: {value?.toString()?.length}/255
      </p>
    );
  };

  return (
    <>
      <div
        ref={ref}
        className="floating-input-container"
        style={type === 'textarea' ? {} : customProperty}
      >
        {type === 'textarea' ? (
          <>
            <div
              style={{
                display: 'flex',
                background: 'white',
                alignItems: 'center',
                minHeight: '40px',
                padding: '10px 0',
              }}
            >
              <label
                id="tooltipInfo"
                htmlFor="textareaInput"
                title={t(label)}
                className="text-area-label"
                style={{ lineHeight: '0.9' }}
              >
                {t(label)}
                {isRequired ? (
                  <span style={{ color: 'red', marginLeft: '0.3rem' }}>*</span>
                ) : (
                  <></>
                )}
              </label>
              {tooltip ? (
                <>
                  <i
                    id={name || 'Tooltip'}
                    className="far fa-question-circle info-icon"
                  />
                  <Tooltip
                    placement="right"
                    target={name || 'Tooltip'}
                    style={{ background: '#6D6D6D' }}
                    isOpen={toggleTooltip}
                    toggle={() => setToggleTooltip(!toggleTooltip)}
                  >
                    <span>{t(tooltip || '-')}</span>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="form-float scheme-des">
              <textarea
                name={name}
                id="textareaInput"
                rows={rows}
                placeholder=" "
                value={value}
                className={`w-100 ${className} ${
                  isInvalid ? 'is-invalid input-error' : ''
                }`}
                onKeyDown={actionOnTextArea}
                onKeyPress={actionOnTextArea}
                onChange={(e: any) => {
                  if (e.target.value?.length > 255) {
                    e.target.value = e.target.value.slice(0, 255);
                  }
                  if (onChange) onChange(e);
                }}
                style={{
                  height: '90px',
                  fontSize: '13px',
                  fontFamily: 'sans-serif',
                  paddingTop: '4px',
                }}
              />
            </div>
          </>
        ) : type === 'address_text_area' ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                background: 'black',
              }}
            >
              <label
                id="tooltipInfo"
                htmlFor="textareaInput"
                title={t(label)}
                className="text-area-label"
              >
                {t(label)}
                {isRequired ? (
                  <span style={{ color: 'red', marginLeft: '0.3rem' }}>*</span>
                ) : (
                  <></>
                )}
              </label>
              {tooltip ? (
                <>
                  <i
                    id={name || 'Tooltip'}
                    className="far fa-question-circle info-icon"
                  />
                  <Tooltip
                    placement="right"
                    target={name || 'Tooltip'}
                    isOpen={toggleTooltip}
                    toggle={() => setToggleTooltip(!toggleTooltip)}
                  >
                    <span>{t(tooltip || '-')}</span>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="form-float scheme-des">
              <textarea
                name={name}
                id="textareaInput"
                rows={rows}
                placeholder=" "
                value={value}
                className={`w-100 ${className} ${
                  isInvalid ? 'is-invalid input-error' : ''
                }`}
                onKeyDown={actionOnTextArea}
                onKeyPress={actionOnTextArea}
                onChange={(e: any) => {
                  if (e.target.value?.length > 255) {
                    e.target.value = e.target.value.slice(0, 255);
                  }
                  if (onChange) onChange(e);
                }}
                style={{
                  height: '180px',
                }}
              />
            </div>
          </>
        ) : (
          <>
            <Field
              maxlength="255"
              id="input"
              disabled={disabled || false}
              name={name}
              value={value}
              as={type}
              className={[className, `w-100 ${isInvalid ? 'is-invalid' : ''}`]}
              style={{
                ...style,
                ...{
                  height: `${inputHeight}px`,
                  // marginTop: '25px',
                },
              }}
              placeholder=""
              onFocus={() => {
                setInputHeight(inputHeightFocus);
              }}
              onBlur={() => {
                if (!value) setInputHeight(inputHeightCal);
              }}
              onChange={(e: any) => {
                if (e.target.value?.length > 255) {
                  e.target.value = e.target.value.slice(0, 255);
                }
                if (onChange) onChange(e);
              }}
            />
            <Label id={name || 'Tooltip'} htmlFor="input" title={t(label)}>
              {t(label)}
              {isRequired ? (
                <>
                  <span style={{ color: 'transparent', marginLeft: '0.3rem' }}>
                    *
                  </span>
                </>
              ) : (
                <></>
              )}
            </Label>
          </>
        )}
      </div>
      {(type === 'textarea' || type === 'address_text_area') && checkingTextLength()}
    </>
  );
};

export default FloatingInputComponent;
