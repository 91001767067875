import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Row } from 'reactstrap';

const CardLoading = () => {
  return (
    <Row className="loading-row py-5 justify-content-center align-items-center">
      <ScaleLoader
        color="#009a78"
        height={35}
        width={4}
        radius={2}
        margin={2}
        loading
      />
    </Row>
  );
};

export default CardLoading;
