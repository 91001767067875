import ContentType from 'src/constants/enum/contentType';

interface Form {
  [key: string]: {
    icon: string;
    iconColor?: string;
    label: string;
    price: number | string;
    content_type: ContentType;
  };
}

const squareRoot = (num: number, square: number) => num ** (1 / square);
const getBaseLog = (num: number, base: number) => Math.log(num) / Math.log(base);
const exponent = (num: number, pow: number) => num ** pow;

// const calculateFollowerPrice = (
//   num: number,
//   start_rank: number,
//   end_rank: number,
// ) => {
//   // \left(\left(1.185\log \left(\sqrt{500000}\right)\right)^{4.15}\right)

//   let start_rank_control = 0;
//   const startDigi = start_rank / 10 - 0.5;
//   if (startDigi > 0) {
//     start_rank_control = startDigi * 10 * 0.1262;
//   } else {
//     start_rank_control = startDigi * 10 * 0.0828;
//   }

//   const endDigi = end_rank / 10 - 0.5;
//   let end_rank_control = 0;
//   if (endDigi > 0) {
//     end_rank_control = endDigi * 10 * 0.0157;
//   } else {
//     end_rank_control = endDigi * 10 * 0.0213;
//   }

//   const sqr = squareRoot(num, 2);
//   const log = getBaseLog(sqr, 10);
//   const multi = log * 1.185 + start_rank_control - end_rank_control;
//   const expo = exponent(multi, 4.15 - startDigi + endDigi);
//   return expo;
// };

const calculateFollowerPrice = (num: number, pow: number) => {
  // \left(\left(1.185\log \left(\sqrt{500000}\right)\right)^{4.15}\right)
  const eq = exponent(num, pow);
  return eq;
};

const calculateEngagementPrice = (num: number) => {
  // \left(1.66\log _{20}\left(\sqrt[3]{55000}\right)\:\right)^{4.275}
  const newNum = num || 1;
  const sqr = squareRoot(newNum, 3);
  const log = getBaseLog(sqr, 20);
  const multi = log * 1.66;
  const expo = exponent(multi, 4.275);
  return expo;
};

export const calculateInfluencerPrice = ({
  follower,
  engagement,
  multiply,
  engagement_multiply,
  power,
}: any) => {
  const rawPrice = calculateFollowerPrice(follower, power);
  const rawEngagementPrice = calculateEngagementPrice(engagement);
  const price = rawPrice * multiply;
  const engagementPrice = rawEngagementPrice * engagement_multiply;

  return { price, engagementPrice, totalPrice: price + engagementPrice };
};

// enum ContentType {
//   SHARE = 'SHARE',
//   PHOTO = 'PHOTO',
//   VIDEO = 'VIDEO',
//   LIVE = 'LIVE',
//   LIKE_AND_COMMENT = 'LIKE_AND_COMMENT',
//   REVIEW = 'REVIEW',
//   SUBSCRIBE = 'SUBSCRIBE',
//   LIKE_PAGE = 'LIKE_PAGE',
//   SHARE_GROUP = 'SHARE_GROUP',
// }

const ContentPriceControl = () => {
  const initialStateForm: Form = {
    like_and_comment_content_price: {
      icon: 'fas fa-camera-retro',
      iconColor: '#F08080',
      label: 'Like and Comment',
      price: 0,
      content_type: ContentType.LIKE_AND_COMMENT,
    },
    review_content_price: {
      icon: 'fas fa-camera-retro',
      iconColor: '#F08080',
      label: 'Review',
      price: 0,
      content_type: ContentType.REVIEW,
    },
    share_content_price: {
      icon: 'fas fa-share-alt',
      iconColor: '#6495ED',
      label: 'Share',
      price: 0,
      content_type: ContentType.SHARE,
    },
    photo_content_price: {
      icon: 'fas fa-camera-retro',
      iconColor: '#F08080',
      label: 'Post',
      price: 0,
      content_type: ContentType.PHOTO,
    },
    like_page_content_price: {
      icon: 'fas fa-camera-retro',
      iconColor: '#F08080',
      label: 'Like page',
      price: 0,
      content_type: ContentType.LIKE_PAGE,
    },
    share_group_content_price: {
      icon: 'fas fa-camera-retro',
      iconColor: '#F08080',
      label: 'Share to group',
      price: 0,
      content_type: ContentType.SHARE_GROUP,
    },
    subscribe_content_price: {
      icon: 'fas fa-camera-retro',
      iconColor: '#F08080',
      label: 'Subscribe',
      price: 0,
      content_type: ContentType.SUBSCRIBE,
    },
    video_content_price: {
      icon: 'fas fa-solid fa-video',
      iconColor: '#ee5555',
      label: 'Video',
      price: 0,
      content_type: ContentType.VIDEO,
    },
  };

  const calculatePrice = ({
    follower,
    engagement,
    multiply,
    start_rank,
    end_rank,
    engagement_multiply,
    share_multiply,
    post_multiply,
    video_multiply,
    power,
    offset,
  }: any) => {
    const { totalPrice } = calculateInfluencerPrice({
      follower: follower || 1,
      engagement,
      multiply: multiply || 5,
      start_rank: start_rank || 5,
      end_rank: end_rank || 5,
      engagement_multiply: engagement_multiply || 5,
      power: power || 0.5,
      offset: offset || 0,
    });

    return {
      share_price: totalPrice * share_multiply + parseFloat(offset),
      post_price: totalPrice * post_multiply + parseFloat(offset),
      video_price: totalPrice * video_multiply + parseFloat(offset),
    };
  };

  return {
    initialStateForm,
    calculatePrice,
  };
};

export default ContentPriceControl;
