import usFlag from '../assets/images/flags/us.jpg';
import thFlag from '../assets/images/flags/th.jpg';

const languages: Object = {
  en: {
    label: 'English',
    flag: usFlag,
  },
  th: {
    label: 'ไทย',
    flag: thFlag,
  },
};

export default languages;
