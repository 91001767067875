/* eslint-disable no-shadow */
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  getFaqsFilter as onGetFaqsFilter,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import choice from 'src/assets/images/choice.png';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import { removeFaq } from 'src/graphql/admin/faq';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBar';
import PageSizeOptions from '../Table/PageSizeOptions';
import FaqModal from '../Table/FaqModal';
import CreateFaqModal from '../Table/CreateFaqModal';

const FAQsTable = ({ t }: any) => {
  const dispatch = useDispatch();

  const { faqs } = useSelector((state: any) => ({ faqs: state.FAQs.faqs }));

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [faqDetail, setFaqDetail] = useState([]);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const getDefaultPayload = () => {
    return {
      limit: pageSize,
      page,
    };
  };

  const refreshData = () => {
    dispatch(onGetFaqsFilter(getDefaultPayload()));
  };

  useEffect(() => {
    dispatch(onGetFaqsFilter(getDefaultPayload()));
  }, []);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? +value : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };

    dispatch(onGetFaqsFilter(payload));
    setPage(newPage);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = +size;

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(),
      limit: newPageSize,
      page: newPage,
    };

    dispatch(onGetFaqsFilter(payload));
    setPageSize(newPageSize);
    setPage(newPage);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(),
      search: val,
      page: 1,
    };

    dispatch(onGetFaqsFilter(payload));
    setSearch(search);
    setPage(1);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(),
      search: '',
      page: 1,
    };

    dispatch(onGetFaqsFilter(payload));
    setSearch('');
    setPage(1);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        sortBy: newSortBy,
        sortType: newSortType,
        page: newPage,
      };

      dispatch(onGetFaqsFilter(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setPage(newPage);
    },
    [search, page, pageSize],
  );

  //table
  const columns: Column<any>[] = useMemo(() => {
    return [
      {
        Header: t('table:title'),
        accessor: ({ title }: { title: string }) => title,
        width: 100,
        id: 'title',
      },
      {
        Header: t('table:detail'),
        accessor: ({ detail }: { detail: string }) => detail,
        width: 500,
        id: 'detail',
      },

      {
        Header: t('table:Action'),
        accessor: (data) => (
          <UncontrolledDropdown>
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={async () => {
                  await Swal.fire({
                    title: t('swal:Are you sure ?'),
                    text: t("swal:You won't be able to revert this!"),
                    imageUrl: choice,
                    imageHeight: 200,
                    width: 300,
                    imageAlt: 'A tall image',
                    customClass: {},
                    cancelButtonColor: 'red',
                    showCancelButton: true,
                    confirmButtonColor: '#009a78',
                    confirmButtonText: t('button:Yes'),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      dispatch(setLoading());
                      await removeFaq(data.key);
                      refreshData();
                      dispatch(unsetLoading());
                    }
                  });
                }}
              >
                <i className="fas fa-trash icon-red" />
                &nbsp;&nbsp;{t('table:Delete')}
              </DropdownItem>
              <div className="dropdown-divider" />
              <DropdownItem
                onClick={() => {
                  setFaqDetail(data);
                  handleModalToggle();
                }}
              >
                <i className="fas fa-file-alt" />
                &nbsp;&nbsp;{t('Detail')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        disableSort: true,
        width: 30,
      },
    ];
  }, [t]);

  const hiddenOnXS: any = ['detail'];
  const hiddenOnSM: any = ['detail'];
  const hiddenOnMD: any = ['detail'];
  const hiddenOnLG: any = [];

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCreateModalToggle = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
  };
  return (
    <>
      <FaqModal
        data={faqDetail}
        isOpen={modalOpen}
        refreshData={refreshData}
        handleToggle={handleModalToggle}
        handleClose={handleModalClose}
      />
      <CreateFaqModal
        isOpen={createModalOpen}
        refreshData={refreshData}
        handleToggle={handleCreateModalToggle}
        handleClose={handleCreateModalClose}
      />
      <Row className="justify-content-between m-0">
        <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
          <PageSizeOptions
            defaultValue={pageSize}
            handlePageSizeChange={handlePageSizeChange}
          />
        </Col>
        <Col xs={12} md={9} lg={9}>
          <Row className="align-items-center justify-content-end">
            <Col sm={12} md={8} lg={6}>
              <SearchBar
                placeholder={t('Search title')}
                handleSearch={handleSearch}
                handleClear={handleClear}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={2} className="align-self-baseline">
          <button
            type="button"
            className="btn btn-info fw-normal fit-content mt-2 ps-4 pe-4"
            onClick={() => setCreateModalOpen(true)}
          >
            {t('button:Create New FAQ')}
          </button>
        </Col>
      </Row>

      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={faqs.items || []}
        page={page}
        pageSize={pageSize}
        total={faqs.totalCount || 1}
        handlePageChange={handlePageChange}
        onSort={handleSort}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
      />
    </>
  );
};
export default withTranslation()(FAQsTable);
