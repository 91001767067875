const paramsFromURL = (params: string[]) => {
  const windowUrl = window.location.search;
  const searchParam = new URLSearchParams(windowUrl);
  const newParams = [];
  for (let i = 0; i < params?.length; i++) {
    newParams.push(searchParam.get(params[i]));
  }
  return newParams;
};

export default paramsFromURL;
