import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import error from '../assets/images/404.png';

const NotFoundPage = ({ t }: any) => {
  return (
    <div className="my-5 pt-5">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-5">
              <h1 className="display-1 fw-semibold">
                4<span className="text-primary mx-2">0</span>4
              </h1>
              <h4 className="text-uppercase">Not Found</h4>
              <p className="text-uppercase">{t('Error.Page Not Found')}</p>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary" to="/">
                  {t('Back To Dashboard')}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row justify-content-center">
          <div className="col-md-10 col-xl-8">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(NotFoundPage);
