import { call, put, takeEvery } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import {
  createUserInfoGql,
  getUserInfoGql,
  updateUserInfoGql,
  uploadProfile,
} from 'src/graphql/users';
import { UsersTypes } from './actionTypes';
import {
  getUsers as getUsersGql,
  getUserDetail as getUserDetailGql,
  delete2FAGql,
} from '../../graphql/admin/user';
import {
  getUsersSuccess,
  getUserDetailsSuccess,
  createUserInfoSuccess,
  getUserInfoSuccess,
  updateUserInfoSuccess,
} from './actions';
import { changeTimezone as changeTimezoneGql } from '../../graphql/common/index';
import notify from '../../utils/notify';
import i18n from '../../i18n';
import { Response } from '../../common/types';
import {
  getLoggedInUser,
  setLoading,
  unsetLoading,
  uploadProfileSuccess,
} from '../actions';

function* getUsers({ payload: UsersQuery }: any) {
  const response: Promise<any> = yield call(getUsersGql, UsersQuery);

  yield put(getUsersSuccess(response));
}

function* getUserDetail({ payload: { id } }: any) {
  const response: Promise<any> = yield call(getUserDetailGql, id);

  yield put(getUserDetailsSuccess(response));
}

function* changeTimezone({ payload }: any) {
  const response: Response = yield call(changeTimezoneGql, payload);

  if (!isEmpty(response) && isEmpty(response.errors)) {
    yield put(getLoggedInUser());

    notify(i18n.t('success:Change Timezone Success'), 'success');
  }
}

function* fetchCreateUserInfo({ payload }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(createUserInfoGql, payload);

    yield put(createUserInfoSuccess(response));
  } catch (error) {
    //error
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchGetUserInfo({ unLoading }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(getUserInfoGql);
    yield put(getUserInfoSuccess(response));
    // if (response) {
    //   if (!response?.name && window.location.pathname !== '/profile') {
    //     setOpen(true);
    //     setTimeout(() => {
    //       notify(t('error:The campaign has not been updated.'), 'warning');
    //       history.push('/profile');
    //     }, 500);
    //   }
    // }
  } catch (error) {
    //error
  } finally {
    if (!unLoading) {
      yield put(unsetLoading());
    }
  }
}

function* fetchUpdateUserInfo({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(updateUserInfoGql, payload);

    yield put(updateUserInfoSuccess(response));
  } catch (error) {
    //error
  } finally {
    yield put(unsetLoading());
  }
}

function* fetchUploadProfile({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(uploadProfile, payload);

    yield put(uploadProfileSuccess(response));
  } catch (error) {
    //error
  } finally {
    yield put(unsetLoading());
  }
}

function* delete2FactorAuthen({ payload }: any) {
  console.log(payload);
  try {
    yield put(setLoading());
    yield call(delete2FAGql, payload);
    notify('Delete 2FA sucess', 'success');
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* usersSaga() {
  yield takeEvery(UsersTypes.GET_USERS, getUsers);
  yield takeEvery(UsersTypes.GET_USER_DETAIL, getUserDetail);
  yield takeEvery(UsersTypes.CHANGE_TIMEZONE, changeTimezone);

  yield takeEvery(UsersTypes.CREATE_USER_INFO, fetchCreateUserInfo);

  yield takeEvery(UsersTypes.GET_USER_INFO, fetchGetUserInfo);

  yield takeEvery(UsersTypes.UPDATE_USER_INFO, fetchUpdateUserInfo);

  yield takeEvery(UsersTypes.UPLOAD_PROFILE, fetchUploadProfile);

  yield takeEvery(UsersTypes.DELETE_2FA, delete2FactorAuthen);
}

export default usersSaga;
