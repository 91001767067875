// eslint-disable-next-line import/prefer-default-export
export function transformStringAmountInput(value) {
  let amount = value.toString();

  amount = amount.replace(/[^0-9.]/g, '');

  const parts = amount.split('.');

  if (parts?.length > 1) {
    const decimal = parts[1]?.length > 4 ? parts[1].slice(0, 4) : parts[1];

    amount = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimal}`;
  } else {
    amount = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  // eslint-disable-next-line consistent-return
  return amount;
}

export function transformStringUrlInput(value) {
  const webUrl = value.substring(value.indexOf('.') + 1);
  const URL = webUrl.substring(0, webUrl?.length - 1);

  return URL;
}
