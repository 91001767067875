import React, { useCallback, useState } from 'react';
import { Column } from 'react-table';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getEarningTransaction } from 'src/store/actions';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CustomTable from '../Common/CustomTable';
import DateTimeComponent from '../Common/DateTimeComponent';
import DateTimeTypes from '../../constants/dateTimeTypes';

const EarningHistory = ({ isApplication, t }: any) => {
  //* Params
  const { id }: { id: string } = useParams();

  //* Variables
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [sortType, setSortType] = useState('');
  const [sortBy, setSortBy] = useState('');

  const { earningHistory, earningTransactionLoading } = useSelector(
    (state: any) => ({
      earningHistory: state.WebsiteInfo.earningTransaction,
      earningTransactionLoading: state.Global.earningTransactionLoading,
    }),
  );

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Timestamp'),
        accessor: ({ created_at }) => {
          return (
            <DateTimeComponent
              dateTime={created_at}
              dateTimeType={DateTimeTypes.RECENT_TRANSACTION_DATE_TIME}
            />
          );
        },
        id: 'created_at',
      },
      {
        Header: t('table:Fiat Amount'),
        accessor: ({ FIAT_amount }) => {
          return `${floorToFixed(FIAT_amount, 4)} USDT`;
        },
        id: 'FIAT_amount',
      },
      {
        Header: t('table:Opening Balance'),
        accessor: ({ movement }) => {
          return `${floorToFixed(movement.opening_balance, 4)} USDT`;
        },
        id: 'opening_balance',
      },
    ],
    [t, page],
  );
  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = ['col-2'];
  const hiddenOnLG: any = [];

  //* Functions
  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;
    const payload: any = {
      limit: pageSize,
      page: newPage,
      sortBy: 'created_at',
      sortType: 'DESC',
    };

    if (isApplication) payload.application_key = id;
    else payload.website_id = +id;

    dispatch(getEarningTransaction(payload));
    setPage(newPage);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';
      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;
      const payload: any = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      if (isApplication) payload.application_key = id;
      else payload.website_id = +id;

      dispatch(getEarningTransaction(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page, pageSize],
  );

  const getDefaultPayload = (oldPage: number) => {
    setPage(oldPage);

    return {
      limit: pageSize,
      page,
    };
  };

  return (
    <CustomTable
      headerCSS="fw-normal bg-header-table"
      columns={columns}
      data={earningHistory.items || []}
      page={page}
      pageSize={pageSize}
      total={earningHistory.totalCount}
      handlePageChange={handlePageChange}
      hiddenOnXS={hiddenOnXS}
      hiddenOnSM={hiddenOnSM}
      hiddenOnMD={hiddenOnMD}
      hiddenOnLG={hiddenOnLG}
      onSort={handleSort}
      marginTopClassName="mt-0"
      isLoading={earningTransactionLoading}
    />
  );
};

export default withTranslation()(EarningHistory);
