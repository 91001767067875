import React from 'react';
import { FormGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import movementTypesEnum from '../../constants/movementTypesEnum';

const MovementTypeOptions = ({ t, defaultValue, handleTypeChange }: any) => {
  const handleChange = (e: any) => {
    const status = e.currentTarget.value;

    handleTypeChange(status);
  };

  const renderMovementTypesOption = () => {
    return Object.keys(movementTypesEnum).map((key: string) => {
      const text = key === 'ALL' ? t('table:Select Type') : t(`table:${key}`);

      return (
        <option
          // @ts-ignore
          value={movementTypesEnum[key]}
          defaultValue={defaultValue}
        >
          {text}
        </option>
      );
    });
  };

  return (
    <FormGroup>
      <select className="mb-3 mb-md-0 form-control" onChange={handleChange}>
        {renderMovementTypesOption()}
      </select>
    </FormGroup>
  );
};

export default withTranslation()(MovementTypeOptions);
