/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum WalletTypes {
  GET_WALLET = '@@walet/GET_WALLET',
  GET_WALLET_SUCCESS = '@@walet/GET_WALLET_SUCCESS',
  GET_WALLET_FAIL = '@@walet/GET_WALLET_FAIL',

  GET_SUM_BALANCE_WALLET = '@@walet/GET_SUM_BALANCE_WALLET',
  GET_SUM_BALANCE_WALLET_SUCCESS = '@@walet/GET_SUM_BALANCE_WALLET_SUCCESS',
  GET_SUM_BALANCE_WALLET_FAIL = '@@walet/GET_SUM_BALANCE_WALLET_FAIL',

  GET_USER_WALLET_BY_CURRENCY = '@@walet/GET_USER_WALLET_BY_CURRENCY',
  GET_USER_WALLET_BY_CURRENCY_SUCESS = '@@walet/GET_USER_WALLET_BY_CURRENCY_SUCESS',

  GET_ADMIN_WALLET = '@@wallet/GET_ADMIN_WALLET',
  GET_ADMIN_WALLET_SUCCESS = '@@wallet/GET_ADMIN_WALLET_SUCCESS',

  GET_SUM_BALANCE_WALLET_UUID = '@@walet/GET_SUM_BALANCE_WALLET_UUID',
  GET_SUM_BALANCE_WALLET_UUID_SUCCESS = '@@walet/GET_SUM_BALANCE_WALLET_SUCCESS',

  GET_BALANCE_WALLET_UUID = '@@walet/GET_BALANCE_WALLET_UUID',
  GET_BALANCE_WALLET_UUID_SUCCESS = '@@walet/GET_BALANCE_WALLET_UUID_SUCCESS',

  GET_MARKETER_AVAILABLE_CURRENCIES = '@@walet/GET_MARKETER_AVAILABLE_CURRENCIES',
  GET_MARKETER_AVAILABLE_CURRENCIES_SUCCESS = '@@walet/GET_MARKETER_AVAILABLE_CURRENCIES_SUCCESS',
}

export interface WalletState {
  wallet: any;
  error: Object;
  sumWallet: any;
  eachWallet: [];
  adminWallet: any[];
  marketerCurrencies: any[];
}
