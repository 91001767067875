import { withTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import FAQsTable from 'src/components/Admin/FAQsTable';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import menu from '../../constants/menu';

const FaqsSettingPage = ({ t }: any) => {
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          pageTitle={t('Page.FAQs')}
          menus={[{ menu: menu.DASHBOARD }, { menu: menu.FAQs, active: true }]}
        />

        <Card>
          <CardBody>
            <FAQsTable />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
export default withTranslation()(FaqsSettingPage);
