import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import AprroveWebsite from 'src/components/Publisher/ApproveWebsite';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import PendingApproval from 'src/components/Publisher/pendingApproval';
import menu from '../../constants/menu';
import Breadcrumb from '../../components/Common/Breadcrumb';

const PublisherAdminPage = ({ t }: any) => {
  const [headerPillsTab, setHeaderPillsTab] = useState('1');

  // UI Tab
  const toggleHeaderPills = (tab: any) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Publisher Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            pageTitle={t('Page.Publisher Website')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.PUBLISHER_WEBSITE, active: true },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          'tab-active-black': headerPillsTab === '1',
                        })}
                        onClick={() => {
                          toggleHeaderPills('1');
                        }}
                      >
                        <h5 className="m-0">{t('Menus.All request approval')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          'tab-active-black': headerPillsTab === '2',
                        })}
                        onClick={() => {
                          toggleHeaderPills('2');
                        }}
                      >
                        <h5 className="m-0">{t('Menus.Approval Website')}</h5>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent className="text-white" activeTab={headerPillsTab}>
                    <TabPane tabId="1">
                      <AprroveWebsite />
                    </TabPane>
                    <TabPane tabId="2">
                      <PendingApproval />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(PublisherAdminPage);
