import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';
import { setUserProfileList, setUserProfileStatus } from 'src/store/actions';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';

const onYoutubeProcess = async (
  { token, dispatch, email, history }: any,
  rURL: string | null = null,
) => {
  let channelID = '';
  if (!token) {
    throw new Error('This Google Account not has YouTube account');
  }
  try {
    const youtubeDataRes = await fetch(
      `https://www.googleapis.com/youtube/v3/channels?access_token=${token}&mine=true`,
    );

    const youtubeData = await youtubeDataRes.json();
    if (!youtubeData.items) {
      throw new Error('This Google Account not has YouTube account');
    }
    channelID = youtubeData?.items[0]?.id;
  } catch (error: any) {
    dispatch(setUserProfileList([]));
    dispatch(setUserProfileStatus(error));
    throw new Error(error);
  }

  if (!channelID) return;

  try {
    const youtubeAccountRes: any = await fetch(
      `${config.backendUrl}api/youtubeData?channelId=${channelID}`,
    );

    const youtubeAccountData = await youtubeAccountRes.json();

    const profile_path = `https://www.youtube.com/channel/${channelID}`;

    const expireDate = await getExpireDate(token);

    if (youtubeAccountData && youtubeAccountData?.items) {
      const userData: UserSocialProfile = {
        email,
        id: youtubeAccountData.items[0].id,
        access_token: token,
        access_token_secret: '',
        fan_count: +youtubeAccountData.items[0].statistics.subscriberCount,
        name: youtubeAccountData.items[0].snippet.title,
        social_media_type: SocialMediaType.YOUTUBE,
        picture_url: youtubeAccountData.items[0].snippet.thumbnails.high.url,
        profile_path,
        expire_access_token: expireDate,
      };

      dispatch(setUserProfileList([userData]));
      dispatch(setUserProfileStatus('PASS'));
      if (rURL != null) {
        history.replace(`/influencer/${rURL}`);
      }
    } else {
      dispatch(setUserProfileList([]));
      dispatch(setUserProfileStatus('Cannot get Youtube account data'));
    }
  } catch (error: any) {
    dispatch(setUserProfileList([]));
    dispatch(setUserProfileStatus(error));
    throw new Error(error);
  }
};

const getExpireDate = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  try {
    const res = await fetch(
      'https://oauth2.googleapis.com/tokeninfo?id_token',
      requestOptions,
    );

    const jsonInfo = JSON.parse(await res.text());
    const expire: Date = getExpirationDate(+jsonInfo?.exp);
    return expire;
  } catch (error) {
    return null;
  }
};

const getExpirationDate = (expiresIn: any) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = currentTime + expiresIn;
  return new Date(expirationTime * 1000);
};

export default onYoutubeProcess;
