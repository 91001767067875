import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaFilterTable } from 'src/store/actions';
import control from './InfluencerChoice.style';
import IconRadioInputComponent from '../../../../../shared/IconRadioInput/IconRadioInput.component';

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
}

interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  Categories: any;
}

const { Texts, FlexCol, Radio, Btn, DivContent, GridBox } = control();

const formControls: FormControl = {
  label: 'marketer:create_campaign.campaign_name',
  name: 'name',
  type: FormInputType.STRING,
  required: true,
  Categories: [...CategoriesMarketerCampaign],
};
const InfluencerChoice = ({ form, setForm, toggle }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkAllProperty, setCheckAllProperty] = useState(false);
  const { marketerJob } = useSelector((state: any) => ({
    marketerJob: state.MarketerJob.marketerJob,
  }));

  const AllChoice = () => {
    const all = formControls.Categories.map((data: any) => data.value);

    setCheckAllProperty(!checkAllProperty);
    if (!checkAllProperty) {
      setForm({ ...form, aptitude_ids: all });
    } else {
      setForm({ ...form, aptitude_ids: [] });
    }
  };

  return (
    <DivContent
      justifycontent="flex-start"
      width="100%"
      marginTop="20px"
      className="influencer-choice-container"
    >
      <FlexCol borderTop="1px solid #EAECEE " margin="20px 0">
        <Texts margin="20px 10px 0 10px" fontsize="1.5rem">
          {t('marketer:create_job_campaign:Influencer aptitude')}
        </Texts>
        <FlexCol margin="5px 10%">
          <Radio
            type="radio"
            value="Influencer"
            name="aa"
            width="20px"
            height="20px"
            margin="0 auto 5px auto"
            onClick={() => AllChoice()}
            checked={checkAllProperty}
          />{' '}
          {t('marketer:create_job_campaign:Select all')}
        </FlexCol>
      </FlexCol>
      <GridBox>
        {formControls?.Categories?.map((data: any) => (
          <IconRadioInputComponent
            id={data?.value}
            imageUrl={data?.icon}
            labels={data?.label}
            background={data?.background_color}
            labelSize="1em"
            isChecked={form?.aptitude_ids?.includes(data?.value)}
            onClick={() => {
              let categories: any = [...form?.aptitude_ids];

              if (categories?.includes(data?.value)) {
                categories = categories?.filter(
                  (category: any) => category !== data?.value,
                );
              } else categories = [...categories, data?.value];
              setForm({ ...form, aptitude_ids: categories });
            }}
          />
        ))}
      </GridBox>
      <FlexCol>
        <Btn
          type="submit"
          width="150px"
          height="40px"
          borderradius="10px"
          background="#3DB5FF"
          onClick={(e: any) => {
            e.preventDefault();
            const submitForm = {
              ...form,
              social_media_type: marketerJob.social_media_type,
            };
            delete submitForm.propertyType;

            dispatch(socialMediaFilterTable(submitForm));
            toggle();
          }}
        >
          {t('marketer:create_job_campaign:Submit')}
        </Btn>
      </FlexCol>
    </DivContent>
  );
};

export default InfluencerChoice;
