import { NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const NavbarHeaderAdKey = ({
  label,
  tabId,
  headerPillsTab,
  setHeaderPillsTab,
}: any) => {
  return (
    <NavItem>
      <NavLink
        style={{ cursor: 'pointer' }}
        className={classnames({
          active: tabId === headerPillsTab,
        })}
        onClick={() => {
          setHeaderPillsTab(tabId);
        }}
      >
        <h5>{label}</h5>
      </NavLink>
    </NavItem>
  );
};

export default NavbarHeaderAdKey;
