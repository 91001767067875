import React from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import noCampaignImage from 'src/assets/images/no-campaign-image.png';
import Breadcrumb from '../components/Common/Breadcrumb';
import menu from '../constants/menu';
import NotificationsTable from '../components/Common/NotificationsTable';

const NotificationPage = () => {
  const { notiDetail } = useSelector((state: any) => ({
    notiDetail: state.ActivityLogs.recentLogs,
  }));
  const { t } = useTranslation();
  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('Page.Notification')}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.NOTIFICATION, active: true },
          ]}
        />

        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={6}>
            <Card>
              <CardBody>
                <h2 style={{ paddingLeft: '30px', margin: '0px', color: 'white' }}>
                  {t('Notifications')}
                </h2>
                {notiDetail?.length > 0 ? (
                  <NotificationsTable />
                ) : (
                  <div
                    className="no-campaign-container"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      src={noCampaignImage}
                      className="no-campaign-image-container"
                      alt="noCampaignImage"
                    />
                    <div
                      className="text-container"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        marginTop: '24px',
                      }}
                    >
                      <div className="text-top">
                        {t('table:No notification message')}
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationPage;
