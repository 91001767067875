import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import { getUserDataTwitter, getUserDataTwitterV2 } from 'src/graphql/auth/twitter';
import { setUserProfileList, setUserProfileStatus } from 'src/store/actions';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import getAccessTokenTwitter from './twitterAccessToken';

const onTwitterProcess = async (
  { params, dispatch, history }: any,
  rUrl: string | null = null,
) => {
  try {
    const oauthToken: string | null = params.get('oauth_token');
    const oauthVerifier: string | null = params.get('oauth_verifier');
    if (!oauthToken || !oauthVerifier) throw new Error('Found an error');

    // eslint-disable-next-line no-shadow
    const { token, tokenSecret, userId } = await getAccessTokenTwitter(
      oauthToken,
      oauthVerifier,
    );

    console.log('token', token, 'tokenSecret', tokenSecret, 'userId', userId);

    const response = await getUserDataTwitterV2(token, tokenSecret, userId);
    const datum = JSON.parse(response);
    const { id, public_metrics, name, profile_image_url, username } = datum;
    const userData: UserSocialProfile = {
      id,
      access_token: token,
      access_token_secret: tokenSecret,
      fan_count: public_metrics.followers_count,
      name,
      social_media_type: SocialMediaType.TWITTER,
      picture_url: profile_image_url,
      profile_path: `https://twitter.com/${username}`,
    };

    dispatch(setUserProfileList([userData]));
    dispatch(setUserProfileStatus('PASS'));
    if (rUrl) history.replace(`/influencer/${rUrl}`);
    else history.replace('/influencer/social-calculate');
  } catch (error: any) {
    dispatch(setUserProfileStatus(error?.message));
    history.replace('/influencer/dashboard');
    throw new Error(`${error}`);
  }
};

export default onTwitterProcess;
