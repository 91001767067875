import { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Label,
  CardTitle,
} from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import QRCode from 'react-qr-code';
import GppGoodIcon from '@mui/icons-material/GppGood';
import {
  generateTwoFactorSecret,
  enableTwoFactor,
  disableTwoFactorRequest,
  getTwoFactorStatus,
} from '../../store/auth/twofactor/actions';
import CustomButton from './CustomButton';

const initialValues: any = {
  secret: '',
  code: '',
};

const twoFactorSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
});

const TwoFactorSettingForm = ({ t }: any) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const { twoFactorData, twoFactorEnabled, twoFactorDisabledRequest } = useSelector(
    (state: any) => ({
      twoFactorData: state.twoFactor.data,
      twoFactorEnabled: state.twoFactor.isEnabled,
      twoFactorDisabledRequest: state.twoFactor.isRequestToDisabled,
    }),
  );

  useEffect(() => {
    dispatch(getTwoFactorStatus());

    if (!twoFactorEnabled) {
      dispatch(generateTwoFactorSecret());
    }
  }, []);

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: twoFactorEnabled
        ? t('swal:Disable 2FA Confirm')
        : t('swal:Enable 2FA Confirm'),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        const { code } = values;
        const { secret } = twoFactorData;

        if (twoFactorEnabled) {
          dispatch(disableTwoFactorRequest(code));
          setIsSubmit(true);
          // disable
        } else {
          dispatch(enableTwoFactor(code, secret));
          // enable
        }

        resetForm();
        setSubmitting(true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle
            className="mb-0"
            style={{ paddingTop: '10px', paddingLeft: '15px' }}
          >
            <h5 className="m-0" style={{ fontSize: '16px' }}>
              {t('2FA Setting')}
            </h5>
          </CardTitle>
          <p
            className="card-title-desc"
            style={{ paddingBottom: '10px', paddingLeft: '15px' }}
          >
            {t('2FA Setting DESC')}
          </p>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={twoFactorSchema}
            render={({ errors, touched }) => (
              <FormikForm>
                <Row>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {!twoFactorEnabled ? (
                    <>
                      <Col className="d-flex justify-content-center mb-4" md="12">
                        {!isEmpty(twoFactorData) && (
                          <QRCode value={twoFactorData.otpauthUrl} />
                        )}
                      </Col>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="secret">{t('Form.2FA Secret')}</Label>
                          <Field
                            name="secret"
                            type="text"
                            className="form-control"
                            disabled
                            id="secret"
                            value={
                              !isEmpty(twoFactorData) ? twoFactorData.secret : ''
                            }
                          />
                        </FormGroup>
                      </Col>
                    </>
                  ) : twoFactorEnabled && !twoFactorDisabledRequest ? (
                    <div style={{ marginBottom: '10px' }}>
                      <GppGoodIcon sx={{ color: '#009a78', fontSize: 40 }} />
                      {t('Enabled Two Factor')}
                    </div>
                  ) : (
                    <div style={{ marginBottom: '10px' }}>
                      <GppGoodIcon sx={{ color: '#009a78', fontSize: 40 }} />
                      {t('Enabled Two Factor')}
                      {t('Enabled Two Factor')}
                    </div>
                  )}
                  <Col md="12">
                    <FormGroup className="mb-2">
                      <Label htmlFor="amount">{t('Form.2FA OTP')}</Label>
                      <Field
                        name="code"
                        type="text"
                        className={`form-control ${
                          errors.code && touched.code ? 'is-invalid' : ''
                        }`}
                        id="code"
                      />
                      <ErrorMessage
                        name="code"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {twoFactorEnabled ? (
                  <>
                    {isSubmit && (
                      <Label htmlFor="amount" style={{ color: '#009a78' }}>
                        {t(
                          'Form.Please confirm your email to disable. Two factor authentication',
                        )}
                      </Label>
                    )}
                    <CustomButton size="s" status="submit" label={t('Disable')} />
                  </>
                ) : (
                  <CustomButton
                    size="ssp"
                    status="submit"
                    label={t('Button.Submit')}
                  />
                )}
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default withTranslation()(TwoFactorSettingForm);
