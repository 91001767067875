import { PaymentTypes } from './actionTypes';

export const getAllPaymentTransactionsRequest = (payload: {
  limit: number;
  page: number;
  search?: string;
  userId?: string;
  isCampaign: boolean;
}) => {
  return {
    type: PaymentTypes.GET_ALL_PAYMENT_TRANSACTIONS_REQUEST,
    payload: { ...payload },
  };
};

export const getAllPaymentTransactionsRequestSuccess = (transactions: any) => {
  return {
    type: PaymentTypes.GET_ALL_PAYMENT_TRANSACTIONS_REQUEST_SUCCESS,
    payload: { ...transactions },
  };
};

export const getAllPaymentTransactionsRequestFail = (errorMessage: any) => {
  return {
    type: PaymentTypes.GET_ALL_PAYMENT_TRANSACTIONS_REQUEST_FAIL,
    payload: errorMessage,
  };
};
