/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum SocialMediaType {
  ALL = 'ALL',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
}
export default SocialMediaType;
