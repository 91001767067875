import MarketerJobTypes from './actionTypes';

export const INIT_STATE: MarketerJobNew = {
  marketerJobNew: {
    job_type: '',
    marketer_campaign_key: '',
    job: [
      {
        name: '',
        content_type: '',
        thumbnail_cover: '',
        social_media_type: '',
        work_order: '',
        currency_id: null,
        job_requirement: {
          max_budget: 0,
          min_budget: 0,
          is_male: false,
          is_female: false,
          is_male_lgbt: false,
          is_female_lgbt: false,
          min_age: 0,
          max_age: 0,
          fixed_price: null,
          aptitude_ids: [],
          country_name: '',
        },
        link_accompanying_work: '',
        hashtag: '', //รวมเป็น string ตัวเดียวคั่นด้วย ,
        social_media_info: null,
        date_submission: '',
        last_day_accept_job: '',
        last_day_approve_job: '',
        last_day_influencer_submit: '',
        last_day_to_approve: '',
        influencer_post_internal_job: '',
        finish_internal_work: '',
      },
    ],
  },
  descriptionJobNew: {
    job_type: '',
    marketer_campaign_key: '',
    country_name: '',
    aptitude_ids: [],
    is_male: false,
    is_female: false,
    is_male_lgbt: false,
    is_female_lgbt: false,
    min_age: 0,
    max_age: 0,
    fixed_price: null,
    currency_id: null,
    name: '',
    thumbnail_cover: '',
  },
  filter: [],
  error: {},
  loading: false,
};

export interface MarketerJobNew {
  marketerJobNew: MarketerJobNewType;
  descriptionJobNew: DescriptionJobNew;
  filter?: Array<string>;
  error?: {};
  loading?: boolean;
}

export interface DescriptionJobNew {
  job_type: 'INVITE_ONLY' | 'PUBLISH' | '';
  marketer_campaign_key: string;
  country_name?: string | null;
  aptitude_ids?: Array<string> | null;
  is_male?: boolean | null;
  is_female?: boolean | null;
  is_male_lgbt?: boolean | null;
  is_female_lgbt?: boolean | null;
  min_age?: number | null;
  max_age?: number | null;
  fixed_price?: number | null;
  currency_id?: number | null;
  name: string;
  thumbnail_cover: string;
}

export interface MarketerJobNewType {
  job_type: string;
  marketer_campaign_key: string;
  job: Array<Job>;
}

export enum SocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
}

interface Job {
  isSelected?: boolean | null; // แค่เอามาเช็คเฉยๆไม่เกี่ยวกับตอนส่งหรือตอนไปใช้จริง
  name: string;
  content_type: string;
  thumbnail_cover: string;
  social_media_type: SocialMediaType | '';
  work_order: string;
  currency_id?: number | null;
  job_requirement: {
    max_budget: number;
    min_budget: number;
    is_male?: boolean | null;
    is_female?: boolean | null;
    is_male_lgbt?: boolean | null;
    is_female_lgbt?: boolean | null;
    min_age?: number | null;
    max_age?: number | null;
    fixed_price?: number | null;
    aptitude_ids?: Array<string> | null;
    country_name?: string | null;
  } | null;
  link_accompanying_work: string;
  hashtag: string; //รวมเป็น string ตัวเดียวคั่นด้วย ,
  social_media_info?: string | null;
  date_submission: string;
  last_day_accept_job: string;
  last_day_approve_job: string;
  last_day_influencer_submit: string;
  last_day_to_approve: string;
  influencer_post_internal_job: string;
  finish_internal_work: string;
}

interface MarketerJobNewAction {
  type: MarketerJobTypes;
  payload?: MarketerJobNew;
}

const MarketerCampaignJobsNew = (
  state = INIT_STATE,
  action: MarketerJobNewAction,
) => {
  switch (action.type) {
    case MarketerJobTypes.GET_MARKETER_JOB_SUCCESS:
      return {
        ...state,
        marketerJobNew: action.payload,
      };
    case MarketerJobTypes.CREATE_MARKETER_JOB_NEW:
      return {
        ...state,
        marketerJobNew: action.payload,
      };
    case MarketerJobTypes.CREATE_MARKETER_JOBS_NEW:
      return {
        ...state,
        marketerJobNew: action.payload,
      };
    case MarketerJobTypes.SET_DESCRIPTION_JOB_NEW:
      return {
        ...state,
        descriptionJobNew: action.payload,
      };

    case MarketerJobTypes.UPDATE_MARKETER_JOB_SUCCESS:
      return {
        ...state,
        marketerJobNew: action.payload,
      };

    case MarketerJobTypes.FILTER_JOB_SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };
    case MarketerJobTypes.CLEAR_MARKETER_JOB:
      return {
        ...state,
        marketerJobNew: {},
      };
    default:
      return state;
  }
};

export default MarketerCampaignJobsNew;
