import { DefaultValuesTypes } from './actionTypes';

export const getDefaultCampaign = (payload: {
  device_platform: string;
  banner_format?: string;
  ad_format?: string;
}) => ({
  type: DefaultValuesTypes.GET_DEFAULT_CAMPAIGN,
  payload,
});

export const getDefaultCampaignSuccess = (defaultCampaign: any) => ({
  type: DefaultValuesTypes.GET_DEFAULT_CAMPAIGN_SUCCESS,
  payload: defaultCampaign,
});

export const getAllDefaultCampaignSuccess = (defaultCampaign: any) => ({
  type: DefaultValuesTypes.GET_ALL_DEFAULT_CAMPAIGN_SUCCESS,
  payload: defaultCampaign,
});

export const getDefaultCampaignFail = (error: any) => ({
  type: DefaultValuesTypes.GET_DEFAULT_CAMPAIGN_FAIL,
  payload: error,
});

export const getDefaultWebsite = () => ({
  type: DefaultValuesTypes.GET_DEFAULT_WEBSITE,
});

export const getDefaultWebsiteSuccess = (defaultWebsite: any) => ({
  type: DefaultValuesTypes.GET_DEFAULT_WEBSITE_SUCCESS,
  payload: defaultWebsite,
});

export const getDefaultWebsiteFail = (error: any) => ({
  type: DefaultValuesTypes.GET_DEFAULT_WEBSITE_FAIL,
  payload: error,
});
export const getFeeWithdrawConfigs = () => ({
  type: DefaultValuesTypes.GET_FEE_WITHDRAW_CONFIGS,
});

export const getFeeWithdrawConfigsSuccess = (configs: any) => ({
  type: DefaultValuesTypes.GET_FEE_WITHDRAW_CONFIGS_SUCCESS,
  payload: configs,
});

export const getFeeWithdrawConfigsFail = (error: any) => ({
  type: DefaultValuesTypes.GET_FEE_WITHDRAW_CONFIGS_FAIL,
  payload: error,
});

export const getMinimumWithdrawConfigs = () => ({
  type: DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIGS,
});

export const getMinimumWithdrawConfigsSuccess = (configs: any) => ({
  type: DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIGS_SUCCESS,
  payload: configs,
});

export const getMinimumWithdrawConfigsFail = (error: any) => ({
  type: DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIGS_FAIL,
  payload: error,
});

export const getMinimumWithdrawConfig = (symbol: string) => ({
  type: DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIG,
  payload: { symbol },
});

export const getMinimumWithdrawConfigSuccess = (config: any) => ({
  type: DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIG_SUCCESS,
  payload: config,
});

export const getMinimumWithdrawConfigFail = (error: any) => ({
  type: DefaultValuesTypes.GET_MINIMUM_WITHDRAW_CONFIG_FAIL,
  payload: error,
});

export const updateConfig = (key: string, value: string) => ({
  type: DefaultValuesTypes.UPDATE_CONFIG,
  payload: { key, value },
});

export const getProfitConfigs = () => ({
  type: DefaultValuesTypes.GET_PROFIT_CONFIGS,
});

export const getProfitConfigsSuccess = (configs: any) => ({
  type: DefaultValuesTypes.GET_PROFIT_CONFIGS_SUCCESS,
  payload: configs,
});
export const getDefaultValueByKey = (key: string) => ({
  type: DefaultValuesTypes.GET_DEFAULT_VALUE_BY_KEY,
  key,
});
export const getDefaultValueByKeySuccess = (payload: any) => ({
  type: DefaultValuesTypes.GET_DEFAULT_VALUE_BY_KEY_SUCCESS,
  payload,
});
export const getSuggestJobBudgetConfigs = () => ({
  type: DefaultValuesTypes.GET_SUGGEST_JOB_BUDGET_CONFIGS,
});
export const getSuggestJobBudgetConfigsSuccess = (payalod: any) => ({
  type: DefaultValuesTypes.GET_SUGGEST_JOB_BUDGET_CONFIGS_SUCCESS,
  payalod,
});
