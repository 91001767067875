import {
  ErrorMessage,
  Form as FormikForm,
  Formik,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup } from 'reactstrap';
import FloatingInputComponent from 'src/components/shared/FloatingInput/FloatingInput.component';
import { UserSocialProfile } from 'src/store/calculateSocialMedia/reducer';
import ErrorFocus from 'src/utils/ErrorFocus';
import pageStyles from '../../../../../pages/Publisher/Influencer/ChannelDetail/ChannelDetail.styled';
import controls from './Introduce.control';

const { TitleStyle, FlexRow, Texts, ButtonEdit, ButtonSave, ButtonCancel } =
  pageStyles();

const IntroduceComponent = ({
  errors,
  touched,
  formValues,
  setFormValues,
  disableEdit,
}: {
  errors?: FormikErrors<UserSocialProfile>;
  touched?: FormikTouched<UserSocialProfile>;
  formValues?: UserSocialProfile;
  setFormValues?: Dispatch<SetStateAction<UserSocialProfile>>;
  disableEdit?: any;
}) => {
  const {
    introduce,
    setIntroduce,
    editState,
    setEditState,
    validationSchema,
    onSubmit,
    onCancel,
    userProfileSelected$,
  } = controls();

  const { t } = useTranslation();

  if (userProfileSelected$)
    return (
      <>
        <TitleStyle style={{ marginBottom: 0 }}>
          {t('influencer:introduce yourself')}
        </TitleStyle>
        <FlexRow width="90%">
          <FormGroup className="text-start w-100">
            <FloatingInputComponent
              name="introduce"
              type="textarea"
              value={formValues?.introduce}
              isInvalid={(errors?.introduce && touched?.introduce) || false}
              onChange={(e: any) => {
                e.preventDefault();
                const { value } = e.target;
                if (!setFormValues) return;

                setFormValues((current) => ({
                  ...current,
                  introduce: value,
                }));
              }}
              label={t('influencer:Tell your strengths and identity to the brand.')}
            />
            <ErrorMessage
              name="introduce"
              component="div"
              className="invalid-message"
            />
            <ErrorFocus name="introduce" />
            {/* <p
              style={{
                display: 'block',
                textAlign: 'end',
                fontSize: '0.8rem',
                fontWeight: 'lighter',
              }}
            >
              {t('number of characters')}: {formValues?.introduce?.length}/255
            </p> */}
          </FormGroup>
        </FlexRow>
      </>
    );

  if (editState)
    return (
      <>
        <TitleStyle style={{ marginBottom: 0 }}>
          {t('influencer:introduce yourself')}
        </TitleStyle>
        <FlexRow width="90%">
          <Formik
            initialValues={introduce}
            onSubmit={() => onSubmit(introduce.introduce)}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({
              errors: introduceErrors,
              touched: introduceTouched,
              handleChange,
            }: any) => {
              return (
                <Col sm={12} md={7} lg={12}>
                  <FormikForm>
                    <FormGroup className="text-start">
                      <FloatingInputComponent
                        type="textarea"
                        name="introduce"
                        value={introduce.introduce}
                        isInvalid={
                          introduceErrors.introduce && introduceTouched.introduce
                        }
                        onChange={(e: any) => {
                          e.preventDefault();
                          const { value } = e.target;
                          setIntroduce({
                            ...introduce,
                            introduce: value,
                          });
                          handleChange(e);
                        }}
                        label={t(
                          'influencer:Tell your strengths and identity to the brand.',
                        )}
                      />
                      <ErrorMessage
                        name="introduce"
                        component="div"
                        className="invalid-message"
                      />
                      <ErrorFocus name="introduce" />
                    </FormGroup>
                    {/* <p
                      style={{
                        textAlign: 'end',
                        fontSize: '0.8rem',
                        fontWeight: 'lighter',
                      }}
                    >
                      {t('number of characters')}: {introduce.introduce.length}/255
                    </p> */}
                    <ButtonCancel
                      type="button"
                      onClick={() => onCancel()}
                      style={{ marginRight: '1rem' }}
                    >
                      <span>{t('influencer:account.cancel')}</span>
                    </ButtonCancel>
                    <ButtonSave type="submit">
                      <span>{t('influencer:account.save')}</span>
                    </ButtonSave>
                  </FormikForm>
                </Col>
              );
            }}
          </Formik>
        </FlexRow>
      </>
    );

  return (
    <>
      <TitleStyle style={{ marginBottom: 0 }}>
        {t('influencer:introduce yourself')}
      </TitleStyle>
      <FlexRow width="90%">
        <Texts
          style={{
            margin: '0',
            overflow: 'initial',
            textOverflow: 'initial',
            whiteSpace: 'initial',
            textAlign: 'start',
            wordBreak: 'break-all',
            fontSize: '1rem',
          }}
        >
          {introduce.introduce ? introduce.introduce : 'ไม่ได้ระบุ'}
        </Texts>
      </FlexRow>
      {!disableEdit && (
        <ButtonEdit onClick={() => setEditState(true)}>
          <i
            className="fas fa-pen"
            style={{
              margin: '5px 5px 0 0',
              color: 'white',
            }}
          />
          <span
            style={{
              color: 'white',
            }}
          >
            {t('influencer:account.edit')}
          </span>
        </ButtonEdit>
      )}
    </>
  );
};

IntroduceComponent.defaultProps = {
  errors: null,
  touched: null,
  formValues: null,
  setFormValues: null,
  disableEdit: false,
};

export default IntroduceComponent;
