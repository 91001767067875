import { useTranslation } from 'react-i18next';
import EnumStatus from 'src/constants/enum/marketerCampaign';
import inprogress_status from 'src/assets/images/card-status-inprogress.png';
import cancel_status from 'src/assets/images/card-status-cancel.png';
import finish_status from 'src/assets/images/card-status-finish.png';
import draft_status from 'src/assets/images/card-status-draft.png';

const CampaignStatusColor = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return '#839aa8';
    case EnumStatus.INPROGRESS:
      return '#EBA937';
    case EnumStatus.FINISHED:
      return '#009a78';
    case EnumStatus.CANCEL:
      return '#ff0000';
    default:
      return '#839aa8';
  }
};
const CampaignStatusIcon = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return 'fas fa-circle';
    case EnumStatus.INPROGRESS:
      return 'fas fa-adjust';
    case EnumStatus.FINISHED:
      return 'fas fa-check-circle';
    case EnumStatus.CANCEL:
      return 'fas fa-ban';
    default:
      return '';
  }
};
// const CampaignStatusBackGroundColor = (status: string) => {
//   switch (status) {
//     case EnumStatus.DRAFT:
//       return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.3) 30.83%, rgba(255, 255, 255, 0) 68.18%), #A3A3A3';
//     case EnumStatus.INPROGRESS:
//       return 'linear-gradient(271.74deg,rgba(255, 255, 255, 0.3) 30.83%,rgba(255, 255, 255, 0) 68.18%),#ffbc54';
//     case EnumStatus.FINISHED:
//       return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.4) 30.83%, rgba(255, 255, 255, 0) 68.18%), #50D0B9';
//     case EnumStatus.CANCEL:
//       return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.3) 30.83%, rgba(255, 255, 255, 0) 68.18%), #FF8C97';
//     default:
//       return 'linear-gradient(271.74deg, rgba(255, 255, 255, 0.3) 30.83%, rgba(255, 255, 255, 0) 68.18%), #A3A3A3';
//   }
// };
const CampaignStatusBackGroundColor = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return ' #A3A3A3';
    case EnumStatus.INPROGRESS:
      return '#ffbc54';
    case EnumStatus.FINISHED:
      return ' #50D0B9';
    case EnumStatus.CANCEL:
      return '#FF8C97';
    default:
      return ' #A3A3A3';
  }
};

const CampaignStatusButtonColor = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return '#839aa8';
    case EnumStatus.INPROGRESS:
      return '#EBA937';
    case EnumStatus.FINISHED:
      return '#009a78';
    case EnumStatus.CANCEL:
      return '#ff0000';
    default:
      return '#839aa8';
  }
};

const CampaignStatusBackGroundImage = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return draft_status;
    case EnumStatus.INPROGRESS:
      return inprogress_status;
    case EnumStatus.FINISHED:
      return finish_status;
    case EnumStatus.CANCEL:
      return cancel_status;
    default:
      return draft_status;
  }
};

const CampaignStatusColorTag = (status: string) => {
  switch (status) {
    case EnumStatus.DRAFT:
      return 'linear-gradient(180deg, rgba(163, 163, 163, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.INPROGRESS:
      return 'linear-gradient(180deg, rgba(255, 188, 84, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.FINISHED:
      return 'linear-gradient(180deg, rgba(80, 208, 185, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    case EnumStatus.CANCEL:
      return 'linear-gradient(180deg, rgba(255, 140, 151, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
    default:
      return 'linear-gradient(180deg, rgba(163, 163, 163, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF';
  }
};
const MarketerCampaignStatus = ({ status }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          //color: `${InfluencerJobStatusColor(status)}`,
          color: '#495057',
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 600,
          letterSpacing: '0em',
          textAlign: 'left',
        }}
      >
        <div style={{ paddingRight: '8px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
            }}
          >
            <i
              className={`${CampaignStatusIcon(status)}`}
              style={{
                color: `${CampaignStatusColor(status)}`,
                display: 'flex',
                alignItems: 'center',
                height: '18px',
                width: '18px',
                borderRadius: '0px',
              }}
            />
          </div>
        </div>
        {t(`enum:${status}`)}
      </div>
    </>
  );
};

export {
  MarketerCampaignStatus,
  CampaignStatusBackGroundColor,
  CampaignStatusColorTag,
  CampaignStatusBackGroundImage,
  CampaignStatusButtonColor,
};
