/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import CustomTable from 'src/components/Common/CustomTable';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import User from 'src/assets/images/users/avatar-2.jpg';
import config from 'src/config';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { setLoading, unsetLoading, getAllAdminApproveLog } from 'src/store/actions';
import Swal from 'sweetalert2';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import {
  filterNewFollowTableGql,
  updaterNewFollowGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import SearchBar from 'src/components/Table/SearchBar';

const ApproveNewFollower = ({ verifyStatus }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);
  const [data, setData] = useState({ items: [], totalCount: 0 });
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [page, setPage] = useState(1);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    verify_status: newVerifyStatus,
    sortBy,
    sortType,
  });

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: '#009a78',
      customClass: {},
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());

        await updaterNewFollowGql({
          verify_status: verify,
          key,
        });

        dispatch(unsetLoading());
        getData();
      }
    });
  };

  //table
  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('name'),
        accessor: ({ social_media_info }: any) => (
          <>
            {social_media_info?.profile_path ? (
              <a
                href={social_media_info?.profile_path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social_media_info?.name}
              </a>
            ) : (
              social_media_info?.name
            )}
          </>
        ),

        id: 'social_id',
        disableSort: true,
        width: 150,
      },
      {
        Header: t('table:old follower'),
        accessor: ({ social_media_info }: any) => social_media_info?.follower,
        id: 'old_follower',
        disableSort: true,
        width: 155,
      },
      {
        Header: t('table:new follower'),
        accessor: ({ new_follower }: any) => new_follower,
        id: 'new_follower',
        disableSort: true,
        width: 155,
      },
      {
        Header: t(`table:view Influencer profile`),
        accessor: ({ social_media_info }: any) => (
          <a
            className="cursor  fw-bold view-profile"
            href="campaign"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/InfluencerChannel?channel=${social_media_info?.key}`);
            }}
          >
            {t(`table:view profile`)}
          </a>
        ),
        id: 'influencer_profile',
        disableSort: true,
        width: 170,
      },
      {
        Header: t('marketer:create_campaign:status'),
        accessor: ({ verify_status }: any) => {
          let badgeColor: string = 'warning';
          switch (verify_status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verify_status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        disableSort: true,
      },
      {
        Header: t('img'),
        accessor: ({ social_media_info }: any) => (
          <img
            src={
              !social_media_info?.image_profile_url
                ? User
                : social_media_info?.image_profile_url
            }
            alt="image_profile_url"
            style={{ width: 40, height: 40, borderRadius: '50%' }}
          />
        ),

        id: 'profile',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Action'),
        accessor: ({ verify_status, key }: any) => (
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {verify_status !== VerifyStatus[0] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('PENDING', key)}
                  className="text-warning"
                >
                  <i className="mdi mdi-account-clock text-warning" />
                  &nbsp;&nbsp;{t('table:PENDING')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[1] && (
                <DropdownItem
                  onClick={async () => {
                    clickVerifyStatus('APPROVED', key);
                  }}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[2] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('REJECTED', key)}
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:created_at'),
        accessor: ({ social_media_info }: any) => {
          if (!social_media_info?.created_at) return 'null';
          const created_at_date = new Date(social_media_info?.created_at);
          const Day = created_at_date.getDate();
          const month = created_at_date.getMonth() + 1;
          const year = created_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'created_at',
        width: 100,
      },
    ];
  }, [t, param.page, param.limit, param]);

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...param,
        sortBy: newSortBy,
        sortType: newSortType,
        page: newPage,
      };

      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [page],
  );

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    setParam(payload);
  };

  const handleStatusChange = (event: any) => {
    setNewVerifyStatus(event.value);
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...param,
      limit: +newPageSize,
      page: 1,
    };
    setParam(payload);
  };

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('new_influencer_request');
    dispatch(getAllAdminApproveLog());
  };

  const getData = async () => {
    const newData = await filterNewFollowTableGql(param);
    setData(newData);
  };

  useEffect(() => {
    fetchApproveLog();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [param]);

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  const handleSearch = (search: string) => {
    const payload = {
      ...param,
      name: search,
      page: 1,
    };
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      name: '',
      page: 1,
    };
    setParam(payload);
  };

  return (
    <div>
      <MetaTags>
        <title>
          {t('page:Job')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card className="h-100">
              <CardBody className="h-100">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handlePageSizeChange}
                    colors="white"
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <div style={{ width: '150px', marginRight: '20px' }}>
                      <Dropdown
                        options={Status}
                        action={handleStatusChange}
                        default_value={Status[3]}
                      /> */}
                    {/* </div> */}
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={data?.items || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={data?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                    onSort={handleSort}
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApproveNewFollower;
