/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum CreativeTypeTypes {
  /** Get creativeType */
  GET_CREATIVE_TYPE = '@@creativeType/GET_CREATIVE_TYPE',
  GET_CREATIVE_TYPE_SUCCESS = '@@creativeType/GET_CREATIVE_TYPE_SUCCESS',
  GET_CREATIVE_TYPE_FAIL = '@@creativeType/GET_CREATIVE_TYPE_FAIL',
}

export interface CreativeTypeState {
  creativeType: Array<any>;
  error: Object;
}
