import { all, fork } from 'redux-saga/effects';

// Layout
import LayoutSaga from './layout/saga';

// Login
import authSaga from './auth/login/saga';

// Register
import registerSaga from './auth/register/saga';

// User Profile
import ProfileSaga from './auth/profile/saga';

// Forget Password
import forgotPasswordSaga from './auth/forgotpwd/saga';

// Two Factor
import twoFactorSaga from './auth/twofactor/saga';

// Website
import websitesSaga from './website/saga';

// Currencies
import currencySaga from './currencies/saga';

// Finance Withdraw
import withdrawSaga from './finance/withdraw/saga';

// Finance Wallet
import walletSaga from './finance/wallet/saga';

// Finance Deposit Address
import depositAddressesSaga from './finance/depositAddresses/saga';

// Campaign
import campaignSaga from './campaigns/saga';

// FAQ
import faqSaga from './faqs/saga';

// country
import countrySaga from './countries/saga';

import creativeTypeSaga from './creativeType/saga';

// Creative
import creativeSaga from './creatives/saga';

import depositSaga from './finance/deposit/saga';

import paymentSaga from './finance/payment/saga';

// Default Value
import defaultValue from './default-values/saga';

import movementSaga from './movement/saga';

import changePasswordSaga from './auth/changepassword/saga';

import usersSaga from './users/saga';

import systemBalanceSaga from './systembalance/saga';

import activityLogsSaga from './activity-logs/saga';

import allowNotificationMailSaga from './allow-notification-mail/saga';

import referralSaga from './referral/saga';

import ApplicationSaga from './application/saga';

import Aptitude from './aptitude/saga';

import MarketerCategory from './marketerCategory/saga';

import marketerCampaign from './marketerCampaign/saga';

import marketerJob from './marketerJob/saga';

import socialMediaInfo from './socialMediaInfo/saga';
import inviteSaga from './finance/inviteOnly/saga';
import publishSaga from './finance/publish/saga';
import suggestJob from './suggestJob/saga';

//Ban
import ban from './ban/saga';
//OwnCurrency
import own_currency from './own-currency/saga';

import taskTypeSaga from './tasktype/saga';

export default function* rootSaga() {
  yield all([
    // public
    fork(LayoutSaga),
    fork(authSaga),
    fork(registerSaga),
    fork(ProfileSaga),
    fork(forgotPasswordSaga),
    fork(twoFactorSaga),
    fork(depositAddressesSaga),
    fork(websitesSaga),
    fork(campaignSaga),
    fork(faqSaga),
    fork(countrySaga),
    fork(creativeTypeSaga),
    fork(creativeSaga),
    fork(depositSaga),
    fork(inviteSaga),
    fork(publishSaga),
    // fork(getSuggestJob),
    fork(paymentSaga),
    fork(walletSaga),
    fork(currencySaga),
    fork(withdrawSaga),
    fork(defaultValue),
    fork(movementSaga),
    fork(changePasswordSaga),
    fork(usersSaga),
    fork(systemBalanceSaga),
    fork(activityLogsSaga),
    fork(allowNotificationMailSaga),
    fork(referralSaga),
    fork(ApplicationSaga),
    fork(Aptitude),
    fork(MarketerCategory),
    fork(marketerCampaign),
    fork(marketerJob),
    fork(socialMediaInfo),
    fork(suggestJob),
    fork(ban),
    fork(own_currency),
    fork(taskTypeSaga),
  ]);
}
