import { ApplicationsTypes } from './actionTypes';

export const getApplicationsFilter = (payload: {
  search?: string;
  status?: string | null;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
}) => {
  const newPayload = payload;
  if (payload.status === 'ALL') {
    delete newPayload.status;
  }
  return {
    type: ApplicationsTypes.GET_APPLICATIONS_FILTER,
    payload: newPayload,
  };
};

export const getApplicationsFilterSuccess = (applicationsFilter: any) => {
  return {
    type: ApplicationsTypes.GET_APPLICATIONS_FILTER_SUCCESS,
    payload: applicationsFilter,
  };
};

export const getApplicationByKey = (application_key: string) => {
  return {
    type: ApplicationsTypes.GET_APPLICATION_BY_KEY,
    payload: application_key,
  };
};

export const getApplicationByKeySuccess = (applicationsFilter: any) => {
  return {
    type: ApplicationsTypes.GET_APPLICATION_BY_KEY_SUCCESS,
    payload: applicationsFilter,
  };
};

export const getApplicationByKeyFail = (error: any) => {
  return {
    type: ApplicationsTypes.GET_APPLICATION_BY_KEY_FAIL,
    payload: error,
  };
};

export const getApplicationCreativeFilter = (payload: {
  limit?: number;
  page?: number;
  sortBy?: string;
  sortType?: string;
  application_key: string;
}) => ({
  type: ApplicationsTypes.GET_APPLICATION_CREATIVE_FILTER,
  payload,
});

export const getApplicationCreativeFilterSuccess = (
  applicationsCreativeFilter: any,
) => {
  return {
    type: ApplicationsTypes.GET_APPLICATION_CREATIVE_FILTER_SUCCESS,
    payload: applicationsCreativeFilter,
  };
};

// Application graphql datum
export const getApplicationGraphDatum = (payload: {
  application_id: string;
  limit: number;
  page: number;
  log_range: string;
  log_start?: string;
  log_end?: string;
  shortcut_range?: string;
  sortBy?: string;
  sortType?: string;
}) => ({
  type: ApplicationsTypes.GET_APPLICATION_GRAPH_DATUM,
  payload: { ...payload },
});

export const getApplicationGraphDatumSuccess = (application_graph_datum: any) => ({
  type: ApplicationsTypes.GET_APPLICATION_GRAPH_DATUM_SUCCESS,
  payload: application_graph_datum,
});

export const clearAppGraphDatum = () => ({
  type: ApplicationsTypes.CLEAR_APP_GRAPH_DATUM,
});

export const getApplicationGraphDatumFail = (error: any) => ({
  type: ApplicationsTypes.GET_APPLICATION_GRAPH_DATUM_FAIL,
  payload: error,
});

// Application datum
export const getFilterApplicationDatum = (payload: {
  application_key: any;
  search: string;
  limit: number;
  page: number;
  sortBy: string;
  sortType: string;
}) => ({
  type: ApplicationsTypes.GET_FILTER_APPLICATION_DATUM_TABLE,
  payload: { ...payload },
});

export const getFilterApplicationDatumSuccess = (application_filter_datum: any) => ({
  type: ApplicationsTypes.GET_FILTER_APPLICATION_DATUM_TABLE_SUCCESS,
  payload: application_filter_datum,
});

export const getFilterApplicationDatumFail = (error: any) => ({
  type: ApplicationsTypes.GET_FILTER_APPLICATION_DATUM_TABLE_FAIL,
  payload: error,
});

// Application datum
export const getPublisherEarningTransaction = (payload: {
  application_key: any;
  limit: number;
  page: number;
  sortBy: string;
  sortType: string;
}) => ({
  type: ApplicationsTypes.GET_PUBLISHER_EARNING_TRANSACTION,
  payload: { ...payload },
});

export const getPublisherEarningTransactionSuccess = (
  application_earning_transaction: any,
) => ({
  type: ApplicationsTypes.GET_PUBLISHER_EARNING_TRANSACTION_SUCCESS,
  payload: application_earning_transaction,
});

export const getPublisherEarningTransactionFail = (error: any) => ({
  type: ApplicationsTypes.GET_PUBLISHER_EARNING_TRANSACTION_FAIL,
  payload: error,
});
