import { CampaignsTypes } from './actionTypes';

export const getCampaigns = () => ({
  type: CampaignsTypes.GET_CAMPAIGNS,
});

export const getSumDailyCurrentBudget = () => ({
  type: CampaignsTypes.GET_SUM_DAILY_CURRENT_BUDGET,
});

export const getSumDailyCurrentBudgetSuccess = (payload: any) => ({
  type: CampaignsTypes.GET_SUM_DAILY_CURRENT_BUDGET_SUCCESS,
  payload,
});

export const getCampaignsSuccess = (campaigns: any) => ({
  type: CampaignsTypes.GET_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});

export const getCampaignsFail = (error: any) => ({
  type: CampaignsTypes.GET_CAMPAIGNS_FAIL,
  payload: error,
});

export const getCampaignsFilter = (payload: {
  limit: number;
  page: number;
  name?: string;
  status?: any;
  ad_format?: string;
}) => {
  return {
    type: CampaignsTypes.GET_CAMPAIGNS_FILTER,
    payload: { ...payload },
  };
};

export const getCampaignsFilterSuccess = (transactions: any) => {
  return {
    type: CampaignsTypes.GET_CAMPAIGNS_FILTER_SUCCESS,
    payload: transactions,
  };
};

export const getCampaign = (id: number) => ({
  type: CampaignsTypes.GET_CAMPAIGN,
  id,
});

export const getCampaignSuccess = (campaign: any, id: number) => ({
  type: CampaignsTypes.GET_CAMPAIGN_SUCCESS,
  payload: campaign,
  id,
});

export const getCampaignTodayTotal = () => ({
  type: CampaignsTypes.GET_CAMPAIGN_TODAY_TOTAL,
});

export const getCampaignTodayTotalSuccess = (campaign: any) => ({
  type: CampaignsTypes.GET_CAMPAIGN_TODAY_TOTAL_SUCCESS,
  payload: campaign,
});

export const getCampaignStatusSummary = (bannerType: string) => ({
  type: CampaignsTypes.GET_CAMPAIGN_STATUS_SUMMARY,
  bannerType,
});

export const getCampaignStatusSummarySuccess = (campaign: any) => ({
  type: CampaignsTypes.GET_CAMPAIGN_STATUS_SUMMARY_SUCCESS,
  payload: campaign,
});

export const getCampaignFail = (error: any) => ({
  type: CampaignsTypes.GET_CAMPAIGN_FAIL,
  payload: error,
});

export const getCampaignReport = (payload: {
  searchCreative?: string;
  search?: string;
  limit?: number;
  page?: number;
  start_date?: string | null;
  end_date?: string | null;
  campaign_key?: string;
}) => ({
  type: CampaignsTypes.GET_CAMPAIGN_REPORT,
  payload: { ...payload },
});

export const getCampaignReportSuccess = (campaignReport: any) => ({
  type: CampaignsTypes.GET_CAMPAIGN_REPORT_SUCCESS,
  payload: campaignReport,
});

export const getCampaignReportFail = (error: any) => ({
  type: CampaignsTypes.GET_CAMPAIGN_REPORT_FAIL,
  payload: error,
});

export const campaignExcludeWebsites = (id: number) => {
  return {
    type: CampaignsTypes.CAMPAIGN_EXCLUDE_WEBSITES,
    id,
  };
};

export const campaignExcludeWebsitesSuccess = (excludeWebsites: any) => {
  return {
    type: CampaignsTypes.CAMPAIGN_EXCLUDE_WEBSITES_SUCCESS,
    payload: excludeWebsites,
  };
};
