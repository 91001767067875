export default {
  BTC: 'BTC',
  ETH: 'ETH',
  USDT_ERC20: 'USDT_ERC20',
  USDT_TRC20: 'USDT_TRC20',
  USDT_BEP20: 'USDT_BEP20',
  XRP: 'XRP',
  WIS_ERC20: 'WIS_ERC20',
  WIS_TRC20: 'WIS_TRC20',
  DOGE: 'DOGE',
  CAS: 'CAS',
  WIS: 'WIS',
  USDT: 'USDT',
};
