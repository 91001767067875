import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ReportChart from 'src/components/Publisher/ReportChart';
import WebsiteReports from 'src/components/Publisher/Website/WebsiteReports';

const WebReport = () => {
  const { id }: any = useParams();
  const { websiteGraphDatum } = useSelector((state: any) => state.WebsiteInfo);
  const [datumReportParam, setDatumReportParam] = useState({
    website_id: +id,
    limit: 10,
    page: 1,
    log_range: 'DAILY',
  });
  return (
    <>
      <div className="mb-3">
        <ReportChart
          graph_datum={websiteGraphDatum}
          setDatumReportParam={setDatumReportParam}
          datumReportParam={datumReportParam}
        />
      </div>
      <div>
        <WebsiteReports
          ads_data={{
            items: websiteGraphDatum?.items,
            totalCount: websiteGraphDatum?.totalCount,
          }}
          setDatumReportParam={setDatumReportParam}
          datumReportParam={datumReportParam}
        />
      </div>
    </>
  );
};

export default WebReport;
