import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserProfileList } from 'src/store/actions';

const InfluencerDashboardControl = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserProfileList([]));
  }, []);
  return {};
};

export default InfluencerDashboardControl;
