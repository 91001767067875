import { useState, useEffect } from 'react';

export default function useWindowSize() {
  // const isSSR = typeof window !== 'undefined';
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    window.addEventListener('load', () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
    window.addEventListener('resize', () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });

      window.removeEventListener('load', () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    };
  }, []);

  return windowSize;
}
