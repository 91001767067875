import React from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from 'src/utils/windowSize';
import { Row, Col, BreadcrumbItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import menu from '../../constants/menu';

interface BreadcrumbProps {
  t: any;
  pageTitle: string;
  menus: { menu: string; active?: boolean; id?: string; isSamePage?: boolean }[];
}

// eslint-disable-next-line react/prop-types
const Breadcrumb = ({ t, pageTitle, menus }: BreadcrumbProps) => {
  const { width } = useWindowSize();
  const renderMenus = () => {
    return menus.map((item) => {
      let path = '/';

      switch (item.menu) {
        case menu.DASHBOARD:
          path = '/';
          break;
        case menu.WITHDRAW_TRANSACTIONS:
          path = '/admin/approve/withdraw';
          break;
        case menu.WEBSITE:
          path = '/mywebsite';
          break;
        case menu.WEBSITEINFO:
          path = '/mywebsite/websiteinfo/:id(\\d+)';
          break;
        case menu.PROFILE:
          path = '/profile';
          break;
        case menu.ADMIN_MOVEMENT_BALANCE:
          path = '/admin/movement-balance';
          break;
        case menu.USER_DETAIL:
          path = `/admin/users/${item.id}`;
          break;
        case menu.ADMIN_SETTINGS:
          path = '/admin/settings';
          break;
        case menu.ADMIN_SETTINGS_ADVERTISER:
          path = '/admin/settingAdvertiser';
          break;
        case menu.NOTIFICATION:
          path = '/notifications';
          break;
        case menu.SYSTEM_BALANCE_LOG:
          path = '/system-balance-log';
          break;
        case menu.CRYPTO_BALANCE_LOG:
          path = '/crypto-balance-log';
          break;
        case menu.ROLE_BALANCE_LOG:
          path = '/role-balance-log';
          break;
        case menu.PROFIT_BALANCE_LOG:
          path = '/admin/profit-balance-log';
          break;
        case menu.SYSTEM_BALANCE_OVERVIEW:
          path = '/system-balance-overview';
          break;
        case menu.ADMIN_USERS:
          path = '/admin/users';
          break;
        case menu.CAMPAIGN:
          path = '/campaigns/markerter/job';
          break;
        case menu.WALLET:
          path = '/wallet';
          break;
        case menu.APPROVAL_CREATIVE:
          path = '/admin/approve/approvalAdvertiser';
          break;
        case menu.PUBLISHER_WEBSITE:
          path = '/admin/publisher';
          break;
        case menu.NEW_INFLUENCER:
          path = '/admin/approve/influencer';
          break;
        case menu.INFLUENCER_REQUEST_NEW_PRICE:
          path = '/admin/approve/influencer-request';
          break;
        case menu.JOB_APPROVAL:
          path = '/admin/manage-job';
          break;
        case menu.PAYMENT_TRANSACTION:
          path = '/admin/payment';
          break;
        case menu.INFLUENCER_RATE_PRICE:
          path = '/admin/setting-influencer';
          break;
        case menu.ALL_USER:
          path = '/admin/users';
          break;
        case menu.CAMPAIGN_DETAIL:
          path = `/campaigns/${item.id}`;
          break;
        case menu.FAQs:
          path = `/admin/settingFAQs`;
          break;
        case menu.Document:
          path = '/doc/mobile-ad';
          break;
        case menu.HOME:
          path = '/';
          break;
        case menu.MARKETER_INVITEONLY_CAMPAIGN:
          path = '/';
          break;
        case menu.MARKETER_ANNOUNCEMENT_CAMPAIGN:
          path = '/marketer/PublicCampaign';
          break;
        case menu.MARKETER_CAMPAIGN_JOB:
          path = `/MarketerCampaign?campaign=${item.id}`;
          break;
        case menu.MARKETER_CAMPAIGN_JOB_ANNOUNCEMENT:
          path = `/MarketerPublicCampaign?campaign=${item.id}`;
          break;
        case menu.JOB_TRACKING:
          path = `/Marketer/JobDetail?job=${item.id}`;
          break;
        case menu.CREATE_CAMPAIGN:
          path = '/marketer/create-campaign';
          break;
        case menu.CREATE_JOB:
          path = `/Marketer/EditJobCampaign?job=${item.id}`;
          break;
        case menu.JOBS:
          path = '/influencer/job';
          break;
        case menu.MY_JOBS:
          path = `/JobDetail?job=${item.id}&detail=${item.id}`;
          break;
        case menu.OVERVIEW:
          path = '/';
          break;
        case menu.SOCIAL_CHANNEL:
          path = `InfluencerChannel?channel=${item.id}`;
          break;
        case menu.ADD_SOCIAL_CHANNEL:
          path = '/influencer/channel-add';
          break;
        case menu.CREATESOCIALCHANNEL:
          path = '/influencer/create-channel-detail';
          break;
        case menu.SPEED_UP:
          path = '/admin/approve/speed-up-job';
          break;
        case menu.SUGGESTED_JOBS:
          path = '/influencer/publish-job';
          break;
        case menu.RENEW_SOCIAL_TOKEN:
          path = '/influencer/renew';
          break;
        case menu.DISCOVER:
          path = '/Discover';
          break;
        case menu.DISCOVER_ALL:
          path = '/DiscoverAll';
          break;
        case menu.BAN_USER:
          path = '/admin/reportJob';
          break;
        case menu.REPORT_DECLINE_JOB:
          path = '/admin/reportDeclineJob';
          break;
        case menu.REQUEST_FOLLOWER:
          path = '/admin/approve/influencer-follower';
          break;
        default:
          path = '/';
      }

      return (
        <BreadcrumbItem active={item.active} key={item.menu}>
          <Link
            to={
              item.isSamePage
                ? window.location.pathname + window.location.search
                : path
            }
          >
            {t(
              `Menus.${item.menu
                .split(' ')
                .map((str) => upperFirst(str))
                .join(' ')}`,
            )}
          </Link>
        </BreadcrumbItem>
      );
    });
  };

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">{renderMenus()}</ol>
          </div>
          <h5
            className="mb-0"
            style={
              width <= 300
                ? { fontSize: '12px', visibility: 'hidden' }
                : { visibility: 'hidden' }
            }
          >
            {pageTitle}
          </h5>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(Breadcrumb);
