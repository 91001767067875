import { gql } from '@apollo/client';
import { client } from '../client';
import { ReferralTransactionsQuery } from '../../common/types';

const getSystemTaxReferralGql = async () => {
  const query = gql`
    query {
      getSystemTax
    }
  `;

  const result: any = await client.query({
    query,
    fetchPolicy: 'no-cache',
  });

  return result.data.getSystemTax;
};

const getReferralEmailByRefCode = async (ref: string) => {
  const GET_REFERRAL_EMAIL_BY_REF_CODE = gql`
    query GetReferralEmailByRefCode($ref: String!) {
      getUserByReferralCode(ref: $ref) {
        email
      }
    }
  `;

  const result: any = await client.query({
    query: GET_REFERRAL_EMAIL_BY_REF_CODE,
    variables: {
      ref,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.getUserByReferralCode.email;
};

const getReferralTransactions = async (queryInput: ReferralTransactionsQuery) => {
  const GET_REFERRAL_TRASACTIONS = gql`
    query GetReferralTransactions($input: ReferralQueryInput!) {
      getReferralTransactions(input: $input) {
        items {
          parent {
            email
            role
          }
          child {
            email
            role
          }
          total_earnings
          last_updated_at
        }
        totalCount
        sumTotalEarnings
        sumTotalEarningBySymbols {
          symbol
          sum_total_earnings
        }
      }
    }
  `;

  const result: any = await client.query({
    query: GET_REFERRAL_TRASACTIONS,
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
  });

  return result.data.getReferralTransactions;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getReferralEmailByRefCode,
  getReferralTransactions,
  getSystemTaxReferralGql,
};
