import ForgotPwdTypes from './actionTypes';

export const forgotPassword = (data: any, history: any) => {
  return {
    type: ForgotPwdTypes.FORGOT_PASSWORD,
    payload: { data, history },
  };
};

export const forgotPasswordSuccess = (message: string) => {
  return {
    type: ForgotPwdTypes.FORGOT_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const forgotPasswordFailed = (message: any) => {
  return {
    type: ForgotPwdTypes.FORGOT_PASSWORD_ERROR,
    payload: message,
  };
};

export const resetPassword = (data: any, history: any) => {
  return {
    type: ForgotPwdTypes.RESET_PASSWORD,
    payload: { data, history },
  };
};

export const resetPasswordSuccess = (message: string) => {
  return {
    type: ForgotPwdTypes.RESET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const resetPasswordFailed = (message: any) => {
  return {
    type: ForgotPwdTypes.RESET_PASSWORD_ERROR,
    payload: message,
  };
};
