import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import done from '../assets/images/done.png';
import { disableTwoFactorConfirm } from '../store/auth/twofactor/actions';

const TwoFactorDisableConfirmPage = ({ location, t }: any) => {
  const dispatch = useDispatch();
  const { id, token } = queryString.parse(location.search);

  useEffect(() => {
    const data = {
      id,
      token,
    };

    dispatch(disableTwoFactorConfirm(data));
  }, []);

  return (
    <div className="my-5 pt-5">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-5">
              <h1 className="display-1 fw-semibold">Successfully</h1>
              <h4 className="text-uppercase">Two Factor Disabled</h4>
              <p>Disabled Two Factor Successfully.</p>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary" to="/">
                  {t('Back To Dashboard')}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row justify-content-center">
          <div className="col-md-10 col-xl-8">
            <div>
              <img src={done} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(withRouter(TwoFactorDisableConfirmPage));
