import { useSelector } from 'react-redux';
import {
  recentTransactionDate,
  recentTransactionDateTime,
  normalDateTime,
  transactionDetailDateTime,
  getNotificationPastTime,
  getDateTimeFullFormat,
} from '../../utils/dateTime';
import DateTimeTypes from '../../constants/dateTimeTypes';

const DateTimeComponent = ({ dateTime, dateTimeType }: any) => {
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));

  const renderDateTime = () => {
    switch (dateTimeType) {
      case DateTimeTypes.RECENT_TRANSACTION_DATE:
        return recentTransactionDate(dateTime, user.timezone);
      case DateTimeTypes.RECENT_TRANSACTION_DATE_TIME:
        return recentTransactionDateTime(dateTime, user.timezone);
      case DateTimeTypes.NORMAL_DATE_TIME:
        return normalDateTime(dateTime, user.timezone);
      case DateTimeTypes.TRANSACTION_DETAIL_DATE_TIME:
        return transactionDetailDateTime(dateTime, user.timezone);
      case DateTimeTypes.GET_NOTIFICATION_PAST_TIME:
        return getNotificationPastTime(dateTime, user.timezone);
      case DateTimeTypes.GET_DATE_TIME_FULL_FORMAT:
        return getDateTimeFullFormat(dateTime, user.timezone);
      default:
        return null;
    }
  };

  return <>{renderDateTime()}</>;
};

export default DateTimeComponent;
