import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HomepageDrawer } from 'src/components/CommonForBoth/Menus/drawer';
import LanguageDropdown from 'src/components/CommonForBoth/TopbarDropdown/LanguageDropdown';
import config from 'src/config';
import useWindowSize from 'src/utils/windowSize';
import logo from '../../assets/images/flupower/logo-headpage.png';
import 'src/components/Home/HomePageContent.style.scss';

const HomePageNavBar = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div
      style={{
        padding: '30px 25px 20px 15px',
      }}
      className="container home-page-banner__header d-flex align-items-center justify-content-center"
    >
      <div
        className="home-page-banner__logo mx-2 d-flex justify-content-center align-items-center"
        style={{ height: '55px' }}
      >
        <img src={logo} alt="logo" />
      </div>
      <div className="flex-grow-1 d-none d-md-flex justify-content-between">
        <ul className="home-page-banner__navbar" style={{ textAlign: 'center' }}>
          <li className="nav-item" style={{ textAlign: 'center' }}>
            {/* <Link to="/portfolio" className="nav-link text-white font-size-10">
              {t('Showcase')}
            </Link> */}
          </li>
          <li className="nav-item" style={{ textAlign: 'left' }}>
            {/* <Link to="/faqs" className="nav-link text-white font-size-10">
              {t('FAQ')}
            </Link> */}
          </li>
        </ul>
      </div>
      <div className="me-2">
        {width > 767 && (
          <LanguageDropdown
            bodyCSS="btn home-dropdown-language"
            textCSS="text-black btn-language"
            fill="white"
          />
        )}
      </div>

      <div className="d-flex align-items-center">
        <div className="register-login">
          <Link to="/login">
            <div className="btn btn-outline-light btn-outline-rounded text-white mx-2 font-size-14">
              {t('Login')}
            </div>
          </Link>
          <Link to="/register" className="btn btn-black font-size-14" type="button">
            {t('Register')}
          </Link>
        </div>
      </div>
      <div style={{ position: 'absolute', right: '2rem' }}>
        {' '}
        {width <= 767 && <HomepageDrawer />}
      </div>
    </div>
  );
};

export default HomePageNavBar;
