import { call, put, takeEvery } from 'redux-saga/effects';

import { CreativeTypeTypes } from './actionTypes';

import { getCreativeTypeSuccess, getCreativeTypeFail } from './actions';

import creativeTypeGraphql from '../../graphql/advertiser/creativeType';

import { setLoading, unsetLoading } from '../actions';

function* fetchCreativeType() {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(creativeTypeGraphql);

    yield put(getCreativeTypeSuccess(response));
  } catch (error) {
    yield put(getCreativeTypeFail(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* creativeType() {
  yield takeEvery(CreativeTypeTypes.GET_CREATIVE_TYPE, fetchCreativeType);
}

export default creativeType;
