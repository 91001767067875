import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useParams } from 'react-router';
import {
  campaignExcludeWebsites as onCampaignExcludeWebsites,
  setLoading,
  unsetLoading,
} from '../../../store/actions';
import {
  createCampaignExcludeWebsiteGraphql,
  removeCampaignExcludeWebsite,
} from '../../../graphql/advertiser/campaign';

const ManageWeb = ({ t }: any) => {
  const { id }: any = useParams();

  //from react
  const dispatch = useDispatch();
  //state variable
  const [numFrom, setNumFrom] = useState([0]);
  //update exclude web variable
  const [url, setUrl] = useState<any>(['']);
  //redux
  const { campaignExcludeWebsites } = useSelector((state: any) => ({
    campaignExcludeWebsites: state.Campaigns.campaignExcludeWebsites,
  }));

  //default values
  const [yubSchema, setYubSchema] = useState<any>({
    url0: Yup.string().required(t('invalid.Required')),
  });
  const [initialValues, setInitialValues] = useState<any>({
    url0: '',
  });
  const manageWebSchema = Yup.object().shape(yubSchema);
  //submit
  const onSubmit = async () => {
    dispatch(setLoading());
    await Promise.all(
      await url.map(async (urlData: any) => {
        const result = await createCampaignExcludeWebsiteGraphql({
          campaign_key: id,
          urlWeb: urlData,
        });
        return [result];
      }),
    );
    dispatch(onCampaignExcludeWebsites(id));
    dynamicClearForm();
    dispatch(unsetLoading());
    setInitialValues({ url0: '' });
    setYubSchema({
      url0: Yup.string().required(t('invalid.Required')),
    });
  };
  //general function
  const dynamicAddForm = () => {
    const newInitialValues = initialValues;
    newInitialValues[`url${numFrom?.length}`] = '';
    const newYubSchema = yubSchema;
    newYubSchema[`url${numFrom?.length}`] =
      newYubSchema[`url${numFrom?.length - 1}`];

    setInitialValues(newInitialValues);
    setYubSchema(newYubSchema);
    setNumFrom([...numFrom, numFrom?.length]);
    setUrl([...url, '']);
  };
  const dynamicPopForm = () => {
    const newInitialValues = initialValues;
    delete newInitialValues[`url${numFrom?.length - 1}`];
    const newYubSchema = yubSchema;
    delete newYubSchema[`url${numFrom?.length - 1}`];
    const minusNum = [...numFrom];
    minusNum.pop();
    const minusUrl = [...url];
    minusUrl.pop();
    setInitialValues(newInitialValues);
    setNumFrom(minusNum);
    setUrl(minusUrl);
  };
  const dynamicClearForm = () => {
    setInitialValues({
      url0: '',
    });
    setYubSchema({
      url0: Yup.string().required(t('invalid.Required'))
        .url(`The URL field is invalid. Make sure to include the protocol (HTTP/HTTPS)
            `),
    });
    setNumFrom([0]);
    setUrl(['']);
  };
  //set data
  useEffect(() => {
    dispatch(onCampaignExcludeWebsites(id));
  }, []);

  return (
    <Row className="p-4">
      {campaignExcludeWebsites?.length !== 0 && (
        <>
          {' '}
          <Col md={5}>
            <div>
              <p className="font-size-22 fw-bold">{t('campaign:Excluded web')}</p>

              <SimpleBar className="px-3 mb-4" style={{ maxHeight: '220px' }}>
                {campaignExcludeWebsites.map((data: any) => {
                  return (
                    <Row key={data.key} style={{ borderBottom: '1px solid ' }}>
                      <Col className="mt-2" xs={10}>
                        {data.urlWeb}
                      </Col>
                      <Col className="mt-2" xs={2}>
                        <div className="avatar-sm text-center">
                          <span
                            className="avatar-bin bg-gainsboro rounded-circle"
                            onKeyDown={() => {}}
                            onClick={async () => {
                              await Swal.fire({
                                width: 300,
                                title: t('swal:Are you sure ?'),
                                text: t("swal:You won't be able to revert this!"),
                                imageUrl: choice,
                                imageHeight: 200,
                                customClass: {},
                                imageAlt: 'A tall image',
                                cancelButtonColor: 'red',
                                showCancelButton: true,
                                confirmButtonColor: '#009a78',
                                confirmButtonText: t('button:Yes'),
                                cancelButtonText: t('swal:Cancel'),
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  dispatch(setLoading());
                                  await removeCampaignExcludeWebsite(data.key);
                                  dispatch(onCampaignExcludeWebsites(id));
                                  dispatch(unsetLoading());
                                }
                              });
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <i className="mdi mdi-delete font-size-16 text-primary" />
                          </span>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </SimpleBar>
            </div>
          </Col>
          <Col md={7} />
        </>
      )}
      <Col md={5}>
        <h5 className="font-size-22 fw-bold mt-2 ">
          {t('campaign:Add Exclude web')}{' '}
          <button
            type="button"
            className="btn btn-info w-auto mt-3 font-size-16 fw-normal float-end ms-3"
            onClick={dynamicAddForm}
          >
            <i
              className="mdi mdi-pen-plus font-size-14 d-flex justify-content-center align-items-center"
              style={{ width: '21px', height: '21px' }}
            />
          </button>
          {numFrom?.length > 1 && (
            <button
              type="button"
              className="btn btn-danger mt-3 font-size-16 float-end"
              onClick={dynamicPopForm}
            >
              <i
                className="mdi mdi-pencil-minus font-size-16 d-flex justify-content-center align-items-center"
                style={{ width: '21px', height: '21px' }}
              />
            </button>
          )}
        </h5>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={manageWebSchema}
        >
          {({ errors, touched, handleChange }) => (
            <FormikForm>
              {numFrom.map((i) => (
                <div key={i}>
                  <FormGroup className="mt-2">
                    <Label htmlFor={`url${i}`} className="input-label fs-5">
                      Website Domain {i + 1}
                      <span className="input-required">*</span>
                    </Label>
                    <Field
                      name={`url${i}`}
                      value={url[i]}
                      type="text"
                      className={`form-control  ${
                        errors[`url${i}`] && touched[`url${i}`] && 'is-invalid'
                      }`}
                      disabled={url[i - 1] === ''}
                      onChange={(e: any) => {
                        const singleUrl = [...url];
                        singleUrl[i] = e.currentTarget.value;
                        setUrl(singleUrl);
                        handleChange(e);
                      }}
                      style={url[i - 1] === '' ? { cursor: 'no-drop' } : {}}
                      placeholder={t('invalid.Required')}
                    />

                    <ErrorMessage
                      name={`url${i}`}
                      component="div"
                      className="invalid-message fs-6"
                    />
                  </FormGroup>
                </div>
              ))}

              <br />
              <button
                type="submit"
                className="btn btn-success ms-1 mt-3 fw-bold w-auto"
                disabled={Object.keys(errors)?.length !== 0}
              >
                {t('button:Save Change')}
              </button>
            </FormikForm>
          )}
        </Formik>
      </Col>
      <Col md={1} />
      <Col md={6} className="mt-4 mt-md-0 p-0 p-md-4">
        <div className="instruction-container">
          <h6 className="font-size-22">{t('Instruction')}</h6>
          <p>{t('Exclude Web Instructions')}</p>
          <p>{t('Exclude Web Instructions 2')}</p>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(ManageWeb);
