import { getRequestAccessTokenTwitter } from 'src/graphql/auth/twitter';

const getAccessTokenTwitter = async (
  oauthToken: string,
  oauthVerifier: string,
): Promise<{ token: string; tokenSecret: string; userId: string }> => {
  const response = await getRequestAccessTokenTwitter(oauthToken, oauthVerifier);
  const datum = JSON.parse(response);
  const { token, tokenSecret, userId } = datum;
  if (!token || !tokenSecret || !userId) throw new Error('Found an error');
  return { token, tokenSecret, userId };
};

export default getAccessTokenTwitter;
