/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum MarketerJobTypes {
  CREATE_MARKETER_JOB = '@@marketer_job/CREATE_MARKETER_JOB',
  CREATE_MARKETER_JOB_NEW = '@@marketer_job/CREATE_MARKETER_JOB_NEW',
  CREATE_MARKETER_JOBS_NEW = '@@marketer_job/CREATE_MARKETER_JOBS_NEW',
  SET_DESCRIPTION_JOB_NEW = '@@marketer_job/SET_DESCRIPTION_JOB_NEW',

  GET_MARKETER_JOB = '@@marketer_job/GET_MARKETER_JOB',
  GET_MARKETER_JOB_SUCCESS = '@@marketer_job/GET_MARKETER_JOB_SUCCESS',

  UPDATE_MARKETER_JOB = '@@marketer_job/UPDATE_MARKETER_JOB',
  UPDATE_MARKETER_JOB_SUCCESS = '@@marketer_job/UPDATE_MARKETER_JOB_SUCCESS',

  FILTER_JOB = '@@marker_campaign/FILTER_JOB',
  FILTER_JOB_SUCCESS = '@@marker_campaign/FILTER_JOB_SUCCESS',

  CLEAR_MARKETER_JOB = '@@marker_campaign/CLEAR_MARKETER_JOB',
}

export default MarketerJobTypes;
