import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { networks, toggleNetworkAvailable } from 'src/graphql/common';
import {
  getCurrencies as onGetCurrencies,
  toggleCurrencyAvailable,
} from '../../store/currencies/actions';
import CoinImage from '../Common/CoinImage';

const NetworksSettingComponent = ({ t }: any) => {
  const dispatch = useDispatch();
  const [Networks, setNetworks] = useState([]);

  const queryNetwork = async () => {
    const _networks = await networks();
    if (_networks) {
      setNetworks(_networks);
    }
  };

  useEffect(() => {
    queryNetwork();
  }, []);

  const { currencies } = useSelector((state: any) => ({
    currencies: state.Currencies.currencies,
  }));

  const handleToggleNetworkAvailable = (network: any) => {
    const { name } = network;
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Setting Available Network Confirm', { name }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {
        popup: 'border-color',
      },
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await toggleNetworkAvailable(network.id);
        await queryNetwork();
      }
    });
  };

  const renderNetworkStatus = (network: any) => {
    return network.is_available ? (
      <div className="led-box">
        <div className="led-green" />
      </div>
    ) : (
      <div className="led-box">
        <div className="led-red" />
      </div>
    );
  };

  return (
    <Row>
      <div className="p-2 text-danger">
        *{t('Available Currency Setting Helper')}
      </div>
      {!isEmpty(Networks) &&
        Networks.map((network: any) => {
          return (
            <Col lg={4}>
              <Card>
                <CardBody className="overflow-hidden position-relative">
                  <div className="d-flex">
                    <h5 className="d-flex align-self-center text-secondary align-items-center mb-0">
                      <span className="logo-sm mx-1 text-white">{network.name}</span>
                    </h5>
                    <span className="d-flex align-items-center flex-grow-1 mx-2">
                      {renderNetworkStatus(network)}
                    </span>
                    <div className="form-check form-switch form-switch-lg">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizelg"
                        onChange={() => {
                          handleToggleNetworkAvailable(network);
                        }}
                        checked={network.is_available}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default withTranslation()(NetworksSettingComponent);
