import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import AdvertiserSelectSubRole from 'src/components/Advertiser/Dashboard/AdvertiserSelectSubRole';
import HomePageContent from 'src/components/Home/HomePageContent';
import { useTranslation } from 'react-i18next';
import Role from 'src/constants/enum/role';
import notify from 'src/utils/notify';
import { useHistory } from 'react-router';
import { getUserInfo } from 'src/store/users/actions';
import { checkIsBanned, getDefaultWebsite } from 'src/store/actions';
import Banned from 'src/components/Banned.component';
import ModalBanUsers from 'src/components/Advertiser/Models/SelectInfuencer/ModalBanUsers';
import Layout from '../components/Layout';
import Dashboard from './Dashboard';
import InfluencerDashboardComponent from './Publisher/Influencer/InfluencerDashboard/InfluencerDashboard.component';
import MarketerCampaignIndex from './Advertiser/Marketer/CreateMarketerCampaign/marketerCampaignIndex';
import MarketerCampaignIndexNew from './Advertiser/Marketer/CreateMarketerCampaign/marketerCampaignIndexNew';
import AdvertiserDashboardNew from './Dashboard/AdvertiserDashboardNew';

const HomePage = () => {
  const dispatch = useDispatch();
  const ref: any = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();

  const { isLoggedIn, userInfo, user, ban } = useSelector((state: any) => ({
    isLoggedIn: state.login.isLoggedIn,
    user: state.login.user,
    userInfo: state.Users.userInfo,
    ban: state.Ban.checkIsBan,
  }));

  useEffect(() => {
    if (user) {
      if (!userInfo.name) {
        dispatch(getUserInfo());
      }
      if (user.role === 'ADVERTISER') {
        dispatch(getDefaultWebsite());
      }
      dispatch(checkIsBanned());
    }
  }, [user]);
  // console.log(user);
  // console.log(userInfo.name);

  const [init, setInit] = useState(false);
  useEffect(() => {
    if (
      userInfo?.name ||
      window.location.pathname === '/profile' ||
      !isLoggedIn ||
      !userInfo.dataReceived ||
      user.role === Role.ADMIN
    )
      return;

    setOpen(true);
    // if (!init) {
    //   setInit(true);
    // } else {
    //   if (user.role) {
    //     if (user.role !== Role.MARKETER || user.role !== Role.ADVERTISER) {
    //       if (!userInfo.name) {
    //         setTimeout(() => {
    //           notify(t('error:please fill your information.'), 'warning');
    //           history.push('/profile');
    //         }, 500);
    //       }
    //     }
    //   }
    // }
  }, [userInfo.dataReceived]);

  useEffect(() => {
    if (!init) {
      setInit(true);
    } else {
      if (user?.role) {
        if (user.role !== Role.ADMIN) {
          if (!userInfo.name) {
            setTimeout(() => {
              notify(t('error:please fill your information.'), 'warning');
              history.push('/profile');
            }, 500);
          }
        }
      }
    }
  }, [userInfo]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (open) {
          onDrawerClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, open]);

  const onDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (ban) {
      if (ban.isBan === true) {
        history.push('/banned');
      }
    }
  }, [ban]);
  const renderDashboard = () => {
    // if (user.role !== Role.ADMIN) history.push('/banned');
    switch (user?.role) {
      case 'ADVERTISER':
        // return <MarketerCampaignIndex />;
        //return <MarketerCampaignIndexNew />;
        return <AdvertiserDashboardNew />;
      case 'PUBLISHER':
        return <InfluencerDashboardComponent />;
      case 'ADMIN':
        return <Dashboard />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <Layout>
            <>
              <Helmet>
                <title>Dashboard</title>
                <link rel="canonical" href="/dashboard" />
              </Helmet>
              {/* <Container fluid> */}
              {renderDashboard()}
              {/* </Container>; */}
              {/* {user.role === 'ADMIN' || user.role === 'OWNER' ? (
                <Dashboard />
              ) : user.role === 'ADVERTISER' ? (
                <div style={{ marginTop: 75 }}>
                  <AdvertiserSelectSubRole />
                </div>
              ) : (
                <div>
                  <PublisherDashboard />
                </div>
              )} */}
            </>
          </Layout>
        </>
      ) : (
        <HomePageContent />
        // <Login />
      )}
    </>
  );
};

export default HomePage;
