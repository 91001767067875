import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import balanceLogRangeEnum from '../../constants/balanceLogRangeEnum';

const DatePickerOptions = ({
  date,
  startDate,
  endDate,
  handleChange,
  placeholder,
  rangeType,
  isSmall,
}: any) => {
  const convertLocalToUTCDate = (d: any) => {
    // eslint-disable-next-line no-param-reassign
    d = new Date(d);
    // eslint-disable-next-line no-param-reassign
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    return d;
  };

  const getMinDate = () => {
    if (startDate) {
      // eslint-disable-next-line no-param-reassign
      const d = new Date(startDate);

      return new Date(
        rangeType === 'MONTHLY'
          ? d.setMonth(d.getMonth() + 1)
          : d.setDate(d.getDate() + 1),
      );
    } else {
      return null;
    }
  };

  const getMaxDate = () => {
    if (endDate) {
      // eslint-disable-next-line no-param-reassign
      const d = new Date(endDate);

      return new Date(
        rangeType === 'MONTHLY'
          ? d.setMonth(d.getMonth() - 1)
          : d.setDate(d.getDate() - 1),
      );
    } else {
      return new Date();
    }
  };

  return (
    <DatePicker
      className={`mb-3 mb-md-0 form-control ${isSmall ? 'form-control-sm' : ''}`}
      value={date}
      minDate={getMinDate()}
      maxDate={getMaxDate()}
      onChange={(d: any) => handleChange(convertLocalToUTCDate(d))}
      placeholderText={placeholder}
      dateFormat={
        rangeType === balanceLogRangeEnum.MONTHLY ? 'MM/yyyy' : 'MM/dd/yyyy'
      }
      showMonthYearPicker={rangeType === balanceLogRangeEnum.MONTHLY}
      showFullMonthYearPicker
    />
  );
};

export default withTranslation()(DatePickerOptions);
