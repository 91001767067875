// eslint-disable-next-line import/prefer-default-export
export function splitWordsWithUnderscore(symbol) {
  return symbol.split('_').join(' ');
}
export function splitUnderscoreWithTuple(symbol) {
  return symbol.split('_')?.length > 1
    ? symbol.split('_')?.join('(').concat(')')
    : symbol;
}

export function getCurrencySymbolFromMinWithdrawConfigKey(key) {
  return key.split('MIN_WITHDRAW_')[1];
}

export function getCurrencySymbolFromFeeWithdrawConfigKey(key) {
  return key.split('FEE_WITHDRAW_')[1];
}
export function getNetworkFromSymbol(symbol) {
  if (!symbol) {
    return '';
  }
  const splits = symbol.split('_');

  return splits?.length > 1 ? splits[1] : splits[0];
}
