import { combineReducers } from 'redux';

// Global
import Global from './global/reducer';

// Layout
import Layout from './layout/reducer';

// login
import login from './auth/login/reducer';

// register
import register from './auth/register/reducer';

// User Profile
import profile from './auth/profile/reducer';

// Forget Password
import forgotPassword from './auth/forgotpwd/reducer';

// Two Factor
import twoFactor from './auth/twofactor/reducer';

// Website List
import { Websitelist, WebsiteInfo } from './website/reducer';

// Currencies
import Currencies from './currencies/reducer';

// Finance Withdraw
import Withdraw from './finance/withdraw/reducer';

// Finance Wallet
import Wallet from './finance/wallet/reducer';

// Finance Deposit Address
import DepositAddresses from './finance/depositAddresses/reducer';
import publish from './finance/publish/reducer';
import inviteOnly from './finance/inviteOnly/reducer';
// Campaign
import Campaigns from './campaigns/reducer';

// FAQ
import FAQs from './faqs/reducer';

// Country
import Countries from './countries/reducer';

// CreativeType
import CreativeType from './creativeType/reducer';

// Creative
import Creatives from './creatives/reducer';

import Deposit from './finance/deposit/reducer';

// Finance Paymen
import Payment from './finance/payment/reducer';

// Default Value
import DefaultValue from './default-values/reducer';

import movement from './movement/reducer';

import changePassword from './auth/changepassword/reducer';

import Users from './users/reducer';

import SystemBalance from './systembalance/reducer';

import ActivityLogs from './activity-logs/reducer';

import AllowNotificationMails from './allow-notification-mail/reducer';

import Referral from './referral/reducer';

import Application from './application/reducer';

import Aptitude from './aptitude/reducer';

import MarketerCategory from './marketerCategory/reducer';

import MarketerCampaign from './marketerCampaign/reducer';

import MarketerJob from './marketerJob/reducer';

import MarketerCampaignJobsNew from './marketerJobNew/reducer';

import SocialMediaInfo from './socialMediaInfo/reducer';

import CalculateSocialMedia from './calculateSocialMedia/reducer';
import SuggestJobs from './suggestJob/reducer';

//Ban
import Ban from './ban/reducer';
import OwnCurrency from './own-currency/reducer';
import TaskTypes from './tasktype/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  login,
  register,
  profile,
  forgotPassword,
  twoFactor,
  DepositAddresses,
  Websitelist,
  WebsiteInfo,
  Deposit,
  Payment,
  Application,
  Wallet,
  publish,
  inviteOnly,
  SuggestJobs,
  Campaigns,
  FAQs,
  Countries,
  CreativeType,
  Creatives,
  Currencies,
  Withdraw,
  Global,
  DefaultValue,
  movement,
  changePassword,
  Users,
  SystemBalance,
  ActivityLogs,
  AllowNotificationMails,
  Referral,
  Aptitude,
  MarketerCategory,
  MarketerCampaign,
  MarketerJob,
  MarketerCampaignJobsNew,
  SocialMediaInfo,
  CalculateSocialMedia,
  Ban,
  OwnCurrency,
  TaskTypes,
});

export default rootReducer;
