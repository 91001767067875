import { call, put, takeEvery } from 'redux-saga/effects';
import { checkIsBannedGql, getBanListGql } from 'src/graphql/common/ban';
import { setLoading, unsetLoading } from '../actions';
import { getBanHistorySuccess, checkIsBannedSuccess } from './actions';
import BanType from './actionTypes';

function* getUserBanHistory({ payload }: any) {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(getBanListGql, payload);

    yield put(getBanHistorySuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* checkUserIsBanned() {
  try {
    yield put(setLoading());

    const response: Promise<any> = yield call(checkIsBannedGql);

    yield put(checkIsBannedSuccess(response));
  } catch (error) {
    //
  } finally {
    yield put(unsetLoading());
  }
}

function* ban() {
  yield takeEvery(BanType.GET_BAN_HISTORY, getUserBanHistory);
  yield takeEvery(BanType.CHECK_IS_BANNED, checkUserIsBanned);
}

export default ban;
