import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import { createFaq } from 'src/graphql/admin/faq';
import { useDispatch } from 'react-redux';
import { setLoading, unsetLoading } from 'src/store/actions';
import { FormGroup, Modal } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';

const CreateFaqModal = ({
  isOpen,
  handleToggle,
  refreshData,
  handleClose,
  t,
}: any) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');

  //default value
  const initialValues = {
    title: '',
    detail: '',
  };

  const FormikSchema = Yup.object().shape({
    title: Yup.string().required('Required').max(250),
    detail: Yup.string().required('Required').max(250),
  });
  const onSubmit = async () => {
    if (detail && title) {
      dispatch(setLoading());
      await createFaq({ title, detail });
      await refreshData();
      setTitle('');
      setDetail('');
      handleClose();
      dispatch(unsetLoading());
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={FormikSchema}
      >
        {({ errors, touched, handleChange }) => (
          <FormikForm>
            <div className="modal-header">
              <h5 className="modal-title mt-0">New FAQ</h5>
              <button
                type="button"
                onClick={handleClose}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-4 table-responsive">
              <table className="table mb-0">
                <tbody>
                  <tr className="fw-bold">
                    <FormGroup>
                      <p className="font-size-18 fw-bold">
                        {t('campaign:Title Price')}
                      </p>
                      <Field
                        name="title"
                        type="text"
                        autocomplete="off"
                        className={`form-control ${
                          errors.title && touched.title && 'is-invalid'
                        }`}
                        onChange={(e: any) => {
                          setTitle(e.target.value);
                          handleChange(e);
                        }}
                      />

                      <ErrorMessage
                        name="title"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </tr>
                  <tr className="fw-bold">
                    <FormGroup>
                      <p className="font-size-18 fw-bold">
                        {t('campaign:Detail Price')}
                      </p>
                      <Field
                        name="detail"
                        type="text"
                        as="textarea"
                        autocomplete="off"
                        className={`form-control ${
                          errors.detail && touched.detail && 'is-invalid'
                        }`}
                        onChange={(e: any) => {
                          setDetail(e.target.value);
                          handleChange(e);
                        }}
                      />

                      <ErrorMessage
                        name="detail"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  setTitle('');
                  setDetail('');
                }}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t('button:Cancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!(title && detail)}
              >
                {t('button:Add')}
              </button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default withTranslation()(CreateFaqModal);
