import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavItem, NavLink } from 'reactstrap';

const WebTabBar = ({ headerPillsTab, toggleHeaderPills, t }: any) => {
  const websiteData = useSelector((state: any) => state.WebsiteInfo.websiteInfo);
  return (
    <div className="nav-tabs-custom nav-scrollbar-none">
      {tabHeaders.map(({ tabId, label, style }: any) =>
        (websiteData.status !== 'REJECTED' && tabId === 3) || tabId !== 3 ? (
          <NavItem key={uniqueId()}>
            <NavLink
              style={style}
              className={classnames({
                active: headerPillsTab === tabId,
              })}
              onClick={() => {
                toggleHeaderPills(tabId);
              }}
            >
              <h5>{t(label)}</h5>
            </NavLink>
          </NavItem>
        ) : (
          ''
        ),
      )}
    </div>
  );
};

interface tabHeader {
  tabId: number;
  label: string;
  style?: any;
}
const tabHeaders: tabHeader[] = [
  {
    tabId: 1,
    label: 'Page.Ad units',
    style: {
      cursor: 'pointer',
    },
  },
  {
    tabId: 2,
    label: 'Page.Ad auto',
    style: {
      cursor: 'pointer',
    },
  },
  {
    tabId: 3,
    label: 'Page.Reports',
    style: {
      cursor: 'pointer',
    },
  },
  {
    tabId: 4,
    label: 'Page.Exclude Ad Categories',
    style: {
      cursor: 'pointer',
    },
  },
  {
    tabId: 5,
    label: 'Page.History',
    style: {
      cursor: 'pointer',
    },
  },
];

export default withTranslation()(WebTabBar);
