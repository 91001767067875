import SocialMediaInfoTypes from './actionTypes';

export interface Aptitude {
  key?: string;
  name: string;
}

export interface KeywordAptitude {
  key?: string;
  name: string;
}

interface SocialMediaInfoState {
  socialMediaInfo: any[];
  socialMediaInfoImage: any[];
  socialMediaInfoData: any | null;
  socialJob: any[];
  socialJobItem: any;
  filter: {};
  error: any | null;
  loading: boolean;
  aptitudes: Aptitude[];
  keywordAptitudes: KeywordAptitude[];
  influencerPriceRate: any[];
  influencerRequestNewPrice: {};
  socialMediaFilterTable: any[];
  requestNewPriceFilterTable: any[];
  getSocialPost: {};
  onSocialMediaInfoLoading: boolean;
  socialJobCardData: {};
  socialJobSummary: {};
  socialMediaInfoByUUID: any[];
  socialMediaInfoExpiredToken: any | null;
  socialJobCount: {};
  requestSocialMediaFilterTable: any[];
  discover: any[];
  recommendContentPrice: any | null;
  updateFrom3rdParty: any;
  filterG: {};
  socialHasJobRequest: {};
  socialHasJobTask: [];
  socialHasJobReview: {};
  marketerPendingBalance: {};
  socialGleamJboForReview: any;
  influencerActivePendingCount: {};
  declinedJob: [];
  userHasReportDecline: boolean;
  onUserHasReportDeclineLoading: boolean;
  socialGleamJobItem: any;
}

export const INIT_STATE: SocialMediaInfoState = {
  socialMediaInfo: [],
  socialMediaInfoImage: [],
  socialMediaInfoData: {},
  onSocialMediaInfoLoading: true,
  socialJob: [],
  socialJobItem: {},
  filter: {},
  error: {},
  loading: false,
  aptitudes: [],
  keywordAptitudes: [],
  influencerPriceRate: [],
  influencerRequestNewPrice: {},
  socialMediaFilterTable: [],
  requestNewPriceFilterTable: [],
  getSocialPost: {},
  socialJobCardData: {},
  socialJobSummary: {},
  socialMediaInfoByUUID: [],
  socialMediaInfoExpiredToken: null,
  socialJobCount: {},
  requestSocialMediaFilterTable: [],
  discover: [],
  recommendContentPrice: null,
  updateFrom3rdParty: null,
  filterG: {},
  socialHasJobRequest: {},
  socialHasJobTask: [],
  socialHasJobReview: {},
  marketerPendingBalance: {},
  socialGleamJboForReview: null,
  influencerActivePendingCount: {},
  declinedJob: [],
  userHasReportDecline: false,
  onUserHasReportDeclineLoading: true,
  socialGleamJobItem: {},
};

const SocialMediaInfo = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_SUCCESS:
      return {
        ...state,
        socialMediaInfo: action.payload,
      };

    case SocialMediaInfoTypes.GET_SOCIAL_JOB_BY_JOB_KEY_SUCCESS:
      return {
        ...state,
        socialJob: action.payload,
        onSocialMediaInfoLoading: false,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_JOB:
      return {
        ...state,
        onSocialMediaInfoLoading: true,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_JOB_SUCCESS:
      return {
        ...state,
        onSocialMediaInfoLoading: false,
        socialJobItem: action.payload,
      };
    case SocialMediaInfoTypes.RESET_SOCIAL_JOB:
      return {
        ...state,
        socialJobItem: {},
      };
    case SocialMediaInfoTypes.GET_SOCIAL_POST_SUCCESS:
      return {
        ...state,
        getSocialPost: action.payload,
      };
    case SocialMediaInfoTypes.FILTER_SOCIAL_JOB_SUCCESS:
      return {
        ...state,
        filter: action.payload,
        onSocialMediaInfoLoading: false,
      };
    case SocialMediaInfoTypes.GET_ONE_SOCIAL_MEDIA_INFO_SUCCESS:
      return {
        ...state,
        socialMediaInfoData: action.payload,
        onSocialMediaInfoLoading: false,
      };
    case SocialMediaInfoTypes.GET_ONE_SOCIAL_MEDIA_INFO:
      return {
        ...state,
        onSocialMediaInfoLoading: true,
      };
    case SocialMediaInfoTypes.GET_ALL_APTITUDE_SUCCESS:
      return {
        ...state,
        aptitudes: action.payload,
      };
    case SocialMediaInfoTypes.GET_ALL_APTITUDE_FAIL:
      return {
        ...state,
        aptitudes: [],
      };
    case SocialMediaInfoTypes.GET_ALL_KEYWORD_APTITUDE_SUCCESS:
      return {
        ...state,
        keywordAptitudes: action.payload,
      };

    case SocialMediaInfoTypes.SOCIAL_JOB_CARD_DATA:
      return {
        ...state,
        socialJobCardData: action.payload,
        onSocialMediaInfoLoading: false,
      };

    case SocialMediaInfoTypes.GET_ALL_KEYWORD_APTITUDE_FAIL:
      return {
        ...state,
        keywordAptitudes: [],
      };

    case SocialMediaInfoTypes.GET_INFLUENCER_PRICE_RATE_SUCCESS:
      return {
        ...state,
        influencerPriceRate: action.payload,
      };
    case SocialMediaInfoTypes.GET_INFLUENCER_REQUEST_NEW_PRICE_SUCCESS:
      return {
        ...state,
        influencerRequestNewPrice: action.payload,
      };
    case SocialMediaInfoTypes.SOCIAL_MEDIA_FILTER_TABLE_SUCCESS:
      return {
        ...state,
        socialMediaFilterTable: action.payload,
      };
    case SocialMediaInfoTypes.GET_REQUEST_NEW_PRICE_FILTER_TABLE_SUCCESS:
      return {
        ...state,
        requestNewPriceFilterTable: action.payload,
      };
    case SocialMediaInfoTypes.SOCIAL_JOB_SUMMARY_SUCCESS:
      return {
        ...state,
        socialJobSummary: action.payload,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_By_UUID_SUCCESS:
      return {
        ...state,
        socialMediaInfoByUUID: action.payload,
      };
    case SocialMediaInfoTypes.GET_EXPIRED_SOCIAL_MEDIA_TOKEN_SUCCESS:
      return {
        ...state,
        socialMediaInfoExpiredToken: action.payload,
      };
    case SocialMediaInfoTypes.CLEAR_EXPIRED_SOCIAL_MEDIA_TOKEN:
      return {
        ...state,
        socialMediaInfoExpiredToken: null,
      };
    case SocialMediaInfoTypes.SOCIAL_JOB_COUNT_SUCCESS:
      return {
        ...state,
        socialJobCount: action.payload,
      };
    case SocialMediaInfoTypes.REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE_SUCCESS:
      return {
        ...state,
        requestSocialMediaFilterTable: action.payload,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE_SUCCESS:
      return {
        ...state,
        discover: action.payload,
      };
    case SocialMediaInfoTypes.GET_RECOMMEND_CONTENT_PRICE_SUCCESS:
      return {
        ...state,
        recommendContentPrice: action.payload,
      };
    case SocialMediaInfoTypes.UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY_SUCCESS:
      return {
        ...state,
        updateFrom3rdParty: action.payload,
      };
    case SocialMediaInfoTypes.FILTER_SOCIAL_GLEAM_JOB_SUCCESS:
      return {
        ...state,
        filterG: action.payload,
        onSocialMediaInfoLoading: false,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY_SUCCESS:
      return {
        ...state,
        socialHasJobRequest: action.payload,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_WORK_SUCCESS:
      return {
        ...state,
        socialHasJobTask: action.payload,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY_SUCCESS:
      return {
        ...state,
        socialHasJobReview: action.payload,
      };
    case SocialMediaInfoTypes.MARKETER_SUM_PENDING_BALANCE_SUCCESS:
      return {
        ...state,
        marketerPendingBalance: action.payload,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        socialGleamJboForReview: action.payload,
      };
    case SocialMediaInfoTypes.INFLUENCER_ACTIVE_PENDING_JOB_COUNT_SUCCESS:
      return {
        ...state,
        influencerActivePendingCount: action.payload,
      };
    case SocialMediaInfoTypes.REPORT_JOB_DECLINE_SUCCESS:
      return {
        ...state,
        declinedJob: action.payload,
      };
    case SocialMediaInfoTypes.GET_REPORT_JOB_DECLINE_SUCCESS:
      return {
        ...state,
        userHasReportDecline: action.payload,
        onUserHasReportDeclineLoading: false,
      };
    case SocialMediaInfoTypes.GET_ADMIN_REPORT_JOB_DECLINE_SUCCESS:
      return {
        ...state,
        declinedJob: action.payload,
      };
    case SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_KEY_SUCCESS:
      return {
        ...state,
        socialGleamJobItem: action.payload,
      };
    default:
      return state;
  }
};

export default SocialMediaInfo;
