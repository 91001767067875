/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum ContentType {
  SHARE = 'SHARE',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  LIVE = 'LIVE',
  LIKE_AND_COMMENT = 'LIKE_AND_COMMENT',
  REVIEW = 'REVIEW',
  SUBSCRIBE = 'SUBSCRIBE',
  LIKE_PAGE = 'LIKE_PAGE',
  SHARE_GROUP = 'SHARE_GROUP',
}

export default ContentType;
