import { useTranslation } from 'react-i18next';
import { Card, Collapse, Modal } from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import date_step1 from 'src/assets/images/date_step1.png';
import date_step7 from 'src/assets/images/date_step7.png';
import date_step2 from 'src/assets/images/date_step2.png';
import date_step3 from 'src/assets/images/date_step3.png';
import date_step4 from 'src/assets/images/date_step4.png';
import date_step5 from 'src/assets/images/date_step5.png';
import date_step6 from 'src/assets/images/date_step6.png';
import { formatDateForm } from 'src/utils/dateTime';
import {
  updateSocialMediaHasJobGql,
  linkSocialMediaToMarketerJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialJob, setLoading, unsetLoading } from 'src/store/actions';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import Swal from 'sweetalert2';
import './jobDetailCollapse1.style.scss';
import search from 'src/assets/images/maggi.png';
import { useEffect, useState } from 'react';
import paramsFromURL from 'src/constants/paramsFromURL';
import CustomButton from 'src/components/Common/CustomButton';
import { filterFromRequirementsGql } from 'src/graphql/advertiser/job-requirement';
import MockJobRequirementTable from 'src/pages/Advertiser/Marketer/jobSummary/mockJobRequirementTable';
import config from 'src/config';
import moment from 'moment';

const JobDetailCollapse1 = ({
  collapse,
  marketerJob,
  workOrderRef,
  socialJobItem,
  setCollapse,
  able_get_job,
}: any) => {
  const { t } = useTranslation();

  const { influencerData, marketerCampaign } = useSelector((state: any) => ({
    influencerData: state.Users.userInfo,
    marketerCampaign: state.MarketerCampaignJobsNew,
  }));

  console.log('socialJobItem', socialJobItem);

  const [socialJobId]: any = paramsFromURL(['detail']);
  const dispatch = useDispatch();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  useEffect(() => {
    if (collapse.collapse1) setIsCollapseOpen(true);
  }, []);

  const [requirementData, setRequirementData] = useState<any>();

  const getRequirementData = async () => {
    const data = await filterFromRequirementsGql({
      limit: 10,
      page: 1,
      social_job_status: 'NEW_JOB',
      social_media_key: null,
      is_able_get_job: able_get_job === 'true',
      job_key: marketerJob.key,
    });

    setRequirementData(data);
  };

  useEffect(() => {
    if (marketerJob) {
      if (marketerJob?.job_type === 'PUBLISH') {
        getRequirementData();
      }
    }
  }, [marketerJob]);

  let timelineViewData = [
    {
      date: marketerJob?.date_submission,
      dataString: 'send job to influencer',
      icon: date_step1,
    },
    {
      date: marketerJob?.last_day_accept_job,
      dataString: 'influencer accept job',
      icon: date_step7,
    },
    {
      date: marketerJob?.last_day_influencer_submit,
      dataString: 'Last day influencer submit work for review',
      icon: date_step3,
    },
    {
      date: marketerJob?.last_day_to_approve,
      dataString: 'Last day review and approve',
      icon: date_step4,
    },
    {
      date: marketerJob?.influencer_post_internal_job,
      dataString: 'influencer submit job',
      icon: date_step5,
    },
    {
      date: marketerJob?.finish_internal_work,
      dataString: 'state completed',
      icon: date_step6,
    },
  ];

  const insert = (arr: any, index: number, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  if (marketerJob?.job_type === 'PUBLISH') {
    timelineViewData = insert(timelineViewData, 2, {
      date: marketerJob?.last_day_approve_job,
      dataString: 'The last day marketer confirm job.',
      icon: date_step2,
    });
  }

  const AcceptJob = async () => {
    const res: any = await updateSocialMediaHasJobGql({
      key: socialJobId,
      accept_date: new Date(),
    });
    setCollapse({ ...collapse, collapse1: false });
    if (res) {
      dispatch(getSocialJob(socialJobId));
      notify(i18n.t('success:Job has accepted.'), 'success');
    } else notify(i18n.t('error:Job has not accept .'), 'error');
  };
  const AcceptPublishJob = async (social_key: string) => {
    dispatch(setLoading());
    // const newSocialJob = await linkSocialMediaToJobGql({
    //   social_media_info_keys: [social_key],
    //   job_key: marketerJob.key,
    // });
    const newSocialJob = await linkSocialMediaToMarketerJobGql({
      social_media_info: [{ social_media_info_key: social_key, price: null }],
      job_key: marketerJob.key,
    });
    if (!newSocialJob?.length) {
      notify(
        i18n.t(`error:${newSocialJob.toString().split('Error: ')[1]}`),
        'error',
      );
      dispatch(unsetLoading());
      return;
    }
    if (newSocialJob?.length > 0) {
      const res: any = await updateSocialMediaHasJobGql({
        key: newSocialJob[newSocialJob?.length - 1].key,
        submit_date: new Date(requirementData.items[0].job.submit_date),
        accept_date: new Date(),
      });
      if (res) {
        //dispatch(getSocialJob(social_key));
        window.location.replace(
          `/JobDetail?job=${marketerJob.key}&detail=${newSocialJob[0].key}`,
        );
        notify(i18n.t('success:Job has accepted.'), 'success');
      } else notify(i18n.t('error:Job has not accept .'), 'error');
      dispatch(unsetLoading());
    }
  };

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      width: 300,
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      setCollapse({ ...collapse, collapse1: false });
      if (result.isConfirmed) {
        await updateSocialMediaHasJobGql({
          status: verify,
          key,
        });
      }
    });
  };

  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');

  return (
    <div className="job-detail-collapse1-container ">
      <Modal
        size="sm"
        isOpen={isOpen}
        toggle={toggleModal}
        style={{ maxWidth: '550px' }}
      >
        <div className="job-detail-collapse1-container ">
          <div className="influencer-select-modal">
            <h4>{t('select you influencer you want to accept job')}</h4>
            {requirementData?.items[0]?.image_key?.length ? (
              <div
                className="influencer-image-container"
                style={{ width: 'fit-content', display: 'flex', flexWrap: 'wrap' }}
              >
                {requirementData?.items[0]?.image_key.map(
                  (url: string, index: number) => {
                    return (
                      <div
                        key={url}
                        className="image-control"
                        onClick={async () => {
                          toggleModal();
                          await Swal.fire({
                            width: 300,
                            title: t('swal:Are you sure ?'),
                            text: t("swal:You won't be able to revert this!"),
                            imageUrl: choice,
                            imageHeight: 200,
                            imageAlt: 'A tall image',
                            customClass: {},
                            cancelButtonColor: 'red',
                            showCancelButton: true,
                            confirmButtonColor: '#009a78',
                            confirmButtonText: t('button:Yes'),
                            cancelButtonText: t('swal:Cancel'),
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              await AcceptPublishJob(
                                requirementData?.items[0]?.social_key[index],
                              );
                            }
                          });
                        }}
                      >
                        <img src={url} alt={url} />
                        <p>{requirementData?.items[0]?.name[index]}</p>
                      </div>
                    );
                  },
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
      <div
        className={`job-detail-flex-container ${
          isCollapseOpen ? 'border-radius-card-open' : 'border-radius-card-close'
        }`}
        onClick={() => setCollapse({ ...collapse, collapse1: !collapse.collapse1 })}
        style={{
          cursor: 'pointer',
        }}
      >
        <div className="job-detail-flex">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {socialJobItem?.accept_date && (
                <div className="icon-check">
                  <i
                    className="fa fa-check"
                    style={{
                      fontSize: '10px',
                      position: 'absolute',
                      top: '3px',
                      left: '2px',
                    }}
                  />
                </div>
              )}
              <h5 className="job-detail-number mt-2">1</h5>
            </div>
            <h3 className="fs-5 mt-2">{t('marketer:job_detail:New Job')}</h3>
          </div>
          <div className="work-icon-container">
            <img
              src={search}
              alt=""
              style={{ width: '40px', marginBottom: '5px', marginRight: '45px' }}
            />
          </div>
        </div>
      </div>

      <Collapse
        isOpen={collapse.collapse1}
        className="job-detail-collapse"
        onEntering={() => {
          setIsCollapseOpen(true);
        }}
        onExited={() => {
          setIsCollapseOpen(false);
        }}
      >
        <div className="influencer-card">
          <div className="header-job-title mt-4">{marketerJob?.name}</div>

          <div className="new-job-container">
            {marketerJob?.work_order && (
              <>
                <div style={{ borderTop: '1px solid #495057' }} />
                <div className="title-head">
                  {t('marketer:job_detail:Brief to influencer')}
                </div>
                <div
                  className="text-editor-displays"
                  style={{ wordBreak: 'break-all' }}
                >
                  <div ref={workOrderRef} style={{ marginTop: '20px' }} />
                </div>
              </>
            )}

            {marketerJob?.target_group && (
              <>
                <div className="title-head">
                  {t('marketer:create_job_campaign:target_group')}
                </div>
                <div className="detail-text">{marketerJob?.target_group}</div>
              </>
            )}

            {marketerJob?.product_name && (
              <>
                <div className="title-head">
                  {t('marketer:job_detail:Product name')}
                </div>
                <div className="detail-text">{marketerJob?.product_name}</div>
              </>
            )}

            {marketerJob?.market_challenge && (
              <>
                <div className="title-head">
                  {t('marketer:create_job_campaign:Market_Challenges')}
                </div>
                <div className="detail-text">{marketerJob?.market_challenge}</div>
              </>
            )}

            {marketerJob?.highlight_of_product && (
              <>
                <div className="title-head">
                  {t('marketer:create_job_campaign:Highlights_product')}
                </div>
                <div className="detail-text">
                  {marketerJob?.highlight_of_product}
                </div>
              </>
            )}

            {marketerJob?.forbidden_phrase && (
              <>
                <div className="title-head">
                  {t('marketer:job_detail:word_forbidden')}
                </div>
                <div className="detail-text">{marketerJob?.forbidden_phrase}</div>
              </>
            )}
            {marketerJob?.thumbnail_cover && (
              <>
                <div className="title-head" style={{ marginBottom: '0.5rem' }}>
                  {t('marketer:job_detail:Product Image')}
                </div>

                <img
                  src={`${config.backendUrl}api/jobThumbnail/${marketerJob?.thumbnail_cover}`}
                  alt={marketerJob?.thumbnail_cover}
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    height: 'auto',
                  }}
                />
              </>
            )}

            {marketerJob?.link_share && (
              <>
                <div className="title-head">
                  {t('marketer:job_detail:link_share')}
                </div>
                <div className="detail-text">
                  <a href={marketerJob?.link_share} target="_blank" rel="noreferrer">
                    {marketerJob?.link_share}
                  </a>
                </div>
              </>
            )}

            {marketerJob?.hashtag && (
              <>
                <div className="title-head">{t('marketer:job_detail:#Hashtag')}</div>
                <div className="detail-text">{marketerJob?.hashtag}</div>
              </>
            )}
            {marketerJob?.message_to_influencer && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:job_detail:message_to_influencer')}
                </div>
                <div className="detail-text">
                  {marketerJob?.message_to_influencer}
                </div>
              </>
            )}
            {marketerJob?.link_accompanying_work && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:job_detail:link_accompanying_work')}
                </div>
                <div className="detail-text">
                  {marketerJob?.link_accompanying_work}
                </div>
              </>
            )}
            {marketerJob?.brands_prohibit && (
              <>
                {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
                <div className="title-head">
                  {t('marketer:job_detail:brands_prohibit')}
                </div>
                <div className="detail-text">{marketerJob?.brands_prohibit}</div>
              </>
            )}
            {/* <div style={{ borderTop: '1px solid #495057' }} /> */}
          </div>

          <div className="header-job-title mt-4">
            {t('marketer:job_detail:Job timeline')}
          </div>
          <div className="time-line-div">
            <div className="timeline">
              {timelineViewData.map((item) => {
                return (
                  <div className="timeline-container" key={item.dataString}>
                    <Card className="timeline-content">
                      <img
                        src={item.icon}
                        alt={item.icon}
                        style={{ width: '80px', height: '80px' }}
                      />
                      <div className="icon-group-time-line ">
                        <h5 style={{ color: '#5D6D6D', fontSize: '1rem' }}>
                          {t(`marketer:job_detail:${item.dataString}`)}
                        </h5>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '13px',
                            gap: '10px',
                          }}
                        >
                          <Card className="card-date-container-selected">
                            <label
                              className="card-date-selected-label"
                              htmlFor="date-select"
                            >
                              {formatDateForm(item?.date)}
                            </label>
                          </Card>
                          <Card
                            style={{
                              width: '40px',
                              height: '33px',
                              background: '#FFFFFF',
                              border: '2px solid #21603E',
                              borderRadius: '8px',
                              display: 'flex',
                              cursor: 'no-drop',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <label
                              style={{
                                fontSize: '11px',
                                color: '#21603E',
                                cursor: 'no-drop',
                                marginTop: '9px',
                              }}
                              htmlFor="calendar"
                            >
                              {' '}
                              <i className="fas fa-calendar-alt" />
                            </label>
                          </Card>
                          <span
                            style={{
                              fontSize: '16px',
                              color: '#21603E',
                              marginTop: '5px',
                            }}
                          >
                            {currentTimeZone}{' '}
                            <span style={{ visibility: 'hidden' }}>
                              plaintextnoneed
                            </span>
                          </span>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MockJobRequirementTable requirements={marketerJob?.job_requirement} />
          </div>

          {!socialJobItem?.accept_date && socialJobItem?.status !== 'CANCEL' && (
            <div className="button-job">
              <div style={{ marginRight: '15px' }}>
                <CustomButton
                  handleClick={async (e: any) => {
                    if (marketerJob?.job_type === 'PUBLISH') {
                      setOpen(true);
                    } else {
                      await Swal.fire({
                        width: 300,
                        title: t('swal:Are you sure ?'),
                        text: t("swal:You won't be able to revert this!"),
                        imageUrl: choice,
                        imageHeight: 200,
                        imageAlt: 'A tall image',
                        customClass: {},
                        cancelButtonColor: 'red',
                        showCancelButton: true,
                        confirmButtonColor: '#009a78',
                        confirmButtonText: t('button:Yes'),
                        cancelButtonText: t('button:No'),
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          e.preventDefault();
                          await AcceptJob();
                        }
                      });
                    }
                  }}
                  size="s"
                  status="submit"
                  label={
                    marketerCampaign?.marketerJobNew?.job_type === 'PUBLISH'
                      ? t('Apply job')
                      : t('marketer:job_detail:Accept Job')
                  }
                />
              </div>
              {marketerJob?.job_type !== 'PUBLISH' && (
                <div>
                  <CustomButton
                    handleClick={async (e: any) => {
                      await Swal.fire({
                        width: 300,
                        title: t('swal:Are you sure ?'),
                        text: t("swal:You won't be able to revert this!"),
                        imageUrl: choice,
                        imageHeight: 200,
                        imageAlt: 'A tall image',
                        customClass: {},
                        cancelButtonColor: 'red',
                        showCancelButton: true,
                        confirmButtonColor: '#009a78',
                        confirmButtonText: t('button:Yes'),
                        cancelButtonText: t('button:No'),
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          e.preventDefault();
                          await clickVerifyStatus('CANCEL', socialJobId);
                          dispatch(getSocialJob(socialJobId));
                        }
                      });
                    }}
                    size="s"
                    status="cancel"
                    label={t('marketer:job_detail:Reject Job')}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default JobDetailCollapse1;
