import { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialJob } from 'src/store/actions';
import Role from 'src/constants/enum/role';
import { useTranslation } from 'react-i18next';
import config from 'src/config';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import Zoom from 'react-img-zoom-afork';
import { getSocialJobGql } from 'src/graphql/advertiser/socialMediaInfo';
import useWindowDimensions from '../useWindowDimensions';
import './GleamJobImagePreview.style.scss';

const GleamJobImagePreview = ({ socialHasJob }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { windowWidth } = useWindowDimensions();
  const [imagesWidth, setImagesWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState<any>(0);
  const [displayImageKey, setDisplayImageKey] = useState([]);
  const [isClick, setIsClick] = useState(true);
  const [socialJobTaskItem, setSocialJobTaskItem] = useState<any>({});
  const { user, socialJobItem } = useSelector((state: any) => ({
    socialJobItem: state.SocialMediaInfo.socialJobItem,
    user: state.login.user,
  }));
  // Effects
  useEffect(() => {
    // dispatch(getSocialJob(socialHasJob.key));
    const fetchSocialJob = async (key: string) => {
      try {
        const res = await getSocialJobGql(key);
        if (res?.image_storages) {
          const newDisplay = res?.image_storages;
          setDisplayImageKey(newDisplay);
          setSocialJobTaskItem(res);
          sumImageWidth();
        }
      } catch (error) {
        console.log('cannot get social job');
      }
    };

    fetchSocialJob(socialHasJob.key);
  }, [socialHasJob]);

  // useEffect(() => {
  //   if (!socialJobItem?.image_storages) return;

  //   const newDisplay = socialJobItem?.image_storages;
  //   setDisplayImageKey(newDisplay);
  // }, [socialJobItem?.image_storages]);

  const slideImage: any = useRef();

  const refs: any = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const sumImageWidth = () => {
    setTimeout(() => {
      let newImagesWidth = 0;
      for (let i = 0; i < refs.current.length; i++) {
        const ref = refs.current[i];
        const imageWidth = ref?.current?.offsetWidth;

        newImagesWidth += imageWidth || 0;
      }
      setImagesWidth(newImagesWidth);
    }, 300);
  };
  sumImageWidth();
  useEffect(() => {
    sumImageWidth();
  }, [socialJobTaskItem]);

  const screenWidth: any = slideImage?.current?.scrollWidth;

  let currentScreenWidth: any = slideImage?.current?.clientWidth;
  const SlideWidth: any = slideImage?.current?.scrollLeft;

  if (displayImageKey?.length === 0) {
    currentScreenWidth = 0;
  }

  //if (!socialJobTaskItem?.image_storages?.length) return <></>;
  return (
    <div className="influencer-job-detail-container pointer">
      <div className="influencer-card-post" style={{ margin: '0rem 0rem' }}>
        {user.role === Role.ADVERTISER ? (
          <div className="campaign-name-title review-post-influ">
            {t('Influencer Post t')}
          </div>
        ) : (
          <div className="campaign-name-title review-post">{t('Your Post t')}</div>
        )}
        <hr />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {displayImageKey?.length !== 0 && (
            <div className="image-carousel-container">
              <div
                ref={slideImage}
                onScroll={(e: any) => {
                  setScrollLeft(e?.currentTarget?.scrollLeft);
                }}
                style={{
                  overflowX: 'auto',
                  display: '-webkit-box',
                  width: '100%',
                  scrollBehavior: 'smooth',
                }}
              >
                {/* <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                  }}
                >
                  <div
                    style={{
                      color: 'white',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      background: '#ff7785',
                      width: '30px',
                      height: '30px',
                      zIndex: '-10',
                      marginLeft: '35px',
                      marginTop: '85px',
                      display: !scrollLeft ? 'none' : 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      visibility: !scrollLeft ? 'hidden' : 'visible',
                    }}
                    onClick={() => {
                      slideImage.current.scrollLeft -= windowWidth * 0.3;
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </div>
                  <div
                    style={{
                      color: 'white',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      background: '#ff7785',
                      width: '30px',
                      marginTop: '85px',
                      zIndex: '1',
                      visibility:
                        (screenWidth - SlideWidth - currentScreenWidth !== 0 ||
                          (SlideWidth === 0 && currentScreenWidth < imagesWidth)) &&
                        currentScreenWidth
                          ? 'visible'
                          : 'hidden',
                      marginLeft: `${currentScreenWidth - 120 || 0}px`,
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      slideImage.current.scrollLeft += windowWidth * 0.3;
                    }}
                  >
                    <KeyboardArrowRightIcon />
                  </div>
                </div> */}
                {displayImageKey.map((data: any, index: number) => {
                  return (
                    // <>
                    //   <img
                    //     src={`${config.backendUrl}api/socialJob/${data?.key}`}
                    //     ref={refs.current[index]}
                    //     alt={data?.key}
                    //     className="image-container"
                    //     style={{
                    //       width: 'auto',
                    //       height: '100%',
                    //     }}
                    //   />
                    // </>
                    <div
                      onClick={() => {
                        setIsClick(!isClick);
                      }}
                      key={data?.key}
                      ref={refs.current[index]}
                      className={`${
                        isClick === true ? 'hover-image-before' : 'hover-image-after'
                      }`}
                    >
                      <img
                        src={`${config.backendUrl}api/socialJob/${data?.key}`}
                        alt={data?.key}
                        style={{
                          width: 'auto',
                          height: '100%',
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {socialHasJob?.link_post !== 'gleam' ? (
          <div
            style={{
              backgroundColor: '#eeeded',
              width: '100%',
              padding: 10,
              borderRadius: '8px',
              wordBreak: 'break-all',
              marginTop: '10px',
              color: 'black',
            }}
            onClick={() => {
              window.open(socialJobTaskItem?.link_post);
            }}
          >
            {socialJobTaskItem?.link_post}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default GleamJobImagePreview;
