import { useEffect, useState } from 'react';
import { Card, CardFooter, FormGroup } from 'reactstrap';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import choice from 'src/assets/images/choice.png';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDefaultCampaignGraphql } from 'src/graphql/default-value/default-campaign';
import { setLoading, unsetLoading } from '../../store/global/actions';

const SettingCpmForm = ({
  t,
  creativeType,
  defaultMinimumCPM,
  defaultRecommendCPM,
  minimumCPMKey,
  recommendCPMKey,
}: any) => {
  const dispatch = useDispatch();

  const [minimumCPM, setMinimumCPM] = useState('');
  const [recommendCPM, setRecommendCPM] = useState('');

  const initialValues = {
    minimumCPM: defaultMinimumCPM,
    recommendCPM: defaultRecommendCPM,
  };

  const createCampaignSchema = Yup.object().shape({
    minimumCPM: Yup.number().required('Required').min(0),
    recommendCPM: Yup.number().required('Required').min(0),
  });

  const onsubmit = async () => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t('swal:Do you want to update!'),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        if (minimumCPM !== defaultMinimumCPM) {
          await updateDefaultCampaignGraphql({
            key: minimumCPMKey,
            value: minimumCPM.toString(),
          });
        }
        if (recommendCPM !== defaultRecommendCPM) {
          await updateDefaultCampaignGraphql({
            key: recommendCPMKey,
            value: recommendCPM.toString(),
          });
        }
        dispatch(unsetLoading());
      }
    });
  };

  useEffect(() => {
    setMinimumCPM(defaultMinimumCPM);
  }, [defaultMinimumCPM]);
  useEffect(() => {
    setRecommendCPM(defaultRecommendCPM);
  }, [defaultRecommendCPM]);

  return (
    <Card className="p-4">
      <p className="font-size-24 fw-bold">{creativeType}</p>
      <Formik
        initialValues={initialValues}
        onSubmit={onsubmit}
        validationSchema={createCampaignSchema}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <FormikForm>
              <FormGroup>
                <p>{t('campaign:Minimum CPM Price')}</p>

                <div className="input-group">
                  <Field
                    name="minimumCPM"
                    type="number"
                    step="any"
                    min="0"
                    value={minimumCPM}
                    className={`form-control form-control-lg ${
                      errors.minimumCPM && touched.minimumCPM && 'is-invalid '
                    } `}
                    onChange={(e: any) => {
                      setMinimumCPM(e.currentTarget.value);
                      handleChange(e);
                    }}
                  />
                  <div className="input-group-text">$ / CPM</div>
                  <ErrorMessage
                    name="minimumCPM"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </FormGroup>
              <FormGroup className="mt-3">
                <p>{t('campaign:recommendCPM CPM Price')}</p>

                <div className="input-group">
                  <Field
                    name="recommendCPM"
                    type="number"
                    step="any"
                    min="0"
                    value={recommendCPM}
                    className={`form-control form-control-lg ${
                      errors.recommendCPM && touched.recommendCPM && 'is-invalid '
                    } `}
                    onChange={(e: any) => {
                      setRecommendCPM(e.currentTarget.value);
                      handleChange(e);
                    }}
                  />
                  <div className="input-group-text">$ / CPM</div>
                  <ErrorMessage
                    name="recommendCPM"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </FormGroup>
              <CardFooter>
                {' '}
                <h3 className="float-end">
                  <button type="submit" className="btn btn-success btn-md">
                    {t('button:Confirm')}
                  </button>
                </h3>{' '}
              </CardFooter>
            </FormikForm>
          );
        }}
      </Formik>
    </Card>
  );
};
export default withTranslation()(SettingCpmForm);
