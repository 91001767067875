import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { transactionDetailDateTime } from 'src/utils/dateTime';
import { adminPublisherEarningTransactionDetail } from 'src/graphql/publisher/earningTransaction';

const EarningDetailModal = ({
  title,
  isOpen,
  handleToggle,
  handleClose,
  data,
  t,
}: any) => {
  const [jobName, setJobName] = useState('');

  const getJobName = async () => {
    const res = await adminPublisherEarningTransactionDetail(
      data.social_media_has_job_id,
    );
    setJobName(res.job.name);
  };
  useEffect(() => {
    getJobName();
  }, []);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {data && (
        <div className="modal-body p-0">
          <div className="table-responsive">
            <table className="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:ID')}:
                  </th>
                  <td>{data.uuid}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('Campaign Name')}:
                  </th>
                  <td>{jobName}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Amount')}:
                  </th>
                  <td>{data.FIAT_amount}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Timestamp')}:
                  </th>
                  <td>
                    {transactionDetailDateTime(data.created_at, i18n.language)}
                  </td>
                </tr>
                {/* <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Number of creatives')}:
                  </th> */}
                {/* {data.creativeCampaignDatums ? (
                                        <td>{data.creativeCampaignDatums.length}</td>
                                    ) : (
                                        <td>0</td>
                                    )} */}
                {/* </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withTranslation()(EarningDetailModal);
