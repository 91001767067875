/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export enum BalanceTypes {
  GET_SUM_WALLETS = '@@systembalance/GET_SUM_WALLETS',
  GET_SUM_WALLETS_SUCCESS = '@@systembalance/GET_SUM_WALLETS_SUCCESS',

  GET_SUM_CRYPTOS_BALANCE = '@@systembalance/GET_SUM_CRYPTOS_BALANCE',
  GET_SUM_CRYPTOS_BALANCE_SUCCESS = '@@systembalance/GET_SUM_CRYPTOS_BALANCE_SUCCESS',

  GET_SUM_PROFITS_BALANCE = '@@systembalance/GET_SUM_PROFITS_BALANCE',
  GET_SUM_PROFITS_BALANCE_SUCCESS = '@@systembalance/GET_SUM_PROFITS_BALANCE_SUCCESS',

  GET_CRYPTO_WALLETS = '@@systembalance/GET_CRYPTO_WALLETS',
  GET_CRYPTO_WALLETS_SUCCESS = '@@systembalance/GET_CRYPTO_WALLETS_SUCCESS',

  GET_SUM_WALLETS_ROLE = '@@systembalance/GET_SUM_WALLETS_ROLE',
  GET_SUM_WALLETS_ROLE_SUCCESS = '@@systembalance/GET_SUM_WALLETS_ROLE_SUCCESS',

  GET_SYSTEM_BALANCE_LOG_TRANSACTIONS = '@@systembalance/GET_SYSTEM_BALANCE_LOG_TRANSACTIONS',
  GET_SYSTEM_BALANCE_LOG_TRANSACTIONS_SUCCESS = '@@systembalance/GET_SYSTEM_BALANCE_LOG_TRANSACTIONS_SUCCESS',

  GET_PROFIT_BALANCE_LOG_TRANSACTIONS = '@@systembalance/GET_PROFIT_BALANCE_LOG_TRANSACTIONS',
  GET_PROFIT_BALANCE_LOG_TRANSACTIONS_SUCCESS = '@@systembalance/GET_PROFIT_BALANCE_LOG_TRANSACTIONS_SUCCESS',

  GET_CRYPTO_BALANCE_LOG_TRANSACTIONS = '@@systembalance/GET_CRYPTO_BALANCE_LOG_TRANSACTIONS',
  GET_CRYPTO_BALANCE_LOG_TRANSACTIONS_SUCCESS = '@@systembalance/GET_CRYPTO_BALANCE_LOG_TRANSACTIONS_SUCCESS',

  GET_ROLE_BALANCE_LOG_TRANSACTIONS = '@@systembalance/GET_ROLE_BALANCE_LOG_TRANSACTIONS',
  GET_ROLE_BALANCE_LOG_TRANSACTIONS_SUCCESS = '@@systembalance/GET_ROLE_BALANCE_LOG_TRANSACTIONS_SUCCESS',

  FAIL = '@@systembalance/FAIL',
}
