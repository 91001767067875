import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {
  getCurrencies as onGetCurrencies,
  toggleCurrencyAvailable,
} from '../../store/currencies/actions';
import CoinImage from '../Common/CoinImage';

const CurrenciesSettingComponent = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetCurrencies());
  }, []);

  const { currencies } = useSelector((state: any) => ({
    currencies: state.Currencies.currencies,
  }));

  const handleToggleCurrencyAvailable = (symbol: string) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Setting Available Currency Confirm', { symbol }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(toggleCurrencyAvailable(symbol));
      }
    });
  };

  const renderCurrencyStatus = (currency: any) => {
    return currency.is_available ? (
      <div className="led-box">
        <div className="led-green" />
      </div>
    ) : (
      <div className="led-box">
        <div className="led-red" />
      </div>
    );
  };

  return (
    <Row>
      <div className="p-2 text-danger">
        *{t('Available Currency Setting Helper')}
      </div>
      {!isEmpty(currencies) &&
        currencies.map((currency: any) => {
          return (
            <Col lg={4}>
              <Card>
                <CardBody className="overflow-hidden position-relative">
                  <div className="d-flex">
                    <h5 className="d-flex align-self-center text-white align-items-center mb-0">
                      <span className="logo-sm mx-1">
                        <CoinImage symbol={currency.symbol} size="md" />
                        &nbsp;&nbsp;{currency.symbol}
                      </span>
                    </h5>
                    <span className="d-flex align-items-center flex-grow-1 mx-2">
                      {renderCurrencyStatus(currency)}
                    </span>
                    <div className="form-check form-switch form-switch-lg">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizelg"
                        onChange={() => {
                          handleToggleCurrencyAvailable(currency.symbol);
                        }}
                        checked={currency.is_available}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default withTranslation()(CurrenciesSettingComponent);
