/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum DefaultValuesTypes {
  GET_DEFAULT_CAMPAIGN = '@@defaultCampaign/GET_DEFAULT_CAMPAIGN',
  GET_DEFAULT_CAMPAIGN_SUCCESS = '@@defaultCampaign/GET_DEFAULT_CAMPAIGN_SUCCESS',
  GET_DEFAULT_CAMPAIGN_FAIL = '@@defaultCampaign/GET_DEFAULT_CAMPAIGN_FAIL',
  GET_ALL_DEFAULT_CAMPAIGN_SUCCESS = '@@defaultCampaign/GET_ALL_DEFAULT_CAMPAIGN_SUCCESS',

  GET_MINIMUM_WITHDRAW_CONFIGS = '@@defaultValues/GET_MINIMUM_WITHDRAW_CONFIGS',
  GET_MINIMUM_WITHDRAW_CONFIGS_SUCCESS = '@@defaultValues/GET_MINIMUM_WITHDRAW_CONFIGS_SUCCESS',
  GET_MINIMUM_WITHDRAW_CONFIGS_FAIL = '@@defaultValues/GET_MINIMUM_WITHDRAW_CONFIGS_FAIL',

  GET_FEE_WITHDRAW_CONFIGS = '@@defaultValues/GET_FEE_WITHDRAW_CONFIGS',
  GET_FEE_WITHDRAW_CONFIGS_SUCCESS = '@@defaultValues/GET_FEE_WITHDRAW_CONFIGS_SUCCESS',
  GET_FEE_WITHDRAW_CONFIGS_FAIL = '@@defaultValues/GET_FEE_WITHDRAW_CONFIGS_FAIL',

  GET_MINIMUM_WITHDRAW_CONFIG = '@@defaultValues/GET_MINIMUM_WITHDRAW_CONFIG',
  GET_MINIMUM_WITHDRAW_CONFIG_SUCCESS = '@@defaultValues/GET_MINIMUM_WITHDRAW_CONFIG_SUCCESS',
  GET_MINIMUM_WITHDRAW_CONFIG_FAIL = '@@defaultValues/GET_MINIMUM_WITHDRAW_CONFIG_FAIL',

  UPDATE_CONFIG = '@@defaultValues/UPDATE_CONFIG',

  GET_DEFAULT_VALUE_BY_KEY = '@@defaultValues/GET_DEFAULT_VALUE_BY_KEY',
  GET_DEFAULT_VALUE_BY_KEY_SUCCESS = '@@defaultValues/GET_DEFAULT_VALUE_BY_KEY_SUCCESS',

  /** Get defaultWebsite by user id  */
  GET_DEFAULT_WEBSITE = '@@defaultWebsite/GET_DEFAULT_WEBSITE',
  GET_DEFAULT_WEBSITE_SUCCESS = '@@defaultWebsite/GET_DEFAULT_WEBSITE_SUCCESS',
  GET_DEFAULT_WEBSITE_FAIL = '@@defaultWebsite/GET_DEFAULT_WEBSITE_FAIL',

  GET_PROFIT_CONFIGS = '@@defaultValues/GET_PROFIT_CONFIGS',
  GET_PROFIT_CONFIGS_SUCCESS = '@@defaultValues/GET_PROFIT_CONFIGS_SUCCESS',

  GET_SUGGEST_JOB_BUDGET_CONFIGS = '@@defaultWebsite/GET_SUGGEST_JOB_BUDGET_CONFIGS',
  GET_SUGGEST_JOB_BUDGET_CONFIGS_SUCCESS = '@@defaultWebsite/GET_SUGGEST_JOB_BUDGET_CONFIGS_SUCCESS',
}

export interface DefaultValuesState {
  defaultCampaign: Array<any>;
  allDefaultCampaigns: Object;
  defaultWebsite: Array<any>;
  systemProfitConfigs: Array<any>;
  minWithdrawConfigs: Array<any>;
  minimumWithdrawConfig: Object;
  feeWithdrawConfigs: Array<any>;
  error: Object;
  loading: Boolean;
  tax: number;
  suggestJobBudgetConfigs: Array<any>;
}
