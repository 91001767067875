import { DepositTypes } from './actionTypes';

const initialState = {
  transactions: [],
  error: '',
  loading: false,
};

const deposit = (state = initialState, action: any) => {
  switch (action.type) {
    case DepositTypes.GET_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case DepositTypes.GET_ALL_DEPOSIT_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case DepositTypes.GET_DEPOSIT_TRANSACTIONS_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default deposit;
