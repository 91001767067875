import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import CoinImage from '../Common/CoinImage';
import { transformStringAmountInput } from '../../utils/input';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import WithdrawStatus from '../Common/WithdrawStatus';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const RecentActivity = ({ transactions }: any) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader
        className="align-items-center d-flex"
        style={{ backgroundColor: 'transparent' }}
      >
        <CardTitle
          className="mb-0 flex-grow-1"
          style={{ padding: '20px', color: 'white' }}
        >
          {t('Recent Withdraw Request')}
        </CardTitle>
      </CardHeader>

      <CardBody className="px-0">
        <SimpleBar className="px-3" style={{ maxHeight: '352px' }}>
          <ul className="list-unstyled activity-wid mb-0">
            {transactions &&
              transactions.map((transaction: any) => {
                return (
                  <li className="activity-list activity-border">
                    <div className="activity-icon avatar-md">
                      <span className="avatar-title bg-soft-warning text-warning rounded-circle">
                        <CoinImage symbol={transaction.currency.symbol} size="xl" />
                      </span>
                    </div>
                    <div className="timeline-list-item">
                      <div className="d-flex justify-content-end">
                        <div className="d-none d-sm-block flex-grow-1 overflow-hidden me-4">
                          <h6 className="mb-1 text-white">
                            <DateTimeComponent
                              dateTime={transaction.updated_at}
                              dateTimeType={
                                DateTimeTypes.RECENT_TRANSACTION_DATE_TIME
                              }
                            />
                          </h6>
                          <p className="text-truncate text-white">
                            {transaction.address}
                          </p>
                        </div>
                        <div className="flex-shrink-0 text-end me-3">
                          <h6 className="mb-1 text-white">
                            {`-${transformStringAmountInput(
                              transaction.CRYPTO_amount,
                            )} ${splitUnderscoreWithTuple(
                              transaction.currency.symbol,
                            )}`}
                          </h6>
                          <div className="text-white">
                            {`${transformStringAmountInput(
                              transaction.FIAT_amount,
                            )}  USDT`}
                          </div>
                          <WithdrawStatus status={transaction.status} />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </SimpleBar>
      </CardBody>
    </Card>
  );
};

export default RecentActivity;
