/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum CalculateSocialMediaTypes {
  SET_USER_PROFILE_LIST = '@@calculate_social_media/SET_USER_PROFILE_LIST',
  SET_USER_PROFILE_SELECTED = '@@calculate_social_media/SET_USER_PROFILE_SELECTED',
  SET_FACEBOOK_ERROR = '@@calculate_social_media/SET_FACEBOOK_ERROR',
  SET_USER_PROFILE_STATUS = '@@calculate_social_media/SET_USER_PROFILE_STATUS',
}

export default CalculateSocialMediaTypes;
