import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Label } from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import config from 'src/config';
import {
  getAvailableCurrencies,
  getAvailableNetwork,
} from 'src/store/currencies/actions';
import { getCurrencyByIdGQL } from 'src/graphql/common';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { checkAddressIsInternal } from 'src/graphql/advertiser/depositAddress';
import { validateAddress, transformAddressInShortFrom } from '../../utils/address';
import { transformStringAmountInput } from '../../utils/input';
import { withdrawRequest } from '../../store/finance/withdraw/actions';
import currenciesSymbol from '../../constants/currenciesSymbol';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import { getMinimumWithdrawConfig } from '../../store/default-values/actions';
import CustomButton from '../Common/CustomButton';
import { getWithdrawAndFeeWallet } from '../../graphql/common/index';

const initialValues = {
  symbol: '',
  network: '',
  address: '',
  tag: '',
  amount: '',
  code: '',
};

const withdrawSchema: any = Yup.object().shape({
  symbol: Yup.string().required(),
  network: Yup.string().required(),
  address: Yup.string().required(),
  amount: Yup.string().required(),
  code: Yup.string().required('2FA code is a required field'),
});

const WithdrawForm = ({ t }: any) => {
  const dispatch = useDispatch();

  const [symbol, setSymbol] = useState('');
  const [coin, setCoin] = useState('');
  const [minimumx, setMinnimumx] = useState(0);
  const [coinWallet, setCoinWallet] = useState(0);
  const [feeSymbol, setFeeSymbol] = useState('USDT');
  const [currency, setCurrency] = useState<any>(null);
  const [currencyId, setCurrencyId] = useState<any>(+9);
  const [addressSymbol, setAddressSymbol] = useState('');
  const [networkSelect, setNetworkSelect] = useState('');
  const [address, setAddress] = useState('');
  const [isAddressValid, setValidAddress] = useState(true);
  const [isValidWalletBalance, setValidWalletBalance] = useState(true);
  const [isValidMinWithdraw, setValidMinWithdraw] = useState(true);
  const [fee, setFee] = useState(0);
  const [feeBalance, setFeeBalance] = useState(0);

  const { wallet, minimumWithdrawConfig } = useSelector((state: any) => ({
    wallet: state.Wallet.wallet,
    twoFactorEnabled: state.twoFactor.isEnabled,
    minimumWithdrawConfig: state.DefaultValue.minimumWithdrawConfig,
  }));

  useEffect(() => {
    // const x = async () => {
    //   const data = await getFee({
    //     symbol: 'XRP',
    //     address: 'rUmF3HKaDWjRSatsQ1nfDTMocuuZQ4ntnM',
    //   });
    //   console.log('data', data);
    // };
    // x();
  }, []);
  const { network, currencies } = useSelector((state: any) => ({
    network: state.Currencies.availableNetworks,
    currencies: state.Currencies.availableCurrencies,
  }));

  const onSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    console.log('values', values);
    if (isAddressValid && isValidWalletBalance && isValidMinWithdraw) {
      Swal.fire({
        title: t('swal:Are you sure'),
        text: t('swal:Withdraw Request Confirm', {
          amount: values.amount,
          symbol,
          address: transformAddressInShortFrom(values.address, 6),
        }),
        imageUrl: choice,
        width: 300,
        imageHeight: 200,
        imageAlt: 'A tall image',
        customClass: {},
        cancelButtonColor: 'red',
        showCancelButton: true,
        confirmButtonText: t('button:Yes'),
        cancelButtonText: t('button:No'),
        confirmButtonColor: '#009a78',
      }).then((result) => {
        if (result.isConfirmed) {
          if (feeBalance < fee) {
            notify(i18n.t('error:Your fee balance is not enough.'), 'error');
          } else {
            const newValues = { ...values };
            newValues.amount = parseFloat(newValues.amount.replace(/,/g, ''));
            // console.log('newvalue', newValues);
            dispatch(withdrawRequest(newValues));

            resetForm();
            setSubmitting(true);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setSubmitting(false);
        }
      });
    }
  };

  /**
   * Check Address's Format By Symbol
   *
   * @param address
   * @param symbol
   */
  const handleCheckAddress = async () => {
    let isValid = true;

    if (config.appEnv === 'production') {
      isValid = validateAddress(address, addressSymbol);
    }

    if (address && addressSymbol && networkSelect) {
      setValidAddress(isValid);
      setSymbol(addressSymbol);
      if (isValid) {
        const data = await getWithdrawAndFeeWallet(
          addressSymbol,
          networkSelect,
          address,
        );
        // console.log('data', data);
        // console.log('data', data.withdrawWallet.symbol);
        // console.log('data', data.feeWallet.symbol);
        // console.log('dwdwd', data.withdrawWallet.balance);
        setCoinWallet(data.withdrawWallet.balance);
        setMinnimumx(+data.minimumWithdraw);
        setFeeSymbol(data.feeWallet.symbol);
        setFeeBalance(+data.feeWallet.balance);
        setFee(data.fee);
      }
    }
  };

  useEffect(() => {
    handleCheckAddress();
  }, [address, addressSymbol, networkSelect]);

  const handleAmountChange = (value: string, setFieldValue: any) => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));

    if (value) {
      setValidWalletBalance(parsedValue <= coinWallet);

      if (minimumx && symbol) {
        setValidMinWithdraw(parsedValue >= +minimumx);
      }
    }

    setFieldValue('amount', transformStringAmountInput(value));
  };

  useEffect(() => {
    dispatch(getAvailableNetwork());
    dispatch(getAvailableCurrencies());
  }, []);

  const renderAvailableCurrencies = () => {
    const arr: any = [];
    // console.log('available', currencies);
    for (let i = 0; i < currencies.length; i++) {
      const currency = currencies[i].symbol;
      arr.push(<option value={currency}>{currency}</option>);
    }

    return arr;
  };

  const renderCurrencies = () => {
    const arr: any = [];
    for (let i = 0; i < network?.length; i++) {
      arr.push(<option value={network[i]}>{network[i]}</option>);
    }

    return arr;
  };
  const renderCoin = () => {
    const arr: any = [];
  };

  const renderAddressError = (errors: any) => {
    let errorText: string = '';
    let html: any = '';

    if (!isAddressValid) {
      if (!isEmpty(errors.address)) {
        errorText = errors.address;
      } else {
        errorText = symbol
          ? t('error:Invalid Address Symbol Format', { symbol })
          : t('error:Invalid Address Format');
      }

      html = <div className="invalid-feedback">{errorText}</div>;
    } else {
      html = (
        <ErrorMessage name="address" component="div" className="invalid-feedback" />
      );
    }

    return html;
  };

  const renderAmountError = (errors: any) => {
    let errorText: string = '';
    let html: any = '';

    if (!isValidWalletBalance) {
      if (!isEmpty(errors.amount)) {
        errorText = errors.amount;
      } else {
        errorText = t('Error.Amount More Than Balance');
      }

      html = <div className="invalid-feedback">{errorText}</div>;
    } else if (!isValidMinWithdraw) {
      if (!isEmpty(errors.amount)) {
        errorText = errors.amount;
      } else {
        errorText = t('Error.Amount Must Be More Than', {
          min: minimumWithdrawConfig.estimate_in_USD,
        });
      }

      html = <div className="invalid-feedback">{errorText}</div>;
    } else {
      html = (
        <ErrorMessage name="amount" component="div" className="invalid-feedback" />
      );
    }

    return html;
  };

  useEffect(() => {
    const { currency_id } = currencyId;
    // console.log('dfwdqwdqw', currencyId);
    if (currencyId) {
      fetchCurrency(currencyId);
    }
  }, [currencyId]);

  const fetchCurrency = async (currency_id: number) => {
    const _currency = await getCurrencyByIdGQL(currency_id);
    // console.log('_currency', _currency.main_symbol);
    setCurrency(_currency.main_symbol);
  };

  const getMaxAmount = (setFieldValue: any) => {
    const maxAmount = transformStringAmountInput(coinWallet);
    // setValidWalletBalance(maxAmount < coinWallet);

    // if (minimumWithdrawConfig && symbol) {
    //   setValidMinWithdraw(maxAmount >= +minimumWithdrawConfig.estimate_in_USD);
    // }
    setFieldValue('amount', transformStringAmountInput(coinWallet));
  };

  // console.log('currency', currency);
  return (
    <>
      <Card style={{ color: 'white' }}>
        <CardHeader style={{ backgroundColor: 'transparent' }}>
          <span style={{ padding: '10px' }}>
            {' '}
            <h6 style={{ color: 'white', paddingLeft: '20px' }}>
              {t('Cryptocurrency Withdraw')}
            </h6>
            <p
              className="card-title-desc"
              style={{ color: 'white', paddingLeft: '20px' }}
            >
              {t('Cryptocurrency Withdraw DESC')}
            </p>
          </span>
        </CardHeader>
        <CardBody>
          <Formik
            validationSchema={withdrawSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ errors, values, touched, setFieldValue, handleChange }) => (
              <FormikForm>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="symbol">{t('Coin')}</Label>
                          <Field
                            as="select"
                            name="symbol"
                            className={`form-control ${
                              errors.symbol && touched.symbol ? 'is-invalid' : ''
                            } arrow-dropdown`}
                            style={{ borderRadius: '20px' }}
                            // onBlur={() => {
                            //   handleCheckAddress(values.address, values.symbol);
                            //

                            onChange={(e: any) => {
                              const { value } = e.currentTarget;
                              // setCurrencyId(currencies.filter(item=> !))
                              const re = currencies.filter(
                                (values: any) => values?.symbol === value,
                              );
                              setAddressSymbol(value);
                              setFieldValue('amount', 0);
                              if (re.length) setCurrencyId(re[0].id);
                              // console.log('eee', currencyId);
                              handleChange(e);
                            }}
                          >
                            {' '}
                            <option value="">
                              {t('Select your coin to withdraw')}
                            </option>
                            {renderAvailableCurrencies()}
                          </Field>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          style={{ margin: '0px 0px 12px 0px' }}
                        >
                          <Label htmlFor="symbol">{t('Withdraw Method')}</Label>
                          <Field
                            as="select"
                            name="network"
                            className={`form-control ${
                              errors.network && touched.network ? 'is-invalid' : ''
                            } arrow-dropdown`}
                            style={{ borderRadius: '20px' }}
                            // onBlur={() => {
                            //   handleCheckAddress(values.address, values.symbol);
                            // }}
                            onChange={(e: any) => {
                              const { value } = e.currentTarget;
                              setNetworkSelect(value);
                              // setSymbol(value);
                              // handleCheckAddress(values.address, values.symbol);
                              handleChange(e);
                            }}
                          >
                            <option value="">
                              {t('Select your withdraw method')}
                            </option>
                            {renderCurrencies()}
                          </Field>
                          <Label
                            style={{ margin: '13px 0 13px 0' }}
                            htmlFor="address"
                          >
                            {t('withDraw.Address')}
                          </Label>
                          <Field
                            placeholder="e.g 0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            name="address"
                            type="text"
                            className={`form-control ${
                              !isAddressValid || (errors.address && touched.address)
                                ? 'is-invalid'
                                : ''
                            }`}
                            style={{ borderRadius: '20px' }}
                            id="address"
                            onBlur={() => {
                              setAddress(values.address);
                              // handleCheckAddress(values.address, values.symbol);
                            }}
                            autocomplete="off"
                          />
                          {renderAddressError(errors)}
                        </FormGroup>
                      </Col>

                      <ErrorMessage
                        name="symbol"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  {/* <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="tag">{t('Withdraw Tag')}</Label>
                      <Field
                        name="tag"
                        type="text"
                        className={`form-control ${
                          errors.tag && touched.tag ? 'is-invalid' : ''
                        }`}
                        placeholder={t('Withdraw Tag Placeholder')}
                        id="tag"
                        autocomplete="off"
                        disabled={symbol !== currenciesSymbol.XRP}
                      />
                      <ErrorMessage
                        name="tag"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col> */}
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="amount">{t('Form.Amount')}</Label>
                      <div style={{ position: 'relative' }}>
                        <Field
                          placeholder="e.g 10 "
                          name="amount"
                          type="text"
                          className={`form-control ${
                            !isValidMinWithdraw ||
                            !isValidWalletBalance ||
                            (errors.amount && touched.amount)
                              ? 'is-invalid'
                              : ''
                          }`}
                          style={{ borderRadius: '20px' }}
                          id="amount"
                          onChange={(e: any) => {
                            handleAmountChange(e.currentTarget.value, setFieldValue);
                          }}
                          autocomplete="off"
                          aria-describedby="minWithdrawValueHelp"
                        />{' '}
                        <span
                          style={{
                            marginLeft: 'auto',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '0.5rem',
                            right: '2rem',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'black',
                          }}
                          onClick={() => getMaxAmount(setFieldValue)}
                        >
                          Max
                        </span>
                        <small
                          id="minWithdrawValueHelp"
                          className="form-text text-white"
                        >
                          {t('Minimum Withdraw', {
                            amount: minimumx,
                          })}{' '}
                          {currency || 'USDT'}
                        </small>
                        {/* {renderAmountError(errors)} */}
                      </div>
                      <div className="form-control-plaintext">
                        &nbsp;&nbsp;
                        {!isEmpty(wallet) && coinWallet !== null && (
                          <>
                            <strong className="text-decoration available-balance text-white font-size-15">
                              {`${t('Available Amount')}`}{' '}
                              {`${transformStringAmountInput(coinWallet)} ${
                                currency || 'USDT'
                              } / Fee ${fee} USDT`}
                            </strong>
                          </>
                        )}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="code">{t('Form.2FA OTP')}</Label>
                      <Field
                        placeholder="e.g 307 567"
                        name="code"
                        type="text"
                        className={`form-control ${
                          errors.code && touched.code ? 'is-invalid' : ''
                        }`}
                        style={{ borderRadius: '20px' }}
                        id="code"
                        autocomplete="off"
                      />

                      <ErrorMessage
                        name="code"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <CustomButton size="sp" status="submit" label={t('Button.Submit')} />
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default withTranslation()(WithdrawForm);
