import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { NavItem, NavLink } from 'reactstrap';

const ApplicationInfoNavbar = ({
  headerPillsTab,
  setHeaderPillsTab,
  onCallPublisherHistory,
  t,
}: any) => (
  <div className="nav-tabs-custom nav-scrollbar-none">
    <NavItem>
      <NavLink
        style={{
          cursor: 'pointer',
        }}
        className={classnames({
          active: headerPillsTab === 1,
        })}
        onClick={() => {
          setHeaderPillsTab(1);
        }}
      >
        <h5>{t('Page.Ad units')}</h5>
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        style={{
          cursor: 'pointer',
        }}
        className={classnames({
          active: headerPillsTab === 2,
        })}
        onClick={() => {
          setHeaderPillsTab(2);
        }}
      >
        <h5>{t('Page.Reports')}</h5>
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        style={{ cursor: 'pointer' }}
        className={classnames({
          active: headerPillsTab === 3,
        })}
        onClick={() => {
          setHeaderPillsTab(3);
          onCallPublisherHistory();
        }}
      >
        <h5>{t('Page.History')}</h5>
      </NavLink>
    </NavItem>
  </div>
);

export default withTranslation()(ApplicationInfoNavbar);
