import { useTranslation } from 'react-i18next';

import control from './MarketerCampaignSummaryWidget.control';
import './MarketerCampaignSummaryWidget.style.scss';

const MarketerCampaignSummaryWidgetComponent = () => {
  const { t } = useTranslation();
  const { statusSummary } = control();

  return (
    <div className="h-100-per p-0 mb-0">
      <div className="row justify-content-center h-100">
        {/* <div className="col-12" style={{ height: '10%' }}>
          <span className="font-size-20 fw-bold p-0 m-0">
            {t('campaign:Campaigns Summary')}
          </span>
        </div> */}
        <div className="col-12 col-md-4 h-md-100 h-auto">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100 bg-white">
            <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
              <div className="text-center">
                <span className="fw-bold" style={{ fontSize: '6em' }}>
                  <span>{statusSummary.active}</span>
                </span>
                <span
                  className="fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem', fontSize: '1.5em' }}
                >
                  {t('marketer:campaign_summary.campaign')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 h-md-100 h-auto">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100 bg-white">
            <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
              <div className="text-center">
                <span className="fw-bold" style={{ fontSize: '6em' }}>
                  <span>{statusSummary.finished}</span>
                </span>
                <span
                  className="fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem', fontSize: '1.5em' }}
                >
                  {t('marketer:campaign_summary.works')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-md-0 mb-4 h-md-100 h-auto">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100 bg-white">
            <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
              <div className="text-center">
                <span className="fw-bold" style={{ fontSize: '6em' }}>
                  <span>{statusSummary.paused}</span>
                </span>
                <span
                  className="fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem', fontSize: '1.5em' }}
                >
                  {t('marketer:campaign_summary.influencers')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketerCampaignSummaryWidgetComponent;
