import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { CardBody, Modal } from 'reactstrap';
import ImageAutoBalance from 'src/components/Common/ImageAutoBalance';
import config from 'src/config';
import CustomTable from '../../Common/CustomTable';

const ModelUploadFormGallery = (props: any) => {
  const {
    isOpen,
    toggleModel,
    creatives,
    updateCreative,
    setFromGallery,
    setIsOpenModelNewCreative,
    setFile,
    creativeSizes,
  } = props;

  const { t } = useTranslation();

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Preview'),
        accessor: ({ image_storage, size }) => (
          <div className="pic-frame">
            <div className="pic-middle">
              <ImageAutoBalance
                href={`${config.backendUrl}creatives/display/${image_storage.key}`}
                alt="ad image"
                src={`${config.backendUrl}creatives/display/${image_storage.key}`}
                sizeImage={50}
                width={+size.split('x')[0]}
                height={+size.split('x')[1]}
              />
            </div>
          </div>
        ),
        id: 'Preview',
        disableSort: true,
      },
      {
        Header: t('tableCreative:Creative Name'),
        accessor: ({ name }) => name,
        rowCss: 'mt-3',
        id: 'name',
      },
      {
        Header: t('tableCreative:Creative dimensions'),
        accessor: ({ size }) => size,
        Cell: ({ cell }: any) => {
          let invalidSize = true;
          if (creativeSizes.longString.includes(cell.row.original.size)) {
            invalidSize = false;
          }
          return (
            <>
              <div className="mt-3">{cell.row.original.size}</div>
              {invalidSize && (
                <div className="text-danger">{t('creative:invalid dimensions')}</div>
              )}
            </>
          );
        },
        id: 'size',
      },
      {
        Header: t('table:Action'),
        accessor: (creative) => {
          let invalidSize = true;

          if (creativeSizes.longString.includes(creative.size)) {
            invalidSize = false;
          }

          return (
            <div className="mt-3">
              <button
                disabled={invalidSize}
                type="button"
                className="btn btn-success text-size-24"
                onClick={() => {
                  if (updateCreative) {
                    setFile(creative);
                    toggleModel();
                    setIsOpenModelNewCreative(true);
                    setFromGallery(true);
                  }
                }}
              >
                {t('button:Select')}
              </button>
            </div>
          );
        },
        disableSort: true,
        id: 'Action',
      },
    ],
    [toggleModel],
  );

  return (
    <Modal isOpen={isOpen} toggle={toggleModel} centered size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t('creative:Select creative')}</h5>
      </div>

      <CardBody className="pb-3">
        <CustomTable
          columns={columns}
          data={creatives}
          headerCSS="fw-normal  bg-header-table"
          autoSort
          hidePagination
        />
        <div>
          {t('creative:creative size')} : {creativeSizes.longString}
        </div>
      </CardBody>

      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleModel}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          {t('button:Cancel')}
        </button>
      </div>
    </Modal>
  );
};
export default ModelUploadFormGallery;
