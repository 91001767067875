//react
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
//react style
import { Row, CardBody, Col, Container, Card } from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
import { Column } from 'react-table';
//component
import MetaTags from 'react-meta-tags';
import SearchBar from 'src/components/Table/SearchBar';
import CustomTable from 'src/components/Common/CustomTable';
//redux
import Breadcrumb from 'src/components/Common/Breadcrumb';
import choice from 'src/assets/images/choice.png';
import config from 'src/config';
import menu from 'src/constants/menu';
import Dropdown from 'src/components/CommonForBoth/Menus/Dropdown';
import { getApplicationsFilter as onGetApplicationsFilter } from 'src/store/application/actions';
import Swal from 'sweetalert2';
import { setLoading, unsetLoading } from 'src/store/actions';
import { softDeleteApplication } from 'src/graphql/publisher/application';
import AppVerifyStatus from 'src/components/Common/AppVerifyStatus';
import { floorToFixed } from 'src/common/data/mathToFixed';

const ApplicationIndex = ({ t }: any) => {
  //from react
  const dispatch = useDispatch();
  const history = useHistory();
  //state variables
  const [statusSelected, setStatusSelected] = useState<string | null>('ALL');
  const [page, setPage] = useState(1);
  const [nameSearch, setNameSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  //general variable
  const { Applications, applicationsLoading } = useSelector((state: any) => ({
    Applications: state.Application.applications,
    applicationsLoading: state.Global.applicationsLoading,
  }));

  const Status = [
    {
      value: 'GETTING_READY',
      label: t('table:GETTING_READY'),
    },
    { value: 'REQUIRES_REVIEW', label: t('table:REQUIRES_REVIEW') },
    { value: null, label: t('All') },
  ];

  //general function
  const getDefaultPayload = () => ({
    limit: 10,
    page,
    status: statusSelected,
    search: nameSearch,
  });

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(),
      page: newPage,
    };
    dispatch(onGetApplicationsFilter(payload));
    setPage(newPage);
  };
  const handleStatusChange = (status: { value: string }) => {
    const statusValue = status ? status.value : '';
    const payload = {
      ...getDefaultPayload(),
      page: 1,
      status: statusValue,
    };
    dispatch(onGetApplicationsFilter(payload));
    setStatusSelected(statusValue);
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...getDefaultPayload(),
      search: searchInput,
    };
    dispatch(onGetApplicationsFilter(payload));
    setNameSearch(searchInput);
  };

  const handleClear = () => {
    setNameSearch('');
    const payload = {
      ...getDefaultPayload(),
      search: '',
    };
    dispatch(onGetApplicationsFilter(payload));
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(),
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetApplicationsFilter(payload));
      setPage(newPage);
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [page, 10],
  );

  const deleteApplication = (name: string, application_key: string) => {
    Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to delete application`, { applicationName: name }),
      imageUrl: choice,
      imageHeight: 200,
      width: 300,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await softDeleteApplication(application_key);
        dispatch(onGetApplicationsFilter(getDefaultPayload()));
        dispatch(unsetLoading());
      } else {
        dispatch(unsetLoading());
      }
    });
  };

  //table
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Name'),
        accessor: ({ name, application_key }) => (
          <div
            style={{
              paddingLeft: 20,
              textAlign: 'left',
            }}
          >
            <Link to={`/Application/${application_key}`}>
              <label className="label-link" htmlFor="Name">
                {name?.length > 50 ? `${name.slice(0, 50)}... ` : name}
              </label>
            </Link>
          </div>
        ),
        id: 'name',
        rowCss: 'mt-3',
        width: 250,
      },
      {
        Header: t('table:Status / Store name'),
        accessor: ({ store_name, status }) =>
          store_name ? (
            <div>
              <AppVerifyStatus status={status} />
              <div>
                {store_name?.length > 45
                  ? `store name: ${store_name.slice(0, 45)}... `
                  : `store name: ${store_name}`}
              </div>
            </div>
          ) : (
            <AppVerifyStatus status={status} />
          ),
        id: 'status',
        rowCss: 'mt-3',
        disableSortBy: true,
        width: 180,
      },
      {
        Header: t('table:Platform'),
        accessor: ({ mobile_platform }) => mobile_platform,
        id: 'mobile_platform',
        rowCss: 'mt-3',
        disableSortBy: true,
        width: 150,
      },
      {
        Header: t('table:Earnings/day'),
        accessor: ({ current_revenue }) => (
          <div style={{ color: '#009A78', fontWeight: 'bold' }}>{`${floorToFixed(
            current_revenue,
            2,
          )} USDT`}</div>
        ),
        id: 'current_revenue',
        rowCss: 'mt-3',
        width: 150,
      },
      {
        Header: t('table:Maxpayout'),
        accessor: ({ revenue }) => (
          <div style={{ color: '#009A78', fontWeight: 'bold' }}>{`${floorToFixed(
            revenue,
            2,
          )} USDT`}</div>
        ),
        id: 'revenue',
        rowCss: 'mt-3',
        width: 150,
      },
      {
        Header: t('table:Action'),
        accessor: ({ application_key, name }) => (
          <button
            type="button"
            onClick={() => deleteApplication(name, application_key)}
            style={{
              width: 40,
              height: 40,
              color: '#0c6cff',
              backgroundColor: '#dedef7',
              border: 0,
              borderRadius: 50,
            }}
          >
            <i className="mdi mdi-delete font-size-22" />
          </button>
        ),
        id: 'action',
        rowCss: 'mt-2',
        width: 150,
        disableSortBy: true,
      },
    ],
    [t, page],
  );

  const hiddenOnXS: any = [
    'Total budget',
    'adType',
    'Status',
    'Clicks',
    'CTR',
    'eCPM',
  ];
  const hiddenOnSM: any = ['Total budget', 'adType', 'Status'];
  const hiddenOnMD: any = ['Total budget'];
  const hiddenOnLG: any = [];

  //set data
  useEffect(() => {
    dispatch(onGetApplicationsFilter(getDefaultPayload()));
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('page:Application')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('page:Application')}
          menus={[{ menu: menu.DASHBOARD }, { menu: menu.CAMPAIGN, active: true }]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="justify-content-between p-1-rem-horizontal">
                  <Col xs={12} sm={4} md={3} lg={3} className="mt-3">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        history.push(`/application/create`);
                      }}
                    >
                      {t('button:Add application')}
                    </button>
                  </Col>

                  <Col md={1} lg={2} />
                  <Col xs={12} md={8} lg={7}>
                    <Row>
                      <Col sm={12} md={3} className="mt-3">
                        <Dropdown
                          options={Status}
                          placeholder={t('placeHolder.SelectStatus')}
                          action={handleStatusChange}
                          default_value={Status[3]}
                          style={{ minWidth: '125px' }}
                        />
                      </Col>
                      <Col sm={12} md={9}>
                        <SearchBar
                          placeholder={t('table:Search Name')}
                          handleSearch={handleSearch}
                          handleClear={handleClear}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <CardBody>
                  <CustomTable
                    columns={columns}
                    data={Applications.items || []}
                    page={page}
                    pageSize={10}
                    total={Applications.totalCount || 1}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                    hiddenOnXS={hiddenOnXS}
                    hiddenOnSM={hiddenOnSM}
                    hiddenOnMD={hiddenOnMD}
                    hiddenOnLG={hiddenOnLG}
                    onSort={handleSort}
                    isLoading={applicationsLoading}
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(ApplicationIndex);
