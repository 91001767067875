import { WithdrawTypes } from './actionTypes';

const initialState = {
  recentTransactions: [],
  transactions: [],
  error: '',
  loading: false,
};

const withdraw = (state = initialState, action: any) => {
  switch (action.type) {
    case WithdrawTypes.WITHDRAW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WithdrawTypes.WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case WithdrawTypes.GET_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case WithdrawTypes.GET_RECENT_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        recentTransactions: action.payload,
      };
    case WithdrawTypes.GET_ALL_WITHDRAW_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };
    case WithdrawTypes.WITHDRAW_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WithdrawTypes.WITHDRAW_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default withdraw;
