import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Col, Container, Row, UncontrolledAlert } from 'reactstrap';
import queryString from 'query-string';

//import images
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import logo from '../../assets/images/logo-colorful.svg';
import logoWhite from '../../assets/images/logo-white.svg';

import CarouselPage from './CarouselPage';

import { verifyEmail } from '../../store/auth/register/actions';
import config from '../../config';
import { layoutTheme } from '../../constants/layout';

const VerifyEmail = ({ location, history, t }: any) => {
  const dispatch = useDispatch();
  const { id, expires, hash, signature, role } = queryString.parse(location.search);
  const { layoutMode, register } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    register: state.register,
  }));

  useEffect(() => {
    const data = {
      id,
      expires,
      hash,
      signature,
    };

    dispatch(verifyEmail(data, history, role));
  }, []);

  return (
    <>
      <MetaTags>
        <title>Verify Email | {config.appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={
                            layoutMode === layoutTheme.DARKMODE ? logoWhite : logo
                          }
                          alt=""
                          height="28"
                        />{' '}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bx-mail-send h2 mb-0 text-primary" />
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>{register.error ? '' : t('Success')}</h4>
                          {register.error ? (
                            <UncontrolledAlert color="danger" className="alert">
                              {register.message}
                              &nbsp;
                              {t('Login And Resend')}
                              &nbsp;
                              <Link to="/login">{t('Login')}</Link>
                            </UncontrolledAlert>
                          ) : (
                            <p className="text-muted">{t('Verify Email')}</p>
                          )}
                          <div className="mt-4">
                            <Link to="/" className="btn btn-primary w-100">
                              {t('Back To Homepage')}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(withRouter(VerifyEmail));
