import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { getCampaignStatusSummary as onGetCampaignStatusSummary } from 'src/store/actions';

const CampaignSummaryWidget = ({ t }: any) => {
  const dispatch = useDispatch();

  const { statusSummary } = useSelector((state: any) => ({
    statusSummary: state.Campaigns.statusSummary,
  }));

  useEffect(() => {
    dispatch(onGetCampaignStatusSummary(''));
  }, []);

  // <h3 className="font-size-20 fw-bold">{t('campaign:Campaigns Summary')}</h3>
  //     <Row className="ms-3 mt-3">
  //       <Col className="font-size-26">
  //         <p className="font-size-18 mb-0">{t('enumCampaign:Active')}</p>
  //         <span className="fw-light">{statusSummary.active}</span>
  //       </Col>
  //       <Col className="font-size-26">
  //         <p className="font-size-18 mb-0">{t('enumCampaign:Finish')}</p>
  //         <span className="fw-light">{statusSummary.finished}</span>
  //       </Col>
  //     </Row>
  //     <Row className="ms-3 mt-3">
  //       <Col className="font-size-26">
  //         <p className="font-size-18 mb-0">{t('enumCampaign:Pause')}</p>
  //         <span className="fw-light">{statusSummary.paused}</span>
  //       </Col>
  //       <Col className="font-size-26">
  //         <p className="font-size-18 mb-0">{t('enumCampaign:Draft')}</p>
  //         <span className="fw-light">{statusSummary.draft}</span>
  //       </Col>
  //     </Row>
  //     <Row className="ms-3 mt-3">
  //       <Col lg={6} className="font-size-26">
  //         <p className="font-size-18 mb-0">{t('enumCampaign:Out of budget')}</p>
  //         <span className="fw-light">{statusSummary.outOfBudget}</span>
  //       </Col>
  //     </Row>

  return (
    <div className="h-100-per p-0 mb-0">
      <Row className="justify-content-center h-100">
        <Col xs={12} style={{ height: 'auto' }}>
          <span className="font-size-20 fw-bold p-0 m-0">
            {t('campaign:Campaigns Summary')}
          </span>
        </Col>
        <Col xs={12} sm={6} className="pe-sm-1 pb-sm-1">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{statusSummary.active}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('enumCampaign:Active')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-running fs-1"
                  style={{ fontSize: '1.5em', color: '#E4DCCF' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6} className="ps-sm-1 pb-sm-1">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{statusSummary.finished}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('enumCampaign:Finish')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-check fs-1"
                  style={{ fontSize: '1.5em', color: '#E4DCCF' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6} className="pe-sm-1 pt-sm-1">
          <div className="card-shadow-sm px-3 py-2 h-100 w-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{statusSummary.paused}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('enumCampaign:Pause')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-hourglass-half fs-1"
                  style={{ fontSize: '1.5em', color: '#E4DCCF' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6} className="ps-sm-1 pt-sm-1">
          <div className="card-shadow-sm px-3 py-2 w-100 h-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{statusSummary.draft}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('enumCampaign:Draft')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fas fa-file-alt fs-1"
                  style={{ fontSize: '1.5em', color: '#E4DCCF' }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} className="pt-sm-1">
          <div className="card-shadow-sm px-3 py-2 w-100 h-100">
            <Row className="h-100">
              <Col
                xs={8}
                sm={6}
                className="d-flex flex-column align-items-start justify-content-center"
              >
                <span className="fs-1 fw-bold">
                  <span>{statusSummary.outOfBudget}</span>
                </span>
                <span
                  className="fs-6 fw-bold text-muted mb-0 d-block"
                  style={{ marginTop: '-0.8rem' }}
                >
                  {t('enumCampaign:Out of budget')}
                </span>
              </Col>
              <Col
                xs={4}
                sm={6}
                className="d-flex align-items-center justify-content-end"
              >
                <i
                  className="fab fa-creative-commons-nc fs-1"
                  style={{
                    fontSize: '1.5em',
                    color: '#E4DCCF',
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(CampaignSummaryWidget);
