import { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CardBody, Row } from 'reactstrap';
import config from 'src/config';

const AdAuto = ({ t }: any) => {
  const textAreaRef = useRef<HTMLInputElement>(null);
  const webData = useSelector((state: any) => state.WebsiteInfo.websiteInfo);

  const autoAdScript = `<script async src="${
    config.reactAppAdApiUrl
  }autoad.js?client=${webData?.website_key}&websiteCreative=${
    Object.keys(webData)?.length ? webData?.CreativeWebsite[0].key : ''
  }"></script>`;

  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copySuccess) setCopySuccess(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [copySuccess]);

  const handleCopy = (val: string) => {
    navigator.clipboard.writeText(val);
    setCopySuccess(true);
  };

  return (
    <Row>
      <div className="card-body">
        <h4 className="font-size-16">{t('Instruction')}</h4>
        <p className="card-content font-size-14">{t('Instruction Desc')}</p>
      </div>
      <div className="card-body">
        <h4 className="font-size-16">{t('Unified code')}</h4>
        <p className="card-content font-size-14">{t('Unified code Desc')}</p>

        <div>
          <CardBody className="col-md-10 col-xl-10">
            <div className="input-group font-size-30">
              <input
                className="form-control"
                ref={textAreaRef}
                type="text"
                value={
                  webData?.status === 'APPROVED'
                    ? autoAdScript
                    : t('Waiting for approve')
                }
                style={{
                  background: 'none',
                  backgroundColor:
                    webData?.status === 'APPROVED'
                      ? 'transparent'
                      : 'rgb(255,193,7, 0.1)',
                  borderColor: webData?.status === 'APPROVED' ? '' : '#ffc107',
                  fontSize: 20,
                  color: webData?.status === 'APPROVED' ? 'black' : '#ffc107',
                }}
                readOnly
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() => {
                  handleCopy(
                    webData?.status === 'APPROVED'
                      ? autoAdScript
                      : 'Waiting for approve',
                  );
                }}
                style={{
                  background: 'none',
                  backgroundColor:
                    webData?.status === 'APPROVED'
                      ? 'transparent'
                      : 'rgb(255,193,7, 0.1)',
                  borderColor: webData?.status === 'APPROVED' ? '' : '#ffc107',
                  fontSize: 20,
                  color: webData?.status === 'APPROVED' ? 'black' : '#ffc107',
                }}
              >
                {!copySuccess ? (
                  <i className="far fa-copy" />
                ) : (
                  <>
                    <i className="fas fa-check-circle" />{' '}
                  </>
                )}
              </button>
            </div>
          </CardBody>
        </div>
      </div>
    </Row>
  );
};

export default withTranslation()(AdAuto);
