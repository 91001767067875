import React, { useEffect } from 'react';
import { Column } from 'react-table';
import config from 'src/config';
import ImageAutoBalance from 'src/components/Common/ImageAutoBalance';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import CustomTable from '../../Common/CustomTable';

const UploadCreativeTable = (props: any) => {
  const { fileUploads, setFileUploads, UploadAdFormat, t } = props;

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('Preview'),
        accessor: (creatives) => {
          if (creatives.data) {
            return (
              <div className="pic-frame">
                <div className="pic-middle">
                  <ImageAutoBalance
                    href={`${config.backendUrl}creatives/display/${creatives.data.image_storage.key}`}
                    alt="ad image"
                    src={`${config.backendUrl}creatives/display/${creatives.data.image_storage.key}`}
                    sizeImage={50}
                    width={+creatives.data.size.split('x')[0]}
                    height={+creatives.data.size.split('x')[1]}
                    size="md"
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div className="pic-frame">
                <div className="pic-middle">
                  {creatives[0].fileSize && (
                    <ImageAutoBalance
                      href={null}
                      alt={creatives[0].name}
                      src={creatives[0].preview}
                      sizeImage={50}
                      width={+creatives[0].fileSize.split('x')[0]}
                      height={+creatives[0].fileSize.split('x')[1]}
                      size="md"
                    />
                  )}
                </div>
              </div>
            );
          }
        },
        id: 'Preview',
        disableSort: true,
      },
      {
        Header: 'Name',
        accessor: (creatives) =>
          creatives.data ? creatives.data.name : creatives[0].nameAd,
        rowCss: 'mt-3',
        id: 'Name',
      },
      {
        Header: 'URL',
        accessor: (creatives) => {
          return creatives.data ? creatives.url_ad : creatives[0].url_ad;
        },
        Cell: ({ cell }: any) => {
          const rowData = cell.row.original;

          let url_ad;
          url_ad = rowData.data ? rowData.url_ad : rowData[0].url_ad;
          const urlOnclick = url_ad;
          url_ad = `${url_ad.slice(0, 20)} ${url_ad.slice(20, url_ad?.length)}`;
          if (url_ad?.length > 20) {
            url_ad = `${url_ad.slice(0, 45)}...`;
          }

          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {url_ad}
            </a>
          );
        },
        rowCss: 'mt-2',

        id: 'URL',
      },
      {
        Header: 'Creative Size',
        accessor: (creatives) => {
          const fileSize = creatives.data
            ? creatives.data.size
            : creatives[0].fileSize;
          return fileSize;
        },
        rowCss: 'mt-3',
        id: 'Size',
      },
      {
        Header: 'Status',
        accessor: (creatives) => {
          const isActive = creatives.data
            ? creatives.isActive
            : creatives[0].isActive;
          return isActive ? 'active' : 'No active';
        },
        rowCss: 'mt-3',
        disableSort: true,
        id: 'Status',
      },
      {
        Header: 'Action',
        accessor: (creatives, rowIndex) => {
          return (
            <div className="text-center mt-2 rounded-circle">
              <div className="avatar-md mx-auto">
                <span
                  className="avatar-bin bg-gainsboro rounded-circle"
                  onKeyDown={() => {}}
                  onClick={() => {
                    Swal.fire({
                      width: 300,
                      title: t('swal:Are you sure ?'),
                      text: t("swal:You won't be able to revert this!"),
                      imageUrl: choice,
                      imageHeight: 200,
                      imageAlt: 'A tall image',
                      cancelButtonColor: 'red',
                      customClass: {},
                      showCancelButton: true,
                      confirmButtonColor: '#009a78',
                      confirmButtonText: t('button:Yes'),
                      cancelButtonText: t('swal:Cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        const temp = [...fileUploads];
                        temp.splice(rowIndex, 1);
                        setFileUploads(temp);
                      }
                    });
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <i className="mdi mdi-delete font-size-26 text-primary" />
                </span>
              </div>
            </div>
          );
        },
        id: 'Action',
        disableSort: true,
      },
    ],
    [fileUploads],
  );

  const columnsNative: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('Preview'),
        accessor: (creatives) => {
          if (creatives.data) {
            return (
              <div className="pic-frame">
                <div className="pic-middle">
                  <ImageAutoBalance
                    href={`${config.backendUrl}creatives/display/${creatives.data.image_storage.key}`}
                    alt="ad image"
                    src={`${config.backendUrl}creatives/display/${creatives.data.image_storage.key}`}
                    sizeImage={50}
                    width={+creatives.data.size.split('x')[0]}
                    height={+creatives.data.size.split('x')[1]}
                    size="md"
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div className="pic-frame">
                <div className="pic-middle">
                  {creatives[0].fileSize && (
                    <ImageAutoBalance
                      href={null}
                      alt={creatives[0].name}
                      src={creatives[0].preview}
                      sizeImage={50}
                      width={+creatives[0].fileSize.split('x')[0]}
                      height={+creatives[0].fileSize.split('x')[1]}
                      size="md"
                    />
                  )}
                </div>
              </div>
            );
          }
        },
        id: 'Preview',
        disableSort: true,
      },
      {
        Header: 'Name',
        accessor: (creatives) =>
          creatives.data ? creatives.data.name : creatives[0].nameAd,
        rowCss: 'mt-3',
        id: 'Name',
      },
      {
        Header: 'URL',
        accessor: (creatives) => {
          return creatives.data ? creatives.url_ad : creatives[0].url_ad;
        },
        Cell: ({ cell }: any) => {
          const rowData = cell.row.original;

          let url_ad;
          url_ad = rowData.data ? rowData.url_ad : rowData[0].url_ad;
          const urlOnclick = url_ad;
          url_ad = `${url_ad.slice(0, 20)} ${url_ad.slice(20, url_ad?.length)}`;
          if (url_ad?.length > 20) {
            url_ad = `${url_ad.slice(0, 45)}...`;
          }

          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {url_ad}
            </a>
          );
        },
        rowCss: 'mt-2',

        id: 'URL',
      },
      {
        Header: 'Title',
        accessor: (creatives) =>
          creatives.data ? creatives.titleAd : creatives[0].titleAd,
        rowCss: 'mt-3',
        id: 'title',
      },
      {
        Header: 'Description',
        accessor: (creatives) =>
          creatives.data ? creatives.descriptionAd : creatives[0].descriptionAd,
        rowCss: 'mt-3',
        width: 500,
        id: 'description',
      },
      {
        Header: 'Creative Size',
        accessor: (creatives) => {
          const fileSize = creatives.data
            ? creatives.data.size
            : creatives[0].fileSize;
          return fileSize;
        },
        rowCss: 'mt-3',
        id: 'Size',
      },
      {
        Header: 'Status',
        accessor: (creatives) => {
          const isActive = creatives.data
            ? creatives.isActive
            : creatives[0].isActive;
          return isActive ? 'active' : 'No active';
        },
        rowCss: 'mt-3',
        disableSort: true,
        id: 'Status',
      },
      {
        Header: 'Action',
        accessor: (creatives, rowIndex) => {
          return (
            <div className="text-center mt-2 rounded-circle">
              <div className="avatar-md mx-auto">
                <span
                  className="avatar-bin bg-gainsboro rounded-circle"
                  onKeyDown={() => {}}
                  onClick={() => {
                    Swal.fire({
                      width: 300,
                      title: t('swal:Are you sure ?'),
                      text: t("swal:You won't be able to revert this!"),
                      imageUrl: choice,
                      imageHeight: 200,
                      imageAlt: 'A tall image',
                      cancelButtonColor: 'red',
                      customClass: {},
                      showCancelButton: true,
                      confirmButtonColor: '#009a78',
                      confirmButtonText: t('button:Yes'),
                      cancelButtonText: t('swal:Cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        const temp = [...fileUploads];
                        temp.splice(rowIndex, 1);
                        setFileUploads(temp);
                      }
                    });
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <i className="mdi mdi-delete font-size-26 text-primary" />
                </span>
              </div>
            </div>
          );
        },
        id: 'Action',
        disableSort: true,
      },
    ],
    [fileUploads],
  );

  const columnsPop: Column<any>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (creatives) =>
          creatives.data ? creatives.data.name : creatives[0].nameAd,
        rowCss: 'mt-3',
        id: 'Name',
      },
      {
        Header: 'URL',
        accessor: (creatives) => {
          return creatives.data ? creatives.url_ad : creatives[0].url_ad;
        },
        Cell: ({ cell }: any) => {
          const rowData = cell.row.original;

          let url_ad;
          url_ad = rowData.data ? rowData.url_ad : rowData[0].url_ad;
          const urlOnclick = url_ad;
          url_ad = `${url_ad.slice(0, 20)} ${url_ad.slice(20, url_ad?.length)}`;
          if (url_ad?.length > 20) {
            url_ad = `${url_ad.slice(0, 45)}...`;
          }

          return (
            <a href={urlOnclick} target="_blank" rel="noreferrer">
              {url_ad}
            </a>
          );
        },
        rowCss: 'mt-2',

        id: 'URL',
      },
      {
        Header: 'Status',
        accessor: (creatives) => {
          const isActive = creatives.data
            ? creatives.isActive
            : creatives[0].isActive;
          return isActive ? 'active' : 'No active';
        },
        rowCss: 'mt-3',
        disableSort: true,
        id: 'Status',
      },
      {
        Header: 'Action',
        accessor: (creatives, rowIndex) => {
          return (
            <div className="text-center mt-2 rounded-circle">
              <div className="avatar-md mx-auto">
                <span
                  className="avatar-bin bg-gainsboro rounded-circle"
                  onKeyDown={() => {}}
                  onClick={() => {
                    Swal.fire({
                      width: 300,
                      title: t('swal:Are you sure ?'),
                      text: t("swal:You won't be able to revert this!"),
                      imageUrl: choice,
                      imageHeight: 200,
                      imageAlt: 'A tall image',
                      cancelButtonColor: 'red',
                      customClass: {},
                      showCancelButton: true,
                      confirmButtonColor: '#009a78',
                      confirmButtonText: t('button:Yes'),
                      cancelButtonText: t('swal:Cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        const temp = [...fileUploads];
                        temp.splice(rowIndex, 1);
                        setFileUploads(temp);
                      }
                    });
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <i className="mdi mdi-delete font-size-26 text-primary" />
                </span>
              </div>
            </div>
          );
        },
        id: 'Action',
        disableSort: true,
      },
    ],
    [fileUploads],
  );
  useEffect(() => {
    setFileUploads([]);
  }, [UploadAdFormat]);

  let TableDisplay;
  switch (UploadAdFormat) {
    case 'POP':
      TableDisplay = (
        <CustomTable
          columns={columnsPop}
          data={fileUploads}
          page={1}
          autoSort
          pageSize={10}
          total={10}
          hidePagination
          headerCSS="fw-normal bg-header-table"
        />
      );
      break;
    case 'NATIVE':
      TableDisplay = (
        <CustomTable
          columns={columnsNative}
          data={fileUploads}
          page={1}
          autoSort
          pageSize={10}
          total={10}
          hidePagination
          headerCSS="fw-normal bg-header-table"
        />
      );
      break;
    default:
      TableDisplay = (
        <div className="mt-3">
          <CustomTable
            columns={columns}
            data={fileUploads}
            page={1}
            autoSort
            pageSize={10}
            total={10}
            hidePagination
            headerCSS="fw-normal bg-header-table"
          />
        </div>
      );
  }
  return <>{fileUploads?.length !== 0 && TableDisplay}</>;
};

export default withTranslation()(UploadCreativeTable);
