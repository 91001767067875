import CalculateSocialMediaTypes from './actionTypes';
import { UserSocialProfile } from './reducer';

export const setUserProfileList = (payload: UserSocialProfile[]) => ({
  type: CalculateSocialMediaTypes.SET_USER_PROFILE_LIST,
  payload,
});

export const setUserProfileSelected = (payload: UserSocialProfile | null) => ({
  type: CalculateSocialMediaTypes.SET_USER_PROFILE_SELECTED,
  payload,
});

export const setFacebookError = (payload: boolean) => ({
  type: CalculateSocialMediaTypes.SET_FACEBOOK_ERROR,
  payload,
});

export const setUserProfileStatus = (payload: String) => ({
  type: CalculateSocialMediaTypes.SET_USER_PROFILE_STATUS,
  payload,
});
