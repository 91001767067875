import { FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import balanceLogRangeEnum from '../../constants/balanceLogRangeEnum';

const BalanceLogRangeOptions = ({
  defaultValue,
  handleBalanceLogRangeChange,
  isSmall,
}: any) => {
  const { t }: any = useTranslation();
  const handleChange = (e: any) => {
    const logRange = e.currentTarget.value;
    handleBalanceLogRangeChange(logRange);
  };

  const renderBalanceLogRangeOptions = () =>
    Object.keys(balanceLogRangeEnum).map((key: string) => (
      <option
        // @ts-ignore
        value={balanceLogRangeEnum[key]}
        defaultValue={defaultValue}
      >
        {t(`${key}`)}
      </option>
    ));

  return (
    <FormGroup>
      <select
        className={`mb-3 mb-md-0 form-control ${isSmall ? 'form-control-sm' : ''}`}
        onChange={handleChange}
      >
        {renderBalanceLogRangeOptions()}
      </select>
    </FormGroup>
  );
};

export default BalanceLogRangeOptions;
