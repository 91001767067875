import { gql } from '@apollo/client';
import { client } from '../client';
import {
  WithdrawTransactionsQuery,
  DepositTransactionsQuery,
} from '../../common/types';

const getAdminDepositTransactions = async (queryInput: DepositTransactionsQuery) => {
  const GET_ADMIN_DEPOSIT_TRANSACTIONS = gql`
    query GetAdminDepositTransactions($input: TransactionsQueryInput!) {
      adminDepositTransactions(input: $input) {
        items {
          uuid
          tx_hash
          address
          CRYPTO_amount
          FIAT_amount
          FIAT_rate
          created_at
          updated_at
          checksum_status
          fromAddress
          transferType
          currency {
            name
            symbol
            main_symbol
            explorer_link
          }
          user {
            uuid
            email
          }
        }
        totalCount
      }
    }
  `;
  try {
    const result: any = await client.query({
      query: GET_ADMIN_DEPOSIT_TRANSACTIONS,
      variables: {
        input: queryInput,
      },
      fetchPolicy: 'no-cache',
    });
    return result.data.adminDepositTransactions;
  } catch (error: any) {
    return null;
  }
};

const getPaymentTransactions = async (
  limit: number,
  page: number,
  search: string,
  userId: string,
  isCampaign: boolean,
) => {
  const GET_PAYMENT_TRANSACTIONS = gql`
    query AdvertiserPaymentTransactions(
      $transactionCampaignInput: AdvertiserTransactionsQueryInput
    ) {
      advertiserPaymentTransactions(
        transactionCampaignInput: $transactionCampaignInput
      ) {
        items {
          id
          FIAT_amount
          uuid
          campaignDatums {
            dailyImpression
            CPM
            creativeCampaignDatums {
              uuid
              dailyImpression
              currentDailyBudget
              creativeCampaign {
                name
              }
              creative {
                name
                size
                image_storage {
                  key
                }
              }
            }
          }
          campaign {
            id
            name
            CPM
            user {
              email
            }
          }
          created_at
        }
        totalCount
      }
    }
  `;
  try {
    const transactionCampaignInput = { limit, page, search, userId, isCampaign };
    const result: any = await client.query({
      query: GET_PAYMENT_TRANSACTIONS,
      variables: { transactionCampaignInput },
      fetchPolicy: 'no-cache',
    });

    return result.data.advertiserPaymentTransactions;
  } catch (error) {
    return null;
  }
};

const getAdminWithdrawTransactions = async (
  queryInput: WithdrawTransactionsQuery,
) => {
  const GET_ADMIN_WITHDRAW_TRANSACTION = gql`
    query GetAdminWithdrawTransactions($input: TransactionsQueryInput!) {
      adminWithdrawTransactions(input: $input) {
        items {
          uuid
          tx_hash
          address
          CRYPTO_amount
          FIAT_amount
          FIAT_rate
          fee
          created_at
          updated_at
          status
          checksum_status
          transferType
          real_txHash
          fairmarket_message
          currency {
            name
            symbol
            main_symbol
            explorer_link
          }
          user {
            uuid
            email
            role
          }
        }
        totalCount
      }
    }
  `;

  try {
    const result: any = await client.query({
      query: GET_ADMIN_WITHDRAW_TRANSACTION,
      variables: {
        input: queryInput,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.adminWithdrawTransactions;
  } catch (error: any) {
    return null;
  }
};

const getInviteOnlyTransactions = async (
  limit: number,
  page: number,
  search: string,
  userId: string,
  isCampaign: boolean,
  jobType: string,
) => {
  const GET_INVITEONLY_TRANSACTIONS = gql`
    query AdvertiserPaymentTransactions(
      $transactionCampaignInput: AdvertiserTransactionsQueryInput
    ) {
      advertiserPaymentTransactions(
        transactionCampaignInput: $transactionCampaignInput
      ) {
        items {
          id
          FIAT_amount
          tax
          uuid
          user {
            email
          }
          social_media_has_job {
            job {
              name
              currency {
                symbol
                network
              }
            }
            social_media_info {
              social_media_type
            }
          }
          created_at
        }
        totalCount
      }
    }
  `;
  const transactionCampaignInput = {
    limit,
    page,
    search,
    userId,
    isCampaign,
    jobType,
  };

  const result: any = await client.query({
    query: GET_INVITEONLY_TRANSACTIONS,
    variables: { transactionCampaignInput },
    fetchPolicy: 'no-cache',
  });

  return result.data.advertiserPaymentTransactions;
};
const getPublishTransactions = async (
  limit: number,
  page: number,
  search: string,
  userId: string,
  isCampaign: boolean,
  jobType: string,
) => {
  const GET_PUBLISH_TRANSACTIONS = gql`
    query AdvertiserPaymentTransactions(
      $transactionCampaignInput: AdvertiserTransactionsQueryInput
    ) {
      advertiserPaymentTransactions(
        transactionCampaignInput: $transactionCampaignInput
      ) {
        items {
          id
          FIAT_amount
          tax
          uuid
          user {
            email
          }
          social_media_has_job {
            job {
              name
              currency {
                symbol
                network
              }
            }
            social_media_info {
              social_media_type
            }
          }
          created_at
        }
        totalCount
      }
    }
  `;
  const transactionCampaignInput = {
    limit,
    page,
    search,
    userId,
    isCampaign,
    jobType,
  };
  const result: any = await client.query({
    query: GET_PUBLISH_TRANSACTIONS,
    variables: { transactionCampaignInput },
    fetchPolicy: 'no-cache',
  });

  return result.data.advertiserPaymentTransactions;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getAdminDepositTransactions,
  getAdminWithdrawTransactions,
  getPaymentTransactions,
  getPublishTransactions,
  getInviteOnlyTransactions,
};
