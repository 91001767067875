import { call, put, takeEvery } from 'redux-saga/effects';
import { PaymentTypes } from './actionTypes';

import { getPaymentTransactions } from '../../../graphql/admin/transaction';
import { getAllPaymentTransactionsRequestSuccess } from './actions';

function* getAllPaymentTransactionsRequest({
  payload: { limit, page, search, userId, isCampaign },
}: any) {
  const response: Promise<any> = yield call(
    getPaymentTransactions,
    limit,
    page,
    search,
    userId,
    isCampaign,
  );
  yield put(getAllPaymentTransactionsRequestSuccess(response));
}

function* paymentSaga() {
  yield takeEvery(
    PaymentTypes.GET_ALL_PAYMENT_TRANSACTIONS_REQUEST,
    getAllPaymentTransactionsRequest,
  );
}

export default paymentSaga;
