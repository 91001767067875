import OwnCurrencyTypes from './actionTypes';

export const getFilterOwnCurrency = (payload: any) => ({
  type: OwnCurrencyTypes.GET_FILTER_OWN_CURRENCY,
  payload,
});

export const getFilterOwnCurrencySuccess = (payload: any) => ({
  type: OwnCurrencyTypes.GET_FILTER_OWN_CURRENCY_SUCCESS,
  payload,
});

export const getFilterMarketerOwnCurrency = (payload: any) => ({
  type: OwnCurrencyTypes.GET_FILTER_MARKETER_OWN_CURRENCY,
  payload,
});

export const getFilterMarketerOwnCurrencySuccess = (payload: any) => ({
  type: OwnCurrencyTypes.GET_FILTER_MARKETER_OWN_CURRENCY_SUCCESS,
  payload,
});
