import Pagination from '@mui/material/Pagination';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import MarketerCampaignStatusButtonGroup from 'src/components/Advertiser/marketerCampaignStatusButtonGroup';
import { OnLoading } from 'src/components/Common/Loading';
import {
  CampaignStatusBackGroundColor,
  MarketerCampaignStatus,
} from 'src/components/Common/status/MarketerCampaign';
import SearchBar from 'src/components/Table/SearchBar';

import config from 'src/config';

import {
  getDefaultWebsite,
  getSocialMediaInfoGroupByAptitude,
} from 'src/store/actions';
import { filterMarketerCampaign } from 'src/store/marketerCampaign/actions';
import './MarketerCampaignIndex.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import { floorToFixed } from 'src/common/data/mathToFixed';

const MarketerCampaignIndex = ({ campaignType }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPublish = campaignType === 'PUBLISH';
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'ALL',
    marketer_campaign_type: campaignType ? 'PUBLISH' : 'INVITE_ONLY',
  });

  const { data, defaultTax, socialMediaInfo } = useSelector((state: any) => ({
    socialMediaInfo: state.SocialMediaInfo.discover,
    data: state.MarketerCampaign.filter,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
  }));
  const tax = defaultTax / 100 + 1;
  if (data && Object.keys(data)?.length) {
    for (let i = 0; i < data?.items?.length; i++) {
      const campaign = data.items[i];
      let num_influencer = 0;
      let num_follower = 0;
      let total_price = 0;
      for (let j = 0; j < campaign.jobs?.length; j++) {
        const job = campaign.jobs[j];
        num_influencer += job.social_media_has_jobs?.length || 0;
        for (let k = 0; k < job.social_media_has_jobs?.length; k++) {
          const socialJob = job.social_media_has_jobs[k];
          num_follower += socialJob.social_media_info.follower || 0;
          total_price += socialJob.price || 0;
        }
      }

      data.items[i].num_influencer = num_influencer;
      data.items[i].num_follower = num_follower;
      data.items[i].total_price = total_price * tax;
    }
  }

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(filterMarketerCampaign(payload, campaignType));
    setParam(payload);
  };

  const handleSearch = (searchInput: string) => {
    const payload = {
      ...param,
      search: searchInput,
    };
    dispatch(filterMarketerCampaign(payload, campaignType));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      search: '',
    };
    dispatch(filterMarketerCampaign(payload, campaignType));
    setParam(payload);
  };

  // const handlePageSizeChange = (newPageSize: string) => {
  //   const payload = {
  //     ...param,
  //     limit: +newPageSize,
  //     page: 1,
  //   };
  //   dispatch(filterMarketerCampaign(payload));
  //   setParam(payload);
  // };

  useEffect(() => {
    dispatch(getSocialMediaInfoGroupByAptitude());
    dispatch(getDefaultWebsite());
    dispatch(filterMarketerCampaign(param, campaignType));
  }, []);

  useMemo(() => {
    if (!data) return;
    if (!Object.keys(data)?.length) return;
    const { totalCount, isPublish: dataIsPublish } = data;

    if (campaignType === 'PUBLISH' ? dataIsPublish : !dataIsPublish) {
      if (!totalCount) {
        const path =
          campaignType === 'PUBLISH'
            ? '/marketer/Create/PublicCampaign'
            : '/marketer/create-campaign';
        history.push(path);
      }
    }
  }, [data]);

  if (!Object.keys(data)?.length) return <OnLoading />;

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t('page:Campaign')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <div className="marketer-campaign-container">
          <Breadcrumb
            pageTitle={t(
              isPublish ? 'Menus.Public Campaign' : 'Menus.Invite-Only Campaign',
            )}
            menus={[
              { menu: menu.HOME, active: true },
              {
                menu: isPublish
                  ? menu.MARKETER_ANNOUNCEMENT_CAMPAIGN
                  : menu.MARKETER_INVITEONLY_CAMPAIGN,
                isSamePage: true,
              },
            ]}
          />
          <div className="header-campaign-container">
            <MarketerCampaignStatusButtonGroup
              statusCount={data?.statusCount}
              param={param}
              setParam={setParam}
              filterFunction={filterMarketerCampaign}
            />
            <div className="search-add-campaign-container">
              <SearchBar
                placeholder={t('table:Search name or brand name')}
                handleSearch={handleSearch}
                handleClear={handleClear}
                disableClear
              />
              <button
                type="button"
                className="add-campaign-button"
                onClick={() => {
                  if (isPublish) {
                    history.push(`/marketer/Create/PublicCampaign`);
                  } else {
                    history.push(`/marketer/create-campaign`);
                  }
                }}
              >
                <div style={{ fontSize: '14px' }}>{t('button:Add campaign')}</div>
              </button>
            </div>
          </div>
          {!data?.totalCount ? (
            <></>
          ) : (
            <>
              {data?.items?.map((items: any) => {
                return (
                  <div
                    key={items.key}
                    style={{
                      margin: 'auto',
                    }}
                    className="card-containerMarket"
                  >
                    <Card
                      className="card"
                      onClick={(e) => {
                        e.preventDefault();
                        if (items.key) {
                          if (isPublish) {
                            history.push(
                              `/MarketerPublicCampaign?campaign=${items.key}`,
                              { camPaignName: items.name },
                            );
                          } else {
                            history.push(`/MarketerCampaign?campaign=${items.key}`, {
                              camPaignName: items.name,
                            });
                          }
                        }
                      }}
                      style={{
                        background: `${CampaignStatusBackGroundColor(items.status)}`,
                      }}
                    >
                      <CardTitle
                        style={{
                          background: 'white',
                          borderTopLeftRadius: '18px',
                          borderTopRightRadius: '18px',
                        }}
                      >
                        <div className="title-container">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="campaign-name-title">{items.name}</div>
                            <div className="brand-name-title">
                              {items.brand_name}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                              marginTop: '8px',
                            }}
                          >
                            <MarketerCampaignStatus
                              status={items.status}
                              style={{ maxWidth: '200px' }}
                            />
                          </div>
                        </div>
                      </CardTitle>
                      <CardBody style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="group-footer">
                          <div className="column-group">
                            <div className="job-count">{items.num_job}</div>
                            <div className="job">{t('table:Job')}</div>
                          </div>
                          <div className="column-group">
                            <div className="price-num">
                              {floorToFixed(items.total_price, 2)} USDT
                            </div>
                            <div className="price">
                              {t('marketer:create_campaign.Price')}
                            </div>
                          </div>
                          <div className="column-group">
                            <div className="influencer-num">
                              {items.num_influencer ? items.num_influencer : '-'}
                            </div>
                            <div className="influencer">
                              {t('marketer:create_campaign.Influencer')}
                            </div>
                          </div>
                          <div className="column-group">
                            <div className="follower-num">
                              {items.num_follower ? items.num_follower : '-'}
                            </div>
                            <div className="follower">
                              {t('marketer:create_campaign.Followers')}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                );
              })}

              <Pagination
                count={Math.ceil(data?.totalCount / param.limit)}
                page={param.page}
                color="standard"
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
                boundaryCount={2}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default MarketerCampaignIndex;
