/* eslint-disable no-return-assign */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { findSocialsByJobKeyGql } from 'src/graphql/advertiser/job';
import { linkSocialMediaToJobGql } from 'src/graphql/advertiser/socialMediaInfo';
import {
  getDefaultWebsite,
  getMarketerJob,
  getUserWalletByCurrency,
  getWallet,
} from 'src/store/actions';
import {
  getSocialMediaInfo,
  socialMediaFilterTable,
} from 'src/store/socialMediaInfo/actions';
import stepCreate from 'src/assets/images/Step1.png';
import stepSummary from 'src/assets/images/Step4.png';
import stepChooseInfu from 'src/assets/images/Step3.png';
import stepSelectSocial from 'src/assets/images/Step2.png';
import SortBy from 'src/components/Advertiser/Models/SelectInfuencer/SortBy';
import Sort from 'src/components/Advertiser/Models/Sort/Sort';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from 'src/store/users/actions';
// Assets
import bgUserCard from 'src/assets/images/bg-user-card.svg';
import { Progress } from 'reactstrap';
import '../MarketerJobCampaignDetail/stepper.style.scss';
import CustomButton from 'src/components/Common/CustomButton';
import { floorToFixed } from 'src/common/data/mathToFixed';
import paramsFromURL from 'src/constants/paramsFromURL';
import { Dialog, Pagination, Rating } from '@mui/material';
import ChannelDetailPage from 'src/pages/Publisher/Influencer/ChannelDetail/ChannelDetail.component';
import { createMarketerJobNew } from 'src/store/marketerJobNew/actions';
// eslint-disable-next-line import/order
import {
  SelectInfluencerControl,
  FlexRow,
  FlexCol,
  Texts,
  ImgTag,
  CardStyle,
  ButtonStyle,
  InputSearch,
  SearchBtn,
  SelectInfu,
  SelectSort,
  BtnSort,
  SocialCard,
  ButtonRequest,
} from './selectInfluencer.style';
import './selectInfluencer.style.scss';
// eslint-disable-next-line import/order
import { getCurrencyByIdGQL } from 'src/graphql/common';
import * as formatter from 'src/utils/convert-value';

// eslint-disable-next-line no-unused-vars, no-shadow
enum Gender {
  // eslint-disable-next-line no-unused-vars
  MALE = 'MEN',
  // eslint-disable-next-line no-unused-vars
  FEMALE = 'WOMEN',
}
// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyInfluencer {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  FOLLOWERS = 'follower',
  // eslint-disable-next-line no-unused-vars
  RATING = 'rating',
  // eslint-disable-next-line no-unused-vars
  COST = 'photo_content_price',
}
// eslint-disable-next-line no-unused-vars, no-shadow
enum PropertyType {
  // eslint-disable-next-line no-unused-vars
  FOLLOWER = 'FOLLOWER',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'INFLUENCER',
}
interface FilterForm {
  propertyType?: PropertyType | undefined;
  country?: string;
  gender?: Gender;
  max_age?: number;
  min_age?: number;
  aptitude_ids: string[];
  limit?: number;
  page?: number;
  sort_by?: string;
  name?: string;
}
const SelectInfluencerPage = () => {
  const { socialCards, setSocialCards } = SelectInfluencerControl();
  const history = useHistory();
  const [keys, setKeys] = useState<any>([]);
  const { t }: any = useTranslation();
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isEnoughBalance, setIsEnoughBalance] = useState(true);
  const [inputName, setInputName] = useState('');
  const [cardKey, setCardKey] = useState('');
  const [isRequestPrices, setIsRequestPrices] = useState<Array<number>>([]);
  const [notSelectError, setNotSelectError] = useState(false);
  const [influencerPrices, setInfluencerPrices] = useState<Record<string, number>>(
    {},
  );
  const refs = useRef([]);
  const initialStateForm: FilterForm = {
    aptitude_ids: [],
    name: inputName,
    limit: 10,
    page: 1,
  };
  const [filterForm, setFilterForm] = useState<FilterForm>(initialStateForm);
  const [sumData, setSumData] = useState<any>({
    totalPrice: 0,
    engagement: 0,
    followers: 0,
  });

  const [jobId]: any = paramsFromURL(['job']);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [pages, setPages] = useState<number>(1);
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState<any>(null);
  const [currencyId, setCurrencyId] = useState<any>(null);

  const dispatch = useDispatch();
  const {
    data,
    totalcount,
    wallet,
    marketerJob,
    defaultTax,
    marketerJobNew,
    descriptionJonNew,
  } = useSelector((state: any) => ({
    data: state.SocialMediaInfo.socialMediaFilterTable.items,
    totalcount: state.SocialMediaInfo.socialMediaFilterTable.totalCount,
    wallet: state?.Wallet?.wallet,
    marketerJob: state.MarketerJob.marketerJob,
    userInfo: state.Users.userInfo,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    marketerJobNew: state.MarketerCampaignJobsNew.marketerJobNew,
    descriptionJonNew: state.MarketerCampaignJobsNew.descriptionJobNew,
  }));
  const tax = defaultTax / 100 + 1;

  // console.log('marketerJobNew', marketerJobNew);

  const FindSocial = async () => {
    const socials = await findSocialsByJobKeyGql({ job_key: jobId });
    if (!Object.keys(marketerJob)?.length) return;
    let newPrice = 0;
    let newFollower = 0;
    let newPostEngagement = 0;
    for (let i = 0; i < socials?.length; i++) {
      const {
        key,
        photo_content_price,
        share_content_price,
        video_content_price,
        follower = socials.follower,
        post_engagement = socials.post_engagement,
      } = socials[i];
      if (!keys.includes(key)) keys.push(key);

      let value = 0;
      if (marketerJob?.content_type === 'SHARE') value = share_content_price || 0;
      if (marketerJob?.content_type === 'PHOTO') value = photo_content_price || 0;
      if (marketerJob?.content_type === 'VIDEO') value = video_content_price || 0;
      newPrice += value * tax;
      newFollower += follower;
      newPostEngagement += post_engagement;
    }
    setSumData({
      ...sumData,
      totalPrice: newPrice,
      engagement: newPostEngagement,
      followers: newFollower,
    });
    setKeys(keys);
  };

  const submitForm = {
    aptitude_ids: [],
    social_media_type:
      marketerJobNew?.job[0]?.social_media_type || marketerJob?.social_media_type,
    content_type:
      marketerJobNew?.job[0]?.content_type?.toUpperCase() ||
      marketerJob?.content_type,
  };

  const selectInfluencer = (card: SocialCard, price: number) => {
    const obj: any = { ...influencerPrices };
    if (card.key in influencerPrices) {
      delete obj[card.key];
    } else {
      obj[card.key] = price;
    }

    setInfluencerPrices({ ...obj });
  };

  useEffect(() => {
    const values = localStorage.getItem('marketerJobNew');
    const descriptionJobNew = localStorage.getItem('descriptionJobNew');
    const d = async () => {};
    if (values) {
      dispatch(createMarketerJobNew(JSON.parse(values)));
    }
    if (descriptionJobNew && JSON.parse(descriptionJobNew)?.currency_id) {
      setCurrencyId(JSON.parse(descriptionJobNew)?.currency_id);
    }
    d();
  }, []);
  useEffect(() => {
    dispatch(getDefaultWebsite());
    dispatch(getSocialMediaInfo());
    //dispatch(getWallet());
    if (jobId) {
      dispatch(getMarketerJob(jobId));
    }
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    if (marketerJob && jobId) {
      FindSocial();
    }
  }, [marketerJob]);

  useEffect(() => {
    // const { currency_id } = descriptionJonNew;
    // if (currency_id) {
    //   setCurrencyId(currency_id);
    // }
  }, [descriptionJonNew]);

  const fetchCurrency = async (currency_id: number) => {
    const _currency = await getCurrencyByIdGQL(currency_id);
    setCurrency(_currency);
  };

  useEffect(() => {
    if (currencyId) {
      dispatch(getUserWalletByCurrency(currencyId));
      fetchCurrency(currencyId);
    } else {
      dispatch(getWallet());
    }
  }, [currencyId]);

  const updateTotalData = () => {
    setSumData((pre: any) => {
      return {
        ...pre,
        totalPrice: Object.values(influencerPrices).reduce((a, b) => a + b, 0),
      };
    });
  };
  useEffect(() => {
    updateTotalData();
  }, [influencerPrices]);

  useEffect(() => {
    if (marketerJob?.social_media_type || marketerJobNew?.job[0]?.social_media_type)
      dispatch(socialMediaFilterTable(submitForm));
  }, [marketerJob, marketerJobNew]);
  useEffect(() => {
    setSocialCards(data);
  }, [data]);
  // const balance = wallet?.balance || 0;

  useEffect(() => {
    if (wallet?.balance) {
      setBalance(wallet.balance);
    }
  }, [wallet]);

  useEffect(() => {
    if (balance >= sumData.totalPrice) {
      setIsEnoughBalance(true);
    } else {
      setIsEnoughBalance(false);
    }
  }, [wallet, sumData.totalPrice, balance]);

  const toggle = () => {
    setIsOpen(!isOpen);
    // setFilterForm(initialStateForm);
  };

  const toggleSort = () => {
    setIsOpenSort(!isOpenSort);
  };
  const handleClose = () => {
    setIsOpenProfile(false);
  };
  const SelectedCard = (
    card: any,
    price: number,
    isSelected: Boolean,
    selectedIndex?: number,
  ) => {
    if (isSelected) {
      setSumData({
        ...sumData,
        totalPrice: sumData.totalPrice - price,
        engagement: sumData.engagement - card.post_engagement,
        followers: sumData.followers - card.follower,
      });
      const newKeys = keys.filter(
        (item: any, index: number) => index !== selectedIndex,
      );
      setKeys(newKeys);
    } else {
      setSumData({
        ...sumData,
        totalPrice: sumData.totalPrice + price,
        engagement: sumData.engagement + card.post_engagement,
        followers: sumData.followers + card.follower,
      });
      setKeys([...keys, card.key]);
    }
    updateTotalData();
  };

  const onChangeValue = (event: any) => {
    setFilterForm({
      ...filterForm,
      name: event.target.value,
    });
    setInputName(event.target.value);
  };
  // const getAge = (date: any): number => {
  //   const year = date.slice(0, 4);
  //   const d = new Date();
  //   const yearNow = d.getFullYear();
  //   const age = yearNow - year;
  //   return age;
  // };

  const stepDisplay = () => {
    return (
      <div className="stepper-container">
        <>
          <img src={stepCreate} alt="stepCreate" className="stepper-active-img" />

          <div className="stepper-dot" />
        </>
        <>
          <img
            src={stepSelectSocial}
            alt="stepCreate"
            className="stepper-active-img"
          />

          <div className="stepper-dot" />
        </>
        <>
          <img
            src={stepChooseInfu}
            alt="stepChooseInfu"
            className="stepper-active-img"
          />

          <div className="stepper-dot" />
        </>

        <img src={stepSummary} alt="stepSummary" className="stepper-active-img" />
      </div>
    );
  };
  return (
    <div className="page-content">
      <div className="stepper-progressbar-container">
        <div style={{ marginBottom: '38px' }}>{stepDisplay()}</div>
        <Progress
          style={{
            transform: 'scale(0.8)',
            height: '35px',
            width: '100%',
            borderRadius: '44px',
            background: '#D9D9D9',
          }}
          max="3"
          value="2"
        />
        <div
          style={{
            marginTop: '24px',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '20px',
            letterSpacing: '0em',
            color: '#495057',
            textAlign: 'center',
          }}
        >
          STEP 3 : {t('marketer:create_job_campaign:Select Influencer')}
        </div>
      </div>

      {/* Search and filter */}
      <FlexCol style={{ margin: '2.5rem 0 auto' }}>
        {/* Search input */}
        <FlexRow
          width="100%"
          height="5rem"
          marginTop="-10px"
          justifycontent="center"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.4rem',
              background: '#ffffff',
              borderRadius: '8px',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              width: '100%',
              maxWidth: '420px',
            }}
          >
            <InputSearch
              type="text"
              placeholder={t('marketer:create_job_campaign:Search by keyword')}
              name="search"
              onChange={(e) => onChangeValue(e)}
            />
            <SearchBtn
              type="submit"
              className="searchButton"
              onClick={(e: any) => {
                e.preventDefault();
                let formSub;
                if (marketerJob && jobId) {
                  formSub = {
                    ...filterForm,
                    social_media_type: marketerJob.social_media_type,
                    content_type: marketerJob.content_type,
                  };
                } else {
                  formSub = {
                    ...filterForm,
                    social_media_type: marketerJobNew?.job[0]?.social_media_type,
                    content_type: marketerJobNew?.job[0]?.content_type,
                  };
                }

                delete formSub.propertyType;
                dispatch(socialMediaFilterTable(formSub));
              }}
            >
              <i
                className="fa fa-search"
                style={{
                  position: 'relative',
                  margin: '5px auto',
                  // color: '	#6495ED',
                }}
              />
            </SearchBtn>
          </div>
        </FlexRow>
        {/* Filter */}
        <FlexRow
          width="100%"
          height="5rem"
          marginTop="-10px"
          justifycontent="center"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.4rem',
              background: '#ffffff',
              borderRadius: '8px',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              width: '100%',
              maxWidth: '420px',
            }}
          >
            <SelectInfu>
              <BtnSort name="cars" id="cars" onClick={() => setIsOpen(!isOpen)}>
                <Texts margin="auto" fontsize="1rem">
                  {t('marketer:create_job_campaign:filter influencer from')}{' '}
                  <i
                    className="fas fa-caret-down"
                    style={{
                      position: 'absolute',
                      margin: '3px 10px',
                    }}
                  />
                </Texts>
              </BtnSort>
            </SelectInfu>
            <SelectSort>
              <BtnSort
                name="cars"
                id="cars"
                onClick={() => setIsOpenSort(!isOpenSort)}
              >
                <Texts margin="auto" fontsize="1rem">
                  {t('marketer:create_job_campaign:Sort_by')}
                  <i
                    className="fas fa-caret-down"
                    style={{
                      position: 'absolute',
                      margin: '3px 10px',
                    }}
                  />
                </Texts>
              </BtnSort>
            </SelectSort>
          </div>
        </FlexRow>
      </FlexCol>
      {/* Summary cards */}
      <FlexRow
        width="100%"
        height="auto"
        justifycontent="center"
        borderRadius="5px"
        marginTop="0rem"
        marginBottom={!isEnoughBalance ? '1.5rem' : '3rem'}
        padding="0"
      >
        <FlexRow
          width="60%"
          height="100%"
          justifycontent="center"
          style={{ gap: '0.5rem' }}
        >
          <FlexCol
            width="10rem"
            height="8rem"
            style={{
              textAlign: 'center',
              borderRadius: '8px',
              boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
              padding: '0.5rem',
              background: '#ffffff',
            }}
          >
            <i
              className="fas fa-chalkboard-teacher"
              style={{
                width: 'auto',
                height: 'auto',
                fontSize: '2.5em',
                marginBottom: '0.9rem',

                color: '#AEBAC9',
              }}
            />
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {keys?.length}
            </Texts>
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {t('marketer:create_job_campaign:Influencer')}
            </Texts>
          </FlexCol>
          <FlexCol
            width="10rem"
            height="8rem"
            style={{
              textAlign: 'center',
              borderRadius: '8px',
              boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
              padding: '0.5rem',
              background: '#ffffff',
            }}
          >
            <i
              className="fas fa-users"
              style={{
                width: 'auto',
                height: 'auto',
                fontSize: '2.5em',
                marginBottom: '0.9rem',
                color: '#9997AD',
              }}
            />
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {sumData.followers}
            </Texts>
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {t('marketer:create_job_campaign:Follower')}
            </Texts>
          </FlexCol>
          <FlexCol
            width="10rem"
            height="8rem"
            style={{
              textAlign: 'center',
              borderRadius: '8px',
              boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
              padding: '0.5rem',
              background: '#ffffff',
            }}
          >
            <i
              className="fas fa-handshake"
              style={{
                width: 'auto',
                height: 'auto',
                fontSize: '2.5em',
                marginBottom: '0.9rem',

                color: '#8B7489',
              }}
            />
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {sumData.engagement}
            </Texts>
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {t('marketer:create_job_campaign:Engagement')}
            </Texts>
          </FlexCol>
          <FlexCol
            width="10rem"
            height="8rem"
            style={{
              textAlign: 'center',
              borderRadius: '8px',
              boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
              padding: '0.5rem',
              background: '#ffffff',
            }}
          >
            <i
              className="fas fa-coins"
              style={{
                width: 'auto',
                height: 'auto',
                fontSize: '2.5em',
                marginBottom: '0.9rem',

                color: '#7A535F',
              }}
            />
            <Texts margin="0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {t('marketer:create_job_campaign:use_from', {
                amount: formatter.naFormatter(
                  Object.values(influencerPrices).reduce(
                    (prev, cur) => prev + cur,
                    0,
                  ),
                  2,
                ),
                // || floorToFixed(sumData.totalPrice, 2),
                symbol: currency?.main_symbol ?? 'USDT',
              })}
            </Texts>
            <Texts
              margin="0"
              style={{ textAlign: 'center', fontWeight: 'bold', color: '#009A78' }}
            >
              {floorToFixed(balance, 2)} {currency?.main_symbol ?? 'USDT'}
            </Texts>
          </FlexCol>
        </FlexRow>
      </FlexRow>
      {/* Error balance */}

      {/* Cards influencer */}
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div className="width-cover">
          <div className="select-influencer-container">
            {socialCards &&
              socialCards.map((card: SocialCard, index: number) => {
                let price: any = 0;
                let price_rate: any = 1;
                const influencerPrice = (influencerPrices as Record<string, number>)[
                  card.key
                ];

                const isSelected: boolean = keys?.includes(card.key);

                const isRequestPrice: boolean = isRequestPrices?.includes(index);
                const selectedIndex = keys?.indexOf(card.key);
                switch (
                  marketerJobNew?.job[0]?.content_type ||
                  marketerJob?.content_type
                ) {
                  case 'SHARE':
                    price = card?.share_content_price * tax || 0;
                    break;
                  case 'PHOTO':
                    price = card?.photo_content_price * tax || 0;
                    break;
                  case 'VIDEO':
                    price = card?.video_content_price * tax || 0;
                    break;
                  default:
                    break;
                }
                price_rate = currency?.is_marketer_currency
                  ? currency.price_in_USDT
                  : currency?.rate_in_USD ?? 1;
                price /= price_rate;
                return (
                  <>
                    <CardStyle
                      key={card.key}
                      onClick={() => {
                        SelectedCard(card, price, isSelected, selectedIndex);
                        if (marketerJobNew?.job[0]?.content_type) {
                          selectInfluencer(
                            card,
                            parseFloat((refs as any).current[index].value),
                          );
                        }
                        if (notSelectError) setNotSelectError(false);
                      }}
                      className="icon-select-influencer"
                      style={{
                        minWidth: '180px',
                        border: isSelected
                          ? '2.5px solid #a6dfad'
                          : '2.5px solid white',
                        borderRadius: '8px',
                        backgroundImage: `url(${bgUserCard})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    >
                      <div
                        className={
                          isSelected ? 'icon-container icon-check' : 'icon-container'
                        }
                      >
                        <i className="fas fa-check icon" />
                      </div>
                      <FlexCol>
                        <ImgTag
                          src={card?.image_profile_url}
                          alt="Channel Logo"
                          className="channel-logo"
                          width="45px"
                          height="45px"
                          borderradius="50%"
                          style={{
                            boxShadow:
                              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                          }}
                        />
                        <Texts
                          fontsize="1rem"
                          className="name-overflow"
                          style={{
                            wordBreak: 'break-all',
                            margin: '0',
                            marginTop: '0.5rem',
                            color: '#354B45',
                            fontWeight: 'bold',
                            textShadow:
                              '2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3), 2px 2px 2px rgba(206,89,55,0)',
                          }}
                        >
                          {card.name}
                        </Texts>
                      </FlexCol>
                      <FlexRow
                        justifycontent="center"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Rating
                          name="rating"
                          size="small"
                          value={card?.rating}
                          precision={0.25}
                          readOnly
                        />
                      </FlexRow>
                      <FlexRow
                        justifycontent="center"
                        width="100%"
                        style={{ padding: 0 }}
                      >
                        <FlexCol
                          height="fit-content"
                          width="80%"
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Texts
                            style={{
                              position: 'relative',
                              margin: '0',
                              fontSize: '0.9rem',
                              fontWeight: 'bolder',
                              textAlign: 'center',
                              width: '100%',
                            }}
                          >
                            {card.follower}
                          </Texts>
                          <Texts
                            style={{
                              position: 'relative',
                              margin: '0',
                              fontSize: '14px',
                              textAlign: 'center',
                              width: '100%',
                              color: '#A2B5BB',
                            }}
                          >
                            {t('marketer:create_job_campaign:Follower')}
                          </Texts>
                        </FlexCol>
                      </FlexRow>

                      <FlexCol width="100%">
                        <div
                          style={{
                            display: 'flex',
                            // gap: '4px',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          <input
                            ref={(el) => ((refs as any).current[index] = el)}
                            type="number"
                            disabled={!isRequestPrice}
                            style={{
                              marginTop: '5px',
                              fontSize: '0.9rem',
                              fontWeight: 'bolder',
                              width: isRequestPrice ? '60px' : '70px',
                              color: '#009A78',
                              margin: '0',
                              outline: '0',
                              border: isRequestPrice ? '1px solid' : '0',
                              background: 'white',
                              textAlign: isRequestPrice ? 'center' : 'center',
                              marginRight: '2rem',
                            }}
                            defaultValue={
                              influencerPrice
                                ? floorToFixed(influencerPrice, 2)
                                : price
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                          <span
                            style={{
                              display: 'flex',
                              position: 'absolute',
                              right: isRequestPrice ? '-0.2rem' : '0rem',
                            }}
                          >
                            {currency?.main_symbol ?? 'USDT'}
                          </span>
                        </div>

                        {isRequestPrice ? (
                          <div style={{ display: 'flex', marginTop: '4px' }}>
                            <button
                              className="btn-request-price"
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                let list = [...isRequestPrices];
                                list = list.filter((value) => value !== index);
                                setIsRequestPrices(list);
                                if (card.key in influencerPrices) {
                                  const currentPrice = (refs as any).current[index]
                                    .value;
                                  influencerPrices[card.key] =
                                    parseFloat(currentPrice);
                                  setInfluencerPrices(influencerPrices);
                                  updateTotalData();
                                }
                              }}
                            >
                              Submit
                            </button>
                            <button
                              className="btn-request-price"
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                let list = [...isRequestPrices];
                                list = list.filter((value) => value !== index);
                                setIsRequestPrices(list);
                                (refs as any).current[index].value = floorToFixed(
                                  influencerPrice,
                                  2,
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <ButtonRequest
                            onClick={(e) => {
                              if (card.key in influencerPrices) {
                                e.stopPropagation();
                              }
                              // e.stopPropagation();
                              const list = [...isRequestPrices];
                              list.push(index);
                              setIsRequestPrices(list);
                            }}
                          >
                            {t('button:Request Price')}
                          </ButtonRequest>
                        )}

                        <ButtonStyle
                          style={{ fontSize: '0.8rem', marginTop: '3px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            setCardKey(card.key);
                            setIsOpenProfile(true);
                          }}
                        >
                          {t('marketer:create_job_campaign:Profile')}
                        </ButtonStyle>
                      </FlexCol>
                    </CardStyle>
                  </>
                );
              })}
          </div>
        </div>
      </div>

      <FlexRow width="100%" justifycontent="center">
        {!isEnoughBalance && (
          <FlexRow width="100%" justifycontent="center">
            <Texts
              fontsize="1rem"
              style={{
                color: '	#CD5C5C',
                textAlign: 'center',
                fontWeight: 'bolder',
              }}
            >
              {t('marketer:create_job_campaign:Your balance is not enough.')}
            </Texts>
          </FlexRow>
        )}
        {notSelectError && (
          <FlexRow width="100%" justifycontent="center">
            <Texts
              fontsize="1rem"
              style={{
                color: '	#CD5C5C',
                textAlign: 'center',
                fontWeight: 'bolder',
              }}
            >
              {t('marketer:create_job_campaign:Choose at least one influencer.')}
            </Texts>
          </FlexRow>
        )}
        <div style={{ margin: '20px', width: '150px', height: '40px' }}>
          <CustomButton
            size="md"
            status="submit"
            label={t('marketer:create_job_campaign:Submit')}
            handleClick={async () => {
              if (jobId && keys?.length && balance >= sumData.totalPrice) {
                await linkSocialMediaToJobGql({
                  social_media_info_keys: keys,
                  job_key: jobId,
                  end_date: new Date(),
                });
                history.push(`/JobSummary?job=${jobId}`);
              } else if (!keys?.length || balance < sumData.totalPrice) {
                setNotSelectError(true);
              } else if (
                marketerJobNew?.job[0]?.content_type &&
                socialCards?.length > 0
              ) {
                const influencers = [];
                // eslint-disable-next-line no-restricted-syntax
                for (const [key, value] of Object.entries(influencerPrices)) {
                  influencers.push({
                    social_media_info_key: key,
                    price: value,
                  });
                }
                const submitValues = { ...marketerJobNew };
                submitValues.job[0].social_media_info = [...influencers];
                dispatch(createMarketerJobNew(submitValues));
                localStorage.setItem('marketerJobNew', JSON.stringify(submitValues));
                history.push('/Marketer/ScheduleJob');
              }
            }}
            // isDisable={!keys.length}
          />
        </div>
      </FlexRow>
      <SortBy
        isOpen={isOpen}
        toggle={toggle}
        setFilterForm={setFilterForm}
        filterForm={filterForm}
      />
      <Sort isOpen={isOpenSort} toggle={toggleSort} />
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        open={isOpenProfile}
        PaperProps={{
          style: { position: 'relative', margin: '0 5px' },
        }}
        style={{ zIndex: 1050 }}
      >
        <span
          style={{
            position: 'absolute',
            right: '10px',
            top: '5px',
            fontSize: '25px',
            fontWeight: 'bold',
            cursor: 'pointer',
            zIndex: '1100',
          }}
          onClick={handleClose}
        >
          X
        </span>
        <ChannelDetailPage influencerKey={cardKey} />
      </Dialog>
    </div>
  );
};

export default SelectInfluencerPage;
