import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { layoutTheme } from 'src/constants/layout';
import logo from '../../../assets/images/flupower/sleeplogo.png';
import logoWhite from '../../../assets/images/flupower/footpagelogo.png';

import control from './TermsOfUse.control';
import './TermsOfUse.style.scss';

const TermsOfUsePage = () => {
  const { layoutMode } = control();
  const { t } = useTranslation();

  return (
    <div className="terms-of-use-container text-white">
      <ul className="bg-bubbles">
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      <Link to="/" className="d-block auth-logo" style={{ zIndex: 2 }}>
        <img
          src={layoutMode === layoutTheme.DARKMODE ? logoWhite : logo}
          alt=""
          height="28"
        />
      </Link>
      <div className="card">
        <span className="header">{t('Terms of Use')}</span>
        <hr />
        <div className="content-container">
          <p className="content">
            {t(
              'Welcome to Flupower.com to read the contents of the term of use by continuing to use our website, you agree to these terms of use.This website is created and maintained by Flupower Limited. 11/F WHEELOCK HSE 20 PEDDER ST,CENTRAL, HONG KONG. Please read before use.',
            )}
          </p>
          <p className="content">
            {t(
              'Flupower.com every effort has been made to provide a user experience that is comfortable and efficient. However, we will not be liable for any loss arising from the information on this website.',
            )}
          </p>
          <p className="content">
            {t(
              'The Company may, at its discretion, make amendments. or update these Terms of Use from time to time. Changes will take effect as soon as the Company publishes them on the Flupower platform. And any changes in jurisdiction relating to dispute resolution set forth in the Governing Law and Jurisdiction section.It shall not apply to disputes for which the parties have given notice to each other on or before the date on which the changes will be published on the Flupower Platform.Marketer is continued use of the Flupower platform following the publication of the revised Terms of Use. It is assumed that the Marketer accepts and agrees to the changes.The Marketer must check this page each time the Marketer logs into the Flupower platform to keep the Marketer informed of any changes.',
            )}
          </p>
          <p className="content">
            {t(
              'The Company reserves the right in its sole discretion to discontinue or modify the Flupower Platform, the Services, or any Content. that the company has published on the Flupower Platform without notice.The Company shall not be liable if the Flupower Platform is completely or partially unavailable.and at any time or at any time the company may suspend the user. As well as registered users have full or partial access to the Flupower Platform.',
            )}
          </p>
          <p className="content">
            {t(
              'If the Marketer chooses or receives username and password or any information as part of the security process. Marketers must keep that information confidential.And the Marketer must not disclose that information to any other person or entity. In addition, the Marketer acknowledges that Member Accounts are the exclusive rights of the Marketer.And agrees not to allow any other person to access the Flupower Platform or any part of the Flupower Platform using a username, and password or Marketer is security informationThe Marketer agrees to notify the company as soon as the username and password is used without permission or there is a breach of security. In addition, the Marketer agrees that the Marketer will leave or sign out of the Member Account at the end of use.Marketers must be caution when logging in to member accounts from the public. or a shared computer so that others do not see or save the password.Or other personal information, the company will not be liable for the loss. or any damages arising from the Marketer is failure to comply with the above requirements.',
            )}
          </p>
          <p className="content">
            {t(
              'The company has the right to suspend the use of the username, password, or any other identification at the Marketer is request or at the Company is discretion, at any time.and for whatever reason or no reason including if the Company considers that the Marketer has violated any provision of these Terms of Use.',
            )}
          </p>
          <p className="content">
            {t(
              'The Company does not claim that the Flupower Platform or any part of the Content is accessible or suitable for use outside of Thailand.The access to the Flupower Platform may not be legal for certain persons or countries. If the Marketer logs into the Flupower Platform from outside Thailand, it is assumed that the Marketer is the sole operator.And are responsible for their own compliance with local laws.',
            )}
          </p>
          <p className="content">
            {t('Please read')}{' '}
            <Link to="/privacy-policy">
              <a href="TermsOfUse">{t('Privacy & Policy')}.</a>
            </Link>{' '}
            {t(
              'To understand the information we collect from you, including the use of visitor personal information, access.',
            )}
          </p>
          <p className="content">
            {t(
              'Flupower.com Every effort has been made to enable people with disabilities to access and use our website. We have no intention of infringing accessibility rights of disabled people in any way.',
            )}
          </p>
          {/* <p className="content">
            เว็บ Flupower.com
            ติดตั้งโค้ดที่จะบล็อกไม่ให้ผู้อ่านสามารถดูเนื้อหาในเว็บได้ถ้าหากเว็บบราวเซอร์ของผู้อ่านติดตั้ง
            extension ที่ใช้เพื่อบล็อคโฆษณา
            ทางเราจำเป็นต้องทำเช่นนี้เนื่องจากเราต้องหารายได้จากการขายโฆษณามาจ่ายค่าบำรุงเซิฟเวอร์
            รวมถึงมาหล่อเลี้ยงนักเขียนของเราเพื่อให้ทางเราสามารถผลิตคอนเท้นที่มีคุณภาพได้
            ทางผู้ใช้งานสามารถปิด Adblocker ของท่านก่อนเข้าชมเนื้อหา หรือ whitelist
            เว็บของเราออกจาก adblocker ของท่าน
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default TermsOfUsePage;
