import config from 'src/config';
import { useRef, useState, createRef, useEffect, memo } from 'react';
import useWindowDimensions from 'src/components/useWindowDimensions';
import { removeSocialJobImageByKey } from 'src/graphql/advertiser/socialMediaInfo';
import { getSocialJob } from 'src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import './jobDetailCollapse5.style.scss';
import Trash from 'src/assets/images/Trash-01.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Role from 'src/constants/enum/role';

// eslint-disable-next-line no-unused-vars
const JobDetailImagePreview = ({
  items,
  socialJobId,
  selectedImages,
  setSelectedImages,
  collapse2,
  newImageKeys,
  setNewImageKeys,
  isChecked,
}: any) => {
  const { windowWidth } = useWindowDimensions();
  const [imagesWidth, setImagesWidth] = useState(0);
  const dispatch = useDispatch();
  const [scrollLeft, setScrollLeft] = useState<any>(0);
  const [displayImageKey, setDisplayImageKey] = useState([]);

  useEffect(() => {
    if (!items?.image_storages) return;

    const newDisplay = newImageKeys
      ? [...items?.image_storages, ...newImageKeys]
      : items?.image_storages;
    setDisplayImageKey(newDisplay);
  }, [items?.image_storages, newImageKeys]);

  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const deleteUploadedImage = async (key: string, oldIndex: number) => {
    await removeSocialJobImageByKey(key);

    const editNewImageKey = newImageKeys.filter(
      (newImageKey: any) => newImageKey.key !== key,
    );
    setNewImageKeys(editNewImageKey);

    const newDisplayImage = displayImageKey.filter((_, index) => oldIndex !== index);
    setDisplayImageKey(newDisplayImage);
  };

  const slideImage: any = useRef();

  const refs: any = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const sumImageWidth = () => {
    setTimeout(() => {
      let newImagesWidth = 0;
      for (let i = 0; i < refs.current?.length; i++) {
        const ref = refs.current[i];
        const imageWidth = ref?.current?.offsetWidth;

        newImagesWidth += imageWidth || 0;
      }
      setImagesWidth(newImagesWidth);
    }, 300);
  };
  sumImageWidth();
  useEffect(() => {
    sumImageWidth();
  }, [items]);

  const screenWidth: any = slideImage?.current?.scrollWidth;

  let currentScreenWidth: any = slideImage?.current?.clientWidth;
  const SlideWidth: any = slideImage?.current?.scrollLeft;

  if (displayImageKey?.length === 0) {
    currentScreenWidth = 0;
  }
  if (!items?.image_storages?.length && !newImageKeys) return <></>;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div className="image-carousel-container">
        <div
          ref={slideImage}
          onScroll={(e: any) => {
            setScrollLeft(e?.currentTarget?.scrollLeft);
          }}
          style={{
            overflowX: 'auto',
            display: '-webkit-box',
            width: '100%',
            scrollBehavior: 'smooth',
          }}
        >
          <div
            style={{
              display: 'flex',
              position: 'absolute',
            }}
          >
            <div
              style={{
                color: 'white',
                borderRadius: '50%',
                cursor: 'pointer',
                background: '#ff7785',
                width: '30px',
                height: '30px',
                zIndex: '1',
                marginLeft: '35px',
                marginTop: '85px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                visibility: !scrollLeft ? 'hidden' : 'visible',
              }}
              onClick={() => {
                slideImage.current.scrollLeft -= windowWidth * 0.3;
              }}
            >
              <KeyboardArrowLeftIcon />
            </div>
            <div
              style={{
                color: 'white',
                borderRadius: '50%',
                cursor: 'pointer',
                background: '#ff7785',
                width: '30px',
                marginTop: '85px',
                zIndex: '1',
                visibility:
                  (screenWidth - SlideWidth - currentScreenWidth !== 0 ||
                    (SlideWidth === 0 && currentScreenWidth < imagesWidth)) &&
                  currentScreenWidth
                    ? 'visible'
                    : 'hidden',
                marginLeft: `${currentScreenWidth - 120 || 0}px`,
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                slideImage.current.scrollLeft += windowWidth * 0.3;
              }}
            >
              <KeyboardArrowRightIcon />
            </div>
          </div>
          {items?.marketer_approve_date && !collapse2
            ? items.image_storages.map((data: any, index: number) => {
                return (
                  data.marketer_verified && (
                    <div
                      key={data?.key}
                      ref={refs.current[index]}
                      style={{
                        position: 'relative',
                        borderRadius: '8px',
                        width: 'fit-content',
                        background: '#77CE6F',
                        margin: '3px',
                        overflow: 'hidden',
                        height: '200px',
                        padding: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src={`${config.backendUrl}api/socialJob/${data?.key}`}
                        alt={data?.key}
                        style={{
                          width: 'auto',
                          height: '100%',
                        }}
                      />
                    </div>
                  )
                );
              })
            : displayImageKey.map((data: any, index: number) => {
                // newImageKeys
                return (
                  <div
                    key={data?.key}
                    ref={refs.current[index]}
                    style={{
                      position: 'relative',
                      borderRadius: '8px',
                      width: 'fit-content',
                      // background: '#77CE6F',
                      margin: '3px',
                      overflow: 'hidden',
                      height: '200px',
                      // padding: selectedImages?.includes(data?.key) ? '8px' : '0',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (
                        user.role === Role.ADVERTISER &&
                        !items?.marketer_approve_date
                      )
                        if (!selectedImages?.includes(data?.key)) {
                          setSelectedImages([...selectedImages, data?.key]);
                        } else {
                          const newSelect = selectedImages.filter(
                            (item: any) => item !== data?.key,
                          );
                          setSelectedImages(newSelect);
                        }
                    }}
                  >
                    {user.role === Role.PUBLISHER && !items.marketer_approve_date ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          deleteUploadedImage(data.key, index);
                        }}
                      >
                        <img
                          src={Trash}
                          alt="trash"
                          style={{ width: '30px', height: '30px' }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className="job-img"
                      style={{
                        padding: selectedImages?.includes(data?.key) ? '8px' : '0',
                      }}
                    >
                      <img
                        src={`${config.backendUrl}api/socialJob/${data?.key}`}
                        alt={data?.key}
                        style={{
                          width: 'auto',
                          height: '100%',
                        }}
                      />
                      {selectedImages?.includes(data?.key) && (
                        <i className="fas fa-check" />
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default memo(JobDetailImagePreview);
