import { call, delay, put, takeEvery } from 'redux-saga/effects';
// Login Redux States
import { isEmpty } from 'lodash';
import Role from 'src/constants/enum/role';
import {
  loginFailed,
  loginSuccess,
  logoutUserSuccess,
  resendEmailFailed,
  resendEmailSuccess,
  getSocialMediaInfoImageSuccess,
  getSocialMediaInfoImage,
} from './actions';
import LoginTypes from './actionTypes';
// Include Both Helper File with needed methods
import { Response } from '../../../common/types';
import {
  getSocialMediaInfoImageGql,
  login as loginGql,
  logout as logoutGql,
  resendEmail as resendEmailGql,
} from '../../../graphql/common/auth';
import { getLoggedInUser as getLoggedInUserGql } from '../../../graphql/common/index';
import i18n from '../../../i18n';
import { getErrorMessageByErrorCode } from '../../../utils/error';
import notify from '../../../utils/notify';
import {
  clearActivityLogs,
  getAllAdminApproveLog,
  getLoggedInUserSuccess,
  getRecentActivityLogs,
  getUserInfo,
  getWallet,
  removeUserStore,
} from '../../actions';
import { setLoading, unsetLoading } from '../../global/actions';
import { changeLayout } from '../../layout/actions';

function* loginUser({ payload: { data } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(loginGql, data);

    // if (!isEmpty(response) && !isEmpty(response.errors)) {
    //   yield put(
    //     loginFailed(
    //       getErrorMessageByErrorCode(response.errors[0].error_code),
    //       response.errors[0].error_code,
    //     ),
    //   );
    // }
    if (!isEmpty(response) && !isEmpty(response.data)) {
      const { data: loginData } = response;

      const d = new Date();

      localStorage.setItem('access-token', loginData.login.accessToken);
      localStorage.setItem('last-silent-refresh', d.getTime().toString());
      const { user } = loginData.login;
      yield put(loginSuccess(user));
    } else {
      yield put(unsetLoading());
      throw response.errors;
    }
  } catch (error: any) {
    yield put(loginFailed(error[0]));
  }
}

function* loginSuccessSaga({ payload }: any) {
  yield delay(500);
  yield put(setLoading());
  yield put(getUserInfo(true));
  yield put(getWallet());
  yield put(getRecentActivityLogs());
  if (payload.role === Role.PUBLISHER) {
    yield put(getSocialMediaInfoImage(true));
  }
  if (payload.role === Role.ADMIN) {
    yield put(getAllAdminApproveLog());
  }
  yield put(changeLayout(payload.role));
}

function* getSocialMediaInfoImageSaga({ unLoading }: any) {
  try {
    yield put(setLoading());
    const response: Promise<any> = yield call(getSocialMediaInfoImageGql);

    yield put(getSocialMediaInfoImageSuccess(response));
  } catch (error) {
    // something here
  } finally {
    if (!unLoading) {
      yield put(unsetLoading());
    }
  }
}
function* logoutUser({ payload: { history } }: any) {
  try {
    yield call(logoutGql);

    yield put(logoutUserSuccess());
    yield put(clearActivityLogs());
    yield put(removeUserStore());

    if (history) notify(i18n.t('success:Logout Success'), 'success');

    const persistRoot = localStorage.getItem('persist:root');
    const userData = persistRoot ? JSON.parse(persistRoot) : null;
    const userLayout = userData ? JSON.parse(userData.Layout) : null;

    localStorage.removeItem('access-token');
    localStorage.removeItem('last-silent-refresh');

    yield delay(1000);

    if (history) {
      window.location.replace(`/`);
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}

function* resendEmail({ payload: { email, role } }: any) {
  try {
    yield put(setLoading());

    const response: Response = yield call(resendEmailGql, email, role);

    if (!isEmpty(response) && !isEmpty(response.errors)) {
      yield put(
        resendEmailFailed(
          getErrorMessageByErrorCode(response.errors[0].error_code),
          response.errors[0].error_code,
        ),
      );
    }

    if (!isEmpty(response) && !isEmpty(response.data)) {
      yield put(resendEmailSuccess());
      notify(i18n.t('success:Resend Email Success'), 'success');
    }
  } catch (error) {
    yield put(resendEmailFailed(error));
  } finally {
    yield put(unsetLoading());
  }
}

function* getLoggedInUser() {
  const response: Promise<any> = yield call(getLoggedInUserGql);

  yield put(getLoggedInUserSuccess(response));
}

function* authSaga() {
  yield takeEvery(
    LoginTypes.GET_SOCIAL_MEDIA_INFO_IMAGE,
    getSocialMediaInfoImageSaga,
  );

  yield takeEvery(LoginTypes.LOGIN_SUCCESS, loginSuccessSaga);
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
  yield takeEvery(LoginTypes.RESEND_EMAIL, resendEmail);
  yield takeEvery(LoginTypes.GET_LOGGEDIN_USER, getLoggedInUser);
}

export default authSaga;
